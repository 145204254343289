import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import useMutateThirdParties from "./useMutateThirdParties";

export const useDisassociateThirdParty = (
  agreementId: number,
  thirdPartyId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementCommandApi);
  const mutateThirdParties = useMutateThirdParties(agreementId);

  return useCommand<void, void, ResponseError>(
    () =>
      api.disassociateThirdParty({
        agreementId,
        thirdPartyId,
      }),
    {
      ...options,
      onSuccess: (result) => {
        options.onSuccess?.(result);
        mutateThirdParties();
      },
    },
  );
};
