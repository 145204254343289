import { AmountField, Form } from "@ldms/mui-sdk/forms";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAddFinancialProduct } from "api/onboarding/financial-products/addFinancialProduct";
import { TranslateDayCountConvention } from "apps/onboarding/components";
import { ControlledTextField } from "common/components";
import { useResponseError, useYupResolver } from "common/hooks";
import {
  DayCountConventionModel,
  FrequencyOfInstalmentsModel,
  ProductTypeModel,
  SettlementTypeModel,
} from "generated/onboarding/models";
import { Controller, Resolver, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type AddFinancialProductFormFieldValues = {
  name: string;
  financialProductType: ProductTypeModel;
  interestAccrualFrequency: FrequencyOfInstalmentsModel;
  interestCompoundingFrequency: FrequencyOfInstalmentsModel;
  dayCountConvention: DayCountConventionModel;
  settlementType: SettlementTypeModel;
  discountPercentage?: number | null;
  allowBalloonPayment: boolean;
  applyBalloonAsLastPayment: boolean;
  allowAdvancedPayment: boolean;
  allowResidualValue: boolean;
  allowSecondaryRental: boolean;
};

const discountPercentageLabel =
  "add_financial_product.discount_percentage_label";
const nameLabel = "add_financial_product.name_label";
const settlementTypeLabel = "add_financial_product.settlement_type_label";
const commonSelect = "common:please_select";

const useAddFinancialProductResolver =
  (): Resolver<AddFinancialProductFormFieldValues> => {
    const { t } = useTranslation("onboardings");

    const transformField = (v: number): number | undefined => {
      return isNaN(v) ? undefined : v;
    };

    return useYupResolver<AddFinancialProductFormFieldValues>((yup) =>
      yup.object().shape({
        name: yup
          .string()
          .isRequired(t(nameLabel))
          .maxCharacters(50, t(nameLabel)),
        financialProductType: yup
          .string()
          .isRequired(t("add_financial_product.financial_product_type_label")),
        interestAccrualFrequency: yup
          .string()
          .isRequired(
            t("add_financial_product.interest_accrual_frequency_label"),
          ),
        interestCompoundingFrequency: yup
          .string()
          .isRequired(
            t("add_financial_product.interest_compounding_frequency_label"),
          ),
        dayCountConvention: yup
          .string()
          .isRequired(t("add_financial_product.day_count_convention_label")),
        settlementType: yup.string().when("financialProductType", {
          is: (value: string) =>
            value !== ProductTypeModel.FixedRateOperatingLease,
          then: yup.string().isRequired(t(settlementTypeLabel)),
        }),
        discountPercentage: yup
          .number(t(discountPercentageLabel))
          .transform(transformField)
          .nullable()
          .when("financialProductType", {
            is: (value: string) =>
              value !== ProductTypeModel.FixedRateOperatingLease,
            then: yup
              .number(t(discountPercentageLabel))
              .nullable()
              .greaterThanAmount(0, t(discountPercentageLabel))
              .notGreaterThanPercentage(100, t(discountPercentageLabel)),
          }),
        allowBoonPayment: yup.boolean(),
        applyBalloonasLastPayment: yup.boolean(),
        allowAdvancedPayment: yup.boolean(),
        allowSecondaryRental: yup.boolean(),
        allowResidualValue: yup.boolean(),
      }),
    );
  };

const AddFinancialProductFormControls = ({
  form,
}: {
  form: UseFormReturn<AddFinancialProductFormFieldValues>;
}) => {
  const { t } = useTranslation("onboardings");
  return (
    <Grid container>
      <Grid item sm={12}>
        <TextField
          label={t(nameLabel)}
          error={Boolean(form.formState.errors.name?.message)}
          helperText={form.formState.errors.name?.message}
          {...form.register("name")}
          required
        />
      </Grid>
      <Grid item sm={12}>
        <ControlledTextField
          name="financialProductType"
          control={form.control}
          label={t("add_financial_product.financial_product_type_label")}
          required
          error={form.formState.errors.financialProductType?.message}
          helperText={form.formState.errors.financialProductType?.message}
          select
          SelectProps={{ displayEmpty: true }}
          onChange={(event) => {
            if (
              event.target.value === ProductTypeModel.FixedRateOperatingLease
            ) {
              form.unregister("discountPercentage");
              form.unregister("settlementType");
            }
            if (event.target.value === ProductTypeModel.FixedRateLoan) {
              form.unregister("allowSecondaryRental");
            }
            if (event.target.value === ProductTypeModel.FixedRateHirePurchase) {
              form.unregister("allowSecondaryRental");
              form.unregister("allowResidualValue");
            }
          }}
        >
          <MenuItem value="">
            <i>{t(commonSelect)}</i>
          </MenuItem>
          {Object.values(ProductTypeModel).map((product) => (
            <MenuItem key={product} value={product}>
              {product}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid item sm={12}>
        <ControlledTextField
          select
          required
          control={form.control}
          error={form.formState.errors.interestAccrualFrequency?.message}
          helperText={form.formState.errors.interestAccrualFrequency?.message}
          SelectProps={{ displayEmpty: true }}
          label={t("add_financial_product.interest_accrual_frequency_label")}
          name="interestAccrualFrequency"
        >
          <MenuItem value="">
            <i>{t(commonSelect)}</i>
          </MenuItem>
          {Object.values(FrequencyOfInstalmentsModel).map((frequency) => (
            <MenuItem key={frequency} value={frequency}>
              {frequency}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid item sm={12}>
        <ControlledTextField
          select
          control={form.control}
          required
          error={form.formState.errors.interestCompoundingFrequency?.message}
          helperText={
            form.formState.errors.interestCompoundingFrequency?.message
          }
          label={t(
            "add_financial_product.interest_compounding_frequency_label",
          )}
          SelectProps={{ displayEmpty: true }}
          name="interestCompoundingFrequency"
        >
          <MenuItem value="">
            <i>{t(commonSelect)}</i>
          </MenuItem>
          {Object.values(FrequencyOfInstalmentsModel).map((frequency) => (
            <MenuItem key={frequency} value={frequency}>
              {frequency}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid item sm={12}>
        <ControlledTextField
          label={t("add_financial_product.day_count_convention_label")}
          control={form.control}
          error={form.formState.errors.dayCountConvention?.message}
          helperText={form.formState.errors.dayCountConvention?.message}
          select
          required
          name="dayCountConvention"
          SelectProps={{ displayEmpty: true }}
        >
          <MenuItem value="">
            <i>{t(commonSelect)}</i>
          </MenuItem>
          {Object.values(DayCountConventionModel).map((convention) => (
            <MenuItem key={convention} value={convention}>
              <TranslateDayCountConvention convention={convention} />
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      {form.watch("financialProductType") !==
        ProductTypeModel.FixedRateOperatingLease && (
        <>
          <Grid item sm={12}>
            <ControlledTextField
              select
              control={form.control}
              required
              label={t(settlementTypeLabel)}
              name="settlementType"
              SelectProps={{ displayEmpty: true }}
              error={form.formState.errors.settlementType?.message}
              helperText={form.formState.errors.settlementType?.message}
            >
              <MenuItem value="">
                <i>{t(commonSelect)}</i>
              </MenuItem>
              {Object.values(SettlementTypeModel).map((settlementType) => (
                <MenuItem key={settlementType} value={settlementType}>
                  {settlementType}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item sm={12}>
            <AmountField
              name="discountPercentage"
              error={Boolean(form.formState.errors.discountPercentage?.message)}
              helperText={form.formState.errors.discountPercentage?.message}
              label={t("add_financial_product.discount_percentage_label")}
              control={form.control}
            />
          </Grid>
        </>
      )}
      <Grid item sm={12} mt={3}>
        <Divider />
      </Grid>
      <Grid item mt={3}>
        <Grid container rowSpacing={0.5}>
          <Grid item sm={12}>
            <FormControlLabel
              label={t("add_financial_product.allow_balloon_payment_label")}
              control={
                <Controller
                  name="allowBalloonPayment"
                  control={form.control}
                  render={({ field: { value, ...field } }) => (
                    <Checkbox
                      {...field}
                      checked={Boolean(value)}
                      size="small"
                      onChange={(e): void => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
            />
          </Grid>
          <Grid item sm={12}>
            <FormControlLabel
              label={t(
                "add_financial_product.balloon_as_last_instalment_label",
              )}
              control={
                <Controller
                  name="applyBalloonAsLastPayment"
                  control={form.control}
                  render={({ field: { value, ...field } }) => (
                    <Checkbox
                      {...field}
                      checked={Boolean(value)}
                      size="small"
                      onChange={(e): void => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
            />
          </Grid>
          <Grid item sm={12}>
            <FormControlLabel
              label={t("add_financial_product.allow_advanced_payment_label")}
              control={
                <Controller
                  name="allowAdvancedPayment"
                  control={form.control}
                  render={({ field: { value, ...field } }) => (
                    <Checkbox
                      {...field}
                      checked={Boolean(value)}
                      size="small"
                      onChange={(e): void => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
            />
          </Grid>
          {form.watch("financialProductType") !==
            ProductTypeModel.FixedRateHirePurchase && (
            <Grid item sm={12}>
              <FormControlLabel
                label={t("add_financial_product.allow_residual_value_label")}
                control={
                  <Controller
                    name="allowResidualValue"
                    control={form.control}
                    render={({ field: { value, ...field } }) => (
                      <Checkbox
                        {...field}
                        checked={Boolean(value)}
                        size="small"
                        onChange={(e): void => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
          )}
          {form.watch("financialProductType") !==
            ProductTypeModel.FixedRateHirePurchase &&
            form.watch("financialProductType") !==
              ProductTypeModel.FixedRateLoan && (
              <Grid item sm={12}>
                <FormControlLabel
                  label={t(
                    "add_financial_product.allow_secondary_rental_label",
                  )}
                  control={
                    <Controller
                      name="allowSecondaryRental"
                      control={form.control}
                      render={({ field: { value, ...field } }) => (
                        <Checkbox
                          {...field}
                          checked={Boolean(value)}
                          size="small"
                          onChange={(e): void =>
                            field.onChange(e.target.checked)
                          }
                        />
                      )}
                    />
                  }
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const AddFinancialProductContainer = () => {
  const { t } = useTranslation("onboardings");
  const navigate = useNavigate();
  const resolver = useAddFinancialProductResolver();
  const error = useResponseError([
    [
      "financial_product_name_exists",
      t("add_financial_product.name_already_exists"),
    ],
  ]);

  const createFinancialProduct = useAddFinancialProduct({
    onSuccess: (response) => {
      navigate(`..${response.raw.headers.get("location")}`);
    },
    onError: (response) => {
      error.setError(response.code);
    },
  });

  const handleOnSubmit = (data: AddFinancialProductFormFieldValues) => {
    return createFinancialProduct.execute({
      ...data,
      discountPercentage: Number(data.discountPercentage?.toFixed(2)),
      allowResidualValue: data.allowResidualValue ?? false,
      allowSecondaryRental: data.allowSecondaryRental ?? false,
    });
  };

  return (
    <Form
      label={t("add_financial_product.form_label")}
      onSubmit={handleOnSubmit}
      resolver={resolver}
      defaultValues={{
        allowAdvancedPayment: false,
        allowBalloonPayment: false,
        applyBalloonAsLastPayment: false,
        allowResidualValue: false,
        allowSecondaryRental: false,
        dayCountConvention: "" as DayCountConventionModel,
        financialProductType: "" as ProductTypeModel,
        interestAccrualFrequency: "" as FrequencyOfInstalmentsModel,
        interestCompoundingFrequency: "" as FrequencyOfInstalmentsModel,
        discountPercentage: null,
        name: "",
        settlementType: "" as SettlementTypeModel,
      }}
    >
      {(form) => (
        <Container maxWidth="sm">
          <Paper>
            <Stack gap={4} padding={2}>
              <AddFinancialProductFormControls form={form} />
              {error.message && (
                <Typography color="error">{error.message}</Typography>
              )}
              <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
                <Button onClick={() => navigate("../")}>
                  {t("common:cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={form.formState.isSubmitting}
                >
                  {t("common:save")}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Container>
      )}
    </Form>
  );
};
export default AddFinancialProductContainer;
