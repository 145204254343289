import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ResponseError } from "generated";
import { CustomerContactApi } from "generated/servicing-v2/apis/CustomerContactApi";
import { CustomerContactModel } from "generated/servicing-v2/models/CustomerContactModel";
import { useSWRConfig } from "swr";
import { keys } from ".";

export const useAddContact = (
  clientId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(CustomerContactApi, "v2");
  const swr = useSWRConfig();

  return useCommand<CustomerContactModel, void, ResponseError>(
    (model) =>
      api.addCustomerContact({
        id: clientId,
        customerContactModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.list(clientId));
        options.onSuccess?.();
      },
    },
  );
};
