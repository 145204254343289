import { Combobox } from "@headlessui/react";
import { Close, SearchOutlined } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Chip,
  Divider,
  IconButton,
  InputBase,
  LinearProgress,
  ListItemText,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useListAgreements } from "api/agreements";
import { useListAssets } from "api/assets/listAssets";
import { useListCustomers } from "api/customers";
import { Loader, NoResults, QueryError } from "common/components";
import {
  AgreementListItemModel,
  AssetListItemModel,
  ClientListItemModel,
} from "generated/core/models";
import { debounce } from "lodash";
import React, {
  ChangeEventHandler,
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type SearchListItem = { id: number; name: string; href: string };

interface CustomTableCellProps {
  width: string;
  children: ReactNode;
}

function CustomTableCell({
  width,
  children,
}: CustomTableCellProps): ReactElement {
  return (
    <TableCell
      sx={{
        width: width,
        border: "0",
        cursor: "pointer",
      }}
    >
      {children}
    </TableCell>
  );
}

interface ShortcutChipProps {
  label: string;
}

function ShortcutChip({ label }: ShortcutChipProps): ReactElement {
  return (
    <Chip
      size="small"
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        color: "white",
        borderRadius: 1,
        pointerEvents: "none",
      })}
      label={label}
    />
  );
}

const highlightColour = "rgba(0, 0, 0, 0.04)";

function name(customerName?: string, companyName?: string): string | undefined {
  return customerName ? customerName : companyName;
}

function displayName(
  customerName?: string,
  companyName?: string,
): string | undefined {
  return companyName && customerName
    ? `${customerName} (${companyName})`
    : name(customerName, companyName);
}

interface AgreementTableProps {
  data: AgreementListItemModel[];
}

function AgreementTable({ data }: AgreementTableProps): ReactElement {
  const { t } = useTranslation("common");

  return (
    <Table size="small" aria-label={t("top_bar.agreement_table")}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ border: "0" }}>
            {t("top_bar.agreements_label")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((entry) => (
          <Combobox.Option
            as={Fragment}
            key={entry.id}
            value={{
              id: entry.id,
              name: entry.number,
              href: `/servicing/agreements/${entry.id}`,
            }}
          >
            {({ selected, active }) => (
              <TableRow
                hover
                sx={{
                  backgroundColor: active ? highlightColour : "white",
                }}
              >
                <CustomTableCell width="900px">
                  <ListItemText
                    primary={entry.number ?? "-"}
                    secondary={
                      displayName(entry.customerName, entry.companyName) ?? "-"
                    }
                  />
                </CustomTableCell>
              </TableRow>
            )}
          </Combobox.Option>
        ))}
      </TableBody>
    </Table>
  );
}

interface CustomerTableProps {
  data: ClientListItemModel[];
}

function CustomerTable({ data }: CustomerTableProps): ReactElement {
  const { t } = useTranslation("common");

  return (
    <Table size="small" aria-label={t("top_bar.customer_table")}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ border: "0" }}>
            {t("top_bar.customers_label")}
          </TableCell>
          <TableCell sx={{ border: "0" }}>
            {t("top_bar.company_registration_label")}
          </TableCell>
          <TableCell sx={{ border: "0" }}>
            {t("top_bar.vat_registration_label")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((entry) => (
          <Combobox.Option
            as={Fragment}
            key={entry.id}
            value={{
              id: entry.id,
              name: displayName(entry.name, entry.companyName),
              href: `/servicing/customers/${entry.id}`,
            }}
          >
            {({ selected, active }) => (
              <TableRow
                hover
                sx={{
                  backgroundColor: active ? highlightColour : "white",
                }}
              >
                <CustomTableCell width="450px">
                  <ListItemText
                    primary={displayName(entry.name, entry.companyName) ?? "-"}
                    secondary={entry.address ?? "-"}
                  />
                </CustomTableCell>
                <CustomTableCell width="225px">
                  {entry.companyRegistrationNumber ?? "-"}
                </CustomTableCell>
                <CustomTableCell width="225px">
                  {entry.vatRegistrationNumber ?? "-"}
                </CustomTableCell>
              </TableRow>
            )}
          </Combobox.Option>
        ))}
      </TableBody>
    </Table>
  );
}

interface AssetsTableProps {
  data: AssetListItemModel[];
}

function AssetsTable({ data }: AssetsTableProps): ReactElement {
  const { t } = useTranslation("common");

  return (
    <Table size="small" aria-label={t("top_bar.assets_table")}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ border: "0" }}>{t("top_bar.asset_label")}</TableCell>
          <TableCell sx={{ border: "0" }}>
            {t("top_bar.registration_number_label")}
          </TableCell>
          <TableCell sx={{ border: "0" }}>
            {t("top_bar.serial_number_label")}
          </TableCell>
          <TableCell sx={{ border: "0" }}>{t("top_bar.vin_label")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((entry) => (
          <Combobox.Option
            as={Fragment}
            key={entry.id}
            value={{
              id: entry.id,
              name: entry.agreementId,
              href: `/servicing/agreements/${entry.agreementId}/assets`,
            }}
          >
            {({ selected, active }) => (
              <TableRow
                hover
                sx={{
                  backgroundColor: active ? highlightColour : "white",
                }}
              >
                <CustomTableCell width="225px">
                  <ListItemText
                    primary={entry.agreementNumber ?? "-"}
                    secondary={
                      displayName(entry.customerName, entry.companyName) ?? "-"
                    }
                  />
                </CustomTableCell>
                <CustomTableCell width="225px">
                  {entry.registrationNumber ?? "-"}
                </CustomTableCell>
                <CustomTableCell width="225px">
                  {entry.serialNumber ?? "-"}
                </CustomTableCell>
                <CustomTableCell width="225px">
                  {entry.vehicleIdentificationNumber ?? "-"}
                </CustomTableCell>
              </TableRow>
            )}
          </Combobox.Option>
        ))}
      </TableBody>
    </Table>
  );
}

export default function SearchContainer(): ReactElement {
  const { t } = useTranslation("common");
  const [isOpen, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const isMac = navigator.userAgent.toUpperCase().indexOf("MAC") >= 0;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = () => {
    setOpen(true);
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setAnchorEl(buttonRef.current);
  }, []);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (!isMac && event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setOpen((currentState) => !currentState);
      }

      if (isMac && event.metaKey && event.key === "k") {
        event.preventDefault();
        setOpen((currentState) => !currentState);
      }
    },
    [isMac],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const customers = useListCustomers({
    pageSize: 3,
    params: { query, enabled: query.trim().length > 2 },
  });

  const agreements = useListAgreements({
    pageSize: 3,
    params: { query, enabled: query.trim().length > 2 },
  });

  const assets = useListAssets({
    pageSize: 3,
    params: { query, enabled: query.trim().length > 2 },
  });

  const onQueryChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    setQuery(event.target.value);
  };

  const onClose = () => {
    setOpen(false);
    setQuery("");
  };

  const handleQueryChange = useMemo(() => debounce(onQueryChange, 300), []);

  useEffect(() => {
    return handleQueryChange.cancel;
  }, [handleQueryChange]);

  return (
    <>
      <Popover
        open={isOpen}
        disableRestoreFocus
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        marginThreshold={5}
      >
        <Combobox
          onChange={(value: SearchListItem) => {
            onClose();
            navigate(value.href);
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            position="relative"
            sx={{ width: "900px", height: "50px" }}
            padding={0}
          >
            <Stack spacing={2} direction="row" alignItems="center" width="100%">
              <Box paddingLeft={2} display="flex" alignItems="center">
                <SearchOutlined color="inherit" sx={{ marginRight: 1 }} />
              </Box>
              <Box display="flex" width="100%">
                <InputBase
                  autoFocus
                  inputComponent={Combobox.Input}
                  inputProps={{
                    displayValue: (item: SearchListItem) => item.name,
                  }}
                  sx={{
                    border: 0,
                    fontSize: 15,
                    width: "100%",
                    outline: "none",
                  }}
                  placeholder={t("top_bar.search_label")}
                  onChange={handleQueryChange}
                />
              </Box>

              <Box justifyContent="flex-end" display="flex" alignItems="center">
                <IconButton
                  aria-label={t("common:close")}
                  onClick={onClose}
                  size="small"
                  sx={{ marginRight: 2 }}
                >
                  <Close />
                </IconButton>
              </Box>
            </Stack>
          </Box>
          <Divider variant="fullWidth" />

          <Loader
            ready={Boolean(
              (agreements.data || agreements.error) &&
                (customers.data || customers.error) &&
                (assets.data || assets.error),
            )}
            fallback={query.trim().length > 2 ? <LinearProgress /> : <></>}
            render={() => {
              if (agreements.error || !agreements.data) {
                return (
                  <Box paddingY={2}>
                    <QueryError onRetry={agreements.refetch} />
                  </Box>
                );
              }

              if (customers.error || !customers.data) {
                return (
                  <Box paddingY={2}>
                    <QueryError onRetry={customers.refetch} />
                  </Box>
                );
              }

              if (assets.error || !assets.data) {
                return (
                  <Box paddingY={2}>
                    <QueryError onRetry={assets.refetch} />
                  </Box>
                );
              }

              if (
                agreements.data.results.length === 0 &&
                customers.data.results.length === 0 &&
                assets.data.results.length === 0
              ) {
                return <NoResults />;
              }

              return (
                <>
                  {customers.data.results.length > 0 && (
                    <>
                      <CustomerTable data={customers.data.results} />
                      <Divider />
                    </>
                  )}

                  {agreements.data.results.length > 0 && (
                    <>
                      <AgreementTable data={agreements.data.results} />
                      <Divider />
                    </>
                  )}

                  {assets.data.results.length > 0 && (
                    <>
                      <AssetsTable data={assets.data.results} />
                    </>
                  )}
                </>
              );
            }}
          />
        </Combobox>
      </Popover>
      <Box width={300} color="white">
        <ButtonBase
          onClick={handleClick}
          ref={buttonRef}
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.dark,
            borderRadius: 1,
            paddingX: theme.spacing(1),
            height: theme.spacing(4.5),
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          })}
        >
          <Box display="flex">
            <SearchOutlined color="inherit" sx={{ marginRight: 1 }} />
            <Typography marginTop={0.25} marginRight={1}>
              {t("top_bar.search_label")}
            </Typography>
          </Box>
          {isMac && <ShortcutChip label="⌘+K" />}
          {!isMac && <ShortcutChip label="CTRL+K" />}
        </ButtonBase>
      </Box>
    </>
  );
}
