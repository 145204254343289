/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomInstalmentModel
 */
export interface CustomInstalmentModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof CustomInstalmentModel
     */
    startingOn: Date;
    /**
     * The number of instalments with the defined amounts
     * @type {number}
     * @memberof CustomInstalmentModel
     */
    forNumberOfInstalments: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CustomInstalmentModel
     */
    instalmentAmount: string;
}

/**
 * Check if a given object implements the CustomInstalmentModel interface.
 */
export function instanceOfCustomInstalmentModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startingOn" in value;
    isInstance = isInstance && "forNumberOfInstalments" in value;
    isInstance = isInstance && "instalmentAmount" in value;

    return isInstance;
}

export function CustomInstalmentModelFromJSON(json: any): CustomInstalmentModel {
    return CustomInstalmentModelFromJSONTyped(json, false);
}

export function CustomInstalmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomInstalmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingOn': (new Date(json['startingOn'])),
        'forNumberOfInstalments': json['forNumberOfInstalments'],
        'instalmentAmount': json['instalmentAmount'],
    };
}

export function CustomInstalmentModelToJSON(value?: CustomInstalmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startingOn': (value.startingOn.toISOString().substring(0,10)),
        'forNumberOfInstalments': value.forNumberOfInstalments,
        'instalmentAmount': value.instalmentAmount,
    };
}

