/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A memo
 * @export
 * @interface MemoModel
 */
export interface MemoModel {
    /**
     * A date time format
     * @type {string}
     * @memberof MemoModel
     */
    created: string;
    /**
     * The name of the operator who created this memo
     * @type {string}
     * @memberof MemoModel
     */
    operatorName: string;
    /**
     * The content of the memo
     * @type {string}
     * @memberof MemoModel
     */
    note: string;
    /**
     * The agreement number the memo is for
     * @type {string}
     * @memberof MemoModel
     */
    agreementNumber?: string;
    /**
     * A read only Id
     * @type {number}
     * @memberof MemoModel
     */
    readonly agreementId?: number;
}

/**
 * Check if a given object implements the MemoModel interface.
 */
export function instanceOfMemoModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "operatorName" in value;
    isInstance = isInstance && "note" in value;

    return isInstance;
}

export function MemoModelFromJSON(json: any): MemoModel {
    return MemoModelFromJSONTyped(json, false);
}

export function MemoModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemoModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'operatorName': json['operatorName'],
        'note': json['note'],
        'agreementNumber': !exists(json, 'agreementNumber') ? undefined : json['agreementNumber'],
        'agreementId': !exists(json, 'agreementId') ? undefined : json['agreementId'],
    };
}

export function MemoModelToJSON(value?: MemoModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'operatorName': value.operatorName,
        'note': value.note,
        'agreementNumber': value.agreementNumber,
    };
}

