/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Contacts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The contact point type
 * @export
 */
export const ContactPointTypeModel = {
    HomeTelephone: 'Home Telephone',
    WorkTelephone: 'Work Telephone',
    Email: 'Email',
    Fax: 'Fax'
} as const;
export type ContactPointTypeModel = typeof ContactPointTypeModel[keyof typeof ContactPointTypeModel];


export function ContactPointTypeModelFromJSON(json: any): ContactPointTypeModel {
    return ContactPointTypeModelFromJSONTyped(json, false);
}

export function ContactPointTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPointTypeModel {
    return json as ContactPointTypeModel;
}

export function ContactPointTypeModelToJSON(value?: ContactPointTypeModel | null): any {
    return value as any;
}

