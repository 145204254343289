import { Box, Grid, TextField } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FieldErrors, Path, UseFormRegister } from "support/react-hook-form";

export interface AddressFieldsetModel {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  postcode?: string;
}

interface AddressFieldsetProps<TFieldValues extends AddressFieldsetModel> {
  labels?: {
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    addressLine4?: string;
    postcode?: string;
  };
  errors: FieldErrors<TFieldValues>;
  register: UseFormRegister<TFieldValues>;
}

export default function AddressFieldset<
  TFieldValues extends AddressFieldsetModel,
>({
  labels: overrideLabels = {},
  errors,
  register,
}: AddressFieldsetProps<TFieldValues>): ReactElement {
  const { t } = useTranslation();
  const labels = {
    addressLine1: t("common:address.address_line_1"),
    addressLine2: t("common:address.address_line_2"),
    addressLine3: t("common:address.address_line_3"),
    addressLine4: t("common:address.address_line_4"),
    postcode: t("common:address.postcode"),
    ...overrideLabels,
  };

  return (
    <Box component="fieldset" sx={{ border: 0, margin: 0, padding: 0 }}>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine1" as Path<TFieldValues>)}
            error={Boolean(errors?.addressLine1)}
            helperText={errors?.addressLine1?.message as string | undefined}
            label={labels.addressLine1}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine2" as Path<TFieldValues>)}
            error={Boolean(errors?.addressLine2)}
            helperText={errors?.addressLine2?.message as string | undefined}
            label={labels.addressLine2}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine3" as Path<TFieldValues>)}
            error={Boolean(errors?.addressLine3)}
            helperText={errors?.addressLine3?.message as string | undefined}
            label={labels.addressLine3}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine4" as Path<TFieldValues>)}
            error={Boolean(errors?.addressLine4)}
            helperText={errors?.addressLine4?.message as string | undefined}
            label={labels.addressLine4}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("postcode" as Path<TFieldValues>)}
            error={Boolean(errors?.postcode)}
            helperText={errors?.postcode?.message as string | undefined}
            label={labels.postcode}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
