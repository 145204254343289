/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreditDetailsModel
 */
export interface CreditDetailsModel {
    /**
     * The type of credit
     * @type {string}
     * @memberof CreditDetailsModel
     */
    type: string;
    /**
     * The term in months
     * @type {number}
     * @memberof CreditDetailsModel
     */
    term: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreditDetailsModel
     */
    amount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreditDetailsModel
     */
    total: string;
    /**
     * The penalty in months
     * @type {number}
     * @memberof CreditDetailsModel
     */
    penalty?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreditDetailsModel
     */
    sinkingFund?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreditDetailsModel
     */
    costPrice?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreditDetailsModel
     */
    costPriceVat?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreditDetailsModel
     */
    vat?: string;
}

/**
 * Check if a given object implements the CreditDetailsModel interface.
 */
export function instanceOfCreditDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "term" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function CreditDetailsModelFromJSON(json: any): CreditDetailsModel {
    return CreditDetailsModelFromJSONTyped(json, false);
}

export function CreditDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'term': json['term'],
        'amount': json['amount'],
        'total': json['total'],
        'penalty': !exists(json, 'penalty') ? undefined : json['penalty'],
        'sinkingFund': !exists(json, 'sinkingFund') ? undefined : json['sinkingFund'],
        'costPrice': !exists(json, 'costPrice') ? undefined : json['costPrice'],
        'costPriceVat': !exists(json, 'costPriceVat') ? undefined : json['costPriceVat'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
    };
}

export function CreditDetailsModelToJSON(value?: CreditDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'term': value.term,
        'amount': value.amount,
        'total': value.total,
        'penalty': value.penalty,
        'sinkingFund': value.sinkingFund,
        'costPrice': value.costPrice,
        'costPriceVat': value.costPriceVat,
        'vat': value.vat,
    };
}

