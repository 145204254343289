import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import { useLocale } from "common/hooks";
import { ScheduleModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface B2BScheduleListProps {
  schedule: ScheduleModel;
  loading: boolean;
}

export default function B2BScheduleList({
  schedule,
  loading,
}: B2BScheduleListProps): ReactElement {
  const { t } = useTranslation("agreements");
  const locale = useLocale();
  const { formatAmount } = useFormat();

  const tableHeadings: [string, TableCellProps["align"]][] = [
    [t("b2b.number_label"), "right"],
    [t("b2b.due_date_label"), "left"],
    [t("b2b.instalment_amount_label"), "right"],
    [t("b2b.interest_paid_label"), "right"],
    [t("b2b.capital_paid_label"), "right"],
    [t("b2b.expenses_other_label"), "right"],
    [t("b2b.expenses_loss_pool_label"), "right"],
    [t("b2b.lender_fees_label"), "right"],
    [t("b2b.capital_outstanding_label"), "right"],
    [t("instalments.commission_charge"), "right"],
    [t("instalments.commission_balance"), "right"],
  ];

  const renderTableHeading = ([heading, alignment]: [
    string,
    TableCellProps["align"],
  ]): ReactElement => (
    <TableCell key={heading} align={alignment}>
      {heading}
    </TableCell>
  );

  return (
    <Table size="small" stickyHeader aria-label={t("b2b.table_label")}>
      <TableHead>
        <TableRow>{tableHeadings.map(renderTableHeading)}</TableRow>
        {loading && (
          <TableRow>
            <TableCell colSpan={tableHeadings.length} padding="none">
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {schedule.b2bInstalments.map((b2bInstalment) => (
          <TableRow key={b2bInstalment.instalmentNumber} hover>
            <TableCell align="right">
              {b2bInstalment.instalmentNumber}
            </TableCell>
            <TableCell>
              {b2bInstalment.instalmentDate &&
                locale.formatDate(b2bInstalment.instalmentDate)}
            </TableCell>
            <TableCell align="right">
              {formatAmount(b2bInstalment.instalmentAmount)}
            </TableCell>
            <TableCell align="right">
              {formatAmount(b2bInstalment.interestPaid)}
            </TableCell>
            <TableCell align="right">
              {formatAmount(b2bInstalment.capitalPaid)}
            </TableCell>
            <TableCell align="right">
              {formatAmount(b2bInstalment.expensesOther)}
            </TableCell>
            <TableCell align="right">
              {formatAmount(b2bInstalment.expensesLossPool)}
            </TableCell>
            <TableCell align="right">
              {formatAmount(b2bInstalment.lenderFeesPaid)}
            </TableCell>
            <TableCell align="right">
              {formatAmount(b2bInstalment.capitalOutstanding)}
            </TableCell>
            <TableCell align="right">
              {formatAmount(b2bInstalment.commissionCharge)}
            </TableCell>
            <TableCell align="right">
              {formatAmount(b2bInstalment.commissionBalance)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter
        sx={(theme): SystemStyleObject => ({
          "& > tr": {
            "& > td, & > th": {
              height: "35px",
              backgroundColor: theme.palette.background.paper,
              position: "sticky",
              borderTop: `1px solid ${theme.palette.divider}`,
              bottom: 0,
            },
          },
        })}
      >
        <TableRow>
          <TableCell colSpan={2} align="right" component="th" scope="row">
            {t("b2b.totals_label")}
          </TableCell>
          <TableCell align="right">
            {formatAmount(schedule.b2bTotalInstalmentAmount)}
          </TableCell>
          <TableCell align="right">
            {formatAmount(schedule.b2bTotalInterestPaid)}
          </TableCell>
          <TableCell align="right">
            {formatAmount(schedule.b2bTotalCapitalPaid)}
          </TableCell>
          <TableCell align="right">
            {formatAmount(schedule.b2bTotalExpensesOther)}
          </TableCell>
          <TableCell align="right">
            {formatAmount(schedule.b2bTotalExpensesLossPool)}
          </TableCell>
          <TableCell align="right">
            {formatAmount(schedule.b2bTotalLenderFeesPaid)}
          </TableCell>
          <TableCell align="right"></TableCell>
          <TableCell align="right">
            {formatAmount(schedule.b2bTotalCommissionCharge)}
          </TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}
