import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { OnboardingApi } from "generated/onboarding/apis";
import { OnboardingSummaryModel } from "generated/onboarding/models";
import { ErrorLike } from "support/error-handler";
import { detail } from "./keys";

type UseGetOnboardingSummary = (
  onboardingId: number,
) => UseQueryReturn<OnboardingSummaryModel, ErrorLike>;

export const useGetOnboardingSummary: UseGetOnboardingSummary = (
  onboardingId: number,
) => {
  const api = useApi(OnboardingApi);

  return useQuery(detail(onboardingId), () =>
    api.getOnboardingSummary({ onboardingId: onboardingId }),
  );
};
