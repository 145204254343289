import { useQuery, UseQueryReturn } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/agreements/transactions/keys";
import { useApi } from "common/providers/ApiProvider";
import { AgreementQueryApi } from "generated/core/apis/AgreementQueryApi";
import { AgreementTransactionModel } from "generated/core/models/AgreementTransactionModel";

type UseListAgreementTransactions = (
  agreementId: number,
) => UseQueryReturn<AgreementTransactionModel[]>;

export const useListAgreementTransactions: UseListAgreementTransactions = (
  agreementId,
) => {
  const api = useApi(AgreementQueryApi);

  return useQuery(keys.list(agreementId), () =>
    api.listAgreementTransactions({ agreementId }),
  );
};
