import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { ReactElement, forwardRef, useState } from "react";

type PasswordTextFieldProps = {
  error?: string;
  visibleLabel: string;
  obfuscatedLabel: string;
} & Omit<TextFieldProps, "error">;

const PasswordTextField = forwardRef<HTMLInputElement, PasswordTextFieldProps>(
  function PasswordTextFieldForwardedRef(
    { visibleLabel, obfuscatedLabel, error, ...props },
    ref,
  ): ReactElement {
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordVisibilityClick = (): void => {
      setShowPassword((password) => !password);
    };

    return (
      <TextField
        {...props}
        ref={ref}
        error={Boolean(error)}
        helperText={error}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handlePasswordVisibilityClick} size="large">
                {showPassword ? (
                  <Visibility aria-label={visibleLabel} />
                ) : (
                  <VisibilityOff aria-label={obfuscatedLabel} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  },
);

export default PasswordTextField;
