import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { useGetVatCodeDetails } from "api/vat-codes/getVatCodeDetails";
import VatCodeDetailsContainer from "apps/servicing/modules/settings/containers/VatCodeDetailsContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

interface VatCodeDetailsViewProps {
  vatCode: string;
}

function VatCodeDetailsView({ vatCode }: VatCodeDetailsViewProps) {
  const { t } = useTranslation("servicing");
  const vatCodeDetails = useGetVatCodeDetails(vatCode);

  const appConfig = useAppConfiguration();

  return (
    <main>
      <ViewLayout
        title={vatCodeDetails.data?.name}
        breadcrumbs={[
          {
            href: `${appConfig.appRoutes.servicing}/settings/vat-codes`,
            label: t("vat_rates.breadcrumb_link_label"),
          },
        ]}
      >
        <ContainedLayout>
          <VatCodeDetailsContainer vatCode={vatCode} />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:vat-codes:manage"],
})(VatCodeDetailsView);
