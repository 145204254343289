/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Customers within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanySizeModel } from './CompanySizeModel';
import {
    CompanySizeModelFromJSON,
    CompanySizeModelFromJSONTyped,
    CompanySizeModelToJSON,
} from './CompanySizeModel';
import type { CustomerTypeModel } from './CustomerTypeModel';
import {
    CustomerTypeModelFromJSON,
    CustomerTypeModelFromJSONTyped,
    CustomerTypeModelToJSON,
} from './CustomerTypeModel';

/**
 * 
 * @export
 * @interface CustomerOverviewModel
 */
export interface CustomerOverviewModel {
    /**
     * A read only Id
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    readonly systemId?: string;
    /**
     * The individuality of the customer
     * @type {boolean}
     * @memberof CustomerOverviewModel
     */
    isIndividual?: boolean;
    /**
     * The name of the company
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    companyName?: string;
    /**
     * 
     * @type {CustomerTypeModel}
     * @memberof CustomerOverviewModel
     */
    customerType?: CustomerTypeModel;
    /**
     * The company registration number
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    companyRegistrationNumber?: string;
    /**
     * The Tax registration number
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    taxRegistrationNumber?: string;
    /**
     * 
     * @type {CompanySizeModel}
     * @memberof CustomerOverviewModel
     */
    companySize?: CompanySizeModel;
    /**
     * The sector of economic activity
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    sectorOfEconomicActivityCode?: string;
    /**
     * The company sector
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    companySectorCode?: string;
    /**
     * Title Of the Customer
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    title?: string;
    /**
     * First name of the Customer
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    firstName?: string;
    /**
     * Second name of the Customer
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    secondName?: string;
    /**
     * Last name of the Customer
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    lastName?: string;
    /**
     * Trading name of the Customer
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    tradingName?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof CustomerOverviewModel
     */
    dateOfBirth?: Date;
    /**
     * The national identification number
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    nationalIdentificationNumber?: string;
    /**
     * The first line of the address
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    addressLine1: string;
    /**
     * The second line of the address
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    addressLine2: string;
    /**
     * The third line of the address
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    addressLine3?: string;
    /**
     * The fourth line of the address
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    addressLine4?: string;
    /**
     * The postcode of the address
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    postcode: string;
    /**
     * Indicator for system generated correspondence
     * @type {boolean}
     * @memberof CustomerOverviewModel
     */
    isReceiveSystemGeneratedCorrespondence?: boolean;
    /**
     * The customer identification number
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    customerIdentificationNumber?: string;
}

/**
 * Check if a given object implements the CustomerOverviewModel interface.
 */
export function instanceOfCustomerOverviewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "addressLine1" in value;
    isInstance = isInstance && "addressLine2" in value;
    isInstance = isInstance && "postcode" in value;

    return isInstance;
}

export function CustomerOverviewModelFromJSON(json: any): CustomerOverviewModel {
    return CustomerOverviewModelFromJSONTyped(json, false);
}

export function CustomerOverviewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOverviewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemId': !exists(json, 'systemId') ? undefined : json['systemId'],
        'isIndividual': !exists(json, 'isIndividual') ? undefined : json['isIndividual'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'customerType': !exists(json, 'customerType') ? undefined : CustomerTypeModelFromJSON(json['customerType']),
        'companyRegistrationNumber': !exists(json, 'companyRegistrationNumber') ? undefined : json['companyRegistrationNumber'],
        'taxRegistrationNumber': !exists(json, 'taxRegistrationNumber') ? undefined : json['taxRegistrationNumber'],
        'companySize': !exists(json, 'companySize') ? undefined : CompanySizeModelFromJSON(json['companySize']),
        'sectorOfEconomicActivityCode': !exists(json, 'sectorOfEconomicActivityCode') ? undefined : json['sectorOfEconomicActivityCode'],
        'companySectorCode': !exists(json, 'companySectorCode') ? undefined : json['companySectorCode'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'secondName': !exists(json, 'secondName') ? undefined : json['secondName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'tradingName': !exists(json, 'tradingName') ? undefined : json['tradingName'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'nationalIdentificationNumber': !exists(json, 'nationalIdentificationNumber') ? undefined : json['nationalIdentificationNumber'],
        'addressLine1': json['addressLine1'],
        'addressLine2': json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': json['postcode'],
        'isReceiveSystemGeneratedCorrespondence': !exists(json, 'isReceiveSystemGeneratedCorrespondence') ? undefined : json['isReceiveSystemGeneratedCorrespondence'],
        'customerIdentificationNumber': !exists(json, 'customerIdentificationNumber') ? undefined : json['customerIdentificationNumber'],
    };
}

export function CustomerOverviewModelToJSON(value?: CustomerOverviewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isIndividual': value.isIndividual,
        'companyName': value.companyName,
        'customerType': CustomerTypeModelToJSON(value.customerType),
        'companyRegistrationNumber': value.companyRegistrationNumber,
        'taxRegistrationNumber': value.taxRegistrationNumber,
        'companySize': CompanySizeModelToJSON(value.companySize),
        'sectorOfEconomicActivityCode': value.sectorOfEconomicActivityCode,
        'companySectorCode': value.companySectorCode,
        'title': value.title,
        'firstName': value.firstName,
        'secondName': value.secondName,
        'lastName': value.lastName,
        'tradingName': value.tradingName,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substring(0,10)),
        'nationalIdentificationNumber': value.nationalIdentificationNumber,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
        'isReceiveSystemGeneratedCorrespondence': value.isReceiveSystemGeneratedCorrespondence,
        'customerIdentificationNumber': value.customerIdentificationNumber,
    };
}

