import { formatISO } from "date-fns";
import { useTranslation } from "react-i18next";

export default function useLocale(): {
  formatDate: (dateObj: Date) => string;
  formatDateTime: (dateObj: Date) => string;
  formatISODate: (dateObj: Date) => string;
  formatNumberOrdinal: (number: number) => string;
  formatAmount: (number: number) => string;
} {
  const { i18n } = useTranslation();

  const formatDate = (date: Date): string =>
    new Intl.DateTimeFormat(i18n.language).format(date);

  const formatDateTime = (date: Date): string =>
    new Intl.DateTimeFormat(i18n.language, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    })
      .format(date)
      .replace(",", "");

  const formatISODate = (date: Date): string => {
    return formatISO(date, { representation: "date" });
  };

  const formatNumberOrdinal = (number: number): string => {
    const format = new Intl.PluralRules(i18n.language, {
      type: "ordinal",
    });

    const messages: {
      [local: string]: {
        [ordinal: string]: string;
      };
    } = {
      "en-GB": {
        one: "st",
        two: "nd",
        few: "rd",
        other: "th",
      },
      default: {
        one: "st",
        two: "nd",
        few: "rd",
        other: "th",
      },
    };

    const message = messages[i18n.language]
      ? messages[i18n.language]
      : messages["default"];

    return `${number}${message[format.select(number)]}`;
  };

  function formatAmount(number: number): string {
    return number === 0 || isNaN(number)
      ? "-"
      : Intl.NumberFormat(i18n.language, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(number);
  }

  return {
    formatDate,
    formatDateTime,
    formatISODate,
    formatNumberOrdinal,
    formatAmount,
  };
}
