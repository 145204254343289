import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { keys } from "api/agreements";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import { UpdateStatusModel } from "generated/core/models/UpdateStatusModel";
import { useSWRConfig } from "swr";

export const useUpdateStatus = (
  agreementId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementCommandApi);
  const swr = useSWRConfig();

  return useCommand<UpdateStatusModel, void, ResponseError>(
    (model) =>
      api.updateStatus({
        agreementId,
        updateStatusModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.detail(agreementId));
        options.onSuccess?.();
      },
    },
  );
};
