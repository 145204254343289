import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { DescriptionList, EditButton } from "@ldms/mui-sdk/components";
import { MoreVert } from "@mui/icons-material";
import {
  IconButton,
  Menu,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useGetFinancialProductDetails } from "api/onboarding/financial-products/getFinancialProductDetails";
import { TranslateDayCountConvention } from "apps/onboarding/components";
import AssociateFeesContainer from "apps/onboarding/containers/AssociateFeesContainer";
import DisassociateFeeContainer from "apps/onboarding/containers/DisassociateFeeContainer";
import { UpdateFinancialProductContainer } from "apps/onboarding/containers/UpdateFinancialProductContainer";
import { FeeTypeEnum } from "apps/onboarding/types";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
  YesNoValue,
} from "common/components";
import DevToggle from "common/components/DevToggle";
import Surface from "common/components/Surface";
import { FeeTypeModel, ProductTypeModel } from "generated/onboarding/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface FinancialProductDetailsContainerProps {
  financialProductId: number;
}

type ModelKeys = keyof typeof FeeTypeModel;

interface DisassociateFeeMenuProps {
  financialProductId: number;
  feeId: number;
  currentFees: number[];
}

function DisassociateFeeMenu({
  financialProductId,
  feeId,
  currentFees,
}: DisassociateFeeMenuProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuElement);

  const handleMenuClose = (): void => {
    setMenuElement(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuElement(event.currentTarget);
  };

  return (
    <>
      <Menu open={menuOpen} anchorEl={menuElement} onClose={handleMenuClose}>
        <DisassociateFeeContainer
          financialProductId={financialProductId}
          feeId={feeId}
          currentFees={currentFees}
          closeMenu={handleMenuClose}
        />
      </Menu>

      <IconButton
        aria-label={t("financial_products.details.fees.menu_button")}
        color="primary"
        onClick={handleMenuClick}
        size="small"
      >
        <MoreVert fontSize="inherit" />
      </IconButton>
    </>
  );
}

export default function FinancialProductDetailsContainer({
  financialProductId,
}: FinancialProductDetailsContainerProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const [open, setOpen] = useState(false);
  const financialProduct = useGetFinancialProductDetails(financialProductId);
  const currentFees = financialProduct.data?.fees?.map((fee) => fee.id) ?? [];

  return (
    <>
      <Loader
        ready={Boolean(financialProduct.data ?? financialProduct.error)}
        render={(): ReactElement => {
          if (financialProduct.error || !financialProduct.data) {
            return <QueryError onRetry={financialProduct.refetch} />;
          }

          return (
            <Stack gap={3}>
              <Surface
                title={t("financial_products.details.details_heading")}
                action={
                  financialProduct.data.type !==
                    ProductTypeModel.FixedRateOperatingLease && (
                    <DevToggle feature="dev">
                      <AccessControl
                        allowedPermissions={[
                          "onboarding:financial-products:manage",
                        ]}
                      >
                        <EditButton onClick={() => setOpen(true)} />
                      </AccessControl>
                    </DevToggle>
                  )
                }
              >
                <DescriptionList
                  label={t("financial_products.details.details_heading")}
                >
                  <DescriptionList.Item
                    label={t("financial_products.details.name_label")}
                  >
                    {financialProduct.data.name}
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t("financial_products.details.type_label")}
                  >
                    {financialProduct.data.type}
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t(
                      "financial_products.details.allow_balloon_payment_label",
                    )}
                  >
                    <YesNoValue
                      value={financialProduct.data.allowBalloonPayment}
                    />
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t(
                      "financial_products.details.balloon_as_last_instalment_label",
                    )}
                  >
                    <YesNoValue
                      value={financialProduct.data.applyBalloonAsLastPayment}
                    />
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t(
                      "financial_products.details.allow_advanced_payment",
                    )}
                  >
                    <YesNoValue
                      value={financialProduct.data.allowAdvancedPayment}
                    />
                  </DescriptionList.Item>
                  <>
                    {financialProduct.data.type !==
                      ProductTypeModel.FixedRateHirePurchase && (
                      <DescriptionList.Item
                        label={t(
                          "financial_products.details.allow_residual_value_label",
                        )}
                      >
                        <YesNoValue
                          value={financialProduct.data.allowResidualValue}
                        />
                      </DescriptionList.Item>
                    )}
                  </>
                  <>
                    {financialProduct.data.type !==
                      ProductTypeModel.FixedRateHirePurchase &&
                      financialProduct.data.type !==
                        ProductTypeModel.FixedRateLoan && (
                        <DescriptionList.Item
                          label={t(
                            "financial_products.details.allow_secondary_rental_label",
                          )}
                        >
                          <YesNoValue
                            value={financialProduct.data.allowSecondaryRental}
                          />
                        </DescriptionList.Item>
                      )}
                  </>

                  <DescriptionList.Item
                    label={t(
                      "financial_products.details.interest_accrual_frequency_label",
                    )}
                  >
                    {financialProduct.data.interestAccrualFrequency}
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t(
                      "financial_products.details.interest_compounding_frequency_label",
                    )}
                  >
                    {financialProduct.data.interestCompoundingFrequency}
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t(
                      "financial_products.details.day_count_convention_label",
                    )}
                  >
                    <TranslateDayCountConvention
                      convention={financialProduct.data.dayCountConvention}
                    />
                  </DescriptionList.Item>
                  <>
                    {financialProduct.data.type !==
                      ProductTypeModel.FixedRateOperatingLease && (
                      <>
                        <DescriptionList.Item
                          label={t(
                            "financial_products.details.settlement_type_label",
                          )}
                        >
                          {financialProduct.data.settlementType}
                        </DescriptionList.Item>
                        <DescriptionList.Item
                          label={t(
                            "financial_products.details.discount_percentage_label",
                          )}
                        >
                          {financialProduct.data.discountPercentage}
                        </DescriptionList.Item>
                      </>
                    )}
                  </>
                </DescriptionList>
              </Surface>

              <Surface
                disableGutters
                title={t("financial_products.details.fees.fees_heading")}
                action={
                  <AssociateFeesContainer
                    financialProductId={financialProductId}
                    currentFees={currentFees}
                  />
                }
              >
                <Table
                  aria-label={t(
                    "financial_products.details.fees.table_name_label",
                  )}
                  size="small"
                >
                  <LoadableTableHead
                    loading={financialProduct.isValidating}
                    headings={[
                      t("financial_products.details.fees.name_label"),
                      t("financial_products.details.fees.type_label"),
                      t("financial_products.details.fees.taxable_label"),
                      t(
                        "financial_products.details.fees.deducted_from_advance_label",
                      ),
                      t("financial_products.details.fees.enabled_label"),
                      t("financial_products.details.fees.collect_by_dd_label"),
                      t(
                        "financial_products.details.fees.include_on_invoices_label",
                      ),
                      "",
                    ]}
                  />
                  <TableBody>
                    {financialProduct.data.fees?.map((associatedFee, index) => (
                      <TableRow key={index}>
                        <TableCell>{associatedFee.name}</TableCell>
                        <TableCell>
                          {t(
                            FeeTypeEnum[
                              Object.keys(FeeTypeModel).find(
                                (key) =>
                                  FeeTypeModel[key as ModelKeys] ===
                                  associatedFee.type,
                              ) as ModelKeys
                            ],
                          )}
                        </TableCell>
                        <TableCell>
                          <YesNoValue value={associatedFee.taxable} />
                        </TableCell>
                        <TableCell>
                          <YesNoValue
                            value={associatedFee.deductedFromAdvance}
                          />
                        </TableCell>
                        <TableCell>
                          <YesNoValue value={associatedFee.enabled} />
                        </TableCell>
                        <TableCell>
                          <YesNoValue
                            value={associatedFee.collectByDirectDebit}
                          />
                        </TableCell>
                        <TableCell>
                          <YesNoValue value={associatedFee.includeOnInvoices} />
                        </TableCell>
                        <TableCell sx={{ paddingY: 0.25 }} align="right">
                          <DisassociateFeeMenu
                            financialProductId={financialProductId}
                            feeId={associatedFee.id}
                            currentFees={currentFees}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {financialProduct.data.fees?.length === 0 && <NoResults />}
              </Surface>
            </Stack>
          );
        }}
      />

      <UpdateFinancialProductContainer
        open={open}
        onClose={() => setOpen(false)}
        id={financialProductId}
      />
    </>
  );
}
