import SecondaryRentalDetailsContainer from "apps/servicing/modules/agreements/containers/SecondaryRentalDetailsContainer/SecondaryRentalDetailsContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";

export default function SecondaryRentalsView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();

  return (
    <ViewLayout title={t("secondary_rental.heading")}>
      <ContainedLayout>
        <SecondaryRentalDetailsContainer secondaryRentalId={agreement.id} />
      </ContainedLayout>
    </ViewLayout>
  );
}
