import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import { AddAgreementFinancialPostingModel } from "generated/core/models";
import { keys } from ".";

export const useAddAgreementFinancialPosting = (
  agreementId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand<AddAgreementFinancialPostingModel, void, ResponseError>(
    (model) =>
      api.addAgreementFinancialPosting({
        agreementId,
        addAgreementFinancialPostingModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.list(agreementId));
        options.onSuccess?.();
      },
    },
  );
};
