import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { TerminationQueryApi } from "generated/core/apis";
import { TerminationRecoveryDetailsModel } from "generated/core/models";
import { ErrorLike } from "support/error-handler";
import { keys } from ".";

type UseGetTerminationRecoveryDetails = (
  terminationId: number,
) => UseQueryReturn<TerminationRecoveryDetailsModel, ErrorLike>;

export const useGetTerminationRecoveryDetails: UseGetTerminationRecoveryDetails =
  (terminationId: number) => {
    const api = useApi(TerminationQueryApi);

    return useQuery(keys.detail(terminationId), () =>
      api.getTerminationRecoveryDetails({ terminationId }),
    );
  };
