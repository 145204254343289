/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing third parties within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Update Third Party
 * @export
 * @interface UpdateThirdPartyModel
 */
export interface UpdateThirdPartyModel {
    /**
     * Indicates whether the third party is active or not
     * @type {boolean}
     * @memberof UpdateThirdPartyModel
     */
    active: boolean;
    /**
     * The third party name
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    name: string;
    /**
     * The third party name
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    contact?: string;
    /**
     * The first line of the address
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    addressLine1?: string;
    /**
     * The second line of the address
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    addressLine2?: string;
    /**
     * The third line of the address
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    addressLine3?: string;
    /**
     * The fourth line of the address
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    addressLine4?: string;
    /**
     * The postcode of the address
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    postcode?: string;
    /**
     * The telephone for the third party
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    telephone?: string;
    /**
     * The fax number for the third party
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    faxNumber?: string;
    /**
     * The email address for the third party
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    emailAddress?: string;
    /**
     * The VAT registration number for a Manufacturer Third Party
     * @type {string}
     * @memberof UpdateThirdPartyModel
     */
    vatRegistrationNumber?: string;
}

/**
 * Check if a given object implements the UpdateThirdPartyModel interface.
 */
export function instanceOfUpdateThirdPartyModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function UpdateThirdPartyModelFromJSON(json: any): UpdateThirdPartyModel {
    return UpdateThirdPartyModelFromJSONTyped(json, false);
}

export function UpdateThirdPartyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateThirdPartyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': json['active'],
        'name': json['name'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'faxNumber': !exists(json, 'faxNumber') ? undefined : json['faxNumber'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'vatRegistrationNumber': !exists(json, 'vatRegistrationNumber') ? undefined : json['vatRegistrationNumber'],
    };
}

export function UpdateThirdPartyModelToJSON(value?: UpdateThirdPartyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'name': value.name,
        'contact': value.contact,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
        'telephone': value.telephone,
        'faxNumber': value.faxNumber,
        'emailAddress': value.emailAddress,
        'vatRegistrationNumber': value.vatRegistrationNumber,
    };
}

