/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Direct Debits within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DirectDebitSubmissionDetailsModel,
  DirectDebitSubmissionListItemModel,
  DirectDebitSubmissionPaymentTypeModel,
} from '../models/index';
import {
    DirectDebitSubmissionDetailsModelFromJSON,
    DirectDebitSubmissionDetailsModelToJSON,
    DirectDebitSubmissionListItemModelFromJSON,
    DirectDebitSubmissionListItemModelToJSON,
    DirectDebitSubmissionPaymentTypeModelFromJSON,
    DirectDebitSubmissionPaymentTypeModelToJSON,
} from '../models/index';

export interface DownloadSubmissionFileRequest {
    submissionId: number;
}

export interface ListDirectDebitSubmissionPaymentsRequest {
    submissionId: number;
    type?: string;
}

/**
 * 
 */
export class DirectDebitQueryApi extends runtime.BaseAPI {

    /**
     * Download Submission File
     */
    async downloadSubmissionFileRaw(requestParameters: DownloadSubmissionFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling downloadSubmissionFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["direct-debit-submissions:manage"]);
        }

        const response = await this.request({
            path: `/direct-debit-submissions/{submissionId}/file`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download Submission File
     */
    async downloadSubmissionFile(requestParameters: DownloadSubmissionFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadSubmissionFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Direct Debit Submission Payments
     */
    async listDirectDebitSubmissionPaymentsRaw(requestParameters: ListDirectDebitSubmissionPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DirectDebitSubmissionDetailsModel>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling listDirectDebitSubmissionPayments.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["direct-debit-submissions:view"]);
        }

        const response = await this.request({
            path: `/direct-debit-submissions/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DirectDebitSubmissionDetailsModelFromJSON(jsonValue));
    }

    /**
     * View Direct Debit Submission Payments
     */
    async listDirectDebitSubmissionPayments(requestParameters: ListDirectDebitSubmissionPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DirectDebitSubmissionDetailsModel> {
        const response = await this.listDirectDebitSubmissionPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Direct Debit Submissions
     */
    async listDirectDebitSubmissionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DirectDebitSubmissionListItemModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["direct-debit-submissions:view"]);
        }

        const response = await this.request({
            path: `/direct-debit-submissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DirectDebitSubmissionListItemModelFromJSON));
    }

    /**
     * View Direct Debit Submissions
     */
    async listDirectDebitSubmissions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DirectDebitSubmissionListItemModel>> {
        const response = await this.listDirectDebitSubmissionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List available direct debit payment types
     */
    async listPaymentTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DirectDebitSubmissionPaymentTypeModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["payment-types:view"]);
        }

        const response = await this.request({
            path: `/direct-debit-submissions/payment-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DirectDebitSubmissionPaymentTypeModelFromJSON));
    }

    /**
     * List available direct debit payment types
     */
    async listPaymentTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DirectDebitSubmissionPaymentTypeModel>> {
        const response = await this.listPaymentTypesRaw(initOverrides);
        return await response.value();
    }

}
