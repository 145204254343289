/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateTemplateVersionMetadataModel,
  TemplateListItemModel,
  TemplateMergeFieldListItemModel,
  TemplateModel,
  UpdateTemplateDetailModel,
} from '../models';
import {
    CreateTemplateVersionMetadataModelFromJSON,
    CreateTemplateVersionMetadataModelToJSON,
    TemplateListItemModelFromJSON,
    TemplateListItemModelToJSON,
    TemplateMergeFieldListItemModelFromJSON,
    TemplateMergeFieldListItemModelToJSON,
    TemplateModelFromJSON,
    TemplateModelToJSON,
    UpdateTemplateDetailModelFromJSON,
    UpdateTemplateDetailModelToJSON,
} from '../models';

export interface CreateTemplateVersionRequest {
    templateId: string;
    metadata: CreateTemplateVersionMetadataModel;
    file: Blob;
}

export interface DownloadTemplateVersionRequest {
    templateId: string;
    version: number;
}

export interface GetTemplateRequest {
    templateId: string;
}

export interface ListTemplatesRequest {
    portfolioId?: number;
    type?: string;
}

export interface PreviewLetterTemplateRequest {
    body: Blob;
}

export interface PreviewTemplateRequest {
    templateId: string;
    body: Blob;
}

export interface PreviewTemplateVersionRequest {
    templateId: string;
    version: number;
}

export interface RestoreVersionRequest {
    templateId: string;
    version: number;
}

export interface UpdateTemplateDetailsRequest {
    templateId: string;
    updateTemplateDetailModel: UpdateTemplateDetailModel;
}

/**
 * 
 */
export class TemplateApi extends runtime.BaseAPI {

    /**
     * Create a new template version
     */
    async createTemplateVersionRaw(requestParameters: CreateTemplateVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling createTemplateVersion.');
        }

        if (requestParameters.metadata === null || requestParameters.metadata === undefined) {
            throw new runtime.RequiredError('metadata','Required parameter requestParameters.metadata was null or undefined when calling createTemplateVersion.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling createTemplateVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:manage"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.metadata !== undefined) {
            formParams.append('metadata', new Blob([JSON.stringify(CreateTemplateVersionMetadataModelToJSON(requestParameters.metadata))], { type: "application/json", }));
                    }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/templates/{templateId}/versions`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new template version
     */
    async createTemplateVersion(requestParameters: CreateTemplateVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createTemplateVersionRaw(requestParameters, initOverrides);
    }

    /**
     * Download a version of a template
     */
    async downloadTemplateVersionRaw(requestParameters: DownloadTemplateVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling downloadTemplateVersion.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling downloadTemplateVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:view"]);
        }

        const response = await this.request({
            path: `/documents/templates/{templateId}/versions/{version}`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download a version of a template
     */
    async downloadTemplateVersion(requestParameters: DownloadTemplateVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadTemplateVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve details for an individual template
     */
    async getTemplateRaw(requestParameters: GetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateModel>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling getTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:view"]);
        }

        const response = await this.request({
            path: `/documents/templates/{templateId}`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateModelFromJSON(jsonValue));
    }

    /**
     * Retrieve details for an individual template
     */
    async getTemplate(requestParameters: GetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateModel> {
        const response = await this.getTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available template merge fields
     */
    async listTemplateMergeFieldsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TemplateMergeFieldListItemModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:view"]);
        }

        const response = await this.request({
            path: `/templates/letters/merge-fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TemplateMergeFieldListItemModelFromJSON));
    }

    /**
     * List available template merge fields
     */
    async listTemplateMergeFields(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TemplateMergeFieldListItemModel>> {
        const response = await this.listTemplateMergeFieldsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List available templates
     */
    async listTemplatesRaw(requestParameters: ListTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TemplateListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:view"]);
        }

        const response = await this.request({
            path: `/documents/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TemplateListItemModelFromJSON));
    }

    /**
     * List available templates
     */
    async listTemplates(requestParameters: ListTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TemplateListItemModel>> {
        const response = await this.listTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a preview of a letter template
     */
    async previewLetterTemplateRaw(requestParameters: PreviewLetterTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling previewLetterTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:manage"]);
        }

        const response = await this.request({
            path: `/templates/letters/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Generate a preview of a letter template
     */
    async previewLetterTemplate(requestParameters: PreviewLetterTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.previewLetterTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a preview of a template
     */
    async previewTemplateRaw(requestParameters: PreviewTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling previewTemplate.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling previewTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:manage"]);
        }

        const response = await this.request({
            path: `/templates/{templateId}/preview`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Generate a preview of a template
     */
    async previewTemplate(requestParameters: PreviewTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.previewTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a preview of a template version
     */
    async previewTemplateVersionRaw(requestParameters: PreviewTemplateVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling previewTemplateVersion.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling previewTemplateVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:manage"]);
        }

        const response = await this.request({
            path: `/templates/{templateId}/versions/{version}/preview`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Generate a preview of a template version
     */
    async previewTemplateVersion(requestParameters: PreviewTemplateVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.previewTemplateVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Restore a previous version of a template
     */
    async restoreVersionRaw(requestParameters: RestoreVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling restoreVersion.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling restoreVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:manage"]);
        }

        const response = await this.request({
            path: `/documents/templates/{templateId}/versions/{version}/restore`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Restore a previous version of a template
     */
    async restoreVersion(requestParameters: RestoreVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.restoreVersionRaw(requestParameters, initOverrides);
    }

    /**
     * Update a template\'s details
     */
    async updateTemplateDetailsRaw(requestParameters: UpdateTemplateDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling updateTemplateDetails.');
        }

        if (requestParameters.updateTemplateDetailModel === null || requestParameters.updateTemplateDetailModel === undefined) {
            throw new runtime.RequiredError('updateTemplateDetailModel','Required parameter requestParameters.updateTemplateDetailModel was null or undefined when calling updateTemplateDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:manage"]);
        }

        const response = await this.request({
            path: `/documents/templates/{templateId}`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTemplateDetailModelToJSON(requestParameters.updateTemplateDetailModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a template\'s details
     */
    async updateTemplateDetails(requestParameters: UpdateTemplateDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTemplateDetailsRaw(requestParameters, initOverrides);
    }

}
