import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Button } from "@mui/material";
import { useRejectSettlementQuote } from "api/settlement-quotes/rejectSettlementQuote";
import { useConfirm } from "common/hooks";
import { SettlementStatusModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface RejectSettlementQuoteContainerProps {
  settlementQuoteId: number;
  settlementStatus?: SettlementStatusModel;
  onSuccess?: () => void;
}

export default function RejectSettlementQuoteContainer({
  settlementQuoteId,
  settlementStatus,
  onSuccess,
}: RejectSettlementQuoteContainerProps): ReactElement {
  const { t } = useTranslation("common");
  const rejectSettlementQuote = useRejectSettlementQuote(settlementQuoteId, {
    onSuccess,
  });
  const confirm = useConfirm();

  const onRejectSettlementQuote = async (): Promise<void> => {
    confirm.handlePrompt(() => rejectSettlementQuote.execute());
  };

  const onCloseErrorDialog = (): void => {
    rejectSettlementQuote.reset();
  };

  return (
    <>
      <Button
        disabled={settlementStatus !== SettlementStatusModel.Quoted}
        onClick={onRejectSettlementQuote}
        variant="contained"
      >
        {t("reject_settlement_quote.button_label")}
      </Button>

      <AlertDialog
        content={rejectSettlementQuote.error?.message}
        labels={{ close: t("alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(rejectSettlementQuote.error)}
        title={t("reject_settlement_quote.error_title")}
      />

      <ConfirmationDialog
        content={t("reject_settlement_quote.confirmation_message")}
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("reject_settlement_quote.confirmation_title")}
        labels={{
          confirm: t("yes"),
          reject: t("no"),
        }}
      />
    </>
  );
}
