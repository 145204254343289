import { useSession } from "@ldms/mui-sdk/bootstrap";
import { ArrowDropDown } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { useTerminate } from "api/agreements/terminate";
import { useWriteOff } from "api/agreements/write-off";
import ConfirmUpdateAgreementStatusContainer from "apps/servicing/modules/agreements/containers/ConfirmUpdateAgreementStatusContainer";
import UpdateAgreementStatusContainer from "apps/servicing/modules/agreements/containers/UpdateAgreementStatusContainer";
import useModal from "common/hooks/useModal";
import { useAgreement } from "common/providers";
import { StatusModel } from "generated/core/models";
import { ProductTypeModel } from "generated/onboarding/models";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface GetMenuItemsProps {
  statusMenuItems: { label: string; open: () => void }[];
  status: string;
}

function GetAgreementStatusMenuItems({
  statusMenuItems,
  status,
}: GetMenuItemsProps) {
  const { t } = useTranslation("agreements");
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuElement);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuElement(event.currentTarget);
  };
  const handleMenuClose = (): void => {
    setMenuElement(null);
  };
  const session = useSession();

  return (
    <>
      <Button
        endIcon={<ArrowDropDown />}
        aria-label={t("progress_status_menu_button")}
        variant="contained"
        onClick={handleMenuClick}
        disabled={!session.hasPermission("servicing:workflow:manage")}
      >
        {status}
      </Button>
      <Menu open={menuOpen} anchorEl={menuElement} onClose={handleMenuClose}>
        {statusMenuItems.map((menuItem) => (
          <MenuItem key={menuItem.label} onClick={menuItem.open}>
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default function UpdateAgreementStatusMenuContainer(): ReactElement {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const endOfTerm = useModal();
  const pendingTermination = useModal();
  const terminate = useModal();
  const writeOff = useModal();
  const pendingSecondaryRental = useModal();
  const paidInFull = useModal();

  const handleUpdateStatusSuccess = (): void => {
    if (endOfTerm.isOpen) {
      endOfTerm.close();
    }
    if (pendingTermination.isOpen) {
      pendingTermination.close();
    }
    if (terminate.isOpen) {
      terminate.close();
    }
    if (writeOff.isOpen) {
      writeOff.close();
    }
    if (pendingSecondaryRental.isOpen) {
      pendingSecondaryRental.close();
    }

    if (paidInFull.isOpen) {
      paidInFull.close();
    }
  };

  const terminateCommand = useTerminate(agreement.id, {
    onSuccess: handleUpdateStatusSuccess,
  });

  const onSubmitTerminate = async (): Promise<void> => {
    await terminateCommand.execute();
    await agreement.mutate();
  };

  const writeOffCommand = useWriteOff(agreement.id, {
    onSuccess: handleUpdateStatusSuccess,
  });

  const onSubmitWriteOff = async (): Promise<void> => {
    await writeOffCommand.execute();
    await agreement.mutate();
  };

  const terminationUpdateButton = "termination.update_button";

  return (
    <>
      {agreement.data?.statusCode &&
        !["EOT", "TERMPEND", "TERMINATION", "WRITE OFF", "PAIDFULL"].includes(
          agreement.data.statusCode,
        ) && (
          <GetAgreementStatusMenuItems
            status={agreement.data?.status}
            statusMenuItems={[
              {
                label: t("end_of_term.update_button"),
                open: endOfTerm.open,
              },
              {
                label: t("pending_termination.update_button"),
                open: pendingTermination.open,
              },
              {
                label: t(terminationUpdateButton),
                open: terminate.open,
              },
            ]}
          />
        )}

      {agreement.data?.statusCode === "TERMPEND" && (
        <GetAgreementStatusMenuItems
          status={agreement.data?.status}
          statusMenuItems={[
            {
              label: t(terminationUpdateButton),
              open: terminate.open,
            },
          ]}
        />
      )}

      {agreement.data?.statusCode === "TERMINATION" && (
        <GetAgreementStatusMenuItems
          status={agreement.data?.status}
          statusMenuItems={[
            {
              label: t("write_off.update_button"),
              open: writeOff.open,
            },
          ]}
        />
      )}

      {agreement.data?.statusCode === "WRITE OFF" && (
        <Button
          aria-label={t("progress_status_menu_button")}
          variant="contained"
          disabled={true}
        >
          {agreement.data?.status}
        </Button>
      )}

      {agreement.data?.statusCode && agreement.data.statusCode === "EOT" && (
        <GetAgreementStatusMenuItems
          status={agreement.data?.status}
          statusMenuItems={[
            {
              label: t(terminationUpdateButton),
              open: terminate.open,
            },
          ].concat(
            [
              ProductTypeModel.FixedRateOperatingLease as string,
              ProductTypeModel.FixedRateFinanceLease as string,
            ].includes(agreement?.data.productName)
              ? {
                  label: t("pending_secondary_rental.update_button"),
                  open: pendingSecondaryRental.open,
                }
              : [],
            Number(agreement.data.currentBalance) <= 0
              ? {
                  label: t("paid_in_full.update_button"),
                  open: paidInFull.open,
                }
              : [],
          )}
        />
      )}

      {agreement.data?.statusCode === "PAIDFULL" && (
        <Button
          aria-label={t("progress_status_menu_button")}
          variant="contained"
          disabled={true}
        >
          {agreement.data?.status}
        </Button>
      )}

      <UpdateAgreementStatusContainer
        status={StatusModel.EndOfTerm}
        open={endOfTerm.isOpen}
        onClose={endOfTerm.close}
        onSuccess={handleUpdateStatusSuccess}
        confirmationMessage={t("end_of_term.description_label")}
        title={t("end_of_term.title_label")}
      />

      <UpdateAgreementStatusContainer
        status={StatusModel.PendingTermination}
        open={pendingTermination.isOpen}
        onClose={pendingTermination.close}
        onSuccess={handleUpdateStatusSuccess}
        confirmationMessage={t("pending_termination.description_label")}
        title={t("pending_termination.title_label")}
      />

      <UpdateAgreementStatusContainer
        status={StatusModel.PendingSecondaryRental}
        open={pendingSecondaryRental.isOpen}
        onClose={pendingSecondaryRental.close}
        onSuccess={handleUpdateStatusSuccess}
        confirmationMessage={t("pending_secondary_rental.description_label")}
        title={t("pending_secondary_rental.title_label")}
      />

      <UpdateAgreementStatusContainer
        status={StatusModel.PaidInFull}
        open={paidInFull.isOpen}
        onClose={paidInFull.close}
        onSuccess={handleUpdateStatusSuccess}
        confirmationMessage={t("paid_in_full.description_label")}
        title={t("paid_in_full.title_label")}
      />

      {agreement.data?.agreementNumber && (
        <>
          <ConfirmUpdateAgreementStatusContainer
            open={terminate.isOpen}
            onClose={terminate.close}
            onSubmit={onSubmitTerminate}
            confirmationMessage={t("termination.description_label")}
            title={t("termination.title_label")}
          />

          <ConfirmUpdateAgreementStatusContainer
            open={writeOff.isOpen}
            onClose={writeOff.close}
            onSubmit={onSubmitWriteOff}
            confirmationMessage={t("write_off.description_label")}
            title={t("write_off.title_label")}
          />
        </>
      )}
    </>
  );
}
