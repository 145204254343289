import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { OnboardingFeeDetailsModel } from "apps/onboarding/containers";
import { NoResults, YesNoValue } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface OnboardingFeesListProps {
  data: OnboardingFeeDetailsModel[];
  onRemove(index: number): void;
}

export default function OnboardingFeesList({
  data,
  onRemove,
}: OnboardingFeesListProps): ReactElement {
  const { t } = useTranslation("onboardings");

  const { formatAmount } = useFormat();

  const tableHeaders: string[] = [
    t("fees_and_commissions.fees.list.name_label"),
    t("fees_and_commissions.fees.list.type_label"),
    t("fees_and_commissions.fees.list.amount_label"),
    t("fees_and_commissions.fees.list.taxable_label"),
    t("fees_and_commissions.fees.list.deducted_from_advance_label"),
    t("fees_and_commissions.fees.collect_by_dd_label"),
    t("fees_and_commissions.fees.include_on_invoices_label"),
    "",
  ];

  const makeRemoveOnboardingFeeHandler = (index: number) => (): void =>
    onRemove(index);

  return (
    <>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table
          stickyHeader
          size="small"
          aria-label={t("fees_and_commissions.fees.list.table_name_label")}
        >
          <TableHead>
            <TableRow>
              {tableHeaders.map((label) => (
                <TableCell key={label}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{t(item.type)}</TableCell>
                <TableCell>{formatAmount(item.amount)}</TableCell>
                <TableCell>
                  <YesNoValue value={Boolean(item.taxable)} />
                </TableCell>
                <TableCell>
                  <YesNoValue value={Boolean(item.deductedFromAdvance)} />
                </TableCell>
                <TableCell>
                  <YesNoValue value={Boolean(item.collectByDirectDebit)} />
                </TableCell>
                <TableCell>
                  <YesNoValue value={Boolean(item.includeOnInvoices)} />
                </TableCell>
                <TableCell>
                  <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                      <Button
                        color="primary"
                        onClick={makeRemoveOnboardingFeeHandler(index)}
                        size="small"
                      >
                        {t("fees_and_commissions.fees.list.remove_fee_button")}
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length === 0 && (
        <NoResults message={t("fees_and_commissions.fees.no_fees_added")} />
      )}
    </>
  );
}
