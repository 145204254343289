import { Box, CircularProgress } from "@mui/material";
import { ReactNode } from "react";

const defaultFallback = (
  <Box display="flex" justifyContent="center">
    <CircularProgress />
  </Box>
);

interface LoaderProps {
  fallback?: ReactNode;
  ready: boolean;
  render(): ReactNode;
}

/**
 * A component that renders a component when ready
 * @param props LoaderProps
 */
export default function Loader({
  ready,
  render,
  fallback = defaultFallback,
}: LoaderProps) {
  return ready ? <>{render()}</> : <>{fallback}</>;
}
