import { QueryKey } from "api";

export const all = (): QueryKey => ["companies"];

export const list = (): QueryKey => [...all()];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (companyId: string): QueryKey => [
  ...details(),
  companyId,
];
