import { AmountField, DateField } from "@ldms/mui-sdk/forms";
import { Grid, MenuItem } from "@mui/material";
import { ControlledTextField } from "common/components";
import { FinancialPostingStageListItemModel } from "generated/core/models";
import { ReactElement } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface AddFinancialPostingFieldValues {
  agreement?: { label: string; value: string };
  accountName: string;
  stageGroup: string;
  stageCode: string;
  assetId?: number;
  amount: number;
  financialDate: Date;
}

export interface AssetFinancialPosting {
  id: number;
  display: string;
}

interface AddFinancialPostingFormControlsProps {
  props: UseFormReturn<AddFinancialPostingFieldValues>;
  settledAssets?: AssetFinancialPosting[];
  financialPostings?: FinancialPostingStageListItemModel[];
}

export default function AddFinancialPostingFormControls({
  props,
  settledAssets,
  financialPostings,
}: AddFinancialPostingFormControlsProps): ReactElement {
  const { t } = useTranslation("agreements");
  const formWatch = props.watch();
  const pleaseSelectDefaultValue = "common:please_select";

  const handleChangeAccountName = (): void => {
    props.setValue("stageGroup", "");
    props.setValue("stageCode", "");
    props.setValue("assetId", 0);
  };

  const handleChangeStageGroup = (): void => {
    props.setValue("stageCode", "");
    props.setValue("assetId", 0);
  };

  const handleChangeStageName = (): void => {
    props.setValue("assetId", 0);
  };

  const unqiueAccountNames = Array.from(
    new Set(financialPostings?.map((a) => a.accountCode)),
  ).map((accountCode) => {
    return financialPostings?.find((a) => a.accountCode === accountCode);
  });

  const unqiueStageGroups = Array.from(
    new Set(financialPostings?.map((a) => a.stageGroup)),
  ).map((stageGroup) => {
    return financialPostings?.find((a) => a.stageGroup === stageGroup);
  });

  const filterAssociatedStageNames = financialPostings?.filter(
    (a) =>
      a.accountCode === formWatch.accountName &&
      a.sourceCode === formWatch.stageGroup,
  );

  return (
    <>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={12}>
          <ControlledTextField
            helperText={props.formState.errors.accountName?.message}
            error={props.formState.errors.accountName?.message}
            control={props.control}
            id="accountName"
            name="accountName"
            label={t("financial_postings.add.account_label")}
            SelectProps={{ displayEmpty: true }}
            select
            defaultValue=""
            required
            onChange={handleChangeAccountName}
          >
            <MenuItem value="">
              <i>{t(pleaseSelectDefaultValue)}</i>
            </MenuItem>

            {unqiueAccountNames.map((accountName) => (
              <MenuItem
                key={accountName?.accountCode}
                value={accountName?.accountCode}
              >
                {`${accountName?.accountCode} - ${accountName?.accountName}`}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item sm={12}>
          <ControlledTextField
            helperText={props.formState.errors.stageGroup?.message}
            error={props.formState.errors.stageGroup?.message}
            control={props.control}
            id="stageGroup"
            name="stageGroup"
            label={t("financial_postings.add.stage_group_label")}
            SelectProps={{ displayEmpty: true }}
            select
            defaultValue=""
            required
            disabled={
              formWatch.accountName === undefined ||
              formWatch.accountName === ""
            }
            onChange={handleChangeStageGroup}
          >
            <MenuItem value="">
              <i>{t(pleaseSelectDefaultValue)}</i>
            </MenuItem>

            {unqiueStageGroups.map((stageGroup) => (
              <MenuItem
                key={stageGroup?.sourceCode}
                value={stageGroup?.sourceCode}
              >
                {stageGroup?.stageGroup}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item sm={12}>
          <ControlledTextField
            helperText={props.formState.errors.stageCode?.message}
            error={props.formState.errors.stageCode?.message}
            control={props.control}
            id="stageCode"
            name="stageCode"
            label={t("financial_postings.add.stage_name_label")}
            SelectProps={{ displayEmpty: true }}
            select
            defaultValue=""
            disabled={
              formWatch.stageGroup === undefined ||
              formWatch.stageGroup === "" ||
              formWatch.accountName === undefined ||
              formWatch.accountName === "" ||
              filterAssociatedStageNames?.length === 0
            }
            required
            onChange={handleChangeStageName}
          >
            <MenuItem value="">
              <i>{t(pleaseSelectDefaultValue)}</i>
            </MenuItem>
            {filterAssociatedStageNames?.map((stageName) => (
              <MenuItem key={stageName?.stageCode} value={stageName?.stageCode}>
                {`${stageName?.stageCode} - ${stageName?.stageName}`}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item sm={12}>
          {formWatch.stageGroup === "ES" && (
            <ControlledTextField
              helperText={props.formState.errors.assetId?.message}
              error={props.formState.errors.assetId?.message}
              control={props.control}
              id="assetId"
              name="assetId"
              label={t("financial_postings.add.asset_label")}
              SelectProps={{ displayEmpty: true }}
              select
              defaultValue={0}
              disabled={
                formWatch.stageCode === undefined ||
                formWatch.stageCode === "" ||
                settledAssets?.length === 0
              }
            >
              <MenuItem value={0}>
                <i>{t(pleaseSelectDefaultValue)}</i>
              </MenuItem>
              {settledAssets?.map((asset) => (
                <MenuItem key={asset.id} value={asset.id}>
                  {asset.display}
                </MenuItem>
              ))}
            </ControlledTextField>
          )}
        </Grid>
        <Grid item sm={6}>
          <AmountField
            error={Boolean(props.formState.errors?.amount?.message)}
            helperText={props.formState.errors?.amount?.message}
            control={props.control}
            name="amount"
            label={t("financial_postings.add.amount_label")}
            required
          />
        </Grid>
        <Grid item sm={6}>
          <DateField
            control={props.control}
            name="financialDate"
            error={Boolean(props.formState.errors.financialDate?.message)}
            helperText={props.formState.errors.financialDate?.message}
            label={t("financial_postings.add.date_label")}
            required
          />
        </Grid>
      </Grid>
    </>
  );
}
