import { useResponseError } from "common/hooks";
import { ApiResponse } from "generated";
import { useState } from "react";
import errorHandler from "support/error-handler";
import fileDownloader from "support/file-downloader";

interface UseReportDownloadReturn<TData> {
  download: (data: TData) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
}

export default function useReportDownload<TData>(
  downloadReport: (data: TData) => Promise<ApiResponse<string | Blob>>,
  options: {
    onSuccess?: () => void;
  } = {},
): UseReportDownloadReturn<TData> {
  const error = useResponseError();
  const [isLoading, setIsLoading] = useState(false);

  const download = async (data: TData): Promise<void> => {
    try {
      error.reset();
      setIsLoading(true);

      const response = await downloadReport(data);

      await fileDownloader(response);
      options.onSuccess?.();
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    download,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
  };
}
