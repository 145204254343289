/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstalmentTotalsModel
 */
export interface InstalmentTotalsModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentTotalsModel
     */
    totalCapital: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentTotalsModel
     */
    totalInterest: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentTotalsModel
     */
    totalVat: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentTotalsModel
     */
    totalRental: string;
}

/**
 * Check if a given object implements the InstalmentTotalsModel interface.
 */
export function instanceOfInstalmentTotalsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalCapital" in value;
    isInstance = isInstance && "totalInterest" in value;
    isInstance = isInstance && "totalVat" in value;
    isInstance = isInstance && "totalRental" in value;

    return isInstance;
}

export function InstalmentTotalsModelFromJSON(json: any): InstalmentTotalsModel {
    return InstalmentTotalsModelFromJSONTyped(json, false);
}

export function InstalmentTotalsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstalmentTotalsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCapital': json['totalCapital'],
        'totalInterest': json['totalInterest'],
        'totalVat': json['totalVat'],
        'totalRental': json['totalRental'],
    };
}

export function InstalmentTotalsModelToJSON(value?: InstalmentTotalsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCapital': value.totalCapital,
        'totalInterest': value.totalInterest,
        'totalVat': value.totalVat,
        'totalRental': value.totalRental,
    };
}

