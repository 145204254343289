import { list } from "api/documents/portfolios/templates/keys";
import useQuery, {
  UseQueryOptions,
  UseQueryReturn,
} from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { DocumentsApi } from "generated/documents/apis";
import { DocumentTemplateListItemModel } from "generated/documents/models";

type UseListDocumentTemplates = (
  portfolioId: number,
  options?: UseQueryOptions,
) => UseQueryReturn<DocumentTemplateListItemModel[]>;

export const useListDocumentTemplates: UseListDocumentTemplates = (
  portfolioId: number,
  options = {},
) => {
  const api = useApi(DocumentsApi);

  return useQuery(
    list(portfolioId),
    () => api.listDocumentTemplates({ portfolioId }),
    options,
  );
};
