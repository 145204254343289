import EquifaxInsightReportFormDialog, {
  EquifaxInsightFormValues,
} from "apps/reporting/components/EquifaxInsightReportFormDialog";
import { useReportDownload } from "apps/reporting/hooks";
import { useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { ApiResponse } from "generated";
import { PortfolioQueryApi, ReportQueryApi } from "generated/core/apis";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

interface EquifaxInsightReportProps {
  onClose(): void;
  open: boolean;
}

export default function EquifaxInsightReportContainer({
  onClose,
  open,
}: EquifaxInsightReportProps): ReactElement {
  const { t } = useTranslation("reporting");
  const reportApi = useApi(ReportQueryApi);
  const portfolioQueryApi = useApi(PortfolioQueryApi);
  const portfolios = useSWR(["portfolios"], () =>
    portfolioQueryApi.listPortfolios(),
  );

  const portfoliosWithEquifax =
    portfolios.data?.filter(
      (portfolio) => portfolio.equifaxInsightNumber !== undefined,
    ) ?? [];

  const uniqueEquifaxNumbers = Array.from(
    new Set(
      portfoliosWithEquifax.map((portfolio) => portfolio.equifaxInsightNumber),
    ),
  );

  const equifaxInsightReportApi = (
    data: EquifaxInsightFormValues,
  ): Promise<ApiResponse<string>> =>
    reportApi.equifaxInsightRaw({
      reportId: Number(data.reportId),
    });

  const equifaxInsightReportDownload = useReportDownload(
    equifaxInsightReportApi,
    {
      onSuccess: onClose,
    },
  );

  const onSubmit = async (data: EquifaxInsightFormValues): Promise<void> =>
    equifaxInsightReportDownload.download(data);

  const resolver = useYupResolver<EquifaxInsightFormValues>((yup) =>
    yup.object().shape({
      reportId: yup
        .string()
        .isRequired(t("report_dialog.equifax.reporting_period_label")),
      equifaxInsightNumber: yup
        .string()
        .isRequired(t("report_dialog.equifax.equifax_insight_label")),
    }),
  );

  return (
    <EquifaxInsightReportFormDialog
      title={t("report_dialog.equifax.title")}
      error={equifaxInsightReportDownload.error?.message}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      equifaxNumbers={uniqueEquifaxNumbers}
      ready={
        Boolean(portfolios.data) && !equifaxInsightReportDownload.isLoading
      }
      resolver={resolver}
    />
  );
}
