import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import { useState } from "react";
import errorHandler from "support/error-handler";
import useMutateThirdParties from "./useMutateThirdParties";

type Command = ({ thirdPartyId }: { thirdPartyId: number }) => Promise<void>;

interface UseAssociateThirdPartyReturn {
  command: Command;
  isLoading: boolean;
  error: Error | undefined;
}

export const useAssociateThirdParty = (
  agreementId: number,
  options: { onSuccess?: () => void } = {},
): UseAssociateThirdPartyReturn => {
  const api = useApi(AgreementCommandApi);
  const error = useResponseError();
  const [isLoading, setIsLoading] = useState(false);
  const mutateThirdParties = useMutateThirdParties(agreementId);

  const command: Command = async ({ thirdPartyId }): Promise<void> => {
    try {
      setIsLoading(true);
      await api.associateThirdParty({
        agreementId: agreementId,
        thirdPartyId,
      });
      options.onSuccess?.();
      mutateThirdParties();
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
  };
};
