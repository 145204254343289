import { DateField } from "@ldms/mui-sdk/forms";
import { Grid, MenuItem, TextField } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface TransactionFilterProps {
  transactionTypes: string[];
  filters: {
    transactionType: string;
    fromDate: Date | null;
    toDate: Date | null;
  };
  onChange(key: string, value: string | Date | null): void;
}

export default function TransactionsFilter({
  transactionTypes,
  filters,
  onChange,
}: TransactionFilterProps): ReactElement {
  const { t } = useTranslation("agreements");

  const makeDateChangeHandler =
    (name: string) =>
    (date: Date | null): void => {
      onChange(name, date);
    };

  const handleTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onChange(event.target.name, event.target.value);
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <DateField
          name="fromDate"
          label={t("transactions.from")}
          inputProps={{
            max: filters.toDate,
          }}
          margin="none"
          value={filters.fromDate}
          onChange={makeDateChangeHandler("fromDate")}
        />
      </Grid>
      <Grid item>
        <DateField
          name="toDate"
          label={t("transactions.to")}
          inputProps={{
            min: filters.fromDate,
            max: new Date().toISOString().split("T")[0],
          }}
          margin="none"
          onChange={makeDateChangeHandler("toDate")}
          value={filters.toDate}
        />
      </Grid>
      <Grid item>
        <TextField
          InputLabelProps={{
            htmlFor: "transactionTypeFilter",
            shrink: true,
          }}
          inputProps={{ displayEmpty: true, id: "transactionTypeFilter" }}
          onChange={handleTypeChange}
          label={t("transactions.type")}
          name="transactionType"
          select
          size="small"
          value={filters.transactionType}
          variant="outlined"
          margin="none"
        >
          <MenuItem value="" key="">
            {t("transactions.all_transaction_types")}
          </MenuItem>
          {transactionTypes.map((tt) => (
            <MenuItem value={tt} key={tt}>
              {tt}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}
