import { Fade, Typography } from "@mui/material";
import ProviderLoading from "common/components/ProviderLoading";
import { EnvironmentApi } from "generated/gateway/apis";
import { EnvironmentModel } from "generated/gateway/models";
import { Configuration, ResponseError } from "generated/gateway/runtime";
import { ReactNode, createContext, useContext, useMemo } from "react";
import useSWR from "swr";

export type Environment = {
  features: string[];
  application?: string;
  production: boolean | undefined;
  helpUrl: string;
};

export const EnvironmentContext = createContext<Environment>({
  features: [],
  application: "",
  production: true,
  helpUrl: "",
});

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);

  return {
    features: context.features,
    application: context.application,
    isFeatureEnabled: (feature: string) => {
      return context.features.includes(feature);
    },
    isProduction: context.production,
    helpUrl: context.helpUrl,
  };
};

export interface EnvironmentProviderProps {
  children: ReactNode;
  application?: string;
}

const useGetEnvironment = () => {
  const environmentApi: EnvironmentApi = new EnvironmentApi(
    new Configuration({
      basePath: "/api",
      credentials: "include",
    }),
  );

  return useSWR<EnvironmentModel, ResponseError>("/environment", () =>
    environmentApi.getEnvironment(),
  );
};

const EnvironmentContextProvider = ({
  data,
  application = "",
  children,
}: {
  data: EnvironmentModel;
  application?: string;
  children: ReactNode;
}) => {
  const applicationFeatures = data.applications?.[application]?.features ?? [];
  const globalFeatures = data.applications?.["global"]?.features ?? [];
  const combinedFeatures = applicationFeatures.concat(globalFeatures);

  const value = useMemo(
    () => ({
      application,
      features: combinedFeatures,
      production: data.production,
      helpUrl: data.helpUrl ?? "",
    }),
    [application, combinedFeatures, data.production, data.helpUrl],
  );

  return (
    <EnvironmentContext.Provider value={value}>
      {children}
    </EnvironmentContext.Provider>
  );
};

const EnvironmentProvider = ({
  children,
  application,
}: EnvironmentProviderProps) => {
  const environment = useGetEnvironment();

  const isLoading = !environment.data && !environment.error;
  if (isLoading) {
    return (
      <Fade in style={{ transitionDelay: "500ms" }}>
        <div>
          <ProviderLoading />
        </div>
      </Fade>
    );
  }

  if (environment.error || !environment.data) {
    return <Typography>Error</Typography>;
  }

  return (
    <EnvironmentContextProvider
      application={application}
      data={environment.data}
    >
      {children}
    </EnvironmentContextProvider>
  );
};

export default EnvironmentProvider;
