import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { PreviewTemplateRequest, TemplateApi } from "generated/documents/apis";

export const usePreviewTemplate = (
  options: UseCommandOptions<Blob, ResponseError> = {},
) => {
  const api = useApi(TemplateApi);
  return useCommand<PreviewTemplateRequest, Blob, ResponseError>(
    (requestParameters) => {
      return api.previewTemplate(requestParameters);
    },
    options,
  );
};
