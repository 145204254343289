import { AmountField, DateField, NumberField } from "@ldms/mui-sdk/forms";
import { Grid, MenuItem, TextField } from "@mui/material";
import { RepaymentTermsFormModel } from "apps/onboarding/containers";
import { ControlledTextField } from "common/components";
import { FrequencyOfInstalmentsModel } from "generated/onboarding/models";
import { useTranslation } from "react-i18next";
import { Control, FieldErrors, UseFormRegister } from "support/react-hook-form";

interface RepaymentTermsFieldSetProps {
  labels: {
    loanTerm: string;
    repaymentFrequency: string;
    frequencyAmount: string;
    inceptionDate: string;
    firstPaymentDate: string;
    customerInterestRate: string;
    b2bFundingRate: string;
    rateToBroker: string;
  };
  errors?: FieldErrors<RepaymentTermsFormModel>;
  register: UseFormRegister<RepaymentTermsFormModel>;
  control: Control<RepaymentTermsFormModel>;
  seasonal: boolean;
  custom: boolean;
}

export default function RepaymentTermsFieldSet(
  props: RepaymentTermsFieldSetProps,
): React.ReactElement {
  const { t } = useTranslation("onboardings");
  return (
    <Grid container>
      <Grid item>
        <NumberField
          control={props.control}
          error={Boolean(props.errors?.loanTerm?.message)}
          helperText={props.errors?.loanTerm?.message}
          label={props.labels.loanTerm}
          name="loanTerm"
          required
        />
        {!props.seasonal && !props.custom && (
          <>
            <ControlledTextField
              helperText={props.errors?.repaymentFrequency?.message}
              SelectProps={{ displayEmpty: true }}
              error={props.errors?.repaymentFrequency?.message}
              control={props.control}
              label={props.labels.repaymentFrequency}
              id="repaymentFrequency"
              name="repaymentFrequency"
              select
              required
            >
              <MenuItem value="">{t("common:please_select")}</MenuItem>
              {Object.values(FrequencyOfInstalmentsModel).map(
                (currentValue) => (
                  <MenuItem
                    value={String(currentValue)}
                    key={String(currentValue)}
                  >
                    {currentValue}
                  </MenuItem>
                ),
              )}
            </ControlledTextField>
            <AmountField
              error={Boolean(props.errors?.frequencyAmount?.message)}
              helperText={props.errors?.frequencyAmount?.message}
              control={props.control}
              name="frequencyAmount"
              required
              label={props.labels.frequencyAmount}
            />
          </>
        )}
        <DateField
          control={props.control}
          name="inceptionDate"
          error={Boolean(props.errors?.inceptionDate?.message)}
          helperText={props.errors?.inceptionDate?.message}
          label={props.labels.inceptionDate}
          required
        />

        {!props.custom && (
          <DateField
            control={props.control}
            name="firstPaymentDate"
            error={Boolean(props.errors?.firstPaymentDate?.message)}
            helperText={props.errors?.firstPaymentDate?.message}
            label={props.labels.firstPaymentDate}
            required
          />
        )}

        <TextField
          {...props.register("customerInterestRate")}
          helperText={props.errors?.customerInterestRate?.message}
          error={Boolean(props.errors?.customerInterestRate)}
          label={props.labels.customerInterestRate}
          type="number"
          required
        />
        <TextField
          {...props.register("b2bFundingRate")}
          helperText={props.errors?.b2bFundingRate?.message}
          error={Boolean(props.errors?.b2bFundingRate)}
          label={props.labels.b2bFundingRate}
          type="number"
        />
        <TextField
          {...props.register("rateToBroker")}
          helperText={props.errors?.rateToBroker?.message}
          error={Boolean(props.errors?.rateToBroker)}
          label={props.labels.rateToBroker}
          type="number"
        />
      </Grid>
    </Grid>
  );
}
