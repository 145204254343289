import * as keys from "api/report/equifax/keys";
import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { ReportQueryApi } from "generated/core/apis";
import { EquifaxInsightReportListItemModel } from "generated/core/models";

type UseListAvailableEquifaxInsightReports = (
  equifaxInsightNumber: string,
) => UseQueryReturn<EquifaxInsightReportListItemModel[]>;

export const useListAvailableEquifaxInsightReports: UseListAvailableEquifaxInsightReports =
  (equifaxInsightNumber: string) => {
    const api = useApi(ReportQueryApi);

    return useQuery(keys.list(equifaxInsightNumber), () =>
      api.listAvailableEquifaxInsightReports({
        equifaxInsightNumber,
      }),
    );
  };
