import { useQuery } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { RolesApi } from "generated/admin/apis";
import * as keys from "./keys";

export const useListRoles = () => {
  const api = useApi(RolesApi);

  return useQuery(keys.list(), () => api.listRoles());
};
