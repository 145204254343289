/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The details of the insurance associated with the agreement
 * @export
 * @interface InsuranceModel
 */
export interface InsuranceModel {
    /**
     * The name of the insurance company
     * @type {string}
     * @memberof InsuranceModel
     */
    name?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InsuranceModel
     */
    premiumTotal?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InsuranceModel
     */
    cost?: string;
    /**
     * Insurance premium tax rate
     * @type {number}
     * @memberof InsuranceModel
     */
    insurancePremiumTaxRate?: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof InsuranceModel
     */
    insuranceStartDate?: Date;
}

/**
 * Check if a given object implements the InsuranceModel interface.
 */
export function instanceOfInsuranceModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InsuranceModelFromJSON(json: any): InsuranceModel {
    return InsuranceModelFromJSONTyped(json, false);
}

export function InsuranceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'premiumTotal': !exists(json, 'premiumTotal') ? undefined : json['premiumTotal'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'insurancePremiumTaxRate': !exists(json, 'insurancePremiumTaxRate') ? undefined : json['insurancePremiumTaxRate'],
        'insuranceStartDate': !exists(json, 'insuranceStartDate') ? undefined : (new Date(json['insuranceStartDate'])),
    };
}

export function InsuranceModelToJSON(value?: InsuranceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'premiumTotal': value.premiumTotal,
        'cost': value.cost,
        'insurancePremiumTaxRate': value.insurancePremiumTaxRate,
        'insuranceStartDate': value.insuranceStartDate === undefined ? undefined : (value.insuranceStartDate.toISOString().substring(0,10)),
    };
}

