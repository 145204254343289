/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DeliveryMethodModel = {
    Email: 'Email',
    Letter: 'Letter'
} as const;
export type DeliveryMethodModel = typeof DeliveryMethodModel[keyof typeof DeliveryMethodModel];


export function DeliveryMethodModelFromJSON(json: any): DeliveryMethodModel {
    return DeliveryMethodModelFromJSONTyped(json, false);
}

export function DeliveryMethodModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryMethodModel {
    return json as DeliveryMethodModel;
}

export function DeliveryMethodModelToJSON(value?: DeliveryMethodModel | null): any {
    return value as any;
}

