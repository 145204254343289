import { useSession, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Box } from "@mui/material";
import UnexpectedError from "common/components/UnexpectedError";
import SidebarNavigation from "common/containers/SidebarNavigation";
import SidebarLayout from "common/layouts/SidebarLayout";
import { compose } from "lodash/fp";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

function SettingsLayout() {
  const { t } = useTranslation("servicing");

  const makeItem = (label: string, to: string, display = true) => {
    return { label: t(label), to, display };
  };
  const session = useSession();

  const navigationMenu = [
    makeItem(
      "navigation.companies",
      "companies",
      session.hasPermission("servicing:companies:manage"),
    ),
    makeItem(
      "navigation.bank_accounts",
      "bank-accounts",
      session.hasPermission("servicing:bank-accounts:elevated"),
    ),
    makeItem(
      "navigation.vat_codes",
      "vat-codes",
      session.hasPermission("servicing:vat-codes:manage"),
    ),
    makeItem(
      "navigation.third_parties_link",
      "third-parties",
      session.hasPermission("servicing:third-parties:manage"),
    ),
  ].concat(
    makeItem(
      "navigation.charges",
      "charges",
      session.hasPermission("servicing:charges:manage"),
    ),
  );

  return (
    <SidebarLayout
      sidebar={<SidebarNavigation menu={navigationMenu} />}
      title={t("settings_title")}
    >
      <Box height="100%" component="main">
        <ErrorBoundary FallbackComponent={UnexpectedError}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </SidebarLayout>
  );
}

export default compose(
  withTranslationLoader("servicing"),
  withProtectedView({
    allowedPermissions: [
      "servicing:companies:manage",
      "servicing:bank-accounts:elevated",
      "servicing:vat-codes:manage",
      "servicing:third-parties:manage",
      "servicing:charges:manage",
    ],
  }),
)(SettingsLayout);
