/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeliveryStatusModel,
  LetterListItemModel,
  LetterVerificationModel,
} from '../models';
import {
    DeliveryStatusModelFromJSON,
    DeliveryStatusModelToJSON,
    LetterListItemModelFromJSON,
    LetterListItemModelToJSON,
    LetterVerificationModelFromJSON,
    LetterVerificationModelToJSON,
} from '../models';

export interface ListLettersToEmailRequest {
    offset?: number;
    limit?: number;
    status?: DeliveryStatusModel;
    from?: Date;
    to?: Date;
    type?: string;
}

export interface RejectLettersToEmailRequest {
    letterVerificationModel: LetterVerificationModel;
}

export interface VerifyLettersToEmailRequest {
    letterVerificationModel: LetterVerificationModel;
}

export interface ViewLetterRequest {
    letterId: string;
}

/**
 * 
 */
export class LetterApi extends runtime.BaseAPI {

    /**
     * Retrieve a list of letters being sent via email
     */
    async listLettersToEmailRaw(requestParameters: ListLettersToEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LetterListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["batches:view"]);
        }

        const response = await this.request({
            path: `/documents/letters/email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LetterListItemModelFromJSON));
    }

    /**
     * Retrieve a list of letters being sent via email
     */
    async listLettersToEmail(requestParameters: ListLettersToEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LetterListItemModel>> {
        const response = await this.listLettersToEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject a list of Letters to Email
     */
    async rejectLettersToEmailRaw(requestParameters: RejectLettersToEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.letterVerificationModel === null || requestParameters.letterVerificationModel === undefined) {
            throw new runtime.RequiredError('letterVerificationModel','Required parameter requestParameters.letterVerificationModel was null or undefined when calling rejectLettersToEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["batches:view"]);
        }

        const response = await this.request({
            path: `/documents/letters/email/reject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LetterVerificationModelToJSON(requestParameters.letterVerificationModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject a list of Letters to Email
     */
    async rejectLettersToEmail(requestParameters: RejectLettersToEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rejectLettersToEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Verify a list of Letters to Email
     */
    async verifyLettersToEmailRaw(requestParameters: VerifyLettersToEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.letterVerificationModel === null || requestParameters.letterVerificationModel === undefined) {
            throw new runtime.RequiredError('letterVerificationModel','Required parameter requestParameters.letterVerificationModel was null or undefined when calling verifyLettersToEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["batches:view"]);
        }

        const response = await this.request({
            path: `/documents/letters/email/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LetterVerificationModelToJSON(requestParameters.letterVerificationModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verify a list of Letters to Email
     */
    async verifyLettersToEmail(requestParameters: VerifyLettersToEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyLettersToEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve a letter
     */
    async viewLetterRaw(requestParameters: ViewLetterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.letterId === null || requestParameters.letterId === undefined) {
            throw new runtime.RequiredError('letterId','Required parameter requestParameters.letterId was null or undefined when calling viewLetter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["batches:view"]);
        }

        const response = await this.request({
            path: `/documents/letters/{letterId}`.replace(`{${"letterId"}}`, encodeURIComponent(String(requestParameters.letterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Retrieve a letter
     */
    async viewLetter(requestParameters: ViewLetterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.viewLetterRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
