import { ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Typography } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface OnboardingAssetConfirmationDialogProps {
  numberOfAssets: number;
  open: boolean;
  onCancel(): void;
  onConfirm(): void;
}

export default function OnboardingAssetConfirmationDialog({
  numberOfAssets,
  open,
  onCancel,
  onConfirm,
}: OnboardingAssetConfirmationDialogProps): ReactElement {
  const { t } = useTranslation("onboardings");
  return (
    <ConfirmationDialog
      content={
        <Typography>
          {t("asset_confirmation_dialog.body", { count: numberOfAssets })}
        </Typography>
      }
      open={open}
      onConfirm={onConfirm}
      onReject={onCancel}
      title={t("asset_confirmation_dialog.title")}
      labels={{
        confirm: t("common:yes"),
        reject: t("common:no"),
      }}
    />
  );
}
