import { AmountField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { MenuItem, Stack } from "@mui/material";
import useUpdateFinancialProduct from "api/onboarding/financial-products/updateFinancialProduct";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { FinancialProductApi } from "generated/onboarding/apis";
import { SettlementTypeModel } from "generated/onboarding/models";
import { useTranslation } from "react-i18next";

type UpdateFinancialProductContainerProps = {
  id: number;
  onClose: () => void;
  open: boolean;
};

const settlementTypeLabel = "update_financial_product.settlement_type_label";
const discountPercentageLabel =
  "update_financial_product.discount_percentage_label";

const UpdateFinancialProductContainer = ({
  id,
  onClose,
  open,
}: UpdateFinancialProductContainerProps) => {
  const { t } = useTranslation("onboardings");
  const api = useApi(FinancialProductApi);

  const resolver = useYupResolver<{
    settlementType: SettlementTypeModel;
    discountPercentage: number;
  }>((yup) =>
    yup.object().shape({
      settlementType: yup.string().isRequired(t(settlementTypeLabel)),
      discountPercentage: yup
        .number(t(discountPercentageLabel))
        .nullable()
        .greaterThanAmount(0, t(discountPercentageLabel))
        .notGreaterThanPercentage(100, t(discountPercentageLabel)),
    }),
  );

  const updateFinancialProduct = useUpdateFinancialProduct(id, {
    onSuccess: () => onClose(),
  });

  const handleDefaultvalues = async () => {
    const response = await api.getFinancialProduct({
      financialProductId: id,
    });

    return {
      discountPercentage: response.discountPercentage ?? null,
      settlementType: response.settlementType ?? ("" as SettlementTypeModel),
    };
  };

  const handleUpdateFinancialProductSubmit = (data: {
    settlementType: SettlementTypeModel;
    discountPercentage: number;
  }) => {
    return updateFinancialProduct.execute({
      discountPercentage: data.discountPercentage,
      settlementType: data.settlementType,
    });
  };

  return (
    <FormDialog
      onClose={onClose}
      title={t("update_financial_product.heading")}
      open={open}
      onSubmit={handleUpdateFinancialProductSubmit}
      defaultValues={handleDefaultvalues}
      resolver={resolver}
    >
      {(form) => {
        return (
          <Stack direction="row" gap={2}>
            <ControlledTextField
              select
              control={form.control}
              required
              label={t(settlementTypeLabel)}
              name="settlementType"
              SelectProps={{ displayEmpty: true }}
              error={form.formState.errors.settlementType?.message}
              helperText={form.formState.errors.settlementType?.message}
            >
              <MenuItem value="">
                <i>{t("common:please_select")}</i>
              </MenuItem>
              {Object.values(SettlementTypeModel).map((settlementType) => (
                <MenuItem key={settlementType} value={settlementType}>
                  {settlementType}
                </MenuItem>
              ))}
            </ControlledTextField>
            <AmountField
              name="discountPercentage"
              error={Boolean(form.formState.errors.discountPercentage?.message)}
              helperText={form.formState.errors.discountPercentage?.message}
              label={t(discountPercentageLabel)}
              control={form.control}
            />
          </Stack>
        );
      }}
    </FormDialog>
  );
};

export default UpdateFinancialProductContainer;
