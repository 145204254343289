import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { SecondaryRentalCommandApi } from "generated/core/apis";
import { UpdateSecondaryRentalDetailsModel } from "generated/core/models";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

export const useUpdateSecondaryRentalDetails = (
  secondaryRentalId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(SecondaryRentalCommandApi);
  const swr = useSWRConfig();

  return useCommand(
    async (model: UpdateSecondaryRentalDetailsModel) =>
      api.updateSecondaryRentalDetails({
        secondaryRentalId,
        updateSecondaryRentalDetailsModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.detail(secondaryRentalId));
        options.onSuccess?.();
      },
    },
  );
};
