import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import { AlertDialog } from "@ldms/mui-sdk/templates";
import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import AgreementFinancialPostingsContainer from "apps/servicing/modules/agreements/containers/AgreementFinancialPostingsContainer";
import ModifiableToggle from "common/components/ModifiableToggle";
import { useResponseError } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement, useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import errorHandler from "support/error-handler";
import fileDownloader from "support/file-downloader";

export default function AgreementFinancialPostingsView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const appConfig = useAppConfiguration();
  const agreementApi = useApi(AgreementQueryApi);
  const error = useResponseError();
  const [exportProgress, setExportProgress] = useState(false);
  const onCloseErrorDialog = (): void => error.reset();

  const makeAgreementFinancialPostingsHandler = async (): Promise<void> => {
    try {
      error.reset();
      setExportProgress(true);
      const response = await agreementApi.exportAgreementFinancialPostingsRaw({
        agreementId: agreement.id,
      });
      await fileDownloader(response);
      setExportProgress(false);
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
      setExportProgress(false);
    }
  };

  return (
    <>
      <ViewLayout
        title={t("financial_postings.heading")}
        action={
          <>
            <AccessControl
              allowedPermissions={["servicing:financial-postings:elevated"]}
            >
              <ModifiableToggle>
                <AddButton
                  component={RouterLink}
                  to={`${appConfig.appRoutes.servicing}/agreements/${agreement.id}/financial-postings/add-financial-posting`}
                  role="link"
                  variant="contained"
                />
              </ModifiableToggle>
            </AccessControl>

            <AccessControl
              allowedPermissions={["servicing:financial-postings:manage"]}
            >
              <Button
                onClick={makeAgreementFinancialPostingsHandler}
                startIcon={<Download />}
                disabled={exportProgress}
                variant="outlined"
              >
                {t("financial_postings.export_button")}
              </Button>
            </AccessControl>
          </>
        }
      >
        <ContainedLayout>
          <AgreementFinancialPostingsContainer agreementId={agreement.id} />
        </ContainedLayout>
      </ViewLayout>

      <AlertDialog
        content={error.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(error.message)}
        title={t("financial_postings.error_title")}
      />
    </>
  );
}
