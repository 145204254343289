import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import CreateAutomationContainer from "apps/admin/containers/CreateAutomationContainer/CreateAutomationContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout/ViewLayout";
import { useTranslation } from "react-i18next";
const CreateAutomationView = () => {
  const { t } = useTranslation("automations");

  return (
    <ViewLayout title={t("create_automation_header")}>
      <ContainedLayout>
        <CreateAutomationContainer />
      </ContainedLayout>
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: ["admin:automations:manage"],
})(CreateAutomationView);
