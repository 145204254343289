import { AgreementAssets } from "apps/servicing/modules/agreements/containers";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";

export default function AssetsView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();

  return (
    <ViewLayout title={t("assets.heading")}>
      <ContainedLayout>
        <AgreementAssets agreementId={agreement.id} />
      </ContainedLayout>
    </ViewLayout>
  );
}
