/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClientAgreementListItemModel } from './ClientAgreementListItemModel';
import {
    ClientAgreementListItemModelFromJSON,
    ClientAgreementListItemModelFromJSONTyped,
    ClientAgreementListItemModelToJSON,
} from './ClientAgreementListItemModel';

/**
 * 
 * @export
 * @interface AgreementListModel
 */
export interface AgreementListModel {
    /**
     * The number of agreements for this client
     * @type {number}
     * @memberof AgreementListModel
     */
    numberOfAgreements: number;
    /**
     * The number of assets accross all agreements
     * @type {number}
     * @memberof AgreementListModel
     */
    numberOfAssets: number;
    /**
     * The number of live assets accross all agreements
     * @type {number}
     * @memberof AgreementListModel
     */
    numberOfLiveAssets: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementListModel
     */
    totalBalance: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementListModel
     */
    totalArrears: string;
    /**
     * 
     * @type {Array<ClientAgreementListItemModel>}
     * @memberof AgreementListModel
     */
    agreements: Array<ClientAgreementListItemModel>;
}

/**
 * Check if a given object implements the AgreementListModel interface.
 */
export function instanceOfAgreementListModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "numberOfAgreements" in value;
    isInstance = isInstance && "numberOfAssets" in value;
    isInstance = isInstance && "numberOfLiveAssets" in value;
    isInstance = isInstance && "totalBalance" in value;
    isInstance = isInstance && "totalArrears" in value;
    isInstance = isInstance && "agreements" in value;

    return isInstance;
}

export function AgreementListModelFromJSON(json: any): AgreementListModel {
    return AgreementListModelFromJSONTyped(json, false);
}

export function AgreementListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfAgreements': json['numberOfAgreements'],
        'numberOfAssets': json['numberOfAssets'],
        'numberOfLiveAssets': json['numberOfLiveAssets'],
        'totalBalance': json['totalBalance'],
        'totalArrears': json['totalArrears'],
        'agreements': ((json['agreements'] as Array<any>).map(ClientAgreementListItemModelFromJSON)),
    };
}

export function AgreementListModelToJSON(value?: AgreementListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfAgreements': value.numberOfAgreements,
        'numberOfAssets': value.numberOfAssets,
        'numberOfLiveAssets': value.numberOfLiveAssets,
        'totalBalance': value.totalBalance,
        'totalArrears': value.totalArrears,
        'agreements': ((value.agreements as Array<any>).map(ClientAgreementListItemModelToJSON)),
    };
}

