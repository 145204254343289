import { Step, StepButton, Stepper } from "@mui/material";
import { useTranslation } from "react-i18next";

type AutomationsStepperProps = {
  step: number;
  onStepChange: (step: number) => void;
};

const AutomationsStepper = ({
  step,
  onStepChange,
}: AutomationsStepperProps) => {
  const { t } = useTranslation("automations");
  const steps = [t("stepper.automation_set_up"), t("stepper.action_details")];

  return (
    <Stepper
      aria-label={t("stepper.aria_label")}
      orientation="vertical"
      activeStep={step}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepButton onClick={() => onStepChange(index)}>{label}</StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default AutomationsStepper;
