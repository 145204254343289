/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateAutomationModel,
  UpdateAutomationModel,
} from '../models/index';
import {
    CreateAutomationModelFromJSON,
    CreateAutomationModelToJSON,
    UpdateAutomationModelFromJSON,
    UpdateAutomationModelToJSON,
} from '../models/index';

export interface ActivateAutomationRequest {
    automationId: number;
}

export interface CreateAutomationRequest {
    createAutomationModel: CreateAutomationModel;
    emailTemplate?: Blob;
}

export interface DeactivateAutomationRequest {
    automationId: number;
}

export interface UpdateAutomationRequest {
    automationId: number;
    updateAutomationModel?: UpdateAutomationModel;
}

/**
 * 
 */
export class AutomationCommandApi extends runtime.BaseAPI {

    /**
     * Activate an automation
     */
    async activateAutomationRaw(requestParameters: ActivateAutomationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.automationId === null || requestParameters.automationId === undefined) {
            throw new runtime.RequiredError('automationId','Required parameter requestParameters.automationId was null or undefined when calling activateAutomation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["automations:elevated"]);
        }

        const response = await this.request({
            path: `/automations/{automationId}/activate`.replace(`{${"automationId"}}`, encodeURIComponent(String(requestParameters.automationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate an automation
     */
    async activateAutomation(requestParameters: ActivateAutomationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateAutomationRaw(requestParameters, initOverrides);
    }

    /**
     * Create an automation
     */
    async createAutomationRaw(requestParameters: CreateAutomationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createAutomationModel === null || requestParameters.createAutomationModel === undefined) {
            throw new runtime.RequiredError('createAutomationModel','Required parameter requestParameters.createAutomationModel was null or undefined when calling createAutomation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["automations:manage"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.emailTemplate !== undefined) {
            formParams.append('emailTemplate', requestParameters.emailTemplate as any);
        }

        if (requestParameters.createAutomationModel !== undefined) {
            formParams.append('createAutomationModel', new Blob([JSON.stringify(CreateAutomationModelToJSON(requestParameters.createAutomationModel))], { type: "application/json", }));
                    }

        const response = await this.request({
            path: `/automations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an automation
     */
    async createAutomation(requestParameters: CreateAutomationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAutomationRaw(requestParameters, initOverrides);
    }

    /**
     * Deactivate an automation
     */
    async deactivateAutomationRaw(requestParameters: DeactivateAutomationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.automationId === null || requestParameters.automationId === undefined) {
            throw new runtime.RequiredError('automationId','Required parameter requestParameters.automationId was null or undefined when calling deactivateAutomation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["automations:elevated"]);
        }

        const response = await this.request({
            path: `/automations/{automationId}/deactivate`.replace(`{${"automationId"}}`, encodeURIComponent(String(requestParameters.automationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivate an automation
     */
    async deactivateAutomation(requestParameters: DeactivateAutomationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateAutomationRaw(requestParameters, initOverrides);
    }

    /**
     * Update an Automation
     */
    async updateAutomationRaw(requestParameters: UpdateAutomationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.automationId === null || requestParameters.automationId === undefined) {
            throw new runtime.RequiredError('automationId','Required parameter requestParameters.automationId was null or undefined when calling updateAutomation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["automations:manage"]);
        }

        const response = await this.request({
            path: `/automations/{automationId}`.replace(`{${"automationId"}}`, encodeURIComponent(String(requestParameters.automationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAutomationModelToJSON(requestParameters.updateAutomationModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an Automation
     */
    async updateAutomation(requestParameters: UpdateAutomationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAutomationRaw(requestParameters, initOverrides);
    }

}
