import { QueryKey } from "api";
import { keys } from "api/customers";

export const all = (clientId: number): QueryKey => [
  ...keys.detail(clientId),
  "attachments",
];

export const list = (
  clientId: number,
  query?: {
    pageSize?: number;
    params?: { from?: Date; to?: Date; type?: string; agreement?: string };
  },
): QueryKey => [
  ...all(clientId),
  "list",
  query?.params?.from,
  query?.params?.to,
  query?.params?.type,
  query?.params?.agreement,
];
