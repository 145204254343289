/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActivityTypeModel,
  AgreementListModel,
  BankAccountDetailsModel,
  BankAccountListItemModel,
  ClientActivityModel,
  ClientAssetListItemModel,
  ClientFilterListItemModel,
  ClientFilterModel,
  ClientListItemModel,
  ContactListItemModel,
  ContactTypeListItemModel,
  CorrespondenceMethodListItemModel,
  CorrespondenceMethodTypeListItemModel,
  CustomerOverviewModel,
  DocumentCategoryModel,
  MandatesListItemModel,
  MemoModel,
} from '../models/index';
import {
    ActivityTypeModelFromJSON,
    ActivityTypeModelToJSON,
    AgreementListModelFromJSON,
    AgreementListModelToJSON,
    BankAccountDetailsModelFromJSON,
    BankAccountDetailsModelToJSON,
    BankAccountListItemModelFromJSON,
    BankAccountListItemModelToJSON,
    ClientActivityModelFromJSON,
    ClientActivityModelToJSON,
    ClientAssetListItemModelFromJSON,
    ClientAssetListItemModelToJSON,
    ClientFilterListItemModelFromJSON,
    ClientFilterListItemModelToJSON,
    ClientFilterModelFromJSON,
    ClientFilterModelToJSON,
    ClientListItemModelFromJSON,
    ClientListItemModelToJSON,
    ContactListItemModelFromJSON,
    ContactListItemModelToJSON,
    ContactTypeListItemModelFromJSON,
    ContactTypeListItemModelToJSON,
    CorrespondenceMethodListItemModelFromJSON,
    CorrespondenceMethodListItemModelToJSON,
    CorrespondenceMethodTypeListItemModelFromJSON,
    CorrespondenceMethodTypeListItemModelToJSON,
    CustomerOverviewModelFromJSON,
    CustomerOverviewModelToJSON,
    DocumentCategoryModelFromJSON,
    DocumentCategoryModelToJSON,
    MandatesListItemModelFromJSON,
    MandatesListItemModelToJSON,
    MemoModelFromJSON,
    MemoModelToJSON,
} from '../models/index';

export interface FindClientsRequest {
    offset?: number;
    limit?: number;
    clientFilterModel?: ClientFilterModel;
}

export interface GetBankAccountDetailsRequest {
    clientId: number;
    bankAccountId: number;
}

export interface GetClientRequest {
    clientId: number;
}

export interface ListBankAccountsRequest {
    clientId: number;
}

export interface ListClientActivityRequest {
    clientId: number;
    from?: Date;
    type?: string;
    agreementId?: number;
}

export interface ListClientAgreementsRequest {
    clientId: number;
    live?: boolean;
    query?: string;
}

export interface ListClientAssetsRequest {
    clientId: number;
    live?: boolean;
}

export interface ListClientMemosRequest {
    clientId: number;
    from?: Date;
}

export interface ListClientsRequest {
    offset?: number;
    limit?: number;
    query?: string;
}

export interface ListContactsRequest {
    clientId: number;
}

export interface ListCorrespondenceMethodsRequest {
    clientId: number;
    contactId: number;
    current?: boolean;
}

export interface ListMandatesRequest {
    clientId: number;
    bankAccountId: number;
}

/**
 * 
 */
export class ClientQueryApi extends runtime.BaseAPI {

    /**
     * Finds clients matching a specified criteria
     */
    async findClientsRaw(requestParameters: FindClientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientFilterListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["clients:view"]);
        }

        const response = await this.request({
            path: `/clients/filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientFilterModelToJSON(requestParameters.clientFilterModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientFilterListItemModelFromJSON));
    }

    /**
     * Finds clients matching a specified criteria
     */
    async findClients(requestParameters: FindClientsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientFilterListItemModel>> {
        const response = await this.findClientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the bank account details
     */
    async getBankAccountDetailsRaw(requestParameters: GetBankAccountDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BankAccountDetailsModel>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getBankAccountDetails.');
        }

        if (requestParameters.bankAccountId === null || requestParameters.bankAccountId === undefined) {
            throw new runtime.RequiredError('bankAccountId','Required parameter requestParameters.bankAccountId was null or undefined when calling getBankAccountDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["bank-accounts:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/bank-accounts/{bankAccountId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"bankAccountId"}}`, encodeURIComponent(String(requestParameters.bankAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BankAccountDetailsModelFromJSON(jsonValue));
    }

    /**
     * Get the bank account details
     */
    async getBankAccountDetails(requestParameters: GetBankAccountDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BankAccountDetailsModel> {
        const response = await this.getBankAccountDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Customer details
     */
    async getClientRaw(requestParameters: GetClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerOverviewModel>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling getClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["clients:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerOverviewModelFromJSON(jsonValue));
    }

    /**
     * View Customer details
     */
    async getClient(requestParameters: GetClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerOverviewModel> {
        const response = await this.getClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List activity types available
     */
    async listActivityTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ActivityTypeModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["client-activity-types:view"]);
        }

        const response = await this.request({
            path: `/activity-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityTypeModelFromJSON));
    }

    /**
     * List activity types available
     */
    async listActivityTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ActivityTypeModel>> {
        const response = await this.listActivityTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List Bank Account Details
     */
    async listBankAccountsRaw(requestParameters: ListBankAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BankAccountListItemModel>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listBankAccounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["bank-accounts:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/bank-accounts`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BankAccountListItemModelFromJSON));
    }

    /**
     * List Bank Account Details
     */
    async listBankAccounts(requestParameters: ListBankAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BankAccountListItemModel>> {
        const response = await this.listBankAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Client activity
     */
    async listClientActivityRaw(requestParameters: ListClientActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientActivityModel>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listClientActivity.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.agreementId !== undefined) {
            queryParameters['agreementId'] = requestParameters.agreementId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["client-activity:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/activity`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientActivityModelFromJSON));
    }

    /**
     * View Client activity
     */
    async listClientActivity(requestParameters: ListClientActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientActivityModel>> {
        const response = await this.listClientActivityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Client agreements
     */
    async listClientAgreementsRaw(requestParameters: ListClientAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgreementListModel>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listClientAgreements.');
        }

        const queryParameters: any = {};

        if (requestParameters.live !== undefined) {
            queryParameters['live'] = requestParameters.live;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["client-agreements:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/agreements`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgreementListModelFromJSON(jsonValue));
    }

    /**
     * View Client agreements
     */
    async listClientAgreements(requestParameters: ListClientAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgreementListModel> {
        const response = await this.listClientAgreementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Client assets
     */
    async listClientAssetsRaw(requestParameters: ListClientAssetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientAssetListItemModel>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listClientAssets.');
        }

        const queryParameters: any = {};

        if (requestParameters.live !== undefined) {
            queryParameters['live'] = requestParameters.live;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["client-assets:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/assets`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientAssetListItemModelFromJSON));
    }

    /**
     * View Client assets
     */
    async listClientAssets(requestParameters: ListClientAssetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientAssetListItemModel>> {
        const response = await this.listClientAssetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Client Memos
     */
    async listClientMemosRaw(requestParameters: ListClientMemosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemoModel>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listClientMemos.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["client-memos:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/memos`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemoModelFromJSON));
    }

    /**
     * View Client Memos
     */
    async listClientMemos(requestParameters: ListClientMemosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemoModel>> {
        const response = await this.listClientMemosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Provides an API to list clients in the system
     */
    async listClientsRaw(requestParameters: ListClientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["clients:view"]);
        }

        const response = await this.request({
            path: `/clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientListItemModelFromJSON));
    }

    /**
     * Provides an API to list clients in the system
     */
    async listClients(requestParameters: ListClientsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientListItemModel>> {
        const response = await this.listClientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available contact types
     */
    async listContactTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContactTypeListItemModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["contact-types:view"]);
        }

        const response = await this.request({
            path: `/contact-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactTypeListItemModelFromJSON));
    }

    /**
     * List available contact types
     */
    async listContactTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContactTypeListItemModel>> {
        const response = await this.listContactTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List Client Contact Information
     */
    async listContactsRaw(requestParameters: ListContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContactListItemModel>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listContacts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["contacts:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/contacts`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactListItemModelFromJSON));
    }

    /**
     * List Client Contact Information
     */
    async listContacts(requestParameters: ListContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContactListItemModel>> {
        const response = await this.listContactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available correspondence method types
     */
    async listCorrespondenceMethodTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CorrespondenceMethodTypeListItemModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["correspondence-methods:view"]);
        }

        const response = await this.request({
            path: `/correspondence-method-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CorrespondenceMethodTypeListItemModelFromJSON));
    }

    /**
     * List available correspondence method types
     */
    async listCorrespondenceMethodTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CorrespondenceMethodTypeListItemModel>> {
        const response = await this.listCorrespondenceMethodTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List correspondence methods associated to a contact
     */
    async listCorrespondenceMethodsRaw(requestParameters: ListCorrespondenceMethodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CorrespondenceMethodListItemModel>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listCorrespondenceMethods.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling listCorrespondenceMethods.');
        }

        const queryParameters: any = {};

        if (requestParameters.current !== undefined) {
            queryParameters['current'] = requestParameters.current;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["correspondence-methods:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/contacts/{contactId}/correspondence-methods`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CorrespondenceMethodListItemModelFromJSON));
    }

    /**
     * List correspondence methods associated to a contact
     */
    async listCorrespondenceMethods(requestParameters: ListCorrespondenceMethodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CorrespondenceMethodListItemModel>> {
        const response = await this.listCorrespondenceMethodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available document categories
     */
    async listDocumentCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentCategoryModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["document-categories:view"]);
        }

        const response = await this.request({
            path: `/document-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentCategoryModelFromJSON));
    }

    /**
     * List available document categories
     */
    async listDocumentCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentCategoryModel>> {
        const response = await this.listDocumentCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get mandates associated to a bank account
     */
    async listMandatesRaw(requestParameters: ListMandatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MandatesListItemModel>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listMandates.');
        }

        if (requestParameters.bankAccountId === null || requestParameters.bankAccountId === undefined) {
            throw new runtime.RequiredError('bankAccountId','Required parameter requestParameters.bankAccountId was null or undefined when calling listMandates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["bank-accounts:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/bank-accounts/{bankAccountId}/mandates`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"bankAccountId"}}`, encodeURIComponent(String(requestParameters.bankAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MandatesListItemModelFromJSON));
    }

    /**
     * Get mandates associated to a bank account
     */
    async listMandates(requestParameters: ListMandatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MandatesListItemModel>> {
        const response = await this.listMandatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
