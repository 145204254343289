import { DescriptionList } from "@ldms/mui-sdk/components";
import { Stack, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useGetVatCodeDetails } from "api/vat-codes/getVatCodeDetails";
import AddVatRateContainer from "apps/servicing/modules/settings/containers/AddVatRateContainer";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
} from "common/components";
import Surface from "common/components/Surface";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface VatCodeDetailsContainerProps {
  vatCode: string;
}

export default function VatCodeDetailsContainer({
  vatCode,
}: VatCodeDetailsContainerProps): ReactElement {
  const { t } = useTranslation("servicing");
  const vatCodeDetails = useGetVatCodeDetails(vatCode);
  const locale = useLocale();

  return (
    <Loader
      ready={Boolean(vatCodeDetails.data ?? vatCodeDetails.error)}
      render={(): ReactElement => {
        if (vatCodeDetails.error || !vatCodeDetails.data) {
          return <QueryError onRetry={vatCodeDetails.refetch} />;
        }

        return (
          <Stack gap={3}>
            <Surface title={t("vat_rates.details.current_details_heading")}>
              <DescriptionList
                label={t("vat_rates.details.current_details_heading")}
              >
                <DescriptionList.Item
                  label={t("vat_rates.details.effective_date_label")}
                >
                  {locale.formatDate(vatCodeDetails.data.effectiveDate)}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t("vat_rates.details.vat_rate_label")}
                >
                  {vatCodeDetails.data.currentRate}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t("vat_rates.details.created_by_label")}
                >
                  {vatCodeDetails.data.createdBy}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t("vat_rates.details.created_date_label")}
                >
                  {locale.formatDate(vatCodeDetails.data.createdAt)}
                </DescriptionList.Item>
              </DescriptionList>
            </Surface>

            <Surface
              disableGutters
              title={t("vat_rates.details.vat_rate_history_heading")}
              action={<AddVatRateContainer systemId={vatCode} />}
            >
              <Table aria-label={t("vat_rates.table_name_label")} size="small">
                <LoadableTableHead
                  headings={[
                    t("vat_rates.details.effective_date_label"),
                    t("vat_rates.details.vat_rate_label"),
                    t("vat_rates.details.created_by_label"),
                    t("vat_rates.details.created_date_label"),
                  ]}
                  loading={vatCodeDetails.isValidating}
                />
                <TableBody>
                  {vatCodeDetails.data.rates.map((vatRate, index) => (
                    <TableRow key={index} hover>
                      <TableCell>
                        {locale.formatDate(vatRate.effectiveDate)}
                      </TableCell>
                      <TableCell>{vatRate.rate}</TableCell>
                      <TableCell>{vatRate.createdBy}</TableCell>
                      <TableCell>
                        {locale.formatDate(vatRate.createdAt)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {vatCodeDetails.data.rates.length === 0 && <NoResults />}
            </Surface>
          </Stack>
        );
      }}
    />
  );
}
