/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A batch of documents
 * @export
 * @interface BatchModel
 */
export interface BatchModel {
    /**
     * 
     * @type {string}
     * @memberof BatchModel
     */
    readonly id: string;
    /**
     * The batch reference
     * @type {string}
     * @memberof BatchModel
     */
    readonly reference: string;
    /**
     * A date time format
     * @type {string}
     * @memberof BatchModel
     */
    createdAt: string;
    /**
     * A date time format
     * @type {string}
     * @memberof BatchModel
     */
    downloadedAt?: string;
    /**
     * The operator/username that downloaded the batch
     * @type {string}
     * @memberof BatchModel
     */
    downloadedBy?: string;
    /**
     * A date time format
     * @type {string}
     * @memberof BatchModel
     */
    printedAt?: string;
    /**
     * The operator/username that printed the batch
     * @type {string}
     * @memberof BatchModel
     */
    printedBy?: string;
    /**
     * The batch type
     * @type {string}
     * @memberof BatchModel
     */
    type: string;
    /**
     * The batch size
     * @type {number}
     * @memberof BatchModel
     */
    size: number;
    /**
     * The current status of the batch
     * @type {string}
     * @memberof BatchModel
     */
    status: string;
    /**
     * The portfolio name
     * @type {string}
     * @memberof BatchModel
     */
    portfolioName: string;
}

/**
 * Check if a given object implements the BatchModel interface.
 */
export function instanceOfBatchModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "portfolioName" in value;

    return isInstance;
}

export function BatchModelFromJSON(json: any): BatchModel {
    return BatchModelFromJSONTyped(json, false);
}

export function BatchModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reference': json['reference'],
        'createdAt': json['createdAt'],
        'downloadedAt': !exists(json, 'downloadedAt') ? undefined : json['downloadedAt'],
        'downloadedBy': !exists(json, 'downloadedBy') ? undefined : json['downloadedBy'],
        'printedAt': !exists(json, 'printedAt') ? undefined : json['printedAt'],
        'printedBy': !exists(json, 'printedBy') ? undefined : json['printedBy'],
        'type': json['type'],
        'size': json['size'],
        'status': json['status'],
        'portfolioName': json['portfolioName'],
    };
}

export function BatchModelToJSON(value?: BatchModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'downloadedAt': value.downloadedAt,
        'downloadedBy': value.downloadedBy,
        'printedAt': value.printedAt,
        'printedBy': value.printedBy,
        'type': value.type,
        'size': value.size,
        'status': value.status,
        'portfolioName': value.portfolioName,
    };
}

