import { useQuery } from "@ldms/mui-sdk/cqrs";
import * as agreementKeys from "api/agreements/payees/keys";
import { useApi } from "common/providers/ApiProvider";
import { AgreementPaymentInstructionsApi } from "generated/servicing-v2/apis";

export const useListPayees = (id: string) => {
  const api = useApi(AgreementPaymentInstructionsApi, "v2");
  return useQuery(agreementKeys.list(id), () =>
    api.listPayees({
      id,
    }),
  );
};
