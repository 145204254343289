import { Box, Container, Grid, Paper } from "@mui/material";
import { useGetAutomation } from "api/automations/getAutomation";
import { useUpdateAutomation } from "api/automations/updateAutomation";
import { useListPortfolios } from "api/portfolios/listPortfolios";
import AutomationActionDetailsForm from "apps/admin/components/AutomationActionDetailsForm/AutomationActionDetailsForm";
import AutomationSetUpForm from "apps/admin/components/AutomationSetupForm";
import AutomationsStepper from "apps/admin/components/AutomationStepper/AutomationStepper";
import { AutomationActionDetailsFormFieldValues } from "apps/admin/containers/CreateAutomationContainer/CreateAutomationContainer";
import { Loader, QueryError } from "common/components";
import {
  AutomationParameterModel,
  EventTypeModel,
} from "generated/core/models";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type AutomationFormFieldValues = {
  ruleName: string;
  description: string;
  portfolio: string;
  value: number;
  then: string;
  timeTrigger: string;
  when: EventTypeModel | "";
};

const parameterKeys = {
  timeTrigger: "time_trigger",
  when: "when",
  then: "then",
  to: "to",
  fromAddress: "from.address",
  directDebitPayerFilter: "direct_debit.payer.filter",
  value: "value",
};

const allowedKeys = [
  parameterKeys.timeTrigger,
  parameterKeys.when,
  parameterKeys.then,
  parameterKeys.to,
  parameterKeys.fromAddress,
  parameterKeys.value,
  parameterKeys.directDebitPayerFilter,
];

const mapParameters = (params: AutomationParameterModel[] | undefined) => {
  if (!params) {
    return {};
  }

  const createParamKeyValuePair = (param: AutomationParameterModel) =>
    allowedKeys.includes(param.key) ? { [param.key]: param.value } : null;

  const paramKeyValuesArray = params
    .map(createParamKeyValuePair)
    .filter((pair) => pair !== null);

  return Object.assign({}, ...paramKeyValuesArray);
};

const EditAutomationContainer = ({
  automationId,
}: {
  automationId: number;
}) => {
  const automationDetails = useGetAutomation(automationId);
  const mappedDefaultValues = mapParameters(automationDetails.data?.parameters);
  const listPortfolios = useListPortfolios();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<AutomationFormFieldValues>({
    description: "",
    portfolio: "",
    ruleName: "",
    then: "",
    timeTrigger: "",
    value: 3,
    when: "",
  });

  const onSetUpFormSubmit = (data: AutomationFormFieldValues) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
    setStep(1);
  };

  const updateAutomation = useUpdateAutomation(automationId, {
    onSuccess: () => {
      navigate(`../automations/${automationId}`);
    },
  });

  const handleStepChange = (step: number) => {
    setStep(step);
  };

  const handleBackStep = () => {
    setStep(0);
  };

  const handleActionDetailsSubmit = (
    data: AutomationActionDetailsFormFieldValues,
  ) => {
    updateAutomation.execute({
      name: formData.ruleName,
      description: formData.description,
      portfolioId: Number(formData.portfolio),
      parameters: [
        { key: parameterKeys.value, value: String(formData.value) },
        { key: parameterKeys.then, value: formData.then },
        { key: "correspondence_method", value: "Email" },
        { key: parameterKeys.to, value: data.to },
        { key: parameterKeys.fromAddress, value: data.from },
        { key: parameterKeys.directDebitPayerFilter, value: data.conditions },
        { key: parameterKeys.timeTrigger, value: formData.timeTrigger },
      ],
    });
  };

  const stringOrUndefined = (value: string | undefined) => {
    if (value) {
      return value;
    }
    return "";
  };

  return (
    <Loader
      ready={Boolean(
        (automationDetails.data ?? automationDetails.error) &&
          (listPortfolios.data ?? listPortfolios.error),
      )}
      render={() => {
        if (!listPortfolios.data || listPortfolios.error) {
          return <QueryError onRetry={listPortfolios.refetch} />;
        }

        if (!automationDetails.data || automationDetails.error) {
          return <QueryError onRetry={listPortfolios.refetch} />;
        }

        return (
          <Container sx={{ margin: 0 }}>
            <Grid container>
              <Grid item width={200}>
                <AutomationsStepper
                  step={step}
                  onStepChange={handleStepChange}
                />
              </Grid>
              <Grid item xs>
                <Container maxWidth="sm">
                  <Paper>
                    <Box p={2}>
                      {step === 0 && (
                        <AutomationSetUpForm
                          portfolioList={listPortfolios.data}
                          onSubmit={onSetUpFormSubmit}
                          defaultValues={{
                            description: stringOrUndefined(
                              automationDetails.data.description,
                            ),
                            portfolio:
                              automationDetails.data.portfolioId.toString(),
                            ruleName: automationDetails.data.name,
                            then: stringOrUndefined(mappedDefaultValues.then),
                            timeTrigger: stringOrUndefined(
                              mappedDefaultValues.time_trigger,
                            ),
                            value: Number(mappedDefaultValues.value),
                            when: "Payment Due",
                          }}
                        />
                      )}
                      {step === 1 && (
                        <AutomationActionDetailsForm
                          onBackStep={handleBackStep}
                          onSubmit={handleActionDetailsSubmit}
                          defaultValues={{
                            from: stringOrUndefined(
                              mappedDefaultValues[parameterKeys.fromAddress],
                            ),
                            to: stringOrUndefined(mappedDefaultValues.to),
                            conditions: stringOrUndefined(
                              mappedDefaultValues[
                                parameterKeys.directDebitPayerFilter
                              ],
                            ),
                          }}
                        />
                      )}
                    </Box>
                  </Paper>
                </Container>
              </Grid>
            </Grid>
          </Container>
        );
      }}
    />
  );
};

export default EditAutomationContainer;
