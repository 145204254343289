import { list } from "api/customers/contact-points/keys";
import useQuery, {
  UseQueryOptions,
  UseQueryReturn,
} from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { ClientContactPointQueryApi } from "generated/core/apis";
import { CustomerContactWithContactPointsListItemModel } from "generated/core/models";

type UseListContactPoints = (
  clientId: number,
  type?: string,
  options?: UseQueryOptions,
) => UseQueryReturn<CustomerContactWithContactPointsListItemModel[]>;

export const useListContactPoints: UseListContactPoints = (
  clientId: number,
  type?: string,
  options = {},
) => {
  const api = useApi(ClientContactPointQueryApi);

  return useQuery(
    list(clientId, type),
    () => api.listContactPoints({ clientId, type }),
    options,
  );
};
