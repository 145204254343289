import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ButtonBase, ClickAwayListener, Popover } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useEffect, useRef } from "react";

export interface SplitButtonProps {
  disabled: boolean;
  options: { label: string; onClick: () => Promise<void> }[];
}

export default function SplitButton({ disabled, options }: SplitButtonProps) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const buttonGroupRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setAnchorEl(buttonGroupRef.current);
  }, []);

  return (
    <>
      <ButtonGroup variant="contained">
        <Button
          type="submit"
          onClick={options[0].onClick}
          disabled={disabled}
          ref={buttonGroupRef}
        >
          {options[0].label}
        </Button>
        <Button
          size="small"
          onClick={handleOpen}
          disabled={disabled}
          aria-label="arrow-drop-down"
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem>
              {options.map(
                (option, index) =>
                  index !== 0 && (
                    <ButtonBase
                      key={option.label}
                      type="submit"
                      onClick={option.onClick}
                      disabled={disabled}
                    >
                      <MenuItem key={option.label} onClick={handleClose}>
                        {option.label}
                      </MenuItem>
                    </ButtonBase>
                  ),
              )}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </>
  );
}
