/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventTypeModel } from './EventTypeModel';
import {
    EventTypeModelFromJSON,
    EventTypeModelFromJSONTyped,
    EventTypeModelToJSON,
} from './EventTypeModel';

/**
 * Defines the 'When' event of an automation.
 * E.g. When a payment is due.
 * 
 * @export
 * @interface AutomationEventModel
 */
export interface AutomationEventModel {
    /**
     * 
     * @type {EventTypeModel}
     * @memberof AutomationEventModel
     */
    type: EventTypeModel;
}

/**
 * Check if a given object implements the AutomationEventModel interface.
 */
export function instanceOfAutomationEventModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function AutomationEventModelFromJSON(json: any): AutomationEventModel {
    return AutomationEventModelFromJSONTyped(json, false);
}

export function AutomationEventModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomationEventModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': EventTypeModelFromJSON(json['type']),
    };
}

export function AutomationEventModelToJSON(value?: AutomationEventModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': EventTypeModelToJSON(value.type),
    };
}

