/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing settlement quotes within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApproveSettlementQuoteRequest {
    settlementQuoteId: number;
}

export interface RejectSettlementQuoteRequest {
    settlementQuoteId: number;
}

/**
 * 
 */
export class SettlementQuoteCommandApi extends runtime.BaseAPI {

    /**
     * Approve a settlement quote for the provided settlement quote id
     */
    async approveSettlementQuoteRaw(requestParameters: ApproveSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.settlementQuoteId === null || requestParameters.settlementQuoteId === undefined) {
            throw new runtime.RequiredError('settlementQuoteId','Required parameter requestParameters.settlementQuoteId was null or undefined when calling approveSettlementQuote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["settlement-quotes:elevated"]);
        }

        const response = await this.request({
            path: `/settlement-quotes/{settlementQuoteId}`.replace(`{${"settlementQuoteId"}}`, encodeURIComponent(String(requestParameters.settlementQuoteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve a settlement quote for the provided settlement quote id
     */
    async approveSettlementQuote(requestParameters: ApproveSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approveSettlementQuoteRaw(requestParameters, initOverrides);
    }

    /**
     * Reject a settlement quote
     */
    async rejectSettlementQuoteRaw(requestParameters: RejectSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.settlementQuoteId === null || requestParameters.settlementQuoteId === undefined) {
            throw new runtime.RequiredError('settlementQuoteId','Required parameter requestParameters.settlementQuoteId was null or undefined when calling rejectSettlementQuote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["settlement-quotes:elevated"]);
        }

        const response = await this.request({
            path: `/settlement-quotes/{settlementQuoteId}`.replace(`{${"settlementQuoteId"}}`, encodeURIComponent(String(requestParameters.settlementQuoteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject a settlement quote
     */
    async rejectSettlementQuote(requestParameters: RejectSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rejectSettlementQuoteRaw(requestParameters, initOverrides);
    }

}
