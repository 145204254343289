import { AddButton } from "@ldms/mui-sdk/components";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { Stack, TextField, Typography } from "@mui/material";
import { useCreateRole } from "api/roles/createRole";
import { useResponseError, useYupResolver } from "common/hooks";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface CreateRoleFormValues {
  name: string;
  description: string;
}

const nameLabel = "create_role.name_label";
const descriptionLabel = "create_role.description_label";

export default function CreateRoleContainer(): ReactElement {
  const { t } = useTranslation("admin");

  const resolver = useYupResolver<CreateRoleFormValues>((yup) => {
    return yup.object().shape({
      name: yup
        .string()
        .isRequired(t(nameLabel))
        .maxCharacters(255, t(nameLabel)),
      description: yup
        .string()
        .isRequired(t(descriptionLabel))
        .maxCharacters(255, t(descriptionLabel)),
    });
  });

  const error = useResponseError([
    ["role_exists", t("create_role.role_exists_error_message")],
  ]);

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const createRole = useCreateRole({
    onSuccess: (response) => {
      navigate(`..${response.raw.headers.get("location")}`);
      setOpen(false);
    },
    onError: (response) => {
      error.setError(response.code);
    },
  });

  const onClose = () => {
    setOpen(false);
    error.reset();
  };

  const openCreateRoleDialog = () => setOpen(true);

  const onSubmit = async (data: CreateRoleFormValues): Promise<void> => {
    await createRole.execute(data);
  };

  return (
    <>
      <AddButton variant="contained" onClick={openCreateRoleDialog} />
      <FormDialog
        dialogProps={{
          maxWidth: "xs",
        }}
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        resolver={resolver}
        title={t("create_role.title_label")}
      >
        {(form): ReactElement => (
          <Stack gap={2}>
            <TextField
              {...form.register("name")}
              label={t(nameLabel)}
              required
              error={Boolean(form.formState.errors.name?.message)}
              helperText={form.formState.errors.name?.message}
              disabled={form.formState.isSubmitting}
            />
            <TextField
              {...form.register("description")}
              label={t(descriptionLabel)}
              required
              error={Boolean(form.formState.errors.description?.message)}
              helperText={form.formState.errors.description?.message}
              disabled={form.formState.isSubmitting}
              multiline
              rows={3}
            />

            {error.message && (
              <Typography color="error">{error.message}</Typography>
            )}
          </Stack>
        )}
      </FormDialog>
    </>
  );
}
