import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import AddVatCodeContainer from "apps/servicing/modules/settings/containers/AddVatCodeContainer";
import ListVatCodesContainer from "apps/servicing/modules/settings/containers/ListVatCodesContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

function VatCodesView() {
  const { t } = useTranslation("servicing");

  return (
    <main>
      <ViewLayout
        title={t("vat_codes.vat_codes_heading")}
        action={<AddVatCodeContainer />}
      >
        <ContainedLayout>
          <ListVatCodesContainer />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:vat-codes:manage"],
})(VatCodesView);
