/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TermModel = {
    FixedTerm: 'Fixed Term',
    MinimumTerm: 'Minimum Term'
} as const;
export type TermModel = typeof TermModel[keyof typeof TermModel];


export function TermModelFromJSON(json: any): TermModel {
    return TermModelFromJSONTyped(json, false);
}

export function TermModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermModel {
    return json as TermModel;
}

export function TermModelToJSON(value?: TermModel | null): any {
    return value as any;
}

