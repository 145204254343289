import { Typography } from "@mui/material";
import { AppError, Loader } from "common/components";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";
import { createDisplayName } from "sdk/views/createDisplayName";

const ModifiableAccessView: React.FC<{
  children: React.ReactNode | React.ReactNode[];
}> = ({ children }) => {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  return (
    <Loader
      ready={Boolean(agreement.error ?? agreement.data)}
      render={() => {
        if (!agreement.data || agreement.error) {
          return (
            <Typography color="error" role="alert">
              {t("common:error.default")}
            </Typography>
          );
        }

        if (!agreement.isModifiable) {
          return (
            <AppError
              title={t("write_off.no_write_off_access_title")}
              message={t("write_off.no_write_off_access_message")}
            />
          );
        }

        return <>{children}</>;
      }}
    />
  );
};

export const withModifiableAccess = <P,>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const withModifiableAccess: React.FC<P & JSX.IntrinsicAttributes> = (
    props,
  ) => (
    <ModifiableAccessView>
      <WrappedComponent {...props} />
    </ModifiableAccessView>
  );

  withModifiableAccess.displayName = createDisplayName(
    WrappedComponent,
    withModifiableAccess.name,
  );

  return withModifiableAccess;
};
