/* tslint:disable */
/* eslint-disable */
/**
 * Engage Tasks
 * API for managing tasks
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgreementResultListItemModel } from './AgreementResultListItemModel';
import {
    AgreementResultListItemModelFromJSON,
    AgreementResultListItemModelFromJSONTyped,
    AgreementResultListItemModelToJSON,
} from './AgreementResultListItemModel';
import type { CustomerResultListItemModel } from './CustomerResultListItemModel';
import {
    CustomerResultListItemModelFromJSON,
    CustomerResultListItemModelFromJSONTyped,
    CustomerResultListItemModelToJSON,
} from './CustomerResultListItemModel';

/**
 * 
 * @export
 * @interface CustomersAndAgreementsModel
 */
export interface CustomersAndAgreementsModel {
    /**
     * 
     * @type {Array<CustomerResultListItemModel>}
     * @memberof CustomersAndAgreementsModel
     */
    customers: Array<CustomerResultListItemModel>;
    /**
     * 
     * @type {Array<AgreementResultListItemModel>}
     * @memberof CustomersAndAgreementsModel
     */
    agreements: Array<AgreementResultListItemModel>;
}

/**
 * Check if a given object implements the CustomersAndAgreementsModel interface.
 */
export function instanceOfCustomersAndAgreementsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customers" in value;
    isInstance = isInstance && "agreements" in value;

    return isInstance;
}

export function CustomersAndAgreementsModelFromJSON(json: any): CustomersAndAgreementsModel {
    return CustomersAndAgreementsModelFromJSONTyped(json, false);
}

export function CustomersAndAgreementsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomersAndAgreementsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customers': ((json['customers'] as Array<any>).map(CustomerResultListItemModelFromJSON)),
        'agreements': ((json['agreements'] as Array<any>).map(AgreementResultListItemModelFromJSON)),
    };
}

export function CustomersAndAgreementsModelToJSON(value?: CustomersAndAgreementsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customers': ((value.customers as Array<any>).map(CustomerResultListItemModelToJSON)),
        'agreements': ((value.agreements as Array<any>).map(AgreementResultListItemModelToJSON)),
    };
}

