/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Insurers within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsurerListItemModel
 */
export interface InsurerListItemModel {
    /**
     * Code that represents the insurer
     * @type {string}
     * @memberof InsurerListItemModel
     */
    code: string;
    /**
     * Name of the insurer
     * @type {string}
     * @memberof InsurerListItemModel
     */
    name: string;
}

/**
 * Check if a given object implements the InsurerListItemModel interface.
 */
export function instanceOfInsurerListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function InsurerListItemModelFromJSON(json: any): InsurerListItemModel {
    return InsurerListItemModelFromJSONTyped(json, false);
}

export function InsurerListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsurerListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
    };
}

export function InsurerListItemModelToJSON(value?: InsurerListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
    };
}

