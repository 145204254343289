/* tslint:disable */
/* eslint-disable */
/**
 * Engage Admin
 * API for managing roles within the Engage Admin application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationModel } from './ApplicationModel';
import {
    ApplicationModelFromJSON,
    ApplicationModelFromJSONTyped,
    ApplicationModelToJSON,
} from './ApplicationModel';

/**
 * 
 * @export
 * @interface RoleDetailsModel
 */
export interface RoleDetailsModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof RoleDetailsModel
     */
    id: number;
    /**
     * The name of the role
     * @type {string}
     * @memberof RoleDetailsModel
     */
    name: string;
    /**
     * The description of the role
     * @type {string}
     * @memberof RoleDetailsModel
     */
    description: string;
    /**
     * Total number of users assigned to this role
     * @type {number}
     * @memberof RoleDetailsModel
     */
    usersAssigned: number;
    /**
     * Denotes whether a user can update the role
     * @type {boolean}
     * @memberof RoleDetailsModel
     */
    updatable: boolean;
    /**
     * The role permission's application categories
     * @type {Array<ApplicationModel>}
     * @memberof RoleDetailsModel
     */
    applications?: Array<ApplicationModel>;
}

/**
 * Check if a given object implements the RoleDetailsModel interface.
 */
export function instanceOfRoleDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "usersAssigned" in value;
    isInstance = isInstance && "updatable" in value;

    return isInstance;
}

export function RoleDetailsModelFromJSON(json: any): RoleDetailsModel {
    return RoleDetailsModelFromJSONTyped(json, false);
}

export function RoleDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'usersAssigned': json['usersAssigned'],
        'updatable': json['updatable'],
        'applications': !exists(json, 'applications') ? undefined : ((json['applications'] as Array<any>).map(ApplicationModelFromJSON)),
    };
}

export function RoleDetailsModelToJSON(value?: RoleDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'usersAssigned': value.usersAssigned,
        'updatable': value.updatable,
        'applications': value.applications === undefined ? undefined : ((value.applications as Array<any>).map(ApplicationModelToJSON)),
    };
}

