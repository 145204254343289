import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import { CreateSettlementQuoteModel } from "generated/core/models";
import { useSWRConfig } from "swr";
import { keys } from ".";

export const useCreateSettlementQuote = (
  agreementId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementCommandApi);
  const swr = useSWRConfig();

  return useCommand<CreateSettlementQuoteModel, void, ResponseError>(
    (model) =>
      api.createSettlementQuote({
        agreementId,
        createSettlementQuoteModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.list(agreementId));
        options.onSuccess?.();
      },
    },
  );
};
