import { useFormat } from "@ldms/mui-sdk/formatting";
import { AmountField, Form, NumberField } from "@ldms/mui-sdk/forms";
import { Box, MenuItem, Typography } from "@mui/material";
import {
  FinancialDetailsCashPriceTable,
  OnboardingStepActions,
} from "apps/onboarding/components";
import FinancialDetailsBalanceTable from "apps/onboarding/components/FinancialDetailsBalanceTable";
import { useOnboarding } from "apps/onboarding/providers";
import { TypeOfLeaseEnum } from "apps/onboarding/types";
import { ControlledTextField } from "common/components";
import CashDepositField from "common/components/CashDepositField";
import { useYupResolver } from "common/hooks";
import { useStepper } from "common/providers";
import { FrequencyOfInstalmentsModel } from "generated/onboarding/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface FinanceLeaseFinancialDetailsFormModel {
  balloonPayment?: number;
  cashDeposit?: number;
  typeOfLease: string;
  blindDiscount?: number;
  vatReclaimMonth?: number;
  subsidyPayment?: number;
  partExchange?: number;
  secondaryRentalAmount?: number;
  secondaryRentalFrequency?: FrequencyOfInstalmentsModel | "";
  secondaryRentalIntroducerShare?: number;
  secondaryRentalMaintenenceCharge?: number;
  secondaryRentalInsurancePremium?: number;
}

const balloonPaymentLabel = "finance_lease.balloon_payment_label";
const cashDepositLabel = "finance_lease.cash_deposit_label";
const blindDiscountLabel = "finance_lease.blind_discount_label";
const vatReclaimMonthLabel = "finance_lease.vat_reclaim_month_label";
const subsidyPaymentLabel = "finance_lease.subsidy_payment_label";
const partExchangeLabel = "finance_lease.part_exchange_label";
const secondaryRentalAmountLabel =
  "finance_lease.secondary_rental_amount_label";
const secondaryRentalFrequencyLabel =
  "finance_lease.secondary_rental_frequency_label";
const secondaryRentalIntroducerShareLabel =
  "finance_lease.secondary_rental_introducer_share_label";
const secondaryRentalMaintenenceChargeLabel =
  "finance_lease.secondary_rental_maintenence_charge_label";
const secondaryRentalInsurancePremiumLabel =
  "finance_lease.secondary_rental_insurance_premium_label";
const greaterThanZero = "common:validation.is_greater_than_zero";

const transformField = (v: string, o: string): string | null | undefined => {
  return o === "" ? undefined : v;
};

const getValue = (amount?: number): number => {
  return amount ? Number(amount) : 0;
};

export default function FinanceLeaseFinancialDetails(): ReactElement {
  const { t } = useTranslation("onboardings");
  const onboarding = useOnboarding();
  const stepper = useStepper();
  const { formatAmount } = useFormat();

  const totalNetCashPrice =
    onboarding.state.assetDetails?.totalNetCashPrice ?? 0;
  const totalVat = onboarding.state.assetDetails?.totalVat ?? 0;
  const totalCashPriceIncVat = totalNetCashPrice + totalVat;

  const defaultValues = onboarding.state
    .financialDetails as FinanceLeaseFinancialDetailsFormModel;

  const isFundedVat = onboarding.state.agreementDetails?.isFundedVat;

  const resolver = useYupResolver<FinanceLeaseFinancialDetailsFormModel>(
    (yup) => {
      return yup.object().shape({
        vatReclaimMonth: yup
          .number(t(vatReclaimMonthLabel))
          .transform(transformField)
          .between(
            1,
            13,
            t("form_validation.vat_reclaim_month_must_be_between", {
              label: t(vatReclaimMonthLabel),
            }),
          )
          .test({
            name: "requiredWhenVatReclaim",
            test: (vatReclaimValue, context) => {
              if (!vatReclaimValue && isFundedVat) {
                return context.createError({
                  path: context.path,
                  message: t(
                    "form_validation.vat_reclaim_is_required_when_funding_vat",
                    {
                      label: t(vatReclaimMonthLabel),
                    },
                  ),
                });
              }
              return true;
            },
          }),
        cashDeposit: yup
          .number(t(cashDepositLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(cashDepositLabel))
          .maxAmount(99999999.99, t(cashDepositLabel)),
        blindDiscount: yup
          .number(t(blindDiscountLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(blindDiscountLabel))
          .maxAmount(999999.99, t(blindDiscountLabel)),
        typeOfLease: yup
          .string()
          .isRequired(t("finance_lease.type_of_lease_label")),
        balloonPayment: yup
          .number(t(balloonPaymentLabel))
          .transform(transformField)
          .minAmount(0, t(balloonPaymentLabel))
          .maxAmount(99999999.99, t(balloonPaymentLabel)),
        subsidyPayment: yup
          .number(t(subsidyPaymentLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(subsidyPaymentLabel))
          .maxAmount(99999999.99, t(subsidyPaymentLabel)),
        partExchange: yup
          .number(t(partExchangeLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(partExchangeLabel))
          .maxAmount(99999999.99, t(partExchangeLabel)),
        secondaryRentalAmount: yup
          .number(t(secondaryRentalAmountLabel))
          .when("typeOfLease", {
            is: TypeOfLeaseEnum.MinimumTerm,
            then: yup
              .number(t(secondaryRentalAmountLabel))
              .transform(transformField)
              .moreThan(
                0,
                t(greaterThanZero, {
                  label: t(secondaryRentalAmountLabel),
                }),
              )
              .isRequired(t(secondaryRentalAmountLabel))
              .maxAmount(99999999.99, t(secondaryRentalAmountLabel)),
          }),
        secondaryRentalFrequency: yup.string().when("typeOfLease", {
          is: TypeOfLeaseEnum.MinimumTerm,
          then: yup
            .string()
            .transform(transformField)
            .isRequired(t(secondaryRentalFrequencyLabel)),
        }),
        secondaryRentalIntroducerShare: yup
          .number(t(secondaryRentalIntroducerShareLabel))
          .transform(transformField)
          .minAmount(0, t(secondaryRentalIntroducerShareLabel))
          .maxAmount(100, t(secondaryRentalIntroducerShareLabel)),
        secondaryRentalInsurancePremium: yup
          .number(t(secondaryRentalInsurancePremiumLabel))
          .transform(transformField)
          .nullable()
          .moreThan(
            0,
            t(greaterThanZero, {
              label: t(secondaryRentalInsurancePremiumLabel),
            }),
          )
          .maxAmount(99999999.99, t(secondaryRentalInsurancePremiumLabel)),
        secondaryRentalMaintenenceCharge: yup
          .number(t(secondaryRentalMaintenenceChargeLabel))
          .transform(transformField)
          .nullable()
          .moreThan(
            0,
            t(greaterThanZero, {
              label: t(secondaryRentalMaintenenceChargeLabel),
            }),
          )
          .maxAmount(99999999.99, t(secondaryRentalMaintenenceChargeLabel)),
      });
    },
  );

  const onSubmit = (data: FinanceLeaseFinancialDetailsFormModel): void => {
    onboarding.submitFinancialDetails(data);
    stepper.next();
  };

  return (
    <>
      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h1" }}>
          {t("financial_details.title")}
        </Typography>
      </Box>

      <Form
        label={t("financial_details.title")}
        defaultValues={{
          ...defaultValues,
          typeOfLease: defaultValues?.typeOfLease ?? "",
          secondaryRentalFrequency:
            defaultValues?.secondaryRentalFrequency ?? "",
        }}
        resolver={resolver}
        onSubmit={onSubmit}
        options={{ shouldUnregister: true }}
      >
        {(form) => {
          const formWatch = form.watch();
          const totalDeposit =
            getValue(formWatch.cashDeposit) +
            getValue(formWatch.blindDiscount) +
            getValue(formWatch.subsidyPayment) +
            getValue(formWatch.partExchange);

          const fundedVat = isFundedVat ? totalVat : 0;
          const balanceFinanced = totalNetCashPrice + fundedVat - totalDeposit;

          return (
            <>
              <FinancialDetailsCashPriceTable
                testId="financialLease.table"
                totalCashPriceIncVat={{
                  amount: formatAmount(totalCashPriceIncVat),
                  label: "finance_lease.total_cash_price_inc_vat_label",
                }}
                totalNetCashPrice={{
                  amount: formatAmount(totalNetCashPrice.toFixed(2)),
                  label: "finance_lease.total_net_cash_price_label",
                }}
                totalVat={{
                  amount: formatAmount(totalVat.toFixed(2)),
                  label: "finance_lease.vat_on_cash_price_label",
                }}
              />
              <NumberField
                control={form.control}
                error={Boolean(form.formState.errors?.vatReclaimMonth?.message)}
                helperText={form.formState.errors?.vatReclaimMonth?.message}
                label={t(vatReclaimMonthLabel)}
                name="vatReclaimMonth"
                defaultValue={defaultValues?.vatReclaimMonth}
              />
              <CashDepositField
                error={Boolean(form.formState.errors.cashDeposit?.message)}
                control={form.control}
                helperText={form.formState.errors.cashDeposit?.message}
              />
              <AmountField
                control={form.control}
                label={t(partExchangeLabel)}
                name="partExchange"
                error={Boolean(form.formState.errors.partExchange?.message)}
                helperText={form.formState.errors.partExchange?.message}
                defaultValue={defaultValues?.partExchange}
              />
              <AmountField
                control={form.control}
                label={t(balloonPaymentLabel)}
                name="balloonPayment"
                error={Boolean(form.formState.errors.balloonPayment?.message)}
                helperText={form.formState.errors.balloonPayment?.message}
                defaultValue={defaultValues?.balloonPayment}
              />
              <AmountField
                control={form.control}
                label={t(blindDiscountLabel)}
                name="blindDiscount"
                error={Boolean(form.formState.errors.blindDiscount?.message)}
                helperText={form.formState.errors.blindDiscount?.message}
                defaultValue={defaultValues?.blindDiscount}
              />

              <ControlledTextField
                helperText={form.formState.errors?.typeOfLease?.message}
                SelectProps={{ displayEmpty: true }}
                error={form.formState.errors?.typeOfLease?.message}
                control={form.control}
                label={t("finance_lease.type_of_lease_label")}
                id="typeOfLease"
                name="typeOfLease"
                select
                required
              >
                <MenuItem value="">{t("common:please_select")}</MenuItem>
                {Object.values(TypeOfLeaseEnum).map((currentValue) => (
                  <MenuItem
                    value={String(currentValue)}
                    key={String(currentValue)}
                  >
                    {t(currentValue)}
                  </MenuItem>
                ))}
              </ControlledTextField>

              <>
                {formWatch.typeOfLease === TypeOfLeaseEnum.MinimumTerm && (
                  <>
                    <Box marginY={3}>
                      <Typography variant="h4" variantMapping={{ h4: "h1" }}>
                        {t("financial_details.secondary_rentals_heading")}
                      </Typography>
                    </Box>
                    <AmountField
                      control={form.control}
                      name="secondaryRentalAmount"
                      label={t(secondaryRentalAmountLabel)}
                      helperText={
                        form.formState.errors.secondaryRentalAmount?.message
                      }
                      error={Boolean(
                        form.formState.errors.secondaryRentalAmount?.message,
                      )}
                      required
                    />
                    <ControlledTextField
                      helperText={
                        form.formState.errors?.secondaryRentalFrequency?.message
                      }
                      SelectProps={{ displayEmpty: true }}
                      error={
                        form.formState.errors?.secondaryRentalFrequency?.message
                      }
                      control={form.control}
                      name="secondaryRentalFrequency"
                      id="secondaryRentalFrequency"
                      label={t(secondaryRentalFrequencyLabel)}
                      select
                      required
                    >
                      <MenuItem value="">{t("common:please_select")}</MenuItem>
                      {Object.values(FrequencyOfInstalmentsModel).map(
                        (value) => (
                          <MenuItem value={String(value)} key={String(value)}>
                            {value}
                          </MenuItem>
                        ),
                      )}
                    </ControlledTextField>
                    <NumberField
                      control={form.control}
                      name="secondaryRentalIntroducerShare"
                      label={t(secondaryRentalIntroducerShareLabel)}
                      helperText={
                        form.formState.errors.secondaryRentalIntroducerShare
                          ?.message
                      }
                      error={Boolean(
                        form.formState.errors.secondaryRentalIntroducerShare
                          ?.message,
                      )}
                    />
                    <AmountField
                      name="secondaryRentalMaintenenceCharge"
                      label={t(secondaryRentalMaintenenceChargeLabel)}
                      control={form.control}
                      helperText={
                        form.formState.errors.secondaryRentalMaintenenceCharge
                          ?.message
                      }
                      error={Boolean(
                        form.formState.errors.secondaryRentalMaintenenceCharge
                          ?.message,
                      )}
                    />
                    <AmountField
                      name="secondaryRentalInsurancePremium"
                      label={t(secondaryRentalInsurancePremiumLabel)}
                      control={form.control}
                      helperText={
                        form.formState.errors.secondaryRentalInsurancePremium
                          ?.message
                      }
                      error={Boolean(
                        form.formState.errors.secondaryRentalInsurancePremium
                          ?.message,
                      )}
                    />
                  </>
                )}
              </>

              <FinancialDetailsBalanceTable
                totalDeposit={{
                  label: "finance_lease.total_deposit_label",
                  amount: formatAmount(totalDeposit),
                }}
                balanceFinanced={{
                  label: "finance_lease.balance_financed_label",
                  amount: formatAmount(balanceFinanced),
                }}
                manufacturerSubsidy={{
                  label: "finance_lease.total_manufacturer_subsidy_label",
                  amount: formatAmount(defaultValues?.subsidyPayment),
                }}
              />

              <OnboardingStepActions
                back={{
                  label: t("common:stepper.back_button"),
                  onBack: stepper.previous,
                }}
                next={{ label: t("common:stepper.next_button") }}
              />
            </>
          );
        }}
      </Form>
    </>
  );
}
