import { useFormat } from "@ldms/mui-sdk/formatting";
import { Loader } from "@ldms/mui-sdk/templates";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import {
  OnboardingAssetDialog,
  OnboardingAssetList,
  OnboardingStepActions,
} from "apps/onboarding/components";
import OnboardingAssetConfirmationDialog from "apps/onboarding/components/OnboardingAssetConfirmationDialog";
import { useOnboarding } from "apps/onboarding/providers";
import { KeyValueRow, KeyValueTable, QueryError } from "common/components";
import { useConfirm } from "common/hooks";
import { useApi, useStepper } from "common/providers";
import { AssetQueryApi } from "generated/core/apis";
import { AssetTypeModel } from "generated/core/models";
import { ProductTypeModel } from "generated/onboarding/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

function getAssetList(
  assets: OnboardingAssetFormModel[],
  assetTypes: AssetTypeModel[],
): OnboardingAssetFormModel[] {
  return assets.map((item) => {
    const type =
      assetTypes.find((assetType: AssetTypeModel) => {
        return assetType.code === item.typeCode;
      })?.type ?? "";
    return {
      ...item,
      typeCode: type,
    };
  });
}

export interface OnboardingAssetFormModel {
  typeCode: string;
  description: string;
  manufacturerDescription?: string;
  newOrUsed: string;
  model?: string;
  registrationNumber?: string;
  registrationDate?: Date;
  yearOfManufacture?: number;
  serialNumber?: string;
  vehicleIdentificationNumber?: string;
  costPriceExVat: number;
  vat: number;
  hpiRegisteredDate?: Date;
  includeInsurance: boolean;
  assetCategory?: string;
  assetBanding?: string;
  fleetDiscount?: number;
  serviceProviderId: { label?: string; value?: number } | null;
  manufacturerSubsidy?: number;
  schemeCode?: string;
  vatCode?: string;
}

export interface AssetDetailsFormModel {
  assets: OnboardingAssetFormModel[];
}

type Dialog = "Add" | "Update";

interface AssetDetailsStepProps {
  initialActiveStep?: string;
}

export default function AssetDetailsStep({
  initialActiveStep,
}: AssetDetailsStepProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const { formatAmount } = useFormat();
  const stepper = useStepper();
  const [isError, setError] = useState(false);
  const assetApi = useApi(AssetQueryApi);
  const assetTypes = useSWR(["/assets/types"], () => assetApi.listAssetTypes());
  const onboarding = useOnboarding();
  const product = onboarding.state.agreementDetails
    ?.productType as ProductTypeModel;
  const [assetList, setAssetList] = useState<OnboardingAssetFormModel[]>(
    onboarding.state.assetDetails?.assets.slice() ?? [],
  );
  const [activeDialog, setActiveDialog] = useState<Dialog | undefined>();
  const [selectedAssetId, setSelectedAssetId] = useState<number | undefined>(
    undefined,
  );
  const confirm = useConfirm();

  const onSubmit = (): void => {
    if (assetList.length === 0) {
      setError(true);
    } else {
      confirm.handlePrompt((): void => {
        onboarding.submitAssetDetails(assetList);
        stepper.next();
      });
    }
  };

  const handleAddAssetDialogOpen = (index?: number): void => {
    setSelectedAssetId(index);
    setActiveDialog("Add");
  };

  const handleUpdateAssetDialogOpen = (index: number): void => {
    setSelectedAssetId(index);
    setActiveDialog("Update");
  };

  const handleDialogClose = (): void => {
    setActiveDialog(undefined);
    setSelectedAssetId(undefined);
  };

  const handleAddAssetSubmit = (assetModel: OnboardingAssetFormModel): void => {
    setAssetList([...assetList, assetModel]);
    handleDialogClose();
    setError(false);
  };

  const makeUpdateAssetSubmit = (index: number) => {
    return (assetModel: OnboardingAssetFormModel): void => {
      const temp = [...assetList];
      temp[index] = assetModel;
      setAssetList(temp);
      handleDialogClose();
    };
  };

  const handleRemoveAssetSubmit = (index: number): void => {
    setAssetList((current) => current.filter((_, i) => i !== index));
  };

  const totalCostPrice = assetList.reduce(
    (partialSum, asset) => partialSum + asset.costPriceExVat,
    0,
  );
  const totalVat = assetList.reduce(
    (partialSum, asset) => partialSum + asset.vat,
    0,
  );

  const totalFleetDiscount = assetList.reduce(
    (partialSum, asset) =>
      partialSum + (asset.fleetDiscount ? asset.fleetDiscount : 0),
    0,
  );

  const totalSubsidyAmount = assetList.reduce(
    (partialSum, asset) => partialSum + (asset.manufacturerSubsidy ?? 0),
    0,
  );

  const totalCostPriceIncVat = totalCostPrice + (totalVat || 0);

  const assetTypeList = assetTypes.data ?? [];

  return (
    <>
      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h1" }}>
          {t("asset_details.title")}
        </Typography>
      </Box>
      <Loader
        ready={Boolean(assetTypes.data ?? assetTypes.error)}
        render={(): ReactElement => {
          if (assetTypes.error ?? !assetTypes.data) {
            return <QueryError onRetry={() => assetTypes.mutate()} />;
          }

          return (
            <>
              <OnboardingAssetDialog
                assetTypes={assetTypeList}
                labels={{
                  title: t("add_asset.title"),
                  submitButton: t("add_asset.add_asset_button"),
                }}
                defaultValues={
                  typeof selectedAssetId !== "undefined"
                    ? assetList[selectedAssetId]
                    : undefined
                }
                open={activeDialog === "Add"}
                onSubmit={handleAddAssetSubmit}
                onClose={handleDialogClose}
                isFundedVat={onboarding.state.agreementDetails?.isFundedVat}
                product={product}
              />

              <OnboardingAssetConfirmationDialog
                numberOfAssets={assetList.length}
                open={confirm.isOpen}
                onCancel={confirm.handleReject}
                onConfirm={confirm.handleConfirm}
              />

              {selectedAssetId !== undefined && (
                <OnboardingAssetDialog
                  assetTypes={assetTypeList}
                  labels={{
                    title: t("update_asset.title"),
                    submitButton: t("update_asset.update_asset_button"),
                  }}
                  defaultValues={assetList[selectedAssetId]}
                  open={activeDialog === "Update"}
                  onSubmit={makeUpdateAssetSubmit(selectedAssetId)}
                  onClose={handleDialogClose}
                  product={product}
                />
              )}

              <Box
                alignItems="center"
                component="header"
                display="flex"
                paddingY={2}
              >
                <Box flex={1}>
                  <Typography variant="h6">
                    {t("asset_details.assets_label")}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    aria-label={t("asset_details.add_asset_button")}
                    color="primary"
                    variant="contained"
                    onClick={() => handleAddAssetDialogOpen()}
                  >
                    {t("asset_details.add_asset_button")}
                  </Button>
                </Box>
              </Box>

              <Paper>
                <OnboardingAssetList
                  data={getAssetList(assetList, assetTypeList)}
                  onClone={handleAddAssetDialogOpen}
                  onUpdate={handleUpdateAssetDialogOpen}
                  onRemove={handleRemoveAssetSubmit}
                  product={product}
                />
              </Paper>

              {isError && (
                <Box marginTop={2}>
                  <Alert severity="error" icon={false}>
                    {t("asset_details.no_assets_error")}
                  </Alert>
                </Box>
              )}

              <Box marginY={3}>
                <Divider />
              </Box>

              <Grid container spacing={4} justifyContent="flex-end">
                <Grid item>
                  <Typography variant="h6">
                    {t("asset_details.assets_to_be_financed_title")}
                  </Typography>
                </Grid>
                <Grid item>
                  <KeyValueTable testId="assetsToBeFinanced.table">
                    <KeyValueRow
                      align="right"
                      label={t("asset_details.total_cash_price_ex_vat_label")}
                    >
                      {formatAmount(totalCostPrice)}
                    </KeyValueRow>
                    <KeyValueRow
                      align="right"
                      label={t("asset_details.total_vat_label")}
                    >
                      {formatAmount(totalVat)}
                    </KeyValueRow>
                    <>
                      {product === ProductTypeModel.FixedRateHirePurchase && (
                        <KeyValueRow
                          align="right"
                          label={t("asset_details.total_fleet_discount_label")}
                        >
                          {formatAmount(totalFleetDiscount)}
                        </KeyValueRow>
                      )}
                      {(product === ProductTypeModel.FixedRateHirePurchase ||
                        product === ProductTypeModel.FixedRateFinanceLease) && (
                        <KeyValueRow
                          align="right"
                          label={t(
                            "asset_details.total_manufacturer_subsidy_label",
                          )}
                        >
                          {formatAmount(totalSubsidyAmount)}
                        </KeyValueRow>
                      )}
                    </>
                    <KeyValueRow
                      align="right"
                      label={t("asset_details.total_cash_price_inc_vat_label")}
                    >
                      {formatAmount(totalCostPriceIncVat)}
                    </KeyValueRow>
                  </KeyValueTable>
                </Grid>
              </Grid>
            </>
          );
        }}
      />
      <OnboardingStepActions
        back={
          initialActiveStep !== "/asset-details"
            ? {
                label: t("common:stepper.back_button"),
                onBack: stepper.previous,
              }
            : undefined
        }
        next={{ label: t("common:stepper.next_button"), onNext: onSubmit }}
      />
    </>
  );
}
