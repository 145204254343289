import { DateField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Box,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useListFeesUnpaid } from "api/agreements/transactions/fees-unpaid";
import { usePayFees } from "api/agreements/transactions/pay-fees";
import { NoResults, QueryError } from "common/components";
import { useLocale, useYupResolver } from "common/hooks";
import { useAgreement } from "common/providers";
import { FeeUnpaidListItemModel } from "generated/core/models";
import React from "react";
import { Controller, Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface MarkFeesAsPaidContainerProps {
  open: boolean;
  onSuccess: () => void;
}

interface PayFeesFormValues {
  transactions: number[];
  paidDate: Date;
}

const usePayFeesResolver = (): Resolver<PayFeesFormValues> => {
  const { t } = useTranslation("agreements");
  const dateLabel = t("transactions.mark_fees_as_paid.paid_date_label");

  return useYupResolver<PayFeesFormValues>((yup) =>
    yup.object().shape({
      paidDate: yup
        .date()
        .localDate()
        .isValidDate(dateLabel)
        .isRequired(dateLabel)
        .isNotFuture(dateLabel),
      transactions: yup.array().min(1),
    }),
  );
};

export default function MarkFeesAsPaidContainer({
  open,
  onSuccess,
}: MarkFeesAsPaidContainerProps): React.ReactElement {
  const { t } = useTranslation("agreements");
  const resolver = usePayFeesResolver();

  const agreement = useAgreement();
  const listUnpaidFees = useListFeesUnpaid(agreement.id, { enabled: open });
  const payFees = usePayFees(agreement.id, { onSuccess });

  const locale = useLocale();

  const tableHeaders: string[] = [
    t("transactions.mark_fees_as_paid.date_heading"),
    t("transactions.mark_fees_as_paid.amount_heading"),
    t("transactions.mark_fees_as_paid.description_heading"),
  ];

  const handleSubmit = (values: PayFeesFormValues): Promise<void> => {
    return payFees.command(values);
  };

  const onClose = (): void => {
    payFees.resetError();
    onSuccess();
  };

  return (
    <FormDialog
      title={t("transactions.mark_fees_as_paid.heading_label")}
      onSubmit={handleSubmit}
      onClose={onClose}
      open={open}
      resolver={resolver}
      dialogProps={{ scroll: "paper" }}
      ready={Boolean(listUnpaidFees.data ?? listUnpaidFees.error)}
      defaultValues={{ transactions: [] }}
      disabled={listUnpaidFees.data?.length === 0 || payFees.isLoading}
    >
      {(form) => {
        if (listUnpaidFees.error || !listUnpaidFees.data) {
          return <QueryError onRetry={listUnpaidFees.refetch} />;
        }

        const transactionIds = form.watch("transactions");
        const rowCount = listUnpaidFees.data.length;
        const numSelected = transactionIds.length;

        const handleCheck = (checkedId: number) => {
          if (transactionIds.includes(checkedId)) {
            return transactionIds.filter(
              (transactionId) => transactionId !== checkedId,
            );
          }
          return [...transactionIds, checkedId];
        };

        const handleCheckAll = (unpaidFees: FeeUnpaidListItemModel[]) => () => {
          if (numSelected < unpaidFees.length) {
            return form.resetField("transactions", {
              defaultValue: unpaidFees.map(
                ({ transactionId }) => transactionId,
              ),
            });
          }
          return form.resetField("transactions", { defaultValue: [] });
        };

        return (
          <>
            <Stack gap={2}>
              <Box maxHeight={350} overflow="auto">
                <Table
                  size="small"
                  stickyHeader
                  aria-label={t(
                    "transactions.mark_fees_as_paid.table_name_label",
                  )}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={
                            numSelected > 0 && numSelected < rowCount
                          }
                          checked={rowCount === numSelected && rowCount !== 0}
                          disabled={rowCount === 0}
                          onChange={handleCheckAll(listUnpaidFees.data)}
                          inputProps={{
                            "aria-label": t(
                              "transactions.mark_fees_as_paid.mark_all_checkbox",
                            ),
                          }}
                        />
                      </TableCell>
                      {tableHeaders.map((label) => (
                        <TableCell
                          key={label}
                          size="small"
                          sx={{ width: "160px" }}
                        >
                          {label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {listUnpaidFees.data.map((fee, index) => (
                      <TableRow hover key={fee.transactionId}>
                        <TableCell padding="checkbox" width="10%">
                          <Controller
                            name="transactions"
                            control={form.control}
                            render={({ field }) => (
                              <Checkbox
                                onChange={() =>
                                  field.onChange(handleCheck(fee.transactionId))
                                }
                                inputRef={field.ref}
                                name={field.name}
                                checked={transactionIds.includes(
                                  fee.transactionId,
                                )}
                                inputProps={{
                                  "aria-label": `transactions.mark_fees_as_paid.checkbox.${index}`,
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell width="20%">
                          {locale.formatDate(fee.dueDate)}
                        </TableCell>
                        <TableCell width="20%">{fee.amount}</TableCell>
                        <TableCell width="50%">{fee.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {listUnpaidFees.data.length === 0 && <NoResults />}
                {form.formState.errors.transactions && (
                  <Typography
                    color="error"
                    variant="body2"
                    sx={{ marginTop: 1 }}
                  >
                    {/*Cannot access FieldError as its being typed incorrectly as a FieldError[]:
                     https://github.com/react-hook-form/react-hook-form/issues/7711 */}
                    {t("transactions.mark_fees_as_paid.valid_checkbox")}
                  </Typography>
                )}
              </Box>
              <DateField
                required
                error={Boolean(form.formState.errors.paidDate)}
                name="paidDate"
                label={t("transactions.mark_fees_as_paid.paid_date_label")}
                helperText={form.formState.errors.paidDate?.message}
                control={form.control}
                disabled={rowCount === 0}
              />

              {payFees.error && (
                <Typography color="error">{payFees.error?.message}</Typography>
              )}
            </Stack>
          </>
        );
      }}
    </FormDialog>
  );
}
