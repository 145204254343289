import { useEngageConfig } from "@ldms/mui-sdk/bootstrap";
import { DateField } from "@ldms/mui-sdk/forms";
import { Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  LinearProgress,
  Link,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { blue, green, orange, red } from "@mui/material/colors";
import { useListTasks } from "api/tasks/listTasks";
import { AssignedToFilter } from "apps/start/components/filters/AssignedToFilter";
import { AssigneeOptions } from "apps/start/components/filters/AssignedToFilter/AssignedToFilter";
import { CustomerAndAgreementsFilter } from "apps/start/components/filters/CustomerAndAgreementsFilter";
import { CustomerAndAgreementsFilterProps } from "apps/start/components/filters/CustomerAndAgreementsFilter/CustomerAndAgreementsFilter";
import { CreateTaskContainer } from "common/containers/CreateTaskContainer/CreateTaskContainer";
import { ViewTaskContainer } from "common/containers/ViewTaskContainer/ViewTaskContainer";
import { useLocale } from "common/hooks";
import ListLayout from "common/layouts/ListLayout";
import { ListTasksRequest } from "generated/tasks/apis";
import { TaskStatusModel } from "generated/tasks/models";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface ListTaskFilterProps {
  assigneeId?: AssigneeOptions;
  from?: Date | null;
  to?: Date | null;
  customerAndAgreement: CustomerAndAgreementsFilterProps | null;
}

interface TasksFilterProps {
  filters: ListTaskFilterProps;
  onChange: (filters: ListTaskFilterProps) => void;
  labels: {
    assigneeId: string;
    from: string;
    to: string;
    customersAndAgreements: string;
  };
}

const TasksFilter = ({ filters, onChange, labels }: TasksFilterProps) => {
  const { t } = useTranslation(["start"]);

  const handleAssignToChange = (newValue: AssigneeOptions) => {
    return onChange({
      ...filters,
      assigneeId: {
        id: newValue.id,
        name: newValue.name,
      },
    });
  };

  const handleCustomerAndAgreementschange = (
    newValue: CustomerAndAgreementsFilterProps | null,
  ) => {
    return onChange({ ...filters, customerAndAgreement: newValue });
  };

  const handleFromDateChange = (newValue: Date | null) => {
    return onChange({ ...filters, from: newValue });
  };

  const handleToDateChange = (newValue: Date | null) => {
    return onChange({ ...filters, to: newValue });
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <AssignedToFilter
          onChange={handleAssignToChange}
          value={
            filters.assigneeId?.id === "All"
              ? { name: t("assignee_filter_list.all"), id: "All" }
              : filters.assigneeId
          }
          label={labels.assigneeId}
        />
      </Grid>
      <Grid item>
        <DateField
          name="fromDate"
          label={t(labels.from)}
          margin="none"
          onChange={handleFromDateChange}
          value={filters.from}
          id="from-date"
        />
      </Grid>
      <Grid item>
        <DateField
          name="toDate"
          label={t(labels.to)}
          margin="none"
          onChange={handleToDateChange}
          value={filters.to}
          id="to-date"
        />
      </Grid>
      <Grid item>
        <CustomerAndAgreementsFilter
          onChange={handleCustomerAndAgreementschange}
          value={filters.customerAndAgreement}
          label={labels.customersAndAgreements}
        />
      </Grid>
    </Grid>
  );
};

const mapFiltersToParams = (filters: ListTaskFilterProps) => {
  const { customerAndAgreement, assigneeId, from, to } = filters;

  const params: ListTasksRequest = {
    agreementId: undefined,
    customerId: undefined,
    assigneeId: filters.assigneeId?.id,
    from: undefined,
    to: undefined,
  };

  if (assigneeId?.id === "All") {
    params.assigneeId = undefined;
  }

  if (assigneeId?.id === "Unassigned") {
    params.assigneeId = "UNASSIGNED";
  }

  if (assigneeId && !["All", "Unassigned"].includes(assigneeId.id)) {
    params.assigneeId = assigneeId.id;
  }

  if (customerAndAgreement?.type === "Customer") {
    params.agreementId = undefined;
    params.customerId = customerAndAgreement.id;
  }

  if (customerAndAgreement?.type === "Agreement") {
    params.customerId = undefined;
    params.agreementId = customerAndAgreement.id;
  }

  if (from) {
    params.from = from;
  }

  if (to) {
    params.to = to;
  }

  return params;
};

export enum TaskStatusEnum {
  "On Track" = "list.on_track",
  Complete = "list.completed",
  Urgent = "list.urgent",
  Overdue = "list.overdue",
}

const CreateTaskAction = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {t("common:create")}
      </Button>
      <CreateTaskContainer open={open} onClose={() => setOpen(false)} />
    </>
  );
};

const ListTasksContainer = () => {
  const [currentTaskId, setCurrentTaskId] = useState<number | undefined>();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filters, setFilters] = useState<ListTaskFilterProps>({
    assigneeId: {
      name: "All",
      id: "All",
    },
    customerAndAgreement: null,
    from: null,
    to: null,
  });

  const { t } = useTranslation(["start"]);
  const locale = useLocale();
  const engageConfig = useEngageConfig();

  const tasks = useListTasks({
    pageSize: rowsPerPage,
    params: mapFiltersToParams(filters),
  });

  const headings = [
    t("list.status_header"),
    t("list.title_header"),
    t("list.assigned_to_header"),
    t("list.deadline_header"),
    t("list.customer_header"),
    t("list.agreement_header"),
  ];

  const statusColourMap = new Map<TaskStatusModel, string>([
    [TaskStatusModel.Overdue, red[700]],
    [TaskStatusModel.Urgent, orange[500]],
    [TaskStatusModel.OnTrack, green[400]],
    [TaskStatusModel.Complete, blue[400]],
  ]);

  const handleViewTask = (taskId: number) => (): void => {
    setCurrentTaskId(taskId);
  };

  const handleClose = () => {
    setCurrentTaskId(undefined);
  };

  const onFilterChange = (newFilters: ListTaskFilterProps): void =>
    setFilters(newFilters);

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => tasks.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  const labels = {
    assigneeId: "filters.assignee",
    from: "filters.from",
    to: "filters.to",
    customersAndAgreements: "filters.customers_and_agreements",
  };

  const renderTasks = (): ReactElement => {
    if (tasks.error || !tasks.data) {
      return (
        <Box display="flex" justifyContent="center">
          <Box
            aria-describedby="error-description"
            aria-labelledby="error-label"
            role="alert"
            maxWidth={300}
            textAlign="center"
          >
            <Typography id="error-label" gutterBottom variant="h6">
              {t("common:query_error.unexpected_error_title")}
            </Typography>
            <Typography id="error-description">
              {t("common:query_error.unexpected_error_message")}
            </Typography>
            <Box marginTop={2}>
              <Button
                color="primary"
                onClick={tasks.refetch}
                variant="contained"
              >
                {t("common:query_error.unexpected_error_retry_button")}
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Table size="small" stickyHeader aria-label={t("list.label")}>
          <TableHead>
            <TableRow>
              {headings.map((label) => (
                <TableCell key={label} size="small">
                  {label}
                </TableCell>
              ))}
            </TableRow>
            {tasks.isValidating && (
              <TableRow>
                <TableCell colSpan={10} padding="none">
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {tasks.data.results.map((task) => (
              <TableRow key={task.id} hover>
                <TableCell component="th" scope="row" width={1}>
                  <Chip
                    label={t(TaskStatusEnum[task.status])}
                    sx={{
                      backgroundColor: statusColourMap.get(task.status),
                      color: "white",
                      width: "100%",
                    }}
                    size="small"
                  />
                </TableCell>
                <TableCell padding="none" scope="row">
                  <ListItemButton onClick={handleViewTask(task.id)} dense>
                    <ListItemText
                      primary={task.title}
                      primaryTypographyProps={{ color: "primary" }}
                    />
                  </ListItemButton>
                </TableCell>
                <TableCell>{task.assignedTo}</TableCell>
                <TableCell>
                  {locale.formatDateTime(new Date(task.dueAt))}
                </TableCell>
                <TableCell>
                  {task.customerName && (
                    <Link
                      href={`${engageConfig.appRoutes.servicing}/customers/${task.customerId}`}
                    >
                      {task.customerName}
                    </Link>
                  )}
                </TableCell>
                <TableCell>
                  {task.agreementNumber && (
                    <Link
                      href={`${engageConfig.appRoutes.servicing}/agreements/${task.agreementId}`}
                    >
                      {task.agreementNumber}
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {tasks?.data?.results.length === 0 && (
          <Box display="flex" justifyContent="center" p={2}>
            <Typography>{t("list.no_tasks")}</Typography>
          </Box>
        )}

        {currentTaskId && (
          <ViewTaskContainer
            open={Boolean(currentTaskId)}
            onClose={handleClose}
            taskId={currentTaskId}
          />
        )}
      </>
    );
  };

  return (
    <ListLayout
      pagination={{
        count: tasks.data?.paging.total ?? 0,
        onRowsPerPageChange: onChangeRowsPerPage,
        onPageChange: onChangePage,
        page: tasks.paging.page,
        rowsPerPage,
      }}
      filters={
        <TasksFilter
          labels={labels}
          onChange={onFilterChange}
          filters={filters}
        />
      }
      actions={<CreateTaskAction />}
    >
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(tasks.data || tasks.error)}
        render={renderTasks}
      />
    </ListLayout>
  );
};

export default ListTasksContainer;
