import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Loader } from "@ldms/mui-sdk/templates";
import { Box } from "@mui/material";
import DirectDebitDetails from "apps/finance/containers/DirectDebitDetails";
import { AppError } from "common/components";
import LabelledChip from "common/components/LabelledChip";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useApi } from "common/providers";
import { DirectDebitQueryApi } from "generated/core/apis";
import { DirectDebitSubmissionDetailsModel } from "generated/core/models";
import { compose } from "lodash/fp";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { withTranslationLoader } from "sdk/views";
import { ErrorLike } from "support/error-handler";
import useStickySWR from "support/use-sticky-swr";

interface DirectDebitSubmissionDetailsProps {
  submissionId: number;
}

function DirectDebitSubmissionDetails({
  submissionId,
}: DirectDebitSubmissionDetailsProps): ReactElement {
  const { ready, t } = useTranslation("finance");
  const { formatAmount } = useFormat();
  const appConfig = useAppConfiguration();
  const directDebitQueryApi = useApi(DirectDebitQueryApi);

  const directDebitSubmissionDetails = useStickySWR<
    DirectDebitSubmissionDetailsModel,
    ErrorLike
  >(["/direct-debit-submissions/:submissionId", ""], () =>
    directDebitQueryApi.listDirectDebitSubmissionPayments({
      submissionId,
      type: "",
    }),
  );

  const errorCodesTranslations = new Map(
    ready
      ? [
          [
            "404",
            {
              message: t("error.direct_debit_submission_404_message"),
              title: t("error.direct_debit_submission_404"),
            },
          ],
        ]
      : undefined,
  );

  const renderDirectDebitSubmissionDetails = (): ReactElement => {
    if (
      directDebitSubmissionDetails.error ||
      !directDebitSubmissionDetails.data
    ) {
      const errorCodeTranslation = errorCodesTranslations?.get(
        String(directDebitSubmissionDetails.error?.status),
      );

      return (
        <AppError
          message={errorCodeTranslation?.message}
          title={errorCodeTranslation?.title}
        />
      );
    }

    return (
      <>
        <Box display="grid" gridTemplateRows="max-content 1fr">
          <ViewLayout
            breadcrumbs={[
              {
                href: `${appConfig.appRoutes.finance}`,
                label: t("direct_debit_submissions.title"),
              },
            ]}
            title={directDebitSubmissionDetails.data?.payments[0].sun}
            labelledChip={
              directDebitSubmissionDetails.data ? (
                <>
                  <LabelledChip
                    label={t(
                      "direct_debit_submissions.total_submission_amount",
                    )}
                    value={formatAmount(
                      directDebitSubmissionDetails.data?.totalAmount,
                    )}
                  />
                </>
              ) : undefined
            }
          >
            <ContainedLayout>
              <DirectDebitDetails submissionId={submissionId} />
            </ContainedLayout>
          </ViewLayout>
        </Box>
      </>
    );
  };

  return (
    <Loader
      ready={Boolean(
        directDebitSubmissionDetails.error ?? directDebitSubmissionDetails.data,
      )}
      render={renderDirectDebitSubmissionDetails}
    />
  );
}

export default compose(
  withTranslationLoader("finance"),
  withProtectedView({
    allowedPermissions: ["finance:dd-submissions:view"],
  }),
)(DirectDebitSubmissionDetails);
