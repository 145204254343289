/* tslint:disable */
/* eslint-disable */
/**
 * Engage Gateway (Profile)
 * API for managing Profiles within Engage Gateway
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EnvironmentModel,
} from '../models/index';
import {
    EnvironmentModelFromJSON,
    EnvironmentModelToJSON,
} from '../models/index';

/**
 * 
 */
export class EnvironmentApi extends runtime.BaseAPI {

    /**
     * Retrieves details about the current Engage environment
     */
    async getEnvironmentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnvironmentModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentModelFromJSON(jsonValue));
    }

    /**
     * Retrieves details about the current Engage environment
     */
    async getEnvironment(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnvironmentModel> {
        const response = await this.getEnvironmentRaw(initOverrides);
        return await response.value();
    }

}
