/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankDetailsModel
 */
export interface BankDetailsModel {
    /**
     * Name of the Bank
     * @type {string}
     * @memberof BankDetailsModel
     */
    bankName: string;
    /**
     * Bank Account Name
     * @type {string}
     * @memberof BankDetailsModel
     */
    accountName: string;
    /**
     * Bank Sort Code
     * @type {string}
     * @memberof BankDetailsModel
     */
    sortCode?: string;
    /**
     * Bank Account Number
     * @type {string}
     * @memberof BankDetailsModel
     */
    accountNumber?: string;
    /**
     * International Bank Account Number
     * @type {string}
     * @memberof BankDetailsModel
     */
    iban?: string;
    /**
     * Bank Identifier Code
     * @type {string}
     * @memberof BankDetailsModel
     */
    bic?: string;
}

/**
 * Check if a given object implements the BankDetailsModel interface.
 */
export function instanceOfBankDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bankName" in value;
    isInstance = isInstance && "accountName" in value;

    return isInstance;
}

export function BankDetailsModelFromJSON(json: any): BankDetailsModel {
    return BankDetailsModelFromJSONTyped(json, false);
}

export function BankDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankName': json['bankName'],
        'accountName': json['accountName'],
        'sortCode': !exists(json, 'sortCode') ? undefined : json['sortCode'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
    };
}

export function BankDetailsModelToJSON(value?: BankDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankName': value.bankName,
        'accountName': value.accountName,
        'sortCode': value.sortCode,
        'accountNumber': value.accountNumber,
        'iban': value.iban,
        'bic': value.bic,
    };
}

