/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Change types within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RecoverableTypeModel = {
    DateOfCharge: 'Date of Charge',
    AtMaturity: 'At Maturity'
} as const;
export type RecoverableTypeModel = typeof RecoverableTypeModel[keyof typeof RecoverableTypeModel];


export function RecoverableTypeModelFromJSON(json: any): RecoverableTypeModel {
    return RecoverableTypeModelFromJSONTyped(json, false);
}

export function RecoverableTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecoverableTypeModel {
    return json as RecoverableTypeModel;
}

export function RecoverableTypeModelToJSON(value?: RecoverableTypeModel | null): any {
    return value as any;
}

