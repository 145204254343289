import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useYupResolver } from "common/hooks";
import { useAgreement } from "common/providers";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface TerminateAgreementContainerProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  confirmationMessage: string;
  title: string;
}

export interface ConditionalUpdateAgreementFieldValues {
  agreementNumber: string;
}

const ConfirmUpdateAgreementStatusContainer = ({
  open,
  onClose,
  onSubmit,
  confirmationMessage,
  title,
}: TerminateAgreementContainerProps) => {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const agreementNumber = agreement.data?.agreementNumber.toUpperCase();
  const agreementNumberLabel = "confirm_status_update.agreement_number_label";

  const resolver = useYupResolver<ConditionalUpdateAgreementFieldValues>(
    (yup) =>
      yup.object().shape({
        agreementNumber: yup
          .string()
          .isRequired(t(agreementNumberLabel))
          .uppercase()
          .trim()
          .equals(
            [agreementNumber],
            t(
              "confirm_status_update.agreement_number_not_match_error_message",
              {
                label: t(agreementNumberLabel),
              },
            ),
          ),
      }),
  );

  const form = useForm<ConditionalUpdateAgreementFieldValues>({
    resolver,
    shouldUnregister: true,
  });

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby={title}
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <form
          method="POST"
          onSubmit={form.handleSubmit(onSubmit)}
          aria-labelledby={title}
          noValidate
        >
          <DialogTitle id={title}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>{title}</Box>
              <Box>
                <IconButton
                  aria-label={t("common:close")}
                  onClick={onClose}
                  size="small"
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography>{confirmationMessage}</Typography>
            <TextField
              {...form.register("agreementNumber")}
              error={Boolean(form.formState.errors?.agreementNumber)}
              helperText={form.formState.errors.agreementNumber?.message}
              label={t(agreementNumberLabel)}
              variant="outlined"
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              {t("common:no")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={form.formState.isSubmitting}
              variant="contained"
            >
              {t("common:yes")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ConfirmUpdateAgreementStatusContainer;
