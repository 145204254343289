import { Grid, TextField } from "@mui/material";
import React from "react";

interface ReadOnlyUserDetailsProps {
  user: {
    username: string;
    firstName: string;
    lastName: string;
  };
  labels: {
    username: string;
    firstName: string;
    lastName: string;
  };
}

export default function ReadOnlyUserDetails(
  props: Readonly<ReadOnlyUserDetailsProps>,
): React.ReactElement {
  return (
    <>
      <TextField
        name="username"
        label={props.labels.username}
        disabled
        value={props.user.username}
      />
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            name="firstName"
            label={props.labels.firstName}
            disabled
            value={props.user.firstName}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            name="lastName"
            label={props.labels.lastName}
            disabled
            value={props.user.lastName}
          />
        </Grid>
      </Grid>
    </>
  );
}
