import { useQuery } from "@ldms/mui-sdk/cqrs";
import * as agreementKeys from "api/agreements/payment-instructions/keys";
import { useApi } from "common/providers/ApiProvider";
import { PaymentInstructionsApi } from "generated/servicing-v2/apis";

export const useGetPaymentInstruction = (agreementId: string, id: string) => {
  const api = useApi(PaymentInstructionsApi, "v2");
  return useQuery(agreementKeys.detail(agreementId, id), () =>
    api.getPaymentInstructions({
      id,
    }),
  );
};
