import * as customerKeys from "api/customers/keys";
export const all = (customerId: number | string) => [
  ...customerKeys.detail(customerId),
  "bank-accounts",
];

export const detail = (bankAccountId: string | number) => [
  "bank-accounts",
  "detail",
  bankAccountId,
];

export const listBanks = (customerId: string) => [...all(customerId), "list"];

export const list = (customerId: number | string, bankAccountId: number) => [
  ...all(customerId),
  ...detail(bankAccountId),
  "mandates",
  "list",
  bankAccountId,
];
