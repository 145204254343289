/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssetInstalmentListItemModel
 */
export interface AssetInstalmentListItemModel {
    /**
     * The instalment number
     * @type {number}
     * @memberof AssetInstalmentListItemModel
     */
    instalmentNumber: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetInstalmentListItemModel
     */
    instalmentDate: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetInstalmentListItemModel
     */
    capital: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetInstalmentListItemModel
     */
    interest: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetInstalmentListItemModel
     */
    vat: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetInstalmentListItemModel
     */
    rental: string;
}

/**
 * Check if a given object implements the AssetInstalmentListItemModel interface.
 */
export function instanceOfAssetInstalmentListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "instalmentNumber" in value;
    isInstance = isInstance && "instalmentDate" in value;
    isInstance = isInstance && "capital" in value;
    isInstance = isInstance && "interest" in value;
    isInstance = isInstance && "vat" in value;
    isInstance = isInstance && "rental" in value;

    return isInstance;
}

export function AssetInstalmentListItemModelFromJSON(json: any): AssetInstalmentListItemModel {
    return AssetInstalmentListItemModelFromJSONTyped(json, false);
}

export function AssetInstalmentListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetInstalmentListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instalmentNumber': json['instalmentNumber'],
        'instalmentDate': (new Date(json['instalmentDate'])),
        'capital': json['capital'],
        'interest': json['interest'],
        'vat': json['vat'],
        'rental': json['rental'],
    };
}

export function AssetInstalmentListItemModelToJSON(value?: AssetInstalmentListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instalmentNumber': value.instalmentNumber,
        'instalmentDate': (value.instalmentDate.toISOString().substring(0,10)),
        'capital': value.capital,
        'interest': value.interest,
        'vat': value.vat,
        'rental': value.rental,
    };
}

