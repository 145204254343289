import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function useResponseError(errors: [string, string][] = []): {
  message: string | undefined;
  reset: () => void;
  setError: (errorCode: string) => void;
} {
  const [errorCode, setErrorCode] = useState("");
  const [isError, setError] = useState(false);
  const { t } = useTranslation();

  const errorCodeTranslations = new Map([
    ["bad_request", t("common:validation.is_invalid_form_400_message")],
    ...errors,
  ]);

  const message = isError
    ? errorCodeTranslations.get(errorCode) || t("common:error.default")
    : undefined;

  const onError = (code: string): void => {
    setError(true);
    setErrorCode(code);
  };

  const reset = (): void => {
    setError(false);
    setErrorCode("");
  };

  return { message, setError: onError, reset };
}
