import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { SettlementQuoteCommandApi } from "generated/core/apis";
import { useSWRConfig } from "swr";
import { keys } from ".";

export const useRejectSettlementQuote = (
  settlementQuoteId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(SettlementQuoteCommandApi);
  const swr = useSWRConfig();

  return useCommand<void, void, ResponseError>(
    () =>
      api.rejectSettlementQuote({
        settlementQuoteId,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.detail(settlementQuoteId));
        options.onSuccess?.();
      },
    },
  );
};
