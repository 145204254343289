import { Box, Drawer, Toolbar, Typography } from "@mui/material";
import { useEnvironment } from "common/providers/EnvironmentProvider";
import { ReactElement, ReactNode } from "react";

const drawerWidth = 240;

interface SidebarLayoutProps {
  children: ReactNode;
  heading?: ReactNode;
  navigation?: ReactNode;
  sidebar?: ReactNode;
  title?: ReactNode;
}

export default function SidebarLayout({
  children,
  heading,
  navigation,
  sidebar,
  title,
}: SidebarLayoutProps): ReactElement {
  const environment = useEnvironment();

  return (
    <Box
      display="grid"
      gridTemplateColumns={`${drawerWidth}px 1fr`}
      height="100%"
    >
      <Drawer
        sx={{
          width: drawerWidth,
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            width: drawerWidth,
            zIndex: 1,
          },
        }}
        variant="permanent"
      >
        {(title || navigation || heading) && (
          <>
            <Toolbar variant="dense" />

            <Box aria-labelledby="sidebar-navigation-heading" component="nav">
              <Box
                paddingX={3}
                marginTop={!environment.isProduction ? "54px" : 3}
              >
                <Typography
                  color="textSecondary"
                  id="sidebar-navigation-heading"
                  variant="overline"
                >
                  {heading}
                </Typography>
              </Box>

              <Box paddingX={3}>
                {["string", "undefined"].includes(typeof title) ? (
                  <Box whiteSpace="pre-wrap">
                    <Typography variant="h6">{title || " "}</Typography>
                  </Box>
                ) : (
                  title
                )}
              </Box>

              <Box paddingX={1}>{navigation}</Box>
            </Box>
          </>
        )}

        {sidebar}
      </Drawer>
      <Box>{children}</Box>
    </Box>
  );
}
