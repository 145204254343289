import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { usePopover } from "@ldms/mui-sdk/hooks";
import { MoreVert } from "@mui/icons-material";
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import ModifiableToggle from "common/components/ModifiableToggle";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

export type Transaction = {
  transactionId?: number;
  date: Date;
  bankCode?: string;
  type: string;
  description?: string;
  creditAmount?: string;
  debitAmount?: string;
  balance: string;
  returnable: boolean;
  transferable: boolean;
  removable: boolean;
};

interface TransactionDropdownProps {
  transaction: Transaction;
  transactionId: number;
  onRemoveChargeClick: (transactionId: number) => void;
  onReturnPaymentClick: (transactionId: number) => void;
  onTransferPaymentClick: (transactionId: number) => void;
}

const TransactionDropdown = ({
  transaction,
  transactionId,
  onRemoveChargeClick,
  onReturnPaymentClick,
  onTransferPaymentClick,
}: TransactionDropdownProps) => {
  const { t } = useTranslation("agreements");
  const popover = usePopover();

  return (
    <>
      <IconButton
        aria-label={t("common:actions_button")}
        onClick={popover.handleClick}
        size="small"
      >
        <MoreVert fontSize="inherit" />
      </IconButton>
      <Menu {...popover.popoverProps}>
        {[
          transaction.returnable && (
            <MenuItem
              key="return"
              onClick={() => onReturnPaymentClick(transactionId)}
            >
              {t("transactions.list.return_payment_button")}
            </MenuItem>
          ),
          transaction.transferable && (
            <MenuItem
              key="transfer"
              onClick={() => onTransferPaymentClick(transactionId)}
            >
              {t("transactions.list.transfer_payment_button")}
            </MenuItem>
          ),
          transaction.removable && (
            <MenuItem
              key="remove"
              onClick={() => onRemoveChargeClick(transactionId)}
            >
              {t("transactions.list.remove_charge_button")}
            </MenuItem>
          ),
        ].filter(Boolean)}
      </Menu>
    </>
  );
};

interface TransactionsListProps {
  transactions?: Transaction[];
  loading: boolean;
  onReturnPayment(transactionId: number): void;
  onTransferPayment(transactionId: number): void;
  onRemoveCharge(transactionId: number): void;
}

export default function TransactionsList({
  transactions,
  loading,
  onReturnPayment,
  onTransferPayment,
  onRemoveCharge,
}: TransactionsListProps): ReactElement {
  const { t } = useTranslation("agreements");
  const locale = useLocale();
  const { formatAmount } = useFormat();
  const tableHeaders: string[] = [
    t("transactions.list.date"),
    t("transactions.list.bank_code"),
    t("transactions.list.type"),
    t("transactions.list.description"),
    t("transactions.list.credit"),
    t("transactions.list.debit"),
    t("transactions.list.balance"),
    "",
  ];

  return (
    <>
      <Table
        aria-label={t("transactions.list.title")}
        size="small"
        stickyHeader
      >
        <LoadableTableHead headings={tableHeaders} loading={loading} />
        <TableBody>
          {transactions?.map((transaction) => (
            <TableRow
              key={uuidv4()}
              sx={(theme) =>
                transaction.type.toUpperCase().endsWith("-RET")
                  ? {
                      backgroundColor: theme.palette.grey[100],
                    }
                  : {}
              }
              hover
            >
              <TableCell>
                {transaction.date && locale.formatDate(transaction.date)}
              </TableCell>
              <TableCell>{transaction.bankCode}</TableCell>
              <TableCell>{transaction.type}</TableCell>
              <TableCell>{transaction.description}</TableCell>
              <TableCell>{formatAmount(transaction.creditAmount)}</TableCell>
              <TableCell>{formatAmount(transaction.debitAmount)}</TableCell>
              <TableCell>{formatAmount(transaction.balance)}</TableCell>
              <TableCell sx={{ paddingY: 0.25 }} align="right">
                <ModifiableToggle>
                  <AccessControl
                    allowedPermissions={["servicing:transactions:manage"]}
                  >
                    <>
                      {typeof transaction.transactionId !== "undefined" &&
                        [
                          transaction.returnable,
                          transaction.transferable,
                          transaction.removable,
                        ].some(Boolean) && (
                          <TransactionDropdown
                            transaction={transaction}
                            transactionId={transaction.transactionId}
                            onRemoveChargeClick={onRemoveCharge}
                            onReturnPaymentClick={onReturnPayment}
                            onTransferPaymentClick={onTransferPayment}
                          />
                        )}
                    </>
                  </AccessControl>
                </ModifiableToggle>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {(transactions ?? []).length === 0 && <NoResults />}
    </>
  );
}
