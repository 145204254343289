/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientListItemModel
 */
export interface ClientListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof ClientListItemModel
     */
    readonly id: number;
    /**
     * The name of the client
     * @type {string}
     * @memberof ClientListItemModel
     */
    name?: string;
    /**
     * The name of the company
     * @type {string}
     * @memberof ClientListItemModel
     */
    companyName?: string;
    /**
     * The client's postal address
     * @type {string}
     * @memberof ClientListItemModel
     */
    address?: string;
    /**
     * The client's email address
     * @type {string}
     * @memberof ClientListItemModel
     */
    email?: string;
    /**
     * The client's primary phone number
     * @type {string}
     * @memberof ClientListItemModel
     */
    phone?: string;
    /**
     * The company registration number
     * @type {string}
     * @memberof ClientListItemModel
     */
    companyRegistrationNumber?: string;
    /**
     * The VAT registration number
     * @type {string}
     * @memberof ClientListItemModel
     */
    vatRegistrationNumber?: string;
}

/**
 * Check if a given object implements the ClientListItemModel interface.
 */
export function instanceOfClientListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ClientListItemModelFromJSON(json: any): ClientListItemModel {
    return ClientListItemModelFromJSONTyped(json, false);
}

export function ClientListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'companyRegistrationNumber': !exists(json, 'companyRegistrationNumber') ? undefined : json['companyRegistrationNumber'],
        'vatRegistrationNumber': !exists(json, 'vatRegistrationNumber') ? undefined : json['vatRegistrationNumber'],
    };
}

export function ClientListItemModelToJSON(value?: ClientListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'companyName': value.companyName,
        'address': value.address,
        'email': value.email,
        'phone': value.phone,
        'companyRegistrationNumber': value.companyRegistrationNumber,
        'vatRegistrationNumber': value.vatRegistrationNumber,
    };
}

