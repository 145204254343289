/* tslint:disable */
/* eslint-disable */
/**
 * Engage Admin
 * API for managing users within the Engage Admin application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleModel } from './RoleModel';
import {
    RoleModelFromJSON,
    RoleModelFromJSONTyped,
    RoleModelToJSON,
} from './RoleModel';

/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * The user's ID
     * @type {string}
     * @memberof UserModel
     */
    id: string;
    /**
     * The user's first name
     * @type {string}
     * @memberof UserModel
     */
    firstName: string;
    /**
     * The user's last name
     * @type {string}
     * @memberof UserModel
     */
    lastName: string;
    /**
     * The user's username
     * @type {string}
     * @memberof UserModel
     */
    username: string;
    /**
     * Whether the user's account is active or not
     * @type {boolean}
     * @memberof UserModel
     */
    active: boolean;
    /**
     * The user's email
     * @type {string}
     * @memberof UserModel
     */
    email?: string;
    /**
     * THe user's roles
     * @type {Array<RoleModel>}
     * @memberof UserModel
     */
    roles: Array<RoleModel>;
}

/**
 * Check if a given object implements the UserModel interface.
 */
export function instanceOfUserModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function UserModelFromJSON(json: any): UserModel {
    return UserModelFromJSONTyped(json, false);
}

export function UserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'username': json['username'],
        'active': json['active'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'roles': ((json['roles'] as Array<any>).map(RoleModelFromJSON)),
    };
}

export function UserModelToJSON(value?: UserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'username': value.username,
        'active': value.active,
        'email': value.email,
        'roles': ((value.roles as Array<any>).map(RoleModelToJSON)),
    };
}

