import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useListAgreementAssets } from "api/agreements/assets";
import { useGetTerminationRecoveryDetails } from "api/terminations";
import UpdateTerminationRecoveryDetailsContainer from "apps/servicing/modules/agreements/containers/UpdateTerminationRecoveryDetailsContainer";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
} from "common/components";
import Surface from "common/components/Surface";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface TerminationRecoveryDetailsContainerProps {
  agreementId: number;
}

export default function TerminationRecoveryDetailsContainer({
  agreementId,
}: TerminationRecoveryDetailsContainerProps): ReactElement {
  const terminationRecoveryDetails =
    useGetTerminationRecoveryDetails(agreementId);
  const assets = useListAgreementAssets(agreementId);
  const { t } = useTranslation("agreements");
  const { formatAmount } = useFormat();

  return (
    <Loader
      ready={Boolean(
        assets.data ??
          assets.error ??
          terminationRecoveryDetails.data ??
          terminationRecoveryDetails.error,
      )}
      render={(): ReactElement => {
        if (assets.error || !assets.data) {
          return <QueryError onRetry={assets.refetch} />;
        }

        if (
          terminationRecoveryDetails.error ||
          !terminationRecoveryDetails.data
        ) {
          return <QueryError onRetry={terminationRecoveryDetails.refetch} />;
        }

        return (
          <>
            <Surface disableGutters title={t("terminations.assets_label")}>
              <Table aria-label={t("terminations.assets_label")} size="small">
                <LoadableTableHead
                  headings={[
                    t("terminations.asset_type_label"),
                    t("terminations.asset_description_label"),
                    t("terminations.year_of_manufacture_label"),
                    t("terminations.asset_purchase_price_label"),
                  ]}
                  loading={assets.isValidating}
                />
                <TableBody>
                  {assets.data.map((terminationAsset) => (
                    <TableRow key={terminationAsset.id}>
                      <TableCell>{terminationAsset.type}</TableCell>
                      <TableCell>{terminationAsset.description}</TableCell>
                      <TableCell>
                        {terminationAsset.yearManufactured
                          ? terminationAsset.yearManufactured.getFullYear()
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {formatAmount(terminationAsset.purchasePrice)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {assets.data.length === 0 && <NoResults />}
            </Surface>
            <Box paddingY={3}>
              <Stack spacing={2}>
                <Surface
                  title={t("terminations.recovery_details_label")}
                  action={
                    <UpdateTerminationRecoveryDetailsContainer
                      terminationId={agreementId}
                      terminationRecoveryDetails={
                        terminationRecoveryDetails.data
                      }
                    />
                  }
                >
                  <Stack spacing={3}>
                    <DescriptionList
                      label={t("terminations.recovery_details_label")}
                    >
                      <DescriptionList.Item
                        label={t("terminations.current_arrears_label")}
                      >
                        {formatAmount(
                          terminationRecoveryDetails.data.currentArrears,
                        )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t("terminations.capital_outstanding_label")}
                      >
                        {formatAmount(
                          terminationRecoveryDetails.data.capitalOutstanding,
                        )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t("terminations.recovery_costs_label")}
                      >
                        {formatAmount(
                          terminationRecoveryDetails.data.recoveryCosts,
                        )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t("terminations.customer_payments_label")}
                      >
                        {formatAmount(
                          terminationRecoveryDetails.data.customerPayments,
                        )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t("terminations.total_to_recover_label")}
                      >
                        {formatAmount(
                          terminationRecoveryDetails.data.totalToRecover,
                        )}
                      </DescriptionList.Item>
                    </DescriptionList>
                    <DescriptionList
                      label={t("terminations.write_off_details_label")}
                    >
                      <DescriptionList.Item
                        label={t("terminations.guarantors_label")}
                      >
                        {formatAmount(
                          terminationRecoveryDetails.data.guarantors,
                        )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t("terminations.sale_of_asset_label")}
                      >
                        {formatAmount(
                          terminationRecoveryDetails.data.saleOfAsset,
                        )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t("terminations.buy_back_label")}
                      >
                        {formatAmount(terminationRecoveryDetails.data.buyBack)}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t("terminations.total_write_off_label")}
                      >
                        {formatAmount(
                          terminationRecoveryDetails.data.totalWriteOff,
                        )}
                      </DescriptionList.Item>
                    </DescriptionList>
                  </Stack>
                </Surface>
              </Stack>
            </Box>
          </>
        );
      }}
    />
  );
}
