import { AmountField, DateField } from "@ldms/mui-sdk/forms";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { useAddInsurance } from "api/agreements/insurance/addInsurance";
import { useListInsurers } from "api/insurers";
import { ControlledTextField, Loader } from "common/components";
import { useYupResolver } from "common/hooks";
import { AddInsuranceModel, InsurerListItemModel } from "generated/core/models";
import { ReactElement, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface AddInsuranceFieldValues {
  code: string;
  premiumTotal: number;
  cost: number;
  insurancePremiumTaxRate: number;
  startDate: Date;
}

interface AddInsuranceContainerProps {
  agreementId: number;
}

interface AddInsuranceDialogContainerProps {
  onSuccess(): void;
  onClose(): void;
  open: boolean;
  agreementId: number;
}

interface AddInsuranceDialogBodyProp {
  insurers?: InsurerListItemModel[];
  onClose(): void;
  agreementId: number;
  onSuccess(): void;
}

const useAddInsuranceResolver = (): Resolver<AddInsuranceFieldValues> => {
  const { t } = useTranslation(["agreements", "common"]);
  const insurerLabel = t("insurance.add_insurance.insurer_label");
  const insurancePremiumTaxRateLabel = t(
    "insurance.add_insurance.insurance_premium_tax_rate_label",
  );
  const startDateLabel = t("insurance.add_insurance.start_date_label");
  const transformField = (v: string, o: string): string | null | undefined => {
    return o === "" ? null : v;
  };
  const premiumTotalLabel = t("insurance.add_insurance.premium_total_label");
  const insuranceCostLabel = t("insurance.add_insurance.cost_label");

  return useYupResolver<AddInsuranceFieldValues>((yup) =>
    yup.object().shape({
      code: yup.string().isRequired(insurerLabel),
      premiumTotal: yup
        .number(premiumTotalLabel)
        .nullable()
        .transform(transformField)
        .isRequired(premiumTotalLabel),
      cost: yup
        .number(insuranceCostLabel)
        .nullable()
        .transform(transformField)
        .isRequired(insuranceCostLabel),
      insurancePremiumTaxRate: yup
        .number(insurancePremiumTaxRateLabel)
        .nullable()
        .transform(transformField)
        .isRequired(insurancePremiumTaxRateLabel)
        .maxAmount(100, insurancePremiumTaxRateLabel),
      startDate: yup
        .date()
        .localDate()
        .isValidDate(startDateLabel)
        .isNotBeforeToday(startDateLabel)
        .isRequired(startDateLabel),
    }),
  );
};

function AddInsuranceDialogBody({
  insurers,
  onClose,
  agreementId,
  onSuccess: onSuccessCallback,
}: AddInsuranceDialogBodyProp): ReactElement {
  const { t } = useTranslation("agreements");
  const resolver = useAddInsuranceResolver();
  const form = useForm<AddInsuranceFieldValues>({
    resolver,
    defaultValues: { code: "" },
  });
  const addInsurance = useAddInsurance(agreementId, {
    onSuccess: onSuccessCallback,
  });
  const onSubmit = async (data: AddInsuranceFieldValues): Promise<void> => {
    const addInsuranceModel: AddInsuranceModel = {
      ...data,
      premiumTotal: data.premiumTotal.toFixed(2),
      cost: data.cost.toFixed(2),
      startDate: data.startDate,
    };
    await addInsurance.command(addInsuranceModel);
  };

  return (
    <form
      method="POST"
      noValidate
      aria-label={t("insurance.add_insurance.title_label")}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <DialogTitle id="add-insurance-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{t("insurance.add_insurance.title_label")}</Box>
          <Box>
            <IconButton
              onClick={onClose}
              size="small"
              aria-label={t("common:close")}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Grid rowSpacing={0.5} container spacing={2}>
              <Grid item sm={6}>
                <ControlledTextField
                  select
                  id="code"
                  name="code"
                  label={t("insurance.add_insurance.insurer_label")}
                  helperText={form.formState.errors?.code?.message}
                  error={form.formState.errors?.code?.message}
                  SelectProps={{ displayEmpty: true }}
                  control={form.control}
                  required
                >
                  <MenuItem value="">
                    <i>{t("common:please_select")}</i>
                  </MenuItem>

                  {insurers?.map((insurer) => (
                    <MenuItem key={insurer.code} value={insurer.code}>
                      {insurer.name}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid item sm={6}>
                <AmountField
                  error={Boolean(form.formState.errors?.premiumTotal?.message)}
                  helperText={form.formState.errors?.premiumTotal?.message}
                  control={form.control}
                  name="premiumTotal"
                  required
                  label={t("insurance.add_insurance.premium_total_label")}
                />
              </Grid>
              <Grid item sm={6}>
                <AmountField
                  error={Boolean(form.formState.errors?.cost?.message)}
                  helperText={form.formState.errors?.cost?.message}
                  control={form.control}
                  name="cost"
                  label={t("insurance.add_insurance.cost_label")}
                  required
                />
              </Grid>
              <Grid item sm={6}>
                <AmountField
                  name="insurancePremiumTaxRate"
                  error={Boolean(
                    form.formState.errors?.insurancePremiumTaxRate?.message,
                  )}
                  helperText={
                    form.formState.errors?.insurancePremiumTaxRate?.message
                  }
                  control={form.control}
                  label={t(
                    "insurance.add_insurance.insurance_premium_tax_rate_label",
                  )}
                  required
                />
              </Grid>
              <Grid item sm={6}>
                <DateField
                  required
                  error={Boolean(form.formState.errors?.startDate?.message)}
                  name="startDate"
                  label={t("insurance.add_insurance.start_date_label")}
                  helperText={form.formState.errors?.startDate?.message}
                  control={form.control}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {addInsurance.error && (
          <Typography color="error" aria-label="form.error">
            {addInsurance.error.message}
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={form.formState.isSubmitting}
        >
          {t("insurance.add_insurance.apply_insurance_button")}
        </Button>
      </DialogActions>
    </form>
  );
}

function AddInsuranceDialogContainer({
  onSuccess: onSuccessCallback,
  onClose,
  open,
  agreementId,
}: AddInsuranceDialogContainerProps): ReactElement {
  const { t } = useTranslation("agreements");
  const insurers = useListInsurers();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="add-insurance-title"
    >
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(insurers.data) || Boolean(insurers.error)}
        render={(): ReactElement =>
          insurers.error ? (
            <Typography color="error" role="alert">
              {t("common:error.default")}
            </Typography>
          ) : (
            <AddInsuranceDialogBody
              insurers={insurers.data}
              onSuccess={onSuccessCallback}
              agreementId={agreementId}
              onClose={onClose}
            />
          )
        }
      />
    </Dialog>
  );
}

export default function AddInsuranceContainer({
  agreementId,
}: AddInsuranceContainerProps): ReactElement {
  const { t } = useTranslation("agreements");
  const [openAddInsuranceDialog, setOpenAddInsuranceDialog] = useState(false);

  const openDialog = (): void => {
    setOpenAddInsuranceDialog(true);
  };

  const closeDialog = (): void => {
    setOpenAddInsuranceDialog(false);
  };

  return (
    <>
      <Button
        aria-label={t("insurance.apply_insurance_button")}
        color="primary"
        variant="contained"
        onClick={openDialog}
      >
        {t("insurance.apply_insurance_button")}
      </Button>
      <AddInsuranceDialogContainer
        onSuccess={closeDialog}
        open={openAddInsuranceDialog}
        onClose={closeDialog}
        agreementId={agreementId}
      />
    </>
  );
}
