import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { useGetFinancialProductDetails } from "api/onboarding/financial-products/getFinancialProductDetails";
import FinancialProductDetailsContainer from "apps/onboarding/containers/FinancialProductDetailsContainer/FinancialProductDetailsContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

interface FinancialProductDetailsViewProps {
  financialProductId: number;
}

function FinancialProductDetailsView({
  financialProductId,
}: FinancialProductDetailsViewProps) {
  const { t } = useTranslation("onboardings");
  const vatCodeDetails = useGetFinancialProductDetails(financialProductId);

  const appConfig = useAppConfiguration();

  return (
    <main>
      <ViewLayout
        title={vatCodeDetails.data?.name}
        breadcrumbs={[
          {
            href: `${appConfig.appRoutes.onboarding}/settings/financial-products`,
            label: t("financial_products.details.breadcrumb_link_label"),
          },
        ]}
      >
        <ContainedLayout>
          <FinancialProductDetailsContainer
            financialProductId={financialProductId}
          />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["onboarding:financial-products:manage"],
})(FinancialProductDetailsView);
