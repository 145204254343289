/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentTypeModel } from './AttachmentTypeModel';
import {
    AttachmentTypeModelFromJSON,
    AttachmentTypeModelFromJSONTyped,
    AttachmentTypeModelToJSON,
} from './AttachmentTypeModel';
import type { DeliveryMethodModel } from './DeliveryMethodModel';
import {
    DeliveryMethodModelFromJSON,
    DeliveryMethodModelFromJSONTyped,
    DeliveryMethodModelToJSON,
} from './DeliveryMethodModel';
import type { DeliveryStatusModel } from './DeliveryStatusModel';
import {
    DeliveryStatusModelFromJSON,
    DeliveryStatusModelFromJSONTyped,
    DeliveryStatusModelToJSON,
} from './DeliveryStatusModel';

/**
 * 
 * @export
 * @interface ClientAttachmentListItemModel
 */
export interface ClientAttachmentListItemModel {
    /**
     * A read only Id
     * @type {string}
     * @memberof ClientAttachmentListItemModel
     */
    readonly id: string;
    /**
     * 
     * @type {AttachmentTypeModel}
     * @memberof ClientAttachmentListItemModel
     */
    type: AttachmentTypeModel;
    /**
     * 
     * @type {string}
     * @memberof ClientAttachmentListItemModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttachmentListItemModel
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof ClientAttachmentListItemModel
     */
    createdDate: Date;
    /**
     * 
     * @type {DeliveryMethodModel}
     * @memberof ClientAttachmentListItemModel
     */
    deliveryMethod?: DeliveryMethodModel;
    /**
     * 
     * @type {DeliveryStatusModel}
     * @memberof ClientAttachmentListItemModel
     */
    deliveryStatus?: DeliveryStatusModel;
    /**
     * The number of the agreement
     * @type {string}
     * @memberof ClientAttachmentListItemModel
     */
    agreementNumber?: string;
}

/**
 * Check if a given object implements the ClientAttachmentListItemModel interface.
 */
export function instanceOfClientAttachmentListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "createdDate" in value;

    return isInstance;
}

export function ClientAttachmentListItemModelFromJSON(json: any): ClientAttachmentListItemModel {
    return ClientAttachmentListItemModelFromJSONTyped(json, false);
}

export function ClientAttachmentListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientAttachmentListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': AttachmentTypeModelFromJSON(json['type']),
        'filename': json['filename'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdDate': (new Date(json['createdDate'])),
        'deliveryMethod': !exists(json, 'deliveryMethod') ? undefined : DeliveryMethodModelFromJSON(json['deliveryMethod']),
        'deliveryStatus': !exists(json, 'deliveryStatus') ? undefined : DeliveryStatusModelFromJSON(json['deliveryStatus']),
        'agreementNumber': !exists(json, 'agreementNumber') ? undefined : json['agreementNumber'],
    };
}

export function ClientAttachmentListItemModelToJSON(value?: ClientAttachmentListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': AttachmentTypeModelToJSON(value.type),
        'filename': value.filename,
        'description': value.description,
        'createdDate': (value.createdDate.toISOString().substring(0,10)),
        'deliveryMethod': DeliveryMethodModelToJSON(value.deliveryMethod),
        'deliveryStatus': DeliveryStatusModelToJSON(value.deliveryStatus),
        'agreementNumber': value.agreementNumber,
    };
}

