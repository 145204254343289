import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { ClientQueryApi } from "generated/core/apis";
import { ClientAssetListItemModel } from "generated/core/models";
import { keys } from ".";

type UseListClientAssets = (
  clientId: number,
  params?: { live?: boolean },
) => UseQueryReturn<ClientAssetListItemModel[]>;

export const useListClientAssets: UseListClientAssets = (
  clientId: number,
  params?: { live?: boolean },
) => {
  const api = useApi(ClientQueryApi);

  return useQuery(keys.list(clientId, params), () =>
    api.listClientAssets({
      clientId: clientId,
      live: params?.live,
    }),
  );
};
