import { QueryKey } from "api";
import { keys } from "api/agreements";

export const all = (agreement: number): QueryKey => [
  ...keys.detail(agreement),
  "fees-unpaid",
];

export const list = (agreement: number): QueryKey => [
  ...all(agreement),
  "list",
];
