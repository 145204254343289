/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The charge to be removed
 * @export
 * @interface RemoveChargeModel
 */
export interface RemoveChargeModel {
    /**
     * The transaction identifier of the charge to be removed
     * @type {number}
     * @memberof RemoveChargeModel
     */
    transactionId: number;
    /**
     * The code type representing the reason for removing the charge
     * @type {string}
     * @memberof RemoveChargeModel
     */
    reasonCode: string;
}

/**
 * Check if a given object implements the RemoveChargeModel interface.
 */
export function instanceOfRemoveChargeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transactionId" in value;
    isInstance = isInstance && "reasonCode" in value;

    return isInstance;
}

export function RemoveChargeModelFromJSON(json: any): RemoveChargeModel {
    return RemoveChargeModelFromJSONTyped(json, false);
}

export function RemoveChargeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveChargeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'reasonCode': json['reasonCode'],
    };
}

export function RemoveChargeModelToJSON(value?: RemoveChargeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionId': value.transactionId,
        'reasonCode': value.reasonCode,
    };
}

