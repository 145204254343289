import { MoreVert } from "@mui/icons-material";
import {
  IconButton,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useListFees } from "api/onboarding/fees/listFees";
import { UpdateFeeEnabledContainer } from "apps/onboarding/containers";
import { FeeTypeEnum } from "apps/onboarding/types";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
  YesNoValue,
} from "common/components";
import ListLayout from "common/layouts/ListLayout";
import { FeeTypeModel } from "generated/onboarding/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

type ModelKeys = keyof typeof FeeTypeModel;

interface UpdateFeeEnabledMenuProps {
  feeId: number;
  enabled: boolean;
}

function UpdateFeeEnabledMenu({
  feeId,
  enabled,
}: UpdateFeeEnabledMenuProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuElement);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuElement(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setMenuElement(null);
  };

  return (
    <>
      <IconButton
        aria-label={t("fees.list_fees.menu_button")}
        color="primary"
        onClick={handleMenuClick}
        size="small"
      >
        <MoreVert fontSize="inherit" />
      </IconButton>

      <Menu open={menuOpen} anchorEl={menuElement} onClose={handleMenuClose}>
        <UpdateFeeEnabledContainer
          feeId={feeId}
          enabled={enabled}
          closeMenu={handleMenuClose}
        />
      </Menu>
    </>
  );
}

export default function ListFeesContainer(): ReactElement {
  const { t } = useTranslation("onboardings");
  const fees = useListFees();

  return (
    <Loader
      ready={Boolean(fees.data ?? fees.error)}
      render={(): ReactElement => {
        if (fees.error || !fees.data) {
          return <QueryError onRetry={fees.refetch} />;
        }

        return (
          <ListLayout>
            <Table
              aria-label={t("fees.list_fees.title_label")}
              size="small"
              stickyHeader
            >
              <LoadableTableHead
                headings={[
                  t("fees.list_fees.name_label"),
                  t("fees.list_fees.type_label"),
                  t("fees.list_fees.taxable_label"),
                  t("fees.list_fees.deducted_from_advance_label"),
                  t("fees.list_fees.enabled_label"),
                  t("fees.list_fees.collect_by_dd_label"),
                  t("fees.list_fees.include_on_invoices_label"),
                  "",
                ]}
                loading={fees.isValidating}
              />
              <TableBody>
                {fees.data.map((fee) => (
                  <TableRow key={fee.id} hover>
                    <TableCell>{fee.name}</TableCell>
                    <TableCell>
                      {t(
                        FeeTypeEnum[
                          Object.keys(FeeTypeModel).find(
                            (key) =>
                              FeeTypeModel[key as ModelKeys] === fee.type,
                          ) as ModelKeys
                        ],
                      )}
                    </TableCell>
                    <TableCell>
                      <YesNoValue value={fee.taxable} />
                    </TableCell>
                    <TableCell>
                      <YesNoValue value={fee.deductedFromAdvance} />
                    </TableCell>
                    <TableCell>
                      <YesNoValue value={fee.enabled} />
                    </TableCell>
                    <TableCell>
                      <YesNoValue value={fee.collectByDirectDebit} />
                    </TableCell>
                    <TableCell>
                      <YesNoValue value={fee.includeOnInvoices} />
                    </TableCell>
                    <TableCell sx={{ paddingY: 0.25 }} align="right">
                      <UpdateFeeEnabledMenu
                        feeId={fee.id}
                        enabled={fee.enabled}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {fees.data.length === 0 && <NoResults />}
          </ListLayout>
        );
      }}
    />
  );
}
