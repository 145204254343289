import {
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration/useAppConfiguration";
import { AutomationStatusTypeModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export interface Automation {
  id: number;
  name: string;
  portfolioName: string;
  description?: string;
  status: AutomationStatusTypeModel;
}

interface AutomationListProps {
  data?: Automation[];
  loading: boolean;
}

export default function AutomationsList({
  data,
  loading,
}: Readonly<AutomationListProps>): ReactElement {
  const { t } = useTranslation("automations");
  const appConfig = useAppConfiguration();

  const automationStatus = new Map([
    [
      AutomationStatusTypeModel.Inactive,
      t("automations_list.inactive_description"),
    ],
    [
      AutomationStatusTypeModel.Active,
      t("automations_list.active_description"),
    ],
    [
      AutomationStatusTypeModel.Executing,
      t("automations_list.executing_description"),
    ],
  ]);

  return (
    <>
      <Table
        aria-label={t("automations_list.automation_table_label")}
        size="small"
        stickyHeader
      >
        <LoadableTableHead
          headings={[
            t("automations_list.rule_name_heading_label"),
            t("automations_list.portfolio_heading_label"),
            t("automations_list.description_heading_label"),
            t("automations_list.status_heading_label"),
          ]}
          loading={loading}
        />
        <TableBody>
          {data?.map((automation) => (
            <TableRow key={uuidv4()}>
              <TableCell component="th" padding="none" scope="row">
                <ListItemButton
                  dense
                  component={RouterLink}
                  to={`${appConfig.appRoutes.admin}/automations/${automation.id}`}
                >
                  <ListItemText
                    primary={automation.name}
                    primaryTypographyProps={{ color: "primary" }}
                  />
                </ListItemButton>
              </TableCell>
              <TableCell>{automation.portfolioName}</TableCell>
              <TableCell>{automation.description}</TableCell>
              <TableCell>{automationStatus.get(automation.status)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(data ?? []).length === 0 && <NoResults />}
    </>
  );
}
