import { EditButton } from "@ldms/mui-sdk/components";
import { AmountField, NumberField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { Grid, MenuItem, Typography } from "@mui/material";
import { useUpdateSecondaryRentalDetails } from "api/secondary-rentals/updateSecondaryRentalDetails";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import { useAgreement } from "common/providers";
import {
  PaymentFrequencyModel,
  SecondaryRentalDetailsModel,
  UpdateSecondaryRentalDetailsModel,
} from "generated/core/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Resolver } from "support/react-hook-form";

export interface UpdateSecondaryRentalDetailsFieldValues {
  amount: number;
  frequency: PaymentFrequencyModel;
  introducerShare: number;
  maintenanceCharge: number;
  insurancePremium: number;
}

interface UpdateSecondaryRentalDetailsContainerProps {
  secondaryRentalId: number;
  secondaryRentalDetails: SecondaryRentalDetailsModel;
}

interface UpdateSecondaryRentalDetailsDialogProps {
  onSuccess(): void;
  onClose(): void;
  open: boolean;
  secondaryRentalId: number;
  secondaryRentalDetails: SecondaryRentalDetailsModel;
}

const useSecondaryRentalDetailsResolver =
  (): Resolver<UpdateSecondaryRentalDetailsFieldValues> => {
    const { t } = useTranslation("agreements");
    const transformField = (
      v: string,
      o: string,
    ): string | null | undefined => {
      return o === "" ? null : v;
    };
    const amountLabel = "secondary_rental.update_details.amount_label";
    const frequencyLabel = "secondary_rental.update_details.frequency_label";
    const introducerShareLabel =
      "secondary_rental.update_details.introducer_share_label";
    const insurancePremiumLabel =
      "secondary_rental.update_details.insurance_premium_label";
    const maintenanceChargeLabel =
      "secondary_rental.update_details.maintenance_charge_label";
    const isGreaterThanZero = "common:validation.is_greater_than_zero";

    return useYupResolver<UpdateSecondaryRentalDetailsFieldValues>((yup) =>
      yup.object().shape({
        amount: yup
          .number(t(amountLabel))
          .isRequired(t(amountLabel))
          .moreThan(
            0,
            t(isGreaterThanZero, {
              label: t(amountLabel),
            }),
          )
          .nullable()
          .maxAmount(99999999.99, t(amountLabel)),
        frequency: yup.string().isRequired(t(frequencyLabel)),
        introducerShare: yup
          .number(t(introducerShareLabel))
          .transform(transformField)
          .nullable()
          .minAmount(0, t(introducerShareLabel))
          .maxAmount(100, t(introducerShareLabel)),
        insurancePremium: yup
          .number(t(insurancePremiumLabel))
          .transform(transformField)
          .nullable()
          .moreThan(
            0,
            t(isGreaterThanZero, {
              label: t(insurancePremiumLabel),
            }),
          )
          .maxAmount(99999999.99, t(insurancePremiumLabel)),
        maintenanceCharge: yup
          .number(t(maintenanceChargeLabel))
          .transform(transformField)
          .nullable()
          .moreThan(
            0,
            t(isGreaterThanZero, {
              label: t(maintenanceChargeLabel),
            }),
          )
          .maxAmount(99999999.99, t(maintenanceChargeLabel)),
      }),
    );
  };

function UpdateSecondaryRentalDetailsDialogContainer({
  onSuccess,
  onClose,
  open,
  secondaryRentalId,
  secondaryRentalDetails,
}: UpdateSecondaryRentalDetailsDialogProps): ReactElement {
  const { t } = useTranslation("agreements");
  const resolver = useSecondaryRentalDetailsResolver();
  const updateSecondaryRentalDetails = useUpdateSecondaryRentalDetails(
    secondaryRentalId,
    {
      onSuccess,
    },
  );

  const handleOnClose = (): void => {
    updateSecondaryRentalDetails.reset();
    onClose();
  };

  const onSubmit = async (
    data: UpdateSecondaryRentalDetailsFieldValues,
  ): Promise<void> => {
    const updateRecoveryDetails: UpdateSecondaryRentalDetailsModel = {
      ...data,
      amount: data.amount?.toFixed(2),
      frequency: data.frequency,
      introducerShare: data.introducerShare ? data.introducerShare : 0,
      insurancePremium: data.insurancePremium?.toFixed(2),
      maintenanceCharge: data.maintenanceCharge?.toFixed(2),
    };
    await updateSecondaryRentalDetails.execute(updateRecoveryDetails);
  };

  return (
    <>
      <FormDialog
        title={t("secondary_rental.update_details.heading_label")}
        onSubmit={onSubmit}
        onClose={handleOnClose}
        open={open}
        resolver={resolver}
        defaultValues={{
          amount: Number(secondaryRentalDetails.amount),
          frequency: secondaryRentalDetails.frequency,
          introducerShare: Number(secondaryRentalDetails.introducerShare),
          insurancePremium: Number(secondaryRentalDetails.insurancePremium),
          maintenanceCharge: Number(secondaryRentalDetails.maintenanceCharge),
        }}
        disabled={updateSecondaryRentalDetails.isExecuting}
      >
        {(form) => {
          return (
            <>
              <Grid container spacing={2} rowSpacing={0.5}>
                <Grid item sm={12}>
                  <AmountField
                    control={form.control}
                    name="amount"
                    label={t("secondary_rental.update_details.amount_label")}
                    helperText={form.formState.errors.amount?.message}
                    error={Boolean(form.formState.errors.amount?.message)}
                    required
                  />
                </Grid>
                <Grid item sm={12}>
                  <ControlledTextField
                    helperText={form.formState.errors?.frequency?.message}
                    SelectProps={{ displayEmpty: true }}
                    error={form.formState.errors?.frequency?.message}
                    control={form.control}
                    name="frequency"
                    id="frequency"
                    label={t("secondary_rental.update_details.frequency_label")}
                    select
                    required
                  >
                    <MenuItem value="">{t("common:please_select")}</MenuItem>
                    {Object.values(PaymentFrequencyModel).map((value) => (
                      <MenuItem value={String(value)} key={String(value)}>
                        {value}
                      </MenuItem>
                    ))}
                  </ControlledTextField>
                </Grid>
                <Grid item sm={12}>
                  <NumberField
                    control={form.control}
                    name="introducerShare"
                    label={t(
                      "secondary_rental.update_details.introducer_share_label",
                    )}
                    helperText={form.formState.errors.introducerShare?.message}
                    error={Boolean(
                      form.formState.errors.introducerShare?.message,
                    )}
                  />
                </Grid>
                <Grid item sm={12}>
                  <AmountField
                    name="maintenanceCharge"
                    label={t(
                      "secondary_rental.update_details.maintenance_charge_label",
                    )}
                    control={form.control}
                    helperText={
                      form.formState.errors.maintenanceCharge?.message
                    }
                    error={Boolean(
                      form.formState.errors.maintenanceCharge?.message,
                    )}
                  />
                </Grid>
                <Grid item sm={12}>
                  <AmountField
                    name="insurancePremium"
                    label={t(
                      "secondary_rental.update_details.insurance_premium_label",
                    )}
                    control={form.control}
                    helperText={form.formState.errors.insurancePremium?.message}
                    error={Boolean(
                      form.formState.errors.insurancePremium?.message,
                    )}
                  />
                </Grid>
              </Grid>

              {updateSecondaryRentalDetails.error && (
                <Typography color="error">
                  {t("common:error.default")}
                </Typography>
              )}
            </>
          );
        }}
      </FormDialog>
    </>
  );
}

export default function UpdateSecondaryRentalDetailsContainer({
  secondaryRentalId,
  secondaryRentalDetails,
}: UpdateSecondaryRentalDetailsContainerProps): ReactElement {
  const agreement = useAgreement();
  const { t } = useTranslation("agreements");
  const [open, setOpen] = useState(false);
  const openDialog = (): void => {
    setOpen(true);
  };
  const closeDialog = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UpdateSecondaryRentalDetailsDialogContainer
        onSuccess={closeDialog}
        open={open}
        onClose={closeDialog}
        secondaryRentalId={secondaryRentalId}
        secondaryRentalDetails={secondaryRentalDetails}
      />

      {agreement.data &&
        ["PENDSECRENTAL", "SECRENT", "SECRENTA"].includes(
          agreement.data?.statusCode,
        ) && (
          <EditButton onClick={openDialog}>
            {t("secondary_rental.update_details.edit_button")}
          </EditButton>
        )}
    </>
  );
}
