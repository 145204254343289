import { AccessControl, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Button, Typography } from "@mui/material";
import { useGetRole } from "api/roles";
import { useDeleteRole } from "api/roles/deleteRole";
import EditRolePermissionsContainer from "apps/admin/containers/EditRolePermissionsContainer/EditRolePermissionsContainer";
import { useConfirm } from "common/hooks";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout/ViewLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const RoleDetailsView = ({ roleId }: { roleId: string }) => {
  const { t } = useTranslation("roles");
  const navigate = useNavigate();
  const roleIdtoNumber = Number(roleId);
  const roleDetails = useGetRole({ id: roleIdtoNumber });
  const deleteRole = useDeleteRole({ onSuccess: () => navigate(`../roles`) });
  const confirm = useConfirm();

  return (
    <ViewLayout
      breadcrumbs={[{ label: t("roles_list.title"), href: "../roles" }]}
      title={roleDetails.data?.name}
      action={
        roleDetails.data?.updatable && (
          <AccessControl allowedPermissions={["admin:roles:manage"]}>
            <Button
              variant="contained"
              disabled={deleteRole.isExecuting}
              onClick={() =>
                confirm.handlePrompt(() =>
                  deleteRole.execute({ roleId: roleIdtoNumber }),
                )
              }
            >
              {t("common:delete")}
            </Button>
            <ConfirmationDialog
              open={confirm.isOpen}
              title={t("delete_role.title")}
              labels={{
                confirm: t("common:yes"),
                reject: t("common:no"),
              }}
              onConfirm={confirm.handleConfirm}
              onReject={confirm.handleReject}
              content={
                <>
                  <Typography>{t("delete_role.description")}</Typography>
                  <Typography>
                    {t("delete_role.assigned_users", {
                      numberOfUsers: roleDetails.data.usersAssigned,
                    })}
                  </Typography>
                </>
              }
            />
          </AccessControl>
        )
      }
    >
      <ContainedLayout>
        <EditRolePermissionsContainer roleId={roleIdtoNumber} />
      </ContainedLayout>
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: ["admin:roles:manage", "admin:roles:view"],
})(RoleDetailsView);
