/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The contact point type
 * @export
 */
export const CustomerContactPointTypeModel = {
    Email: 'Email',
    HomeTelephone: 'Home Telephone',
    WorkTelephone: 'Work Telephone',
    Fax: 'Fax'
} as const;
export type CustomerContactPointTypeModel = typeof CustomerContactPointTypeModel[keyof typeof CustomerContactPointTypeModel];


export function CustomerContactPointTypeModelFromJSON(json: any): CustomerContactPointTypeModel {
    return CustomerContactPointTypeModelFromJSONTyped(json, false);
}

export function CustomerContactPointTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerContactPointTypeModel {
    return json as CustomerContactPointTypeModel;
}

export function CustomerContactPointTypeModelToJSON(value?: CustomerContactPointTypeModel | null): any {
    return value as any;
}

