import PublishIcon from "@mui/icons-material/Publish";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next/";

interface FileUploaderProps {
  onInput: (file: File) => void;
  loading: boolean;
  prompt: string;
  accept?: {
    types: string[];
    rejectedMessage: string;
  };
}

export default function FileUploader({
  onInput,
  loading,
  prompt,
  accept,
}: FileUploaderProps) {
  const { t } = useTranslation("documents");

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles[0]) {
        return;
      }
      onInput(acceptedFiles[0]);
    },
    [onInput],
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: accept?.types,
    });

  const promptTypography = isDragActive ? (
    <Typography>{t("file_uploader_active_drag_text")}</Typography>
  ) : (
    <Typography>{prompt}</Typography>
  );

  return (
    <>
      <Box
        height={200}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        border={1}
        sx={(theme) => ({
          border: `1px dashed ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.grey[100],
          cursor: "pointer",
        })}
        {...getRootProps()}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <input
              aria-label={t("file_uploader_label")}
              {...getInputProps()}
            ></input>
            <Box marginBottom={1} fontSize={60}>
              <PublishIcon fontSize="inherit" color="primary" />
            </Box>
            {promptTypography}
          </>
        )}
      </Box>

      {!!fileRejections[0] && accept && (
        <Box paddingTop={1}>
          <Typography color="error" data-testid="form.error">
            {accept.rejectedMessage}
          </Typography>
        </Box>
      )}
    </>
  );
}
