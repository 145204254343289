import { Box, Container, Typography } from "@mui/material";
import { AgreementsContainer } from "apps/servicing/modules/agreements/containers";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function AgreementsView(): ReactElement {
  const { t } = useTranslation("agreements");

  return (
    <>
      <Box marginY={3}>
        <Container maxWidth="xl">
          <Typography component="h1" variant="h2">
            {t("agreements.heading")}
          </Typography>
        </Container>
      </Box>
      <Box marginY={3}>
        <Container maxWidth="xl">
          <AgreementsContainer />
        </Container>
      </Box>
    </>
  );
}
