import { AmountField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Alert,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { useListFees } from "api/onboarding/fees/listFees";
import { useGetFinancialProductDetails } from "api/onboarding/financial-products/getFinancialProductDetails";
import OnboardingFeeList from "apps/onboarding/components/OnboardingFeeList";
import {
  OnboardingFeeDetailsModel,
  OnboardingFeeModel,
} from "apps/onboarding/containers";
import { FeeTypeEnum } from "apps/onboarding/types";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import {
  FeeTypeModel,
  FinancialProductFeeListItemModel,
} from "generated/onboarding/models";
import { ReactElement, useState } from "react";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface OnboardingAssetListProps {
  financialProductId: number;
  fees: OnboardingFeeModel[];
  setFeesList: (fees: OnboardingFeeModel[]) => void;
}

const amountLabel = "fees_and_commissions.fees.amount_label";

function useFeeResolver(): Resolver<OnboardingFeeModel> {
  const { t } = useTranslation("onboardings");

  return useYupResolver<OnboardingFeeModel>((yup) =>
    yup.object().shape({
      id: yup.string().isRequired(t("fees_and_commissions.fees.name_label")),
      amount: yup
        .number(t(amountLabel))
        .isRequired(t(amountLabel))
        .moreThan(
          0,
          t("common:validation.is_greater_than_zero", {
            label: t(amountLabel),
          }),
        )
        .maxAmount(99999999.99, t(amountLabel)),
    }),
  );
}

type ModelKeys = keyof typeof FeeTypeModel;

function getFeesList(
  onboardingFees: OnboardingFeeModel[],
  financialProductFees: FinancialProductFeeListItemModel[],
): OnboardingFeeDetailsModel[] {
  return onboardingFees.map((onboardingFee) => {
    const feeDetails = financialProductFees.find(
      (fee) => fee.id === Number(onboardingFee.id),
    );

    return {
      ...onboardingFee,
      type: FeeTypeEnum[
        Object.keys(FeeTypeModel).find(
          (key) => FeeTypeModel[key as ModelKeys] === feeDetails?.type,
        ) as ModelKeys
      ],
      deductedFromAdvance: Boolean(feeDetails?.deductedFromAdvance),
      taxable: Boolean(feeDetails?.taxable),
      name: feeDetails?.name ?? "",
      collectByDirectDebit: Boolean(feeDetails?.collectByDirectDebit),
      includeOnInvoices: Boolean(feeDetails?.includeOnInvoices),
    };
  });
}

export default function OnboardingFeesContainer({
  financialProductId,
  fees,
  setFeesList,
}: OnboardingAssetListProps): ReactElement {
  const { t } = useTranslation("onboardings");

  const financialProduct = useGetFinancialProductDetails(financialProductId);
  const feesList = useListFees();
  const [open, setOpen] = useState(false);
  const feeResolver = useFeeResolver();

  const handleDialogClose = (): void => {
    setOpen(false);
  };

  const handleAddAssetDialogOpen = (): void => {
    setOpen(true);
  };

  const handleAddFeeSubmit = (fee: OnboardingFeeModel): void => {
    setFeesList([...fees, fee]);
    handleDialogClose();
  };

  const handleRemoveFeeSubmit = (index: number): void => {
    setFeesList(fees.filter((_, i) => i !== index));
  };

  const currentFeeIds = fees.map((fee) => fee.id);

  const availableFees =
    financialProduct.data?.fees?.filter(
      (financialProductFee) =>
        !currentFeeIds.includes(String(financialProductFee.id)),
    ) ?? [];

  return (
    <>
      <Box alignItems="center" component="header" display="flex" paddingY={2}>
        <Box flex={1}>
          <Typography variant="h6">
            {t("fees_and_commissions.fees_heading")}
          </Typography>
        </Box>
        <Box>
          <Button
            aria-label={t("fees_and_commissions.fees.add_fee_button")}
            color="primary"
            variant="contained"
            onClick={handleAddAssetDialogOpen}
          >
            {t("fees_and_commissions.fees.add_fee_button")}
          </Button>
        </Box>
      </Box>

      <Box marginBottom={3}>
        <FormDialog
          onSubmit={handleAddFeeSubmit}
          resolver={feeResolver}
          title={t("fees_and_commissions.fees.add_fee_title")}
          onClose={handleDialogClose}
          open={open}
          disabled={availableFees?.length === 0}
          defaultValues={{ id: "" }}
        >
          {(form): ReactElement => {
            return (
              <>
                {availableFees?.length === 0 && (
                  <Alert severity="warning">
                    {t("fees_and_commissions.fees.no_fees_available")}
                  </Alert>
                )}

                {availableFees?.length !== 0 && (
                  <Grid container spacing={2} rowSpacing={0.5}>
                    <Grid item sm={6}>
                      <ControlledTextField
                        id="id"
                        name="id"
                        label={t("fees_and_commissions.fees.name_label")}
                        helperText={form.formState.errors.id?.message}
                        error={form.formState.errors.id?.message}
                        SelectProps={{ displayEmpty: true }}
                        control={form.control}
                        select
                        required
                      >
                        <MenuItem value="">
                          <i>{t("common:please_select")}</i>
                        </MenuItem>

                        {availableFees?.map((fee) => (
                          <MenuItem key={fee.id} value={fee.id}>
                            {fee.name}
                          </MenuItem>
                        ))}
                      </ControlledTextField>
                    </Grid>
                    <Grid item sm={6}>
                      <AmountField
                        control={form.control}
                        label={t("fees_and_commissions.fees.amount_label")}
                        name="amount"
                        error={Boolean(form.formState.errors.amount?.message)}
                        helperText={form.formState.errors.amount?.message}
                        defaultValue={form.formState.defaultValues?.amount}
                        required
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            );
          }}
        </FormDialog>

        <Paper>
          <OnboardingFeeList
            data={getFeesList(fees, feesList.data ?? [])}
            onRemove={handleRemoveFeeSubmit}
          />
        </Paper>
      </Box>
    </>
  );
}
