/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEmailModel
 */
export interface CreateEmailModel {
    /**
     * An email address
     * @type {string}
     * @memberof CreateEmailModel
     */
    recipient: string;
    /**
     * The subject line of the email address
     * @type {string}
     * @memberof CreateEmailModel
     */
    subject: string;
    /**
     * The body text of the email
     * @type {string}
     * @memberof CreateEmailModel
     */
    body: string;
    /**
     * A read write Id
     * @type {number}
     * @memberof CreateEmailModel
     */
    agreementId: number;
    /**
     * A read write Id
     * @type {number}
     * @memberof CreateEmailModel
     */
    portfolioId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateEmailModel
     */
    attachments?: Array<string>;
}

/**
 * Check if a given object implements the CreateEmailModel interface.
 */
export function instanceOfCreateEmailModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recipient" in value;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "agreementId" in value;

    return isInstance;
}

export function CreateEmailModelFromJSON(json: any): CreateEmailModel {
    return CreateEmailModelFromJSONTyped(json, false);
}

export function CreateEmailModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmailModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipient': json['recipient'],
        'subject': json['subject'],
        'body': json['body'],
        'agreementId': json['agreementId'],
        'portfolioId': !exists(json, 'portfolioId') ? undefined : json['portfolioId'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
    };
}

export function CreateEmailModelToJSON(value?: CreateEmailModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipient': value.recipient,
        'subject': value.subject,
        'body': value.body,
        'agreementId': value.agreementId,
        'portfolioId': value.portfolioId,
        'attachments': value.attachments,
    };
}

