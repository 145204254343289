import { useFormat } from "@ldms/mui-sdk/formatting";
import { FormDialog } from "@ldms/mui-sdk/templates";
import OnboardingAssetFieldSet from "apps/onboarding/components/OnboardingAssetFieldSet";
import { OnboardingAssetFormModel } from "apps/onboarding/containers";
import { useYupResolver } from "common/hooks";
import { AssetTypeModel } from "generated/core/models";
import { ProductTypeModel } from "generated/onboarding/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const costPriceExVatLabel = "asset_dialog.cost_price_excluding_vat_label";
const vatLabel = "asset_dialog.vat_label";
const fleetDiscountLabel = "asset_dialog.fleet_discount_label";
const manufacturerSubsidyLabel = "asset_dialog.manufacturer_subsidy_label";
const schemeCodeLabel = "asset_dialog.scheme_code_label";

const transformField = (v: string, o: string): string | null | undefined => {
  return o === "" ? null : v;
};

interface OnboardingAssetDialogProps {
  assetTypes: AssetTypeModel[];
  labels: {
    title: string;
    submitButton: string;
  };
  open: boolean;
  defaultValues?: Partial<OnboardingAssetFormModel>;
  onSubmit(assetModel: OnboardingAssetFormModel): void;
  onClose(): void;
  isFundedVat?: boolean;
  product: ProductTypeModel;
}

export default function OnboardingAssetDialog({
  assetTypes,
  labels,
  open,
  onSubmit: onSubmitCallback,
  onClose,
  defaultValues = {
    typeCode: "",
    assetCategory: "",
    newOrUsed: "",
    vatCode: "",
    includeInsurance: true,
    serviceProviderId: null,
  },
  isFundedVat,
  product,
}: OnboardingAssetDialogProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const { formatAmount } = useFormat();

  const resolver = useYupResolver<OnboardingAssetFormModel>((yup) => {
    return yup.object().shape({
      typeCode: yup.string().isRequired(t("asset_dialog.type_label")),
      description: yup
        .string()
        .isRequired(t("asset_dialog.description_label"))
        .maxCharacters(100, t("asset_dialog.description_label")),
      manufacturerDescription: yup
        .string()
        .maxCharacters(50, t("asset_dialog.manufacturer_description_label")),
      model: yup.string().maxCharacters(50, t("asset_dialog.model_label")),
      newOrUsed: yup.string().isRequired(t("asset_dialog.new_or_used_label")),
      registrationNumber: yup
        .string()
        .maxCharacters(20, t("asset_dialog.registration_number_label")),
      registrationDate: yup.date().localDate().nullable(),
      yearOfManufacture: yup
        .number(t("asset_dialog.year_of_manufacture_label"))
        .nullable()
        .transform(transformField)
        .between(
          1900,
          new Date().getFullYear() + 1,
          t("form_validation.year_of_manufacture_must_be_between", {
            label: t("asset_dialog.year_of_manufacture_label"),
            lowerLimit: 1900,
            upperLimit: new Date().getFullYear() + 1,
          }),
        ),
      serialNumber: yup
        .string()
        .maxCharacters(50, t("asset_dialog.serial_number_label")),
      vehicleIdentificationNumber: yup
        .string()
        .maxCharacters(
          50,
          t("asset_dialog.vehicle_identification_number_label"),
        ),
      costPriceExVat: yup
        .number(t(costPriceExVatLabel))
        .nullable()
        .transform(transformField)
        .isRequired(t(costPriceExVatLabel))
        .greaterThanAmount(0, t(costPriceExVatLabel))
        .maxAmount(99999999.99, t(costPriceExVatLabel)),
      vat: yup
        .number(t(vatLabel))
        .nullable()
        .transform(transformField)
        .maxAmount(99999999.99, t(vatLabel))
        .minAmount(0, t(vatLabel))
        .test({
          name: "fundedVAT",
          message: t("asset_dialog.vat_error_label"),
          test: (value) => {
            if (isFundedVat) {
              if (value === undefined) {
                return false;
              }
              return value > 0;
            }
            return true;
          },
        }),
      hpiRegisteredDate: yup.date().localDate().nullable(),
      assetBanding: yup
        .string()
        .maxCharacters(5, t("asset_dialog.asset_banding_label")),
      fleetDiscount: yup
        .number(t(fleetDiscountLabel))
        .nullable()
        .minAmount(0, t(fleetDiscountLabel))
        .lessThan(
          yup.ref("costPriceExVat"),
          t("asset_dialog.fleet_discount_error_label"),
        ),
      manufacturerSubsidy: yup
        .number(t(manufacturerSubsidyLabel))
        .nullable()
        .transform(transformField)
        .minAmount(0, t(manufacturerSubsidyLabel))
        .greaterThanAmount(0, t(manufacturerSubsidyLabel))
        .lessThan(
          yup.ref("costPriceExVat"),
          t("asset_dialog.manufacturer_subsidy_error_label"),
        ),
      serviceProviderId: yup
        .object()
        .nullable()
        .test({
          name: "serviceProviderDependencies",
          message: t("asset_dialog.invalid_manufacturer_dependency_label"),
          test: (value, testContext) => {
            if (testContext.parent.manufacturerSubsidy) {
              return Boolean(value);
            }
            return true;
          },
        }),
      schemeCode: yup
        .string()
        .nullable()
        .maxCharacters(50, t(schemeCodeLabel))
        .test({
          name: "schemeCodeDependencies",
          message: t("asset_dialog.invalid_scheme_code_dependency_label"),
          test: (value, testContext) => {
            if (testContext.parent.manufacturerSubsidy) {
              return Boolean(value);
            }
            return true;
          },
        }),
      vatCode: yup
        .string()
        .nullable()
        .transform(transformField)
        .test({
          name: "vatCodeDependencies",
          message: t("asset_dialog.invalid_vat_code_id_dependency_label"),
          test: (value, testContext) => {
            if (testContext.parent.manufacturerSubsidy) {
              return Boolean(value);
            }
            return true;
          },
        }),
    });
  });

  const onSubmit = (formData: OnboardingAssetFormModel): void => {
    const yearOfManufacture = formData.yearOfManufacture
      ? parseInt(String(formData.yearOfManufacture))
      : undefined;
    const registrationDate = formData.registrationDate
      ? new Date(formData.registrationDate)
      : undefined;
    const hpiRegisteredDate = formData.hpiRegisteredDate
      ? new Date(formData.hpiRegisteredDate)
      : undefined;

    return onSubmitCallback({
      ...formData,
      registrationDate: registrationDate,
      hpiRegisteredDate: hpiRegisteredDate,
      yearOfManufacture: yearOfManufacture,
      costPriceExVat: Number(formData.costPriceExVat),
      vat: Number(formData.vat) || 0,
      includeInsurance: Boolean(formData.includeInsurance),
      fleetDiscount:
        product === ProductTypeModel.FixedRateHirePurchase
          ? formData.fleetDiscount
          : 0,
      manufacturerSubsidy:
        product === ProductTypeModel.FixedRateHirePurchase ||
        product === ProductTypeModel.FixedRateFinanceLease
          ? formData.manufacturerSubsidy
          : undefined,
      vatCode: formData.vatCode ?? "",
    });
  };

  const defaultType = assetTypes.find((assetType: AssetTypeModel) => {
    return assetType.code === defaultValues.typeCode;
  });

  return (
    <FormDialog
      dialogProps={{
        fullWidth: true,
        maxWidth: "sm",
      }}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      defaultValues={{
        typeCode: defaultType?.code || "",
        ...defaultValues,
      }}
      title={labels.title}
      labels={{ cancel: t("common:cancel"), submit: labels.submitButton }}
      resolver={resolver}
    >
      {(form) => {
        const costPriceExVatValue = Number(form.watch("costPriceExVat"));
        const vatAmount = Number(form.watch("vat"));
        const fleetDiscount = Number(form.watch("fleetDiscount"));
        const costPriceIncVat = costPriceExVatValue + (vatAmount || 0);
        const cashPriceOfAsset = costPriceExVatValue - (fleetDiscount || 0);
        const costPriceIncVatValue = costPriceIncVat
          ? formatAmount(Number(costPriceIncVat).toFixed(2))
          : "-";
        const cashPriceOfAssetValue = cashPriceOfAsset
          ? formatAmount(Number(cashPriceOfAsset).toFixed(2))
          : "-";

        return (
          <OnboardingAssetFieldSet
            costPriceIncVat={costPriceIncVatValue}
            cashPriceOfAsset={cashPriceOfAssetValue}
            assetTypes={assetTypes}
            errors={form.formState.errors}
            control={form.control}
            register={form.register}
            product={product}
          />
        );
      }}
    </FormDialog>
  );
}
