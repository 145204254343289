/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (report)
 * API for reporting within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CaisReportListItemModel
 */
export interface CaisReportListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof CaisReportListItemModel
     */
    readonly id: number;
    /**
     * The source code
     * @type {string}
     * @memberof CaisReportListItemModel
     */
    sourceCode: string;
    /**
     * The company or portfolio name
     * @type {string}
     * @memberof CaisReportListItemModel
     */
    companyOrPortfolioName: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof CaisReportListItemModel
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the CaisReportListItemModel interface.
 */
export function instanceOfCaisReportListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sourceCode" in value;
    isInstance = isInstance && "companyOrPortfolioName" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function CaisReportListItemModelFromJSON(json: any): CaisReportListItemModel {
    return CaisReportListItemModelFromJSONTyped(json, false);
}

export function CaisReportListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaisReportListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sourceCode': json['sourceCode'],
        'companyOrPortfolioName': json['companyOrPortfolioName'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function CaisReportListItemModelToJSON(value?: CaisReportListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceCode': value.sourceCode,
        'companyOrPortfolioName': value.companyOrPortfolioName,
        'createdAt': (value.createdAt.toISOString().substring(0,10)),
    };
}

