import { useFormat } from "@ldms/mui-sdk/formatting";
import { AmountField, Form } from "@ldms/mui-sdk/forms";
import { Box, Typography } from "@mui/material";
import {
  FinancialDetailsBalanceTable,
  OnboardingStepActions,
} from "apps/onboarding/components";
import { useOnboarding } from "apps/onboarding/providers";
import { KeyValueRow, KeyValueTable } from "common/components";
import CashDepositField from "common/components/CashDepositField";
import { useYupResolver } from "common/hooks";
import { useStepper } from "common/providers";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const balloonPaymentLabel = "finance_lease.balloon_payment_label";
const cashDepositLabel = "finance_lease.cash_deposit_label";

export interface CommercialLoanFinancialDetailsFormModel {
  balloonPayment?: number;
  cashDeposit?: number;
}

const transformField = (v: string, o: string): string | null | undefined => {
  return o === "" ? undefined : v;
};

const getValue = (amount?: number): number => {
  return amount ? Number(amount) : 0;
};

export default function CommercialLoanFinancialDetails(): ReactElement {
  const { t } = useTranslation("onboardings");
  const onboarding = useOnboarding();
  const stepper = useStepper();
  const { formatAmount } = useFormat();

  const resolver = useYupResolver<CommercialLoanFinancialDetailsFormModel>(
    (yup) =>
      yup.object().shape({
        balloonPayment: yup
          .number(t(balloonPaymentLabel))
          .label(t(balloonPaymentLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(balloonPaymentLabel))
          .maxAmount(99999999.99, t(balloonPaymentLabel)),
        cashDeposit: yup
          .number(t(balloonPaymentLabel))
          .label(t(cashDepositLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(cashDepositLabel))
          .maxAmount(99999999.99, t(cashDepositLabel)),
      }),
  );

  const defaultValues = onboarding.state
    .financialDetails as CommercialLoanFinancialDetailsFormModel;

  const onSubmit = (data: CommercialLoanFinancialDetailsFormModel): void => {
    onboarding.submitFinancialDetails(data);
    stepper.next();
  };

  return (
    <>
      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h1" }}>
          {t("financial_details.title")}
        </Typography>
      </Box>
      <KeyValueTable testId="financialDetails.table">
        <KeyValueRow
          align="right"
          label={t("commercial_loan.total_net_cash_price_label")}
        >
          {formatAmount(
            (onboarding.state.assetDetails?.totalNetCashPrice ?? 0).toFixed(2),
          )}
        </KeyValueRow>
      </KeyValueTable>
      <Form
        defaultValues={defaultValues}
        label={t("financial_details.title")}
        onSubmit={onSubmit}
        resolver={resolver}
      >
        {(form) => {
          const formWatch = form.watch();
          const totalNetCashPrice =
            onboarding.state.assetDetails?.totalNetCashPrice ?? 0;
          const totalDeposit = getValue(formWatch.cashDeposit);
          const balanceFinanced = totalNetCashPrice - totalDeposit;

          return (
            <>
              <AmountField
                control={form.control}
                label={t(balloonPaymentLabel)}
                name="balloonPayment"
                error={Boolean(form.formState.errors.balloonPayment?.message)}
                helperText={form.formState.errors.balloonPayment?.message}
                defaultValue={defaultValues?.balloonPayment}
              />
              <CashDepositField
                error={Boolean(form.formState.errors.cashDeposit?.message)}
                control={form.control}
                helperText={form.formState.errors.cashDeposit?.message}
              />

              <FinancialDetailsBalanceTable
                totalDeposit={{
                  label: "finance_lease.total_deposit_label",
                  amount: formatAmount(totalDeposit),
                }}
                balanceFinanced={{
                  label: "finance_lease.balance_financed_label",
                  amount: formatAmount(balanceFinanced),
                }}
              />

              <OnboardingStepActions
                back={{
                  label: t("common:stepper.back_button"),
                  onBack: stepper.previous,
                }}
                next={{ label: t("common:stepper.next_button") }}
              />
            </>
          );
        }}
      </Form>
    </>
  );
}
