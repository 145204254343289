import { useFormat } from "@ldms/mui-sdk/formatting";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { LoadableTableHead, NoResults, QueryError } from "common/components";
import { useLocale } from "common/hooks";
import ListLayout from "common/layouts/ListLayout";
import { ChangeEventHandler, MouseEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

export interface FinancialPosting {
  financialDate: Date;
  agreementNumber: string;
  asset?: string;
  accountName: string;
  stageGroup: string;
  stageName: string;
  amount: string;
  financialPeriod: string;
  reference: string;
}

interface FinancialPostingListProps {
  data: FinancialPosting[] | undefined;
  error: Error | undefined;
  rowsPerPage: number;
  page: number;
  total: number;
  loading: boolean;
  onRetry: () => Promise<void>;
  onChangePage(_: MouseEvent<HTMLButtonElement> | null, page: number): void;
  onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement>;
}

export default function FinancialPostingList({
  data,
  error,
  rowsPerPage,
  page,
  total,
  loading,
  onRetry,
  onChangePage,
  onChangeRowsPerPage,
}: FinancialPostingListProps): ReactElement {
  const { t } = useTranslation("agreements");
  const locale = useLocale();
  const { formatAmount } = useFormat();

  return (
    <>
      {!!error || !data ? (
        <QueryError onRetry={onRetry} />
      ) : (
        <>
          <ListLayout
            pagination={{
              onPageChange: onChangePage,
              onRowsPerPageChange: onChangeRowsPerPage,
              rowsPerPage: rowsPerPage,
              page: page,
              count: total || 0,
            }}
          >
            <Table
              aria-label={t("financial_postings.table_name_label")}
              size="small"
              stickyHeader
            >
              <LoadableTableHead
                headings={[
                  t("financial_postings.date_label"),
                  t("financial_postings.agreement_number_label"),
                  t("financial_postings.asset_label"),
                  t("financial_postings.stage_group_label"),
                  t("financial_postings.stage_name_label"),
                  t("financial_postings.account_name_label"),
                  t("financial_postings.amount_label"),
                  t("financial_postings.financial_period_label"),
                  t("financial_postings.reference_label"),
                ]}
                loading={loading}
              />
              <TableBody>
                {data.map((financialPosting) => (
                  <TableRow key={uuidv4()}>
                    <TableCell>
                      {locale.formatDate(financialPosting.financialDate)}
                    </TableCell>
                    <TableCell>{financialPosting.agreementNumber}</TableCell>
                    <TableCell>{financialPosting.asset}</TableCell>
                    <TableCell>{financialPosting.stageGroup}</TableCell>
                    <TableCell>{financialPosting.stageName}</TableCell>
                    <TableCell>{financialPosting.accountName}</TableCell>
                    <TableCell>
                      {formatAmount(financialPosting.amount)}
                    </TableCell>
                    <TableCell>{financialPosting.financialPeriod}</TableCell>
                    <TableCell>{financialPosting.reference}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {data.length === 0 && <NoResults />}
          </ListLayout>
        </>
      )}
    </>
  );
}
