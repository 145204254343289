/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddAgreementFinancialPostingModel,
  AddInsuranceModel,
  AddMemoModel,
  ApplyPaymentHolidayModel,
  ApplySettlementQuoteModel,
  ChargeModel,
  ChequeTransactionModel,
  CreateDirectDebitModel,
  CreateSettlementQuoteModel,
  PayFeesModel,
  RefundTransactionModel,
  RemoveChargeModel,
  RepresentDirectDebitModel,
  ReturnPaymentModel,
  TransactionModel,
  TransferPaymentModel,
  UpdateStatusModel,
  UpdateSubsidyDateModel,
} from '../models/index';
import {
    AddAgreementFinancialPostingModelFromJSON,
    AddAgreementFinancialPostingModelToJSON,
    AddInsuranceModelFromJSON,
    AddInsuranceModelToJSON,
    AddMemoModelFromJSON,
    AddMemoModelToJSON,
    ApplyPaymentHolidayModelFromJSON,
    ApplyPaymentHolidayModelToJSON,
    ApplySettlementQuoteModelFromJSON,
    ApplySettlementQuoteModelToJSON,
    ChargeModelFromJSON,
    ChargeModelToJSON,
    ChequeTransactionModelFromJSON,
    ChequeTransactionModelToJSON,
    CreateDirectDebitModelFromJSON,
    CreateDirectDebitModelToJSON,
    CreateSettlementQuoteModelFromJSON,
    CreateSettlementQuoteModelToJSON,
    PayFeesModelFromJSON,
    PayFeesModelToJSON,
    RefundTransactionModelFromJSON,
    RefundTransactionModelToJSON,
    RemoveChargeModelFromJSON,
    RemoveChargeModelToJSON,
    RepresentDirectDebitModelFromJSON,
    RepresentDirectDebitModelToJSON,
    ReturnPaymentModelFromJSON,
    ReturnPaymentModelToJSON,
    TransactionModelFromJSON,
    TransactionModelToJSON,
    TransferPaymentModelFromJSON,
    TransferPaymentModelToJSON,
    UpdateStatusModelFromJSON,
    UpdateStatusModelToJSON,
    UpdateSubsidyDateModelFromJSON,
    UpdateSubsidyDateModelToJSON,
} from '../models/index';

export interface AddAgreementFinancialPostingRequest {
    agreementId: number;
    addAgreementFinancialPostingModel?: AddAgreementFinancialPostingModel;
}

export interface AddAgreementMemoRequest {
    agreementId: number;
    addMemoModel?: AddMemoModel;
}

export interface AddInsuranceRequest {
    agreementId: number;
    addInsuranceModel?: AddInsuranceModel;
}

export interface ApplyPaymentHolidayRequest {
    agreementId: number;
    applyPaymentHolidayModel?: ApplyPaymentHolidayModel;
}

export interface ApplySettlementQuoteRequest {
    agreementId: number;
    applySettlementQuoteModel?: ApplySettlementQuoteModel;
}

export interface AssociateThirdPartyRequest {
    agreementId: number;
    thirdPartyId: number;
}

export interface CancelPaymentInstructionRequest {
    agreementId: number;
    paymentInstructionId: number;
}

export interface ChequeTransactionRequest {
    agreementId: number;
    chequeTransactionModel?: ChequeTransactionModel;
}

export interface CreateDirectDebitRequest {
    agreementId: number;
    createDirectDebitModel?: CreateDirectDebitModel;
}

export interface CreateSettlementQuoteRequest {
    agreementId: number;
    createSettlementQuoteModel?: CreateSettlementQuoteModel;
}

export interface DisassociateThirdPartyRequest {
    agreementId: number;
    thirdPartyId: number;
}

export interface PayFeesRequest {
    agreementId: number;
    payFeesModel?: PayFeesModel;
}

export interface PostChargeRequest {
    agreementId: number;
    chargeModel?: ChargeModel;
}

export interface RefreshPaymentAllocationRequest {
    agreementId: number;
}

export interface RefundTransactionRequest {
    agreementId: number;
    refundTransactionModel?: RefundTransactionModel;
}

export interface RemoveChargeRequest {
    agreementId: number;
    removeChargeModel?: RemoveChargeModel;
}

export interface RemoveInsuranceRequest {
    agreementId: number;
}

export interface RepresentDirectDebitRequest {
    agreementId: number;
    representDirectDebitModel?: RepresentDirectDebitModel;
}

export interface ReturnPaymentRequest {
    agreementId: number;
    returnPaymentModel?: ReturnPaymentModel;
}

export interface TerminateRequest {
    agreementId: number;
}

export interface TransactionRequest {
    agreementId: number;
    transactionModel?: TransactionModel;
}

export interface TransferPaymentRequest {
    agreementId: number;
    transferPaymentModel?: TransferPaymentModel;
}

export interface UpdateStatusRequest {
    agreementId: number;
    updateStatusModel?: UpdateStatusModel;
}

export interface UpdateSubsidyDateRequest {
    agreementId: number;
    updateSubsidyDateModel?: UpdateSubsidyDateModel;
}

export interface WriteOffRequest {
    agreementId: number;
}

/**
 * 
 */
export class AgreementCommandApi extends runtime.BaseAPI {

    /**
     * Add an agreement financial posting
     */
    async addAgreementFinancialPostingRaw(requestParameters: AddAgreementFinancialPostingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling addAgreementFinancialPosting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-financial-postings:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/financial-postings`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAgreementFinancialPostingModelToJSON(requestParameters.addAgreementFinancialPostingModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add an agreement financial posting
     */
    async addAgreementFinancialPosting(requestParameters: AddAgreementFinancialPostingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addAgreementFinancialPostingRaw(requestParameters, initOverrides);
    }

    /**
     * Add a new agreement memo
     */
    async addAgreementMemoRaw(requestParameters: AddAgreementMemoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling addAgreementMemo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-memos:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/memos`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMemoModelToJSON(requestParameters.addMemoModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a new agreement memo
     */
    async addAgreementMemo(requestParameters: AddAgreementMemoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addAgreementMemoRaw(requestParameters, initOverrides);
    }

    /**
     * Add insurance to an agreement
     */
    async addInsuranceRaw(requestParameters: AddInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling addInsurance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["insurance:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/insurance`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddInsuranceModelToJSON(requestParameters.addInsuranceModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add insurance to an agreement
     */
    async addInsurance(requestParameters: AddInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addInsuranceRaw(requestParameters, initOverrides);
    }

    /**
     * Apply payment holiday
     */
    async applyPaymentHolidayRaw(requestParameters: ApplyPaymentHolidayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling applyPaymentHoliday.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-schedule:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/payment-holiday`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplyPaymentHolidayModelToJSON(requestParameters.applyPaymentHolidayModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Apply payment holiday
     */
    async applyPaymentHoliday(requestParameters: ApplyPaymentHolidayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applyPaymentHolidayRaw(requestParameters, initOverrides);
    }

    /**
     * Apply a settlement quote to an agreement
     */
    async applySettlementQuoteRaw(requestParameters: ApplySettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling applySettlementQuote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-settlement-quotes:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/settlement`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplySettlementQuoteModelToJSON(requestParameters.applySettlementQuoteModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Apply a settlement quote to an agreement
     */
    async applySettlementQuote(requestParameters: ApplySettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applySettlementQuoteRaw(requestParameters, initOverrides);
    }

    /**
     * Associate a third party to an agreement
     */
    async associateThirdPartyRaw(requestParameters: AssociateThirdPartyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling associateThirdParty.');
        }

        if (requestParameters.thirdPartyId === null || requestParameters.thirdPartyId === undefined) {
            throw new runtime.RequiredError('thirdPartyId','Required parameter requestParameters.thirdPartyId was null or undefined when calling associateThirdParty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-third-parties:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/third-parties/{thirdPartyId}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"thirdPartyId"}}`, encodeURIComponent(String(requestParameters.thirdPartyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Associate a third party to an agreement
     */
    async associateThirdParty(requestParameters: AssociateThirdPartyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.associateThirdPartyRaw(requestParameters, initOverrides);
    }

    /**
     * Cancel a payment instruction
     */
    async cancelPaymentInstructionRaw(requestParameters: CancelPaymentInstructionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling cancelPaymentInstruction.');
        }

        if (requestParameters.paymentInstructionId === null || requestParameters.paymentInstructionId === undefined) {
            throw new runtime.RequiredError('paymentInstructionId','Required parameter requestParameters.paymentInstructionId was null or undefined when calling cancelPaymentInstruction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["payment-instructions:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/payment-instructions/{paymentInstructionId}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"paymentInstructionId"}}`, encodeURIComponent(String(requestParameters.paymentInstructionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel a payment instruction
     */
    async cancelPaymentInstruction(requestParameters: CancelPaymentInstructionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelPaymentInstructionRaw(requestParameters, initOverrides);
    }

    /**
     * Post a transaction for a cheque
     */
    async chequeTransactionRaw(requestParameters: ChequeTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling chequeTransaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["cheque-transaction:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/transactions/cheque`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChequeTransactionModelToJSON(requestParameters.chequeTransactionModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post a transaction for a cheque
     */
    async chequeTransaction(requestParameters: ChequeTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.chequeTransactionRaw(requestParameters, initOverrides);
    }

    /**
     * Create a direct debit
     */
    async createDirectDebitRaw(requestParameters: CreateDirectDebitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling createDirectDebit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["direct-debit:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/payment-instructions/direct-debit`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDirectDebitModelToJSON(requestParameters.createDirectDebitModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a direct debit
     */
    async createDirectDebit(requestParameters: CreateDirectDebitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createDirectDebitRaw(requestParameters, initOverrides);
    }

    /**
     * Create a settlement quote for an agreement
     */
    async createSettlementQuoteRaw(requestParameters: CreateSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling createSettlementQuote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-settlement-quotes:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/settlement-quotes`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSettlementQuoteModelToJSON(requestParameters.createSettlementQuoteModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a settlement quote for an agreement
     */
    async createSettlementQuote(requestParameters: CreateSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createSettlementQuoteRaw(requestParameters, initOverrides);
    }

    /**
     * Disassociate a third party from an agreement
     */
    async disassociateThirdPartyRaw(requestParameters: DisassociateThirdPartyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling disassociateThirdParty.');
        }

        if (requestParameters.thirdPartyId === null || requestParameters.thirdPartyId === undefined) {
            throw new runtime.RequiredError('thirdPartyId','Required parameter requestParameters.thirdPartyId was null or undefined when calling disassociateThirdParty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-third-parties:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/third-parties/{thirdPartyId}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"thirdPartyId"}}`, encodeURIComponent(String(requestParameters.thirdPartyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disassociate a third party from an agreement
     */
    async disassociateThirdParty(requestParameters: DisassociateThirdPartyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.disassociateThirdPartyRaw(requestParameters, initOverrides);
    }

    /**
     * Mark a fee as paid
     */
    async payFeesRaw(requestParameters: PayFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling payFees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["pay-fees:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/transactions/pay-fees`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PayFeesModelToJSON(requestParameters.payFeesModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark a fee as paid
     */
    async payFees(requestParameters: PayFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.payFeesRaw(requestParameters, initOverrides);
    }

    /**
     * Post a transaction for a charge
     */
    async postChargeRaw(requestParameters: PostChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling postCharge.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["post-charge:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/transactions/charge`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChargeModelToJSON(requestParameters.chargeModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post a transaction for a charge
     */
    async postCharge(requestParameters: PostChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postChargeRaw(requestParameters, initOverrides);
    }

    /**
     * Refresh payment Allocation
     */
    async refreshPaymentAllocationRaw(requestParameters: RefreshPaymentAllocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling refreshPaymentAllocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["payment-allocation:refresh"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/payment-allocations`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Refresh payment Allocation
     */
    async refreshPaymentAllocation(requestParameters: RefreshPaymentAllocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.refreshPaymentAllocationRaw(requestParameters, initOverrides);
    }

    /**
     * Post a transaction for a refund
     */
    async refundTransactionRaw(requestParameters: RefundTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling refundTransaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["refund-transaction:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/transactions/refund`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefundTransactionModelToJSON(requestParameters.refundTransactionModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post a transaction for a refund
     */
    async refundTransaction(requestParameters: RefundTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.refundTransactionRaw(requestParameters, initOverrides);
    }

    /**
     * Remove a charge
     */
    async removeChargeRaw(requestParameters: RemoveChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling removeCharge.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["remove-charge:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/transactions/remove-charge`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveChargeModelToJSON(requestParameters.removeChargeModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a charge
     */
    async removeCharge(requestParameters: RemoveChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeChargeRaw(requestParameters, initOverrides);
    }

    /**
     * Remove insurance from an agreement
     */
    async removeInsuranceRaw(requestParameters: RemoveInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling removeInsurance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["insurance:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/insurance`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove insurance from an agreement
     */
    async removeInsurance(requestParameters: RemoveInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeInsuranceRaw(requestParameters, initOverrides);
    }

    /**
     * Re-present the last direct debit
     */
    async representDirectDebitRaw(requestParameters: RepresentDirectDebitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling representDirectDebit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["represent-direct-debit:elevated"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/transactions/represent-direct-debit`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentDirectDebitModelToJSON(requestParameters.representDirectDebitModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Re-present the last direct debit
     */
    async representDirectDebit(requestParameters: RepresentDirectDebitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.representDirectDebitRaw(requestParameters, initOverrides);
    }

    /**
     * Return a payment
     */
    async returnPaymentRaw(requestParameters: ReturnPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling returnPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["return-payment:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/transactions/return`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReturnPaymentModelToJSON(requestParameters.returnPaymentModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Return a payment
     */
    async returnPayment(requestParameters: ReturnPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.returnPaymentRaw(requestParameters, initOverrides);
    }

    /**
     * Creates the termination for an agreement
     */
    async terminateRaw(requestParameters: TerminateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling terminate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-termination:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/termination`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates the termination for an agreement
     */
    async terminate(requestParameters: TerminateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.terminateRaw(requestParameters, initOverrides);
    }

    /**
     * Post a transaction for a standard payment
     */
    async transactionRaw(requestParameters: TransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling transaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["transactions:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/transactions/payment`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionModelToJSON(requestParameters.transactionModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post a transaction for a standard payment
     */
    async transaction(requestParameters: TransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.transactionRaw(requestParameters, initOverrides);
    }

    /**
     * Transfer a payment
     */
    async transferPaymentRaw(requestParameters: TransferPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling transferPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["transfer-payment:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/transactions/transfer`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransferPaymentModelToJSON(requestParameters.transferPaymentModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Transfer a payment
     */
    async transferPayment(requestParameters: TransferPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.transferPaymentRaw(requestParameters, initOverrides);
    }

    /**
     * Set the status of an agreement
     */
    async updateStatusRaw(requestParameters: UpdateStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling updateStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-status:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/status`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStatusModelToJSON(requestParameters.updateStatusModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the status of an agreement
     */
    async updateStatus(requestParameters: UpdateStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Update subsidy date
     */
    async updateSubsidyDateRaw(requestParameters: UpdateSubsidyDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling updateSubsidyDate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["subsidy:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/subsidy`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubsidyDateModelToJSON(requestParameters.updateSubsidyDateModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update subsidy date
     */
    async updateSubsidyDate(requestParameters: UpdateSubsidyDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSubsidyDateRaw(requestParameters, initOverrides);
    }

    /**
     * Creates a write off for an agreement
     */
    async writeOffRaw(requestParameters: WriteOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling writeOff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-write-off:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/write-off`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a write off for an agreement
     */
    async writeOff(requestParameters: WriteOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.writeOffRaw(requestParameters, initOverrides);
    }

}
