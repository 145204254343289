import { QueryKey } from "api";

export const all = (): QueryKey => ["financial-postings"];

export const lists = (): QueryKey => [...all(), "list"];

export const list = (params?: {
  agreementId?: number;
  portfolioId?: number;
  accountCode?: string;
  sourceCode?: string;
  stageCode?: string;
  from?: Date;
  to?: Date;
}): QueryKey => [
  ...lists(),
  params?.agreementId,
  params?.portfolioId,
  params?.accountCode,
  params?.sourceCode,
  params?.stageCode,
  params?.from,
  params?.to,
];
