import { QueryKey } from "api";
import { OnboardingStatusModel } from "generated/onboarding/models";

export const all = (): QueryKey => ["onboarding"];

export const lists = (): QueryKey => [...all(), "list"];

export const list = (params?: {
  status?: OnboardingStatusModel;
  from?: Date;
  to?: Date;
}): QueryKey => [...lists(), params?.status, params?.from, params?.to];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (onboardingId: number): QueryKey => [
  ...details(),
  onboardingId,
];
