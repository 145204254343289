import { ApiContext } from "@ldms/mui-sdk/bootstrap";
import { BaseAPI, Configuration } from "generated";
import { useContext } from "react";

const maybeBasePath = (basePath: string | undefined) => {
  return typeof basePath !== "undefined" && basePath.length > 0
    ? basePath
    : undefined;
};

export const useApi = <T extends BaseAPI>(
  Api: {
    new (config: Configuration): T;
  },
  namespace?: string,
): T => {
  const apiContext = useContext(ApiContext);

  const basePath = [
    apiContext.basePath,
    namespace ?? apiContext.defaultNamespace,
  ]
    .filter(Boolean)
    .join("/");

  return new Api(
    new Configuration({
      ...apiContext,
      basePath: maybeBasePath(basePath),
    }),
  );
};
