import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { OnboardingAssetFormModel } from "apps/onboarding/containers";
import { NoResults } from "common/components";
import { ProductTypeModel } from "generated/onboarding/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

interface OnboardingAssetListProps {
  data: OnboardingAssetFormModel[];
  onClone(index: number): void;
  onUpdate(index: number): void;
  onRemove(index: number): void;
  product: ProductTypeModel;
}

export default function OnboardingAssetList({
  data,
  onClone,
  onUpdate,
  onRemove,
  product,
}: OnboardingAssetListProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const { formatAmount } = useFormat();
  const tableHeaders: string[] = [
    t("asset_details.type_label"),
    t("asset_details.description_label"),
    t("asset_details.cash_price_ex_vat_label"),
    t("asset_details.vat_label"),
    t("asset_details.cash_price_inc_vat_label"),
    "",
  ];

  if (product === ProductTypeModel.FixedRateHirePurchase) {
    tableHeaders.splice(4, 0, t("asset_details.fleet_discount_label"));
    tableHeaders.splice(5, 0, t("asset_details.manufacturer_subsidy_label"));
  }

  if (product === ProductTypeModel.FixedRateFinanceLease) {
    tableHeaders.splice(4, 0, t("asset_details.manufacturer_subsidy_label"));
  }

  const makeCloneOnboardingAssetHandler = (index: number) => (): void =>
    onClone(index);

  const makeUpdateOnboardingAssetHandler = (index: number) => (): void =>
    onUpdate(index);

  const makeRemoveOnboardingAssetHandler = (index: number) => (): void =>
    onRemove(index);

  return (
    <>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table
          stickyHeader
          size="small"
          data-testid="onboardingAssetListProps.table"
        >
          <TableHead>
            <TableRow>
              {tableHeaders.map((label) => (
                <TableCell key={label}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={uuidv4()} hover>
                <TableCell>{item.typeCode}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{formatAmount(item.costPriceExVat)}</TableCell>
                <TableCell>
                  {item.vat ? formatAmount(item.vat) : "0.00"}
                </TableCell>
                {product === ProductTypeModel.FixedRateHirePurchase && (
                  <TableCell>
                    {formatAmount(
                      Number(
                        item.fleetDiscount ? item.fleetDiscount : 0,
                      ).toFixed(2),
                    )}
                  </TableCell>
                )}
                {(product === ProductTypeModel.FixedRateHirePurchase ||
                  product === ProductTypeModel.FixedRateFinanceLease) && (
                  <TableCell>
                    {formatAmount(
                      Number(item.manufacturerSubsidy ?? 0).toFixed(2),
                    )}
                  </TableCell>
                )}
                <TableCell>
                  {formatAmount(
                    Number(
                      item.costPriceExVat +
                        item.vat -
                        (item.fleetDiscount ? item.fleetDiscount : 0),
                    ).toFixed(2),
                  )}
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row">
                    <Button
                      color="primary"
                      onClick={makeCloneOnboardingAssetHandler(index)}
                      size="small"
                    >
                      {t("asset_details.clone_asset_button")}
                    </Button>
                    <Button
                      color="primary"
                      onClick={makeUpdateOnboardingAssetHandler(index)}
                      size="small"
                    >
                      {t("asset_details.edit_asset_button")}
                    </Button>
                    <Button
                      color="primary"
                      onClick={makeRemoveOnboardingAssetHandler(index)}
                      size="small"
                    >
                      {t("asset_details.remove_asset_button")}
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(data || []).length === 0 && (
        <NoResults message={t("asset_details.no_assets_added_message")} />
      )}
    </>
  );
}
