import {
  ConfirmationDialog,
  FormDialog,
  Loader,
} from "@ldms/mui-sdk/templates";
import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import {
  useListContactPoints,
  useUpdateContactPoint,
} from "api/customers/contacts/contact-points";
import { ModelKeys } from "apps/servicing/modules/customers/components/ContactPointList/ContactPointList";
import { ContactPoint, ContactPointType } from "apps/servicing/types";
import { ControlledTextField, QueryError } from "common/components";
import { useConfirm, useYupResolver } from "common/hooks";
import {
  ContactPointListItemModel,
  ContactPointModel,
  ContactPointTypeModel,
} from "generated/servicing-v2/models";
import { ReactElement } from "react";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface EditContactPointFieldValues {
  type: ContactPointTypeModel;
  value: string;
  comment?: string;
}

export interface EditContactPoint extends ContactPoint {
  objectSystemId: string;
}

interface EditContactPointContainerProps {
  contactPoint: EditContactPoint;
  onClose(): void;
  open: boolean;
  isInvoiceByEmail: boolean;
  forCustomer: boolean;
}

interface EditContactPointFormDialogProps {
  contactPoint: EditContactPoint;
  contactPoints?: ContactPointListItemModel[];
  onClose(): void;
  open: boolean;
  forCustomer: boolean;
  isInvoiceByEmail: boolean;
}

const useEditContactPointResolver =
  (): Resolver<EditContactPointFieldValues> => {
    const { t } = useTranslation(["clients", "common"]);
    const detailLabel = t("edit_correspondence_method.detail_label");
    return useYupResolver<EditContactPointFieldValues>((yup) =>
      yup.object().shape({
        type: yup
          .string()
          .isRequired(t("edit_correspondence_method.type_label")),
        value: yup
          .string()
          .maxCharacters(200, detailLabel)
          .isRequired(detailLabel),
        comment: yup
          .string()
          .maxCharacters(50, "edit_correspondence_method.comment_label"),
      }),
    );
  };

function EditContactPointFormDialog({
  contactPoint,
  contactPoints = [],
  onClose,
  open,
  forCustomer,
  isInvoiceByEmail,
}: Readonly<EditContactPointFormDialogProps>) {
  const { t } = useTranslation(["clients", "common"]);
  const editContactPoint = useUpdateContactPoint(
    contactPoint.objectSystemId,
    contactPoint.systemId,
    forCustomer,
    { onSuccess: onClose },
  );
  const confirmEditEmailType = useConfirm();
  const resolver = useEditContactPointResolver();

  const currentMethod = contactPoint.type as ContactPointTypeModel;

  const onSubmit = async (data: ContactPointModel): Promise<void> => {
    isInvoiceByEmail &&
    currentMethod === ContactPointTypeModel.Email &&
    data.type !== ContactPointTypeModel.Email
      ? confirmEditEmailType.handlePrompt(async (): Promise<void> => {
          await editContactPoint.execute(data);
        })
      : await editContactPoint.execute(data);
  };

  const availableContactPoints =
    currentMethod &&
    Object.keys(ContactPointTypeModel)
      .filter(
        (key) =>
          !(contactPoints ?? []).some(
            (item) =>
              item.type ===
              ContactPointTypeModel[key as keyof typeof ContactPointTypeModel],
          ),
      )
      .map(
        (key) =>
          ContactPointTypeModel[key as keyof typeof ContactPointTypeModel],
      )
      .concat(currentMethod);

  return (
    <FormDialog
      title={t("edit_correspondence_method.title_label")}
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      resolver={resolver}
      dialogProps={{ maxWidth: "sm" }}
      ready={Boolean(availableContactPoints)}
      defaultValues={{
        type: currentMethod,
        value: contactPoint.value,
        comment: contactPoint.comment,
      }}
      disabled={editContactPoint.isExecuting}
    >
      {(form) => {
        return (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ControlledTextField
                  helperText={form.formState.errors?.type?.message}
                  select
                  control={form.control}
                  id="type"
                  name="type"
                  label={t("edit_correspondence_method.type_label")}
                  error={form.formState.errors?.type?.message}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">
                    <i>{t("common:please_select")}</i>
                  </MenuItem>
                  {availableContactPoints?.map((contactPointType) => {
                    return (
                      <MenuItem value={contactPointType} key={contactPointType}>
                        {
                          ContactPointType[
                            Object.keys(ContactPointTypeModel).find(
                              (key) =>
                                ContactPointTypeModel[key as ModelKeys] ===
                                contactPointType,
                            ) as ModelKeys
                          ]
                        }
                      </MenuItem>
                    );
                  })}
                </ControlledTextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...form.register("value")}
                  error={Boolean(form.formState.errors.value)}
                  helperText={form.formState.errors.value?.message}
                  label={t("edit_correspondence_method.detail_label")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...form.register("comment")}
                  helperText={form.formState.errors.comment?.message}
                  error={Boolean(form.formState.errors.comment)}
                  label={t("edit_correspondence_method.comment_label")}
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>

            <ConfirmationDialog
              content={
                <Typography>
                  {t(
                    "edit_correspondence_method.invoice_by_email_warning_message",
                  )}
                </Typography>
              }
              open={confirmEditEmailType.isOpen}
              onConfirm={confirmEditEmailType.handleConfirm}
              onReject={confirmEditEmailType.handleReject}
              title={t("edit_correspondence_method.title_label")}
              labels={{
                confirm: t("common:yes"),
                reject: t("common:no"),
              }}
            />

            {editContactPoint.error && (
              <Box>
                <Typography color="error">
                  {t("common:error.default")}
                </Typography>
              </Box>
            )}
          </>
        );
      }}
    </FormDialog>
  );
}

export default function EditContactPointContainer({
  contactPoint,
  onClose,
  open,
  forCustomer,
  isInvoiceByEmail,
}: Readonly<EditContactPointContainerProps>): ReactElement {
  const contactPoints = useListContactPoints(
    contactPoint.objectSystemId,
    forCustomer,
  );

  return (
    <Loader
      ready={Boolean(contactPoints.data ?? contactPoints.error)}
      render={() => {
        if (!contactPoints.data || contactPoints.error) {
          return <QueryError onRetry={contactPoints.refetch} />;
        }

        return (
          <EditContactPointFormDialog
            contactPoint={contactPoint}
            open={open}
            onClose={onClose}
            contactPoints={contactPoints.data}
            isInvoiceByEmail={isInvoiceByEmail}
            forCustomer={forCustomer}
          />
        );
      }}
    />
  );
}
