/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The asset manufacturer
 * @export
 * @interface AssetManufacturerModel
 */
export interface AssetManufacturerModel {
    /**
     * The manufacturer code
     * @type {string}
     * @memberof AssetManufacturerModel
     */
    code?: string;
    /**
     * The manufacturer code description
     * @type {string}
     * @memberof AssetManufacturerModel
     */
    description?: string;
}

/**
 * Check if a given object implements the AssetManufacturerModel interface.
 */
export function instanceOfAssetManufacturerModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssetManufacturerModelFromJSON(json: any): AssetManufacturerModel {
    return AssetManufacturerModelFromJSONTyped(json, false);
}

export function AssetManufacturerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetManufacturerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function AssetManufacturerModelToJSON(value?: AssetManufacturerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'description': value.description,
    };
}

