import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ResponseError } from "generated";
import { ContactsApi } from "generated/servicing-v2/apis/ContactsApi";
import { ContactModel } from "generated/servicing-v2/models/ContactModel";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

export const useUpdateContact = (
  customerId: string,
  contactId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(ContactsApi, "v2");
  const swr = useSWRConfig();

  return useCommand<ContactModel, void, ResponseError>(
    (model) =>
      api.updateContact({
        id: contactId,
        contactModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.list(customerId));
        options.onSuccess?.();
      },
    },
  );
};
