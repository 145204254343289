/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The contact point
 * @export
 * @interface ContactPointListItemModel
 */
export interface ContactPointListItemModel {
    /**
     * The contact point type description
     * @type {string}
     * @memberof ContactPointListItemModel
     */
    type: string;
    /**
     * The contact point
     * @type {string}
     * @memberof ContactPointListItemModel
     */
    value: string;
}

/**
 * Check if a given object implements the ContactPointListItemModel interface.
 */
export function instanceOfContactPointListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function ContactPointListItemModelFromJSON(json: any): ContactPointListItemModel {
    return ContactPointListItemModelFromJSONTyped(json, false);
}

export function ContactPointListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPointListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'value': json['value'],
    };
}

export function ContactPointListItemModelToJSON(value?: ContactPointListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'value': value.value,
    };
}

