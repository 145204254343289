/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSubsidyDateModel
 */
export interface UpdateSubsidyDateModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof UpdateSubsidyDateModel
     */
    subsidyDate: Date;
}

/**
 * Check if a given object implements the UpdateSubsidyDateModel interface.
 */
export function instanceOfUpdateSubsidyDateModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subsidyDate" in value;

    return isInstance;
}

export function UpdateSubsidyDateModelFromJSON(json: any): UpdateSubsidyDateModel {
    return UpdateSubsidyDateModelFromJSONTyped(json, false);
}

export function UpdateSubsidyDateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSubsidyDateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subsidyDate': (new Date(json['subsidyDate'])),
    };
}

export function UpdateSubsidyDateModelToJSON(value?: UpdateSubsidyDateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subsidyDate': (value.subsidyDate.toISOString().substring(0,10)),
    };
}

