import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { InsuranceModel } from "generated/core/models";
import { ErrorLike } from "support/error-handler";
import * as keys from "./keys";

type UseGetInsurance = (
  agreementId: number,
) => UseQueryReturn<InsuranceModel, ErrorLike>;

export const useGetInsurance: UseGetInsurance = (agreementId: number) => {
  const api = useApi(AgreementQueryApi);

  return useQuery(keys.detail(agreementId), () =>
    api.getInsurance({ agreementId: agreementId }),
  );
};
