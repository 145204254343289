import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { FeesCommandApi } from "generated/onboarding/apis";
import { AddFeeModel } from "generated/onboarding/models";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

export const useAddFee = (
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(FeesCommandApi);
  const swr = useSWRConfig();

  return useCommand<AddFeeModel, void, ResponseError>(
    (model) =>
      api.addFee({
        addFeeModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.list());
        options.onSuccess?.();
      },
    },
  );
};
