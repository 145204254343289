import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { all } from "api/email/keys";
import { useApi } from "common/providers";
import { EmailApi } from "generated/documents/apis";
import { CreateEmailModel } from "generated/documents/models";
import { useSWRConfig } from "swr";

export const useSendEmail = (
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(EmailApi);
  const swr = useSWRConfig();

  return useCommand<CreateEmailModel, void, ResponseError>(
    (model) =>
      api.sendEmail({
        createEmailModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(all());
        options.onSuccess?.();
      },
    },
  );
};
