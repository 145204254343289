import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import AddFeeContainer from "apps/onboarding/containers/AddFeeContainer";
import ListFeesContainer from "apps/onboarding/containers/ListFeesContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

function FeesView() {
  const { t } = useTranslation("onboardings");

  return (
    <main>
      <ViewLayout title={t("fees.fees_heading")} action={<AddFeeContainer />}>
        <ContainedLayout>
          <ListFeesContainer />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["onboarding:fees:manage"],
})(FeesView);
