import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Button } from "@mui/material";
import { useSubmitOnboardingForReview } from "api/onboarding/review";
import { useConfirm } from "common/hooks";
import { OnboardingStatusModel } from "generated/onboarding/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface SubmitOnboardingForReviewContainerProps {
  onboardingId: number;
  onboardingStatus?: OnboardingStatusModel;
}

export default function SubmitOnboardingForReviewContainer({
  onboardingId,
  onboardingStatus,
}: SubmitOnboardingForReviewContainerProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const confirm = useConfirm();
  const navigate = useNavigate();
  const closeLabel = t("common:alert.close");
  const errorTitleLabel = t("submit_for_review.error_title");

  const onSuccess = (): void => {
    navigate("..");
  };

  const submitOnboardingForReview = useSubmitOnboardingForReview(onboardingId, {
    onSuccess,
  });

  const onSubmitOnboardingForReview = async (): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      await submitOnboardingForReview.execute({ onboardingId });
    });
  };

  const onCloseErrorDialog = (): void => {
    submitOnboardingForReview.reset();
  };

  return (
    <>
      <AccessControl allowedPermissions={["onboarding:onboarding:manage"]}>
        <Button
          aria-label={t("submit_for_review.button_label")}
          variant="contained"
          onClick={onSubmitOnboardingForReview}
          disabled={onboardingStatus !== OnboardingStatusModel.Staged}
        >
          {t("submit_for_review.button_label")}
        </Button>
      </AccessControl>

      <ConfirmationDialog
        content={t("submit_for_review.confirmation_message")}
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("submit_for_review.confirmation_title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        content={submitOnboardingForReview.error?.message}
        labels={{ close: closeLabel }}
        onClose={onCloseErrorDialog}
        open={Boolean(submitOnboardingForReview.error)}
        title={errorTitleLabel}
      />
    </>
  );
}
