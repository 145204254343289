import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { all } from "api/documents/templates/keys";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { PortfolioApi } from "generated/documents/apis";
import { ConfigurePortfolioModel } from "generated/documents/models";

export const useConfigurePortfolio = (
  portfolioId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(PortfolioApi);
  const partialMutate = usePartialMutate();

  return useCommand<ConfigurePortfolioModel, void, ResponseError>(
    (model) =>
      api.configurePortfolio({
        portfolioId,
        configurePortfolioModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(all());
        options.onSuccess?.();
      },
    },
  );
};
