import { Divider, Grid, TextField } from "@mui/material";
import AddressFieldset, {
  AddressFieldsetModel,
} from "common/components/AddressFieldset";
import { ReactNode } from "react";
import { FieldErrors, Path, UseFormRegister } from "support/react-hook-form";

export type ThirdPartyFormValues = {
  telephone: string;
  faxNumber: string;
  emailAddress: string;
} & AddressFieldsetModel;

export type ThirdPartyControlLabels = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  postcode: string;
  telephone: string;
  faxNumber: string;
  emailAddress: string;
};

interface ThirdPartyControlsProps<TFieldValues extends ThirdPartyFormValues> {
  children: ReactNode;
  errors: FieldErrors<TFieldValues>;
  labels: ThirdPartyControlLabels;
  register: UseFormRegister<TFieldValues>;
}

export default function ThirdPartyControls<
  TFieldValues extends ThirdPartyFormValues,
>({
  children,
  errors,
  labels,
  register,
}: ThirdPartyControlsProps<TFieldValues>) {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        {children}
      </Grid>

      <Grid item sm={12}>
        <Divider flexItem variant="fullWidth" />
      </Grid>

      <Grid item>
        <AddressFieldset labels={labels} errors={errors} register={register} />
      </Grid>

      <Grid item sm={12}>
        <Divider flexItem variant="fullWidth" />
      </Grid>

      <Grid item>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sm={6}>
            <TextField
              {...register("telephone" as Path<TFieldValues>)}
              error={Boolean(errors?.telephone)}
              helperText={errors?.telephone?.message as string | undefined}
              label={labels.telephone}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              {...register("faxNumber" as Path<TFieldValues>)}
              error={Boolean(errors?.faxNumber)}
              helperText={errors?.faxNumber?.message as string | undefined}
              label={labels.faxNumber}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              {...register("emailAddress" as Path<TFieldValues>)}
              error={Boolean(errors?.emailAddress)}
              helperText={errors?.emailAddress?.message as string | undefined}
              label={labels.emailAddress}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
