import useQuery, {
  UseQueryOptions,
  UseQueryReturn,
} from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { FeeUnpaidListItemModel } from "generated/core/models";
import { keys } from ".";

type UseListFeesUnpaid = (
  agreementId: number,
  options?: UseQueryOptions,
) => UseQueryReturn<FeeUnpaidListItemModel[]>;

export const useListFeesUnpaid: UseListFeesUnpaid = (
  agreementId: number,
  options = {},
) => {
  const api = useApi(AgreementQueryApi);

  return useQuery(
    keys.list(agreementId),
    () =>
      api.listFeesUnpaid({
        agreementId,
      }),
    options,
  );
};
