import { DescriptionList } from "@ldms/mui-sdk/components";
import { useGetCustomerV2 } from "api/customers/contacts/getCustomer";
import { YesNoValue } from "common/components";
import DevToggle from "common/components/DevToggle/DevToggle";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface CustomerDetailsProps {
  customerId: string;
}

export default function CustomerDetails({
  customerId,
}: Readonly<CustomerDetailsProps>): ReactElement {
  const { t } = useTranslation("clients");
  const locale = useLocale();
  const customerOverview = useGetCustomerV2(customerId);

  return (
    <DescriptionList label={t("customer_details.customer_details_label")}>
      <DescriptionList.Item label={t("customer_details.company_type_label")}>
        {customerOverview?.data?.customerType}
      </DescriptionList.Item>

      {["Partnership", "Sole Trader"].some(
        (ct) => ct === customerOverview?.data?.customerType,
      ) ? (
        <>
          <DescriptionList.Item
            label={t("customer_details.trading_name_label")}
          >
            {customerOverview?.data?.companyName
              ? customerOverview?.data?.companyName
              : "-"}
          </DescriptionList.Item>

          <DescriptionList.Item label={t("customer_details.name_label")}>
            {[
              customerOverview?.data?.title,
              customerOverview?.data?.firstName,
              customerOverview?.data?.secondName,
              customerOverview?.data?.lastName,
            ].join(" ")}
          </DescriptionList.Item>

          <DescriptionList.Item
            label={t("customer_details.date_of_birth_label")}
          >
            {customerOverview?.data?.dateOfBirth
              ? locale.formatDate(customerOverview?.data?.dateOfBirth)
              : "-"}
          </DescriptionList.Item>
          <DescriptionList.Item
            label={t("customer_details.national_identification_number_label")}
          >
            {customerOverview?.data?.nationalIdentificationNumber}
          </DescriptionList.Item>
          <DescriptionList.Item
            label={t("customer_details.customer_identification_number_label")}
          >
            {customerOverview?.data?.customerIdentificationNumber}
          </DescriptionList.Item>
        </>
      ) : (
        <>
          <DescriptionList.Item
            label={t("customer_details.company_name_label")}
          >
            {customerOverview?.data?.companyName}
          </DescriptionList.Item>

          <DescriptionList.Item
            label={t("customer_details.company_registration_number_label")}
          >
            {customerOverview?.data?.companyRegistrationNumber}
          </DescriptionList.Item>

          <DescriptionList.Item
            label={t("customer_details.vat_registration_number_label")}
          >
            {customerOverview?.data?.taxRegistrationNumber}
          </DescriptionList.Item>

          <DescriptionList.Item
            label={t("customer_details.company_size_label")}
          >
            {customerOverview?.data?.companySize}
          </DescriptionList.Item>
          <DescriptionList.Item
            label={t("customer_details.sector_of_economic_activity_label")}
          >
            {customerOverview?.data?.sectorOfEconomicActivityCode}
          </DescriptionList.Item>
          <DescriptionList.Item
            label={t("customer_details.company_sector_label")}
          >
            {customerOverview?.data?.companySectorCode}
          </DescriptionList.Item>
          <DescriptionList.Item
            label={t("customer_details.customer_identification_number_label")}
          >
            {customerOverview?.data?.customerIdentificationNumber}
          </DescriptionList.Item>
        </>
      )}

      <DescriptionList.Item label={t("customer_details.address_label")}>
        {[
          customerOverview?.data?.addressLine1,
          customerOverview?.data?.addressLine2,
          customerOverview?.data?.addressLine3,
          customerOverview?.data?.addressLine4,
          customerOverview?.data?.postcode,
        ]
          .filter(Boolean)
          .join(", ")}
      </DescriptionList.Item>
      <DevToggle feature="dev">
        <DescriptionList.Item
          label={t("customer_details.system_generated_correspondence_label")}
        >
          <YesNoValue
            value={
              customerOverview?.data?.isReceiveSystemGeneratedCorrespondence
            }
          />
        </DescriptionList.Item>
      </DevToggle>
    </DescriptionList>
  );
}
