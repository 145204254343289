import { Box, Button, Typography } from "@mui/material";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";

const UnexpectedError = ({ resetErrorBoundary }: Partial<FallbackProps>) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center" height="100%" paddingTop={4}>
      <Box
        aria-describedby="error-description"
        aria-labelledby="error-label"
        role="alert"
        maxWidth={300}
        textAlign="center"
      >
        <Typography id="error-label" gutterBottom variant="h6">
          {t("error_boundary.unexpected_error_title")}
        </Typography>
        <Typography id="error-description">
          {t("error_boundary.unexpected_error_message")}
        </Typography>
        <Box marginTop={2}>
          <Button
            color="primary"
            onClick={resetErrorBoundary}
            variant="contained"
          >
            {t("error_boundary.unexpected_error_retry_button")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UnexpectedError;
