import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ResponseError } from "generated";
import { BankAccountsApi } from "generated/servicing-v2/apis";
import { UpdateBankAccountModel } from "generated/servicing-v2/models";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

export const useUpdateBankAccount = (
  customerId: string,
  bankAccountId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(BankAccountsApi, "v2");
  const swr = useSWRConfig();

  return useCommand<UpdateBankAccountModel, void, ResponseError>(
    (model) =>
      api.updateBankAccount({
        id: bankAccountId,
        updateBankAccountModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.listBanks(customerId));
        options.onSuccess?.();
      },
    },
  );
};
