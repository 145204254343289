import { Box, Container, Typography } from "@mui/material";
import { CustomersContainer } from "apps/servicing/modules/customers/containers";
import { useTranslation } from "react-i18next";
import { withTranslationLoader } from "sdk/views";

function CustomersView() {
  const { t } = useTranslation("customers");

  return (
    <>
      <Box marginY={3}>
        <Container maxWidth="xl">
          <Typography component="h1" variant="h2">
            {t("customers.heading")}
          </Typography>
        </Container>
      </Box>
      <Box marginY={3}>
        <Container maxWidth="xl">
          <CustomersContainer />
        </Container>
      </Box>
    </>
  );
}

export default withTranslationLoader("customers")(CustomersView);
