import { useQueryPaged, UseQueryPagedReturn } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ClientAttachmentQueryApi } from "generated/core/apis/ClientAttachmentQueryApi";
import { ClientAttachmentListItemModel } from "generated/core/models/ClientAttachmentListItemModel";
import { keys } from ".";

type UseListCustomerAttachments = (
  clientId: number,
  query?: {
    pageSize?: number;
    params?: {
      from?: Date;
      to?: Date;
      type?: string;
      agreement?: string;
    };
  },
) => UseQueryPagedReturn<ClientAttachmentListItemModel>;

export const useListCustomerAttachments: UseListCustomerAttachments = (
  clientId: number,
  query?: {
    pageSize?: number;
    params?: {
      from?: Date;
      to?: Date;
      type?: string;
      agreement?: string;
    };
  },
) => {
  const api = useApi(ClientAttachmentQueryApi);

  return useQueryPaged(
    keys.list(clientId, query),
    async (_key, paging) =>
      api.listClientAttachmentsRaw({
        clientId: clientId,
        limit: paging.pageSize,
        offset: paging.offset,
        from: query?.params?.from,
        to: query?.params?.to,
        type: query?.params?.type,
        agreement: query?.params?.agreement,
      }),
    { limit: query?.pageSize },
  );
};
