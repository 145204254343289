import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { DescriptionList } from "@ldms/mui-sdk/components";
import { useCommand } from "@ldms/mui-sdk/cqrs";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { Calculate } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CurrentPartialSettlementQuoteDetails from "apps/servicing/components/CurrentPartialSettlementQuoteDetails";
import { useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { AssetQueryApi } from "generated/core/apis";
import {
  AssetModel,
  CalculatePartialSettlementQuoteModel,
} from "generated/core/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import formatAmount from "support/format-amount/format-amount";

interface CreateAssetSettlementQuoteContainerProps {
  asset: AssetModel;
}

interface PartialSettlementQuoteFormValues {
  lumpSumPayment: string;
  vat: string | null;
  totalPartialSettlementValue: string | null;
}

const lumpSumPaymentLabel =
  "partial_settlement_calculator.lump_sum_payment_label";

const useCalculatePartialSettlementResolver = () => {
  const { t } = useTranslation("assets");

  const transformField = (v: string, o: string): string | null | undefined => {
    return o === "" ? undefined : v;
  };

  return useYupResolver<PartialSettlementQuoteFormValues>((yup) =>
    yup.object().shape({
      lumpSumPayment: yup
        .number(t(lumpSumPaymentLabel))
        .transform(transformField)
        .greaterThanAmount(0, t(lumpSumPaymentLabel))
        .isRequired(t(lumpSumPaymentLabel)),
    }),
  );
};

export default function CreateAssetPartialSettlementQuoteContainer({
  asset,
}: CreateAssetSettlementQuoteContainerProps) {
  const [partialSettlementData, setPartialSettlementData] = useState<
    CalculatePartialSettlementQuoteModel | undefined
  >(undefined);
  const [disableSave, setDisableSave] = useState(true);
  const [
    openCreateAssetSettlementQuoteDialog,
    setOpenCreateAssetSettlementQuoteDialog,
  ] = useState(false);

  const { t } = useTranslation("assets");
  const api = useApi(AssetQueryApi);
  const resolver = useCalculatePartialSettlementResolver();

  const calculatePartialSettlementQuote = useCommand<
    { assetId: number; amount: string },
    void,
    ResponseError
  >(async (model) => {
    setPartialSettlementData(undefined);
    const result = await api.getCalculatedPartialSettlementQuote(model);
    setPartialSettlementData(result);
  });

  const handleCalculate = (amount: string) => {
    calculatePartialSettlementQuote.execute({ amount, assetId: asset.id });
    setDisableSave(false);
  };

  const openDialog = (): void => {
    setPartialSettlementData(undefined);
    setOpenCreateAssetSettlementQuoteDialog(true);
  };

  const closeDialog = (): void => {
    setPartialSettlementData(undefined);
    setOpenCreateAssetSettlementQuoteDialog(false);
    setDisableSave(true);
  };

  return (
    <>
      <Button variant="contained" onClick={openDialog}>
        {t("partial_settlement_quotes.create_quote.create_button")}
      </Button>
      <FormDialog
        title={t("partial_settlement_quotes.create_quote.create_title")}
        onClose={closeDialog}
        ready={Boolean(asset.agreementId)}
        open={openCreateAssetSettlementQuoteDialog}
        onSubmit={closeDialog}
        resolver={resolver}
        disabled={disableSave}
        defaultValues={{
          lumpSumPayment: "",
        }}
      >
        {(form): ReactElement => {
          return (
            <Stack gap={2}>
              <CurrentPartialSettlementQuoteDetails asset={asset} />
              <Divider sx={{ marginY: 3 }} />
              <Typography variant="h5">
                {t("partial_settlement_calculator.label")}
              </Typography>
              <Grid container alignItems="start" spacing={2}>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("lumpSumPayment")}
                    required
                    label={t(lumpSumPaymentLabel)}
                    margin="none"
                    helperText={form.formState.errors.lumpSumPayment?.message}
                    error={Boolean(form.formState.errors.lumpSumPayment)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("vat")}
                    label={t("partial_settlement_calculator.vat_label")}
                    disabled
                    value={partialSettlementData?.vatAmount ?? 0}
                    margin="none"
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("totalPartialSettlementValue")}
                    label={t(
                      "partial_settlement_calculator.total_partial_settlement_value_label",
                    )}
                    disabled
                    margin="none"
                    value={partialSettlementData?.totalSettlementAmount ?? 0}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    height: "100%",
                  }}
                  margin="none"
                >
                  <Button
                    onClick={() => {
                      if (
                        form.getValues("lumpSumPayment") &&
                        Number(form.getValues("lumpSumPayment")) > 0
                      ) {
                        handleCalculate(form.getValues("lumpSumPayment"));
                      }
                      form.trigger("lumpSumPayment");
                    }}
                    size="small"
                    startIcon={<Calculate />}
                  >
                    {t("partial_settlement_calculator.calculate_button")}
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ marginY: 2 }}>
                <DescriptionList
                  label={t(
                    "partial_settlement_calculator.summary.partial_settlement_calculation",
                  )}
                >
                  <DescriptionList.Item
                    label={t(
                      "partial_settlement_calculator.summary.partial_settlement_rebate_label",
                    )}
                  >
                    {formatAmount(
                      Number(
                        partialSettlementData?.partialSettlementRebate ?? 0,
                      ),
                    )}
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t(
                      "partial_settlement_calculator.summary.new_agreement_instalment_amount_label",
                    )}
                  >
                    {formatAmount(
                      Number(
                        partialSettlementData?.agreementInstalmentAmount ?? 0,
                      ),
                    )}
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t(
                      "partial_settlement_calculator.summary.new_asset_instalment_amount_label",
                    )}
                  >
                    {formatAmount(
                      Number(partialSettlementData?.assetInstalmentAmount ?? 0),
                    )}
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t(
                      "partial_settlement_calculator.summary.new_balloon_instalment_amount_label",
                    )}
                  >
                    {formatAmount(
                      Number(partialSettlementData?.assetBalloonAmount ?? 0),
                    )}
                  </DescriptionList.Item>
                </DescriptionList>
              </Box>
            </Stack>
          );
        }}
      </FormDialog>
    </>
  );
}
