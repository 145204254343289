import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useDeleteContactPoint } from "api/customers/contacts/contact-points/deleteContactPoint";
import EditContactPointContainer from "apps/servicing/modules/customers/containers/EditContactPointContainer";
import { ContactPoint } from "apps/servicing/types";
import { useConfirm } from "common/hooks";
import useModal from "common/hooks/useModal";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface ManageContactPointMenuProps {
  objectSystemId: string;
  contactPoint: ContactPoint;
  forCustomer: boolean;
  isInvoiceByEmail: boolean;
}

export default function ManageContactPointMenu({
  objectSystemId,
  contactPoint,
  forCustomer,
  isInvoiceByEmail,
}: Readonly<ManageContactPointMenuProps>): ReactElement {
  const { t } = useTranslation("clients");
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuElement);
  const edit = useModal();
  const confirmRemove = useConfirm();
  const deleteContactPoint = useDeleteContactPoint(
    objectSystemId,
    contactPoint.systemId,
    forCustomer,
  );

  const handleRemoveClick = (): void => {
    confirmRemove.handlePrompt(async (): Promise<void> => {
      await deleteContactPoint.execute();
      handleMenuClose();
    });
  };

  const handleMenuClose = (): void => {
    setMenuElement(null);
    if (edit.isOpen) {
      edit.close();
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuElement(event.currentTarget);
  };

  const onCloseErrorDialog = (): void => {
    deleteContactPoint.reset();
    handleMenuClose();
  };

  return (
    <>
      <IconButton
        onClick={handleMenuClick}
        aria-label={t("contacts.correspondence_methods_menu_button")}
        color="primary"
      >
        <MoreVert />
      </IconButton>

      <Menu anchorEl={menuElement} onClose={handleMenuClose} open={menuOpen}>
        <MenuItem
          aria-label={t("edit_correspondence_method.edit_button")}
          onClick={edit.open}
        >
          {t("edit_correspondence_method.edit_button")}
        </MenuItem>
        <MenuItem
          aria-label={t("remove_correspondence_method.remove_button")}
          onClick={handleRemoveClick}
        >
          {t("remove_correspondence_method.remove_button")}
        </MenuItem>
      </Menu>

      <EditContactPointContainer
        open={edit.isOpen}
        contactPoint={{
          ...contactPoint,
          objectSystemId: objectSystemId,
          systemId: contactPoint.systemId,
        }}
        onClose={handleMenuClose}
        forCustomer={forCustomer}
        isInvoiceByEmail={isInvoiceByEmail}
      />

      <ConfirmationDialog
        content={
          <Typography>
            {contactPoint.type === "Email" && isInvoiceByEmail
              ? t(
                  "remove_correspondence_method.invoice_by_email_warning_message",
                )
              : t("remove_correspondence_method.confirmation_message")}
          </Typography>
        }
        open={confirmRemove.isOpen}
        onConfirm={confirmRemove.handleConfirm}
        onReject={confirmRemove.handleReject}
        title={t("remove_correspondence_method.title_label")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        content={deleteContactPoint.error?.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(deleteContactPoint.error)}
        title={t("remove_correspondence_method.error_title")}
      />
    </>
  );
}
