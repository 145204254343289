/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Customers within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A memo
 * @export
 * @interface MemoModel
 */
export interface MemoModel {
    /**
     * The name of the creator of the memo
     * @type {string}
     * @memberof MemoModel
     */
    createdBy: string;
    /**
     * The date and time of the memo creation
     * @type {Date}
     * @memberof MemoModel
     */
    createdAt: Date;
    /**
     * The content of the memo
     * @type {string}
     * @memberof MemoModel
     */
    note: string;
    /**
     * The agreement reference number associated to the memo
     * @type {string}
     * @memberof MemoModel
     */
    agreementReference?: string;
    /**
     * The legacy ID associated to the agreement
     * @type {number}
     * @memberof MemoModel
     */
    agreementLegacyId?: number;
}

/**
 * Check if a given object implements the MemoModel interface.
 */
export function instanceOfMemoModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "note" in value;

    return isInstance;
}

export function MemoModelFromJSON(json: any): MemoModel {
    return MemoModelFromJSONTyped(json, false);
}

export function MemoModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemoModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdBy': json['createdBy'],
        'createdAt': (new Date(json['createdAt'])),
        'note': json['note'],
        'agreementReference': !exists(json, 'agreementReference') ? undefined : json['agreementReference'],
        'agreementLegacyId': !exists(json, 'agreementLegacyId') ? undefined : json['agreementLegacyId'],
    };
}

export function MemoModelToJSON(value?: MemoModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': value.createdBy,
        'createdAt': (value.createdAt.toISOString()),
        'note': value.note,
        'agreementReference': value.agreementReference,
        'agreementLegacyId': value.agreementLegacyId,
    };
}

