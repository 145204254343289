/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing financial products within onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateFinancialProductModel,
  FinancialProductDetailsModel,
  UpdateFinancialProductModel,
} from '../models/index';
import {
    CreateFinancialProductModelFromJSON,
    CreateFinancialProductModelToJSON,
    FinancialProductDetailsModelFromJSON,
    FinancialProductDetailsModelToJSON,
    UpdateFinancialProductModelFromJSON,
    UpdateFinancialProductModelToJSON,
} from '../models/index';

export interface AssociateFeesRequest {
    financialProductId: number;
    requestBody: Array<number>;
}

export interface CreateFinancialProductRequest {
    createFinancialProductModel?: CreateFinancialProductModel;
}

export interface GetFinancialProductRequest {
    financialProductId: number;
}

export interface UpdateFinancialProductRequest {
    financialProductId: number;
    updateFinancialProductModel: UpdateFinancialProductModel;
}

/**
 * 
 */
export class FinancialProductApi extends runtime.BaseAPI {

    /**
     * Associates fees to a financial product
     */
    async associateFeesRaw(requestParameters: AssociateFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.financialProductId === null || requestParameters.financialProductId === undefined) {
            throw new runtime.RequiredError('financialProductId','Required parameter requestParameters.financialProductId was null or undefined when calling associateFees.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling associateFees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["financial-products:manage"]);
        }

        const response = await this.request({
            path: `/onboardings/financial-products/{financialProductId}/fees`.replace(`{${"financialProductId"}}`, encodeURIComponent(String(requestParameters.financialProductId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Associates fees to a financial product
     */
    async associateFees(requestParameters: AssociateFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.associateFeesRaw(requestParameters, initOverrides);
    }

    /**
     * Create a financial product
     */
    async createFinancialProductRaw(requestParameters: CreateFinancialProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["financial-products:manage"]);
        }

        const response = await this.request({
            path: `/onboardings/financial-products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFinancialProductModelToJSON(requestParameters.createFinancialProductModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a financial product
     */
    async createFinancialProduct(requestParameters: CreateFinancialProductRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createFinancialProductRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves detail of a financial product
     */
    async getFinancialProductRaw(requestParameters: GetFinancialProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FinancialProductDetailsModel>> {
        if (requestParameters.financialProductId === null || requestParameters.financialProductId === undefined) {
            throw new runtime.RequiredError('financialProductId','Required parameter requestParameters.financialProductId was null or undefined when calling getFinancialProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["financial-products:view"]);
        }

        const response = await this.request({
            path: `/onboardings/financial-products/{financialProductId}`.replace(`{${"financialProductId"}}`, encodeURIComponent(String(requestParameters.financialProductId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FinancialProductDetailsModelFromJSON(jsonValue));
    }

    /**
     * Retrieves detail of a financial product
     */
    async getFinancialProduct(requestParameters: GetFinancialProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FinancialProductDetailsModel> {
        const response = await this.getFinancialProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a financial product
     */
    async updateFinancialProductRaw(requestParameters: UpdateFinancialProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.financialProductId === null || requestParameters.financialProductId === undefined) {
            throw new runtime.RequiredError('financialProductId','Required parameter requestParameters.financialProductId was null or undefined when calling updateFinancialProduct.');
        }

        if (requestParameters.updateFinancialProductModel === null || requestParameters.updateFinancialProductModel === undefined) {
            throw new runtime.RequiredError('updateFinancialProductModel','Required parameter requestParameters.updateFinancialProductModel was null or undefined when calling updateFinancialProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["financial-products:manage"]);
        }

        const response = await this.request({
            path: `/onboardings/financial-products/{financialProductId}`.replace(`{${"financialProductId"}}`, encodeURIComponent(String(requestParameters.financialProductId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFinancialProductModelToJSON(requestParameters.updateFinancialProductModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a financial product
     */
    async updateFinancialProduct(requestParameters: UpdateFinancialProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateFinancialProductRaw(requestParameters, initOverrides);
    }

}
