import { AmountField, DateField } from "@ldms/mui-sdk/forms";
import { Loader } from "@ldms/mui-sdk/templates";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useListThirdParties } from "api/third-parties";
import { useListVatCodes } from "api/vat-codes/listVatCodes";
import { OnboardingAssetFormModel } from "apps/onboarding/containers";
import {
  ControlledTextField,
  KeyValueRow,
  KeyValueTable,
  QueryError,
} from "common/components";
import { AssetTypeModel, VatCodeListItemModel } from "generated/core/models";
import { ProductTypeModel } from "generated/onboarding/models";
import _ from "lodash";
import {
  Children,
  FC,
  ReactElement,
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

enum NewUsed {
  New = "New",
  Used = "Used",
}

enum AssetCategory {
  Hard = "Hard",
  Soft = "Soft",
}

const newUsedValues = Object.values(NewUsed);
const assetCategoryValues = Object.values(AssetCategory);
const pleaseSelectLabel = "common:please_select";

const TwoColumnGrid: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Grid container columnSpacing={2}>
      {Children.map(children, (child) => (
        <Grid item sm={6}>
          {isValidElement(child) && cloneElement(child)}
        </Grid>
      ))}
    </Grid>
  );
};

interface OnBoardingFieldSetProps {
  costPriceIncVat: string;
  cashPriceOfAsset: string;
  assetTypes: AssetTypeModel[];
  errors: FieldErrors<OnboardingAssetFormModel>;
  control: Control<OnboardingAssetFormModel>;
  register: UseFormRegister<OnboardingAssetFormModel>;
  product: ProductTypeModel;
}

interface ManufacturerAutoCompleteProps {
  control: Control<OnboardingAssetFormModel>;
  error: FieldErrors<OnboardingAssetFormModel>;
}

interface ManufacturerSectionProps {
  control: Control<OnboardingAssetFormModel>;
  errors: FieldErrors<OnboardingAssetFormModel>;
  register: UseFormRegister<OnboardingAssetFormModel>;
  vatCodes: VatCodeListItemModel[];
}

interface RegistrationSectionProps {
  control: Control<OnboardingAssetFormModel>;
  errors: FieldErrors<OnboardingAssetFormModel>;
  register: UseFormRegister<OnboardingAssetFormModel>;
}

function RegistrationSection({
  control,
  errors,
  register,
}: RegistrationSectionProps): ReactElement {
  const { t } = useTranslation("onboardings");

  return (
    <TwoColumnGrid>
      <TextField
        {...register("registrationNumber")}
        label={t("asset_dialog.registration_number_label")}
        helperText={errors.registrationNumber?.message}
        error={Boolean(errors.registrationNumber)}
      />

      <DateField
        control={control}
        name="registrationDate"
        error={Boolean(errors.registrationDate?.message)}
        helperText={errors.registrationDate?.message}
        label={t("asset_dialog.registration_date_label")}
      />

      <ControlledTextField
        helperText={errors?.newOrUsed?.message}
        SelectProps={{ displayEmpty: true }}
        error={errors?.newOrUsed?.message}
        control={control}
        id="newOrUsed"
        name="newOrUsed"
        label={t("asset_dialog.new_or_used_label")}
        select
        required
      >
        <MenuItem value="">{t(pleaseSelectLabel)}</MenuItem>
        {newUsedValues.map((newUsed) => (
          <MenuItem key={String(newUsed)} value={String(newUsed)}>
            {newUsed}
          </MenuItem>
        ))}
      </ControlledTextField>

      <TextField
        {...register("yearOfManufacture")}
        label={t("asset_dialog.year_of_manufacture_label")}
        error={Boolean(errors.yearOfManufacture)}
        helperText={errors.yearOfManufacture?.message}
        type="number"
      />

      <TextField
        {...register("vehicleIdentificationNumber")}
        label={t("asset_dialog.vehicle_identification_number_label")}
        error={Boolean(errors.vehicleIdentificationNumber)}
        helperText={errors.vehicleIdentificationNumber?.message}
      />

      <TextField
        {...register("serialNumber")}
        label={t("asset_dialog.serial_number_label")}
        error={Boolean(errors.serialNumber)}
        helperText={errors.serialNumber?.message}
      />
    </TwoColumnGrid>
  );
}

function ManufacturerSection({
  control,
  errors,
  register,
  vatCodes,
}: ManufacturerSectionProps): ReactElement {
  const { t } = useTranslation("onboardings");

  return (
    <>
      <Divider sx={{ marginY: 2 }} />

      <TwoColumnGrid>
        <ManufacturerAutoComplete control={control} error={errors} />

        <TextField
          {...register("manufacturerSubsidy")}
          label={t("asset_dialog.manufacturer_subsidy_label")}
          helperText={errors.manufacturerSubsidy?.message}
          error={Boolean(errors.manufacturerSubsidy)}
        />

        <TextField
          {...register("schemeCode")}
          label={t("asset_dialog.scheme_code_label")}
          helperText={errors.schemeCode?.message}
          error={Boolean(errors.schemeCode)}
        />

        <ControlledTextField
          helperText={errors?.vatCode?.message}
          SelectProps={{ displayEmpty: true }}
          error={errors.vatCode?.message}
          control={control}
          id="vatCode"
          name="vatCode"
          label={t("asset_dialog.manufacturer_vat_code_label")}
          select
        >
          <MenuItem value="">{t(pleaseSelectLabel)}</MenuItem>
          {vatCodes.map((vatCode) => (
            <MenuItem key={vatCode.vatCode} value={vatCode.vatCode}>
              {`${vatCode.description} - ${vatCode.rate}%`}
            </MenuItem>
          ))}
        </ControlledTextField>
      </TwoColumnGrid>
    </>
  );
}

function ManufacturerAutoComplete({
  control,
  error,
}: ManufacturerAutoCompleteProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const [query, setQuery] = useState("");

  const listThirdParties = useListThirdParties({
    params: { query, type: "Manufacturer" },
  });

  const options =
    listThirdParties.data?.results.map((option) => ({
      label: option.name,
      value: option.id,
    })) ?? [];

  const handleQueryChange = useMemo(
    () =>
      _.debounce((_event, newValue) => {
        return setQuery(newValue);
      }, 300),
    [],
  );

  useEffect(() => {
    return handleQueryChange.cancel;
  }, [handleQueryChange]);

  return (
    <Controller
      control={control}
      name="serviceProviderId"
      render={({ field }) => (
        <Autocomplete
          ListboxProps={{ style: { maxHeight: "15rem" }, "aria-busy": true }}
          value={field.value}
          onChange={(_event, newValue) => {
            setQuery(newValue?.label ?? query);
            return field.onChange(newValue);
          }}
          onInputChange={handleQueryChange}
          options={options}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              label={t("asset_dialog.manufacturer_label")}
              error={Boolean(error.serviceProviderId)}
              helperText={error?.serviceProviderId?.message}
              InputProps={{
                ...params.InputProps,
                value: query,
                endAdornment: (
                  <>
                    {(!listThirdParties.data ||
                      listThirdParties.isValidating) && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}

export default function OnboardingAssetFieldSet({
  costPriceIncVat,
  cashPriceOfAsset,
  assetTypes,
  errors,
  control,
  register,
  product,
}: OnBoardingFieldSetProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const listVatCodes = useListVatCodes();

  return (
    <Loader
      ready={Boolean(assetTypes && (listVatCodes.data || listVatCodes.error))}
      render={(): ReactElement => {
        if (listVatCodes.error || !listVatCodes.data) {
          return <QueryError onRetry={listVatCodes.refetch} />;
        }

        return (
          <>
            <Stack spacing={2}>
              <ControlledTextField
                helperText={errors?.typeCode?.message}
                SelectProps={{ displayEmpty: true }}
                error={errors.typeCode?.message}
                control={control}
                id="typeCode"
                name="typeCode"
                label={t("asset_dialog.type_label")}
                select
                required
              >
                <MenuItem value="">{t(pleaseSelectLabel)}</MenuItem>
                {assetTypes.map((assetType: AssetTypeModel) => (
                  <MenuItem key={assetType.code} value={assetType.code}>
                    {assetType.type}
                  </MenuItem>
                ))}
              </ControlledTextField>

              <TextField
                {...register("description")}
                label={t("asset_dialog.description_label")}
                helperText={errors.description?.message}
                error={Boolean(errors.description?.message)}
                required
              />

              <TextField
                {...register("manufacturerDescription")}
                label={t("asset_dialog.manufacturer_description_label")}
                helperText={errors.manufacturerDescription?.message}
                error={Boolean(errors.manufacturerDescription?.message)}
              />

              <TextField
                {...register("model")}
                label={t("asset_dialog.model_label")}
                helperText={errors.model?.message}
                error={Boolean(errors.model?.message)}
              />

              <ControlledTextField
                helperText={errors?.assetCategory?.message}
                SelectProps={{ displayEmpty: true }}
                error={errors?.assetCategory?.message}
                control={control}
                id="assetCategory"
                name="assetCategory"
                label={t("asset_dialog.asset_category_label")}
                select
              >
                <MenuItem value="">{t("common:none")}</MenuItem>
                {assetCategoryValues.map((category) => (
                  <MenuItem key={String(category)} value={String(category)}>
                    {category}
                  </MenuItem>
                ))}
              </ControlledTextField>

              <TextField
                {...register("assetBanding")}
                helperText={errors.assetBanding?.message}
                label={t("asset_dialog.asset_banding_label")}
                error={Boolean(errors.assetBanding)}
              />

              <DateField
                control={control}
                name="hpiRegisteredDate"
                error={Boolean(errors.hpiRegisteredDate?.message)}
                helperText={errors.hpiRegisteredDate?.message}
                label={t("asset_dialog.registered_interest_label")}
              />

              <FormControlLabel
                label={String(t("asset_dialog.include_insurance_label"))}
                labelPlacement="end"
                control={
                  <Controller
                    control={control}
                    name="includeInsurance"
                    render={({ field }): ReactElement => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e): void => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Stack>

            <Divider sx={{ marginY: 2 }} />

            <RegistrationSection
              control={control}
              register={register}
              errors={errors}
            />

            {(product === ProductTypeModel.FixedRateHirePurchase ||
              product === ProductTypeModel.FixedRateFinanceLease) && (
              <ManufacturerSection
                control={control}
                register={register}
                errors={errors}
                vatCodes={listVatCodes.data}
              />
            )}

            <Divider sx={{ marginY: 2 }} />

            <TwoColumnGrid>
              <AmountField
                control={control}
                label={t("asset_dialog.cost_price_excluding_vat_label")}
                name="costPriceExVat"
                error={Boolean(errors.costPriceExVat?.message)}
                helperText={errors.costPriceExVat?.message}
                required
              />
              <AmountField
                control={control}
                label={t("asset_dialog.vat_label")}
                name="vat"
                error={Boolean(errors.vat?.message)}
                helperText={errors.vat?.message}
              />
            </TwoColumnGrid>

            {product === ProductTypeModel.FixedRateHirePurchase && (
              <TwoColumnGrid>
                <AmountField
                  control={control}
                  label={t("asset_dialog.fleet_discount_label")}
                  name="fleetDiscount"
                  error={Boolean(errors.fleetDiscount?.message)}
                  helperText={errors.fleetDiscount?.message}
                />
              </TwoColumnGrid>
            )}

            <Box marginLeft={-2} marginRight={-2}>
              <KeyValueTable colWidth={220} testId="costPriceIncVat">
                <KeyValueRow
                  align="right"
                  label={t("asset_dialog.cost_price_including_vat_label")}
                >
                  {costPriceIncVat}
                </KeyValueRow>
                <>
                  {product === ProductTypeModel.FixedRateHirePurchase && (
                    <KeyValueRow
                      align="right"
                      label={t("asset_dialog.asset_invoice_price_label")}
                    >
                      {cashPriceOfAsset}
                    </KeyValueRow>
                  )}
                </>
              </KeyValueTable>
            </Box>
          </>
        );
      }}
    />
  );
}
