import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import FilterFinancialPostingsContainer from "apps/finance/containers/FilterFinancialPostingsContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { compose } from "lodash/fp";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { withTranslationLoader } from "sdk/views";

function FilterFinancialPostingsView(): ReactElement {
  const { t } = useTranslation("finance");

  return (
    <ViewLayout title={t("financial_postings.heading")}>
      <ContainedLayout>
        <FilterFinancialPostingsContainer />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default compose(
  withTranslationLoader("finance"),
  withProtectedView({
    allowedPermissions: ["finance:asset-financial-postings:view"],
  }),
)(FilterFinancialPostingsView);
