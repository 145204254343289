import { useSession, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import DirectDebit from "apps/finance/containers/DirectDebit";
import { useLocale } from "common/hooks";
import ContainedLayout from "common/layouts/ContainedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useApi } from "common/providers";
import { DirectDebitQueryApi } from "generated/core/apis";
import { DirectDebitSubmissionListItemModel } from "generated/core/models";
import { compose } from "lodash/fp";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { withTranslationLoader } from "sdk/views";
import { downloadAsCSV } from "support/download";
import { ErrorLike } from "support/error-handler";
import useStickySWR from "support/use-sticky-swr";

function DirectDebitSubmissionsView(): ReactElement {
  const { t } = useTranslation("finance");
  const directDebitQueryApi = useApi(DirectDebitQueryApi);
  const directDebitSubmissions = useStickySWR<
    DirectDebitSubmissionListItemModel[],
    ErrorLike
  >(["/finance/direct-debits"], () =>
    directDebitQueryApi.listDirectDebitSubmissions(),
  );
  const locale = useLocale();
  const session = useSession();

  const convertStatus = (status: string): string => {
    switch (status) {
      case "PEND":
        return t("direct_debit_submissions.pending_label");
      case "FILE":
        return t("direct_debit_submissions.file_generated_label");
      default:
        return "";
    }
  };

  const handleExport = () => {
    downloadAsCSV(
      [
        [
          t("direct_debit_submissions.sun_label"),
          t("direct_debit_submissions.submission_date_label"),
          t("direct_debit_submissions.due_date_label"),
          t("direct_debit_submissions.number_of_payments_label"),
          t("direct_debit_submissions.total_value_label"),
          t("direct_debit_submissions.status_label"),
        ],
        ...(directDebitSubmissions.data || []).map((submission) => [
          submission.sun,
          locale.formatISODate(submission.submissionDate),
          locale.formatISODate(submission.dueDate),
          submission.numberOfPayments,
          submission.totalValue,
          convertStatus(submission.status),
        ]),
      ],
      {
        filename: "direct_debit_submissions_summary",
      },
    );
  };

  return (
    <ViewLayout
      title={t("direct_debit_submissions.title")}
      action={
        session.hasPermission("finance:dd-submissions:export") && (
          <Button
            onClick={handleExport}
            startIcon={<Download fontSize="inherit" />}
            variant="contained"
          >
            {t("direct_debit_submissions.export_button_label")}
          </Button>
        )
      }
    >
      <ContainedLayout>
        <DirectDebit />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default compose(
  withTranslationLoader("finance"),
  withProtectedView({
    allowedPermissions: ["finance:dd-submissions:view"],
  }),
)(DirectDebitSubmissionsView);
