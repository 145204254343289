/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Customers within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateMemoRequestModel,
  ErrorWithParametersModel,
  MemoModel,
} from '../models/index';
import {
    CreateMemoRequestModelFromJSON,
    CreateMemoRequestModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
    MemoModelFromJSON,
    MemoModelToJSON,
} from '../models/index';

export interface CreateMemoRequest {
    id: string;
    createMemoRequestModel: CreateMemoRequestModel;
}

export interface ListCustomerMemosRequest {
    id: string;
    from?: Date;
}

/**
 * 
 */
export class CustomerMemosApi extends runtime.BaseAPI {

    /**
     * API to create a memo
     */
    async createMemoRaw(requestParameters: CreateMemoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createMemo.');
        }

        if (requestParameters.createMemoRequestModel === null || requestParameters.createMemoRequestModel === undefined) {
            throw new runtime.RequiredError('createMemoRequestModel','Required parameter requestParameters.createMemoRequestModel was null or undefined when calling createMemo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customer:memos:manage"]);
        }

        const response = await this.request({
            path: `/customers/{id}/memos`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMemoRequestModelToJSON(requestParameters.createMemoRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * API to create a memo
     */
    async createMemo(requestParameters: CreateMemoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createMemoRaw(requestParameters, initOverrides);
    }

    /**
     * API to list Customer memos
     */
    async listCustomerMemosRaw(requestParameters: ListCustomerMemosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemoModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listCustomerMemos.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customer:memos:view"]);
        }

        const response = await this.request({
            path: `/customers/{id}/memos`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemoModelFromJSON));
    }

    /**
     * API to list Customer memos
     */
    async listCustomerMemos(requestParameters: ListCustomerMemosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemoModel>> {
        const response = await this.listCustomerMemosRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
