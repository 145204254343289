import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import ListLetterTemplatesContainer from "apps/documents/containers/ListLetterTemplatesContainer/ListLetterTemplatesContainer";
import ContainedLayout from "common/layouts/ContainedLayout";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

function LetterTemplatesView(): ReactElement {
  const { t } = useTranslation("documents");

  return (
    <ViewLayout title={t("letter_templates.heading")}>
      <FixedLayout>
        <ContainedLayout>
          <ListLetterTemplatesContainer />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}

export default withProtectedView({
  allowedPermissions: ["documents:templates:manage"],
})(LetterTemplatesView);
