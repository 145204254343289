import { i18n as sdkI18n } from "@ldms/mui-sdk/bootstrap";
import i18n, { i18n as I18n, InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const config: InitOptions = {
  debug: process.env.NODE_ENV === "development",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  load: "languageOnly",
  react: {
    useSuspense: false, // disable experimental feature
  },
  backend: {
    loadPath: `/servicing/locales/{{lng}}/{{ns}}.json?version=${__ASSET_VERSION__}`,
  },
};

export default function makeI18n(overrides: InitOptions = {}): I18n {
  i18n
    .use(LanguageDetector)
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(HttpBackend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({ ...config, ...overrides, detection: { order: ["localStorage"] } })
    .catch(console.error);

  i18n.on("languageChanged", (lng) => {
    sdkI18n.changeLanguage(lng);
  });

  return i18n;
}
