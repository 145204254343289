import { Box } from "@mui/material";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { limitResizeObserverAnimationFrames } from "support/resize-observer";

interface FixedLayoutProps {
  children: ReactNode;
}

export default function FixedLayout({ children }: FixedLayoutProps) {
  const targetRef = useRef<HTMLElement>(document.createElement("div"));
  const [height, setHeight] = useState<number>();
  const isHeight = typeof height !== "undefined";

  const resizeObserverRef = useRef(
    new ResizeObserver(
      limitResizeObserverAnimationFrames((entries) => {
        setHeight(entries[0].contentRect.height);
      }),
    ),
  );

  useLayoutEffect(() => {
    const { current: target } = targetRef;
    const { current: resizeObserver } = resizeObserverRef;
    resizeObserver.observe(target);

    const handleResize = () => setHeight(window.innerHeight - target.offsetTop);
    window.addEventListener("resize", handleResize);

    if (!isHeight) {
      setHeight(target.getBoundingClientRect().height);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      resizeObserver.unobserve(target);
    };
  }, [isHeight, targetRef, resizeObserverRef]);

  return (
    <Box ref={targetRef} maxHeight="100%" height="100%" overflow="hidden">
      {isHeight && (
        <Box overflow="hidden" style={{ height }}>
          {children}
        </Box>
      )}
    </Box>
  );
}
