import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { list } from "api/charge-types/history/keys";
import { detail } from "api/charge-types/keys";
import { useApi } from "common/providers";
import { ChargeTypeApi } from "generated/servicing-v2/apis";
import { UpdateChargeTypeDetailsModel } from "generated/servicing-v2/models";
import { useSWRConfig } from "swr";

export const useUpdateChargeTypeDetails = (
  id: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(ChargeTypeApi, "v2");
  const swr = useSWRConfig();

  return useCommand<UpdateChargeTypeDetailsModel, void, ResponseError>(
    (model) =>
      api.updateChargeTypeDetails({
        id: id,
        updateChargeTypeDetailsModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(detail(id));
        swr.mutate(list(id));
        options.onSuccess?.();
      },
    },
  );
};
