import { useQuery, UseQueryReturn } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/assets/keys";
import { useApi } from "common/providers/ApiProvider";
import { AssetQueryApi } from "generated/core/apis/AssetQueryApi";
import { AssetModel } from "generated/core/models/AssetModel";
import { ErrorLike } from "support/error-handler";

type UseGetAsset = (
  assetId: number,
  params?: { current?: boolean },
) => UseQueryReturn<AssetModel, ErrorLike>;

export const useGetAsset: UseGetAsset = (
  assetId: number,
  params?: { current?: boolean },
) => {
  const api = useApi(AssetQueryApi);

  return useQuery(keys.details(assetId), () =>
    api.getAsset({ assetId, ...params }),
  );
};
