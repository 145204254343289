/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Attachments within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentTypeModel } from './AttachmentTypeModel';
import {
    AttachmentTypeModelFromJSON,
    AttachmentTypeModelFromJSONTyped,
    AttachmentTypeModelToJSON,
} from './AttachmentTypeModel';

/**
 * An attachment to upload
 * @export
 * @interface AttachmentModel
 */
export interface AttachmentModel {
    /**
     * 
     * @type {string}
     * @memberof AttachmentModel
     */
    filename: string;
    /**
     * 
     * @type {AttachmentTypeModel}
     * @memberof AttachmentModel
     */
    attachmentType: AttachmentTypeModel;
    /**
     * 
     * @type {string}
     * @memberof AttachmentModel
     */
    description?: string;
}

/**
 * Check if a given object implements the AttachmentModel interface.
 */
export function instanceOfAttachmentModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "attachmentType" in value;

    return isInstance;
}

export function AttachmentModelFromJSON(json: any): AttachmentModel {
    return AttachmentModelFromJSONTyped(json, false);
}

export function AttachmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
        'attachmentType': AttachmentTypeModelFromJSON(json['attachmentType']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function AttachmentModelToJSON(value?: AttachmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'attachmentType': AttachmentTypeModelToJSON(value.attachmentType),
        'description': value.description,
    };
}

