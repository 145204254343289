import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Box, Stack } from "@mui/material";
import { useGetSecondaryRentalDetails } from "api/secondary-rentals";
import UpdateSecondaryRentalDetailsContainer from "apps/servicing/modules/agreements/containers/UpdateSecondaryRentalDetailsContainer";
import { Loader, NoResults, QueryError } from "common/components";
import Surface from "common/components/Surface";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface SecondaryRentalDetailsContainerProps {
  secondaryRentalId: number;
}

export default function SecondaryRentalDetailsContainer({
  secondaryRentalId,
}: SecondaryRentalDetailsContainerProps): ReactElement {
  const secondaryRentalDetails =
    useGetSecondaryRentalDetails(secondaryRentalId);
  const { t } = useTranslation("agreements");
  const { formatAmount, formatNumber } = useFormat();
  const locale = useLocale();

  return (
    <Loader
      ready={Boolean(
        secondaryRentalDetails.data ?? secondaryRentalDetails.error,
      )}
      render={(): ReactElement => {
        if (secondaryRentalDetails?.error?.status === 404) {
          return (
            <NoResults
              message={t(
                "secondary_rental.no_secondary_rentals_warning_message",
              )}
            />
          );
        }

        if (secondaryRentalDetails.error || !secondaryRentalDetails.data) {
          return <QueryError onRetry={secondaryRentalDetails.refetch} />;
        }

        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <Stack spacing={2}>
              <Surface
                title={t("secondary_rental.details_label")}
                action={
                  <AccessControl
                    allowedPermissions={["servicing:secondary-rentals:manage"]}
                  >
                    <UpdateSecondaryRentalDetailsContainer
                      secondaryRentalId={secondaryRentalId}
                      secondaryRentalDetails={secondaryRentalDetails.data}
                    />
                  </AccessControl>
                }
              >
                <Stack spacing={3}>
                  <DescriptionList label={t("secondary_rental.details_label")}>
                    <DescriptionList.Item
                      label={t("secondary_rental.start_date_label")}
                    >
                      {secondaryRentalDetails.data.startDate
                        ? locale.formatDate(
                            secondaryRentalDetails.data.startDate,
                          )
                        : "-"}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t("secondary_rental.amount_label")}
                    >
                      {formatAmount(secondaryRentalDetails.data.amount)}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t("secondary_rental.frequency_label")}
                    >
                      {secondaryRentalDetails.data.frequency}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t("secondary_rental.introducer_share_label")}
                    >
                      {formatNumber(
                        secondaryRentalDetails.data.introducerShare,
                      )}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t("secondary_rental.maintenance_charge_label")}
                    >
                      {formatAmount(
                        secondaryRentalDetails.data.maintenanceCharge,
                      )}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t("secondary_rental.insurance_premium_label")}
                    >
                      {formatAmount(
                        secondaryRentalDetails.data.insurancePremium,
                      )}
                    </DescriptionList.Item>
                  </DescriptionList>
                </Stack>
              </Surface>
            </Stack>

            <Stack spacing={2}>
              <Surface
                title={t("secondary_rental.secondary_term_details_label")}
              >
                <Stack spacing={3}>
                  <DescriptionList
                    label={t("secondary_rental.secondary_term_details_label")}
                  >
                    <DescriptionList.Item
                      label={t("secondary_rental.secondary_term_arrears_label")}
                    >
                      {formatAmount(
                        secondaryRentalDetails.data.secondaryTermArrears,
                      )}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t(
                        "secondary_rental.secondary_term_capital_balance_label",
                      )}
                    >
                      {formatAmount(
                        secondaryRentalDetails.data.secondaryTermCapitalBalance,
                      )}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t(
                        "secondary_rental.secondary_term_interest_balance_label",
                      )}
                    >
                      {formatAmount(
                        secondaryRentalDetails.data
                          .secondaryTermInterestBalance,
                      )}
                    </DescriptionList.Item>
                  </DescriptionList>
                </Stack>
              </Surface>
            </Stack>
            <Stack spacing={2}>
              <Surface title={t("secondary_rental.primary_term_details_label")}>
                <Stack spacing={3}>
                  <DescriptionList
                    label={t("secondary_rental.primary_term_details_label")}
                  >
                    <DescriptionList.Item
                      label={t("secondary_rental.primary_term_arrears_label")}
                    >
                      {formatAmount(
                        secondaryRentalDetails.data.primaryTermArrears,
                      )}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t(
                        "secondary_rental.primary_term_capital_balance_label",
                      )}
                    >
                      {formatAmount(
                        secondaryRentalDetails.data.primaryTermCapitalBalance,
                      )}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t(
                        "secondary_rental.primary_term_interest_balance_label",
                      )}
                    >
                      {formatAmount(
                        secondaryRentalDetails.data.primaryTermInterestBalance,
                      )}
                    </DescriptionList.Item>
                  </DescriptionList>
                </Stack>
              </Surface>
            </Stack>
          </Box>
        );
      }}
    />
  );
}
