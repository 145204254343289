/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetAgreementModel } from './AssetAgreementModel';
import {
    AssetAgreementModelFromJSON,
    AssetAgreementModelFromJSONTyped,
    AssetAgreementModelToJSON,
} from './AssetAgreementModel';

/**
 * 
 * @export
 * @interface ClientAssetListItemModel
 */
export interface ClientAssetListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof ClientAssetListItemModel
     */
    readonly id: number;
    /**
     * The Asset serial number
     * @type {string}
     * @memberof ClientAssetListItemModel
     */
    serialNumber?: string;
    /**
     * The asset registration number
     * @type {string}
     * @memberof ClientAssetListItemModel
     */
    registrationNumber?: string;
    /**
     * The asset type
     * @type {string}
     * @memberof ClientAssetListItemModel
     */
    type?: string;
    /**
     * The manufacturer description of the asset
     * @type {string}
     * @memberof ClientAssetListItemModel
     */
    manufacturerDescription?: string;
    /**
     * The status of the asset
     * @type {string}
     * @memberof ClientAssetListItemModel
     */
    status?: string;
    /**
     * 
     * @type {AssetAgreementModel}
     * @memberof ClientAssetListItemModel
     */
    agreement?: AssetAgreementModel;
    /**
     * The identifier of the asset for the agreement
     * @type {number}
     * @memberof ClientAssetListItemModel
     */
    assetNumber?: number;
    /**
     * The description of the asset
     * @type {string}
     * @memberof ClientAssetListItemModel
     */
    description?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ClientAssetListItemModel
     */
    purchasePrice?: string;
}

/**
 * Check if a given object implements the ClientAssetListItemModel interface.
 */
export function instanceOfClientAssetListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ClientAssetListItemModelFromJSON(json: any): ClientAssetListItemModel {
    return ClientAssetListItemModelFromJSONTyped(json, false);
}

export function ClientAssetListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientAssetListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'manufacturerDescription': !exists(json, 'manufacturerDescription') ? undefined : json['manufacturerDescription'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'agreement': !exists(json, 'agreement') ? undefined : AssetAgreementModelFromJSON(json['agreement']),
        'assetNumber': !exists(json, 'assetNumber') ? undefined : json['assetNumber'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'purchasePrice': !exists(json, 'purchasePrice') ? undefined : json['purchasePrice'],
    };
}

export function ClientAssetListItemModelToJSON(value?: ClientAssetListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumber': value.serialNumber,
        'registrationNumber': value.registrationNumber,
        'type': value.type,
        'manufacturerDescription': value.manufacturerDescription,
        'status': value.status,
        'agreement': AssetAgreementModelToJSON(value.agreement),
        'assetNumber': value.assetNumber,
        'description': value.description,
        'purchasePrice': value.purchasePrice,
    };
}

