/* tslint:disable */
/* eslint-disable */
/**
 * Engage Admin
 * API for managing roles within the Engage Admin application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DomainModel } from './DomainModel';
import {
    DomainModelFromJSON,
    DomainModelFromJSONTyped,
    DomainModelToJSON,
} from './DomainModel';

/**
 * 
 * @export
 * @interface ApplicationModel
 */
export interface ApplicationModel {
    /**
     * The application where the role permission belongs
     * @type {string}
     * @memberof ApplicationModel
     */
    name: string;
    /**
     * The application's domains
     * @type {Array<DomainModel>}
     * @memberof ApplicationModel
     */
    domains: Array<DomainModel>;
}

/**
 * Check if a given object implements the ApplicationModel interface.
 */
export function instanceOfApplicationModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "domains" in value;

    return isInstance;
}

export function ApplicationModelFromJSON(json: any): ApplicationModel {
    return ApplicationModelFromJSONTyped(json, false);
}

export function ApplicationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'domains': ((json['domains'] as Array<any>).map(DomainModelFromJSON)),
    };
}

export function ApplicationModelToJSON(value?: ApplicationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'domains': ((value.domains as Array<any>).map(DomainModelToJSON)),
    };
}

