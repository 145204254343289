import { useQuery } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import * as keys from "./keys";

export const useListPaymentAllocations = (agreementId: number) => {
  const api = useApi(AgreementQueryApi);

  return useQuery(keys.list(agreementId), () =>
    api.listPaymentAllocations({
      agreementId,
    }),
  );
};
