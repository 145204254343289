/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssetValuationListItemModel
 */
export interface AssetValuationListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AssetValuationListItemModel
     */
    readonly id: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetValuationListItemModel
     */
    valuationDate: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetValuationListItemModel
     */
    value: string;
    /**
     * the person or company that completed the valuation
     * @type {string}
     * @memberof AssetValuationListItemModel
     */
    completedBy?: string;
    /**
     * the source of the valuation
     * @type {string}
     * @memberof AssetValuationListItemModel
     */
    source?: string;
}

/**
 * Check if a given object implements the AssetValuationListItemModel interface.
 */
export function instanceOfAssetValuationListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "valuationDate" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function AssetValuationListItemModelFromJSON(json: any): AssetValuationListItemModel {
    return AssetValuationListItemModelFromJSONTyped(json, false);
}

export function AssetValuationListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetValuationListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'valuationDate': (new Date(json['valuationDate'])),
        'value': json['value'],
        'completedBy': !exists(json, 'completedBy') ? undefined : json['completedBy'],
        'source': !exists(json, 'source') ? undefined : json['source'],
    };
}

export function AssetValuationListItemModelToJSON(value?: AssetValuationListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valuationDate': (value.valuationDate.toISOString().substring(0,10)),
        'value': value.value,
        'completedBy': value.completedBy,
        'source': value.source,
    };
}

