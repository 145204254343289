import { useApi } from "common/providers/ApiProvider";
import { AgreementQueryApi } from "generated/core/apis";
import { AgreementModel } from "generated/core/models";
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { CustomError } from "support/error-handler";
import useSWR from "swr";

export interface UseAgreementMethods {
  id: number;
  data?: AgreementModel;
  error?: CustomError;
  mutate(data?: AgreementModel): Promise<AgreementModel | undefined>;
  isModifiable?: boolean;
}

export const AgreementContext = createContext<UseAgreementMethods>({
  id: 0,
  data: undefined,
  error: undefined,
  mutate: () => Promise.resolve(undefined),
  isModifiable: false,
});

export const useAgreement = (): UseAgreementMethods =>
  useContext(AgreementContext);

interface AgreementProviderProps {
  agreementId: number;
  children: ReactNode;
}

export default function AgreementProvider({
  children,
  agreementId,
}: AgreementProviderProps): ReactElement {
  const api = useApi(AgreementQueryApi);
  const agreement = useSWR(["/agreement/:id", agreementId], () =>
    api.getAgreement({ agreementId }),
  );

  const value = useMemo(
    () => ({
      id: agreementId,
      data: agreement.data,
      error: agreement.error,
      mutate: agreement.mutate,
      isModifiable:
        agreement.data?.statusCode !== "WRITE OFF" &&
        agreement.data?.statusCode !== "PAIDFULL",
    }),
    [agreementId, agreement],
  );

  return (
    <AgreementContext.Provider value={value}>
      {children}
    </AgreementContext.Provider>
  );
}
