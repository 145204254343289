/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Payment Instructions within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The Payment type
 * @export
 */
export const PaymentTypeModel = {
    AdHocAmount: 'Ad-hoc Amount',
    AmountDue: 'Amount Due'
} as const;
export type PaymentTypeModel = typeof PaymentTypeModel[keyof typeof PaymentTypeModel];


export function PaymentTypeModelFromJSON(json: any): PaymentTypeModel {
    return PaymentTypeModelFromJSONTyped(json, false);
}

export function PaymentTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTypeModel {
    return json as PaymentTypeModel;
}

export function PaymentTypeModelToJSON(value?: PaymentTypeModel | null): any {
    return value as any;
}

