import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { RolesApi } from "generated/admin/apis";
import { UpdateRoleModel } from "generated/admin/models";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

const useUpdateRole = (
  roleId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(RolesApi);
  const { mutate } = useSWRConfig();

  return useCommand(
    async (updateRoleModel: UpdateRoleModel) =>
      api.updateRole({
        roleId,
        updateRoleModel,
      }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        mutate(keys.list());
        mutate(keys.detail(roleId));
      },
    },
  );
};

export { useUpdateRole };
