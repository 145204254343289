import { Loader } from "@ldms/mui-sdk/templates";
import { Typography } from "@mui/material";
import { useGetCustomer } from "api/customers/contacts/getCustomer";
import CustomerDescription from "apps/servicing/modules/customers/components/CustomerDescription";
import Surface from "common/components/Surface";
import { useTranslation } from "react-i18next";

interface CustomerDetailsContainerProps {
  customerId: number;
}

export default function CustomerDetailsContainer({
  customerId,
}: CustomerDetailsContainerProps) {
  const { t } = useTranslation("customers");
  const customerOverview = useGetCustomer(customerId);

  const renderLoadedDetails = () => {
    if (customerOverview.error || !customerOverview.data) {
      return (
        <Typography color="error" data-testid="customerOverview.error">
          {t("common:error.default")}
        </Typography>
      );
    }

    return <CustomerDescription customer={customerOverview.data} />;
  };

  return (
    <Surface title={t("overview.customer_details.heading")}>
      <Loader
        ready={Boolean(customerOverview?.data ?? customerOverview?.error)}
        render={renderLoadedDetails}
      />
    </Surface>
  );
}
