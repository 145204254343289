/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetModel } from './AssetModel';
import {
    AssetModelFromJSON,
    AssetModelFromJSONTyped,
    AssetModelToJSON,
} from './AssetModel';
import type { CustomerDetailsModel } from './CustomerDetailsModel';
import {
    CustomerDetailsModelFromJSON,
    CustomerDetailsModelFromJSONTyped,
    CustomerDetailsModelToJSON,
} from './CustomerDetailsModel';
import type { FeesAndCommissionsModel } from './FeesAndCommissionsModel';
import {
    FeesAndCommissionsModelFromJSON,
    FeesAndCommissionsModelFromJSONTyped,
    FeesAndCommissionsModelToJSON,
} from './FeesAndCommissionsModel';
import type { FinancialDetailsModel } from './FinancialDetailsModel';
import {
    FinancialDetailsModelFromJSON,
    FinancialDetailsModelFromJSONTyped,
    FinancialDetailsModelToJSON,
} from './FinancialDetailsModel';
import type { MaintenanceDetailsModel } from './MaintenanceDetailsModel';
import {
    MaintenanceDetailsModelFromJSON,
    MaintenanceDetailsModelFromJSONTyped,
    MaintenanceDetailsModelToJSON,
} from './MaintenanceDetailsModel';
import type { RepaymentTermsModel } from './RepaymentTermsModel';
import {
    RepaymentTermsModelFromJSON,
    RepaymentTermsModelFromJSONTyped,
    RepaymentTermsModelToJSON,
} from './RepaymentTermsModel';
import type { SecondaryRentalDetailsModel } from './SecondaryRentalDetailsModel';
import {
    SecondaryRentalDetailsModelFromJSON,
    SecondaryRentalDetailsModelFromJSONTyped,
    SecondaryRentalDetailsModelToJSON,
} from './SecondaryRentalDetailsModel';

/**
 * 
 * @export
 * @interface OnboardingModel
 */
export interface OnboardingModel {
    /**
     * 
     * @type {CustomerDetailsModel}
     * @memberof OnboardingModel
     */
    customer?: CustomerDetailsModel;
    /**
     * A read write Id
     * @type {number}
     * @memberof OnboardingModel
     */
    existingCustomerId?: number;
    /**
     * Name of the customer if onboarding with an existing customer
     * @type {string}
     * @memberof OnboardingModel
     */
    existingCustomerName?: string;
    /**
     * Portfolio to onboard to
     * @type {string}
     * @memberof OnboardingModel
     */
    portfolio: string;
    /**
     * Financial Product Id
     * @type {number}
     * @memberof OnboardingModel
     */
    productId?: number;
    /**
     * The facility risk rating associated to the customer
     * @type {number}
     * @memberof OnboardingModel
     */
    facilityRiskRating?: number;
    /**
     * The agreement number for the completed onboarding
     * @type {string}
     * @memberof OnboardingModel
     */
    agreementNumber?: string;
    /**
     * The loss pool percentage
     * @type {number}
     * @memberof OnboardingModel
     */
    lossPoolPercentage?: number;
    /**
     * Flag to define if agreement vat is applicable for funded vat
     * @type {boolean}
     * @memberof OnboardingModel
     */
    isFundedVat?: boolean;
    /**
     * The vat rate to use for the onboarding
     * @type {number}
     * @memberof OnboardingModel
     */
    vatRate?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingModel
     */
    setupCosts?: string;
    /**
     * 
     * @type {Array<AssetModel>}
     * @memberof OnboardingModel
     */
    assets: Array<AssetModel>;
    /**
     * 
     * @type {FinancialDetailsModel}
     * @memberof OnboardingModel
     */
    financialDetails: FinancialDetailsModel;
    /**
     * 
     * @type {FeesAndCommissionsModel}
     * @memberof OnboardingModel
     */
    feesAndCommissions?: FeesAndCommissionsModel;
    /**
     * 
     * @type {RepaymentTermsModel}
     * @memberof OnboardingModel
     */
    repaymentTerms: RepaymentTermsModel;
    /**
     * 
     * @type {MaintenanceDetailsModel}
     * @memberof OnboardingModel
     */
    maintenance?: MaintenanceDetailsModel;
    /**
     * The funding line for the agreement
     * @type {string}
     * @memberof OnboardingModel
     */
    fundingLine?: string;
    /**
     * 
     * @type {SecondaryRentalDetailsModel}
     * @memberof OnboardingModel
     */
    secondaryRental?: SecondaryRentalDetailsModel;
}

/**
 * Check if a given object implements the OnboardingModel interface.
 */
export function instanceOfOnboardingModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "portfolio" in value;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "financialDetails" in value;
    isInstance = isInstance && "repaymentTerms" in value;

    return isInstance;
}

export function OnboardingModelFromJSON(json: any): OnboardingModel {
    return OnboardingModelFromJSONTyped(json, false);
}

export function OnboardingModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customer': !exists(json, 'customer') ? undefined : CustomerDetailsModelFromJSON(json['customer']),
        'existingCustomerId': !exists(json, 'existingCustomerId') ? undefined : json['existingCustomerId'],
        'existingCustomerName': !exists(json, 'existingCustomerName') ? undefined : json['existingCustomerName'],
        'portfolio': json['portfolio'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'facilityRiskRating': !exists(json, 'facilityRiskRating') ? undefined : json['facilityRiskRating'],
        'agreementNumber': !exists(json, 'agreementNumber') ? undefined : json['agreementNumber'],
        'lossPoolPercentage': !exists(json, 'lossPoolPercentage') ? undefined : json['lossPoolPercentage'],
        'isFundedVat': !exists(json, 'isFundedVat') ? undefined : json['isFundedVat'],
        'vatRate': !exists(json, 'vatRate') ? undefined : json['vatRate'],
        'setupCosts': !exists(json, 'setupCosts') ? undefined : json['setupCosts'],
        'assets': ((json['assets'] as Array<any>).map(AssetModelFromJSON)),
        'financialDetails': FinancialDetailsModelFromJSON(json['financialDetails']),
        'feesAndCommissions': !exists(json, 'feesAndCommissions') ? undefined : FeesAndCommissionsModelFromJSON(json['feesAndCommissions']),
        'repaymentTerms': RepaymentTermsModelFromJSON(json['repaymentTerms']),
        'maintenance': !exists(json, 'maintenance') ? undefined : MaintenanceDetailsModelFromJSON(json['maintenance']),
        'fundingLine': !exists(json, 'fundingLine') ? undefined : json['fundingLine'],
        'secondaryRental': !exists(json, 'secondaryRental') ? undefined : SecondaryRentalDetailsModelFromJSON(json['secondaryRental']),
    };
}

export function OnboardingModelToJSON(value?: OnboardingModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customer': CustomerDetailsModelToJSON(value.customer),
        'existingCustomerId': value.existingCustomerId,
        'existingCustomerName': value.existingCustomerName,
        'portfolio': value.portfolio,
        'productId': value.productId,
        'facilityRiskRating': value.facilityRiskRating,
        'agreementNumber': value.agreementNumber,
        'lossPoolPercentage': value.lossPoolPercentage,
        'isFundedVat': value.isFundedVat,
        'vatRate': value.vatRate,
        'setupCosts': value.setupCosts,
        'assets': ((value.assets as Array<any>).map(AssetModelToJSON)),
        'financialDetails': FinancialDetailsModelToJSON(value.financialDetails),
        'feesAndCommissions': FeesAndCommissionsModelToJSON(value.feesAndCommissions),
        'repaymentTerms': RepaymentTermsModelToJSON(value.repaymentTerms),
        'maintenance': MaintenanceDetailsModelToJSON(value.maintenance),
        'fundingLine': value.fundingLine,
        'secondaryRental': SecondaryRentalDetailsModelToJSON(value.secondaryRental),
    };
}

