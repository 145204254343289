import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { useLocale } from "common/hooks";
import { useTranslation } from "react-i18next";

interface InsuranceDescriptionProps {
  name?: string;
  premiumTotal?: string;
  cost?: string;
  insurancePremiumTaxRate?: number;
  insuranceStartDate?: Date;
}

export default function InsuranceDescription(props: InsuranceDescriptionProps) {
  const { t } = useTranslation("agreements");
  const { formatAmount } = useFormat();
  const locale = useLocale();

  return (
    <DescriptionList label={t("insurance.insurance_details.details_label")}>
      <DescriptionList.Item
        label={t("insurance.insurance_details.insurer_heading")}
      >
        {props.name}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("insurance.insurance_details.premium_total_heading")}
      >
        {formatAmount(props.premiumTotal)}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("insurance.insurance_details.cost_heading")}
      >
        {formatAmount(props.cost)}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t(
          "insurance.insurance_details.insurance_premium_tax_rate_heading",
        )}
      >
        {`${formatAmount(props.insurancePremiumTaxRate)}%`}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("insurance.insurance_details.insurance_start_date_heading")}
      >
        {props.insuranceStartDate
          ? locale.formatDate(props.insuranceStartDate)
          : "-"}
      </DescriptionList.Item>
    </DescriptionList>
  );
}
