/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The payment to be transfered
 * @export
 * @interface TransferPaymentModel
 */
export interface TransferPaymentModel {
    /**
     * The transaction identifier of the payment that is to be transfered
     * @type {number}
     * @memberof TransferPaymentModel
     */
    transactionId: number;
    /**
     * The agreement identifier of the agreement that the payment is being transferred to
     * @type {number}
     * @memberof TransferPaymentModel
     */
    agreementId: number;
    /**
     * The comment for the payment transfer
     * @type {string}
     * @memberof TransferPaymentModel
     */
    comment?: string;
}

/**
 * Check if a given object implements the TransferPaymentModel interface.
 */
export function instanceOfTransferPaymentModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transactionId" in value;
    isInstance = isInstance && "agreementId" in value;

    return isInstance;
}

export function TransferPaymentModelFromJSON(json: any): TransferPaymentModel {
    return TransferPaymentModelFromJSONTyped(json, false);
}

export function TransferPaymentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferPaymentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'agreementId': json['agreementId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function TransferPaymentModelToJSON(value?: TransferPaymentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionId': value.transactionId,
        'agreementId': value.agreementId,
        'comment': value.comment,
    };
}

