import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { Loader } from "@ldms/mui-sdk/templates";
import { Close, Download, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogContentProps,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { useGetFinancialDetails } from "api/assets/financial-details/getFinancialDetails";
import { useGetAsset } from "api/assets/getAsset";
import AssetDetailsContainer from "apps/servicing/containers/AssetDetailsContainer";
import AssetFinancialContainer from "apps/servicing/containers/AssetFinancialContainer";
import AssetPartialSettlementQuotesContainer from "apps/servicing/containers/AssetPartialSettlementQuotesContainer";
import AssetSettlementQuotesContainer from "apps/servicing/containers/AssetSettlementQuotesContainer";
import AssetValuationsContainer from "apps/servicing/containers/AssetValuationsContainer";
import UpdateAssetContainer from "apps/servicing/containers/UpdateAssetContainer";
import AssetDialogLayout from "apps/servicing/layouts/AssetDialogLayout";
import { QueryError } from "common/components";
import { useLocale } from "common/hooks";
import { useAgreement } from "common/providers";
import { useEnvironment } from "common/providers/EnvironmentProvider";
import {
  FinancialDetailsModel,
  PaymentFrequencyModel,
} from "generated/core/models";
import { ProductTypeModel } from "generated/onboarding/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { downloadAsCSV } from "support/download";

interface TabPanelProps extends DialogContentProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <DialogContent
      role="tabpanel"
      hidden={value !== index}
      id={`asset-tabpanel-${index}`}
      aria-labelledby={`asset-tab-${index}`}
      sx={{ paddingY: 0 }}
      {...other}
    >
      <Box paddingY={3}>{value === index && children}</Box>
    </DialogContent>
  );
}

const a11yProps = (index: number) => ({
  id: `asset-tab-${index}`,
  "aria-controls": `asset-tabpanel-${index}`,
});

interface AssetTabsProps {
  assetId: number;
  canSettle: boolean;
  isModifiable: boolean;
}

function AssetTabs({ assetId, canSettle, isModifiable }: AssetTabsProps) {
  const { t } = useTranslation("assets");
  const [value, setValue] = useState(0);
  const environment = useEnvironment();
  const agreement = useAgreement();

  const handleChange = (_: unknown, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Container>
        <Tabs value={value} onChange={handleChange}>
          {[
            <Tab
              key={0}
              value={0}
              label={t("tabs.asset_details")}
              {...a11yProps(0)}
            />,
            <Tab
              key={1}
              value={1}
              label={t("tabs.financial_details")}
              {...a11yProps(1)}
            />,
            <Tab
              key={2}
              value={2}
              label={t("tabs.valuations")}
              {...a11yProps(2)}
            />,
          ]
            .concat(
              canSettle && isModifiable ? (
                <Tab
                  key={3}
                  value={3}
                  label={t("tabs.settlement_quotes")}
                  {...a11yProps(3)}
                />
              ) : (
                []
              ),
            )
            .concat(
              environment.isFeatureEnabled("dev") &&
                agreement.data?.productName !==
                  ProductTypeModel.FixedRateOperatingLease &&
                agreement.data?.paymentFrequency !==
                  PaymentFrequencyModel.Irregular ? (
                <Tab
                  key={4}
                  value={4}
                  label={t("tabs.partial_settlement_quotes")}
                  {...a11yProps(4)}
                />
              ) : (
                []
              ),
            )}
        </Tabs>
      </Container>
      <Divider />
      <TabPanel value={value} index={0}>
        <AssetDetailsContainer assetId={assetId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AssetFinancialContainer assetId={assetId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AssetValuationsContainer
          assetId={assetId}
          isModifiable={isModifiable}
        />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <AssetSettlementQuotesContainer assetId={assetId} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <AssetPartialSettlementQuotesContainer assetId={assetId} />
      </TabPanel>
    </>
  );
}

interface ExportInstalmentsProps {
  assetId: number;
}

function ExportInstalmentsButton({ assetId }: ExportInstalmentsProps) {
  const { t } = useTranslation("assets");
  const financialDetails = useGetFinancialDetails(assetId);
  const locale = useLocale();

  const makeExportHandler = (data: FinancialDetailsModel) => () => {
    downloadAsCSV(
      [
        [
          t("financial_details.instalment_number"),
          t("financial_details.due_date"),
          t("financial_details.capital"),
          t("financial_details.interest"),
          t("financial_details.vat"),
          t("financial_details.rental"),
        ],
        ...data.instalments.map((row) => [
          row.instalmentNumber,
          locale.formatISODate(row.instalmentDate),
          row.capital,
          row.interest,
          row.vat,
          row.rental,
        ]),
      ],
      {
        filename: `${assetId}_financial`,
      },
    );
  };

  return financialDetails.data ? (
    <Button
      startIcon={<Download />}
      onClick={makeExportHandler(financialDetails.data)}
    >
      {t("export_button")}
    </Button>
  ) : (
    <Button startIcon={<Download />} role={undefined} disabled>
      {t("export_button")}
    </Button>
  );
}

interface AssetDialogViewProps {
  assetId: number;
  open: boolean;
}

export default function AssetDialogView({
  assetId,
  open,
}: AssetDialogViewProps) {
  const { ready, t } = useTranslation("assets");
  const navigate = useNavigate();
  const [isEditing, setEditing] = useState(false);

  const handleEdit = () => setEditing(true);

  const handleCloseEdit = () => setEditing(false);

  const onClose = () => navigate("../assets");

  const asset = useGetAsset(assetId);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { height: "100%" } }}
    >
      {Boolean(assetId) && ready && (
        <>
          <Loader
            fallback={
              <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress />
              </Box>
            }
            ready={Boolean(asset.data) || Boolean(asset.error)}
            render={(): ReactElement => {
              if (asset.error || !asset.data) {
                return (
                  <>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      marginTop={2}
                      marginRight={2}
                    >
                      <IconButton aria-label={t("close")} onClick={onClose}>
                        <Close />
                      </IconButton>
                    </Box>
                    <QueryError onRetry={asset.refetch} />
                  </>
                );
              }
              return (
                <AssetDialogLayout
                  action={
                    <Stack direction="row" spacing={1}>
                      {asset.data?.isActive && (
                        <AccessControl
                          allowedPermissions={["servicing:assets:edit"]}
                        >
                          <Button onClick={handleEdit} startIcon={<Edit />}>
                            {t("edit_button")}
                          </Button>
                        </AccessControl>
                      )}

                      <AccessControl
                        allowedPermissions={["servicing:assets:export"]}
                      >
                        <ExportInstalmentsButton assetId={assetId} />
                      </AccessControl>
                    </Stack>
                  }
                  assetId={assetId}
                  clientId={asset.data.clientId}
                  onClose={onClose}
                >
                  <AssetTabs
                    assetId={assetId}
                    canSettle={asset.data.canSettle}
                    isModifiable={asset.data.isActive}
                  />
                </AssetDialogLayout>
              );
            }}
          />

          <UpdateAssetContainer
            assetId={assetId}
            open={isEditing}
            onClose={handleCloseEdit}
            onSuccess={handleCloseEdit}
          />
        </>
      )}
    </Dialog>
  );
}
