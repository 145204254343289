/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The charge to be applied
 * @export
 * @interface ChargeModel
 */
export interface ChargeModel {
    /**
     * The charge code
     * @type {string}
     * @memberof ChargeModel
     */
    code: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ChargeModel
     */
    amount: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof ChargeModel
     */
    dateDue: Date;
    /**
     * The comments for a charge
     * @type {string}
     * @memberof ChargeModel
     */
    comments: string;
    /**
     * The payee for a charge
     * @type {string}
     * @memberof ChargeModel
     */
    payee?: string;
}

/**
 * Check if a given object implements the ChargeModel interface.
 */
export function instanceOfChargeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "dateDue" in value;
    isInstance = isInstance && "comments" in value;

    return isInstance;
}

export function ChargeModelFromJSON(json: any): ChargeModel {
    return ChargeModelFromJSONTyped(json, false);
}

export function ChargeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'amount': json['amount'],
        'dateDue': (new Date(json['dateDue'])),
        'comments': json['comments'],
        'payee': !exists(json, 'payee') ? undefined : json['payee'],
    };
}

export function ChargeModelToJSON(value?: ChargeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'amount': value.amount,
        'dateDue': (value.dateDue.toISOString().substring(0,10)),
        'comments': value.comments,
        'payee': value.payee,
    };
}

