import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ApiResponse } from "generated";
import {
  ClientAttachmentCommandApi,
  CreateClientAttachmentRequest,
} from "generated/core/apis";

export const useCreateCustomerAttachment = (
  options: UseCommandOptions<ApiResponse<void>, ResponseError> = {},
) => {
  const api = useApi(ClientAttachmentCommandApi);

  return useCommand<
    CreateClientAttachmentRequest,
    ApiResponse<void>,
    ResponseError
  >((requestParameters) => {
    return api.createClientAttachmentRaw(requestParameters);
  }, options);
};
