import { Form } from "@ldms/mui-sdk/forms";
import { Box, Divider, TextField, Typography } from "@mui/material";
import { OnboardingStepActions } from "apps/onboarding/components";
import { useOnboarding } from "apps/onboarding/providers";
import { useYupResolver } from "common/hooks";
import { useStepper } from "common/providers/StepperProvider";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Resolver } from "support/react-hook-form";

export interface CustomerDetailsFormModel {
  addressLine1: string;
  addressLine2: string;
  addressLine3?: string;
  addressLine4?: string;
  postcode: string;
  email?: string;
  telephone?: string;
  bankName: string;
  sortCode?: string;
  accountNumber?: string;
  bankAccountName: string;
  iban?: string;
  bic?: string;
  obligorRiskRating?: number;
}

const labels = {
  accountNumber: "customer_details.bank_account_number_label",
  addreseLine1: "customer_details.address_line_1_label",
  addreseLine2: "customer_details.address_line_2_label",
  postcode: "customer_details.postcode_label",
  sortCode: "customer_details.sort_code_label",
  iban: "customer_details.iban_label",
  bic: "customer_details.bic_label",
  bankName: "customer_details.bank_name_label",
  bankAccountName: "customer_details.bank_account_name_label",
  isRequiredValidation: "common:validation.is_required",
};

const useAddPortfolioBankAccountResolver =
  (): Resolver<CustomerDetailsFormModel> => {
    const { t } = useTranslation("onboardings");
    const validateDependentField = (
      value: string | undefined,
      length: number,
    ): boolean => {
      if (length) {
        return Boolean(value);
      }
      return true;
    };

    const obligorRiskRatingLabel = "customer_details.obligor_risk_rating_label";

    return useYupResolver<CustomerDetailsFormModel>((yup) => {
      const validateLengthForOptionalString = (
        value: string,
        requiredLength: number,
      ) => {
        if (value.length === 0) {
          return true;
        }
        return value.length === requiredLength;
      };

      return yup.object().shape({
        addressLine1: yup
          .string()
          .isRequired(t(labels.addreseLine1))
          .maxCharacters(50, t(labels.addreseLine1)),
        addressLine2: yup
          .string()
          .isRequired(t(labels.addreseLine2))
          .maxCharacters(50, t(labels.addreseLine2)),
        addressLine3: yup
          .string()
          .maxCharacters(50, t("customer_details.address_line_3_label")),
        addressLine4: yup
          .string()
          .maxCharacters(50, t("customer_details.address_line_4_label")),
        postcode: yup
          .string()
          .isRequired(t(labels.postcode))
          .maxCharacters(10, t(labels.postcode)),
        email: yup
          .string()
          .maxCharacters(200, t("customer_details.email_address_label")),
        telephone: yup
          .string()
          .maxCharacters(15, t("customer_details.phone_number_label")),
        bankName: yup
          .string()
          .isRequired(t(labels.bankName))
          .maxCharacters(50, t(labels.bankName)),
        bankAccountName: yup
          .string()
          .isRequired(t(labels.bankAccountName))
          .maxCharacters(50, t(labels.bankAccountName)),
        sortCode: yup
          .string()
          .test({
            name: "isDependentOnAccountNumber",
            message: t(labels.isRequiredValidation, {
              label: t(labels.sortCode),
            }),
            test: (value, testContext) =>
              validateDependentField(value, testContext.parent.accountNumber),
          })
          .oneOfBankDetailsRequired("sortCodeRequired")
          .test(
            "len",
            t("form_validation.sort_code_must_be_6_digits"),
            (sortCode) => validateLengthForOptionalString(String(sortCode), 6),
          ),
        accountNumber: yup
          .string()
          .test({
            name: "isDependentOnSortCode",
            message: t(labels.isRequiredValidation, {
              label: t(labels.accountNumber),
            }),
            test: (value, testContext) =>
              validateDependentField(value, testContext.parent.sortCode),
          })
          .oneOfBankDetailsRequired("accountNumberRequired")
          .test(
            "len",
            t("form_validation.account_number_must_be_8_digits"),
            (accountNumber) =>
              validateLengthForOptionalString(String(accountNumber), 8),
          ),
        iban: yup
          .string()
          .test({
            name: "isDependentOnBic",
            message: t(labels.isRequiredValidation, {
              label: t(labels.iban),
            }),
            test: (value, testContext) =>
              validateDependentField(value, testContext.parent.bic),
          })
          .oneOfBankDetailsRequired("ibanRequired")
          .maxCharacters(30, t(labels.iban))
          .validateIban(),
        bic: yup
          .string()
          .test({
            name: "isDependentOnIban",
            message: t(labels.isRequiredValidation, {
              label: t(labels.bic),
            }),
            test: (value, testContext) =>
              validateDependentField(value, testContext.parent.iban),
          })
          .oneOfBankDetailsRequired("bicRequired")
          .maxCharacters(30, t(labels.bic))
          .validateBic(),

        obligorRiskRating: yup
          .number(t(obligorRiskRatingLabel))
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .minAmount(0, t(obligorRiskRatingLabel))
          .maxAmount(999.99999, t(obligorRiskRatingLabel)),
      });
    });
  };

export default function CustomerDetailsStep(): ReactElement {
  const { t } = useTranslation("onboardings");
  const stepper = useStepper();
  const onboarding = useOnboarding();
  const resolver = useAddPortfolioBankAccountResolver();

  const onSubmit = (data: CustomerDetailsFormModel): void => {
    onboarding.submitCustomerDetails(data);
    stepper.next();
  };

  return (
    <>
      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h1" }}>
          {t("customer_details.title")}
        </Typography>
      </Box>

      <Form
        label={t("customer_details.title")}
        defaultValues={onboarding.state.customerDetails}
        onSubmit={onSubmit}
        resolver={resolver}
      >
        {(form) => (
          <>
            <TextField
              {...form.register("addressLine1")}
              label={t(labels.addreseLine1)}
              helperText={form.formState.errors.addressLine1?.message}
              error={Boolean(form.formState.errors.addressLine1)}
              required
            />
            <TextField
              {...form.register("addressLine2")}
              label={t(labels.addreseLine2)}
              helperText={form.formState.errors.addressLine2?.message}
              error={Boolean(form.formState.errors.addressLine2)}
              required
            />
            <TextField
              {...form.register("addressLine3")}
              label={t("customer_details.address_line_3_label")}
              helperText={form.formState.errors.addressLine3?.message}
              error={Boolean(form.formState.errors.addressLine3)}
            />
            <TextField
              {...form.register("addressLine4")}
              label={t("customer_details.address_line_4_label")}
              helperText={form.formState.errors.addressLine4?.message}
              error={Boolean(form.formState.errors.addressLine4)}
            />
            <TextField
              {...form.register("postcode")}
              label={t(labels.postcode)}
              helperText={form.formState.errors.postcode?.message}
              error={Boolean(form.formState.errors.postcode)}
              required
            />

            <Box marginY={3}>
              <Divider />
            </Box>

            <TextField
              {...form.register("email")}
              label={t("customer_details.email_address_label")}
              helperText={form.formState.errors.email?.message}
              error={Boolean(form.formState.errors.email)}
            />
            <TextField
              {...form.register("telephone")}
              label={t("customer_details.phone_number_label")}
              helperText={form.formState.errors.telephone?.message}
              error={Boolean(form.formState.errors.telephone)}
            />

            <Box marginY={3}>
              <Divider />
            </Box>

            <TextField
              {...form.register("bankName")}
              label={t(labels.bankName)}
              helperText={form.formState.errors.bankName?.message}
              error={Boolean(form.formState.errors.bankName)}
              required
            />
            <TextField
              {...form.register("bankAccountName")}
              label={t(labels.bankAccountName)}
              helperText={form.formState.errors.bankAccountName?.message}
              error={Boolean(form.formState.errors.bankAccountName)}
              required
            />
            <TextField
              {...form.register("sortCode")}
              type="number"
              label={t(labels.sortCode)}
              helperText={form.formState.errors.sortCode?.message}
              error={Boolean(form.formState.errors.sortCode)}
            />
            <TextField
              {...form.register("accountNumber")}
              type="number"
              label={t(labels.accountNumber)}
              helperText={form.formState.errors.accountNumber?.message}
              error={Boolean(form.formState.errors.accountNumber)}
            />
            <TextField
              {...form.register("iban")}
              label={t(labels.iban)}
              helperText={form.formState.errors.iban?.message}
              error={Boolean(form.formState.errors.iban)}
            />
            <TextField
              {...form.register("bic")}
              label={t(labels.bic)}
              helperText={form.formState.errors.bic?.message}
              error={Boolean(form.formState.errors.bic)}
            />
            <Box marginY={3}>
              <Divider />
            </Box>
            <TextField
              {...form.register("obligorRiskRating")}
              type="number"
              label={t("customer_details.obligor_risk_rating_label")}
              helperText={form.formState.errors.obligorRiskRating?.message}
              error={Boolean(form.formState.errors.obligorRiskRating)}
            />
            <OnboardingStepActions
              back={{
                label: t("common:stepper.back_button"),
                onBack: stepper.previous,
              }}
              next={{ label: t("common:stepper.next_button") }}
            />
          </>
        )}
      </Form>
    </>
  );
}
