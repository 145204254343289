import { DayCountConventionModel } from "generated/onboarding/models";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const TranslateDayCountConvention: FC<{ convention: string }> = ({
  convention,
}) => {
  const { t } = useTranslation("onboardings");

  if (convention === DayCountConventionModel.Actual365) {
    return <>{t("financial_products.actual_365")}</>;
  }

  return <>{convention}</>;
};

export default TranslateDayCountConvention;
