import { ReportingView } from "apps/reporting/views";
import { withAppAccess } from "common/security/withAppAccess";
import { compose } from "lodash/fp";
import { Route, Routes } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

const App = () => {
  return (
    <Routes>
      <Route index element={<ReportingView />} />
    </Routes>
  );
};

export default compose(
  withTranslationLoader("reporting"),
  withAppAccess("reporting"),
)(App);
