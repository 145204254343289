import { TextField } from "@mui/material";
import { PortfolioDateReportDialogFormValues } from "apps/reporting/types";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FieldErrors, UseFormRegister } from "support/react-hook-form";

interface DateRangeControlProps {
  errors: FieldErrors<PortfolioDateReportDialogFormValues>;
  register: UseFormRegister<PortfolioDateReportDialogFormValues>;
}

export default function DateRangeControl({
  errors,
  register,
}: DateRangeControlProps): ReactElement {
  const { t } = useTranslation("reporting");

  return (
    <>
      <TextField
        {...register("fromDate")}
        helperText={errors.fromDate?.message}
        error={Boolean(errors.fromDate)}
        label={t("report_dialog.from_date_label")}
        type="date"
      />

      <TextField
        {...register("toDate")}
        helperText={errors.toDate?.message}
        error={Boolean(errors.toDate)}
        label={t("report_dialog.to_date_label")}
        type="date"
      />
    </>
  );
}
