import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { FinancialProductApi } from "generated/onboarding/apis";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

export const useAssociateFees = (
  financialProductId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(FinancialProductApi);
  const swr = useSWRConfig();

  return useCommand<number[], void, ResponseError>(
    (fees) =>
      api.associateFees({
        financialProductId,
        requestBody: fees,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.detail(financialProductId));
        options.onSuccess?.();
      },
    },
  );
};
