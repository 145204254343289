/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Financial Postings within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FinancialPostingListItemModel,
  FinancialPostingStageListItemModel,
} from '../models/index';
import {
    FinancialPostingListItemModelFromJSON,
    FinancialPostingListItemModelToJSON,
    FinancialPostingStageListItemModelFromJSON,
    FinancialPostingStageListItemModelToJSON,
} from '../models/index';

export interface ExportFinancialPostingsRequest {
    agreementId?: number;
    portfolioId?: number;
    accountCode?: string;
    sourceCode?: string;
    stageCode?: string;
    from?: Date;
    to?: Date;
}

export interface ListFinancialPostingStagesRequest {
    agreementId?: number;
}

export interface ListFinancialPostingsRequest {
    agreementId?: number;
    portfolioId?: number;
    accountCode?: string;
    sourceCode?: string;
    stageCode?: string;
    from?: Date;
    to?: Date;
    offset?: number;
    limit?: number;
}

/**
 * 
 */
export class FinancialPostingQueryApi extends runtime.BaseAPI {

    /**
     * Export financial postings
     */
    async exportFinancialPostingsRaw(requestParameters: ExportFinancialPostingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.agreementId !== undefined) {
            queryParameters['agreementId'] = requestParameters.agreementId;
        }

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.accountCode !== undefined) {
            queryParameters['accountCode'] = requestParameters.accountCode;
        }

        if (requestParameters.sourceCode !== undefined) {
            queryParameters['sourceCode'] = requestParameters.sourceCode;
        }

        if (requestParameters.stageCode !== undefined) {
            queryParameters['stageCode'] = requestParameters.stageCode;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["financial-postings:export"]);
        }

        const response = await this.request({
            path: `/financial-postings/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export financial postings
     */
    async exportFinancialPostings(requestParameters: ExportFinancialPostingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportFinancialPostingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available stage names for an agreement
     */
    async listFinancialPostingStagesRaw(requestParameters: ListFinancialPostingStagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FinancialPostingStageListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.agreementId !== undefined) {
            queryParameters['agreementId'] = requestParameters.agreementId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["financial-postings:view"]);
        }

        const response = await this.request({
            path: `/financial-postings/stages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FinancialPostingStageListItemModelFromJSON));
    }

    /**
     * List available stage names for an agreement
     */
    async listFinancialPostingStages(requestParameters: ListFinancialPostingStagesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FinancialPostingStageListItemModel>> {
        const response = await this.listFinancialPostingStagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the financial postings associated to an agreement or portfolio
     */
    async listFinancialPostingsRaw(requestParameters: ListFinancialPostingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FinancialPostingListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.agreementId !== undefined) {
            queryParameters['agreementId'] = requestParameters.agreementId;
        }

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.accountCode !== undefined) {
            queryParameters['accountCode'] = requestParameters.accountCode;
        }

        if (requestParameters.sourceCode !== undefined) {
            queryParameters['sourceCode'] = requestParameters.sourceCode;
        }

        if (requestParameters.stageCode !== undefined) {
            queryParameters['stageCode'] = requestParameters.stageCode;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["financial-postings:view"]);
        }

        const response = await this.request({
            path: `/financial-postings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FinancialPostingListItemModelFromJSON));
    }

    /**
     * Retrieve the financial postings associated to an agreement or portfolio
     */
    async listFinancialPostings(requestParameters: ListFinancialPostingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FinancialPostingListItemModel>> {
        const response = await this.listFinancialPostingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
