import { ClientAssets } from "apps/servicing/modules/customers/containers";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { ContainedLayout } from "common/layouts";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

export default function AssetsView() {
  const { t } = useTranslation("customers");
  const customer = useCustomer();

  return (
    <ViewLayout title={t("assets.heading")}>
      <FixedLayout>
        <ContainedLayout>
          <ClientAssets clientId={customer.data.id} />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}
