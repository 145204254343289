import * as keys from "api/financial-postings/stages/keys";
import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { FinancialPostingQueryApi } from "generated/core/apis";
import { FinancialPostingStageListItemModel } from "generated/core/models";

type UseListFinancialPostingStages = (params?: {
  agreementId: number;
}) => UseQueryReturn<FinancialPostingStageListItemModel[]>;

export const useListFinancialPostingStages: UseListFinancialPostingStages =
  (params?: { agreementId: number }) => {
    const api = useApi(FinancialPostingQueryApi);

    return useQuery(keys.list(params), () =>
      api.listFinancialPostingStages({ agreementId: params?.agreementId }),
    );
  };
