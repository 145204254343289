import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Button, Typography } from "@mui/material";
import { useApproveSettlementQuote } from "api/settlement-quotes/approveSettlementQuote";
import { useConfirm } from "common/hooks";
import { SettlementStatusModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface ApproveSettlementQuoteDetailsContainerProps {
  settlementQuoteId: number;
  settlementStatus?: SettlementStatusModel;
  onSuccess?: () => void;
}

export default function ApproveSettlementQuoteContainer({
  settlementQuoteId,
  settlementStatus,
  onSuccess,
}: ApproveSettlementQuoteDetailsContainerProps): ReactElement {
  const { t } = useTranslation("common");
  const confirm = useConfirm();

  const approveSettlementQuote = useApproveSettlementQuote(settlementQuoteId, {
    onSuccess,
  });

  const onApproveSettlementQuote = async (): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      await approveSettlementQuote.execute({ settlementQuoteId });
    });
  };

  const onCloseErrorDialog = (): void => {
    approveSettlementQuote.reset();
  };

  return (
    <>
      <Button
        aria-label={t("approve_settlement_quote.button_label")}
        variant="contained"
        onClick={onApproveSettlementQuote}
        disabled={settlementStatus !== SettlementStatusModel.Quoted}
      >
        {t("approve_settlement_quote.button_label")}
      </Button>

      <ConfirmationDialog
        content={
          <Typography>
            {t("approve_settlement_quote.confirmation_message")}
          </Typography>
        }
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("approve_settlement_quote.confirmation_title")}
        labels={{
          confirm: t("yes"),
          reject: t("no"),
        }}
      />

      <AlertDialog
        content={approveSettlementQuote.error?.message}
        labels={{ close: t("alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(approveSettlementQuote.error)}
        title={t("approve_settlement_quote.error_title")}
      />
    </>
  );
}
