/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Tax Codes within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateTaxCodeRequestModel,
  CreateTaxRateRequestModel,
  ErrorWithParametersModel,
  TaxCodeDetailsModel,
  TaxCodeListItemModel,
} from '../models/index';
import {
    CreateTaxCodeRequestModelFromJSON,
    CreateTaxCodeRequestModelToJSON,
    CreateTaxRateRequestModelFromJSON,
    CreateTaxRateRequestModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
    TaxCodeDetailsModelFromJSON,
    TaxCodeDetailsModelToJSON,
    TaxCodeListItemModelFromJSON,
    TaxCodeListItemModelToJSON,
} from '../models/index';

export interface CreateTaxCodeRequest {
    createTaxCodeRequestModel: CreateTaxCodeRequestModel;
}

export interface CreateTaxRateRequest {
    id: string;
    createTaxRateRequestModel: CreateTaxRateRequestModel;
}

export interface GetTaxCodeDetailsRequest {
    id: string;
}

/**
 * 
 */
export class TaxCodesApi extends runtime.BaseAPI {

    /**
     * Create a new Tax Code
     */
    async createTaxCodeRaw(requestParameters: CreateTaxCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createTaxCodeRequestModel === null || requestParameters.createTaxCodeRequestModel === undefined) {
            throw new runtime.RequiredError('createTaxCodeRequestModel','Required parameter requestParameters.createTaxCodeRequestModel was null or undefined when calling createTaxCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["tax:codes:manage"]);
        }

        const response = await this.request({
            path: `/tax-codes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTaxCodeRequestModelToJSON(requestParameters.createTaxCodeRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new Tax Code
     */
    async createTaxCode(requestParameters: CreateTaxCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createTaxCodeRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new TaxRate
     */
    async createTaxRateRaw(requestParameters: CreateTaxRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createTaxRate.');
        }

        if (requestParameters.createTaxRateRequestModel === null || requestParameters.createTaxRateRequestModel === undefined) {
            throw new runtime.RequiredError('createTaxRateRequestModel','Required parameter requestParameters.createTaxRateRequestModel was null or undefined when calling createTaxRate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["tax:codes:manage"]);
        }

        const response = await this.request({
            path: `/tax-codes/{id}/rates`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTaxRateRequestModelToJSON(requestParameters.createTaxRateRequestModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new TaxRate
     */
    async createTaxRate(requestParameters: CreateTaxRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createTaxRateRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch Full details of a Tax Code
     */
    async getTaxCodeDetailsRaw(requestParameters: GetTaxCodeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaxCodeDetailsModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTaxCodeDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["tax:codes:view"]);
        }

        const response = await this.request({
            path: `/tax-codes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxCodeDetailsModelFromJSON(jsonValue));
    }

    /**
     * Fetch Full details of a Tax Code
     */
    async getTaxCodeDetails(requestParameters: GetTaxCodeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaxCodeDetailsModel> {
        const response = await this.getTaxCodeDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch list of Tax Codes
     */
    async listTaxCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TaxCodeListItemModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["tax:codes:view"]);
        }

        const response = await this.request({
            path: `/tax-codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxCodeListItemModelFromJSON));
    }

    /**
     * Fetch list of Tax Codes
     */
    async listTaxCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TaxCodeListItemModel>> {
        const response = await this.listTaxCodesRaw(initOverrides);
        return await response.value();
    }

}
