import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { PortfolioBankAccountsApi } from "generated/servicing-v2/apis";
import { CreatePortfolioBankAccountModel } from "generated/servicing-v2/models";
import { keys } from ".";

export const useAddPortfolioBankAccount = (
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(PortfolioBankAccountsApi, "v2");
  const partialMutate = usePartialMutate();

  return useCommand<CreatePortfolioBankAccountModel, void, ResponseError>(
    (portfolioBankAccount) =>
      api.createPortfolioBankAccount({
        createPortfolioBankAccountModel: portfolioBankAccount,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.list());
        options.onSuccess?.();
      },
    },
  );
};
