import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { CustomerContactApi } from "generated/servicing-v2/apis/CustomerContactApi";
import { ContactsListItemModel } from "generated/servicing-v2/models/ContactsListItemModel";
import { keys } from ".";

type UseListContacts = (
  customerId: string,
) => UseQueryReturn<ContactsListItemModel[]>;

export const useListContacts: UseListContacts = (customerId: string) => {
  const api = useApi(CustomerContactApi, "v2");

  return useQuery(keys.list(customerId), () =>
    api.listCustomerContacts({
      id: customerId,
    }),
  );
};
