import { Box, CircularProgress } from "@mui/material";
import { useListOnboardings } from "api/onboarding";
import {
  OnboardingList,
  OnboardingListFilter,
} from "apps/onboarding/components";
import { OnboardingStatusEnum } from "apps/onboarding/types";
import { Loader, QueryError } from "common/components";
import ListLayout from "common/layouts/ListLayout";
import { OnboardingStatusModel } from "generated/onboarding/models";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";

interface ListOnboardingFilterProps {
  status?: OnboardingStatusModel;
  from?: Date;
  to?: Date;
}

export default function OnboardingListContainer(): ReactElement {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filters, setFilters] = useState<ListOnboardingFilterProps>({});
  const onboardings = useListOnboardings({
    pageSize: rowsPerPage,
    params: {
      ...filters,
      from: filters.from,
      to: filters.to,
    },
  });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => onboardings.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  const onFilterChange = (key: string, value: string): void => {
    setFilters((current) => ({ ...current, [key]: value }));
  };

  const renderOnboardings = (): ReactElement => {
    if (onboardings.error || !onboardings.data) {
      return <QueryError onRetry={onboardings.refetch} />;
    }

    return (
      <OnboardingList
        data={onboardings.data.results.map((onboarding) => {
          return {
            ...onboarding,
            statusType: OnboardingStatusEnum[onboarding.statusType],
          };
        })}
        loading={onboardings.isValidating}
      />
    );
  };

  return (
    <>
      <ListLayout
        filters={
          <OnboardingListFilter filters={filters} onChange={onFilterChange} />
        }
        pagination={{
          onPageChange: onChangePage,
          onRowsPerPageChange: onChangeRowsPerPage,
          rowsPerPage,
          page: onboardings.paging.page,
          count: onboardings.data?.paging.total || 0,
        }}
      >
        <Loader
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
          ready={Boolean(onboardings.data ?? onboardings.error)}
          render={renderOnboardings}
        />
      </ListLayout>
    </>
  );
}
