import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { useGetCompany } from "api/companies";
import { useGetPortfolioV2 } from "api/portfolios/getPortfolio";
import PortfolioDetailsContainer from "apps/servicing/modules/settings/containers/PortfolioDetailsContainer";
import UpdatePortfolioContainer from "apps/servicing/modules/settings/containers/UpdatePortfolioContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

interface PortfolioDetailsViewProps {
  companyId: string;
  portfolioId: string;
}

function PortfolioDetailsView({
  companyId,
  portfolioId,
}: PortfolioDetailsViewProps) {
  const { t } = useTranslation("servicing");
  const company = useGetCompany(companyId);
  const portfolio = useGetPortfolioV2(portfolioId);
  const appConfig = useAppConfiguration();

  return (
    <main>
      <ViewLayout
        title={portfolio.data?.name}
        breadcrumbs={[
          {
            href: `${appConfig.appRoutes.servicing}/settings/companies`,
            label: t("companies.breadcrumb_link_label"),
          },
          company.data
            ? {
                href: `${appConfig.appRoutes.servicing}/settings/companies/${companyId}`,
                label: company.data?.name,
              }
            : { label: "..." },
          {
            label: t("companies.portfolio_details.breadcrumb_portfolio_label"),
          },
        ]}
        action={<UpdatePortfolioContainer portfolioId={portfolioId} />}
      >
        <ContainedLayout>
          <PortfolioDetailsContainer portfolioId={portfolioId} />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:companies:manage"],
})(PortfolioDetailsView);
