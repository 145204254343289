import { PortfolioDateReportDialogFormValues } from "apps/reporting/types";
import { useYupResolver } from "common/hooks";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface UseApplySettlementQuoteReturn {
  BeforeTodayResolver: () => Resolver<PortfolioDateReportDialogFormValues>;
  TodayOrBeforeResolver: () => Resolver<PortfolioDateReportDialogFormValues>;
  FutureDatedResolver: () => Resolver<PortfolioDateReportDialogFormValues>;
}

export default function usePortfolioDateReportResolver(): UseApplySettlementQuoteReturn {
  const { t } = useTranslation("reporting");

  const portfolioLabel = t("report_dialog.portfolio_label");
  const fromDateLabel = t("report_dialog.from_date_label");
  const toDateLabel = t("report_dialog.to_date_label");

  const BeforeTodayResolver =
    (): Resolver<PortfolioDateReportDialogFormValues> => {
      return useYupResolver<PortfolioDateReportDialogFormValues>((yup) => {
        return yup.object().shape({
          portfolio: yup.string().isRequired(portfolioLabel),
          fromDate: yup
            .date()
            .localDate()
            .isValidDate(fromDateLabel)
            .isBeforeToday(fromDateLabel)
            .isRequired(fromDateLabel),
          toDate: yup
            .date()
            .localDate()
            .isValidDate(toDateLabel)
            .isBeforeToday(toDateLabel)
            .isRequired(toDateLabel),
        });
      });
    };

  const TodayOrBeforeResolver =
    (): Resolver<PortfolioDateReportDialogFormValues> => {
      return useYupResolver<PortfolioDateReportDialogFormValues>((yup) => {
        return yup.object().shape({
          portfolio: yup.string().isRequired(portfolioLabel),
          fromDate: yup
            .date()
            .localDate()
            .isValidDate(fromDateLabel)
            .isNotFuture(fromDateLabel)
            .isRequired(fromDateLabel),
          toDate: yup
            .date()
            .localDate()
            .isValidDate(toDateLabel)
            .isNotFuture(toDateLabel)
            .isRequired(toDateLabel),
        });
      });
    };

  const FutureDatedResolver =
    (): Resolver<PortfolioDateReportDialogFormValues> => {
      return useYupResolver<PortfolioDateReportDialogFormValues>((yup) => {
        return yup.object().shape({
          portfolio: yup.string().isRequired(portfolioLabel),
          fromDate: yup
            .date()
            .localDate()
            .isValidDate(fromDateLabel)
            .isRequired(fromDateLabel),
          toDate: yup
            .date()
            .localDate()
            .isValidDate(toDateLabel)
            .isRequired(toDateLabel),
        });
      });
    };

  return {
    BeforeTodayResolver,
    FutureDatedResolver,
    TodayOrBeforeResolver,
  };
}
