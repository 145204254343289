import { Loader } from "@ldms/mui-sdk/templates";
import { Search } from "@mui/icons-material";
import { Box, CircularProgress, TextField } from "@mui/material";
import { useListAgreements } from "api/agreements";
import {
  Agreement,
  AgreementList,
} from "apps/servicing/modules/agreements/components";
import { QueryError } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { AgreementListItemModel } from "generated/core/models";
import { debounce } from "lodash";
import {
  ChangeEventHandler,
  MouseEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

export default function AgreementsContainer(): ReactElement {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState("");
  const appConfig = useAppConfiguration();
  const { t } = useTranslation("agreements");
  const agreements = useListAgreements({
    pageSize: rowsPerPage,
    params: { query },
  });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => agreements.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  const onQueryChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    setQuery(event.target.value);
  };

  const handleQueryChange = useMemo(() => debounce(onQueryChange, 300), []);

  useEffect(() => {
    return handleQueryChange.cancel;
  }, [handleQueryChange]);

  const mapAgreement = (agreement: AgreementListItemModel): Agreement => ({
    ...agreement,
    number:
      agreement.number ||
      t("agreements.agreement_number_placeholder", { id: agreement.id }),
    product: agreement.product,
    customer: {
      id: agreement.customerId,
      name: agreement.customerName,
      companyName: agreement.companyName,
      href: `${appConfig.appRoutes.servicing}/customers/${agreement.customerId}`,
    },
    balance: agreement.balance,
    arrears: agreement.arrears,
    status: agreement.status,
    href: `${appConfig.appRoutes.servicing}/agreements/${agreement.id}`,
  });

  return (
    <ListLayout
      filters={
        <TextField
          InputProps={{
            endAdornment: <Search />,
          }}
          inputProps={{
            "aria-label": t("agreements.search_label"),
          }}
          margin="none"
          onChange={handleQueryChange}
        />
      }
      pagination={{
        onPageChange: onChangePage,
        onRowsPerPageChange: onChangeRowsPerPage,
        rowsPerPage,
        page: agreements.paging.page,
        count: agreements.data?.paging.total || 0,
      }}
    >
      <Loader
        ready={Boolean(agreements.data || agreements.error)}
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        render={(): ReactElement => {
          if (agreements.error || !agreements.data) {
            return (
              <Box paddingY={2}>
                <QueryError onRetry={agreements.refetch} />
              </Box>
            );
          }

          return (
            <AgreementList
              data={agreements.data.results.map(mapAgreement)}
              loading={agreements.isValidating}
            />
          );
        }}
      />
    </ListLayout>
  );
}
