import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/roles/keys";
import { useApi } from "common/providers";
import { RolesApi } from "generated/admin/apis";
import { useSWRConfig } from "swr";

type DisassociatePermissionType = {
  permissionId: number;
};

const useDisassociatePermission = (
  roleId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(RolesApi);
  const { mutate } = useSWRConfig();

  return useCommand<DisassociatePermissionType, void, ResponseError>(
    (model) =>
      api.disassociatePermission({ roleId, permissionId: model.permissionId }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        mutate(keys.list());
        mutate(keys.detail(roleId));
      },
    },
  );
};

export { useDisassociatePermission };
