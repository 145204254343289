import { Box, Typography } from "@mui/material";
import { StepperMenu } from "common/providers";
import { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface OnboardingLayoutProps {
  menu: string[];
  children: ReactNode;
  customerName?: string;
}

export default function OnboardingLayout({
  menu,
  children,
  customerName,
}: OnboardingLayoutProps): ReactElement {
  const { t } = useTranslation("onboardings");

  return (
    <Box display="flex">
      <Box flexBasis={300} alignSelf="flex-start" position="sticky">
        <Box marginBottom={2}>
          <Typography component="h1" variant="h2">
            {t("menu_heading")}
          </Typography>
          {customerName && <Typography>{customerName}</Typography>}
        </Box>
        <StepperMenu labels={menu} />
      </Box>
      <Box flex={1}>{children}</Box>
    </Box>
  );
}
