import { TextField, TextFieldProps } from "@mui/material";
import { ReactElement } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

type ControlledTextFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  error?: string;
} & Omit<TextFieldProps, "error">;

export default function ControlledTextField<TFieldValues extends FieldValues>({
  control,
  name: controllerName,
  error,
  ...textFieldProps
}: ControlledTextFieldProps<TFieldValues>): ReactElement {
  return (
    <Controller
      control={control}
      name={controllerName}
      render={({
        field: { onChange, onBlur, value, name, ref },
      }): ReactElement => (
        <TextField
          {...textFieldProps}
          ref={ref}
          name={name}
          onChange={(event) => {
            textFieldProps.onChange?.(event);
            onChange(event);
          }}
          onBlur={onBlur}
          value={value}
          helperText={error}
          error={Boolean(error)}
        />
      )}
    />
  );
}
