import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface AgreementOverviewDetail {
  agreementNumber: string;
  customerName: string;
  productName: string;
  leaseType?: string;
  status: string;
  portfolioName: string;
  currentBalance: string;
  arrearsBalance: string;
  facilityRiskRating?: number;
  setupCosts?: string;
  maintainerName?: string;
  maintenanceAmount?: string;
  maintenanceCost?: string;
}

interface AgreementOverviewDetailsProps {
  agreement?: AgreementOverviewDetail;
}

export default function AgreementOverviewDetails({
  agreement,
}: AgreementOverviewDetailsProps): ReactElement {
  const { t } = useTranslation("agreements");
  const { formatAmount, formatNumber } = useFormat();

  return (
    <DescriptionList label={t("overview.heading")}>
      <DescriptionList.Item label={t("overview.agreement_number")}>
        {agreement?.agreementNumber}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.customer")}>
        {agreement?.customerName}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.product")}>
        {agreement?.productName}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.lease_type")}>
        {agreement?.leaseType || "-"}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.status")}>
        {agreement?.status}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.portfolio")}>
        {agreement?.portfolioName}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.current_balance")}>
        {formatAmount(agreement?.currentBalance)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.arrears_balance")}>
        {formatAmount(agreement?.arrearsBalance)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.facility_risk_rating")}>
        {formatNumber(agreement?.facilityRiskRating)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.setup_costs_label")}>
        {formatAmount(agreement?.setupCosts)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.maintainer_name")}>
        {agreement?.maintainerName ?? "-"}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.maintenance_amount")}>
        {formatAmount(agreement?.maintenanceAmount)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("overview.maintenance_cost")}>
        {formatAmount(agreement?.maintenanceCost)}
      </DescriptionList.Item>
    </DescriptionList>
  );
}
