import { AlertDialog } from "@ldms/mui-sdk/templates";
import { Typography } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

export const truncateText = (value: string, length?: number): string => {
  const maxLength = length ?? 150;

  if (value.length > maxLength) {
    return `${value.substring(0, maxLength)}...`;
  }

  return value;
};

interface TruncateTypographyProps {
  children: string;
  title: React.ReactNode;
}

export default function TruncateTypography({
  children,
  title,
}: TruncateTypographyProps): ReactElement {
  const [isFullContentView, setFullContentView] = useState(false);
  const { t } = useTranslation();
  const handleViewContent = (): void => setFullContentView(true);

  const handleCloseDialog = (): void => {
    setFullContentView(false);
  };

  return (
    <>
      <Typography onClick={handleViewContent}>
        {truncateText(children)}
      </Typography>
      <AlertDialog
        content={children}
        labels={{ close: t("alert.close") }}
        onClose={handleCloseDialog}
        open={isFullContentView}
        title={title}
      />
    </>
  );
}
