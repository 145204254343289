import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { CustomerMemosApi } from "generated/servicing-v2/apis";
import { MemoModel } from "generated/servicing-v2/models";
import { keys } from ".";

type UseListCustomerMemos = (
  customerId: string,
  params: { from?: Date },
) => UseQueryReturn<MemoModel[]>;

export const useListCustomerMemos: UseListCustomerMemos = (
  customerId: string,
  params: { from?: Date } = {},
) => {
  const api = useApi(CustomerMemosApi, "v2");

  return useQuery(keys.list(customerId, params), () =>
    api.listCustomerMemos({
      id: customerId,
      from: params.from,
    }),
  );
};
