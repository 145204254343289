import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ResponseError } from "generated";
import { TasksApi } from "generated/tasks/apis";
import { AddCommentModel } from "generated/tasks/models";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

const useAddComment = (
  taskId: number,
  options?: UseCommandOptions<void, ResponseError>,
) => {
  const api = useApi(TasksApi, "task-management");
  const swr = useSWRConfig();

  return useCommand(
    async (commentModel: AddCommentModel) =>
      api.addComment({
        taskId,
        addCommentModel: commentModel,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.detail(taskId));
        options?.onSuccess?.();
      },
    },
  );
};

export { useAddComment };
