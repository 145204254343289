import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Delete } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  useDisassociateThirdParty,
  useListAgreementThirdParties,
} from "api/agreements/third-parties";
import {
  AgreementThirdPartyDetailsContainer,
  AssociateThirdPartyContainer,
} from "apps/servicing/modules/agreements/containers";
import { Loader } from "common/components";
import Surface from "common/components/Surface";
import { useConfirm, useResponseError } from "common/hooks";
import { AgreementThirdPartyListItemModel } from "generated/core/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

const CommandConfirmation = ({
  open,
  title,
  content,
  onConfirm,
  onReject,
  errorMessage,
  onCloseError,
}: {
  open: boolean;
  title: string;
  content: string;
  onConfirm: () => void;
  onReject: () => void;
  errorMessage: string | undefined;
  onCloseError: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <AlertDialog
        content={errorMessage}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseError}
        open={Boolean(errorMessage)}
        title={t("common:error.title")}
      />

      <ConfirmationDialog
        content={content}
        open={open}
        onConfirm={onConfirm}
        onReject={onReject}
        title={title}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />
    </>
  );
};

const DisassociateThirdParty = ({
  agreementId,
  thirdPartyId,
}: {
  agreementId: number;
  thirdPartyId: number;
}) => {
  const { t } = useTranslation("agreements");
  const responseError = useResponseError();
  const disassociateThirdParty = useDisassociateThirdParty(
    agreementId,
    thirdPartyId,
    {
      onError: (error) => responseError.setError(error.code),
    },
  );
  const confirm = useConfirm();

  const handleClick = () =>
    confirm.handlePrompt(() => disassociateThirdParty.execute());

  return (
    <>
      <IconButton
        color="primary"
        edge="end"
        onClick={handleClick}
        size="small"
        aria-label={t("third_parties.disassociate_label")}
      >
        <Delete fontSize="small" />
      </IconButton>

      <CommandConfirmation
        open={confirm.isOpen}
        title={t("third_parties.disassociate_confirmation.title_label")}
        content={t("third_parties.disassociate_confirmation.message")}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        onCloseError={responseError.reset}
        errorMessage={responseError.message}
      />
    </>
  );
};

interface AgreementThirdPartiesContainerProps {
  agreementId: number;
}

export default function AgreementThirdPartiesContainer({
  agreementId,
}: AgreementThirdPartiesContainerProps): ReactElement {
  const { t } = useTranslation("agreements");

  const [thirdPartyId, setThirdPartyId] = useState<number>();
  const thirdParties = useListAgreementThirdParties(agreementId);

  const firstThirdParty = thirdParties.data?.[0];
  const currentThirdParty =
    thirdParties.data?.find((thirdParty) => thirdParty.id === thirdPartyId) ||
    firstThirdParty;

  const handleThirdPartyTypeChange =
    (thirdPartyListItem: AgreementThirdPartyListItemModel) => (): void => {
      setThirdPartyId(thirdPartyListItem.id);
    };

  return (
    <Loader
      ready={Boolean(thirdParties.data || thirdParties.error)}
      render={(): ReactElement => {
        if (thirdParties.error) {
          return (
            <>
              <Typography color="error" data-testid="contacts.error">
                {t("common:error.default")}
              </Typography>
            </>
          );
        }

        if (thirdParties.data?.length === 0) {
          return (
            <Box textAlign="center">
              <Typography color="textSecondary" gutterBottom>
                {t("third_parties.no_third_parties")}
              </Typography>
              <AccessControl
                allowedPermissions={["servicing:third-parties:associate"]}
              >
                <AssociateThirdPartyContainer agreementId={agreementId} />
              </AccessControl>
            </Box>
          );
        }

        return (
          <Box display="flex" justifyContent="center">
            <Grid container spacing={4}>
              <Grid item>
                <Box width={300}>
                  <Surface
                    disableGutters
                    title={t("third_parties.list_heading")}
                  >
                    <List disablePadding dense>
                      {thirdParties.data?.map((thirdParty) => (
                        <ListItem
                          key={thirdParty.id}
                          onClick={handleThirdPartyTypeChange(thirdParty)}
                          selected={currentThirdParty?.id === thirdParty.id}
                          button
                          secondaryAction={
                            <AccessControl
                              allowedPermissions={[
                                "servicing:third-parties:associate",
                              ]}
                            >
                              <DisassociateThirdParty
                                agreementId={agreementId}
                                thirdPartyId={thirdParty.id}
                              />
                            </AccessControl>
                          }
                        >
                          <ListItemText
                            primary={thirdParty.type}
                            secondary={thirdParty.name}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Surface>
                </Box>
              </Grid>
              <Grid item xs>
                <Loader
                  fallback={
                    <Box display="flex" justifyContent="center" p={2}>
                      <CircularProgress />
                    </Box>
                  }
                  ready={Boolean(currentThirdParty)}
                  render={(): ReactElement => (
                    <AgreementThirdPartyDetailsContainer
                      thirdParty={
                        currentThirdParty as AgreementThirdPartyListItemModel
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        );
      }}
    />
  );
}
