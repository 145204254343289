import * as keys from "api/documents/keys";
import { QueryKey } from "api/types";
import { ListTemplatesRequest } from "generated/documents/apis";

export const all = (): QueryKey => [...keys.all(), "templates"];

export const list = (params?: ListTemplatesRequest) => [
  ...all(),
  "list",
  ...(params ? [params.portfolioId, params.type] : []),
];

export const detail = (id: string): QueryKey => [...all(), "detail", id];
