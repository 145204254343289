import { AccessControl, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Stack } from "@mui/material";
import { useGetTemplate } from "api/documents/templates/getTemplate";
import EditLetterTemplateContainer from "apps/documents/containers/EditLetterTemplateContainer";
import TemplateVersions from "apps/documents/containers/TemplateVersions";
import UploadTemplateVersionContainer from "apps/documents/containers/UploadTemplateVersionContainer";
import { TemplateTypeEnum } from "apps/documents/types";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface VersionsViewProps {
  templateId: string;
  type: TemplateTypeEnum;
}

function VersionsView({
  templateId,
  type,
}: Readonly<VersionsViewProps>): ReactElement {
  const { t } = useTranslation("documents");
  const appConfig = useAppConfiguration();
  const template = useGetTemplate(templateId);

  const breadcrumbLabel =
    type === TemplateTypeEnum.System
      ? t("breadcrumbs.templates_label")
      : t("breadcrumbs.letter_templates_label");

  const breadcrumbHref =
    type === TemplateTypeEnum.System
      ? `${appConfig.appRoutes.documents}/settings/templates`
      : `${appConfig.appRoutes.documents}/settings/letter-templates`;

  const currentVersion =
    template?.data?.versions[template?.data?.versions.length - 1].version ?? 0;

  return (
    <ViewLayout
      action={
        template.data && (
          <AccessControl allowedPermissions={["documents:templates:manage"]}>
            <Stack direction="row" spacing={2}>
              {type === TemplateTypeEnum.Letters && (
                <EditLetterTemplateContainer
                  templateId={templateId}
                  defaultValues={{
                    name: template.data.type,
                    description: template.data.description,
                    documentType: template.data.documentType,
                    status: template.data.active,
                  }}
                />
              )}
              <UploadTemplateVersionContainer
                templateId={templateId}
                currentVersion={currentVersion}
              />
            </Stack>
          </AccessControl>
        )
      }
      breadcrumbs={[
        {
          label: breadcrumbLabel,
          href: breadcrumbHref,
        },
      ]}
      title={template.data?.type}
    >
      <ContainedLayout>
        <TemplateVersions templateId={templateId} type={type} />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default withProtectedView({
  allowedPermissions: ["documents:templates:manage"],
})(VersionsView);
