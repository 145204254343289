import { ClientActivity } from "apps/servicing/modules/customers/containers";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { ContainedLayout } from "common/layouts";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function ActivityView(): ReactElement {
  const customer = useCustomer();
  const { t } = useTranslation("customers");

  return (
    <ViewLayout title={t("activity.heading")}>
      <FixedLayout>
        <ContainedLayout>
          <ClientActivity clientId={customer.data.id} />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}
