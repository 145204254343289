/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTemplateVersionMetadataModel
 */
export interface CreateTemplateVersionMetadataModel {
    /**
     * The comment associated to the new template version
     * @type {string}
     * @memberof CreateTemplateVersionMetadataModel
     */
    comment: string;
}

/**
 * Check if a given object implements the CreateTemplateVersionMetadataModel interface.
 */
export function instanceOfCreateTemplateVersionMetadataModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "comment" in value;

    return isInstance;
}

export function CreateTemplateVersionMetadataModelFromJSON(json: any): CreateTemplateVersionMetadataModel {
    return CreateTemplateVersionMetadataModelFromJSONTyped(json, false);
}

export function CreateTemplateVersionMetadataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTemplateVersionMetadataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': json['comment'],
    };
}

export function CreateTemplateVersionMetadataModelToJSON(value?: CreateTemplateVersionMetadataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
    };
}

