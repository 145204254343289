import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import ListFinancialProductsContainer from "apps/onboarding/containers/ListFinancialProductsContainer";
import DevToggle from "common/components/DevToggle";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function FinancialProductsView() {
  const { t } = useTranslation("onboardings");

  return (
    <main>
      <ViewLayout
        title={t("financial_products.financial_products_heading")}
        action={
          <DevToggle feature="dev">
            <AddButton
              variant="contained"
              component={Link}
              to="../financial-products/add-financial-product"
            />
          </DevToggle>
        }
      >
        <ContainedLayout>
          <ListFinancialProductsContainer />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["onboarding:financial-products:manage"],
})(FinancialProductsView);
