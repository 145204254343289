import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { keys } from "api/agreements/schedule";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementScheduleApi } from "generated/core/apis";
import { ChangeInstalmentPaymentDateModel } from "generated/core/models";

export const useChangeInstalmentPaymentDate = (
  agreementId: number,
  instalmentNumber: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementScheduleApi);
  const partialMutate = usePartialMutate();

  return useCommand<ChangeInstalmentPaymentDateModel, void, ResponseError>(
    (model) =>
      api.changeInstalmentPaymentDate({
        agreementId,
        instalmentNumber,
        changeInstalmentPaymentDateModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.list(agreementId));
        options.onSuccess?.();
      },
    },
  );
};
