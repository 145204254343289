import { useState } from "react";

const useModal = () => {
  const [isOpen, setOpen] = useState(false);

  return {
    open: () => setOpen(true),
    close: () => setOpen(false),
    isOpen,
  };
};

export default useModal;
