/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Tax Codes within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxRateListItemModel
 */
export interface TaxRateListItemModel {
    /**
     * Actual Tax rate
     * @type {number}
     * @memberof TaxRateListItemModel
     */
    rate: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof TaxRateListItemModel
     */
    effectiveDate: Date;
    /**
     * Username of the user that created the tax rate
     * @type {string}
     * @memberof TaxRateListItemModel
     */
    createdBy: string;
    /**
     * A date time format
     * @type {Date}
     * @memberof TaxRateListItemModel
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the TaxRateListItemModel interface.
 */
export function instanceOfTaxRateListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "effectiveDate" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function TaxRateListItemModelFromJSON(json: any): TaxRateListItemModel {
    return TaxRateListItemModelFromJSONTyped(json, false);
}

export function TaxRateListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxRateListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rate': json['rate'],
        'effectiveDate': (new Date(json['effectiveDate'])),
        'createdBy': json['createdBy'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function TaxRateListItemModelToJSON(value?: TaxRateListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rate': value.rate,
        'effectiveDate': (value.effectiveDate.toISOString().substring(0,10)),
        'createdBy': value.createdBy,
        'createdAt': (value.createdAt.toISOString()),
    };
}

