/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Agreements within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactTypeModel } from './ContactTypeModel';
import {
    ContactTypeModelFromJSON,
    ContactTypeModelFromJSONTyped,
    ContactTypeModelToJSON,
} from './ContactTypeModel';

/**
 * A Payee
 * @export
 * @interface PayeeModel
 */
export interface PayeeModel {
    /**
     * Unique ID of customer/contact
     * @type {string}
     * @memberof PayeeModel
     */
    bankSystemId: string;
    /**
     * 
     * @type {ContactTypeModel}
     * @memberof PayeeModel
     */
    contactType?: ContactTypeModel;
    /**
     * Unique ID of customer/contact
     * @type {string}
     * @memberof PayeeModel
     */
    contactSystemId?: string;
    /**
     * Name of the payee
     * @type {string}
     * @memberof PayeeModel
     */
    name: string;
    /**
     * The name of the account holder
     * @type {string}
     * @memberof PayeeModel
     */
    accountHoldersName?: string;
    /**
     * The bank account sort code
     * @type {string}
     * @memberof PayeeModel
     */
    sortCode: string;
    /**
     * The bank account number
     * @type {string}
     * @memberof PayeeModel
     */
    accountNumber: string;
    /**
     * The international bank account number
     * @type {string}
     * @memberof PayeeModel
     */
    iban: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof PayeeModel
     */
    bic: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PayeeModel
     */
    earliestMandateStartDate: Date;
}

/**
 * Check if a given object implements the PayeeModel interface.
 */
export function instanceOfPayeeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bankSystemId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "sortCode" in value;
    isInstance = isInstance && "accountNumber" in value;
    isInstance = isInstance && "iban" in value;
    isInstance = isInstance && "bic" in value;
    isInstance = isInstance && "earliestMandateStartDate" in value;

    return isInstance;
}

export function PayeeModelFromJSON(json: any): PayeeModel {
    return PayeeModelFromJSONTyped(json, false);
}

export function PayeeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayeeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankSystemId': json['bankSystemId'],
        'contactType': !exists(json, 'contactType') ? undefined : ContactTypeModelFromJSON(json['contactType']),
        'contactSystemId': !exists(json, 'contactSystemId') ? undefined : json['contactSystemId'],
        'name': json['name'],
        'accountHoldersName': !exists(json, 'accountHoldersName') ? undefined : json['accountHoldersName'],
        'sortCode': json['sortCode'],
        'accountNumber': json['accountNumber'],
        'iban': json['iban'],
        'bic': json['bic'],
        'earliestMandateStartDate': (new Date(json['earliestMandateStartDate'])),
    };
}

export function PayeeModelToJSON(value?: PayeeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankSystemId': value.bankSystemId,
        'contactType': ContactTypeModelToJSON(value.contactType),
        'contactSystemId': value.contactSystemId,
        'name': value.name,
        'accountHoldersName': value.accountHoldersName,
        'sortCode': value.sortCode,
        'accountNumber': value.accountNumber,
        'iban': value.iban,
        'bic': value.bic,
        'earliestMandateStartDate': (value.earliestMandateStartDate.toISOString().substring(0,10)),
    };
}

