/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';

/**
 * 
 * @export
 * @interface CustomerOverviewModel
 */
export interface CustomerOverviewModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof CustomerOverviewModel
     */
    readonly id: number;
    /**
     * A read only Id
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    readonly systemId: string;
    /**
     * The individuality of the customer
     * @type {boolean}
     * @memberof CustomerOverviewModel
     */
    isIndividual: boolean;
    /**
     * The name of the customer
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    name: string;
    /**
     * The name of the company
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    companyName?: string;
    /**
     * The company type
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    companyType: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof CustomerOverviewModel
     */
    address?: AddressModel;
    /**
     * The contact email address
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    email?: string;
    /**
     * The contact telephone number
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    telephoneNumber?: string;
    /**
     * The company registration number
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    companyRegistrationNumber?: string;
    /**
     * The VAT registration number
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    vatRegistrationNumber?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    totalCurrentBalance?: string;
    /**
     * The obligor risk rating associated to the customer
     * @type {number}
     * @memberof CustomerOverviewModel
     */
    obligorRiskRating?: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof CustomerOverviewModel
     */
    dateOfBirth?: Date;
    /**
     * The company size
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    companySize?: string;
    /**
     * The national identification number
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    nationalIdentificationNumber?: string;
    /**
     * The sector of economic activity
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    sectorOfEconomicActivity?: string;
    /**
     * The company sector
     * @type {string}
     * @memberof CustomerOverviewModel
     */
    companySector?: string;
}

/**
 * Check if a given object implements the CustomerOverviewModel interface.
 */
export function instanceOfCustomerOverviewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemId" in value;
    isInstance = isInstance && "isIndividual" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyType" in value;

    return isInstance;
}

export function CustomerOverviewModelFromJSON(json: any): CustomerOverviewModel {
    return CustomerOverviewModelFromJSONTyped(json, false);
}

export function CustomerOverviewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOverviewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemId': json['systemId'],
        'isIndividual': json['isIndividual'],
        'name': json['name'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyType': json['companyType'],
        'address': !exists(json, 'address') ? undefined : AddressModelFromJSON(json['address']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'telephoneNumber': !exists(json, 'telephoneNumber') ? undefined : json['telephoneNumber'],
        'companyRegistrationNumber': !exists(json, 'companyRegistrationNumber') ? undefined : json['companyRegistrationNumber'],
        'vatRegistrationNumber': !exists(json, 'vatRegistrationNumber') ? undefined : json['vatRegistrationNumber'],
        'totalCurrentBalance': !exists(json, 'totalCurrentBalance') ? undefined : json['totalCurrentBalance'],
        'obligorRiskRating': !exists(json, 'obligorRiskRating') ? undefined : json['obligorRiskRating'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'companySize': !exists(json, 'companySize') ? undefined : json['companySize'],
        'nationalIdentificationNumber': !exists(json, 'nationalIdentificationNumber') ? undefined : json['nationalIdentificationNumber'],
        'sectorOfEconomicActivity': !exists(json, 'sectorOfEconomicActivity') ? undefined : json['sectorOfEconomicActivity'],
        'companySector': !exists(json, 'companySector') ? undefined : json['companySector'],
    };
}

export function CustomerOverviewModelToJSON(value?: CustomerOverviewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isIndividual': value.isIndividual,
        'name': value.name,
        'companyName': value.companyName,
        'companyType': value.companyType,
        'address': AddressModelToJSON(value.address),
        'email': value.email,
        'telephoneNumber': value.telephoneNumber,
        'companyRegistrationNumber': value.companyRegistrationNumber,
        'vatRegistrationNumber': value.vatRegistrationNumber,
        'totalCurrentBalance': value.totalCurrentBalance,
        'obligorRiskRating': value.obligorRiskRating,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substring(0,10)),
        'companySize': value.companySize,
        'nationalIdentificationNumber': value.nationalIdentificationNumber,
        'sectorOfEconomicActivity': value.sectorOfEconomicActivity,
        'companySector': value.companySector,
    };
}

