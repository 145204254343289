import { QueryKey } from "api";

export const all = (): QueryKey => ["portfolios"];

export const list = (): QueryKey => [...all(), "list"];

export const details = (): QueryKey => [...all(), "list", "detail"];

export const detail = (portfolioId: number | string): QueryKey => [
  ...details(),
  portfolioId,
];
