import { QueryKey } from "api";

export const all = (): QueryKey => ["portfolio-bank-accounts"];

export const list = (): QueryKey => [...all(), "list"];

export const details = (): QueryKey => [...all(), "detail"];
export const detail = (portfolioBankAccountId: number | string): QueryKey => [
  ...details(),
  portfolioBankAccountId,
];
