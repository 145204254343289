/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientActivityModel
 */
export interface ClientActivityModel {
    /**
     * A date time format
     * @type {string}
     * @memberof ClientActivityModel
     */
    created: string;
    /**
     * The operator(user) name who performed the activity
     * @type {string}
     * @memberof ClientActivityModel
     */
    operatorName: string;
    /**
     * A read only Id
     * @type {number}
     * @memberof ClientActivityModel
     */
    readonly agreementId?: number;
    /**
     * An alternative unique identifier used in correspondence
     * @type {string}
     * @memberof ClientActivityModel
     */
    agreementNumber?: string;
    /**
     * The activity action that was performed
     * @type {string}
     * @memberof ClientActivityModel
     */
    action: string;
    /**
     * The more detailed description of the activity
     * @type {string}
     * @memberof ClientActivityModel
     */
    description?: string;
}

/**
 * Check if a given object implements the ClientActivityModel interface.
 */
export function instanceOfClientActivityModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "operatorName" in value;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function ClientActivityModelFromJSON(json: any): ClientActivityModel {
    return ClientActivityModelFromJSONTyped(json, false);
}

export function ClientActivityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientActivityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'operatorName': json['operatorName'],
        'agreementId': !exists(json, 'agreementId') ? undefined : json['agreementId'],
        'agreementNumber': !exists(json, 'agreementNumber') ? undefined : json['agreementNumber'],
        'action': json['action'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ClientActivityModelToJSON(value?: ClientActivityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'operatorName': value.operatorName,
        'agreementNumber': value.agreementNumber,
        'action': value.action,
        'description': value.description,
    };
}

