import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { Loader } from "@ldms/mui-sdk/templates";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  AgreementOverviewDetails,
  FeesAndCommissionsDetails,
  FinancialDetails,
  RepaymentTermsDetails,
} from "apps/servicing/modules/agreements/components";
import FinancialDetailsMenu from "apps/servicing/modules/agreements/containers/FinancialDetailsMenu";
import ModifiableToggle from "common/components/ModifiableToggle";
import Surface from "common/components/Surface";
import { useAgreement } from "common/providers";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function AgreementDetailsContainer(): ReactElement {
  const { t } = useTranslation(["agreements", "common"]);
  const agreement = useAgreement();

  const renderAgreementDetails = (): ReactElement => {
    return agreement.error ? (
      <Typography color="error" data-testid="agreementDetails.error">
        {t("common:error.default")}
      </Typography>
    ) : (
      <>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Stack spacing={2}>
                <Surface title={t("overview.heading")}>
                  <AgreementOverviewDetails agreement={agreement?.data} />
                </Surface>

                <Surface
                  title={t("financial_details.heading")}
                  action={
                    agreement.data?.subsidyDate && (
                      <AccessControl
                        allowedPermissions={["servicing:subsidy:manage"]}
                      >
                        <ModifiableToggle>
                          <FinancialDetailsMenu
                            agreementId={agreement.id}
                            subsidyDate={agreement?.data?.subsidyDate}
                          />
                        </ModifiableToggle>
                      </AccessControl>
                    )
                  }
                >
                  <FinancialDetails financialDetails={agreement?.data} />
                </Surface>
              </Stack>
            </Grid>
            <Grid item sm={6}>
              <Stack spacing={2}>
                <Surface title={t("repayment_terms.heading")}>
                  <RepaymentTermsDetails repaymentTerms={agreement?.data} />
                </Surface>

                <Surface title={t("fees_commissions.heading")}>
                  <FeesAndCommissionsDetails
                    feesAndCommissions={agreement?.data}
                    fees={agreement.data?.fees}
                  />
                </Surface>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <Loader
      ready={Boolean(agreement.data || agreement.error)}
      render={renderAgreementDetails}
    />
  );
}
