import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { AgreementThirdPartyListItemModel } from "generated/core/models";
import { keys } from ".";

type UseListAgreementThirdParties = (
  agreementId: number,
) => UseQueryReturn<AgreementThirdPartyListItemModel[]>;

export const useListAgreementThirdParties: UseListAgreementThirdParties = (
  agreementId: number,
) => {
  const api = useApi(AgreementQueryApi);

  return useQuery(keys.list(agreementId), () =>
    api.listAgreementThirdParties({
      agreementId: agreementId,
    }),
  );
};
