/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Customers within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomerModel,
  CustomerOverviewModel,
  ErrorWithParametersModel,
} from '../models/index';
import {
    CustomerModelFromJSON,
    CustomerModelToJSON,
    CustomerOverviewModelFromJSON,
    CustomerOverviewModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
} from '../models/index';

export interface GetCustomerDetailsRequest {
    id: string;
}

export interface UpdateCustomerRequest {
    id: string;
    customerModel: CustomerModel;
}

/**
 * 
 */
export class CustomersApi extends runtime.BaseAPI {

    /**
     * Get Customer details
     */
    async getCustomerDetailsRaw(requestParameters: GetCustomerDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerOverviewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomerDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customers:view"]);
        }

        const response = await this.request({
            path: `/customers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerOverviewModelFromJSON(jsonValue));
    }

    /**
     * Get Customer details
     */
    async getCustomerDetails(requestParameters: GetCustomerDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerOverviewModel> {
        const response = await this.getCustomerDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Customer
     */
    async updateCustomerRaw(requestParameters: UpdateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCustomer.');
        }

        if (requestParameters.customerModel === null || requestParameters.customerModel === undefined) {
            throw new runtime.RequiredError('customerModel','Required parameter requestParameters.customerModel was null or undefined when calling updateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customers:manage"]);
        }

        const response = await this.request({
            path: `/customers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerModelToJSON(requestParameters.customerModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Customer
     */
    async updateCustomer(requestParameters: UpdateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomerRaw(requestParameters, initOverrides);
    }

}
