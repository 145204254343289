import { DateField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useUpdateSubsidyDate } from "api/agreements/subsidy";
import { useYupResolver } from "common/hooks";
import { useAgreement } from "common/providers";
import { UpdateSubsidyDateModel } from "generated/core/models";
import React, { ReactElement, useState } from "react";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface FinancialDetailsMenuProps {
  agreementId: number;
  subsidyDate: Date;
}

export interface UpdateSubsidyDateFieldValues {
  subsidyDate: Date;
}

const useUpdateSubsidyDateResolver =
  (): Resolver<UpdateSubsidyDateFieldValues> => {
    const { t } = useTranslation("agreements");
    const subsidyDateLabel = t("subsidy_date.subsidy_date_label");
    return useYupResolver<UpdateSubsidyDateFieldValues>((yup) =>
      yup.object().shape({
        subsidyDate: yup
          .date()
          .localDate()
          .isValidDate(subsidyDateLabel)
          .isRequired(subsidyDateLabel),
      }),
    );
  };

export default function FinancialDetailsMenu({
  agreementId,
  subsidyDate,
}: Readonly<FinancialDetailsMenuProps>): ReactElement {
  const { t } = useTranslation("agreements");
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const menuOpen = Boolean(menuElement);
  const resolver = useUpdateSubsidyDateResolver();
  const agreement = useAgreement();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuElement(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setMenuElement(null);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = (): void => {
    setDialogOpen(false);
    setMenuElement(null);
  };

  const onSuccess = () => {
    handleCloseDialog();
    agreement.mutate();
  };

  const updateSubsidyDate = useUpdateSubsidyDate(agreementId, {
    onSuccess,
  });

  const onSubmit = async (
    data: UpdateSubsidyDateFieldValues,
  ): Promise<void> => {
    const updateSubsidyDateModel: UpdateSubsidyDateModel = {
      subsidyDate: data.subsidyDate,
    };
    await updateSubsidyDate.command(updateSubsidyDateModel);
  };

  return (
    <>
      <IconButton
        aria-label={t("subsidy_date.menu_button")}
        color="primary"
        onClick={handleMenuClick}
      >
        <MoreVert />
      </IconButton>

      <Menu open={menuOpen} anchorEl={menuElement} onClose={handleMenuClose}>
        <MenuItem onClick={handleOpenDialog}>
          {t("subsidy_date.update_button")}
        </MenuItem>
      </Menu>

      <FormDialog
        dialogProps={{ maxWidth: "sm", scroll: "paper" }}
        title={t("subsidy_date.title_label")}
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSubmit={onSubmit}
        resolver={resolver}
        ready={Boolean(subsidyDate)}
        defaultValues={{ subsidyDate }}
      >
        {(form): ReactElement => {
          return (
            <Stack gap={2} padding={2}>
              <DateField
                error={Boolean(form.formState.errors?.subsidyDate?.message)}
                helperText={form.formState.errors?.subsidyDate?.message}
                control={form.control}
                name="subsidyDate"
                label={t("subsidy_date.subsidy_date_label")}
                margin="none"
                defaultValue={subsidyDate}
                required
              />
              {updateSubsidyDate.error && (
                <Typography color="error">
                  {updateSubsidyDate.error?.message}
                </Typography>
              )}
            </Stack>
          );
        }}
      </FormDialog>
    </>
  );
}
