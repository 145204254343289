/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing termination within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TerminationRecoveryDetailsModel
 */
export interface TerminationRecoveryDetailsModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof TerminationRecoveryDetailsModel
     */
    currentArrears: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof TerminationRecoveryDetailsModel
     */
    capitalOutstanding: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof TerminationRecoveryDetailsModel
     */
    recoveryCosts?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof TerminationRecoveryDetailsModel
     */
    customerPayments?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof TerminationRecoveryDetailsModel
     */
    guarantors?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof TerminationRecoveryDetailsModel
     */
    saleOfAsset?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof TerminationRecoveryDetailsModel
     */
    buyBack?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof TerminationRecoveryDetailsModel
     */
    totalToRecover: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof TerminationRecoveryDetailsModel
     */
    totalWriteOff: string;
}

/**
 * Check if a given object implements the TerminationRecoveryDetailsModel interface.
 */
export function instanceOfTerminationRecoveryDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentArrears" in value;
    isInstance = isInstance && "capitalOutstanding" in value;
    isInstance = isInstance && "totalToRecover" in value;
    isInstance = isInstance && "totalWriteOff" in value;

    return isInstance;
}

export function TerminationRecoveryDetailsModelFromJSON(json: any): TerminationRecoveryDetailsModel {
    return TerminationRecoveryDetailsModelFromJSONTyped(json, false);
}

export function TerminationRecoveryDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TerminationRecoveryDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentArrears': json['currentArrears'],
        'capitalOutstanding': json['capitalOutstanding'],
        'recoveryCosts': !exists(json, 'recoveryCosts') ? undefined : json['recoveryCosts'],
        'customerPayments': !exists(json, 'customerPayments') ? undefined : json['customerPayments'],
        'guarantors': !exists(json, 'guarantors') ? undefined : json['guarantors'],
        'saleOfAsset': !exists(json, 'saleOfAsset') ? undefined : json['saleOfAsset'],
        'buyBack': !exists(json, 'buyBack') ? undefined : json['buyBack'],
        'totalToRecover': json['totalToRecover'],
        'totalWriteOff': json['totalWriteOff'],
    };
}

export function TerminationRecoveryDetailsModelToJSON(value?: TerminationRecoveryDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentArrears': value.currentArrears,
        'capitalOutstanding': value.capitalOutstanding,
        'recoveryCosts': value.recoveryCosts,
        'customerPayments': value.customerPayments,
        'guarantors': value.guarantors,
        'saleOfAsset': value.saleOfAsset,
        'buyBack': value.buyBack,
        'totalToRecover': value.totalToRecover,
        'totalWriteOff': value.totalWriteOff,
    };
}

