import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { PortfoliosApi } from "generated/servicing-v2/apis";
import { PortfolioListItemModel } from "generated/servicing-v2/models";
import { keys } from ".";

type UseListPortfolios = (
  companyId: string,
) => UseQueryReturn<PortfolioListItemModel[]>;

export const useListPortfolios: UseListPortfolios = (
  companySystemId: string,
) => {
  const api = useApi(PortfoliosApi, "v2");

  return useQuery(keys.list(companySystemId), () =>
    api.listPortfolios({
      companySystemId,
    }),
  );
};
