import { AddButton } from "@ldms/mui-sdk/components";
import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useAddFee } from "api/onboarding/fees/addFee";
import { FeeTypeEnum } from "apps/onboarding/types";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import { AddFeeModel, FeeTypeModel } from "generated/onboarding/models";
import { ReactElement, useState } from "react";
import { Controller, Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface AddFeeFieldValues {
  name: string;
  type: FeeTypeModel;
  taxable: boolean;
  deductedFromAdvance: boolean;
  collectByDirectDebit: boolean;
  includeOnInvoices: boolean;
}

const feeNameLabel = "fees.add_fee.name_label";

const useAddFeeResolver = (): Resolver<AddFeeFieldValues> => {
  const { t } = useTranslation("onboardings");

  return useYupResolver<AddFeeFieldValues>((yup) =>
    yup.object().shape({
      name: yup
        .string()
        .isRequired(t(feeNameLabel))
        .maxCharacters(200, t(feeNameLabel)),
      type: yup.string().isRequired(t("fees.add_fee.fee_type_label")),
      deductedFromAdvance: yup.boolean(),
      taxable: yup.boolean(),
      collectByDirectDebit: yup.boolean(),
      includeOnInvoices: yup.boolean(),
    }),
  );
};

export default function AddFeeContainer(): ReactElement {
  const { t } = useTranslation("onboardings");
  const resolver = useAddFeeResolver();
  const [openAddFeeDialog, setOpenAddFeeDialog] = useState(false);

  const openDialog = (): void => {
    setOpenAddFeeDialog(true);
  };

  const closeDialog = (): void => {
    setOpenAddFeeDialog(false);
    addFee.reset();
  };

  const addFee = useAddFee({
    onSuccess: closeDialog,
  });

  const onSubmit = async (data: AddFeeFieldValues): Promise<void> => {
    addFee.reset();
    const addFeeModel: AddFeeModel = {
      ...data,
      enabled: true,
    };

    await addFee.execute(addFeeModel);
  };

  return (
    <>
      <AddButton variant="contained" onClick={openDialog} />

      <FormDialog
        onSubmit={onSubmit}
        resolver={resolver}
        title={t("fees.add_fee.title_label")}
        onClose={closeDialog}
        open={openAddFeeDialog}
        disabled={addFee.isExecuting}
        defaultValues={{
          type: undefined,
        }}
      >
        {(form): ReactElement => {
          return (
            <>
              <Grid container spacing={2} rowSpacing={0.5}>
                <Grid item sm={12}>
                  <TextField
                    {...form.register("name")}
                    helperText={form.formState.errors.name?.message}
                    error={Boolean(form.formState.errors.name)}
                    label={t(feeNameLabel)}
                    required
                  />
                </Grid>
                <Grid item sm={12}>
                  <ControlledTextField
                    id="type"
                    name="type"
                    label={t("fees.add_fee.fee_type_label")}
                    helperText={form.formState.errors?.type?.message}
                    error={form.formState.errors?.type?.message}
                    SelectProps={{ displayEmpty: true }}
                    control={form.control}
                    select
                    required
                  >
                    <MenuItem value={undefined}>
                      {t("common:please_select")}
                    </MenuItem>
                    {Object.keys(FeeTypeModel).map((type) => (
                      <MenuItem
                        value={FeeTypeModel[type as keyof typeof FeeTypeModel]}
                        key={type}
                      >
                        {t(FeeTypeEnum[type as keyof typeof FeeTypeEnum])}
                      </MenuItem>
                    ))}
                  </ControlledTextField>
                </Grid>
                <Grid item sm={12}>
                  <FormControlLabel
                    componentsProps={{
                      typography: { variant: "body2" },
                    }}
                    label={String(t("fees.add_fee.taxable_label"))}
                    labelPlacement="end"
                    control={
                      <Controller
                        control={form.control}
                        name="taxable"
                        defaultValue={false}
                        render={({
                          field: { value, ...field },
                        }): ReactElement => (
                          <Checkbox
                            {...field}
                            checked={value}
                            onChange={(e): void =>
                              field.onChange(e.target.checked)
                            }
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormControlLabel
                    componentsProps={{
                      typography: { variant: "body2" },
                    }}
                    label={String(
                      t("fees.add_fee.deducted_from_advance_label"),
                    )}
                    labelPlacement="end"
                    control={
                      <Controller
                        control={form.control}
                        name="deductedFromAdvance"
                        defaultValue={false}
                        render={({
                          field: { value, ...field },
                        }): ReactElement => (
                          <Checkbox
                            {...field}
                            checked={value}
                            onChange={(e): void =>
                              field.onChange(e.target.checked)
                            }
                          />
                        )}
                      />
                    }
                  />
                </Grid>

                <Grid item sm={12}>
                  <FormControlLabel
                    componentsProps={{
                      typography: { variant: "body2" },
                    }}
                    label={String(t("fees.add_fee.collect_by_dd_label"))}
                    labelPlacement="end"
                    control={
                      <Controller
                        control={form.control}
                        name="collectByDirectDebit"
                        defaultValue={false}
                        render={({
                          field: { value, ...field },
                        }): ReactElement => (
                          <Checkbox
                            {...field}
                            checked={value}
                            onChange={(e): void =>
                              field.onChange(e.target.checked)
                            }
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormControlLabel
                    componentsProps={{
                      typography: { variant: "body2" },
                    }}
                    label={String(t("fees.add_fee.include_on_invoices_label"))}
                    labelPlacement="end"
                    control={
                      <Controller
                        control={form.control}
                        name="includeOnInvoices"
                        defaultValue={false}
                        render={({
                          field: { value, ...field },
                        }): ReactElement => (
                          <Checkbox
                            {...field}
                            checked={value}
                            onChange={(e): void =>
                              field.onChange(e.target.checked)
                            }
                          />
                        )}
                      />
                    }
                  />
                </Grid>
              </Grid>

              {addFee.error && (
                <Box>
                  <Typography color="error">
                    {t("common:error.default")}
                  </Typography>
                </Box>
              )}
            </>
          );
        }}
      </FormDialog>
    </>
  );
}
