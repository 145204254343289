import { Loader } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useListPaymentInstructions } from "api/agreements/payment-instructions/listPaymentInstructions";
import { PaymentInstructionsList } from "apps/servicing/modules/agreements/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface AgreementPaymentInstructionsProps {
  agreementId: number;
  agreementSystemId: string;
}

export default function AgreementPaymentInstructions({
  agreementId,
  agreementSystemId,
}: Readonly<AgreementPaymentInstructionsProps>): ReactElement {
  const appConfig = useAppConfiguration();
  const { t } = useTranslation(["agreements", "common"]);
  const paymentInstructions = useListPaymentInstructions(agreementSystemId);

  return (
    <ListLayout>
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(paymentInstructions.data ?? paymentInstructions.error)}
        render={() => {
          if (paymentInstructions.error || !paymentInstructions.data) {
            return (
              <Typography
                aria-label={t("common:error.default")}
                color="error"
                role="alert"
              >
                {t("common:error.default")}
              </Typography>
            );
          }

          return (
            <PaymentInstructionsList
              agreementId={agreementId}
              data={paymentInstructions.data.map((instruction) => ({
                ...instruction,
                href: `${appConfig.appRoutes.servicing}/agreements/${agreementId}/payment-instructions/${instruction.id}`,
              }))}
              loading={paymentInstructions.isValidating}
            />
          );
        }}
      />
    </ListLayout>
  );
}
