import { DateField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { useListAssignees } from "api/tasks/assignees/listAssignees";
import { useCreateTask } from "api/tasks/createTask";
import { useFindCustomerAndAgreements } from "api/tasks/findCustomersAndAgreements/findCustomerAndAgreements";
import * as keys from "api/tasks/keys";
import { usePartialMutate, useYupResolver } from "common/hooks";
import _ from "lodash";
import { useMemo, useState } from "react";
import {
  Controller,
  FieldError,
  FieldErrors,
  Resolver,
  UseFormReturn,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

const labels = {
  title: "create_task_dialog.title_label",
  description: "create_task_dialog.description_label",
  dueAt: "create_task_dialog.due_date_label",
  dueTime: "create_task_dialog.due_date_time_label",
  agreement: "create_task_dialog.agreement_label",
  customer: "create_task_dialog.customer_label",
};

const useCreateTaskResolver = (): Resolver<CreateTaskFieldValues> => {
  const { t } = useTranslation(["start"]);

  const transformField = (v: string, o: string): string | null | undefined => {
    return o === "" ? undefined : v;
  };

  return useYupResolver<CreateTaskFieldValues>((yup) =>
    yup.object().shape({
      title: yup.string().isRequired(t(labels.title)).max(250),
      description: yup.string().isRequired(t(labels.description)).max(2000),
      dueAt: yup
        .date()
        .localDate()
        .isValidDate(t(labels.dueAt))
        .isNotBeforeToday(t(labels.dueAt))
        .isRequired(t(labels.dueAt)),
      dueTime: yup
        .string()
        .transform(transformField)
        .isRequired(t(labels.dueTime))
        .test({
          name: "isNotBeforeCurrentTime",
          message: t("create_task_dialog.due_date_and_time_error"),
          test: (value: string | undefined, testContext) => {
            if (testContext.parent.dueAt === undefined) {
              return true;
            }

            const dueAt = testContext.parent.dueAt
              ?.toISOString()
              .slice(0, 11)
              .concat(value);

            return new Date(dueAt) >= new Date();
          },
        }),
      assignToId: yup.object().nullable(),
      agreement: yup
        .object()
        .nullable()
        .shape({
          id: yup.number(t(labels.agreement)),
        }),
      customer: yup.object().nullable(),
    }),
  );
};

const CustomerAutocomplete = ({
  onSelect,
  value,
  form,
}: {
  form: UseFormReturn<CreateTaskFieldValues>;
  onSelect: (value: { id: number; name: string } | null) => void;
  value: { id: number; name: string } | null;
}) => {
  const { t } = useTranslation(["start"]);
  const [query, setQuery] = useState("");

  const findCustomersAndAgreements = useFindCustomerAndAgreements({
    query,
    enabled: query.trim().length > 2,
    customerId: form.watch("customer.id") ?? "",
  });

  const customers = findCustomersAndAgreements.data?.customers ?? [];

  const handleQueryChange = useMemo(
    () => _.debounce((_event, newValue) => setQuery(newValue), 300),
    [],
  );

  return (
    <Autocomplete
      options={customers.map((customer) => ({
        id: customer.id,
        name: customer.name,
      }))}
      id="customer-field"
      onChange={(_, newValue) => {
        onSelect(newValue);
        form.setValue("agreement", null);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      value={value}
      loading={
        !findCustomersAndAgreements.data &&
        findCustomersAndAgreements.isValidating
      }
      disabled={
        form.watch("customer") === null && form.watch("agreement") !== null
      }
      noOptionsText={t("no_options_text")}
      onInputChange={handleQueryChange}
      renderInput={(params) => (
        <TextField
          {...params}
          value={query}
          label={t(labels.customer)}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={(form.formState.errors.customer as FieldError)?.message}
          error={Boolean(form.formState.errors.customer)}
          margin="none"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {findCustomersAndAgreements.isValidating && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

const AgreementAutocomplete = ({
  form,
  onSelect,
  value,
}: {
  form: UseFormReturn<CreateTaskFieldValues>;
  onSelect: (value: { id: number; name: string } | null) => void;
  value: { id: number; name: string } | null;
}) => {
  const { t } = useTranslation(["start"]);
  const [query, setQuery] = useState("");

  const findCustomersAndAgreements = useFindCustomerAndAgreements({
    customerId: form.watch("customer.id") ?? undefined,
    query,
    enabled: query.trim().length > 2,
  });

  const agreements = findCustomersAndAgreements.data?.agreements ?? [];

  const handleQueryChange = useMemo(
    () => _.debounce((_event, newValue) => setQuery(newValue), 300),
    [],
  );

  return (
    <Autocomplete
      options={agreements.map((agreement) => ({
        id: agreement.id,
        name: agreement.agreementNumber,
      }))}
      onChange={(_, newValue) => onSelect(newValue)}
      id="agreement-field"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      value={value}
      noOptionsText={t("no_options_text")}
      loading={
        !findCustomersAndAgreements.data &&
        findCustomersAndAgreements.isValidating
      }
      onInputChange={handleQueryChange}
      renderInput={(params) => (
        <TextField
          {...params}
          value={query}
          label={t(labels.agreement)}
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(
            (form.formState.errors.assigneeToId as FieldErrors)?.id?.message,
          )}
          helperText={form.formState.errors.assigneeToId?.id?.message}
          margin="none"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {findCustomersAndAgreements.isValidating && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export type CreateTaskFieldValues = {
  title: string;
  description: string;
  dueAt: Date;
  dueTime: string;
  assigneeToId: { name: string; id: string } | null;
  customer: { name: string; id: number } | null;
  agreement: { name: string; id: number } | null;
};

const CreateTaskContainer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation(["start"]);
  const partialMutate = usePartialMutate();
  const resolver = useCreateTaskResolver();
  const createTask = useCreateTask({
    onSuccess: () => {
      onClose();
      partialMutate(() => keys.list());
    },
  });
  const assignees = useListAssignees();

  const assigneeOptions = assignees?.data?.map((name) => ({
    name: [name.firstName, name.lastName].join(" "),
    id: name.id,
  }));

  const handleSubmit = (data: CreateTaskFieldValues) => {
    const dueAt = data.dueAt.toISOString().slice(0, 11).concat(data.dueTime);

    createTask.execute({
      description: data.description,
      dueAt: dueAt,
      title: data.title,
      agreementId: data.agreement?.id,
      assigneeToId: data.assigneeToId?.id,
      customerId: data.customer?.id,
    });
  };

  return (
    <Box sx={{ background: "none" }}>
      <FormDialog
        open={open}
        onClose={onClose}
        title={t("create_task_dialog.title")}
        onSubmit={handleSubmit}
        labels={{
          cancel: t("common:cancel"),
          submit: t("common:create"),
        }}
        defaultValues={{
          agreement: null,
          assigneeToId: null,
          customer: null,
          description: "",
          dueAt: undefined,
          dueTime: "09:00",
          title: "",
        }}
        disabled={createTask.isExecuting}
        resolver={resolver}
      >
        {(form) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                {...form.register("title")}
                label={t(labels.title)}
                error={Boolean(form.formState.errors.title?.message)}
                helperText={form.formState.errors.title?.message}
                id="title-field"
                margin="none"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...form.register("description")}
                label={t(labels.description)}
                error={Boolean(form.formState.errors.description?.message)}
                helperText={form.formState.errors.description?.message}
                multiline
                id="description-field"
                rows={6}
                margin="none"
                required
              />
            </Grid>
            <Grid item sm={6}>
              <DateField
                name="dueAt"
                label={t(labels.dueAt)}
                control={form.control}
                id="due-at-field"
                error={Boolean(form.formState.errors.dueAt?.message)}
                helperText={form.formState.errors.dueAt?.message}
                margin="none"
                required
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                type="time"
                margin="none"
                id="due-time-field"
                {...form.register("dueTime")}
                label={t(labels.dueTime)}
                error={Boolean(form.formState.errors.dueTime?.message)}
                helperText={form.formState.errors.dueTime?.message}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="customer"
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <CustomerAutocomplete
                    onSelect={onChange}
                    value={value}
                    form={form}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="agreement"
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <AgreementAutocomplete
                    onSelect={onChange}
                    value={value}
                    form={form}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="assigneeToId"
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={assigneeOptions ?? []}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                    }}
                    id="assignee-to-id-field"
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(option) => option.name}
                    value={value}
                    renderInput={({ inputProps, ...params }) => (
                      <TextField
                        {...params}
                        label={t("create_task_dialog.assignee_label")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          ...inputProps,
                        }}
                        error={Boolean(
                          (form.formState.errors.assigneeToId as FieldErrors)
                            ?.id?.message,
                        )}
                        helperText={
                          form.formState.errors.assigneeToId?.id?.message
                        }
                        margin="none"
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      </FormDialog>
    </Box>
  );
};

export { CreateTaskContainer };
