import { LinearProgress, TableCell, TableHead, TableRow } from "@mui/material";
import { ReactElement, ReactNode } from "react";

interface LoadableTableHeadProps {
  headings: string[];
  loading: boolean;
  checkbox?: ReactNode;
}

export default function LoadableTableHead({
  headings,
  loading,
  checkbox,
}: LoadableTableHeadProps): ReactElement {
  return (
    <TableHead>
      <>
        <TableRow>
          {checkbox && <TableCell padding="checkbox">{checkbox}</TableCell>}
          {headings.map((label) => (
            <TableCell key={label} size="small">
              {label}
            </TableCell>
          ))}
        </TableRow>
        {loading && (
          <TableRow>
            <TableCell colSpan={10} padding="none">
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}
      </>
    </TableHead>
  );
}
