/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';

/**
 * 
 * @export
 * @interface UpdateBankAccountModel
 */
export interface UpdateBankAccountModel {
    /**
     * The name of the bank
     * @type {string}
     * @memberof UpdateBankAccountModel
     */
    bankName?: string;
    /**
     * The name of the account holder
     * @type {string}
     * @memberof UpdateBankAccountModel
     */
    accountHolderName: string;
    /**
     * The bank account sort code
     * @type {string}
     * @memberof UpdateBankAccountModel
     */
    sortCode?: string;
    /**
     * The bank account number
     * @type {string}
     * @memberof UpdateBankAccountModel
     */
    accountNumber?: string;
    /**
     * The international bank account number
     * @type {string}
     * @memberof UpdateBankAccountModel
     */
    iban?: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof UpdateBankAccountModel
     */
    bic?: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof UpdateBankAccountModel
     */
    address?: AddressModel;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBankAccountModel
     */
    migrateMandates?: boolean;
}

/**
 * Check if a given object implements the UpdateBankAccountModel interface.
 */
export function instanceOfUpdateBankAccountModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accountHolderName" in value;

    return isInstance;
}

export function UpdateBankAccountModelFromJSON(json: any): UpdateBankAccountModel {
    return UpdateBankAccountModelFromJSONTyped(json, false);
}

export function UpdateBankAccountModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBankAccountModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankName': !exists(json, 'bankName') ? undefined : json['bankName'],
        'accountHolderName': json['accountHolderName'],
        'sortCode': !exists(json, 'sortCode') ? undefined : json['sortCode'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
        'address': !exists(json, 'address') ? undefined : AddressModelFromJSON(json['address']),
        'migrateMandates': !exists(json, 'migrateMandates') ? undefined : json['migrateMandates'],
    };
}

export function UpdateBankAccountModelToJSON(value?: UpdateBankAccountModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankName': value.bankName,
        'accountHolderName': value.accountHolderName,
        'sortCode': value.sortCode,
        'accountNumber': value.accountNumber,
        'iban': value.iban,
        'bic': value.bic,
        'address': AddressModelToJSON(value.address),
        'migrateMandates': value.migrateMandates,
    };
}

