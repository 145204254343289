/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing vat codes within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VatRateListItemModel } from './VatRateListItemModel';
import {
    VatRateListItemModelFromJSON,
    VatRateListItemModelFromJSONTyped,
    VatRateListItemModelToJSON,
} from './VatRateListItemModel';

/**
 * 
 * @export
 * @interface VatCodeDetailsModel
 */
export interface VatCodeDetailsModel {
    /**
     * Vat code
     * @type {string}
     * @memberof VatCodeDetailsModel
     */
    vatCode: string;
    /**
     * Description of the vat code
     * @type {string}
     * @memberof VatCodeDetailsModel
     */
    description: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof VatCodeDetailsModel
     */
    effectiveDate: Date;
    /**
     * Vat rate
     * @type {number}
     * @memberof VatCodeDetailsModel
     */
    rate: number;
    /**
     * Username of the user that created the vat rate
     * @type {string}
     * @memberof VatCodeDetailsModel
     */
    createdBy: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof VatCodeDetailsModel
     */
    createdDate: Date;
    /**
     * 
     * @type {Array<VatRateListItemModel>}
     * @memberof VatCodeDetailsModel
     */
    vatRates: Array<VatRateListItemModel>;
}

/**
 * Check if a given object implements the VatCodeDetailsModel interface.
 */
export function instanceOfVatCodeDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vatCode" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "effectiveDate" in value;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "vatRates" in value;

    return isInstance;
}

export function VatCodeDetailsModelFromJSON(json: any): VatCodeDetailsModel {
    return VatCodeDetailsModelFromJSONTyped(json, false);
}

export function VatCodeDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VatCodeDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vatCode': json['vatCode'],
        'description': json['description'],
        'effectiveDate': (new Date(json['effectiveDate'])),
        'rate': json['rate'],
        'createdBy': json['createdBy'],
        'createdDate': (new Date(json['createdDate'])),
        'vatRates': ((json['vatRates'] as Array<any>).map(VatRateListItemModelFromJSON)),
    };
}

export function VatCodeDetailsModelToJSON(value?: VatCodeDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vatCode': value.vatCode,
        'description': value.description,
        'effectiveDate': (value.effectiveDate.toISOString().substring(0,10)),
        'rate': value.rate,
        'createdBy': value.createdBy,
        'createdDate': (value.createdDate.toISOString().substring(0,10)),
        'vatRates': ((value.vatRates as Array<any>).map(VatRateListItemModelToJSON)),
    };
}

