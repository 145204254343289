/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Direct Debits within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DirectDebitSubmissionPaymentListItemModel } from './DirectDebitSubmissionPaymentListItemModel';
import {
    DirectDebitSubmissionPaymentListItemModelFromJSON,
    DirectDebitSubmissionPaymentListItemModelFromJSONTyped,
    DirectDebitSubmissionPaymentListItemModelToJSON,
} from './DirectDebitSubmissionPaymentListItemModel';

/**
 * 
 * @export
 * @interface DirectDebitSubmissionDetailsModel
 */
export interface DirectDebitSubmissionDetailsModel {
    /**
     * 
     * @type {Array<DirectDebitSubmissionPaymentListItemModel>}
     * @memberof DirectDebitSubmissionDetailsModel
     */
    payments: Array<DirectDebitSubmissionPaymentListItemModel>;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof DirectDebitSubmissionDetailsModel
     */
    totalAmount: string;
}

/**
 * Check if a given object implements the DirectDebitSubmissionDetailsModel interface.
 */
export function instanceOfDirectDebitSubmissionDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payments" in value;
    isInstance = isInstance && "totalAmount" in value;

    return isInstance;
}

export function DirectDebitSubmissionDetailsModelFromJSON(json: any): DirectDebitSubmissionDetailsModel {
    return DirectDebitSubmissionDetailsModelFromJSONTyped(json, false);
}

export function DirectDebitSubmissionDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectDebitSubmissionDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payments': ((json['payments'] as Array<any>).map(DirectDebitSubmissionPaymentListItemModelFromJSON)),
        'totalAmount': json['totalAmount'],
    };
}

export function DirectDebitSubmissionDetailsModelToJSON(value?: DirectDebitSubmissionDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payments': ((value.payments as Array<any>).map(DirectDebitSubmissionPaymentListItemModelToJSON)),
        'totalAmount': value.totalAmount,
    };
}

