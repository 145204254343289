/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssetListItemModel
 */
export interface AssetListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AssetListItemModel
     */
    readonly id: number;
    /**
     * A read only Id
     * @type {number}
     * @memberof AssetListItemModel
     */
    readonly agreementId: number;
    /**
     * The agreement number
     * @type {string}
     * @memberof AssetListItemModel
     */
    agreementNumber: string;
    /**
     * The name of the customer that owns the asset
     * @type {string}
     * @memberof AssetListItemModel
     */
    customerName?: string;
    /**
     * The name of the company that owns the client
     * @type {string}
     * @memberof AssetListItemModel
     */
    companyName?: string;
    /**
     * The asset serial number
     * @type {string}
     * @memberof AssetListItemModel
     */
    serialNumber?: string;
    /**
     * The asset registration number
     * @type {string}
     * @memberof AssetListItemModel
     */
    registrationNumber?: string;
    /**
     * The asset vehicle identification number
     * @type {string}
     * @memberof AssetListItemModel
     */
    vehicleIdentificationNumber?: string;
}

/**
 * Check if a given object implements the AssetListItemModel interface.
 */
export function instanceOfAssetListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "agreementId" in value;
    isInstance = isInstance && "agreementNumber" in value;

    return isInstance;
}

export function AssetListItemModelFromJSON(json: any): AssetListItemModel {
    return AssetListItemModelFromJSONTyped(json, false);
}

export function AssetListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'agreementId': json['agreementId'],
        'agreementNumber': json['agreementNumber'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'vehicleIdentificationNumber': !exists(json, 'vehicleIdentificationNumber') ? undefined : json['vehicleIdentificationNumber'],
    };
}

export function AssetListItemModelToJSON(value?: AssetListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreementNumber': value.agreementNumber,
        'customerName': value.customerName,
        'companyName': value.companyName,
        'serialNumber': value.serialNumber,
        'registrationNumber': value.registrationNumber,
        'vehicleIdentificationNumber': value.vehicleIdentificationNumber,
    };
}

