/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressModel
 */
export interface AddressModel {
    /**
     * first address line
     * @type {string}
     * @memberof AddressModel
     */
    addressLine1: string;
    /**
     * second address line
     * @type {string}
     * @memberof AddressModel
     */
    addressLine2: string;
    /**
     * third address line
     * @type {string}
     * @memberof AddressModel
     */
    addressLine3?: string;
    /**
     * fourth address line
     * @type {string}
     * @memberof AddressModel
     */
    addressLine4?: string;
    /**
     * Postal Code
     * @type {string}
     * @memberof AddressModel
     */
    postcode: string;
}

/**
 * Check if a given object implements the AddressModel interface.
 */
export function instanceOfAddressModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "addressLine1" in value;
    isInstance = isInstance && "addressLine2" in value;
    isInstance = isInstance && "postcode" in value;

    return isInstance;
}

export function AddressModelFromJSON(json: any): AddressModel {
    return AddressModelFromJSONTyped(json, false);
}

export function AddressModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressLine1': json['addressLine1'],
        'addressLine2': json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': json['postcode'],
    };
}

export function AddressModelToJSON(value?: AddressModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
    };
}

