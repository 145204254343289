/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing financial products within onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Known fee types driving their behaviour
 * @export
 */
export const FeeTypeModel = {
    FrontEnd: 'Front End',
    BackEnd: 'Back End',
    Annual: 'Annual'
} as const;
export type FeeTypeModel = typeof FeeTypeModel[keyof typeof FeeTypeModel];


export function FeeTypeModelFromJSON(json: any): FeeTypeModel {
    return FeeTypeModelFromJSONTyped(json, false);
}

export function FeeTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeeTypeModel {
    return json as FeeTypeModel;
}

export function FeeTypeModelToJSON(value?: FeeTypeModel | null): any {
    return value as any;
}

