import { Loader } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useListAgreementAssets } from "api/agreements/assets";
import AssetList from "apps/servicing/components/AssetList";
import AssetListLayout from "apps/servicing/layouts/AssetListLayout";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface AgreementAssetProps {
  agreementId: number;
}

interface AgreementAssetsParameters {
  live?: boolean;
}

export default function AgreementAssets({
  agreementId,
}: AgreementAssetProps): ReactElement {
  const { t, ready } = useTranslation(["agreements", "assets"]);

  const [parameters, setParameters] = useState<AgreementAssetsParameters>({
    live: undefined,
  });

  const agreementAssets = useListAgreementAssets(agreementId, parameters);

  return (
    <AssetListLayout onParameterChange={setParameters} parameters={parameters}>
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(agreementAssets.data ?? agreementAssets.error) && ready}
        render={() => {
          if (!agreementAssets.data || agreementAssets.error) {
            return (
              <Typography color="error" data-testid="agreementAssets.error">
                {t("common:error.default")}
              </Typography>
            );
          }

          return (
            <AssetList
              data={[
                {
                  agreementNumber: "",
                  assets: [...agreementAssets.data]
                    .sort((a, b) =>
                      (a.assetNumber || 0) > (b.assetNumber || 0) ? 1 : -1,
                    )
                    .map((asset) => ({
                      ...asset,
                      assetUrl: `?selectedAsset=${asset.id}`,
                    })),
                },
              ]}
              label={t("assets.list_label")}
              loading={agreementAssets.isValidating}
            />
          );
        }}
      />
    </AssetListLayout>
  );
}
