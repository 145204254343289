import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { DescriptionList } from "@ldms/mui-sdk/components";
import {
  AlertDialog,
  ConfirmationDialog,
  Loader,
} from "@ldms/mui-sdk/templates";
import { Button, Stack } from "@mui/material";
import { useGetBankAccountDetails } from "api/customers/bank-accounts/getBankAccountDetails";
import { useListMandates } from "api/customers/bank-accounts/listMandates";
import { MandatesList } from "apps/servicing/modules/customers/components";
import { QueryError } from "common/components";
import Surface from "common/components/Surface";
import { useConfirm, useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { ClientCommandApi } from "generated/core/apis";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";

interface BankAccountDetailsProps {
  legacyBankAccountId: number;
  bankAccountId: string;
  clientId: number;
  onCancelMandates(): void;
}

export default function BankAccountDetails({
  legacyBankAccountId,
  bankAccountId,
  clientId,
  onCancelMandates: onCancelMandatesCallBack,
}: Readonly<BankAccountDetailsProps>) {
  const { t } = useTranslation(["clients", "common"]);
  const bankAccount = useGetBankAccountDetails(bankAccountId);
  const mandates = useListMandates(clientId, legacyBankAccountId);
  const commandApi = useApi(ClientCommandApi);
  const confirm = useConfirm();
  const error = useResponseError();

  const onCloseErrorDialog = (): void => error.reset();

  const onCancelMandates = async (): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      try {
        await commandApi.cancelMandates({
          clientId,
          bankAccountId: legacyBankAccountId,
        });
        onCancelMandatesCallBack();
        await mandates.refetch();
      } catch (errorResponse) {
        error.setError((await errorHandler(errorResponse)).code);
      }
    });
  };

  const renderBankAccountDetails = () => {
    if (bankAccount.error || !bankAccount.data) {
      return <QueryError onRetry={bankAccount.refetch} />;
    }
    if (mandates.error || !mandates.data) {
      return <QueryError onRetry={mandates.refetch} />;
    }

    return (
      <Stack spacing={2}>
        <Surface title={t("bank_account_details.banks_account_details_label")}>
          <DescriptionList
            label={t("bank_account_details.banks_account_details_label")}
          >
            <DescriptionList.Item label={t("bank_account_details.type_label")}>
              {bankAccount.data.isCustomer ? "Main" : bankAccount.data.type}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("bank_account_details.bank_name_label")}
            >
              {bankAccount.data.bankName}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("bank_account_details.address_label")}
            >
              {[
                bankAccount.data.addressLine1,
                bankAccount.data.addressLine2,
                bankAccount.data.addressLine3,
                bankAccount.data.addressLine4,
                bankAccount.data.postcode,
              ]
                .filter(Boolean)
                .join(" ")}
            </DescriptionList.Item>
            <DescriptionList.Item label={t("bank_account_details.iban_label")}>
              {bankAccount.data.iban}
            </DescriptionList.Item>
            <DescriptionList.Item label={t("bank_account_details.bic_label")}>
              {bankAccount.data.bic}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("bank_account_details.account_holders_name_label")}
            >
              {bankAccount.data.accountHoldersName}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("bank_account_details.account_number_label")}
            >
              {bankAccount.data.accountNumber}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("bank_account_details.sort_code_label")}
            >
              {bankAccount.data.sortCode}
            </DescriptionList.Item>
          </DescriptionList>
        </Surface>

        <Surface
          title={t("mandates.mandates_title")}
          action={
            <AccessControl
              allowedPermissions={["servicing:bank-accounts:manage"]}
            >
              <Button
                disabled={mandates.data.length === 0}
                onClick={onCancelMandates}
              >
                {t("mandates.cancel_mandates_button")}
              </Button>
            </AccessControl>
          }
          disableGutters
        >
          <MandatesList data={mandates.data} loading={mandates.isValidating} />
        </Surface>
      </Stack>
    );
  };

  return (
    <>
      <Loader
        ready={
          Boolean(bankAccount.data ?? bankAccount.error) &&
          Boolean(mandates.data ?? mandates.error)
        }
        render={renderBankAccountDetails}
      />

      <ConfirmationDialog
        content={t("mandates.cancel_confirmation.message")}
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("mandates.cancel_confirmation.title_label")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        content={error.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(error.message)}
        title={t("mandates.error_title")}
      />
    </>
  );
}
