import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { keys } from "api/agreements/attachments";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementAttachmentCommandApi } from "generated/core/apis";
import {
  CreatedDocumentModel,
  CreateDocumentModel,
} from "generated/core/models";

export const useCreateDocument = (
  agreementId: number,
  options: UseCommandOptions<CreatedDocumentModel, ResponseError> = {},
) => {
  const api = useApi(AgreementAttachmentCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand<CreateDocumentModel, CreatedDocumentModel, ResponseError>(
    (model) =>
      api.createDocument({
        agreementId,
        createDocumentModel: model,
      }),
    {
      ...options,
      onSuccess: (result) => {
        partialMutate(keys.list(agreementId));
        options.onSuccess?.(result);
      },
    },
  );
};
