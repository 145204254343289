import AgreementInsuranceContainer from "apps/servicing/modules/agreements/containers/AgreementInsuranceContainer";
import { withModifiableAccess } from "apps/servicing/modules/agreements/hocs/withModifiableAccess";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";

function AgreementInsuranceView() {
  const agreement = useAgreement();
  const { t } = useTranslation("agreements");

  return (
    <ViewLayout title={t("insurance.heading")}>
      <ContainedLayout>
        <AgreementInsuranceContainer agreementId={agreement.id} />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default withModifiableAccess(AgreementInsuranceView);
