import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { PaymentHolidayDetailsModel } from "generated/core/models";
import { ErrorLike } from "support/error-handler";
import * as keys from "./keys";

type UseGetPaymentHolidayDetails = (
  agreementId: number,
  params: {
    instalmentNumber: number;
    numberOfInstalments: number;
    isFullPaymentHoliday: boolean;
  },
) => UseQueryReturn<PaymentHolidayDetailsModel, ErrorLike>;

export const useGetPaymentHolidayDetails: UseGetPaymentHolidayDetails = (
  agreementId: number,
  params: {
    instalmentNumber: number;
    numberOfInstalments: number;
    isFullPaymentHoliday: boolean;
  },
) => {
  const api = useApi(AgreementQueryApi);

  return useQuery(keys.detail(agreementId, params), () =>
    api.getPaymentHolidayDetails({
      agreementId: agreementId,
      instalmentNumber: params.instalmentNumber,
      numberOfInstalments: params.numberOfInstalments,
      isFullPaymentHoliday: params.isFullPaymentHoliday,
    }),
  );
};
