import { useSession, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import { AlertDialog } from "@ldms/mui-sdk/templates";
import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import FinancialPostingsContainer from "apps/finance/containers/FinancialPostingsContainer";
import { useResponseError } from "common/hooks";
import ContainedLayout from "common/layouts/ContainedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useApi } from "common/providers";
import { FinancialPostingQueryApi } from "generated/core/apis";
import { compose } from "lodash/fp";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";
import errorHandler from "support/error-handler";
import fileDownloader from "support/file-downloader";

export interface FinancialPostingsParameters {
  agreementId?: number;
  portfolioId?: number;
  accountCode?: string;
  sourceCode?: string;
  stageCode?: string;
  from?: Date;
  to?: Date;
}

const handleUndefinedParameters = (parameter: string | null) => {
  if (parameter === "All") {
    return undefined;
  }

  if (parameter) {
    return Number(parameter);
  }

  return undefined;
};

function FinancialPostingsView(): ReactElement {
  const { t } = useTranslation("finance");

  const [exportProgress, setExportProgress] = useState(false);
  const financialPostingApi = useApi(FinancialPostingQueryApi);
  const error = useResponseError();
  const onCloseErrorDialog = (): void => error.reset();
  const session = useSession();

  const [search] = useSearchParams({
    agreementId: "",
    portfolio: "",
    accountName: "",
    stageGroup: "",
    stageCode: "",
    from: "",
    to: "",
  });

  const financialPostingParams: FinancialPostingsParameters = {
    agreementId: handleUndefinedParameters(search.get("agreementId")),
    portfolioId: handleUndefinedParameters(search.get("portfolioId")),
    accountCode:
      String(search.get("accountName")) === "All"
        ? undefined
        : String(search.get("accountName")),
    sourceCode:
      String(search.get("stageGroup")) === "All"
        ? undefined
        : String(search.get("stageGroup")),
    stageCode:
      String(search.get("stageCode")) === "All"
        ? undefined
        : String(search.get("stageCode")),
    from: new Date(String(search.get("from"))),
    to: new Date(String(search.get("to"))),
  };

  const makeFinancialPostingsHandler = async (): Promise<void> => {
    try {
      error.reset();
      setExportProgress(true);
      const response = await financialPostingApi.exportFinancialPostingsRaw({
        agreementId: financialPostingParams.agreementId,
        portfolioId: financialPostingParams.portfolioId,
        accountCode: financialPostingParams.accountCode,
        sourceCode: financialPostingParams.sourceCode,
        stageCode: financialPostingParams.stageCode,
        from: financialPostingParams.from,
        to: financialPostingParams.to,
      });
      await fileDownloader(response);
      setExportProgress(false);
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
      setExportProgress(false);
    }
  };

  return (
    <>
      <ViewLayout
        title={t("financial_postings.heading")}
        breadcrumbs={[
          {
            href: `../filter-financial-postings`,
            label: t("financial_postings.breadcrumb_link_label"),
          },
        ]}
        action={
          <>
            {session.hasPermission("finance:asset-financial-postings:add") && (
              <AddButton
                component={RouterLink}
                to="../financial-postings/add-financial-posting"
                role="link"
                variant="contained"
              />
            )}
            {session.hasPermission(
              "finance:asset-financial-postings:export",
            ) && (
              <Button
                onClick={makeFinancialPostingsHandler}
                startIcon={<Download fontSize="inherit" />}
                size="small"
                disabled={exportProgress}
              >
                {t("financial_postings.export_button")}
              </Button>
            )}
          </>
        }
      >
        <ContainedLayout>
          <FinancialPostingsContainer
            financialPostingsParameters={financialPostingParams}
          />
        </ContainedLayout>
      </ViewLayout>

      <AlertDialog
        content={error.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(error.message)}
        title={t("financial_postings.error_title")}
      />
    </>
  );
}

export default compose(
  withTranslationLoader("finance"),
  withProtectedView({
    allowedPermissions: ["finance:asset-financial-postings:view"],
  }),
)(FinancialPostingsView);
