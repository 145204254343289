import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface YesNoValueProps {
  value?: boolean;
}

export default function YesNoValue({ value }: YesNoValueProps): ReactElement {
  const { t } = useTranslation("common");
  return <>{value ? t("common:yes") : t("common:no")}</>;
}
