export const config = {
  appRoutes: {
    admin: "/admin",
    auth: "/auth",
    loginPath: "/oauth2/authorization/engage",
    users: "/users",
    documents: "/documents",
    onboarding: "/onboarding",
    finance: "/finance",
    reporting: "/reporting",
    servicing: "/servicing",
    thirdParties: "/third-parties",
    tasks: "/tasks",
  },
} as const;
