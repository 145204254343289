import { useFormat } from "@ldms/mui-sdk/formatting";
import { Loader } from "@ldms/mui-sdk/templates";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useListChargeTypeHistory } from "api/charge-types/history";
import { LoadableTableHead, NoResults, QueryError } from "common/components";
import Surface from "common/components/Surface";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

interface ListChargeTypeHistoryContainerProps {
  chargeId: string;
}

export default function ListChargeTypeHistoryContainer({
  chargeId,
}: Readonly<ListChargeTypeHistoryContainerProps>): ReactElement {
  const { t } = useTranslation("servicing");
  const chargeTypeHistory = useListChargeTypeHistory(chargeId);
  const locale = useLocale();
  const { formatAmount } = useFormat();

  return (
    <Loader
      ready={Boolean(chargeTypeHistory.data ?? chargeTypeHistory.error)}
      render={(): ReactElement => {
        if (chargeTypeHistory.error || !chargeTypeHistory.data) {
          return <QueryError onRetry={chargeTypeHistory.refetch} />;
        }

        return (
          <Surface
            disableGutters
            title={t("charge_details.details.table_name_label")}
          >
            <Table
              aria-label={t("charge_details.details.table_name_label")}
              size="small"
            >
              <LoadableTableHead
                headings={[
                  t("charge_details.details.details_history_date_label"),
                  t("charge_details.details.details_history_amount_label"),
                ]}
                loading={chargeTypeHistory.isValidating}
              />
              <TableBody>
                {chargeTypeHistory.data?.map((charge, index) => (
                  <TableRow key={uuidv4()} hover>
                    <TableCell
                      sx={{
                        width: "30%",
                      }}
                    >
                      {`${locale.formatDate(
                        charge.modifiedDate,
                      )} - ${charge.modifiedBy.toUpperCase()}`}
                    </TableCell>
                    <TableCell>{formatAmount(charge.amount)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {chargeTypeHistory.data?.length === 0 && <NoResults />}
          </Surface>
        );
      }}
    />
  );
}
