import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import AddAgreementFinancialPostingContainer from "apps/servicing/modules/agreements/containers/AddAgreementFinancialPostingContainer";
import { withModifiableAccess } from "apps/servicing/modules/agreements/hocs/withModifiableAccess";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { compose } from "lodash/fp";
import { useTranslation } from "react-i18next";

function AddAgreementFinancialPostingView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const agreementId = agreement.id;
  const appConfig = useAppConfiguration();

  return (
    <ViewLayout
      title={t("financial_postings.add.heading")}
      breadcrumbs={[
        {
          href: `${appConfig.appRoutes.servicing}/agreements/${agreementId}/financial-postings`,
          label: t("financial_postings.breadcrumb_link_label"),
        },
      ]}
    >
      <ContainedLayout>
        <AddAgreementFinancialPostingContainer agreementId={agreementId} />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default compose(
  withProtectedView({
    allowedPermissions: ["servicing:financial-postings:elevated"],
  }),
  withModifiableAccess,
)(AddAgreementFinancialPostingView);
