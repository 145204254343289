import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import DirectDebitSubmissionsList from "apps/finance/components/DirectDebitSubmissionsList";
import { Loader } from "common/components";
import { useConfirm, useResponseError } from "common/hooks";
import ListLayout from "common/layouts/ListLayout";
import { useApi } from "common/providers";
import {
  DirectDebitCommandApi,
  DirectDebitQueryApi,
} from "generated/core/apis";
import { DirectDebitSubmissionListItemModel } from "generated/core/models/DirectDebitSubmissionListItemModel";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import errorHandler, { ErrorLike } from "support/error-handler";
import fileDownloader from "support/file-downloader";
import useStickySWR from "support/use-sticky-swr";

export default function DirectDebit(): ReactElement {
  const directDebitQueryApi = useApi(DirectDebitQueryApi);
  const { t } = useTranslation("finance");

  const confirm = useConfirm();
  const error = useResponseError();
  const directDebitCommandApi = useApi(DirectDebitCommandApi);

  const directDebitSubmissions = useStickySWR<
    DirectDebitSubmissionListItemModel[],
    ErrorLike
  >(["/finance/direct-debits"], () =>
    directDebitQueryApi.listDirectDebitSubmissions(),
  );
  const [submissionStatus, setSubmissionStatus] = useState("");

  const onCloseErrorDialog = (): void => error.reset();

  const onGenerateFile = (submissionId: number, status: string): void => {
    setSubmissionStatus(status);
    confirm.handlePrompt(async (): Promise<void> => {
      try {
        await directDebitCommandApi.generateSubmissionFile({
          submissionId,
        });
        directDebitSubmissions.mutate();
      } catch (errorResponse) {
        error.setError((await errorHandler(errorResponse)).code);
      }
    });
  };

  const onApplyPayments = (submissionId: number, status: string): void => {
    setSubmissionStatus(status);
    confirm.handlePrompt(async (): Promise<void> => {
      try {
        await directDebitCommandApi.applyPayments({
          submissionId,
        });
        directDebitSubmissions.mutate();
      } catch (errorResponse) {
        error.setError((await errorHandler(errorResponse)).code);
      }
    });
  };

  const onDownloadSubmissionFile = async (
    submissionId: number,
  ): Promise<void> => {
    const response = await directDebitQueryApi.downloadSubmissionFileRaw({
      submissionId,
    });
    fileDownloader(response);
  };

  if (directDebitSubmissions.error) {
    return (
      <Typography color="error" data-testid="directDebitSubmissions.error">
        {t("common:error.default")}
      </Typography>
    );
  }

  return (
    <>
      <AlertDialog
        content={error.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(error.message)}
        title={t("direct_debit_submissions.error_title")}
      />

      <ConfirmationDialog
        content={
          submissionStatus === "PEND"
            ? t("direct_debit_submissions.generate_file_confirmation_message")
            : t("direct_debit_submissions.apply_payments_confirmation_message")
        }
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={
          submissionStatus === "PEND"
            ? t("direct_debit_submissions.generate_file_confirmation_title")
            : t("direct_debit_submissions.apply_payments_confirmation_title")
        }
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <Box>
        <Loader
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
          ready={Boolean(directDebitSubmissions.data)}
          render={(): ReactElement => (
            <ListLayout>
              <DirectDebitSubmissionsList
                data={directDebitSubmissions.data}
                loading={directDebitSubmissions.isValidating}
                onGenerateFile={onGenerateFile}
                onApplyPayments={onApplyPayments}
                onDownloadSubmissionFile={onDownloadSubmissionFile}
              />
            </ListLayout>
          )}
        />
      </Box>
    </>
  );
}
