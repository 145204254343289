/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing termination within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UpdateTerminationRecoveryDetailsModel,
} from '../models/index';
import {
    UpdateTerminationRecoveryDetailsModelFromJSON,
    UpdateTerminationRecoveryDetailsModelToJSON,
} from '../models/index';

export interface UpdateTerminationRecoveryDetailsRequest {
    terminationId: number;
    updateTerminationRecoveryDetailsModel?: UpdateTerminationRecoveryDetailsModel;
}

/**
 * 
 */
export class TerminationCommandApi extends runtime.BaseAPI {

    /**
     * Update termination recovery details
     */
    async updateTerminationRecoveryDetailsRaw(requestParameters: UpdateTerminationRecoveryDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.terminationId === null || requestParameters.terminationId === undefined) {
            throw new runtime.RequiredError('terminationId','Required parameter requestParameters.terminationId was null or undefined when calling updateTerminationRecoveryDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["terminations:manage"]);
        }

        const response = await this.request({
            path: `/terminations/{terminationId}`.replace(`{${"terminationId"}}`, encodeURIComponent(String(requestParameters.terminationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTerminationRecoveryDetailsModelToJSON(requestParameters.updateTerminationRecoveryDetailsModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update termination recovery details
     */
    async updateTerminationRecoveryDetails(requestParameters: UpdateTerminationRecoveryDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTerminationRecoveryDetailsRaw(requestParameters, initOverrides);
    }

}
