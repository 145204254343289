import { useQuery } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { AssigneeApi } from "generated/tasks/apis";
import * as keys from "./keys";

const useListAssignees = () => {
  const api = useApi(AssigneeApi, "task-management");

  return useQuery(keys.list(), () => api.listAssignees());
};

export { useListAssignees };
