import { usePartialMutate, useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { CompaniesApi } from "generated/servicing-v2/apis";
import { CreateCompanyModel } from "generated/servicing-v2/models";
import { useState } from "react";
import errorHandler from "support/error-handler";
import { keys } from ".";

interface AddCompanySubmitReturn {
  command: (company: CreateCompanyModel) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
}

export const useAddCompany = (
  options: { onSuccess?: () => void } = {},
): AddCompanySubmitReturn => {
  const api = useApi(CompaniesApi, "v2");
  const error = useResponseError();
  const [isLoading, setIsLoading] = useState(false);
  const partialMutate = usePartialMutate();

  const command = async (company: CreateCompanyModel): Promise<void> => {
    try {
      setIsLoading(true);
      await api.createCompany({
        createCompanyModel: company,
      });
      options.onSuccess?.();
      partialMutate(keys.list());
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
  };
};
