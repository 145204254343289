import { QueryKey } from "api";

export const all = (): QueryKey => ["third-parties"];

export const lists = (): QueryKey => [...all(), "list"];

export const list = (params?: { query?: string; type?: string }): QueryKey => [
  ...all(),
  "list",
  params?.query,
  params?.type,
];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (): QueryKey => [...details()];
