/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The details of the asset settlement quote associated with the asset
 * @export
 * @interface AssetSettlementQuoteSummaryModel
 */
export interface AssetSettlementQuoteSummaryModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetSettlementQuoteSummaryModel
     */
    expiryDate?: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetSettlementQuoteSummaryModel
     */
    outstandingArrears: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetSettlementQuoteSummaryModel
     */
    outstandingCapital: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetSettlementQuoteSummaryModel
     */
    outstandingInterest: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetSettlementQuoteSummaryModel
     */
    vat: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetSettlementQuoteSummaryModel
     */
    newPeriodicRentalAmount: string;
    /**
     * Whether the date of settlement falls within a dd window
     * @type {boolean}
     * @memberof AssetSettlementQuoteSummaryModel
     */
    inDdWindow: boolean;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetSettlementQuoteSummaryModel
     */
    totalAmountDue: string;
    /**
     * Denotes whether the agreement has a subsidy payment
     * @type {boolean}
     * @memberof AssetSettlementQuoteSummaryModel
     */
    hasSubsidy: boolean;
}

/**
 * Check if a given object implements the AssetSettlementQuoteSummaryModel interface.
 */
export function instanceOfAssetSettlementQuoteSummaryModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "outstandingArrears" in value;
    isInstance = isInstance && "outstandingCapital" in value;
    isInstance = isInstance && "outstandingInterest" in value;
    isInstance = isInstance && "vat" in value;
    isInstance = isInstance && "newPeriodicRentalAmount" in value;
    isInstance = isInstance && "inDdWindow" in value;
    isInstance = isInstance && "totalAmountDue" in value;
    isInstance = isInstance && "hasSubsidy" in value;

    return isInstance;
}

export function AssetSettlementQuoteSummaryModelFromJSON(json: any): AssetSettlementQuoteSummaryModel {
    return AssetSettlementQuoteSummaryModelFromJSONTyped(json, false);
}

export function AssetSettlementQuoteSummaryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetSettlementQuoteSummaryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiryDate': !exists(json, 'expiryDate') ? undefined : (new Date(json['expiryDate'])),
        'outstandingArrears': json['outstandingArrears'],
        'outstandingCapital': json['outstandingCapital'],
        'outstandingInterest': json['outstandingInterest'],
        'vat': json['vat'],
        'newPeriodicRentalAmount': json['newPeriodicRentalAmount'],
        'inDdWindow': json['inDdWindow'],
        'totalAmountDue': json['totalAmountDue'],
        'hasSubsidy': json['hasSubsidy'],
    };
}

export function AssetSettlementQuoteSummaryModelToJSON(value?: AssetSettlementQuoteSummaryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expiryDate': value.expiryDate === undefined ? undefined : (value.expiryDate.toISOString().substring(0,10)),
        'outstandingArrears': value.outstandingArrears,
        'outstandingCapital': value.outstandingCapital,
        'outstandingInterest': value.outstandingInterest,
        'vat': value.vat,
        'newPeriodicRentalAmount': value.newPeriodicRentalAmount,
        'inDdWindow': value.inDdWindow,
        'totalAmountDue': value.totalAmountDue,
        'hasSubsidy': value.hasSubsidy,
    };
}

