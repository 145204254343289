/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EventTypeModel = {
    PaymentDue: 'Payment Due'
} as const;
export type EventTypeModel = typeof EventTypeModel[keyof typeof EventTypeModel];


export function EventTypeModelFromJSON(json: any): EventTypeModel {
    return EventTypeModelFromJSONTyped(json, false);
}

export function EventTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTypeModel {
    return json as EventTypeModel;
}

export function EventTypeModelToJSON(value?: EventTypeModel | null): any {
    return value as any;
}

