import { Box, Container, ContainerProps } from "@mui/material";
import { ReactElement, ReactNode } from "react";

interface ContainedLayoutProps {
  children: ReactNode;
  maxWidth?: ContainerProps["maxWidth"];
}

export default function ContainedLayout({
  children,
  maxWidth = false,
}: ContainedLayoutProps): ReactElement {
  return (
    <Box paddingY={3} height="100%">
      <Container maxWidth={maxWidth} sx={{ height: "100%" }}>
        {children}
      </Container>
    </Box>
  );
}
