/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AutomationParameterModel } from './AutomationParameterModel';
import {
    AutomationParameterModelFromJSON,
    AutomationParameterModelFromJSONTyped,
    AutomationParameterModelToJSON,
} from './AutomationParameterModel';

/**
 * 
 * @export
 * @interface UpdateAutomationModel
 */
export interface UpdateAutomationModel {
    /**
     * The Id of the portfolio
     * @type {number}
     * @memberof UpdateAutomationModel
     */
    portfolioId: number;
    /**
     * The user-defined name of the automation
     * @type {string}
     * @memberof UpdateAutomationModel
     */
    name: string;
    /**
     * The user-defined description of the automation
     * @type {string}
     * @memberof UpdateAutomationModel
     */
    description?: string;
    /**
     * 
     * @type {Array<AutomationParameterModel>}
     * @memberof UpdateAutomationModel
     */
    parameters: Array<AutomationParameterModel>;
}

/**
 * Check if a given object implements the UpdateAutomationModel interface.
 */
export function instanceOfUpdateAutomationModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "portfolioId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "parameters" in value;

    return isInstance;
}

export function UpdateAutomationModelFromJSON(json: any): UpdateAutomationModel {
    return UpdateAutomationModelFromJSONTyped(json, false);
}

export function UpdateAutomationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAutomationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'portfolioId': json['portfolioId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parameters': ((json['parameters'] as Array<any>).map(AutomationParameterModelFromJSON)),
    };
}

export function UpdateAutomationModelToJSON(value?: UpdateAutomationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portfolioId': value.portfolioId,
        'name': value.name,
        'description': value.description,
        'parameters': ((value.parameters as Array<any>).map(AutomationParameterModelToJSON)),
    };
}

