/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The fee unpaid
 * @export
 * @interface FeeUnpaidListItemModel
 */
export interface FeeUnpaidListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof FeeUnpaidListItemModel
     */
    readonly transactionId: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof FeeUnpaidListItemModel
     */
    dueDate: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeeUnpaidListItemModel
     */
    amount: string;
    /**
     * The description of the fee to be marked as paid
     * @type {string}
     * @memberof FeeUnpaidListItemModel
     */
    description: string;
}

/**
 * Check if a given object implements the FeeUnpaidListItemModel interface.
 */
export function instanceOfFeeUnpaidListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transactionId" in value;
    isInstance = isInstance && "dueDate" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function FeeUnpaidListItemModelFromJSON(json: any): FeeUnpaidListItemModel {
    return FeeUnpaidListItemModelFromJSONTyped(json, false);
}

export function FeeUnpaidListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeeUnpaidListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'dueDate': (new Date(json['dueDate'])),
        'amount': json['amount'],
        'description': json['description'],
    };
}

export function FeeUnpaidListItemModelToJSON(value?: FeeUnpaidListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dueDate': (value.dueDate.toISOString().substring(0,10)),
        'amount': value.amount,
        'description': value.description,
    };
}

