import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Box, Typography } from "@mui/material";
import { useAgreement } from "common/providers";
import { AssetModel } from "generated/core/models";
import React from "react";
import { useTranslation } from "react-i18next";

type CurrentPartialSettlementQuoteDetailsProps = {
  asset: AssetModel;
};

export default function CurrentPartialSettlementQuoteDetails({
  asset,
}: CurrentPartialSettlementQuoteDetailsProps): React.ReactElement {
  const { t } = useTranslation("assets");
  const { formatAmount } = useFormat();
  const agreement = useAgreement();

  return (
    <Box>
      <Typography variant="h5" marginY={1}>
        {t("partial_settlement_quotes.summary.current_details_label")}
      </Typography>
      <DescriptionList
        label={t("partial_settlement_quotes.summary.current_details_label")}
      >
        <DescriptionList.Item
          label={t(
            "partial_settlement_quotes.summary.agreement_instalment_label",
          )}
        >
          {formatAmount(Number(agreement?.data?.paymentAmount))}
        </DescriptionList.Item>
        <DescriptionList.Item
          label={t("partial_settlement_quotes.summary.asset_instalment_label")}
        >
          {formatAmount(Number(asset?.instalmentAmount ?? 0))}
        </DescriptionList.Item>
        <DescriptionList.Item
          label={t("partial_settlement_quotes.summary.asset_balloon_label")}
        >
          {formatAmount(Number(asset?.balloonAmount ?? 0))}
        </DescriptionList.Item>
      </DescriptionList>
    </Box>
  );
}
