import {
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useListFinancialProducts } from "api/onboarding/financial-products/listFinancialProducts";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
  YesNoValue,
} from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export default function ListFinancialProductsContainer(): ReactElement {
  const { t } = useTranslation("onboardings");
  const financialProducts = useListFinancialProducts();
  const appConfig = useAppConfiguration();

  return (
    <Loader
      ready={Boolean(financialProducts.data ?? financialProducts.error)}
      render={(): ReactElement => {
        if (financialProducts.error || !financialProducts.data) {
          return <QueryError onRetry={financialProducts.refetch} />;
        }

        return (
          <ListLayout>
            <Table
              aria-label={t("financial_products.financial_products_heading")}
              size="small"
              stickyHeader
            >
              <LoadableTableHead
                headings={[
                  t("financial_products.list_financial_products.name_label"),
                  t("financial_products.list_financial_products.type_label"),
                  t(
                    "financial_products.list_financial_products.allow_balloon_payment_label",
                  ),
                  t(
                    "financial_products.list_financial_products.balloon_as_last_instalment_label",
                  ),
                  t(
                    "financial_products.list_financial_products.allow_advanced_payment",
                  ),
                  t(
                    "financial_products.list_financial_products.interest_accrual_frequency_label",
                  ),
                  t(
                    "financial_products.list_financial_products.interest_compounding_frequency_label",
                  ),
                ]}
                loading={financialProducts.isValidating}
              />
              <TableBody>
                {financialProducts.data.map((financialProduct) => (
                  <TableRow key={financialProduct.id} hover>
                    <TableCell
                      padding="none"
                      sx={{ width: "300px" }}
                      component="th"
                      scope="row"
                    >
                      <ListItemButton
                        dense
                        component={RouterLink}
                        to={`${appConfig.appRoutes.onboarding}/settings/financial-products/${financialProduct.id}`}
                      >
                        <ListItemText
                          primaryTypographyProps={{ color: "primary" }}
                          primary={financialProduct.name}
                        />
                      </ListItemButton>
                    </TableCell>
                    <TableCell>{financialProduct.type}</TableCell>
                    <TableCell>
                      <YesNoValue
                        value={financialProduct.allowBalloonPayment}
                      />
                    </TableCell>
                    <TableCell>
                      <YesNoValue
                        value={financialProduct.applyBalloonAsLastPayment}
                      />
                    </TableCell>
                    <TableCell>
                      <YesNoValue
                        value={financialProduct.allowAdvancedPayment}
                      />
                    </TableCell>
                    <TableCell>
                      {financialProduct.interestAccrualFrequency}
                    </TableCell>
                    <TableCell>
                      {financialProduct.interestCompoundingFrequency}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {financialProducts.data.length === 0 && <NoResults />}
          </ListLayout>
        );
      }}
    />
  );
}
