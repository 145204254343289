import { OnboardingLayout } from "apps/onboarding/components";
import {
  AgreementDetailsStep,
  AssetDetailsStep,
  CompanyDetailsStep,
  CustomerDetailsStep,
  FeesAndCommissionsStep,
  FinancialDetailsStep,
  RepaymentTermsStep,
} from "apps/onboarding/containers";
import { useOnboarding } from "apps/onboarding/providers";
import { Step, StepperProvider } from "common/providers";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export type StepperRoutes = {
  companyDetails?: string;
  customerDetails?: string;
  assetDetails: string;
  agreementDetails: string;
  financialDetails: string;
  feesAndCommissionsStep: string;
  repaymentTerms: string;
};

interface OnboardStepperContainerProps {
  initialActiveStep?: string;
  routes: StepperRoutes;
}

export default function OnboardStepperContainer({
  initialActiveStep,
  routes,
}: OnboardStepperContainerProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const onboarding = useOnboarding();

  const menuLabels = [
    t("agreement_details.title"),
    t("asset_details.title"),
    t("financial_details.title"),
    t("fees_and_commissions.title"),
    t("repayment_terms.title"),
  ];

  if (routes.companyDetails) {
    menuLabels.unshift(t("company_details.title"), t("customer_details.title"));
  }

  return (
    <StepperProvider
      initialActiveStep={initialActiveStep}
      steps={Object.values(routes)}
    >
      <OnboardingLayout
        customerName={onboarding.customerName}
        menu={menuLabels}
      >
        <>
          {routes.companyDetails && routes.customerDetails && (
            <>
              <Step path={routes.companyDetails}>
                <CompanyDetailsStep />
              </Step>
              <Step path={routes.customerDetails}>
                <CustomerDetailsStep />
              </Step>
            </>
          )}

          <Step path={routes.agreementDetails}>
            <AgreementDetailsStep />
          </Step>

          <Step path={routes.assetDetails}>
            <AssetDetailsStep initialActiveStep={initialActiveStep} />
          </Step>

          {onboarding.state.agreementDetails?.productType && (
            <>
              <Step path={routes.financialDetails}>
                <FinancialDetailsStep />
              </Step>
              <Step path={routes.feesAndCommissionsStep}>
                <FeesAndCommissionsStep />
              </Step>
              <Step path={routes.repaymentTerms}>
                <RepaymentTermsStep />
              </Step>
            </>
          )}
        </>
      </OnboardingLayout>
    </StepperProvider>
  );
}
