import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Loader } from "@ldms/mui-sdk/templates";
import { Box, Skeleton, SkeletonProps, Stack } from "@mui/material";
import { useGetFinancialDetails } from "api/assets/financial-details/getFinancialDetails";
import InstalmentsTable from "apps/servicing/components/InstalmentsTable";
import { QueryError } from "common/components";
import { useTranslation } from "react-i18next";

const Fallback = () => {
  const { t } = useTranslation("assets");
  const skeletonProps = (key: number): SkeletonProps => ({
    key,
    sx: { bgcolor: "action.hover" },
    variant: "rectangular",
  });

  return (
    <Stack
      spacing={3}
      aria-label={t("financial_details.loading_label")}
      role="progressbar"
    >
      <Box>
        {[...new Array(3)].map((_, index) =>
          index % 2 === 0 ? (
            <Skeleton {...skeletonProps(index)} />
          ) : (
            <Box key={index} height={26} />
          ),
        )}
      </Box>

      <Box>
        <Box>
          <Skeleton
            variant="rectangular"
            height={37}
            sx={{ bgcolor: "action.hover" }}
          />
        </Box>
        {[...new Array(50)].map((_, index) => (
          <Box key={index} display="flex" alignItems="flex-end" height={32}>
            <Skeleton {...skeletonProps(index)} height={1} width="100%" />
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

interface AssetFinancialProps {
  assetId: number;
}

export default function AssetFinancialContainer({
  assetId,
}: AssetFinancialProps) {
  const { t } = useTranslation("assets");
  const { formatAmount } = useFormat();
  const financialDetails = useGetFinancialDetails(assetId);

  return (
    <Loader
      fallback={<Fallback />}
      ready={Boolean(financialDetails.data ?? financialDetails.error)}
      render={() => {
        if (financialDetails.error || !financialDetails.data) {
          return <QueryError onRetry={financialDetails.refetch} />;
        }

        const {
          creditDetails,
          instalments = [],
          totals,
        } = financialDetails.data;

        return (
          <Stack spacing={3}>
            <DescriptionList label={t("financial_details.description_label")}>
              <DescriptionList.Item
                label={t("financial_details.cost_price_of_asset")}
              >
                {formatAmount(creditDetails?.costPrice)}
              </DescriptionList.Item>
              <DescriptionList.Item label={t("financial_details.vat")}>
                {formatAmount(creditDetails?.vat)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("financial_details.cost_price_of_asset_vat")}
              >
                {formatAmount(creditDetails?.costPriceVat)}
              </DescriptionList.Item>
            </DescriptionList>

            <InstalmentsTable
              instalments={instalments}
              totals={totals}
              loading={financialDetails.isValidating}
            />
          </Stack>
        );
      }}
    />
  );
}
