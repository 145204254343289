/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing secondary rentals within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentFrequencyModel = {
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    HalfYearly: 'Half Yearly',
    Annually: 'Annually',
    Irregular: 'Irregular'
} as const;
export type PaymentFrequencyModel = typeof PaymentFrequencyModel[keyof typeof PaymentFrequencyModel];


export function PaymentFrequencyModelFromJSON(json: any): PaymentFrequencyModel {
    return PaymentFrequencyModelFromJSONTyped(json, false);
}

export function PaymentFrequencyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFrequencyModel {
    return json as PaymentFrequencyModel;
}

export function PaymentFrequencyModelToJSON(value?: PaymentFrequencyModel | null): any {
    return value as any;
}

