/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AutomationStatusTypeModel = {
    Active: 'Active',
    Inactive: 'Inactive',
    Executing: 'Executing'
} as const;
export type AutomationStatusTypeModel = typeof AutomationStatusTypeModel[keyof typeof AutomationStatusTypeModel];


export function AutomationStatusTypeModelFromJSON(json: any): AutomationStatusTypeModel {
    return AutomationStatusTypeModelFromJSONTyped(json, false);
}

export function AutomationStatusTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomationStatusTypeModel {
    return json as AutomationStatusTypeModel;
}

export function AutomationStatusTypeModelToJSON(value?: AutomationStatusTypeModel | null): any {
    return value as any;
}

