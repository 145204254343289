import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { useLocale } from "common/hooks";
import { AssetSettlementQuoteSummaryModel } from "generated/core/models";
import React from "react";
import { useTranslation } from "react-i18next";

type AssetSettlementQuoteSummaryProps = {
  settlementQuoteSummary: AssetSettlementQuoteSummaryModel;
  discountField: number;
};

export default function AssetSettlementQuoteSummary({
  settlementQuoteSummary,
  discountField,
}: AssetSettlementQuoteSummaryProps): React.ReactElement {
  const { t } = useTranslation("assets");
  const { formatAmount } = useFormat();
  const locale = useLocale();

  return (
    <DescriptionList
      label={t("settlement_quotes.create_quote.summary.summary_list")}
    >
      <DescriptionList.Item
        label={t(
          "settlement_quotes.create_quote.summary.expiration_date_label",
        )}
      >
        {settlementQuoteSummary?.expiryDate
          ? locale.formatDate(settlementQuoteSummary.expiryDate)
          : "-"}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t(
          "settlement_quotes.create_quote.summary.outstanding_arrears_label",
        )}
      >
        {formatAmount(Number(settlementQuoteSummary?.outstandingArrears))}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t(
          "settlement_quotes.create_quote.summary.outstanding_capital_label",
        )}
      >
        {formatAmount(Number(settlementQuoteSummary?.outstandingCapital))}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("settlement_quotes.create_quote.summary.interest_label")}
      >
        {formatAmount(Number(settlementQuoteSummary?.outstandingInterest))}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("settlement_quotes.create_quote.summary.vat_label")}
      >
        {formatAmount(Number(settlementQuoteSummary?.vat))}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("settlement_quotes.create_quote.summary.total_due_label")}
      >
        {formatAmount(Number(settlementQuoteSummary?.totalAmountDue))}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t(
          "settlement_quotes.create_quote.summary.discount_amount_label",
        )}
      >
        {formatAmount(discountField)}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("settlement_quotes.create_quote.summary.total_settle_label")}
      >
        {formatAmount(
          Number(settlementQuoteSummary?.totalAmountDue) - discountField,
        )}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t(
          "settlement_quotes.create_quote.summary.new_periodic_rental_amount_label",
        )}
      >
        {formatAmount(Number(settlementQuoteSummary?.newPeriodicRentalAmount))}
      </DescriptionList.Item>
    </DescriptionList>
  );
}
