/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DayCountConventionModel = {
    Actual365: 'ACTUAL365'
} as const;
export type DayCountConventionModel = typeof DayCountConventionModel[keyof typeof DayCountConventionModel];


export function DayCountConventionModelFromJSON(json: any): DayCountConventionModel {
    return DayCountConventionModelFromJSONTyped(json, false);
}

export function DayCountConventionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayCountConventionModel {
    return json as DayCountConventionModel;
}

export function DayCountConventionModelToJSON(value?: DayCountConventionModel | null): any {
    return value as any;
}

