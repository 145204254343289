import { useEngageConfig, useSession } from "@ldms/mui-sdk/bootstrap";
import { AdminPanelSettings, Apps } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  Fade,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Modal,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { kebabCase } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

const StyledPopper = styled(Popper)`
  z-index: 90000000;
`;

export enum UserRole {
  USER_MANAGER = "User Manager",
  DOCUMENT_MANAGER = "Document Manager",
  REPORTING_MANAGER = "Reporting Manager",
  ADMINISTRATOR = "Administrator",
  BUSINESS_SUPPORT = "Business Support",
}

const AppSwitcher = () => {
  const { t } = useTranslation("shell");
  const appConfig = useEngageConfig();
  const location = useLocation();
  const session = useSession();
  const anchorRef = useRef<null | HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleAppsOpen = () => {
    setOpen(true);
  };

  const handleAppsClose = () => {
    setOpen(false);
  };

  const permissionContains = (permissions: Set<string>, subString: string) => {
    return Array.from(permissions).some((permission) =>
      permission.startsWith(subString),
    );
  };

  const appList: [string, string, string, boolean][] = [
    [
      appConfig.appRoutes.servicing,
      t("navigation.servicing_link"),
      t("navigation.servicing_description"),
      permissionContains(session.data.permissions, "servicing:"),
    ],
    [
      appConfig.appRoutes.documents,
      t("navigation.documents_link"),
      t("navigation.documents_description"),
      permissionContains(session.data.permissions, "documents:"),
    ],
    [
      appConfig.appRoutes.onboarding,
      t("navigation.onboarding_link"),
      t("navigation.onboarding_description"),
      permissionContains(session.data.permissions, "onboarding:"),
    ],
    [
      appConfig.appRoutes.finance,
      t("navigation.finance_link"),
      t("navigation.finance_description"),
      permissionContains(session.data.permissions, "finance:"),
    ],
    [
      appConfig.appRoutes.reporting,
      t("navigation.reporting_link"),
      t("navigation.reporting_description"),
      permissionContains(session.data.permissions, "reporting:"),
    ],
    [
      appConfig.appRoutes.tasks,
      t("navigation.tasks_link"),
      t("navigation.tasks_description"),
      true,
    ],
  ];

  return (
    <>
      <IconButton
        aria-label={t("apps_button")}
        color="inherit"
        id="apps-button"
        ref={anchorRef}
        size="large"
        onClick={handleAppsOpen}
      >
        <Apps />
      </IconButton>

      <Modal
        open={open}
        onClose={handleAppsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledPopper
          anchorEl={anchorRef.current}
          open
          role={undefined}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps }) => (
            <Box marginLeft={2} marginTop={-1}>
              <Fade {...TransitionProps}>
                <Box component={Paper} paddingX={2} paddingY={2}>
                  <Box>
                    <Box paddingX={2}>
                      <Typography color="textSecondary" variant="overline">
                        {t("apps_heading")}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      spacing={0.5}
                      sx={(theme) => ({
                        width: "100%",
                        maxWidth: theme.breakpoints.values.md,
                      })}
                    >
                      {appList
                        .filter(([, , , hasAccess]) => hasAccess)
                        .map(([to, primary, secondary], index) => {
                          const descriptionId = `app-switcher-${kebabCase(
                            primary.toLocaleLowerCase(),
                          )}-description`;

                          return (
                            <Grid key={to} item sm={6}>
                              <ListItemButton
                                component={ReactRouterLink}
                                to={to}
                                aria-label={primary}
                                aria-describedby={descriptionId}
                                autoFocus={index === 0}
                                onClick={handleAppsClose}
                                selected={location.pathname.startsWith(to)}
                                sx={{ borderRadius: 1 }}
                              >
                                <ListItemText
                                  primary={primary}
                                  primaryTypographyProps={{
                                    fontWeight: 500,
                                  }}
                                  secondary={secondary}
                                  secondaryTypographyProps={{
                                    id: descriptionId,
                                  }}
                                />
                              </ListItemButton>
                            </Grid>
                          );
                        })}
                    </Grid>
                    {permissionContains(session.data.permissions, "admin:") && (
                      <>
                        <Divider
                          sx={{ marginY: 1, opacity: 0.6 }}
                          variant="middle"
                        />

                        <Grid container spacing={0.5}>
                          <Grid item sm={6}>
                            <ListItemButton
                              component={ReactRouterLink}
                              to={appConfig.appRoutes.admin}
                              aria-label={t("navigation.admin_link")}
                              aria-describedby="app-switcher-administation-description"
                              onClick={handleAppsClose}
                              selected={location.pathname.startsWith(
                                appConfig.appRoutes.admin,
                              )}
                            >
                              <ListItemAvatar>
                                <Avatar>
                                  <AdminPanelSettings />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={t("navigation.admin_link")}
                                primaryTypographyProps={{
                                  fontWeight: 500,
                                }}
                                secondary={t("navigation.admin_description")}
                                secondaryTypographyProps={{
                                  id: "app-switcher-administation-description",
                                }}
                              />
                            </ListItemButton>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Box>
                </Box>
              </Fade>
            </Box>
          )}
        </StyledPopper>
      </Modal>
    </>
  );
};

export default AppSwitcher;
