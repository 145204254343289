import { EditButton } from "@ldms/mui-sdk/components";
import { AmountField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { Grid, Typography } from "@mui/material";
import { useUpdateTerminationRecoveryDetails } from "api/terminations/updateTerminationRecoveryDetails";
import { useYupResolver } from "common/hooks";
import {
  TerminationRecoveryDetailsModel,
  UpdateTerminationRecoveryDetailsModel,
} from "generated/core/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Resolver } from "support/react-hook-form";

export interface UpdateTerminationRecoveryDetailsFieldValues {
  recoveryCosts?: number;
  guarantors?: number;
  saleOfAsset?: number;
  buyBack?: number;
  customerPayments?: number;
}

interface UpdateTerminationRecoveryDetailsContainerProps {
  terminationId: number;
  terminationRecoveryDetails: TerminationRecoveryDetailsModel;
}

interface UpdateTerminationRecoveryDetailsDialogProps {
  onSuccess(): void;
  onClose(): void;
  open: boolean;
  terminationId: number;
  terminationRecoveryDetails: TerminationRecoveryDetailsModel;
}

const useTerminationRecoveryDetailsResolver =
  (): Resolver<UpdateTerminationRecoveryDetailsFieldValues> => {
    const { t } = useTranslation("agreements");
    const recoveryCostsLabel = t(
      "terminations.update_details.recovery_costs_label",
    );
    const saleOfAssetLabel = t(
      "terminations.update_details.sale_of_asset_label",
    );
    const customerPaymentsLabel = t(
      "terminations.update_details.customer_payments_label",
    );
    const buyBackLabel = t("terminations.update_details.buy_back_label");
    const guarantorsLabel = t("terminations.update_details.guarantors_label");
    const transformField = (
      v: string,
      o: string,
    ): string | null | undefined => {
      return o === "" ? null : v;
    };

    return useYupResolver<UpdateTerminationRecoveryDetailsFieldValues>((yup) =>
      yup.object().shape({
        recoveryCosts: yup
          .number(t(recoveryCostsLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(recoveryCostsLabel))
          .maxAmount(999999.99, t(recoveryCostsLabel)),
        saleOfAsset: yup
          .number(t(saleOfAssetLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(saleOfAssetLabel))
          .maxAmount(999999.99, t(saleOfAssetLabel)),
        customerPayments: yup
          .number(t(customerPaymentsLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(customerPaymentsLabel))
          .maxAmount(999999.99, t(customerPaymentsLabel)),
        buyBack: yup
          .number(t(buyBackLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(buyBackLabel))
          .maxAmount(999999.99, t(buyBackLabel)),
        guarantors: yup
          .number(t(guarantorsLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(guarantorsLabel))
          .maxAmount(999999.99, t(guarantorsLabel)),
      }),
    );
  };

function UpdateTerminationRecoveryDetailsDialogContainer({
  onSuccess,
  onClose,
  open,
  terminationId,
  terminationRecoveryDetails,
}: UpdateTerminationRecoveryDetailsDialogProps): ReactElement {
  const { t } = useTranslation("agreements");
  const resolver = useTerminationRecoveryDetailsResolver();
  const updateTerminationRecoveryDetails = useUpdateTerminationRecoveryDetails(
    terminationId,
    {
      onSuccess,
    },
  );

  const handleOnClose = (): void => {
    updateTerminationRecoveryDetails.reset();
    onClose();
  };

  const onSubmit = async (
    data: UpdateTerminationRecoveryDetailsFieldValues,
  ): Promise<void> => {
    const updateRecoveryDetails: UpdateTerminationRecoveryDetailsModel = {
      ...data,
      recoveryCosts: data.recoveryCosts?.toFixed(2),
      guarantors: data.guarantors?.toFixed(2),
      saleOfAsset: data.saleOfAsset?.toFixed(2),
      buyBack: data.buyBack?.toFixed(2),
      customerPayments: data.customerPayments?.toFixed(2),
    };
    await updateTerminationRecoveryDetails.execute(updateRecoveryDetails);
  };

  return (
    <>
      <FormDialog
        title={t("terminations.update_details.heading_label")}
        onSubmit={onSubmit}
        onClose={handleOnClose}
        open={open}
        resolver={resolver}
        defaultValues={{
          recoveryCosts: Number(terminationRecoveryDetails.recoveryCosts),
          customerPayments: Number(terminationRecoveryDetails.customerPayments),
          guarantors: Number(terminationRecoveryDetails.guarantors),
          buyBack: Number(terminationRecoveryDetails.buyBack),
          saleOfAsset: Number(terminationRecoveryDetails.saleOfAsset),
        }}
        disabled={updateTerminationRecoveryDetails.isExecuting}
      >
        {(form) => {
          return (
            <>
              <Grid container spacing={2} rowSpacing={0.5}>
                <Grid item sm={12}>
                  <AmountField
                    name="recoveryCosts"
                    error={Boolean(
                      form.formState.errors?.recoveryCosts?.message,
                    )}
                    helperText={form.formState.errors?.recoveryCosts?.message}
                    control={form.control}
                    label={t(
                      "terminations.update_details.recovery_costs_label",
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <AmountField
                    name="customerPayments"
                    error={Boolean(
                      form.formState.errors?.customerPayments?.message,
                    )}
                    helperText={
                      form.formState.errors?.customerPayments?.message
                    }
                    control={form.control}
                    label={t(
                      "terminations.update_details.customer_payments_label",
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <AmountField
                    name="guarantors"
                    error={Boolean(form.formState.errors?.guarantors?.message)}
                    helperText={form.formState.errors?.guarantors?.message}
                    control={form.control}
                    label={t("terminations.update_details.guarantors_label")}
                  />
                </Grid>

                <Grid item sm={12}>
                  <AmountField
                    name="saleOfAsset"
                    error={Boolean(form.formState.errors?.saleOfAsset?.message)}
                    helperText={form.formState.errors?.saleOfAsset?.message}
                    control={form.control}
                    label={t("terminations.update_details.sale_of_asset_label")}
                  />
                </Grid>

                <Grid item sm={12}>
                  <AmountField
                    name="buyBack"
                    error={Boolean(form.formState.errors?.buyBack?.message)}
                    helperText={form.formState.errors?.buyBack?.message}
                    control={form.control}
                    label={t("terminations.update_details.buy_back_label")}
                  />
                </Grid>
              </Grid>

              {updateTerminationRecoveryDetails.error && (
                <Typography color="error">
                  {t("common:error.default")}
                </Typography>
              )}
            </>
          );
        }}
      </FormDialog>
    </>
  );
}

export default function UpdateTerminationRecoveryDetailsContainer({
  terminationId,
  terminationRecoveryDetails,
}: UpdateTerminationRecoveryDetailsContainerProps): ReactElement {
  const { t } = useTranslation("agreements");
  const [open, setOpen] = useState(false);
  const openDialog = (): void => {
    setOpen(true);
  };
  const closeDialog = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UpdateTerminationRecoveryDetailsDialogContainer
        onSuccess={closeDialog}
        open={open}
        onClose={closeDialog}
        terminationId={terminationId}
        terminationRecoveryDetails={terminationRecoveryDetails}
      />

      <EditButton onClick={openDialog}>
        {t("terminations.update_details.edit_button")}
      </EditButton>
    </>
  );
}
