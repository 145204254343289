import { useSession, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { EditButton } from "@ldms/mui-sdk/components";
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import { useGetOnboardingSummary } from "api/onboarding/summary/getOnboardingSummary";
import {
  ApproveOnboardingContainer,
  OnboardingSummaryDetailsContainer,
  RejectOnboardingContainer,
  SubmitOnboardingForReviewContainer,
} from "apps/onboarding/containers";
import { Loader } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import {
  OnboardingStatusModel,
  ProductTypeModel,
} from "generated/onboarding/models";
import { compose } from "lodash/fp";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

interface OnboardingReviewViewProps {
  onboardingId: number;
}

function OnboardingReviewView({
  onboardingId,
}: OnboardingReviewViewProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const appConfig = useAppConfiguration();
  const onboardingSummary = useGetOnboardingSummary(onboardingId);
  const session = useSession();

  const renderEditButton = () => {
    return (
      (onboardingSummary.data?.statusType === OnboardingStatusModel.Review &&
        session.hasPermission("onboarding:onboarding:manage")) ||
      (onboardingSummary.data?.statusType === OnboardingStatusModel.Staged &&
        session.hasPermission("onboarding:onboarding:elevated"))
    );
  };

  return (
    <Loader
      ready={Boolean(onboardingSummary.data ?? onboardingSummary.error)}
      render={(): ReactElement => {
        if (onboardingSummary.error || !onboardingSummary.data) {
          return (
            <Typography color="error" role="alert">
              {t("common:error.default")}
            </Typography>
          );
        }
        return (
          <ViewLayout
            title={
              <Stack direction="row" spacing={1}>
                <Typography component="h1" variant="h4">
                  {t("onboarding_summary.review.title_label")}
                </Typography>
                {onboardingSummary.data.statusType && (
                  <Chip
                    label={onboardingSummary.data.statusType}
                    size="small"
                  />
                )}
              </Stack>
            }
            breadcrumbs={[
              {
                href: `${appConfig.appRoutes.onboarding}`,
                label: t("onboarding_summary.review.onboarding_link"),
              },
            ]}
            action={
              <Grid container spacing={1}>
                {renderEditButton() && (
                  <Grid item>
                    <EditButton
                      component={RouterLink}
                      to={`${appConfig.appRoutes.onboarding}/${onboardingId}/edit`}
                    />
                  </Grid>
                )}

                {onboardingSummary.data?.statusType ===
                  OnboardingStatusModel.Staged && (
                  <Grid item>
                    <SubmitOnboardingForReviewContainer
                      onboardingId={onboardingId}
                      onboardingStatus={onboardingSummary.data.statusType}
                    />
                  </Grid>
                )}

                {session.hasPermission("onboarding:onboarding:elevated") &&
                  onboardingSummary.data?.statusType ===
                    OnboardingStatusModel.Review && (
                    <>
                      <Grid item>
                        <ApproveOnboardingContainer
                          onboardingId={onboardingId}
                        />
                      </Grid>
                      <Grid item>
                        <RejectOnboardingContainer
                          onboardingId={onboardingId}
                        />
                      </Grid>
                    </>
                  )}
              </Grid>
            }
          >
            <ContainedLayout>
              <Box marginBottom={3}>
                <>
                  {onboardingSummary.data &&
                    onboardingSummary.data.portfolioId !== undefined && (
                      <OnboardingSummaryDetailsContainer
                        onboardingSummary={onboardingSummary.data}
                        product={
                          onboardingSummary.data.productType as ProductTypeModel
                        }
                        gridColumns={3}
                        portfolioId={onboardingSummary.data.portfolioId}
                      />
                    )}
                </>
              </Box>
            </ContainedLayout>
          </ViewLayout>
        );
      }}
    />
  );
}

export default compose(
  withTranslationLoader("onboardings"),
  withProtectedView({
    allowedPermissions: ["onboarding:onboarding:view"],
  }),
)(OnboardingReviewView);
