/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Add insurance to an agreement
 * @export
 * @interface AddInsuranceModel
 */
export interface AddInsuranceModel {
    /**
     * code that represents the insurer
     * @type {string}
     * @memberof AddInsuranceModel
     */
    code: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AddInsuranceModel
     */
    premiumTotal: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AddInsuranceModel
     */
    cost: string;
    /**
     * Insurance premium tax rate
     * @type {number}
     * @memberof AddInsuranceModel
     */
    insurancePremiumTaxRate: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AddInsuranceModel
     */
    startDate: Date;
}

/**
 * Check if a given object implements the AddInsuranceModel interface.
 */
export function instanceOfAddInsuranceModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "premiumTotal" in value;
    isInstance = isInstance && "cost" in value;
    isInstance = isInstance && "insurancePremiumTaxRate" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function AddInsuranceModelFromJSON(json: any): AddInsuranceModel {
    return AddInsuranceModelFromJSONTyped(json, false);
}

export function AddInsuranceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddInsuranceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'premiumTotal': json['premiumTotal'],
        'cost': json['cost'],
        'insurancePremiumTaxRate': json['insurancePremiumTaxRate'],
        'startDate': (new Date(json['startDate'])),
    };
}

export function AddInsuranceModelToJSON(value?: AddInsuranceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'premiumTotal': value.premiumTotal,
        'cost': value.cost,
        'insurancePremiumTaxRate': value.insurancePremiumTaxRate,
        'startDate': (value.startDate.toISOString().substring(0,10)),
    };
}

