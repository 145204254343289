import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface BatchContent {
  id: string;
  agreementId: number;
  agreementNumber: string;
  deliveryMethod?: string;
  deliveryStatus?: string;
  type: string;
  processedAt: string;
  filename?: string;
}

interface BatchContentListProps {
  data?: BatchContent[];
  loading: boolean;
}

export default function BatchContentList({
  data,
  loading,
}: Readonly<BatchContentListProps>): ReactElement {
  const { t } = useTranslation("documents");

  const tableHeaders: string[] = [
    t("batch_content_list.document_name_label"),
    t("batch_content_list.agreement_number_label"),
  ];

  return (
    <>
      <Table size="small" aria-label={t("batch_content_list.title")}>
        <LoadableTableHead headings={tableHeaders} loading={loading} />

        <TableBody>
          {data?.map((item, _index) => (
            <TableRow key={item.id}>
              <TableCell>{item.filename}</TableCell>
              <TableCell>{item.agreementNumber}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(data ?? []).length === 0 && <NoResults />}
    </>
  );
}
