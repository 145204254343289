/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateListItemModel
 */
export interface TemplateListItemModel {
    /**
     * The UUID of the template
     * @type {string}
     * @memberof TemplateListItemModel
     */
    id: string;
    /**
     * The template type
     * @type {string}
     * @memberof TemplateListItemModel
     */
    type?: string;
    /**
     * Flag denoting if the template type is enabled
     * @type {boolean}
     * @memberof TemplateListItemModel
     */
    active: boolean;
    /**
     * The name of the template type
     * @type {string}
     * @memberof TemplateListItemModel
     */
    name: string;
    /**
     * The template type
     * @type {string}
     * @memberof TemplateListItemModel
     */
    documentType: string;
    /**
     * A date time format
     * @type {string}
     * @memberof TemplateListItemModel
     */
    createdAt?: string;
    /**
     * The name of the user who created the template
     * @type {string}
     * @memberof TemplateListItemModel
     */
    createdBy?: string;
    /**
     * The version number of the template
     * @type {number}
     * @memberof TemplateListItemModel
     */
    version: number;
}

/**
 * Check if a given object implements the TemplateListItemModel interface.
 */
export function instanceOfTemplateListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "documentType" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function TemplateListItemModelFromJSON(json: any): TemplateListItemModel {
    return TemplateListItemModelFromJSONTyped(json, false);
}

export function TemplateListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'active': json['active'],
        'name': json['name'],
        'documentType': json['documentType'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'version': json['version'],
    };
}

export function TemplateListItemModelToJSON(value?: TemplateListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'active': value.active,
        'name': value.name,
        'documentType': value.documentType,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'version': value.version,
    };
}

