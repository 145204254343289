import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import EditAutomationContainer from "apps/admin/containers/EditAutomationContainer/EditAutomationContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout/ViewLayout";
import { useTranslation } from "react-i18next";

const EditAutomationView = ({ automationId }: { automationId: string }) => {
  const { t } = useTranslation(["automations"]);

  return (
    <ViewLayout title={t("edit_automation.title")}>
      <ContainedLayout>
        <EditAutomationContainer automationId={Number(automationId)} />
      </ContainedLayout>
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: ["admin:automations:manage"],
})(EditAutomationView);
