/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Portfolios within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Portfolio Details
 * @export
 * @interface PortfolioBankDetailsModel
 */
export interface PortfolioBankDetailsModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof PortfolioBankDetailsModel
     */
    readonly id: number;
    /**
     * A read only Id
     * @type {string}
     * @memberof PortfolioBankDetailsModel
     */
    readonly systemId?: string;
    /**
     * The portfolio bank name
     * @type {string}
     * @memberof PortfolioBankDetailsModel
     */
    name: string;
    /**
     * The bank account number
     * @type {string}
     * @memberof PortfolioBankDetailsModel
     */
    accountNumber?: string;
    /**
     * The international bank account number
     * @type {string}
     * @memberof PortfolioBankDetailsModel
     */
    iban?: string;
    /**
     * The service user number
     * @type {string}
     * @memberof PortfolioBankDetailsModel
     */
    serviceUserNumber: string;
    /**
     * The bank account sort code
     * @type {string}
     * @memberof PortfolioBankDetailsModel
     */
    sortCode?: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof PortfolioBankDetailsModel
     */
    bic?: string;
}

/**
 * Check if a given object implements the PortfolioBankDetailsModel interface.
 */
export function instanceOfPortfolioBankDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "serviceUserNumber" in value;

    return isInstance;
}

export function PortfolioBankDetailsModelFromJSON(json: any): PortfolioBankDetailsModel {
    return PortfolioBankDetailsModelFromJSONTyped(json, false);
}

export function PortfolioBankDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioBankDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemId': !exists(json, 'systemId') ? undefined : json['systemId'],
        'name': json['name'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'serviceUserNumber': json['serviceUserNumber'],
        'sortCode': !exists(json, 'sortCode') ? undefined : json['sortCode'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
    };
}

export function PortfolioBankDetailsModelToJSON(value?: PortfolioBankDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'accountNumber': value.accountNumber,
        'iban': value.iban,
        'serviceUserNumber': value.serviceUserNumber,
        'sortCode': value.sortCode,
        'bic': value.bic,
    };
}

