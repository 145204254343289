import { AddButton } from "@ldms/mui-sdk/components";
import { useAddCustomerMemo } from "api/customers/memos/addCustomerMemo";
import { AddMemoFormDialog } from "common/components";
import { AddMemoModel } from "generated/core/models/AddMemoModel";
import { useState } from "react";

interface AddMemoContainerProps {
  customerId: string;
}

export default function AddMemoContainer({
  customerId,
}: AddMemoContainerProps) {
  const addCustomerMemo = useAddCustomerMemo(customerId);
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => setOpen(true);

  const onClose = () => setOpen(false);

  const onSubmit = async (model: AddMemoModel) => {
    await addCustomerMemo.command(model);
    onClose();
  };

  return (
    <>
      <AddButton onClick={handleClick} variant="contained" />
      <AddMemoFormDialog onClose={onClose} onSubmit={onSubmit} open={isOpen} />
    </>
  );
}
