import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import AddContactContainer from "apps/servicing/modules/customers/containers/AddContactContainer";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout/ViewLayout";
import { useTranslation } from "react-i18next";

const AddContactView = () => {
  const { t } = useTranslation(["clients", "common"]);
  const customer = useCustomer();

  return (
    <ViewLayout
      title={t("add_contact.title")}
      breadcrumbs={[
        { label: t("clients:breadcrumbs.contacts"), href: "../contacts" },
      ]}
    >
      <ContainedLayout>
        <AddContactContainer customerId={customer.data.systemId} />
      </ContainedLayout>
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: ["servicing:contacts:manage"],
})(AddContactView);
