import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Button, Typography } from "@mui/material";
import { useRejectOnboarding } from "api/onboarding/rejectOnboarding";
import { useConfirm } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface RejectOnboardingContainerProps {
  onboardingId: number;
}

export default function RejectOnboardingContainer({
  onboardingId,
}: RejectOnboardingContainerProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const confirm = useConfirm();
  const navigate = useNavigate();
  const closeLabel = t("common:alert.close");
  const errorTitleLabel = t("reject.error_title");

  const onSuccess = (): void => {
    navigate("..");
  };

  const rejectOnboarding = useRejectOnboarding(onboardingId, {
    onSuccess,
  });

  const onRejectOnboarding = async (): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      await rejectOnboarding.execute({ onboardingId });
    });
  };

  const onCloseErrorDialog = (): void => {
    rejectOnboarding.reset();
  };

  return (
    <>
      <Button
        aria-label={t("reject.button_label")}
        variant="contained"
        onClick={onRejectOnboarding}
      >
        {t("reject.button_label")}
      </Button>

      <ConfirmationDialog
        content={<Typography>{t("reject.confirmation_message")}</Typography>}
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("reject.confirmation_title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        content={rejectOnboarding.error?.message}
        labels={{ close: closeLabel }}
        onClose={onCloseErrorDialog}
        open={Boolean(rejectOnboarding.error)}
        title={errorTitleLabel}
      />
    </>
  );
}
