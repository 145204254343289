import { AddButton } from "@ldms/mui-sdk/components";
import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Box,
  Checkbox,
  FormHelperText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useListFees } from "api/onboarding/fees/listFees";
import { useAssociateFees } from "api/onboarding/financial-products/associateFees";
import { FeeTypeEnum } from "apps/onboarding/types";
import { NoResults, QueryError, YesNoValue } from "common/components";
import { useYupResolver } from "common/hooks";
import { FeeTypeModel } from "generated/onboarding/models";
import React, { ReactElement, useState } from "react";
import { Controller, Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface AssociateFeesContainerProps {
  financialProductId: number;
  currentFees: number[];
}

interface AssociateFeesDialogContainerProps {
  onSuccess(): void;
  onClose(): void;
  open: boolean;
  financialProductId: number;
  currentFees: number[];
}

interface AssociateFeesFormValues {
  fees: number[];
}

type ModelKeys = keyof typeof FeeTypeModel;

const useAssociateFeesResolver = (): Resolver<AssociateFeesFormValues> => {
  const { t } = useTranslation("onboardings");
  return useYupResolver<AssociateFeesFormValues>((yup) =>
    yup.object().shape({
      fees: yup
        .array()
        .min(
          1,
          t("financial_products.associate_fees.no_fees_selected_error_message"),
        ),
    }),
  );
};

function AssociateFeesDialogContainer({
  onSuccess: onSuccessCallback,
  onClose,
  open,
  financialProductId,
  currentFees,
}: AssociateFeesDialogContainerProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const resolver = useAssociateFeesResolver();
  const fees = useListFees({ enabled: true });
  const associateFees = useAssociateFees(financialProductId, {
    onSuccess: onSuccessCallback,
  });

  const availableFees =
    fees.data?.filter((a) => a && !currentFees.includes(a.id)) || [];

  const tableHeaders: string[] = [
    "",
    t("financial_products.details.fees.name_label"),
    t("financial_products.details.fees.type_label"),
    t("financial_products.details.fees.taxable_label"),
    t("financial_products.details.fees.deducted_from_advance_label"),
  ];

  const handleSubmit = async (
    values: AssociateFeesFormValues,
  ): Promise<void> => {
    await associateFees.execute(currentFees.concat(values.fees));
  };

  const handleClose = () => {
    associateFees.reset();
    onClose();
  };

  return (
    <FormDialog
      title={t("financial_products.associate_fees.heading_label")}
      onSubmit={handleSubmit}
      onClose={handleClose}
      open={open}
      resolver={resolver}
      dialogProps={{ scroll: "paper", maxWidth: "md" }}
      ready={Boolean(fees.data || fees.error)}
      defaultValues={{ fees: [] }}
      disabled={availableFees.length === 0 || associateFees.isExecuting}
    >
      {(form) => {
        if (fees.error || !fees.data) {
          return <QueryError onRetry={fees.refetch} />;
        }

        const feeIds = form.watch("fees");

        const handleCheck = (checkedId: number) => {
          if (feeIds.includes(checkedId)) {
            return feeIds.filter((feeId) => feeId !== checkedId);
          }
          return [...feeIds, checkedId];
        };

        return (
          <>
            <Stack gap={2}>
              <Box maxHeight={350} overflow="auto">
                <Table
                  size="small"
                  stickyHeader
                  aria-label={t(
                    "financial_products.associate_fees.heading_label",
                  )}
                >
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((label) => (
                        <TableCell key={label} size="small">
                          {label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {availableFees.map((availableFee, index) => (
                      <TableRow hover key={availableFee.id}>
                        <TableCell padding="checkbox" width="5%">
                          <Controller
                            name="fees"
                            control={form.control}
                            render={({ field }) => (
                              <Checkbox
                                onChange={() =>
                                  field.onChange(handleCheck(availableFee.id))
                                }
                                inputRef={field.ref}
                                name={field.name}
                                checked={feeIds.includes(availableFee.id)}
                                inputProps={{
                                  "aria-label": `financial_products.associate_fees.checkbox.${index}`,
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>{availableFee.name}</TableCell>
                        <TableCell>
                          {t(
                            FeeTypeEnum[
                              Object.keys(FeeTypeModel).find(
                                (key) =>
                                  FeeTypeModel[key as ModelKeys] ===
                                  availableFee.type,
                              ) as ModelKeys
                            ],
                          )}
                        </TableCell>
                        <TableCell>
                          <YesNoValue value={availableFee.taxable} />
                        </TableCell>
                        <TableCell>
                          <YesNoValue
                            value={availableFee.deductedFromAdvance}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                {availableFees.length === 0 && <NoResults />}

                <FormHelperText
                  error={Boolean(form.formState.errors?.fees?.message)}
                  margin="dense"
                >
                  {form.formState.errors?.fees?.message}
                </FormHelperText>
              </Box>

              {associateFees.error && (
                <Typography color="error">
                  {t("common:error.default")}
                </Typography>
              )}
            </Stack>
          </>
        );
      }}
    </FormDialog>
  );
}

export default function AssociateFeesContainer({
  financialProductId,
  currentFees,
}: AssociateFeesContainerProps): React.ReactElement {
  const [openAddFeeDialog, setOpenAddFeeDialog] = useState(false);
  const openDialog = (): void => {
    setOpenAddFeeDialog(true);
  };

  const closeDialog = (): void => {
    setOpenAddFeeDialog(false);
  };

  return (
    <>
      <AddButton onClick={openDialog} />

      <AssociateFeesDialogContainer
        onSuccess={closeDialog}
        open={openAddFeeDialog}
        onClose={closeDialog}
        financialProductId={financialProductId}
        currentFees={currentFees}
      />
    </>
  );
}
