import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import Templates from "apps/documents/containers/Templates";
import ContainedLayout from "common/layouts/ContainedLayout";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

function TemplatesView(): ReactElement {
  const { t } = useTranslation("documents");

  return (
    <ViewLayout title={t("templates.heading")}>
      <FixedLayout>
        <ContainedLayout>
          <Templates />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}

export default withProtectedView({
  allowedPermissions: ["documents:templates:manage"],
})(TemplatesView);
