import { AlertDialog } from "@ldms/mui-sdk/templates";
import { MenuItem } from "@mui/material";
import { useCreateInvoice } from "api/agreements/schedule/invoice";
import { useConfirm } from "common/hooks";
import { InstalmentListItemModel } from "generated/core/models";
import { useTranslation } from "react-i18next";

export interface CreateInvoiceContainerProps {
  agreementNumber: number;
  selectedInstalment: InstalmentListItemModel;
}

const CreateInvoiceContainer = ({
  agreementNumber,
  selectedInstalment,
}: CreateInvoiceContainerProps) => {
  const { t } = useTranslation(["agreements"]);
  const confirm = useConfirm();

  const handleOnClose = () => {
    confirm.handleConfirm();
  };

  const createInvoice = useCreateInvoice(
    agreementNumber,
    selectedInstalment.instalmentNumber,
  );

  const handleCreateInvoice = async () => {
    confirm.handlePrompt(() => createInvoice.execute());
  };

  return (
    <>
      <MenuItem onClick={handleCreateInvoice}>
        {t("repayment_schedule.create_invoice")}
      </MenuItem>

      <AlertDialog
        content={t("repayment_schedule.create_invoice_request")}
        labels={{ close: t("common:alert.close") }}
        open={confirm.isOpen}
        title={t("repayment_schedule.create_invoice")}
        onClose={handleOnClose}
      />
    </>
  );
};

export default CreateInvoiceContainer;
