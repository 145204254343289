import { Form, NumberField } from "@ldms/mui-sdk/forms";
import { ConfirmationDialog } from "@ldms/mui-sdk/templates";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAddCompany } from "api/companies";
import { useListCurrencies } from "api/currencies";
import { useListVatCodesV2 } from "api/vat-codes/listVatCodes";
import { ControlledTextField, Loader } from "common/components";
import { useConfirm, useYupResolver } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import {
  CreateCompanyModel,
  CurrencyListItemModel,
  TaxCodeListItemModel,
} from "generated/servicing-v2/models";
import { omit } from "lodash";
import { ReactElement } from "react";
import {
  Control,
  FieldErrors,
  Resolver,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
export interface AddCompanyFieldValues {
  name: string;
  currency: string;
  registrationNumber?: string;
  vatNumber?: string;
  caisSourceCode?: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  postcode: string;
  vatCode: string;
  originationCommissionPercentage?: number;
}

const useAddCompanyResolver = (): Resolver<AddCompanyFieldValues> => {
  const { t } = useTranslation("servicing");

  const companyLabel = "companies.add_company.company_name_label";
  const addressLine1Label = "companies.add_company.address_line_1_label";
  const postcodeLabel = "companies.add_company.postcode_label";
  const originationCommissionLabel =
    "companies.add_company.origination_commission_label";

  return useYupResolver<AddCompanyFieldValues>((yup) =>
    yup.object().shape({
      name: yup
        .string()
        .isRequired(t(companyLabel))
        .maxCharacters(100, t(companyLabel)),
      currency: yup
        .string()
        .isRequired(t("companies.add_company.currency_label")),
      registrationNumber: yup
        .string()
        .maxCharacters(
          10,
          t("companies.add_company.registration_number_label"),
        ),
      vatNumber: yup
        .string()
        .maxCharacters(15, t("companies.add_company.vat_number_label")),
      caisSourceCode: yup
        .string()
        .maxCharacters(50, t("companies.add_company.cais_source_code_label")),
      addressLine1: yup
        .string()
        .isRequired(t(addressLine1Label))
        .maxCharacters(50, t(addressLine1Label)),
      addressLine2: yup
        .string()
        .maxCharacters(50, t("companies.add_company.address_line_2_label")),
      addressLine3: yup
        .string()
        .maxCharacters(50, t("companies.add_company.address_line_3_label")),
      addressLine4: yup
        .string()
        .maxCharacters(50, t("companies.add_company.address_line_4_label")),
      postcode: yup
        .string()
        .isRequired(t(postcodeLabel))
        .maxCharacters(10, t(postcodeLabel)),
      vatCode: yup
        .string()
        .isRequired(t("companies.add_company.vat_code_label")),
      originationCommissionPercentage: yup
        .number(t(originationCommissionLabel))
        .nullable()
        .minAmount(0, t(originationCommissionLabel))
        .maxAmount(100.0, t(originationCommissionLabel)),
    }),
  );
};

interface AddCompanyFormControlsProps {
  errors: FieldErrors<AddCompanyFieldValues>;
  register: UseFormRegister<AddCompanyFieldValues>;
  control: Control<AddCompanyFieldValues>;
  vatCodes: TaxCodeListItemModel[];
  currencies: CurrencyListItemModel[];
}

function AddCompanyFormControls({
  errors,
  register,
  control,
  vatCodes,
  currencies,
}: AddCompanyFormControlsProps): ReactElement {
  const { t } = useTranslation("servicing");

  return (
    <>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={12}>
          <TextField
            {...register("name")}
            label={t("companies.add_company.company_name_label")}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            required
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("registrationNumber")}
            label={t("companies.add_company.registration_number_label")}
            error={Boolean(errors.registrationNumber)}
            helperText={errors.registrationNumber?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("vatNumber")}
            label={t("companies.add_company.vat_number_label")}
            error={Boolean(errors.vatNumber)}
            helperText={errors.vatNumber?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <ControlledTextField
            id="currency"
            name="currency"
            label={t("companies.add_company.currency_label")}
            helperText={errors.currency?.message}
            error={errors.currency?.message}
            SelectProps={{ displayEmpty: true }}
            control={control}
            select
            required
          >
            <MenuItem value="">
              <i>{t("common:please_select")}</i>
            </MenuItem>

            {currencies.map((currency) => (
              <MenuItem key={currency.code} value={currency.code}>
                {currency.code}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("caisSourceCode")}
            label={t("companies.add_company.cais_source_code_label")}
            error={Boolean(errors.caisSourceCode)}
            helperText={errors.caisSourceCode?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <ControlledTextField
            id="vatCode"
            name="vatCode"
            label={t("companies.add_company.vat_code_label")}
            helperText={errors.vatCode?.message}
            error={errors.vatCode?.message}
            SelectProps={{ displayEmpty: true }}
            control={control}
            select
            required
          >
            <MenuItem value="">
              <i>{t("common:please_select")}</i>
            </MenuItem>

            {vatCodes.map((vatCode) => (
              <MenuItem key={vatCode.systemId} value={vatCode.systemId}>
                {vatCode.name}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item sm={6}>
          <NumberField
            control={control}
            label={t("companies.add_company.origination_commission_label")}
            name="originationCommissionPercentage"
            error={Boolean(errors.originationCommissionPercentage?.message)}
            helperText={errors.originationCommissionPercentage?.message}
          />
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Divider flexItem variant="fullWidth" />
      </Grid>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine1")}
            label={t("companies.add_company.address_line_1_label")}
            error={Boolean(errors.addressLine1)}
            helperText={errors.addressLine1?.message}
            required
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine2")}
            label={t("companies.add_company.address_line_2_label")}
            error={Boolean(errors.addressLine2)}
            helperText={errors.addressLine2?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine3")}
            label={t("companies.add_company.address_line_3_label")}
            error={Boolean(errors.addressLine3)}
            helperText={errors.addressLine3?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            label={t("companies.add_company.address_line_4_label")}
            {...register("addressLine4")}
            helperText={errors.addressLine4?.message}
            error={Boolean(errors.addressLine4)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            label={t("companies.add_company.postcode_label")}
            {...register("postcode")}
            error={Boolean(errors.postcode)}
            helperText={errors.postcode?.message}
            required
          />
        </Grid>
      </Grid>
    </>
  );
}

interface AddCompanyDialogProps {
  vatCodes: TaxCodeListItemModel[];
  currencies: CurrencyListItemModel[];
}

function AddCompanyDialog({
  vatCodes,
  currencies,
}: AddCompanyDialogProps): ReactElement {
  const { t } = useTranslation("servicing");
  const navigate = useNavigate();
  const confirm = useConfirm();
  const resolver = useAddCompanyResolver();
  const appConfig = useAppConfiguration();

  const onSuccess = () => {
    navigate("..");
  };

  const addCompany = useAddCompany({ onSuccess });

  const onSubmit = async (data: AddCompanyFieldValues): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      const addCompanyModel: CreateCompanyModel = {
        ...omit(data, "vatCode", "vatNumber"),
        taxCode: data.vatCode,
        taxRegistrationNumber: data.vatNumber,
      };
      await addCompany.command(addCompanyModel);
    });
  };

  return (
    <>
      <ConfirmationDialog
        content={
          <Typography>
            {t("companies.add_company.confirmation_message")}
          </Typography>
        }
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("companies.add_company.title_label")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <Form
        label={t("companies.add_company.title_label")}
        onSubmit={onSubmit}
        resolver={resolver}
        defaultValues={{ currency: "", vatCode: "" }}
      >
        {(form): ReactElement => {
          return (
            <Container maxWidth="sm">
              <Paper>
                <Stack gap={2} padding={2}>
                  <AddCompanyFormControls
                    errors={form.formState.errors}
                    register={form.register}
                    control={form.control}
                    vatCodes={vatCodes}
                    currencies={currencies}
                  />

                  {addCompany.error && (
                    <Box>
                      <Typography color="error" data-testid="form.error">
                        {addCompany.error.message}
                      </Typography>
                    </Box>
                  )}

                  <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Button
                      role="link"
                      key="common:cancel"
                      component={RouterLink}
                      to={`${appConfig.appRoutes.servicing}/settings/companies`}
                      color="primary"
                    >
                      {t("common:cancel")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={addCompany.isLoading}
                    >
                      {t("companies.add_company.submit_button")}
                    </Button>
                  </Box>
                </Stack>
              </Paper>
            </Container>
          );
        }}
      </Form>
    </>
  );
}

export default function AddCompanyContainer(): ReactElement {
  const { t } = useTranslation("servicing");
  const currencies = useListCurrencies();
  const vatCodes = useListVatCodesV2();

  return (
    <Loader
      ready={Boolean(
        (currencies.data || currencies.error) &&
          (vatCodes.data || vatCodes.error),
      )}
      render={(): ReactElement => {
        if (
          currencies.error ||
          !currencies.data ||
          vatCodes.error ||
          !vatCodes.data
        ) {
          return (
            <Typography color="error" data-testid="company.error">
              {t("common:error.default")}
            </Typography>
          );
        }

        return (
          <AddCompanyDialog
            vatCodes={vatCodes.data}
            currencies={currencies.data}
          />
        );
      }}
    />
  );
}
