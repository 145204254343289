import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Button } from "@mui/material";
import { useRemoveInsurance } from "api/agreements/insurance/removeInsurance";
import { useConfirm } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface RemoveAgreementInsuranceContainerProps {
  agreementId: number;
}

export default function RemoveAgreementInsuranceContainer({
  agreementId,
}: RemoveAgreementInsuranceContainerProps): ReactElement {
  const { t } = useTranslation("agreements");
  const removeInsurance = useRemoveInsurance(agreementId);
  const confirm = useConfirm();

  const onRemoveInsurance = async (): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      await removeInsurance.command();
    });
  };

  const onCloseErrorDialog = (): void => {
    removeInsurance.reset();
  };

  return (
    <>
      <Button onClick={onRemoveInsurance}>
        {t("insurance.remove.button_label")}
      </Button>

      <ConfirmationDialog
        content={t("insurance.remove.confirmation.message")}
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("insurance.remove.confirmation.title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        content={removeInsurance.error?.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(removeInsurance.error)}
        title={t("insurance.remove.error_title")}
      />
    </>
  );
}
