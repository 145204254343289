import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { Loader } from "@ldms/mui-sdk/templates";
import { Download } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useListAgreementTransactions } from "api/agreements/transactions/listAgreementTransactions";
import {
  TransactionsFilter,
  TransactionsList,
} from "apps/servicing/modules/agreements/components";
import {
  PostCharge,
  RemoveCharge,
  ReturnPayment,
  TransferPayment,
} from "apps/servicing/modules/agreements/containers";
import { useLocale } from "common/hooks";
import ListLayout from "common/layouts/ListLayout";
import { useAgreement } from "common/providers";
import { AgreementTransactionModel } from "generated/core/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { downloadAsCSV } from "support/download";

interface AgreementTransactionFilters {
  transactionType: string;
  fromDate: Date | null;
  toDate: Date | null;
}

const filterByTransactionType = (filters: AgreementTransactionFilters) => {
  return (transaction: AgreementTransactionModel) => {
    if (
      filters.transactionType !== "" &&
      transaction.type !== filters.transactionType
    ) {
      return false;
    }

    return true;
  };
};

const filterByToDate = (filters: AgreementTransactionFilters) => {
  return (transaction: AgreementTransactionModel) => {
    if (
      filters.toDate !== null &&
      new Date(transaction.date) > filters.toDate
    ) {
      return false;
    }

    return true;
  };
};

const filterByFromDate = (filters: AgreementTransactionFilters) => {
  return (transaction: AgreementTransactionModel) => {
    if (
      filters.fromDate !== null &&
      new Date(transaction.date) < filters.fromDate
    ) {
      return false;
    }

    return true;
  };
};

type Dialog =
  | "AddTransaction"
  | "PostCharge"
  | "ReturnPayment"
  | "TransferPayment"
  | "RemoveCharge";

export default function AgreementTransactions(): ReactElement {
  const agreement = useAgreement();
  const [activeDialog, setActiveDialog] = useState<Dialog | undefined>();
  const { t } = useTranslation(["agreements", "common"]);
  const transactions = useListAgreementTransactions(agreement.id);
  const locale = useLocale();
  const [selectedTransactionId, setSelectedTransactionId] = useState<number>();
  const [filters, setFilters] = useState<AgreementTransactionFilters>({
    transactionType: "",
    fromDate: null,
    toDate: null,
  });

  const handleCloseDialog = (): void => {
    setActiveDialog(undefined);
  };

  const filteredTransactions = (transactions.data ?? [])
    .filter(filterByTransactionType(filters))
    .filter(filterByFromDate(filters))
    .filter(filterByToDate(filters));

  const handleExport = () => {
    downloadAsCSV(
      [
        [
          t("transactions.list.date"),
          t("transactions.list.bank_code"),
          t("transactions.list.type"),
          t("transactions.list.description"),
          t("transactions.list.credit"),
          t("transactions.list.debit"),
          t("transactions.list.balance"),
        ],
        ...filteredTransactions.map((row) => [
          row.date && locale.formatISODate(row.date),
          row.bankCode,
          row.type,
          row.description,
          row.creditAmount,
          row.debitAmount,
          row.balance,
        ]),
      ],
      {
        filename: `${agreement.id}_transactions`,
      },
    );
  };

  const onFilterChange = (key: string, value: string): void => {
    setFilters((current) => ({ ...current, [key]: value }));
  };

  const onReturnPayment = (transactionId: number): void => {
    setSelectedTransactionId(transactionId);
    setActiveDialog("ReturnPayment");
  };

  const onTransferPayment = (transactionId: number): void => {
    setSelectedTransactionId(transactionId);
    setActiveDialog("TransferPayment");
  };

  const onRemoveCharge = (transactionId: number): void => {
    setSelectedTransactionId(transactionId);
    setActiveDialog("RemoveCharge");
  };

  const onSubmit = (): void => {
    transactions.refetch();
    setActiveDialog(undefined);
  };

  const onReturnPaymentSubmit = (applyCharge: boolean): void => {
    onSubmit();
    if (applyCharge) {
      setActiveDialog("PostCharge");
    }
  };

  return (
    <>
      <PostCharge
        open={activeDialog === "PostCharge"}
        onClose={handleCloseDialog}
      />

      {selectedTransactionId && (
        <>
          <ReturnPayment
            transactionId={selectedTransactionId}
            transactions={transactions.data}
            onClose={handleCloseDialog}
            onSubmit={onReturnPaymentSubmit}
            open={activeDialog === "ReturnPayment"}
          />
          <TransferPayment
            transactionId={selectedTransactionId}
            onClose={handleCloseDialog}
            onSubmit={() => setActiveDialog(undefined)}
            open={activeDialog === "TransferPayment"}
          />
          <RemoveCharge
            transactionId={selectedTransactionId}
            transactions={transactions.data}
            onClose={handleCloseDialog}
            onSubmit={onSubmit}
            open={activeDialog === "RemoveCharge"}
          />
        </>
      )}

      <ListLayout
        filters={
          <TransactionsFilter
            filters={filters}
            onChange={onFilterChange}
            transactionTypes={Array.from(
              new Set(transactions.data?.map((value) => value.type) ?? []),
            )}
          />
        }
        actions={
          <AccessControl allowedPermissions={["servicing:transactions:manage"]}>
            <Button
              onClick={handleExport}
              startIcon={<Download fontSize="inherit" />}
            >
              {t("transactions.export")}
            </Button>
          </AccessControl>
        }
      >
        <Loader
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
          ready={Boolean(transactions.data ?? transactions.error)}
          render={() => {
            if (transactions.error) {
              return (
                <Typography
                  aria-label={t("common:error.default")}
                  color="error"
                  role="alert"
                >
                  {t("common:error.default")}
                </Typography>
              );
            }

            return (
              <TransactionsList
                transactions={filteredTransactions}
                loading={transactions.isValidating}
                onReturnPayment={onReturnPayment}
                onTransferPayment={onTransferPayment}
                onRemoveCharge={onRemoveCharge}
              />
            );
          }}
        />
      </ListLayout>
    </>
  );
}
