import {
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useListCompanies } from "api/companies/listCompanies";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
} from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export default function ListCompaniesContainer(): ReactElement {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const companies = useListCompanies({ pageSize: rowsPerPage });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => companies.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Loader
      ready={Boolean(companies.data ?? companies.error)}
      render={(): ReactElement => {
        if (companies.error || !companies.data) {
          return <QueryError onRetry={companies.refetch} />;
        }

        return (
          <ListLayout
            pagination={{
              onPageChange: onChangePage,
              onRowsPerPageChange: onChangeRowsPerPage,
              rowsPerPage: rowsPerPage,
              page: companies.paging.page,
              count: companies.data.paging.total || 0,
            }}
          >
            <Table
              aria-label={t("companies.table_name_label")}
              size="small"
              stickyHeader
            >
              <LoadableTableHead
                headings={[
                  t("companies.list_companies.company_name_label"),
                  t("companies.list_companies.registration_number_label"),
                  t("companies.list_companies.vat_number_label"),
                  t("companies.list_companies.vat_code_label"),
                ]}
                loading={companies.isValidating}
              />
              <TableBody>
                {companies.data.results.map((company) => (
                  <TableRow key={company.systemId} hover>
                    <TableCell
                      padding="none"
                      sx={{ width: "500px" }}
                      component="th"
                      scope="row"
                    >
                      <ListItemButton
                        dense
                        component={RouterLink}
                        to={`${appConfig.appRoutes.servicing}/settings/companies/${company.systemId}`}
                      >
                        <ListItemText
                          primaryTypographyProps={{ color: "primary" }}
                          primary={company.name}
                        />
                      </ListItemButton>
                    </TableCell>
                    <TableCell>{company.registrationNumber}</TableCell>
                    <TableCell>{company.taxRegistrationNumber}</TableCell>
                    <TableCell>{company.taxCode}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {companies.data.results.length === 0 && <NoResults />}
          </ListLayout>
        );
      }}
    />
  );
}
