import { DateField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import { useAddComment } from "api/tasks/addComment";
import { useListAssignees } from "api/tasks/assignees/listAssignees";
import { useEditTask } from "api/tasks/editTask";
import { useFindCustomerAndAgreements } from "api/tasks/findCustomersAndAgreements/findCustomerAndAgreements";
import { useYupResolver } from "common/hooks";
import _ from "lodash";
import { useMemo, useState } from "react";
import {
  Controller,
  FieldError,
  FieldErrors,
  UseFormReturn,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

const useEditTaskResolver = () => {
  const { t } = useTranslation(["start"]);

  const transformField = (v: string, o: string): string | null | undefined => {
    return o === "" ? undefined : v;
  };

  return useYupResolver<EditTaskFieldValues>((yup) =>
    yup.object().shape({
      assignToId: yup.object().nullable(),
      customer: yup.object().nullable(),
      title: yup.string().isRequired(t(labels.title)).max(250),
      dueTime: yup
        .string()
        .transform(transformField)
        .isRequired(t(labels.dueTime))
        .test({
          name: "isNotBeforeCurrentTime",
          message: t("edit_task_dialog.due_date_and_time_error"),
          test: (value: string | undefined, testContext) => {
            const dueAt = testContext.parent.dueAt
              ?.toISOString()
              .slice(0, 11)
              .concat(value);

            return new Date(dueAt) >= new Date();
          },
        }),
      description: yup.string().isRequired(t(labels.description)).max(2000),
      agreement: yup
        .object()
        .nullable()
        .shape({
          id: yup.number(t(labels.agreement)),
        }),
      dueAt: yup
        .date()
        .localDate()
        .isValidDate(t(labels.dueAt))
        .isNotBeforeToday(t(labels.dueAt))
        .isRequired(t(labels.dueAt)),
    }),
  );
};

const AgreementAutocomplete = ({
  helperText,
  error,
  value,
  form,
  onSelect,
}: {
  helperText: string | undefined;
  onSelect: (
    value: { id: number | undefined; name: string | undefined } | null,
  ) => void;
  form: UseFormReturn<EditTaskFieldValues>;
  value: { id: number | undefined; name: string | undefined } | null;
  error: boolean;
}) => {
  const { t } = useTranslation(["start"]);
  const [query, setQuery] = useState("");

  const findCustomersAndAgreements = useFindCustomerAndAgreements({
    query,
    enabled: query.trim().length > 2,
    customerId: form.watch("customer.id") ?? undefined,
  });

  const agreements = findCustomersAndAgreements.data?.agreements ?? [];

  const handleQueryChange = useMemo(
    () => _.debounce((_event, newValue) => setQuery(newValue), 300),
    [],
  );

  return (
    <Autocomplete
      options={agreements.map((agreement) => ({
        id: agreement.id,
        name: agreement.agreementNumber,
      }))}
      id="agreement-field"
      onChange={(_, newValue) => onSelect(newValue)}
      isOptionEqualToValue={(option, value) => option.id !== value.name}
      getOptionLabel={(option) => option.name ?? ""}
      value={value}
      loading={
        !findCustomersAndAgreements.data &&
        findCustomersAndAgreements.isValidating
      }
      onInputChange={handleQueryChange}
      noOptionsText={t("no_options_text")}
      renderInput={(params) => (
        <TextField
          {...params}
          value={query}
          label={t(labels.agreement)}
          InputLabelProps={{
            shrink: true,
          }}
          error={error}
          helperText={helperText}
          margin="none"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {findCustomersAndAgreements.isValidating && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

const CustomerAutocomplete = ({
  helperText,
  error,
  form,
  value,
  onSelect,
}: {
  error: boolean;
  helperText: string | undefined;
  onSelect: (
    value: { id: number | undefined; name: string | undefined } | null,
  ) => void;
  value: { id: number | undefined; name: string | undefined } | null;
  form: UseFormReturn<EditTaskFieldValues>;
}) => {
  const [query, setQuery] = useState("");
  const { t } = useTranslation(["start"]);

  const findCustomersAndAgreements = useFindCustomerAndAgreements({
    query,
    enabled: query.trim().length > 2,
    customerId: form.watch("customer.id") ?? undefined,
  });

  const customers = findCustomersAndAgreements.data?.customers ?? [];

  const handleQueryChange = useMemo(
    () => _.debounce((_event, newValue) => setQuery(newValue), 300),
    [],
  );

  return (
    <Autocomplete
      options={customers.map((customer) => ({
        id: customer.id,
        name: customer.name,
      }))}
      id="customer-field"
      onChange={(_, newValue) => {
        onSelect(newValue);
        form.setValue("agreement", null);
      }}
      isOptionEqualToValue={(option, value) => option.id !== value.name}
      getOptionLabel={(option) => option.name ?? ""}
      noOptionsText={t("no_options_text")}
      value={value}
      loading={
        !findCustomersAndAgreements.data &&
        findCustomersAndAgreements.isValidating
      }
      disabled={
        form.watch("customer") === null && form.watch("agreement") !== null
      }
      onInputChange={handleQueryChange}
      renderInput={(params) => (
        <TextField
          {...params}
          value={query}
          label={t(labels.customer)}
          InputLabelProps={{
            shrink: true,
          }}
          error={error}
          helperText={helperText}
          margin="none"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {findCustomersAndAgreements.isValidating && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

interface EditTaskDialogProps {
  taskId: number;
  open: boolean;
  onClose: () => void;
  defaultValues: EditTaskFieldValues;
}

export type EditTaskFieldValues = {
  title: string;
  description: string;
  dueAt: Date;
  dueTime: string;
  assigneeToId: { name: string | undefined; id: string | undefined } | null;
  customer: { name: string | undefined; id: number | undefined } | null;
  agreement: {
    name: string | undefined;
    id: number | undefined;
    customerId: number | undefined;
  } | null;
};

const labels = {
  title: "edit_task_dialog.title_label",
  description: "edit_task_dialog.description_label",
  dueAt: "edit_task_dialog.due_date_label",
  dueTime: "edit_task_dialog.due_date_time_label",
  agreement: "edit_task_dialog.agreement_label",
  customer: "edit_task_dialog.customer_label",
};

const EditTaskDialog = ({
  taskId,
  open,
  onClose,
  defaultValues,
}: EditTaskDialogProps) => {
  const { t } = useTranslation(["start"]);
  const resolver = useEditTaskResolver();
  const createComment = useAddComment(taskId);
  const assignees = useListAssignees();

  const editTask = useEditTask(taskId, {
    onSuccess: async () => {
      await createComment.execute({ comment: t("comments.comment_task_edit") });
      onClose();
    },
  });

  const assigneeOptions = (assignees?.data ?? []).map((name) => ({
    name: [name.firstName, name.lastName].join(" "),
    id: name.id,
  }));

  const handleSubmit = (data: EditTaskFieldValues) => {
    const dueAt = data.dueAt?.toISOString().slice(0, 11).concat(data.dueTime);

    editTask.execute({
      description: data.description,
      dueAt: dueAt,
      title: data.title,
      agreementId: data.agreement?.id,
      assigneeToId: data.assigneeToId?.id,
      customerId: data.customer?.id,
    });
  };

  return (
    <FormDialog
      title={t("edit_task_dialog.title")}
      onSubmit={handleSubmit}
      onClose={onClose}
      open={open}
      resolver={resolver}
      labels={{
        submit: t("common:save"),
        cancel: t("common:cancel"),
      }}
      defaultValues={defaultValues}
      disabled={editTask.isExecuting}
    >
      {(form) => (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label={t(labels.title)}
              error={Boolean(form.formState.errors.title?.message)}
              {...form.register("title")}
              id="title-field"
              helperText={form.formState.errors.title?.message}
              required
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t(labels.description)}
              {...form.register("description")}
              helperText={form.formState.errors.description?.message}
              error={Boolean(form.formState.errors.description?.message)}
              id="description-field"
              multiline
              margin="none"
              required
              rows={6}
            />
          </Grid>
          <Grid item sm={6}>
            <DateField
              label={t(labels.dueAt)}
              name="dueAt"
              error={Boolean(form.formState.errors.dueAt?.message)}
              control={form.control}
              id="due-at-field"
              margin="none"
              helperText={form.formState.errors.dueAt?.message}
              required
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              margin="none"
              id="due-time-field"
              type="time"
              {...form.register("dueTime")}
              label={t(labels.dueTime)}
              helperText={form.formState.errors.dueTime?.message}
              error={Boolean(form.formState.errors.dueTime?.message)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <CustomerAutocomplete
                  value={value}
                  onSelect={onChange}
                  helperText={
                    (form.formState.errors.customer as FieldError)?.message
                  }
                  error={Boolean(form.formState.errors.customer)}
                  form={form}
                />
              )}
              control={form.control}
              name="customer"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="agreement"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <AgreementAutocomplete
                  value={value}
                  form={form}
                  onSelect={onChange}
                  error={Boolean(form.formState.errors.agreement)}
                  helperText={
                    (form.formState.errors.agreement as FieldError)?.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="assigneeToId"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  options={assigneeOptions || []}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  id="assignee-to-id-field"
                  isOptionEqualToValue={(option, value) =>
                    option.id !== value.name
                  }
                  getOptionLabel={(option) => option?.name ?? ""}
                  value={value}
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      {...params}
                      label={t("edit_task_dialog.assignee_label")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...inputProps,
                      }}
                      error={Boolean(
                        (form.formState.errors.assigneeToId as FieldErrors)?.id
                          ?.message,
                      )}
                      helperText={
                        form.formState.errors.assigneeToId?.id?.message
                      }
                      margin="none"
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </FormDialog>
  );
};

export default EditTaskDialog;
