import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { Close, Warning } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ModelKeys } from "apps/servicing/modules/customers/components/ContactPointList/ContactPointList";
import { ContactPointType } from "apps/servicing/types";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import { ContactPointTypeModel } from "generated/servicing-v2/models";
import { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface AddContactPointFieldValues {
  type: ContactPointTypeModel;
  value: string;
}

interface AddCorrespondenceMethodDialogProps {
  onClose(): void;
  onSubmit(body: AddContactPointFieldValues): Promise<void>;
  contactPointTypes: ContactPointTypeModel[];
  open: boolean;
  error?: ResponseError;
}

export default function AddCorrespondenceMethodDialog({
  onClose,
  onSubmit,
  contactPointTypes,
  open,
  error,
}: Readonly<AddCorrespondenceMethodDialogProps>): ReactElement {
  const { t } = useTranslation(["clients", "common"]);
  const detailLabel = t("add_correspondence_method.detail_label");
  const resolver = useYupResolver<AddContactPointFieldValues>((yup) =>
    yup.object().shape({
      type: yup.string().isRequired(t("add_correspondence_method.type_label")),
      value: yup
        .string()
        .maxCharacters(200, detailLabel)
        .isRequired(detailLabel),
    }),
  );

  const { reset, ...form } = useForm<AddContactPointFieldValues>({
    resolver,
  });

  useEffect(() => {
    reset({ value: "" });
  }, [open, reset]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      aria-labelledby="add-correspondence-method-title"
    >
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        aria-label={t("add_correspondence_method.title_label")}
        noValidate
        method="POST"
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{t("add_correspondence_method.title_label")}</Box>
            <Box>
              <IconButton
                onClick={onClose}
                aria-label={t("common:close")}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          {contactPointTypes?.length === 0 && (
            <Alert severity="warning" icon={<Warning />}>
              <Box component="header" display="flex" alignItems="center">
                <Typography variant="body2">
                  {t(
                    "add_correspondence_method.no_type_available_warning_message",
                  )}
                </Typography>
              </Box>
            </Alert>
          )}

          <Grid container spacing={1}>
            <Grid item xs={5}>
              <ControlledTextField
                select
                id="type"
                name="type"
                defaultValue=""
                label={t("add_correspondence_method.type_label")}
                helperText={form.formState.errors?.type?.message}
                error={form.formState.errors?.type?.message}
                SelectProps={{ displayEmpty: true }}
                control={form.control}
              >
                <MenuItem value="">
                  {contactPointTypes?.length === 0 ? (
                    <i>No Available Types</i>
                  ) : (
                    <i>Please Select</i>
                  )}
                </MenuItem>

                {contactPointTypes?.map((contactPointType) => {
                  return (
                    <MenuItem value={contactPointType} key={contactPointType}>
                      {
                        ContactPointType[
                          Object.keys(ContactPointTypeModel).find(
                            (key) =>
                              ContactPointTypeModel[key as ModelKeys] ===
                              contactPointType,
                          ) as ModelKeys
                        ]
                      }
                    </MenuItem>
                  );
                })}
              </ControlledTextField>
            </Grid>
            <Grid item xs={7}>
              <TextField
                {...form.register("value")}
                error={Boolean(form.formState.errors.value)}
                helperText={form.formState.errors.value?.message}
                label={detailLabel}
              />
            </Grid>
          </Grid>

          {error?.message && (
            <Typography color="error" data-testid="form.error">
              {error.message}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {t("common:cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={
              form.formState.isSubmitting || contactPointTypes?.length === 0
            }
          >
            {t("add_correspondence_method.add_correspondence_method_button")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
