import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { UsersApi } from "generated/admin/apis";
import { ResetPasswordModel } from "generated/admin/models";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

const useResetPassword = (
  userId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const usersApi = useApi(UsersApi);
  const swr = useSWRConfig();

  const resetPassword = async (model: ResetPasswordModel) => {
    const response = await usersApi.resetPassword({
      userId,
      resetPasswordModel: model,
    });

    return response;
  };

  return useCommand(resetPassword, {
    ...options,
    onSuccess: () => {
      options.onSuccess?.();
      swr.mutate(keys.detail(userId));
    },
  });
};

export { useResetPassword };
