/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing secondary rentals within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SecondaryRentalDetailsModel,
} from '../models/index';
import {
    SecondaryRentalDetailsModelFromJSON,
    SecondaryRentalDetailsModelToJSON,
} from '../models/index';

export interface GetSecondaryRentalDetailsRequest {
    secondaryRentalId: number;
}

/**
 * 
 */
export class SecondaryRentalQueryApi extends runtime.BaseAPI {

    /**
     * Get secondary rental details
     */
    async getSecondaryRentalDetailsRaw(requestParameters: GetSecondaryRentalDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecondaryRentalDetailsModel>> {
        if (requestParameters.secondaryRentalId === null || requestParameters.secondaryRentalId === undefined) {
            throw new runtime.RequiredError('secondaryRentalId','Required parameter requestParameters.secondaryRentalId was null or undefined when calling getSecondaryRentalDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["secondary-rentals:view"]);
        }

        const response = await this.request({
            path: `/secondary-rentals/{secondaryRentalId}`.replace(`{${"secondaryRentalId"}}`, encodeURIComponent(String(requestParameters.secondaryRentalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecondaryRentalDetailsModelFromJSON(jsonValue));
    }

    /**
     * Get secondary rental details
     */
    async getSecondaryRentalDetails(requestParameters: GetSecondaryRentalDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecondaryRentalDetailsModel> {
        const response = await this.getSecondaryRentalDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
