import {
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface ChargeType {
  code: string;
  name: string;
  amount?: string;
  payeeDetails: string;
  typeCode: string;
  typeDescription: string;
  defaultPayee?: string;
}

interface ChargeListProps {
  data?: ChargeType[];
  loading: boolean;
  onSelectChargeType(chargeType: ChargeType | undefined): void;
  selected?: string;
}

export default function ChargeList({
  data,
  loading,
  onSelectChargeType,
  selected,
}: ChargeListProps): ReactElement {
  const { t } = useTranslation("agreements");
  const tableHeaders: string[] = [
    "",
    t("transactions.post_charge.charge_types.list.code_label"),
    t("transactions.post_charge.charge_types.list.charge_label"),
    t("transactions.post_charge.charge_types.list.amount_label"),
    t("transactions.post_charge.charge_types.list.type_label"),
  ];

  const handleSelectChargeType = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = event.target;
    const chargeType = data?.find((type) => type.code === value);
    onSelectChargeType(chargeType);
  };

  return (
    <>
      <RadioGroup
        aria-label={t(
          "transactions.post_charge.charge_types.radio_group_label",
        )}
        name="charge-types"
        onChange={handleSelectChargeType}
        value={selected}
      >
        <Table
          size="small"
          stickyHeader
          aria-label={t(
            "transactions.post_charge.charge_types.list.title_label",
          )}
        >
          <LoadableTableHead headings={tableHeaders} loading={loading} />
          <TableBody>
            {data?.map((chargeType) => (
              <TableRow key={chargeType.code}>
                <TableCell padding="checkbox">
                  <Radio value={chargeType.code} />
                </TableCell>
                <TableCell>{chargeType.code}</TableCell>
                <TableCell>{chargeType.name}</TableCell>
                <TableCell>{chargeType.amount}</TableCell>
                <TableCell>{chargeType.typeDescription}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </RadioGroup>
      {(data || []).length === 0 && <NoResults />}
    </>
  );
}
