import useQuery, {
  UseQueryOptions,
  UseQueryReturn,
} from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { TemplateApi } from "generated/documents/apis";
import { TemplateModel } from "generated/documents/models";
import { detail } from "./keys";

type UseGetTemplate = (
  templateId: string,
  options?: UseQueryOptions,
) => UseQueryReturn<TemplateModel>;

export const useGetTemplate: UseGetTemplate = (templateId, options = {}) => {
  const api = useApi(TemplateApi);

  return useQuery(
    templateId ? detail(templateId) : null,
    () => {
      return api.getTemplate({ templateId });
    },
    options,
  );
};
