import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { useListAvailableEquifaxInsightReports } from "api/report/equifax/listAvailableEquifaxInsightReports";
import { ControlledTextField, Loader } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Control, Resolver, useForm } from "support/react-hook-form";

export interface EquifaxInsightFormValues {
  reportId: string;
  equifaxInsightNumber: string;
}

interface EquifaxInsightFormDialogProps {
  error?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (formValues: EquifaxInsightFormValues) => void;
  ready: boolean;
  equifaxNumbers: (string | undefined)[];
  resolver: Resolver<EquifaxInsightFormValues>;
  title: string;
}

interface EquifaxReportingPeriodProps {
  error?: string;
  equifaxInsightNumber: string;
  isSubmitting: boolean;
  control: Control<EquifaxInsightFormValues>;
}

function EquifaxReportingPeriod({
  equifaxInsightNumber,
  error,
  isSubmitting,
  control,
}: EquifaxReportingPeriodProps): ReactElement {
  const { t } = useTranslation(["reporting"]);

  const equifaxReports =
    useListAvailableEquifaxInsightReports(equifaxInsightNumber);

  const reportPeriodLabel = (reportingDate: Date): string =>
    `${reportingDate.toLocaleString("default", {
      month: "short",
    })} - ${reportingDate.getFullYear()}`;

  return (
    <ControlledTextField
      helperText={error}
      SelectProps={{ displayEmpty: true }}
      error={error}
      control={control}
      id="reportId"
      name="reportId"
      label={t("report_dialog.equifax.reporting_period_label")}
      select
      disabled={isSubmitting}
    >
      <MenuItem key="please-select" value="">
        {t("common:please_select")}
      </MenuItem>
      {equifaxReports.data &&
        equifaxReports.data.map((report) => (
          <MenuItem key={report.id} value={report.id}>
            {reportPeriodLabel(report.createdDate)}
          </MenuItem>
        ))}
    </ControlledTextField>
  );
}

export default function EquifaxInsightFormDialog({
  error,
  open,
  onClose,
  onSubmit,
  ready,
  equifaxNumbers,
  resolver,
  title,
}: EquifaxInsightFormDialogProps): ReactElement {
  const { t } = useTranslation(["reporting"]);

  const form = useForm<EquifaxInsightFormValues>({
    defaultValues: {
      reportId: "",
      equifaxInsightNumber: "",
    },
    resolver,
  });

  const formWatch = form.watch();

  const handleCloseAlertDialog = (): void => {
    form.reset();
    onClose();
  };

  return (
    <Dialog
      onClose={handleCloseAlertDialog}
      aria-labelledby="equifax_insight-report-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <Loader
        ready={ready}
        render={(): ReactElement => (
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            aria-label={t("report_dialog.equifax.title")}
          >
            <DialogTitle id="equifax-report-dialog-title">
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{title}</Box>
                <Box>
                  <IconButton
                    aria-label={t("common:close")}
                    onClick={handleCloseAlertDialog}
                    size="small"
                  >
                    <Close />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              <ControlledTextField
                helperText={
                  form.formState.errors?.equifaxInsightNumber?.message
                }
                SelectProps={{ displayEmpty: true }}
                error={form.formState.errors?.equifaxInsightNumber?.message}
                control={form.control}
                id="equifaxInsightNumber"
                name="equifaxInsightNumber"
                label={t("report_dialog.equifax.equifax_insight_label")}
                select
                disabled={form.formState.isSubmitting}
              >
                <MenuItem key="please-select" value="">
                  {t("common:please_select")}
                </MenuItem>

                {equifaxNumbers.map((equifaxNumber) => (
                  <MenuItem key={equifaxNumber} value={equifaxNumber}>
                    {equifaxNumber}
                  </MenuItem>
                ))}
              </ControlledTextField>

              <EquifaxReportingPeriod
                equifaxInsightNumber={formWatch.equifaxInsightNumber}
                error={form.formState.errors?.reportId?.message}
                isSubmitting={form.formState.isSubmitting}
                control={form.control}
              />

              {error && <Typography color="error">{error}</Typography>}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleCloseAlertDialog}>
                {t("common:cancel")}
              </Button>
              <Button
                type="submit"
                disabled={form.formState.isSubmitting}
                variant="contained"
                color="primary"
              >
                {t("report_dialog.generate_button")}
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}
