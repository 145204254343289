import AdminLayout from "apps/admin/layouts/AdminLayout";
import UserDetailsView from "apps/admin/views/UserDetailsView";
import UsersView from "apps/admin/views/UsersView";
import { useEnvironment } from "common/providers/EnvironmentProvider";
import { withAppAccess } from "common/security/withAppAccess";
import PageNotFoundView from "common/views/PageNotFoundView";
import { compose } from "lodash/fp";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteAdapter } from "sdk/router/RouteAdapter";
import { withTranslationLoader } from "sdk/views";
import AutomationDetailsView from "./views/AutomationDetailsView/AutomationDetailsView";
import AutomationsView from "./views/AutomationsView/AutomationsView";
import CreateAutomationView from "./views/CreateAutomationView/CreateAutomationView";
import EditAutomationView from "./views/EditAutomationView/EditAutomationView";
import RoleDetailsView from "./views/RoleDetailsView/RoleDetailsView";
import RolesView from "./views/RolesView/RolesView";

const App = () => {
  const environment = useEnvironment();
  return (
    <Routes>
      <Route path="*" element={<PageNotFoundView />} />
      <Route index element={<Navigate to="users" />} />
      <Route path="*" element={<AdminLayout />}>
        <Route path="users" element={<UsersView />} />
        <Route
          path="users/:userId"
          element={
            <RouteAdapter
              params={["userId"]}
              render={(route) => (
                <UserDetailsView userId={route.params.userId} />
              )}
            />
          }
        />

        {environment.isFeatureEnabled("automations") && (
          <>
            <Route index path="automations" element={<AutomationsView />} />
            <Route
              path="automations/create-automation"
              element={<CreateAutomationView />}
            />
            <Route
              path="automations/:automationId"
              element={
                <RouteAdapter
                  params={["automationId"]}
                  render={(route) => (
                    <AutomationDetailsView
                      automationId={route.params.automationId}
                    />
                  )}
                />
              }
            />
            <Route
              path="automations/:automationId/edit-automation"
              element={
                <RouteAdapter
                  params={["automationId"]}
                  render={(route) => (
                    <EditAutomationView
                      automationId={route.params.automationId}
                    />
                  )}
                />
              }
            />
          </>
        )}

        <Route index path="roles" element={<RolesView />} />
        <Route
          path="roles/:roleId"
          element={
            <RouteAdapter
              params={["roleId"]}
              render={(route) => (
                <RoleDetailsView roleId={route.params.roleId} />
              )}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default compose(
  withTranslationLoader(["admin", "users", "automations"]),
  withAppAccess("admin"),
)(App);
