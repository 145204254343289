import { useQuery } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ChargeTypeApi } from "generated/servicing-v2/apis";
import { keys } from ".";

export const useListChargeTypeHistory = (id: string) => {
  const api = useApi(ChargeTypeApi, "v2");

  return useQuery(keys.list(id), () => api.listChargeTypeHistory({ id }));
};
