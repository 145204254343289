/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The fees to be marked as paid
 * @export
 * @interface PayFeesModel
 */
export interface PayFeesModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof PayFeesModel
     */
    transactions: Array<number>;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PayFeesModel
     */
    paidDate: Date;
}

/**
 * Check if a given object implements the PayFeesModel interface.
 */
export function instanceOfPayFeesModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transactions" in value;
    isInstance = isInstance && "paidDate" in value;

    return isInstance;
}

export function PayFeesModelFromJSON(json: any): PayFeesModel {
    return PayFeesModelFromJSONTyped(json, false);
}

export function PayFeesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayFeesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactions': json['transactions'],
        'paidDate': (new Date(json['paidDate'])),
    };
}

export function PayFeesModelToJSON(value?: PayFeesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactions': value.transactions,
        'paidDate': (value.paidDate.toISOString().substring(0,10)),
    };
}

