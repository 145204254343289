import * as agreementKeys from "api/agreements/keys";

export const all = (agreementId: number) => [
  ...agreementKeys.detail(agreementId),
  "transactions",
];

export const list = (agreementId: number) => [
  all(agreementId),
  "transactions",
  "list",
];
