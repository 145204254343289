/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgreementFeeModel } from './AgreementFeeModel';
import {
    AgreementFeeModelFromJSON,
    AgreementFeeModelFromJSONTyped,
    AgreementFeeModelToJSON,
} from './AgreementFeeModel';
import type { AgreementInstalmentModel } from './AgreementInstalmentModel';
import {
    AgreementInstalmentModelFromJSON,
    AgreementInstalmentModelFromJSONTyped,
    AgreementInstalmentModelToJSON,
} from './AgreementInstalmentModel';
import type { AssetModel } from './AssetModel';
import {
    AssetModelFromJSON,
    AssetModelFromJSONTyped,
    AssetModelToJSON,
} from './AssetModel';
import type { CustomerDetailsModel } from './CustomerDetailsModel';
import {
    CustomerDetailsModelFromJSON,
    CustomerDetailsModelFromJSONTyped,
    CustomerDetailsModelToJSON,
} from './CustomerDetailsModel';
import type { FeesAndCommissionsModel } from './FeesAndCommissionsModel';
import {
    FeesAndCommissionsModelFromJSON,
    FeesAndCommissionsModelFromJSONTyped,
    FeesAndCommissionsModelToJSON,
} from './FeesAndCommissionsModel';
import type { FrequencyOfInstalmentsModel } from './FrequencyOfInstalmentsModel';
import {
    FrequencyOfInstalmentsModelFromJSON,
    FrequencyOfInstalmentsModelFromJSONTyped,
    FrequencyOfInstalmentsModelToJSON,
} from './FrequencyOfInstalmentsModel';
import type { OnboardingStatusModel } from './OnboardingStatusModel';
import {
    OnboardingStatusModelFromJSON,
    OnboardingStatusModelFromJSONTyped,
    OnboardingStatusModelToJSON,
} from './OnboardingStatusModel';
import type { PaymentStructureModel } from './PaymentStructureModel';
import {
    PaymentStructureModelFromJSON,
    PaymentStructureModelFromJSONTyped,
    PaymentStructureModelToJSON,
} from './PaymentStructureModel';
import type { ProductTypeModel } from './ProductTypeModel';
import {
    ProductTypeModelFromJSON,
    ProductTypeModelFromJSONTyped,
    ProductTypeModelToJSON,
} from './ProductTypeModel';
import type { SeasonalPaymentStructureModel } from './SeasonalPaymentStructureModel';
import {
    SeasonalPaymentStructureModelFromJSON,
    SeasonalPaymentStructureModelFromJSONTyped,
    SeasonalPaymentStructureModelToJSON,
} from './SeasonalPaymentStructureModel';
import type { TermModel } from './TermModel';
import {
    TermModelFromJSON,
    TermModelFromJSONTyped,
    TermModelToJSON,
} from './TermModel';

/**
 * 
 * @export
 * @interface OnboardingSummaryModel
 */
export interface OnboardingSummaryModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof OnboardingSummaryModel
     */
    readonly id: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    totalCashPriceNet: string;
    /**
     * Total number of instalments
     * @type {number}
     * @memberof OnboardingSummaryModel
     */
    totalNumberOfInstalments: number;
    /**
     * The term of the agreement in months
     * @type {number}
     * @memberof OnboardingSummaryModel
     */
    term: number;
    /**
     * 
     * @type {FrequencyOfInstalmentsModel}
     * @memberof OnboardingSummaryModel
     */
    frequencyOfInstalments: FrequencyOfInstalmentsModel;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    instalmentAmount: string;
    /**
     * The actual interest rate percentage
     * @type {number}
     * @memberof OnboardingSummaryModel
     */
    interestRate: number;
    /**
     * The interest rate that was used before the actual rate was calculated
     * @type {number}
     * @memberof OnboardingSummaryModel
     */
    customerInterestRate?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    advancedPayment?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    balloonPayment?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    residualValue?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    totalBalancePayable: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    totalCostOfCredit: string;
    /**
     * 
     * @type {Array<AgreementInstalmentModel>}
     * @memberof OnboardingSummaryModel
     */
    instalments: Array<AgreementInstalmentModel>;
    /**
     * 
     * @type {PaymentStructureModel}
     * @memberof OnboardingSummaryModel
     */
    paymentStructure: PaymentStructureModel;
    /**
     * 
     * @type {Array<SeasonalPaymentStructureModel>}
     * @memberof OnboardingSummaryModel
     */
    seasonalPaymentStructure?: Array<SeasonalPaymentStructureModel>;
    /**
     * A read only Id
     * @type {number}
     * @memberof OnboardingSummaryModel
     */
    readonly portfolioId?: number;
    /**
     * 
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    productName?: string;
    /**
     * 
     * @type {ProductTypeModel}
     * @memberof OnboardingSummaryModel
     */
    productType?: ProductTypeModel;
    /**
     * 
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    maintainerName?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    maintenanceAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    maintenanceCost?: string;
    /**
     * Int value representing the VAT reclaim month (1 to 13)
     * @type {number}
     * @memberof OnboardingSummaryModel
     */
    vatReclaimMonth?: number;
    /**
     * denotes whether the agreement is funding VAT
     * @type {boolean}
     * @memberof OnboardingSummaryModel
     */
    isFundedVat?: boolean;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    fundedVatAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    deposit?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    partExchange?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    blindDiscount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    subsidyPayment?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    fleetDiscount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    arrangementFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    valuationFee?: string;
    /**
     * 
     * @type {TermModel}
     * @memberof OnboardingSummaryModel
     */
    typeOfLease?: TermModel;
    /**
     * 
     * @type {FeesAndCommissionsModel}
     * @memberof OnboardingSummaryModel
     */
    feesAndCommissions?: FeesAndCommissionsModel;
    /**
     * A format for dates
     * @type {Date}
     * @memberof OnboardingSummaryModel
     */
    inceptionDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof OnboardingSummaryModel
     */
    firstRepaymentDate?: Date;
    /**
     * 
     * @type {Array<AssetModel>}
     * @memberof OnboardingSummaryModel
     */
    assets?: Array<AssetModel>;
    /**
     * 
     * @type {Array<AgreementFeeModel>}
     * @memberof OnboardingSummaryModel
     */
    fees?: Array<AgreementFeeModel>;
    /**
     * 
     * @type {OnboardingStatusModel}
     * @memberof OnboardingSummaryModel
     */
    statusType?: OnboardingStatusModel;
    /**
     * The agreement number for the completed onboarding
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    agreementNumber?: string;
    /**
     * 
     * @type {CustomerDetailsModel}
     * @memberof OnboardingSummaryModel
     */
    customer?: CustomerDetailsModel;
    /**
     * The name of the existing customer
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    existingCustomerName?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    fundingLine?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof OnboardingSummaryModel
     */
    secondaryRentalStartDate?: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    secondaryRentalAmount?: string;
    /**
     * 
     * @type {FrequencyOfInstalmentsModel}
     * @memberof OnboardingSummaryModel
     */
    secondaryRentalFrequency?: FrequencyOfInstalmentsModel;
    /**
     * The introducer share percentage
     * @type {number}
     * @memberof OnboardingSummaryModel
     */
    secondaryRentalIntroducerShare?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    secondaryRentalMaintenanceCharge?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingSummaryModel
     */
    secondaryRentalInsurancePremium?: string;
}

/**
 * Check if a given object implements the OnboardingSummaryModel interface.
 */
export function instanceOfOnboardingSummaryModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "totalCashPriceNet" in value;
    isInstance = isInstance && "totalNumberOfInstalments" in value;
    isInstance = isInstance && "term" in value;
    isInstance = isInstance && "frequencyOfInstalments" in value;
    isInstance = isInstance && "instalmentAmount" in value;
    isInstance = isInstance && "interestRate" in value;
    isInstance = isInstance && "totalBalancePayable" in value;
    isInstance = isInstance && "totalCostOfCredit" in value;
    isInstance = isInstance && "instalments" in value;
    isInstance = isInstance && "paymentStructure" in value;

    return isInstance;
}

export function OnboardingSummaryModelFromJSON(json: any): OnboardingSummaryModel {
    return OnboardingSummaryModelFromJSONTyped(json, false);
}

export function OnboardingSummaryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingSummaryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'totalCashPriceNet': json['totalCashPriceNet'],
        'totalNumberOfInstalments': json['totalNumberOfInstalments'],
        'term': json['term'],
        'frequencyOfInstalments': FrequencyOfInstalmentsModelFromJSON(json['frequencyOfInstalments']),
        'instalmentAmount': json['instalmentAmount'],
        'interestRate': json['interestRate'],
        'customerInterestRate': !exists(json, 'customerInterestRate') ? undefined : json['customerInterestRate'],
        'advancedPayment': !exists(json, 'advancedPayment') ? undefined : json['advancedPayment'],
        'balloonPayment': !exists(json, 'balloonPayment') ? undefined : json['balloonPayment'],
        'residualValue': !exists(json, 'residualValue') ? undefined : json['residualValue'],
        'totalBalancePayable': json['totalBalancePayable'],
        'totalCostOfCredit': json['totalCostOfCredit'],
        'instalments': ((json['instalments'] as Array<any>).map(AgreementInstalmentModelFromJSON)),
        'paymentStructure': PaymentStructureModelFromJSON(json['paymentStructure']),
        'seasonalPaymentStructure': !exists(json, 'seasonalPaymentStructure') ? undefined : ((json['seasonalPaymentStructure'] as Array<any>).map(SeasonalPaymentStructureModelFromJSON)),
        'portfolioId': !exists(json, 'portfolioId') ? undefined : json['portfolioId'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'productType': !exists(json, 'productType') ? undefined : ProductTypeModelFromJSON(json['productType']),
        'maintainerName': !exists(json, 'maintainerName') ? undefined : json['maintainerName'],
        'maintenanceAmount': !exists(json, 'maintenanceAmount') ? undefined : json['maintenanceAmount'],
        'maintenanceCost': !exists(json, 'maintenanceCost') ? undefined : json['maintenanceCost'],
        'vatReclaimMonth': !exists(json, 'vatReclaimMonth') ? undefined : json['vatReclaimMonth'],
        'isFundedVat': !exists(json, 'isFundedVat') ? undefined : json['isFundedVat'],
        'fundedVatAmount': !exists(json, 'fundedVatAmount') ? undefined : json['fundedVatAmount'],
        'deposit': !exists(json, 'deposit') ? undefined : json['deposit'],
        'partExchange': !exists(json, 'partExchange') ? undefined : json['partExchange'],
        'blindDiscount': !exists(json, 'blindDiscount') ? undefined : json['blindDiscount'],
        'subsidyPayment': !exists(json, 'subsidyPayment') ? undefined : json['subsidyPayment'],
        'fleetDiscount': !exists(json, 'fleetDiscount') ? undefined : json['fleetDiscount'],
        'arrangementFee': !exists(json, 'arrangementFee') ? undefined : json['arrangementFee'],
        'valuationFee': !exists(json, 'valuationFee') ? undefined : json['valuationFee'],
        'typeOfLease': !exists(json, 'typeOfLease') ? undefined : TermModelFromJSON(json['typeOfLease']),
        'feesAndCommissions': !exists(json, 'feesAndCommissions') ? undefined : FeesAndCommissionsModelFromJSON(json['feesAndCommissions']),
        'inceptionDate': !exists(json, 'inceptionDate') ? undefined : (new Date(json['inceptionDate'])),
        'firstRepaymentDate': !exists(json, 'firstRepaymentDate') ? undefined : (new Date(json['firstRepaymentDate'])),
        'assets': !exists(json, 'assets') ? undefined : ((json['assets'] as Array<any>).map(AssetModelFromJSON)),
        'fees': !exists(json, 'fees') ? undefined : ((json['fees'] as Array<any>).map(AgreementFeeModelFromJSON)),
        'statusType': !exists(json, 'statusType') ? undefined : OnboardingStatusModelFromJSON(json['statusType']),
        'agreementNumber': !exists(json, 'agreementNumber') ? undefined : json['agreementNumber'],
        'customer': !exists(json, 'customer') ? undefined : CustomerDetailsModelFromJSON(json['customer']),
        'existingCustomerName': !exists(json, 'existingCustomerName') ? undefined : json['existingCustomerName'],
        'fundingLine': !exists(json, 'fundingLine') ? undefined : json['fundingLine'],
        'secondaryRentalStartDate': !exists(json, 'secondaryRentalStartDate') ? undefined : (new Date(json['secondaryRentalStartDate'])),
        'secondaryRentalAmount': !exists(json, 'secondaryRentalAmount') ? undefined : json['secondaryRentalAmount'],
        'secondaryRentalFrequency': !exists(json, 'secondaryRentalFrequency') ? undefined : FrequencyOfInstalmentsModelFromJSON(json['secondaryRentalFrequency']),
        'secondaryRentalIntroducerShare': !exists(json, 'secondaryRentalIntroducerShare') ? undefined : json['secondaryRentalIntroducerShare'],
        'secondaryRentalMaintenanceCharge': !exists(json, 'secondaryRentalMaintenanceCharge') ? undefined : json['secondaryRentalMaintenanceCharge'],
        'secondaryRentalInsurancePremium': !exists(json, 'secondaryRentalInsurancePremium') ? undefined : json['secondaryRentalInsurancePremium'],
    };
}

export function OnboardingSummaryModelToJSON(value?: OnboardingSummaryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCashPriceNet': value.totalCashPriceNet,
        'totalNumberOfInstalments': value.totalNumberOfInstalments,
        'term': value.term,
        'frequencyOfInstalments': FrequencyOfInstalmentsModelToJSON(value.frequencyOfInstalments),
        'instalmentAmount': value.instalmentAmount,
        'interestRate': value.interestRate,
        'customerInterestRate': value.customerInterestRate,
        'advancedPayment': value.advancedPayment,
        'balloonPayment': value.balloonPayment,
        'residualValue': value.residualValue,
        'totalBalancePayable': value.totalBalancePayable,
        'totalCostOfCredit': value.totalCostOfCredit,
        'instalments': ((value.instalments as Array<any>).map(AgreementInstalmentModelToJSON)),
        'paymentStructure': PaymentStructureModelToJSON(value.paymentStructure),
        'seasonalPaymentStructure': value.seasonalPaymentStructure === undefined ? undefined : ((value.seasonalPaymentStructure as Array<any>).map(SeasonalPaymentStructureModelToJSON)),
        'productName': value.productName,
        'productType': ProductTypeModelToJSON(value.productType),
        'maintainerName': value.maintainerName,
        'maintenanceAmount': value.maintenanceAmount,
        'maintenanceCost': value.maintenanceCost,
        'vatReclaimMonth': value.vatReclaimMonth,
        'isFundedVat': value.isFundedVat,
        'fundedVatAmount': value.fundedVatAmount,
        'deposit': value.deposit,
        'partExchange': value.partExchange,
        'blindDiscount': value.blindDiscount,
        'subsidyPayment': value.subsidyPayment,
        'fleetDiscount': value.fleetDiscount,
        'arrangementFee': value.arrangementFee,
        'valuationFee': value.valuationFee,
        'typeOfLease': TermModelToJSON(value.typeOfLease),
        'feesAndCommissions': FeesAndCommissionsModelToJSON(value.feesAndCommissions),
        'inceptionDate': value.inceptionDate === undefined ? undefined : (value.inceptionDate.toISOString().substring(0,10)),
        'firstRepaymentDate': value.firstRepaymentDate === undefined ? undefined : (value.firstRepaymentDate.toISOString().substring(0,10)),
        'assets': value.assets === undefined ? undefined : ((value.assets as Array<any>).map(AssetModelToJSON)),
        'fees': value.fees === undefined ? undefined : ((value.fees as Array<any>).map(AgreementFeeModelToJSON)),
        'statusType': OnboardingStatusModelToJSON(value.statusType),
        'agreementNumber': value.agreementNumber,
        'customer': CustomerDetailsModelToJSON(value.customer),
        'existingCustomerName': value.existingCustomerName,
        'fundingLine': value.fundingLine,
        'secondaryRentalStartDate': value.secondaryRentalStartDate === undefined ? undefined : (value.secondaryRentalStartDate.toISOString().substring(0,10)),
        'secondaryRentalAmount': value.secondaryRentalAmount,
        'secondaryRentalFrequency': FrequencyOfInstalmentsModelToJSON(value.secondaryRentalFrequency),
        'secondaryRentalIntroducerShare': value.secondaryRentalIntroducerShare,
        'secondaryRentalMaintenanceCharge': value.secondaryRentalMaintenanceCharge,
        'secondaryRentalInsurancePremium': value.secondaryRentalInsurancePremium,
    };
}

