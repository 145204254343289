/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OnboardingStatusModel } from './OnboardingStatusModel';
import {
    OnboardingStatusModelFromJSON,
    OnboardingStatusModelFromJSONTyped,
    OnboardingStatusModelToJSON,
} from './OnboardingStatusModel';

/**
 * 
 * @export
 * @interface OnboardingListItemModel
 */
export interface OnboardingListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof OnboardingListItemModel
     */
    readonly id?: number;
    /**
     * A read only Id
     * @type {number}
     * @memberof OnboardingListItemModel
     */
    readonly agreementId?: number;
    /**
     * A read only Id
     * @type {number}
     * @memberof OnboardingListItemModel
     */
    readonly customerId?: number;
    /**
     * The agreement number for the completed onboarding
     * @type {string}
     * @memberof OnboardingListItemModel
     */
    agreementNumber?: string;
    /**
     * Customer name
     * @type {string}
     * @memberof OnboardingListItemModel
     */
    name?: string;
    /**
     * Company or trading name
     * @type {string}
     * @memberof OnboardingListItemModel
     */
    companyName?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof OnboardingListItemModel
     */
    totalAmountRepayable: string;
    /**
     * The user name of the person who created the onboarding
     * @type {string}
     * @memberof OnboardingListItemModel
     */
    createdBy: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof OnboardingListItemModel
     */
    createdAt: Date;
    /**
     * 
     * @type {OnboardingStatusModel}
     * @memberof OnboardingListItemModel
     */
    statusType: OnboardingStatusModel;
}

/**
 * Check if a given object implements the OnboardingListItemModel interface.
 */
export function instanceOfOnboardingListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalAmountRepayable" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "statusType" in value;

    return isInstance;
}

export function OnboardingListItemModelFromJSON(json: any): OnboardingListItemModel {
    return OnboardingListItemModelFromJSONTyped(json, false);
}

export function OnboardingListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'agreementId': !exists(json, 'agreementId') ? undefined : json['agreementId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'agreementNumber': !exists(json, 'agreementNumber') ? undefined : json['agreementNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'totalAmountRepayable': json['totalAmountRepayable'],
        'createdBy': json['createdBy'],
        'createdAt': (new Date(json['createdAt'])),
        'statusType': OnboardingStatusModelFromJSON(json['statusType']),
    };
}

export function OnboardingListItemModelToJSON(value?: OnboardingListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreementNumber': value.agreementNumber,
        'name': value.name,
        'companyName': value.companyName,
        'totalAmountRepayable': value.totalAmountRepayable,
        'createdBy': value.createdBy,
        'createdAt': (value.createdAt.toISOString().substring(0,10)),
        'statusType': OnboardingStatusModelToJSON(value.statusType),
    };
}

