/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Financial Postings within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddFinancialPostingModel
 */
export interface AddFinancialPostingModel {
    /**
     * The code of the financial stage
     * @type {string}
     * @memberof AddFinancialPostingModel
     */
    stageCode: string;
    /**
     * The asset ID
     * @type {number}
     * @memberof AddFinancialPostingModel
     */
    assetId?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AddFinancialPostingModel
     */
    amount: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AddFinancialPostingModel
     */
    financialDate: Date;
    /**
     * The agreement ID
     * @type {number}
     * @memberof AddFinancialPostingModel
     */
    agreementId: number;
}

/**
 * Check if a given object implements the AddFinancialPostingModel interface.
 */
export function instanceOfAddFinancialPostingModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stageCode" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "financialDate" in value;
    isInstance = isInstance && "agreementId" in value;

    return isInstance;
}

export function AddFinancialPostingModelFromJSON(json: any): AddFinancialPostingModel {
    return AddFinancialPostingModelFromJSONTyped(json, false);
}

export function AddFinancialPostingModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddFinancialPostingModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stageCode': json['stageCode'],
        'assetId': !exists(json, 'assetId') ? undefined : json['assetId'],
        'amount': json['amount'],
        'financialDate': (new Date(json['financialDate'])),
        'agreementId': json['agreementId'],
    };
}

export function AddFinancialPostingModelToJSON(value?: AddFinancialPostingModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stageCode': value.stageCode,
        'assetId': value.assetId,
        'amount': value.amount,
        'financialDate': (value.financialDate.toISOString().substring(0,10)),
        'agreementId': value.agreementId,
    };
}

