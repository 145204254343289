import CaisReportFormDialog, {
  CaisReportFormValues,
} from "apps/reporting/components/CaisReportFormDialog";
import { useReportDownload } from "apps/reporting/hooks";
import { useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { ApiResponse } from "generated";
import { ReportQueryApi } from "generated/core/apis";
import { CaisReportListItemModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

interface CaisReportProps {
  onClose(): void;
  open: boolean;
}

export default function CaisReportContainer({
  onClose,
  open,
}: CaisReportProps): ReactElement {
  const { t } = useTranslation("reporting");
  const reportQueryApi = useApi(ReportQueryApi);
  const reports = useSWR(["reports"], () =>
    reportQueryApi.availableCaisReports(),
  );

  const caisReportApi = async (
    data: CaisReportFormValues,
  ): Promise<ApiResponse<string>> =>
    reportQueryApi.caisRaw({
      reportId: Number(data.report),
    });

  const caisReportDownload = useReportDownload(caisReportApi, {
    onSuccess: onClose,
  });

  const resolver = useYupResolver<CaisReportFormValues>((yup) =>
    yup.object().shape({
      report: yup
        .string()
        .isRequired(t("report_dialog.cais.reporting_period_label")),
    }),
  );

  const onSubmit = async (data: CaisReportFormValues): Promise<void> =>
    caisReportDownload.download(data);

  const reportPeriodLabel = (report: CaisReportListItemModel): string =>
    `${report.createdAt.toLocaleString("default", {
      month: "short",
    })} - ${report.createdAt.getFullYear()}`;

  return (
    <CaisReportFormDialog
      title={t("report_dialog.cais.title")}
      error={caisReportDownload.error?.message}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      reports={(reports.data || []).map((report) => ({
        label: reportPeriodLabel(report),
        value: String(report.id),
      }))}
      ready={Boolean(reports.data) && !caisReportDownload.isLoading}
      resolver={resolver}
    />
  );
}
