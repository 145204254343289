import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import {
  AddBankAccount,
  BankAccounts as BankAccountsContainer,
} from "apps/servicing/modules/customers/containers";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import useModal from "common/hooks/useModal";
import { ContainedLayout } from "common/layouts";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

function AddBankAccountAction() {
  const customer = useCustomer();
  const modal = useModal();

  return (
    <>
      <AddButton variant="contained" onClick={modal.open} />
      <AddBankAccount
        customerId={customer.data.systemId}
        open={modal.isOpen}
        onSubmit={modal.close}
        onClose={modal.close}
      />
    </>
  );
}

export default function BankAccountsView() {
  const { t } = useTranslation("customers");

  return (
    <ViewLayout
      title={t("bank_accounts.heading")}
      action={
        <AccessControl allowedPermissions={["servicing:bank-accounts:manage"]}>
          <AddBankAccountAction />
        </AccessControl>
      }
    >
      <FixedLayout>
        <ContainedLayout>
          <BankAccountsContainer />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}
