import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface FinancialDetail {
  totalCashPrice: string;
  vatOnCashPrice: string;
  totalNetCashPrice: string;
  cashDeposit: string;
  partExchange: string;
  totalDeposit: string;
  balloonPayment: string;
  balanceFinanced: string;
  residualValue: string;
  residualPercentage: string;
  funderIrr: string;
  customerIrr: string;
  blindDiscount: string;
  fleetDiscount: string;
  vatReclaimMonth?: number;
  subsidyPayment: string;
  subsidyDate?: Date;
}

interface FinancialDetailsProps {
  financialDetails?: FinancialDetail;
}

const createDateFormatter =
  (format: (date: Date) => string) =>
  (date?: Date): string =>
    date ? format(date) : "-";

export default function FinancialDetails({
  financialDetails,
}: FinancialDetailsProps): ReactElement {
  const { t } = useTranslation("agreements");
  const { formatAmount, formatNumber } = useFormat();
  const locale = useLocale();
  const formatDate = createDateFormatter(locale.formatDate);

  return (
    <DescriptionList label={t("financial_details.heading")}>
      <DescriptionList.Item
        label={t("financial_details.total_cash_price_net_vat")}
      >
        {formatAmount(financialDetails?.totalNetCashPrice)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.vat_cash_price")}>
        {formatAmount(financialDetails?.vatOnCashPrice)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.total_cash_price")}>
        {formatAmount(financialDetails?.totalCashPrice)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.vat_reclaim_month")}>
        {formatNumber(financialDetails?.vatReclaimMonth)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.cash_deposit")}>
        {formatAmount(financialDetails?.cashDeposit)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.part_exchange")}>
        {formatAmount(financialDetails?.partExchange)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.total_deposit")}>
        {formatAmount(financialDetails?.totalDeposit)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.balloon_payment")}>
        {formatAmount(financialDetails?.balloonPayment)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.balance_financed")}>
        {formatAmount(financialDetails?.balanceFinanced)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.residual_value")}>
        {formatAmount(financialDetails?.residualValue)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.residual_percentage")}>
        {`${financialDetails?.residualPercentage}%`}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.funder_irr")}>
        {`${financialDetails?.funderIrr}%`}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.customer_irr")}>
        {`${financialDetails?.customerIrr}%`}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.blind_discount")}>
        {formatAmount(financialDetails?.blindDiscount)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.fleet_discount")}>
        {formatAmount(financialDetails?.fleetDiscount)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.subsidy_payment")}>
        {formatAmount(financialDetails?.subsidyPayment)}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("financial_details.subsidy_date")}>
        {formatDate(financialDetails?.subsidyDate)}
      </DescriptionList.Item>
    </DescriptionList>
  );
}
