import { AccessControl, useSession } from "@ldms/mui-sdk/bootstrap";
import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import UpdateValuationContainer from "apps/servicing/containers/UpdateValuationContainer";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface Valuations {
  id: number;
  valuationDate: Date;
  value: string;
  completedBy?: string;
  source?: string;
}

interface ValuationsListProps {
  data: Valuations[];
  isValidating: boolean;
  assetId: number;
  isModifiable: boolean;
}

export default function ValuationsList({
  data,
  isValidating,
  assetId,
  isModifiable,
}: ValuationsListProps): ReactElement {
  const { t } = useTranslation("assets");
  const locale = useLocale();
  const { formatAmount } = useFormat();

  const tableHeaders: string[] = [
    t("valuations.list.date_label"),
    t("valuations.list.value_label"),
    t("valuations.list.completed_by_label"),
    t("valuations.list.source_label"),
    "",
  ];

  const session = useSession();

  return (
    <TableContainer>
      <Table aria-label={t("valuations.list.table_name_label")} size="small">
        <LoadableTableHead headings={tableHeaders} loading={isValidating} />

        <TableBody>
          {data.map((valuation) => (
            <TableRow key={valuation.id} hover>
              <TableCell>
                <Typography>
                  {valuation.valuationDate &&
                    locale.formatDate(valuation.valuationDate)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{formatAmount(valuation.value)}</Typography>
              </TableCell>
              <TableCell>{valuation.completedBy ?? "-"}</TableCell>
              <TableCell>{valuation.source ?? "-"}</TableCell>
              <TableCell>
                {isModifiable &&
                  session.hasPermission("servicing:valuations:manage") && (
                    <AccessControl
                      allowedPermissions={["servicing:valuations:manage"]}
                    >
                      <UpdateValuationContainer
                        data={valuation}
                        valuationId={valuation.id}
                        assetId={assetId}
                      />
                    </AccessControl>
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data?.length === 0 && (
        <NoResults message={t("valuations.no_valuations")} />
      )}
    </TableContainer>
  );
}
