import { Session, SessionContext } from "@ldms/mui-sdk/bootstrap";
import {
  Box,
  Container,
  Fade,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ProfileApi } from "generated/gateway/apis/ProfileApi";
import { ProfileModel } from "generated/gateway/models/ProfileModel";
import { Configuration, ResponseError } from "generated/gateway/runtime";
import { useMemo } from "react";
import useSWR, { SWRConfiguration } from "swr";

const LoadingApplication: React.FC = () => {
  return (
    <Box mt={8}>
      <Container maxWidth="sm" sx={{ height: "100vh" }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6" align="center">
              Loading Application...
            </Typography>
          </Grid>
          <Grid item>
            <LinearProgress />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const useGetProfile = (config: SWRConfiguration = {}) => {
  const profileApi = new ProfileApi(
    new Configuration({
      basePath: "/api",
      credentials: "include",
    }),
  );

  return useSWR<ProfileModel, ResponseError>(
    "/me",
    () => profileApi.getProfile(),
    config,
  );
};

const SessionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const profile = useGetProfile();

  const value = useMemo(
    () => ({
      data: profile.data && {
        ...profile.data,
        permissions: new Set(profile.data?.permissions),
      },
      isValidating: profile.isValidating,
      revalidate: async () => {
        await profile.mutate();
      },
    }),
    [profile],
  );

  if (!value.data && !profile.error) {
    return (
      <Fade in style={{ transitionDelay: "500ms" }}>
        <div>
          <LoadingApplication />
        </div>
      </Fade>
    );
  }

  if (profile.error && profile.error.response.status !== 401) {
    return <Typography>Error</Typography>;
  }

  if (!value.data) {
    return null;
  }

  return (
    <SessionContext.Provider value={value as Session}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
