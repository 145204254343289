import { Loader } from "@ldms/mui-sdk/templates";
import { Box, DialogContent, Divider, Paper, Tab, Tabs } from "@mui/material";
import { useListPortfolios } from "api/documents/templates/portfolios/listPortfolios";
import LettersToEmailListContainer from "apps/documents/containers/LettersToEmailListContainer";
import { QueryError } from "common/components";
import { DeliveryStatusModel } from "generated/documents/models";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <DialogContent
      hidden={value !== index}
      role="tabpanel"
      id={`letters-to-email-tabpanel-${index}`}
      sx={{ paddingY: 0, paddingX: 0, height: "100%" }}
      aria-labelledby={`letters-to-email-tab-${index}`}
      {...other}
    >
      <Box overflow="hidden" height="100%">
        {value === index && children}
      </Box>
    </DialogContent>
  );
}

const a11yProps = (index: number) => ({
  id: `letters-to-email-tab-${index}`,
  "aria-controls": `letters-to-email-tabpanel-${index}`,
});

export default function LettersToEmailContainer(): ReactElement {
  const { t } = useTranslation(["documents"]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const portfolios = useListPortfolios();

  const displayPending = portfolios.data
    ?.map((portfolio) => portfolio.systemLetterVerificationEnabled)
    .includes(true);

  const tabIndex = displayPending ? 1 : 0;

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", maxHeight: "100%" }}>
      <Loader
        ready={Boolean(portfolios.data || portfolios.error)}
        render={(): ReactElement => {
          if (portfolios.error || !portfolios.data) {
            return <QueryError onRetry={portfolios.refetch} />;
          }
          return (
            <Box
              height="100%"
              display="grid"
              overflow="hidden"
              gridTemplateRows="min-content min-content 1fr"
            >
              <Tabs value={value} onChange={handleChange}>
                {displayPending && (
                  <Tab
                    label={t("letters_to_email.pending_verification_tab")}
                    {...a11yProps(0)}
                  />
                )}
                <Tab
                  label={t("letters_to_email.sent_tab")}
                  {...a11yProps(tabIndex)}
                />
                <Tab
                  label={t("letters_to_email.failed_tab")}
                  {...a11yProps(tabIndex + 1)}
                />
              </Tabs>

              <Divider />

              <Box maxHeight="100%" overflow="hidden">
                {displayPending && (
                  <TabPanel value={value} index={0}>
                    <LettersToEmailListContainer
                      status={DeliveryStatusModel.Pending}
                    />
                  </TabPanel>
                )}
                <TabPanel value={value} index={tabIndex}>
                  <LettersToEmailListContainer
                    status={DeliveryStatusModel.Sent}
                  />
                </TabPanel>
                <TabPanel value={value} index={tabIndex + 1}>
                  <LettersToEmailListContainer
                    status={DeliveryStatusModel.Failed}
                  />
                </TabPanel>
              </Box>
            </Box>
          );
        }}
      />
    </Paper>
  );
}
