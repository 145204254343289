import {
  DateRangeControl,
  PortfolioReportFormDialog,
} from "apps/reporting/components";
import { usePortfolioDateReportResolver } from "apps/reporting/hooks";
import { PortfolioDateReportDialogFormValues } from "apps/reporting/types";
import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { format, startOfToday } from "date-fns";
import { PortfolioQueryApi, ReportQueryApi } from "generated/core/apis";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";
import fileDownloader from "support/file-downloader";
import useSWR from "swr";

interface AgreementSetUpReportContainerProps {
  onClose(): void;
  open: boolean;
}

export default function AgreementSetUpReportContainer({
  onClose,
  open,
}: AgreementSetUpReportContainerProps): ReactElement {
  const { t } = useTranslation("reporting");
  const error = useResponseError();

  const [exportProgress, setExportProgress] = useState(false);

  const reportQueryApi = useApi(ReportQueryApi);

  const portfolioQueryApi = useApi(PortfolioQueryApi);
  const portfolios = useSWR(["portfolios"], () =>
    portfolioQueryApi.listPortfolios(),
  );

  const resolver = usePortfolioDateReportResolver().TodayOrBeforeResolver();

  const onSubmit = async (
    data: PortfolioDateReportDialogFormValues,
  ): Promise<void> => {
    try {
      error.reset();
      setExportProgress(true);

      const response = await reportQueryApi.agreementSetupRaw({
        from: new Date(data.fromDate),
        to: new Date(data.toDate),
        portfolioId:
          data.portfolio === "All" ? undefined : Number(data.portfolio),
      });

      await fileDownloader(response);
      onClose();
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setExportProgress(false);
    }
  };

  return (
    <PortfolioReportFormDialog<PortfolioDateReportDialogFormValues>
      defaultValues={{
        portfolio: "",
        fromDate: format(startOfToday(), "yyyy-MM-dd"),
        toDate: format(startOfToday(), "yyyy-MM-dd"),
      }}
      onClose={onClose}
      onSubmit={onSubmit}
      error={error.message}
      open={open}
      portfolios={[
        { label: t("report_dialog.all_portfolio_option"), value: "All" },
        ...(portfolios.data || []).map((portfolio) => ({
          label: portfolio.name,
          value: String(portfolio.id),
        })),
      ]}
      ready={Boolean(portfolios.data) && !exportProgress}
      resolver={resolver}
      title={t("report_dialog.agreement_set_up.title")}
    >
      {(form): ReactElement => (
        <DateRangeControl
          errors={form.formState.errors}
          register={form.register}
        />
      )}
    </PortfolioReportFormDialog>
  );
}
