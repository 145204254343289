/* tslint:disable */
/* eslint-disable */
/**
 * Engage Gateway (Profile)
 * API for managing Profiles within Engage Gateway
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProfileModel,
} from '../models/index';
import {
    ProfileModelFromJSON,
    ProfileModelToJSON,
} from '../models/index';

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     * Retrieve profile of the current user
     */
    async getProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileModelFromJSON(jsonValue));
    }

    /**
     * Retrieve profile of the current user
     */
    async getProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileModel> {
        const response = await this.getProfileRaw(initOverrides);
        return await response.value();
    }

}
