/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Change types within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChargeTypeDetailsModel,
  ChargeTypeHistoryListItemModel,
  ChargeTypeListItemModel,
  ChargeTypeModel,
  ErrorWithParametersModel,
  UpdateChargeTypeActiveStatusModel,
  UpdateChargeTypeDetailsModel,
} from '../models/index';
import {
    ChargeTypeDetailsModelFromJSON,
    ChargeTypeDetailsModelToJSON,
    ChargeTypeHistoryListItemModelFromJSON,
    ChargeTypeHistoryListItemModelToJSON,
    ChargeTypeListItemModelFromJSON,
    ChargeTypeListItemModelToJSON,
    ChargeTypeModelFromJSON,
    ChargeTypeModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
    UpdateChargeTypeActiveStatusModelFromJSON,
    UpdateChargeTypeActiveStatusModelToJSON,
    UpdateChargeTypeDetailsModelFromJSON,
    UpdateChargeTypeDetailsModelToJSON,
} from '../models/index';

export interface CreateChargeTypeRequest {
    chargeTypeModel: ChargeTypeModel;
}

export interface GetChargeTypeDetailsRequest {
    id: string;
}

export interface ListChargeTypeHistoryRequest {
    id: string;
}

export interface ListChargeTypesRequest {
    offset?: number;
    limit?: number;
}

export interface UpdateChargeTypeActiveStatusRequest {
    id: string;
    updateChargeTypeActiveStatusModel: UpdateChargeTypeActiveStatusModel;
}

export interface UpdateChargeTypeDetailsRequest {
    id: string;
    updateChargeTypeDetailsModel: UpdateChargeTypeDetailsModel;
}

/**
 * 
 */
export class ChargeTypeApi extends runtime.BaseAPI {

    /**
     * Create a Charge Type
     */
    async createChargeTypeRaw(requestParameters: CreateChargeTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.chargeTypeModel === null || requestParameters.chargeTypeModel === undefined) {
            throw new runtime.RequiredError('chargeTypeModel','Required parameter requestParameters.chargeTypeModel was null or undefined when calling createChargeType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:manage"]);
        }

        const response = await this.request({
            path: `/charge-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChargeTypeModelToJSON(requestParameters.chargeTypeModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Charge Type
     */
    async createChargeType(requestParameters: CreateChargeTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createChargeTypeRaw(requestParameters, initOverrides);
    }

    /**
     * Get charge type details
     */
    async getChargeTypeDetailsRaw(requestParameters: GetChargeTypeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChargeTypeDetailsModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getChargeTypeDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:view"]);
        }

        const response = await this.request({
            path: `/charge-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChargeTypeDetailsModelFromJSON(jsonValue));
    }

    /**
     * Get charge type details
     */
    async getChargeTypeDetails(requestParameters: GetChargeTypeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChargeTypeDetailsModel> {
        const response = await this.getChargeTypeDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List charge type history
     */
    async listChargeTypeHistoryRaw(requestParameters: ListChargeTypeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChargeTypeHistoryListItemModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listChargeTypeHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:view"]);
        }

        const response = await this.request({
            path: `/charge-types/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChargeTypeHistoryListItemModelFromJSON));
    }

    /**
     * List charge type history
     */
    async listChargeTypeHistory(requestParameters: ListChargeTypeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChargeTypeHistoryListItemModel>> {
        const response = await this.listChargeTypeHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Charge Types
     */
    async listChargeTypesRaw(requestParameters: ListChargeTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChargeTypeListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:view"]);
        }

        const response = await this.request({
            path: `/charge-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChargeTypeListItemModelFromJSON));
    }

    /**
     * View Charge Types
     */
    async listChargeTypes(requestParameters: ListChargeTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChargeTypeListItemModel>> {
        const response = await this.listChargeTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Charge Type Active Status
     */
    async updateChargeTypeActiveStatusRaw(requestParameters: UpdateChargeTypeActiveStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateChargeTypeActiveStatus.');
        }

        if (requestParameters.updateChargeTypeActiveStatusModel === null || requestParameters.updateChargeTypeActiveStatusModel === undefined) {
            throw new runtime.RequiredError('updateChargeTypeActiveStatusModel','Required parameter requestParameters.updateChargeTypeActiveStatusModel was null or undefined when calling updateChargeTypeActiveStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:manage"]);
        }

        const response = await this.request({
            path: `/charge-types/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChargeTypeActiveStatusModelToJSON(requestParameters.updateChargeTypeActiveStatusModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Charge Type Active Status
     */
    async updateChargeTypeActiveStatus(requestParameters: UpdateChargeTypeActiveStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateChargeTypeActiveStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Update Charge Type Details
     */
    async updateChargeTypeDetailsRaw(requestParameters: UpdateChargeTypeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateChargeTypeDetails.');
        }

        if (requestParameters.updateChargeTypeDetailsModel === null || requestParameters.updateChargeTypeDetailsModel === undefined) {
            throw new runtime.RequiredError('updateChargeTypeDetailsModel','Required parameter requestParameters.updateChargeTypeDetailsModel was null or undefined when calling updateChargeTypeDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:manage"]);
        }

        const response = await this.request({
            path: `/charge-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChargeTypeDetailsModelToJSON(requestParameters.updateChargeTypeDetailsModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Charge Type Details
     */
    async updateChargeTypeDetails(requestParameters: UpdateChargeTypeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateChargeTypeDetailsRaw(requestParameters, initOverrides);
    }

}
