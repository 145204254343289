import * as keys from "api/documents/keys";
import { QueryKey } from "api/types";

export const all = (): QueryKey => [...keys.all(), "letters", "email"];

export const lists = (): QueryKey => [...all(), "list"];

export const list = (params?: {
  status?: string;
  from?: Date;
  to?: Date;
  type?: string;
}): QueryKey => [
  ...lists(),
  params?.status,
  params?.from,
  params?.to,
  params?.type,
];
