import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { RolesApi } from "generated/admin/apis";
import { RoleModel } from "generated/admin/models";

import * as keys from "./keys";

type UseListRoles = () => UseQueryReturn<RoleModel[]>;

export const useListRoles: UseListRoles = () => {
  const rolesApi = useApi(RolesApi);

  const listRoles = async () => {
    const response = await rolesApi.listRoles();
    return response;
  };

  return useQuery(keys.list(), listRoles);
};
