/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Customers within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BankAccountsListItemModel,
} from '../models/index';
import {
    BankAccountsListItemModelFromJSON,
    BankAccountsListItemModelToJSON,
} from '../models/index';

export interface ListCustomerBankAccountsRequest {
    id: string;
}

/**
 * 
 */
export class CustomerBanksApi extends runtime.BaseAPI {

    /**
     * List Customer Bank accounts
     */
    async listCustomerBankAccountsRaw(requestParameters: ListCustomerBankAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BankAccountsListItemModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listCustomerBankAccounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customers:bank-accounts:view"]);
        }

        const response = await this.request({
            path: `/customers/{id}/bank-accounts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BankAccountsListItemModelFromJSON));
    }

    /**
     * List Customer Bank accounts
     */
    async listCustomerBankAccounts(requestParameters: ListCustomerBankAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BankAccountsListItemModel>> {
        const response = await this.listCustomerBankAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
