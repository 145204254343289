import {
  AccessAlarm,
  FiberManualRecord,
  InsertLink,
  Person,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Divider,
  Drawer,
  Fab,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { blue, green, orange, red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import * as keys from "api/tasks/keys";
import { truncateText } from "common/components/TruncateTypography";
import { CreateTaskContainer } from "common/containers/CreateTaskContainer/CreateTaskContainer";
import { ViewTaskContainer } from "common/containers/ViewTaskContainer/ViewTaskContainer";
import { useLocale } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration/useAppConfiguration";
import { useApi } from "common/providers";
import { useEnvironment } from "common/providers/EnvironmentProvider";
import { TasksApi } from "generated/tasks/apis";
import { TaskListItemModel, TaskStatusModel } from "generated/tasks/models";
import { FC, MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link as RouterLink } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

import useSWRInfinite from "swr/infinite";

const drawerWidth = 300;

const DrawerAdjacent = styled("div", {
  shouldForwardProp: (prop) =>
    !(["open", "isDrawerTemporary"] as PropertyKey[]).includes(prop),
})<{
  open?: boolean;
  isDrawerTemporary: boolean;
}>(({ theme, open, isDrawerTemporary }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: isDrawerTemporary ? 0 : -drawerWidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

const DrawerContent = ({
  handleViewTask,
  handleOpenCreateTask,
}: {
  handleViewTask: (
    id: number,
  ) => MouseEventHandler<HTMLButtonElement> | undefined;
  handleOpenCreateTask: () => void;
}) => {
  const [tasksAssignedToUser, setTasksAssignedToUser] = useState(true);
  const [hasMoreData, setHasMoreData] = useState<undefined | boolean>();

  const { t } = useTranslation(["start"]);
  const locale = useLocale();
  const appConfig = useAppConfiguration();
  const api = useApi(TasksApi, "task-management");
  const environment = useEnvironment();

  const { data, size, setSize, isValidating } = useSWRInfinite(
    (index) => {
      const limit = 25;
      const offset = index * limit;
      const listKeys = [
        keys.list({ assignedToCurrentUser: tasksAssignedToUser }),
        limit,
        offset,
      ];
      return listKeys;
    },
    async (...params) => {
      const limit = params[params.length - 2] as number;
      const offset = params[params.length - 1] as number;
      const res = await api.listTasksRaw({
        assignedToCurrentUser: tasksAssignedToUser,
        limit: limit,
        offset: offset,
      });
      const data: TaskListItemModel[] = await res.raw.json();
      const moreDataToDisplay = data.length === limit;
      setHasMoreData(moreDataToDisplay);
      return data;
    },
  );

  const taskList: TaskListItemModel[] = data ? data.flat() : [];

  const loadMoreTasks = () => {
    setSize(size + 1);
  };

  const statusColourMap = new Map<TaskStatusModel, string>([
    [TaskStatusModel.Overdue, red[700]],
    [TaskStatusModel.Urgent, orange[500]],
    [TaskStatusModel.OnTrack, green[400]],
    [TaskStatusModel.Complete, blue[400]],
  ]);

  return (
    <>
      <Box
        sx={(theme) => ({
          borderBottom: 1,
          borderColor: theme.palette.divider,
          paddingX: 2,
          paddingY: 1.75,
          marginTop: !environment.isProduction ? "30px" : 0,
        })}
      >
        <Box display="flex" gap={2}>
          <Box display="flex" gap={1}>
            <Chip
              clickable
              size="small"
              aria-label={t("filters.assigned_to_me")}
              label={t("filters.assigned_to_me")}
              onClick={() => setTasksAssignedToUser((prevState) => !prevState)}
              color={tasksAssignedToUser ? "primary" : "default"}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <List sx={{ padding: 1 }} aria-label={t("sidebar.list")}>
          {taskList.map((task: TaskListItemModel, index: number) => (
            <ListItem
              key={task.id}
              role="listitem"
              sx={{
                padding: 0,
                margin: 0,
                "& .MuiPaper-root": {
                  width: "100%",
                },
              }}
            >
              {index > 0 && <Divider light />}
              <Card key={task.id} elevation={0} sx={{ marginY: 0.5 }}>
                <CardActionArea
                  onClick={handleViewTask(task.id)}
                  aria-label={t("sidebar.view_task")}
                >
                  <CardContent sx={{ paddingY: 1 }}>
                    <Typography fontWeight={500} gutterBottom display="flex">
                      <FiberManualRecord
                        fontSize="inherit"
                        sx={{
                          verticalAlign: "middle",
                          marginRight: 1,
                          marginTop: 0.45,
                          color: statusColourMap.get(task.status),
                        }}
                      />
                      <Typography
                        component="span"
                        sx={{ verticalAlign: "middle" }}
                      >
                        {truncateText(task.title, 50)}
                      </Typography>
                    </Typography>
                    <Stack
                      direction="row"
                      sx={{ flexWrap: "wrap", alignItems: "flex-start" }}
                    >
                      {task.customerName && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mr: task.agreementNumber ? 2 : 0,
                          }}
                        >
                          <InsertLink
                            fontSize="inherit"
                            sx={{
                              verticalAlign: "middle",
                              mr: 1,
                              zIndex: 999,
                            }}
                          />
                          <Link
                            component={RouterLink}
                            to={`${appConfig.appRoutes.servicing}/customers/${task.customerId}/overview`}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {task.customerName}
                          </Link>
                        </Typography>
                      )}
                      {task.agreementNumber && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <InsertLink
                            fontSize="inherit"
                            sx={{
                              verticalAlign: "middle",
                              mr: 1,
                              zIndex: 999,
                            }}
                          />
                          <Link
                            component={RouterLink}
                            to={`${appConfig.appRoutes.servicing}/agreements/${task.agreementId}/overview`}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {task.agreementNumber}
                          </Link>
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Typography variant="body2" color="text.secondary">
                        <AccessAlarm
                          fontSize="inherit"
                          sx={{ verticalAlign: "middle", marginRight: 1 }}
                        />
                        {locale.formatDate(new Date(task.dueAt))}
                      </Typography>
                      {task.assignedTo && (
                        <Typography variant="body2" color="text.secondary">
                          <Person
                            fontSize="inherit"
                            sx={{ verticalAlign: "middle", marginRight: 1 }}
                          />
                          {task.assignedTo}
                        </Typography>
                      )}
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </ListItem>
          ))}
          {taskList && hasMoreData && !isValidating && (
            <>
              <Divider light />
              <ListItem>
                <ListItemButton onClick={loadMoreTasks} dense>
                  <ListItemText
                    sx={{ textAlign: "center" }}
                    primaryTypographyProps={{
                      color: "primary",
                    }}
                    primary={t("sidebar.load_more_tasks_button")}
                  />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
        <Fab
          color="primary"
          size="small"
          aria-label={t("create_task_button")}
          sx={{ position: "absolute", bottom: 15, right: 25 }}
          onClick={() => handleOpenCreateTask()}
        >
          <AddIcon />
        </Fab>
      </Box>
    </>
  );
};

const TasksDrawer: React.FC<{ open: boolean; isDrawerTemporary: boolean }> = ({
  open,
  isDrawerTemporary,
}) => {
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState<boolean>(false);
  const [currentTaskId, setCurrentTaskId] = useState<number | undefined>();
  const { t } = useTranslation();

  const handleViewTask = (taskId: number) => (): void => {
    setCurrentTaskId(taskId);
  };

  const handleCloseCreateTask = () => {
    setIsCreateTaskOpen(false);
  };

  const handleOpenCreateTask = () => {
    setIsCreateTaskOpen(true);
  };

  return (
    <>
      <Drawer
        anchor="right"
        aria-label={t("common:tasks_sidebar.tasks_label")}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          position: "relative",
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            overflow: "none",
          },
        }}
        open={open}
        variant={isDrawerTemporary ? "temporary" : "persistent"}
        hideBackdrop={isDrawerTemporary}
        keepMounted={isDrawerTemporary}
        aria-hidden={open}
        PaperProps={{ sx: { overflow: "none" } }}
      >
        <Toolbar variant="dense" />
        <DrawerContent
          handleViewTask={handleViewTask}
          handleOpenCreateTask={handleOpenCreateTask}
        />
      </Drawer>

      {isCreateTaskOpen && (
        <CreateTaskContainer
          onClose={handleCloseCreateTask}
          open={isCreateTaskOpen}
        />
      )}

      {currentTaskId && (
        <ViewTaskContainer
          open={Boolean(currentTaskId)}
          onClose={() => setCurrentTaskId(undefined)}
          taskId={currentTaskId}
        />
      )}
    </>
  );
};

const TasksLayout: FC<{ open: boolean }> = ({ open }) => {
  const isTemporary = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl"),
  );

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
      }}
    >
      <DrawerAdjacent
        open={open}
        isDrawerTemporary={isTemporary}
        sx={{
          flex: 1,
          zIndex: 1,
        }}
      >
        <Outlet />
      </DrawerAdjacent>
      <TasksDrawer open={open} isDrawerTemporary={isTemporary} />
    </Box>
  );
};

export default withTranslationLoader("tasks")(TasksLayout);
