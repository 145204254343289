import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { SecondaryRentalQueryApi } from "generated/core/apis";
import { SecondaryRentalDetailsModel } from "generated/core/models";
import { ErrorLike } from "support/error-handler";
import { keys } from ".";

type UseGetSecondaryRentalDetails = (
  secondaryRentalId: number,
) => UseQueryReturn<SecondaryRentalDetailsModel, ErrorLike>;

export const useGetSecondaryRentalDetails: UseGetSecondaryRentalDetails = (
  secondaryRentalId: number,
) => {
  const api = useApi(SecondaryRentalQueryApi);

  return useQuery(keys.detail(secondaryRentalId), () =>
    api.getSecondaryRentalDetails({ secondaryRentalId }),
  );
};
