import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { UsersApi } from "generated/admin/apis";
import { UserModel } from "generated/admin/models";
import { ErrorLike } from "support/error-handler";
import { keys } from ".";

type UseGetUser = (userId: string) => UseQueryReturn<UserModel, ErrorLike>;

export const useGetUser: UseGetUser = (userId: string) => {
  const userApi = useApi(UsersApi);

  const getUser = async () => {
    return await userApi.getUser({ userId });
  };

  return useQuery(keys.detail(userId), getUser);
};
