import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { ClientQueryApi } from "generated/core/apis";
import { AgreementListModel } from "generated/core/models";
import { keys } from ".";

type UseListCustomerAgreements = (
  customerId: number,
  params?: { query?: string; live?: boolean },
) => UseQueryReturn<AgreementListModel>;

export const useListCustomerAgreements: UseListCustomerAgreements = (
  customerId: number,
  params: { query?: string; live?: boolean } = {},
) => {
  const api = useApi(ClientQueryApi);

  return useQuery(keys.list(customerId, params), () =>
    api.listClientAgreements({ clientId: customerId, ...params }),
  );
};
