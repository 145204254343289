import { AddButton } from "@ldms/mui-sdk/components";
import { DateField, NumberField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useAddVatCode } from "api/vat-codes/addVatCode";
import { useYupResolver } from "common/hooks";
import { format } from "date-fns";
import { CreateTaxCodeRequestModel } from "generated/servicing-v2/models";
import { ReactElement, useState } from "react";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface AddVatCodeFieldValues {
  description: string;
  effectiveDate: Date;
  rate: number;
}

const transformField = (v: string, o: string): string | null | undefined => {
  return o === "" ? undefined : v;
};

const useAddCompanyResolver = (): Resolver<AddVatCodeFieldValues> => {
  const { t } = useTranslation("servicing");

  const rateLabel = "vat_codes.add_vat_code.vat_rate_label";
  const descriptionLabel = "vat_codes.add_vat_code.vat_description_label";
  const effectiveDateLabel = "vat_codes.add_vat_code.effective_date_label";

  return useYupResolver<AddVatCodeFieldValues>((yup) =>
    yup.object().shape({
      effectiveDate: yup
        .date()
        .localDate()
        .isValidDate(t(effectiveDateLabel))
        .isRequired(t(effectiveDateLabel))
        .isNotFuture(t(effectiveDateLabel)),
      description: yup
        .string()
        .isRequired(t(descriptionLabel))
        .maxCharacters(100, t(descriptionLabel)),
      rate: yup
        .number(t(rateLabel))
        .transform(transformField)
        .isRequired(t(rateLabel))
        .minAmount(0, t(rateLabel))
        .maxAmount(99.75, t(rateLabel)),
    }),
  );
};

export default function AddVatCodeContainer(): ReactElement {
  const { t } = useTranslation("servicing");
  const resolver = useAddCompanyResolver();
  const [openAddVatCodeDialog, setOpenAddVatCodeDialog] = useState(false);

  const openDialog = (): void => {
    setOpenAddVatCodeDialog(true);
  };

  const closeDialog = (): void => {
    setOpenAddVatCodeDialog(false);
    addVatCode.reset();
  };

  const addVatCode = useAddVatCode({
    onSuccess: closeDialog,
  });

  const onSubmit = async (data: AddVatCodeFieldValues): Promise<void> => {
    addVatCode.reset();
    const addVatCodeModel: CreateTaxCodeRequestModel = {
      name: data.description,
      effectiveDate: data.effectiveDate,
      rate: data.rate,
    };
    await addVatCode.execute(addVatCodeModel);
  };

  const responseError = new Map([
    ["vat_code_exists", t("vat_codes.add_vat_code.vat_code_exists_message")],
  ]);

  return (
    <>
      <AddButton variant="contained" onClick={openDialog} />

      <FormDialog
        onSubmit={onSubmit}
        resolver={resolver}
        title={t("vat_codes.add_vat_code.title_label")}
        onClose={closeDialog}
        open={openAddVatCodeDialog}
        defaultValues={{
          effectiveDate: new Date(format(new Date(), "yyyy-MM-dd")),
        }}
        disabled={addVatCode.isExecuting}
      >
        {(form): ReactElement => {
          return (
            <>
              <Grid container spacing={2} rowSpacing={0.5}>
                <Grid item sm={12}>
                  <DateField
                    error={Boolean(
                      form.formState.errors?.effectiveDate?.message,
                    )}
                    helperText={form.formState.errors?.effectiveDate?.message}
                    control={form.control}
                    name="effectiveDate"
                    label={t("vat_codes.add_vat_code.effective_date_label")}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                    required
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    {...form.register("description")}
                    error={Boolean(form.formState.errors.description)}
                    helperText={form.formState.errors.description?.message}
                    label={t("vat_codes.add_vat_code.vat_description_label")}
                    required
                  />
                </Grid>
                <Grid item sm={12}>
                  <NumberField
                    control={form.control}
                    label={t("vat_codes.add_vat_code.vat_rate_label")}
                    name="rate"
                    error={Boolean(form.formState.errors.rate?.message)}
                    helperText={form.formState.errors.rate?.message}
                    required
                    maximumFractionDigits={2}
                  />
                </Grid>
              </Grid>

              {addVatCode.error && (
                <Box>
                  <Typography color="error">
                    {responseError.get(addVatCode.error.code) ??
                      t("common:error.default")}
                  </Typography>
                </Box>
              )}
            </>
          );
        }}
      </FormDialog>
    </>
  );
}
