import { PagedQuery, useQueryPaged } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { AgreementFinancialPostingListItemModel } from "generated/core/models";
import { keys } from ".";

export const useListAgreementFinancialPostings: PagedQuery<
  AgreementFinancialPostingListItemModel,
  { agreementId: number }
> = ({ pageSize, params = {} } = {}) => {
  const api = useApi(AgreementQueryApi);

  return useQueryPaged(
    keys.list(Number(params.agreementId)),
    async (_key, paging) =>
      api.listAgreementFinancialPostingsRaw({
        agreementId: Number(params.agreementId),
        limit: paging.pageSize,
        offset: paging.offset,
      }),
    { limit: pageSize },
  );
};
