import { Loader } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import * as agreementKeys from "api/agreements/memos/keys";
import { MemoList, ViewDateToggle } from "common/components";
import ListLayout from "common/layouts/ListLayout";
import { useApi } from "common/providers";
import { startOfDay, subMonths } from "date-fns";
import { AgreementQueryApi } from "generated/core/apis";
import { MemoModel } from "generated/core/models";
import { ChangeEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import useStickySWR from "support/use-sticky-swr";

interface AgreementMemosProps {
  agreementId: number;
}

type FromValues = "lastSixMonths" | "";

interface AgreementMemosParameters {
  from: FromValues;
}

export default function AgreementMemos({
  agreementId,
}: AgreementMemosProps): ReactElement {
  const sixMonthsAgo = startOfDay(subMonths(new Date(), 6));
  const { t } = useTranslation("agreements");
  const [parameters, setParameters] = useState<AgreementMemosParameters>({
    from: "lastSixMonths",
  });
  const agreementQueryApi = useApi(AgreementQueryApi);
  const from = parameters.from === "lastSixMonths" ? sixMonthsAgo : undefined;
  const agreementMemos = useStickySWR<MemoModel[]>(
    agreementKeys.list(agreementId, from),
    () =>
      agreementQueryApi.listAgreementMemos({
        agreementId,
        from,
      }),
  );

  const handleDateRangeChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setParameters({
      from: event.target.value as FromValues,
    });
  };

  return (
    <ListLayout
      filters={
        <Box marginTop={-0.5}>
          <ViewDateToggle
            onChange={handleDateRangeChange}
            value={parameters.from}
            testId="viewDateToggle.agreementMemo.viewAll"
          />
        </Box>
      }
    >
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(agreementMemos.data ?? agreementMemos.error)}
        render={() => {
          if (agreementMemos.error || !agreementMemos.data) {
            return (
              <Typography
                aria-label={t("common:error.default")}
                color="error"
                role="alert"
                data-testid="agreementMemos.error"
              >
                {t("common:error.default")}
              </Typography>
            );
          }

          return (
            <MemoList
              loading={agreementMemos.isValidating}
              memos={agreementMemos.data.map((memo) => ({
                ...memo,
                created: new Date(memo.created),
              }))}
              showAgreementNumber={false}
            />
          );
        }}
      />
    </ListLayout>
  );
}
