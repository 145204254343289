/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Companies within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyListItemModel
 */
export interface CompanyListItemModel {
    /**
     * Unique identifier of Company
     * @type {string}
     * @memberof CompanyListItemModel
     */
    systemId: string;
    /**
     * The company name
     * @type {string}
     * @memberof CompanyListItemModel
     */
    name: string;
    /**
     * The company registration number
     * @type {string}
     * @memberof CompanyListItemModel
     */
    registrationNumber?: string;
    /**
     * The Tax registration number
     * @type {string}
     * @memberof CompanyListItemModel
     */
    taxRegistrationNumber?: string;
    /**
     * The Tax code description
     * @type {string}
     * @memberof CompanyListItemModel
     */
    taxCode: string;
}

/**
 * Check if a given object implements the CompanyListItemModel interface.
 */
export function instanceOfCompanyListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "systemId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "taxCode" in value;

    return isInstance;
}

export function CompanyListItemModelFromJSON(json: any): CompanyListItemModel {
    return CompanyListItemModelFromJSONTyped(json, false);
}

export function CompanyListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemId': json['systemId'],
        'name': json['name'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'taxRegistrationNumber': !exists(json, 'taxRegistrationNumber') ? undefined : json['taxRegistrationNumber'],
        'taxCode': json['taxCode'],
    };
}

export function CompanyListItemModelToJSON(value?: CompanyListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'systemId': value.systemId,
        'name': value.name,
        'registrationNumber': value.registrationNumber,
        'taxRegistrationNumber': value.taxRegistrationNumber,
        'taxCode': value.taxCode,
    };
}

