import { Form } from "@ldms/mui-sdk/forms";
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useListAgreementAssets } from "api/agreements/assets";
import { useAddAgreementFinancialPosting } from "api/agreements/financial-postings/addAgreementFinancialPosting";
import { useListFinancialPostingStages } from "api/financial-postings/stages/listFinancialPostingStages";
import { Loader, QueryError } from "common/components";
import AddFinancialPostingFormControls, {
  AddFinancialPostingFieldValues,
  AssetFinancialPosting,
} from "common/components/AddFinancialPostingFormControls";
import { useYupResolver } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import {
  AddAgreementFinancialPostingModel,
  AgreementAssetListItemModel,
  FinancialPostingStageListItemModel,
} from "generated/core/models";
import { ReactElement } from "react";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

interface AddAgreementFinancialPostingContainerProps {
  agreementId: number;
}

interface AddAgreementFinancialPostingBodyProps {
  listFinancialPostingStages: FinancialPostingStageListItemModel[];
  agreementAssets: AgreementAssetListItemModel[];
  agreementId: number;
}

const useAddAgreementFinancialPostingResolver =
  (): Resolver<AddFinancialPostingFieldValues> => {
    const { t } = useTranslation("agreements");
    const transformField = (
      v: string,
      o: string,
    ): string | null | undefined => {
      return o === "" ? null : v;
    };
    const addAmountLabel = t("financial_postings.add.amount_label");

    return useYupResolver<AddFinancialPostingFieldValues>((yup) =>
      yup.object().shape({
        accountName: yup
          .string()
          .isRequired(t("financial_postings.add.account_label")),
        stageGroup: yup
          .string()
          .isRequired(t("financial_postings.add.stage_group_label")),
        stageCode: yup
          .string()
          .isRequired(t("financial_postings.add.stage_name_label")),
        amount: yup
          .number(addAmountLabel)
          .nullable()
          .transform(transformField)
          .isRequired(addAmountLabel)
          .greaterThanAmount(0, addAmountLabel),
        financialDate: yup
          .date()
          .localDate()
          .isRequired(t("financial_postings.add.date_label"))
          .isNotFuture(t("financial_postings.add.date_label")),
      }),
    );
  };

function AddAgreementFinancialPostingBody({
  listFinancialPostingStages,
  agreementAssets,
  agreementId,
}: AddAgreementFinancialPostingBodyProps): ReactElement {
  const { t } = useTranslation("agreements");
  const resolver = useAddAgreementFinancialPostingResolver();
  const navigate = useNavigate();
  const appConfig = useAppConfiguration();
  const onSuccess = async (): Promise<void> => {
    navigate("..", { relative: "path" });
  };

  const addAgreementFinancialPosting = useAddAgreementFinancialPosting(
    agreementId,
    {
      onSuccess,
    },
  );

  const responseError = new Map([
    [
      "invalid_financial_posting_before_start_date",
      t(
        "financial_postings.add.invalid_financial_posting_before_start_date_error_message",
      ),
    ],
    [
      "invalid_financial_posting_date",
      t("financial_postings.add.invalid_financial_posting_date_error_message"),
    ],
  ]);

  const onSubmit = async (
    data: AddFinancialPostingFieldValues,
  ): Promise<void> => {
    const addAgreementFinancialPostingModel: AddAgreementFinancialPostingModel =
      {
        ...data,
        stageCode: data.stageCode,
        assetId: data.assetId === 0 ? undefined : data.assetId,
        amount: data.amount.toFixed(2),
        financialDate: data.financialDate,
      };

    await addAgreementFinancialPosting.execute(
      addAgreementFinancialPostingModel,
    );
  };

  const getSettledAssets = agreementAssets.filter(
    (s) => s.status === "Settled",
  );

  const displaySettledAssets = getSettledAssets?.map(
    (asset: AgreementAssetListItemModel): AssetFinancialPosting => {
      return {
        id: asset.id,
        display: `${asset?.type} - ${
          asset?.serialNumber ? asset.serialNumber : asset.registrationNumber
        }`,
      };
    },
  );

  return (
    <>
      <Form
        label={t("financial_postings.add.form_title")}
        onSubmit={onSubmit}
        resolver={resolver}
        options={{ shouldUnregister: true }}
        defaultValues={{
          accountName: "",
          stageGroup: "",
          stageCode: "",
          assetId: 0,
          amount: undefined,
          financialDate: undefined,
        }}
      >
        {(form): ReactElement => {
          return (
            <Container maxWidth="sm">
              <Paper>
                <Stack gap={2} padding={2}>
                  <Typography variant="h6">
                    {t("financial_postings.add.form_title")}
                  </Typography>

                  <AddFinancialPostingFormControls
                    settledAssets={displaySettledAssets}
                    props={form}
                    financialPostings={listFinancialPostingStages}
                  />

                  {addAgreementFinancialPosting.error && (
                    <Typography color="error" aria-label="form.error">
                      {responseError.get(
                        addAgreementFinancialPosting.error.code,
                      ) ?? t("common:error.default")}
                    </Typography>
                  )}

                  <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Button
                      role="link"
                      key="common:cancel"
                      component={RouterLink}
                      to={`${appConfig.appRoutes.servicing}/agreements/${agreementId}/financial-postings`}
                      color="primary"
                    >
                      {t("common:cancel")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={addAgreementFinancialPosting.isExecuting}
                    >
                      {t("financial_postings.add.submit_button")}
                    </Button>
                  </Box>
                </Stack>
              </Paper>
            </Container>
          );
        }}
      </Form>
    </>
  );
}

export default function AddAgreementFinancialPostingContainer({
  agreementId,
}: AddAgreementFinancialPostingContainerProps): ReactElement {
  const listFinancialPostingStages = useListFinancialPostingStages({
    agreementId,
  });
  const agreementAssets = useListAgreementAssets(agreementId);

  return (
    <>
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(
          listFinancialPostingStages.data ||
            listFinancialPostingStages.error ||
            agreementAssets.data ||
            agreementAssets.error,
        )}
        render={(): ReactElement => {
          if (
            listFinancialPostingStages.error ||
            !listFinancialPostingStages.data
          ) {
            return <QueryError onRetry={listFinancialPostingStages.refetch} />;
          }
          if (agreementAssets.error || !agreementAssets.data) {
            return <QueryError onRetry={agreementAssets.refetch} />;
          }

          return (
            <AddAgreementFinancialPostingBody
              listFinancialPostingStages={listFinancialPostingStages.data}
              agreementAssets={agreementAssets.data}
              agreementId={agreementId}
            />
          );
        }}
      />
    </>
  );
}
