import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useUpdateTemplateDetails } from "api/documents/templates/updateTemplateDetails";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import { AttachmentTypeModel } from "generated/core/models";
import { UpdateTemplateDetailModel } from "generated/documents/models";
import { ReactElement, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface EditLetterTemplateContainerProps {
  templateId: string;
  defaultValues: UpdateTemplateDetailModel;
}

export default function EditLetterTemplateContainer({
  templateId,
  defaultValues,
}: Readonly<EditLetterTemplateContainerProps>): ReactElement {
  const { t } = useTranslation("documents");
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const editTemplate = useUpdateTemplateDetails(templateId, {
    onSuccess: closeDialog,
  });

  const nameLabel = t("letter_templates.edit.template_name_label");
  const documentTypeLabel = t("letter_templates.edit.document_type_label");
  const descriptionLabel = t("letter_templates.edit.description_label");
  const activeLabel = t("letter_templates.edit.active_label");

  const responseError = (name?: string) => {
    return new Map([
      [
        "conflict",
        t("letter_templates.edit.existing_name_error", {
          name,
        }),
      ],
    ]);
  };

  const resolver = useYupResolver<UpdateTemplateDetailModel>((yup) =>
    yup.object().shape({
      name: yup.string().isRequired(nameLabel).maxCharacters(100, nameLabel),
      documentType: yup.string().isRequired(documentTypeLabel),
      description: yup
        .string()
        .isRequired(descriptionLabel)
        .maxCharacters(200, descriptionLabel),
    }),
  );

  const onSuccess = async (data: UpdateTemplateDetailModel): Promise<void> => {
    editTemplate.execute({
      templateId,
      updateTemplateDetailModel: data,
    });
  };

  return (
    <>
      <Button variant="contained" onClick={openDialog}>
        {t("letter_templates.edit.edit_button")}
      </Button>

      <FormDialog
        onClose={closeDialog}
        onSubmit={onSuccess}
        title={t("letter_templates.edit.title_label")}
        open={isOpen}
        resolver={resolver}
        defaultValues={defaultValues}
        disabled={editTemplate.isExecuting}
      >
        {(form) => (
          <>
            <TextField
              {...form.register("name")}
              error={Boolean(form.formState.errors?.name)}
              fullWidth
              multiline
              variant="outlined"
              required
              helperText={form.formState.errors.name?.message}
              label={nameLabel}
            />

            <ControlledTextField
              select
              id="documentType"
              name="documentType"
              error={form.formState.errors?.documentType?.message}
              SelectProps={{ displayEmpty: true }}
              helperText={form.formState.errors?.documentType?.message}
              control={form.control}
              required
              label={documentTypeLabel}
            >
              <MenuItem value="">
                <i>{t("common:please_select")}</i>
              </MenuItem>

              {Object.values(AttachmentTypeModel).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </ControlledTextField>

            <TextField
              {...form.register("description")}
              error={Boolean(form.formState.errors?.description)}
              required
              helperText={form.formState.errors.description?.message}
              fullWidth
              rows={3}
              variant="outlined"
              label={descriptionLabel}
              multiline
            />

            <FormControlLabel
              label={activeLabel}
              labelPlacement="end"
              control={
                <Controller
                  control={form.control}
                  name="status"
                  render={({ field }): ReactElement => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e): void => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
            />

            {editTemplate.error && (
              <Box marginTop={1}>
                <Typography color="error">
                  {responseError(form.getValues().name).get(
                    editTemplate.error.code,
                  ) ?? t("common:error.default")}
                </Typography>
              </Box>
            )}
          </>
        )}
      </FormDialog>
    </>
  );
}
