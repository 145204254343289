import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/documents/templates/keys";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import {
  TemplateApi,
  UpdateTemplateDetailsRequest,
} from "generated/documents/apis";

export const useUpdateTemplateDetails = (
  templateId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(TemplateApi);
  const partialMutate = usePartialMutate();

  return useCommand<UpdateTemplateDetailsRequest, void, ResponseError>(
    (requestParameters) => {
      return api.updateTemplateDetails(requestParameters);
    },
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.detail(templateId));
        options.onSuccess?.();
      },
    },
  );
};
