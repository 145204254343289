/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServicePlanModel
 */
export interface ServicePlanModel {
    /**
     * The service plan code
     * @type {string}
     * @memberof ServicePlanModel
     */
    code?: string;
    /**
     * The service plan type
     * @type {string}
     * @memberof ServicePlanModel
     */
    type?: string;
    /**
     * The service plan description
     * @type {string}
     * @memberof ServicePlanModel
     */
    description?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ServicePlanModel
     */
    fee?: string;
}

/**
 * Check if a given object implements the ServicePlanModel interface.
 */
export function instanceOfServicePlanModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServicePlanModelFromJSON(json: any): ServicePlanModel {
    return ServicePlanModelFromJSONTyped(json, false);
}

export function ServicePlanModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServicePlanModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
    };
}

export function ServicePlanModelToJSON(value?: ServicePlanModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'type': value.type,
        'description': value.description,
        'fee': value.fee,
    };
}

