import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { SettlementQuoteListItemModel } from "generated/core/models";
import { keys } from ".";

type UseListSettlementQuotes = (
  agreementId: number,
) => UseQueryReturn<SettlementQuoteListItemModel[]>;

export const useListSettlementQuotes: UseListSettlementQuotes = (
  agreementId: number,
) => {
  const api = useApi(AgreementQueryApi);

  return useQuery(keys.list(agreementId), () =>
    api.listSettlementQuotes({
      agreementId,
    }),
  );
};
