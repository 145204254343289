import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { ResponseError } from "generated";
import { AutomationCommandApi } from "generated/core/apis";
import { UpdateAutomationModel } from "generated/core/models";
import * as auditHistoryKeys from "./auditHistory/keys";
import * as keys from "./keys";

const useUpdateAutomation = (
  automationId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AutomationCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand(
    async (updateAutomationModel: UpdateAutomationModel) =>
      api.updateAutomation({
        automationId,
        updateAutomationModel,
      }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        partialMutate(keys.all());
        partialMutate(auditHistoryKeys.list(automationId));
      },
    },
  );
};

export { useUpdateAutomation };
