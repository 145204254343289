/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing secondary rentals within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UpdateSecondaryRentalDetailsModel,
} from '../models/index';
import {
    UpdateSecondaryRentalDetailsModelFromJSON,
    UpdateSecondaryRentalDetailsModelToJSON,
} from '../models/index';

export interface UpdateSecondaryRentalDetailsRequest {
    secondaryRentalId: number;
    updateSecondaryRentalDetailsModel?: UpdateSecondaryRentalDetailsModel;
}

/**
 * 
 */
export class SecondaryRentalCommandApi extends runtime.BaseAPI {

    /**
     * Update secondary rental details
     */
    async updateSecondaryRentalDetailsRaw(requestParameters: UpdateSecondaryRentalDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.secondaryRentalId === null || requestParameters.secondaryRentalId === undefined) {
            throw new runtime.RequiredError('secondaryRentalId','Required parameter requestParameters.secondaryRentalId was null or undefined when calling updateSecondaryRentalDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["secondary-rentals:manage"]);
        }

        const response = await this.request({
            path: `/secondary-rentals/{secondaryRentalId}`.replace(`{${"secondaryRentalId"}}`, encodeURIComponent(String(requestParameters.secondaryRentalId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSecondaryRentalDetailsModelToJSON(requestParameters.updateSecondaryRentalDetailsModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update secondary rental details
     */
    async updateSecondaryRentalDetails(requestParameters: UpdateSecondaryRentalDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSecondaryRentalDetailsRaw(requestParameters, initOverrides);
    }

}
