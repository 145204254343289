/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Portfolio Bank Accounts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DirectDebitSubmissionFileTypeModel } from './DirectDebitSubmissionFileTypeModel';
import {
    DirectDebitSubmissionFileTypeModelFromJSON,
    DirectDebitSubmissionFileTypeModelFromJSONTyped,
    DirectDebitSubmissionFileTypeModelToJSON,
} from './DirectDebitSubmissionFileTypeModel';

/**
 * 
 * @export
 * @interface PortfolioBankAccountDetailsModel
 */
export interface PortfolioBankAccountDetailsModel {
    /**
     * Unique identifier of Portfolio Bank Account
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    systemId: string;
    /**
     * The portfolio bank account name
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    name: string;
    /**
     * The service user number
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    serviceUserNumber: string;
    /**
     * The portfolio bank account sort code
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    sortCode?: string;
    /**
     * The portfolio bank account number
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    accountNumber?: string;
    /**
     * The international bank account number
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    iban?: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    bic?: string;
    /**
     * The first line of the address
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    addressLine1: string;
    /**
     * The second line of the address
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    addressLine2?: string;
    /**
     * The third line of the address
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    addressLine3?: string;
    /**
     * The fourth line of the address
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    addressLine4?: string;
    /**
     * The postcode of the address
     * @type {string}
     * @memberof PortfolioBankAccountDetailsModel
     */
    postcode: string;
    /**
     * 
     * @type {DirectDebitSubmissionFileTypeModel}
     * @memberof PortfolioBankAccountDetailsModel
     */
    directDebitSubmissionFileType: DirectDebitSubmissionFileTypeModel;
}

/**
 * Check if a given object implements the PortfolioBankAccountDetailsModel interface.
 */
export function instanceOfPortfolioBankAccountDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "systemId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "serviceUserNumber" in value;
    isInstance = isInstance && "addressLine1" in value;
    isInstance = isInstance && "postcode" in value;
    isInstance = isInstance && "directDebitSubmissionFileType" in value;

    return isInstance;
}

export function PortfolioBankAccountDetailsModelFromJSON(json: any): PortfolioBankAccountDetailsModel {
    return PortfolioBankAccountDetailsModelFromJSONTyped(json, false);
}

export function PortfolioBankAccountDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioBankAccountDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemId': json['systemId'],
        'name': json['name'],
        'serviceUserNumber': json['serviceUserNumber'],
        'sortCode': !exists(json, 'sortCode') ? undefined : json['sortCode'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
        'addressLine1': json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': json['postcode'],
        'directDebitSubmissionFileType': DirectDebitSubmissionFileTypeModelFromJSON(json['directDebitSubmissionFileType']),
    };
}

export function PortfolioBankAccountDetailsModelToJSON(value?: PortfolioBankAccountDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'systemId': value.systemId,
        'name': value.name,
        'serviceUserNumber': value.serviceUserNumber,
        'sortCode': value.sortCode,
        'accountNumber': value.accountNumber,
        'iban': value.iban,
        'bic': value.bic,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
        'directDebitSubmissionFileType': DirectDebitSubmissionFileTypeModelToJSON(value.directDebitSubmissionFileType),
    };
}

