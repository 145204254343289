/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';
import type { CategoryModel } from './CategoryModel';
import {
    CategoryModelFromJSON,
    CategoryModelFromJSONTyped,
    CategoryModelToJSON,
} from './CategoryModel';
import type { NewOrUsedModel } from './NewOrUsedModel';
import {
    NewOrUsedModelFromJSON,
    NewOrUsedModelFromJSONTyped,
    NewOrUsedModelToJSON,
} from './NewOrUsedModel';

/**
 * 
 * @export
 * @interface UpdateAssetModel
 */
export interface UpdateAssetModel {
    /**
     * The asset type code
     * @type {string}
     * @memberof UpdateAssetModel
     */
    typeCode: string;
    /**
     * The asset description
     * @type {string}
     * @memberof UpdateAssetModel
     */
    description: string;
    /**
     * The asset serial number
     * @type {string}
     * @memberof UpdateAssetModel
     */
    serialNumber?: string;
    /**
     * The asset registration number
     * @type {string}
     * @memberof UpdateAssetModel
     */
    registrationNumber?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof UpdateAssetModel
     */
    registrationDate?: Date;
    /**
     * The asset vehicle identification number
     * @type {string}
     * @memberof UpdateAssetModel
     */
    vehicleIdentificationNumber?: string;
    /**
     * The asset colour
     * @type {string}
     * @memberof UpdateAssetModel
     */
    colour?: string;
    /**
     * The asset manufacturer
     * @type {string}
     * @memberof UpdateAssetModel
     */
    manufacturerCode?: string;
    /**
     * The asset model
     * @type {string}
     * @memberof UpdateAssetModel
     */
    model?: string;
    /**
     * The asset body type
     * @type {string}
     * @memberof UpdateAssetModel
     */
    bodyType?: string;
    /**
     * The asset year of manufacture
     * @type {string}
     * @memberof UpdateAssetModel
     */
    yearManufactured?: string;
    /**
     * 
     * @type {NewOrUsedModel}
     * @memberof UpdateAssetModel
     */
    newOrUsed: NewOrUsedModel;
    /**
     * The asset engine size
     * @type {number}
     * @memberof UpdateAssetModel
     */
    engineSize?: number;
    /**
     * The annual mileage
     * @type {number}
     * @memberof UpdateAssetModel
     */
    annualMileage?: number;
    /**
     * The registered keeper of the asset
     * @type {string}
     * @memberof UpdateAssetModel
     */
    registeredKeeper?: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof UpdateAssetModel
     */
    keeperAddress?: AddressModel;
    /**
     * A format for dates
     * @type {Date}
     * @memberof UpdateAssetModel
     */
    hpiRegisteredDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof UpdateAssetModel
     */
    hpiRemovedDate?: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof UpdateAssetModel
     */
    residualValue?: string;
    /**
     * The purchase order number
     * @type {string}
     * @memberof UpdateAssetModel
     */
    purchaseOrderNumber?: string;
    /**
     * Flag to define if the user wishes to apply insurance
     * @type {boolean}
     * @memberof UpdateAssetModel
     */
    includeInsurance?: boolean;
    /**
     * 
     * @type {CategoryModel}
     * @memberof UpdateAssetModel
     */
    category?: CategoryModel | null;
    /**
     * The asset banding
     * @type {string}
     * @memberof UpdateAssetModel
     */
    banding?: string;
    /**
     * Flag to indicate whether manufacturer subsidy has been paid
     * @type {boolean}
     * @memberof UpdateAssetModel
     */
    manufacturerSubsidyPaid?: boolean;
}

/**
 * Check if a given object implements the UpdateAssetModel interface.
 */
export function instanceOfUpdateAssetModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "typeCode" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "newOrUsed" in value;

    return isInstance;
}

export function UpdateAssetModelFromJSON(json: any): UpdateAssetModel {
    return UpdateAssetModelFromJSONTyped(json, false);
}

export function UpdateAssetModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAssetModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeCode': json['typeCode'],
        'description': json['description'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'registrationDate': !exists(json, 'registrationDate') ? undefined : (new Date(json['registrationDate'])),
        'vehicleIdentificationNumber': !exists(json, 'vehicleIdentificationNumber') ? undefined : json['vehicleIdentificationNumber'],
        'colour': !exists(json, 'colour') ? undefined : json['colour'],
        'manufacturerCode': !exists(json, 'manufacturerCode') ? undefined : json['manufacturerCode'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'bodyType': !exists(json, 'bodyType') ? undefined : json['bodyType'],
        'yearManufactured': !exists(json, 'yearManufactured') ? undefined : json['yearManufactured'],
        'newOrUsed': NewOrUsedModelFromJSON(json['newOrUsed']),
        'engineSize': !exists(json, 'engineSize') ? undefined : json['engineSize'],
        'annualMileage': !exists(json, 'annualMileage') ? undefined : json['annualMileage'],
        'registeredKeeper': !exists(json, 'registeredKeeper') ? undefined : json['registeredKeeper'],
        'keeperAddress': !exists(json, 'keeperAddress') ? undefined : AddressModelFromJSON(json['keeperAddress']),
        'hpiRegisteredDate': !exists(json, 'hpiRegisteredDate') ? undefined : (new Date(json['hpiRegisteredDate'])),
        'hpiRemovedDate': !exists(json, 'hpiRemovedDate') ? undefined : (new Date(json['hpiRemovedDate'])),
        'residualValue': !exists(json, 'residualValue') ? undefined : json['residualValue'],
        'purchaseOrderNumber': !exists(json, 'purchaseOrderNumber') ? undefined : json['purchaseOrderNumber'],
        'includeInsurance': !exists(json, 'includeInsurance') ? undefined : json['includeInsurance'],
        'category': !exists(json, 'category') ? undefined : CategoryModelFromJSON(json['category']),
        'banding': !exists(json, 'banding') ? undefined : json['banding'],
        'manufacturerSubsidyPaid': !exists(json, 'manufacturerSubsidyPaid') ? undefined : json['manufacturerSubsidyPaid'],
    };
}

export function UpdateAssetModelToJSON(value?: UpdateAssetModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'typeCode': value.typeCode,
        'description': value.description,
        'serialNumber': value.serialNumber,
        'registrationNumber': value.registrationNumber,
        'registrationDate': value.registrationDate === undefined ? undefined : (value.registrationDate.toISOString().substring(0,10)),
        'vehicleIdentificationNumber': value.vehicleIdentificationNumber,
        'colour': value.colour,
        'manufacturerCode': value.manufacturerCode,
        'model': value.model,
        'bodyType': value.bodyType,
        'yearManufactured': value.yearManufactured,
        'newOrUsed': NewOrUsedModelToJSON(value.newOrUsed),
        'engineSize': value.engineSize,
        'annualMileage': value.annualMileage,
        'registeredKeeper': value.registeredKeeper,
        'keeperAddress': AddressModelToJSON(value.keeperAddress),
        'hpiRegisteredDate': value.hpiRegisteredDate === undefined ? undefined : (value.hpiRegisteredDate.toISOString().substring(0,10)),
        'hpiRemovedDate': value.hpiRemovedDate === undefined ? undefined : (value.hpiRemovedDate.toISOString().substring(0,10)),
        'residualValue': value.residualValue,
        'purchaseOrderNumber': value.purchaseOrderNumber,
        'includeInsurance': value.includeInsurance,
        'category': CategoryModelToJSON(value.category),
        'banding': value.banding,
        'manufacturerSubsidyPaid': value.manufacturerSubsidyPaid,
    };
}

