import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { FinancialProductApi } from "generated/onboarding/apis";
import { UpdateFinancialProductModel } from "generated/onboarding/models";
import * as keys from "./keys";

const useUpdateFinancialProduct = (
  financialProductId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(FinancialProductApi);
  const partialMutate = usePartialMutate();

  return useCommand<UpdateFinancialProductModel, void, ResponseError>(
    (updateFinancialProductModel) =>
      api.updateFinancialProduct({
        financialProductId,
        updateFinancialProductModel,
      }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        partialMutate(keys.detail(financialProductId));
        partialMutate(keys.list());
      },
    },
  );
};

export default useUpdateFinancialProduct;
