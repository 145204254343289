import { createContext, useContext } from "react";

export interface AppConfigurationReturn {
  appRoutes: {
    admin: string;
    auth: string;
    loginPath: string;
    users: string;
    documents: string;
    onboarding: string;
    finance: string;
    reporting: string;
    servicing: string;
    thirdParties: string;
  };
}

const AppConfigurationContext = createContext<AppConfigurationReturn>({
  appRoutes: {
    admin: "/admin",
    auth: "/auth",
    loginPath: "/oauth2/authorization/engage",
    users: "/users",
    documents: "/documents",
    onboarding: "/onboarding",
    finance: "/finance",
    reporting: "/reporting",
    servicing: "/servicing",
    thirdParties: "/third-parties",
  },
});

export default function useAppConfiguration(): AppConfigurationReturn {
  return useContext(AppConfigurationContext);
}
