/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing settlement quotes within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountTypeModel } from './DiscountTypeModel';
import {
    DiscountTypeModelFromJSON,
    DiscountTypeModelFromJSONTyped,
    DiscountTypeModelToJSON,
} from './DiscountTypeModel';
import type { SettlementStatusModel } from './SettlementStatusModel';
import {
    SettlementStatusModelFromJSON,
    SettlementStatusModelFromJSONTyped,
    SettlementStatusModelToJSON,
} from './SettlementStatusModel';

/**
 * Settlement Quote Details
 * @export
 * @interface SettlementQuoteModel
 */
export interface SettlementQuoteModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof SettlementQuoteModel
     */
    settlementDate: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof SettlementQuoteModel
     */
    expiryDate: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    discountAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    discountPercentage?: string;
    /**
     * 
     * @type {DiscountTypeModel}
     * @memberof SettlementQuoteModel
     */
    discountType?: DiscountTypeModel;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    outstandingArrears: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    allFeesAndCharges?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    allFutureInstalmentAmounts: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    overPayments?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    vat?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    totalAmountDue: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    totalAmountToSettle: string;
    /**
     * 
     * @type {SettlementStatusModel}
     * @memberof SettlementQuoteModel
     */
    status: SettlementStatusModel;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    totalOutstandingCapitalInterest?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteModel
     */
    newPeriodicMonthlyInstalment?: string;
}

/**
 * Check if a given object implements the SettlementQuoteModel interface.
 */
export function instanceOfSettlementQuoteModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "settlementDate" in value;
    isInstance = isInstance && "expiryDate" in value;
    isInstance = isInstance && "outstandingArrears" in value;
    isInstance = isInstance && "allFutureInstalmentAmounts" in value;
    isInstance = isInstance && "totalAmountDue" in value;
    isInstance = isInstance && "totalAmountToSettle" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function SettlementQuoteModelFromJSON(json: any): SettlementQuoteModel {
    return SettlementQuoteModelFromJSONTyped(json, false);
}

export function SettlementQuoteModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementQuoteModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settlementDate': (new Date(json['settlementDate'])),
        'expiryDate': (new Date(json['expiryDate'])),
        'discountAmount': !exists(json, 'discountAmount') ? undefined : json['discountAmount'],
        'discountPercentage': !exists(json, 'discountPercentage') ? undefined : json['discountPercentage'],
        'discountType': !exists(json, 'discountType') ? undefined : DiscountTypeModelFromJSON(json['discountType']),
        'outstandingArrears': json['outstandingArrears'],
        'allFeesAndCharges': !exists(json, 'allFeesAndCharges') ? undefined : json['allFeesAndCharges'],
        'allFutureInstalmentAmounts': json['allFutureInstalmentAmounts'],
        'overPayments': !exists(json, 'overPayments') ? undefined : json['overPayments'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'totalAmountDue': json['totalAmountDue'],
        'totalAmountToSettle': json['totalAmountToSettle'],
        'status': SettlementStatusModelFromJSON(json['status']),
        'totalOutstandingCapitalInterest': !exists(json, 'totalOutstandingCapitalInterest') ? undefined : json['totalOutstandingCapitalInterest'],
        'newPeriodicMonthlyInstalment': !exists(json, 'newPeriodicMonthlyInstalment') ? undefined : json['newPeriodicMonthlyInstalment'],
    };
}

export function SettlementQuoteModelToJSON(value?: SettlementQuoteModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settlementDate': (value.settlementDate.toISOString().substring(0,10)),
        'expiryDate': (value.expiryDate.toISOString().substring(0,10)),
        'discountAmount': value.discountAmount,
        'discountPercentage': value.discountPercentage,
        'discountType': DiscountTypeModelToJSON(value.discountType),
        'outstandingArrears': value.outstandingArrears,
        'allFeesAndCharges': value.allFeesAndCharges,
        'allFutureInstalmentAmounts': value.allFutureInstalmentAmounts,
        'overPayments': value.overPayments,
        'vat': value.vat,
        'totalAmountDue': value.totalAmountDue,
        'totalAmountToSettle': value.totalAmountToSettle,
        'status': SettlementStatusModelToJSON(value.status),
        'totalOutstandingCapitalInterest': value.totalOutstandingCapitalInterest,
        'newPeriodicMonthlyInstalment': value.newPeriodicMonthlyInstalment,
    };
}

