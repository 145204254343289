import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import EditContactContainer from "apps/servicing/modules/customers/containers/EditContactContainer/EditContactContainer";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout/ViewLayout";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const EditContactView = () => {
  const { t } = useTranslation(["clients", "common"]);
  const customer = useCustomer();
  const { contactId } = useParams();

  return (
    <ViewLayout
      title={t("update_contact.title")}
      breadcrumbs={[
        { label: t("clients:breadcrumbs.contacts"), href: "../contacts" },
      ]}
    >
      <ContainedLayout>
        <EditContactContainer
          customerId={customer.data.systemId}
          contactId={String(contactId)}
        />
      </ContainedLayout>
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: ["servicing:contacts:manage"],
})(EditContactView);
