import { Form } from "@ldms/mui-sdk/forms";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import { EventTypeModel, PortfolioListItemModel } from "generated/core/models";
import { useTranslation } from "react-i18next";

export type AutomationFormFieldValues = {
  ruleName: string;
  description: string;
  portfolio: string;
  value: number;
  then: string;
  timeTrigger: string;
  when: EventTypeModel;
};

type AutomationSetUpFormProps = {
  onSubmit: (data: AutomationFormFieldValues) => void;
  portfolioList: PortfolioListItemModel[] | undefined;
  defaultValues: AutomationFormFieldValues;
};

const AutomationSetUpForm = ({
  onSubmit,
  portfolioList,
  defaultValues,
}: AutomationSetUpFormProps) => {
  const { t } = useTranslation("automations");

  const ruleNameLabel = "automation_set_up_form.rule_name_field_label";
  const valueLabel = "automation_set_up_form.value_field_label";
  const automationSetupFormTitle = "automation_set_up_form.title";

  const transformField = (v: number): number | undefined => {
    return isNaN(v) ? undefined : v;
  };

  const resolver = useYupResolver<AutomationFormFieldValues>((yup) =>
    yup.object().shape({
      ruleName: yup
        .string()
        .maxCharacters(50, t(ruleNameLabel))
        .isRequired(t(ruleNameLabel)),
      description: yup
        .string()
        .maxCharacters(
          200,
          t("automation_set_up_form.description_field_label"),
        ),
      portfolio: yup
        .string()
        .isRequired(t("automation_set_up_form.portfolio_field_label")),
      when: yup.string(),
      timeTrigger: yup.string(),
      value: yup
        .number(t(valueLabel))
        .transform(transformField)
        .isRequired(t(valueLabel))
        .minAmount(1, t(valueLabel))
        .maxAmount(5, t(valueLabel)),
      then: yup.string(),
    }),
  );

  return (
    <>
      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h2" }}>
          {t(automationSetupFormTitle)}
        </Typography>
      </Box>
      <Form
        label={t(automationSetupFormTitle)}
        title={t(automationSetupFormTitle)}
        defaultValues={defaultValues}
        resolver={resolver}
        onSubmit={onSubmit}
        options={{
          shouldUnregister: true,
        }}
      >
        {(form) => (
          <Stack gap={2}>
            <TextField
              {...form.register("ruleName")}
              label={t(ruleNameLabel)}
              required
              error={Boolean(form.formState.errors.ruleName?.message)}
              helperText={form.formState.errors.ruleName?.message}
              disabled={form.formState.isSubmitting}
            />
            <TextField
              {...form.register("description")}
              error={Boolean(form.formState.errors.description?.message)}
              helperText={form.formState.errors.description?.message}
              label={t("automation_set_up_form.description_field_label")}
              disabled={form.formState.isSubmitting}
            />
            <ControlledTextField
              label={t("automation_set_up_form.portfolio_field_label")}
              control={form.control}
              SelectProps={{ displayEmpty: true }}
              error={form.formState.errors.portfolio?.message}
              select
              name="portfolio"
              disabled={form.formState.isSubmitting}
              required
            >
              <MenuItem value="">{t("common:please_select")}</MenuItem>
              {portfolioList?.map((portfolio) => (
                <MenuItem key={portfolio.id} value={portfolio.id}>
                  {portfolio.name}
                </MenuItem>
              ))}
            </ControlledTextField>
            <Divider />
            <ControlledTextField
              label={t("automation_set_up_form.when_field_label")}
              control={form.control}
              SelectProps={{ displayEmpty: true }}
              select
              name="when"
              disabled
            >
              <MenuItem value={EventTypeModel.PaymentDue}>
                {t("automation_set_up_form.payment_due")}
              </MenuItem>
            </ControlledTextField>
            <ControlledTextField
              label={t("automation_set_up_form.time_trigger_field_label")}
              control={form.control}
              SelectProps={{ displayEmpty: true }}
              select
              disabled
              name="timeTrigger"
            >
              <MenuItem value="Days Before">
                {t("automation_set_up_form.days_before")}
              </MenuItem>
            </ControlledTextField>
            <TextField
              label={t(valueLabel)}
              error={Boolean(form.formState.errors.value?.message)}
              helperText={form.formState.errors.value?.message}
              type="number"
              required
              disabled={form.formState.isSubmitting}
              inputProps={{
                min: 1,
                max: 5,
              }}
              {...form.register("value")}
            />
            <Divider />
            <ControlledTextField
              label={t("automation_set_up_form.then_field_label")}
              control={form.control}
              SelectProps={{ displayEmpty: true }}
              select
              disabled
              name="then"
            >
              <MenuItem value="Send Email">
                {t("automation_set_up_form.send_email")}
              </MenuItem>
            </ControlledTextField>
            <Box alignSelf="flex-end">
              <Button
                variant="contained"
                disabled={form.formState.isSubmitting}
                type="submit"
              >
                {t("common:next")}
              </Button>
            </Box>
          </Stack>
        )}
      </Form>
    </>
  );
};

export default AutomationSetUpForm;
