import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Stack } from "@mui/material";
import { YesNoValue } from "common/components";
import useLocale from "common/hooks/useLocale";
import { NewOrUsedModel } from "generated/core/models/NewOrUsedModel";
import { Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface AssetOverview {
  purchaseOrderNumber?: string;
  type: string;
  description: string;
  serialNumber?: string;
  registrationNumber?: string;
  registrationDate?: Date;
  vehicleIdentificationNumber?: string;
  colour?: string;
  manufacturerCode?: string;
  manufacturer?: string;
  model?: string;
  bodyType?: string;
  yearManufactured?: string;
  newOrUsed: NewOrUsedModel;
  engineSize?: number;
  annualMileage?: number;
  hpiRegisteredDate?: Date;
  hpiRemovedDate?: Date;
  registeredKeeper?: string;
  keeperAddress?: {
    addressLine1: string;
    addressLine2: string;
    addressLine3?: string;
    addressLine4?: string;
    postcode: string;
  };
  purchasePrice?: string;
  depositPaid?: string;
  partExValue?: string;
  tradeInSum?: string;
  dealerIncentive?: string;
  documentFee?: string;
  facilityFee?: string;
  chargeForCredit?: string;
  totalAmountPayable?: string;
  settledDate?: Date;
  residualValue?: string;
  servicePlan?: boolean;
  includeInsurance?: boolean;
  category?: string;
  banding?: string;
  fleetDiscount?: string;
  invoicePrice?: string;
  manufacturerName?: string;
  manufacturerSubsidyAmount?: string;
  manufacturerSchemeCode?: string;
  manufacturerSubsidyVatRate?: number;
  manufacturerSubsidyPaid?: boolean;
}

const createDateFormatter =
  (format: (date: Date) => string) =>
  (date?: Date): string =>
    date ? format(date) : "";

interface AssetOverviewDescriptionProps {
  overview: AssetOverview;
}

export default function AssetOverviewDescription({
  overview,
}: AssetOverviewDescriptionProps): ReactElement {
  const { t } = useTranslation("assets");
  const locale = useLocale();
  const { formatAmount } = useFormat();
  const formatDate = createDateFormatter(locale.formatDate);

  return (
    <Stack spacing={3}>
      <DescriptionList label={t("asset_overview.details_description_label")}>
        <DescriptionList.Item label={t("details.purchase_order_number")}>
          {overview.purchaseOrderNumber}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.type")}>
          {overview.type}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.description")}>
          {overview.description}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.serial_number")}>
          {overview.serialNumber}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.registration_number")}>
          {overview.registrationNumber}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.registration_date")}>
          {formatDate(overview.registrationDate)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.vin")}>
          {overview.vehicleIdentificationNumber}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.colour")}>
          {overview.colour}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.manufacturer")}>
          {overview.manufacturerCode && overview.manufacturer
            ? `${overview.manufacturerCode} - ${overview.manufacturer}`
            : undefined}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.model")}>
          {overview.model}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.body_type")}>
          {overview.bodyType}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.year_manufactured")}>
          {overview.yearManufactured}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.new_used")}>
          {overview.newOrUsed}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.engine_size")}>
          {overview.engineSize}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.annual_mileage")}>
          {overview.annualMileage}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.registered_interest")}>
          {formatDate(overview.hpiRegisteredDate)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.registered_interest_removed")}>
          {formatDate(overview.hpiRemovedDate)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.registered_keeper")}>
          {overview.registeredKeeper}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.keeper_address.label")}>
          {Object.values(overview.keeperAddress ?? {})
            .filter(Boolean)
            .join(", ")}
        </DescriptionList.Item>
      </DescriptionList>

      <DescriptionList label={t("asset_overview.costs_description_label")}>
        <DescriptionList.Item label={t("details.purchase_price")}>
          {formatAmount(overview.purchasePrice)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.fleet_discount")}>
          {formatAmount(overview.fleetDiscount)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.asset_invoice_price")}>
          {formatAmount(overview.invoicePrice)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.deposit_paid")}>
          {formatAmount(overview.depositPaid)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.part_exchange_value")}>
          {formatAmount(overview.partExValue)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.trade_in_sum")}>
          {formatAmount(overview.tradeInSum)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.dealer_incentive")}>
          {formatAmount(overview.dealerIncentive)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.document_fee")}>
          {formatAmount(overview.documentFee)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.facility_fee")}>
          {formatAmount(overview.facilityFee)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.charge_for_credit")}>
          {formatAmount(overview.chargeForCredit)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.total_amount_payable")}>
          {formatAmount(overview.totalAmountPayable)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.settled_date")}>
          {formatDate(overview.settledDate)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.residual_value")}>
          {formatAmount(overview.residualValue)}
        </DescriptionList.Item>
        {typeof overview.includeInsurance !== "undefined" ? (
          <DescriptionList.Item label={t("details.include_insurance_label")}>
            <YesNoValue value={overview.includeInsurance} />
          </DescriptionList.Item>
        ) : (
          <Fragment />
        )}
        <DescriptionList.Item label={t("details.asset_category_label")}>
          {overview.category}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.asset_banding_label")}>
          {overview.banding}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.manufacturer_name_label")}>
          {overview.manufacturerName}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.manufacturer_subsidy_label")}>
          {formatAmount(overview.manufacturerSubsidyAmount)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.manufacturer_scheme_label")}>
          {overview.manufacturerSchemeCode}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.manufacturer_vat_label")}>
          {overview.manufacturerSubsidyVatRate !== undefined
            ? `${formatAmount(overview.manufacturerSubsidyVatRate)}%`
            : undefined}
        </DescriptionList.Item>
        <DescriptionList.Item label={t("details.manufacturer_paid_label")}>
          <YesNoValue value={overview.manufacturerSubsidyPaid} />
        </DescriptionList.Item>
      </DescriptionList>
    </Stack>
  );
}
