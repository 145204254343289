import { QueryKey } from "api";
import { keys } from "api/customers";

export const all = (customerId: string): QueryKey => [
  ...keys.detail(customerId),
  "memos",
];

export const list = (
  customerId: string,
  params: { from?: Date } = {},
): QueryKey => [...all(customerId), "list", params.from];
