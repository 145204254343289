import { LocaleDropdown } from "@ldms/mui-sdk/bootstrap";
import { IconTooltipButton } from "@ldms/mui-sdk/components";
import { ExitToApp as ExitToAppIcon } from "@mui/icons-material";
import { FC } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

const UserMenu: FC = () => {
  const [cookies] = useCookies();
  const { i18n, t } = useTranslation("shell");

  const onLocaleChange = (localeCode: string) => {
    i18n.changeLanguage(localeCode);
  };

  return (
    <>
      <LocaleDropdown locale={i18n.language} onChange={onLocaleChange} />
      <form aria-label={t("logout_button")} action="/logout" method="POST">
        <input
          name="_csrf"
          value={cookies["XSRF-TOKEN"]}
          type="hidden"
          data-testid="csrf"
        />
        <IconTooltipButton title={t("logout_button")} type="submit">
          <ExitToAppIcon />
        </IconTooltipButton>
      </form>
    </>
  );
};

export default UserMenu;
