import AdminApp from "apps/admin";
import DocumentsApp from "apps/documents";
import FinanceApp from "apps/finance";
import OnboardingApp from "apps/onboarding";
import ReportingApp from "apps/reporting";
import ServicingApp from "apps/servicing";
import StartApp from "apps/start";
import EnvironmentProvider from "common/providers/EnvironmentProvider/EnvironmentProvider";
import { Route, Routes } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

const App = () => {
  return (
    <Routes>
      <Route
        path="admin/*"
        element={
          <EnvironmentProvider application="admin">
            <AdminApp />
          </EnvironmentProvider>
        }
      />
      <Route
        path="documents/*"
        element={
          <EnvironmentProvider application="documents">
            <DocumentsApp />
          </EnvironmentProvider>
        }
      />
      <Route
        path="servicing/*"
        element={
          <EnvironmentProvider application="servicing">
            <ServicingApp />
          </EnvironmentProvider>
        }
      />
      <Route
        path="finance/*"
        element={
          <EnvironmentProvider application="finance">
            <FinanceApp />
          </EnvironmentProvider>
        }
      />
      <Route
        path="onboarding/*"
        element={
          <EnvironmentProvider application="onboarding">
            <OnboardingApp />
          </EnvironmentProvider>
        }
      />
      <Route
        path="reporting/*"
        element={
          <EnvironmentProvider application="reporting">
            <ReportingApp />
          </EnvironmentProvider>
        }
      />
      <Route
        path="*"
        element={
          <EnvironmentProvider application="global">
            <StartApp />
          </EnvironmentProvider>
        }
      />
    </Routes>
  );
};

export default withTranslationLoader("common")(App);
