/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgreementAttachmentListItemModel,
  FileTypeModel,
  PreviewDocumentModel,
} from '../models/index';
import {
    AgreementAttachmentListItemModelFromJSON,
    AgreementAttachmentListItemModelToJSON,
    FileTypeModelFromJSON,
    FileTypeModelToJSON,
    PreviewDocumentModelFromJSON,
    PreviewDocumentModelToJSON,
} from '../models/index';

export interface ListAgreementAttachmentsRequest {
    agreementId: number;
    type?: string;
    from?: Date;
    to?: Date;
}

export interface PreviewDocumentRequest {
    agreementId: number;
    fileType?: FileTypeModel;
    previewDocumentModel?: PreviewDocumentModel;
}

/**
 * 
 */
export class AgreementAttachmentQueryApi extends runtime.BaseAPI {

    /**
     * List agreement attachments
     */
    async listAgreementAttachmentsRaw(requestParameters: ListAgreementAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgreementAttachmentListItemModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAgreementAttachments.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-documents:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/attachments`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgreementAttachmentListItemModelFromJSON));
    }

    /**
     * List agreement attachments
     */
    async listAgreementAttachments(requestParameters: ListAgreementAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgreementAttachmentListItemModel>> {
        const response = await this.listAgreementAttachmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Preview a Document
     */
    async previewDocumentRaw(requestParameters: PreviewDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling previewDocument.');
        }

        const queryParameters: any = {};

        if (requestParameters.fileType !== undefined) {
            queryParameters['fileType'] = requestParameters.fileType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-documents:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/documents/preview`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreviewDocumentModelToJSON(requestParameters.previewDocumentModel),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Preview a Document
     */
    async previewDocument(requestParameters: PreviewDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.previewDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
