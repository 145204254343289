/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Attachments within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDocumentModel
 */
export interface CreateDocumentModel {
    /**
     * The UUID of the template to use when generating
     * @type {string}
     * @memberof CreateDocumentModel
     */
    templateId: string;
    /**
     * The description associated to the file
     * @type {string}
     * @memberof CreateDocumentModel
     */
    description: string;
    /**
     * Free text to be included in the resulting document
     * @type {string}
     * @memberof CreateDocumentModel
     */
    freeText?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof CreateDocumentModel
     */
    fromDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof CreateDocumentModel
     */
    toDate?: Date;
}

/**
 * Check if a given object implements the CreateDocumentModel interface.
 */
export function instanceOfCreateDocumentModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateId" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function CreateDocumentModelFromJSON(json: any): CreateDocumentModel {
    return CreateDocumentModelFromJSONTyped(json, false);
}

export function CreateDocumentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDocumentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateId': json['templateId'],
        'description': json['description'],
        'freeText': !exists(json, 'freeText') ? undefined : json['freeText'],
        'fromDate': !exists(json, 'fromDate') ? undefined : (new Date(json['fromDate'])),
        'toDate': !exists(json, 'toDate') ? undefined : (new Date(json['toDate'])),
    };
}

export function CreateDocumentModelToJSON(value?: CreateDocumentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateId': value.templateId,
        'description': value.description,
        'freeText': value.freeText,
        'fromDate': value.fromDate === undefined ? undefined : (value.fromDate.toISOString().substring(0,10)),
        'toDate': value.toDate === undefined ? undefined : (value.toDate.toISOString().substring(0,10)),
    };
}

