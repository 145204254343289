import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import {
  AlertDialog,
  ConfirmationDialog,
  Loader,
} from "@ldms/mui-sdk/templates";
import { Delete } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useDeleteContact } from "api/customers/contacts/deleteContact";
import { useListContacts } from "api/customers/contacts/listContacts";
import { ContactDetails } from "apps/servicing/modules/customers/containers";
import Surface from "common/components/Surface";
import useConfirm from "common/hooks/useConfirm";
import useResponseError from "common/hooks/useResponseError";
import { ContactsListItemModel } from "generated/servicing-v2/models/ContactsListItemModel";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

const DeleteContact = ({
  customerId,
  contactId,
}: {
  customerId: string;
  contactId: string;
}) => {
  const { t } = useTranslation("clients");
  const responseError = useResponseError();
  const deleteContact = useDeleteContact(customerId, contactId, {
    onError: (error) => responseError.setError(error.code),
  });
  const confirm = useConfirm();

  const handleClick = () => confirm.handlePrompt(() => deleteContact.execute());

  return (
    <>
      <IconButton
        color="primary"
        edge="end"
        onClick={handleClick}
        size="small"
        aria-label={t("contacts.delete")}
      >
        <Delete fontSize="small" />
      </IconButton>

      <AlertDialog
        content={responseError.message}
        labels={{ close: t("common:alert.close") }}
        onClose={responseError.reset}
        open={Boolean(responseError.message)}
        title={t("contacts.delete_error_title")}
      />

      <ConfirmationDialog
        content={t("contacts.delete_confirmation.message")}
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("contacts.delete_confirmation.title_label")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />
    </>
  );
};

interface ContactProps {
  clientSystemId: string;
}

export default function Contacts({
  clientSystemId,
}: Readonly<ContactProps>): ReactElement {
  const { t } = useTranslation("clients");
  const contacts = useListContacts(clientSystemId);
  const [contactId, setContactId] = useState<string>();

  const mainContact = contacts.data?.find((contact) => contact.isCustomer);
  const firstContact = contacts.data?.[0];
  const currentContact =
    contacts.data?.find((contact) => contact.systemId === contactId) ||
    mainContact ||
    firstContact;

  const handleContactTypeChange =
    (contactListItem: ContactsListItemModel) => (): void => {
      setContactId(contactListItem.systemId);
    };

  const renderContacts = (): ReactElement => {
    if (!contacts.data || contacts.error) {
      return (
        <Typography color="error" data-testid="contacts.error">
          {t("common:error.default")}
        </Typography>
      );
    }

    if (contacts.data.length === 0 || !currentContact) {
      return (
        <Box
          alignItems="center"
          display="flex"
          height={420}
          justifyContent="center"
        >
          <Typography color="textSecondary">
            {t("no_client_contacts")}
          </Typography>
        </Box>
      );
    }

    return (
      <Box display="flex" justifyContent="center">
        <Grid container spacing={4}>
          <Grid item>
            <Box width={300}>
              <Surface
                disableGutters
                title={t("contacts.contact_list_heading")}
              >
                <List dense disablePadding>
                  {[...contacts.data]
                    .sort((a) =>
                      a.systemId === mainContact?.systemId ? -1 : 1,
                    )
                    .map((contact) => (
                      <ListItemButton
                        onClick={handleContactTypeChange(contact)}
                        selected={currentContact?.systemId === contact.systemId}
                        key={contact.systemId}
                        disableGutters
                        sx={{ paddingY: 0 }}
                      >
                        <ListItem
                          secondaryAction={
                            !contact.isCustomer && (
                              <AccessControl
                                allowedPermissions={[
                                  "servicing:contacts:manage",
                                ]}
                              >
                                <DeleteContact
                                  customerId={clientSystemId}
                                  contactId={contact.systemId}
                                />
                              </AccessControl>
                            )
                          }
                        >
                          <ListItemText
                            primary={contact.isCustomer ? "Main" : contact.type}
                            secondary={
                              contact.companyName
                                ? contact.companyName
                                : [
                                    contact.firstName,
                                    contact.secondName,
                                    contact.lastName,
                                  ]
                                    .filter(Boolean)
                                    .join(" ")
                            }
                          />
                        </ListItem>
                      </ListItemButton>
                    ))}
                </List>
              </Surface>
            </Box>
          </Grid>
          <Grid item xs>
            <Loader
              fallback={
                <Box display="flex" justifyContent="center" p={2}>
                  <CircularProgress />
                </Box>
              }
              ready={Boolean(currentContact)}
              render={(): ReactElement => (
                <ContactDetails
                  clientSystemId={clientSystemId}
                  contact={currentContact}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Loader
      fallback={
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      }
      ready={Boolean(contacts.data || contacts.error)}
      render={renderContacts}
    />
  );
}
