import { TablePagination } from "@mui/material";
import { CustomerList, QueryError } from "common/components";
import { Customer } from "common/components/CustomerList";
import { ChangeEventHandler, MouseEvent, ReactElement } from "react";

interface PaginatedCustomerListProps {
  data: Customer[] | undefined;
  error: Error | undefined;
  rowsPerPage: number;
  page: number;
  total: number;
  isLoading: boolean;
  onRetry: () => Promise<void>;
  onChangePage(_: MouseEvent<HTMLButtonElement> | null, page: number): void;
  onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement>;
}

export default function PaginatedCustomerList({
  data,
  error,
  rowsPerPage,
  page,
  total,
  isLoading,
  onRetry,
  onChangePage,
  onChangeRowsPerPage,
}: PaginatedCustomerListProps): ReactElement {
  return (
    <>
      {!!error || !data ? (
        <QueryError onRetry={onRetry} />
      ) : (
        <>
          <CustomerList data={data} loading={isLoading} />
          <TablePagination
            component="div"
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            count={total || 0}
          />
        </>
      )}
    </>
  );
}
