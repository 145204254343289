import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import { NavLink, resolvePath, useLocation } from "react-router-dom";

interface SidebarNavigationProps {
  heading?: string;
  menu: { label: string; to: string; display: boolean }[];
}

export default function SidebarNavigation({
  heading,
  menu,
}: SidebarNavigationProps): ReactElement {
  const location = useLocation();

  return (
    <Box component="nav" aria-labelledby="sidebar-navigation-heading">
      <Box paddingX={3}>
        <Typography
          color="textSecondary"
          id="sidebar-navigation-heading"
          variant="overline"
        >
          {heading}
        </Typography>
      </Box>

      <Box paddingX={1}>
        <List>
          {menu.map(
            (item) =>
              item.display && (
                <li key={item.label}>
                  <ListItemButton
                    component={NavLink}
                    role="link"
                    selected={location.pathname.includes(
                      resolvePath(item.to).pathname,
                    )}
                    sx={{ borderRadius: 1, py: 1 }}
                    to={item.to}
                  >
                    <ListItemText primary={item.label} sx={{ my: 0 }} />
                  </ListItemButton>
                </li>
              ),
          )}
        </List>
      </Box>
    </Box>
  );
}
