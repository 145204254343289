import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Button, Typography } from "@mui/material";
import { useApproveOnboarding } from "api/onboarding/approveOnboarding";
import { useGetOnboarding } from "api/onboarding/getOnboarding";
import { useConfirm, useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementApi } from "generated/onboarding/apis";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import errorHandler from "support/error-handler";

interface ApproveOnboardingContainerProps {
  onboardingId: number;
}

export default function ApproveOnboardingContainer({
  onboardingId,
}: ApproveOnboardingContainerProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const agreementApi = useApi(AgreementApi);
  const confirm = useConfirm();
  const responseError = useResponseError();
  const [isAlertOpen, setAlertOpen] = useState(false);
  const navigate = useNavigate();
  const closeLabel = t("common:alert.close");
  const errorTitleLabel = t("approve.error_title");

  const handleAgreementNumberExists = async (
    agreementNumber: string | undefined,
  ): Promise<boolean> => {
    responseError.reset();
    if (agreementNumber) {
      try {
        const response = await agreementApi.findOnboardedAgreementsRaw({
          agreementNumber,
        });

        if (Number(response.raw.headers.get("X-Total-Size")) > 0) {
          setAlertOpen(true);
          return true;
        }

        return false;
      } catch (error) {
        responseError.setError((await errorHandler(error)).code);
        return true;
      }
    }
    return false;
  };

  const onSuccess = (): void => {
    navigate("..");
  };

  const approveOnboarding = useApproveOnboarding(onboardingId, {
    onSuccess,
  });

  const onboarding = useGetOnboarding(onboardingId);

  const onApproveOnboarding = async (): Promise<void> => {
    const agreementExists = await handleAgreementNumberExists(
      onboarding.data?.agreementNumber,
    );

    if (!agreementExists) {
      confirm.handlePrompt(() => approveOnboarding.execute());
    }
  };

  const onCloseErrorDialog = (): void => {
    approveOnboarding.reset();
  };

  const handleDialogClose = (): void => {
    setAlertOpen(false);
  };

  return (
    <>
      <Button
        aria-label={t("approve.button_label")}
        variant="contained"
        onClick={onApproveOnboarding}
      >
        {t("approve.button_label")}
      </Button>

      <ConfirmationDialog
        content={<Typography>{t("approve.confirmation_message")}</Typography>}
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("approve.confirmation_title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        content={approveOnboarding.error?.message}
        labels={{ close: closeLabel }}
        onClose={onCloseErrorDialog}
        open={Boolean(approveOnboarding.error)}
        title={errorTitleLabel}
      />

      <AlertDialog
        content={t("approve.duplicate_agreement_number")}
        labels={{ close: closeLabel }}
        onClose={handleDialogClose}
        open={isAlertOpen}
        title={errorTitleLabel}
      />

      <AlertDialog
        content={responseError.message}
        labels={{ close: closeLabel }}
        onClose={responseError.reset}
        open={Boolean(responseError.message)}
        title={errorTitleLabel}
      />
    </>
  );
}
