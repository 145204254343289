import { useListFinancialPostings } from "api/financial-postings";
import { FinancialPostingsParameters } from "apps/finance/views/FinancialPostingsView/FinancialPostingsView";
import { Loader, QueryError } from "common/components";
import FinancialPostingList from "common/components/FinancialPostingList";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";

interface FinancialPostingsContainerProps {
  financialPostingsParameters: FinancialPostingsParameters;
}

export default function FinancialPostingsContainer({
  financialPostingsParameters,
}: FinancialPostingsContainerProps): ReactElement {
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const financialPostings = useListFinancialPostings({
    pageSize: rowsPerPage,
    params: {
      agreementId: financialPostingsParameters.agreementId,
      portfolioId: financialPostingsParameters.portfolioId,
      stageCode: financialPostingsParameters.stageCode,
      accountCode: financialPostingsParameters.accountCode,
      sourceCode: financialPostingsParameters.sourceCode,
      from: financialPostingsParameters.from,
      to: financialPostingsParameters.to,
    },
  });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => financialPostings.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Loader
      ready={Boolean(financialPostings.error ?? financialPostings.data)}
      render={(): ReactElement => {
        if (!financialPostings.data || financialPostings.error) {
          return <QueryError onRetry={financialPostings.refetch} />;
        }

        return (
          <FinancialPostingList
            data={financialPostings.data?.results}
            error={financialPostings.error}
            onRetry={financialPostings.refetch}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            page={financialPostings.paging.page}
            total={financialPostings.data?.paging.total || 0}
            loading={financialPostings.isValidating}
            rowsPerPage={rowsPerPage}
          />
        );
      }}
    />
  );
}
