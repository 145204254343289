import { ApiResponse } from "generated";
import download from "support/download";

export default async function fileDownloader(
  response: ApiResponse<void | string | Blob>,
): Promise<void> {
  const filename = String(
    response.raw.headers.get("Content-Disposition"),
  ).split("filename=")[1];
  const blob = await response.raw.blob();
  const url = window.URL.createObjectURL(blob);
  download(url, filename);
}
