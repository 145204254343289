/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RepaymentMonthModel = {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December'
} as const;
export type RepaymentMonthModel = typeof RepaymentMonthModel[keyof typeof RepaymentMonthModel];


export function RepaymentMonthModelFromJSON(json: any): RepaymentMonthModel {
    return RepaymentMonthModelFromJSONTyped(json, false);
}

export function RepaymentMonthModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepaymentMonthModel {
    return json as RepaymentMonthModel;
}

export function RepaymentMonthModelToJSON(value?: RepaymentMonthModel | null): any {
    return value as any;
}

