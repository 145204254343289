import { Box, Container, Grid, Paper } from "@mui/material";
import { useCreateAutomation } from "api/automations/createAutomation";
import { useListPortfolios } from "api/portfolios/listPortfolios";
import AutomationActionDetailsForm from "apps/admin/components/AutomationActionDetailsForm/AutomationActionDetailsForm";
import AutomationSetUpForm from "apps/admin/components/AutomationSetupForm/AutomationSetupForm";
import AutomationsStepper from "apps/admin/components/AutomationStepper/AutomationStepper";
import { Loader, QueryError } from "common/components";
import { useResponseError } from "common/hooks";
import {
  DirectDebitPayerFilterModel,
  EventTypeModel,
} from "generated/core/models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export type AutomationActionDetailsFormFieldValues = {
  to: string;
  from: string;
  emailTemplate?: File;
  conditions: string;
};

type AutomationFormFieldValues = {
  ruleName: string;
  description: string;
  portfolio: string;
  value: number;
  then: string;
  timeTrigger: string;
  when: EventTypeModel;
};

const CreateAutomationsContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("automations");
  const listPortfolios = useListPortfolios();

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<AutomationFormFieldValues>({
    description: "",
    portfolio: "",
    ruleName: "",
    then: "Send Email",
    timeTrigger: "Days Before",
    value: 3,
    when: "Payment Due",
  });

  const createAutomation = useCreateAutomation({
    onSuccess: (response) => {
      navigate(`..${response.raw.headers.get("location")}`);
    },
    onError: (response) => {
      responseError.setError(response.code);
    },
  });

  const responseError = useResponseError([
    ["INVALID_FILE_SIZE", t("file_size_zero_error")],
    ["INVALID_FILE", t("file_type_upload_error")],
    ["INVALID_FILE_CONTENT_TYPE", t("file_type_upload_error")],
  ]);

  const handleStepChange = (step: number) => {
    setStep(step);
    createAutomation.reset();
  };

  const onSetUpFormSubmit = (data: AutomationFormFieldValues) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
    setStep(1);
  };

  const handleBackStep = () => {
    setStep(0);
    createAutomation.reset();
  };
  const handleActionDetailsSubmit = (
    data: AutomationActionDetailsFormFieldValues,
  ) => {
    createAutomation.reset();
    return createAutomation.execute({
      createAutomationModel: {
        event: { type: formData.when },
        name: formData.ruleName,
        description: formData.description,
        portfolioId: Number(formData.portfolio),
        parameters: [
          { key: "value", value: String(formData.value) },
          { key: "then", value: formData.then },
          { key: "correspondence_method", value: "Email" },
          { key: "to", value: data.to },
          { key: "from.address", value: data.from },
          { key: "time_trigger", value: formData.timeTrigger },
          { key: "direct_debit.payer.filter", value: data.conditions },
        ],
      },
      emailTemplate: data.emailTemplate,
    });
  };

  return (
    <Loader
      ready={Boolean(listPortfolios.data ?? listPortfolios.error)}
      render={() => {
        if (!listPortfolios.data || listPortfolios.error) {
          return <QueryError onRetry={listPortfolios.refetch} />;
        }
        return (
          <Container sx={{ margin: 0 }}>
            <Grid container>
              <Grid item width={200}>
                <AutomationsStepper
                  onStepChange={handleStepChange}
                  step={step}
                />
              </Grid>
              <Grid item xs>
                <Container maxWidth="sm">
                  <Paper>
                    <Box p={2}>
                      {step === 0 && (
                        <AutomationSetUpForm
                          onSubmit={onSetUpFormSubmit}
                          portfolioList={listPortfolios.data}
                          defaultValues={formData}
                        />
                      )}
                      {step === 1 && (
                        <AutomationActionDetailsForm
                          onBackStep={handleBackStep}
                          responseError={responseError}
                          isEmail={formData.then === "Send Email"}
                          onSubmit={handleActionDetailsSubmit}
                          defaultValues={{
                            from: "",
                            to: "Main Contact",
                            conditions:
                              DirectDebitPayerFilterModel.NoExclusions,
                          }}
                        />
                      )}
                    </Box>
                  </Paper>
                </Container>
              </Grid>
            </Grid>
          </Container>
        );
      }}
    />
  );
};

export default CreateAutomationsContainer;
