import { AmountField } from "@ldms/mui-sdk/forms";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { RepaymentTermsFormModel } from "apps/onboarding/containers";
import React from "react";
import { Control, FieldErrors } from "support/react-hook-form";

interface SeasonalPaymentsSetProps {
  labels: {
    title: string;
    january: string;
    february: string;
    march: string;
    april: string;
    may: string;
    june: string;
    july: string;
    august: string;
    september: string;
    october: string;
    november: string;
    december: string;
  };
  errors: FieldErrors<RepaymentTermsFormModel>;
  control: Control<RepaymentTermsFormModel>;
}

export default function SeasonalPaymentsFieldSet(
  props: SeasonalPaymentsSetProps,
): React.ReactElement {
  return (
    <>
      <Box marginY={3}>
        <Divider />
      </Box>

      <Grid container>
        <Grid item sm={6}>
          <Typography variant="h6">{props.labels.title}</Typography>
        </Grid>
        <Grid item sm={6}>
          <AmountField
            error={Boolean(props.errors?.january?.message)}
            helperText={props.errors?.january?.message}
            control={props.control}
            label={props.labels.january}
            name="january"
            required
          />
          <AmountField
            error={Boolean(props.errors?.february?.message)}
            helperText={props.errors?.february?.message}
            control={props.control}
            label={props.labels.february}
            name="february"
            required
          />
          <AmountField
            error={Boolean(props.errors?.march?.message)}
            helperText={props.errors?.march?.message}
            control={props.control}
            label={props.labels.march}
            name="march"
            required
          />
          <AmountField
            error={Boolean(props.errors?.april?.message)}
            helperText={props.errors?.april?.message}
            control={props.control}
            label={props.labels.april}
            name="april"
            required
          />
          <AmountField
            error={Boolean(props.errors?.may?.message)}
            helperText={props.errors?.may?.message}
            control={props.control}
            label={props.labels.may}
            name="may"
            required
          />
          <AmountField
            error={Boolean(props.errors?.june?.message)}
            helperText={props.errors?.june?.message}
            control={props.control}
            label={props.labels.june}
            name="june"
            required
          />
          <AmountField
            error={Boolean(props.errors?.july?.message)}
            helperText={props.errors?.july?.message}
            control={props.control}
            label={props.labels.july}
            name="july"
            required
          />
          <AmountField
            error={Boolean(props.errors?.august?.message)}
            helperText={props.errors?.august?.message}
            control={props.control}
            label={props.labels.august}
            name="august"
            required
          />
          <AmountField
            error={Boolean(props.errors?.september?.message)}
            helperText={props.errors?.september?.message}
            control={props.control}
            label={props.labels.september}
            name="september"
            required
          />
          <AmountField
            error={Boolean(props.errors?.october?.message)}
            helperText={props.errors?.october?.message}
            control={props.control}
            label={props.labels.october}
            name="october"
            required
          />
          <AmountField
            error={Boolean(props.errors?.november?.message)}
            helperText={props.errors?.november?.message}
            control={props.control}
            label={props.labels.november}
            name="november"
            required
          />
          <AmountField
            error={Boolean(props.errors?.december?.message)}
            helperText={props.errors?.december?.message}
            control={props.control}
            label={props.labels.december}
            name="december"
            required
          />
        </Grid>
      </Grid>
    </>
  );
}
