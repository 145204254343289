import TerminationRecoveryDetailsContainer from "apps/servicing/modules/agreements/containers/TerminationRecoveryDetailsContainer";
import { withModifiableAccess } from "apps/servicing/modules/agreements/hocs/withModifiableAccess";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";

function TerminationAndWriteOffView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();

  return (
    <>
      <ViewLayout title={t("terminations.heading")}>
        <ContainedLayout>
          <TerminationRecoveryDetailsContainer agreementId={agreement.id} />
        </ContainedLayout>
      </ViewLayout>
    </>
  );
}

export default withModifiableAccess(TerminationAndWriteOffView);
