/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Calculate partial settlement quote
 * @export
 * @interface CalculatePartialSettlementQuoteModel
 */
export interface CalculatePartialSettlementQuoteModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CalculatePartialSettlementQuoteModel
     */
    vatAmount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CalculatePartialSettlementQuoteModel
     */
    totalSettlementAmount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CalculatePartialSettlementQuoteModel
     */
    partialSettlementRebate: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CalculatePartialSettlementQuoteModel
     */
    agreementInstalmentAmount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CalculatePartialSettlementQuoteModel
     */
    assetInstalmentAmount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CalculatePartialSettlementQuoteModel
     */
    assetBalloonAmount: string;
}

/**
 * Check if a given object implements the CalculatePartialSettlementQuoteModel interface.
 */
export function instanceOfCalculatePartialSettlementQuoteModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vatAmount" in value;
    isInstance = isInstance && "totalSettlementAmount" in value;
    isInstance = isInstance && "partialSettlementRebate" in value;
    isInstance = isInstance && "agreementInstalmentAmount" in value;
    isInstance = isInstance && "assetInstalmentAmount" in value;
    isInstance = isInstance && "assetBalloonAmount" in value;

    return isInstance;
}

export function CalculatePartialSettlementQuoteModelFromJSON(json: any): CalculatePartialSettlementQuoteModel {
    return CalculatePartialSettlementQuoteModelFromJSONTyped(json, false);
}

export function CalculatePartialSettlementQuoteModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculatePartialSettlementQuoteModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vatAmount': json['vatAmount'],
        'totalSettlementAmount': json['totalSettlementAmount'],
        'partialSettlementRebate': json['partialSettlementRebate'],
        'agreementInstalmentAmount': json['agreementInstalmentAmount'],
        'assetInstalmentAmount': json['assetInstalmentAmount'],
        'assetBalloonAmount': json['assetBalloonAmount'],
    };
}

export function CalculatePartialSettlementQuoteModelToJSON(value?: CalculatePartialSettlementQuoteModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vatAmount': value.vatAmount,
        'totalSettlementAmount': value.totalSettlementAmount,
        'partialSettlementRebate': value.partialSettlementRebate,
        'agreementInstalmentAmount': value.agreementInstalmentAmount,
        'assetInstalmentAmount': value.assetInstalmentAmount,
        'assetBalloonAmount': value.assetBalloonAmount,
    };
}

