/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactPointListItemModel } from './ContactPointListItemModel';
import {
    ContactPointListItemModelFromJSON,
    ContactPointListItemModelFromJSONTyped,
    ContactPointListItemModelToJSON,
} from './ContactPointListItemModel';

/**
 * The contact
 * @export
 * @interface CustomerContactWithContactPointsListItemModel
 */
export interface CustomerContactWithContactPointsListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof CustomerContactWithContactPointsListItemModel
     */
    readonly id: number;
    /**
     * The client type
     * @type {string}
     * @memberof CustomerContactWithContactPointsListItemModel
     */
    type: string;
    /**
     * 
     * @type {Array<ContactPointListItemModel>}
     * @memberof CustomerContactWithContactPointsListItemModel
     */
    contactPoints: Array<ContactPointListItemModel>;
}

/**
 * Check if a given object implements the CustomerContactWithContactPointsListItemModel interface.
 */
export function instanceOfCustomerContactWithContactPointsListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "contactPoints" in value;

    return isInstance;
}

export function CustomerContactWithContactPointsListItemModelFromJSON(json: any): CustomerContactWithContactPointsListItemModel {
    return CustomerContactWithContactPointsListItemModelFromJSONTyped(json, false);
}

export function CustomerContactWithContactPointsListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerContactWithContactPointsListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'contactPoints': ((json['contactPoints'] as Array<any>).map(ContactPointListItemModelFromJSON)),
    };
}

export function CustomerContactWithContactPointsListItemModelToJSON(value?: CustomerContactWithContactPointsListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'contactPoints': ((value.contactPoints as Array<any>).map(ContactPointListItemModelToJSON)),
    };
}

