import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { CompaniesApi } from "generated/servicing-v2/apis";
import { CompanyModel } from "generated/servicing-v2/models/CompanyModel";
import { ErrorLike } from "support/error-handler";
import { keys } from ".";

type UseGetCompany = (
  companyId: string,
) => UseQueryReturn<CompanyModel, ErrorLike>;

export const useGetCompany: UseGetCompany = (companyId: string) => {
  const api = useApi(CompaniesApi, "v2");

  return useQuery(keys.detail(companyId), () =>
    api.getCompany({ id: companyId }),
  );
};
