import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface CustomerDescriptionProps {
  customer?: {
    companyType: string;
    companyRegistrationNumber?: string;
    vatRegistrationNumber?: string;
    name: string;
    companyName?: string;
    isIndividual: boolean;
    address?: {
      addressLine1?: string;
      addressLine2?: string;
      addressLine3?: string;
      addressLine4?: string;
      postcode?: string;
    };
    telephoneNumber?: string;
    email?: string;
    obligorRiskRating?: number;
  };
}

export default function CustomerDescription({
  customer,
}: CustomerDescriptionProps): ReactElement {
  const { t } = useTranslation("customers");
  const { formatNumber } = useFormat();

  const name = customer?.isIndividual ? customer?.name : customer?.companyName;

  const displayName =
    customer?.isIndividual && customer?.companyName
      ? `${name} (${customer?.companyName})`
      : name;

  return (
    <DescriptionList label={t("overview.customer_details.heading")}>
      <DescriptionList.Item label={t("overview.customer_details.name_label")}>
        {displayName}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("overview.customer_details.company_type_label")}
      >
        {customer?.companyType}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("overview.customer_details.address_label")}
      >
        {[
          customer?.address?.addressLine1,
          customer?.address?.addressLine2,
          customer?.address?.addressLine3,
          customer?.address?.addressLine4,
          customer?.address?.postcode,
        ]
          .filter(Boolean)
          .join(", ")}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("overview.customer_details.company_registration_number_label")}
      >
        {customer?.companyRegistrationNumber}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("overview.customer_details.vat_registration_number_label")}
      >
        {customer?.vatRegistrationNumber}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("overview.customer_details.telephone_label")}
      >
        {customer?.telephoneNumber}
      </DescriptionList.Item>
      <DescriptionList.Item label={t("overview.customer_details.email_label")}>
        {customer?.email}
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("overview.customer_details.obligor_risk_rating_label")}
      >
        {formatNumber(customer?.obligorRiskRating)}
      </DescriptionList.Item>
    </DescriptionList>
  );
}
