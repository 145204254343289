import { DescriptionList, EditButton } from "@ldms/mui-sdk/components";
import { Loader } from "@ldms/mui-sdk/templates";
import { useGetCompany } from "api/companies";
import { QueryError } from "common/components";
import Surface from "common/components/Surface";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface CompanyDetailsContainerProps {
  companyId: string;
}

export default function CompanyDetailsContainer({
  companyId,
}: CompanyDetailsContainerProps): ReactElement {
  const { t } = useTranslation("servicing");
  const company = useGetCompany(companyId);
  const appConfig = useAppConfiguration();
  const href = `${appConfig.appRoutes.servicing}/settings/companies/${companyId}/edit-company`;

  const renderCompanyDetails = (): ReactElement => (
    <Surface
      title={t("companies.company_details.title_label")}
      action={<EditButton component={RouterLink} to={href} />}
    >
      <DescriptionList label={t("companies.company_details.title_label")}>
        <DescriptionList.Item
          label={t("companies.company_details.registration_number_label")}
        >
          {company.data?.registrationNumber}
        </DescriptionList.Item>
        <DescriptionList.Item
          label={t("companies.company_details.vat_number_label")}
        >
          {company.data?.taxRegistrationNumber}
        </DescriptionList.Item>
        <DescriptionList.Item
          label={t("companies.company_details.currency_label")}
        >
          {company.data?.currency}
        </DescriptionList.Item>
        <DescriptionList.Item
          label={t("companies.company_details.cais_source_code_label")}
        >
          {company.data?.caisSourceCode}
        </DescriptionList.Item>
        <DescriptionList.Item
          label={t("companies.company_details.address_label")}
        >
          {[
            company.data?.addressLine1,
            company.data?.addressLine2,
            company.data?.addressLine3,
            company.data?.addressLine4,
            company.data?.postcode,
          ]
            .filter(Boolean)
            .join(", ")}
        </DescriptionList.Item>
        <DescriptionList.Item
          label={t("companies.company_details.vat_code_label")}
        >
          {company.data?.taxCodeDescription}
        </DescriptionList.Item>
        <DescriptionList.Item
          label={t("companies.company_details.origination_commission_label")}
        >
          {company.data?.originationCommissionPercentage &&
            Number(company.data?.originationCommissionPercentage).toFixed(2)}
        </DescriptionList.Item>
      </DescriptionList>
    </Surface>
  );

  return (
    <Loader
      ready={Boolean(company.data || company.error)}
      render={(): ReactElement => {
        if (company.error || !company.data) {
          return <QueryError onRetry={company.refetch} />;
        }
        return renderCompanyDetails();
      }}
    />
  );
}
