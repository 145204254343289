/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StatusModel } from './StatusModel';
import {
    StatusModelFromJSON,
    StatusModelFromJSONTyped,
    StatusModelToJSON,
} from './StatusModel';

/**
 * Update status
 * @export
 * @interface UpdateStatusModel
 */
export interface UpdateStatusModel {
    /**
     * 
     * @type {StatusModel}
     * @memberof UpdateStatusModel
     */
    status: StatusModel;
}

/**
 * Check if a given object implements the UpdateStatusModel interface.
 */
export function instanceOfUpdateStatusModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function UpdateStatusModelFromJSON(json: any): UpdateStatusModel {
    return UpdateStatusModelFromJSONTyped(json, false);
}

export function UpdateStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStatusModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': StatusModelFromJSON(json['status']),
    };
}

export function UpdateStatusModelToJSON(value?: UpdateStatusModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': StatusModelToJSON(value.status),
    };
}

