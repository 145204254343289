import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import AddPortfolioBankAccountContainer from "apps/servicing/modules/settings/containers/AddPortfolioBankAccountContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

function AddPortfolioBankAccountView() {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();

  return (
    <main>
      <ViewLayout
        title={t("bank_accounts.add_bank_account.title_label")}
        breadcrumbs={[
          {
            href: `${appConfig.appRoutes.servicing}/settings/bank-accounts`,
            label: t("bank_accounts.breadcrumb_link_label"),
          },
        ]}
      >
        <ContainedLayout>
          <AddPortfolioBankAccountContainer />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:bank-accounts:elevated"],
})(AddPortfolioBankAccountView);
