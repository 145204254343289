/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The charge type
 * @export
 * @interface ChargeTypeModel
 */
export interface ChargeTypeModel {
    /**
     * The charge type code
     * @type {string}
     * @memberof ChargeTypeModel
     */
    code: string;
    /**
     * The charge type name
     * @type {string}
     * @memberof ChargeTypeModel
     */
    name: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ChargeTypeModel
     */
    amount?: string;
    /**
     * Indicates if a cheque will be Required, Optional or Not Applicable on application of the charge
     * @type {string}
     * @memberof ChargeTypeModel
     */
    payeeDetails: ChargeTypeModelPayeeDetailsEnum;
    /**
     * The code for the type of charge
     * @type {string}
     * @memberof ChargeTypeModel
     */
    typeCode: string;
    /**
     * The description for the type of charge
     * @type {string}
     * @memberof ChargeTypeModel
     */
    typeDescription: string;
    /**
     * The default name for which any cheque raised will be made payable to
     * @type {string}
     * @memberof ChargeTypeModel
     */
    defaultPayee?: string;
}


/**
 * @export
 */
export const ChargeTypeModelPayeeDetailsEnum = {
    Required: 'Required',
    Optional: 'Optional',
    NotApplicable: 'Not Applicable'
} as const;
export type ChargeTypeModelPayeeDetailsEnum = typeof ChargeTypeModelPayeeDetailsEnum[keyof typeof ChargeTypeModelPayeeDetailsEnum];


/**
 * Check if a given object implements the ChargeTypeModel interface.
 */
export function instanceOfChargeTypeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "payeeDetails" in value;
    isInstance = isInstance && "typeCode" in value;
    isInstance = isInstance && "typeDescription" in value;

    return isInstance;
}

export function ChargeTypeModelFromJSON(json: any): ChargeTypeModel {
    return ChargeTypeModelFromJSONTyped(json, false);
}

export function ChargeTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeTypeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'payeeDetails': json['payeeDetails'],
        'typeCode': json['typeCode'],
        'typeDescription': json['typeDescription'],
        'defaultPayee': !exists(json, 'defaultPayee') ? undefined : json['defaultPayee'],
    };
}

export function ChargeTypeModelToJSON(value?: ChargeTypeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'amount': value.amount,
        'payeeDetails': value.payeeDetails,
        'typeCode': value.typeCode,
        'typeDescription': value.typeDescription,
        'defaultPayee': value.defaultPayee,
    };
}

