/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Payment Instructions within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPaymentInstructionsAllOfBankModel
 */
export interface GetPaymentInstructionsAllOfBankModel {
    /**
     * The name of the bank
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfBankModel
     */
    name?: string;
    /**
     * The name associated to the bank account
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfBankModel
     */
    accountHoldersName?: string;
    /**
     * the sort code
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfBankModel
     */
    sortCode?: string;
    /**
     * the account number
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfBankModel
     */
    accountNumber?: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfBankModel
     */
    bic?: string;
    /**
     * The international bank account number
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfBankModel
     */
    iban?: string;
}

/**
 * Check if a given object implements the GetPaymentInstructionsAllOfBankModel interface.
 */
export function instanceOfGetPaymentInstructionsAllOfBankModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetPaymentInstructionsAllOfBankModelFromJSON(json: any): GetPaymentInstructionsAllOfBankModel {
    return GetPaymentInstructionsAllOfBankModelFromJSONTyped(json, false);
}

export function GetPaymentInstructionsAllOfBankModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentInstructionsAllOfBankModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'accountHoldersName': !exists(json, 'accountHoldersName') ? undefined : json['accountHoldersName'],
        'sortCode': !exists(json, 'sortCode') ? undefined : json['sortCode'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
    };
}

export function GetPaymentInstructionsAllOfBankModelToJSON(value?: GetPaymentInstructionsAllOfBankModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'accountHoldersName': value.accountHoldersName,
        'sortCode': value.sortCode,
        'accountNumber': value.accountNumber,
        'bic': value.bic,
        'iban': value.iban,
    };
}

