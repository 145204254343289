/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Attachments within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreviewDocumentModel
 */
export interface PreviewDocumentModel {
    /**
     * The UUID of the template to use when generating
     * @type {string}
     * @memberof PreviewDocumentModel
     */
    templateId: string;
    /**
     * Free text to be included in the resulting document
     * @type {string}
     * @memberof PreviewDocumentModel
     */
    freeText?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PreviewDocumentModel
     */
    fromDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PreviewDocumentModel
     */
    toDate?: Date;
}

/**
 * Check if a given object implements the PreviewDocumentModel interface.
 */
export function instanceOfPreviewDocumentModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "templateId" in value;

    return isInstance;
}

export function PreviewDocumentModelFromJSON(json: any): PreviewDocumentModel {
    return PreviewDocumentModelFromJSONTyped(json, false);
}

export function PreviewDocumentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewDocumentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateId': json['templateId'],
        'freeText': !exists(json, 'freeText') ? undefined : json['freeText'],
        'fromDate': !exists(json, 'fromDate') ? undefined : (new Date(json['fromDate'])),
        'toDate': !exists(json, 'toDate') ? undefined : (new Date(json['toDate'])),
    };
}

export function PreviewDocumentModelToJSON(value?: PreviewDocumentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateId': value.templateId,
        'freeText': value.freeText,
        'fromDate': value.fromDate === undefined ? undefined : (value.fromDate.toISOString().substring(0,10)),
        'toDate': value.toDate === undefined ? undefined : (value.toDate.toISOString().substring(0,10)),
    };
}

