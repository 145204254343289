import { Loader } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useListClientAssets } from "api/customers/assets";
import AssetList from "apps/servicing/components/AssetList";
import AssetListLayout from "apps/servicing/layouts/AssetListLayout";
import { ClientAssetListItemModel } from "generated/core/models/ClientAssetListItemModel";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

const mapAssetsToList = (
  assets: ClientAssetListItemModel[],
  basePath: string,
) =>
  Array.from(new Set(assets.map((asset) => asset.agreement?.number ?? ""))).map(
    (agreement) => ({
      agreementNumber: agreement,
      assets: assets
        .filter((asset) => asset.agreement?.number === agreement)
        .sort((a, b) => ((a.assetNumber || 0) > (b.assetNumber || 0) ? 1 : -1))
        .map((asset) => ({
          ...asset,
          assetUrl: `${basePath}?selectedAsset=${asset.id}`,
        })),
    }),
  );

interface ClientAssetsProps {
  clientId: number;
}

interface ClientAssetsParameters {
  live?: boolean;
}

export default function ClientAssets({
  clientId,
}: ClientAssetsProps): ReactElement {
  const { t, ready } = useTranslation(["customers", "assets"]);
  const [parameters, setParameters] = useState<ClientAssetsParameters>({
    live: undefined,
  });

  const assets = useListClientAssets(clientId, parameters);

  return (
    <AssetListLayout onParameterChange={setParameters} parameters={parameters}>
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(assets.data ?? assets.error) && ready}
        render={() => {
          if (assets.error || !assets.data) {
            return (
              <Typography color="error" data-testid="assetsList.error">
                {t("common:error.default")}
              </Typography>
            );
          }

          return (
            <AssetList
              data={mapAssetsToList(assets.data, ".")}
              label={t("assets.list_label")}
              loading={assets.isValidating}
            />
          );
        }}
      />
    </AssetListLayout>
  );
}
