import {
  CommercialLoanFinancialDetails,
  CommercialLoanFinancialDetailsFormModel,
  FinanceLeaseFinancialDetails,
  FinanceLeaseFinancialDetailsFormModel,
  HirePurchaseFinancialDetails,
  HirePurchaseFinancialDetailsFormModel,
  OperatingLeaseFinancialDetails,
  OperatingLeaseFinancialDetailsFormModel,
} from "apps/onboarding/components";
import { useOnboarding } from "apps/onboarding/providers";
import { ProductTypeModel } from "generated/onboarding/models";
import { ReactElement } from "react";

export type FinancialDetailsFormModel =
  | HirePurchaseFinancialDetailsFormModel
  | FinanceLeaseFinancialDetailsFormModel
  | OperatingLeaseFinancialDetailsFormModel
  | CommercialLoanFinancialDetailsFormModel;

export default function FinancialDetailsStep(): ReactElement {
  const onboarding = useOnboarding();
  const product = onboarding.state.agreementDetails
    ?.productType as ProductTypeModel;

  if (product === ProductTypeModel.FixedRateHirePurchase) {
    return <HirePurchaseFinancialDetails />;
  }

  if (product === ProductTypeModel.FixedRateFinanceLease) {
    return <FinanceLeaseFinancialDetails />;
  }

  if (product === ProductTypeModel.FixedRateOperatingLease) {
    return <OperatingLeaseFinancialDetails />;
  }

  return <CommercialLoanFinancialDetails />;
}
