/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Portfolios within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortfolioListItemModel
 */
export interface PortfolioListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof PortfolioListItemModel
     */
    readonly id: number;
    /**
     * A read only Id
     * @type {string}
     * @memberof PortfolioListItemModel
     */
    readonly systemId: string;
    /**
     * The portfolio name
     * @type {string}
     * @memberof PortfolioListItemModel
     */
    name: string;
    /**
     * The Equifax Insight Portfolio number
     * @type {string}
     * @memberof PortfolioListItemModel
     */
    equifaxInsightNumber?: string;
    /**
     * CCR Provider Code
     * @type {string}
     * @memberof PortfolioListItemModel
     */
    ccrProviderCode?: string;
    /**
     * The current applicable tax rate for the portfolio
     * @type {number}
     * @memberof PortfolioListItemModel
     */
    currentTaxRate: number;
    /**
     * The default asset depreciation percentage
     * @type {number}
     * @memberof PortfolioListItemModel
     */
    depreciationPercentage?: number;
    /**
     * The default asset depreciation minimum value
     * @type {number}
     * @memberof PortfolioListItemModel
     */
    depreciationMinimumValue?: number;
}

/**
 * Check if a given object implements the PortfolioListItemModel interface.
 */
export function instanceOfPortfolioListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "currentTaxRate" in value;

    return isInstance;
}

export function PortfolioListItemModelFromJSON(json: any): PortfolioListItemModel {
    return PortfolioListItemModelFromJSONTyped(json, false);
}

export function PortfolioListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemId': json['systemId'],
        'name': json['name'],
        'equifaxInsightNumber': !exists(json, 'equifaxInsightNumber') ? undefined : json['equifaxInsightNumber'],
        'ccrProviderCode': !exists(json, 'ccrProviderCode') ? undefined : json['ccrProviderCode'],
        'currentTaxRate': json['currentTaxRate'],
        'depreciationPercentage': !exists(json, 'depreciationPercentage') ? undefined : json['depreciationPercentage'],
        'depreciationMinimumValue': !exists(json, 'depreciationMinimumValue') ? undefined : json['depreciationMinimumValue'],
    };
}

export function PortfolioListItemModelToJSON(value?: PortfolioListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'equifaxInsightNumber': value.equifaxInsightNumber,
        'ccrProviderCode': value.ccrProviderCode,
        'currentTaxRate': value.currentTaxRate,
        'depreciationPercentage': value.depreciationPercentage,
        'depreciationMinimumValue': value.depreciationMinimumValue,
    };
}

