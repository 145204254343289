import { Alert, AlertTitle, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function PasswordCriteriaAlert(): ReactElement {
  const { t } = useTranslation();

  return (
    <Alert severity="info" icon={<Info />}>
      <AlertTitle aria-label="password-criteria">
        {t("users:password_criteria")}
      </AlertTitle>
      <ul>
        {[
          t("users:password_rules.minimum_characters"),
          t("users:password_rules.minimum_numeric"),
          t("users:password_rules.minimum_casing"),
        ].map((content) => (
          <Typography component="li" variant="body2" key={content}>
            {content}
          </Typography>
        ))}
      </ul>
    </Alert>
  );
}
