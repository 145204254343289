import { DateField } from "@ldms/mui-sdk/forms";
import { Grid, MenuItem, TextField } from "@mui/material";
import { BatchStatus } from "apps/documents/types";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface BatchFilterProps {
  types?: string[];
  filters: {
    status?: string;
    type?: string;
    from?: Date;
    to?: Date;
  };
  onChange(key: string, value: string | Date | undefined): void;
}

export default function BatchFilter({
  types,
  filters,
  onChange,
}: Readonly<BatchFilterProps>): ReactElement {
  const { t } = useTranslation("documents");

  const makeDateChangeHandler =
    (name: string) =>
    (date: Date | null): void => {
      onChange(name, date ?? undefined);
    };

  const handleTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target.value === "" ? undefined : event.target.value;
    onChange(event.target.name, value);
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <DateField
          name="from"
          label={t("batches.from")}
          inputProps={{
            max: filters.to,
          }}
          onChange={makeDateChangeHandler("from")}
          margin="none"
          value={filters.from}
        />
      </Grid>
      <Grid item>
        <DateField
          name="to"
          label={t("batches.to")}
          inputProps={{
            min: filters.from,
            max: new Date().toISOString().split("T")[0],
          }}
          onChange={makeDateChangeHandler("to")}
          margin="none"
          value={filters.to}
        />
      </Grid>
      <Grid item>
        <TextField
          InputLabelProps={{
            htmlFor: "batchTypeFilter",
            shrink: true,
          }}
          inputProps={{ displayEmpty: true, id: "batchTypeFilter" }}
          onChange={handleTypeChange}
          label={t("batches.type")}
          name="type"
          select
          size="small"
          value={filters.type ?? ""}
          variant="outlined"
          margin="none"
        >
          <MenuItem value="" key="">
            {t("batches.all_batch_types")}
          </MenuItem>
          {types?.map((type) => (
            <MenuItem value={type} key={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          InputLabelProps={{
            htmlFor: "batchTypeFilter",
            shrink: true,
          }}
          inputProps={{ displayEmpty: true, id: "batchTypeFilter" }}
          onChange={handleTypeChange}
          label={t("batches.status")}
          name="status"
          select
          size="small"
          value={filters.status ?? ""}
          variant="outlined"
          margin="none"
        >
          <MenuItem value="" key="">
            {t("batches.all_batch_statuses")}
          </MenuItem>
          {Object.values(BatchStatus).map((status) => (
            <MenuItem value={status} key={status}>
              {status}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}
