import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers/ApiProvider";
import { BankAccountsApi } from "generated/servicing-v2/apis";
import { BankAccountDetailsModel } from "generated/servicing-v2/models";
import { ErrorLike } from "support/error-handler";
import * as keys from "./keys";

type UseGetBankAccountDetails = (
  id: string,
) => UseQueryReturn<BankAccountDetailsModel, ErrorLike>;

export const useGetBankAccountDetails: UseGetBankAccountDetails = (id) => {
  const api = useApi(BankAccountsApi, "v2");

  return useQuery(keys.detail(id), () => api.getBankAccountDetails({ id }));
};
