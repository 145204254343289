/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Tax Codes within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxCodeListItemModel
 */
export interface TaxCodeListItemModel {
    /**
     * Unique identifier of Vat Code
     * @type {string}
     * @memberof TaxCodeListItemModel
     */
    systemId: string;
    /**
     * The description of the tax code
     * @type {string}
     * @memberof TaxCodeListItemModel
     */
    name: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof TaxCodeListItemModel
     */
    effectiveDate: Date;
    /**
     * Actual Rate of the tax
     * @type {number}
     * @memberof TaxCodeListItemModel
     */
    currentRate: number;
}

/**
 * Check if a given object implements the TaxCodeListItemModel interface.
 */
export function instanceOfTaxCodeListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "systemId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "effectiveDate" in value;
    isInstance = isInstance && "currentRate" in value;

    return isInstance;
}

export function TaxCodeListItemModelFromJSON(json: any): TaxCodeListItemModel {
    return TaxCodeListItemModelFromJSONTyped(json, false);
}

export function TaxCodeListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxCodeListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemId': json['systemId'],
        'name': json['name'],
        'effectiveDate': (new Date(json['effectiveDate'])),
        'currentRate': json['currentRate'],
    };
}

export function TaxCodeListItemModelToJSON(value?: TaxCodeListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'systemId': value.systemId,
        'name': value.name,
        'effectiveDate': (value.effectiveDate.toISOString().substring(0,10)),
        'currentRate': value.currentRate,
    };
}

