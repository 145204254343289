import { ConfirmationDialog, FormDialog } from "@ldms/mui-sdk/templates";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useListAgreements } from "api/agreements";
import { useTransferPayment } from "api/agreements/transactions/transfer";
import { Transaction } from "apps/servicing/modules/agreements/components";
import { useConfirm, useResponseError, useYupResolver } from "common/hooks";
import { useAgreement } from "common/providers";
import _ from "lodash";
import { ReactElement, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface TransferPaymentFormValues {
  agreementIdToTransfer: {
    number: string;
    id: number;
  } | null;
  comment: string;
}

interface TransferPaymentProps {
  transactionId: number;
  transactions?: Transaction[];
  open: boolean;
  onClose(): void;
  onSubmit(): void;
}

export default function TransferPayment({
  transactionId,
  open,
  onClose,
  onSubmit: onSubmitCallback,
}: TransferPaymentProps): ReactElement {
  const [query, setQuery] = useState("");
  const agreement = useAgreement();
  const { t } = useTranslation("agreements");
  const confirm = useConfirm();

  const error = useResponseError([
    [
      "no_such_agreement",
      t("transactions.transfer_payment.no_such_agreement_exception"),
    ],
  ]);

  const transferPayment = useTransferPayment(agreement.id, {
    onSuccess: () => {
      agreement.mutate();
      onSubmitCallback();
    },
    onError: (response) => {
      error.setError(response.code);
    },
  });

  const agreements = useListAgreements({
    params: { enabled: query.trim().length > 1, query: query },
  });

  const agreementIdLabel = t(
    "transactions.transfer_payment.agreement_number_label",
  );

  const resolver = useYupResolver<TransferPaymentFormValues>((yup) => {
    return yup.object().shape({
      agreementIdToTransfer: yup
        .object()
        .nullable()
        .required(
          t("common:validation.is_required", {
            label: agreementIdLabel,
          }),
        )
        .shape({
          id: yup.number(agreementIdLabel),
          number: yup.string(),
        }),
      comment: yup
        .string()
        .maxCharacters(4000, t("transactions.transfer_payment.comment_label")),
    });
  });

  const onSubmit = (data: TransferPaymentFormValues) => {
    confirm.handlePrompt(() => {
      return transferPayment.execute({
        transactionId,
        agreementId: data.agreementIdToTransfer?.id as number,
        comment: data.comment,
      });
    });
  };

  const handleQueryChange = useMemo(
    () => _.debounce((_event, newValue) => setQuery(newValue), 300),
    [],
  );

  const agreementOptions = (agreements.data?.results ?? [])
    .map((agreement) => ({
      number: agreement.number,
      id: agreement.id,
    }))
    .filter((agreementOption) => agreementOption.id !== agreement.id);

  return (
    <FormDialog
      onClose={onClose}
      open={open}
      title={t("transactions.transfer_payment.transfer_payment_dialog_title")}
      onSubmit={onSubmit}
      resolver={resolver}
      ready={!transferPayment.isExecuting}
      defaultValues={{
        agreementIdToTransfer: null,
        comment: "",
      }}
    >
      {(form) => {
        return (
          <>
            <Controller
              control={form.control}
              name="agreementIdToTransfer"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  options={agreementOptions}
                  value={value}
                  noOptionsText={t(
                    "transactions.transfer_payment.no_options_text",
                  )}
                  onChange={(_, newValue) => onChange(newValue)}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.number as string}
                  onInputChange={handleQueryChange}
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      {...params}
                      value={query}
                      label={agreementIdLabel}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(
                        form.formState.errors.agreementIdToTransfer,
                      )}
                      helperText={
                        form.formState.errors.agreementIdToTransfer?.message
                      }
                      margin="none"
                      inputProps={{
                        ...inputProps,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {agreements.isValidating && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
            <TextField
              {...form.register("comment")}
              error={Boolean(form.formState.errors.comment)}
              helperText={form.formState.errors.comment?.message}
              label={t("transactions.transfer_payment.comment_label")}
              rows={3}
              multiline
            />
            {error.message && (
              <Typography color="error">{error.message}</Typography>
            )}
            <ConfirmationDialog
              content={t(
                "transactions.transfer_payment.confirmation_description",
                { agreement: form.watch("agreementIdToTransfer.number") },
              )}
              title={t("transactions.transfer_payment.confirmation_title")}
              onConfirm={confirm.handleConfirm}
              onReject={confirm.handleReject}
              open={confirm.isOpen}
              labels={{
                confirm: t("common:yes"),
                reject: t("common:no"),
              }}
            />
          </>
        );
      }}
    </FormDialog>
  );
}
