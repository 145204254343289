import { useSession, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Box } from "@mui/material";
import UnexpectedError from "common/components/UnexpectedError";
import SidebarNavigation from "common/containers/SidebarNavigation";
import SidebarLayout from "common/layouts/SidebarLayout";
import { compose } from "lodash/fp";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

function SettingsLayout() {
  const { t } = useTranslation("onboardings");
  const session = useSession();

  const makeItem = (label: string, to: string, display = true) => {
    return { label: t(label), to, display };
  };

  const navigationMenu = [
    makeItem(
      "navigation.links.fees",
      "./fees",
      session.hasPermission("onboarding:fees:manage"),
    ),
    makeItem(
      "navigation.links.financial_products",
      "./financial-products",
      session.hasPermission("onboarding:financial-products:manage"),
    ),
  ];

  return (
    <SidebarLayout
      title={t("settings.app_title")}
      sidebar={<SidebarNavigation menu={navigationMenu} />}
    >
      <Box component="main" height="100%">
        <ErrorBoundary FallbackComponent={UnexpectedError}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </SidebarLayout>
  );
}

export default compose(
  withTranslationLoader(["onboarding", "users"]),
  withProtectedView({
    allowedPermissions: [
      "onboarding:fees:manage",
      "onboarding:financial-products:manage",
    ],
  }),
)(SettingsLayout);
