import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { Typography } from "@mui/material";
import { useListValuations } from "api/assets/valuations/listValuations";
import ValuationsList from "apps/servicing/components/ValuationsList";
import AddAssetValuationsContainer from "apps/servicing/containers/AddAssetValuationsContainer";
import { Loader } from "common/components";
import ListLayout from "common/layouts/ListLayout";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface AssetValuationsContainerProps {
  assetId: number;
  isModifiable: boolean;
}

export default function AssetValuationsContainer({
  assetId,
  isModifiable,
}: AssetValuationsContainerProps): ReactElement {
  const { t } = useTranslation("assets");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const valuationsList = useListValuations({
    pageSize: rowsPerPage,
    params: {
      assetId: assetId,
    },
  });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => {
    valuationsList.paging.setPage(page);
  };

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <ListLayout
      actions={
        <AccessControl allowedPermissions={["servicing:valuations:manage"]}>
          <AddAssetValuationsContainer
            assetId={assetId}
            overwrite={valuationsList.data?.results.length !== 0}
            isModifiable={isModifiable}
          />
        </AccessControl>
      }
      pagination={{
        onPageChange: onChangePage,
        onRowsPerPageChange: onChangeRowsPerPage,
        rowsPerPage: rowsPerPage,
        page: valuationsList.paging.page,
        count: valuationsList.data?.paging.total,
      }}
    >
      <Loader
        ready={Boolean(valuationsList.data ?? valuationsList.error)}
        render={(): ReactElement => {
          if (valuationsList.error || !valuationsList.data) {
            return (
              <Typography color="error" data-testid="contacts.error">
                {t("common:error.default")}
              </Typography>
            );
          }

          return (
            <ValuationsList
              data={valuationsList.data.results}
              isValidating={valuationsList.isValidating}
              assetId={assetId}
              isModifiable={isModifiable}
            />
          );
        }}
      />
    </ListLayout>
  );
}
