import {
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import ListLayout from "common/layouts/ListLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface AgreementCashFlow {
  id: number;
  number?: string;
  customer: { id: number; name?: string; companyName?: string };
}

interface AgreementListProps {
  data: AgreementCashFlow[];
  loading: boolean;
  onSelectAgreement(id: number): void;
  selected: number | null;
}

const customerName = (agreement: AgreementCashFlow): string | undefined =>
  agreement.customer.name
    ? agreement.customer.name
    : agreement.customer.companyName;

const displayName = (agreement: AgreementCashFlow): string | undefined =>
  agreement.customer.companyName && agreement.customer.name
    ? `${agreement.customer.name} (${agreement.customer.companyName})`
    : customerName(agreement);

export default function AgreementCashFlowList({
  data,
  loading,
  onSelectAgreement,
  selected = null,
}: AgreementListProps): ReactElement {
  const { t } = useTranslation("reporting");

  const handleSelectAgreement = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = event.target;
    onSelectAgreement(Number(value));
  };

  const sxCol = { minWidth: 240 };

  return (
    <ListLayout>
      <RadioGroup
        aria-label={t("report_dialog.agreement_cash_flow.radio_group_label")}
        name="agreement-cash-flow"
        onChange={handleSelectAgreement}
        value={selected}
      >
        <Table
          stickyHeader
          aria-label={t("report_dialog.agreement_cash_flow.title")}
        >
          <LoadableTableHead
            loading={loading}
            headings={[
              "",
              t("report_dialog.agreement_cash_flow.number_heading"),
              t("report_dialog.agreement_cash_flow.customer_heading"),
            ]}
          />
          <TableBody>
            {data.map((agreement) => (
              <TableRow key={agreement.id} hover>
                <TableCell padding="checkbox">
                  <Radio
                    value={agreement.id}
                    checked={agreement.id === selected}
                  />
                </TableCell>
                <TableCell sx={sxCol}>{agreement.number}</TableCell>
                <TableCell sx={sxCol}>{displayName(agreement)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </RadioGroup>
      {data.length === 0 && <NoResults />}
    </ListLayout>
  );
}
