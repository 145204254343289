import { useQuery } from "@ldms/mui-sdk/cqrs";
import * as agreementKeys from "api/agreements/payment-instructions/keys";
import { useApi } from "common/providers/ApiProvider";
import { AgreementPaymentInstructionsApi } from "generated/servicing-v2/apis";

export const useListPaymentInstructions = (id: string) => {
  const api = useApi(AgreementPaymentInstructionsApi, "v2");
  return useQuery(agreementKeys.list(id), () =>
    api.listPaymentInstructions({
      id,
    }),
  );
};
