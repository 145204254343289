import { UseQueryReturn, useQuery } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { PortfolioQueryApi } from "generated/core/apis";
import { PortfolioListItemModel } from "generated/core/models";
import * as keys from "./keys";

type UseListPortfolios = () => UseQueryReturn<PortfolioListItemModel[]>;

const useListPortfolios: UseListPortfolios = () => {
  const api = useApi(PortfolioQueryApi);

  return useQuery(keys.list(), () => api.listPortfolios());
};

export { useListPortfolios };
