import { EditButton } from "@ldms/mui-sdk/components";
import { ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useListPortfolioBankAccountsV2 } from "api/portfolio-bank-accounts/listPortfolioBankAccounts";
import { useUpdatePortfolio } from "api/portfolios";
import { useGetPortfolioV2 } from "api/portfolios/getPortfolio";
import usePortfolioResolver, {
  PortfolioFieldValues,
} from "apps/servicing/modules/settings/schema";
import { ControlledTextField, Loader } from "common/components";
import { useConfirm } from "common/hooks";
import {
  PortfolioBankAccountListItemModel,
  PortfolioModel,
} from "generated/servicing-v2/models";
import { ChangeEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm, UseFormReturn } from "support/react-hook-form";

interface UpdatePortfoliosContainerProps {
  portfolioId: string;
}

interface UpdatePortfolioProps {
  onSuccess(): void;
  onClose(): void;
  open: boolean;
  portfolioId: string;
}

interface UpdatePortfolioDialogBodyProp {
  defaultValues?: Partial<PortfolioFieldValues>;
  onSubmit(data: PortfolioFieldValues): Promise<void>;
  error?: string;
  bankAccounts: PortfolioBankAccountListItemModel[];
  onClose(): void;
}

const annualisedStraightLine = "Annualised Straight-line";

function UpdatePortfolioDialogContainer({
  onSuccess,
  onClose,
  open,
  portfolioId,
}: UpdatePortfolioProps): ReactElement {
  const { t } = useTranslation("servicing");
  const bankAccounts = useListPortfolioBankAccountsV2();
  const portfolio = useGetPortfolioV2(portfolioId);
  const confirm = useConfirm();

  const updatePortfolio = useUpdatePortfolio(portfolioId, {
    onSuccess,
  });

  const onSubmit = async (data: PortfolioFieldValues): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      const updatePortfolioModel: PortfolioModel = {
        ...data,
        portfolioBankAccountId: data.portfolioBankAccountId,
      };
      await updatePortfolio.command(updatePortfolioModel);
    });
  };

  return (
    <>
      <ConfirmationDialog
        content={
          <Typography>
            {t("companies.update_portfolio.confirmation_message")}
          </Typography>
        }
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("companies.update_portfolio.confirmation_title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="update-portfolio-title"
      >
        <DialogTitle id="update-portfolio-title">
          <Box alignItems="center" display="flex">
            <Box flexGrow={1}>
              {t("companies.update_portfolio.title_label")}
            </Box>
            <Box>
              <IconButton
                aria-label={t("common:close")}
                onClick={onClose}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <Loader
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
          ready={
            Boolean(bankAccounts.data && portfolio.data) ||
            Boolean(bankAccounts.error) ||
            Boolean(portfolio.error)
          }
          render={(): ReactElement =>
            bankAccounts.error || portfolio.error ? (
              <Typography color="error" role="alert">
                {t("common:error.default")}
              </Typography>
            ) : (
              <UpdatePortfolioDialogBody
                onSubmit={onSubmit}
                defaultValues={{
                  name: portfolio.data?.name,
                  isHpiRegistered: portfolio.data?.isHpiRegistered,
                  portfolioBankAccountId: portfolio.data?.bankAccount.systemId
                    ? portfolio.data?.bankAccount.systemId
                    : "",
                  equifaxInsightNumber: portfolio.data?.equifaxInsightNumber,
                  ccrProviderCode: portfolio.data?.ccrProviderCode,
                  assetDepreciationModel:
                    portfolio.data?.depreciationMinimumValue ||
                    portfolio.data?.depreciationPercentage
                      ? annualisedStraightLine
                      : "None",
                  depreciationPercentage:
                    portfolio.data?.depreciationPercentage ?? undefined,

                  depreciationMinimumValue:
                    portfolio.data?.depreciationMinimumValue ?? undefined,
                }}
                error={updatePortfolio.error?.message}
                bankAccounts={bankAccounts.data?.results ?? []}
                onClose={onClose}
              />
            )
          }
        />
      </Dialog>
    </>
  );
}

function UpdatePortfolioDialogBody({
  defaultValues,
  onSubmit,
  error,
  bankAccounts,
  onClose,
}: UpdatePortfolioDialogBodyProp): ReactElement {
  const { t } = useTranslation("servicing");
  const resolver = usePortfolioResolver("update_portfolio");

  const form = useForm<PortfolioFieldValues>({
    resolver,
    defaultValues,
  });

  const assetDepreciationModelValues = new Map([
    ["None", t("companies.update_portfolio.none_value")],
    [
      annualisedStraightLine,
      t("companies.update_portfolio.annualised_straight_line_value"),
    ],
  ]);

  const handleAssetDepreciationModelChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    form: UseFormReturn<PortfolioFieldValues>,
  ) => {
    if (event.target.value === "None") {
      form.unregister("depreciationPercentage");
      form.unregister("depreciationMinimumValue");
    }
  };

  return (
    <form
      id="update-portfolio-form"
      method="POST"
      noValidate
      aria-label={t("companies.update_portfolio.title_label")}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <DialogContent>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sm={12}>
            <Grid container spacing={2} rowSpacing={0.5}>
              <Grid item sm={12}>
                <TextField
                  {...form.register("name")}
                  error={Boolean(form.formState.errors.name)}
                  helperText={form.formState.errors.name?.message}
                  required
                  label={t("companies.update_portfolio.name_label")}
                />
              </Grid>
              <Grid item sm={6}>
                <ControlledTextField
                  select
                  name="portfolioBankAccountId"
                  label={t("companies.update_portfolio.bank_account_label")}
                  id="portfolioBankAccountId"
                  helperText={
                    form.formState.errors?.portfolioBankAccountId?.message
                  }
                  error={form.formState.errors?.portfolioBankAccountId?.message}
                  control={form.control}
                  required
                  defaultValue=""
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">
                    <i>{t("common:please_select")}</i>
                  </MenuItem>

                  {bankAccounts.map((bank) => (
                    <MenuItem key={bank.systemId} value={bank.systemId}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  {...form.register("equifaxInsightNumber", {
                    setValueAs: (value) => value || undefined,
                  })}
                  label={t("companies.update_portfolio.equifax_insight_label")}
                  error={Boolean(
                    form.formState.errors.equifaxInsightNumber?.message,
                  )}
                  helperText={
                    form.formState.errors.equifaxInsightNumber?.message
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  {...form.register("ccrProviderCode", {
                    setValueAs: (value) => value || undefined,
                  })}
                  label={t(
                    "companies.update_portfolio.ccr_provider_code_label",
                  )}
                  error={Boolean(
                    form.formState.errors.ccrProviderCode?.message,
                  )}
                  helperText={form.formState.errors.ccrProviderCode?.message}
                />
              </Grid>
              <Grid item sm={6}>
                <ControlledTextField
                  select
                  id="assetDepreciationModel"
                  name="assetDepreciationModel"
                  label={t(
                    "companies.update_portfolio.asset_depreciation_model_label",
                  )}
                  control={form.control}
                  onChange={(event) =>
                    handleAssetDepreciationModelChange(event, form)
                  }
                >
                  {["None", annualisedStraightLine].map((value) => (
                    <MenuItem key={value} value={value}>
                      {assetDepreciationModelValues.get(value)}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              {form.watch("assetDepreciationModel") ===
                annualisedStraightLine && (
                <>
                  <Grid item sm={6}>
                    <TextField
                      label={t(
                        "companies.update_portfolio.depreciation_percentage_label",
                      )}
                      {...form.register("depreciationPercentage")}
                      error={Boolean(
                        form.formState.errors.depreciationPercentage?.message,
                      )}
                      required
                      helperText={
                        form.formState.errors.depreciationPercentage?.message
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      label={t(
                        "companies.update_portfolio.depreciation_minimum_value_label",
                      )}
                      {...form.register("depreciationMinimumValue")}
                      error={Boolean(
                        form.formState.errors.depreciationMinimumValue?.message,
                      )}
                      required
                      helperText={
                        form.formState.errors.depreciationMinimumValue?.message
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item sm={6}>
                <FormControlLabel
                  labelPlacement="end"
                  label={String(
                    t("companies.update_portfolio.registered_interest_label"),
                  )}
                  control={
                    <Controller
                      name="isHpiRegistered"
                      control={form.control}
                      render={({
                        field: { onChange, value, ...field },
                      }): ReactElement => (
                        <Checkbox
                          {...field}
                          checked={value}
                          onChange={(e): void => onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {error && (
          <Typography
            color="error"
            aria-label={t("companies.update_portfolio.form_error")}
          >
            {error}
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={form.formState.isSubmitting}
        >
          {t("companies.update_portfolio.submit_button")}
        </Button>
      </DialogActions>
    </form>
  );
}

export default function UpdatePortfolioContainer({
  portfolioId,
}: UpdatePortfoliosContainerProps): ReactElement {
  const [open, setOpen] = useState(false);

  const openDialog = (): void => {
    setOpen(true);
  };
  const closeDialog = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UpdatePortfolioDialogContainer
        onSuccess={closeDialog}
        open={open}
        onClose={closeDialog}
        portfolioId={portfolioId}
      />

      <EditButton variant="contained" onClick={openDialog} />
    </>
  );
}
