/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddContactModel
 */
export interface AddContactModel {
    /**
     * The contact type
     * @type {string}
     * @memberof AddContactModel
     */
    type: string;
    /**
     * The title of the primary contact for the company
     * @type {string}
     * @memberof AddContactModel
     */
    title?: string;
    /**
     * The first name of the primary contact for the company
     * @type {string}
     * @memberof AddContactModel
     */
    firstName?: string;
    /**
     * The second name of the primary contact for the company
     * @type {string}
     * @memberof AddContactModel
     */
    secondName?: string;
    /**
     * The last name of the primary contact for the Company
     * @type {string}
     * @memberof AddContactModel
     */
    lastName?: string;
    /**
     * The company name for the contatc
     * @type {string}
     * @memberof AddContactModel
     */
    companyName?: string;
    /**
     * The first line of the address
     * @type {string}
     * @memberof AddContactModel
     */
    addressLine1?: string;
    /**
     * The second line of the address
     * @type {string}
     * @memberof AddContactModel
     */
    addressLine2?: string;
    /**
     * The third line of the address
     * @type {string}
     * @memberof AddContactModel
     */
    addressLine3?: string;
    /**
     * The fourth line of the address
     * @type {string}
     * @memberof AddContactModel
     */
    addressLine4?: string;
    /**
     * The postcode of the address
     * @type {string}
     * @memberof AddContactModel
     */
    postcode?: string;
    /**
     * The work phone number for the contact
     * @type {string}
     * @memberof AddContactModel
     */
    workTelephone?: string;
    /**
     * The home phone number for the contact
     * @type {string}
     * @memberof AddContactModel
     */
    homeTelephone?: string;
    /**
     * The fax number for the contact
     * @type {string}
     * @memberof AddContactModel
     */
    faxNumber?: string;
    /**
     * The email address for the contact
     * @type {string}
     * @memberof AddContactModel
     */
    emailAddress?: string;
    /**
     * Indicates whether the contact receives invoices by email
     * @type {boolean}
     * @memberof AddContactModel
     */
    invoiceByEmail?: boolean;
    /**
     * Indicates whether the contact receives system generated messages
     * @type {boolean}
     * @memberof AddContactModel
     */
    receiveSystemGeneratedCorrespondence?: boolean;
}

/**
 * Check if a given object implements the AddContactModel interface.
 */
export function instanceOfAddContactModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function AddContactModelFromJSON(json: any): AddContactModel {
    return AddContactModelFromJSONTyped(json, false);
}

export function AddContactModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddContactModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'secondName': !exists(json, 'secondName') ? undefined : json['secondName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'workTelephone': !exists(json, 'workTelephone') ? undefined : json['workTelephone'],
        'homeTelephone': !exists(json, 'homeTelephone') ? undefined : json['homeTelephone'],
        'faxNumber': !exists(json, 'faxNumber') ? undefined : json['faxNumber'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'invoiceByEmail': !exists(json, 'invoiceByEmail') ? undefined : json['invoiceByEmail'],
        'receiveSystemGeneratedCorrespondence': !exists(json, 'receiveSystemGeneratedCorrespondence') ? undefined : json['receiveSystemGeneratedCorrespondence'],
    };
}

export function AddContactModelToJSON(value?: AddContactModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'title': value.title,
        'firstName': value.firstName,
        'secondName': value.secondName,
        'lastName': value.lastName,
        'companyName': value.companyName,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
        'workTelephone': value.workTelephone,
        'homeTelephone': value.homeTelephone,
        'faxNumber': value.faxNumber,
        'emailAddress': value.emailAddress,
        'invoiceByEmail': value.invoiceByEmail,
        'receiveSystemGeneratedCorrespondence': value.receiveSystemGeneratedCorrespondence,
    };
}

