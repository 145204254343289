import { ErrorView } from "@ldms/mui-sdk/views";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PageNotFoundView = () => {
  const { t } = useTranslation();
  return (
    <ErrorView
      title={
        <Typography variant="h3" paddingBottom={1}>
          {t("error.page_404_title")}
        </Typography>
      }
      message={
        <Typography paddingBottom={1}>{t("error.page_404_message")}</Typography>
      }
    />
  );
};

export default PageNotFoundView;
