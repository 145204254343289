/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PayeesListItemBankAccountModel
 */
export interface PayeesListItemBankAccountModel {
    /**
     * 
     * @type {string}
     * @memberof PayeesListItemBankAccountModel
     */
    systemId?: string;
    /**
     * The Account Name
     * @type {string}
     * @memberof PayeesListItemBankAccountModel
     */
    accountName?: string;
    /**
     * The Account Number
     * @type {string}
     * @memberof PayeesListItemBankAccountModel
     */
    accountNumber?: string;
    /**
     * The Sort Code
     * @type {string}
     * @memberof PayeesListItemBankAccountModel
     */
    sortCode?: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof PayeesListItemBankAccountModel
     */
    bic?: string;
    /**
     * The international bank account number
     * @type {string}
     * @memberof PayeesListItemBankAccountModel
     */
    iban?: string;
}

/**
 * Check if a given object implements the PayeesListItemBankAccountModel interface.
 */
export function instanceOfPayeesListItemBankAccountModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PayeesListItemBankAccountModelFromJSON(json: any): PayeesListItemBankAccountModel {
    return PayeesListItemBankAccountModelFromJSONTyped(json, false);
}

export function PayeesListItemBankAccountModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayeesListItemBankAccountModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemId': !exists(json, 'systemId') ? undefined : json['systemId'],
        'accountName': !exists(json, 'accountName') ? undefined : json['accountName'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'sortCode': !exists(json, 'sortCode') ? undefined : json['sortCode'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
    };
}

export function PayeesListItemBankAccountModelToJSON(value?: PayeesListItemBankAccountModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'systemId': value.systemId,
        'accountName': value.accountName,
        'accountNumber': value.accountNumber,
        'sortCode': value.sortCode,
        'bic': value.bic,
        'iban': value.iban,
    };
}

