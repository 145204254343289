import { AccessControl, useSession } from "@ldms/mui-sdk/bootstrap";
import { DateField } from "@ldms/mui-sdk/forms";
import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCreateDocument } from "api/agreements/documents/createDocument";
import { usePreviewDocument } from "api/agreements/documents/previewDocument";
import { useListDocumentTemplates } from "api/documents/portfolios/templates/listDocumentTemplates";
import SendEmailContainer from "apps/servicing/modules/agreements/containers/SendEmailContainer";
import { ControlledTextField, Loader, QueryError } from "common/components";
import SplitButton from "common/components/SplitButton/SplitButton";
import { useYupResolver } from "common/hooks";
import { useAgreement } from "common/providers";
import { format, startOfToday } from "date-fns";
import { AgreementModel, FileTypeModel } from "generated/core/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FieldValues,
  FormProvider,
  Path,
  Resolver,
  useForm,
  useWatch,
} from "support/react-hook-form";

export interface CreateDocumentValues {
  templateId: string;
  description: string;
  freeText?: string;
  from?: Date | null;
  to?: Date | null;
}

interface CreateDocumentDialogBodyProps {
  templates: {
    id: string;
    hasFreeText: boolean;
    allowDateRange?: boolean;
    name: string;
  }[];
  onClose(): void;
  agreement: AgreementModel;
  onCloseSaveAndSend(attachmentId: string): void;
}

const freeTextLabel = "agreement_documents.create.free_text_label";
const letterTemplateLabel = "agreement_documents.create.letter_template_label";
const descriptionLabel = "agreement_documents.create.description_label";
const toLabel = "agreement_documents.create.date_to_label";
const fromLabel = "agreement_documents.create.date_from_label";
const dateFormat = "yyyy-MM-dd";

interface TextFieldCharacterCounterProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  totalCharacters: number;
  message?: string;
}

function TextFieldCharacterCounter<TFieldValues extends FieldValues>({
  totalCharacters,
  name,
  message,
}: Readonly<TextFieldCharacterCounterProps<TFieldValues>>): ReactElement {
  const charLength = useWatch({ name }).length;

  return (
    <Box component="span" display="flex" justifyContent="space-between">
      <Typography component="span" variant="caption">
        {message}
      </Typography>

      <Typography
        component="span"
        variant="caption"
        color={charLength > totalCharacters ? "error" : ""}
      >{`${charLength} / ${totalCharacters}`}</Typography>
    </Box>
  );
}

const useCreateDocumentResolver = (
  agreementInception: Date,
  hasDateRange: boolean,
): Resolver<{
  templateId: string;
  description: string;
  freeText?: string;
  agreementInception?: Date | null;
}> => {
  const { t } = useTranslation("agreements");

  return useYupResolver<{
    templateId: string;
    description: string;
    freeText?: string;
    from?: Date | null;
    to?: Date | null;
  }>((yup) =>
    yup.object().shape({
      templateId: yup.string().isRequired(t(letterTemplateLabel)),
      description: yup
        .string()
        .isRequired(t(descriptionLabel))
        .maxCharacters(500, t(descriptionLabel)),
      freeText: yup
        .string()
        .nullable()
        .transform((v, o) => (o === "" ? undefined : v))
        .maxCharacters(4000, t(freeTextLabel)),
      from: hasDateRange
        ? yup
            .date()
            .localDate()
            .isRequired(t(fromLabel))
            .nullable()
            .isValidDate(t(fromLabel))
            .isNotFuture(t(fromLabel))
            .test({
              name: "isBeforeAgreementInception",
              message: t(
                "agreement_documents.create.date_before_agreement_inception",
              ),
              test: (value) => {
                if (!value || !agreementInception) {
                  return true;
                }
                return value >= agreementInception;
              },
            })
            .test({
              name: "isBeforeTo",
              message: t("agreement_documents.create.from_cant_be_after_to"),
              test: function (from) {
                const { to } = this.parent;
                if (!from || !to) {
                  return true;
                }
                return from <= to;
              },
            })
        : yup.date().notRequired().nullable(),
      to: hasDateRange
        ? yup
            .date()
            .localDate()
            .isRequired(t(toLabel))
            .nullable()
            .isValidDate(t(toLabel))
            .isNotFuture(t(toLabel))
            .test({
              name: "isBeforeAgreementInception",
              message: t(
                "agreement_documents.create.date_before_agreement_inception",
              ),
              test: (value) => {
                if (!value || !agreementInception) {
                  return true;
                }
                return value >= agreementInception;
              },
            })
            .test({
              name: "isValidDateRange",
              message: t("agreement_documents.create.to_cant_be_before_from"),
              test: function (to) {
                const { from } = this.parent;
                if (!from || !to) {
                  return true;
                }
                return to >= from;
              },
            })
        : yup.date().notRequired().nullable(),
    }),
  );
};

function CreateDocumentDialogBody({
  templates,
  onClose: onCloseCallback,
  agreement,
  onCloseSaveAndSend: onCloseSaveAndSendCallback,
}: Readonly<CreateDocumentDialogBodyProps>): ReactElement {
  const { t } = useTranslation("agreements");
  const session = useSession();
  const [previewError, setPreviewError] = useState("");
  const [isPreviewed, setIsPreviewed] = useState(false);
  const [hasDateRange, setHasDateRange] = useState(false);

  const resolver = useCreateDocumentResolver(
    agreement.inceptionDate,
    hasDateRange,
  );

  const form = useForm<{
    templateId: string;
    description: string;
    freeText?: string;
    from?: Date | null;
    to?: Date | null;
  }>({
    defaultValues: {
      templateId: "",
      freeText: "",
      description: "",
      from: null,
      to: null,
    },
    resolver,
    mode: "onSubmit",
  });

  const previewDocument = usePreviewDocument(
    agreement.agreementId,
    FileTypeModel.PDF,
    {
      onSuccess: async (response) => {
        const url = window.URL.createObjectURL(response);
        window.open(url, "_blank", "noopener,noreferrer");
        setIsPreviewed(true);
      },
    },
  );

  const downloadLetter = usePreviewDocument(
    agreement.agreementId,
    FileTypeModel.DOCX,
    {
      onSuccess: (response) => {
        const url = window.URL.createObjectURL(response);
        window.open(url, "_blank", "noopener,noreferrer");
        onCloseCallback();
      },
    },
  );

  const onClose = () => {
    setPreviewError("");
    createDocument.reset();
    previewDocument.reset();
    onCloseCallback();
  };

  const createDocument = useCreateDocument(agreement.agreementId, {
    onSuccess: () => {
      onCloseCallback();
    },
  });

  const saveAndSendDocument = useCreateDocument(agreement.agreementId, {
    onSuccess: (response) => {
      onCloseSaveAndSendCallback(response.attachmentId);
    },
  });

  const onPreview = async (
    templateId: string,
    freeText?: string,
    from?: Date | null,
    to?: Date | null,
  ) => {
    await form.trigger("from");
    await form.trigger("to");
    if (!form.formState.errors.to && !form.formState.errors.from) {
      await previewDocument.execute({
        templateId,
        freeText,
        fromDate: from ?? undefined,
        toDate: to ?? undefined,
      });
    }
    setPreviewError("");
  };

  const onDownload = async (
    templateId: string,
    freeText?: string,
    from?: Date | null,
    to?: Date | null,
  ) => {
    await form.trigger("from");
    await form.trigger("to");
    if (!form.formState.errors.to && !form.formState.errors.from) {
      await downloadLetter.execute({
        templateId,
        freeText,
        fromDate: from ?? undefined,
        toDate: to ?? undefined,
      });
    }
  };

  const onSubmitSave = async (data: CreateDocumentValues) => {
    if (isPreviewed) {
      return createDocument.execute({
        description: data.description,
        templateId: data.templateId,
        freeText: data.freeText,
        fromDate: data.from ?? undefined,
        toDate: data.to ?? undefined,
      });
    }
    return setPreviewError(
      t("agreement_documents.create.have_not_previewed_error"),
    );
  };

  const onSubmitSaveAndSend = async (data: CreateDocumentValues) => {
    if (isPreviewed) {
      return saveAndSendDocument.execute({
        description: data.description,
        templateId: data.templateId,
        freeText: data.freeText,
        fromDate: data.from ?? undefined,
        toDate: data.to ?? undefined,
      });
    }
    return setPreviewError(
      t("agreement_documents.create.have_not_previewed_error"),
    );
  };

  const templateIdWatch = useWatch({
    control: form.control,
    name: "templateId",
  });

  return (
    <form
      aria-label={t("agreement_documents.create.title_label")}
      method="POST"
      noValidate
    >
      <FormProvider {...form}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              {t("agreement_documents.create.title_label")}
            </Box>
            <Box>
              <IconButton
                aria-label={t("common:close")}
                onClick={onClose}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          <>
            <ControlledTextField
              select
              id="templateId"
              name="templateId"
              label={t(letterTemplateLabel)}
              helperText={form.formState.errors?.templateId?.message}
              error={form.formState.errors?.templateId?.message}
              SelectProps={{ displayEmpty: true }}
              control={form.control}
              required
              onChange={(event) => {
                setIsPreviewed(false);
                setPreviewError("");
                form.setValue("freeText", undefined);
                form.setValue("description", "");
                if (
                  templates.find((item) => item.id === event.target.value)
                    ?.allowDateRange
                ) {
                  form.unregister("from");
                  form.unregister("to");
                  setHasDateRange(true);
                } else {
                  setHasDateRange(false);
                }
              }}
            >
              <MenuItem value="">
                <i>{t("common:please_select")}</i>
              </MenuItem>

              {templates.map((key) => (
                <MenuItem key={key.id} value={key.id}>
                  {key.name}
                </MenuItem>
              ))}
            </ControlledTextField>
            {templates.find((item) => item.id === templateIdWatch)
              ?.hasFreeText && (
              <TextField
                {...form.register("freeText")}
                error={Boolean(form.formState.errors?.freeText)}
                fullWidth
                onKeyDown={() => {
                  setIsPreviewed(false);
                  setPreviewError("");
                }}
                helperText={
                  <TextFieldCharacterCounter
                    name="freeText"
                    message={form.formState.errors.freeText?.message}
                    totalCharacters={4000}
                  />
                }
                label={t(freeTextLabel)}
                multiline
                minRows={3}
                maxRows={15}
                variant="outlined"
              />
            )}
            {templates.find((item) => item.id === templateIdWatch)
              ?.allowDateRange && (
              <>
                <DateField
                  label={t(fromLabel)}
                  name="from"
                  control={form.control}
                  error={Boolean(form.formState.errors.from)}
                  helperText={form.formState.errors.from?.message}
                  onChange={() => setIsPreviewed(false)}
                  inputProps={{
                    min: format(agreement.inceptionDate, dateFormat),
                    max: format(startOfToday(), dateFormat),
                  }}
                  required
                />
                <DateField
                  control={form.control}
                  name="to"
                  label={t(toLabel)}
                  onChange={() => setIsPreviewed(false)}
                  error={Boolean(form.formState.errors.to)}
                  helperText={form.formState.errors.to?.message}
                  required
                  inputProps={{
                    min: format(agreement.inceptionDate, dateFormat),
                    max: format(startOfToday(), dateFormat),
                  }}
                />
              </>
            )}
            <TextField
              {...form.register("description")}
              error={Boolean(form.formState.errors?.description)}
              fullWidth
              label={t(descriptionLabel)}
              multiline
              variant="outlined"
              required
              minRows={1}
              maxRows={3}
              helperText={
                <TextFieldCharacterCounter
                  name="description"
                  message={form.formState.errors.description?.message}
                  totalCharacters={500}
                />
              }
            />
            {templateIdWatch !== "" && (
              <Stack spacing={2} marginTop={1}>
                <Alert
                  severity="info"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  action={
                    <Button
                      color="inherit"
                      onClick={() =>
                        onDownload(
                          form.getValues().templateId,
                          form.getValues().freeText,
                          form.getValues().from,
                          form.getValues().to,
                        )
                      }
                      disabled={downloadLetter.isExecuting}
                    >
                      {t("agreement_documents.create.download_button")}
                    </Button>
                  }
                >
                  <AlertTitle>
                    {t("agreement_documents.create.download_title_label")}
                  </AlertTitle>
                  <Typography variant="body2">
                    {t("agreement_documents.create.download_message")}
                  </Typography>
                </Alert>

                <Alert
                  severity={isPreviewed ? "success" : "warning"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  action={
                    <Button
                      color="inherit"
                      onClick={() => {
                        onPreview(
                          form.getValues().templateId,
                          form.getValues().freeText,
                          form.getValues().from,
                          form.getValues().to,
                        );
                      }}
                      disabled={previewDocument.isExecuting}
                    >
                      {t("agreement_documents.create.preview_button")}
                    </Button>
                  }
                >
                  <AlertTitle>
                    {t("agreement_documents.create.preview_title_label")}
                  </AlertTitle>
                  <Typography variant="body2">
                    {isPreviewed
                      ? t("agreement_documents.create.previewed_message")
                      : t("agreement_documents.create.not_previewed_message")}
                  </Typography>
                </Alert>
              </Stack>
            )}
            {(createDocument.error ||
              previewDocument.error ||
              downloadLetter.error ||
              previewError) && (
              <Box marginTop={1}>
                <Typography color="error">
                  {previewError || t("common:error.default")}
                </Typography>
              </Box>
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button color="primary" onClick={onClose}>
              {t("common:cancel")}
            </Button>

            {session.hasPermission("servicing:agreement-email:manage") ? (
              <SplitButton
                disabled={createDocument.isExecuting}
                options={[
                  {
                    label: t("common:save"),
                    onClick: form.handleSubmit(onSubmitSave),
                  },
                  {
                    label: t("agreement_documents.create.save_and_send_button"),
                    onClick: form.handleSubmit(onSubmitSaveAndSend),
                  },
                ]}
              />
            ) : (
              <Button
                color="primary"
                disabled={createDocument.isExecuting}
                onClick={form.handleSubmit(onSubmitSave)}
                variant="contained"
              >
                {t("common:save")}
              </Button>
            )}
          </Stack>
        </DialogActions>
      </FormProvider>
    </form>
  );
}

export default function CreateDocumentContainer(): ReactElement {
  const { t } = useTranslation("agreements");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [emailAttachmentId, setEmailAttachmentId] = useState("");
  const agreement = useAgreement();

  const listTemplates = useListDocumentTemplates(
    Number(agreement.data?.portfolioId),
    { enabled: Boolean(agreement.data?.portfolioId) },
  );

  const onClose = () => {
    setIsCreateDialogOpen(false);
  };

  const onCloseSaveAndSend = (attachmentId: string) => {
    setEmailAttachmentId(attachmentId);
    setIsCreateDialogOpen(false);
    setIsEmailDialogOpen(true);
  };

  const onCloseEmail = () => {
    setIsEmailDialogOpen(false);
  };

  const onCreate = (): void => {
    setIsCreateDialogOpen(true);
  };

  return (
    <>
      <Button variant="outlined" onClick={onCreate}>
        {t("agreement_documents.create.create_button")}
      </Button>

      <Dialog
        onClose={onClose}
        open={isCreateDialogOpen}
        fullWidth
        maxWidth="sm"
      >
        <Loader
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
          ready={Boolean(listTemplates.error ?? listTemplates.data)}
          render={(): ReactElement => {
            if (listTemplates.error || !listTemplates.data || !agreement.data) {
              return (
                <Box paddingY={2}>
                  <QueryError onRetry={listTemplates.refetch} />
                </Box>
              );
            }

            return (
              <CreateDocumentDialogBody
                templates={listTemplates.data}
                agreement={agreement.data}
                onClose={onClose}
                onCloseSaveAndSend={onCloseSaveAndSend}
              />
            );
          }}
        />
      </Dialog>

      <AccessControl allowedPermissions={["servicing:agreement-email:manage"]}>
        <SendEmailContainer
          open={isEmailDialogOpen}
          onClose={onCloseEmail}
          defaultValue={emailAttachmentId}
        />
      </AccessControl>
    </>
  );
}
