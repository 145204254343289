export const limitResizeObserverAnimationFrames =
  (callback: (entries: ResizeObserverEntry[]) => void) =>
  (entries: ResizeObserverEntry[]) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      callback(entries);
    });
  };
