import { QueryKey } from "api";

export const all = (): QueryKey => ["batches"];

export const lists = (): QueryKey => [...all(), "list"];

export const list = (params?: {
  from?: Date;
  to?: Date;
  type?: string;
  status?: string;
}): QueryKey => [
  ...lists(),
  params?.from,
  params?.to,
  params?.type,
  params?.status,
];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (batchId: string): QueryKey => [...details(), batchId];
