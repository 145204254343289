import { Loader } from "@ldms/mui-sdk/templates";
import { Box, Skeleton, Stack } from "@mui/material";
import { useGetAsset } from "api/assets/getAsset";
import AssetOverviewDescription from "apps/servicing/components/AssetOverviewDescription";
import { QueryError } from "common/components";
import { useTranslation } from "react-i18next";

const Fallback = () => {
  const { t } = useTranslation("assets");
  const skeletonGroup = (
    <Box>
      {[...new Array(17)].map((_, index) =>
        index % 2 === 0 ? (
          <Skeleton
            key={index}
            variant="rectangular"
            height={26}
            sx={{ bgcolor: "action.hover" }}
          />
        ) : (
          <Box key={index} height={26} />
        ),
      )}
    </Box>
  );

  return (
    <Stack
      spacing={3}
      aria-label={t("details.loading_label")}
      role="progressbar"
    >
      {skeletonGroup}
      {skeletonGroup}
    </Stack>
  );
};

interface AssetDetailsContainerProps {
  assetId: number;
}

export default function AssetDetailsContainer({
  assetId,
}: AssetDetailsContainerProps) {
  const asset = useGetAsset(assetId);

  return (
    <Loader
      fallback={<Fallback />}
      ready={Boolean(asset.data ?? asset.error)}
      render={() => {
        if (!asset.data || asset.error) {
          return <QueryError onRetry={asset.refetch} />;
        }

        return <AssetOverviewDescription overview={asset.data} />;
      }}
    />
  );
}
