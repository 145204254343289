import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/assets/settlement-quotes/keys";
import { useApi } from "common/providers";
import { AssetCommandApi } from "generated/core/apis";
import { CreateAssetSettlementQuoteModel } from "generated/core/models";
import { useSWRConfig } from "swr";

export const useCreateAssetSettlementQuote = (
  assetId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AssetCommandApi);
  const swr = useSWRConfig();

  return useCommand(
    async (createAssetSettlementQuoteModel: CreateAssetSettlementQuoteModel) =>
      api.createAssetSettlementQuote({
        assetId,
        createAssetSettlementQuoteModel,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.list(assetId));
        options.onSuccess?.();
      },
    },
  );
};
