import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { CurrencyApi } from "generated/servicing-v2/apis";
import { CurrencyListItemModel } from "generated/servicing-v2/models";
import { keys } from ".";

type UseListCurrencies = () => UseQueryReturn<CurrencyListItemModel[]>;

export const useListCurrencies: UseListCurrencies = () => {
  const api = useApi(CurrencyApi, "v2");

  return useQuery(keys.list(), () => {
    return api.listCurrencies();
  });
};
