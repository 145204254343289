import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { AgreementAttachmentListItemModel } from "generated/core/models";
import { useApi } from "common/providers";
import { AgreementAttachmentQueryApi } from "generated/core/apis";
import { keys } from ".";

type UseListAgreementAttachments = (
  agreementId: number,
  params: { from?: Date; to?: Date; type?: string },
) => UseQueryReturn<AgreementAttachmentListItemModel[]>;

export const useListAgreementAttachments: UseListAgreementAttachments = (
  agreementId: number,
  params?: { from?: Date; to?: Date; type?: string },
) => {
  const attachmentApi = useApi(AgreementAttachmentQueryApi);

  return useQuery(keys.list(agreementId, params), () =>
    attachmentApi.listAgreementAttachments({
      agreementId: agreementId,
      from: params?.from,
      to: params?.to,
      type: params?.type,
    }),
  );
};
