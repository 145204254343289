/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing vat codes within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VatCodeDetailsModel,
  VatCodeListItemModel,
} from '../models/index';
import {
    VatCodeDetailsModelFromJSON,
    VatCodeDetailsModelToJSON,
    VatCodeListItemModelFromJSON,
    VatCodeListItemModelToJSON,
} from '../models/index';

export interface GetVatCodeDetailsRequest {
    vatCode: string;
}

/**
 * 
 */
export class VatCodeQueryApi extends runtime.BaseAPI {

    /**
     * Get vat code
     */
    async getVatCodeDetailsRaw(requestParameters: GetVatCodeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VatCodeDetailsModel>> {
        if (requestParameters.vatCode === null || requestParameters.vatCode === undefined) {
            throw new runtime.RequiredError('vatCode','Required parameter requestParameters.vatCode was null or undefined when calling getVatCodeDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["vat-codes:view"]);
        }

        const response = await this.request({
            path: `/vat-codes/{vatCode}`.replace(`{${"vatCode"}}`, encodeURIComponent(String(requestParameters.vatCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VatCodeDetailsModelFromJSON(jsonValue));
    }

    /**
     * Get vat code
     */
    async getVatCodeDetails(requestParameters: GetVatCodeDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VatCodeDetailsModel> {
        const response = await this.getVatCodeDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List vat codes
     */
    async listVatCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VatCodeListItemModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["vat-codes:view"]);
        }

        const response = await this.request({
            path: `/vat-codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VatCodeListItemModelFromJSON));
    }

    /**
     * List vat codes
     */
    async listVatCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VatCodeListItemModel>> {
        const response = await this.listVatCodesRaw(initOverrides);
        return await response.value();
    }

}
