import { useRef, useState } from "react";

/**
 * Provides an API to defer a function until a user confirms
 * ```tsx
 * const confirm = useConfirm();
 *
 * const onSubmit = (): void => {
 *   // ...
 * }
 *
 * const onReject = (): void => {
 *   // ...
 * }
 *
 * function MyForm() {
 *   return (
 *     <form onSubmit={confirm.handlePrompt(onSubmit, onReject)}>
 *       ...
 *     </form>
 *
 *     <ConfirmDialog onClose={confirm.handleReject} onConfirm={confirm.handleConfirm} />
 *   );
 * }
 * ```
 */
export default function useConfirm(): {
  isOpen: boolean;
  handleConfirm(): void | Promise<void>;
  handlePrompt(
    callback: () => void | Promise<void>,
    onReject?: () => void | Promise<void>,
  ): void;
  handleReject(): void | Promise<void>;
} {
  const [isOpen, setOpen] = useState(false);
  const callbackRef = useRef<() => void>();
  const rejectRef = useRef<() => void>();

  const handlePrompt = (callback: () => void, onReject?: () => void): void => {
    callbackRef.current = callback;
    rejectRef.current = onReject;
    setOpen(true);
  };

  const cleanup = () => {
    callbackRef.current = undefined;
    rejectRef.current = undefined;
  };

  const handleConfirm = (): void => {
    setOpen(false);
    if (callbackRef.current) {
      callbackRef.current();
      cleanup();
    }
  };

  const handleReject = (): void => {
    setOpen(false);
    if (rejectRef.current) {
      rejectRef.current();
      cleanup();
    }
  };

  return {
    handlePrompt,
    handleConfirm,
    handleReject,
    isOpen,
  };
}
