import { PagedQuery, useQueryPaged } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { CompaniesApi } from "generated/servicing-v2/apis";
import { CompanyListItemModel } from "generated/servicing-v2/models";
import { keys } from ".";

export const useListCompanies: PagedQuery<CompanyListItemModel> = ({
  pageSize,
} = {}) => {
  const api = useApi(CompaniesApi, "v2");

  return useQueryPaged(
    keys.list(),
    async (_key, paging) =>
      api.listCompaniesRaw({
        limit: paging.pageSize,
        offset: paging.offset,
      }),
    { limit: pageSize },
  );
};
