/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssetAgreementModel
 */
export interface AssetAgreementModel {
    /**
     * The agreement number
     * @type {string}
     * @memberof AssetAgreementModel
     */
    number?: string;
    /**
     * The agreement product category
     * @type {string}
     * @memberof AssetAgreementModel
     */
    product?: string;
    /**
     * The agreement status
     * @type {string}
     * @memberof AssetAgreementModel
     */
    status?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetAgreementModel
     */
    opened?: Date;
}

/**
 * Check if a given object implements the AssetAgreementModel interface.
 */
export function instanceOfAssetAgreementModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssetAgreementModelFromJSON(json: any): AssetAgreementModel {
    return AssetAgreementModelFromJSONTyped(json, false);
}

export function AssetAgreementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetAgreementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'product': !exists(json, 'product') ? undefined : json['product'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'opened': !exists(json, 'opened') ? undefined : (new Date(json['opened'])),
    };
}

export function AssetAgreementModelToJSON(value?: AssetAgreementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'product': value.product,
        'status': value.status,
        'opened': value.opened === undefined ? undefined : (value.opened.toISOString().substring(0,10)),
    };
}

