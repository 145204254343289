import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ContactsApi } from "generated/servicing-v2/apis/ContactsApi";
import { useSWRConfig } from "swr";
import { keys } from ".";

export const useDeleteContact = (
  customerId: string,
  contactId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(ContactsApi, "v2");
  const swr = useSWRConfig();
  return useCommand<void, void, ResponseError>(
    () =>
      api.deleteContact({
        id: contactId,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.list(customerId));
        options.onSuccess?.();
      },
    },
  );
};
