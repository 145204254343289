/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LetterListItemModel
 */
export interface LetterListItemModel {
    /**
     * A read only UUID
     * @type {string}
     * @memberof LetterListItemModel
     */
    readonly id: string;
    /**
     * The filename of the letter
     * @type {string}
     * @memberof LetterListItemModel
     */
    filename: string;
    /**
     * The number of the agreement
     * @type {string}
     * @memberof LetterListItemModel
     */
    agreementNumber: string;
    /**
     * The portfolio name
     * @type {string}
     * @memberof LetterListItemModel
     */
    portfolioName: string;
    /**
     * The type of document generated
     * @type {string}
     * @memberof LetterListItemModel
     */
    type: string;
    /**
     * A date time format
     * @type {string}
     * @memberof LetterListItemModel
     */
    processedAt: string;
    /**
     * email address to send the letter to
     * @type {string}
     * @memberof LetterListItemModel
     */
    sentTo: string;
    /**
     * A date time format
     * @type {string}
     * @memberof LetterListItemModel
     */
    failedAt?: string;
    /**
     * A date time format
     * @type {string}
     * @memberof LetterListItemModel
     */
    sentAt?: string;
}

/**
 * Check if a given object implements the LetterListItemModel interface.
 */
export function instanceOfLetterListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "agreementNumber" in value;
    isInstance = isInstance && "portfolioName" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "processedAt" in value;
    isInstance = isInstance && "sentTo" in value;

    return isInstance;
}

export function LetterListItemModelFromJSON(json: any): LetterListItemModel {
    return LetterListItemModelFromJSONTyped(json, false);
}

export function LetterListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LetterListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filename': json['filename'],
        'agreementNumber': json['agreementNumber'],
        'portfolioName': json['portfolioName'],
        'type': json['type'],
        'processedAt': json['processedAt'],
        'sentTo': json['sentTo'],
        'failedAt': !exists(json, 'failedAt') ? undefined : json['failedAt'],
        'sentAt': !exists(json, 'sentAt') ? undefined : json['sentAt'],
    };
}

export function LetterListItemModelToJSON(value?: LetterListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'agreementNumber': value.agreementNumber,
        'portfolioName': value.portfolioName,
        'type': value.type,
        'processedAt': value.processedAt,
        'sentTo': value.sentTo,
        'failedAt': value.failedAt,
        'sentAt': value.sentAt,
    };
}

