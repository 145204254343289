import { useFormat } from "@ldms/mui-sdk/formatting";
import { Form } from "@ldms/mui-sdk/forms";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  useGetSettlementQuoteDetails,
  useListSettlementQuotes,
} from "api/agreements/settlement-quotes";
import { useCreateSettlementQuote } from "api/agreements/settlement-quotes/createSettlementQuote";
import CreateSettlementQuoteFormControls from "apps/servicing/components/CreateSettlementQuoteFormControls";
import { CreateSettlementQuotesFieldValues } from "apps/servicing/components/CreateSettlementQuoteFormControls/CreateSettlementQuoteFormControls";
import useSettlementQuoteResolver from "apps/servicing/hooks/useSettlementQuotesResolver";
import AgreementSettlementQuotationSummary from "apps/servicing/modules/agreements/components/AgreementSettlementQuotationSummary";
import { Loader, QueryError } from "common/components";
import { AgreementSettlementQuoteModel } from "generated/core/models/AgreementSettlementQuoteModel";
import { CreateSettlementQuoteModel } from "generated/core/models/CreateSettlementQuoteModel";
import { DiscountTypeModel } from "generated/core/models/DiscountTypeModel";
import { SettlementStatusModel } from "generated/core/models/SettlementStatusModel";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

interface CreateSettlementQuoteContainerProps {
  agreementId: number;
}

interface CreateSettlementQuotesBodyProp {
  settlementQuoteDetails: AgreementSettlementQuoteModel;
  agreementId: number;
}

const getTotalAmountDue = (
  settlementQuoteDetails: AgreementSettlementQuoteModel,
): number => {
  return (
    Number(settlementQuoteDetails.feesAndCharges) +
    Number(settlementQuoteDetails.futureInstalments) +
    Number(settlementQuoteDetails.outstandingArrears) -
    Number(settlementQuoteDetails.overPayments) +
    Number(settlementQuoteDetails.vat)
  );
};

function CreateSettlementQuotesBody({
  settlementQuoteDetails,
  agreementId,
}: CreateSettlementQuotesBodyProp): ReactElement {
  const { t } = useTranslation("agreements");
  const navigate = useNavigate();
  const { formatAmount } = useFormat();
  const totalAmountDue = getTotalAmountDue(settlementQuoteDetails);
  const settlementList = useListSettlementQuotes(agreementId);
  const settlementExists = [
    SettlementStatusModel.Approved,
    SettlementStatusModel.Quoted,
  ].some(
    (status) => settlementList.data?.map((s) => s.status).includes(status),
  );

  const resolver = useSettlementQuoteResolver(
    Number(totalAmountDue.toFixed(2)),
  );

  const responseError = new Map([
    [
      "invalid_settlement_date",
      t("settlement_quotes.create_quote.invalid_settlement_date_error_message"),
    ],
    [
      "invalid_instalment_period",
      t(
        "settlement_quotes.create_quote.invalid_instalment_period_error_message",
      ),
    ],
    [
      "asset_settlement_quote_exists",
      t(
        "settlement_quotes.create_quote.asset_settlement_quote_exists_error_message",
      ),
    ],
    [
      "invalid_settlement_no_current_schedule",
      t(
        "settlement_quotes.create_quote.invalid_settlement_no_current_schedule_error_message",
      ),
    ],
  ]);

  const onSuccess = async (): Promise<void> => {
    navigate("..", { relative: "path" });
  };

  const createSettlementQuote = useCreateSettlementQuote(agreementId, {
    onSuccess,
  });

  const onSubmit = async (
    data: CreateSettlementQuotesFieldValues,
  ): Promise<void> => {
    const createSettlementQuoteModel: CreateSettlementQuoteModel = {
      ...data,
      discountType: (data.discountType as DiscountTypeModel)
        ? data.discountType
        : undefined,
      discountAmount:
        data.discountType === DiscountTypeModel.Amount
          ? (data.discountAmount ?? 0).toFixed(2)
          : undefined,
      discountPercentage:
        data.discountType === DiscountTypeModel.Percentage
          ? (data.discountPercentage ?? 0).toFixed(2)
          : undefined,
    };
    await createSettlementQuote.execute(createSettlementQuoteModel);
  };

  return (
    <>
      <Form
        label={t("settlement_quotes.create_quote.form_title")}
        onSubmit={onSubmit}
        resolver={resolver}
        options={{ shouldUnregister: true }}
      >
        {(form): ReactElement => {
          const formWatch = form.watch();
          const watchDiscountPercentageAmount = Number(
            (totalAmountDue *
              Number(
                formWatch.discountPercentage ? formWatch.discountPercentage : 0,
              )) /
              100,
          );

          const watchDiscount =
            formWatch.discountType === DiscountTypeModel.Amount
              ? Number(formWatch.discountAmount ?? 0)
              : watchDiscountPercentageAmount;

          return (
            <Container maxWidth="sm">
              <Paper>
                <Stack gap={2} padding={2}>
                  <Typography variant="h6">
                    {t(
                      "settlement_quotes.create_quote.quotation_summary_label",
                    )}
                  </Typography>

                  {settlementExists && (
                    <Alert severity="warning">
                      {t("settlement_quotes.create_quote.settlement_exists")}
                    </Alert>
                  )}

                  {!settlementExists && settlementQuoteDetails.hasSubsidy && (
                    <Alert severity="warning">
                      {t("settlement_quotes.create_quote.subsidy_exists")}
                    </Alert>
                  )}

                  <CreateSettlementQuoteFormControls {...form} />

                  <AgreementSettlementQuotationSummary
                    settlementQuoteDetails={settlementQuoteDetails}
                    discountField={formatAmount(watchDiscount)}
                    totalSettleField={formatAmount(
                      totalAmountDue - watchDiscount,
                    )}
                  />

                  {createSettlementQuote.error && (
                    <Typography color="error" aria-label="form.error">
                      {responseError.get(createSettlementQuote.error.code) ??
                        t("common:error.default")}
                    </Typography>
                  )}

                  <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Button
                      role="link"
                      key="common:cancel"
                      component={RouterLink}
                      to=".."
                      color="primary"
                      relative="path"
                    >
                      {t("common:cancel")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={
                        createSettlementQuote.isExecuting || settlementExists
                      }
                    >
                      {t("settlement_quotes.create_quote.submit_button")}
                    </Button>
                  </Box>
                </Stack>
              </Paper>
            </Container>
          );
        }}
      </Form>
    </>
  );
}

export default function CreateSettlementQuoteContainer({
  agreementId,
}: CreateSettlementQuoteContainerProps): ReactElement {
  const settlementQuoteDetails = useGetSettlementQuoteDetails(agreementId);

  return (
    <>
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(
          settlementQuoteDetails.data ?? settlementQuoteDetails.error,
        )}
        render={(): ReactElement => {
          if (settlementQuoteDetails.error || !settlementQuoteDetails.data) {
            return <QueryError onRetry={settlementQuoteDetails.refetch} />;
          }
          return (
            <CreateSettlementQuotesBody
              settlementQuoteDetails={settlementQuoteDetails.data}
              agreementId={agreementId}
            />
          );
        }}
      />
    </>
  );
}
