import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { DateField, NumberField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useApplyPaymentHoliday } from "api/agreements/payment-holiday/applyPaymentHoliday";
import { useGetPaymentHolidayDetails } from "api/agreements/payment-holiday/getPaymentHolidayDetails";
import { ControlledTextField, Loader } from "common/components";
import { useLocale, useYupResolver } from "common/hooks";
import { useAgreement } from "common/providers";
import { ApplyPaymentHolidayModel } from "generated/core/models";
import { ReactElement, useState } from "react";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

enum PaymentHolidayType {
  FullPaymentHoliday = "repayment_schedule.payment_holiday.full_payment_label",
  InterestOnlyHoliday = "repayment_schedule.payment_holiday.interest_only_label",
}

interface PaymentHolidayFormProps {
  open: boolean;
  onClose: () => void;
  selectedDate: Date;
  instalmentNumber: number;
}

interface PaymentHolidayContainerProps {
  selectedDate: Date;
  instalmentNumber: number;
}

interface PaymentHolidaySummaryTableProps {
  numberOfInstalments: number;
  typeOfHoliday: string;
  instalmentNumber: number;
}

export interface PaymentHolidayFieldValues {
  typeOfHoliday: string;
  numberOfInstalments: number;
}

const transformField = (v: string, o: string): string | null | undefined => {
  return o === "" ? undefined : v;
};

const usePaymentHolidayDateResolver =
  (): Resolver<PaymentHolidayFieldValues> => {
    const { t } = useTranslation("agreements");

    const numberOfInstalmentsLabel = t(
      "repayment_schedule.payment_holiday.number_of_instalments_label",
    );
    return useYupResolver<PaymentHolidayFieldValues>((yup) =>
      yup.object().shape({
        numberOfInstalments: yup
          .number(numberOfInstalmentsLabel)
          .transform(transformField)
          .minAmount(1.0, numberOfInstalmentsLabel)
          .maxAmount(3.0, numberOfInstalmentsLabel)
          .isRequired(numberOfInstalmentsLabel),
        typeOfHoliday: yup
          .string()
          .isRequired(
            t("repayment_schedule.payment_holiday.type_of_holiday_label"),
          ),
      }),
    );
  };

function PaymentHolidaySummaryTable({
  numberOfInstalments,
  typeOfHoliday,
  instalmentNumber,
}: PaymentHolidaySummaryTableProps) {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const paymentHolidaySummary = useGetPaymentHolidayDetails(agreement.id, {
    instalmentNumber: instalmentNumber,
    numberOfInstalments: numberOfInstalments,
    isFullPaymentHoliday:
      typeOfHoliday === PaymentHolidayType.FullPaymentHoliday,
  });
  const locale = useLocale();
  const { formatAmount } = useFormat();

  return (
    <Loader
      fallback={
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      }
      ready={
        Boolean(paymentHolidaySummary.data) ||
        Boolean(paymentHolidaySummary.error)
      }
      render={(): ReactElement => (
        <Grid item sm={12}>
          <DescriptionList
            paddingTop={1}
            label={t(
              "repayment_schedule.payment_holiday.description_list_label",
            )}
          >
            <DescriptionList.Item
              label={t(
                "repayment_schedule.payment_holiday.holiday_end_date_label",
              )}
            >
              {paymentHolidaySummary.data?.endDate
                ? locale.formatDate(paymentHolidaySummary.data?.endDate)
                : "-"}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t(
                "repayment_schedule.payment_holiday.new_maturity_date_label",
              )}
            >
              {paymentHolidaySummary.data?.newMaturityDate
                ? locale.formatDate(paymentHolidaySummary.data?.newMaturityDate)
                : "-"}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t(
                "repayment_schedule.payment_holiday.final_payment_amount_label",
              )}
            >
              {formatAmount(paymentHolidaySummary.data?.finalPaymentAmount)}
            </DescriptionList.Item>
          </DescriptionList>

          {paymentHolidaySummary.error && (
            <Box>
              <Typography
                color="error"
                data-testid="paymentHolidayDetails.error"
              >
                {paymentHolidaySummary.error.message ||
                  t("common:error.default")}
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    />
  );
}

function PaymentHolidayForm({
  open,
  onClose,
  selectedDate,
  instalmentNumber,
}: PaymentHolidayFormProps) {
  const resolver = usePaymentHolidayDateResolver();
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();

  const applyPaymentHoliday = useApplyPaymentHoliday(agreement.id, {
    onSuccess: onClose,
  });

  const onCloseErrorDialog = (): void => {
    applyPaymentHoliday.reset();
    onClose();
  };

  const onSubmit = async (data: PaymentHolidayFieldValues): Promise<void> => {
    applyPaymentHoliday.reset();
    const applyPaymentHolidayModel: ApplyPaymentHolidayModel = {
      instalmentNumber: instalmentNumber,
      numberOfInstalments: data.numberOfInstalments,
      isFullPaymentHoliday:
        data.typeOfHoliday === PaymentHolidayType.FullPaymentHoliday,
    };
    await applyPaymentHoliday.execute(applyPaymentHolidayModel);
  };

  const responseError = new Map([
    [
      "instalment_date_within_dd_window",
      t("repayment_schedule.instalment_date_within_dd_window_error_message"),
    ],
  ]);

  return (
    <FormDialog
      title={t("repayment_schedule.payment_holiday.payment_holiday_title")}
      onClose={onCloseErrorDialog}
      open={open}
      onSubmit={onSubmit}
      resolver={resolver}
      defaultValues={{ typeOfHoliday: "" }}
      disabled={applyPaymentHoliday.isExecuting}
    >
      {(form) => {
        const formWatch = form.watch();
        return (
          <>
            <Grid container spacing={1}>
              <Grid item sm={6}>
                <TextField
                  label={t(
                    "repayment_schedule.payment_holiday.instalment_number_label",
                  )}
                  disabled={true}
                  defaultValue={instalmentNumber}
                />
              </Grid>
              <Grid item sm={6}>
                <DateField
                  name="currentInstalmentDate"
                  label={t(
                    "repayment_schedule.payment_holiday.current_instalment_date_label",
                  )}
                  defaultValue={selectedDate}
                  disabled={true}
                />
              </Grid>
              <Grid item sm={6}>
                <ControlledTextField
                  select
                  id="typeOfHoliday"
                  name="typeOfHoliday"
                  label={t(
                    "repayment_schedule.payment_holiday.type_of_holiday_label",
                  )}
                  helperText={form.formState.errors.typeOfHoliday?.message}
                  error={form.formState.errors.typeOfHoliday?.message}
                  SelectProps={{ displayEmpty: true }}
                  control={form.control}
                  required
                >
                  <MenuItem value="">{t("common:please_select")}</MenuItem>

                  {Object.values(PaymentHolidayType).map((holidayType) => (
                    <MenuItem value={holidayType} key={holidayType}>
                      {t(holidayType)}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid item sm={6}>
                <NumberField
                  control={form.control}
                  name="numberOfInstalments"
                  label={t(
                    "repayment_schedule.payment_holiday.number_of_instalments_label",
                  )}
                  required
                  error={Boolean(
                    form.formState.errors?.numberOfInstalments?.message,
                  )}
                  helperText={
                    form.formState.errors?.numberOfInstalments?.message
                  }
                />
              </Grid>
              {formWatch.numberOfInstalments > 0 &&
                formWatch.numberOfInstalments < 4 &&
                formWatch.typeOfHoliday && (
                  <PaymentHolidaySummaryTable
                    numberOfInstalments={formWatch.numberOfInstalments}
                    typeOfHoliday={formWatch.typeOfHoliday}
                    instalmentNumber={instalmentNumber}
                  />
                )}
            </Grid>
            {applyPaymentHoliday.error && (
              <Box>
                <Typography
                  color="error"
                  data-testid="applyPaymentHoliday.error"
                >
                  {responseError.get(applyPaymentHoliday.error.code) ??
                    t("common:error.default")}
                </Typography>
              </Box>
            )}
          </>
        );
      }}
    </FormDialog>
  );
}

export default function PaymentHolidayContainer({
  selectedDate,
  instalmentNumber,
}: PaymentHolidayContainerProps) {
  const { t } = useTranslation("agreements");

  const [openPaymentHolidayDialog, setOpenPaymentHolidayDialog] =
    useState(false);

  const openDialog = (): void => {
    setOpenPaymentHolidayDialog(true);
  };

  const closeDialog = (): void => {
    setOpenPaymentHolidayDialog(false);
  };

  return (
    <>
      <MenuItem key="return" onClick={openDialog}>
        {t("repayment_schedule.payment_holiday.payment_holiday_button")}
      </MenuItem>

      <PaymentHolidayForm
        open={openPaymentHolidayDialog}
        onClose={closeDialog}
        selectedDate={selectedDate}
        instalmentNumber={instalmentNumber}
      />
    </>
  );
}
