import { Loader } from "@ldms/mui-sdk/templates";
import { useListPaymentAllocations } from "api/agreements/payment-allocations/listPaymentAllocations";
import PaymentAllocationList from "apps/servicing/modules/agreements/components/PaymentAllocationList/PaymentAllocationList";
import { QueryError } from "common/components";

type ListPaymentAllocationsContainerProps = {
  agreementId: number;
};

const ListPaymentAllocationsContainer = ({
  agreementId,
}: ListPaymentAllocationsContainerProps) => {
  const paymentAllocations = useListPaymentAllocations(agreementId);

  return (
    <Loader
      ready={Boolean(paymentAllocations.data ?? paymentAllocations.error)}
      render={() => {
        if (paymentAllocations.error || !paymentAllocations.data) {
          return <QueryError onRetry={paymentAllocations.refetch} />;
        }

        return (
          <PaymentAllocationList
            loading={paymentAllocations.isValidating}
            paymentAllocationsData={paymentAllocations.data}
          />
        );
      }}
    />
  );
};

export default ListPaymentAllocationsContainer;
