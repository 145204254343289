import { Form } from "@ldms/mui-sdk/forms";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import FinancialPostingsFilterParametersContainer, {
  AgreementPortfolioFilter,
  FinancialPostingsFilterParameters,
} from "apps/finance/containers/FinancialPostingsFilterParametersContainer";
import { Loader } from "common/components";
import { useLocale, useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { PortfolioQueryApi } from "generated/core/apis";
import { ReactElement } from "react";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

const useFilterFinancialPostingsResolver =
  (): Resolver<FinancialPostingsFilterParameters> => {
    const { t } = useTranslation("finance");

    return useYupResolver<FinancialPostingsFilterParameters>((yup) =>
      yup.object().shape({
        agreementOrPortfolio: yup
          .string()
          .isRequired(
            t("financial_postings.filter.agreement_or_portfolio_label"),
          ),
        agreement: yup
          .object()
          .when(["agreementOrPortfolio", "specifyAgreement"], {
            is: (
              agreementOrPortfolio: AgreementPortfolioFilter,
              specifyAgreement: string,
            ) =>
              agreementOrPortfolio === AgreementPortfolioFilter.Agreement &&
              specifyAgreement === "specifyAgreement",
            then: yup.object().shape({
              label: yup
                .string()
                .isRequired(
                  t("financial_postings.filter.agreement_number_label"),
                ),
            }),
          }),
        portfolio: yup.string().when("agreementOrPortfolio", {
          is: AgreementPortfolioFilter.Portfolio,
          then: yup
            .string()
            .isRequired(t("financial_postings.filter.portfolio_label")),
        }),
        accountName: yup
          .string()
          .isRequired(t("financial_postings.filter.account_label")),
        stageGroup: yup
          .string()
          .isRequired(t("financial_postings.filter.stage_group_label")),
        stageCode: yup
          .string()
          .isRequired(t("financial_postings.filter.stage_name_label")),
        from: yup
          .date()
          .localDate()
          .isValidDate(t("financial_postings.filter.from_label"))
          .isRequired(t("financial_postings.filter.from_label")),
        to: yup
          .date()
          .localDate()
          .isValidDate(t("financial_postings.filter.to_label"))
          .isRequired(t("financial_postings.filter.to_label")),
      }),
    );
  };

const useMapSearchParameters = () => {
  const locale = useLocale();
  const map = (data: FinancialPostingsFilterParameters) => {
    const obj: Record<string, string> = {
      accountName: data.accountName,
      stageGroup: data.stageGroup,
      stageCode: data.stageCode,
      from: locale.formatISODate(data.from),
      to: locale.formatISODate(data.to),
    };

    if (data.portfolio) {
      obj.portfolioId = data.portfolio;
    }

    if (data.specifyAgreement === "all" && data.portfolio === undefined) {
      obj.agreementId = "All";
    }

    if (data.specifyAgreement !== "all" && data.portfolio === undefined) {
      obj.agreementId = data.agreement.value;
    }

    return new URLSearchParams(obj);
  };

  return { map };
};

export default function FilterFinancialPostingsContainer(): ReactElement {
  const { t } = useTranslation("finance");
  const portfolioApi = useApi(PortfolioQueryApi);
  const portfolios = useSWR(["portfolios"], () =>
    portfolioApi.listPortfolios(),
  );
  const resolver = useFilterFinancialPostingsResolver();
  const navigate = useNavigate();
  const mapSearchParameters = useMapSearchParameters();

  return (
    <>
      <Form
        label={t("financial_postings.filter.heading")}
        options={{ shouldUnregister: true }}
        onSubmit={(data) => {
          navigate(
            `../financial-postings?${mapSearchParameters.map(data).toString()}`,
          );
        }}
        resolver={resolver}
        defaultValues={{
          agreementOrPortfolio: "",
          agreement: { label: "", value: "" },
          portfolio: "",
          specifyAgreement: "all",
          accountName: "",
          stageGroup: "",
          stageCode: "",
          from: undefined,
          to: undefined,
        }}
      >
        {(form): ReactElement => {
          return (
            <Loader
              fallback={
                <Box display="flex" justifyContent="center" p={2}>
                  <CircularProgress />
                </Box>
              }
              ready={Boolean(portfolios.data || portfolios.error)}
              render={(): ReactElement => {
                return (
                  <Container maxWidth="sm">
                    <Paper>
                      <Stack gap={2} padding={2}>
                        <Typography variant="h6">
                          {t("financial_postings.filter.heading")}
                        </Typography>

                        <Grid container spacing={2} rowSpacing={1}>
                          <FinancialPostingsFilterParametersContainer
                            props={form}
                            portfolios={[
                              {
                                label: t(
                                  "financial_postings.filter.all_option",
                                ),
                                value: "All",
                              },
                              ...(portfolios.data ?? []).map((portfolio) => ({
                                label: portfolio.name,
                                value: String(portfolio.id),
                              })),
                            ]}
                          />
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" gap={1}>
                          <Button
                            role="link"
                            type="reset"
                            key="clear"
                            onClick={() => form.reset()}
                            color="primary"
                          >
                            {t("financial_postings.filter.clear_button")}
                          </Button>
                          <Button
                            type="submit"
                            role="link"
                            variant="contained"
                            disabled={form.formState.isSubmitting}
                          >
                            {t(
                              "financial_postings.filter.view_financial_postings_button",
                            )}
                          </Button>
                        </Box>
                      </Stack>
                    </Paper>
                  </Container>
                );
              }}
            />
          );
        }}
      </Form>
    </>
  );
}
