import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers/ApiProvider";
import { ClientQueryApi } from "generated/core/apis/ClientQueryApi";
import { MandatesListItemModel } from "generated/core/models";
import * as keys from "./keys";

type UseListMandates = (
  clientId: number,
  bankAccountId: number,
) => UseQueryReturn<MandatesListItemModel[]>;

export const useListMandates: UseListMandates = (clientId, bankAccountId) => {
  const api = useApi(ClientQueryApi);

  return useQuery(keys.list(clientId, bankAccountId), () =>
    api.listMandates({ clientId, bankAccountId }),
  );
};
