import { useFormat } from "@ldms/mui-sdk/formatting";
import { MoreVert } from "@mui/icons-material";
import {
  IconButton,
  ListItemButton,
  ListItemText,
  Menu,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from "@mui/material";
import { useListChargeTypes } from "api/charge-types";
import ActivateChargeContainer from "apps/onboarding/containers/ActivateChargeContainer/ActivateChargeContainer";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
} from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface ActivateChargeMenuProps {
  chargeId: string;
  active: boolean;
}

function ActivateChargeMenu({
  chargeId,
  active,
}: Readonly<ActivateChargeMenuProps>): ReactElement {
  const { t } = useTranslation("servicing");
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuElement);

  const handleMenuClose = (): void => {
    setMenuElement(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuElement(event.currentTarget);
  };

  return (
    <>
      <IconButton
        onClick={handleMenuClick}
        size="small"
        aria-label={t("charges.list_charges.menu_button")}
        color="primary"
      >
        <MoreVert fontSize="inherit" />
      </IconButton>

      <Menu open={menuOpen} anchorEl={menuElement} onClose={handleMenuClose}>
        <ActivateChargeContainer
          chargeId={chargeId}
          active={active}
          closeMenu={handleMenuClose}
        />
      </Menu>
    </>
  );
}

export default function ListChargesContainer(): ReactElement {
  const { t } = useTranslation("servicing");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const charges = useListChargeTypes({ pageSize: rowsPerPage });
  const { formatAmount } = useFormat();
  const appConfig = useAppConfiguration();

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => charges.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Loader
      ready={Boolean(charges.data ?? charges.error)}
      render={(): ReactElement => {
        if (charges.error || !charges.data) {
          return <QueryError onRetry={charges.refetch} />;
        }

        return (
          <ListLayout
            pagination={{
              onPageChange: onChangePage,
              onRowsPerPageChange: onChangeRowsPerPage,
              rowsPerPage: rowsPerPage,
              page: charges.paging.page,
              count: charges.data.paging.total || 0,
            }}
          >
            <Table
              aria-label={t("charges.list_charges.table_name_label")}
              size="small"
              stickyHeader
            >
              <LoadableTableHead
                headings={[
                  t("charges.list_charges.charge_name_heading"),
                  t("charges.list_charges.charge_type_heading"),
                  t("charges.list_charges.allocation_type_heading"),
                  t("charges.list_charges.amount_heading"),
                  t("charges.list_charges.status_heading"),
                  t("charges.list_charges.created_by_heading"),
                  "",
                ]}
                loading={charges.isValidating}
              />
              <TableBody>
                {charges.data.results.map((charge) => {
                  const sxActive: SxProps<Theme> = (theme) =>
                    !charge.isActive
                      ? { color: theme.palette.text.disabled }
                      : {};
                  return (
                    <TableRow key={charge.code} hover>
                      <TableCell
                        padding="none"
                        sx={{ width: "500px" }}
                        component="th"
                        scope="row"
                      >
                        <ListItemButton
                          dense
                          component={RouterLink}
                          to={`${appConfig.appRoutes.servicing}/settings/charges/${charge.systemId}`}
                        >
                          <ListItemText
                            primaryTypographyProps={{ color: "primary" }}
                            primary={charge.name}
                          />
                        </ListItemButton>
                      </TableCell>
                      <TableCell sx={sxActive}>{charge.subType}</TableCell>
                      <TableCell sx={sxActive}>
                        {charge.allocationType}
                      </TableCell>
                      <TableCell sx={sxActive}>
                        {formatAmount(charge.amount)}
                      </TableCell>
                      <TableCell sx={sxActive}>
                        {charge.isActive
                          ? t("charges.list_charges.active_label")
                          : t("charges.list_charges.inactive_label")}
                      </TableCell>
                      <TableCell sx={sxActive}>
                        {charge.createdBy.toUpperCase()}
                      </TableCell>
                      <TableCell sx={{ paddingY: 0.25 }} align="right">
                        <ActivateChargeMenu
                          chargeId={charge.systemId}
                          active={charge.isActive}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {charges.data.results.length === 0 && <NoResults />}
          </ListLayout>
        );
      }}
    />
  );
}
