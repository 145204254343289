import { QueryKey } from "api";
import { keys } from "api/agreements";

export const all = (agreementId: number): QueryKey => [
  ...keys.detail(agreementId),
  "insurance",
];

export const details = (agreementId: number): QueryKey => [
  ...all(agreementId),
  "detail",
];

export const detail = (agreementId: number): QueryKey => [
  ...details(agreementId),
];
