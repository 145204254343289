import { DescriptionList, EditButton } from "@ldms/mui-sdk/components";
import { Loader } from "@ldms/mui-sdk/templates";
import { useGetPortfolioBankAccount } from "api/portfolio-bank-accounts";
import { QueryError } from "common/components";
import Surface from "common/components/Surface";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface PortfolioBankAccountDetailsContainerProps {
  portfolioBankAccountId: string;
}

export default function PortfolioBankAccountDetailsContainer({
  portfolioBankAccountId,
}: PortfolioBankAccountDetailsContainerProps): ReactElement {
  const { t } = useTranslation("servicing");
  const portfolioBankAccount = useGetPortfolioBankAccount(
    portfolioBankAccountId,
  );
  const appConfig = useAppConfiguration();
  const href = `${appConfig.appRoutes.servicing}/settings/bank-accounts/${portfolioBankAccountId}/edit`;

  return (
    <Loader
      ready={Boolean(portfolioBankAccount.data ?? portfolioBankAccount.error)}
      render={(): ReactElement => {
        if (portfolioBankAccount.error || !portfolioBankAccount.data) {
          return <QueryError onRetry={portfolioBankAccount.refetch} />;
        }

        return (
          <Surface
            title={t("bank_accounts.bank_account_details.details_heading")}
            action={<EditButton component={RouterLink} to={href} />}
          >
            <DescriptionList
              label={t("bank_accounts.bank_account_details.details_heading")}
            >
              <DescriptionList.Item
                label={t(
                  "bank_accounts.bank_account_details.service_user_number_label",
                )}
              >
                {portfolioBankAccount.data.serviceUserNumber}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("bank_accounts.bank_account_details.sort_code_label")}
              >
                {portfolioBankAccount.data.sortCode}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t(
                  "bank_accounts.bank_account_details.account_number_label",
                )}
              >
                {portfolioBankAccount.data.accountNumber}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("bank_accounts.bank_account_details.iban_label")}
              >
                {portfolioBankAccount.data.iban}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("bank_accounts.bank_account_details.bic_label")}
              >
                {portfolioBankAccount.data.bic}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t(
                  "bank_accounts.bank_account_details.submission_file_type_label",
                )}
              >
                {portfolioBankAccount.data.directDebitSubmissionFileType}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("bank_accounts.bank_account_details.address_label")}
              >
                {[
                  portfolioBankAccount.data.addressLine1,
                  portfolioBankAccount.data.addressLine2,
                  portfolioBankAccount.data.addressLine3,
                  portfolioBankAccount.data.addressLine4,
                  portfolioBankAccount.data.postcode,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </DescriptionList.Item>
            </DescriptionList>
          </Surface>
        );
      }}
    />
  );
}
