import { FormDialog } from "@ldms/mui-sdk/templates";
import { Box, MenuItem, Stack, TextField, Typography } from "@mui/material";
import {
  ControlledTextField,
  FileUploader,
  UploadedFileBanner,
} from "common/components";
import { useYupResolver } from "common/hooks";
import { AttachmentTypeModel } from "generated/core/models/AttachmentTypeModel";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

const attachmentTypes = Object.values(AttachmentTypeModel);
const maxFileSize = 31457280; //maxFileSize = 30MB

export type Attachment = {
  filename: string;
  description?: string;
  attachmentType: AttachmentTypeModel;
  file: Blob;
};

interface AddAttachmentErrorProps {
  error?: string;
}

function AddAttachmentError({ error }: AddAttachmentErrorProps): ReactElement {
  return (
    <Typography color="error" data-testid="form.error">
      {error}
    </Typography>
  );
}

interface AddAttachmentDialogProps {
  onClose(): void;
  onSubmit(data: Attachment): Promise<void>;
  open: boolean;
  error?: string;
}

export default function AddAttachmentDialog({
  open,
  error,
  onClose,
  onSubmit: onSubmitCallBack,
}: AddAttachmentDialogProps): ReactElement {
  const { t } = useTranslation("documents");
  const [filename, setFilename] = useState("");
  const [attachmentBlob, setAttachmentBlob] = useState<File>();
  const [uploadError, setUploadError] = useState("");

  const descriptionLabel = t("description_label");
  const typeHeadingLabel = t("type_heading_label");
  const filenameLabel = t("file_name_label");

  const resolver = useYupResolver<Attachment>((yup) =>
    yup.object().shape({
      description: yup.string().maxCharacters(500, descriptionLabel),
      filename: yup.string(),
      attachmentType: yup.string().isRequired(typeHeadingLabel),
      file: yup.object(),
    }),
  );

  const validateFileUpload = (file: File): void => {
    if (file.size > maxFileSize) {
      throw new Error(t("file_size_upload_error", { filename: file.name }));
    }

    if (file.type !== "application/pdf") {
      throw new Error(t("file_type_upload_error", { filename: file.name }));
    }
  };

  const onAttachmentUpload = async (file: File): Promise<void> => {
    setUploadError("");
    setFilename(file.name);
    try {
      validateFileUpload(file);
      setAttachmentBlob(file);
    } catch (validationError) {
      setUploadError((validationError as Error).message);
      setAttachmentBlob(undefined);
    }
  };

  const onFileDelete = (): void => {
    setAttachmentBlob(undefined);
    setUploadError("");
    setFilename("");
  };

  const handleClose = (): void => {
    onFileDelete();
    onClose();
  };

  async function onSubmit(data: Attachment): Promise<void> {
    await onSubmitCallBack({
      ...data,
      filename: filename,
      file: attachmentBlob ?? new Blob(),
    });
    onFileDelete();
  }

  return (
    <FormDialog
      onClose={handleClose}
      onSubmit={onSubmit}
      open={open}
      title={t("attach_document_title")}
      resolver={resolver}
      defaultValues={{
        description: "",
        filename: "",
        attachmentType: AttachmentTypeModel.AnnualStatement,
      }}
    >
      {(form) => (
        <>
          {!attachmentBlob && (
            <Box marginBottom={1}>
              <FileUploader
                onInput={onAttachmentUpload}
                loading={false}
                prompt={t("upload_prompt_text")}
              />
            </Box>
          )}

          {attachmentBlob && (
            <Stack>
              <UploadedFileBanner
                onFileDelete={onFileDelete}
                fileName={filename}
                buttonText={t("delete_document_button")}
              />
              <TextField
                {...form.register("filename", { disabled: true })}
                error={Boolean(form.formState.errors.filename)}
                helperText={form.formState.errors.filename?.message}
                label={filenameLabel}
                value={filename}
              />
              <ControlledTextField
                disabled={form.formState.isSubmitting}
                error={form.formState.errors.attachmentType?.message}
                control={form.control}
                fullWidth
                id="type"
                label={typeHeadingLabel}
                select
                name="attachmentType"
              >
                {attachmentTypes.map((type) => (
                  <MenuItem value={String(type)} key={String(type)}>
                    {type}
                  </MenuItem>
                ))}
              </ControlledTextField>
              <TextField
                {...form.register("description")}
                disabled={form.formState.isSubmitting}
                error={Boolean(form.formState.errors.description)}
                helperText={form.formState.errors.description?.message}
                label={descriptionLabel}
                multiline
                rows={5}
              />
            </Stack>
          )}
          {(error || uploadError) && (
            <AddAttachmentError error={error ?? uploadError} />
          )}
        </>
      )}
    </FormDialog>
  );
}
