import { Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useListTemplates } from "api/documents/templates/listTemplates";
import { useListPortfolios } from "api/documents/templates/portfolios/listPortfolios";
import TemplateList from "apps/documents/components/TemplateList";
import { TemplateTypeEnum } from "apps/documents/types";
import { NoResults } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface TemplatesPortfolioFilterParameter {
  portfolioId?: number;
  type?: string;
}

export default function Templates(): ReactElement {
  const [parameters, setParameters] =
    useState<TemplatesPortfolioFilterParameter>({
      portfolioId: undefined,
      type: TemplateTypeEnum.System,
    });
  const portfolios = useListPortfolios();
  const defaultPortfolio = portfolios.data?.[0]?.id;
  const templates = useListTemplates(parameters, {
    enabled: Boolean(parameters.portfolioId),
  });
  const { t } = useTranslation("documents");
  const appConfig = useAppConfiguration();

  useEffect(() => {
    if (defaultPortfolio) {
      setParameters({
        portfolioId: defaultPortfolio,
        type: TemplateTypeEnum.System,
      });
    }
  }, [defaultPortfolio]);

  const errorMessage = (
    <Typography
      role="alert"
      color="error"
      aria-label={t("common:error.default")}
    >
      {t("common:error.default")}
    </Typography>
  );

  const handlePortfolioChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const { name, value } = event.target;
    setParameters((portfolioFilters) => ({
      ...portfolioFilters,
      [name]: value,
    }));
  };

  const renderTemplates = (): ReactElement => {
    if (portfolios.error || !portfolios.data) {
      return errorMessage;
    }

    if (portfolios.data.length === 0) {
      return <NoResults message={t("templates.no_portfolios_message")} />;
    }

    return (
      <ListLayout
        filters={
          <TextField
            inputProps={{ id: "portfolioId" }}
            onChange={handlePortfolioChange}
            label={t("portfolio_drop_down")}
            name="portfolioId"
            select
            margin="none"
            size="small"
            InputLabelProps={{
              htmlFor: "portfolioId",
              shrink: true,
            }}
            value={parameters.portfolioId ?? ""}
            variant="outlined"
          >
            {portfolios.data.map((portfolio) => (
              <MenuItem value={portfolio.id} key={portfolio.id}>
                {portfolio.name}
              </MenuItem>
            ))}
          </TextField>
        }
      >
        <Loader
          ready={Boolean(templates.data ?? templates.error)}
          render={() => {
            if (templates.error || !templates.data) {
              return errorMessage;
            }

            return (
              <TemplateList
                data={templates.data.map((item) => ({
                  id: item.id,
                  name: item.name,
                  lastUpdatedAt: item.createdAt,
                  lastUpdatedBy: item.createdBy,
                  version: item.version,
                }))}
                templateUrl={`${appConfig.appRoutes.documents}/settings/templates`}
                loading={templates.isValidating}
              />
            );
          }}
        />
      </ListLayout>
    );
  };

  return (
    <Loader
      fallback={
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      }
      ready={Boolean(portfolios.data ?? portfolios.error)}
      render={renderTemplates}
    />
  );
}
