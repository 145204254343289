/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';

/**
 * Third Party Assocaited With An Agreement
 * @export
 * @interface AgreementThirdPartyListItemModel
 */
export interface AgreementThirdPartyListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AgreementThirdPartyListItemModel
     */
    readonly id: number;
    /**
     * A read only Id
     * @type {number}
     * @memberof AgreementThirdPartyListItemModel
     */
    readonly agreementId?: number;
    /**
     * Name of service provider
     * @type {string}
     * @memberof AgreementThirdPartyListItemModel
     */
    name: string;
    /**
     * The third party type description
     * @type {string}
     * @memberof AgreementThirdPartyListItemModel
     */
    type: string;
    /**
     * The nominated contact for the third party
     * @type {string}
     * @memberof AgreementThirdPartyListItemModel
     */
    contact?: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof AgreementThirdPartyListItemModel
     */
    address: AddressModel;
    /**
     * Third party fax number
     * @type {string}
     * @memberof AgreementThirdPartyListItemModel
     */
    faxNumber?: string;
    /**
     * Third party email number
     * @type {string}
     * @memberof AgreementThirdPartyListItemModel
     */
    emailAddress?: string;
    /**
     * Third party phone number
     * @type {string}
     * @memberof AgreementThirdPartyListItemModel
     */
    telephone?: string;
    /**
     * The VAT registration number for a Manufacturer Third Party
     * @type {string}
     * @memberof AgreementThirdPartyListItemModel
     */
    vatRegistrationNumber?: string;
}

/**
 * Check if a given object implements the AgreementThirdPartyListItemModel interface.
 */
export function instanceOfAgreementThirdPartyListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function AgreementThirdPartyListItemModelFromJSON(json: any): AgreementThirdPartyListItemModel {
    return AgreementThirdPartyListItemModelFromJSONTyped(json, false);
}

export function AgreementThirdPartyListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementThirdPartyListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'agreementId': !exists(json, 'agreementId') ? undefined : json['agreementId'],
        'name': json['name'],
        'type': json['type'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'address': AddressModelFromJSON(json['address']),
        'faxNumber': !exists(json, 'faxNumber') ? undefined : json['faxNumber'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'vatRegistrationNumber': !exists(json, 'vatRegistrationNumber') ? undefined : json['vatRegistrationNumber'],
    };
}

export function AgreementThirdPartyListItemModelToJSON(value?: AgreementThirdPartyListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'contact': value.contact,
        'address': AddressModelToJSON(value.address),
        'faxNumber': value.faxNumber,
        'emailAddress': value.emailAddress,
        'telephone': value.telephone,
        'vatRegistrationNumber': value.vatRegistrationNumber,
    };
}

