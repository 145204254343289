/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Payment Instructions within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetPaymentInstructionsModel,
} from '../models/index';
import {
    GetPaymentInstructionsModelFromJSON,
    GetPaymentInstructionsModelToJSON,
} from '../models/index';

export interface DeletePaymentInstructionRequest {
    id: string;
}

export interface GetPaymentInstructionsRequest {
    id: string;
}

/**
 * 
 */
export class PaymentInstructionsApi extends runtime.BaseAPI {

    /**
     * Delete a Payment Instruction
     */
    async deletePaymentInstructionRaw(requestParameters: DeletePaymentInstructionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePaymentInstruction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement:payment-instructions:manage"]);
        }

        const response = await this.request({
            path: `/payment-instructions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Payment Instruction
     */
    async deletePaymentInstruction(requestParameters: DeletePaymentInstructionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePaymentInstructionRaw(requestParameters, initOverrides);
    }

    /**
     * Get Payment Instructions
     */
    async getPaymentInstructionsRaw(requestParameters: GetPaymentInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaymentInstructionsModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPaymentInstructions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement:payment-instructions:view"]);
        }

        const response = await this.request({
            path: `/payment-instructions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentInstructionsModelFromJSON(jsonValue));
    }

    /**
     * Get Payment Instructions
     */
    async getPaymentInstructions(requestParameters: GetPaymentInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaymentInstructionsModel> {
        const response = await this.getPaymentInstructionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
