import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Loader } from "@ldms/mui-sdk/templates";
import { Box } from "@mui/material";
import { useGetPaymentInstruction } from "api/agreements/payment-instructions/getPaymentInstruction";
import { AppError } from "common/components";
import Surface from "common/components/Surface";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

function useErrorCodeTranslations(): Map<
  string,
  {
    message: string;
    title: string;
  }
> {
  const { t } = useTranslation("agreements");

  return new Map([
    [
      "paymentInstruction404",
      {
        message: t("error.payment_instruction_404_message"),
        title: t("error.payment_instruction_404"),
      },
    ],
  ]);
}

interface PaymentInstructionDetailsProps {
  paymentInstructionId: string;
  agreementId: string;
}

export default function PaymentInstructionDetails({
  paymentInstructionId,
  agreementId,
}: Readonly<PaymentInstructionDetailsProps>): ReactElement {
  const { t } = useTranslation(["agreements", "common"]);
  const errorCodeTranslations = useErrorCodeTranslations();
  const locale = useLocale();
  const { formatAmount } = useFormat();
  const paymentInstruction = useGetPaymentInstruction(
    agreementId,
    paymentInstructionId,
  );

  const renderPaymentInstructionDetails = (): ReactElement => {
    if (paymentInstruction.error || !paymentInstruction.data) {
      const errorCodeTranslation = errorCodeTranslations?.get(
        `paymentInstruction${paymentInstruction.error?.cause}`,
      );
      return (
        <AppError
          message={errorCodeTranslation?.message}
          title={errorCodeTranslation?.title}
        />
      );
    }

    const details = paymentInstruction.data?.details;
    const bank = paymentInstruction.data?.bank;
    const payee = paymentInstruction.data?.payee;

    return (
      <Box
        display="grid"
        gap={2}
        gridTemplateColumns={{
          lg: "repeat(2, minmax(0, 1fr))",
          xl: "repeat(3, minmax(0, 1fr))",
        }}
      >
        <Surface title={t("payments.details.details_title")}>
          <DescriptionList label={t("payments.details.details_title")}>
            <DescriptionList.Item label={t("payments.details.method_label")}>
              {details?.method}
            </DescriptionList.Item>

            <DescriptionList.Item label={t("payments.details.type_label")}>
              {paymentInstruction.data?.type}
            </DescriptionList.Item>

            <DescriptionList.Item label={t("payments.details.amount_label")}>
              {formatAmount(paymentInstruction.data?.amount)}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.details.day_of_month_label")}
            >
              {paymentInstruction?.data?.dayOfMonth &&
                locale.formatNumberOrdinal(paymentInstruction.data?.dayOfMonth)}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.details.start_date_label")}
            >
              {paymentInstruction?.data?.startDate &&
                locale.formatDate(new Date(paymentInstruction.data?.startDate))}
            </DescriptionList.Item>

            <DescriptionList.Item label={t("payments.details.end_date_label")}>
              {paymentInstruction.data?.endDate &&
                locale.formatDate(new Date(paymentInstruction?.data?.endDate))}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.details.signature_date_label")}
            >
              {paymentInstruction.data?.signatureDate &&
                locale.formatDate(
                  new Date(paymentInstruction?.data?.signatureDate),
                )}
            </DescriptionList.Item>

            <DescriptionList.Item label={t("payments.details.reference_label")}>
              {details?.reference}
            </DescriptionList.Item>
          </DescriptionList>
        </Surface>
        <Surface title={t("payments.payee_details.payee_details_title")}>
          <DescriptionList
            label={t("payments.payee_details.payee_details_title")}
          >
            <DescriptionList.Item
              label={t("payments.payee_details.payee_label")}
            >
              {payee?.name}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.payee_details.type_label")}
            >
              {payee?.type ?? "Main"}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.payee_details.address_label")}
            >
              {[
                payee?.addressLine1,
                payee?.addressLine2,
                payee?.addressLine3,
                payee?.addressLine4,
                payee?.postcode,
              ]
                .filter(Boolean)
                .join(" ")}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.payee_details.phone_label")}
            >
              {payee?.telephoneNumber}
            </DescriptionList.Item>

            <DescriptionList.Item
              label={t("payments.payee_details.email_label")}
            >
              {payee?.email}
            </DescriptionList.Item>
          </DescriptionList>
        </Surface>
        <Surface title={t("payments.bank_details.bank_details_title")}>
          <DescriptionList
            label={t("payments.bank_details.bank_details_title")}
          >
            <DescriptionList.Item label={t("payments.bank_details.bank_label")}>
              {bank?.name}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("payments.bank_details.name_on_account_label")}
            >
              {bank?.accountHoldersName}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("payments.bank_details.sort_code_label")}
            >
              {bank?.sortCode}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("payments.bank_details.account_number_label")}
            >
              {bank?.accountNumber}
            </DescriptionList.Item>
            <DescriptionList.Item label={t("payments.bank_details.iban_label")}>
              {bank?.iban}
            </DescriptionList.Item>
            <DescriptionList.Item label={t("payments.bank_details.bic_label")}>
              {bank?.bic}
            </DescriptionList.Item>
          </DescriptionList>
        </Surface>
      </Box>
    );
  };

  return (
    <Loader
      ready={Boolean(paymentInstruction.data || paymentInstruction.error)}
      render={renderPaymentInstructionDetails}
    />
  );
}
