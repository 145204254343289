/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';
import type { NewOrUsedModel } from './NewOrUsedModel';
import {
    NewOrUsedModelFromJSON,
    NewOrUsedModelFromJSONTyped,
    NewOrUsedModelToJSON,
} from './NewOrUsedModel';

/**
 * The asset
 * @export
 * @interface AssetModel
 */
export interface AssetModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AssetModel
     */
    readonly id: number;
    /**
     * A read only Id
     * @type {number}
     * @memberof AssetModel
     */
    readonly clientId: number;
    /**
     * A read only Id
     * @type {number}
     * @memberof AssetModel
     */
    readonly agreementId: number;
    /**
     * The agreement number
     * @type {string}
     * @memberof AssetModel
     */
    agreementNumber?: string;
    /**
     * The asset number
     * @type {number}
     * @memberof AssetModel
     */
    assetNumber: number;
    /**
     * The asset status
     * @type {string}
     * @memberof AssetModel
     */
    status?: string;
    /**
     * The purchase order number
     * @type {string}
     * @memberof AssetModel
     */
    purchaseOrderNumber?: string;
    /**
     * The asset type
     * @type {string}
     * @memberof AssetModel
     */
    type: string;
    /**
     * The asset type code
     * @type {string}
     * @memberof AssetModel
     */
    code: string;
    /**
     * The asset description
     * @type {string}
     * @memberof AssetModel
     */
    description: string;
    /**
     * The asset serial number
     * @type {string}
     * @memberof AssetModel
     */
    serialNumber?: string;
    /**
     * The asset registration number
     * @type {string}
     * @memberof AssetModel
     */
    registrationNumber?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetModel
     */
    registrationDate?: Date;
    /**
     * The asset vehicle identification number
     * @type {string}
     * @memberof AssetModel
     */
    vehicleIdentificationNumber?: string;
    /**
     * The asset colour
     * @type {string}
     * @memberof AssetModel
     */
    colour?: string;
    /**
     * The asset manufacturer
     * @type {string}
     * @memberof AssetModel
     */
    manufacturerCode?: string;
    /**
     * The asset manufacturer
     * @type {string}
     * @memberof AssetModel
     */
    manufacturer?: string;
    /**
     * The asset model
     * @type {string}
     * @memberof AssetModel
     */
    model?: string;
    /**
     * The asset body type
     * @type {string}
     * @memberof AssetModel
     */
    bodyType?: string;
    /**
     * The asset year of manufacture
     * @type {string}
     * @memberof AssetModel
     */
    yearManufactured?: string;
    /**
     * 
     * @type {NewOrUsedModel}
     * @memberof AssetModel
     */
    newOrUsed: NewOrUsedModel;
    /**
     * The asset engine size
     * @type {number}
     * @memberof AssetModel
     */
    engineSize?: number;
    /**
     * The annual mileage
     * @type {number}
     * @memberof AssetModel
     */
    annualMileage?: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetModel
     */
    hpiRegisteredDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetModel
     */
    hpiRemovedDate?: Date;
    /**
     * The registered keeper of the asset
     * @type {string}
     * @memberof AssetModel
     */
    registeredKeeper?: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof AssetModel
     */
    keeperAddress?: AddressModel;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    purchasePrice?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    depositPaid?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    partExValue?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    tradeInSum?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    dealerIncentive?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    documentFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    facilityFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    chargeForCredit?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    totalAmountPayable?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetModel
     */
    settledDate?: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    residualValue?: string;
    /**
     * Flag to indicate whether the asset has a service plan
     * @type {boolean}
     * @memberof AssetModel
     */
    servicePlan?: boolean;
    /**
     * Flag to define if the user wishes to apply insurance
     * @type {boolean}
     * @memberof AssetModel
     */
    includeInsurance?: boolean;
    /**
     * The asset category
     * @type {string}
     * @memberof AssetModel
     */
    category?: string;
    /**
     * The asset banding
     * @type {string}
     * @memberof AssetModel
     */
    banding?: string;
    /**
     * Flag to indicate whether the asset can be settled
     * @type {boolean}
     * @memberof AssetModel
     */
    canSettle: boolean;
    /**
     * Flag to indicate whether the agreement is active
     * @type {boolean}
     * @memberof AssetModel
     */
    isActive: boolean;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    fleetDiscount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    invoicePrice?: string;
    /**
     * The asset manufacturer name
     * @type {string}
     * @memberof AssetModel
     */
    manufacturerName?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    manufacturerSubsidyAmount?: string;
    /**
     * The asset manufacturer scheme code
     * @type {string}
     * @memberof AssetModel
     */
    manufacturerSchemeCode?: string;
    /**
     * Vat rate
     * @type {number}
     * @memberof AssetModel
     */
    manufacturerSubsidyVatRate?: number;
    /**
     * Flag to indicate whether manufacturer subsidy has been paid
     * @type {boolean}
     * @memberof AssetModel
     */
    manufacturerSubsidyPaid?: boolean;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    instalmentAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    balloonAmount?: string;
}

/**
 * Check if a given object implements the AssetModel interface.
 */
export function instanceOfAssetModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "agreementId" in value;
    isInstance = isInstance && "assetNumber" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "newOrUsed" in value;
    isInstance = isInstance && "canSettle" in value;
    isInstance = isInstance && "isActive" in value;

    return isInstance;
}

export function AssetModelFromJSON(json: any): AssetModel {
    return AssetModelFromJSONTyped(json, false);
}

export function AssetModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'clientId': json['clientId'],
        'agreementId': json['agreementId'],
        'agreementNumber': !exists(json, 'agreementNumber') ? undefined : json['agreementNumber'],
        'assetNumber': json['assetNumber'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'purchaseOrderNumber': !exists(json, 'purchaseOrderNumber') ? undefined : json['purchaseOrderNumber'],
        'type': json['type'],
        'code': json['code'],
        'description': json['description'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'registrationDate': !exists(json, 'registrationDate') ? undefined : (new Date(json['registrationDate'])),
        'vehicleIdentificationNumber': !exists(json, 'vehicleIdentificationNumber') ? undefined : json['vehicleIdentificationNumber'],
        'colour': !exists(json, 'colour') ? undefined : json['colour'],
        'manufacturerCode': !exists(json, 'manufacturerCode') ? undefined : json['manufacturerCode'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'bodyType': !exists(json, 'bodyType') ? undefined : json['bodyType'],
        'yearManufactured': !exists(json, 'yearManufactured') ? undefined : json['yearManufactured'],
        'newOrUsed': NewOrUsedModelFromJSON(json['newOrUsed']),
        'engineSize': !exists(json, 'engineSize') ? undefined : json['engineSize'],
        'annualMileage': !exists(json, 'annualMileage') ? undefined : json['annualMileage'],
        'hpiRegisteredDate': !exists(json, 'hpiRegisteredDate') ? undefined : (new Date(json['hpiRegisteredDate'])),
        'hpiRemovedDate': !exists(json, 'hpiRemovedDate') ? undefined : (new Date(json['hpiRemovedDate'])),
        'registeredKeeper': !exists(json, 'registeredKeeper') ? undefined : json['registeredKeeper'],
        'keeperAddress': !exists(json, 'keeperAddress') ? undefined : AddressModelFromJSON(json['keeperAddress']),
        'purchasePrice': !exists(json, 'purchasePrice') ? undefined : json['purchasePrice'],
        'depositPaid': !exists(json, 'depositPaid') ? undefined : json['depositPaid'],
        'partExValue': !exists(json, 'partExValue') ? undefined : json['partExValue'],
        'tradeInSum': !exists(json, 'tradeInSum') ? undefined : json['tradeInSum'],
        'dealerIncentive': !exists(json, 'dealerIncentive') ? undefined : json['dealerIncentive'],
        'documentFee': !exists(json, 'documentFee') ? undefined : json['documentFee'],
        'facilityFee': !exists(json, 'facilityFee') ? undefined : json['facilityFee'],
        'chargeForCredit': !exists(json, 'chargeForCredit') ? undefined : json['chargeForCredit'],
        'totalAmountPayable': !exists(json, 'totalAmountPayable') ? undefined : json['totalAmountPayable'],
        'settledDate': !exists(json, 'settledDate') ? undefined : (new Date(json['settledDate'])),
        'residualValue': !exists(json, 'residualValue') ? undefined : json['residualValue'],
        'servicePlan': !exists(json, 'servicePlan') ? undefined : json['servicePlan'],
        'includeInsurance': !exists(json, 'includeInsurance') ? undefined : json['includeInsurance'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'banding': !exists(json, 'banding') ? undefined : json['banding'],
        'canSettle': json['canSettle'],
        'isActive': json['isActive'],
        'fleetDiscount': !exists(json, 'fleetDiscount') ? undefined : json['fleetDiscount'],
        'invoicePrice': !exists(json, 'invoicePrice') ? undefined : json['invoicePrice'],
        'manufacturerName': !exists(json, 'manufacturerName') ? undefined : json['manufacturerName'],
        'manufacturerSubsidyAmount': !exists(json, 'manufacturerSubsidyAmount') ? undefined : json['manufacturerSubsidyAmount'],
        'manufacturerSchemeCode': !exists(json, 'manufacturerSchemeCode') ? undefined : json['manufacturerSchemeCode'],
        'manufacturerSubsidyVatRate': !exists(json, 'manufacturerSubsidyVatRate') ? undefined : json['manufacturerSubsidyVatRate'],
        'manufacturerSubsidyPaid': !exists(json, 'manufacturerSubsidyPaid') ? undefined : json['manufacturerSubsidyPaid'],
        'instalmentAmount': !exists(json, 'instalmentAmount') ? undefined : json['instalmentAmount'],
        'balloonAmount': !exists(json, 'balloonAmount') ? undefined : json['balloonAmount'],
    };
}

export function AssetModelToJSON(value?: AssetModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreementNumber': value.agreementNumber,
        'assetNumber': value.assetNumber,
        'status': value.status,
        'purchaseOrderNumber': value.purchaseOrderNumber,
        'type': value.type,
        'code': value.code,
        'description': value.description,
        'serialNumber': value.serialNumber,
        'registrationNumber': value.registrationNumber,
        'registrationDate': value.registrationDate === undefined ? undefined : (value.registrationDate.toISOString().substring(0,10)),
        'vehicleIdentificationNumber': value.vehicleIdentificationNumber,
        'colour': value.colour,
        'manufacturerCode': value.manufacturerCode,
        'manufacturer': value.manufacturer,
        'model': value.model,
        'bodyType': value.bodyType,
        'yearManufactured': value.yearManufactured,
        'newOrUsed': NewOrUsedModelToJSON(value.newOrUsed),
        'engineSize': value.engineSize,
        'annualMileage': value.annualMileage,
        'hpiRegisteredDate': value.hpiRegisteredDate === undefined ? undefined : (value.hpiRegisteredDate.toISOString().substring(0,10)),
        'hpiRemovedDate': value.hpiRemovedDate === undefined ? undefined : (value.hpiRemovedDate.toISOString().substring(0,10)),
        'registeredKeeper': value.registeredKeeper,
        'keeperAddress': AddressModelToJSON(value.keeperAddress),
        'purchasePrice': value.purchasePrice,
        'depositPaid': value.depositPaid,
        'partExValue': value.partExValue,
        'tradeInSum': value.tradeInSum,
        'dealerIncentive': value.dealerIncentive,
        'documentFee': value.documentFee,
        'facilityFee': value.facilityFee,
        'chargeForCredit': value.chargeForCredit,
        'totalAmountPayable': value.totalAmountPayable,
        'settledDate': value.settledDate === undefined ? undefined : (value.settledDate.toISOString().substring(0,10)),
        'residualValue': value.residualValue,
        'servicePlan': value.servicePlan,
        'includeInsurance': value.includeInsurance,
        'category': value.category,
        'banding': value.banding,
        'canSettle': value.canSettle,
        'isActive': value.isActive,
        'fleetDiscount': value.fleetDiscount,
        'invoicePrice': value.invoicePrice,
        'manufacturerName': value.manufacturerName,
        'manufacturerSubsidyAmount': value.manufacturerSubsidyAmount,
        'manufacturerSchemeCode': value.manufacturerSchemeCode,
        'manufacturerSubsidyVatRate': value.manufacturerSubsidyVatRate,
        'manufacturerSubsidyPaid': value.manufacturerSubsidyPaid,
        'instalmentAmount': value.instalmentAmount,
        'balloonAmount': value.balloonAmount,
    };
}

