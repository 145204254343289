import { PagedQuery, useQueryPaged } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { OnboardingApi } from "generated/onboarding/apis";
import {
  OnboardingListItemModel,
  OnboardingStatusModel,
} from "generated/onboarding/models";
import { keys } from ".";

export const useListOnboardings: PagedQuery<
  OnboardingListItemModel,
  { status?: OnboardingStatusModel; from?: Date; to?: Date }
> = ({ pageSize, params = {} } = {}) => {
  const api = useApi(OnboardingApi);

  return useQueryPaged(
    keys.list({ status: params.status, from: params.from, to: params.to }),
    async (_key, paging) =>
      api.listOnboardingsRaw({
        limit: paging.pageSize,
        offset: paging.offset,
        status: params.status ? params.status.toUpperCase() : undefined,
        from: params.from,
        to: params.to,
      }),
    { limit: pageSize },
  );
};
