import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import Contacts from "apps/servicing/modules/customers/containers/Contacts";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export default function ContactsView() {
  const { t } = useTranslation("customers");
  const customer = useCustomer();

  return (
    <ViewLayout
      action={
        <AccessControl allowedPermissions={["servicing:contacts:manage"]}>
          <AddButton
            component={RouterLink}
            color="primary"
            variant="contained"
            to="../contacts/add-contact"
          />
        </AccessControl>
      }
      title={t("contacts.heading")}
    >
      <ContainedLayout>
        <Contacts clientSystemId={customer.data.systemId} />
      </ContainedLayout>
    </ViewLayout>
  );
}
