/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Portfolios within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PortfolioBankDetailsModel } from './PortfolioBankDetailsModel';
import {
    PortfolioBankDetailsModelFromJSON,
    PortfolioBankDetailsModelFromJSONTyped,
    PortfolioBankDetailsModelToJSON,
} from './PortfolioBankDetailsModel';

/**
 * 
 * @export
 * @interface PortfolioDetailsModel
 */
export interface PortfolioDetailsModel {
    /**
     * Unique identifier of Portfolio
     * @type {string}
     * @memberof PortfolioDetailsModel
     */
    systemId: string;
    /**
     * The portfolio name
     * @type {string}
     * @memberof PortfolioDetailsModel
     */
    name: string;
    /**
     * Indicates whether the portfolio is hpi registered
     * @type {boolean}
     * @memberof PortfolioDetailsModel
     */
    isHpiRegistered: boolean;
    /**
     * The Equifax Insight Portfolio number
     * @type {string}
     * @memberof PortfolioDetailsModel
     */
    equifaxInsightNumber?: string;
    /**
     * CCR Provider Code
     * @type {string}
     * @memberof PortfolioDetailsModel
     */
    ccrProviderCode?: string;
    /**
     * 
     * @type {PortfolioBankDetailsModel}
     * @memberof PortfolioDetailsModel
     */
    bankAccount: PortfolioBankDetailsModel;
    /**
     * The Unique identifier of the company associated to the portfolio
     * @type {string}
     * @memberof PortfolioDetailsModel
     */
    readonly companyId: string;
    /**
     * The default asset depreciation percentage
     * @type {number}
     * @memberof PortfolioDetailsModel
     */
    depreciationPercentage?: number;
    /**
     * The default asset depreciation minimum value
     * @type {number}
     * @memberof PortfolioDetailsModel
     */
    depreciationMinimumValue?: number;
}

/**
 * Check if a given object implements the PortfolioDetailsModel interface.
 */
export function instanceOfPortfolioDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "systemId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isHpiRegistered" in value;
    isInstance = isInstance && "bankAccount" in value;
    isInstance = isInstance && "companyId" in value;

    return isInstance;
}

export function PortfolioDetailsModelFromJSON(json: any): PortfolioDetailsModel {
    return PortfolioDetailsModelFromJSONTyped(json, false);
}

export function PortfolioDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemId': json['systemId'],
        'name': json['name'],
        'isHpiRegistered': json['isHpiRegistered'],
        'equifaxInsightNumber': !exists(json, 'equifaxInsightNumber') ? undefined : json['equifaxInsightNumber'],
        'ccrProviderCode': !exists(json, 'ccrProviderCode') ? undefined : json['ccrProviderCode'],
        'bankAccount': PortfolioBankDetailsModelFromJSON(json['bankAccount']),
        'companyId': json['companyId'],
        'depreciationPercentage': !exists(json, 'depreciationPercentage') ? undefined : json['depreciationPercentage'],
        'depreciationMinimumValue': !exists(json, 'depreciationMinimumValue') ? undefined : json['depreciationMinimumValue'],
    };
}

export function PortfolioDetailsModelToJSON(value?: PortfolioDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'systemId': value.systemId,
        'name': value.name,
        'isHpiRegistered': value.isHpiRegistered,
        'equifaxInsightNumber': value.equifaxInsightNumber,
        'ccrProviderCode': value.ccrProviderCode,
        'bankAccount': PortfolioBankDetailsModelToJSON(value.bankAccount),
        'depreciationPercentage': value.depreciationPercentage,
        'depreciationMinimumValue': value.depreciationMinimumValue,
    };
}

