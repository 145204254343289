/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Financial Postings within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FinancialPostingListItemModel
 */
export interface FinancialPostingListItemModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof FinancialPostingListItemModel
     */
    financialDate: Date;
    /**
     * The agreement number
     * @type {string}
     * @memberof FinancialPostingListItemModel
     */
    agreementNumber: string;
    /**
     * The asset type and serial number
     * @type {string}
     * @memberof FinancialPostingListItemModel
     */
    asset?: string;
    /**
     * The financial posting account code and name
     * @type {string}
     * @memberof FinancialPostingListItemModel
     */
    accountName: string;
    /**
     * The financial posting stage group
     * @type {string}
     * @memberof FinancialPostingListItemModel
     */
    stageGroup: string;
    /**
     * The financial posting stage name
     * @type {string}
     * @memberof FinancialPostingListItemModel
     */
    stageName: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FinancialPostingListItemModel
     */
    amount: string;
    /**
     * The period (YYYYMM) for the financial posting
     * @type {string}
     * @memberof FinancialPostingListItemModel
     */
    financialPeriod: string;
    /**
     * The financial posting reference
     * @type {string}
     * @memberof FinancialPostingListItemModel
     */
    reference: string;
}

/**
 * Check if a given object implements the FinancialPostingListItemModel interface.
 */
export function instanceOfFinancialPostingListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "financialDate" in value;
    isInstance = isInstance && "agreementNumber" in value;
    isInstance = isInstance && "accountName" in value;
    isInstance = isInstance && "stageGroup" in value;
    isInstance = isInstance && "stageName" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "financialPeriod" in value;
    isInstance = isInstance && "reference" in value;

    return isInstance;
}

export function FinancialPostingListItemModelFromJSON(json: any): FinancialPostingListItemModel {
    return FinancialPostingListItemModelFromJSONTyped(json, false);
}

export function FinancialPostingListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialPostingListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'financialDate': (new Date(json['financialDate'])),
        'agreementNumber': json['agreementNumber'],
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'accountName': json['accountName'],
        'stageGroup': json['stageGroup'],
        'stageName': json['stageName'],
        'amount': json['amount'],
        'financialPeriod': json['financialPeriod'],
        'reference': json['reference'],
    };
}

export function FinancialPostingListItemModelToJSON(value?: FinancialPostingListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'financialDate': (value.financialDate.toISOString().substring(0,10)),
        'agreementNumber': value.agreementNumber,
        'asset': value.asset,
        'accountName': value.accountName,
        'stageGroup': value.stageGroup,
        'stageName': value.stageName,
        'amount': value.amount,
        'financialPeriod': value.financialPeriod,
        'reference': value.reference,
    };
}

