import { useFormat } from "@ldms/mui-sdk/formatting";
import { AmountField, Form } from "@ldms/mui-sdk/forms";
import { Box, Typography } from "@mui/material";
import {
  FinancialDetailsBalanceTable,
  FinancialDetailsCashPriceTable,
  OnboardingStepActions,
} from "apps/onboarding/components";
import { useOnboarding } from "apps/onboarding/providers";
import CashDepositField from "common/components/CashDepositField";
import { useYupResolver } from "common/hooks";
import { useStepper } from "common/providers";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const transformField = (v: string, o: string): string | null | undefined => {
  return o === "" ? undefined : v;
};

const getValue = (amount?: number): number => {
  return amount ? Number(amount) : 0;
};

const cashDepositLabel = "financial_details.cash_deposit_label";
const partExchangeLabel = "hire_purchase.part_exchange_label";
const balloonPaymentLabel = "hire_purchase.balloon_payment_label";
const subsidyPaymentLabel = "hire_purchase.subsidy_payment_label";
const fleetDiscountLabel = "hire_purchase.fleet_discount_label";

export interface HirePurchaseFinancialDetailsFormModel {
  balloonPayment?: number;
  cashDeposit?: number;
  partExchange?: number;
  fleetDiscount?: number;
  subsidyPayment?: number;
}

export default function HirePurchaseFinancialDetails(): ReactElement {
  const { t } = useTranslation("onboardings");
  const onboarding = useOnboarding();
  const stepper = useStepper();
  const { formatAmount } = useFormat();
  const totalNetCashPrice =
    onboarding.state.assetDetails?.totalNetCashPrice ?? 0;
  const totalVat = onboarding.state.assetDetails?.totalVat ?? 0;
  const totalCashPriceIncVat = totalNetCashPrice + totalVat;

  const resolver = useYupResolver<HirePurchaseFinancialDetailsFormModel>(
    (yup) =>
      yup.object().shape({
        cashDeposit: yup
          .number(t(cashDepositLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(cashDepositLabel))
          .maxAmount(99999999.99, t(cashDepositLabel)),
        partExchange: yup
          .number(t(partExchangeLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(partExchangeLabel))
          .maxAmount(99999999.99, t(partExchangeLabel)),
        balloonPayment: yup
          .number(t(balloonPaymentLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(balloonPaymentLabel))
          .maxAmount(99999999.99, t(balloonPaymentLabel)),
        fleetDiscount: yup
          .number(t(fleetDiscountLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(fleetDiscountLabel))
          .maxAmount(99999999.99, t(fleetDiscountLabel)),
        subsidyPayment: yup
          .number(t(subsidyPaymentLabel))
          .nullable()
          .transform(transformField)
          .minAmount(0, t(subsidyPaymentLabel))
          .maxAmount(99999999.99, t(subsidyPaymentLabel)),
      }),
  );

  const onSubmit = (data: HirePurchaseFinancialDetailsFormModel): void => {
    onboarding.submitFinancialDetails(data);
    stepper.next();
  };

  const defaultValues = onboarding.state
    .financialDetails as HirePurchaseFinancialDetailsFormModel;

  return (
    <>
      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h1" }}>
          {t("financial_details.title")}
        </Typography>
      </Box>

      <Form
        defaultValues={defaultValues}
        resolver={resolver}
        label={t("financial_details.title")}
        onSubmit={onSubmit}
      >
        {(form) => {
          const formWatch = form.watch();
          const totalDeposit =
            getValue(formWatch.cashDeposit) +
            getValue(formWatch.partExchange) +
            getValue(formWatch.fleetDiscount) +
            getValue(formWatch.subsidyPayment);
          const balanceFinanced = totalNetCashPrice - totalDeposit;

          return (
            <>
              <FinancialDetailsCashPriceTable
                testId="financialDetails.cashPrice.table"
                totalVat={{
                  label: "hire_purchase.vat_on_cash_price_label",
                  amount: formatAmount(totalVat.toFixed(2)),
                }}
                totalNetCashPrice={{
                  label: "hire_purchase.total_cash_price_net_vat_label",
                  amount: formatAmount(totalNetCashPrice.toFixed(2)),
                }}
                totalCashPriceIncVat={{
                  label: "hire_purchase.total_cash_price_inc_vat_label",
                  amount: formatAmount(totalCashPriceIncVat.toFixed(2)),
                }}
              />
              <CashDepositField
                helperText={form.formState.errors.cashDeposit?.message}
                defaultValue={defaultValues?.cashDeposit}
                control={form.control}
                error={Boolean(form.formState.errors.cashDeposit?.message)}
              />
              <AmountField
                control={form.control}
                label={t(partExchangeLabel)}
                name="partExchange"
                error={Boolean(form.formState.errors.partExchange?.message)}
                helperText={form.formState.errors.partExchange?.message}
                defaultValue={defaultValues?.partExchange}
              />
              <AmountField
                control={form.control}
                label={t(balloonPaymentLabel)}
                name="balloonPayment"
                error={Boolean(form.formState.errors.balloonPayment?.message)}
                helperText={form.formState.errors.balloonPayment?.message}
                defaultValue={defaultValues?.balloonPayment}
              />

              <FinancialDetailsBalanceTable
                totalDeposit={{
                  label: "hire_purchase.total_deposit_label",
                  amount: formatAmount(totalDeposit),
                }}
                balanceFinanced={{
                  label: "hire_purchase.balance_financed_label",
                  amount: formatAmount(balanceFinanced),
                }}
                fleetDiscount={{
                  label: "hire_purchase.total_fleet_discount_label",
                  amount: formatAmount(defaultValues?.fleetDiscount),
                }}
                manufacturerSubsidy={{
                  label: "hire_purchase.total_manufacturer_subsidy_label",
                  amount: formatAmount(defaultValues?.subsidyPayment),
                }}
              />

              <OnboardingStepActions
                back={{
                  label: t("common:stepper.back_button"),
                  onBack: stepper.previous,
                }}
                next={{ label: t("common:stepper.next_button") }}
              />
            </>
          );
        }}
      </Form>
    </>
  );
}
