/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgreementActivityModel,
  AgreementAssetListItemModel,
  AgreementFinancialPostingListItemModel,
  AgreementListItemModel,
  AgreementModel,
  AgreementSettlementQuoteModel,
  AgreementThirdPartyListItemModel,
  AgreementTransactionModel,
  AvailableAgreementThirdPartyListItemModel,
  ChargeTypeModel,
  FeeUnpaidListItemModel,
  IdTypeModel,
  InsuranceModel,
  MemoModel,
  PayeesListItemModel,
  PaymentAllocationListItemModel,
  PaymentHolidayDetailsModel,
  PaymentInstructionModel,
  PaymentInstructionsListItemModel,
  RemovalReasonModel,
  ResponseFormatModel,
  ReversalReasonModel,
  ScheduleModel,
  SettlementQuoteListItemModel,
  TransactionBankModel,
  TransactionTypeModel,
} from '../models/index';
import {
    AgreementActivityModelFromJSON,
    AgreementActivityModelToJSON,
    AgreementAssetListItemModelFromJSON,
    AgreementAssetListItemModelToJSON,
    AgreementFinancialPostingListItemModelFromJSON,
    AgreementFinancialPostingListItemModelToJSON,
    AgreementListItemModelFromJSON,
    AgreementListItemModelToJSON,
    AgreementModelFromJSON,
    AgreementModelToJSON,
    AgreementSettlementQuoteModelFromJSON,
    AgreementSettlementQuoteModelToJSON,
    AgreementThirdPartyListItemModelFromJSON,
    AgreementThirdPartyListItemModelToJSON,
    AgreementTransactionModelFromJSON,
    AgreementTransactionModelToJSON,
    AvailableAgreementThirdPartyListItemModelFromJSON,
    AvailableAgreementThirdPartyListItemModelToJSON,
    ChargeTypeModelFromJSON,
    ChargeTypeModelToJSON,
    FeeUnpaidListItemModelFromJSON,
    FeeUnpaidListItemModelToJSON,
    IdTypeModelFromJSON,
    IdTypeModelToJSON,
    InsuranceModelFromJSON,
    InsuranceModelToJSON,
    MemoModelFromJSON,
    MemoModelToJSON,
    PayeesListItemModelFromJSON,
    PayeesListItemModelToJSON,
    PaymentAllocationListItemModelFromJSON,
    PaymentAllocationListItemModelToJSON,
    PaymentHolidayDetailsModelFromJSON,
    PaymentHolidayDetailsModelToJSON,
    PaymentInstructionModelFromJSON,
    PaymentInstructionModelToJSON,
    PaymentInstructionsListItemModelFromJSON,
    PaymentInstructionsListItemModelToJSON,
    RemovalReasonModelFromJSON,
    RemovalReasonModelToJSON,
    ResponseFormatModelFromJSON,
    ResponseFormatModelToJSON,
    ReversalReasonModelFromJSON,
    ReversalReasonModelToJSON,
    ScheduleModelFromJSON,
    ScheduleModelToJSON,
    SettlementQuoteListItemModelFromJSON,
    SettlementQuoteListItemModelToJSON,
    TransactionBankModelFromJSON,
    TransactionBankModelToJSON,
    TransactionTypeModelFromJSON,
    TransactionTypeModelToJSON,
} from '../models/index';

export interface CurrentStatusRequest {
    type: IdTypeModel;
    format: ResponseFormatModel;
    requestBody?: Array<string>;
}

export interface ExportAgreementFinancialPostingsRequest {
    agreementId: number;
}

export interface GetAgreementRequest {
    agreementId: number;
}

export interface GetAgreementSettlementQuoteDetailsRequest {
    agreementId: number;
}

export interface GetInsuranceRequest {
    agreementId: number;
}

export interface GetPaymentHolidayDetailsRequest {
    agreementId: number;
    instalmentNumber?: number;
    numberOfInstalments?: number;
    isFullPaymentHoliday?: boolean;
}

export interface GetPaymentInstructionRequest {
    agreementId: number;
    paymentInstructionId: number;
}

export interface ListAgreementActivityRequest {
    agreementId: number;
    type?: string;
    from?: Date;
}

export interface ListAgreementAssetsRequest {
    agreementId: number;
    live?: boolean;
}

export interface ListAgreementFinancialPostingsRequest {
    agreementId: number;
    offset?: number;
    limit?: number;
}

export interface ListAgreementMemosRequest {
    agreementId: number;
    from?: Date;
}

export interface ListAgreementThirdPartiesRequest {
    agreementId: number;
}

export interface ListAgreementTransactionsRequest {
    agreementId: number;
}

export interface ListAgreementsRequest {
    offset?: number;
    limit?: number;
    query?: string;
    agreementNumber?: string;
    active?: boolean;
}

export interface ListAvailableAgreementThirdPartiesRequest {
    agreementId: number;
    query?: string;
}

export interface ListFeesUnpaidRequest {
    agreementId: number;
}

export interface ListPayeesRequest {
    agreementId: number;
}

export interface ListPaymentAllocationsRequest {
    agreementId: number;
}

export interface ListPaymentInstructionsRequest {
    agreementId: number;
}

export interface ListScheduleRequest {
    agreementId: number;
}

export interface ListSettlementQuotesRequest {
    agreementId: number;
}

/**
 * 
 */
export class AgreementQueryApi extends runtime.BaseAPI {

    /**
     * Retrieve the current status of agreements for a provided set of Id\'s.
     */
    async currentStatusRaw(requestParameters: CurrentStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling currentStatus.');
        }

        if (requestParameters.format === null || requestParameters.format === undefined) {
            throw new runtime.RequiredError('format','Required parameter requestParameters.format was null or undefined when calling currentStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreements:view"]);
        }

        const response = await this.request({
            path: `/agreements/status-lookup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Retrieve the current status of agreements for a provided set of Id\'s.
     */
    async currentStatus(requestParameters: CurrentStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.currentStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export financial postings
     */
    async exportAgreementFinancialPostingsRaw(requestParameters: ExportAgreementFinancialPostingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling exportAgreementFinancialPostings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-financial-postings:export"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/financial-postings/export`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export financial postings
     */
    async exportAgreementFinancialPostings(requestParameters: ExportAgreementFinancialPostingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportAgreementFinancialPostingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the details of an agreement
     */
    async getAgreementRaw(requestParameters: GetAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgreementModel>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling getAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreements:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgreementModelFromJSON(jsonValue));
    }

    /**
     * Retrieve the details of an agreement
     */
    async getAgreement(requestParameters: GetAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgreementModel> {
        const response = await this.getAgreementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get settlement quote details
     */
    async getAgreementSettlementQuoteDetailsRaw(requestParameters: GetAgreementSettlementQuoteDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AgreementSettlementQuoteModel>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling getAgreementSettlementQuoteDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-settlement-quotes:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/settlement`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgreementSettlementQuoteModelFromJSON(jsonValue));
    }

    /**
     * Get settlement quote details
     */
    async getAgreementSettlementQuoteDetails(requestParameters: GetAgreementSettlementQuoteDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AgreementSettlementQuoteModel> {
        const response = await this.getAgreementSettlementQuoteDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Insurance Details
     */
    async getInsuranceRaw(requestParameters: GetInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InsuranceModel>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling getInsurance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["insurance:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/insurance`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsuranceModelFromJSON(jsonValue));
    }

    /**
     * Get Insurance Details
     */
    async getInsurance(requestParameters: GetInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InsuranceModel> {
        const response = await this.getInsuranceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the payment holiday details for an agreement
     */
    async getPaymentHolidayDetailsRaw(requestParameters: GetPaymentHolidayDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentHolidayDetailsModel>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling getPaymentHolidayDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.instalmentNumber !== undefined) {
            queryParameters['instalmentNumber'] = requestParameters.instalmentNumber;
        }

        if (requestParameters.numberOfInstalments !== undefined) {
            queryParameters['numberOfInstalments'] = requestParameters.numberOfInstalments;
        }

        if (requestParameters.isFullPaymentHoliday !== undefined) {
            queryParameters['isFullPaymentHoliday'] = requestParameters.isFullPaymentHoliday;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-schedule:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/payment-holiday`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentHolidayDetailsModelFromJSON(jsonValue));
    }

    /**
     * Retrieve the payment holiday details for an agreement
     */
    async getPaymentHolidayDetails(requestParameters: GetPaymentHolidayDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentHolidayDetailsModel> {
        const response = await this.getPaymentHolidayDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get payment instruction Details
     */
    async getPaymentInstructionRaw(requestParameters: GetPaymentInstructionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentInstructionModel>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling getPaymentInstruction.');
        }

        if (requestParameters.paymentInstructionId === null || requestParameters.paymentInstructionId === undefined) {
            throw new runtime.RequiredError('paymentInstructionId','Required parameter requestParameters.paymentInstructionId was null or undefined when calling getPaymentInstruction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["payment-instructions:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/payment-instructions/{paymentInstructionId}`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"paymentInstructionId"}}`, encodeURIComponent(String(requestParameters.paymentInstructionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentInstructionModelFromJSON(jsonValue));
    }

    /**
     * Get payment instruction Details
     */
    async getPaymentInstruction(requestParameters: GetPaymentInstructionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentInstructionModel> {
        const response = await this.getPaymentInstructionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Agreement activity
     */
    async listAgreementActivityRaw(requestParameters: ListAgreementActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgreementActivityModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAgreementActivity.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-activity:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/activity`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgreementActivityModelFromJSON));
    }

    /**
     * View Agreement activity
     */
    async listAgreementActivity(requestParameters: ListAgreementActivityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgreementActivityModel>> {
        const response = await this.listAgreementActivityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Agreement assets
     */
    async listAgreementAssetsRaw(requestParameters: ListAgreementAssetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgreementAssetListItemModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAgreementAssets.');
        }

        const queryParameters: any = {};

        if (requestParameters.live !== undefined) {
            queryParameters['live'] = requestParameters.live;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-assets:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/assets`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgreementAssetListItemModelFromJSON));
    }

    /**
     * View Agreement assets
     */
    async listAgreementAssets(requestParameters: ListAgreementAssetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgreementAssetListItemModel>> {
        const response = await this.listAgreementAssetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the financial postings associated to the agreement
     */
    async listAgreementFinancialPostingsRaw(requestParameters: ListAgreementFinancialPostingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgreementFinancialPostingListItemModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAgreementFinancialPostings.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-financial-postings:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/financial-postings`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgreementFinancialPostingListItemModelFromJSON));
    }

    /**
     * Retrieve the financial postings associated to the agreement
     */
    async listAgreementFinancialPostings(requestParameters: ListAgreementFinancialPostingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgreementFinancialPostingListItemModel>> {
        const response = await this.listAgreementFinancialPostingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Agreement Memos
     */
    async listAgreementMemosRaw(requestParameters: ListAgreementMemosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemoModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAgreementMemos.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-memos:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/memos`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemoModelFromJSON));
    }

    /**
     * View Agreement Memos
     */
    async listAgreementMemos(requestParameters: ListAgreementMemosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemoModel>> {
        const response = await this.listAgreementMemosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Third Parties associated with an Agreement
     */
    async listAgreementThirdPartiesRaw(requestParameters: ListAgreementThirdPartiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgreementThirdPartyListItemModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAgreementThirdParties.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-third-parties:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/third-parties`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgreementThirdPartyListItemModelFromJSON));
    }

    /**
     * List Third Parties associated with an Agreement
     */
    async listAgreementThirdParties(requestParameters: ListAgreementThirdPartiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgreementThirdPartyListItemModel>> {
        const response = await this.listAgreementThirdPartiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List agreement transactions
     */
    async listAgreementTransactionsRaw(requestParameters: ListAgreementTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgreementTransactionModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAgreementTransactions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["transactions:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/transactions`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgreementTransactionModelFromJSON));
    }

    /**
     * List agreement transactions
     */
    async listAgreementTransactions(requestParameters: ListAgreementTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgreementTransactionModel>> {
        const response = await this.listAgreementTransactionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Provides an API to list agreements in the system
     */
    async listAgreementsRaw(requestParameters: ListAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AgreementListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.agreementNumber !== undefined) {
            queryParameters['agreementNumber'] = requestParameters.agreementNumber;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreements:view"]);
        }

        const response = await this.request({
            path: `/agreements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgreementListItemModelFromJSON));
    }

    /**
     * Provides an API to list agreements in the system
     */
    async listAgreements(requestParameters: ListAgreementsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AgreementListItemModel>> {
        const response = await this.listAgreementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available third parties to associate to an agreement
     */
    async listAvailableAgreementThirdPartiesRaw(requestParameters: ListAvailableAgreementThirdPartiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AvailableAgreementThirdPartyListItemModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listAvailableAgreementThirdParties.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-third-parties:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/available-third-parties`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AvailableAgreementThirdPartyListItemModelFromJSON));
    }

    /**
     * List available third parties to associate to an agreement
     */
    async listAvailableAgreementThirdParties(requestParameters: ListAvailableAgreementThirdPartiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AvailableAgreementThirdPartyListItemModel>> {
        const response = await this.listAvailableAgreementThirdPartiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available charge types
     */
    async listChargeTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChargeTypeModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["charge-types:view"]);
        }

        const response = await this.request({
            path: `/agreements/transactions/charge-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChargeTypeModelFromJSON));
    }

    /**
     * List available charge types
     */
    async listChargeTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChargeTypeModel>> {
        const response = await this.listChargeTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List fees unpaid associated to an agreement
     */
    async listFeesUnpaidRaw(requestParameters: ListFeesUnpaidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FeeUnpaidListItemModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listFeesUnpaid.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["fees-unpaid:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/fees-unpaid`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FeeUnpaidListItemModelFromJSON));
    }

    /**
     * List fees unpaid associated to an agreement
     */
    async listFeesUnpaid(requestParameters: ListFeesUnpaidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FeeUnpaidListItemModel>> {
        const response = await this.listFeesUnpaidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List payees and their associated bank details
     */
    async listPayeesRaw(requestParameters: ListPayeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PayeesListItemModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listPayees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["payees:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/payment-instructions/payees`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PayeesListItemModelFromJSON));
    }

    /**
     * List payees and their associated bank details
     */
    async listPayees(requestParameters: ListPayeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PayeesListItemModel>> {
        const response = await this.listPayeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List payment allocations
     */
    async listPaymentAllocationsRaw(requestParameters: ListPaymentAllocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PaymentAllocationListItemModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listPaymentAllocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["payment-allocation:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/payment-allocations`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentAllocationListItemModelFromJSON));
    }

    /**
     * List payment allocations
     */
    async listPaymentAllocations(requestParameters: ListPaymentAllocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PaymentAllocationListItemModel>> {
        const response = await this.listPaymentAllocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List payment instructions
     */
    async listPaymentInstructionsRaw(requestParameters: ListPaymentInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PaymentInstructionsListItemModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listPaymentInstructions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["payment-instructions:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/payment-instructions`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentInstructionsListItemModelFromJSON));
    }

    /**
     * List payment instructions
     */
    async listPaymentInstructions(requestParameters: ListPaymentInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PaymentInstructionsListItemModel>> {
        const response = await this.listPaymentInstructionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available removal reasons
     */
    async listRemovalReasonsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RemovalReasonModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["removal-reasons:view"]);
        }

        const response = await this.request({
            path: `/agreements/transactions/removal-reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RemovalReasonModelFromJSON));
    }

    /**
     * List available removal reasons
     */
    async listRemovalReasons(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RemovalReasonModel>> {
        const response = await this.listRemovalReasonsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List available reversal reasons
     */
    async listReversalReasonsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReversalReasonModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["reversal-reasons:view"]);
        }

        const response = await this.request({
            path: `/agreements/transactions/reversal-reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReversalReasonModelFromJSON));
    }

    /**
     * List available reversal reasons
     */
    async listReversalReasons(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReversalReasonModel>> {
        const response = await this.listReversalReasonsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the schedule for an agreement
     */
    async listScheduleRaw(requestParameters: ListScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduleModel>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-schedule:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/schedule`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleModelFromJSON(jsonValue));
    }

    /**
     * Retrieve the schedule for an agreement
     */
    async listSchedule(requestParameters: ListScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduleModel> {
        const response = await this.listScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List settlement quotes
     */
    async listSettlementQuotesRaw(requestParameters: ListSettlementQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SettlementQuoteListItemModel>>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling listSettlementQuotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-settlement-quotes:view"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/settlement-quotes`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SettlementQuoteListItemModelFromJSON));
    }

    /**
     * List settlement quotes
     */
    async listSettlementQuotes(requestParameters: ListSettlementQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SettlementQuoteListItemModel>> {
        const response = await this.listSettlementQuotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available transaction banks
     */
    async listTransactionBanksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TransactionBankModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["transactions-banks:view"]);
        }

        const response = await this.request({
            path: `/agreements/transactions/banks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TransactionBankModelFromJSON));
    }

    /**
     * List available transaction banks
     */
    async listTransactionBanks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TransactionBankModel>> {
        const response = await this.listTransactionBanksRaw(initOverrides);
        return await response.value();
    }

    /**
     * List available transaction types
     */
    async listTransactionTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TransactionTypeModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["transactions-types:view"]);
        }

        const response = await this.request({
            path: `/agreements/transactions/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TransactionTypeModelFromJSON));
    }

    /**
     * List available transaction types
     */
    async listTransactionTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TransactionTypeModel>> {
        const response = await this.listTransactionTypesRaw(initOverrides);
        return await response.value();
    }

}
