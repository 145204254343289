import { useFormat } from "@ldms/mui-sdk/formatting";
import { Link, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const getName = (name?: string, companyName?: string): string => {
  if (!name) {
    return companyName || "";
  }

  return name && companyName ? `${name} (${companyName})` : name;
};

export interface OnboardingListItem {
  agreementUrl?: string;
  customerUrl?: string;
  statusUrl?: string;
  id?: number;
  agreementId?: number;
  customerId?: number;
  agreementNumber?: string;
  name?: string;
  companyName?: string;
  totalAmountRepayable: string;
  createdBy: string;
  createdAt: Date;
  statusType: string;
}

interface OnboardingListProps {
  data: OnboardingListItem[];
  loading: boolean;
}

interface CellWithOptionalLinkProps {
  url?: string;
  displayValue: string;
}

function CellWithOptionalLink({
  url,
  displayValue,
}: CellWithOptionalLinkProps): ReactElement {
  return (
    <TableCell>
      {url ? <Link href={url}>{displayValue}</Link> : displayValue}
    </TableCell>
  );
}

export default function OnboardingList({
  data,
  loading,
}: OnboardingListProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const locale = useLocale();
  const { formatAmount } = useFormat();
  const appConfig = useAppConfiguration();

  const mapOnboarding = (
    onboarding: OnboardingListItem,
  ): OnboardingListItem => ({
    ...onboarding,
    customerUrl: onboarding.customerId
      ? `${appConfig.appRoutes.servicing}/customers/${onboarding.customerId}`
      : undefined,
    agreementUrl: onboarding.agreementId
      ? `${appConfig.appRoutes.servicing}/agreements/${onboarding.agreementId}`
      : undefined,
    statusUrl: onboarding.id
      ? `${appConfig.appRoutes.onboarding}/review/${onboarding.id}`
      : undefined,
  });

  const tableHeaders: string[] = [
    t("onboarding_list.agreement_number_label"),
    t("onboarding_list.customer_label"),
    t("onboarding_list.total_amount_repayable_label"),
    t("onboarding_list.created_label"),
    t("onboarding_list.status_label"),
  ];

  return (
    <>
      <Table size="small" aria-label={t("onboarding_list.table")}>
        <LoadableTableHead headings={tableHeaders} loading={loading} />

        <TableBody>
          {data.map(mapOnboarding)?.map((item) => (
            <TableRow key={item.id}>
              <CellWithOptionalLink
                url={item.agreementUrl}
                displayValue={item.agreementNumber || "-"}
              />
              <CellWithOptionalLink
                url={item.customerUrl}
                displayValue={getName(item.name, item.companyName)}
              />
              <TableCell>{formatAmount(item.totalAmountRepayable)}</TableCell>
              <TableCell>
                {`${locale.formatDate(new Date(item.createdAt))} - ${
                  item.createdBy
                }`}
              </TableCell>
              <TableCell>
                {item.statusUrl ? (
                  <Link component={RouterLink} to={item.statusUrl}>
                    {t(item.statusType)}
                  </Link>
                ) : (
                  t(item.statusType)
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <NoResults message={t("onboarding_list.no_onboardings_message")} />
      )}
    </>
  );
}
