/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Financial Postings within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The available stage names associated to an agreement
 * @export
 * @interface FinancialPostingStageListItemModel
 */
export interface FinancialPostingStageListItemModel {
    /**
     * The financial stage code
     * @type {string}
     * @memberof FinancialPostingStageListItemModel
     */
    stageCode: string;
    /**
     * The name of the stage group
     * @type {string}
     * @memberof FinancialPostingStageListItemModel
     */
    stageGroup: string;
    /**
     * The name of the financial stage
     * @type {string}
     * @memberof FinancialPostingStageListItemModel
     */
    stageName: string;
    /**
     * The source code associated to the stage code
     * @type {string}
     * @memberof FinancialPostingStageListItemModel
     */
    sourceCode: string;
    /**
     * The financial account code
     * @type {string}
     * @memberof FinancialPostingStageListItemModel
     */
    accountCode: string;
    /**
     * The name of the financial account
     * @type {string}
     * @memberof FinancialPostingStageListItemModel
     */
    accountName: string;
}

/**
 * Check if a given object implements the FinancialPostingStageListItemModel interface.
 */
export function instanceOfFinancialPostingStageListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stageCode" in value;
    isInstance = isInstance && "stageGroup" in value;
    isInstance = isInstance && "stageName" in value;
    isInstance = isInstance && "sourceCode" in value;
    isInstance = isInstance && "accountCode" in value;
    isInstance = isInstance && "accountName" in value;

    return isInstance;
}

export function FinancialPostingStageListItemModelFromJSON(json: any): FinancialPostingStageListItemModel {
    return FinancialPostingStageListItemModelFromJSONTyped(json, false);
}

export function FinancialPostingStageListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialPostingStageListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stageCode': json['stageCode'],
        'stageGroup': json['stageGroup'],
        'stageName': json['stageName'],
        'sourceCode': json['sourceCode'],
        'accountCode': json['accountCode'],
        'accountName': json['accountName'],
    };
}

export function FinancialPostingStageListItemModelToJSON(value?: FinancialPostingStageListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stageCode': value.stageCode,
        'stageGroup': value.stageGroup,
        'stageName': value.stageName,
        'sourceCode': value.sourceCode,
        'accountCode': value.accountCode,
        'accountName': value.accountName,
    };
}

