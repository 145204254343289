import { Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ActivityList, ViewDateToggle } from "common/components";
import ListLayout from "common/layouts/ListLayout";
import { useApi } from "common/providers";
import { startOfDay, subMonths } from "date-fns";
import { AgreementQueryApi, ClientQueryApi } from "generated/core/apis";
import { AgreementActivityModel } from "generated/core/models";
import { ChangeEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorLike } from "support/error-handler";
import useStickySWR from "support/use-sticky-swr";
import useSWR from "swr";

interface AgreementActivityProps {
  agreementId: number;
}

interface AgreementActivityFilterParameters {
  from: "lastSixMonths" | "";
  type: string;
}

export default function AgreementActivity({
  agreementId,
}: AgreementActivityProps): ReactElement {
  const agreementQueryApi = useApi(AgreementQueryApi);
  const clientApi = useApi(ClientQueryApi);
  const { t } = useTranslation(["agreements", "common"]);
  const sixMonthsAgo = startOfDay(subMonths(new Date(), 6));
  const [parameters, setParameters] =
    useState<AgreementActivityFilterParameters>({
      from: "lastSixMonths",
      type: "",
    });

  const activity = useStickySWR<AgreementActivityModel[], ErrorLike>(
    ["/agreements/:id/activity", parameters.from, parameters.type],
    () =>
      agreementQueryApi.listAgreementActivity({
        agreementId,
        from: parameters.from === "lastSixMonths" ? sixMonthsAgo : undefined,
        type: parameters.type,
      }),
    {
      dedupingInterval: 0,
    },
  );

  const activityTypes = useSWR(["/activity-types"], () =>
    clientApi.listActivityTypes(),
  );

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setParameters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));
  };

  return (
    <ListLayout
      filters={
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              InputLabelProps={{
                htmlFor: "type",
                shrink: true,
              }}
              inputProps={{ displayEmpty: true, id: "type" }}
              onChange={handleFilterChange}
              label={t("activity.activity")}
              name="type"
              select
              size="small"
              value={parameters.type}
              variant="outlined"
              margin="none"
            >
              <MenuItem value="">{t("activity.all_activity")}</MenuItem>
              {(activityTypes.data || []).map((activityType) => (
                <MenuItem value={activityType.type} key={activityType.type}>
                  {activityType.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <ViewDateToggle
              onChange={handleFilterChange}
              value={parameters.from}
              testId="viewDateToggle.viewAll"
            />
          </Grid>
        </Grid>
      }
    >
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(activity.data ?? activity.error)}
        render={() => {
          if (activity.error || !activity.data) {
            return (
              <Typography
                aria-label={t("common:error.default")}
                color="error"
                role="alert"
                data-testid="agreementActivityList.error"
              >
                {t("common:error.default")}
              </Typography>
            );
          }

          return (
            <ActivityList
              showAgreementId={false}
              data={activity.data.map((item) => ({
                date: item.created,
                user: item.createdBy,
                action: item.action,
                actionNote: item.description,
              }))}
              loading={activity.isValidating}
            />
          );
        }}
      />
    </ListLayout>
  );
}
