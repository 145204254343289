import { ConfirmationDialog } from "@ldms/mui-sdk/templates";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useUpdatePortfolioBankAccount } from "api/portfolio-bank-accounts/updatePortfolioBankAccount";
import { SubmissionFileType } from "apps/admin/types";
import { ControlledTextField, Loader } from "common/components";
import { useConfirm, useYupResolver } from "common/hooks";
import { omit } from "lodash";
import { ReactElement } from "react";
import {
  Control,
  FieldErrors,
  Resolver,
  UseFormRegister,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

export interface UpdatePortfolioBankAccountFieldValues {
  name: string;
  serviceUserNumber: string;
  sortCode?: string;
  accountNumber?: string;
  iban?: string;
  bic?: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  postcode: string;
  submissionFileType?: string;
}

const labels = {
  name: "bank_accounts.edit_bank_account.name_label",
  serviceUserNumber:
    "bank_accounts.edit_bank_account.service_user_number_label",
  accountNumber: "bank_accounts.edit_bank_account.account_number_label",
  sortCode: "bank_accounts.edit_bank_account.sort_code_label",
  iban: "bank_accounts.edit_bank_account.iban_label",
  bic: "bank_accounts.edit_bank_account.bic_label",
  addressLine1: "bank_accounts.edit_bank_account.address_line_1_label",
  addressLine2: "bank_accounts.edit_bank_account.address_line_2_label",
  addressLine3: "bank_accounts.edit_bank_account.address_line_3_label",
  addressLine4: "bank_accounts.edit_bank_account.address_line_4_label",
  postcode: "bank_accounts.edit_bank_account.postcode_label",
  isRequiredValidation: "common:validation.is_required",
  bankDetailsValidation: "bank_accounts.validation.bank_details_required",
  submissionFileType:
    "bank_accounts.edit_bank_account.submission_file_type_label",
};

interface UpdatePortfolioBankAccountFormControlsProps {
  errors: FieldErrors<UpdatePortfolioBankAccountFieldValues>;
  register: UseFormRegister<UpdatePortfolioBankAccountFieldValues>;
  control: Control<UpdatePortfolioBankAccountFieldValues>;
}

function UpdatePortfolioBankAccountFormControls({
  errors,
  register,
  control,
}: UpdatePortfolioBankAccountFormControlsProps): ReactElement {
  const { t } = useTranslation("servicing");

  return (
    <>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={6}>
          <TextField
            {...register("name")}
            required
            label={t(labels.name)}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("serviceUserNumber")}
            required
            label={t(labels.serviceUserNumber)}
            error={Boolean(errors.serviceUserNumber)}
            helperText={errors.serviceUserNumber?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("sortCode")}
            error={Boolean(errors.sortCode)}
            helperText={errors.sortCode?.message}
            label={t(labels.sortCode)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("accountNumber")}
            {...register("accountNumber")}
            error={Boolean(errors.accountNumber)}
            helperText={errors.accountNumber?.message}
            label={t(labels.accountNumber)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("iban")}
            error={Boolean(errors.iban)}
            helperText={errors.iban?.message}
            label={t(labels.iban)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("bic")}
            error={Boolean(errors.bic)}
            helperText={errors.bic?.message}
            label={t(labels.bic)}
          />
        </Grid>
        <Grid item sm={6}>
          <ControlledTextField
            required
            select
            id="submissionFileType"
            name="submissionFileType"
            label={t(labels.submissionFileType)}
            helperText={errors.submissionFileType?.message}
            error={errors.submissionFileType?.message}
            SelectProps={{ displayEmpty: true }}
            control={control}
          >
            {Object.values(SubmissionFileType).map((submissionFileType) => (
              <MenuItem
                key={String(submissionFileType)}
                value={String(submissionFileType)}
              >
                {submissionFileType}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Divider flexItem variant="fullWidth" />
      </Grid>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine1")}
            required
            label={t(labels.addressLine1)}
            error={Boolean(errors.addressLine1)}
            helperText={errors.addressLine1?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine2")}
            error={Boolean(errors.addressLine2)}
            helperText={errors.addressLine2?.message}
            label={t(labels.addressLine2)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine3")}
            error={Boolean(errors.addressLine3)}
            helperText={errors.addressLine3?.message}
            label={t(labels.addressLine3)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine4")}
            error={Boolean(errors.addressLine4)}
            helperText={errors.addressLine4?.message}
            label={t(labels.addressLine4)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("postcode")}
            error={Boolean(errors.postcode)}
            helperText={errors.postcode?.message}
            required
            label={t(labels.postcode)}
          />
        </Grid>
      </Grid>
    </>
  );
}

const useUpdatePortfolioBankAccountResolver = (
  defaultValues: UpdatePortfolioBankAccountFieldValues,
): Resolver<UpdatePortfolioBankAccountFieldValues> => {
  const { t } = useTranslation("servicing");

  const validateDependentField = (
    value: string | undefined,
    length: number,
  ): boolean => {
    if (length) {
      return Boolean(value);
    }
    return true;
  };

  return useYupResolver<UpdatePortfolioBankAccountFieldValues>((yup) => {
    return yup.object().shape({
      name: yup
        .string()
        .isRequired(t(labels.name))
        .maxCharacters(50, t(labels.name)),
      serviceUserNumber: yup
        .string()
        .isRequired(t(labels.serviceUserNumber))
        .maxCharacters(30, t(labels.serviceUserNumber)),
      addressLine1: yup
        .string()
        .maxCharacters(50, t(labels.addressLine1))
        .isRequired(t(labels.addressLine1)),
      addressLine2: yup.string().maxCharacters(50, t(labels.addressLine2)),
      addressLine3: yup.string().maxCharacters(50, t(labels.addressLine3)),
      addressLine4: yup.string().maxCharacters(50, t(labels.addressLine4)),
      postcode: yup
        .string()
        .maxCharacters(10, t(labels.postcode))
        .isRequired(t(labels.postcode)),
      sortCode: yup
        .string()
        .maxCharacters(10, t(labels.sortCode))
        .test({
          name: "isDependentOnAccountNumber",
          message: t(labels.isRequiredValidation, {
            label: t(labels.sortCode),
          }),
          test: (value, testContext) =>
            validateDependentField(value, testContext.parent.accountNumber),
        })
        .oneOfBankDetailsRequired("sortCodeRequired"),
      accountNumber: yup
        .string()
        .oneOfBankDetailsRequired("accountNumberRequired")
        .maxCharacters(10, t(labels.accountNumber))
        .test({
          name: "isDependentOnSortCode",
          message: t(labels.isRequiredValidation, {
            label: t(labels.accountNumber),
          }),
          test: (value, testContext) =>
            validateDependentField(value, testContext.parent.sortCode),
        }),
      iban: yup
        .string()
        .oneOfBankDetailsRequired("ibanRequired")
        .maxCharacters(50, t(labels.iban))
        .test({
          name: "isDependentOnBic",
          message: t(labels.isRequiredValidation, {
            label: t(labels.iban),
          }),
          test: (value, testContext) =>
            validateDependentField(value, testContext.parent.bic),
        })
        .validateIban(),
      bic: yup
        .string()
        .maxCharacters(20, t(labels.bic))
        .test({
          name: "isDependentOnIban",
          message: t(labels.isRequiredValidation, {
            label: t(labels.bic),
          }),
          test: (value, testContext) =>
            validateDependentField(value, testContext.parent.iban),
        })
        .oneOfBankDetailsRequired("bicRequired")
        .validateBic(),
      submissionFileType: yup.string().isRequired(t(labels.submissionFileType)),
    });
  });
};

interface UpdatePortfolioBankAccountContainerProps {
  portfolioBankAccountId: string;
  defaultValues: UpdatePortfolioBankAccountFieldValues;
}

export default function UpdatePortfolioBankAccountContainer({
  portfolioBankAccountId,
  defaultValues,
}: UpdatePortfolioBankAccountContainerProps): ReactElement {
  const { t } = useTranslation("servicing");
  const resolver = useUpdatePortfolioBankAccountResolver(defaultValues);
  const navigate = useNavigate();
  const confirm = useConfirm();

  const onSuccess = () => {
    navigate("..");
  };

  const updatePortfolioBankAccount = useUpdatePortfolioBankAccount(
    portfolioBankAccountId,
    {
      onSuccess,
    },
  );

  const { ...form } = useForm<UpdatePortfolioBankAccountFieldValues>({
    resolver,
    defaultValues: { ...defaultValues },
  });

  const responseError = new Map([
    [
      "service_user_number_exists",
      t("bank_accounts.validation.duplicate_service_user_number"),
    ],
  ]);

  const onSubmit = async (
    data: UpdatePortfolioBankAccountFieldValues,
  ): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      updatePortfolioBankAccount.execute({
        ...omit(data, "submissionFileType"),
        bic: data.bic === "" ? undefined : data.bic,
        iban: data.iban === "" ? undefined : data.iban,
        directDebitSubmissionFileType:
          data.submissionFileType as SubmissionFileType,
      });
    });
  };

  const { isSubmitting } = form.formState;

  const renderUpdatePortfolioBankAccountForm = (): ReactElement => {
    return (
      <form
        id="edit-bank-account-form"
        method="POST"
        aria-label={t("bank_accounts.edit_bank_account.title_label")}
        noValidate
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <ConfirmationDialog
          content={
            <Typography>
              {t("bank_accounts.edit_bank_account.confirmation_message")}
            </Typography>
          }
          open={confirm.isOpen}
          onConfirm={confirm.handleConfirm}
          onReject={confirm.handleReject}
          title={t("bank_accounts.edit_bank_account.title_label")}
          labels={{
            confirm: t("common:yes"),
            reject: t("common:no"),
          }}
        />
        <Container maxWidth="sm">
          <Paper>
            <Stack gap={2} padding={2}>
              <UpdatePortfolioBankAccountFormControls
                errors={form.formState.errors}
                register={form.register}
                control={form.control}
              />
              {updatePortfolioBankAccount.error && (
                <Box>
                  <Typography color="error">
                    {responseError.get(updatePortfolioBankAccount.error.code) ??
                      t("common:error.default")}
                  </Typography>
                </Box>
              )}
              <Box display="flex" justifyContent="flex-end" gap={1}>
                <Button
                  role="link"
                  component={RouterLink}
                  to=".."
                  color="primary"
                >
                  {t("common:cancel")}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {t("bank_accounts.edit_bank_account.submit_button")}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Container>
      </form>
    );
  };

  return (
    <Loader
      ready={Boolean(defaultValues)}
      render={renderUpdatePortfolioBankAccountForm}
    />
  );
}
