/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanySizeModel } from './CompanySizeModel';
import {
    CompanySizeModelFromJSON,
    CompanySizeModelFromJSONTyped,
    CompanySizeModelToJSON,
} from './CompanySizeModel';

/**
 * 
 * @export
 * @interface CompanyModel
 */
export interface CompanyModel {
    /**
     * Company Name
     * @type {string}
     * @memberof CompanyModel
     */
    companyName: string;
    /**
     * Company Registration Number
     * @type {string}
     * @memberof CompanyModel
     */
    companyRegistrationNumber?: string;
    /**
     * VAT Registration Number
     * @type {string}
     * @memberof CompanyModel
     */
    vatRegistrationNumber?: string;
    /**
     * 
     * @type {CompanySizeModel}
     * @memberof CompanyModel
     */
    companySize?: CompanySizeModel;
    /**
     * Sector of Economic Activity
     * @type {string}
     * @memberof CompanyModel
     */
    sectorOfEconomicActivity?: string;
    /**
     * Company Sector
     * @type {string}
     * @memberof CompanyModel
     */
    companySector?: string;
}

/**
 * Check if a given object implements the CompanyModel interface.
 */
export function instanceOfCompanyModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyName" in value;

    return isInstance;
}

export function CompanyModelFromJSON(json: any): CompanyModel {
    return CompanyModelFromJSONTyped(json, false);
}

export function CompanyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        'companyRegistrationNumber': !exists(json, 'companyRegistrationNumber') ? undefined : json['companyRegistrationNumber'],
        'vatRegistrationNumber': !exists(json, 'vatRegistrationNumber') ? undefined : json['vatRegistrationNumber'],
        'companySize': !exists(json, 'companySize') ? undefined : CompanySizeModelFromJSON(json['companySize']),
        'sectorOfEconomicActivity': !exists(json, 'sectorOfEconomicActivity') ? undefined : json['sectorOfEconomicActivity'],
        'companySector': !exists(json, 'companySector') ? undefined : json['companySector'],
    };
}

export function CompanyModelToJSON(value?: CompanyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'companyRegistrationNumber': value.companyRegistrationNumber,
        'vatRegistrationNumber': value.vatRegistrationNumber,
        'companySize': CompanySizeModelToJSON(value.companySize),
        'sectorOfEconomicActivity': value.sectorOfEconomicActivity,
        'companySector': value.companySector,
    };
}

