import { MenuItem } from "@mui/material";
import { useListPaymentAllocations } from "api/agreements/payment-allocations/listPaymentAllocations";
import { useLocale } from "common/hooks";
import { PaymentAllocationListItemModelPaidIndicatorEnum } from "generated/core/models";
import { useTranslation } from "react-i18next";
import { downloadAsCSV } from "support/download";

interface ExportPaymentAllocationsContainerProps {
  agreementId: number;
}

const ExportPaymentAllocationsContainer = ({
  agreementId,
}: ExportPaymentAllocationsContainerProps) => {
  const { t } = useTranslation(["agreements"]);
  const paymentAllocations = useListPaymentAllocations(agreementId);
  const locale = useLocale();

  const headings = [
    t("payment_allocation.transaction_date"),
    t("payment_allocation.record_type"),
    t("payment_allocation.allocation_type"),
    t("payment_allocation.amount"),
    t("payment_allocation.unallocated_amount"),
    t("payment_allocation.paid_indicator"),
  ];

  const paidIndicatorDescription = new Map([
    [undefined, ""],
    [
      PaymentAllocationListItemModelPaidIndicatorEnum.CashAllocated,
      t("payment_allocation.cash_allocated_description"),
    ],
    [
      PaymentAllocationListItemModelPaidIndicatorEnum.NotFullyAllocated,
      t("payment_allocation.not_fully_allocated_description"),
    ],
    [
      PaymentAllocationListItemModelPaidIndicatorEnum.FullyAllocated,
      t("payment_allocation.fully_allocated_description"),
    ],
  ]);

  const handleExport = () => {
    downloadAsCSV(
      [
        headings,
        ...(paymentAllocations.data ?? []).map((row) => {
          const data = [
            row.transactionDate && locale.formatISODate(row.transactionDate),
            row.recordType,
            row.allocationType,
            row.amount,
            row.unallocatedAmount,
            paidIndicatorDescription.get(row.paidIndicator),
          ];
          return data;
        }),
      ],
      {
        filename: `${agreementId}_payment_allocations`,
      },
    );
  };

  return (
    <MenuItem disabled={paymentAllocations.isValidating} onClick={handleExport}>
      {t("payment_allocation.export_menu_item")}
    </MenuItem>
  );
};

export default ExportPaymentAllocationsContainer;
