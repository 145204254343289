import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { ConfirmationDialog, Loader } from "@ldms/mui-sdk/templates";
import { Alert, Box, Button, Typography } from "@mui/material";
import { useGetCustomer } from "api/customers/contacts/getCustomer";
import { useGetOnboarding } from "api/onboarding/getOnboarding";
import { OnboardStepperContainer } from "apps/onboarding/containers";
import {
  OnboardingProvider,
  OnboardingState,
  mapOnboardingModelToState,
} from "apps/onboarding/providers";
import { QueryError } from "common/components";
import { useConfirm } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import { compose } from "lodash/fp";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

interface EditOnboardingViewProps {
  onboardingId: number;
  initialActiveStep?: string;
}

interface EditOnboardCustomerProps {
  onboardingId: number;
  initialState: OnboardingState;
  initialActiveStep?: string;
}

interface EditOnboardExistingCustomerProps {
  onboardingId: number;
  customerId: number;
  initialState: OnboardingState;
  initialActiveStep?: string;
}

const getIndividualName = (
  name: string,
  tradingName: string | undefined,
): string => {
  return tradingName ? `${name} (${tradingName})` : name;
};

const stepRoutes = {
  agreementDetails: "/agreement-details",
  assetDetails: "/asset-details",
  financialDetails: "/financial-details",
  feesAndCommissionsStep: "/fees-and-commissions",
  repaymentTerms: "/repayment-terms",
};

export function EditOnboardCustomer({
  onboardingId,
  initialState,
  initialActiveStep,
}: EditOnboardCustomerProps): ReactElement {
  return (
    <OnboardingProvider
      initialState={initialState}
      onboardingId={String(onboardingId)}
    >
      <OnboardStepperContainer
        initialActiveStep={initialActiveStep}
        routes={{
          companyDetails: "/",
          customerDetails: "/customer-details",
          ...stepRoutes,
        }}
      />
    </OnboardingProvider>
  );
}

export function EditOnboardExistingCustomer({
  onboardingId,
  customerId,
  initialState,
  initialActiveStep,
}: EditOnboardExistingCustomerProps): ReactElement {
  const customer = useGetCustomer(customerId);

  const name = customer.data?.isIndividual
    ? getIndividualName(customer.data?.name, customer.data?.companyName)
    : customer.data?.companyName;

  return (
    <OnboardingProvider
      customerId={customerId}
      customerName={name}
      initialState={initialState}
      onboardingId={String(onboardingId)}
    >
      <OnboardStepperContainer
        initialActiveStep={initialActiveStep}
        routes={stepRoutes}
      />
    </OnboardingProvider>
  );
}

function EditOnboardingView({
  onboardingId,
}: EditOnboardingViewProps): ReactElement {
  const editOnboarding = useGetOnboarding(onboardingId);
  const appConfig = useAppConfiguration();
  const { t } = useTranslation("onboardings");
  const confirm = useConfirm();
  const navigate = useNavigate();

  const onCancelEditOnboarding = async (): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      navigate(`${appConfig.appRoutes.onboarding}/review/${onboardingId}`);
    });
  };

  return (
    <ContainedLayout maxWidth="lg">
      <Loader
        ready={Boolean(editOnboarding.data ?? editOnboarding.error)}
        render={(): ReactElement => {
          if (editOnboarding.error || !editOnboarding.data) {
            return <QueryError onRetry={editOnboarding.refetch} />;
          }

          const initialState = mapOnboardingModelToState(editOnboarding.data);
          const existingCustomerId = editOnboarding.data.existingCustomerId;
          const initialActiveStep = existingCustomerId
            ? "/agreement-details"
            : undefined;

          return (
            <>
              <Box marginBottom={3}>
                <Alert
                  severity="info"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  action={
                    <Button
                      aria-label={t("common:cancel")}
                      variant="text"
                      onClick={onCancelEditOnboarding}
                      color="primary"
                    >
                      {t("common:cancel")}
                    </Button>
                  }
                >
                  <Typography>{t("edit.edit_alert_label")}</Typography>
                </Alert>
              </Box>
              {!existingCustomerId ? (
                <EditOnboardCustomer
                  onboardingId={onboardingId}
                  initialState={initialState}
                  initialActiveStep={initialActiveStep}
                />
              ) : (
                <EditOnboardExistingCustomer
                  onboardingId={onboardingId}
                  customerId={existingCustomerId}
                  initialState={initialState}
                  initialActiveStep={initialActiveStep}
                />
              )}
              <ConfirmationDialog
                content={
                  <Typography>{t("edit.confirmation_message")}</Typography>
                }
                open={confirm.isOpen}
                onConfirm={confirm.handleConfirm}
                onReject={confirm.handleReject}
                title={t("edit.confirmation_title")}
                labels={{
                  confirm: t("common:yes"),
                  reject: t("common:no"),
                }}
              />
            </>
          );
        }}
      />
    </ContainedLayout>
  );
}

export default compose(
  withTranslationLoader("onboardings"),
  withProtectedView({
    allowedPermissions: ["onboarding:onboarding:manage"],
  }),
)(EditOnboardingView);
