import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { TasksApi } from "generated/tasks/apis";
import { CreateTaskModel } from "generated/tasks/models";
import * as keys from "./keys";

const useCreateTask = (
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(TasksApi, "task-management");
  const partialMutate = usePartialMutate();

  return useCommand(
    async (createTaskModel: CreateTaskModel) =>
      api.createTask({
        createTaskModel,
      }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        partialMutate(keys.list());
      },
    },
  );
};

export { useCreateTask };
