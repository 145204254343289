import useQuery from "common/hooks/useQuery/useQuery";
import { useApi } from "common/providers";
import { RolesApi } from "generated/admin/apis";
import { useEffect, useMemo, useRef } from "react";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

const useGetRole = ({ id }: { id: number }) => {
  const api = useApi(RolesApi);
  const random = useRef(Date.now());
  const { cache } = useSWRConfig();
  const key = useMemo(() => [...keys.detail(id), random], [random, id]);

  useEffect(() => {
    cache.delete(key);
  }, [cache, key]);

  return useQuery(key, () => api.getRole({ roleId: id }));
};

export { useGetRole };
