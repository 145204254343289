import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import AddCompanyContainer from "apps/servicing/modules/settings/containers/AddCompanyContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

function AddCompaniesView() {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();

  return (
    <main>
      <ViewLayout
        title={t("companies.add_company.title_label")}
        breadcrumbs={[
          {
            href: `${appConfig.appRoutes.servicing}/settings/companies`,
            label: t("companies.breadcrumb_link_label"),
          },
        ]}
      >
        <ContainedLayout>
          <AddCompanyContainer />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:companies:manage"],
})(AddCompaniesView);
