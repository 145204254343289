import { Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  CircularProgress,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useListTemplates } from "api/documents/templates/listTemplates";
import { useListPortfolios } from "api/documents/templates/portfolios/listPortfolios";
import CreateLetterTemplateContainer from "apps/documents/containers/CreateLetterTemplateContainer";
import MergeFieldsDialogContainer from "apps/documents/containers/MergeFieldsDialogContainer";
import { TemplateTypeEnum } from "apps/documents/types";
import { LoadableTableHead, NoResults, YesNoValue } from "common/components";
import { useLocale } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface TemplatesPortfolioFilterParameter {
  portfolioId?: number;
  type?: string;
}

export default function ListLetterTemplatesContainer(): ReactElement {
  const { t } = useTranslation("documents");
  const locale = useLocale();
  const appConfig = useAppConfiguration();

  const [parameters, setParameters] =
    useState<TemplatesPortfolioFilterParameter>({
      portfolioId: undefined,
      type: TemplateTypeEnum.Letters,
    });

  const portfolios = useListPortfolios();
  const defaultPortfolio = portfolios.data?.[0]?.id;

  useEffect(() => {
    if (defaultPortfolio) {
      setParameters({
        portfolioId: defaultPortfolio,
        type: TemplateTypeEnum.Letters,
      });
    }
  }, [defaultPortfolio]);

  const handlePortfolioChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const { name, value } = event.target;
    setParameters((portfolioFilters) => ({
      ...portfolioFilters,
      [name]: value,
    }));
  };

  const letterTemplates = useListTemplates(parameters, {
    enabled: Boolean(parameters.portfolioId),
  });

  const errorMessage = (
    <Typography
      role="alert"
      color="error"
      aria-label={t("common:error.default")}
    >
      {t("common:error.default")}
    </Typography>
  );

  const tableHeaders: string[] = [
    t("letter_templates.list.name_label"),
    t("letter_templates.list.type_label"),
    t("letter_templates.list.last_update_label"),
    t("letter_templates.list.version_label"),
    t("letter_templates.list.active_label"),
  ];

  return (
    <Loader
      fallback={
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      }
      ready={Boolean(portfolios.data ?? portfolios.error)}
      render={(): ReactElement => {
        if (portfolios.error || !portfolios.data) {
          return errorMessage;
        }

        if (portfolios.data.length === 0) {
          return (
            <NoResults message={t("letter_templates.no_portfolios_message")} />
          );
        }

        return (
          <ListLayout
            filters={
              <TextField
                inputProps={{ id: "portfolioId" }}
                onChange={handlePortfolioChange}
                label={t("letter_templates.list.portfolio_filter")}
                margin="none"
                size="small"
                name="portfolioId"
                select
                value={parameters.portfolioId ?? ""}
                variant="outlined"
                InputLabelProps={{
                  htmlFor: "portfolioId",
                  shrink: true,
                }}
              >
                {portfolios.data.map((portfolio) => (
                  <MenuItem value={portfolio.id} key={portfolio.id}>
                    {portfolio.name}
                  </MenuItem>
                ))}
              </TextField>
            }
            actions={
              <Stack direction="row" gap={1}>
                <MergeFieldsDialogContainer />
                {parameters.portfolioId && (
                  <CreateLetterTemplateContainer
                    portfolioId={parameters.portfolioId}
                  />
                )}
              </Stack>
            }
          >
            <Loader
              ready={Boolean(letterTemplates.data ?? letterTemplates.error)}
              render={() => {
                if (letterTemplates.error || !letterTemplates.data) {
                  return errorMessage;
                }

                return (
                  <>
                    <Table
                      size="small"
                      aria-label={t("letter_templates.list.table_label")}
                      stickyHeader
                    >
                      <LoadableTableHead
                        headings={tableHeaders}
                        loading={letterTemplates.isValidating}
                      />
                      <TableBody>
                        {letterTemplates.data.map((item) => (
                          <TableRow key={item.id} hover>
                            <TableCell padding="none">
                              <ListItemButton
                                dense
                                component={RouterLink}
                                to={`${appConfig.appRoutes.documents}/settings/letter-templates/${item.id}`}
                              >
                                <ListItemText
                                  primary={item.name}
                                  primaryTypographyProps={{ color: "primary" }}
                                />
                              </ListItemButton>
                            </TableCell>
                            <TableCell>{item.documentType}</TableCell>
                            <TableCell>
                              {item.createdAt
                                ? locale
                                    .formatDateTime(new Date(item.createdAt))
                                    .concat(` - ${item.createdBy}`)
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {item.version === 0
                                ? t("template_versions.base_version")
                                : item.version}
                            </TableCell>
                            <TableCell>
                              <YesNoValue value={item.active} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {letterTemplates.data.length === 0 && <NoResults />}
                  </>
                );
              }}
            />
          </ListLayout>
        );
      }}
    />
  );
}
