import { EditButton } from "@ldms/mui-sdk/components";
import { useListRoles, useUpdateUser, useUpdateUserStatus } from "api/users";
import UpdateUserFormDialog, {
  UpdateUserFormValues,
} from "apps/admin/components/UpdateUserFormDialog";
import { Role, User } from "apps/admin/types";
import { ReactElement, useState } from "react";
import { SubmitHandler } from "support/react-hook-form";

interface UpdateUserContainerProps {
  user: User;
}

export default function UpdateUserContainer({
  user,
}: Readonly<UpdateUserContainerProps>): ReactElement {
  const [isUpdateUserDialogOpen, setIsUpdateUserDialogOpen] = useState(false);

  const closeUpdateUserDialog = (): void => {
    setIsUpdateUserDialogOpen(false);
  };

  const updateUser = useUpdateUser(user.id, {
    onSuccess: closeUpdateUserDialog,
  });

  const updateUserStatus = useUpdateUserStatus(user.id, {
    onSuccess: closeUpdateUserDialog,
  });

  const openUpdateUserDialog = (): void => {
    updateUser.reset();
    updateUserStatus.reset();
    setIsUpdateUserDialogOpen(true);
  };

  const roles = useListRoles();

  const matchRoles = (
    selectedRoles: string[],
    availableRoles: Role[],
  ): string[] =>
    selectedRoles
      .map((id) => availableRoles.find((role) => String(role.id) === id))
      .filter((role: Role | undefined): role is Role => !!role)
      .map((role) => role?.name);

  const roleOptions =
    roles.data?.map((item) => ({
      label: item.name,
      value: String(item.id),
    })) ?? [];

  const makeOnUpdateUserSubmit: SubmitHandler<UpdateUserFormValues> = async (
    data,
  ) => {
    await updateUser.execute({
      ...data,
      roles: matchRoles(data.roles, roles.data ?? []),
    });
  };

  const makeActiveToggle = (active: boolean): Promise<void> =>
    updateUserStatus.execute({ active });

  return (
    <>
      <EditButton onClick={openUpdateUserDialog} />

      <UpdateUserFormDialog
        active={user.active}
        defaultValues={{
          roles: user.roles.map((r) => String(r.id)),
        }}
        username={user.username}
        firstName={user.firstName}
        lastName={user.lastName}
        roleOptions={roleOptions}
        error={updateUserStatus.error?.message ?? updateUser.error?.message}
        open={isUpdateUserDialogOpen}
        onClose={closeUpdateUserDialog}
        onSubmit={makeOnUpdateUserSubmit}
        onActiveToggle={makeActiveToggle}
      />
    </>
  );
}
