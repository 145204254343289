/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommissionCalculationTypeModel } from './CommissionCalculationTypeModel';
import {
    CommissionCalculationTypeModelFromJSON,
    CommissionCalculationTypeModelFromJSONTyped,
    CommissionCalculationTypeModelToJSON,
} from './CommissionCalculationTypeModel';

/**
 * 
 * @export
 * @interface CommissionsModel
 */
export interface CommissionsModel {
    /**
     * The broker commission percentage
     * @type {number}
     * @memberof CommissionsModel
     */
    brokerCommission?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CommissionsModel
     */
    brokerCommissionAmount?: string;
    /**
     * Flag to denote whether the broker commission amount is applied to the capital outstanding
     * @type {boolean}
     * @memberof CommissionsModel
     */
    applyBrokerAmountToCapital?: boolean;
    /**
     * The supplier commission percentage
     * @type {number}
     * @memberof CommissionsModel
     */
    supplierCommission?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CommissionsModel
     */
    supplierCommissionAmount?: string;
    /**
     * Flag to denote whether the supplier commission amount is applied to the capital outstanding
     * @type {boolean}
     * @memberof CommissionsModel
     */
    applySupplierAmountToCapital?: boolean;
    /**
     * denotes whether the VAT is being funded on broker commission
     * @type {boolean}
     * @memberof CommissionsModel
     */
    isBrokerCommissionFundedVat?: boolean;
    /**
     * 
     * @type {CommissionCalculationTypeModel}
     * @memberof CommissionsModel
     */
    commissionCalculationType?: CommissionCalculationTypeModel;
}

/**
 * Check if a given object implements the CommissionsModel interface.
 */
export function instanceOfCommissionsModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CommissionsModelFromJSON(json: any): CommissionsModel {
    return CommissionsModelFromJSONTyped(json, false);
}

export function CommissionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommissionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brokerCommission': !exists(json, 'brokerCommission') ? undefined : json['brokerCommission'],
        'brokerCommissionAmount': !exists(json, 'brokerCommissionAmount') ? undefined : json['brokerCommissionAmount'],
        'applyBrokerAmountToCapital': !exists(json, 'applyBrokerAmountToCapital') ? undefined : json['applyBrokerAmountToCapital'],
        'supplierCommission': !exists(json, 'supplierCommission') ? undefined : json['supplierCommission'],
        'supplierCommissionAmount': !exists(json, 'supplierCommissionAmount') ? undefined : json['supplierCommissionAmount'],
        'applySupplierAmountToCapital': !exists(json, 'applySupplierAmountToCapital') ? undefined : json['applySupplierAmountToCapital'],
        'isBrokerCommissionFundedVat': !exists(json, 'isBrokerCommissionFundedVat') ? undefined : json['isBrokerCommissionFundedVat'],
        'commissionCalculationType': !exists(json, 'commissionCalculationType') ? undefined : CommissionCalculationTypeModelFromJSON(json['commissionCalculationType']),
    };
}

export function CommissionsModelToJSON(value?: CommissionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brokerCommission': value.brokerCommission,
        'brokerCommissionAmount': value.brokerCommissionAmount,
        'applyBrokerAmountToCapital': value.applyBrokerAmountToCapital,
        'supplierCommission': value.supplierCommission,
        'supplierCommissionAmount': value.supplierCommissionAmount,
        'applySupplierAmountToCapital': value.applySupplierAmountToCapital,
        'isBrokerCommissionFundedVat': value.isBrokerCommissionFundedVat,
        'commissionCalculationType': CommissionCalculationTypeModelToJSON(value.commissionCalculationType),
    };
}

