import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/documents/templates/keys";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import {
  CreateLetterTemplateRequest,
  PortfolioApi,
} from "generated/documents/apis";

export const useCreateLetterTemplate = (
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(PortfolioApi);
  const partialMutate = usePartialMutate();

  return useCommand<CreateLetterTemplateRequest, void, ResponseError>(
    (requestParameters) => {
      return api.createLetterTemplate(requestParameters);
    },
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.list());
        options.onSuccess?.();
      },
    },
  );
};
