import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Box } from "@mui/system";
import { useGetCompany } from "api/companies";
import CompanyDetailsContainer from "apps/servicing/modules/settings/containers/CompanyDetailsContainer";
import ListPortfoliosContainer from "apps/servicing/modules/settings/containers/ListPortfoliosContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

interface CompanyDetailsViewProps {
  companyId: string;
}

function CompanyDetailsView({ companyId }: CompanyDetailsViewProps) {
  const { t } = useTranslation("servicing");
  const company = useGetCompany(companyId);
  const appConfig = useAppConfiguration();

  return (
    <main>
      <ViewLayout
        title={company.data?.name}
        breadcrumbs={[
          {
            href: `${appConfig.appRoutes.servicing}/settings/companies`,
            label: t("companies.breadcrumb_link_label"),
          },
        ]}
      >
        <ContainedLayout>
          <CompanyDetailsContainer companyId={companyId} />
          <Box paddingY={3}>
            <ListPortfoliosContainer companyId={companyId} />
          </Box>
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:companies:manage"],
})(CompanyDetailsView);
