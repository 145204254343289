/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrequencyOfInstalmentsModel } from './FrequencyOfInstalmentsModel';
import {
    FrequencyOfInstalmentsModelFromJSON,
    FrequencyOfInstalmentsModelFromJSONTyped,
    FrequencyOfInstalmentsModelToJSON,
} from './FrequencyOfInstalmentsModel';

/**
 * 
 * @export
 * @interface SecondaryRentalDetailsModel
 */
export interface SecondaryRentalDetailsModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    amount?: string;
    /**
     * 
     * @type {FrequencyOfInstalmentsModel}
     * @memberof SecondaryRentalDetailsModel
     */
    frequency?: FrequencyOfInstalmentsModel;
    /**
     * The introducer share percentage
     * @type {number}
     * @memberof SecondaryRentalDetailsModel
     */
    introducerShare?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    maintenanceCharge?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    insurancePremium?: string;
}

/**
 * Check if a given object implements the SecondaryRentalDetailsModel interface.
 */
export function instanceOfSecondaryRentalDetailsModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecondaryRentalDetailsModelFromJSON(json: any): SecondaryRentalDetailsModel {
    return SecondaryRentalDetailsModelFromJSONTyped(json, false);
}

export function SecondaryRentalDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecondaryRentalDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'frequency': !exists(json, 'frequency') ? undefined : FrequencyOfInstalmentsModelFromJSON(json['frequency']),
        'introducerShare': !exists(json, 'introducerShare') ? undefined : json['introducerShare'],
        'maintenanceCharge': !exists(json, 'maintenanceCharge') ? undefined : json['maintenanceCharge'],
        'insurancePremium': !exists(json, 'insurancePremium') ? undefined : json['insurancePremium'],
    };
}

export function SecondaryRentalDetailsModelToJSON(value?: SecondaryRentalDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'frequency': FrequencyOfInstalmentsModelToJSON(value.frequency),
        'introducerShare': value.introducerShare,
        'maintenanceCharge': value.maintenanceCharge,
        'insurancePremium': value.insurancePremium,
    };
}

