import { AccessControl, useSession } from "@ldms/mui-sdk/bootstrap";
import { Button, Menu, MenuItem, MenuProps } from "@mui/material";
import AgreementTransactions from "apps/servicing/modules/agreements/containers/AgreementTransactions";
import MarkFeesAsPaidContainer from "apps/servicing/modules/agreements/containers/MarkFeesAsPaidContainer";
import PostCharge from "apps/servicing/modules/agreements/containers/PostCharge";
import RePresentDirectDebit from "apps/servicing/modules/agreements/containers/RePresentDirectDebit";
import ModifiableToggle from "common/components/ModifiableToggle";
import useModal from "common/hooks/useModal";
import { ContainedLayout } from "common/layouts";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

function AddPostChargeAction() {
  const { t } = useTranslation("agreements");
  const modal = useModal();

  return (
    <>
      <Button variant="contained" onClick={modal.open}>
        {t("transactions.post_charge_button_label")}
      </Button>
      <PostCharge open={modal.isOpen} onClose={modal.close} />
    </>
  );
}

function AddTransactionMenu(props: MenuProps) {
  const { t } = useTranslation("agreements");
  const rePresentAdHoc = useModal();
  const rePresentAmountDue = useModal();
  const feesAsPaid = useModal();

  const elevatedPermission = "servicing:transactions:elevated";
  const managePermission = "servicing:transactions:manage";

  return (
    <ModifiableToggle>
      <AccessControl
        allowedPermissions={[elevatedPermission, managePermission]}
      >
        <Menu {...props}>
          <AccessControl allowedPermissions={[elevatedPermission]}>
            <MenuItem onClick={rePresentAdHoc.open}>
              {t("transactions.represent_direct_debit.adhoc_amount_menu_label")}
            </MenuItem>
            <MenuItem onClick={rePresentAmountDue.open}>
              {t("transactions.represent_direct_debit.amount_due_menu_label")}
            </MenuItem>
          </AccessControl>

          <AccessControl allowedPermissions={[managePermission]}>
            <MenuItem onClick={feesAsPaid.open}>
              {t("transactions.mark_fees_as_paid.menu_button_label")}
            </MenuItem>
          </AccessControl>
        </Menu>

        <AccessControl allowedPermissions={[elevatedPermission]}>
          <RePresentDirectDebit
            directDebitType="Ad-hoc Amount"
            open={rePresentAdHoc.isOpen}
            onClose={rePresentAdHoc.close}
            onSuccess={rePresentAdHoc.close}
          />

          <RePresentDirectDebit
            directDebitType="Amount Due"
            open={rePresentAmountDue.isOpen}
            onClose={rePresentAmountDue.close}
            onSuccess={rePresentAmountDue.close}
          />
        </AccessControl>

        <AccessControl allowedPermissions={[managePermission]}>
          <MarkFeesAsPaidContainer
            open={feesAsPaid.isOpen}
            onSuccess={feesAsPaid.close}
          />
        </AccessControl>
      </AccessControl>
    </ModifiableToggle>
  );
}

export default function TransactionsView(): ReactElement {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const session = useSession();
  const managePermission = "servicing:transactions:manage";

  return (
    <ViewLayout
      title={t("transactions.heading")}
      action={
        agreement.isModifiable && session.hasPermission(managePermission) ? (
          <>
            <Button
              variant="contained"
              component={RouterLink}
              to="../transactions/add-transaction"
            >
              {t("transactions.add_transaction")}
            </Button>
            <AddPostChargeAction />
          </>
        ) : undefined
      }
      menu={AddTransactionMenu}
    >
      <FixedLayout>
        <ContainedLayout>
          <AgreementTransactions />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}
