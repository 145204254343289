/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaintenanceDetailsModel
 */
export interface MaintenanceDetailsModel {
    /**
     * Name of the maintenance company
     * @type {string}
     * @memberof MaintenanceDetailsModel
     */
    maintainerName: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof MaintenanceDetailsModel
     */
    amount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof MaintenanceDetailsModel
     */
    cost?: string;
}

/**
 * Check if a given object implements the MaintenanceDetailsModel interface.
 */
export function instanceOfMaintenanceDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "maintainerName" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function MaintenanceDetailsModelFromJSON(json: any): MaintenanceDetailsModel {
    return MaintenanceDetailsModelFromJSONTyped(json, false);
}

export function MaintenanceDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maintainerName': json['maintainerName'],
        'amount': json['amount'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
    };
}

export function MaintenanceDetailsModelToJSON(value?: MaintenanceDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maintainerName': value.maintainerName,
        'amount': value.amount,
        'cost': value.cost,
    };
}

