/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OnboardingStatusModel = {
    Approved: 'Approved',
    Review: 'Review',
    Rejected: 'Rejected',
    Staged: 'Staged',
    Completed: 'Completed'
} as const;
export type OnboardingStatusModel = typeof OnboardingStatusModel[keyof typeof OnboardingStatusModel];


export function OnboardingStatusModelFromJSON(json: any): OnboardingStatusModel {
    return OnboardingStatusModelFromJSONTyped(json, false);
}

export function OnboardingStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingStatusModel {
    return json as OnboardingStatusModel;
}

export function OnboardingStatusModelToJSON(value?: OnboardingStatusModel | null): any {
    return value as any;
}

