export interface CorrespondenceMethod {
  id: number;
  type: string;
  code: string;
  contact: string;
  comment?: string;
  current?: boolean;
  created?: string;
  old?: boolean;
}

export interface ContactPoint {
  systemId: string;
  type: string;
  value: string;
  comment?: string;
  createdAt?: Date;
}

export enum ContactPointType {
  HomeTelephone = "Home Telephone",
  WorkTelephone = "Work Telephone",
  Email = "Email Address",
  Fax = "Fax Number",
}
