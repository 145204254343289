import { Loader } from "@ldms/mui-sdk/templates";
import { Box } from "@mui/material";
import { useGetBankAccountDetails } from "api/customers/bank-accounts/getBankAccountDetails";

import BankAccountDetails from "apps/servicing/modules/customers/containers/BankAccountDetails";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { QueryError } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useApi } from "common/providers";
import { ClientQueryApi } from "generated/core/apis";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next/";
import useStickySWR from "support/use-sticky-swr";

interface BankAccountProps {
  bankAccountId: string;
}

export default function BankAccountView({
  bankAccountId,
}: BankAccountProps): ReactElement {
  const { t } = useTranslation("customers");
  const customer = useCustomer();
  const appConfig = useAppConfiguration();
  const bankAccount = useGetBankAccountDetails(bankAccountId);
  const clientQueryApi = useApi(ClientQueryApi);

  const bankAccounts = useStickySWR(
    ["/clients/:id/bank-accounts", customer.data.id],
    () => clientQueryApi.listBankAccounts({ clientId: customer.data.id }),
  );

  const bankAccountLegacyId = bankAccounts.data?.find(
    (bank) => bank.systemId === bankAccountId,
  )?.id;

  const renderBankAccount = () => {
    return (
      <BankAccountDetails
        legacyBankAccountId={Number(bankAccountLegacyId)}
        bankAccountId={bankAccountId}
        clientId={customer.data.id}
        onCancelMandates={bankAccount.refetch}
      />
    );
  };

  if (bankAccount.error) {
    return (
      <Box paddingY={2}>
        <QueryError onRetry={bankAccount.refetch} />
      </Box>
    );
  }

  const type = bankAccount.data?.isCustomer ? "Main" : bankAccount.data?.type;

  return (
    <ViewLayout
      breadcrumbs={[
        {
          href: `${appConfig.appRoutes.servicing}/customers/${customer.data.id}/bank-accounts`,
          label: t("breadcrumbs.bank_accounts_link"),
        },
      ]}
      title={
        bankAccount.data ? (
          `${type} - ${bankAccount.data?.accountHoldersName}`
        ) : (
          <>&nbsp;</>
        )
      }
    >
      <ContainedLayout>
        <Loader
          ready={Boolean(bankAccount.data && bankAccounts.data)}
          render={renderBankAccount}
        />
      </ContainedLayout>
    </ViewLayout>
  );
}
