import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import ListPortfolioBankAccountsContainer from "apps/servicing/modules/settings/containers/ListPortfolioBankAccountsContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ContainedLayout from "common/layouts/ContainedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

function PortfolioBankAccountsView() {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();
  const href = `${appConfig.appRoutes.servicing}/settings/bank-accounts/add-bank-account`;

  return (
    <ViewLayout
      title={t("bank_accounts.bank_accounts_heading")}
      action={
        <AddButton component={RouterLink} to={href} variant="contained" />
      }
    >
      <ContainedLayout>
        <ListPortfolioBankAccountsContainer />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:bank-accounts:elevated"],
})(PortfolioBankAccountsView);
