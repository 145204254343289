import { PortfolioReportFormDialog } from "apps/reporting/components";
import {
  usePortfolioReportResolver,
  useReportDownload,
} from "apps/reporting/hooks";
import { PortfolioReportDialogFormValues } from "apps/reporting/types";
import { useApi } from "common/providers";
import { PortfolioQueryApi, ReportQueryApi } from "generated/core/apis";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

interface PortfolioCashFlowReportSummaryReportContainerProps {
  onClose(): void;
  open: boolean;
}

export default function PortfolioCashFlowReportSummaryReportContainer({
  onClose,
  open,
}: PortfolioCashFlowReportSummaryReportContainerProps): ReactElement {
  const { t } = useTranslation("reporting");
  const resolver = usePortfolioReportResolver();
  const portfolioApi = useApi(PortfolioQueryApi);
  const api = useApi(ReportQueryApi);
  const portfolios = useSWR(["portfolios"], () =>
    portfolioApi.listPortfolios(),
  );

  const portfolioCashFlowReportSummaryReportDownload = useReportDownload(
    (data: PortfolioReportDialogFormValues) =>
      api.portfolioCashFlowSummaryRaw({
        portfolioId: Number(data.portfolio),
      }),
    {
      onSuccess: onClose,
    },
  );

  const onSubmit = async (
    data: PortfolioReportDialogFormValues,
  ): Promise<void> =>
    portfolioCashFlowReportSummaryReportDownload.download(data);

  return (
    <PortfolioReportFormDialog<PortfolioReportDialogFormValues>
      onClose={onClose}
      onSubmit={onSubmit}
      error={portfolioCashFlowReportSummaryReportDownload.error?.message}
      open={open}
      portfolios={(portfolios.data ?? []).map((portfolio) => ({
        label: portfolio.name,
        value: String(portfolio.id),
      }))}
      ready={
        Boolean(portfolios.data) &&
        !portfolioCashFlowReportSummaryReportDownload.isLoading
      }
      resolver={resolver}
      title={t("report_dialog.portfolio_cash_flow_summary.title")}
    />
  );
}
