import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { ChangeEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface ViewDateToggleProps {
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  value: "lastSixMonths" | "";
  testId: string;
}

export default function ViewDateToggle({
  onChange,
  value,
  testId,
}: ViewDateToggleProps): ReactElement {
  const { t } = useTranslation("common");

  const control = <Radio color="primary" size="small" />;

  return (
    <RadioGroup
      aria-label="from"
      name="from"
      onChange={onChange}
      row
      value={value}
    >
      <FormControlLabel
        control={control}
        label={String(t("last_6_months"))}
        value="lastSixMonths"
        data-testid="viewDateToggle.lastSixMonths"
      />
      <FormControlLabel
        control={control}
        label={String(t("view_all"))}
        value=""
        data-testid={testId}
      />
    </RadioGroup>
  );
}
