import { Form } from "@ldms/mui-sdk/forms";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAddContact } from "api/customers/contacts";
import { useListContacts } from "api/customers/contacts/listContacts";
import { DigitalCorrespondencePreferences } from "apps/servicing/modules/customers/containers/EditContactContainer/EditContactContainer";
import { ControlledTextField, Loader, QueryError } from "common/components";
import { useYupResolver } from "common/hooks";
import { ContactTypeModel } from "generated/servicing-v2/models/ContactTypeModel";
import { CustomerContactModel } from "generated/servicing-v2/models/CustomerContactModel";
import { ReactElement } from "react";
import {
  Control,
  FieldErrors,
  Resolver,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export interface AddContactFormValues {
  secondName: string;
  title: string;
  contactType: ContactTypeModel;
  lastName: string;
  companyName: string;
  firstName: string;
  addressLine4: string;
  postcode: string;
  addressLine2: string;
  addressLine3: string;
  homeTelephone: string;
  addressLine1: string;
  invoiceByEmail: boolean;
  receiveSystemGeneratedCorrespondence: boolean;
  workTelephone: string;
  emailAddress: string;
  faxNumber: string;
}

interface AddContactFormControlsProps {
  errors: FieldErrors<AddContactFormValues>;
  register: UseFormRegister<AddContactFormValues>;
  control: Control<AddContactFormValues>;
  availableContactTypes: string[] | undefined;
  formWatch: AddContactFormValues;
}

const labels = {
  addressLine1: "add_contact.address_line_1_label",
  addressLine2: "add_contact.address_line_2_label",
  addressLine3: "add_contact.address_line_3_label",
  addressLine4: "add_contact.address_line_4_label",
  postCode: "add_contact.postcode_label",
  email: "add_contact.email_address_label",
};

function AddContactFormControls({
  control,
  errors,
  register,
  formWatch,
  availableContactTypes,
}: Readonly<AddContactFormControlsProps>): ReactElement {
  const { t } = useTranslation("clients");

  return (
    <>
      <ControlledTextField
        id="contactType"
        select
        label={t("add_contact.contact_type_label")}
        name="contactType"
        error={errors.contactType?.message}
        helperText={errors.contactType?.message}
        control={control}
        SelectProps={{ displayEmpty: true }}
        defaultValue=""
        required
      >
        <MenuItem value="">
          <i>{t("common:please_select")}</i>
        </MenuItem>
        {availableContactTypes?.map((contactType) => (
          <MenuItem key={uuidv4()} value={contactType}>
            {contactType}
          </MenuItem>
        ))}
      </ControlledTextField>

      <Grid spacing={2} container>
        <Grid item>
          <Grid spacing={2} rowSpacing={0.5} container>
            <Grid item sm={6}>
              <TextField
                error={Boolean(errors.title)}
                helperText={errors.title?.message}
                label={t("add_contact.title_label")}
                {...register("title")}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message}
                label={t("add_contact.first_name_label")}
                {...register("firstName")}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label={t("add_contact.second_name_label")}
                error={Boolean(errors.secondName)}
                helperText={errors.secondName?.message}
                {...register("secondName")}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message}
                label={t("add_contact.last_name_label")}
                {...register("lastName")}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                error={Boolean(errors.companyName)}
                helperText={errors.companyName?.message}
                label={t("add_contact.company_name_label")}
                {...register("companyName")}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Divider flexItem variant="fullWidth" />
        </Grid>

        <Grid item>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item sm={6}>
              <TextField
                label={t(labels.addressLine1)}
                error={Boolean(errors.addressLine1)}
                helperText={errors.addressLine1?.message}
                {...register("addressLine1")}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                error={Boolean(errors.addressLine2)}
                helperText={errors.addressLine2?.message}
                label={t(labels.addressLine2)}
                {...register("addressLine2")}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                error={Boolean(errors.addressLine3)}
                helperText={errors.addressLine3?.message}
                label={t(labels.addressLine3)}
                {...register("addressLine3")}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label={t(labels.addressLine4)}
                error={Boolean(errors.addressLine4)}
                helperText={errors.addressLine4?.message}
                {...register("addressLine4")}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                error={Boolean(errors.postcode)}
                helperText={errors.postcode?.message}
                label={t(labels.postCode)}
                {...register("postcode")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Divider flexItem variant="fullWidth" />
        </Grid>
        <Grid item>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item sm={6}>
              <TextField
                error={Boolean(errors.workTelephone)}
                helperText={errors.workTelephone?.message}
                label={t("add_contact.work_telephone_label")}
                {...register("workTelephone", {
                  setValueAs: (value) => value || undefined,
                })}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label={t("add_contact.home_telephone_label")}
                error={Boolean(errors.homeTelephone)}
                helperText={errors.homeTelephone?.message}
                {...register("homeTelephone", {
                  setValueAs: (value) => value || undefined,
                })}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                error={Boolean(errors.faxNumber)}
                helperText={errors.faxNumber?.message}
                label={t("add_contact.fax_number_label")}
                {...register("faxNumber", {
                  setValueAs: (value) => value || undefined,
                })}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                error={Boolean(errors.emailAddress)}
                helperText={errors.emailAddress?.message}
                required={formWatch.invoiceByEmail}
                label={t(labels.email)}
                {...register("emailAddress", {
                  setValueAs: (value) => value || undefined,
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Divider flexItem variant="fullWidth" />
        </Grid>
        <Grid item>
          <DigitalCorrespondencePreferences<AddContactFormValues>
            control={control}
            isInvoicing={formWatch.contactType === ContactTypeModel.Invoicing}
          />
        </Grid>
      </Grid>
    </>
  );
}

const useAddContactResolver = (): Resolver<AddContactFormValues> => {
  const { t } = useTranslation("clients");
  const firstNameLastNameCompanyRequired =
    "add_contact.errors.first_name_last_name_company_required";

  return useYupResolver<AddContactFormValues>((yup) =>
    yup.object().shape({
      title: yup.string().maxCharacters(50, t("add_contact.title_label")),
      firstName: yup
        .string()
        .maxCharacters(50, t("add_contact.first_name_label"))
        .test(
          "isDependentOnLastNameAndCompanyName",
          t(firstNameLastNameCompanyRequired),
          function (value, testContext) {
            return (
              value ||
              (!testContext.parent.lastName && testContext.parent.companyName)
            );
          },
        ),
      secondName: yup
        .string()
        .maxCharacters(50, t("add_contact.second_name_label")),
      lastName: yup
        .string()
        .maxCharacters(50, t("add_contact.last_name_label"))
        .test(
          "isDependentOnFirstNameAndCompanyName",
          t(firstNameLastNameCompanyRequired),
          function (value, testContext) {
            return (
              value ||
              (!testContext.parent.firstName && testContext.parent.companyName)
            );
          },
        ),
      contactType: yup.string().isRequired(t("add_contact.contact_type_label")),
      companyName: yup
        .string()
        .maxCharacters(50, t("add_contact.company_name_label"))
        .test(
          "isDependentOnFirstNameAndLastName",
          t(firstNameLastNameCompanyRequired),
          function (value, testContext) {
            return (
              value ||
              (testContext.parent.firstName && testContext.parent.lastName)
            );
          },
        ),
      addressLine1: yup
        .string()
        .optionalAddressLine1Validator(
          "addContactAddressLine1",
          t(labels.addressLine1),
        )
        .maxCharacters(50, t(labels.addressLine1)),
      addressLine2: yup.string().maxCharacters(50, t(labels.addressLine2)),
      addressLine3: yup.string().maxCharacters(50, t(labels.addressLine3)),
      addressLine4: yup.string().maxCharacters(50, t(labels.addressLine4)),
      postcode: yup
        .string()
        .optionalPostcodeValidator("addContactPostcode", t(labels.postCode))
        .maxCharacters(10, t(labels.postCode)),
      homeTelephone: yup
        .string()
        .maxCharacters(200, t("add_contact.home_telephone_label")),
      workTelephone: yup
        .string()
        .maxCharacters(200, t("add_contact.work_telephone_label")),
      emailAddress: yup
        .string()
        .maxCharacters(200, t(labels.email))
        .when("invoiceByEmail", {
          is: true,
          then: yup.string().isRequired(t(labels.email)),
          otherwise: yup.string(),
        }),
      faxNumber: yup
        .string()
        .maxCharacters(200, t("add_contact.fax_number_label")),
      invoiceByEmail: yup.boolean(),
    }),
  );
};

const AddContactContainer = ({ customerId }: { customerId: string }) => {
  const { t } = useTranslation(["clients", "common"]);
  const contacts = useListContacts(customerId);
  const navigate = useNavigate();
  const resolver = useAddContactResolver();
  const addContact = useAddContact(customerId, {
    onSuccess: () => {
      navigate("../contacts");
    },
  });

  const onSubmit = async (data: AddContactFormValues): Promise<void> => {
    const addContactModel: CustomerContactModel = {
      type: data.contactType,
      title: data.title,
      firstName: data.firstName,
      secondName: data.secondName,
      lastName: data.lastName,
      companyName: data.companyName,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      addressLine3: data.addressLine3,
      addressLine4: data.addressLine4,
      postcode: data.postcode,
      homeTelephone: data.homeTelephone,
      faxNumber: data.faxNumber,
      emailAddress: data.emailAddress,
      workTelephone: data.workTelephone,
      receiveSystemGeneratedCorrespondence:
        data.receiveSystemGeneratedCorrespondence,
      invoiceByEmail: data.invoiceByEmail,
    };
    await addContact.execute(addContactModel);
  };

  const currentContactTypes = contacts.data
    ?.map((contact) => contact.type)
    .filter(
      (type) =>
        type === ContactTypeModel.Invoicing ||
        type === ContactTypeModel.Finance,
    );

  const availableContactTypes = (
    Object.values(ContactTypeModel) as Array<keyof typeof ContactTypeModel>
  ).filter(
    (type) => !currentContactTypes?.some((currentType) => type === currentType),
  );

  return (
    <Loader
      fallback={
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      }
      ready={Boolean(contacts.data ?? contacts.error)}
      render={() => {
        if (contacts.error || !contacts.data) {
          return <QueryError onRetry={contacts.refetch} />;
        }

        return (
          <Form
            label={t("add_contact.title")}
            noValidate
            onSubmit={onSubmit}
            options={{ shouldUnregister: true }}
            resolver={resolver}
          >
            {(form) => (
              <Container maxWidth="sm">
                <Paper>
                  <Stack gap={2} padding={2}>
                    <Box>
                      <AddContactFormControls
                        errors={form.formState.errors}
                        formWatch={form.watch()}
                        register={form.register}
                        control={form.control}
                        availableContactTypes={availableContactTypes}
                      />

                      {addContact.error && (
                        <Typography color="error" data-testid="form.error">
                          {t("common:error.default")}
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex" justifyContent="flex-end" gap={1}>
                      <Button onClick={() => navigate("../contacts")}>
                        {t("common:cancel")}
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={form.formState.isSubmitting}
                      >
                        {t("add_contact.add_contact_button")}
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Container>
            )}
          </Form>
        );
      }}
    />
  );
};

export default AddContactContainer;
