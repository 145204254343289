/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClientAttachmentListItemModel,
} from '../models/index';
import {
    ClientAttachmentListItemModelFromJSON,
    ClientAttachmentListItemModelToJSON,
} from '../models/index';

export interface ListClientAttachmentsRequest {
    clientId: number;
    offset?: number;
    limit?: number;
    type?: string;
    agreement?: string;
    from?: Date;
    to?: Date;
}

/**
 * 
 */
export class ClientAttachmentQueryApi extends runtime.BaseAPI {

    /**
     * List client attachments
     */
    async listClientAttachmentsRaw(requestParameters: ListClientAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientAttachmentListItemModel>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listClientAttachments.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.agreement !== undefined) {
            queryParameters['agreement'] = requestParameters.agreement;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["client-documents:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/attachments`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientAttachmentListItemModelFromJSON));
    }

    /**
     * List client attachments
     */
    async listClientAttachments(requestParameters: ListClientAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientAttachmentListItemModel>> {
        const response = await this.listClientAttachmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
