import { Loader } from "@ldms/mui-sdk/templates";
import { Search } from "@mui/icons-material";
import { Box, Pagination, TextField } from "@mui/material";
import { useListCustomerAgreements } from "api/customers/agreements";
import CustomerAgreementList, {
  CustomerAgreement,
} from "apps/servicing/modules/customers/components/CustomerAgreementList";
import { QueryError } from "common/components";
import Surface from "common/components/Surface";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import usePaginateData from "common/hooks/usePaginateData";
import { ClientAgreementListItemModel } from "generated/core/models";
import { debounce } from "lodash";
import {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface CustomerAgreementsProps {
  customerId: number;
}

export default function CustomerAgreementsContainer({
  customerId,
}: CustomerAgreementsProps) {
  const [query, setQuery] = useState("");
  const appConfig = useAppConfiguration();
  const { t } = useTranslation("customers");
  const agreements = useListCustomerAgreements(customerId, { query });

  const mapAgreement = (
    agreement: ClientAgreementListItemModel,
  ): CustomerAgreement => ({
    id: agreement.id,
    number:
      agreement.number ||
      t("agreements.agreement_number_placeholder", { id: agreement.id }),
    product: agreement.product,
    balance: agreement.currentBalance,
    arrears: agreement.arrearsBalance,
    status: agreement.status,
    href: `${appConfig.appRoutes.servicing}/agreements/${agreement.id}`,
  });

  const paginatedData = usePaginateData(
    agreements.data?.agreements.map(mapAgreement) || [],
    5,
    { search: query },
  );

  const onQueryChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    setQuery(event.target.value);
  };

  const handleQueryChange = useMemo(() => debounce(onQueryChange, 300), []);

  useEffect(() => {
    return handleQueryChange.cancel;
  }, [handleQueryChange]);

  const handlePageChange = (_: ChangeEvent<unknown>, value: number): void => {
    paginatedData.setPage(value);
  };

  const renderLoadedAgreements = () => {
    if (agreements.error || !agreements.data) {
      return <QueryError onRetry={agreements.refetch} />;
    }

    return (
      <CustomerAgreementList
        data={paginatedData.results}
        loading={agreements.isValidating}
      />
    );
  };

  return (
    <Surface
      title={t("overview.agreements.heading")}
      disableGutters
      action={
        <Box maxWidth={240}>
          <TextField
            InputProps={{
              endAdornment: <Search />,
            }}
            inputProps={{
              "aria-label": t("overview.agreements.search_label"),
            }}
            margin="none"
            onChange={handleQueryChange}
          />
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="flex-end">
          {paginatedData.totalNumberOfPages > 1 && (
            <Pagination
              count={paginatedData.totalNumberOfPages}
              page={paginatedData.currentPage}
              onChange={handlePageChange}
              siblingCount={0}
              boundaryCount={1}
            />
          )}
        </Box>
      }
    >
      <Loader
        ready={Boolean(agreements.data ?? agreements.error)}
        render={renderLoadedAgreements}
      />
    </Surface>
  );
}
