import { isEqual } from "lodash";
import { useEffect, useState } from "react";

export interface UsePaginateDataReturn<TData> {
  results: TData[];
  currentPage: number;
  totalNumberOfPages: number;
  setPage: (value: number) => void;
}

export default function usePaginateData<TData>(
  data: TData[],
  pageSize: number,
  query?: Record<string, unknown>,
): UsePaginateDataReturn<TData> {
  const [previousQuery, setPreviousQuery] = useState(query);
  const [currentPage, setCurrentPage] = useState(1);
  const setPage = (value: number): void => {
    setCurrentPage(value);
  };

  const totalNumberOfPages = Math.ceil(data.length / pageSize);
  const startOfPage = (currentPage - 1) * pageSize;
  const endOfPage = startOfPage + pageSize;
  const results = data.slice(startOfPage, endOfPage);

  useEffect(() => {
    if (!isEqual(previousQuery, query)) {
      setCurrentPage(1);
      setPreviousQuery(query);
    }
  }, [query, previousQuery, setPreviousQuery, setCurrentPage]);

  return {
    results,
    currentPage,
    totalNumberOfPages,
    setPage,
  };
}
