import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Typography } from "@mui/material";
import { useRepresentDirectDebit } from "api/agreements/transactions/represent-direct-debt";
import { useAgreement } from "common/providers";
import { DirectDebitTypeModel } from "generated/core/models";
import { useTranslation } from "react-i18next";

interface RePresentDirectDebitProps {
  directDebitType: DirectDebitTypeModel;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const RePresentDirectDebit = ({
  directDebitType,
  open,
  onClose,
  onSuccess,
}: RePresentDirectDebitProps) => {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const representDirectDebit = useRepresentDirectDebit(agreement.id);

  const onConfirm = async () => {
    await representDirectDebit.command(directDebitType);
    onSuccess();
  };

  return (
    <>
      <AlertDialog
        title={t("transactions.represent_direct_debit.error_title")}
        content={representDirectDebit.error?.message}
        labels={{ close: t("common:alert.close") }}
        onClose={representDirectDebit.resetError}
        open={Boolean(representDirectDebit.error?.message)}
      />
      <ConfirmationDialog
        open={open}
        onConfirm={onConfirm}
        onReject={onClose}
        title={t("transactions.represent_direct_debit.title_label", {
          paymentType: directDebitType,
        })}
        content={
          <Typography>
            {t("transactions.represent_direct_debit.description_message", {
              paymentType: directDebitType,
            })}
          </Typography>
        }
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />
    </>
  );
};

export default RePresentDirectDebit;
