import { useState } from "react";

type Direction = "asc" | "desc";

export default function useSort<T>(): {
  sortBy: T | undefined;
  sortDirection: Direction;
  onSort: (key: T) => void;
} {
  const [sortDirection, setSortDirection] = useState<Direction>("asc");
  const [sortBy, setSortBy] = useState<T>();

  const onSort = (key: T): void => {
    if (sortBy === key) {
      setSortDirection((direction) => (direction === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(key);
      setSortDirection("asc");
    }
  };

  return {
    sortBy,
    sortDirection,
    onSort,
  };
}
