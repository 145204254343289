import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { useGetPortfolioBankAccount } from "api/portfolio-bank-accounts";
import PortfolioBankAccountDetailsContainer from "apps/servicing/modules/settings/containers/PortfolioBankAccountDetailsContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

interface PortfolioBankAccountDetailsViewProps {
  portfolioBankAccountId: string;
}

function PortfolioBankAccountDetailsView({
  portfolioBankAccountId,
}: PortfolioBankAccountDetailsViewProps) {
  const { t } = useTranslation("servicing");
  const portfolioBankAccount = useGetPortfolioBankAccount(
    portfolioBankAccountId,
  );
  const appConfig = useAppConfiguration();

  return (
    <main>
      <ViewLayout
        title={portfolioBankAccount.data?.name}
        breadcrumbs={[
          {
            href: `${appConfig.appRoutes.servicing}/settings/bank-accounts`,
            label: t("bank_accounts.breadcrumb_link_label"),
          },
        ]}
      >
        <ContainedLayout>
          <PortfolioBankAccountDetailsContainer
            portfolioBankAccountId={portfolioBankAccountId}
          />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:bank-accounts:elevated"],
})(PortfolioBankAccountDetailsView);
