/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgreementListItemModel
 */
export interface AgreementListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AgreementListItemModel
     */
    readonly id: number;
    /**
     * The unique reference of the agreement
     * @type {string}
     * @memberof AgreementListItemModel
     */
    number?: string;
    /**
     * The product of the agreement
     * @type {string}
     * @memberof AgreementListItemModel
     */
    product?: string;
    /**
     * The name of the customer for which the agreement belongs to
     * @type {string}
     * @memberof AgreementListItemModel
     */
    customerName?: string;
    /**
     * The name of the company for which the agreement customer belongs to
     * @type {string}
     * @memberof AgreementListItemModel
     */
    companyName?: string;
    /**
     * The ID of the customer for which the agreement belongs to
     * @type {number}
     * @memberof AgreementListItemModel
     */
    customerId: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementListItemModel
     */
    balance: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementListItemModel
     */
    arrears: string;
    /**
     * The current status of the agreement
     * @type {string}
     * @memberof AgreementListItemModel
     */
    status: string;
}

/**
 * Check if a given object implements the AgreementListItemModel interface.
 */
export function instanceOfAgreementListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "arrears" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function AgreementListItemModelFromJSON(json: any): AgreementListItemModel {
    return AgreementListItemModelFromJSONTyped(json, false);
}

export function AgreementListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'product': !exists(json, 'product') ? undefined : json['product'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'customerId': json['customerId'],
        'balance': json['balance'],
        'arrears': json['arrears'],
        'status': json['status'],
    };
}

export function AgreementListItemModelToJSON(value?: AgreementListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'product': value.product,
        'customerName': value.customerName,
        'companyName': value.companyName,
        'customerId': value.customerId,
        'balance': value.balance,
        'arrears': value.arrears,
        'status': value.status,
    };
}

