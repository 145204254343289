/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FrequencyOfInstalmentsModel = {
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    HalfYearly: 'Half Yearly',
    Annually: 'Annually'
} as const;
export type FrequencyOfInstalmentsModel = typeof FrequencyOfInstalmentsModel[keyof typeof FrequencyOfInstalmentsModel];


export function FrequencyOfInstalmentsModelFromJSON(json: any): FrequencyOfInstalmentsModel {
    return FrequencyOfInstalmentsModelFromJSONTyped(json, false);
}

export function FrequencyOfInstalmentsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrequencyOfInstalmentsModel {
    return json as FrequencyOfInstalmentsModel;
}

export function FrequencyOfInstalmentsModelToJSON(value?: FrequencyOfInstalmentsModel | null): any {
    return value as any;
}

