import { QueryKey } from "api";
import { keys } from "api/agreements";

export const all = (agreementId: number): QueryKey => [
  ...keys.detail(agreementId),
  "payment-holiday",
];

export const details = (agreementId: number): QueryKey => [
  ...all(agreementId),
  "detail",
];

export const detail = (
  agreementId: number,
  params: {
    instalmentNumber: number;
    numberOfInstalments: number;
    isFullPaymentHoliday: boolean;
  },
): QueryKey => [...details(agreementId), params];
