import { Box, Button } from "@mui/material";

interface OnboardingStepActionsProps {
  back?: {
    label: string;
    onBack(): void;
  };
  next: {
    label: string;
    onNext?(): void;
  };
  disabled?: boolean;
}

export default function OnboardingStepActions(
  props: OnboardingStepActionsProps,
) {
  return (
    <Box display="flex" justifyContent="flex-end" paddingY={3}>
      {props.back && (
        <Box marginRight="auto">
          <Button onClick={props.back.onBack} size="large">
            {props.back.label}
          </Button>
        </Box>
      )}

      <Button
        color="primary"
        onClick={props.next.onNext}
        type="submit"
        size="large"
        variant="contained"
        disabled={props.disabled}
      >
        {props.next.label}
      </Button>
    </Box>
  );
}
