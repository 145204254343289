import { QueryKey } from "api";
import { keys } from "api/customers";

export const all = (): QueryKey => [...keys.all(), "contact-points"];

export const list = (clientId: number, type?: string): QueryKey => [
  ...all(),
  "list",
  clientId,
  type,
];
