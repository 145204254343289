import { PagedQuery, useQueryPaged } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { BatchQueryApi } from "generated/documents/apis";
import { BatchModel } from "generated/documents/models";
import { keys } from ".";

export const useListBatches: PagedQuery<
  BatchModel,
  {
    from?: Date;
    to?: Date;
    type?: string;
    status?: string;
  }
> = ({ pageSize, params = {} } = {}) => {
  const api = useApi(BatchQueryApi);

  return useQueryPaged(
    keys.list({
      from: params.from,
      to: params.to,
      type: params.type,
      status: params.status,
    }),
    async (_key, paging) =>
      api.listBatchesRaw({
        limit: paging.pageSize,
        offset: paging.offset,
        from: params.from,
        to: params.to,
        type: params.type,
        status: params.status,
      }),
    { limit: pageSize },
  );
};
