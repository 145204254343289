/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Contacts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactPointTypeModel } from './ContactPointTypeModel';
import {
    ContactPointTypeModelFromJSON,
    ContactPointTypeModelFromJSONTyped,
    ContactPointTypeModelToJSON,
} from './ContactPointTypeModel';

/**
 * 
 * @export
 * @interface ContactPointModel
 */
export interface ContactPointModel {
    /**
     * 
     * @type {ContactPointTypeModel}
     * @memberof ContactPointModel
     */
    type: ContactPointTypeModel;
    /**
     * The value of the contact point
     * @type {string}
     * @memberof ContactPointModel
     */
    value: string;
    /**
     * Audit note for this contact point
     * @type {string}
     * @memberof ContactPointModel
     */
    comment?: string;
}

/**
 * Check if a given object implements the ContactPointModel interface.
 */
export function instanceOfContactPointModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function ContactPointModelFromJSON(json: any): ContactPointModel {
    return ContactPointModelFromJSONTyped(json, false);
}

export function ContactPointModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPointModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': ContactPointTypeModelFromJSON(json['type']),
        'value': json['value'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function ContactPointModelToJSON(value?: ContactPointModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ContactPointTypeModelToJSON(value.type),
        'value': value.value,
        'comment': value.comment,
    };
}

