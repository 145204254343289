import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { OnboardingApi } from "generated/onboarding/apis";
import { FinancialProductModel } from "generated/onboarding/models";
import * as keys from "./keys";

type UseListFinancialProducts = () => UseQueryReturn<FinancialProductModel[]>;

export const useListFinancialProducts: UseListFinancialProducts = () => {
  const api = useApi(OnboardingApi);

  return useQuery(keys.list(), () => {
    return api.listFinancialProducts();
  });
};
