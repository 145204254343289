/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing settlement quotes within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SettlementQuoteModel,
} from '../models/index';
import {
    SettlementQuoteModelFromJSON,
    SettlementQuoteModelToJSON,
} from '../models/index';

export interface GetSettlementQuoteRequest {
    settlementQuoteId: number;
}

/**
 * 
 */
export class SettlementQuoteQueryApi extends runtime.BaseAPI {

    /**
     * Retrieve the details of a settlement quote
     */
    async getSettlementQuoteRaw(requestParameters: GetSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SettlementQuoteModel>> {
        if (requestParameters.settlementQuoteId === null || requestParameters.settlementQuoteId === undefined) {
            throw new runtime.RequiredError('settlementQuoteId','Required parameter requestParameters.settlementQuoteId was null or undefined when calling getSettlementQuote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["settlement-quotes:view"]);
        }

        const response = await this.request({
            path: `/settlement-quotes/{settlementQuoteId}`.replace(`{${"settlementQuoteId"}}`, encodeURIComponent(String(requestParameters.settlementQuoteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettlementQuoteModelFromJSON(jsonValue));
    }

    /**
     * Retrieve the details of a settlement quote
     */
    async getSettlementQuote(requestParameters: GetSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettlementQuoteModel> {
        const response = await this.getSettlementQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
