/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';
import type { BankDetailsModel } from './BankDetailsModel';
import {
    BankDetailsModelFromJSON,
    BankDetailsModelFromJSONTyped,
    BankDetailsModelToJSON,
} from './BankDetailsModel';
import type { CompanyModel } from './CompanyModel';
import {
    CompanyModelFromJSON,
    CompanyModelFromJSONTyped,
    CompanyModelToJSON,
} from './CompanyModel';
import type { CompanyTypeModel } from './CompanyTypeModel';
import {
    CompanyTypeModelFromJSON,
    CompanyTypeModelFromJSONTyped,
    CompanyTypeModelToJSON,
} from './CompanyTypeModel';
import type { CustomerModel } from './CustomerModel';
import {
    CustomerModelFromJSON,
    CustomerModelFromJSONTyped,
    CustomerModelToJSON,
} from './CustomerModel';

/**
 * 
 * @export
 * @interface CustomerDetailsModel
 */
export interface CustomerDetailsModel {
    /**
     * 
     * @type {CompanyTypeModel}
     * @memberof CustomerDetailsModel
     */
    companyType: CompanyTypeModel;
    /**
     * 
     * @type {CompanyModel}
     * @memberof CustomerDetailsModel
     */
    company?: CompanyModel;
    /**
     * 
     * @type {CustomerModel}
     * @memberof CustomerDetailsModel
     */
    customer?: CustomerModel;
    /**
     * 
     * @type {AddressModel}
     * @memberof CustomerDetailsModel
     */
    address: AddressModel;
    /**
     * Customer email address
     * @type {string}
     * @memberof CustomerDetailsModel
     */
    email?: string;
    /**
     * Customer Telephone Number
     * @type {string}
     * @memberof CustomerDetailsModel
     */
    telephoneNumber?: string;
    /**
     * The obligor risk rating associated to the customer
     * @type {number}
     * @memberof CustomerDetailsModel
     */
    obligorRiskRating?: number;
    /**
     * 
     * @type {BankDetailsModel}
     * @memberof CustomerDetailsModel
     */
    bankDetails: BankDetailsModel;
}

/**
 * Check if a given object implements the CustomerDetailsModel interface.
 */
export function instanceOfCustomerDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyType" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "bankDetails" in value;

    return isInstance;
}

export function CustomerDetailsModelFromJSON(json: any): CustomerDetailsModel {
    return CustomerDetailsModelFromJSONTyped(json, false);
}

export function CustomerDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyType': CompanyTypeModelFromJSON(json['companyType']),
        'company': !exists(json, 'company') ? undefined : CompanyModelFromJSON(json['company']),
        'customer': !exists(json, 'customer') ? undefined : CustomerModelFromJSON(json['customer']),
        'address': AddressModelFromJSON(json['address']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'telephoneNumber': !exists(json, 'telephoneNumber') ? undefined : json['telephoneNumber'],
        'obligorRiskRating': !exists(json, 'obligorRiskRating') ? undefined : json['obligorRiskRating'],
        'bankDetails': BankDetailsModelFromJSON(json['bankDetails']),
    };
}

export function CustomerDetailsModelToJSON(value?: CustomerDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyType': CompanyTypeModelToJSON(value.companyType),
        'company': CompanyModelToJSON(value.company),
        'customer': CustomerModelToJSON(value.customer),
        'address': AddressModelToJSON(value.address),
        'email': value.email,
        'telephoneNumber': value.telephoneNumber,
        'obligorRiskRating': value.obligorRiskRating,
        'bankDetails': BankDetailsModelToJSON(value.bankDetails),
    };
}

