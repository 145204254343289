/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentTemplateListItemModel
 */
export interface DocumentTemplateListItemModel {
    /**
     * The UUID of the template
     * @type {string}
     * @memberof DocumentTemplateListItemModel
     */
    id: string;
    /**
     * Flag denoting if the template has a free text field
     * @type {boolean}
     * @memberof DocumentTemplateListItemModel
     */
    hasFreeText: boolean;
    /**
     * The name of the template type
     * @type {string}
     * @memberof DocumentTemplateListItemModel
     */
    name: string;
    /**
     * Flag to denote if template has date range
     * @type {boolean}
     * @memberof DocumentTemplateListItemModel
     */
    allowDateRange: boolean;
}

/**
 * Check if a given object implements the DocumentTemplateListItemModel interface.
 */
export function instanceOfDocumentTemplateListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "hasFreeText" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "allowDateRange" in value;

    return isInstance;
}

export function DocumentTemplateListItemModelFromJSON(json: any): DocumentTemplateListItemModel {
    return DocumentTemplateListItemModelFromJSONTyped(json, false);
}

export function DocumentTemplateListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentTemplateListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'hasFreeText': json['hasFreeText'],
        'name': json['name'],
        'allowDateRange': json['allowDateRange'],
    };
}

export function DocumentTemplateListItemModelToJSON(value?: DocumentTemplateListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hasFreeText': value.hasFreeText,
        'name': value.name,
        'allowDateRange': value.allowDateRange,
    };
}

