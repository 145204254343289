import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  Box,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useGetAsset } from "api/assets/getAsset";
import { useListAssetSettlementQuotes } from "api/assets/settlement-quotes/listAssetSettlementQuotes";
import AssetSettlementQuoteDetailsContainer from "apps/servicing/containers/AssetSettlementQuoteDetailsContainer";
import CreateAssetSettlementQuoteContainer from "apps/servicing/containers/CreateAssetSettlementQuoteContainer/CreateAssetSettlementQuoteContainer";
import { LoadableTableHead, Loader } from "common/components";
import { useLocale } from "common/hooks";
import ListLayout from "common/layouts/ListLayout";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface AssetSettlementQuotesContainerProps {
  assetId: number;
}

export default function AssetSettlementQuotesContainer({
  assetId,
}: AssetSettlementQuotesContainerProps): ReactElement {
  const { t } = useTranslation("assets");
  const locale = useLocale();
  const format = useFormat();
  const assetSettlementQuotes = useListAssetSettlementQuotes(assetId);
  const asset = useGetAsset(assetId);

  const [currentSettlementQuoteId, setCurrentSettlementQuoteId] = useState<
    number | undefined
  >();

  const tableHeaders: string[] = [
    t("settlement_quotes.list_settlement_quotes.status_label"),
    t("settlement_quotes.list_settlement_quotes.settlement_date_label"),
    t("settlement_quotes.list_settlement_quotes.settlement_amount_label"),
    t("settlement_quotes.list_settlement_quotes.quoted_date_label"),
    t("settlement_quotes.list_settlement_quotes.quoted_by_label"),
    t("settlement_quotes.list_settlement_quotes.expiration_date_label"),
  ];

  const openDialog = (settlementQuoteId: number) => (): void => {
    setCurrentSettlementQuoteId(settlementQuoteId);
  };

  const closeDialog = (): void => {
    setCurrentSettlementQuoteId(undefined);
  };

  return (
    <Loader
      ready={Boolean(
        (assetSettlementQuotes.error || assetSettlementQuotes.data) &&
          (asset.error || asset.data),
      )}
      render={(): ReactElement => {
        if (assetSettlementQuotes.error || asset.error || !asset.data) {
          return (
            <Typography color="error" role="alert">
              {t("common:error.default")}
            </Typography>
          );
        }

        if (assetSettlementQuotes.data?.length === 0) {
          return (
            <Box textAlign="center">
              <Typography color="textSecondary" gutterBottom>
                {t("settlement_quotes.no_settlement_quotes")}
              </Typography>
              <CreateAssetSettlementQuoteContainer
                assetId={assetId}
                agreementId={asset.data.agreementId}
              />
            </Box>
          );
        }

        return (
          <>
            <ListLayout
              actions={
                <CreateAssetSettlementQuoteContainer
                  assetId={assetId}
                  agreementId={asset.data.agreementId}
                />
              }
            >
              <Table
                aria-label={t(
                  "settlement_quotes.list_settlement_quotes.table_name_label",
                )}
                size="small"
                stickyHeader
              >
                <LoadableTableHead
                  headings={tableHeaders}
                  loading={assetSettlementQuotes.isValidating}
                />

                <TableBody>
                  {assetSettlementQuotes.data?.map((assetSettlementQuote) => (
                    <TableRow key={assetSettlementQuote.id} hover>
                      <TableCell padding="none" width="12.5%">
                        <ListItemButton
                          dense
                          onClick={openDialog(assetSettlementQuote.id)}
                        >
                          <ListItemText
                            primaryTypographyProps={{ color: "primary" }}
                            primary={assetSettlementQuote.status}
                          />
                        </ListItemButton>
                      </TableCell>
                      <TableCell width="17.5%">
                        {assetSettlementQuote.settlementDate &&
                          locale.formatDate(
                            assetSettlementQuote.settlementDate,
                          )}
                      </TableCell>
                      <TableCell width="17.5%">
                        {format.formatAmount(assetSettlementQuote.amount)}
                      </TableCell>
                      <TableCell width="17.5%">
                        {assetSettlementQuote.quotedDate &&
                          locale.formatDate(assetSettlementQuote.quotedDate)}
                      </TableCell>
                      <TableCell width="17.5%">
                        {assetSettlementQuote.quotedBy}
                      </TableCell>
                      <TableCell width="17.5%">
                        {assetSettlementQuote.expiryDate &&
                          locale.formatDate(assetSettlementQuote.expiryDate)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ListLayout>

            {currentSettlementQuoteId && (
              <AssetSettlementQuoteDetailsContainer
                settlementQuoteId={currentSettlementQuoteId}
                onClose={closeDialog}
                open={Boolean(currentSettlementQuoteId)}
                assetId={assetId}
              />
            )}
          </>
        );
      }}
    />
  );
}
