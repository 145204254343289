/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AutomationAuditListItemModel,
  AutomationDetailsModel,
  AutomationListItemModel,
} from '../models/index';
import {
    AutomationAuditListItemModelFromJSON,
    AutomationAuditListItemModelToJSON,
    AutomationDetailsModelFromJSON,
    AutomationDetailsModelToJSON,
    AutomationListItemModelFromJSON,
    AutomationListItemModelToJSON,
} from '../models/index';

export interface GetAutomationRequest {
    automationId: number;
}

export interface ListAutomationAuditHistoryRequest {
    automationId: number;
}

export interface ListAutomationsRequest {
    portfolioId?: number;
}

/**
 * 
 */
export class AutomationQueryApi extends runtime.BaseAPI {

    /**
     * Retrieve automation details by id
     */
    async getAutomationRaw(requestParameters: GetAutomationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutomationDetailsModel>> {
        if (requestParameters.automationId === null || requestParameters.automationId === undefined) {
            throw new runtime.RequiredError('automationId','Required parameter requestParameters.automationId was null or undefined when calling getAutomation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["automations:view"]);
        }

        const response = await this.request({
            path: `/automations/{automationId}`.replace(`{${"automationId"}}`, encodeURIComponent(String(requestParameters.automationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutomationDetailsModelFromJSON(jsonValue));
    }

    /**
     * Retrieve automation details by id
     */
    async getAutomation(requestParameters: GetAutomationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutomationDetailsModel> {
        const response = await this.getAutomationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Automation Audit History
     */
    async listAutomationAuditHistoryRaw(requestParameters: ListAutomationAuditHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutomationAuditListItemModel>>> {
        if (requestParameters.automationId === null || requestParameters.automationId === undefined) {
            throw new runtime.RequiredError('automationId','Required parameter requestParameters.automationId was null or undefined when calling listAutomationAuditHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["automations:view"]);
        }

        const response = await this.request({
            path: `/automations/{automationId}/audit-history`.replace(`{${"automationId"}}`, encodeURIComponent(String(requestParameters.automationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutomationAuditListItemModelFromJSON));
    }

    /**
     * List Automation Audit History
     */
    async listAutomationAuditHistory(requestParameters: ListAutomationAuditHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutomationAuditListItemModel>> {
        const response = await this.listAutomationAuditHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Automations
     */
    async listAutomationsRaw(requestParameters: ListAutomationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutomationListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["automations:view"]);
        }

        const response = await this.request({
            path: `/automations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutomationListItemModelFromJSON));
    }

    /**
     * List Automations
     */
    async listAutomations(requestParameters: ListAutomationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutomationListItemModel>> {
        const response = await this.listAutomationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
