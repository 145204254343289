/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Portfolio Bank Accounts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortfolioBankAccountListItemModel
 */
export interface PortfolioBankAccountListItemModel {
    /**
     * Unique identifier of Portfolio Bank Account
     * @type {string}
     * @memberof PortfolioBankAccountListItemModel
     */
    systemId: string;
    /**
     * The portfolio bank account name
     * @type {string}
     * @memberof PortfolioBankAccountListItemModel
     */
    name: string;
    /**
     * The service user number
     * @type {string}
     * @memberof PortfolioBankAccountListItemModel
     */
    serviceUserNumber: string;
    /**
     * The portfolio bank account sort code
     * @type {string}
     * @memberof PortfolioBankAccountListItemModel
     */
    sortCode?: string;
    /**
     * The portfolio bank account number
     * @type {string}
     * @memberof PortfolioBankAccountListItemModel
     */
    accountNumber?: string;
    /**
     * The international bank account number
     * @type {string}
     * @memberof PortfolioBankAccountListItemModel
     */
    iban?: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof PortfolioBankAccountListItemModel
     */
    bic?: string;
}

/**
 * Check if a given object implements the PortfolioBankAccountListItemModel interface.
 */
export function instanceOfPortfolioBankAccountListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "systemId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "serviceUserNumber" in value;

    return isInstance;
}

export function PortfolioBankAccountListItemModelFromJSON(json: any): PortfolioBankAccountListItemModel {
    return PortfolioBankAccountListItemModelFromJSONTyped(json, false);
}

export function PortfolioBankAccountListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioBankAccountListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemId': json['systemId'],
        'name': json['name'],
        'serviceUserNumber': json['serviceUserNumber'],
        'sortCode': !exists(json, 'sortCode') ? undefined : json['sortCode'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
    };
}

export function PortfolioBankAccountListItemModelToJSON(value?: PortfolioBankAccountListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'systemId': value.systemId,
        'name': value.name,
        'serviceUserNumber': value.serviceUserNumber,
        'sortCode': value.sortCode,
        'accountNumber': value.accountNumber,
        'iban': value.iban,
        'bic': value.bic,
    };
}

