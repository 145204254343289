import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { Namespace, useTranslation } from "react-i18next";
import { createDisplayName } from "./createDisplayName";

const TranslationLoader = <N extends Namespace>({
  children,
  ns,
}: {
  children: React.ReactElement;
  ns?: N;
}) => {
  const { ready } = useTranslation(ns);

  if (!ready) {
    return (
      <Box display="flex" justifyContent="center" marginY={3}>
        <CircularProgress />
      </Box>
    );
  }

  return children;
};

export const withTranslationLoader =
  <N extends Namespace>(ns?: N) =>
  <P,>(WrappedComponent: React.ComponentType<P>) => {
    const WithTranslationLoader: React.FC<P & JSX.IntrinsicAttributes> = (
      props,
    ) => (
      <TranslationLoader ns={ns}>
        <WrappedComponent {...props} />
      </TranslationLoader>
    );

    WithTranslationLoader.displayName = createDisplayName(
      WrappedComponent,
      withTranslationLoader.name,
    );

    return WithTranslationLoader;
  };
