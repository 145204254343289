import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import AddFinancialProductContainer from "apps/onboarding/containers/AddFinancialProductContainer/AddFinancialProductContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

const AddFinancialProductView = () => {
  const { t } = useTranslation("onboardings");

  return (
    <ViewLayout
      title={t("add_financial_product.heading")}
      breadcrumbs={[
        {
          label: t("financial_products.financial_products_heading"),
          href: "../",
        },
      ]}
    >
      <ContainedLayout>
        <AddFinancialProductContainer />
      </ContainedLayout>
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: ["onboarding:financial-products:manage"],
})(AddFinancialProductView);
