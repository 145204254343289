import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  Box,
  Button,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useListSettlementQuotes } from "api/agreements/settlement-quotes";
import { LoadableTableHead, Loader } from "common/components";
import ModifiableToggle from "common/components/ModifiableToggle";
import { useLocale } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface AgreementSettlementQuotesContainerProps {
  agreementId: number;
}

export default function AgreementSettlementQuotesContainer({
  agreementId,
}: AgreementSettlementQuotesContainerProps): ReactElement {
  const { t } = useTranslation("agreements");
  const locale = useLocale();
  const format = useFormat();
  const appConfig = useAppConfiguration();
  const href = `${appConfig.appRoutes.servicing}/agreements/${agreementId}/settlement-quotes/apply-settlement-quote`;

  const settlementQuotes = useListSettlementQuotes(agreementId);

  const tableHeaders: string[] = [
    t("settlement_quotes.list_settlement_quotes.status_label"),
    t("settlement_quotes.list_settlement_quotes.settlement_date_label"),
    t("settlement_quotes.list_settlement_quotes.settlement_amount_label"),
    t("settlement_quotes.list_settlement_quotes.quoted_date_label"),
    t("settlement_quotes.list_settlement_quotes.quoted_by_label"),
    t("settlement_quotes.list_settlement_quotes.expiration_date_label"),
  ];

  return (
    <Loader
      ready={Boolean(settlementQuotes.error ?? settlementQuotes.data)}
      render={(): ReactElement => {
        if (settlementQuotes.error) {
          return (
            <Typography color="error" role="alert">
              {t("common:error.default")}
            </Typography>
          );
        }

        if (settlementQuotes.data?.length === 0) {
          return (
            <Box textAlign="center">
              <Typography color="textSecondary" gutterBottom>
                {t("settlement_quotes.no_settlement_quotes")}
              </Typography>
              <ModifiableToggle>
                <AccessControl
                  allowedPermissions={["servicing:settlement-quotes:manage"]}
                >
                  <Button
                    component={RouterLink}
                    to={href}
                    role="link"
                    variant="contained"
                  >
                    {t("settlement_quotes.create_quote.create_button")}
                  </Button>
                </AccessControl>
              </ModifiableToggle>
            </Box>
          );
        }

        return (
          <ListLayout>
            <Table
              aria-label={t(
                "settlement_quotes.list_settlement_quotes.table_name_label",
              )}
              size="small"
              stickyHeader
            >
              <LoadableTableHead
                headings={tableHeaders}
                loading={settlementQuotes.isValidating}
              />

              <TableBody>
                {settlementQuotes.data?.map((settlementQuote) => (
                  <TableRow key={settlementQuote.id} hover>
                    <TableCell padding="none" width="12.5%">
                      <ListItemButton
                        dense
                        component={RouterLink}
                        to={`${appConfig.appRoutes.servicing}/agreements/${agreementId}/settlement-quotes/${settlementQuote.id}`}
                      >
                        <ListItemText
                          primaryTypographyProps={{ color: "primary" }}
                          primary={settlementQuote.status}
                        />
                      </ListItemButton>
                    </TableCell>
                    <TableCell width="17.5%">
                      {settlementQuote.settlementDate &&
                        locale.formatDate(settlementQuote.settlementDate)}
                    </TableCell>
                    <TableCell width="17.5%">
                      {format.formatAmount(settlementQuote.amount)}
                    </TableCell>
                    <TableCell width="17.5%">
                      {settlementQuote.quotedDate &&
                        locale.formatDate(settlementQuote.quotedDate)}
                    </TableCell>
                    <TableCell width="17.5%">
                      {settlementQuote.quotedBy}
                    </TableCell>
                    <TableCell width="17.5%">
                      {settlementQuote.expiryDate &&
                        locale.formatDate(settlementQuote.expiryDate)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ListLayout>
        );
      }}
    />
  );
}
