import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { MoreVert } from "@mui/icons-material";
import {
  IconButton,
  LinearProgress,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import ChangePaymentDateContainer from "apps/servicing/modules/agreements/containers/ChangePaymentDateContainer";
import CreateInvoiceContainer from "apps/servicing/modules/agreements/containers/CreateInvoiceContainer/CreateInvoiceContainer";
import PaymentHolidayContainer from "apps/servicing/modules/agreements/containers/PaymentHolidayContainer";
import DevToggle from "common/components/DevToggle/DevToggle";
import ModifiableToggle from "common/components/ModifiableToggle";
import { useLocale } from "common/hooks";
import { useAgreement } from "common/providers";
import { isAfter } from "date-fns";
import {
  InstalmentListItemModel,
  PaymentStructureModel,
  ScheduleModel,
} from "generated/core/models";
import { ProductTypeModel } from "generated/onboarding/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface RepaymentScheduleMenuOptions {
  changePaymentDate: boolean;
}

interface RepaymentScheduleListProps {
  schedule: ScheduleModel;
  loading: boolean;
}

interface RepaymentScheduleMenuProps {
  instalments: InstalmentListItemModel[];
  selectedInstalment: InstalmentListItemModel;
  options: RepaymentScheduleMenuOptions;
}

function RepaymentScheduleMenu({
  instalments,
  selectedInstalment,
  options,
}: RepaymentScheduleMenuProps): ReactElement {
  const { t } = useTranslation("agreements");
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuElement);
  const agreement = useAgreement();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuElement(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setMenuElement(null);
  };

  return (
    <ModifiableToggle>
      <>
        {(options.changePaymentDate ||
          selectedInstalment.allowCreateInvoice) && (
          <>
            <IconButton
              aria-label={t("repayment_schedule.menu_button")}
              color="primary"
              onClick={handleMenuClick}
              size="small"
            >
              <MoreVert fontSize="inherit" />
            </IconButton>

            <Menu
              open={menuOpen}
              anchorEl={menuElement}
              onClose={handleMenuClose}
            >
              {options.changePaymentDate && (
                <ChangePaymentDateContainer
                  selectedInstalment={selectedInstalment}
                  instalments={instalments}
                  closeMenu={handleMenuClose}
                />
              )}

              {selectedInstalment.allowCreateInvoice && (
                <CreateInvoiceContainer
                  agreementNumber={agreement.id}
                  selectedInstalment={selectedInstalment}
                />
              )}

              <DevToggle feature="dev">
                <>
                  {agreement.data?.paymentStructure ===
                    PaymentStructureModel.Regular &&
                    agreement.data.productName !==
                      ProductTypeModel.FixedRateOperatingLease && (
                      <PaymentHolidayContainer
                        instalmentNumber={selectedInstalment.instalmentNumber}
                        selectedDate={selectedInstalment.dueDate}
                      />
                    )}
                </>
              </DevToggle>
            </Menu>
          </>
        )}
      </>
    </ModifiableToggle>
  );
}

const increment = () => {
  let n = -1;
  return () => {
    n = n + 1;
    return n;
  };
};

export default function RepaymentScheduleList({
  schedule,
  loading,
}: RepaymentScheduleListProps): ReactElement {
  const { t } = useTranslation("agreements");
  const locale = useLocale();
  const { formatAmount } = useFormat();

  const tableHeadings: [string, TableCellProps["align"]][] = [
    [t("instalments.number"), "right"],
    [t("instalments.due_date"), "left"],
    [t("instalments.invoice_number"), "left"],
    [t("instalments.capital"), "right"],
    [t("instalments.interest"), "right"],
    [t("instalments.vat"), "right"],
    [t("instalments.repayment"), "right"],
    [t("instalments.fees"), "right"],
    [t("instalments.capital_balance"), "right"],
    ["", "right"],
  ];

  const renderTableHeading = ([heading, alignment]: [
    string,
    TableCellProps["align"],
  ]): ReactElement => (
    <TableCell key={heading} align={alignment}>
      {heading}
    </TableCell>
  );

  const getNumberOfValidInstalments = increment();

  const validInstalmentForChange = schedule.instalments.map(
    (instalment) =>
      isAfter(instalment.dueDate, new Date()) &&
      Number(instalment.instalment) > 0 &&
      getNumberOfValidInstalments() < 2 &&
      instalment.instalmentNumber,
  );

  return (
    <Table
      size="small"
      stickyHeader
      aria-label={t("repayment_schedule.table_label")}
    >
      <TableHead>
        <TableRow>{tableHeadings.map(renderTableHeading)}</TableRow>
        {loading && (
          <TableRow>
            <TableCell colSpan={tableHeadings.length} padding="none">
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {schedule.instalments.map((instalment) => (
          <TableRow key={instalment.instalmentNumber} hover>
            <TableCell align="right">{instalment.instalmentNumber}</TableCell>
            <TableCell>
              {instalment.dueDate && locale.formatDate(instalment.dueDate)}
            </TableCell>
            <TableCell align="left">{instalment.invoiceReference}</TableCell>
            <TableCell align="right">
              {formatAmount(instalment.capital)}
            </TableCell>
            <TableCell align="right">
              {formatAmount(instalment.interest)}
            </TableCell>
            <TableCell align="right">{formatAmount(instalment.vat)}</TableCell>
            <TableCell align="right">
              {formatAmount(instalment.instalment)}
            </TableCell>
            <TableCell align="right">{formatAmount(instalment.fees)}</TableCell>
            <TableCell align="right">
              {formatAmount(instalment.capitalOutstanding)}
            </TableCell>

            <TableCell sx={{ paddingY: 0.25 }} align="right">
              <AccessControl
                allowedPermissions={["servicing:repayment-schedule:manage"]}
              >
                <RepaymentScheduleMenu
                  instalments={schedule.instalments}
                  selectedInstalment={instalment}
                  options={{
                    changePaymentDate: validInstalmentForChange.includes(
                      instalment.instalmentNumber,
                    ),
                  }}
                />
              </AccessControl>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter
        sx={(theme): SystemStyleObject => ({
          "& > tr": {
            "& > td, & > th": {
              height: "35px",
              backgroundColor: theme.palette.background.paper,
              borderTop: `1px solid ${theme.palette.divider}`,
              position: "sticky",
              bottom: 0,
            },
          },
        })}
      >
        <TableRow>
          <TableCell colSpan={2} align="right" component="th" scope="row">
            {t("instalments.totals")}
          </TableCell>
          <TableCell></TableCell>
          <TableCell align="right">
            {formatAmount(schedule.totalCapital)}
          </TableCell>
          <TableCell align="right">
            {formatAmount(schedule.totalInterest)}
          </TableCell>
          <TableCell align="right">{formatAmount(schedule.totalVat)}</TableCell>
          <TableCell align="right">
            {formatAmount(schedule.totalInstalmentAmount)}
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>
      </TableFooter>
    </Table>
  );
}
