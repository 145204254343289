import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import CreateRoleContainer from "apps/admin/containers/CreateRoleContainer/CreateRoleContainer";
import ListRolesContainer from "apps/admin/containers/ListRolesContainer/ListRolesContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout/ViewLayout";
import { useTranslation } from "react-i18next";

const RolesView = () => {
  const { t } = useTranslation("admin");

  return (
    <ViewLayout
      action={<CreateRoleContainer />}
      title={t("roles.roles_heading")}
    >
      <ContainedLayout>
        <ListRolesContainer />
      </ContainedLayout>
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: ["admin:roles:manage"],
})(RolesView);
