/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing termination within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateTerminationRecoveryDetailsModel
 */
export interface UpdateTerminationRecoveryDetailsModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof UpdateTerminationRecoveryDetailsModel
     */
    recoveryCosts?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof UpdateTerminationRecoveryDetailsModel
     */
    customerPayments?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof UpdateTerminationRecoveryDetailsModel
     */
    guarantors?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof UpdateTerminationRecoveryDetailsModel
     */
    saleOfAsset?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof UpdateTerminationRecoveryDetailsModel
     */
    buyBack?: string;
}

/**
 * Check if a given object implements the UpdateTerminationRecoveryDetailsModel interface.
 */
export function instanceOfUpdateTerminationRecoveryDetailsModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateTerminationRecoveryDetailsModelFromJSON(json: any): UpdateTerminationRecoveryDetailsModel {
    return UpdateTerminationRecoveryDetailsModelFromJSONTyped(json, false);
}

export function UpdateTerminationRecoveryDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTerminationRecoveryDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recoveryCosts': !exists(json, 'recoveryCosts') ? undefined : json['recoveryCosts'],
        'customerPayments': !exists(json, 'customerPayments') ? undefined : json['customerPayments'],
        'guarantors': !exists(json, 'guarantors') ? undefined : json['guarantors'],
        'saleOfAsset': !exists(json, 'saleOfAsset') ? undefined : json['saleOfAsset'],
        'buyBack': !exists(json, 'buyBack') ? undefined : json['buyBack'],
    };
}

export function UpdateTerminationRecoveryDetailsModelToJSON(value?: UpdateTerminationRecoveryDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recoveryCosts': value.recoveryCosts,
        'customerPayments': value.customerPayments,
        'guarantors': value.guarantors,
        'saleOfAsset': value.saleOfAsset,
        'buyBack': value.buyBack,
    };
}

