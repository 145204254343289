import { MenuItem } from "@mui/material";
import {
  DateRangeControl,
  PortfolioReportFormDialog,
} from "apps/reporting/components";
import {
  usePortfolioDateReportResolver,
  useReportDownload,
} from "apps/reporting/hooks";
import { PortfolioDateReportDialogFormValues } from "apps/reporting/types";
import { ControlledTextField } from "common/components";
import { useApi } from "common/providers";
import { ApiResponse } from "generated";
import {
  PortfolioQueryApi,
  ReceiptsFilterByEnum,
  ReportQueryApi,
} from "generated/core/apis";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import startOfYesterdayUTC from "support/form-helpers/start-of-yesterday-utc";
import { Control } from "support/react-hook-form";
import useSWR from "swr";

interface ReceiptsReportContainerProps {
  onClose(): void;
  open: boolean;
}

interface FilterByDropdownProps {
  control: Control<PortfolioDateReportDialogFormValues>;
  disabled: boolean;
}

export default function ReceiptsReportContainer({
  onClose,
  open,
}: ReceiptsReportContainerProps): ReactElement {
  const { t } = useTranslation("reporting");
  const resolver = usePortfolioDateReportResolver().BeforeTodayResolver();
  const portfolioQueryApi = useApi(PortfolioQueryApi);
  const reportApi = useApi(ReportQueryApi);
  const portfolios = useSWR(["portfolios"], () =>
    portfolioQueryApi.listPortfolios(),
  );

  const receiptsReportApi = (
    data: PortfolioDateReportDialogFormValues,
  ): Promise<ApiResponse<string>> =>
    reportApi.receiptsRaw({
      portfolioId:
        data.portfolio === "All" ? undefined : Number(data.portfolio),
      filterBy: data.filterBy,
      from: new Date(data.fromDate),
      to: new Date(data.toDate),
    });

  const receiptsReportDownload = useReportDownload(receiptsReportApi, {
    onSuccess: onClose,
  });

  const onSubmit = async (
    data: PortfolioDateReportDialogFormValues,
  ): Promise<void> => receiptsReportDownload.download(data);

  return (
    <PortfolioReportFormDialog<PortfolioDateReportDialogFormValues>
      defaultValues={{
        portfolio: "",
        filterBy: ReceiptsFilterByEnum.TransactionDate,
        fromDate: startOfYesterdayUTC(),
        toDate: startOfYesterdayUTC(),
      }}
      onClose={onClose}
      onSubmit={onSubmit}
      error={receiptsReportDownload.error?.message}
      open={open}
      portfolios={[
        { label: t("report_dialog.all_portfolio_option"), value: "All" },
        ...(portfolios.data || []).map((portfolio) => ({
          label: portfolio.name,
          value: String(portfolio.id),
        })),
      ]}
      ready={Boolean(portfolios.data) && !receiptsReportDownload.isLoading}
      resolver={resolver}
      title={t("report_dialog.receipts.title")}
    >
      {(form): ReactElement => (
        <>
          <FilterByDropdown
            control={form.control}
            disabled={form.formState.isSubmitting}
          />
          <DateRangeControl
            errors={form.formState.errors}
            register={form.register}
          />
        </>
      )}
    </PortfolioReportFormDialog>
  );
}

// Component to render the Filter By dropdown
function FilterByDropdown({
  control,
  disabled,
}: Readonly<FilterByDropdownProps>) {
  const { t } = useTranslation("reporting");

  return (
    <ControlledTextField
      helperText=""
      SelectProps={{ displayEmpty: true }}
      error=""
      control={control}
      id="filterBy"
      name="filterBy"
      label={t("report_dialog.filter_by_label")}
      select
      disabled={disabled}
    >
      <MenuItem
        key={ReceiptsFilterByEnum.TransactionDate}
        value={ReceiptsFilterByEnum.TransactionDate}
      >
        {t("report_dialog.transaction_date")}
      </MenuItem>
      <MenuItem
        key={ReceiptsFilterByEnum.PostedDate}
        value={ReceiptsFilterByEnum.PostedDate}
      >
        {t("report_dialog.posted_date")}
      </MenuItem>
    </ControlledTextField>
  );
}
