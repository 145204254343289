import { Form, NumberField } from "@ldms/mui-sdk/forms";
import { ConfirmationDialog } from "@ldms/mui-sdk/templates";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useUpdateCompany } from "api/companies";
import { useListVatCodesV2 } from "api/vat-codes/listVatCodes";
import { UpdateCompanyFieldValues } from "apps/admin/types";
import { ControlledTextField, Loader } from "common/components";
import { useConfirm } from "common/hooks";
import useYupResolver from "common/hooks/useYupResolver";
import { TaxCodeListItemModel } from "generated/servicing-v2/models";
import { UpdateCompanyModel } from "generated/servicing-v2/models/UpdateCompanyModel";
import { omit } from "lodash";
import { ReactElement } from "react";
import {
  Control,
  FieldErrors,
  Resolver,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const companyLabel = "companies.edit_company.company_name_label";
const addressLine1Label = "companies.edit_company.address_line_1_label";
const postcodeLabel = "companies.edit_company.postcode_label";
const vatCodeLabel = "companies.edit_company.vat_code_label";

interface UpdateCompanyContainerProps {
  companyId: string;
  defaultValues: UpdateCompanyFieldValues;
}

interface UpdateCompanyFormControlsProps {
  errors: FieldErrors<UpdateCompanyFieldValues>;
  register: UseFormRegister<UpdateCompanyFieldValues>;
  control: Control<UpdateCompanyFieldValues>;
  vatCodes?: TaxCodeListItemModel[];
}

interface UpdateCompanyDialogProps {
  vatCodes: TaxCodeListItemModel[];
  defaultValues: UpdateCompanyFieldValues;
  companyId: string;
}

const useUpdateCompanyResolver = (): Resolver<UpdateCompanyFieldValues> => {
  const { t } = useTranslation("servicing");

  const originationCommissionLabel =
    "companies.edit_company.origination_commission_label";

  return useYupResolver<UpdateCompanyFieldValues>((yup) =>
    yup.object().shape({
      name: yup
        .string()
        .isRequired(t(companyLabel))
        .maxCharacters(100, t(companyLabel)),
      caisSourceCode: yup
        .string()
        .maxCharacters(50, t("companies.edit_company.cais_source_code_label")),
      addressLine1: yup
        .string()
        .maxCharacters(50, t(addressLine1Label))
        .isRequired(t(addressLine1Label)),
      addressLine2: yup
        .string()
        .maxCharacters(50, t("companies.edit_company.address_line_2_label")),
      addressLine3: yup
        .string()
        .maxCharacters(50, t("companies.edit_company.address_line_3_label")),
      addressLine4: yup
        .string()
        .maxCharacters(50, t("companies.edit_company.address_line_4_label")),
      postcode: yup
        .string()
        .maxCharacters(10, t(postcodeLabel))
        .isRequired(t(postcodeLabel)),
      registrationNumber: yup
        .string()
        .maxCharacters(
          10,
          t("companies.edit_company.registration_number_label"),
        ),
      vatNumber: yup
        .string()
        .maxCharacters(15, t("companies.edit_company.vat_number_label")),
      vatCode: yup.string().isRequired(t(vatCodeLabel)),
      originationCommissionPercentage: yup
        .number(t(originationCommissionLabel))
        .nullable()
        .minAmount(0, t(originationCommissionLabel))
        .maxAmount(100.0, t(originationCommissionLabel)),
    }),
  );
};

function UpdateCompanyFormControls({
  errors,
  register,
  control,
  vatCodes,
}: UpdateCompanyFormControlsProps): ReactElement {
  const { t } = useTranslation("servicing");

  return (
    <>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={12}>
          <TextField
            {...register("name")}
            label={t(companyLabel)}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            required
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            label={t("companies.edit_company.registration_number_label")}
            {...register("registrationNumber")}
            helperText={errors.registrationNumber?.message}
            error={Boolean(errors.registrationNumber)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("vatNumber")}
            error={Boolean(errors.vatNumber)}
            label={t("companies.edit_company.vat_number_label")}
            helperText={errors.vatNumber?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("currency", { disabled: true })}
            label={t("companies.edit_company.currency_label")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("caisSourceCode")}
            label={t("companies.edit_company.cais_source_code_label")}
            helperText={errors.caisSourceCode?.message}
            error={Boolean(errors.caisSourceCode)}
          />
        </Grid>
        <Grid item sm={6}>
          <ControlledTextField
            id="vatCode"
            name="vatCode"
            label={t(vatCodeLabel)}
            helperText={errors.vatCode?.message}
            error={errors.vatCode?.message}
            SelectProps={{ displayEmpty: true }}
            control={control}
            select
            required
          >
            <MenuItem value="">
              <i>{t("common:please_select")}</i>
            </MenuItem>

            {vatCodes?.map((vatCode) => (
              <MenuItem key={vatCode.systemId} value={vatCode.systemId}>
                {vatCode.name}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item sm={6}>
          <NumberField
            control={control}
            label={t("companies.edit_company.origination_commission_label")}
            name="originationCommissionPercentage"
            helperText={errors.originationCommissionPercentage?.message}
            error={Boolean(errors.originationCommissionPercentage?.message)}
          />
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Divider flexItem variant="fullWidth" />
      </Grid>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine1")}
            label={t(addressLine1Label)}
            helperText={errors.addressLine1?.message}
            required
            error={Boolean(errors.addressLine1)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine2")}
            error={Boolean(errors.addressLine2)}
            helperText={errors.addressLine2?.message}
            label={t("companies.edit_company.address_line_2_label")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            label={t("companies.edit_company.address_line_3_label")}
            {...register("addressLine3")}
            helperText={errors.addressLine3?.message}
            error={Boolean(errors.addressLine3)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine4")}
            label={t("companies.edit_company.address_line_4_label")}
            error={Boolean(errors.addressLine4)}
            helperText={errors.addressLine4?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("postcode")}
            label={t(postcodeLabel)}
            error={Boolean(errors.postcode)}
            helperText={errors.postcode?.message}
            required
          />
        </Grid>
      </Grid>
    </>
  );
}

function UpdateCompanyDialog({
  vatCodes,
  defaultValues,
  companyId,
}: UpdateCompanyDialogProps): ReactElement {
  const { t } = useTranslation("servicing");
  const resolver = useUpdateCompanyResolver();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const onSuccess = () => {
    navigate("..");
  };

  const updateCompany = useUpdateCompany(companyId, { onSuccess });

  const onSubmit = (data: UpdateCompanyFieldValues) => {
    confirm.handlePrompt(async () => {
      const updateCompanyModel: UpdateCompanyModel = {
        ...omit(data, "vatCode", "vatNumber"),
        taxCode: data.vatCode,
        taxRegistrationNumber: data.vatNumber,
      };
      await updateCompany.command(updateCompanyModel);
    });
  };

  return (
    <Form
      label={t("companies.edit_company.title_label")}
      onSubmit={onSubmit}
      resolver={resolver}
      defaultValues={defaultValues}
    >
      {(form): ReactElement => {
        return (
          <>
            <ConfirmationDialog
              content={
                <Typography>
                  {t("companies.edit_company.confirmation_message")}
                </Typography>
              }
              open={confirm.isOpen}
              onConfirm={confirm.handleConfirm}
              onReject={confirm.handleReject}
              title={t("companies.edit_company.title_label")}
              labels={{
                confirm: t("common:yes"),
                reject: t("common:no"),
              }}
            />

            <Container maxWidth="sm">
              <Paper>
                <Stack gap={2} padding={2}>
                  <UpdateCompanyFormControls
                    errors={form.formState.errors}
                    register={form.register}
                    control={form.control}
                    vatCodes={vatCodes}
                  />
                  <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Button
                      role="link"
                      component={RouterLink}
                      color="primary"
                      to=".."
                    >
                      {t("common:cancel")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={updateCompany.isLoading}
                    >
                      {t("companies.edit_company.submit_button")}
                    </Button>
                  </Box>
                </Stack>
                {updateCompany.error && (
                  <Box>
                    <Typography color="error" data-testid="form.error">
                      {updateCompany.error.message}
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Container>
          </>
        );
      }}
    </Form>
  );
}

export default function UpdateCompanyContainer({
  companyId,
  defaultValues,
}: UpdateCompanyContainerProps): ReactElement {
  const { t } = useTranslation("servicing");
  const vatCodes = useListVatCodesV2();

  return (
    <Loader
      ready={Boolean(defaultValues && (vatCodes.data || vatCodes.error))}
      render={(): ReactElement => {
        if (vatCodes.error || !vatCodes.data) {
          return (
            <Typography color="error" data-testid="company.error">
              {t("common:error.default")}
            </Typography>
          );
        }

        return (
          <UpdateCompanyDialog
            defaultValues={defaultValues}
            vatCodes={vatCodes.data}
            companyId={companyId}
          />
        );
      }}
    />
  );
}
