import { Typography } from "@mui/material";
import { AppError, Loader } from "common/components";
import { useAgreement } from "common/providers";
import { ProductTypeModel } from "generated/onboarding/models";
import { useTranslation } from "react-i18next";
import { createDisplayName } from "sdk/views/createDisplayName";

const ProtectView: React.FC<{
  children: React.ReactNode | React.ReactNode[];
}> = ({ children }) => {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  return (
    <Loader
      ready={Boolean(agreement.data ?? agreement.error)}
      render={() => {
        if (agreement.error || !agreement.data) {
          return (
            <Typography color="error" role="alert">
              {t("common:error.default")}
            </Typography>
          );
        }

        if (
          agreement.data?.productName ===
          ProductTypeModel.FixedRateOperatingLease
        ) {
          return (
            <AppError
              title={t("settlement_quotes.no_settlement_quote_title")}
              message={t("settlement_quotes.no_settlement_quote_message")}
            />
          );
        }

        return <>{children}</>;
      }}
    />
  );
};

export const withSettlementQuote = <P,>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const WithSettlementQuote: React.FC<P & JSX.IntrinsicAttributes> = (
    props,
  ) => (
    <ProtectView>
      <WrappedComponent {...props} />
    </ProtectView>
  );

  WithSettlementQuote.displayName = createDisplayName(
    WrappedComponent,
    withSettlementQuote.name,
  );

  return WithSettlementQuote;
};
