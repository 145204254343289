/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (charge types)
 * API for managing Charge Types within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SubTypeModel = {
    Charge: 'Charge',
    Discount: 'Discount'
} as const;
export type SubTypeModel = typeof SubTypeModel[keyof typeof SubTypeModel];


export function SubTypeModelFromJSON(json: any): SubTypeModel {
    return SubTypeModelFromJSONTyped(json, false);
}

export function SubTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubTypeModel {
    return json as SubTypeModel;
}

export function SubTypeModelToJSON(value?: SubTypeModel | null): any {
    return value as any;
}

