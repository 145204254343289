import { QueryKey } from "api";
import { keys } from "api/customers";

export const all = (clientId: number): QueryKey => [
  ...keys.detail(clientId),
  "assets",
];

export const list = (
  clientId: number,
  params?: { live?: boolean },
): QueryKey => [...all(clientId), "list", ...(params ? [params?.live] : [])];
