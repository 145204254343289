import { AmountField, DateField } from "@ldms/mui-sdk/forms";
import { Loader } from "@ldms/mui-sdk/templates";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import * as transactionsKeys from "api/agreements/transactions/keys";
import ChargeList, {
  ChargeType,
} from "apps/servicing/modules/agreements/components/ChargeList";
import { useLocale, useResponseError, useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { useAgreement } from "common/providers/AgreementProvider";
import { AgreementCommandApi, AgreementQueryApi } from "generated/core/apis";
import {
  ChargeModel,
  ChargeTypeModel,
  ChargeTypeModelPayeeDetailsEnum,
} from "generated/core/models";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import errorHandler, { ErrorLike } from "support/error-handler";
import formatAmount from "support/format-amount";
import useSWR, { useSWRConfig } from "swr";

interface PostChargeProps {
  onClose(): void;
  open: boolean;
}

interface PostChargeFormValues {
  code: string;
  amount: number;
  dateDue: Date;
  comments: string;
  payee?: string;
}

export default function PostCharge({
  onClose,
  open,
}: PostChargeProps): ReactElement {
  const { mutate } = useSWRConfig();
  const agreement = useAgreement();
  const [selectedChargeType, setSelectedChargeType] = useState<ChargeType>();
  const { t } = useTranslation(["agreements", "common"]);
  const locale = useLocale();

  const agreementQueryApi = useApi(AgreementQueryApi);
  const agreementCommandApi = useApi(AgreementCommandApi);
  const chargeTypes = useSWR<ChargeTypeModel[], ErrorLike>(
    open ? "/agreement/transactions/chargetypes" : null,
    () => agreementQueryApi.listChargeTypes(),
  );

  const error = useResponseError([
    [
      "due_date_before_dd_window",
      t("transactions.post_charge.due_date_before_dd_submission"),
    ],
  ]);

  const handleClose = () => {
    error.reset();
    onClose();
  };

  const onPostChargeSubmit = async (
    data: PostChargeFormValues,
  ): Promise<void> => {
    try {
      const chargeModel: ChargeModel = {
        code: selectedChargeType?.code || "",
        amount: formatAmount(Number(data.amount)),
        dateDue: data.dateDue,
        comments: data.comments,
        payee: data.payee,
      };
      await agreementCommandApi.postCharge({
        agreementId: agreement.id,
        chargeModel,
      });
      handleClose();
      mutate(transactionsKeys.list(agreement.id));
      agreement.mutate();
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    }
  };

  const onSelectChargeType = (chargeType: ChargeType): void => {
    setSelectedChargeType(chargeType);
    form.setValue("amount", Number(chargeType.amount));
    form.setValue("payee", chargeType.defaultPayee);
  };

  const commentsLabel = t("transactions.post_charge.comments_label");
  const dateDueLabel = t("transactions.post_charge.date_due_label");
  const amountLabel = t("transactions.post_charge.amount_label");
  const payeeLabel = t("transactions.post_charge.payee_label");

  const resolver = useYupResolver<PostChargeFormValues>((yup) =>
    yup.object().shape({
      comments: yup
        .string()
        .isRequired(commentsLabel)
        .maxCharacters(250, commentsLabel),
      dateDue: yup
        .date()
        .localDate()
        .isValidDate(dateDueLabel)
        .isRequired(dateDueLabel),
      amount: yup
        .number(amountLabel)
        .moreThan(
          0,
          t("common:validation.is_greater_than_zero", {
            label: amountLabel,
          }),
        )
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .isRequired(amountLabel)
        .maxAmount(99999999.99, amountLabel),
      payee:
        selectedChargeType?.payeeDetails ===
        ChargeTypeModelPayeeDetailsEnum.Required
          ? yup.string().isRequired(payeeLabel).maxCharacters(50, payeeLabel)
          : yup.string().maxCharacters(50, payeeLabel).optional(),
    }),
  );

  const { reset, ...form } = useForm<PostChargeFormValues>({
    defaultValues: {
      dateDue: new Date(locale.formatISODate(new Date())),
    },
    resolver,
  });

  useEffect(() => {
    setSelectedChargeType(undefined);
    reset();
  }, [open, reset]);

  const renderPostCharge = (): ReactElement =>
    chargeTypes.error ? (
      <Typography color="error">{t("common:error.default")}</Typography>
    ) : (
      <Box display="flex" flexDirection="column" marginBottom={2}>
        <Box maxHeight={300} overflow="auto">
          <ChargeList
            data={chargeTypes.data}
            loading={chargeTypes.isValidating}
            selected={selectedChargeType?.code || ""}
            onSelectChargeType={onSelectChargeType}
          />
        </Box>
      </Box>
    );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="post-charge-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
      scroll="paper"
    >
      <form
        aria-labelledby="post-charge-dialog-title"
        onSubmit={form.handleSubmit(onPostChargeSubmit)}
      >
        <DialogTitle id="post-charge-dialog-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              {t("transactions.post_charge.post_charge_dialog_title")}
            </Box>
            <Box>
              <IconButton
                aria-label={t("common:close")}
                onClick={handleClose}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Loader
            ready={Boolean(chargeTypes.data || chargeTypes.error)}
            render={renderPostCharge}
          />
          <TextField
            {...form.register("comments")}
            helperText={form.formState.errors.comments?.message}
            error={Boolean(form.formState.errors.comments)}
            label={t("transactions.post_charge.comments_label")}
          />
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <DateField
                control={form.control}
                name="dateDue"
                error={Boolean(form.formState.errors?.dateDue?.message)}
                helperText={form.formState.errors?.dateDue?.message}
                label={t("transactions.post_charge.date_due_label")}
              />
            </Grid>
            <Grid item sm={6}>
              <AmountField
                error={Boolean(form.formState.errors?.amount?.message)}
                helperText={form.formState.errors?.amount?.message}
                label={t("transactions.post_charge.amount_label")}
                control={form.control}
                name="amount"
              />
            </Grid>
          </Grid>
          <TextField
            {...form.register("payee", {
              disabled:
                selectedChargeType?.payeeDetails ===
                ChargeTypeModelPayeeDetailsEnum.NotApplicable,
            })}
            helperText={form.formState.errors.payee?.message}
            error={Boolean(form.formState.errors.payee)}
            label={t("transactions.post_charge.payee_label")}
          />
          {error.message && (
            <Typography color="error" data-testid="form.error">
              {error.message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            {t("common:cancel")}
          </Button>
          <Button
            type="submit"
            disabled={!selectedChargeType || form.formState.isSubmitting}
            color="primary"
            variant="contained"
          >
            {t("common:save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
