import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { AgreementSettlementQuoteModel } from "generated/core/models/AgreementSettlementQuoteModel";
import { ErrorLike } from "support/error-handler";
import { keys } from ".";

type UseGetSettlementQuoteDetails = (
  agreementId: number,
) => UseQueryReturn<AgreementSettlementQuoteModel, ErrorLike>;

export const useGetSettlementQuoteDetails: UseGetSettlementQuoteDetails = (
  agreementId: number,
) => {
  const api = useApi(AgreementQueryApi);

  return useQuery(keys.detail(agreementId), () =>
    api.getAgreementSettlementQuoteDetails({ agreementId: agreementId }),
  );
};
