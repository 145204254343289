/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerificationLetterFilterModel
 */
export interface VerificationLetterFilterModel {
    /**
     * ISO8601 date without time
     * @type {Date}
     * @memberof VerificationLetterFilterModel
     */
    fromDate?: Date;
    /**
     * ISO8601 date without time
     * @type {Date}
     * @memberof VerificationLetterFilterModel
     */
    toDate?: Date;
    /**
     * The type of document generated
     * @type {string}
     * @memberof VerificationLetterFilterModel
     */
    type?: string;
}

/**
 * Check if a given object implements the VerificationLetterFilterModel interface.
 */
export function instanceOfVerificationLetterFilterModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VerificationLetterFilterModelFromJSON(json: any): VerificationLetterFilterModel {
    return VerificationLetterFilterModelFromJSONTyped(json, false);
}

export function VerificationLetterFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerificationLetterFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromDate': !exists(json, 'fromDate') ? undefined : (new Date(json['fromDate'])),
        'toDate': !exists(json, 'toDate') ? undefined : (new Date(json['toDate'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function VerificationLetterFilterModelToJSON(value?: VerificationLetterFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fromDate': value.fromDate === undefined ? undefined : (value.fromDate.toISOString().substr(0,10)),
        'toDate': value.toDate === undefined ? undefined : (value.toDate.toISOString().substr(0,10)),
        'type': value.type,
    };
}

