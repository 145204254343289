import { PagedQuery, useQueryPaged } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { FinancialPostingQueryApi } from "generated/core/apis";
import { FinancialPostingListItemModel } from "generated/core/models";
import { keys } from ".";

export const useListFinancialPostings: PagedQuery<
  FinancialPostingListItemModel,
  {
    agreementId?: number;
    portfolioId?: number;
    accountCode?: string;
    sourceCode?: string;
    stageCode?: string;
    from?: Date;
    to?: Date;
  }
> = ({ pageSize, params = {} } = {}) => {
  const api = useApi(FinancialPostingQueryApi);

  return useQueryPaged(
    keys.list({
      agreementId: params.agreementId,
      portfolioId: params.portfolioId,
      accountCode: params.accountCode,
      sourceCode: params.sourceCode,
      stageCode: params.stageCode,
      from: params.from,
      to: params.to,
    }),
    async (_key, paging) =>
      api.listFinancialPostingsRaw({
        agreementId: params.agreementId,
        portfolioId: params.portfolioId,
        accountCode: params.accountCode,
        sourceCode: params.sourceCode,
        stageCode: params.stageCode,
        from: params.from,
        to: params.to,
        limit: paging.pageSize,
        offset: paging.offset,
      }),
    { limit: pageSize },
  );
};
