import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { ContainedLayout } from "@ldms/mui-sdk/layouts";
import AddTransactionContainer from "apps/servicing/modules/agreements/containers/AddTransactionContainer";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

const AddTransactionView = () => {
  const { t } = useTranslation("agreements");
  return (
    <ViewLayout
      title={t("transactions.add_transaction_page.title")}
      breadcrumbs={[
        {
          label: t("transactions.add_transaction_page.breadcrumb_link_label"),
          href: "../transactions",
        },
      ]}
    >
      <ContainedLayout>
        <AddTransactionContainer />
      </ContainedLayout>
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: [
    "servicing:transactions:elevated",
    "servicing:transactions:manage",
  ],
})(AddTransactionView);
