/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentInstructionBankModel } from './PaymentInstructionBankModel';
import {
    PaymentInstructionBankModelFromJSON,
    PaymentInstructionBankModelFromJSONTyped,
    PaymentInstructionBankModelToJSON,
} from './PaymentInstructionBankModel';
import type { PaymentInstructionDetailsModel } from './PaymentInstructionDetailsModel';
import {
    PaymentInstructionDetailsModelFromJSON,
    PaymentInstructionDetailsModelFromJSONTyped,
    PaymentInstructionDetailsModelToJSON,
} from './PaymentInstructionDetailsModel';
import type { PaymentInstructionPayeeModel } from './PaymentInstructionPayeeModel';
import {
    PaymentInstructionPayeeModelFromJSON,
    PaymentInstructionPayeeModelFromJSONTyped,
    PaymentInstructionPayeeModelToJSON,
} from './PaymentInstructionPayeeModel';

/**
 * 
 * @export
 * @interface PaymentInstructionModel
 */
export interface PaymentInstructionModel {
    /**
     * 
     * @type {PaymentInstructionDetailsModel}
     * @memberof PaymentInstructionModel
     */
    details: PaymentInstructionDetailsModel;
    /**
     * 
     * @type {PaymentInstructionPayeeModel}
     * @memberof PaymentInstructionModel
     */
    payee?: PaymentInstructionPayeeModel;
    /**
     * 
     * @type {PaymentInstructionBankModel}
     * @memberof PaymentInstructionModel
     */
    bank?: PaymentInstructionBankModel;
}

/**
 * Check if a given object implements the PaymentInstructionModel interface.
 */
export function instanceOfPaymentInstructionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "details" in value;

    return isInstance;
}

export function PaymentInstructionModelFromJSON(json: any): PaymentInstructionModel {
    return PaymentInstructionModelFromJSONTyped(json, false);
}

export function PaymentInstructionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInstructionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': PaymentInstructionDetailsModelFromJSON(json['details']),
        'payee': !exists(json, 'payee') ? undefined : PaymentInstructionPayeeModelFromJSON(json['payee']),
        'bank': !exists(json, 'bank') ? undefined : PaymentInstructionBankModelFromJSON(json['bank']),
    };
}

export function PaymentInstructionModelToJSON(value?: PaymentInstructionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': PaymentInstructionDetailsModelToJSON(value.details),
        'payee': PaymentInstructionPayeeModelToJSON(value.payee),
        'bank': PaymentInstructionBankModelToJSON(value.bank),
    };
}

