import {
  ApiProvider,
  AppProvider,
  EngageConfigContext,
} from "@ldms/mui-sdk/bootstrap";
import EnvironmentProvider from "common/providers/EnvironmentProvider";
import { FC, PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";
import AppSwitcher from "./components/AppSwitcher";
import SessionProvider from "./components/SessionProvider";
import ShellLayout from "./components/ShellLayout";
import UserMenu from "./components/UserMenu";
import { config } from "./config";

const Shell: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BrowserRouter>
      <EngageConfigContext.Provider value={config}>
        <AppProvider authorizationUri={config.appRoutes.loginPath}>
          <SessionProvider>
            <EnvironmentProvider>
              <ApiProvider basePath="/api" credentials="include">
                <ShellLayout
                  appSwitcher={<AppSwitcher />}
                  userMenu={<UserMenu />}
                >
                  {children}
                </ShellLayout>
              </ApiProvider>
            </EnvironmentProvider>
          </SessionProvider>
        </AppProvider>
      </EngageConfigContext.Provider>
    </BrowserRouter>
  );
};

export default withTranslationLoader("shell")(Shell);
