import { keys } from "api/onboarding";
import {
  AgreementDetailsFormModel,
  CompanyDetailsFormModel,
  CustomerDetailsFormModel,
  FeesAndCommissionsFormModel,
  OnboardingAssetFormModel,
  RepaymentTermsFormModel,
} from "apps/onboarding/containers";
import { FinancialDetailsFormModel } from "apps/onboarding/containers/FinancialDetailsStep";
import { OnboardingType } from "apps/onboarding/types";
import { usePartialMutate } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { useApi } from "common/providers";
import { OnboardingApi } from "generated/onboarding/apis";
import {
  ReactNode,
  createContext,
  useContext,
  useReducer,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { OnboardingState, mapOnboardingStateToModel } from ".";
import onboardingReducer from "./onboardingReducer";

const defaultInitialState: OnboardingState = {
  companyDetails: {
    companyType: "",
    companyName: "",
    tradingName: "",
    title: "",
    firstName: "",
    lastName: "",
  },
  customerDetails: {
    addressLine1: "",
    addressLine2: "",
    postcode: "",
    bankName: "",
    sortCode: "",
    accountNumber: "",
    bankAccountName: "",
  },
  assetDetails: { assets: [], totalNetCashPrice: 0, totalVat: 0 },
  agreementDetails: {
    portfolio: "",
    productId: "",
    productType: "",
    applyBalloonAsLastPayment: false,
    isFundedVat: false,
  },
  financialDetails: { balanceFinanced: 0 },
  feesAndCommissions: {
    commissionPercent: 0,
    frontendFee: 0,
    backendFee: 0,
    commissionAmount: 0,
    balanceFinanced: 0,
    applySupplierAmountToCapital: false,
    applyBrokerAmountToCapital: false,
    feesList: [],
  },
  repaymentTerms: {},
};

interface OnboardingContextValues {
  onboardingId?: string;
  customerId?: number;
  customerName?: string;
  setOnboardingId(id: string): void;
  submitStageOnboarding: (
    repaymentTerms: RepaymentTermsFormModel,
  ) => Promise<void>;
  submitEditOnboarding: (
    repaymentTerms: RepaymentTermsFormModel,
  ) => Promise<void>;
  submitCompanyDetails: (payload: CompanyDetailsFormModel) => void;
  submitCustomerDetails: (payload: CustomerDetailsFormModel) => void;
  submitAssetDetails: (payload: OnboardingAssetFormModel[]) => void;
  submitAgreementDetails: (payload: AgreementDetailsFormModel) => void;
  submitFinancialDetails: (payload: FinancialDetailsFormModel) => void;
  submitFeesAndCommissions: (payload: FeesAndCommissionsFormModel) => void;
  state: OnboardingState;
}

export const useOnboarding = (): OnboardingContextValues => {
  return useContext(OnboardingContext);
};

export const OnboardingContext = createContext<OnboardingContextValues>(
  {} as OnboardingContextValues,
);

interface OnboardingProviderProps {
  children: ReactNode;
  onboardingId?: string;
  customerId?: number;
  customerName?: string;
  initialState?: OnboardingState;
}

export default function OnboardingProvider({
  children,
  onboardingId: initialOnboardingId = "",
  customerId,
  customerName = "",
  initialState = defaultInitialState,
}: OnboardingProviderProps): JSX.Element {
  const appConfig = useAppConfiguration();
  const [onboardingId, setOnboardingId] = useState<string>(initialOnboardingId);
  const stageOnboardingApi = useApi(OnboardingApi);
  const [state, dispatch] = useReducer(onboardingReducer, initialState);
  const navigate = useNavigate();
  const partialMutate = usePartialMutate();

  const submitCompanyDetails = (payload: CompanyDetailsFormModel): void =>
    dispatch({ type: OnboardingType.CompanyDetails, payload });

  const submitCustomerDetails = (payload: CustomerDetailsFormModel): void =>
    dispatch({ type: OnboardingType.CustomerDetails, payload });

  const submitAssetDetails = (payload: OnboardingAssetFormModel[]): void =>
    dispatch({ type: OnboardingType.AssetDetails, payload });

  const submitAgreementDetails = (payload: AgreementDetailsFormModel): void =>
    dispatch({ type: OnboardingType.AgreementDetails, payload });

  const submitFinancialDetails = (payload: FinancialDetailsFormModel): void => {
    dispatch({ type: OnboardingType.FinancialDetails, payload });
  };

  const submitFeesAndCommissions = (
    payload: FeesAndCommissionsFormModel,
  ): void => {
    dispatch({ type: OnboardingType.FeesAndCommissions, payload });
  };

  const submitStageOnboarding = async (
    repaymentTerms: RepaymentTermsFormModel,
  ): Promise<void> => {
    dispatch({
      type: OnboardingType.RepaymentTerms,
      payload: repaymentTerms,
    });

    const response = await stageOnboardingApi.stageOnboardingDataRaw({
      onboardingModel: mapOnboardingStateToModel(
        { ...state, repaymentTerms },
        { id: customerId, name: customerName },
      ),
    });
    const location = response.raw.headers.get("location");

    if (location) {
      setOnboardingId(String(location.split("/").pop()));

      navigate(
        `${appConfig.appRoutes.onboarding}/review/${String(
          location.split("/").pop(),
        )}`,
      );
    }
  };

  const submitEditOnboarding = async (
    repaymentTerms: RepaymentTermsFormModel,
  ): Promise<void> => {
    dispatch({
      type: OnboardingType.RepaymentTerms,
      payload: repaymentTerms,
    });

    await stageOnboardingApi.editOnboardingRaw({
      onboardingId: Number(onboardingId),
      onboardingModel: mapOnboardingStateToModel(
        { ...state, repaymentTerms },
        { id: customerId, name: customerName },
      ),
    });

    navigate(`${appConfig.appRoutes.onboarding}/review/${onboardingId}`);
    partialMutate(keys.all(), undefined);
  };

  return (
    <OnboardingContext.Provider
      value={{
        onboardingId,
        customerId,
        customerName,
        setOnboardingId,
        submitStageOnboarding,
        submitEditOnboarding,
        submitCompanyDetails,
        submitCustomerDetails,
        submitAssetDetails,
        submitAgreementDetails,
        submitFinancialDetails,
        submitFeesAndCommissions,
        state,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}
