import { TableCellButton } from "@ldms/mui-sdk/components";
import {
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import { ClientAttachmentListItemModel } from "generated/core/models/ClientAttachmentListItemModel";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface CustomerAttachmentListProps {
  data?: ClientAttachmentListItemModel[];
  loading: boolean;
  onDocumentDownload(documentId: string): void;
}

export default function CustomerAttachmentList({
  data,
  loading,
  onDocumentDownload,
}: CustomerAttachmentListProps): ReactElement {
  const { t } = useTranslation("clients");
  const locale = useLocale();

  const tableHeaders: string[] = [
    t("documents.list.filename_heading_label"),
    t("documents.list.type_heading_label"),
    t("documents.list.agreement_number_heading_label"),
    t("documents.list.description_heading_label"),
    t("documents.list.date_added_heading_label"),
  ];

  return (
    <>
      <Table size="small" aria-label={t("documents.title")} stickyHeader>
        <LoadableTableHead headings={tableHeaders} loading={loading} />

        <TableBody>
          {data?.map((attachment) => (
            <TableRow key={attachment.id} hover>
              <TableCellButton
                onClick={() => onDocumentDownload(attachment.id)}
              >
                <ListItemText
                  primary={attachment.filename}
                  primaryTypographyProps={{ color: "primary" }}
                />
              </TableCellButton>
              <TableCell>{attachment.type}</TableCell>
              <TableCell>{attachment.agreementNumber}</TableCell>
              <TableCell>{attachment.description}</TableCell>
              <TableCell>{locale.formatDate(attachment.createdDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(data || []).length === 0 && <NoResults />}
    </>
  );
}
