import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import CreateSettlementQuoteContainer from "apps/servicing/modules/agreements/containers/CreateSettlementQuoteContainer";
import { withModifiableAccess } from "apps/servicing/modules/agreements/hocs/withModifiableAccess";
import { withSettlementQuote } from "apps/servicing/modules/agreements/hocs/withSettlementQuote";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import compose from "lodash/fp/compose";
import { useTranslation } from "react-i18next";

function ApplySettlementQuoteView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const agreementId = agreement.id;
  const appConfig = useAppConfiguration();

  return (
    <ViewLayout
      title={t("settlement_quotes.create_quote.heading")}
      breadcrumbs={[
        {
          href: `${appConfig.appRoutes.servicing}/agreements/${agreementId}/settlement-quotes`,
          label: t("settlement_quotes.breadcrumb_link_label"),
        },
      ]}
    >
      <ContainedLayout>
        <CreateSettlementQuoteContainer agreementId={agreementId} />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default compose(
  withProtectedView({
    allowedPermissions: ["servicing:settlement-quotes:manage"],
  }),
  withModifiableAccess,
  withSettlementQuote,
)(ApplySettlementQuoteView);
