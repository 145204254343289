import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { BankAccountsApi } from "generated/servicing-v2/apis";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

export const useDeleteBankAccount = (
  customerId: string,
  bankAccountId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(BankAccountsApi, "v2");
  const swr = useSWRConfig();
  return useCommand<void, void, ResponseError>(
    () =>
      api.deleteBankAccount({
        id: bankAccountId,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.listBanks(customerId));
        options.onSuccess?.();
      },
    },
  );
};
