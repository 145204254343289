import { useFormat } from "@ldms/mui-sdk/formatting";
import { Form, NumberField } from "@ldms/mui-sdk/forms";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { OnboardingStepActions } from "apps/onboarding/components";
import OnboardingFeesContainer from "apps/onboarding/containers/OnboardingFeesContainer";
import { OnboardingState, useOnboarding } from "apps/onboarding/providers";
import { CommissionFieldTypes, FeeTypeEnum } from "apps/onboarding/types";
import {
  ControlledTextField,
  KeyValueRow,
  KeyValueTable,
} from "common/components";
import { useYupResolver } from "common/hooks";
import { useStepper } from "common/providers";
import { ProductTypeModel } from "generated/onboarding/models";
import { CommissionCalculationTypeModel } from "generated/onboarding/models/CommissionCalculationTypeModel";
import { useState } from "react";
import {
  Control,
  Controller,
  FormState,
  Resolver,
  UseFormReturn,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface OnboardingFeeModel {
  id: string;
  amount: number;
}

export interface OnboardingFeeDetailsModel extends OnboardingFeeModel {
  type: FeeTypeEnum;
  name: string;
  taxable: boolean;
  deductedFromAdvance: boolean;
  collectByDirectDebit: boolean;
  includeOnInvoices: boolean;
}

export interface FeesModel {
  arrangementFee?: number;
  annualAdministrationFee?: number;
  facilityFee?: number;
  legalFee?: number;
  valuationFee?: number;
  documentationFee?: number;
  optionToPurchaseFee?: number;
  landRegistryFee?: number;
  exitFee?: number;
  arrangementFeeDeductedFromAdvance?: boolean;
  administrationFee?: number;
  feesList?: OnboardingFeeModel[];
}

export interface CommissionsModel {
  amountOrPercent?: CommissionCalculationTypeModel | "";
  brokerCommission?: number;
  supplierCommission?: number;
  brokerCommissionAmount?: number;
  supplierCommissionAmount?: number;
  applyBrokerAmountToCapital?: boolean;
  applySupplierAmountToCapital?: boolean;
  isBrokerCommissionFunded?: boolean;
}

export interface FeesAndCommissionsFormModel
  extends FeesModel,
    CommissionsModel {}

type Field = { name: keyof FeesAndCommissionsFormModel; label: string };

export type CommissionField = {
  type: CommissionFieldTypes;
  field: Field;
};

export type CommissionFieldSet = CommissionField[];

const addSupplierCommissionToBalanceFinancedLabel =
  "fees_and_commissions.add_supplier_commission_to_balance_financed_label";
const addBrokerCommissionToBalanceFinancedLabel =
  "fees_and_commissions.add_broker_commission_to_balance_financed_label";
const isBrokerCommissionFundedVatLabel =
  "fees_and_commissions.is_broker_commission_funded_vat";

const supplierCommission: CommissionFieldSet = [
  {
    type: CommissionFieldTypes.Amount,
    field: {
      name: "supplierCommission",
      label: "fees_and_commissions.supplier_commission_label",
    },
  },
  {
    type: CommissionFieldTypes.Capitalised,
    field: {
      name: "applySupplierAmountToCapital",
      label: addSupplierCommissionToBalanceFinancedLabel,
    },
  },
];

const supplierCommissionAmount: CommissionFieldSet = [
  {
    type: CommissionFieldTypes.Percent,
    field: {
      name: "supplierCommissionAmount",
      label: "fees_and_commissions.supplier_commission_amount_label",
    },
  },
  {
    type: CommissionFieldTypes.Capitalised,
    field: {
      name: "applySupplierAmountToCapital",
      label: addSupplierCommissionToBalanceFinancedLabel,
    },
  },
];

const brokerCommissionAmount: CommissionFieldSet = [
  {
    type: CommissionFieldTypes.Percent,
    field: {
      name: "brokerCommissionAmount",
      label: "fees_and_commissions.broker_commission_amount_label",
    },
  },
  {
    type: CommissionFieldTypes.Capitalised,
    field: {
      name: "applyBrokerAmountToCapital",
      label: addBrokerCommissionToBalanceFinancedLabel,
    },
  },
  {
    type: CommissionFieldTypes.FundedVat,
    field: {
      name: "isBrokerCommissionFunded",
      label: isBrokerCommissionFundedVatLabel,
    },
  },
];

const brokerCommission: CommissionFieldSet = [
  {
    type: CommissionFieldTypes.Amount,
    field: {
      name: "brokerCommission",
      label: "fees_and_commissions.broker_commission_label",
    },
  },
  {
    type: CommissionFieldTypes.Capitalised,
    field: {
      name: "applyBrokerAmountToCapital",
      label: addBrokerCommissionToBalanceFinancedLabel,
    },
  },
  {
    type: CommissionFieldTypes.FundedVat,
    field: {
      name: "isBrokerCommissionFunded",
      label: isBrokerCommissionFundedVatLabel,
    },
  },
];

const fees = {
  annualAdministrationFee: {
    name: "annualAdministrationFee",
    label: "fees_and_commissions.annual_administration_fee_label",
  },
  legalFee: { name: "legalFee", label: "fees_and_commissions.legal_fee_label" },
  valuationFee: {
    name: "valuationFee",
    label: "fees_and_commissions.valuation_fee_label",
  },
  documentationFee: {
    name: "documentationFee",
    label: "fees_and_commissions.documentation_fee_label",
  },
  arrangementFee: {
    name: "arrangementFee",
    label: "fees_and_commissions.arrangement_fee_label",
  },
  optionToPurchaseFee: {
    name: "optionToPurchaseFee",
    label: "fees_and_commissions.option_to_purchase_fee_label",
  },
  facilityFee: {
    name: "facilityFee",
    label: "fees_and_commissions.facility_fee_label",
  },
  landRegistryFee: {
    name: "landRegistryFee",
    label: "fees_and_commissions.land_registry_fee_label",
  },
  exitFee: {
    name: "exitFee",
    label: "fees_and_commissions.exit_fee_label",
  },
  arrangementFeeDeductedFromAdvance: {
    name: "arrangementFeeDeductedFromAdvance",
    label: "fees_and_commissions.arrangement_fee_deducted_from_advance_label",
  },
  administrationFee: {
    name: "administrationFee",
    label: "fees_and_commissions.administration_fee_label",
  },
} as const;

function useFeesAndCommissionsResolver(): Resolver<FeesAndCommissionsFormModel> {
  const { t } = useTranslation("onboardings");
  return useYupResolver<FeesAndCommissionsFormModel>((yup) =>
    yup.object().shape({
      amountOrPercent: yup.string(),
      arrangementFee: yup
        .number(t(fees.arrangementFee.label))
        .nullable()
        .minAmount(0, t(fees.arrangementFee.label))
        .maxAmount(99999999.99, t(fees.arrangementFee.label)),
      annualAdministrationFee: yup
        .number(t(fees.annualAdministrationFee.label))
        .nullable()
        .minAmount(0, t(fees.annualAdministrationFee.label))
        .maxAmount(99999999.99, t(fees.annualAdministrationFee.label)),
      facilityFee: yup
        .number(t(fees.facilityFee.label))
        .nullable()
        .minAmount(0, t(fees.facilityFee.label))
        .maxAmount(999999.99, t(fees.facilityFee.label)),
      legalFee: yup
        .number(t(fees.legalFee.label))
        .nullable()
        .minAmount(0, t(fees.legalFee.label))
        .maxAmount(99999999.99, t(fees.legalFee.label)),
      valuationFee: yup
        .number(t(fees.valuationFee.label))
        .nullable()
        .minAmount(0, t(fees.valuationFee.label))
        .maxAmount(99999999.99, t(fees.valuationFee.label)),
      documentationFee: yup
        .number(t(fees.documentationFee.label))
        .nullable()
        .minAmount(0, t(fees.documentationFee.label))
        .maxAmount(999999.99, t(fees.documentationFee.label)),
      brokerCommission: yup
        .number(t(brokerCommission[0].field.label))
        .nullable()
        .minAmount(0, t(brokerCommission[0].field.label))
        .maxAmount(999.9999999999, t(brokerCommission[0].field.label)),
      brokerCommissionAmount: yup
        .number(t(brokerCommissionAmount[0].field.label))
        .nullable()
        .minAmount(0, t(brokerCommissionAmount[0].field.label)),
      isBrokerCommissionFunded: yup.boolean().test({
        name: "isBrokerAmountAddedToCapital",
        message: t(
          "fees_and_commissions.broker_commission_capital_when_funding_vat_error_message",
        ),
        test: (isBrokerCommissionFunded, testContext) =>
          !(
            Boolean(isBrokerCommissionFunded) &&
            !Boolean(testContext.parent.applyBrokerAmountToCapital)
          ),
      }),
      supplierCommission: yup
        .number(t(supplierCommission[0].field.label))
        .nullable()
        .minAmount(0, t(supplierCommission[0].field.label))
        .maxAmount(999.9999999999, t(supplierCommission[0].field.label)),
      supplierCommissionAmount: yup
        .number(t(supplierCommissionAmount[0].field.label))
        .nullable()
        .minAmount(0, t(supplierCommissionAmount[0].field.label)),
      optionToPurchaseFee: yup
        .number(t(fees.optionToPurchaseFee.label))
        .nullable()
        .minAmount(0, t(fees.optionToPurchaseFee.label))
        .maxAmount(999999.99, t(fees.optionToPurchaseFee.label)),
      landRegistryFee: yup
        .number(t(fees.landRegistryFee.label))
        .nullable()
        .minAmount(0, t(fees.landRegistryFee.label))
        .maxAmount(99999999.99, t(fees.landRegistryFee.label)),
      exitFee: yup
        .number(t(fees.exitFee.label))
        .nullable()
        .minAmount(0, t(fees.exitFee.label))
        .maxAmount(99999999.99, t(fees.exitFee.label)),
      arrangementFeeDeductedFromAdvance: yup.boolean(),
      administrationFee: yup
        .number(t(fees.administrationFee.label))
        .nullable()
        .minAmount(0, t(fees.administrationFee.label))
        .maxAmount(99999999.99, t(fees.administrationFee.label)),
    }),
  );
}

const getNumberValue = (value: number | undefined) => value || 0;

const getSummary = (
  onboarding: OnboardingState,
  formData: FeesAndCommissionsFormModel,
) => {
  const brokerCommissionPercentageWatch = getNumberValue(
    formData.brokerCommissionAmount,
  );
  const supplierCommissionPercentageWatch = getNumberValue(
    formData.supplierCommissionAmount,
  );
  const brokerCommissionAmountWatch = getNumberValue(formData.brokerCommission);
  const supplierCommissionAmountWatch = getNumberValue(
    formData.supplierCommission,
  );
  const applyBrokerCommissionToCapitalWatch =
    formData.applyBrokerAmountToCapital;
  const applySupplierCommissionToCapitalWatch =
    formData.applySupplierAmountToCapital;
  const isBrokerCommissionFundedWatch = formData.isBrokerCommissionFunded;

  const totalNetCashPrice = getNumberValue(
    onboarding.assetDetails?.totalNetCashPrice,
  );
  const balanceFinanced = getNumberValue(
    onboarding.financialDetails?.balanceFinanced,
  );

  const calculateCommissionAmount = (percentage: number): number =>
    Number(((totalNetCashPrice * percentage) / 100).toFixed(2));

  const calculateCommissionPercentage = (amount: number): number =>
    Number((amount / totalNetCashPrice) * 100);

  const brokerCommission = calculateCommissionPercentage(
    brokerCommissionPercentageWatch,
  );

  const supplierCommission = calculateCommissionPercentage(
    supplierCommissionPercentageWatch,
  );

  const brokerCommissionAmount = calculateCommissionAmount(
    brokerCommissionAmountWatch,
  );
  const supplierCommissionAmount = calculateCommissionAmount(
    supplierCommissionAmountWatch,
  );

  const agreementFundedVat = onboarding.agreementDetails?.isFundedVat
    ? getNumberValue(onboarding.assetDetails?.totalVat)
    : 0;

  const calculateFundedVatWithAmountOrPercent = brokerCommissionAmountWatch
    ? brokerCommissionAmount
    : brokerCommissionPercentageWatch;
  const brokerCommissionFundedVat = isBrokerCommissionFundedWatch
    ? calculateFundedVatWithAmountOrPercent *
      (getNumberValue(onboarding.agreementDetails?.vatRate) / 100)
    : 0;

  const isFundedVat = Boolean(onboarding.agreementDetails?.isFundedVat);

  const totalFundedVat = agreementFundedVat + brokerCommissionFundedVat;

  const balanceFinanceTotal = Number(
    (
      balanceFinanced +
      ((applyBrokerCommissionToCapitalWatch ? brokerCommissionAmount : 0) +
        (applySupplierCommissionToCapitalWatch
          ? supplierCommissionAmount
          : 0)) +
      brokerCommissionFundedVat
    ).toFixed(2),
  );

  const getBalanceFinancePercentageToAmount = () => {
    let result = balanceFinanced;

    if (applyBrokerCommissionToCapitalWatch) {
      result += (totalNetCashPrice * brokerCommission) / 100;
    }

    if (applySupplierCommissionToCapitalWatch) {
      result += (totalNetCashPrice * supplierCommission) / 100;
    }

    result += brokerCommissionFundedVat;

    return Number(result.toFixed(2));
  };

  return {
    isFundedVat,
    totalFundedVat,
    balanceFinanceTotal,
    brokerCommission,
    brokerCommissionAmount,
    supplierCommission,
    supplierCommissionAmount,
    getBalanceFinancePercentageToAmount,
  };
};

const CheckboxField: React.FC<{
  control: Control<FeesAndCommissionsFormModel>;
  name: keyof FeesAndCommissionsFormModel;
  label: string;
  formState: FormState<FeesAndCommissionsFormModel>;
}> = ({ control, formState, name, label }) => {
  return (
    <>
      <FormControlLabel
        componentsProps={{ typography: { variant: "body2" } }}
        label={label}
        labelPlacement="end"
        control={
          <Controller
            control={control}
            name={name}
            defaultValue={false}
            render={({ field: { value, ...field } }) => (
              <Checkbox
                {...field}
                checked={Boolean(value)}
                onChange={(event): void => field.onChange(event.target.checked)}
              />
            )}
          />
        }
      />
      {formState.errors[name] && (
        <FormHelperText error margin="dense">
          {formState.errors[name]?.message}
        </FormHelperText>
      )}
    </>
  );
};

const FeesAndCommissionsFormControls = ({
  form,
  isFundedVat,
  product,
}: {
  form: UseFormReturn<FeesAndCommissionsFormModel>;
  isFundedVat: boolean;
  product: string;
}) => {
  const { t } = useTranslation("onboardings");
  const formWatch = form.watch();

  const getFieldProps = (
    name: keyof FeesAndCommissionsFormModel,
    label: string,
  ) => ({
    control: form.control,
    label,
    name,
    error: Boolean(form.formState.errors[name]),
    helperText: form.formState.errors[name]?.message,
  });

  return (
    <>
      <ControlledTextField
        control={form.control}
        name="amountOrPercent"
        defaultValue=""
        id="amountOrPercent"
        label={t(
          "fees_and_commissions.commission_amount_or_percentage_dropdown_label",
        )}
        SelectProps={{ displayEmpty: true }}
        select
      >
        <MenuItem value="">
          <i>{t("common:please_select")}</i>
        </MenuItem>
        {Object.values(CommissionCalculationTypeModel).map((type) => (
          <MenuItem key={String(type)} value={String(type)}>
            {type}
          </MenuItem>
        ))}
      </ControlledTextField>

      {formWatch.amountOrPercent ===
        CommissionCalculationTypeModel.Percentage && (
        <>
          <NumberField
            maximumFractionDigits={10}
            {...getFieldProps(
              "brokerCommission",
              t("fees_and_commissions.broker_commission_label"),
            )}
          />
          <CheckboxField
            control={form.control}
            label={t(addBrokerCommissionToBalanceFinancedLabel)}
            name="applyBrokerAmountToCapital"
            formState={form.formState}
          />
          {isFundedVat && (
            <CheckboxField
              control={form.control}
              label={t(isBrokerCommissionFundedVatLabel)}
              name="isBrokerCommissionFunded"
              formState={form.formState}
            />
          )}
          {product !== ProductTypeModel.FixedRateLoan && (
            <>
              <NumberField
                maximumFractionDigits={10}
                {...getFieldProps(
                  "supplierCommission",
                  t("fees_and_commissions.supplier_commission_label"),
                )}
              />
              <CheckboxField
                control={form.control}
                label={t(addSupplierCommissionToBalanceFinancedLabel)}
                name="applySupplierAmountToCapital"
                formState={form.formState}
              />
            </>
          )}
        </>
      )}

      {formWatch.amountOrPercent === CommissionCalculationTypeModel.Amount && (
        <>
          <NumberField
            maximumFractionDigits={10}
            {...getFieldProps(
              "brokerCommissionAmount",
              t("fees_and_commissions.broker_commission_lump_sum_amount_label"),
            )}
          />
          <CheckboxField
            control={form.control}
            label={t(addBrokerCommissionToBalanceFinancedLabel)}
            name="applyBrokerAmountToCapital"
            formState={form.formState}
          />
          {isFundedVat && (
            <CheckboxField
              control={form.control}
              label={t("fees_and_commissions.is_broker_commission_funded_vat")}
              name="isBrokerCommissionFunded"
              formState={form.formState}
            />
          )}
          {product !== ProductTypeModel.FixedRateLoan && (
            <>
              <NumberField
                maximumFractionDigits={10}
                {...getFieldProps(
                  "supplierCommissionAmount",
                  t(
                    "fees_and_commissions.supplier_commission_lump_sum_amount_label",
                  ),
                )}
              />
              <CheckboxField
                control={form.control}
                label={t(addSupplierCommissionToBalanceFinancedLabel)}
                name="applySupplierAmountToCapital"
                formState={form.formState}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default function FeesAndCommissionsStep(): React.ReactElement {
  const { t } = useTranslation("onboardings");
  const stepper = useStepper();
  const onboarding = useOnboarding();
  const { formatAmount } = useFormat();
  const product =
    (onboarding.state.agreementDetails?.productType as ProductTypeModel) ||
    undefined;
  const [feesList, setFeesList] = useState<OnboardingFeeModel[]>(
    onboarding.state.feesAndCommissions?.feesList?.slice() ?? [],
  );

  const resolver = useFeesAndCommissionsResolver();

  const onSubmit = (data: FeesAndCommissionsFormModel): void => {
    onboarding.submitFeesAndCommissions({ ...data, feesList: feesList });
    stepper.next();
  };

  return (
    <>
      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h1" }}>
          {t("fees_and_commissions.title")}
        </Typography>
      </Box>

      <OnboardingFeesContainer
        financialProductId={Number(
          onboarding.state.agreementDetails?.productId,
        )}
        fees={feesList}
        setFeesList={setFeesList}
      />

      <Form<FeesAndCommissionsFormModel>
        label={t("fees_and_commissions.title")}
        onSubmit={onSubmit}
        defaultValues={{
          ...onboarding.state.feesAndCommissions,
          arrangementFeeDeductedFromAdvance: Boolean(
            onboarding.state.feesAndCommissions
              ?.arrangementFeeDeductedFromAdvance,
          ),
          applyBrokerAmountToCapital: Boolean(
            onboarding.state.feesAndCommissions?.applyBrokerAmountToCapital,
          ),
          applySupplierAmountToCapital: Boolean(
            onboarding.state.feesAndCommissions?.applySupplierAmountToCapital,
          ),
          isBrokerCommissionFunded: Boolean(
            onboarding.state.feesAndCommissions?.isBrokerCommissionFunded,
          ),
          amountOrPercent:
            onboarding.state.feesAndCommissions?.amountOrPercent ?? "",
        }}
        resolver={resolver}
        options={{
          shouldUnregister: true,
        }}
      >
        {(form) => {
          const formWatch = form.watch();
          const summary = getSummary(onboarding.state, formWatch);

          return (
            <>
              <Divider variant="fullWidth" />

              <Box marginTop={3}>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography variant="h6">
                      {t("fees_and_commissions.commissions_heading")}
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <FeesAndCommissionsFormControls
                      form={form}
                      isFundedVat={summary.isFundedVat}
                      product={product}
                    />
                  </Grid>
                </Grid>
              </Box>

              <KeyValueTable
                testId="feesAndCommission.balance.table"
                label={t("fees_and_commissions.commission_balances")}
              >
                <>
                  {formWatch.amountOrPercent ===
                    CommissionCalculationTypeModel.Percentage && (
                    <>
                      <KeyValueRow
                        align="right"
                        label={t(
                          "fees_and_commissions.broker_commission_amount_label",
                        )}
                      >
                        {formatAmount(summary.brokerCommissionAmount)}
                      </KeyValueRow>
                      {product !== ProductTypeModel.FixedRateLoan && (
                        <KeyValueRow
                          align="right"
                          label={t(
                            "fees_and_commissions.supplier_commission_amount_label",
                          )}
                        >
                          {formatAmount(summary.supplierCommissionAmount)}
                        </KeyValueRow>
                      )}
                      {summary.isFundedVat && (
                        <KeyValueRow
                          align="right"
                          label={t(
                            "fees_and_commissions.total_funded_vat_label",
                          )}
                        >
                          {formatAmount(summary.totalFundedVat)}
                        </KeyValueRow>
                      )}
                    </>
                  )}
                  {formWatch.amountOrPercent ===
                    CommissionCalculationTypeModel.Amount && (
                    <>
                      <KeyValueRow
                        align="right"
                        label={t(
                          "fees_and_commissions.broker_commission_percent_label",
                        )}
                      >
                        {summary.brokerCommission}
                      </KeyValueRow>
                      {product !== ProductTypeModel.FixedRateLoan && (
                        <KeyValueRow
                          align="right"
                          label={t(
                            "fees_and_commissions.supplier_commission_percent_label",
                          )}
                        >
                          {summary.supplierCommission}
                        </KeyValueRow>
                      )}
                      {summary.isFundedVat && (
                        <KeyValueRow
                          align="right"
                          label={t(
                            "fees_and_commissions.total_funded_vat_label",
                          )}
                        >
                          {formatAmount(summary.totalFundedVat)}
                        </KeyValueRow>
                      )}
                    </>
                  )}
                  <KeyValueRow
                    align="right"
                    label={t("fees_and_commissions.balance_financed_label")}
                  >
                    {formWatch.amountOrPercent ===
                    CommissionCalculationTypeModel.Percentage
                      ? formatAmount(summary.balanceFinanceTotal)
                      : formatAmount(
                          summary.getBalanceFinancePercentageToAmount(),
                        )}
                  </KeyValueRow>
                </>
              </KeyValueTable>

              <OnboardingStepActions
                back={{
                  label: t("common:stepper.back_button"),
                  onBack: stepper.previous,
                }}
                next={{ label: t("common:stepper.next_button") }}
              />
            </>
          );
        }}
      </Form>
    </>
  );
}
