import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AppNavigationList, IconTooltipButton } from "@ldms/mui-sdk/components";
import { Settings } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

const Navigation = () => {
  const { t } = useTranslation("onboardings");
  const location = useLocation();

  return (
    <Box display="flex" marginLeft="auto">
      <AppNavigationList
        label={t("secondary_navigation")}
        pathname={location.pathname}
      >
        <AccessControl
          allowedPermissions={[
            "onboarding:fees:manage",
            "onboarding:financial-products:manage",
          ]}
        >
          <li>
            <IconTooltipButton
              title={t("settings_button")}
              component={ReactRouterLink}
              to="onboarding/settings"
            >
              <Settings />
            </IconTooltipButton>
          </li>
        </AccessControl>
      </AppNavigationList>
    </Box>
  );
};

export default Navigation;
