import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { ControlledTextField, Loader } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FieldError, Resolver, useForm } from "support/react-hook-form";

export interface CaisReportFormValues {
  report: string;
}

interface CaisReportFormDialogProps {
  error?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (formValues: CaisReportFormValues) => void;
  ready: boolean;
  reports: { label: string; value: string }[];
  resolver: Resolver<CaisReportFormValues>;
  title: string;
}

export default function CaisReportFormDialog({
  error,
  open,
  onClose,
  onSubmit,
  ready,
  reports,
  resolver,
  title,
}: CaisReportFormDialogProps): ReactElement {
  const { t } = useTranslation(["reporting"]);

  const form = useForm<CaisReportFormValues>({
    defaultValues: {
      report: "",
    },
    resolver,
  });

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="cais-report-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <Loader
        ready={ready}
        render={(): ReactElement => (
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            aria-label={t("report_dialog.cais.title")}
            noValidate
          >
            <DialogTitle id="cais-report-dialog-title">
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{title}</Box>
                <Box>
                  <IconButton
                    aria-label={t("common:close")}
                    onClick={onClose}
                    size="small"
                  >
                    <Close />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              <ControlledTextField
                helperText={
                  (form.formState.errors?.report as FieldError)?.message
                }
                SelectProps={{ displayEmpty: true }}
                error={(form.formState.errors?.report as FieldError)?.message}
                control={form.control}
                id="report"
                name="report"
                label={t("report_dialog.cais.reporting_period_label")}
                select
                disabled={form.formState.isSubmitting}
                required
              >
                <MenuItem key="please-select" value="">
                  {t("common:please_select")}
                </MenuItem>
                {reports.map((report) => (
                  <MenuItem key={report.value} value={report.value}>
                    {report.label}
                  </MenuItem>
                ))}
              </ControlledTextField>

              {error && <Typography color="error">{error}</Typography>}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={onClose}>
                {t("common:cancel")}
              </Button>
              <Button
                type="submit"
                disabled={form.formState.isSubmitting}
                variant="contained"
                color="primary"
              >
                {t("report_dialog.generate_button")}
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}
