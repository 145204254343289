import useQuery, {
  UseQueryOptions,
  UseQueryReturn,
} from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { DirectDebitQueryApi } from "generated/core/apis";
import { DirectDebitSubmissionPaymentTypeModel } from "generated/core/models";
import { list } from "./keys";

type UseListPaymentTypes = (
  options?: UseQueryOptions,
) => UseQueryReturn<DirectDebitSubmissionPaymentTypeModel[]>;

const useListPaymentTypes: UseListPaymentTypes = (options = {}) => {
  const api = useApi(DirectDebitQueryApi);
  const key = list();

  return useQuery(
    key,
    () => {
      return api.listPaymentTypes();
    },
    options,
  );
};

export default useListPaymentTypes;
