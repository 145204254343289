import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import {
  DirectDebitTypeModel,
  RepresentDirectDebitModel,
} from "generated/core/models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";

type Command = (submissionType: DirectDebitTypeModel) => Promise<void>;

interface UseRepresentDirectDebitReturn {
  command: Command;
  isLoading: boolean;
  error: Error | undefined;
  resetError(): void;
}

export const useRepresentDirectDebit = (
  agreementId: number,
  options: { onSuccess?: () => void } = {},
): UseRepresentDirectDebitReturn => {
  const { t } = useTranslation("agreements");
  const api = useApi(AgreementCommandApi);
  const error = useResponseError([
    [
      "invalid_represent",
      t("transactions.represent_direct_debit.invalid_represent_400_message"),
    ],
    [
      "outstanding_dd_submission_request",
      t(
        "transactions.represent_direct_debit.outstanding_dd_submission_400_message",
      ),
    ],
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const command: Command = async (
    submissionType: DirectDebitTypeModel,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const representDirectDebitModel: RepresentDirectDebitModel = {
        paymentType: submissionType,
      };
      await api.representDirectDebit({
        agreementId: agreementId,
        representDirectDebitModel,
      });
      options.onSuccess?.();
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
    resetError: error.reset,
  };
};
