/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Contacts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContactModel,
  ErrorWithParametersModel,
} from '../models/index';
import {
    ContactModelFromJSON,
    ContactModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
} from '../models/index';

export interface DeleteContactRequest {
    id: string;
}

export interface UpdateContactRequest {
    id: string;
    contactModel: ContactModel;
}

/**
 * 
 */
export class ContactsApi extends runtime.BaseAPI {

    /**
     * Delete a Contact
     */
    async deleteContactRaw(requestParameters: DeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["contacts:manage"]);
        }

        const response = await this.request({
            path: `/contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Contact
     */
    async deleteContact(requestParameters: DeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteContactRaw(requestParameters, initOverrides);
    }

    /**
     * Update a Contact
     */
    async updateContactRaw(requestParameters: UpdateContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateContact.');
        }

        if (requestParameters.contactModel === null || requestParameters.contactModel === undefined) {
            throw new runtime.RequiredError('contactModel','Required parameter requestParameters.contactModel was null or undefined when calling updateContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["contacts:manage"]);
        }

        const response = await this.request({
            path: `/contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContactModelToJSON(requestParameters.contactModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a Contact
     */
    async updateContact(requestParameters: UpdateContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateContactRaw(requestParameters, initOverrides);
    }

}
