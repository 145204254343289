/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetInstalmentListItemModel } from './AssetInstalmentListItemModel';
import {
    AssetInstalmentListItemModelFromJSON,
    AssetInstalmentListItemModelFromJSONTyped,
    AssetInstalmentListItemModelToJSON,
} from './AssetInstalmentListItemModel';
import type { CreditDetailsModel } from './CreditDetailsModel';
import {
    CreditDetailsModelFromJSON,
    CreditDetailsModelFromJSONTyped,
    CreditDetailsModelToJSON,
} from './CreditDetailsModel';
import type { InstalmentTotalsModel } from './InstalmentTotalsModel';
import {
    InstalmentTotalsModelFromJSON,
    InstalmentTotalsModelFromJSONTyped,
    InstalmentTotalsModelToJSON,
} from './InstalmentTotalsModel';

/**
 * 
 * @export
 * @interface FinancialDetailsModel
 */
export interface FinancialDetailsModel {
    /**
     * 
     * @type {CreditDetailsModel}
     * @memberof FinancialDetailsModel
     */
    creditDetails: CreditDetailsModel;
    /**
     * 
     * @type {InstalmentTotalsModel}
     * @memberof FinancialDetailsModel
     */
    totals: InstalmentTotalsModel;
    /**
     * 
     * @type {Array<AssetInstalmentListItemModel>}
     * @memberof FinancialDetailsModel
     */
    instalments: Array<AssetInstalmentListItemModel>;
}

/**
 * Check if a given object implements the FinancialDetailsModel interface.
 */
export function instanceOfFinancialDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "creditDetails" in value;
    isInstance = isInstance && "totals" in value;
    isInstance = isInstance && "instalments" in value;

    return isInstance;
}

export function FinancialDetailsModelFromJSON(json: any): FinancialDetailsModel {
    return FinancialDetailsModelFromJSONTyped(json, false);
}

export function FinancialDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creditDetails': CreditDetailsModelFromJSON(json['creditDetails']),
        'totals': InstalmentTotalsModelFromJSON(json['totals']),
        'instalments': ((json['instalments'] as Array<any>).map(AssetInstalmentListItemModelFromJSON)),
    };
}

export function FinancialDetailsModelToJSON(value?: FinancialDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creditDetails': CreditDetailsModelToJSON(value.creditDetails),
        'totals': InstalmentTotalsModelToJSON(value.totals),
        'instalments': ((value.instalments as Array<any>).map(AssetInstalmentListItemModelToJSON)),
    };
}

