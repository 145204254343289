import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { VatCodeQueryApi } from "generated/core/apis";
import { VatCodeListItemModel } from "generated/core/models";
import { TaxCodesApi } from "generated/servicing-v2/apis";
import { TaxCodeListItemModel } from "generated/servicing-v2/models";
import * as keys from "./keys";

type UseListVatCodes = () => UseQueryReturn<VatCodeListItemModel[]>;

export const useListVatCodes: UseListVatCodes = () => {
  const api = useApi(VatCodeQueryApi);

  return useQuery(keys.list(), () => {
    return api.listVatCodes();
  });
};

type UseListVatCodesV2 = () => UseQueryReturn<TaxCodeListItemModel[]>;

export const useListVatCodesV2: UseListVatCodesV2 = () => {
  const api = useApi(TaxCodesApi, "v2");

  return useQuery(keys.list(), () => {
    return api.listTaxCodes();
  });
};
