/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Customers within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContactsListItemModel,
  CustomerContactModel,
  ErrorWithParametersModel,
} from '../models/index';
import {
    ContactsListItemModelFromJSON,
    ContactsListItemModelToJSON,
    CustomerContactModelFromJSON,
    CustomerContactModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
} from '../models/index';

export interface AddCustomerContactRequest {
    id: string;
    customerContactModel: CustomerContactModel;
}

export interface ListCustomerContactsRequest {
    id: string;
}

/**
 * 
 */
export class CustomerContactApi extends runtime.BaseAPI {

    /**
     * Add new Customer contact
     */
    async addCustomerContactRaw(requestParameters: AddCustomerContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addCustomerContact.');
        }

        if (requestParameters.customerContactModel === null || requestParameters.customerContactModel === undefined) {
            throw new runtime.RequiredError('customerContactModel','Required parameter requestParameters.customerContactModel was null or undefined when calling addCustomerContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customers:contacts:manage"]);
        }

        const response = await this.request({
            path: `/customers/{id}/contacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerContactModelToJSON(requestParameters.customerContactModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add new Customer contact
     */
    async addCustomerContact(requestParameters: AddCustomerContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addCustomerContactRaw(requestParameters, initOverrides);
    }

    /**
     * List Customer contacts
     */
    async listCustomerContactsRaw(requestParameters: ListCustomerContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContactsListItemModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listCustomerContacts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customers:contacts:view"]);
        }

        const response = await this.request({
            path: `/customers/{id}/contacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactsListItemModelFromJSON));
    }

    /**
     * List Customer contacts
     */
    async listCustomerContacts(requestParameters: ListCustomerContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContactsListItemModel>> {
        const response = await this.listCustomerContactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
