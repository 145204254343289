import { AccessControl, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import ListAutomationsContainer from "apps/admin/containers/ListAutomationsContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout/ViewLayout";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const AutomationsView = () => {
  const { t } = useTranslation("admin");

  return (
    <ViewLayout
      title={t("automations.automations_heading")}
      action={
        <AccessControl allowedPermissions={["admin:automations:manage"]}>
          <AddButton
            component={RouterLink}
            to="../automations/create-automation"
            variant="contained"
          />
        </AccessControl>
      }
    >
      <ContainedLayout>
        <ListAutomationsContainer />
      </ContainedLayout>
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: ["admin:automations:manage", "admin:automations:view"],
})(AutomationsView);
