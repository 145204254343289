import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useListPortfolios } from "api/companies/portfolios";
import AddPortfolioContainer from "apps/servicing/modules/settings/containers/AddPortfolioContainer";
import { LoadableTableHead, NoResults } from "common/components";
import Surface from "common/components/Surface";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface ListPortfoliosContainerProps {
  companyId: string;
}

export default function ListPortfoliosContainer({
  companyId,
}: ListPortfoliosContainerProps): ReactElement {
  const { t } = useTranslation("servicing");
  const portfolios = useListPortfolios(companyId);
  const appConfig = useAppConfiguration();

  const tableHeaders: string[] = [
    t("companies.list_portfolios.name_heading"),
    t("companies.list_portfolios.registered_interest_heading"),
    t("companies.list_portfolios.equifax_insight_label"),
    t("companies.list_portfolios.ccr_provider_code_label"),
    t("companies.list_portfolios.depreciation_percentage_label"),
    t("companies.list_portfolios.depreciation_minimum_value_label"),
  ];

  return (
    <Surface
      title={t("companies.list_portfolios.table_name_label")}
      action={<AddPortfolioContainer companyId={companyId} />}
      disableGutters
    >
      <Table
        aria-label={t("companies.list_portfolios.table_name_label")}
        size="small"
      >
        <LoadableTableHead
          headings={tableHeaders}
          loading={portfolios.isValidating}
        />

        <TableBody>
          {portfolios.data?.map((portfolio) => (
            <TableRow key={portfolio.systemId} hover>
              <TableCell padding="none" sx={{ width: "650px" }}>
                <ListItemButton
                  dense
                  component={RouterLink}
                  to={`${appConfig.appRoutes.servicing}/settings/companies/${companyId}/portfolios/${portfolio.systemId}`}
                >
                  <ListItemText
                    primaryTypographyProps={{ color: "primary" }}
                    primary={portfolio.name}
                    secondary={portfolio.bankName}
                  />
                </ListItemButton>
              </TableCell>

              <TableCell>
                {portfolio.isHpiRegistered ? (
                  <Box alignItems="center" display="flex">
                    <CheckCircleIcon color="success" />
                    <Typography ml={0.5}>
                      {t("companies.list_portfolios.active_label")}
                    </Typography>
                  </Box>
                ) : (
                  <Box alignItems="center" display="flex">
                    <CancelIcon color="error" />
                    <Typography ml={0.5}>
                      {t("companies.list_portfolios.inactive_label")}
                    </Typography>
                  </Box>
                )}
              </TableCell>
              <TableCell>{portfolio.equifaxInsightNumber ?? "-"}</TableCell>

              <TableCell>{portfolio.ccrProviderCode ?? "-"}</TableCell>
              <TableCell>{portfolio.depreciationPercentage ?? "-"}</TableCell>
              <TableCell>{portfolio.depreciationMinimumValue ?? "-"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {(portfolios.data || []).length === 0 && <NoResults />}
    </Surface>
  );
}
