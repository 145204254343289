import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email?: string;
  active: boolean;
}

interface UserListProps {
  data?: User[];
  loading: boolean;
}

export default function UserList({
  data,
  loading,
}: Readonly<UserListProps>): ReactElement {
  const { t } = useTranslation("users");
  const appConfig = useAppConfiguration();

  return (
    <>
      <Table
        aria-label={t("user_list.label")}
        size="small"
        data-testid="users.table"
        stickyHeader
      >
        <LoadableTableHead
          headings={[
            t("user_list.user"),
            t("user_list.email_label"),
            t("user_list.status"),
          ]}
          loading={loading}
        />
        <TableBody>
          {data?.map((item, index) => (
            <TableRow key={item.id} hover>
              <TableCell
                padding="none"
                component="th"
                scope="row"
                sx={{ width: "500px" }}
              >
                <ListItemButton
                  key={item.id}
                  alignItems="flex-start"
                  dense
                  component={RouterLink}
                  to={`${appConfig.appRoutes.admin}/users/${item.id}`}
                >
                  <ListItemAvatar sx={{ marginTop: "5px" }}>
                    <Avatar>
                      {[item.firstName, item.lastName]
                        .map((n) => n.charAt(0).toUpperCase())
                        .join("")}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ color: "primary" }}
                    primary={[item.firstName, item.lastName].join(" ")}
                    secondary={item.username}
                  />
                </ListItemButton>
              </TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>
                {item.active ? (
                  <Box alignItems="center" display="flex">
                    <CheckCircleIcon fontSize="inherit" color="success" />
                    <Typography variant="body2" ml={0.5}>
                      {t("user_list.active")}
                    </Typography>
                  </Box>
                ) : (
                  <Box alignItems="center" display="flex">
                    <CancelIcon fontSize="inherit" color="error" />
                    <Typography variant="body2" ml={0.5}>
                      {t("user_list.inactive")}
                    </Typography>
                  </Box>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(data ?? []).length === 0 && <NoResults />}
    </>
  );
}
