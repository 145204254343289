/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The status code
 * @export
 */
export const StatusModel = {
    EndOfTerm: 'End of Term',
    PendingTermination: 'Pending Termination',
    PendingSecondaryRental: 'Pending Secondary Rental',
    PaidInFull: 'Paid in Full'
} as const;
export type StatusModel = typeof StatusModel[keyof typeof StatusModel];


export function StatusModelFromJSON(json: any): StatusModel {
    return StatusModelFromJSONTyped(json, false);
}

export function StatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusModel {
    return json as StatusModel;
}

export function StatusModelToJSON(value?: StatusModel | null): any {
    return value as any;
}

