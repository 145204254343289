import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { useGetPortfolioBankAccount } from "api/portfolio-bank-accounts";
import UpdatePortfolioBankAccountContainer from "apps/servicing/modules/settings/containers/UpdatePortfolioBankAccountContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

interface UpdatePortfolioBankAccountViewProps {
  portfolioBankAccountId: string;
  breadcrumbs?: { href?: string; label: string }[];
}

function UpdatePortfolioBankAccountView({
  portfolioBankAccountId,
  breadcrumbs = [],
}: UpdatePortfolioBankAccountViewProps) {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();
  const portfolioBankAccount = useGetPortfolioBankAccount(
    portfolioBankAccountId,
  );

  return (
    <main>
      <ViewLayout
        title={t("bank_accounts.edit_bank_account.title_label")}
        breadcrumbs={[
          {
            href: `${appConfig.appRoutes.servicing}/settings/bank-accounts`,
            label: t("bank_accounts.breadcrumb_link_label"),
          },
          portfolioBankAccount.data
            ? {
                href: `${appConfig.appRoutes.servicing}/settings/bank-accounts/${portfolioBankAccountId}`,
                label: portfolioBankAccount.data?.name,
              }
            : { label: "..." },
          ...breadcrumbs,
        ]}
      >
        <ContainedLayout>
          {portfolioBankAccount.data && (
            <UpdatePortfolioBankAccountContainer
              portfolioBankAccountId={portfolioBankAccountId}
              defaultValues={{
                ...portfolioBankAccount.data,
                submissionFileType:
                  portfolioBankAccount.data.directDebitSubmissionFileType,
              }}
            />
          )}
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:bank-accounts:elevated"],
})(UpdatePortfolioBankAccountView);
