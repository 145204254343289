/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefundTransactionModel
 */
export interface RefundTransactionModel {
    /**
     * The payment type
     * @type {string}
     * @memberof RefundTransactionModel
     */
    type: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof RefundTransactionModel
     */
    bankDate: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof RefundTransactionModel
     */
    amountReceived?: string;
    /**
     * The bank ID
     * @type {number}
     * @memberof RefundTransactionModel
     */
    bankId: number;
    /**
     * A general comment
     * @type {string}
     * @memberof RefundTransactionModel
     */
    comment?: string;
    /**
     * The Payee
     * @type {string}
     * @memberof RefundTransactionModel
     */
    payee: string;
    /**
     * The refund comment for the transaction type
     * @type {string}
     * @memberof RefundTransactionModel
     */
    refundComment?: string;
}

/**
 * Check if a given object implements the RefundTransactionModel interface.
 */
export function instanceOfRefundTransactionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "bankDate" in value;
    isInstance = isInstance && "bankId" in value;
    isInstance = isInstance && "payee" in value;

    return isInstance;
}

export function RefundTransactionModelFromJSON(json: any): RefundTransactionModel {
    return RefundTransactionModelFromJSONTyped(json, false);
}

export function RefundTransactionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefundTransactionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'bankDate': (new Date(json['bankDate'])),
        'amountReceived': !exists(json, 'amountReceived') ? undefined : json['amountReceived'],
        'bankId': json['bankId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'payee': json['payee'],
        'refundComment': !exists(json, 'refundComment') ? undefined : json['refundComment'],
    };
}

export function RefundTransactionModelToJSON(value?: RefundTransactionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'bankDate': (value.bankDate.toISOString().substring(0,10)),
        'amountReceived': value.amountReceived,
        'bankId': value.bankId,
        'comment': value.comment,
        'payee': value.payee,
        'refundComment': value.refundComment,
    };
}

