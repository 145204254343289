/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The transaction type
 * @export
 * @interface TransactionTypeModel
 */
export interface TransactionTypeModel {
    /**
     * The transaction type code
     * @type {string}
     * @memberof TransactionTypeModel
     */
    code?: string;
    /**
     * The name of the transaction type
     * @type {string}
     * @memberof TransactionTypeModel
     */
    type?: string;
}

/**
 * Check if a given object implements the TransactionTypeModel interface.
 */
export function instanceOfTransactionTypeModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TransactionTypeModelFromJSON(json: any): TransactionTypeModel {
    return TransactionTypeModelFromJSONTyped(json, false);
}

export function TransactionTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionTypeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function TransactionTypeModelToJSON(value?: TransactionTypeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'type': value.type,
    };
}

