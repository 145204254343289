/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A direct debit payer filter that can be applied when executing an automation
 * @export
 */
export const DirectDebitPayerFilterModel = {
    NoExclusions: 'No Exclusions',
    ExcludeDd: 'Exclude DD',
    ExcludeNonDd: 'Exclude Non-DD'
} as const;
export type DirectDebitPayerFilterModel = typeof DirectDebitPayerFilterModel[keyof typeof DirectDebitPayerFilterModel];


export function DirectDebitPayerFilterModelFromJSON(json: any): DirectDebitPayerFilterModel {
    return DirectDebitPayerFilterModelFromJSONTyped(json, false);
}

export function DirectDebitPayerFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectDebitPayerFilterModel {
    return json as DirectDebitPayerFilterModel;
}

export function DirectDebitPayerFilterModelToJSON(value?: DirectDebitPayerFilterModel | null): any {
    return value as any;
}

