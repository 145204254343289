import useQuery from "common/hooks/useQuery/useQuery";
import { useApi } from "common/providers";
import { AutomationQueryApi } from "generated/core/apis";
import * as keys from "./keys";

type ListAutomationsProps = {
  portfolioId?: number;
};

const useListAutomations = ({ portfolioId }: ListAutomationsProps) => {
  const api = useApi(AutomationQueryApi);
  return useQuery(keys.list({ portfolioId }), () =>
    api.listAutomations({ portfolioId }),
  );
};

export { useListAutomations };
