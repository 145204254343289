import {
  FieldValues,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";

function useFormWithDefaults<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
>(options?: UseFormProps<TFieldValues, TContext>): UseFormReturn<TFieldValues> {
  return useForm({
    mode: "onBlur",
    ...options,
  });
}
export * from "react-hook-form";
export { useFormWithDefaults as useForm };
