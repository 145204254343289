import { list } from "api/templates/letters/merge-fields/keys";
import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { TemplateApi } from "generated/documents/apis";
import { TemplateMergeFieldListItemModel } from "generated/documents/models";

type UseListTemplateMergeFields = () => UseQueryReturn<
  TemplateMergeFieldListItemModel[]
>;

export const useListTemplateMergeFields: UseListTemplateMergeFields = () => {
  const api = useApi(TemplateApi);

  return useQuery(list(), () => {
    return api.listTemplateMergeFields();
  });
};
