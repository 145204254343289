import { Box, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface NoResultsProps {
  message?: string;
}

export default function NoResults({ message }: NoResultsProps): ReactElement {
  const { t } = useTranslation("common");

  return (
    <Box display="flex" justifyContent="center" p={2}>
      {message ? (
        <Typography>{message}</Typography>
      ) : (
        <Typography>{t("common:no_results_message")}</Typography>
      )}
    </Box>
  );
}
