import {
  DateRangeControl,
  PortfolioReportFormDialog,
} from "apps/reporting/components";
import {
  usePortfolioDateReportResolver,
  useReportDownload,
} from "apps/reporting/hooks";
import { PortfolioDateReportDialogFormValues } from "apps/reporting/types";
import { useApi } from "common/providers";
import { ApiResponse } from "generated";
import { PortfolioQueryApi, ReportQueryApi } from "generated/core/apis";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import startOfYesterdayUTC from "support/form-helpers/start-of-yesterday-utc";
import useSWR from "swr";

interface FeeAmountDueReportProps {
  onClose(): void;
  open: boolean;
}

export default function FeeAmountDueReportContainer({
  onClose,
  open,
}: FeeAmountDueReportProps): ReactElement {
  const { t } = useTranslation("reporting");
  const portfolioQueryApi = useApi(PortfolioQueryApi);
  const reportApi = useApi(ReportQueryApi);
  const portfolios = useSWR(["portfolios"], () =>
    portfolioQueryApi.listPortfolios(),
  );
  const resolver = usePortfolioDateReportResolver().BeforeTodayResolver();

  const feeAmountDueReportApi = async (
    data: PortfolioDateReportDialogFormValues,
  ): Promise<ApiResponse<string>> =>
    reportApi.feeAmountDueRaw({
      portfolioId:
        data.portfolio === "All" ? undefined : Number(data.portfolio),
      from: new Date(data.fromDate),
      to: new Date(data.toDate),
    });

  const feeAmountDueReportDownload = useReportDownload(feeAmountDueReportApi, {
    onSuccess: onClose,
  });

  const onSubmit = async (
    data: PortfolioDateReportDialogFormValues,
  ): Promise<void> => feeAmountDueReportDownload.download(data);

  return (
    <PortfolioReportFormDialog<PortfolioDateReportDialogFormValues>
      defaultValues={{
        portfolio: "",
        fromDate: startOfYesterdayUTC(),
        toDate: startOfYesterdayUTC(),
      }}
      onClose={onClose}
      onSubmit={onSubmit}
      error={feeAmountDueReportDownload.error?.message}
      open={open}
      portfolios={[
        { label: t("report_dialog.all_portfolio_option"), value: "All" },
        ...(portfolios.data || []).map((portfolio) => ({
          label: portfolio.name,
          value: String(portfolio.id),
        })),
      ]}
      ready={Boolean(portfolios.data) && !feeAmountDueReportDownload.isLoading}
      resolver={resolver}
      title={t("report_dialog.fee_amount_due.title")}
    >
      {(form): ReactElement => (
        <DateRangeControl
          errors={form.formState.errors}
          register={form.register}
        />
      )}
    </PortfolioReportFormDialog>
  );
}
