import { AddButton } from "@ldms/mui-sdk/components";
import AddThirdPartyDialogContainer from "apps/servicing/modules/settings/containers/AddThirdPartyDialogContainer";
import { ReactElement, useState } from "react";

interface AddThirdPartyContainerProps {
  onSuccess(): void;
}

export default function AddThirdPartyContainer({
  onSuccess: onSuccessCallback,
}: AddThirdPartyContainerProps): ReactElement {
  const [isAddThirdPartyDialogOpen, setAddThirdPartyDialogOpen] =
    useState(false);

  const openAddThirdPartyDialog = (): void => {
    setAddThirdPartyDialogOpen(true);
  };
  const closeAddThirdPartyDialog = (): void => {
    setAddThirdPartyDialogOpen(false);
  };

  const onSuccess = (): void => {
    closeAddThirdPartyDialog();
    onSuccessCallback();
  };

  return (
    <>
      <AddButton variant="contained" onClick={openAddThirdPartyDialog} />

      <AddThirdPartyDialogContainer
        onSuccess={onSuccess}
        onClose={closeAddThirdPartyDialog}
        open={isAddThirdPartyDialogOpen}
      />
    </>
  );
}
