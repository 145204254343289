/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Agreements within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListPaymentInstructionsModel,
  PayeeModel,
  PaymentInstructionModel,
} from '../models/index';
import {
    ListPaymentInstructionsModelFromJSON,
    ListPaymentInstructionsModelToJSON,
    PayeeModelFromJSON,
    PayeeModelToJSON,
    PaymentInstructionModelFromJSON,
    PaymentInstructionModelToJSON,
} from '../models/index';

export interface CreatePaymentInstructionRequest {
    id: string;
    paymentInstructionModel: PaymentInstructionModel;
}

export interface ListPayeesRequest {
    id: string;
}

export interface ListPaymentInstructionsRequest {
    id: string;
}

/**
 * 
 */
export class AgreementPaymentInstructionsApi extends runtime.BaseAPI {

    /**
     * Create a new Payment Instruction
     */
    async createPaymentInstructionRaw(requestParameters: CreatePaymentInstructionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createPaymentInstruction.');
        }

        if (requestParameters.paymentInstructionModel === null || requestParameters.paymentInstructionModel === undefined) {
            throw new runtime.RequiredError('paymentInstructionModel','Required parameter requestParameters.paymentInstructionModel was null or undefined when calling createPaymentInstruction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement:payment-instructions:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{id}/payment-instructions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentInstructionModelToJSON(requestParameters.paymentInstructionModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new Payment Instruction
     */
    async createPaymentInstruction(requestParameters: CreatePaymentInstructionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPaymentInstructionRaw(requestParameters, initOverrides);
    }

    /**
     * List Payees for an Agreement
     */
    async listPayeesRaw(requestParameters: ListPayeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PayeeModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listPayees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement:payees:view"]);
        }

        const response = await this.request({
            path: `/agreements/{id}/payees`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PayeeModelFromJSON));
    }

    /**
     * List Payees for an Agreement
     */
    async listPayees(requestParameters: ListPayeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PayeeModel>> {
        const response = await this.listPayeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Payment Instructions for an agreement
     */
    async listPaymentInstructionsRaw(requestParameters: ListPaymentInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListPaymentInstructionsModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listPaymentInstructions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement:payment-instructions:view"]);
        }

        const response = await this.request({
            path: `/agreements/{id}/payment-instructions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListPaymentInstructionsModelFromJSON));
    }

    /**
     * List Payment Instructions for an agreement
     */
    async listPaymentInstructions(requestParameters: ListPaymentInstructionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListPaymentInstructionsModel>> {
        const response = await this.listPaymentInstructionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
