import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface AvailableThirdParties {
  id: number;
  name: string;
  type: string;
  contact?: string;
  address?: {
    addressLine1: string;
    addressLine2: string;
    addressLine3?: string;
    addressLine4?: string;
    postcode: string;
  };
}

interface AvailableThirdPartiesListProps {
  data: AvailableThirdParties[] | undefined;
  loading: boolean;
  onAssociateThirdParty: (thirdPartyId: number) => Promise<void>;
}

export default function AvailableThirdPartiesList({
  data,
  loading,
  onAssociateThirdParty,
}: AvailableThirdPartiesListProps): ReactElement {
  const { t } = useTranslation("agreements");

  const makeAssociateThirdPartHandler =
    (thirdPartyId: number) => (): Promise<void> =>
      onAssociateThirdParty(thirdPartyId);

  return (
    <TableContainer>
      <Table aria-label={t("associate_third_parties.title_label")} size="small">
        <LoadableTableHead
          loading={loading}
          headings={[
            t("associate_third_parties.name_label"),
            t("associate_third_parties.type_label"),
            t("associate_third_parties.contact_label"),
            t("associate_third_parties.address_label"),
            "",
          ]}
        />
        <TableBody>
          {data?.map((availableThirdParty) => (
            <TableRow key={availableThirdParty.id} hover>
              <TableCell>{availableThirdParty.name}</TableCell>
              <TableCell>{availableThirdParty.type}</TableCell>
              <TableCell>{availableThirdParty.contact}</TableCell>
              <TableCell>
                {[
                  availableThirdParty.address?.addressLine1,
                  availableThirdParty.address?.addressLine2,
                  availableThirdParty.address?.addressLine3,
                  availableThirdParty.address?.addressLine4,
                  availableThirdParty.address?.postcode,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </TableCell>
              <TableCell sx={{ paddingY: 0.25 }} align="right">
                <Button
                  onClick={makeAssociateThirdPartHandler(
                    availableThirdParty.id,
                  )}
                  size="small"
                >
                  {t("associate_third_parties.associate_button")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data?.length === 0 && <NoResults />}
    </TableContainer>
  );
}
