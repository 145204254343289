/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Direct Debits within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The direct debit payment type
 * @export
 * @interface DirectDebitSubmissionPaymentTypeModel
 */
export interface DirectDebitSubmissionPaymentTypeModel {
    /**
     * The payment type code
     * @type {string}
     * @memberof DirectDebitSubmissionPaymentTypeModel
     */
    type: string;
    /**
     * The name of the payment type
     * @type {string}
     * @memberof DirectDebitSubmissionPaymentTypeModel
     */
    name: string;
}

/**
 * Check if a given object implements the DirectDebitSubmissionPaymentTypeModel interface.
 */
export function instanceOfDirectDebitSubmissionPaymentTypeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function DirectDebitSubmissionPaymentTypeModelFromJSON(json: any): DirectDebitSubmissionPaymentTypeModel {
    return DirectDebitSubmissionPaymentTypeModelFromJSONTyped(json, false);
}

export function DirectDebitSubmissionPaymentTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectDebitSubmissionPaymentTypeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'name': json['name'],
    };
}

export function DirectDebitSubmissionPaymentTypeModelToJSON(value?: DirectDebitSubmissionPaymentTypeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'name': value.name,
    };
}

