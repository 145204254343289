import { Loader } from "@ldms/mui-sdk/templates";
import { Close, Search, Warning } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useListAvailableAgreementThirdParties } from "api/agreements/available-third-parties";
import { useAssociateThirdParty } from "api/agreements/third-parties";
import AvailableThirdPartiesList from "apps/servicing/modules/agreements/components/AvailableThirdPartiesList";
import { debounce } from "lodash";
import {
  ChangeEventHandler,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface AssociateThirdPartyContainerProps {
  agreementId: number;
}

export default function AssociateThirdPartyContainer({
  agreementId,
}: AssociateThirdPartyContainerProps): ReactElement {
  const { t } = useTranslation("agreements");
  const [query, setQuery] = useState("");
  const [isAssociateThirdPartyOpen, setAssociateThirdPartyOpen] =
    useState(false);
  const onQueryChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    setQuery(event.target.value);
  };
  const availableThirdParties = useListAvailableAgreementThirdParties(
    agreementId,
    { query },
  );

  const closeAssociateThirdPartyDialog = (): void => {
    setAssociateThirdPartyOpen(false);
    setQuery("");
  };

  const associateThirdParty = useAssociateThirdParty(agreementId, {
    onSuccess: closeAssociateThirdPartyDialog,
  });

  const onAssociateThirdParty = async (thirdPartyId: number): Promise<void> => {
    await associateThirdParty.command({ thirdPartyId });
  };

  const openAssociateThirdPartyDialog = (): void => {
    setAssociateThirdPartyOpen(true);
  };

  const handleQueryChange = useMemo(() => debounce(onQueryChange, 300), []);

  useEffect(() => {
    return handleQueryChange.cancel;
  }, [handleQueryChange]);

  return (
    <>
      <Button variant="contained" onClick={openAssociateThirdPartyDialog}>
        {t("associate_third_parties.associate_third_party_button")}
      </Button>
      <Dialog
        open={isAssociateThirdPartyOpen}
        fullWidth
        aria-labelledby="associate-third-party-title"
        maxWidth="md"
      >
        <DialogTitle id="associate-third-party-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{t("associate_third_parties.title_label")}</Box>
            <Box>
              <IconButton
                onClick={closeAssociateThirdPartyDialog}
                aria-label={t("common:close")}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item>
                <TextField
                  InputProps={{
                    endAdornment: <Search />,
                  }}
                  inputProps={{
                    "aria-label": t("associate_third_parties.search_label"),
                  }}
                  margin="none"
                  onChange={handleQueryChange}
                />
              </Grid>
            </Grid>
          </Box>
          {availableThirdParties?.data?.length === 0 && (
            <Alert severity="warning" icon={<Warning />}>
              <Box component="header" display="flex" alignItems="center">
                <Typography variant="body2">
                  {t("associate_third_parties.no_third_parties")}
                </Typography>
              </Box>
            </Alert>
          )}
          <Loader
            ready={!associateThirdParty.isLoading}
            render={() => (
              <AvailableThirdPartiesList
                data={availableThirdParties.data}
                loading={availableThirdParties.isValidating}
                onAssociateThirdParty={onAssociateThirdParty}
              />
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
