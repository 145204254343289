/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CorrespondenceMethodListItemModel
 */
export interface CorrespondenceMethodListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof CorrespondenceMethodListItemModel
     */
    readonly id: number;
    /**
     * A read only Id
     * @type {string}
     * @memberof CorrespondenceMethodListItemModel
     */
    readonly systemId?: string;
    /**
     * The contact method type
     * @type {string}
     * @memberof CorrespondenceMethodListItemModel
     */
    type: string;
    /**
     * The correspondance method code
     * @type {string}
     * @memberof CorrespondenceMethodListItemModel
     */
    code: string;
    /**
     * The value of the contact method which can be a contact number or email address
     * @type {string}
     * @memberof CorrespondenceMethodListItemModel
     */
    contact: string;
    /**
     * Audit note for this contact method
     * @type {string}
     * @memberof CorrespondenceMethodListItemModel
     */
    comment?: string;
    /**
     * Whether the contact method is current or not
     * @type {boolean}
     * @memberof CorrespondenceMethodListItemModel
     */
    current?: boolean;
    /**
     * A date time format
     * @type {string}
     * @memberof CorrespondenceMethodListItemModel
     */
    created?: string;
    /**
     * Whether the contact method is of an old type
     * @type {boolean}
     * @memberof CorrespondenceMethodListItemModel
     */
    old?: boolean;
}

/**
 * Check if a given object implements the CorrespondenceMethodListItemModel interface.
 */
export function instanceOfCorrespondenceMethodListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "contact" in value;

    return isInstance;
}

export function CorrespondenceMethodListItemModelFromJSON(json: any): CorrespondenceMethodListItemModel {
    return CorrespondenceMethodListItemModelFromJSONTyped(json, false);
}

export function CorrespondenceMethodListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorrespondenceMethodListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemId': !exists(json, 'systemId') ? undefined : json['systemId'],
        'type': json['type'],
        'code': json['code'],
        'contact': json['contact'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'current': !exists(json, 'current') ? undefined : json['current'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'old': !exists(json, 'old') ? undefined : json['old'],
    };
}

export function CorrespondenceMethodListItemModelToJSON(value?: CorrespondenceMethodListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'code': value.code,
        'contact': value.contact,
        'comment': value.comment,
        'current': value.current,
        'created': value.created,
        'old': value.old,
    };
}

