import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Loading() {
  const { t } = useTranslation("common");
  return (
    <Box mt={8}>
      <Container maxWidth="sm" sx={{ height: "100vh" }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6" align="center">
              {t("provider_loading")}
            </Typography>
          </Grid>
          <Grid item>
            <LinearProgress />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
