import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Box } from "@mui/material";
import { useLocale } from "common/hooks";
import { AgreementSettlementQuoteModel } from "generated/core/models/AgreementSettlementQuoteModel";
import React from "react";
import { useTranslation } from "react-i18next";

type AgreementSettlementQuoteSummaryProps = {
  settlementQuoteDetails: AgreementSettlementQuoteModel;
  discountField: string | number;
  totalSettleField: string;
};

const getTotalAmountDue = (
  settlementQuoteDetails: AgreementSettlementQuoteModel,
): number => {
  return (
    Number(settlementQuoteDetails.feesAndCharges) +
    Number(settlementQuoteDetails.futureInstalments) +
    Number(settlementQuoteDetails.outstandingArrears) -
    Number(settlementQuoteDetails.overPayments) +
    Number(settlementQuoteDetails.vat)
  );
};

const expiryDateLabel = "settlement_quotes.create_quote.expiration_date_label";
const arrearsLabel = "settlement_quotes.create_quote.arrears_label";
const feesLabel = "settlement_quotes.create_quote.fees_label";
const futureLabel = "settlement_quotes.create_quote.future_label";
const overPaymentsLabel = "settlement_quotes.create_quote.over_payments_label";
const vatLabel = "settlement_quotes.create_quote.vat_label";
const totalDueLabel = "settlement_quotes.create_quote.total_due_label";
const discountLabel = "settlement_quotes.create_quote.discount_label";
const totalSettleLabel = "settlement_quotes.create_quote.total_settle_label";

export default function AgreementSettlementQuoteSummary({
  settlementQuoteDetails,
  discountField,
  totalSettleField,
}: AgreementSettlementQuoteSummaryProps): React.ReactElement {
  const { t } = useTranslation("agreements");
  const { formatAmount } = useFormat();
  const totalAmountDue = getTotalAmountDue(settlementQuoteDetails);
  const locale = useLocale();

  return (
    <>
      <DescriptionList
        label={t("settlement_quotes.create_quote.description_label")}
      >
        <DescriptionList.Item label={t(expiryDateLabel)}>
          {settlementQuoteDetails?.expiryDate
            ? locale.formatDate(settlementQuoteDetails.expiryDate)
            : "-"}
        </DescriptionList.Item>
        <DescriptionList.Item label={t(arrearsLabel)}>
          {formatAmount(Number(settlementQuoteDetails?.outstandingArrears))}
        </DescriptionList.Item>
        <DescriptionList.Item label={t(feesLabel)}>
          {formatAmount(Number(settlementQuoteDetails?.feesAndCharges))}
        </DescriptionList.Item>
        <DescriptionList.Item label={t(futureLabel)}>
          {formatAmount(Number(settlementQuoteDetails?.futureInstalments))}
        </DescriptionList.Item>
        <DescriptionList.Item label={t(overPaymentsLabel)}>
          {formatAmount(Number(settlementQuoteDetails?.overPayments))}
        </DescriptionList.Item>
        <DescriptionList.Item label={t(vatLabel)}>
          {formatAmount(Number(settlementQuoteDetails?.vat))}
        </DescriptionList.Item>
        <DescriptionList.Item label={t(totalDueLabel)}>
          {formatAmount(totalAmountDue)}
        </DescriptionList.Item>
        <DescriptionList.Item label={t(discountLabel)}>
          <Box display="flex">
            <Box flex={1} width="1px" textOverflow="ellipsis" overflow="hidden">
              {discountField}
            </Box>
          </Box>
        </DescriptionList.Item>
        <DescriptionList.Item label={t(totalSettleLabel)}>
          <Box display="flex">
            <Box flex={1} width="1px" textOverflow="ellipsis" overflow="hidden">
              {totalSettleField}
            </Box>
          </Box>
        </DescriptionList.Item>
      </DescriptionList>
    </>
  );
}
