/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgreementInstalmentModel
 */
export interface AgreementInstalmentModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AgreementInstalmentModel
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementInstalmentModel
     */
    number: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AgreementInstalmentModel
     */
    date: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementInstalmentModel
     */
    amount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementInstalmentModel
     */
    interest: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementInstalmentModel
     */
    capital: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementInstalmentModel
     */
    capitalOutstanding: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementInstalmentModel
     */
    residualValue: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementInstalmentModel
     */
    fee: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementInstalmentModel
     */
    funderIrr: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementInstalmentModel
     */
    customerIrr: string;
}

/**
 * Check if a given object implements the AgreementInstalmentModel interface.
 */
export function instanceOfAgreementInstalmentModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "interest" in value;
    isInstance = isInstance && "capital" in value;
    isInstance = isInstance && "capitalOutstanding" in value;
    isInstance = isInstance && "residualValue" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "funderIrr" in value;
    isInstance = isInstance && "customerIrr" in value;

    return isInstance;
}

export function AgreementInstalmentModelFromJSON(json: any): AgreementInstalmentModel {
    return AgreementInstalmentModelFromJSONTyped(json, false);
}

export function AgreementInstalmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementInstalmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'date': (new Date(json['date'])),
        'amount': json['amount'],
        'interest': json['interest'],
        'capital': json['capital'],
        'capitalOutstanding': json['capitalOutstanding'],
        'residualValue': json['residualValue'],
        'fee': json['fee'],
        'funderIrr': json['funderIrr'],
        'customerIrr': json['customerIrr'],
    };
}

export function AgreementInstalmentModelToJSON(value?: AgreementInstalmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'date': (value.date.toISOString().substring(0,10)),
        'amount': value.amount,
        'interest': value.interest,
        'capital': value.capital,
        'capitalOutstanding': value.capitalOutstanding,
        'residualValue': value.residualValue,
        'fee': value.fee,
        'funderIrr': value.funderIrr,
        'customerIrr': value.customerIrr,
    };
}

