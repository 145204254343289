import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  MultipleSelectField,
  PasswordCriteriaAlert,
  PasswordTextField,
} from "common/components";
import { useYupResolver } from "common/hooks";
import { CreateUserModel } from "generated/admin/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "support/react-hook-form";

interface CreateUserFormDialogProps {
  onSubmit(data: CreateUserModel): Promise<void>;
  onClose(): void;
  open: boolean;
  error?: string;
  roleOptions?: { label: string; value: string }[];
}

export interface CreateUserFormValues {
  firstName: string;
  lastName: string;
  username: string;
  roles: string[];
  password: string;
  confirmPassword: string;
}

export default function CreateUserFormDialog({
  onSubmit: onSubmitCallback,
  onClose,
  open,
  roleOptions,
  error,
}: Readonly<CreateUserFormDialogProps>): ReactElement {
  const { t } = useTranslation("users");
  const usernameLabel = t("create_user.username_label");
  const firstNameLabel = t("create_user.firstname_label");
  const lastNameLabel = t("create_user.lastname_label");
  const resolver = useYupResolver<CreateUserFormValues>((yup) =>
    yup.object().shape({
      firstName: yup
        .string()
        .isRequired(firstNameLabel)
        .maxCharacters(14, firstNameLabel),
      lastName: yup
        .string()
        .isRequired(lastNameLabel)
        .maxCharacters(14, lastNameLabel),
      username: yup
        .string()
        .isRequired(usernameLabel)
        .maxCharacters(30, usernameLabel)
        .matches(/^[a-z][a-z0-9]*$/i, t("create_user.invalid_username_label")),
      roles: yup.array().of(yup.string()),
      password: yup.string().isRequired(t("create_user.password_label")),
      confirmPassword: yup
        .string()
        .isRequired(t("create_user.confirm_password_label"))
        .oneOf(
          [yup.ref("password")],
          t("validation.password_mismatch_error_label"),
        ),
    }),
  );

  const form = useForm<CreateUserFormValues>({
    defaultValues: { roles: [] },
    resolver,
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const onSubmit = async (data: CreateUserModel) => {
    await onSubmitCallback(data);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="create-user-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <form
        aria-label={t("create_user.title")}
        method="post"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <DialogTitle id="create-user-dialog-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{t("create_user.title")}</Box>
            <Box>
              <IconButton
                aria-label={t("common:close")}
                onClick={handleClose}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            {...form.register("username")}
            label={usernameLabel}
            helperText={form.formState.errors.username?.message}
            error={Boolean(form.formState.errors.username)}
          />
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                {...form.register("firstName")}
                label={t("create_user.firstname_label")}
                helperText={form.formState.errors.firstName?.message}
                error={Boolean(form.formState.errors.firstName)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                {...form.register("lastName")}
                label={t("create_user.lastname_label")}
                helperText={form.formState.errors.lastName?.message}
                error={Boolean(form.formState.errors.lastName)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={12}>
              <MultipleSelectField<CreateUserFormValues>
                label={t("create_user.roles_label")}
                control={form.control}
                name="roles"
                fullWidth
                options={roleOptions ?? []}
                error={form.formState.errors?.roles?.message}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <PasswordTextField
                {...form.register("password")}
                error={form.formState.errors?.password?.message}
                label={t("create_user.password_label")}
                visibleLabel={t("create_user.show_password_label")}
                obfuscatedLabel={t("create_user.hide_password_label")}
              />
            </Grid>
            <Grid item sm={6}>
              <PasswordTextField
                {...form.register("confirmPassword")}
                error={form.formState.errors?.confirmPassword?.message}
                label={t("create_user.confirm_password_label")}
                visibleLabel={t("create_user.show_confirm_password_label")}
                obfuscatedLabel={t("create_user.hide_confirm_password_label")}
              />
            </Grid>
          </Grid>

          {error && (
            <Box py={2}>
              <Typography color="error">{error}</Typography>
            </Box>
          )}

          <Box marginTop={3}>
            <PasswordCriteriaAlert />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            {t("common:cancel")}
          </Button>
          <Button
            type="submit"
            disabled={!roleOptions || form.formState.isSubmitting}
            variant="contained"
            color="primary"
          >
            {t("create_user.submit_button")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
