import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";

import { ApiResponse } from "generated";
import { UsersApi } from "generated/admin/apis";
import { CreateUserModel } from "generated/admin/models";

import * as keys from "./keys";

const useCreateUser = (
  options: UseCommandOptions<ApiResponse<void>, ResponseError> = {},
) => {
  const usersApi = useApi(UsersApi);
  const partialMutate = usePartialMutate();

  const createUser = async (model: CreateUserModel) => {
    const response = await usersApi.createUserRaw({
      createUserModel: model,
    });

    return response;
  };

  return useCommand(createUser, {
    ...options,
    onSuccess: (response) => {
      options.onSuccess?.(response);
      partialMutate(keys.list());
    },
  });
};

export { useCreateUser };
