export const all = () => ["customers"] as const;

export const lists = () => [...all(), "list"] as const;

export const list = (params: { query?: string } = {}) =>
  [...lists(), params.query] as const;

export const details = () => [...all(), "detail"] as const;

export const detail = (id: number | string) => [...details(), id] as const;
