import { usePartialMutate, useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { CustomerMemosApi } from "generated/servicing-v2/apis";
import { CreateMemoRequestModel } from "generated/servicing-v2/models";
import { useState } from "react";
import errorHandler from "support/error-handler";
import * as customerMemoKey from "./keys";

interface AddCustomerMemoReturn {
  command: (addMemoModel: CreateMemoRequestModel) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
}

export const useAddCustomerMemo = (
  systemId: string,
  options: { onSuccess?: () => void } = {},
): AddCustomerMemoReturn => {
  const api = useApi(CustomerMemosApi, "v2");
  const error = useResponseError();
  const partialMutate = usePartialMutate();
  const [isLoading, setIsLoading] = useState(false);

  const command = async (
    addMemoModel: CreateMemoRequestModel,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      await api.createMemo({
        id: systemId,
        createMemoRequestModel: addMemoModel,
      });
      options.onSuccess?.();
      partialMutate(customerMemoKey.all(systemId));
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
  };
};
