/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DirectDebitTypeModel } from './DirectDebitTypeModel';
import {
    DirectDebitTypeModelFromJSON,
    DirectDebitTypeModelFromJSONTyped,
    DirectDebitTypeModelToJSON,
} from './DirectDebitTypeModel';

/**
 * Re-present the last direct debit
 * @export
 * @interface RepresentDirectDebitModel
 */
export interface RepresentDirectDebitModel {
    /**
     * 
     * @type {DirectDebitTypeModel}
     * @memberof RepresentDirectDebitModel
     */
    paymentType: DirectDebitTypeModel;
}

/**
 * Check if a given object implements the RepresentDirectDebitModel interface.
 */
export function instanceOfRepresentDirectDebitModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paymentType" in value;

    return isInstance;
}

export function RepresentDirectDebitModelFromJSON(json: any): RepresentDirectDebitModel {
    return RepresentDirectDebitModelFromJSONTyped(json, false);
}

export function RepresentDirectDebitModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepresentDirectDebitModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentType': DirectDebitTypeModelFromJSON(json['paymentType']),
    };
}

export function RepresentDirectDebitModelToJSON(value?: RepresentDirectDebitModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentType': DirectDebitTypeModelToJSON(value.paymentType),
    };
}

