import { FormDialog } from "@ldms/mui-sdk/templates";
import { Box, Chip, MenuItem, TextField, Typography } from "@mui/material";
import { useListAgreementAttachments } from "api/agreements/attachments";
import { useListContactPoints } from "api/customers/contact-points/useListContactPoints";
import { useSendEmail } from "api/email/sendEmail";
import { ControlledTextField, QueryError } from "common/components";
import { useYupResolver } from "common/hooks";
import { useAgreement } from "common/providers";
import { CustomerContactPointTypeModel } from "generated/core/models";
import { CreateEmailModel } from "generated/documents/models";
import { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface SendEmailContainerProps {
  open: boolean;
  onClose: () => void;
  defaultValue?: string;
}

interface TextFieldCharacterCounterProps {
  totalCharacters: number;
  formValue: string;
  message?: string;
}

function TextFieldCharacterCounter({
  totalCharacters,
  formValue,
  message,
}: TextFieldCharacterCounterProps): ReactElement {
  return (
    <Box component="span" display="flex" justifyContent="space-between">
      <Typography component="span" variant="caption">
        {message}
      </Typography>

      <Typography
        color={formValue.length > totalCharacters ? "error" : ""}
        component="span"
        variant="caption"
      >{`${formValue.length} / ${totalCharacters}`}</Typography>
    </Box>
  );
}

export default function SendEmailContainer({
  open,
  onClose: onCloseCallback,
  defaultValue,
}: SendEmailContainerProps): ReactElement {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();

  const toLabel = t("email.to_label");
  const subjectLabel = t("email.subject_label");
  const bodyLabel = t("email.body_label");
  const attachmentsLabel = t("email.attachments_label");

  const resolver = useYupResolver<CreateEmailModel>((yup) =>
    yup.object().shape({
      recipient: yup.string().isRequired(toLabel),
      subject: yup
        .string()
        .isRequired(subjectLabel)
        .maxCharacters(100, subjectLabel),
      body: yup.string().isRequired(bodyLabel).maxCharacters(4000, bodyLabel),
    }),
  );

  const sendEmail = useSendEmail({
    onSuccess: () => {
      onCloseCallback();
    },
  });

  const listContactPoints = useListContactPoints(
    Number(agreement.data?.customerId),
    CustomerContactPointTypeModel.Email,
  );

  const attachments = useListAgreementAttachments(agreement.id, {});

  const findCustomer = (id: number) => {
    const contact = listContactPoints.data?.find((item) => item.id === id);
    return contact?.contactPoints[0].value ?? "";
  };

  const onSubmit = async (data: CreateEmailModel) => {
    const email = findCustomer(Number(data.recipient));
    return sendEmail.execute({
      ...data,
      recipient: email,
      agreementId: agreement.id,
      portfolioId: agreement.data?.portfolioId,
    });
  };

  const onClose = () => {
    sendEmail.reset();
    onCloseCallback();
  };

  return (
    <FormDialog
      onClose={onClose}
      onSubmit={onSubmit}
      title={t("email.title_label")}
      open={open}
      resolver={resolver}
      disabled={sendEmail.isExecuting}
      defaultValues={{
        recipient: "",
        attachments: defaultValue ? [defaultValue] : [],
      }}
      dialogProps={{ maxWidth: "sm" }}
      labels={{
        submit: t("email.send_button"),
      }}
      ready={Boolean(listContactPoints.data ?? listContactPoints.error)}
    >
      {(form) => {
        const formWatch = form.watch();

        if (listContactPoints.error || !listContactPoints.data) {
          return <QueryError onRetry={listContactPoints.refetch} />;
        }

        return (
          <>
            <ControlledTextField
              select
              id="recipient"
              name="recipient"
              label={toLabel}
              helperText={form.formState.errors?.recipient?.message}
              error={form.formState.errors?.recipient?.message}
              SelectProps={{ displayEmpty: true }}
              control={form.control}
              required
            >
              <MenuItem value="">
                <i>{t("common:please_select")}</i>
              </MenuItem>

              {listContactPoints.data?.map((key) => (
                <MenuItem key={key.id} value={key.id}>
                  {`${key.type} - ${key.contactPoints[0].value}`}
                </MenuItem>
              ))}
            </ControlledTextField>

            <TextField
              {...form.register("subject")}
              error={Boolean(form.formState.errors?.subject)}
              fullWidth
              label={subjectLabel}
              helperText={form.formState.errors?.subject?.message}
              required
            />

            <TextField
              {...form.register("body")}
              error={Boolean(form.formState.errors?.body)}
              fullWidth
              label={bodyLabel}
              multiline
              required
              minRows={5}
              maxRows={10}
              helperText={
                <TextFieldCharacterCounter
                  message={form.formState.errors.body?.message}
                  totalCharacters={4000}
                  formValue={formWatch.body ?? ""}
                />
              }
            />
            <Controller
              control={form.control}
              name="attachments"
              render={({ field }): ReactElement => (
                <TextField
                  aria-label={t("email.attachment_list_label")}
                  {...field}
                  inputProps={{
                    multiple: true,
                    renderValue: (selected: string[]) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                          overflowY: "auto",
                          maxHeight: 200,
                        }}
                      >
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              attachments.data?.find(
                                (attachment) => attachment.id === value,
                              )?.filename
                            }
                          />
                        ))}
                      </Box>
                    ),
                  }}
                  select
                  onChange={(...args) => field.onChange(...args)}
                  fullWidth
                  label={attachmentsLabel}
                >
                  {attachments.data?.map((name) => (
                    <MenuItem key={name.id} value={name.id}>
                      {name.filename}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            {sendEmail.error && (
              <Box marginTop={1}>
                <Typography color="error">
                  {t("common:error.default")}
                </Typography>
              </Box>
            )}
          </>
        );
      }}
    </FormDialog>
  );
}
