import { Loader } from "@ldms/mui-sdk/templates";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import { useGetBatch } from "api/batches";
import BatchContentList from "apps/documents/components/BatchContentList";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface BatchContentDialogContainerProps {
  open: boolean;
  onClose(): void;
  paddedBatchReference: string;
  batchId: string;
  batchType: string;
}

export default function BatchContentDialogContainer({
  open,
  onClose,
  paddedBatchReference,
  batchType,
  batchId,
}: Readonly<BatchContentDialogContainerProps>): ReactElement {
  const { t } = useTranslation("documents");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const batchContentList = useGetBatch({
    pageSize: rowsPerPage,
    params: { batchId },
  });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => batchContentList.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  const renderBatchContent = (): ReactElement =>
    batchContentList.error ? (
      <Typography color="error" aria-label={t("common:error.default")}>
        {t("common:error.default")}
      </Typography>
    ) : (
      <Paper>
        <BatchContentList
          data={batchContentList.data?.results}
          loading={batchContentList.isValidating}
        />
        <TablePagination
          component="div"
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          page={batchContentList.paging.page}
          count={batchContentList.data?.paging.total ?? 0}
        />
      </Paper>
    );

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="batch-content-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="batch-content-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {t("batch_content_dialog.title", {
              batchId: paddedBatchReference,
            })}
          </Box>
          <Box>
            <IconButton
              aria-label={t("common:close")}
              onClick={onClose}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box fontSize="small">
          {t("batch_content_dialog.heading", {
            batchType: batchType,
          })}
        </Box>
      </DialogContent>
      <Loader
        ready={Boolean(batchContentList.data ?? batchContentList.error)}
        render={renderBatchContent}
      />
    </Dialog>
  );
}
