import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { TableCellButton } from "@ldms/mui-sdk/components";
import {
  Box,
  Checkbox,
  CircularProgress,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useListLettersToEmail } from "api/documents/letters/email/listLettersToEmail";
import LettersToEmailListFilter from "apps/documents/components/LettersToEmailListFilter/LettersToEmailListFilter";
import VerifyRejectLettersToEmailContainer from "apps/documents/containers/VerifyRejectLettersToEmailContainer";
import { DeliveryStatusEnum, TemplateType } from "apps/documents/types";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
} from "common/components";
import { useLocale } from "common/hooks";
import ListLayout from "common/layouts/ListLayout";
import {
  DeliveryStatusModel,
  LetterListItemModel,
  LetterVerificationModel,
  VerificationLetterItemModel,
} from "generated/documents/models";
import React, {
  ChangeEventHandler,
  MouseEvent,
  ReactElement,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface PendingVerificationFilterProps {
  type?: TemplateType;
  from?: Date;
  to?: Date;
}

interface LettersToEmailListFilterProps {
  status: DeliveryStatusModel;
}

export default function LettersToEmailListContainer({
  status,
}: Readonly<LettersToEmailListFilterProps>): ReactElement {
  const { t } = useTranslation(["documents"]);
  const locale = useLocale();

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [selected, setSelected] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [filters, setFilters] = useState<PendingVerificationFilterProps>({});
  const pendingEmails = useListLettersToEmail({
    pageSize: rowsPerPage,
    params: {
      ...filters,
      from: filters.from,
      to: filters.to,
      status: status,
    },
  });

  const responseBody: LetterVerificationModel = {
    isAllSelected,
    filters: isAllSelected
      ? {
          fromDate: filters.from,
          toDate: filters.to,
          type: filters.type,
        }
      : undefined,
    selectedIds: isAllSelected
      ? undefined
      : selected.map<VerificationLetterItemModel>((id) => ({
          id,
        })),
  };

  const resetSelected = () => {
    setSelected([]);
    setIsAllSelected(false);
  };

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => {
    pendingEmails.paging.setPage(page);
    resetSelected();
  };

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    resetSelected();
    setRowsPerPage(Number(event.target.value));
  };

  const onFilterChange = (key: string, value: string): void => {
    setFilters((current) => ({ ...current, [key]: value }));
    resetSelected();
  };

  const makeSelectPageHandler =
    (data: LetterListItemModel[]) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = data.map((n) => n.id);
        setIsAllSelected(false);
        return setSelected(newSelected);
      }
      return resetSelected();
    };

  const handleClick = (event: React.MouseEvent<unknown>, checkedId: string) => {
    if (selected.includes(checkedId)) {
      setIsAllSelected(false);
      return setSelected(selected.filter((letterId) => letterId !== checkedId));
    }
    setIsAllSelected(false);
    return setSelected([...selected, checkedId]);
  };

  const handleSelectAll = () => {
    const newSelected = pendingEmails.data?.results.map((n) => n.id);
    setIsAllSelected(true);
    setSelected(newSelected ?? []);
  };

  const numberSelected = isAllSelected
    ? pendingEmails.data?.paging.total
    : selected.length;

  const lettersToEmailPermission = "documents:letters-to-email:manage";

  return (
    <ListLayout
      filters={
        <LettersToEmailListFilter filters={filters} onChange={onFilterChange} />
      }
      pagination={{
        onPageChange: onChangePage,
        onRowsPerPageChange: onChangeRowsPerPage,
        rowsPerPage,
        page: pendingEmails.paging.page,
        count: pendingEmails.data?.paging.total ?? 0,
      }}
      actions={
        <AccessControl allowedPermissions={[lettersToEmailPermission]}>
          <VerifyRejectLettersToEmailContainer
            responseBody={responseBody}
            totalSelected={numberSelected ?? 0}
            onSubmitCallback={resetSelected}
          />
        </AccessControl>
      }
      selected={numberSelected}
      onSelectAll={handleSelectAll}
    >
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(pendingEmails.data ?? pendingEmails.error)}
        render={(): ReactElement => {
          if (pendingEmails.error || !pendingEmails.data) {
            return <QueryError onRetry={pendingEmails.refetch} />;
          }

          const tableHeaders: string[] = [
            t("letters_to_email.letters_list.document_name_label"),
            t("letters_to_email.letters_list.portfolio_label"),
            t("letters_to_email.letters_list.type_label"),
            t("letters_to_email.letters_list.agreement_number_label"),
            t("letters_to_email.letters_list.to_label"),
            t("letters_to_email.letters_list.created_label"),
          ];

          const rowCount = pendingEmails.data.results.length;
          const numSelected = selected.length;
          const isSelected = (name: string) => selected.indexOf(name) !== -1;

          status === DeliveryStatusModel.Failed &&
            tableHeaders.push(t("letters_to_email.letters_list.failed_label"));

          status === DeliveryStatusModel.Sent &&
            tableHeaders.push(t("letters_to_email.letters_list.sent_label"));

          return (
            <>
              <Table
                size="small"
                stickyHeader
                aria-label={t(DeliveryStatusEnum[status])}
              >
                <LoadableTableHead
                  headings={tableHeaders}
                  loading={pendingEmails.isValidating}
                  checkbox={
                    status === DeliveryStatusModel.Pending && (
                      <AccessControl
                        allowedPermissions={[lettersToEmailPermission]}
                      >
                        <Checkbox
                          color="primary"
                          disabled={rowCount === 0}
                          indeterminate={
                            numSelected > 0 && numSelected < rowCount
                          }
                          checked={rowCount === numSelected && rowCount !== 0}
                          onChange={makeSelectPageHandler(
                            pendingEmails.data.results,
                          )}
                          inputProps={{
                            "aria-label": t(
                              "letters_to_email.letters_list.mark_all_checkbox",
                            ),
                          }}
                        />
                      </AccessControl>
                    )
                  }
                />

                <TableBody>
                  {pendingEmails.data.results.map((item, index) => {
                    const isItemSelected = isSelected(item.id);

                    return (
                      <TableRow key={item.id}>
                        {status === DeliveryStatusModel.Pending ? (
                          <AccessControl
                            allowedPermissions={[lettersToEmailPermission]}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-label": `letters_to_email.checkbox.${index}`,
                                }}
                                onClick={(event) => handleClick(event, item.id)}
                              />
                            </TableCell>
                          </AccessControl>
                        ) : (
                          <></>
                        )}

                        <TableCellButton
                          sx={{ height: "100%" }}
                          buttonProps={{
                            component: "a",
                            href: `/api/documents/letters/${item.id}`,
                            target: "_blank",
                          }}
                        >
                          <ListItemText
                            primary={item.filename}
                            primaryTypographyProps={{ color: "primary" }}
                          />
                        </TableCellButton>

                        <TableCell>{item.portfolioName}</TableCell>
                        <TableCell>{item.type}</TableCell>
                        <TableCell>{item.agreementNumber}</TableCell>
                        <TableCell>{item.sentTo}</TableCell>
                        <TableCell>
                          {locale.formatDateTime(new Date(item.processedAt))}
                        </TableCell>
                        {status === DeliveryStatusModel.Failed && (
                          <TableCell>
                            {item.failedAt
                              ? locale.formatDateTime(new Date(item.failedAt))
                              : "-"}
                          </TableCell>
                        )}
                        {status === DeliveryStatusModel.Sent && (
                          <TableCell>
                            {item.sentAt
                              ? locale.formatDateTime(new Date(item.sentAt))
                              : "-"}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {pendingEmails.data.results.length === 0 && (
                <NoResults
                  message={t("letters_to_email.letters_list.no_letters")}
                />
              )}
            </>
          );
        }}
      />
    </ListLayout>
  );
}
