import { useSession, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Box } from "@mui/material";
import UnexpectedError from "common/components/UnexpectedError";
import SidebarNavigation from "common/containers/SidebarNavigation";
import SidebarLayout from "common/layouts/SidebarLayout";
import { compose } from "lodash/fp";

import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

function DocumentsLayout() {
  const { t } = useTranslation("documents");
  const session = useSession();

  const makeItem = (label: string, to: string, display = true) => {
    return { label: t(label), to, display };
  };

  const navigationMenu = [
    makeItem(
      "documents_layout.navigation_links.batches",
      "./batches",
      session.hasPermission("documents:batches:view"),
    ),
    makeItem(
      "documents_layout.navigation_links.letters_to_email",
      "./letters-to-email",
      session.hasPermission("documents:letters-to-email:view"),
    ),
  ];

  return (
    <SidebarLayout
      title={t("app_title")}
      sidebar={<SidebarNavigation menu={navigationMenu} />}
    >
      <Box component="main" height="100%">
        <ErrorBoundary FallbackComponent={UnexpectedError}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </SidebarLayout>
  );
}

export default compose(
  withTranslationLoader("documents"),
  withProtectedView({
    allowedPermissions: [
      "documents:batches:view",
      "documents:letters-to-email:view",
    ],
  }),
)(DocumentsLayout);
