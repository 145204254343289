/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Portfolios within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PortfolioListItemModel,
  PortfolioModel,
} from '../models/index';
import {
    PortfolioListItemModelFromJSON,
    PortfolioListItemModelToJSON,
    PortfolioModelFromJSON,
    PortfolioModelToJSON,
} from '../models/index';

export interface GetPortfolioDetailsRequest {
    portfolioId: number;
}

/**
 * 
 */
export class PortfolioQueryApi extends runtime.BaseAPI {

    /**
     * Provides an API to get details of a portfolio
     */
    async getPortfolioDetailsRaw(requestParameters: GetPortfolioDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PortfolioModel>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling getPortfolioDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolios:view"]);
        }

        const response = await this.request({
            path: `/portfolios/{portfolioId}`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioModelFromJSON(jsonValue));
    }

    /**
     * Provides an API to get details of a portfolio
     */
    async getPortfolioDetails(requestParameters: GetPortfolioDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PortfolioModel> {
        const response = await this.getPortfolioDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List portfolios
     */
    async listPortfoliosRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PortfolioListItemModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolios:view"]);
        }

        const response = await this.request({
            path: `/portfolios`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioListItemModelFromJSON));
    }

    /**
     * List portfolios
     */
    async listPortfolios(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PortfolioListItemModel>> {
        const response = await this.listPortfoliosRaw(initOverrides);
        return await response.value();
    }

}
