import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { ErrorModel } from "generated/core/models";

export type ErrorLike = {
  code: string;
  message: string;
  status: number;
  response: Response;
};

export class CustomError extends Error {
  code: string;

  message: string;

  status: number;

  response: Response;

  constructor(
    message: string,
    { code, response }: { code: string; response: Response },
  ) {
    super(message);

    this.code = code;
    this.message = message;
    this.response = response;
    this.status = response.status;
  }
}

function isResponse(response: unknown): response is Response {
  return response instanceof Response;
}

function isResponseError(response: unknown): response is ResponseError {
  return response instanceof ResponseError;
}

export default async function errorHandler(
  responseError: unknown,
): Promise<CustomError> {
  if (isResponseError(responseError)) {
    return responseError;
  }

  if (isResponse(responseError)) {
    let parsed: ErrorModel | null = null;
    let code = "";
    let message = "";

    try {
      parsed = (await responseError.clone().json()) as ErrorModel;
      code = parsed.code;
      message = parsed.message;
    } catch (error) {
      message = await responseError.clone().text();
    }

    return new CustomError(message, { code, response: responseError });
  }

  throw new Error("errorHandler argument must be an instance of Response");
}
