import { Loader } from "@ldms/mui-sdk/templates";
import { MenuItem } from "@mui/material";
import { useListSchedule } from "api/agreements/schedule";
import { QueryError } from "common/components";
import { useLocale } from "common/hooks";
import { ScheduleModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { downloadAsCSV } from "support/download";

interface ExportRepaymentScheduleProps {
  agreementId: number;
}

export default function ExportRepaymentSchedule({
  agreementId,
}: ExportRepaymentScheduleProps): ReactElement {
  const { t } = useTranslation(["agreements"]);
  const schedule = useListSchedule(agreementId);
  const locale = useLocale();

  const scheduleData: ScheduleModel = schedule.data ?? {
    instalments: [],
    b2bInstalments: [],
    totalCapital: "",
    totalInstalmentAmount: "",
    totalInterest: "",
    totalVat: "",
  };

  const b2bLabel = "b2b.b2b_label";

  const headings = [
    t("instalments.number"),
    t("instalments.due_date"),
    t("instalments.instalment_type"),
    t("instalments.capital"),
    t("instalments.interest"),
    t("instalments.vat"),
    t("instalments.repayment"),
    t("instalments.capital_balance"),
    t("instalments.fees"),
    t("instalments.balloon"),
    t("instalments.lease_instalment"),
    t("instalments.periodic_rental"),
    t("instalments.rental_in_advance"),
    t("instalments.commission_uplift"),
    t("instalments.deprecation"),
    t("instalments.current_net_book_value"),
    t("instalments.commission_income"),
    t("instalments.capital_instalment"),
    t("instalments.capital_outstanding"),
    t("instalments.uplift"),
    t("instalments.uplift_maintenance"),
    t("instalments.billing_date"),
    t("instalments.determination"),
    `${t(b2bLabel)} ${t("b2b.instalment_amount_label")}`,
    `${t(b2bLabel)} ${t("b2b.interest_paid_label")}`,
    `${t(b2bLabel)} ${t("b2b.capital_paid_label")}`,
    `${t(b2bLabel)} ${t("b2b.expenses_other_label")}`,
    `${t(b2bLabel)} ${t("b2b.expenses_loss_pool_label")}`,
    `${t(b2bLabel)} ${t("b2b.lender_fees_label")}`,
    `${t(b2bLabel)} ${t("b2b.capital_outstanding_label")}`,
    `${t(b2bLabel)} ${t("instalments.commission_charge")}`,
    `${t(b2bLabel)} ${t("instalments.commission_balance")}`,
  ];

  const handleExport = () => {
    downloadAsCSV(
      [
        headings,
        ...scheduleData.instalments.map((row, index) => {
          const b2bProfile = scheduleData.b2bInstalments.find(
            (instalment) =>
              instalment.instalmentNumber === row.instalmentNumber,
          );
          const data = [
            index + 1,
            row.dueDate && locale.formatISODate(row.dueDate),
            row.type,
            row.capital,
            row.interest,
            row.vat,
            row.instalment,
            row.capitalOutstanding,
            row.fees,
            row.balloon,
            row.leaseInstalment,
            row.periodicRental,
            row.rentalInAdvance,
            row.commissionUplift,
            row.periodicDepreciation,
            row.currentNetBookValue,
            row.commissionIncome,
            row.variableCapitalInstalment,
            row.variableCapitalOutstanding,
            row.instalmentUplift,
            row.instalmentUpliftMaintenance,
            row.billingDate && locale.formatISODate(row.billingDate),
            row.determination && locale.formatISODate(row.determination),
            b2bProfile?.instalmentAmount,
            b2bProfile?.interestPaid,
            b2bProfile?.capitalPaid,
            b2bProfile?.expensesOther,
            b2bProfile?.expensesLossPool,
            b2bProfile?.lenderFeesPaid,
            b2bProfile?.capitalOutstanding,
            b2bProfile?.commissionCharge,
            b2bProfile?.commissionBalance,
          ];

          return data;
        }),
      ],
      {
        filename: `${agreementId}_schedule`,
      },
    );
  };

  return (
    <Loader
      ready={Boolean(schedule.data ?? schedule.error)}
      render={() => {
        if (schedule.error || !schedule.data) {
          return <QueryError onRetry={schedule.refetch} />;
        }

        return (
          <MenuItem
            disabled={
              schedule.isValidating || schedule.data?.instalments.length === 0
            }
            onClick={handleExport}
          >
            {t("repayment_schedule.export_menu_item")}
          </MenuItem>
        );
      }}
    />
  );
}
