import { Loader } from "@ldms/mui-sdk/templates";
import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useListCustomers } from "api/customers";
import { Customer, PaginatedCustomerList } from "common/components";
import { ClientListItemModel } from "generated/core/models";
import { debounce } from "lodash";
import {
  ChangeEventHandler,
  MouseEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface FindCustomerContainerProps {
  open: boolean;
  onClose(): void;
}

export default function FindCustomerContainer({
  open,
  onClose: onCloseCallback,
}: FindCustomerContainerProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const [query, setQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const existingCustomers = useListCustomers({
    pageSize: rowsPerPage,
    params: { query },
  });

  const mapCustomer = (customer: ClientListItemModel): Customer => ({
    ...customer,
    href: `./customers/${customer.id}/create`,
  });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => existingCustomers.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  const onQueryChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    setQuery(event.target.value);
  };

  const onClose = (): void => {
    onCloseCallback();
    setQuery("");
  };

  const handleQueryChange = useMemo(() => debounce(onQueryChange, 300), []);

  useEffect(() => {
    return handleQueryChange.cancel;
  }, [handleQueryChange]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="find-existing-customer-dialog-title"
      open={open}
      maxWidth="xl"
    >
      <DialogTitle id="find-existing-customer-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {t("find_customer_dialog.find_existing_customer_label")}
          </Box>
          <Box>
            <IconButton
              aria-label={t("common:close")}
              onClick={onClose}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box marginY={3}>
          <Box marginBottom={3} width={500}>
            <TextField
              InputProps={{
                endAdornment: <Search />,
              }}
              inputProps={{
                "aria-label": t("find_customer_dialog.search_label"),
              }}
              margin="none"
              onChange={handleQueryChange}
              fullWidth
            />
          </Box>
          {!!query && (
            <Loader
              ready={Boolean(existingCustomers.data || existingCustomers.error)}
              render={(): ReactElement => {
                return (
                  <PaginatedCustomerList
                    data={existingCustomers.data?.results?.map(mapCustomer)}
                    error={existingCustomers.error}
                    rowsPerPage={rowsPerPage}
                    page={existingCustomers.paging.page}
                    total={existingCustomers.data?.paging.total || 0}
                    isLoading={existingCustomers.isValidating}
                    onRetry={existingCustomers.refetch}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                  />
                );
              }}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
