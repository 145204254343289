/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing financial products within onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DayCountConventionModel } from './DayCountConventionModel';
import {
    DayCountConventionModelFromJSON,
    DayCountConventionModelFromJSONTyped,
    DayCountConventionModelToJSON,
} from './DayCountConventionModel';
import type { FinancialProductFeeListItemModel } from './FinancialProductFeeListItemModel';
import {
    FinancialProductFeeListItemModelFromJSON,
    FinancialProductFeeListItemModelFromJSONTyped,
    FinancialProductFeeListItemModelToJSON,
} from './FinancialProductFeeListItemModel';
import type { FrequencyOfInstalmentsModel } from './FrequencyOfInstalmentsModel';
import {
    FrequencyOfInstalmentsModelFromJSON,
    FrequencyOfInstalmentsModelFromJSONTyped,
    FrequencyOfInstalmentsModelToJSON,
} from './FrequencyOfInstalmentsModel';
import type { ProductTypeModel } from './ProductTypeModel';
import {
    ProductTypeModelFromJSON,
    ProductTypeModelFromJSONTyped,
    ProductTypeModelToJSON,
} from './ProductTypeModel';
import type { SettlementTypeModel } from './SettlementTypeModel';
import {
    SettlementTypeModelFromJSON,
    SettlementTypeModelFromJSONTyped,
    SettlementTypeModelToJSON,
} from './SettlementTypeModel';

/**
 * 
 * @export
 * @interface FinancialProductDetailsModel
 */
export interface FinancialProductDetailsModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof FinancialProductDetailsModel
     */
    readonly id: number;
    /**
     * 
     * @type {ProductTypeModel}
     * @memberof FinancialProductDetailsModel
     */
    type: ProductTypeModel;
    /**
     * 
     * @type {string}
     * @memberof FinancialProductDetailsModel
     */
    name: string;
    /**
     * Flag to define how balloon payment is applied
     * @type {boolean}
     * @memberof FinancialProductDetailsModel
     */
    allowBalloonPayment: boolean;
    /**
     * Flag to define how balloon payment is applied
     * @type {boolean}
     * @memberof FinancialProductDetailsModel
     */
    applyBalloonAsLastPayment: boolean;
    /**
     * Flag to define how balloon payment is applied
     * @type {boolean}
     * @memberof FinancialProductDetailsModel
     */
    allowAdvancedPayment: boolean;
    /**
     * 
     * @type {FrequencyOfInstalmentsModel}
     * @memberof FinancialProductDetailsModel
     */
    interestAccrualFrequency: FrequencyOfInstalmentsModel;
    /**
     * 
     * @type {FrequencyOfInstalmentsModel}
     * @memberof FinancialProductDetailsModel
     */
    interestCompoundingFrequency: FrequencyOfInstalmentsModel;
    /**
     * 
     * @type {DayCountConventionModel}
     * @memberof FinancialProductDetailsModel
     */
    dayCountConvention: DayCountConventionModel;
    /**
     * 
     * @type {SettlementTypeModel}
     * @memberof FinancialProductDetailsModel
     */
    settlementType: SettlementTypeModel;
    /**
     * The discount percentage
     * @type {number}
     * @memberof FinancialProductDetailsModel
     */
    discountPercentage: number;
    /**
     * Flag to define whether residual value is allowed or not
     * @type {boolean}
     * @memberof FinancialProductDetailsModel
     */
    allowResidualValue: boolean;
    /**
     * Flag to define whether secondary rental is allowed or not
     * @type {boolean}
     * @memberof FinancialProductDetailsModel
     */
    allowSecondaryRental: boolean;
    /**
     * 
     * @type {Array<FinancialProductFeeListItemModel>}
     * @memberof FinancialProductDetailsModel
     */
    fees?: Array<FinancialProductFeeListItemModel>;
}

/**
 * Check if a given object implements the FinancialProductDetailsModel interface.
 */
export function instanceOfFinancialProductDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "allowBalloonPayment" in value;
    isInstance = isInstance && "applyBalloonAsLastPayment" in value;
    isInstance = isInstance && "allowAdvancedPayment" in value;
    isInstance = isInstance && "interestAccrualFrequency" in value;
    isInstance = isInstance && "interestCompoundingFrequency" in value;
    isInstance = isInstance && "dayCountConvention" in value;
    isInstance = isInstance && "settlementType" in value;
    isInstance = isInstance && "discountPercentage" in value;
    isInstance = isInstance && "allowResidualValue" in value;
    isInstance = isInstance && "allowSecondaryRental" in value;

    return isInstance;
}

export function FinancialProductDetailsModelFromJSON(json: any): FinancialProductDetailsModel {
    return FinancialProductDetailsModelFromJSONTyped(json, false);
}

export function FinancialProductDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialProductDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': ProductTypeModelFromJSON(json['type']),
        'name': json['name'],
        'allowBalloonPayment': json['allowBalloonPayment'],
        'applyBalloonAsLastPayment': json['applyBalloonAsLastPayment'],
        'allowAdvancedPayment': json['allowAdvancedPayment'],
        'interestAccrualFrequency': FrequencyOfInstalmentsModelFromJSON(json['interestAccrualFrequency']),
        'interestCompoundingFrequency': FrequencyOfInstalmentsModelFromJSON(json['interestCompoundingFrequency']),
        'dayCountConvention': DayCountConventionModelFromJSON(json['dayCountConvention']),
        'settlementType': SettlementTypeModelFromJSON(json['settlementType']),
        'discountPercentage': json['discountPercentage'],
        'allowResidualValue': json['allowResidualValue'],
        'allowSecondaryRental': json['allowSecondaryRental'],
        'fees': !exists(json, 'fees') ? undefined : ((json['fees'] as Array<any>).map(FinancialProductFeeListItemModelFromJSON)),
    };
}

export function FinancialProductDetailsModelToJSON(value?: FinancialProductDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ProductTypeModelToJSON(value.type),
        'name': value.name,
        'allowBalloonPayment': value.allowBalloonPayment,
        'applyBalloonAsLastPayment': value.applyBalloonAsLastPayment,
        'allowAdvancedPayment': value.allowAdvancedPayment,
        'interestAccrualFrequency': FrequencyOfInstalmentsModelToJSON(value.interestAccrualFrequency),
        'interestCompoundingFrequency': FrequencyOfInstalmentsModelToJSON(value.interestCompoundingFrequency),
        'dayCountConvention': DayCountConventionModelToJSON(value.dayCountConvention),
        'settlementType': SettlementTypeModelToJSON(value.settlementType),
        'discountPercentage': value.discountPercentage,
        'allowResidualValue': value.allowResidualValue,
        'allowSecondaryRental': value.allowSecondaryRental,
        'fees': value.fees === undefined ? undefined : ((value.fees as Array<any>).map(FinancialProductFeeListItemModelToJSON)),
    };
}

