import { keys } from "api/agreements/transactions";
import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import { PayFeesModel } from "generated/core/models";
import { useState } from "react";
import errorHandler from "support/error-handler";
import { useSWRConfig } from "swr";

interface UsePayFeesReturn {
  command: (fees: PayFeesModel) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
  resetError(): void;
}

export const usePayFees = (
  agreementId: number,
  options: { onSuccess?: () => void } = {},
): UsePayFeesReturn => {
  const api = useApi(AgreementCommandApi);
  const swr = useSWRConfig();
  const error = useResponseError();
  const [isLoading, setIsLoading] = useState(false);

  const command = async (fees: PayFeesModel): Promise<void> => {
    try {
      setIsLoading(true);
      await api.payFees({
        agreementId: agreementId,
        payFeesModel: fees,
      });
      options.onSuccess?.();
      swr.mutate(keys.list(agreementId));
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
    resetError: error.reset,
  };
};
