import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { DescriptionList } from "@ldms/mui-sdk/components";
import { Avatar, Box } from "@mui/material";
import { useGetUser } from "api/users/";
import UpdateUserContainer from "apps/admin/containers/UpdateUserContainer";
import ResetPasswordContainer from "apps/servicing/modules/settings/containers/ResetPasswordContainer";
import { Loader, QueryError } from "common/components";
import Surface from "common/components/Surface";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

interface UserDetailsViewProps {
  userId: string;
}

function UserDetailsView({ userId }: Readonly<UserDetailsViewProps>) {
  const { t } = useTranslation("users");

  const user = useGetUser(userId);
  const appConfig = useAppConfiguration();

  return (
    <ViewLayout
      breadcrumbs={[
        { href: `${appConfig.appRoutes.admin}/users`, label: t("title") },
      ]}
      title={[user.data?.firstName, user.data?.lastName].join(" ")}
      action={user.data && <ResetPasswordContainer user={user.data} />}
    >
      <Loader
        ready={Boolean(user.data ?? user.error)}
        render={() => {
          if (user.error || !user.data) {
            return <QueryError onRetry={user.refetch} />;
          }

          return (
            <ContainedLayout>
              <Surface
                title={t("details.title_label")}
                action={user.data && <UpdateUserContainer user={user.data} />}
              >
                <Box
                  display="grid"
                  gridTemplateColumns="min-content 1fr"
                  gap={2}
                >
                  <Box padding={2}>
                    <Avatar
                      sx={{
                        height: "100px",
                        width: "100px",
                        fontSize: "30px",
                      }}
                    >
                      {[user.data.firstName, user.data.lastName]
                        .map((n) => n.charAt(0).toUpperCase())
                        .join("")}
                    </Avatar>
                  </Box>
                  <DescriptionList label={t("details.title_label")}>
                    <DescriptionList.Item label={t("details.name_label")}>
                      {[user.data.firstName, user.data.lastName].join(" ")}
                    </DescriptionList.Item>
                    <DescriptionList.Item label={t("details.status_label")}>
                      {user.data?.active
                        ? t("details.active")
                        : t("details.inactive")}
                    </DescriptionList.Item>
                    <DescriptionList.Item label={t("details.email_label")}>
                      {user.data.email}
                    </DescriptionList.Item>
                    <DescriptionList.Item label={t("details.username_label")}>
                      {user.data.username}
                    </DescriptionList.Item>
                    <DescriptionList.Item label={t("details.roles_label")}>
                      {user.data.roles.map((r) => r.name).join(", ")}
                    </DescriptionList.Item>
                  </DescriptionList>
                </Box>
              </Surface>
            </ContainedLayout>
          );
        }}
      />
    </ViewLayout>
  );
}

export default withProtectedView({
  allowedPermissions: ["admin:users:manage"],
})(UserDetailsView);
