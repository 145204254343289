import useQuery, {
  UseQueryOptions,
  UseQueryReturn,
} from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { BatchQueryApi } from "generated/documents/apis";
import { BatchTypeModel } from "generated/documents/models";
import { list } from "./keys";

type UseListBatchesTypes = (
  options?: UseQueryOptions,
) => UseQueryReturn<BatchTypeModel[]>;

export const useListBatchesTypes: UseListBatchesTypes = (options = {}) => {
  const api = useApi(BatchQueryApi);
  const key = list();

  return useQuery(
    key,
    () => {
      return api.listBatchTypes();
    },
    options,
  );
};
