/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Financial Postings within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddFinancialPostingModel,
} from '../models/index';
import {
    AddFinancialPostingModelFromJSON,
    AddFinancialPostingModelToJSON,
} from '../models/index';

export interface AddFinancialPostingRequest {
    addFinancialPostingModel?: AddFinancialPostingModel;
}

/**
 * 
 */
export class FinancialPostingCommandApi extends runtime.BaseAPI {

    /**
     * Add a financial posting
     */
    async addFinancialPostingRaw(requestParameters: AddFinancialPostingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["financial-postings:elevated"]);
        }

        const response = await this.request({
            path: `/financial-postings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFinancialPostingModelToJSON(requestParameters.addFinancialPostingModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a financial posting
     */
    async addFinancialPosting(requestParameters: AddFinancialPostingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addFinancialPostingRaw(requestParameters, initOverrides);
    }

}
