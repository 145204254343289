import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { useListAgreementAssets } from "api/agreements/assets";
import { useListSettlementQuotes } from "api/agreements/settlement-quotes";
import { useCreateAssetSettlementQuote } from "api/assets/settlement-quotes/createAssetSettlementQuote";
import { useGetAssetSettlementQuoteSummary } from "api/assets/settlement-quotes/getAssetSettlementQuoteSummary";
import { useListAssetSettlementQuotes } from "api/assets/settlement-quotes/listAssetSettlementQuotes";
import AssetSettlementQuoteSummary from "apps/servicing/components/AssetSettlementQuoteSummary/AssetSettlementQuoteSummary";
import CreateSettlementQuoteFormControls from "apps/servicing/components/CreateSettlementQuoteFormControls";
import { CreateSettlementQuotesFieldValues } from "apps/servicing/components/CreateSettlementQuoteFormControls/CreateSettlementQuoteFormControls";
import { useSettlementQuotesResolver } from "apps/servicing/hooks";
import {
  AssetSettlementQuoteSummaryModel,
  CreateAssetSettlementQuoteModel,
  DiscountTypeModel,
  SettlementStatusModel,
} from "generated/core/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface CreateAssetSettlementQuoteContainerProps {
  assetId: number;
  agreementId: number;
}

const errorLabel = "common:error.default";

interface AssetSettlementQuoteFormProps {
  assetSettlementSummary: AssetSettlementQuoteSummaryModel;
  onClose: () => void;
  open: boolean;
  assetId: number;
  assetListLength: number;
  agreementId: number;
}

function AssetSettlementQuoteForm({
  assetSettlementSummary,
  onClose,
  open,
  assetId,
  assetListLength,
  agreementId,
}: AssetSettlementQuoteFormProps) {
  const { t } = useTranslation("assets");

  const createAssetSettlementQuote = useCreateAssetSettlementQuote(assetId, {
    onSuccess: onClose,
  });

  const totalAmountDue = Number(assetSettlementSummary.totalAmountDue);
  const resolver = useSettlementQuotesResolver(
    Number(totalAmountDue.toFixed(2)),
  );

  const responseError = new Map([
    [
      "invalid_settlement_date",
      t("settlement_quotes.create_quote.invalid_settlement_date_error_message"),
    ],
    [
      "asset_settlement_quote_exists",
      t("settlement_quotes.create_quote.asset_settlement_exists"),
    ],
    [
      "outstanding_arrears",
      t("settlement_quotes.create_quote.outstanding_arrears_exists"),
    ],
    [
      "settlement_quote_exists",
      t("settlement_quotes.create_quote.agreement_settlement_exists"),
    ],
    [
      "invalid_instalment_period",
      t(
        "settlement_quotes.create_quote.invalid_instalment_period_error_message",
      ),
    ],
    [
      "invalid_settlement_no_current_schedule",
      t(
        "settlement_quotes.create_quote.invalid_settlement_no_current_schedule_error_message",
      ),
    ],
  ]);

  const agreementSettlementList = useListSettlementQuotes(agreementId);
  const agreementSettlementExists = [
    SettlementStatusModel.Approved,
    SettlementStatusModel.Quoted,
  ].some(
    (status) =>
      agreementSettlementList.data?.map((s) => s.status).includes(status),
  );

  const assetSettlementList = useListAssetSettlementQuotes(assetId);
  const assetSettlementExists = [
    SettlementStatusModel.Approved,
    SettlementStatusModel.Quoted,
  ].some(
    (status) => assetSettlementList.data?.map((s) => s.status).includes(status),
  );

  const getPreventativeWarningMessage = () => {
    if (assetListLength === 1) {
      return t("settlement_quotes.create_quote.one_asset_available");
    }
    if (assetSettlementExists) {
      return t("settlement_quotes.create_quote.asset_settlement_exists");
    }
    if (agreementSettlementExists) {
      return t("settlement_quotes.create_quote.agreement_settlement_exists");
    }
    if (assetSettlementSummary.inDdWindow) {
      return t(
        "settlement_quotes.create_quote.invalid_settlement_date_error_message",
      );
    }
    if (Number(assetSettlementSummary.outstandingArrears) > 0) {
      return t("settlement_quotes.create_quote.outstanding_arrears_exists");
    }
    return false;
  };

  const warningMessage = getPreventativeWarningMessage();

  const onSubmit = async (
    data: CreateSettlementQuotesFieldValues,
  ): Promise<void> => {
    const createAssetSettlementQuoteModel: CreateAssetSettlementQuoteModel = {
      ...data,
      discountType: (data.discountType as DiscountTypeModel)
        ? data.discountType
        : undefined,
      discountAmount:
        data.discountType === DiscountTypeModel.Amount
          ? (data.discountAmount ?? 0).toFixed(2)
          : undefined,
      discountPercentage:
        data.discountType === DiscountTypeModel.Percentage
          ? (data.discountPercentage ?? 0).toFixed(2)
          : undefined,
    };
    await createAssetSettlementQuote.execute(createAssetSettlementQuoteModel);
  };

  return (
    <FormDialog
      title={t("settlement_quotes.create_quote.create_title")}
      onClose={onClose}
      open={open}
      onSubmit={onSubmit}
      resolver={resolver}
      disabled={
        Boolean(warningMessage) || createAssetSettlementQuote.isExecuting
      }
      formProps={{
        options: { shouldUnregister: true },
      }}
    >
      {(form) => {
        const formWatch = form.watch();
        const watchDiscountPercentageAmount = Number(
          (totalAmountDue *
            Number(
              formWatch.discountPercentage ? formWatch.discountPercentage : 0,
            )) /
            100,
        );

        const watchDiscount =
          formWatch.discountType === DiscountTypeModel.Amount
            ? Number(formWatch.discountAmount ?? 0)
            : watchDiscountPercentageAmount;

        return (
          <>
            {Boolean(warningMessage) && (
              <Alert severity="warning">{warningMessage}</Alert>
            )}

            {!Boolean(warningMessage) && assetSettlementSummary.hasSubsidy && (
              <Alert severity="warning">
                {t("settlement_quotes.create_quote.subsidy_exists")}
              </Alert>
            )}

            <Stack gap={2}>
              <CreateSettlementQuoteFormControls {...form} />

              <AssetSettlementQuoteSummary
                settlementQuoteSummary={assetSettlementSummary}
                discountField={watchDiscount}
              />

              {createAssetSettlementQuote.error && (
                <Typography color="error" aria-label="form.error">
                  {responseError.get(createAssetSettlementQuote.error.code) ??
                    t("common:error.default")}
                </Typography>
              )}
            </Stack>
          </>
        );
      }}
    </FormDialog>
  );
}

export default function CreateAssetSettlementQuoteContainer({
  assetId,
  agreementId,
}: CreateAssetSettlementQuoteContainerProps) {
  const { t } = useTranslation("assets");
  const assetSettlementSummary = useGetAssetSettlementQuoteSummary(assetId);
  const [
    openCreateAssetSettlementQuoteDialog,
    setOpenCreateAssetSettlementQuoteDialog,
  ] = useState(false);

  const openDialog = (): void => {
    setOpenCreateAssetSettlementQuoteDialog(true);
  };

  const closeDialog = (): void => {
    setOpenCreateAssetSettlementQuoteDialog(false);
  };

  const agreementAssets = useListAgreementAssets(agreementId, { live: true });

  return (
    <>
      <AccessControl
        allowedPermissions={["servicing:asset-settlement-quotes:manage"]}
      >
        <Button variant="contained" onClick={openDialog}>
          {t("settlement_quotes.create_quote.create_button")}
        </Button>
      </AccessControl>

      {assetSettlementSummary.data && agreementAssets.data ? (
        <AssetSettlementQuoteForm
          assetSettlementSummary={assetSettlementSummary.data}
          assetListLength={agreementAssets.data.length}
          open={openCreateAssetSettlementQuoteDialog}
          onClose={closeDialog}
          assetId={assetId}
          agreementId={agreementId}
        />
      ) : (
        <FormDialog
          title={t("settlement_quotes.create_quote.create_title")}
          onClose={closeDialog}
          ready={Boolean(assetSettlementSummary.error ?? agreementAssets.error)}
          open={openCreateAssetSettlementQuoteDialog}
          onSubmit={closeDialog}
          disabled={true}
        >
          {(): ReactElement => {
            if (assetSettlementSummary.error) {
              return <Typography color="error">{t(errorLabel)}</Typography>;
            }
            return <Typography color="error">{t(errorLabel)}</Typography>;
          }}
        </FormDialog>
      )}
    </>
  );
}
