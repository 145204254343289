/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing termination within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TerminationRecoveryDetailsModel,
} from '../models/index';
import {
    TerminationRecoveryDetailsModelFromJSON,
    TerminationRecoveryDetailsModelToJSON,
} from '../models/index';

export interface GetTerminationRecoveryDetailsRequest {
    terminationId: number;
}

/**
 * 
 */
export class TerminationQueryApi extends runtime.BaseAPI {

    /**
     * Get termination recovery details
     */
    async getTerminationRecoveryDetailsRaw(requestParameters: GetTerminationRecoveryDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TerminationRecoveryDetailsModel>> {
        if (requestParameters.terminationId === null || requestParameters.terminationId === undefined) {
            throw new runtime.RequiredError('terminationId','Required parameter requestParameters.terminationId was null or undefined when calling getTerminationRecoveryDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["terminations:view"]);
        }

        const response = await this.request({
            path: `/terminations/{terminationId}`.replace(`{${"terminationId"}}`, encodeURIComponent(String(requestParameters.terminationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TerminationRecoveryDetailsModelFromJSON(jsonValue));
    }

    /**
     * Get termination recovery details
     */
    async getTerminationRecoveryDetails(requestParameters: GetTerminationRecoveryDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TerminationRecoveryDetailsModel> {
        const response = await this.getTerminationRecoveryDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
