import { useQuery, UseQueryReturn } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/assets/types/keys";
import { useApi } from "common/providers/ApiProvider";
import { AssetQueryApi } from "generated/core/apis/AssetQueryApi";
import { AssetTypeModel } from "generated/core/models/AssetTypeModel";
import { ErrorLike } from "support/error-handler";

type UseListAssetTypes = () => UseQueryReturn<AssetTypeModel[], ErrorLike>;

export const useListAssetTypes: UseListAssetTypes = () => {
  const api = useApi(AssetQueryApi);

  return useQuery(keys.list(), () => {
    return api.listAssetTypes();
  });
};
