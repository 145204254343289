/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomInstalmentModel } from './CustomInstalmentModel';
import {
    CustomInstalmentModelFromJSON,
    CustomInstalmentModelFromJSONTyped,
    CustomInstalmentModelToJSON,
} from './CustomInstalmentModel';
import type { FrequencyOfInstalmentsModel } from './FrequencyOfInstalmentsModel';
import {
    FrequencyOfInstalmentsModelFromJSON,
    FrequencyOfInstalmentsModelFromJSONTyped,
    FrequencyOfInstalmentsModelToJSON,
} from './FrequencyOfInstalmentsModel';
import type { InterestCalculationRatesModel } from './InterestCalculationRatesModel';
import {
    InterestCalculationRatesModelFromJSON,
    InterestCalculationRatesModelFromJSONTyped,
    InterestCalculationRatesModelToJSON,
} from './InterestCalculationRatesModel';
import type { MonthlyInstalmentModel } from './MonthlyInstalmentModel';
import {
    MonthlyInstalmentModelFromJSON,
    MonthlyInstalmentModelFromJSONTyped,
    MonthlyInstalmentModelToJSON,
} from './MonthlyInstalmentModel';
import type { PaymentStructureModel } from './PaymentStructureModel';
import {
    PaymentStructureModelFromJSON,
    PaymentStructureModelFromJSONTyped,
    PaymentStructureModelToJSON,
} from './PaymentStructureModel';

/**
 * 
 * @export
 * @interface RepaymentTermsModel
 */
export interface RepaymentTermsModel {
    /**
     * 
     * @type {PaymentStructureModel}
     * @memberof RepaymentTermsModel
     */
    paymentStructure: PaymentStructureModel;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof RepaymentTermsModel
     */
    balanceFinanced?: string;
    /**
     * Total number of instalments
     * @type {number}
     * @memberof RepaymentTermsModel
     */
    totalNumberOfInstalments: number;
    /**
     * The term of the agreement in months
     * @type {number}
     * @memberof RepaymentTermsModel
     */
    term: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof RepaymentTermsModel
     */
    advancedPayment?: string;
    /**
     * 
     * @type {FrequencyOfInstalmentsModel}
     * @memberof RepaymentTermsModel
     */
    frequencyOfInstalments?: FrequencyOfInstalmentsModel;
    /**
     * A format for dates
     * @type {Date}
     * @memberof RepaymentTermsModel
     */
    inceptionDate: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof RepaymentTermsModel
     */
    firstInstalmentDueDate: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof RepaymentTermsModel
     */
    instalmentAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof RepaymentTermsModel
     */
    secondaryRentalPeriodAmount?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof RepaymentTermsModel
     */
    secondaryRentalDueDate?: Date;
    /**
     * 
     * @type {InterestCalculationRatesModel}
     * @memberof RepaymentTermsModel
     */
    interestCalculationRates: InterestCalculationRatesModel;
    /**
     * 
     * @type {Array<MonthlyInstalmentModel>}
     * @memberof RepaymentTermsModel
     */
    instalmentMonths?: Array<MonthlyInstalmentModel>;
    /**
     * 
     * @type {Array<CustomInstalmentModel>}
     * @memberof RepaymentTermsModel
     */
    customInstalments?: Array<CustomInstalmentModel>;
}

/**
 * Check if a given object implements the RepaymentTermsModel interface.
 */
export function instanceOfRepaymentTermsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paymentStructure" in value;
    isInstance = isInstance && "totalNumberOfInstalments" in value;
    isInstance = isInstance && "term" in value;
    isInstance = isInstance && "inceptionDate" in value;
    isInstance = isInstance && "firstInstalmentDueDate" in value;
    isInstance = isInstance && "interestCalculationRates" in value;

    return isInstance;
}

export function RepaymentTermsModelFromJSON(json: any): RepaymentTermsModel {
    return RepaymentTermsModelFromJSONTyped(json, false);
}

export function RepaymentTermsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepaymentTermsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentStructure': PaymentStructureModelFromJSON(json['paymentStructure']),
        'balanceFinanced': !exists(json, 'balanceFinanced') ? undefined : json['balanceFinanced'],
        'totalNumberOfInstalments': json['totalNumberOfInstalments'],
        'term': json['term'],
        'advancedPayment': !exists(json, 'advancedPayment') ? undefined : json['advancedPayment'],
        'frequencyOfInstalments': !exists(json, 'frequencyOfInstalments') ? undefined : FrequencyOfInstalmentsModelFromJSON(json['frequencyOfInstalments']),
        'inceptionDate': (new Date(json['inceptionDate'])),
        'firstInstalmentDueDate': (new Date(json['firstInstalmentDueDate'])),
        'instalmentAmount': !exists(json, 'instalmentAmount') ? undefined : json['instalmentAmount'],
        'secondaryRentalPeriodAmount': !exists(json, 'secondaryRentalPeriodAmount') ? undefined : json['secondaryRentalPeriodAmount'],
        'secondaryRentalDueDate': !exists(json, 'secondaryRentalDueDate') ? undefined : (new Date(json['secondaryRentalDueDate'])),
        'interestCalculationRates': InterestCalculationRatesModelFromJSON(json['interestCalculationRates']),
        'instalmentMonths': !exists(json, 'instalmentMonths') ? undefined : ((json['instalmentMonths'] as Array<any>).map(MonthlyInstalmentModelFromJSON)),
        'customInstalments': !exists(json, 'customInstalments') ? undefined : ((json['customInstalments'] as Array<any>).map(CustomInstalmentModelFromJSON)),
    };
}

export function RepaymentTermsModelToJSON(value?: RepaymentTermsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentStructure': PaymentStructureModelToJSON(value.paymentStructure),
        'balanceFinanced': value.balanceFinanced,
        'totalNumberOfInstalments': value.totalNumberOfInstalments,
        'term': value.term,
        'advancedPayment': value.advancedPayment,
        'frequencyOfInstalments': FrequencyOfInstalmentsModelToJSON(value.frequencyOfInstalments),
        'inceptionDate': (value.inceptionDate.toISOString().substring(0,10)),
        'firstInstalmentDueDate': (value.firstInstalmentDueDate.toISOString().substring(0,10)),
        'instalmentAmount': value.instalmentAmount,
        'secondaryRentalPeriodAmount': value.secondaryRentalPeriodAmount,
        'secondaryRentalDueDate': value.secondaryRentalDueDate === undefined ? undefined : (value.secondaryRentalDueDate.toISOString().substring(0,10)),
        'interestCalculationRates': InterestCalculationRatesModelToJSON(value.interestCalculationRates),
        'instalmentMonths': value.instalmentMonths === undefined ? undefined : ((value.instalmentMonths as Array<any>).map(MonthlyInstalmentModelToJSON)),
        'customInstalments': value.customInstalments === undefined ? undefined : ((value.customInstalments as Array<any>).map(CustomInstalmentModelToJSON)),
    };
}

