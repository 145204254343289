/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplyPaymentHolidayModel
 */
export interface ApplyPaymentHolidayModel {
    /**
     * Denotes the number of first instalment
     * @type {number}
     * @memberof ApplyPaymentHolidayModel
     */
    instalmentNumber: number;
    /**
     * Denotes the number of instalments for the holiday
     * @type {number}
     * @memberof ApplyPaymentHolidayModel
     */
    numberOfInstalments: number;
    /**
     * Denotes whether the payment holiday is full or interest only
     * @type {boolean}
     * @memberof ApplyPaymentHolidayModel
     */
    isFullPaymentHoliday: boolean;
}

/**
 * Check if a given object implements the ApplyPaymentHolidayModel interface.
 */
export function instanceOfApplyPaymentHolidayModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "instalmentNumber" in value;
    isInstance = isInstance && "numberOfInstalments" in value;
    isInstance = isInstance && "isFullPaymentHoliday" in value;

    return isInstance;
}

export function ApplyPaymentHolidayModelFromJSON(json: any): ApplyPaymentHolidayModel {
    return ApplyPaymentHolidayModelFromJSONTyped(json, false);
}

export function ApplyPaymentHolidayModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyPaymentHolidayModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instalmentNumber': json['instalmentNumber'],
        'numberOfInstalments': json['numberOfInstalments'],
        'isFullPaymentHoliday': json['isFullPaymentHoliday'],
    };
}

export function ApplyPaymentHolidayModelToJSON(value?: ApplyPaymentHolidayModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instalmentNumber': value.instalmentNumber,
        'numberOfInstalments': value.numberOfInstalments,
        'isFullPaymentHoliday': value.isFullPaymentHoliday,
    };
}

