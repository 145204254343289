import { Table, TableBody } from "@mui/material";
import React, { Children, cloneElement, ReactElement } from "react";

interface KeyValueTableProps {
  children: ReactElement | ReactElement[];
  colSpan?: number;
  testId: string;
  label?: string;
  colWidth?: number;
}

export default function KeyValueTable({
  children,
  colSpan,
  testId,
  label,
  colWidth = 200,
}: KeyValueTableProps): ReactElement {
  return (
    <Table size="small" data-testid={testId} aria-label={label}>
      <colgroup>
        <col width={colWidth} />
      </colgroup>
      <TableBody>
        {Children.map(children, (child) =>
          cloneElement(child, colSpan ? { colSpan } : {}),
        )}
      </TableBody>
    </Table>
  );
}
