/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VersionListItemModel } from './VersionListItemModel';
import {
    VersionListItemModelFromJSON,
    VersionListItemModelFromJSONTyped,
    VersionListItemModelToJSON,
} from './VersionListItemModel';

/**
 * 
 * @export
 * @interface TemplateModel
 */
export interface TemplateModel {
    /**
     * The template type
     * @type {string}
     * @memberof TemplateModel
     */
    type: string;
    /**
     * The template type
     * @type {string}
     * @memberof TemplateModel
     */
    documentType: string;
    /**
     * The description detailing the template type
     * @type {string}
     * @memberof TemplateModel
     */
    description: string;
    /**
     * 
     * @type {Array<VersionListItemModel>}
     * @memberof TemplateModel
     */
    versions: Array<VersionListItemModel>;
    /**
     * Flag denoting if the template is active or not
     * @type {boolean}
     * @memberof TemplateModel
     */
    active: boolean;
}

/**
 * Check if a given object implements the TemplateModel interface.
 */
export function instanceOfTemplateModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "documentType" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "versions" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function TemplateModelFromJSON(json: any): TemplateModel {
    return TemplateModelFromJSONTyped(json, false);
}

export function TemplateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'documentType': json['documentType'],
        'description': json['description'],
        'versions': ((json['versions'] as Array<any>).map(VersionListItemModelFromJSON)),
        'active': json['active'],
    };
}

export function TemplateModelToJSON(value?: TemplateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'documentType': value.documentType,
        'description': value.description,
        'versions': ((value.versions as Array<any>).map(VersionListItemModelToJSON)),
        'active': value.active,
    };
}

