/* tslint:disable */
/* eslint-disable */
/**
 * Engage Admin
 * API for managing roles within the Engage Admin application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PermissionModel } from './PermissionModel';
import {
    PermissionModelFromJSON,
    PermissionModelFromJSONTyped,
    PermissionModelToJSON,
} from './PermissionModel';

/**
 * 
 * @export
 * @interface DomainModel
 */
export interface DomainModel {
    /**
     * The name of role permission's domain
     * @type {string}
     * @memberof DomainModel
     */
    name: string;
    /**
     * The list of the domain's role permissions
     * @type {Array<PermissionModel>}
     * @memberof DomainModel
     */
    permissions: Array<PermissionModel>;
}

/**
 * Check if a given object implements the DomainModel interface.
 */
export function instanceOfDomainModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function DomainModelFromJSON(json: any): DomainModel {
    return DomainModelFromJSONTyped(json, false);
}

export function DomainModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'permissions': ((json['permissions'] as Array<any>).map(PermissionModelFromJSON)),
    };
}

export function DomainModelToJSON(value?: DomainModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'permissions': ((value.permissions as Array<any>).map(PermissionModelToJSON)),
    };
}

