/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing settlement quotes within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SettlementStatusModel } from './SettlementStatusModel';
import {
    SettlementStatusModelFromJSON,
    SettlementStatusModelFromJSONTyped,
    SettlementStatusModelToJSON,
} from './SettlementStatusModel';

/**
 * List of settlement quotes
 * @export
 * @interface SettlementQuoteListItemModel
 */
export interface SettlementQuoteListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof SettlementQuoteListItemModel
     */
    readonly id: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof SettlementQuoteListItemModel
     */
    settlementDate: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteListItemModel
     */
    amount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SettlementQuoteListItemModel
     */
    discount: string;
    /**
     * 
     * @type {SettlementStatusModel}
     * @memberof SettlementQuoteListItemModel
     */
    status: SettlementStatusModel;
    /**
     * A format for dates
     * @type {Date}
     * @memberof SettlementQuoteListItemModel
     */
    expiryDate: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof SettlementQuoteListItemModel
     */
    quotedDate: Date;
    /**
     * the name of the user who applied the settled quote
     * @type {string}
     * @memberof SettlementQuoteListItemModel
     */
    quotedBy: string;
}

/**
 * Check if a given object implements the SettlementQuoteListItemModel interface.
 */
export function instanceOfSettlementQuoteListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "settlementDate" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "expiryDate" in value;
    isInstance = isInstance && "quotedDate" in value;
    isInstance = isInstance && "quotedBy" in value;

    return isInstance;
}

export function SettlementQuoteListItemModelFromJSON(json: any): SettlementQuoteListItemModel {
    return SettlementQuoteListItemModelFromJSONTyped(json, false);
}

export function SettlementQuoteListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementQuoteListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'settlementDate': (new Date(json['settlementDate'])),
        'amount': json['amount'],
        'discount': json['discount'],
        'status': SettlementStatusModelFromJSON(json['status']),
        'expiryDate': (new Date(json['expiryDate'])),
        'quotedDate': (new Date(json['quotedDate'])),
        'quotedBy': json['quotedBy'],
    };
}

export function SettlementQuoteListItemModelToJSON(value?: SettlementQuoteListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settlementDate': (value.settlementDate.toISOString().substring(0,10)),
        'amount': value.amount,
        'discount': value.discount,
        'status': SettlementStatusModelToJSON(value.status),
        'expiryDate': (value.expiryDate.toISOString().substring(0,10)),
        'quotedDate': (value.quotedDate.toISOString().substring(0,10)),
        'quotedBy': value.quotedBy,
    };
}

