import { AmountField } from "@ldms/mui-sdk/forms";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Control, FieldValues } from "support/react-hook-form";

interface CashDepositProps<TFormValues extends FieldValues> {
  control: Control<TFormValues>;
  error: boolean;
  helperText?: string;
  defaultValue?: number;
}

function CashDepositField<TFormValues extends FieldValues>({
  control,
  error,
  helperText,
  defaultValue,
}: CashDepositProps<TFormValues>): ReactElement {
  const { t } = useTranslation("onboardings");
  return (
    <AmountField
      control={control}
      error={error}
      helperText={helperText}
      label={t("financial_details.cash_deposit_label")}
      name="cashDeposit"
      defaultValue={defaultValue}
    />
  );
}

export default CashDepositField;
