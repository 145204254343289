import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { CustomerContactPointsApi } from "generated/servicing-v2/apis";
import { ContactsContactPointsApi } from "generated/servicing-v2/apis/ContactsContactPointsApi";
import { ContactPointListItemModel } from "generated/servicing-v2/models";
import { keys } from ".";

type UseListContactPoints = (
  id: string,
  forCustomer: boolean,
) => UseQueryReturn<ContactPointListItemModel[]>;

export const useListContactPoints: UseListContactPoints = (
  id: string,
  forCustomer,
) => {
  const customerApi = useApi(CustomerContactPointsApi, "v2");
  const contactApi = useApi(ContactsContactPointsApi, "v2");
  return useQuery(keys.list(id), () => {
    if (forCustomer) {
      return customerApi.listCustomerContactPoints({
        id,
      });
    } else {
      return contactApi.listContactContactPoints({
        id: id,
      });
    }
  });
};
