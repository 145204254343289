import { DateField, Form } from "@ldms/mui-sdk/forms";
import { Box, Divider, MenuItem, TextField, Typography } from "@mui/material";
import { OnboardingStepActions } from "apps/onboarding/components";
import { useOnboarding } from "apps/onboarding/providers";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import { useStepper } from "common/providers/StepperProvider";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface CompanyDetailsFormModel {
  companyType: string;
  companyName: string;
  tradingName: string;
  companyRegistrationNumber?: string;
  vatRegistrationNumber?: string;
  companySize?: string;
  sectorOfEconomicActivity?: string;
  companySector?: string;
  title: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  dateOfBirth?: Date;
  nationalIdentificationNumber?: string;
}

export enum CompanyType {
  LimitedCompany = "Limited Company",
  LimitedLiabilityPartnership = "Limited Liability Partnership",
  Partnership = "Partnership",
  SoleTrader = "Sole Trader",
  CommunityInterestCompany = "Community Interest Company",
  Charity = "Charity",
  PublicSectorCompany = "Public Sector Company",
}

export enum CompanySize {
  LargeEnterprise = "Large Enterprise",
  MediumEnterprise = "Medium Enterprise",
  SmallEnterprise = "Small Enterprise",
  MicroEnterprise = "Micro Enterprise",
}

const companyTypes = Object.values(CompanyType);
const companySize = Object.values(CompanySize);

export function isCustomer(type?: string): boolean {
  return (
    type !== undefined &&
    type !== "" &&
    [CompanyType.Partnership, CompanyType.SoleTrader]
      .map((value) => value.toString())
      .includes(type)
  );
}

export default function CompanyDetailsStep(): ReactElement {
  const { t } = useTranslation("onboardings");
  const stepper = useStepper();
  const onboarding = useOnboarding();

  const companyNameLabel = "company_details.company_name_label";
  const titleLabel = "company_details.title_label";
  const firstNameLabel = "company_details.first_name_label";
  const lastNameLabel = "company_details.last_name_label";

  const resolver = useYupResolver<CompanyDetailsFormModel>((yup) =>
    yup.object().shape({
      companyType: yup
        .string()
        .isRequired(t("company_details.company_type_label")),
      companyName: yup.string().when("companyType", {
        is: (value: string) => !isCustomer(value),
        then: yup
          .string()
          .isRequired(t(companyNameLabel))
          .maxCharacters(50, t(companyNameLabel)),
        otherwise: yup.string(),
      }),
      companyRegistrationNumber: yup
        .string()
        .maxCharacters(
          30,
          t("company_details.company_registration_number_label"),
        ),
      vatRegistrationNumber: yup
        .string()
        .maxCharacters(30, t("company_details.vat_registration_number_label")),
      title: yup.string().when("companyType", {
        is: (value: string) => isCustomer(value),
        then: yup
          .string()
          .isRequired(t(titleLabel))
          .maxCharacters(50, t(titleLabel)),
        otherwise: yup.string(),
      }),
      firstName: yup.string().when("companyType", {
        is: (value: string) => isCustomer(value),
        then: yup
          .string()
          .isRequired(t(firstNameLabel))
          .maxCharacters(50, t(firstNameLabel)),
        otherwise: yup.string(),
      }),
      middleName: yup
        .string()
        .maxCharacters(50, t("company_details.middle_name_label")),
      lastName: yup.string().when("companyType", {
        is: (value: string) => isCustomer(value),
        then: yup
          .string()
          .isRequired(t(lastNameLabel))
          .maxCharacters(50, t(lastNameLabel)),
        otherwise: yup.string(),
      }),
      tradingName: yup.string().when("companyType", {
        is: (value: string) => isCustomer(value),
        then: yup
          .string()
          .maxCharacters(50, t("company_details.trading_name_label")),
      }),
      dateOfBirth: yup.date().when("companyType", {
        is: (value: string) => isCustomer(value),
        then: yup
          .date()
          .localDate()
          .isBeforeToday(t("company_details.date_of_birth_label")),
      }),
      nationalIdentificationNumber: yup
        .string()
        .maxCharacters(
          20,
          t("company_details.national_identificaition_number_label"),
        ),
      sectorOfEconomicActivity: yup
        .string()
        .maxCharacters(
          6,
          t("company_details.sector_of_economic_activity_label"),
        ),
      companySector: yup
        .string()
        .maxCharacters(2, t("company_details.company_sector_label")),
    }),
  );

  const onSubmit = (data: CompanyDetailsFormModel): void => {
    onboarding.submitCompanyDetails(data);
    stepper.next();
  };

  return (
    <>
      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h1" }}>
          {t("company_details.title")}
        </Typography>
      </Box>

      <Form
        label={t("company_details.title")}
        defaultValues={{
          companyType: onboarding.state.companyDetails?.companyType ?? "",
          ...onboarding.state.companyDetails,
        }}
        onSubmit={onSubmit}
        resolver={resolver}
        options={{
          shouldUnregister: true,
        }}
      >
        {(form) => {
          const companyType = form.watch("companyType");

          return (
            <>
              <ControlledTextField
                helperText={form.formState.errors?.companyType?.message}
                SelectProps={{ displayEmpty: true }}
                error={form.formState.errors?.companyType?.message}
                control={form.control}
                id="companyType"
                name="companyType"
                label={t("company_details.company_type_label")}
                select
                required
              >
                <MenuItem value="">{t("common:please_select")}</MenuItem>
                {companyTypes.map((type) => (
                  <MenuItem value={String(type)} key={String(type)}>
                    {type}
                  </MenuItem>
                ))}
              </ControlledTextField>

              <Box marginY={3}>
                <Divider />
              </Box>

              {companyType && !isCustomer(companyType) && (
                <>
                  <TextField
                    {...form.register("companyName")}
                    label={t(companyNameLabel)}
                    helperText={form.formState.errors.companyName?.message}
                    error={Boolean(form.formState.errors.companyName)}
                    required
                  />
                  <TextField
                    {...form.register("companyRegistrationNumber")}
                    label={t(
                      "company_details.company_registration_number_label",
                    )}
                    helperText={
                      form.formState.errors.companyRegistrationNumber?.message
                    }
                    error={Boolean(
                      form.formState.errors.companyRegistrationNumber,
                    )}
                  />
                  <TextField
                    {...form.register("vatRegistrationNumber")}
                    label={t("company_details.vat_registration_number_label")}
                    helperText={
                      form.formState.errors.vatRegistrationNumber?.message
                    }
                    error={Boolean(form.formState.errors.vatRegistrationNumber)}
                  />
                  <ControlledTextField
                    helperText={form.formState.errors?.companySize?.message}
                    SelectProps={{ displayEmpty: true }}
                    error={form.formState.errors?.companySize?.message}
                    control={form.control}
                    id="companySize"
                    name="companySize"
                    label={t("company_details.company_size_label")}
                    select
                    defaultValue=""
                  >
                    <MenuItem value="">{t("common:please_select")}</MenuItem>
                    {companySize.map((type) => (
                      <MenuItem value={String(type)} key={String(type)}>
                        {type}
                      </MenuItem>
                    ))}
                  </ControlledTextField>
                  <TextField
                    {...form.register("sectorOfEconomicActivity")}
                    label={t(
                      "company_details.sector_of_economic_activity_label",
                    )}
                    helperText={
                      form.formState.errors.sectorOfEconomicActivity?.message
                    }
                    error={Boolean(
                      form.formState.errors.sectorOfEconomicActivity,
                    )}
                  />
                  <TextField
                    {...form.register("companySector")}
                    label={t("company_details.company_sector_label")}
                    helperText={form.formState.errors.companySector?.message}
                    error={Boolean(form.formState.errors.companySector)}
                  />
                </>
              )}

              {companyType && isCustomer(companyType) && (
                <>
                  <TextField
                    {...form.register("title")}
                    label={t(titleLabel)}
                    helperText={form.formState.errors.title?.message}
                    error={Boolean(form.formState.errors.title?.message)}
                    required
                  />
                  <TextField
                    {...form.register("firstName")}
                    label={t(firstNameLabel)}
                    helperText={form.formState.errors.firstName?.message}
                    error={Boolean(form.formState.errors.firstName?.message)}
                    required
                  />
                  <TextField
                    {...form.register("middleName")}
                    label={t("company_details.middle_name_label")}
                    helperText={form.formState.errors.middleName?.message}
                    error={Boolean(form.formState.errors.middleName?.message)}
                  />
                  <TextField
                    {...form.register("lastName")}
                    label={t(lastNameLabel)}
                    helperText={form.formState.errors.lastName?.message}
                    error={Boolean(form.formState.errors.lastName?.message)}
                    required
                  />
                  <TextField
                    {...form.register("tradingName")}
                    label={t("company_details.trading_name_label")}
                    helperText={form.formState.errors.tradingName?.message}
                    error={Boolean(form.formState.errors.tradingName?.message)}
                  />
                  <DateField
                    label={t("company_details.date_of_birth_label")}
                    helperText={form.formState.errors.dateOfBirth?.message}
                    error={Boolean(form.formState.errors.dateOfBirth?.message)}
                    name="dateOfBirth"
                    control={form.control}
                  />
                  <TextField
                    {...form.register("nationalIdentificationNumber")}
                    label={t(
                      "company_details.national_identificaition_number_label",
                    )}
                    helperText={
                      form.formState.errors.nationalIdentificationNumber
                        ?.message
                    }
                    error={Boolean(
                      form.formState.errors.nationalIdentificationNumber
                        ?.message,
                    )}
                  />
                </>
              )}
              <OnboardingStepActions
                next={{ label: t("common:stepper.next_button") }}
              />
            </>
          );
        }}
      </Form>
    </>
  );
}
