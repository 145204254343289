import { PortfolioReportFormDialog } from "apps/reporting/components";
import {
  usePortfolioReportResolver,
  useReportDownload,
} from "apps/reporting/hooks";
import { PortfolioReportDialogFormValues } from "apps/reporting/types";
import { useApi } from "common/providers";
import { ApiResponse } from "generated";
import { PortfolioQueryApi, ReportQueryApi } from "generated/core/apis";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

interface AssetReportProps {
  onClose(): void;
  open: boolean;
}

export default function AssetReportContainer({
  onClose,
  open,
}: AssetReportProps): ReactElement {
  const { t } = useTranslation("reporting");
  const resolver = usePortfolioReportResolver();
  const portfolioQueryApi = useApi(PortfolioQueryApi);
  const reportApi = useApi(ReportQueryApi);
  const portfolios = useSWR(["portfolios"], () =>
    portfolioQueryApi.listPortfolios(),
  );

  const assetReportApi = (
    data: PortfolioReportDialogFormValues,
  ): Promise<ApiResponse<string>> =>
    reportApi.assetsRaw({
      portfolioId:
        data.portfolio === "All" ? undefined : Number(data.portfolio),
    });

  const assetReportDownload = useReportDownload(assetReportApi, {
    onSuccess: onClose,
  });

  const onSubmit = async (
    data: PortfolioReportDialogFormValues,
  ): Promise<void> => assetReportDownload.download(data);

  return (
    <PortfolioReportFormDialog<PortfolioReportDialogFormValues>
      portfolios={[
        { label: t("report_dialog.all_portfolio_option"), value: "All" },
        ...(portfolios.data || []).map((portfolio) => ({
          label: portfolio.name,
          value: String(portfolio.id),
        })),
      ]}
      onClose={onClose}
      onSubmit={onSubmit}
      title={t("report_dialog.asset.title")}
      error={assetReportDownload.error?.message}
      open={open}
      ready={Boolean(portfolios.data) && !assetReportDownload.isLoading}
      resolver={resolver}
    />
  );
}
