import { AccessControl, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { FindCustomerContainer } from "apps/onboarding/containers";
import OnboardingListContainer from "apps/onboarding/containers/OnboardingListContainer";
import { compose } from "lodash/fp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

function OnboardingView() {
  const { t } = useTranslation("onboardings");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isCustomerSearchDialogOpen, setCustomerSearchDialogOpen] =
    useState(false);

  const onNewOnboardingClick = (): void => {
    setDialogOpen(true);
  };

  const onNewOnboardingClose = (): void => {
    setDialogOpen(false);
  };

  const onFindCustomerClick = (): void => {
    setDialogOpen(false);
    setCustomerSearchDialogOpen(true);
  };

  const onFindCustomerClose = (): void => {
    setCustomerSearchDialogOpen(false);
  };

  return (
    <Container maxWidth="xl">
      <Box marginY={3}>
        <Box justifyContent="space-between" display="flex" alignItems="center">
          <Box>
            <Typography component="h1" variant="h2">
              {t("title")}
            </Typography>
          </Box>
          <Box>
            <AccessControl
              allowedPermissions={["onboarding:onboarding:manage"]}
            >
              <Button
                aria-label={t("create_onboarding_button")}
                color="primary"
                variant="contained"
                onClick={onNewOnboardingClick}
              >
                {t("create_onboarding_button")}
              </Button>
            </AccessControl>
          </Box>
        </Box>
      </Box>

      <FindCustomerContainer
        open={isCustomerSearchDialogOpen}
        onClose={onFindCustomerClose}
      />

      <Dialog onClose={onNewOnboardingClose} open={isDialogOpen}>
        <DialogTitle>{t("create_onboarding_label")}</DialogTitle>
        <List component="nav" sx={{ pt: 0 }}>
          <ListItem
            button
            component={RouterLink}
            onClick={onNewOnboardingClose}
            to="./create"
          >
            <ListItemText primary={t("new_customer_button")} />
          </ListItem>
          <ListItem button onClick={onFindCustomerClick}>
            <ListItemText primary={t("existing_customer_button")} />
          </ListItem>
        </List>
      </Dialog>

      <OnboardingListContainer />
    </Container>
  );
}

export default compose(
  withTranslationLoader("onboardings"),
  withProtectedView({
    allowedPermissions: ["onboarding:onboarding:view"],
  }),
)(OnboardingView);
