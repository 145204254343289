import { useRef } from "react";
import useSWR, { Fetcher, Key, SWRConfiguration, SWRResponse } from "swr";

function useStickySWR<Data = unknown, Error = unknown>(
  key: Key,
  fetcher: Fetcher<Data> | null,
  config?: SWRConfiguration<Data, Error, Fetcher<Data>>,
): SWRResponse<Data, Error> & { isInitialLoading: boolean } {
  const stickyData = useRef<Data>();
  const swr = useSWR<Data, Error>(key, fetcher, config);

  if (typeof swr.data !== "undefined") {
    stickyData.current = swr.data;
  }

  return {
    ...swr,
    data: stickyData.current,
    isInitialLoading: typeof swr.data === "undefined",
  };
}

export default useStickySWR;
