import { stringify } from "csv-stringify/sync";
import download from "./download";

const downloadAsCSV = (
  data: (string | number | undefined)[][],
  options: {
    filename?: string;
  } = {},
) => {
  const result = stringify(data, {
    eof: false,
    quoted: true,
    quoted_empty: true,
  });

  const blob = new Blob([result]);

  const url = URL.createObjectURL(blob);

  const suffix = new Date()
    .toISOString()
    .split(".")
    .slice(0, -1)
    .join("")
    .replace(/-/g, "")
    .replace(/:/g, "")
    .replace("T", "-");

  download(url, options.filename ? `${options.filename}_${suffix}.csv` : "");
};

export default downloadAsCSV;
