/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CompanySizeModel = {
    LargeEnterprise: 'Large Enterprise',
    MediumEnterprise: 'Medium Enterprise',
    SmallEnterprise: 'Small Enterprise',
    MicroEnterprise: 'Micro Enterprise'
} as const;
export type CompanySizeModel = typeof CompanySizeModel[keyof typeof CompanySizeModel];


export function CompanySizeModelFromJSON(json: any): CompanySizeModel {
    return CompanySizeModelFromJSONTyped(json, false);
}

export function CompanySizeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySizeModel {
    return json as CompanySizeModel;
}

export function CompanySizeModelToJSON(value?: CompanySizeModel | null): any {
    return value as any;
}

