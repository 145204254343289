/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgreementActivityModel
 */
export interface AgreementActivityModel {
    /**
     * A date time format
     * @type {string}
     * @memberof AgreementActivityModel
     */
    created: string;
    /**
     * The operator(user) name who performed the activity
     * @type {string}
     * @memberof AgreementActivityModel
     */
    createdBy: string;
    /**
     * The activity action that was performed
     * @type {string}
     * @memberof AgreementActivityModel
     */
    action: string;
    /**
     * The more detailed description of the activity
     * @type {string}
     * @memberof AgreementActivityModel
     */
    description?: string;
}

/**
 * Check if a given object implements the AgreementActivityModel interface.
 */
export function instanceOfAgreementActivityModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function AgreementActivityModelFromJSON(json: any): AgreementActivityModel {
    return AgreementActivityModelFromJSONTyped(json, false);
}

export function AgreementActivityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementActivityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': json['created'],
        'createdBy': json['createdBy'],
        'action': json['action'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function AgreementActivityModelToJSON(value?: AgreementActivityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'createdBy': value.createdBy,
        'action': value.action,
        'description': value.description,
    };
}

