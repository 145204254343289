import { Loader } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import ViewLiveToggle from "apps/servicing/components/ViewLiveToggle";
import AgreementList, {
  SortBy,
} from "apps/servicing/modules/customers/components/AgreementList";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import useSort from "common/hooks/useSort";
import ListLayout from "common/layouts/ListLayout";
import { useApi } from "common/providers/ApiProvider";
import { ClientQueryApi } from "generated/core/apis/ClientQueryApi";
import { orderBy } from "lodash";
import { ChangeEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import useStickySWR from "support/use-sticky-swr";

interface ClientAgreementsProps {
  clientId: number;
}

interface ClientAgreementsParameters {
  live?: boolean;
}

export default function ClientAgreements({
  clientId,
}: ClientAgreementsProps): ReactElement {
  const appConfig = useAppConfiguration();
  const clientApi = useApi(ClientQueryApi);
  const { t } = useTranslation(["clients", "common"]);
  const sort = useSort<SortBy>();
  const [parameters, setParameters] = useState<ClientAgreementsParameters>({
    live: undefined,
  });

  const agreements = useStickySWR(
    ["/client/:id/agreements", clientId, parameters.live],
    () =>
      clientApi.listClientAgreements({
        clientId,
        live: parameters.live,
      }),
  );

  const totals = agreements.data
    ? {
        numberOfAssets: agreements.data.numberOfAssets,
        totalArrears: agreements.data.totalArrears,
        totalBalance: agreements.data.totalBalance,
      }
    : {};

  function handleViewLiveChange(event: ChangeEvent<HTMLInputElement>): void {
    setParameters({
      live: event.target.value === "liveOnly" ? true : undefined,
    });
  }

  return (
    <ListLayout
      filters={
        <Box marginTop={-0.5}>
          <ViewLiveToggle
            onChange={handleViewLiveChange}
            value={parameters.live ? "liveOnly" : "all"}
          />
        </Box>
      }
    >
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(agreements.data ?? agreements.error)}
        render={() => {
          if (agreements.error || !agreements.data) {
            return (
              <Typography
                aria-label={t("common:error.default")}
                color="error"
                data-testid="assetsList.error"
                role="alert"
              >
                {t("common:error.default")}
              </Typography>
            );
          }

          return (
            <AgreementList
              data={orderBy(
                agreements.data.agreements.map((agreement) => ({
                  ...agreement,
                  href: `${appConfig.appRoutes.servicing}/agreements/${agreement.id}`,
                })),
                sort.sortBy,
                sort.sortDirection,
              )}
              loading={agreements.isValidating}
              numberOfAssets={totals.numberOfAssets}
              onSort={sort.onSort}
              sortDirection={sort.sortDirection}
              sortBy={sort.sortBy}
              totalArrears={totals.totalArrears}
              totalBalance={totals.totalBalance}
            />
          );
        }}
      />
    </ListLayout>
  );
}
