import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Transaction } from "apps/servicing/modules/agreements/components";
import { ControlledTextField } from "common/components";
import { useResponseError, useYupResolver } from "common/hooks";
import { useAgreement, useApi } from "common/providers";
import { AgreementCommandApi, AgreementQueryApi } from "generated/core/apis";
import { ReturnPaymentModel } from "generated/core/models";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";
import useSWR from "swr";

interface ReturnPaymentFormValues {
  reasonCode: string;
  bankDate: Date;
}

interface ReturnPaymentFormDialogProps {
  transactionId: number;
  transactions?: Transaction[];
  open: boolean;
  onClose(): void;
  onSubmit(applyCharge: boolean): void;
}

export default function ReturnPayment({
  transactionId,
  open,
  onClose,
  onSubmit: onSubmitCallback,
}: ReturnPaymentFormDialogProps): ReactElement {
  const agreement = useAgreement();
  const { t } = useTranslation("agreements");
  const [applyCharge, setApplyCharge] = useState(false);

  const agreementQueryApi = useApi(AgreementQueryApi);
  const reversalReasons = useSWR(
    ["agreements/transactions/reversal-reasons"],
    () => agreementQueryApi.listReversalReasons(),
  );
  const agreementCommandApi = useApi(AgreementCommandApi);

  const error = useResponseError();

  const resolver = useYupResolver<ReturnPaymentFormValues>((yup) => {
    const bankDateLabel = t("transactions.return_payment.bank_date_label");
    return yup.object().shape({
      reasonCode: yup
        .string()
        .isRequired(t("transactions.return_payment.reason_label")),
      bankDate: yup
        .date()
        .localDate()
        .isValidDate(bankDateLabel)
        .isRequired(bankDateLabel)
        .isNotFuture(bankDateLabel),
    });
  });

  const { reset, ...form } = useForm<ReturnPaymentFormValues>({
    defaultValues: {
      bankDate: new Date(),
      reasonCode: "",
    },
    resolver,
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const onSubmit = async (data: ReturnPaymentFormValues): Promise<void> => {
    try {
      const returnPaymentModel: ReturnPaymentModel = {
        transactionId,
        reasonCode: data.reasonCode,
        bankDate: data.bankDate,
      };
      await agreementCommandApi.returnPayment({
        agreementId: agreement.id,
        returnPaymentModel,
      });
      agreement.mutate();
      onSubmitCallback(applyCharge);
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    }
  };

  const handleApplyCharge = (): void => setApplyCharge((current) => !current);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="return-payment-dialog-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <form
        method="POST"
        onSubmit={form.handleSubmit(onSubmit)}
        aria-labelledby="return-payment-dialog-title"
      >
        <DialogTitle id="return-payment-dialog-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              {t("transactions.return_payment.return_payment_dialog_title")}
            </Box>
            <Box>
              <IconButton
                aria-label={t("common:close")}
                onClick={onClose}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ControlledTextField
            helperText={form.formState.errors?.reasonCode?.message}
            SelectProps={{ displayEmpty: true }}
            error={form.formState.errors?.reasonCode?.message}
            control={form.control}
            id="reasonCode"
            name="reasonCode"
            label={t("transactions.return_payment.reason_label")}
            select
          >
            <MenuItem value="">{t("common:please_select")}</MenuItem>
            {reversalReasons.data?.map((reason) => (
              <MenuItem key={reason.type} value={reason.type}>
                {reason.name}
              </MenuItem>
            ))}
          </ControlledTextField>
          <TextField
            {...form.register("bankDate")}
            helperText={form.formState.errors.bankDate?.message}
            error={Boolean(form.formState.errors.bankDate)}
            label={t("transactions.return_payment.bank_date_label")}
            type="date"
          />
          {error.message && (
            <Typography color="error">{error.message}</Typography>
          )}
        </DialogContent>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={applyCharge}
                onChange={handleApplyCharge}
                color="primary"
              />
            }
            label={String(t("transactions.return_payment.apply_charge_label"))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t("common:cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={form.formState.isSubmitting}
            variant="contained"
          >
            {t("common:save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
