import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Stack } from "@mui/material";
import Surface from "common/components/Surface";
import useLocale from "common/hooks/useLocale";
import { SettlementStatusModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface SettlementQuoteDetail {
  settlementDate: Date;
  expiryDate: Date;
  discountAmount?: string;
  discountPercentage?: string;
  discountType?: string;
  outstandingArrears: string;
  allFeesAndCharges?: string;
  allFutureInstalmentAmounts: string;
  overPayments?: string;
  vat?: string;
  totalAmountDue: string;
  totalAmountToSettle: string;
  status: SettlementStatusModel;
}

interface SettlementQuoteDetailsProps {
  data: SettlementQuoteDetail;
}

export default function SettlementQuoteDetails({
  data,
}: SettlementQuoteDetailsProps): ReactElement {
  const { t } = useTranslation("agreements");
  const locale = useLocale();
  const { formatAmount } = useFormat();

  return (
    <>
      <Stack spacing={2}>
        <Surface title={t("settlement_quotes.details.details_label")}>
          <Stack spacing={2}>
            <DescriptionList
              label={t("settlement_quotes.details.details_label")}
            >
              <DescriptionList.Item
                label={t("settlement_quotes.details.status_label")}
              >
                {data.status}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("settlement_quotes.details.settlement_date_label")}
              >
                {locale.formatDate(data.settlementDate)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("settlement_quotes.details.expiration_date_label")}
              >
                {locale.formatDate(data.expiryDate)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("settlement_quotes.details.discount_type_label")}
              >
                {data.discountType ||
                  t("settlement_quotes.details.no_discount_label")}
              </DescriptionList.Item>

              {data.discountType === "Percentage" ? (
                <DescriptionList.Item
                  label={t(
                    "settlement_quotes.details.discount_percentage_label",
                  )}
                >
                  {`${data.discountPercentage}%`}
                </DescriptionList.Item>
              ) : (
                <DescriptionList.Item
                  label={t("settlement_quotes.details.discount_amount_label")}
                >
                  {formatAmount(data.discountAmount)}
                </DescriptionList.Item>
              )}
            </DescriptionList>
          </Stack>
        </Surface>

        <Surface title={t("settlement_quotes.details.summary_label")}>
          <Stack spacing={2}>
            <DescriptionList
              label={t("settlement_quotes.details.summary_label")}
            >
              <DescriptionList.Item
                label={t("settlement_quotes.details.outstanding_arrears_label")}
              >
                {formatAmount(data.outstandingArrears)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t(
                  "settlement_quotes.details.all_fees_and_charges_label",
                )}
              >
                {formatAmount(data.allFeesAndCharges)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t(
                  "settlement_quotes.details.all_future_instalment_amounts_label",
                )}
              >
                {formatAmount(data.allFutureInstalmentAmounts)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("settlement_quotes.details.over_payments_label")}
              >
                {formatAmount(data.overPayments)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("settlement_quotes.details.vat_label")}
              >
                {formatAmount(data.vat)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("settlement_quotes.details.discount_amount_label")}
              >
                {formatAmount(data.discountAmount)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t("settlement_quotes.details.total_amount_due_label")}
              >
                {formatAmount(data.totalAmountDue)}
              </DescriptionList.Item>
              <DescriptionList.Item
                label={t(
                  "settlement_quotes.details.total_amount_to_settle_label",
                )}
              >
                {formatAmount(data.totalAmountToSettle)}
              </DescriptionList.Item>
            </DescriptionList>
          </Stack>
        </Surface>
      </Stack>
    </>
  );
}
