import { Loader } from "@ldms/mui-sdk/templates";
import { Search } from "@mui/icons-material";
import { Box, CircularProgress, TextField } from "@mui/material";
import { useListCustomers } from "api/customers";
import { Customer, CustomerList, QueryError } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { ClientListItemModel } from "generated/core/models";
import { debounce } from "lodash";
import {
  ChangeEventHandler,
  MouseEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

export default function CustomersContainer(): ReactElement {
  const { t } = useTranslation("customers");
  const [query, setQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const appConfig = useAppConfiguration();

  const customers = useListCustomers({
    pageSize: rowsPerPage,
    params: { query },
  });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => customers.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  const onQueryChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    setQuery(event.target.value);
  };

  const handleQueryChange = useMemo(() => debounce(onQueryChange, 300), []);

  useEffect(() => {
    return handleQueryChange.cancel;
  }, [handleQueryChange]);

  const mapCustomer = (customer: ClientListItemModel): Customer => ({
    ...customer,
    href: `${appConfig.appRoutes.servicing}/customers/${customer.id}`,
  });

  return (
    <ListLayout
      filters={
        <TextField
          InputProps={{
            endAdornment: <Search />,
          }}
          inputProps={{
            "aria-label": t("customers.search_label"),
          }}
          margin="none"
          onChange={handleQueryChange}
        />
      }
      pagination={{
        onPageChange: onChangePage,
        onRowsPerPageChange: onChangeRowsPerPage,
        rowsPerPage,
        page: customers.paging.page,
        count: customers.data?.paging.total || 0,
      }}
    >
      <Loader
        ready={Boolean(customers.data || customers.error)}
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        render={(): ReactElement => {
          if (customers.error || !customers.data) {
            return (
              <Box paddingY={2}>
                <QueryError onRetry={customers.refetch} />
              </Box>
            );
          }

          return (
            <CustomerList
              data={customers.data.results.map(mapCustomer)}
              loading={customers.isValidating}
            />
          );
        }}
      />
    </ListLayout>
  );
}
