import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { ClientMemos } from "apps/servicing/modules/customers/containers";
import AddMemoContainer from "apps/servicing/modules/customers/containers/AddMemoContainer";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { ContainedLayout } from "common/layouts";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

export default function MemosView() {
  const { t } = useTranslation("customers");
  const customer = useCustomer();

  return (
    <ViewLayout
      title={t("memos.heading")}
      action={
        <AccessControl allowedPermissions={["servicing:customer-memos:manage"]}>
          <AddMemoContainer customerId={customer.data.systemId} />
        </AccessControl>
      }
    >
      <FixedLayout>
        <ContainedLayout>
          <ClientMemos clientId={customer.data.systemId} />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}
