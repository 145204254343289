import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { ApiResponse } from "@ldms/mui-sdk/bootstrap/generated";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { FinancialProductApi } from "generated/onboarding/apis";
import { CreateFinancialProductModel } from "generated/onboarding/models";
import * as keys from "./keys";

const useAddFinancialProduct = (
  options: UseCommandOptions<ApiResponse<void>, ResponseError> = {},
) => {
  const api = useApi(FinancialProductApi);
  const partialMutate = usePartialMutate();

  return useCommand<
    CreateFinancialProductModel,
    ApiResponse<void>,
    ResponseError
  >(
    (model) =>
      api.createFinancialProductRaw({
        createFinancialProductModel: model,
      }),
    {
      ...options,
      onSuccess: (response) => {
        options.onSuccess?.(response);
        partialMutate(keys.list());
      },
    },
  );
};

export { useAddFinancialProduct };
