/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing fees
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddFeeModel,
  UpdateFeeEnabledModel,
} from '../models/index';
import {
    AddFeeModelFromJSON,
    AddFeeModelToJSON,
    UpdateFeeEnabledModelFromJSON,
    UpdateFeeEnabledModelToJSON,
} from '../models/index';

export interface AddFeeRequest {
    addFeeModel: AddFeeModel;
}

export interface UpdateEnabledRequest {
    feeId: number;
    updateFeeEnabledModel: UpdateFeeEnabledModel;
}

/**
 * 
 */
export class FeesCommandApi extends runtime.BaseAPI {

    /**
     * Creates a new configured fee
     */
    async addFeeRaw(requestParameters: AddFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.addFeeModel === null || requestParameters.addFeeModel === undefined) {
            throw new runtime.RequiredError('addFeeModel','Required parameter requestParameters.addFeeModel was null or undefined when calling addFee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["fees:manage"]);
        }

        const response = await this.request({
            path: `/onboardings/fees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFeeModelToJSON(requestParameters.addFeeModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a new configured fee
     */
    async addFee(requestParameters: AddFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addFeeRaw(requestParameters, initOverrides);
    }

    /**
     * Enables / Disables a Fee
     */
    async updateEnabledRaw(requestParameters: UpdateEnabledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.feeId === null || requestParameters.feeId === undefined) {
            throw new runtime.RequiredError('feeId','Required parameter requestParameters.feeId was null or undefined when calling updateEnabled.');
        }

        if (requestParameters.updateFeeEnabledModel === null || requestParameters.updateFeeEnabledModel === undefined) {
            throw new runtime.RequiredError('updateFeeEnabledModel','Required parameter requestParameters.updateFeeEnabledModel was null or undefined when calling updateEnabled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["fees:manage"]);
        }

        const response = await this.request({
            path: `/onboardings/fees/{feeId}`.replace(`{${"feeId"}}`, encodeURIComponent(String(requestParameters.feeId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFeeEnabledModelToJSON(requestParameters.updateFeeEnabledModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enables / Disables a Fee
     */
    async updateEnabled(requestParameters: UpdateEnabledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateEnabledRaw(requestParameters, initOverrides);
    }

}
