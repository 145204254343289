import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useRejectLettersToEmail } from "api/documents/letters/email/rejectLettersToEmail";
import { useVerifyLettersToEmail } from "api/documents/letters/email/verifyLettersToEmail";
import { useConfirm } from "common/hooks";
import { LetterVerificationModel } from "generated/documents/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface VerifyRejectLettersToEmailContainerProps {
  responseBody: LetterVerificationModel;
  totalSelected: number;
  onSubmitCallback: () => void;
}

export default function VerifyRejectLettersToEmailContainer({
  responseBody,
  totalSelected,
  onSubmitCallback,
}: Readonly<VerifyRejectLettersToEmailContainerProps>): ReactElement {
  const confirmVerify = useConfirm();
  const confirmReject = useConfirm();

  const { t } = useTranslation("documents");

  const verifyLettersToEmail = useVerifyLettersToEmail({
    onSuccess: onSubmitCallback,
  });

  const rejectLettersToEmail = useRejectLettersToEmail({
    onSuccess: onSubmitCallback,
  });

  const handleOnVerify = (): void => {
    confirmVerify.handlePrompt(async (): Promise<void> => {
      await verifyLettersToEmail.execute(responseBody);
    });
  };

  const handleOnReject = (): void => {
    confirmReject.handlePrompt(async (): Promise<void> => {
      await rejectLettersToEmail.execute(responseBody);
    });
  };

  const onCloseErrorDialog = (): void => {
    verifyLettersToEmail.reset();
    rejectLettersToEmail.reset();
  };

  return (
    <>
      <ConfirmationDialog
        content={
          <Typography>
            {t("letters_to_email.verify.confirmation_message", {
              count: totalSelected,
            })}
          </Typography>
        }
        open={confirmVerify.isOpen}
        onConfirm={confirmVerify.handleConfirm}
        onReject={confirmVerify.handleReject}
        title={t("letters_to_email.verify.confirmation_title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <ConfirmationDialog
        content={
          <Typography>
            {t("letters_to_email.reject.confirmation_message", {
              count: totalSelected,
            })}
          </Typography>
        }
        open={confirmReject.isOpen}
        onConfirm={confirmReject.handleConfirm}
        onReject={confirmReject.handleReject}
        title={t("letters_to_email.reject.confirmation_title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        content={
          verifyLettersToEmail.error?.message ??
          rejectLettersToEmail.error?.message
        }
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(verifyLettersToEmail.error ?? rejectLettersToEmail.error)}
        title={t("common:error.title")}
      />

      {(totalSelected > 0 ||
        rejectLettersToEmail.isExecuting ||
        verifyLettersToEmail.isExecuting) && (
        <Stack direction="row" gap={1}>
          <Button
            variant="contained"
            onClick={handleOnVerify}
            disabled={
              verifyLettersToEmail.isExecuting ||
              rejectLettersToEmail.isExecuting
            }
            startIcon={
              verifyLettersToEmail.isExecuting && (
                <CircularProgress color="inherit" size="1rem" />
              )
            }
          >
            {t("letters_to_email.verify.send_button")}
          </Button>

          <Button
            variant="contained"
            onClick={handleOnReject}
            disabled={
              rejectLettersToEmail.isExecuting ||
              verifyLettersToEmail.isExecuting
            }
            startIcon={
              rejectLettersToEmail.isExecuting && (
                <CircularProgress color="inherit" size="1rem" />
              )
            }
          >
            {t("letters_to_email.reject.reject_button")}
          </Button>
        </Stack>
      )}
    </>
  );
}
