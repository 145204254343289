import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ManageContactPointMenu from "apps/servicing/modules/customers/containers/ManageContactPointMenu";
import { ContactPoint, ContactPointType } from "apps/servicing/types";
import { ContactPointTypeModel } from "generated/servicing-v2/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface ContactPointListProps {
  objectSystemId: string;
  data?: ContactPoint[];
  isInvoiceByEmail: boolean;
  forCustomer: boolean;
}

export type ModelKeys = keyof typeof ContactPointTypeModel;

export default function ContactPointList({
  objectSystemId,
  data,
  isInvoiceByEmail,
  forCustomer,
}: Readonly<ContactPointListProps>): ReactElement {
  const { t } = useTranslation("clients");

  return (
    <Table aria-label={t("contacts.correspondence_methods")} size="small">
      <colgroup>
        <col width={200} />
        <col width={200} />
        <col />
        <col width={1} />
        <col width={1} />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell>{t("contacts.method")}</TableCell>
          <TableCell>{t("contacts.contact")}</TableCell>
          <TableCell>{t("contacts.comment")}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((contactPoint) => (
          <TableRow key={contactPoint.systemId} hover>
            <TableCell>
              {
                ContactPointType[
                  Object.keys(ContactPointTypeModel).find(
                    (key) =>
                      ContactPointTypeModel[key as ModelKeys] ===
                      contactPoint.type,
                  ) as ModelKeys
                ]
              }
            </TableCell>
            <TableCell
              sx={{
                maxWidth: 360,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {contactPoint.value}
            </TableCell>
            <TableCell>{contactPoint.comment}</TableCell>
            <TableCell sx={{ paddingY: 0.25 }} align="right">
              <AccessControl allowedPermissions={["servicing:contacts:manage"]}>
                <ManageContactPointMenu
                  objectSystemId={objectSystemId}
                  contactPoint={contactPoint}
                  isInvoiceByEmail={isInvoiceByEmail}
                  forCustomer={forCustomer}
                />
              </AccessControl>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
