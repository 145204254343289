/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutomationAuditListItemModel
 */
export interface AutomationAuditListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AutomationAuditListItemModel
     */
    readonly id: number;
    /**
     * The name of the automation
     * @type {string}
     * @memberof AutomationAuditListItemModel
     */
    automationName: string;
    /**
     * The auditing action that was performed
     * @type {string}
     * @memberof AutomationAuditListItemModel
     */
    action: AutomationAuditListItemModelActionEnum;
    /**
     * 
     * @type {Date}
     * @memberof AutomationAuditListItemModel
     */
    timestamp: Date;
    /**
     * The operator(user) name who performed the action
     * @type {string}
     * @memberof AutomationAuditListItemModel
     */
    userName: string;
}


/**
 * @export
 */
export const AutomationAuditListItemModelActionEnum = {
    Created: 'Created',
    Edited: 'Edited',
    Activated: 'Activated',
    Deactivated: 'Deactivated'
} as const;
export type AutomationAuditListItemModelActionEnum = typeof AutomationAuditListItemModelActionEnum[keyof typeof AutomationAuditListItemModelActionEnum];


/**
 * Check if a given object implements the AutomationAuditListItemModel interface.
 */
export function instanceOfAutomationAuditListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "automationName" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "userName" in value;

    return isInstance;
}

export function AutomationAuditListItemModelFromJSON(json: any): AutomationAuditListItemModel {
    return AutomationAuditListItemModelFromJSONTyped(json, false);
}

export function AutomationAuditListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomationAuditListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'automationName': json['automationName'],
        'action': json['action'],
        'timestamp': (new Date(json['timestamp'])),
        'userName': json['userName'],
    };
}

export function AutomationAuditListItemModelToJSON(value?: AutomationAuditListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'automationName': value.automationName,
        'action': value.action,
        'timestamp': (value.timestamp.toISOString()),
        'userName': value.userName,
    };
}

