import { useSession, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Box } from "@mui/material";
import UnexpectedError from "common/components/UnexpectedError";
import SidebarNavigation from "common/containers/SidebarNavigation";
import SidebarLayout from "common/layouts/SidebarLayout";
import { compose } from "lodash/fp";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

const templatesPermissions = "documents:templates:manage";

function SettingsLayout() {
  const { t } = useTranslation("documents");

  const makeItem = (label: string, to: string, display: boolean) => {
    return { label: t(label), to, display };
  };

  const session = useSession();

  const navigationMenu = [
    makeItem(
      "settings.templates_tab",
      "./templates",
      session.hasPermission(templatesPermissions),
    ),
    makeItem(
      "settings.letter_templates_tab",
      "./letter-templates",
      session.hasPermission(templatesPermissions),
    ),
    makeItem(
      "settings.system_letters_tab",
      "./system-letters",
      session.hasPermission("documents:portfolios:manage"),
    ),
  ];

  return (
    <SidebarLayout
      title={t("settings.app_title")}
      sidebar={<SidebarNavigation menu={navigationMenu} />}
    >
      <Box component="main" height="100%">
        <ErrorBoundary FallbackComponent={UnexpectedError}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </SidebarLayout>
  );
}

export default compose(
  withTranslationLoader("documents"),
  withProtectedView({
    allowedPermissions: [templatesPermissions, "documents:portfolios:manage"],
  }),
)(SettingsLayout);
