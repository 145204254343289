/* tslint:disable */
/* eslint-disable */
/**
 * Engage Admin
 * API for managing users within the Engage Admin application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserListItemModel
 */
export interface UserListItemModel {
    /**
     * The user's ID
     * @type {string}
     * @memberof UserListItemModel
     */
    id: string;
    /**
     * The user's first name
     * @type {string}
     * @memberof UserListItemModel
     */
    firstName: string;
    /**
     * The user's last name
     * @type {string}
     * @memberof UserListItemModel
     */
    lastName: string;
    /**
     * The user's username
     * @type {string}
     * @memberof UserListItemModel
     */
    username: string;
    /**
     * Whether the user's account is active or not
     * @type {boolean}
     * @memberof UserListItemModel
     */
    active: boolean;
    /**
     * The user's email
     * @type {string}
     * @memberof UserListItemModel
     */
    email?: string;
}

/**
 * Check if a given object implements the UserListItemModel interface.
 */
export function instanceOfUserListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function UserListItemModelFromJSON(json: any): UserListItemModel {
    return UserListItemModelFromJSONTyped(json, false);
}

export function UserListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'username': json['username'],
        'active': json['active'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function UserListItemModelToJSON(value?: UserListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'username': value.username,
        'active': value.active,
        'email': value.email,
    };
}

