import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { SettlementQuoteQueryApi } from "generated/core/apis";
import { SettlementQuoteModel } from "generated/core/models";
import { ErrorLike } from "support/error-handler";
import { keys } from ".";

type UseGetSettlementQuote = (
  settlementQuoteId: number,
) => UseQueryReturn<SettlementQuoteModel, ErrorLike>;

export const useGetSettlementQuote: UseGetSettlementQuote = (
  settlementQuoteId: number,
) => {
  const api = useApi(SettlementQuoteQueryApi);

  return useQuery(keys.detail(settlementQuoteId), () =>
    api.getSettlementQuote({ settlementQuoteId: settlementQuoteId }),
  );
};
