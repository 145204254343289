import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers/ApiProvider";
import { CustomerBanksApi } from "generated/servicing-v2/apis";
import { BankAccountsListItemModel } from "generated/servicing-v2/models";
import * as keys from "./keys";

type UseListBankAccounts = (
  id: string,
) => UseQueryReturn<BankAccountsListItemModel[]>;

export const useListBankAccounts: UseListBankAccounts = (id) => {
  const api = useApi(CustomerBanksApi, "v2");

  return useQuery(keys.listBanks(id), () =>
    api.listCustomerBankAccounts({ id }),
  );
};
