import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import SystemLettersContainer from "apps/documents/containers/SystemLettersContainer";
import ContainedLayout from "common/layouts/ContainedLayout";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

function SystemLettersView(): ReactElement {
  const { t } = useTranslation("documents");

  return (
    <ViewLayout title={t("system_letters.heading")}>
      <FixedLayout>
        <ContainedLayout>
          <SystemLettersContainer />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}

export default withProtectedView({
  allowedPermissions: ["documents:portfolios:manage"],
})(SystemLettersView);
