import { DescriptionList } from "@ldms/mui-sdk/components";
import { DateField } from "@ldms/mui-sdk/forms";
import { Close, Warning } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useListPaymentInstructions } from "api/agreements/payment-instructions/listPaymentInstructions";
import { ControlledTextField } from "common/components";
import { useLocale, useYupResolver } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { useAgreement } from "common/providers";
import { PayeeModel, PaymentTypeModel } from "generated/servicing-v2/models";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  Control,
  FieldErrors,
  Resolver,
  useForm,
  UseFormRegister,
} from "support/react-hook-form";

enum PaymentInstructionType {
  DirectDebit = "Direct Debit",
}

export interface AddPaymentInstructionFormValues {
  contactSystemId: string;
  payee: string;
  day?: number;
  bankSystemId: string;
  startDate: Date;
  endDate: Date;
  type: PaymentTypeModel;
  amount?: number;
  paymentInstructionType: PaymentInstructionType.DirectDebit;
  signatureDate: Date;
}

const useAddPaymentInstructionResolver = (
  earliestMandateStartDate: Date,
): Resolver<AddPaymentInstructionFormValues> => {
  const { t } = useTranslation(["agreements", "common"]);
  const amountLabel = t("payments.add_payment_instruction.amount_label");
  const startDateLabel = t("payments.add_payment_instruction.start_date_label");
  const dayOfMonthLabel = t(
    "payments.add_payment_instruction.day_of_month_label",
  );
  const signatureDateLabel = t(
    "payments.add_payment_instruction.signature_date_label",
  );
  const locale = useLocale();

  return useYupResolver<AddPaymentInstructionFormValues>((yup) => {
    const paymentInstructionPayeeLabel = t(
      "payments.add_payment_instruction.payee_label",
    );

    return yup.object().shape({
      contactSystemId: yup
        .string()
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .isRequired(paymentInstructionPayeeLabel),
      type: yup
        .string()
        .isRequired(t("payments.add_payment_instruction.type_label")),
      amount: yup.number(amountLabel).when("type", {
        is: (type: PaymentTypeModel) => type === PaymentTypeModel.AdHocAmount,
        then: yup
          .number(amountLabel)
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .isRequired(amountLabel)
          .maxAmount(99999999.99, amountLabel),
      }),
      startDate: yup
        .date()
        .localDate()
        .isRequired(startDateLabel)
        .isValidDate(startDateLabel)
        .min(
          earliestMandateStartDate,
          t("common:validation.is_before_date", {
            labelAfter: t(startDateLabel),
            labelBefore: locale.formatDate(earliestMandateStartDate),
          }),
        ),
      endDate: yup
        .date()
        .localDate()
        .nullable()
        .transform((v, o) => (o === "" ? undefined : v))
        .min(
          yup.ref("startDate"),
          t("common:validation.is_before_date", {
            labelAfter: t("payments.add_payment_instruction.end_date_label"),
            labelBefore: startDateLabel,
          }),
        ),
      signatureDate: yup
        .date()
        .localDate()
        .nullable()
        .isNotFuture(signatureDateLabel)
        .isValidDate(signatureDateLabel),
      day: yup.number(dayOfMonthLabel).when(["type"], {
        is: (type: PaymentTypeModel) => type === PaymentTypeModel.AdHocAmount,
        then: yup
          .number(dayOfMonthLabel)
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .isRequired(dayOfMonthLabel)
          .between(
            1,
            31,
            t("payments.add_payment_instruction.day_of_month_validation_label"),
          ),
      }),
    });
  });
};

interface AddPaymentInstructionFormDialogProps {
  instalmentDay?: number;
  onSubmit(data: AddPaymentInstructionFormValues): Promise<void>;
  onClose(): void;
  payees: PayeeModel[];
}

export default function AddPaymentInstructionFormDialog({
  instalmentDay,
  onClose,
  onSubmit,
  payees,
}: Readonly<AddPaymentInstructionFormDialogProps>): ReactElement {
  const { t } = useTranslation(["agreements", "common"]);
  const [getMandateStartDate, setMandateStartDate] = useState(
    payees[0].earliestMandateStartDate,
  );

  const agreement = useAgreement();
  const agreementSystemId = agreement.data?.systemId ?? "1";
  const paymentInstructions = useListPaymentInstructions(agreementSystemId);
  const getActiveAmountDueExists = paymentInstructions.data?.some(
    (p) => p.type === PaymentTypeModel.AmountDue && p.isActive,
  );

  const resolver = useAddPaymentInstructionResolver(getMandateStartDate);

  const form = useForm<AddPaymentInstructionFormValues>({
    defaultValues: {},
    resolver,
  });
  const [contactSystemId, type] = form.watch(["contactSystemId", "type"]);
  const { clearErrors, setValue } = form;

  useEffect(() => {
    if (
      type !== PaymentTypeModel.AmountDue &&
      type !== PaymentTypeModel.AdHocAmount
    ) {
      return;
    }
    if (type === PaymentTypeModel.AdHocAmount) {
      setValue("day", undefined);
      clearErrors("day");
    } else if (type === PaymentTypeModel.AmountDue) {
      setValue("day", instalmentDay);
      setValue("amount", undefined);
      clearErrors("day");
      clearErrors("amount");
    }
  }, [type, clearErrors, setValue, instalmentDay]);

  useEffect(() => {
    setMandateStartDate(
      payees.find((p) => p.contactSystemId === contactSystemId)
        ?.earliestMandateStartDate ?? getMandateStartDate,
    );
  }, [contactSystemId, getMandateStartDate, payees]);

  return (
    <form
      method="POST"
      aria-label={t(
        "payments.add_payment_instruction.add_payment_instruction_title",
      )}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <DialogTitle id="add-payment-instruction-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {t(
              "payments.add_payment_instruction.add_payment_instruction_title",
            )}
          </Box>
          <Box>
            <IconButton
              aria-label={t("common:close")}
              onClick={onClose}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <AddPaymentInstructionFormControls
          errors={form.formState.errors}
          control={form.control}
          register={form.register}
          payees={payees}
          currentContactSystemId={contactSystemId}
          currentSchedule={type}
          mandateStartDate={getMandateStartDate}
          activeAmountDueExists={Boolean(getActiveAmountDueExists)}
          contactId={agreement.data?.customerId ?? 0}
        />
      </DialogContent>

      {payees?.find((p) => p.contactSystemId === contactSystemId)
        ?.accountHoldersName && (
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {t("common:cancel")}
          </Button>
          <Button
            type="submit"
            disabled={form.formState.isSubmitting}
            variant="contained"
            color="primary"
          >
            {t("payments.add_payment_instruction.submit_button")}
          </Button>
        </DialogActions>
      )}
    </form>
  );
}

interface AddPaymentInstructionFormControlsProps {
  errors: FieldErrors<AddPaymentInstructionFormValues>;
  control: Control<AddPaymentInstructionFormValues>;
  register: UseFormRegister<AddPaymentInstructionFormValues>;
  currentSchedule: string;
  currentContactSystemId: string;
  payees?: PayeeModel[];
  mandateStartDate: Date;
  activeAmountDueExists: boolean;
  contactId: number;
}

function AddPaymentInstructionFormControls({
  errors,
  control,
  register,
  payees,
  currentSchedule,
  currentContactSystemId,
  mandateStartDate,
  activeAmountDueExists,
  contactId,
}: Readonly<AddPaymentInstructionFormControlsProps>): ReactElement {
  const { t } = useTranslation(["agreements", "common"]);
  const appConfig = useAppConfiguration();

  const payee = payees?.find(
    (p) => p.contactSystemId === currentContactSystemId,
  );

  return (
    <>
      <ControlledTextField
        helperText={errors?.contactSystemId?.message}
        error={errors?.contactSystemId?.message}
        SelectProps={{ displayEmpty: true }}
        control={control}
        id="contactSystemId"
        name="contactSystemId"
        label={t("payments.add_payment_instruction.payee_label")}
        select
        defaultValue=""
      >
        <MenuItem value="">
          <i>Please Select</i>
        </MenuItem>
        {payees?.map((p) => (
          <MenuItem key={p.contactSystemId} value={p.contactSystemId}>
            {`${p.contactType ?? "Main"} - ${p.name}`}
          </MenuItem>
        ))}
      </ControlledTextField>

      {Boolean(currentContactSystemId) && payees && (
        <>
          {!payee?.accountHoldersName ? (
            <Stack gap={2} my={2}>
              <Alert severity="warning" icon={<Warning />}>
                <Box component="header" display="flex" alignItems="center">
                  <Typography variant="body2">
                    {t(
                      "payments.add_payment_instruction.no_bank_account_error_message",
                    )}
                  </Typography>
                </Box>
              </Alert>
              <Box textAlign="center">
                <Typography color="textSecondary" gutterBottom>
                  {t("payments.add_payment_instruction.add_bank_account_label")}
                </Typography>
                <Link
                  component={RouterLink}
                  to={`${appConfig.appRoutes.servicing}/customers/${contactId}/bank-accounts`}
                >
                  <Button variant="contained" color="primary">
                    {t(
                      "payments.add_payment_instruction.add_bank_account_button",
                    )}
                  </Button>
                </Link>
              </Box>
            </Stack>
          ) : (
            <>
              <Box my={2}>
                <Paper variant="outlined">
                  <DescriptionList
                    label={t(
                      "payments.add_payment_instruction.bank_account_label",
                    )}
                  >
                    <DescriptionList.Item
                      label={t(
                        "payments.add_payment_instruction.account_name_label",
                      )}
                    >
                      {payee?.accountHoldersName}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t(
                        "payments.add_payment_instruction.account_number_label",
                      )}
                    >
                      {payee?.accountNumber}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t(
                        "payments.add_payment_instruction.sort_code_label",
                      )}
                    >
                      {payee?.sortCode}
                    </DescriptionList.Item>

                    <DescriptionList.Item
                      label={t("payments.add_payment_instruction.iban_label")}
                    >
                      {payee?.iban}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t("payments.add_payment_instruction.bic_label")}
                    >
                      {payee?.bic}
                    </DescriptionList.Item>
                  </DescriptionList>
                </Paper>
              </Box>

              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <ControlledTextField
                    helperText={errors.type?.message}
                    error={errors.type?.message}
                    control={control}
                    SelectProps={{ displayEmpty: true }}
                    id="type"
                    name="type"
                    label={t("payments.add_payment_instruction.type_label")}
                    select
                    defaultValue=""
                  >
                    <MenuItem value="">
                      <i>Please Select</i>
                    </MenuItem>
                    {!activeAmountDueExists && (
                      <MenuItem value={PaymentTypeModel.AmountDue}>
                        {PaymentTypeModel.AmountDue}
                      </MenuItem>
                    )}
                    <MenuItem value={PaymentTypeModel.AdHocAmount}>
                      {PaymentTypeModel.AdHocAmount}
                    </MenuItem>
                  </ControlledTextField>
                </Grid>
                {currentSchedule === PaymentTypeModel.AdHocAmount && (
                  <Grid item sm={6}>
                    <TextField
                      {...register("amount")}
                      label={t("payments.add_payment_instruction.amount_label")}
                      error={Boolean(errors.amount)}
                      helperText={errors.amount?.message}
                      type="number"
                      inputProps={{ step: ".01" }}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <DateField
                    control={control}
                    name="startDate"
                    error={Boolean(errors?.startDate?.message)}
                    helperText={errors?.startDate?.message}
                    inputProps={{
                      min: mandateStartDate.toISOString().split("T")[0],
                    }}
                    label={t(
                      "payments.add_payment_instruction.start_date_label",
                    )}
                  />
                </Grid>

                <Grid item sm={6}>
                  <DateField
                    control={control}
                    name="endDate"
                    error={Boolean(errors?.endDate?.message)}
                    helperText={errors?.endDate?.message}
                    label={t("payments.add_payment_instruction.end_date_label")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TextField
                    {...register("day", {
                      disabled: currentSchedule === PaymentTypeModel.AmountDue,
                    })}
                    error={Boolean(errors.day)}
                    helperText={errors.day?.message}
                    label={t(
                      "payments.add_payment_instruction.day_of_month_label",
                    )}
                    type="number"
                  />
                </Grid>

                <Grid item sm={6}>
                  <DateField
                    control={control}
                    name="signatureDate"
                    error={Boolean(errors?.signatureDate?.message)}
                    helperText={errors?.signatureDate?.message}
                    label={t(
                      "payments.add_payment_instruction.signature_date_label",
                    )}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
