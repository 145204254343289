/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing secondary rentals within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentFrequencyModel } from './PaymentFrequencyModel';
import {
    PaymentFrequencyModelFromJSON,
    PaymentFrequencyModelFromJSONTyped,
    PaymentFrequencyModelToJSON,
} from './PaymentFrequencyModel';

/**
 * 
 * @export
 * @interface UpdateSecondaryRentalDetailsModel
 */
export interface UpdateSecondaryRentalDetailsModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof UpdateSecondaryRentalDetailsModel
     */
    amount: string;
    /**
     * 
     * @type {PaymentFrequencyModel}
     * @memberof UpdateSecondaryRentalDetailsModel
     */
    frequency: PaymentFrequencyModel;
    /**
     * Introducer Share Percentage
     * @type {number}
     * @memberof UpdateSecondaryRentalDetailsModel
     */
    introducerShare?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof UpdateSecondaryRentalDetailsModel
     */
    maintenanceCharge?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof UpdateSecondaryRentalDetailsModel
     */
    insurancePremium?: string;
}

/**
 * Check if a given object implements the UpdateSecondaryRentalDetailsModel interface.
 */
export function instanceOfUpdateSecondaryRentalDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "frequency" in value;

    return isInstance;
}

export function UpdateSecondaryRentalDetailsModelFromJSON(json: any): UpdateSecondaryRentalDetailsModel {
    return UpdateSecondaryRentalDetailsModelFromJSONTyped(json, false);
}

export function UpdateSecondaryRentalDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSecondaryRentalDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'frequency': PaymentFrequencyModelFromJSON(json['frequency']),
        'introducerShare': !exists(json, 'introducerShare') ? undefined : json['introducerShare'],
        'maintenanceCharge': !exists(json, 'maintenanceCharge') ? undefined : json['maintenanceCharge'],
        'insurancePremium': !exists(json, 'insurancePremium') ? undefined : json['insurancePremium'],
    };
}

export function UpdateSecondaryRentalDetailsModelToJSON(value?: UpdateSecondaryRentalDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'frequency': PaymentFrequencyModelToJSON(value.frequency),
        'introducerShare': value.introducerShare,
        'maintenanceCharge': value.maintenanceCharge,
        'insurancePremium': value.insurancePremium,
    };
}

