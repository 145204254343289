/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Bank Accounts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BankAccountDetailsModel,
  CreateBankAccountModel,
  UpdateBankAccountModel,
} from '../models/index';
import {
    BankAccountDetailsModelFromJSON,
    BankAccountDetailsModelToJSON,
    CreateBankAccountModelFromJSON,
    CreateBankAccountModelToJSON,
    UpdateBankAccountModelFromJSON,
    UpdateBankAccountModelToJSON,
} from '../models/index';

export interface CreateBankAccountRequest {
    createBankAccountModel: CreateBankAccountModel;
}

export interface DeleteBankAccountRequest {
    id: string;
}

export interface GetBankAccountDetailsRequest {
    id: string;
}

export interface UpdateBankAccountRequest {
    id: string;
    updateBankAccountModel: UpdateBankAccountModel;
}

/**
 * 
 */
export class BankAccountsApi extends runtime.BaseAPI {

    /**
     * Create a new Bank Account
     */
    async createBankAccountRaw(requestParameters: CreateBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createBankAccountModel === null || requestParameters.createBankAccountModel === undefined) {
            throw new runtime.RequiredError('createBankAccountModel','Required parameter requestParameters.createBankAccountModel was null or undefined when calling createBankAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["bank-accounts:manage"]);
        }

        const response = await this.request({
            path: `/bank-accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBankAccountModelToJSON(requestParameters.createBankAccountModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new Bank Account
     */
    async createBankAccount(requestParameters: CreateBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createBankAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a Bank Account
     */
    async deleteBankAccountRaw(requestParameters: DeleteBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBankAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["bank-accounts:manage"]);
        }

        const response = await this.request({
            path: `/bank-accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Bank Account
     */
    async deleteBankAccount(requestParameters: DeleteBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBankAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Get Bank Account Details
     */
    async getBankAccountDetailsRaw(requestParameters: GetBankAccountDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BankAccountDetailsModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBankAccountDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["bank-accounts:view"]);
        }

        const response = await this.request({
            path: `/bank-accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BankAccountDetailsModelFromJSON(jsonValue));
    }

    /**
     * Get Bank Account Details
     */
    async getBankAccountDetails(requestParameters: GetBankAccountDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BankAccountDetailsModel> {
        const response = await this.getBankAccountDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Bank Account
     */
    async updateBankAccountRaw(requestParameters: UpdateBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBankAccount.');
        }

        if (requestParameters.updateBankAccountModel === null || requestParameters.updateBankAccountModel === undefined) {
            throw new runtime.RequiredError('updateBankAccountModel','Required parameter requestParameters.updateBankAccountModel was null or undefined when calling updateBankAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["bank-accounts:manage"]);
        }

        const response = await this.request({
            path: `/bank-accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBankAccountModelToJSON(requestParameters.updateBankAccountModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a Bank Account
     */
    async updateBankAccount(requestParameters: UpdateBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateBankAccountRaw(requestParameters, initOverrides);
    }

}
