import { useFormat } from "@ldms/mui-sdk/formatting";
import { AmountField } from "@ldms/mui-sdk/forms";
import { AlertDialog } from "@ldms/mui-sdk/templates";
import { Box, Divider, Grid, MenuItem, Typography } from "@mui/material";
import { useGetFinancialProductDetails } from "api/onboarding/financial-products/getFinancialProductDetails";
import {
  OnboardingStepActions,
  RepaymentTermsFieldSet,
} from "apps/onboarding/components";
import SeasonalPaymentsFieldSet from "apps/onboarding/components/SeasonalPaymentsFieldSet";
import CustomInstalmentsContainer from "apps/onboarding/containers/CustomInstalmentsContainer";
import { OnboardingState, useOnboarding } from "apps/onboarding/providers";
import {
  ControlledTextField,
  KeyValueRow,
  KeyValueTable,
} from "common/components";
import { useLocale, useYupResolver } from "common/hooks";
import useError from "common/hooks/useResponseError";
import { YupForm } from "common/hooks/useYupResolver/useYupResolver";
import { useStepper } from "common/providers";
import {
  addMonths,
  formatISO,
  isAfter,
  isBefore,
  isEqual,
  subYears,
} from "date-fns";
import {
  CustomInstalmentModel,
  FinancialProductDetailsModel,
  FrequencyOfInstalmentsModel,
  PaymentStructureModel,
  ProductTypeModel,
} from "generated/onboarding/models";
import { FC } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import errorHandler from "support/error-handler";
import {
  DefaultValues,
  Resolver,
  UseFormReturn,
  useForm,
} from "support/react-hook-form";
import * as yup from "yup";

const makeProductSpecificErrorCodes = (
  product: ProductTypeModel,
): [string, string][] => {
  const codes: [string, string][] = [];

  if (product === ProductTypeModel.FixedRateHirePurchase) {
    codes.push(
      [
        "invalid_instalment_amount",
        "error.instalment_amount_must_be_greater_than_zero",
      ],
      [
        "invalid_first_payment_date",
        "error.first_instalment_date_cannot_be_before_start_date",
      ],
      [
        "invalid_total_loan_amount",
        "error.balance_financed_must_be_greater_than_instalment_amount",
      ],
      [
        "invalid_part_exchange",
        "error.part_exchange_must_be_greater_than_equal_to_zero",
      ],
      [
        "invalid_annual_admin",
        "error.annual_admin_fee_not_applicable_given_instalments",
      ],
      [
        "invalid_number_of_instalment_months",
        "error.invalid_number_of_instalments",
      ],
      ["invalid_instalment_months", "error.invalid_instalment_months"],
      [
        "invalid_monthly_instalment_amounts",
        "error.invalid_monthly_instalment_amounts",
      ],
    );
  }
  if (
    product === ProductTypeModel.FixedRateFinanceLease ||
    product === ProductTypeModel.FixedRateOperatingLease
  ) {
    codes.push(
      [
        "invalid_loan_term",
        "error.period_of_lease_must_be_greater_than_one_period",
      ],
      [
        "invalid_instalment_amount",
        "error.rental_amount_must_be_greater_than_zero",
      ],
      [
        "invalid_first_payment_date",
        "error.first_rental_date_cannot_be_before_start_date",
      ],
      [
        "invalid_total_loan_amount",
        "error.total_amount_must_be_greater_than_rental_amount",
      ],
      [
        "invalid_annual_admin",
        "error.annual_admin_fee_not_applicable_given_rentals",
      ],
      [
        "invalid_advanced_payment",
        "error.initial_payment_amount_must_be_greater_than_equal_to_zero",
      ],
      [
        "invalid_number_of_instalment_months",
        "error.invalid_number_of_rental_payments",
      ],
      ["invalid_instalment_months", "error.invalid_rental_payment_months"],
      [
        "invalid_monthly_instalment_amounts",
        "error.invalid_monthly_rental_amounts",
      ],
      ["invalid_vat_reclaim_month", "error.invalid_vat_reclaim_month"],
      [
        "invalid_first_payment_date_start_date",
        "error.invalid_first_payment_date_start_date",
      ],
    );
  }
  if (product === ProductTypeModel.FixedRateLoan) {
    codes.push(
      [
        "invalid_instalment_amount",
        "error.repayment_amount_must_be_greater_than_zero",
      ],
      [
        "invalid_first_payment_date",
        "error.first_repayment_due_date_cannot_be_before_start_date",
      ],
      [
        "invalid_total_loan_amount",
        "error.total_amount_must_be_greater_than_repayment_amount",
      ],
      ["invalid_asset_vat", "error.asset_vat_must_be_zero"],
      [
        "invalid_number_of_instalment_months",
        "error.invalid_number_of_repayments",
      ],
      ["invalid_instalment_months", "error.invalid_repayment_months"],
      [
        "invalid_monthly_instalment_amounts",
        "error.invalid_monthly_repayment_amounts",
      ],
      [
        "invalid_annual_admin",
        "error.annual_admin_fee_not_applicable_given_instalments",
      ],
    );
  }
  if (
    product === ProductTypeModel.FixedRateHirePurchase ||
    product === ProductTypeModel.FixedRateLoan
  ) {
    codes.push([
      "invalid_loan_term",
      "error.loan_term_must_be_greater_than_one_period",
    ]);
  }
  if (
    product === ProductTypeModel.FixedRateHirePurchase ||
    product === ProductTypeModel.FixedRateFinanceLease ||
    product === ProductTypeModel.FixedRateLoan ||
    product === ProductTypeModel.FixedRateOperatingLease
  ) {
    codes.push([
      "invalid_cash_deposit",
      "error.cash_deposit_must_be_greater_than_equal_to_zero",
    ]);
  }
  if (product === ProductTypeModel.FixedRateFinanceLease) {
    codes.push([
      "invalid_secondary_rental_date",
      "error.secondary_rental_date_must_be_after_final_rental_date",
    ]);
  }
  if (
    product === ProductTypeModel.FixedRateFinanceLease ||
    product === ProductTypeModel.FixedRateHirePurchase ||
    product === ProductTypeModel.FixedRateLoan
  ) {
    codes.push([
      "invalid_balloon_amount",
      "error.balloon_amount_must_be_greater_than_or_equal_to_zero",
    ]);
  }
  if (
    product === ProductTypeModel.FixedRateFinanceLease ||
    product === ProductTypeModel.FixedRateOperatingLease ||
    product === ProductTypeModel.FixedRateHirePurchase
  ) {
    codes.push([
      "invalid_fees",
      "error.fee_amounts_must_be_greater_than_equal_to_zero",
    ]);
  }
  return codes;
};

const getMonths = (formValues: RepaymentTermsFormModel): number[] => {
  return [
    formValues.january || 0,
    formValues.february || 0,
    formValues.march || 0,
    formValues.april || 0,
    formValues.may || 0,
    formValues.june || 0,
    formValues.july || 0,
    formValues.august || 0,
    formValues.september || 0,
    formValues.october || 0,
    formValues.november || 0,
    formValues.december || 0,
  ];
};

const calculateFirstPaymentAmount = (
  paymentStructure: PaymentStructureModel,
  firstPaymentDate: Date | undefined,
  frequencyAmount: number,
  frontendFee: number,
  months: number[],
): number => {
  if (!firstPaymentDate) {
    return 0;
  }

  if (paymentStructure === PaymentStructureModel.Regular) {
    return frontendFee + frequencyAmount;
  } else {
    const monthOrdinal = new Date(firstPaymentDate).getMonth();
    return frontendFee + Number(months[monthOrdinal] || 0);
  }
};

const calculateFinalPaymentAmount = (
  paymentStructure: PaymentStructureModel,
  frequencyAmount: number,
  applyBalloonAsLastPayment: boolean,
  balloonPayment: number,
  backendFee: number,
  finalPaymentDate: Date | undefined,
  months: number[],
): number => {
  if (!finalPaymentDate) {
    return 0;
  }

  let finalPaymentAmount = 0;
  if (paymentStructure === PaymentStructureModel.Regular) {
    finalPaymentAmount = frequencyAmount;
  } else {
    const monthOrdinal = finalPaymentDate.getMonth();
    finalPaymentAmount = Number(months[monthOrdinal] || 0);
  }

  if (balloonPayment) {
    return (
      balloonPayment +
      backendFee +
      (applyBalloonAsLastPayment ? 0 : finalPaymentAmount)
    );
  } else {
    return finalPaymentAmount + backendFee;
  }
};

export const getOnboardingSubmitErrors = (
  t: TFunction<"onboardings">,
  product: ProductTypeModel,
): [string, string][] => {
  const nonProductSpecificErrorCodes: [string, string][] = [
    [
      "max_amortisation_attempts_reached",
      t("error.max_amortisation_attempts_reached"),
    ],
    [
      "invalid_asset_cash_price",
      t("error.asset_cash_price_must_be_greater_than_zero_and_vat"),
    ],
    [
      "invalid_asset_year_of_manufacture",
      t("error.asset_year_of_manufacture_must_be_between_1900_and_next_year", {
        limit: new Date().getFullYear() + 1,
      }),
    ],
    [
      "invalid_customer_interest_rate",
      t(
        "error.customer_interest_rate_must_be_greater_than_zero_less_than_equal_to_hundred",
      ),
    ],
    [
      "invalid_customer_details",
      t("error.agreement_must_be_for_a_new_or_existing_customer"),
    ],
    ["invalid_sort_code", t("error.incorrect_sort_code_format")],
    ["invalid_account_number", t("error.incorrect_account_number_format")],
    ["invalid_setup_costs", t("error.invalid_setup_costs")],
    ["invalid_loss_pool_percentage", t("error.invalid_loss_pool_percentage")],
    [
      "invalid_custom_starting_on_date",
      t("error.invalid_custom_starting_on_date"),
    ],
    [
      "invalid_custom_starting_on_exceeds_term",
      t("error.invalid_custom_starting_on_exceeds_term"),
    ],
    [
      "invalid_custom_instalment_amount",
      t("error.invalid_custom_instalment_amount"),
    ],
    [
      "invalid_custom_number_of_instalments",
      t("error.invalid_custom_number_of_instalments"),
    ],
    [
      "invalid_custom_total_number_of_instalments",
      t("error.invalid_custom_total_number_of_instalments"),
    ],
    [
      "invalid_custom_instalment_ordering",
      t("error.invalid_custom_instalment_ordering"),
    ],
  ];

  const codes: [string, string][] = makeProductSpecificErrorCodes(product).map(
    ([code, message]) => [code, t(message)],
  );

  return [...nonProductSpecificErrorCodes, ...codes];
};

interface FinalPaymentDateProps {
  loanTerm?: number;
  repaymentFrequency?: string;
  firstPaymentDate?: Date;
  paymentStructure?: PaymentStructureModel;
}

const titleLabel = "repayment_terms.title";

const leaseTypes: string[] = [
  ProductTypeModel.FixedRateOperatingLease,
  ProductTypeModel.FixedRateFinanceLease,
];

const frequencyMonths = {
  [FrequencyOfInstalmentsModel.Annually]: 12,
  [FrequencyOfInstalmentsModel.HalfYearly]: 6,
  [FrequencyOfInstalmentsModel.Quarterly]: 3,
  [FrequencyOfInstalmentsModel.Monthly]: 1,
};

const transformField = (v: number): number | undefined => {
  return isNaN(v) ? undefined : v;
};

const getFinalPaymentDate = ({
  loanTerm,
  repaymentFrequency,
  firstPaymentDate,
  paymentStructure,
}: FinalPaymentDateProps): Date | undefined => {
  if (
    paymentStructure === PaymentStructureModel.Seasonal ||
    paymentStructure === PaymentStructureModel.Custom
  ) {
    repaymentFrequency = FrequencyOfInstalmentsModel.Monthly;
  }
  if (!loanTerm || !repaymentFrequency || !firstPaymentDate) {
    return undefined;
  }

  const frequency =
    frequencyMonths[repaymentFrequency as FrequencyOfInstalmentsModel];
  const monthsToAdd = (loanTerm - 1) * frequency;
  return addMonths(new Date(firstPaymentDate), monthsToAdd);
};

const getDefaultDate = (v: Date | undefined): Date | undefined => {
  return v ? new Date(v) : undefined;
};

const getProductTranslation = (product: ProductTypeModel): string => {
  switch (product) {
    case ProductTypeModel.FixedRateHirePurchase:
      return "repayment_terms.hire_purchase";
    case ProductTypeModel.FixedRateFinanceLease:
      return "repayment_terms.finance_lease";
    case ProductTypeModel.FixedRateOperatingLease:
      return "repayment_terms.operating_lease";
    default:
      return "repayment_terms.commercial_loan";
  }
};

export type CustomInstalmentFormModel = {
  startingOn: Date | null;
  instalmentAmount: number | null;
  forNumberOfInstalments: number | null;
};

export type RepaymentTermsFormModel = {
  paymentStructure: PaymentStructureModel;
  loanTerm: number;
  repaymentFrequency?: string;
  frequencyAmount?: number;
  inceptionDate?: Date;
  firstPaymentDate: Date;
  secondaryPeriodRentalAmount?: number;
  firstSecondaryPeriodRentalDate?: Date;
  customerInterestRate: number;
  initialRentalPaymentAmount?: number;
  b2bFundingRate?: number;
  rateToBroker?: number;
  january?: number;
  february?: number;
  march?: number;
  april?: number;
  may?: number;
  june?: number;
  july?: number;
  august?: number;
  september?: number;
  october?: number;
  november?: number;
  december?: number;
  customInstalments?: CustomInstalmentFormModel[];
};

export type RepaymentTermsStateModel = {
  january?: number;
  february?: number;
  march?: number;
  april?: number;
  may?: number;
  june?: number;
  july?: number;
  august?: number;
  september?: number;
  october?: number;
  november?: number;
  december?: number;
  paymentStructure: PaymentStructureModel;
  loanTerm: number;
  repaymentFrequency?: string;
  frequencyAmount?: number;
  inceptionDate: Date;
  firstPaymentDate: Date;
  secondaryPeriodRentalAmount?: number;
  firstSecondaryPeriodRentalDate?: Date;
  customerInterestRate: number;
  initialRentalPaymentAmount?: number;
  b2bFundingRate?: number;
  rateToBroker?: number;
  customInstalments?: {
    startingOn: Date;
    instalmentAmount: number;
    forNumberOfInstalments: number;
  }[];
};

interface ResolverProps {
  product: ProductTypeModel;
  balanceFinanced: number;
  financialProductDetails?: FinancialProductDetailsModel;
}

export function isNotCustomPaymentStructure(paymentStructure: string): boolean {
  return [PaymentStructureModel.Regular, PaymentStructureModel.Seasonal]
    .map((value) => value.toString())
    .includes(paymentStructure);
}
type CustomValues = {
  startingOnValue: Date | undefined;
  inceptionDateValue: Date | undefined;
  lastPayingDate: Date | undefined;
};

const veirfyDefinedValues = (
  values: CustomValues,
): values is {
  startingOnValue: Date;
  inceptionDateValue: Date;
  lastPayingDate: Date;
} => {
  if (
    values.startingOnValue &&
    values.inceptionDateValue &&
    values.lastPayingDate
  ) {
    return true;
  }
  return false;
};

function useRepaymentTermsResolver(
  props: ResolverProps,
): Resolver<RepaymentTermsFormModel> {
  const { t } = useTranslation("onboardings");
  const locale = useLocale();
  const productTranslation = getProductTranslation(props.product);
  const startingOnLabel = t("repayment_terms.starting_on_label");
  const instalmentAmountLabel = t("repayment_terms.instalment_amount_label");
  const numberOfInstalmentsLabel = t(
    "repayment_terms.number_of_instalments_label",
  );
  let inceptionDateValue: Date;
  let loanTermValue: number;
  let forNumberOfInstalmentsTotalValue: number;
  let lastPayingDate: Date | undefined;

  const customInstalmentSchema = (yup: YupForm) => {
    return yup.object().shape({
      startingOn: yup
        .date()
        .nullable()
        .transform(transformField)
        .isRequired(startingOnLabel)
        .isValidDate(startingOnLabel)
        .test({
          name: "invalidStartingOn",
          test: (startingOnValue, context) => {
            const values = {
              startingOnValue,
              inceptionDateValue,
              lastPayingDate,
            };
            if (!veirfyDefinedValues(values)) {
              return true;
            }
            if (isBefore(values.startingOnValue, inceptionDateValue)) {
              return context.createError({
                message: t("common:validation.is_before_date", {
                  labelAfter: startingOnLabel,
                  labelBefore: t(`${productTranslation}.inception_date_label`),
                }),
                path: context.path,
              });
            }
            if (
              leaseTypes.includes(props.product) &&
              isEqual(values.startingOnValue, inceptionDateValue)
            ) {
              return context.createError({
                message: t("common:validation.is_equal", {
                  label: startingOnLabel,
                  referencingLabel: t(
                    `${productTranslation}.inception_date_label`,
                  ),
                }),
                path: context.path,
              });
            }
            if (
              isAfter(
                new Date(locale.formatISODate(values.startingOnValue)),
                new Date(locale.formatISODate(values.lastPayingDate)),
              )
            ) {
              return context.createError({
                message: t("form_validation.is_after", {
                  label: startingOnLabel,
                }),
                path: context.path,
              });
            }
            return true;
          },
        }),
      instalmentAmount: yup
        .number(instalmentAmountLabel)
        .nullable()
        .transform(transformField)
        .isRequired(instalmentAmountLabel)
        .minAmount(0, instalmentAmountLabel),
      forNumberOfInstalments: yup
        .number(numberOfInstalmentsLabel)
        .transform(transformField)
        .isRequired(numberOfInstalmentsLabel)
        .test({
          name: "greaterThanTotalNumberOfInstalments",
          test: (forNumberOfInstalments, context) => {
            if (
              forNumberOfInstalments &&
              forNumberOfInstalments > loanTermValue
            ) {
              return context.createError({
                message: t("common:validation.is_too_large", {
                  limit: loanTermValue,
                  label: numberOfInstalmentsLabel,
                }),
                path: context.path,
              });
            }
            if (
              forNumberOfInstalmentsTotalValue &&
              forNumberOfInstalmentsTotalValue > loanTermValue
            ) {
              return context.createError({
                message: t(
                  "form_validation.exceeds_total_number_of_instalments",
                  {
                    customValue: forNumberOfInstalmentsTotalValue,
                    agreementValue: loanTermValue,
                  },
                ),
                path: context.path,
              });
            }
            return true;
          },
        })
        .moreThan(
          0,
          t("common:validation.is_greater_than_zero", {
            label: numberOfInstalmentsLabel,
          }),
        ),
    });
  };

  return useYupResolver<RepaymentTermsFormModel>((yup) => {
    const makeSeasonalPaymentMonthSchema = (label: string): yup.NumberSchema =>
      yup
        .number(t("repayment_terms.seasonal_payment_structure_label"))
        .transform(transformField)
        .when("paymentStructure", {
          is: "Seasonal",
          then: (schema) => schema.isRequired(label),
        });

    return yup.object().shape({
      paymentStructure: yup
        .string()
        .required(t(`repayment_terms.payment_structure_label`)),
      loanTerm: yup
        .number(t(`${productTranslation}.loan_term_label`))
        .transform(transformField)
        .isRequired(t(`${productTranslation}.loan_term_label`))
        .minAmount(2, t(`${productTranslation}.loan_term_label`))
        .maxAmount(999, t(`${productTranslation}.loan_term_label`)),
      repaymentFrequency: yup.string().when("paymentStructure", {
        is: PaymentStructureModel.Regular,
        then: yup
          .string()
          .isRequired(t(`${productTranslation}.repayment_frequency_label`)),
      }),
      frequencyAmount: yup
        .number(t(`${productTranslation}.frequency_amount_label`))
        .transform(transformField)
        .when("paymentStructure", {
          is: PaymentStructureModel.Regular,
          then: yup
            .number(t(`${productTranslation}.frequency_amount_label`))
            .isRequired(t(`${productTranslation}.frequency_amount_label`))
            .transform(transformField)
            .lessThanAmount(
              props.balanceFinanced,
              t(`${productTranslation}.frequency_amount_label`),
            )
            .greaterThanAmount(
              0,
              t(`${productTranslation}.frequency_amount_label`),
            ),
        }),
      initialRentalPaymentAmount: yup
        .number(t(`${productTranslation}.initial_payment_amount_label`))
        .transform(transformField)
        .greaterThanAmount(
          0,
          t(`${productTranslation}.initial_payment_amount_label`),
        )
        .maxAmount(
          99999999.99,
          t(`${productTranslation}.initial_payment_amount_label`),
        ),
      inceptionDate: yup
        .date()
        .localDate()
        .isValidDate(t(`${productTranslation}.inception_date_label`))
        .isRequired(t(`${productTranslation}.inception_date_label`))
        .test({
          name: "ninetyYears",
          message: t("repayment_terms.invalid_inception_date"),
          test: (value) => {
            if (!value) {
              return true;
            }
            const ninetyYearsAgo = subYears(new Date(), 90);
            return isBefore(ninetyYearsAgo, value);
          },
        }),
      firstPaymentDate: yup.date().when("paymentStructure", {
        is: (value: string) => isNotCustomPaymentStructure(value),
        then: yup
          .date()
          .localDate()
          .isValidDate(t(`${productTranslation}.first_payment_date_label`))
          .min(
            yup.ref("inceptionDate"),
            t("common:validation.is_before_date", {
              labelAfter: t(`${productTranslation}.first_payment_date_label`),
              labelBefore: t(`${productTranslation}.inception_date_label`),
            }),
          )
          .isRequired(t(`${productTranslation}.first_payment_date_label`))
          .when("inceptionDate", (inceptionDate: Date, schema) => {
            return schema.test({
              name: "sameAsInceptionDate",
              message: t("common:validation.is_equal", {
                label: t(`${productTranslation}.first_payment_date_label`),
                referencingLabel: t(
                  `${productTranslation}.inception_date_label`,
                ),
              }),
              test: (firstPaymentDate: Date) => {
                if (!leaseTypes.includes(props.product)) {
                  return true;
                }
                return !isEqual(firstPaymentDate, inceptionDate);
              },
            });
          })
          .when(
            "initialRentalPaymentAmount",
            (initialRentalPaymentAmount: number, schema) => {
              return schema.test({
                name: "sameAsStartDateInitialRentalAmount",
                message: t(
                  "repayment_terms.first_payment_date_after_start_date_when_initial_rental_error_message",
                  {
                    firstPaymentDate: t(
                      `${productTranslation}.first_payment_date_label`,
                    ),
                    inceptionDate: t(
                      `${productTranslation}.inception_date_label`,
                    ),
                    initalRentalAmount: t(
                      `${productTranslation}.initial_payment_amount_label`,
                    ),
                  },
                ),
                test: (firstPaymentDate: Date, context: yup.TestContext) => {
                  if (!initialRentalPaymentAmount) {
                    return true;
                  }
                  return !isEqual(
                    firstPaymentDate,
                    context.parent.inceptionDate,
                  );
                },
              });
            },
          ),
      }),
      customerInterestRate: yup
        .number(t(`${productTranslation}.customer_interest_rate_label`))
        .transform(transformField)
        .greaterThanAmount(
          0,
          t(`${productTranslation}.customer_interest_rate_label`),
        )
        .lessThanAmount(
          100,
          t(`${productTranslation}.customer_interest_rate_label`),
        )
        .isRequired(t(`${productTranslation}.customer_interest_rate_label`)),
      b2bFundingRate: yup
        .number(t(`${productTranslation}.b2b_funding_rate_label`))
        .transform(transformField)
        .greaterThanAmount(0, t(`${productTranslation}.b2b_funding_rate_label`))
        .maxAmount(100, t(`${productTranslation}.b2b_funding_rate_label`))
        .test({
          name: "isCompoundedMonthly",
          message: t("form_validation.lender_rate_compounding_monthly"),
          test: (b2bFundingRate: number | undefined) => {
            if (
              b2bFundingRate &&
              props.financialProductDetails?.interestCompoundingFrequency !==
                FrequencyOfInstalmentsModel.Monthly
            ) {
              return false;
            }
            return true;
          },
        }),
      rateToBroker: yup
        .number(t(`${productTranslation}.rate_to_broker_label`))
        .transform(transformField)
        .maxAmount(100, t(`${productTranslation}.rate_to_broker_label`)),
      january: makeSeasonalPaymentMonthSchema(
        t("repayment_terms.january_label"),
      ),
      february: makeSeasonalPaymentMonthSchema(
        t("repayment_terms.february_label"),
      ),
      march: makeSeasonalPaymentMonthSchema(t("repayment_terms.march_label")),
      april: makeSeasonalPaymentMonthSchema(t("repayment_terms.april_label")),
      may: makeSeasonalPaymentMonthSchema("repayment_terms.may_label"),
      june: makeSeasonalPaymentMonthSchema(t("repayment_terms.june_label")),
      july: makeSeasonalPaymentMonthSchema(t("repayment_terms.july_label")),
      august: makeSeasonalPaymentMonthSchema(t("repayment_terms.august_label")),
      september: makeSeasonalPaymentMonthSchema(
        t("repayment_terms.september_label"),
      ),
      october: makeSeasonalPaymentMonthSchema(
        t("repayment_terms.october_label"),
      ),
      november: makeSeasonalPaymentMonthSchema(
        t("repayment_terms.november_label"),
      ),
      december: makeSeasonalPaymentMonthSchema(
        t("repayment_terms.december_label"),
      ),
      customInstalments: yup.array().when("paymentStructure", {
        is: PaymentStructureModel.Custom,
        then: yup
          .array()
          .test({
            name: "numberOfInstalmentsMoreThanLoanTerm",
            test: (value, testContext) => {
              const total = value?.reduce(
                (
                  accumulator: number,
                  customInstalment: CustomInstalmentModel,
                ) => {
                  return accumulator + customInstalment.forNumberOfInstalments;
                },
                0,
              );

              inceptionDateValue = testContext.parent.inceptionDate;
              loanTermValue = testContext.parent.loanTerm;
              forNumberOfInstalmentsTotalValue = total;
              lastPayingDate = undefined;
              lastPayingDate = addMonths(
                new Date(value?.[0].startingOn),
                loanTermValue - 1,
              );
              return true;
            },
          })
          .of(customInstalmentSchema(yup)),
      }),
    });
  });
}

const RepaymentTermsFormControls: FC<{
  form: UseFormReturn<RepaymentTermsFormModel>;
}> = ({ form }) => {
  const { t } = useTranslation("onboardings");
  const onboarding = useOnboarding();
  const { formatAmount } = useFormat();
  const product = onboarding.state.agreementDetails
    ?.productType as ProductTypeModel;
  const productTranslation = getProductTranslation(product);

  const formValues = form.watch();

  const getNumberValue = (value: number | undefined) => value || 0;

  const isAgreementFundedVat = Boolean(
    onboarding.state.agreementDetails?.isFundedVat,
  );

  const initialRentalVat = isAgreementFundedVat
    ? getNumberValue(formValues.initialRentalPaymentAmount) *
      (getNumberValue(onboarding.state.agreementDetails?.vatRate) / 100)
    : 0;

  const totalFundedVatAmount = isAgreementFundedVat
    ? getNumberValue(
        onboarding.state.feesAndCommissions?.totalFundedVatAmount,
      ) - initialRentalVat
    : 0;

  const balanceFinanced =
    (onboarding.state.feesAndCommissions?.balanceFinanced ??
      onboarding.state.assetDetails?.totalNetCashPrice ??
      0) - initialRentalVat;

  const applyBalloonAsLastPayment =
    onboarding.state.agreementDetails?.applyBalloonAsLastPayment ?? false;
  const frequencyAmount = getNumberValue(formValues.frequencyAmount);
  const months = getMonths(formValues);
  const firstPaymentAmount = calculateFirstPaymentAmount(
    formValues.paymentStructure,
    formValues.firstPaymentDate,
    frequencyAmount,
    getNumberValue(onboarding.state.feesAndCommissions?.frontendFee),
    months,
  );
  const finalPaymentDate = getFinalPaymentDate(formValues);
  const finalPaymentAmount = calculateFinalPaymentAmount(
    formValues.paymentStructure,
    frequencyAmount,
    applyBalloonAsLastPayment,
    getNumberValue(onboarding.state.financialDetails?.balloonPayment),
    getNumberValue(onboarding.state.feesAndCommissions?.backendFee),
    finalPaymentDate,
    months,
  );

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item sm={6}>
          {[
            ...leaseTypes,
            ProductTypeModel.FixedRateHirePurchase,
            ProductTypeModel.FixedRateLoan,
          ].includes(product) && (
            <KeyValueTable
              label="repaymentTerms.totalAmount.table"
              testId="repaymentTerms.totalAmount.table"
            >
              <KeyValueRow
                align="right"
                label={t(`${productTranslation}.total_amount_label`)}
              >
                {formatAmount(balanceFinanced)}
              </KeyValueRow>
            </KeyValueTable>
          )}

          <ControlledTextField
            helperText={form.formState.errors?.paymentStructure?.message}
            SelectProps={{ displayEmpty: true }}
            error={form.formState.errors?.paymentStructure?.message}
            control={form.control}
            label={t(`repayment_terms.payment_structure_label`)}
            id="paymentStructure"
            name="paymentStructure"
            select
            required
          >
            {Object.values(PaymentStructureModel).map((currentValue) => (
              <MenuItem value={String(currentValue)} key={String(currentValue)}>
                {currentValue}
              </MenuItem>
            ))}
          </ControlledTextField>

          <RepaymentTermsFieldSet
            labels={{
              loanTerm: t(`${productTranslation}.loan_term_label`),
              repaymentFrequency: t(
                `${productTranslation}.repayment_frequency_label`,
              ),
              frequencyAmount: t(
                `${productTranslation}.frequency_amount_label`,
              ),
              inceptionDate: t(`${productTranslation}.inception_date_label`),
              firstPaymentDate: t(
                `${productTranslation}.first_payment_date_label`,
              ),
              customerInterestRate: t(
                `${productTranslation}.customer_interest_rate_label`,
              ),
              b2bFundingRate: t(`${productTranslation}.b2b_funding_rate_label`),
              rateToBroker: t(`${productTranslation}.rate_to_broker_label`),
            }}
            errors={form.formState.errors}
            register={form.register}
            control={form.control}
            seasonal={
              formValues.paymentStructure === PaymentStructureModel.Seasonal
            }
            custom={
              formValues.paymentStructure === PaymentStructureModel.Custom
            }
          />

          <AmountField
            label={t(`${productTranslation}.initial_payment_amount_label`)}
            name="initialRentalPaymentAmount"
            error={Boolean(
              form.formState.errors?.initialRentalPaymentAmount?.message,
            )}
            helperText={
              form.formState.errors?.initialRentalPaymentAmount?.message
            }
            control={form.control}
          />

          <KeyValueTable
            label="repaymentTerms.firstPaymentAmount.table"
            testId="repaymentTerms.firstPaymentAmount.table"
          >
            <>
              {product !== ProductTypeModel.FixedRateLoan &&
                formValues.paymentStructure !==
                  PaymentStructureModel.Custom && (
                  <KeyValueRow
                    align="right"
                    label={t(
                      `${productTranslation}.first_payment_amount_label`,
                    )}
                  >
                    {formatAmount(firstPaymentAmount)}
                  </KeyValueRow>
                )}
              {isAgreementFundedVat && (
                <>
                  <KeyValueRow
                    align="right"
                    label={t(`${productTranslation}.vat_on_initial_rental`)}
                  >
                    {formatAmount(initialRentalVat)}
                  </KeyValueRow>
                  <KeyValueRow
                    align="right"
                    label={t(`${productTranslation}.total_funded_vat_label`)}
                  >
                    {formatAmount(totalFundedVatAmount)}
                  </KeyValueRow>
                </>
              )}
            </>
          </KeyValueTable>
        </Grid>
      </Grid>

      {formValues.paymentStructure === PaymentStructureModel.Seasonal && (
        <SeasonalPaymentsFieldSet
          labels={{
            title: t(`${productTranslation}.repayments_title_label`),
            january: t("repayment_terms.january_label"),
            february: t("repayment_terms.february_label"),
            march: t("repayment_terms.march_label"),
            april: t("repayment_terms.april_label"),
            may: t("repayment_terms.may_label"),
            june: t("repayment_terms.june_label"),
            july: t("repayment_terms.july_label"),
            august: t("repayment_terms.august_label"),
            september: t("repayment_terms.september_label"),
            october: t("repayment_terms.october_label"),
            november: t("repayment_terms.november_label"),
            december: t("repayment_terms.december_label"),
          }}
          errors={form.formState.errors}
          control={form.control}
        />
      )}

      {formValues.paymentStructure === PaymentStructureModel.Custom && (
        <CustomInstalmentsContainer
          labels={{
            title: t("repayment_terms.custom_instalments_label"),
            startingOn: t("repayment_terms.starting_on_label"),
            instalmentAmount: t("repayment_terms.instalment_amount_label"),
            numberOfInstalments: t(
              "repayment_terms.number_of_instalments_label",
            ),
            add: t("repayment_terms.add_custom_instalment_button"),
            remove: t("repayment_terms.remove_custom_instalment_button"),
          }}
          form={form}
        />
      )}

      {formValues.paymentStructure !== PaymentStructureModel.Custom && (
        <>
          <Divider sx={{ marginY: 3 }} />
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h6">
                {t("repayment_terms.final_payment_title")}
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <KeyValueTable testId="repaymentTerms.finalPayment.table">
                <KeyValueRow
                  align="right"
                  label={t(`${productTranslation}.final_payment_date_label`)}
                >
                  {finalPaymentDate
                    ? formatISO(finalPaymentDate, {
                        representation: "date",
                      })
                    : "-"}
                </KeyValueRow>
                <KeyValueRow
                  align="right"
                  label={t(`${productTranslation}.final_payment_amount_label`)}
                >
                  {formatAmount(finalPaymentAmount)}
                </KeyValueRow>
              </KeyValueTable>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const makeDefaultValuesFromOnboardingState = (
  onboarding: OnboardingState,
): DefaultValues<RepaymentTermsFormModel> => ({
  january: 0,
  february: 0,
  march: 0,
  april: 0,
  may: 0,
  june: 0,
  july: 0,
  august: 0,
  september: 0,
  october: 0,
  november: 0,
  december: 0,
  ...onboarding.repaymentTerms,
  paymentStructure: onboarding.repaymentTerms?.paymentStructure
    ? onboarding.repaymentTerms?.paymentStructure
    : PaymentStructureModel.Regular,
  repaymentFrequency: onboarding.repaymentTerms?.repaymentFrequency
    ? onboarding.repaymentTerms.repaymentFrequency
    : "",
  inceptionDate: getDefaultDate(onboarding.repaymentTerms?.inceptionDate),
  firstPaymentDate: getDefaultDate(onboarding.repaymentTerms?.firstPaymentDate),
  firstSecondaryPeriodRentalDate: getDefaultDate(
    onboarding.repaymentTerms?.firstSecondaryPeriodRentalDate,
  ),
});

export default function RepaymentTermsStep(): React.ReactElement {
  const { t } = useTranslation("onboardings");
  const stepper = useStepper();
  const onboarding = useOnboarding();
  const product = onboarding.state.agreementDetails
    ?.productType as ProductTypeModel;
  const error = useError(getOnboardingSubmitErrors(t, product));
  const location = useLocation();
  const financialProductDetails = useGetFinancialProductDetails(
    Number(onboarding.state.agreementDetails?.productId),
  );

  const submitButtonLabel = t("common:submit");
  const editOnboarding = location.pathname.includes("edit");

  const balanceFinanced =
    onboarding.state.feesAndCommissions?.balanceFinanced ??
    onboarding.state.assetDetails?.totalNetCashPrice ??
    0;

  const resolver = useRepaymentTermsResolver({
    product: product,
    balanceFinanced: balanceFinanced,
    financialProductDetails: financialProductDetails.data,
  });

  const form = useForm<RepaymentTermsFormModel>({
    defaultValues: makeDefaultValuesFromOnboardingState(onboarding.state),
    resolver,
    shouldUnregister: true,
  });

  const onSubmit = async (data: RepaymentTermsFormModel): Promise<void> => {
    try {
      if (editOnboarding) {
        await onboarding.submitEditOnboarding(data);
      } else {
        await onboarding.submitStageOnboarding(data);
      }
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    }
  };

  const onCloseErrorDialog = (): void => {
    error.reset();
  };

  return (
    <>
      <AlertDialog
        content={error.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(error.message)}
        title={t("repayment_terms.error_dialog_title")}
      />

      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h1" }}>
          {t(titleLabel)}
        </Typography>
      </Box>

      <form
        aria-label={t(titleLabel)}
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate
      >
        <RepaymentTermsFormControls form={form} />

        <OnboardingStepActions
          back={{
            label: t("common:stepper.back_button"),
            onBack: stepper.previous,
          }}
          next={{ label: submitButtonLabel }}
          disabled={form.formState.isSubmitting}
        />
      </form>
    </>
  );
}
