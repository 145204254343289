import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { ApiResponse } from "@ldms/mui-sdk/bootstrap/generated";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { RolesApi } from "generated/admin/apis";
import { CreateRoleModel } from "generated/admin/models";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

const useCreateRole = (
  options: UseCommandOptions<ApiResponse<void>, ResponseError> = {},
) => {
  const { mutate } = useSWRConfig();
  const api = useApi(RolesApi);

  return useCommand(
    async (createRoleModel: CreateRoleModel) => {
      const response = await api.createRoleRaw({ createRoleModel });
      return response;
    },
    {
      ...options,
      onSuccess: (response) => {
        mutate(keys.list());
        options.onSuccess?.(response);
      },
    },
  );
};

export { useCreateRole };
