/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bInstalmentListItemModel
 */
export interface B2bInstalmentListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof B2bInstalmentListItemModel
     */
    readonly instalmentNumber?: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof B2bInstalmentListItemModel
     */
    instalmentDate?: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof B2bInstalmentListItemModel
     */
    instalmentAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof B2bInstalmentListItemModel
     */
    capitalOutstanding?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof B2bInstalmentListItemModel
     */
    interestPaid?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof B2bInstalmentListItemModel
     */
    capitalPaid?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof B2bInstalmentListItemModel
     */
    lenderFeesPaid?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof B2bInstalmentListItemModel
     */
    expensesOther?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof B2bInstalmentListItemModel
     */
    expensesLossPool?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof B2bInstalmentListItemModel
     */
    commissionCharge?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof B2bInstalmentListItemModel
     */
    commissionBalance?: string;
}

/**
 * Check if a given object implements the B2bInstalmentListItemModel interface.
 */
export function instanceOfB2bInstalmentListItemModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bInstalmentListItemModelFromJSON(json: any): B2bInstalmentListItemModel {
    return B2bInstalmentListItemModelFromJSONTyped(json, false);
}

export function B2bInstalmentListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bInstalmentListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instalmentNumber': !exists(json, 'instalmentNumber') ? undefined : json['instalmentNumber'],
        'instalmentDate': !exists(json, 'instalmentDate') ? undefined : (new Date(json['instalmentDate'])),
        'instalmentAmount': !exists(json, 'instalmentAmount') ? undefined : json['instalmentAmount'],
        'capitalOutstanding': !exists(json, 'capitalOutstanding') ? undefined : json['capitalOutstanding'],
        'interestPaid': !exists(json, 'interestPaid') ? undefined : json['interestPaid'],
        'capitalPaid': !exists(json, 'capitalPaid') ? undefined : json['capitalPaid'],
        'lenderFeesPaid': !exists(json, 'lenderFeesPaid') ? undefined : json['lenderFeesPaid'],
        'expensesOther': !exists(json, 'expensesOther') ? undefined : json['expensesOther'],
        'expensesLossPool': !exists(json, 'expensesLossPool') ? undefined : json['expensesLossPool'],
        'commissionCharge': !exists(json, 'commissionCharge') ? undefined : json['commissionCharge'],
        'commissionBalance': !exists(json, 'commissionBalance') ? undefined : json['commissionBalance'],
    };
}

export function B2bInstalmentListItemModelToJSON(value?: B2bInstalmentListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instalmentDate': value.instalmentDate === undefined ? undefined : (value.instalmentDate.toISOString().substring(0,10)),
        'instalmentAmount': value.instalmentAmount,
        'capitalOutstanding': value.capitalOutstanding,
        'interestPaid': value.interestPaid,
        'capitalPaid': value.capitalPaid,
        'lenderFeesPaid': value.lenderFeesPaid,
        'expensesOther': value.expensesOther,
        'expensesLossPool': value.expensesLossPool,
        'commissionCharge': value.commissionCharge,
        'commissionBalance': value.commissionBalance,
    };
}

