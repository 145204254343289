import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import ListCompaniesContainer from "apps/servicing/modules/settings/containers/ListCompaniesContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

function CompaniesView() {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();
  const href = `${appConfig.appRoutes.servicing}/settings/companies/add-company`;

  return (
    <ViewLayout
      title={t("companies.companies_heading")}
      action={
        <AddButton
          component={RouterLink}
          to={href}
          role="link"
          variant="contained"
        />
      }
    >
      <ContainedLayout>
        <ListCompaniesContainer />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:companies:manage"],
})(CompaniesView);
