import { TableCell, TableRow } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

export interface KeyValueRowProps {
  align?: "left" | "right";
  ariaLabel?: string;
  children?: ReactNode;
  colSpan?: number;
  label?: string;
}

export default function KeyValueRow({
  align = "left",
  ariaLabel,
  children,
  colSpan,
  label,
}: KeyValueRowProps): ReactElement {
  return (
    <TableRow>
      <TableCell component="th" scope="col" aria-label={ariaLabel}>
        {label}
      </TableCell>
      <TableCell align={align} colSpan={colSpan}>
        {children || "-"}
      </TableCell>
    </TableRow>
  );
}
