import { QueryKey } from "api/types";

export const admin = (): QueryKey => ["admin"];

export const all = (): QueryKey => [...admin(), "roles"];

export const list = (): QueryKey => [...all(), "list"];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (id: number): QueryKey => [...details(), id];
