/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Contacts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactModel
 */
export interface ContactModel {
    /**
     * Title Of the Contact
     * @type {string}
     * @memberof ContactModel
     */
    title?: string;
    /**
     * First name of the Contact
     * @type {string}
     * @memberof ContactModel
     */
    firstName?: string;
    /**
     * Second name of the Contact
     * @type {string}
     * @memberof ContactModel
     */
    secondName?: string;
    /**
     * Last name of the Contact
     * @type {string}
     * @memberof ContactModel
     */
    lastName?: string;
    /**
     * Trading name of the Contact
     * @type {string}
     * @memberof ContactModel
     */
    companyName?: string;
    /**
     * The first line of the address
     * @type {string}
     * @memberof ContactModel
     */
    addressLine1?: string;
    /**
     * The second line of the address
     * @type {string}
     * @memberof ContactModel
     */
    addressLine2?: string;
    /**
     * The third line of the address
     * @type {string}
     * @memberof ContactModel
     */
    addressLine3?: string;
    /**
     * The fourth line of the address
     * @type {string}
     * @memberof ContactModel
     */
    addressLine4?: string;
    /**
     * The postcode of the address
     * @type {string}
     * @memberof ContactModel
     */
    postcode?: string;
    /**
     * Indicator for invoiced via email
     * @type {boolean}
     * @memberof ContactModel
     */
    invoiceByEmail?: boolean;
    /**
     * Indicator for system generated correspondence
     * @type {boolean}
     * @memberof ContactModel
     */
    receiveSystemGeneratedCorrespondence: boolean;
}

/**
 * Check if a given object implements the ContactModel interface.
 */
export function instanceOfContactModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "receiveSystemGeneratedCorrespondence" in value;

    return isInstance;
}

export function ContactModelFromJSON(json: any): ContactModel {
    return ContactModelFromJSONTyped(json, false);
}

export function ContactModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'secondName': !exists(json, 'secondName') ? undefined : json['secondName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'invoiceByEmail': !exists(json, 'invoiceByEmail') ? undefined : json['invoiceByEmail'],
        'receiveSystemGeneratedCorrespondence': json['receiveSystemGeneratedCorrespondence'],
    };
}

export function ContactModelToJSON(value?: ContactModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'firstName': value.firstName,
        'secondName': value.secondName,
        'lastName': value.lastName,
        'companyName': value.companyName,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
        'invoiceByEmail': value.invoiceByEmail,
        'receiveSystemGeneratedCorrespondence': value.receiveSystemGeneratedCorrespondence,
    };
}

