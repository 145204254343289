import { useAgreement } from "common/providers";
import { ReactElement } from "react";

interface ModifiableToggleProps {
  children: ReactElement;
}

export default function ModifiableToggle({ children }: ModifiableToggleProps) {
  const agreement = useAgreement();
  return agreement.isModifiable ? <>{children}</> : <></>;
}
