export interface Role {
  id: string | number;
  name: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  active: boolean;
  email?: string;
  roles: Role[];
}

export interface UpdateCompanyFieldValues {
  name: string;
  registrationNumber?: string;
  vatNumber?: string;
  currency: string;
  caisSourceCode?: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  postcode: string;
  vatCode: string;
  originationCommissionPercentage?: number;
}

export enum SubmissionFileType {
  BACS = "BACS",
  SEPA = "SEPA",
  SEPA_XML = "SEPA XML",
}
