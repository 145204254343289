import { ApiResponse } from "@ldms/mui-sdk/bootstrap/generated";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ResponseError } from "generated";
import { UsersApi } from "generated/admin/apis";
import { UpdateUserModel } from "generated/admin/models";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

const useUpdateUser = (
  userId: string,
  options: UseCommandOptions<ApiResponse<void>, ResponseError> = {},
) => {
  const usersApi = useApi(UsersApi);
  const swr = useSWRConfig();

  const updateUser = async (model: UpdateUserModel) => {
    const response = await usersApi.updateUserRaw({
      userId,
      updateUserModel: model,
    });
    return response;
  };

  return useCommand(updateUser, {
    ...options,
    onSuccess: (response) => {
      options.onSuccess?.(response);
      swr.mutate(keys.detail(userId));
    },
  });
};

export { useUpdateUser };
