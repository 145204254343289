import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Search } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { useListThirdParties } from "api/third-parties/listThirdParties";
import ThirdPartiesList, {
  ThirdParty,
} from "apps/servicing/modules/settings/components/ThirdPartiesList";
import AddThirdPartyContainer from "apps/servicing/modules/settings/containers/AddThirdPartyContainer/";
import UpdateThirdPartyDialogContainer from "apps/servicing/modules/settings/containers/UpdateThirdPartyDialogContainer";
import { Loader, QueryError } from "common/components";
import { ContainedLayout } from "common/layouts";
import ListLayout from "common/layouts/ListLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { ThirdPartyListItemModel } from "generated/core/models";
import { debounce } from "lodash";
import {
  ChangeEventHandler,
  MouseEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

function ThirdPartiesView(): ReactElement {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { t } = useTranslation("third-parties");
  const [query, setQuery] = useState("");
  const [isUpdateThirdPartyDialogOpen, setUpdateThirdPartyDialogOpen] =
    useState(false);
  const [selectedThirdParty, setSelectedThirdParty] =
    useState<ThirdPartyListItemModel>();

  const onQueryChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    setQuery(event.target.value);
  };

  const thirdParties = useListThirdParties({
    pageSize: rowsPerPage,
    params: { query },
  });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => thirdParties.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  const handleQueryChange = useMemo(() => debounce(onQueryChange, 300), []);

  useEffect(() => {
    return handleQueryChange.cancel;
  }, [handleQueryChange]);

  const onSuccess = (): void => {
    handleCloseUpdateThirdPartyDialog();
    thirdParties.paging.setPage(0);
  };

  const handleCloseUpdateThirdPartyDialog = () => {
    setUpdateThirdPartyDialogOpen(false);
  };

  return (
    <>
      {selectedThirdParty && (
        <UpdateThirdPartyDialogContainer
          id={selectedThirdParty.id}
          open={isUpdateThirdPartyDialogOpen}
          onClose={handleCloseUpdateThirdPartyDialog}
          onSuccess={onSuccess}
          defaultValues={{
            active: selectedThirdParty.active,
            type: selectedThirdParty.type,
            name: selectedThirdParty.name,
            contact: selectedThirdParty.contact,
            telephone: selectedThirdParty.telephone ?? "",
            faxNumber: selectedThirdParty.faxNumber ?? "",
            emailAddress: selectedThirdParty.emailAddress ?? "",
            addressLine1: selectedThirdParty.address?.addressLine1,
            addressLine2: selectedThirdParty.address?.addressLine2,
            addressLine3: selectedThirdParty.address?.addressLine3,
            addressLine4: selectedThirdParty.address?.addressLine4,
            postcode: selectedThirdParty.address?.postcode,
            vatRegistrationNumber: selectedThirdParty.vatRegistrationNumber,
          }}
        />
      )}
      <main>
        <ViewLayout
          title={t("third_parties_heading")}
          action={<AddThirdPartyContainer onSuccess={onSuccess} />}
        >
          <ContainedLayout>
            <ListLayout
              filters={
                <TextField
                  InputProps={{
                    endAdornment: <Search />,
                  }}
                  inputProps={{
                    "aria-label": t("list.search_label"),
                  }}
                  margin="none"
                  onChange={handleQueryChange}
                />
              }
              pagination={{
                onPageChange: onChangePage,
                onRowsPerPageChange: onChangeRowsPerPage,
                rowsPerPage,
                page: thirdParties.paging.page,
                count: thirdParties.data?.paging.total || 0,
              }}
            >
              <Loader
                ready={Boolean(thirdParties.data || thirdParties.error)}
                render={(): ReactElement => {
                  if (thirdParties.error || !thirdParties.data) {
                    return <QueryError onRetry={thirdParties.refetch} />;
                  }

                  const handleOpenUpdateThirdPartyDialog = (
                    thirdParty: ThirdParty,
                  ) => {
                    const selectedThirdParty = thirdParties.data?.results.find(
                      (item) => item.id === thirdParty.id,
                    );
                    setSelectedThirdParty(selectedThirdParty);
                    setUpdateThirdPartyDialogOpen(true);
                  };

                  return (
                    <ThirdPartiesList
                      data={thirdParties.data.results}
                      loading={thirdParties.isValidating}
                      onSelect={handleOpenUpdateThirdPartyDialog}
                    />
                  );
                }}
              />
            </ListLayout>
          </ContainedLayout>
        </ViewLayout>
      </main>
    </>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:third-parties:manage"],
})(ThirdPartiesView);
