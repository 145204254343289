import { Form } from "@ldms/mui-sdk/forms";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AutomationActionDetailsFormFieldValues } from "apps/admin/containers/CreateAutomationContainer/CreateAutomationContainer";
import {
  ControlledTextField,
  FileUploader,
  UploadedFileBanner,
} from "common/components";
import { useYupResolver } from "common/hooks";
import { DirectDebitPayerFilterModel } from "generated/core/models";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

type AutomationActionDetailsFormProps = {
  onBackStep: () => void;
  onSubmit: (data: AutomationActionDetailsFormFieldValues) => void;
  defaultValues: {
    to?: string;
    from?: string;
    emailTemplate?: File;
    conditions: string;
  };
  isEmail?: boolean;
  responseError?: {
    message: string | undefined;
    reset: () => void;
  };
};

const AutomationActionDetailsForm = ({
  onBackStep,
  onSubmit,
  defaultValues,
  isEmail,
  responseError,
}: AutomationActionDetailsFormProps) => {
  const { t } = useTranslation("automations");
  const fromFieldLabel = "action_details_form.from_field_label";
  const conditionsLabel = "action_details_form.conditions_label";

  const resolver = useYupResolver<AutomationActionDetailsFormFieldValues>(
    (yup) =>
      yup.object().shape({
        to: yup.string().isRequired(t("action_details_form.to_field_label")),
        from: yup
          .string()
          .isRequired(t(fromFieldLabel))
          .maxCharacters(200, t(fromFieldLabel)),
        emailTemplate: isEmail
          ? yup
              .mixed()
              .required(t("email_template_required"))
              .test("filesize", t("file_size_upload_error"), (value: File) => {
                return value && value.size <= 10485760;
              })
              .test("filesize", t("file_size_zero_error"), (value: File) => {
                return value && value.size >= 1;
              })
              .test("text/html", t("file_type_upload_error"), (value: File) => {
                return value && value.type === "text/html";
              })
          : yup.mixed().notRequired(),
      }),
  );

  return (
    <>
      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h2" }}>
          {t("action_details_form.title")}
        </Typography>
      </Box>
      <Form
        label={t("action_details_form.title")}
        onSubmit={onSubmit}
        resolver={resolver}
        defaultValues={defaultValues}
      >
        {(form) => {
          const fileWatch = form.watch("emailTemplate");
          const onDeleteFile = () => {
            form.resetField("emailTemplate");
            responseError?.reset();
          };
          const isHtml = fileWatch?.type === "text/html";
          const isCorrectSize = fileWatch?.size && fileWatch.size < 10485760;

          return (
            <Stack gap={2}>
              <ControlledTextField
                label={t("action_details_form.to_field_label")}
                control={form.control}
                SelectProps={{ displayEmpty: true }}
                select
                required
                name="to"
                error={form.formState.errors.to?.message}
                disabled={form.formState.isSubmitting}
              >
                <MenuItem value="">{t("common:please_select")}</MenuItem>
                <MenuItem value="Main Contact">Main Contact</MenuItem>
              </ControlledTextField>
              <TextField
                {...form.register("from")}
                label={t(fromFieldLabel)}
                required
                error={Boolean(form.formState.errors.from?.message)}
                helperText={form.formState.errors.from?.message}
                disabled={form.formState.isSubmitting}
              />

              {isEmail && (!isHtml || !isCorrectSize) && (
                <Box marginBottom={0}>
                  <Controller
                    name="emailTemplate"
                    control={form.control}
                    render={({ field }) => (
                      <FileUploader
                        onInput={(event) => {
                          field.onChange(event);
                          form.trigger("emailTemplate");
                          responseError?.reset();
                        }}
                        loading={false}
                        prompt={t("upload_prompt_text")}
                      />
                    )}
                  />
                  {form.formState.errors.emailTemplate?.message && (
                    <Typography
                      marginLeft="14px"
                      marginTop="4px"
                      lineHeight="20px"
                      fontSize="12px"
                      letterSpacing=".4px"
                      color="error"
                    >
                      {form.formState.errors.emailTemplate?.message}
                    </Typography>
                  )}
                </Box>
              )}

              {!form.formState.errors.emailTemplate?.message &&
                fileWatch?.type === "text/html" && (
                  <UploadedFileBanner
                    onFileDelete={onDeleteFile}
                    fileName={fileWatch.name}
                    buttonText={t("delete_document_button")}
                  />
                )}

              {responseError?.message && (
                <Typography color="error">{responseError?.message}</Typography>
              )}

              <Divider />

              <Controller
                name="conditions"
                defaultValue={defaultValues.conditions}
                control={form.control}
                render={({ field }) => (
                  <>
                    <FormLabel id={t(conditionsLabel)}>
                      {t(conditionsLabel)}
                    </FormLabel>
                    <RadioGroup {...field} aria-labelledby={t(conditionsLabel)}>
                      <FormControlLabel
                        control={<Radio color="primary" size="small" />}
                        label={t("action_details_form.conditions_none")}
                        value={DirectDebitPayerFilterModel.NoExclusions}
                      />

                      <FormControlLabel
                        label={t("action_details_form.conditions_exclude_dd")}
                        value={DirectDebitPayerFilterModel.ExcludeDd}
                        control={<Radio color="primary" size="small" />}
                      />

                      <FormControlLabel
                        label={t(
                          "action_details_form.conditions_exclude_non_dd",
                        )}
                        value={DirectDebitPayerFilterModel.ExcludeNonDd}
                        control={<Radio color="primary" size="small" />}
                      />
                    </RadioGroup>
                  </>
                )}
              />

              <Box display="flex" justifyContent="space-between">
                <Button
                  onClick={onBackStep}
                  disabled={form.formState.isSubmitting}
                >
                  {t("common:back")}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={form.formState.isSubmitting}
                >
                  {t("common:save")}
                </Button>
              </Box>
            </Stack>
          );
        }}
      </Form>
    </>
  );
};

export default AutomationActionDetailsForm;
