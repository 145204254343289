import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import CreateUserContainer from "apps/admin/containers/CreateUserContainer";
import UsersContainer from "apps/admin/containers/UsersContainer/UsersContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

function UsersView() {
  const { t } = useTranslation("admin");

  return (
    <ViewLayout
      title={t("users.users_heading")}
      action={<CreateUserContainer />}
    >
      <ContainedLayout>
        <UsersContainer />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default withProtectedView({
  allowedPermissions: ["admin:users:manage"],
})(UsersView);
