import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCreateLetterTemplate } from "api/documents/portfolios/templates/createLetterTemplate";
import { usePreviewLetterTemplate } from "api/templates/letters/previewLetterTemplate";
import {
  ControlledTextField,
  FileUploader,
  UploadedFileBanner,
} from "common/components";
import { useYupResolver } from "common/hooks";
import { AttachmentTypeModel } from "generated/core/models";
import { CreateLetterTemplateTypeMetadataModel } from "generated/documents/models";
import { isUndefined } from "lodash";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

export type CreateLetterTemplateFormValues =
  CreateLetterTemplateTypeMetadataModel & {
    file: Blob;
  };

interface CreateLetterTemplateContainerProps {
  portfolioId: number;
}

export default function CreateLetterTemplateContainer({
  portfolioId,
}: Readonly<CreateLetterTemplateContainerProps>): ReactElement {
  const { t } = useTranslation("documents");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<File>();
  const [uploadError, setUploadError] = useState<string | undefined>();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const createLetterTemplate = useCreateLetterTemplate({
    onSuccess: () => {
      setFileName("");
      setFile(undefined);
      setIsCreateDialogOpen(false);
    },
  });
  const templatePreview = usePreviewLetterTemplate({
    onSuccess: async (response) => {
      const url = window.URL.createObjectURL(response);
      window.open(url, "_blank", "noopener,noreferrer");
    },
  });

  const responseError = (name?: string) => {
    return new Map([
      ["invalid_fields", t("letter_templates.create.merge_field_error")],
      [
        "conflict",
        t("letter_templates.create.existing_name_error", {
          name,
        }),
      ],
    ]);
  };

  const nameLabel = t("letter_templates.create.name_label");
  const documentTypeLabel = t("letter_templates.create.document_type_label");
  const descriptionLabel = t("letter_templates.create.description_label");

  const validateFileUpload = (file: File): void => {
    if (
      ![
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file.type)
    ) {
      throw new Error(
        t("create_template.template_incorrect_file_type_error", {
          fileName: file.name,
        }),
      );
    }
  };

  const resolver = useYupResolver<CreateLetterTemplateTypeMetadataModel>(
    (yup) =>
      yup.object().shape({
        name: yup.string().isRequired(nameLabel).maxCharacters(100, nameLabel),
        documentType: yup.string().isRequired(documentTypeLabel),
        description: yup
          .string()
          .isRequired(descriptionLabel)
          .maxCharacters(200, descriptionLabel),
      }),
  );

  const onTemplateUpload = async (template: File): Promise<void> => {
    setUploadError(undefined);
    setFileName(template.name);
    try {
      validateFileUpload(template);
      setFile(template);
    } catch (validationError) {
      setUploadError((validationError as Error).message);
      setFile(undefined);
    }
  };

  const onPreview = async (file: File) => {
    await templatePreview.execute({
      body: file,
    });
  };

  const onFileDelete = async (): Promise<void> => {
    setUploadError(undefined);
    setFile(undefined);
    setFileName("");
    createLetterTemplate.reset();
    templatePreview.reset();
  };

  const onSubmit = async (data: CreateLetterTemplateTypeMetadataModel) => {
    file &&
      createLetterTemplate.execute({
        portfolioId: portfolioId,
        metadata: data,
        file: file,
      });
  };

  const onClose = () => {
    setIsCreateDialogOpen(false);
    onFileDelete();
  };

  const onCreate = (): void => {
    setIsCreateDialogOpen(true);
  };

  return (
    <>
      <Button variant="contained" onClick={onCreate}>
        {t("letter_templates.create.create_button")}
      </Button>

      <FormDialog
        onClose={onClose}
        onSubmit={onSubmit}
        title={t("letter_templates.create.title_label")}
        open={isCreateDialogOpen}
        resolver={resolver}
        disabled={!file || createLetterTemplate.isExecuting}
        defaultValues={{ documentType: "" }}
      >
        {(form) => {
          const isFormError =
            !isUndefined(templatePreview.error) ||
            !isUndefined(createLetterTemplate.error) ||
            !isUndefined(uploadError);

          const serviceError = responseError(form.getValues().name).get(
            templatePreview.error?.code ??
              createLetterTemplate.error?.code ??
              "",
          );

          function getFormErrorMessage() {
            if (!isUndefined(uploadError) && uploadError !== "") {
              return uploadError;
            }
            if (!isUndefined(serviceError) && serviceError !== "") {
              return serviceError;
            }

            return t("common:error.default");
          }

          return (
            <>
              <TextField
                {...form.register("name")}
                error={Boolean(form.formState.errors?.name)}
                fullWidth
                helperText={form.formState.errors.name?.message}
                label={nameLabel}
                multiline
                variant="outlined"
                required
                onChange={createLetterTemplate.reset}
              />

              <ControlledTextField
                select
                id="documentType"
                name="documentType"
                label={documentTypeLabel}
                helperText={form.formState.errors?.documentType?.message}
                error={form.formState.errors?.documentType?.message}
                SelectProps={{ displayEmpty: true }}
                control={form.control}
                required
              >
                <MenuItem value="">
                  <i>{t("common:please_select")}</i>
                </MenuItem>

                {Object.values(AttachmentTypeModel).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </ControlledTextField>

              <TextField
                {...form.register("description")}
                error={Boolean(form.formState.errors?.description)}
                fullWidth
                helperText={form.formState.errors.description?.message}
                label={descriptionLabel}
                multiline
                rows={3}
                variant="outlined"
                required
              />

              {!file && (
                <Box marginTop={2}>
                  <FileUploader
                    onInput={onTemplateUpload}
                    loading={false}
                    prompt={t("letter_templates.create.upload_file_text")}
                  />
                </Box>
              )}

              {file && (
                <Stack spacing={2} marginTop={1}>
                  <UploadedFileBanner
                    onFileDelete={onFileDelete}
                    fileName={fileName}
                    buttonText={t("letter_templates.create.delete_button")}
                  />
                  <Alert
                    severity="warning"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    action={
                      <Button
                        color="inherit"
                        onClick={() => onPreview(file)}
                        disabled={templatePreview.isExecuting}
                      >
                        {t("letter_templates.create.preview_button")}
                      </Button>
                    }
                  >
                    <AlertTitle>
                      {t("letter_templates.create.preview_title_label")}
                    </AlertTitle>
                    <Typography variant="body2">
                      {t("letter_templates.create.preview_message_content")}
                    </Typography>
                  </Alert>
                </Stack>
              )}

              {isFormError && (
                <Box marginTop={1}>
                  <Typography color="error">{getFormErrorMessage()}</Typography>
                </Box>
              )}
            </>
          );
        }}
      </FormDialog>
    </>
  );
}
