import { ActionsDropdown } from "@ldms/mui-sdk/components";
import {
  Box,
  Breadcrumbs,
  Link,
  MenuProps,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface ViewLayoutProps {
  action?: React.ReactNode;
  menu?: React.FC<MenuProps>;
  breadcrumbs?: { href?: string; label: string }[];
  children: React.ReactNode;
  title: React.ReactNode;
  labelledChip?: React.ReactNode;
}

export default function ViewLayout({
  action,
  menu,
  breadcrumbs,
  children,
  title,
  labelledChip,
}: ViewLayoutProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Box height="100%" display="grid" gridTemplateRows="max-content 1fr">
      <Box bgcolor="background.paper" component="header" boxShadow={1}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          minHeight={52}
          paddingX={3}
          paddingY={1.25}
        >
          <Box>
            {breadcrumbs && (
              <Box marginBottom={0.25}>
                <Breadcrumbs aria-label={t("breadcrumbs.label")}>
                  {breadcrumbs.map((crumb) =>
                    typeof crumb.href !== "undefined" ? (
                      <Link
                        key={crumb.label}
                        component={RouterLink}
                        to={crumb.href}
                      >
                        {crumb.label}
                      </Link>
                    ) : (
                      <Typography key={crumb.label}>{crumb.label}</Typography>
                    ),
                  )}
                </Breadcrumbs>
              </Box>
            )}
            {typeof title === "object" ? (
              title
            ) : (
              <Typography component="h1" variant="h4">
                {title}
              </Typography>
            )}
          </Box>
          {action && (
            <Box display="flex" marginY={-0.25} paddingX={2} role="toolbar">
              <Stack direction="row" spacing={1}>
                {action}
                {menu && (
                  <div>
                    <ActionsDropdown variant="outlined" renderMenu={menu} />
                  </div>
                )}
              </Stack>
            </Box>
          )}
          {labelledChip && (
            <Box display="flex" marginY={-0.25} paddingX={2} role="toolbar">
              {labelledChip}
            </Box>
          )}
        </Box>
      </Box>
      <Box height="100%">{children}</Box>
    </Box>
  );
}
