import { QueryKey } from "api";
import { keys } from "api/agreements";

export const all = (agreementId: number): QueryKey => [
  ...keys.detail(agreementId),
  "available-third-parties",
];

export const list = (
  agreementId: number,
  params?: { query?: string },
): QueryKey => [...all(agreementId), "list", params?.query];
