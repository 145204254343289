import {
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useListVatCodesV2 } from "api/vat-codes/listVatCodes";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
} from "common/components";
import { useLocale } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export default function ListVatCodesContainer(): ReactElement {
  const { t } = useTranslation("servicing");
  const vatCodes = useListVatCodesV2();
  const locale = useLocale();
  const appConfig = useAppConfiguration();

  return (
    <Loader
      ready={Boolean(vatCodes.data ?? vatCodes.error)}
      render={(): ReactElement => {
        if (vatCodes.error || !vatCodes.data) {
          return <QueryError onRetry={vatCodes.refetch} />;
        }

        return (
          <ListLayout>
            <Table
              aria-label={t("vat_codes.table_name_label")}
              size="small"
              stickyHeader
            >
              <LoadableTableHead
                headings={[
                  t("vat_codes.list_vat_codes.vat_code_heading"),
                  t("vat_codes.list_vat_codes.vat_rate_label"),
                  t("vat_codes.list_vat_codes.effective_date_label"),
                ]}
                loading={vatCodes.isValidating}
              />
              <TableBody>
                {vatCodes.data.map((vatCode) => (
                  <TableRow key={vatCode.systemId} hover>
                    <TableCell
                      padding="none"
                      sx={{ width: "500px" }}
                      component="th"
                      scope="row"
                    >
                      <ListItemButton
                        dense
                        component={RouterLink}
                        to={`${appConfig.appRoutes.servicing}/settings/vat-codes/${vatCode.systemId}`}
                      >
                        <ListItemText
                          primaryTypographyProps={{ color: "primary" }}
                          primary={vatCode.name}
                        />
                      </ListItemButton>
                    </TableCell>
                    <TableCell>{vatCode.currentRate}</TableCell>
                    <TableCell>
                      {locale.formatDate(vatCode.effectiveDate)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {vatCodes.data.length === 0 && <NoResults />}
          </ListLayout>
        );
      }}
    />
  );
}
