/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountTypeModel } from './DiscountTypeModel';
import {
    DiscountTypeModelFromJSON,
    DiscountTypeModelFromJSONTyped,
    DiscountTypeModelToJSON,
} from './DiscountTypeModel';

/**
 * Create an asset settlement quote
 * @export
 * @interface CreateAssetSettlementQuoteModel
 */
export interface CreateAssetSettlementQuoteModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreateAssetSettlementQuoteModel
     */
    discountAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreateAssetSettlementQuoteModel
     */
    discountPercentage?: string;
    /**
     * 
     * @type {DiscountTypeModel}
     * @memberof CreateAssetSettlementQuoteModel
     */
    discountType?: DiscountTypeModel;
}

/**
 * Check if a given object implements the CreateAssetSettlementQuoteModel interface.
 */
export function instanceOfCreateAssetSettlementQuoteModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateAssetSettlementQuoteModelFromJSON(json: any): CreateAssetSettlementQuoteModel {
    return CreateAssetSettlementQuoteModelFromJSONTyped(json, false);
}

export function CreateAssetSettlementQuoteModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAssetSettlementQuoteModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountAmount': !exists(json, 'discountAmount') ? undefined : json['discountAmount'],
        'discountPercentage': !exists(json, 'discountPercentage') ? undefined : json['discountPercentage'],
        'discountType': !exists(json, 'discountType') ? undefined : DiscountTypeModelFromJSON(json['discountType']),
    };
}

export function CreateAssetSettlementQuoteModelToJSON(value?: CreateAssetSettlementQuoteModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountAmount': value.discountAmount,
        'discountPercentage': value.discountPercentage,
        'discountType': DiscountTypeModelToJSON(value.discountType),
    };
}

