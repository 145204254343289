import { AlertDialog, Loader } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { useListAgreementAttachments } from "api/agreements/attachments";
import { useRequestAttachmentDownload } from "api/attachments";
import AgreementDocumentsList from "apps/servicing/modules/agreements/components/AgreementDocumentsList";
import DocumentFilter from "common/components/DocumentFilter";
import ListLayout from "common/layouts/ListLayout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import getLocation from "support/get-location";

interface AgreementAttachmentContainerProps {
  agreementId: number;
}

interface AgreementAttachmentsFilters {
  documentType?: string;
  toDate?: Date;
  fromDate?: Date;
}

const AgreementAttachmentsContainer = ({
  agreementId,
}: AgreementAttachmentContainerProps) => {
  const { t } = useTranslation("agreements");
  const defaultError = t("common:error.default");
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [parameters, setParameters] = useState<AgreementAttachmentsFilters>({
    toDate: undefined,
    fromDate: undefined,
    documentType: undefined,
  });

  const downloadAttachment = useRequestAttachmentDownload({
    onSuccess: (data) => {
      getLocation(data);
    },
    onError: () => setAlertOpen(true),
  });

  const handleFilterChange = (
    key: string,
    value: string | Date | undefined,
  ): void => {
    setParameters({
      ...parameters,
      [key]: value,
    });
  };

  const attachments = useListAgreementAttachments(agreementId, {
    from: parameters.fromDate ? new Date(parameters.fromDate) : undefined,
    to: parameters.toDate ? new Date(parameters.toDate) : undefined,
    type: parameters.documentType,
  });

  const handleAttachmentDownload = async (
    attachmentId: string,
  ): Promise<void> => {
    await downloadAttachment.execute({ attachmentId });
  };

  const handleCloseAlert = (): void => {
    setAlertOpen(false);
  };

  const renderDocuments = () => {
    if (attachments.error || !attachments.data) {
      return (
        <Typography color="error" role="alert">
          {defaultError}
        </Typography>
      );
    }

    return (
      <AgreementDocumentsList
        data={attachments.data}
        loading={attachments.isValidating}
        onDocumentDownload={handleAttachmentDownload}
      />
    );
  };

  return (
    <>
      <AlertDialog
        content={t("common:error.default_message")}
        title={t("common:error.default")}
        labels={{ close: t("common:alert.close") }}
        onClose={handleCloseAlert}
        open={isAlertOpen}
      />
      <ListLayout
        filters={
          <DocumentFilter
            filters={{
              type: parameters.documentType,
            }}
            labels={{
              type: t("agreement_documents.list.type_heading_label"),
              allTypesOption: t(
                "agreement_documents.all_document_types_option",
              ),
              fromDate: t("agreement_documents.from_date_label"),
              toDate: t("agreement_documents.to_date_label"),
            }}
            onChange={handleFilterChange}
          />
        }
      >
        <Paper>
          <Loader
            fallback={
              <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress />
              </Box>
            }
            ready={Boolean(attachments.data ?? attachments.error)}
            render={renderDocuments}
          />
        </Paper>
      </ListLayout>
    </>
  );
};

export default AgreementAttachmentsContainer;
