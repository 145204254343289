import { AlertDialog, Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useListPayees } from "api/agreements/payees/listPayees";
import { useAddPaymentIntruction } from "api/agreements/payment-instructions/addPaymentInstruction";
import {
  AddPaymentInstructionFormDialog,
  AddPaymentInstructionFormValues,
} from "apps/servicing/modules/agreements/components";
import { useResponseError } from "common/hooks";
import { useAgreement } from "common/providers";
import {
  PayeeModel,
  PaymentInstructionModel,
} from "generated/servicing-v2/models";
import { BaseSyntheticEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface FormDialogFallbackProps {
  onClose(event?: BaseSyntheticEvent): void;
  title: string;
}

function FormDialogFallback({
  onClose,
  title,
}: Readonly<FormDialogFallbackProps>): ReactElement {
  const { t } = useTranslation(["agreements", "common"]);

  return (
    <>
      <DialogTitle id="add-payment-instruction-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("common:cancel")}
        </Button>
        <Button color="primary" disabled>
          {t("agreements:payments.add_payment_instruction.submit_button")}
        </Button>
      </DialogActions>
    </>
  );
}

interface AddPaymentInstructionContainerProps {
  agreementId: number;
  agreementSystemId: string;
  onSuccess(): void;
  onClose(): void;
  open: boolean;
}

export default function AddPaymentInstructionContainer({
  agreementId,
  agreementSystemId,
  onClose,
  onSuccess: onSubmitCallback,
  open,
}: Readonly<AddPaymentInstructionContainerProps>): ReactElement {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const addPaymentInstruction = useAddPaymentIntruction(agreementSystemId);
  const error = useResponseError();
  const payeesQueryResponse = useListPayees(agreementSystemId);
  const onCloseErrorDialog = (): void => error.reset();
  const payees = payeesQueryResponse.data as PayeeModel[];

  const onSubmit = async (
    formValues: AddPaymentInstructionFormValues,
  ): Promise<void> => {
    const bankSystemId = payees.find(
      (p) => p.contactSystemId === formValues.contactSystemId,
    )?.bankSystemId;
    const model: PaymentInstructionModel = {
      dayOfMonth: formValues.day,
      type: formValues.type,
      bankSystemId: bankSystemId ?? "1",
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      amount: formValues.amount,
      signatureDate: formValues.signatureDate,
    };
    await addPaymentInstruction.execute(model);
    onSubmitCallback();
  };

  return (
    <>
      {addPaymentInstruction.error && (
        <AlertDialog
          content={addPaymentInstruction.error.message}
          labels={{ close: t("common:alert.close") }}
          onClose={onCloseErrorDialog}
          open={Boolean(addPaymentInstruction.error.message)}
          title={t("payments.add_payment_instruction.error_title")}
        />
      )}

      <Dialog
        onClose={onClose}
        aria-labelledby="add-payment-instruction-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loader
          fallback={
            <FormDialogFallback
              onClose={onClose}
              title={t(
                "payments.add_payment_instruction.add_payment_instruction_title",
              )}
            />
          }
          ready={Boolean(payees)}
          render={(): ReactElement => (
            <AddPaymentInstructionFormDialog
              instalmentDay={agreement.data?.instalmentDay}
              onClose={onClose}
              onSubmit={onSubmit}
              payees={payees}
            />
          )}
        />
      </Dialog>
    </>
  );
}
