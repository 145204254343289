import { Button } from "@mui/material";
import { useResetPassword } from "api/users";
import ResetPasswordFormDialog, {
  ResetPasswordFormValues,
} from "apps/admin/components/ResetPasswordFormDialog";
import { User } from "apps/admin/types";
import { useResponseError } from "common/hooks";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface ResetPasswordContainerProps {
  user: User;
}

export default function ResetPasswordContainer({
  user,
}: ResetPasswordContainerProps): ReactElement {
  const { t } = useTranslation("users");

  const responseError = useResponseError([
    ["invalid_user", t("error.invalid_user")],
    ["invalid_password_format", t("error.invalid_password_format")],
    [
      "invalid_password_previously_used",
      t("error.invalid_password_previously_used"),
    ],
  ]);

  const [isResetPasswordDialogOpen, setResetPasswordDialogOpen] =
    useState(false);

  const closeResetPasswordDialog = (): void => {
    setResetPasswordDialogOpen(false);
  };

  const resetPassword = useResetPassword(user.id, {
    onSuccess: closeResetPasswordDialog,
    onError: (response) => responseError.setError(response.code),
  });

  const openResetPasswordDialog = (): void => {
    resetPassword.reset();
    setResetPasswordDialogOpen(true);
  };

  const makeOnPasswordResetSubmit =
    () =>
    async (data: ResetPasswordFormValues): Promise<void> => {
      await resetPassword.execute({ password: data.newPassword });
    };

  return (
    <>
      <Button
        aria-label={t("reset_password")}
        variant="contained"
        color="primary"
        onClick={openResetPasswordDialog}
      >
        {t("reset_password")}
      </Button>

      <ResetPasswordFormDialog
        onSubmit={makeOnPasswordResetSubmit()}
        open={isResetPasswordDialogOpen}
        onClose={closeResetPasswordDialog}
        username={user.username}
        firstName={user.firstName}
        lastName={user.lastName}
        error={responseError.message}
      />
    </>
  );
}
