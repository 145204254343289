import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { useListAgreementThirdParties } from "api/agreements/third-parties";
import {
  AgreementThirdPartiesContainer,
  AssociateThirdPartyContainer,
} from "apps/servicing/modules/agreements/containers";
import { withModifiableAccess } from "apps/servicing/modules/agreements/hocs/withModifiableAccess";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";

function AgreementThirdPartiesView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const agreementId = agreement.id;
  const listAgreementThirdParties = useListAgreementThirdParties(agreementId);

  return (
    <ViewLayout
      title={t("third_parties.heading")}
      action={
        Boolean(listAgreementThirdParties.data?.length) && (
          <AccessControl
            allowedPermissions={["servicing:third-parties:associate"]}
          >
            <AssociateThirdPartyContainer agreementId={agreementId} />
          </AccessControl>
        )
      }
    >
      <ContainedLayout>
        <AgreementThirdPartiesContainer agreementId={agreementId} />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default withModifiableAccess(AgreementThirdPartiesView);
