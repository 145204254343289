import { Loader } from "@ldms/mui-sdk/templates";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useListAutomations } from "api/automations/listAutomations";
import { useListPortfolios } from "api/portfolios/listPortfolios";
import AutomationsList from "apps/admin/components/AutomationsList";
import { QueryError } from "common/components";
import ListLayout from "common/layouts/ListLayout";
import { ChangeEvent, ReactElement, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

function FilterSelect({
  children,
  id,
  label,
  onChange,
  name,
  value,
}: Readonly<{
  children: ReactNode | ReactNode[];
  id: string;
  label: string;
  name: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  value: string;
}>): ReactElement {
  return (
    <TextField
      value={value}
      variant="outlined"
      margin="none"
      InputLabelProps={{
        htmlFor: id,
        shrink: true,
      }}
      inputProps={{ displayEmpty: true, id }}
      label={label}
      name={name}
      select
      size="small"
      onChange={onChange}
    >
      {children}
    </TextField>
  );
}

export default function ListAutomationsContainer(): ReactElement {
  const [selectedPortfolioId, setSelectedPortfolioId] = useState("");
  const { t } = useTranslation(["automations"]);

  const automations = useListAutomations({
    portfolioId: selectedPortfolioId ? Number(selectedPortfolioId) : undefined,
  });

  const portfolios = useListPortfolios();

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedPortfolioId(event.target.value);
  };

  return (
    <Loader
      ready={Boolean(
        (automations.data ?? automations.error) &&
          (portfolios.data ?? portfolios.error),
      )}
      render={(): ReactElement => {
        if (automations.error || !automations.data) {
          return <QueryError onRetry={automations.refetch} />;
        }
        if (portfolios.error || !portfolios.data) {
          return <QueryError onRetry={portfolios.refetch} />;
        }
        return (
          <ListLayout
            filters={
              <Grid container spacing={1}>
                <Grid item>
                  <FilterSelect
                    id="portfolio"
                    onChange={handleFilterChange}
                    label={t("automations_list.portfolio_filter_label")}
                    name="portfolioId"
                    value={selectedPortfolioId}
                  >
                    <MenuItem value="">
                      {t("automations_list.all_portfolios_option")}
                    </MenuItem>
                    {(portfolios.data ?? []).map((portfolio) => (
                      <MenuItem value={portfolio.id} key={portfolio.id}>
                        {portfolio.name}
                      </MenuItem>
                    ))}
                  </FilterSelect>
                </Grid>
              </Grid>
            }
          >
            <AutomationsList
              data={automations.data}
              loading={automations.isValidating}
            />
          </ListLayout>
        );
      }}
    />
  );
}
