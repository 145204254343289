import { useFormat } from "@ldms/mui-sdk/formatting";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import {
  PaymentAllocationListItemModel,
  PaymentAllocationListItemModelPaidIndicatorEnum,
} from "generated/core/models";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

interface PaymentAllocationsListProps {
  paymentAllocationsData: PaymentAllocationListItemModel[] | undefined;
  loading: boolean;
}

const PaymentAllocationList = ({
  loading,
  paymentAllocationsData,
}: PaymentAllocationsListProps) => {
  const { t } = useTranslation("agreements");
  const locale = useLocale();
  const { formatAmount } = useFormat();

  const paidIndicatorDescription = new Map([
    [undefined, ""],
    [
      PaymentAllocationListItemModelPaidIndicatorEnum.CashAllocated,
      t("payment_allocation.cash_allocated_description"),
    ],
    [
      PaymentAllocationListItemModelPaidIndicatorEnum.NotFullyAllocated,
      t("payment_allocation.not_fully_allocated_description"),
    ],
    [
      PaymentAllocationListItemModelPaidIndicatorEnum.FullyAllocated,
      t("payment_allocation.fully_allocated_description"),
    ],
  ]);

  return (
    <>
      <Table
        size="small"
        stickyHeader
        aria-label={t("payment_allocation.table_label")}
      >
        <LoadableTableHead
          headings={[
            t("payment_allocation.transaction_date"),
            t("payment_allocation.record_type"),
            t("payment_allocation.allocation_type"),
            t("payment_allocation.amount"),
            t("payment_allocation.unallocated_amount"),
            t("payment_allocation.paid_indicator"),
          ]}
          loading={loading}
        />

        <TableBody>
          {paymentAllocationsData?.map((paymentAllocation) => (
            <TableRow key={uuidv4()}>
              <TableCell>
                {paymentAllocation.transactionDate &&
                  locale.formatDate(paymentAllocation.transactionDate)}
              </TableCell>
              <TableCell>{paymentAllocation.recordType}</TableCell>
              <TableCell>{paymentAllocation.allocationType}</TableCell>
              <TableCell>{formatAmount(paymentAllocation.amount)}</TableCell>
              <TableCell>
                {formatAmount(paymentAllocation.unallocatedAmount)}
              </TableCell>
              <TableCell>
                {paidIndicatorDescription.get(paymentAllocation.paidIndicator)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {paymentAllocationsData?.length === 0 && <NoResults />}
    </>
  );
};

export default PaymentAllocationList;
