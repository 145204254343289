/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The details of the agreement settlement quote associated with the agreement
 * @export
 * @interface AgreementSettlementQuoteModel
 */
export interface AgreementSettlementQuoteModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof AgreementSettlementQuoteModel
     */
    expiryDate?: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementSettlementQuoteModel
     */
    outstandingArrears: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementSettlementQuoteModel
     */
    overPayments: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementSettlementQuoteModel
     */
    feesAndCharges: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementSettlementQuoteModel
     */
    futureInstalments: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementSettlementQuoteModel
     */
    vat: string;
    /**
     * Whether the date of settlement falls within a dd window
     * @type {boolean}
     * @memberof AgreementSettlementQuoteModel
     */
    inDdWindow: boolean;
    /**
     * Denotes whether the agreement has a subsidy payment
     * @type {boolean}
     * @memberof AgreementSettlementQuoteModel
     */
    hasSubsidy: boolean;
}

/**
 * Check if a given object implements the AgreementSettlementQuoteModel interface.
 */
export function instanceOfAgreementSettlementQuoteModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "outstandingArrears" in value;
    isInstance = isInstance && "overPayments" in value;
    isInstance = isInstance && "feesAndCharges" in value;
    isInstance = isInstance && "futureInstalments" in value;
    isInstance = isInstance && "vat" in value;
    isInstance = isInstance && "inDdWindow" in value;
    isInstance = isInstance && "hasSubsidy" in value;

    return isInstance;
}

export function AgreementSettlementQuoteModelFromJSON(json: any): AgreementSettlementQuoteModel {
    return AgreementSettlementQuoteModelFromJSONTyped(json, false);
}

export function AgreementSettlementQuoteModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementSettlementQuoteModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiryDate': !exists(json, 'expiryDate') ? undefined : (new Date(json['expiryDate'])),
        'outstandingArrears': json['outstandingArrears'],
        'overPayments': json['overPayments'],
        'feesAndCharges': json['feesAndCharges'],
        'futureInstalments': json['futureInstalments'],
        'vat': json['vat'],
        'inDdWindow': json['inDdWindow'],
        'hasSubsidy': json['hasSubsidy'],
    };
}

export function AgreementSettlementQuoteModelToJSON(value?: AgreementSettlementQuoteModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expiryDate': value.expiryDate === undefined ? undefined : (value.expiryDate.toISOString().substring(0,10)),
        'outstandingArrears': value.outstandingArrears,
        'overPayments': value.overPayments,
        'feesAndCharges': value.feesAndCharges,
        'futureInstalments': value.futureInstalments,
        'vat': value.vat,
        'inDdWindow': value.inDdWindow,
        'hasSubsidy': value.hasSubsidy,
    };
}

