/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';

/**
 * 
 * @export
 * @interface BankAccountDetailsModel
 */
export interface BankAccountDetailsModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof BankAccountDetailsModel
     */
    readonly clientId: number;
    /**
     * The contact type in reference to the client
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    contactType: string;
    /**
     * The title of the client associated to the bank details
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    clientTitle?: string;
    /**
     * The first name of the client associated to the bank details
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    clientFirstName?: string;
    /**
     * The second name of the client associated to the bank details
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    clientSecondName?: string;
    /**
     * The last name of the client associated to the bank details
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    clientLastName?: string;
    /**
     * The name of the bank
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    bankName?: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof BankAccountDetailsModel
     */
    address?: AddressModel;
    /**
     * The international bank account number
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    iban?: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    bic?: string;
    /**
     * The account holders name
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    accountName: string;
    /**
     * The bank account number
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    accountNumber: string;
    /**
     * The bank account sort code
     * @type {string}
     * @memberof BankAccountDetailsModel
     */
    sortCode: string;
}

/**
 * Check if a given object implements the BankAccountDetailsModel interface.
 */
export function instanceOfBankAccountDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "contactType" in value;
    isInstance = isInstance && "accountName" in value;
    isInstance = isInstance && "accountNumber" in value;
    isInstance = isInstance && "sortCode" in value;

    return isInstance;
}

export function BankAccountDetailsModelFromJSON(json: any): BankAccountDetailsModel {
    return BankAccountDetailsModelFromJSONTyped(json, false);
}

export function BankAccountDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankAccountDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'contactType': json['contactType'],
        'clientTitle': !exists(json, 'clientTitle') ? undefined : json['clientTitle'],
        'clientFirstName': !exists(json, 'clientFirstName') ? undefined : json['clientFirstName'],
        'clientSecondName': !exists(json, 'clientSecondName') ? undefined : json['clientSecondName'],
        'clientLastName': !exists(json, 'clientLastName') ? undefined : json['clientLastName'],
        'bankName': !exists(json, 'bankName') ? undefined : json['bankName'],
        'address': !exists(json, 'address') ? undefined : AddressModelFromJSON(json['address']),
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
        'accountName': json['accountName'],
        'accountNumber': json['accountNumber'],
        'sortCode': json['sortCode'],
    };
}

export function BankAccountDetailsModelToJSON(value?: BankAccountDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactType': value.contactType,
        'clientTitle': value.clientTitle,
        'clientFirstName': value.clientFirstName,
        'clientSecondName': value.clientSecondName,
        'clientLastName': value.clientLastName,
        'bankName': value.bankName,
        'address': AddressModelToJSON(value.address),
        'iban': value.iban,
        'bic': value.bic,
        'accountName': value.accountName,
        'accountNumber': value.accountNumber,
        'sortCode': value.sortCode,
    };
}

