import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import AddPaymentInstructionContainer from "apps/servicing/modules/agreements/containers/AddPaymentInstructionContainer";
import AgreementPaymentInstructions from "apps/servicing/modules/agreements/containers/AgreementPaymentInstructions";
import { withModifiableAccess } from "apps/servicing/modules/agreements/hocs/withModifiableAccess";
import { AppError } from "common/components";
import useModal from "common/hooks/useModal";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";

interface AddPaymentInstructionActionProps {
  agreementId: number;
  agreementSystemId: string;
}

function AddPaymentInstructionAction({
  agreementId,
  agreementSystemId,
}: Readonly<AddPaymentInstructionActionProps>) {
  const modal = useModal();

  return (
    <>
      <AddButton onClick={modal.open} variant="contained" />
      <AddPaymentInstructionContainer
        agreementId={agreementId}
        agreementSystemId={agreementSystemId}
        open={modal.isOpen}
        onClose={modal.close}
        onSuccess={modal.close}
      />
    </>
  );
}

function PaymentInstructionsView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();

  if (agreement.error || !agreement.data) {
    return (
      <AppError
        message={agreement.error?.message}
        title={agreement.error?.name}
      />
    );
  }

  return (
    <ViewLayout
      title={t("payments.heading")}
      action={
        <AccessControl
          allowedPermissions={["servicing:payment-instructions:manage"]}
        >
          <AddPaymentInstructionAction
            agreementId={agreement.id}
            agreementSystemId={agreement.data.systemId}
          />
        </AccessControl>
      }
    >
      <ContainedLayout>
        <AgreementPaymentInstructions
          agreementId={agreement.id}
          agreementSystemId={agreement.data.systemId}
        />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default withModifiableAccess(PaymentInstructionsView);
