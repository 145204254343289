import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export interface Agreement {
  id: number;
  number: string;
  product?: string;
  customer: { id: number; name?: string; companyName?: string; href: string };
  balance: string;
  arrears: string;
  status: string;
  href: string;
}

interface AgreementListProps {
  data: Agreement[];
  loading: boolean;
}

const customerName = (agreement: Agreement): string | undefined =>
  agreement.customer.name
    ? agreement.customer.name
    : agreement.customer.companyName;

const displayName = (agreement: Agreement): string | undefined =>
  agreement.customer.companyName && agreement.customer.name
    ? `${agreement.customer.name} (${agreement.customer.companyName})`
    : customerName(agreement);

export default function AgreementList({
  data,
  loading,
}: AgreementListProps): ReactElement {
  const { t } = useTranslation("agreements");
  const { formatAmount } = useFormat();

  const sxCol = { minWidth: 240 };

  return (
    <TableContainer>
      <Table aria-label={t("agreement_list.label")}>
        <LoadableTableHead
          loading={loading}
          headings={[
            t("agreement_list.number_heading"),
            t("agreement_list.product_heading"),
            t("agreement_list.customer_heading"),
            t("agreement_list.current_balance_heading"),
            t("agreement_list.arrears_heading"),
            t("agreement_list.status_heading"),
          ]}
        />
        <TableBody>
          {data?.map((agreement) => (
            <TableRow key={agreement.id} hover>
              <TableCell sx={sxCol}>
                <Link component={RouterLink} to={agreement.href}>
                  {agreement.number}
                </Link>
              </TableCell>
              <TableCell sx={sxCol}>{agreement.product}</TableCell>
              <TableCell sx={sxCol}>
                <Link component={RouterLink} to={agreement.customer.href}>
                  {displayName(agreement) ||
                    t("agreements.customer_name_placeholder", {
                      id: agreement.customer.id,
                    })}
                </Link>
              </TableCell>
              <TableCell sx={sxCol}>
                {formatAmount(agreement.balance)}
              </TableCell>
              <TableCell sx={sxCol}>
                {formatAmount(agreement.arrears)}
              </TableCell>
              <TableCell sx={sxCol}>{agreement.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data.length === 0 && <NoResults />}
    </TableContainer>
  );
}
