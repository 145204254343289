import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { DeleteRoleRequest, RolesApi } from "generated/admin/apis";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

export const useDeleteRole = (
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(RolesApi);
  const swr = useSWRConfig();

  return useCommand<DeleteRoleRequest, void, ResponseError>(
    (model) => api.deleteRole({ roleId: model.roleId }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.list());
        options.onSuccess?.();
      },
    },
  );
};
