import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/tasks/keys";
import usePartialMutate from "common/hooks/usePartialMutate";
import { useApi } from "common/providers";
import { TasksApi } from "generated/tasks/apis";
import { EditTaskModel } from "generated/tasks/models";

const useEditTask = (
  taskId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(TasksApi, "task-management");
  const partialMutate = usePartialMutate();

  return useCommand(
    async (editTaskModel: EditTaskModel) =>
      api.editTask({
        taskId,
        editTaskModel,
      }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        partialMutate(keys.all());
      },
    },
  );
};

export { useEditTask };
