import { DescriptionList } from "@ldms/mui-sdk/components";
import {
  AlertDialog,
  ConfirmationDialog,
  Loader,
} from "@ldms/mui-sdk/templates";
import { Info } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useGetTemplate } from "api/documents/templates/getTemplate";
import { usePreviewTemplateVersion } from "api/templates/previewTemplateVersion";
import TemplateVersionList from "apps/documents/components/TemplateVersionList";
import { TemplateTypeEnum } from "apps/documents/types";
import { YesNoValue } from "common/components";
import Surface from "common/components/Surface";
import { useConfirm, useLocale } from "common/hooks";
import { useApi } from "common/providers";
import { TemplateApi } from "generated/documents/apis";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface TemplateVersionsProps {
  templateId: string;
  type: TemplateTypeEnum;
}

export default function TemplateVersions({
  templateId,
  type,
}: Readonly<TemplateVersionsProps>): ReactElement {
  const { t } = useTranslation("documents");
  const [comment, setComment] = useState("");
  const template = useGetTemplate(templateId);
  const templateCommandApi = useApi(TemplateApi);
  const [templateVersionNumber, setTemplateVersionNumber] = useState(0);
  const confirm = useConfirm();
  const locale = useLocale();

  const previewTemplateVersion = usePreviewTemplateVersion({
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(response);
      window.open(url, "_blank", "noopener,noreferrer");
    },
  });

  const handlePreviewCurrentVersion =
    (templateId: string, version: number) => (): void => {
      previewTemplateVersion.execute({ templateId, version });
    };

  const handleTemplateVersionRestore = (version: number): void => {
    setTemplateVersionNumber(version);
    confirm.handlePrompt(
      (): Promise<void> => onTemplateVersionRestore(version),
    );
  };

  const onTemplateVersionRestore = async (
    versionNumber: number,
  ): Promise<void> => {
    await templateCommandApi.restoreVersion({
      version: versionNumber,
      templateId,
    });
    template.refetch();
  };
  const onCommentClick = (data: string): void => {
    setComment(data);
  };

  const handleCloseViewCommentClick = (): void => {
    setComment("");
  };

  const renderTemplate = () => {
    if (template.error || !template.data) {
      return (
        <Typography color="error" aria-label={t("common:error.default")}>
          {t("common:error.default")}
        </Typography>
      );
    }

    const sortedTemplateData = [...template.data.versions].sort((a, b) =>
      a.version > b.version ? -1 : 1,
    );

    const currentVersion = sortedTemplateData[0].version;

    return (
      <>
        <AlertDialog
          content={comment}
          labels={{ close: t("common:alert.close") }}
          onClose={handleCloseViewCommentClick}
          open={Boolean(comment)}
          title={t("comment_title")}
        />
        <ConfirmationDialog
          content={
            <Alert severity="info" icon={<Info />}>
              <AlertTitle aria-label="restore-version-confirmation">
                {t("restore_version_confirm_alert_title", {
                  version:
                    templateVersionNumber === 0
                      ? t("template_versions.base_version")
                      : templateVersionNumber,
                })}
              </AlertTitle>
              <Typography variant="body2">
                {t("restore_version_confirm_alert_body")}
              </Typography>
            </Alert>
          }
          open={confirm.isOpen}
          onConfirm={confirm.handleConfirm}
          onReject={confirm.handleReject}
          title={t("restore_version_confirm_title", {
            version:
              templateVersionNumber === 0
                ? t("template_versions.base_version")
                : templateVersionNumber,
          })}
          labels={{
            confirm: t("restore_version_confirm_button"),
            reject: t("common:cancel"),
          }}
        />

        <Stack spacing={3}>
          {!template.data.active && type === TemplateTypeEnum.Letters && (
            <Alert
              severity="info"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("letters_to_email.inactive_info_message")}
            </Alert>
          )}
          <Surface
            title={t("template_details.title_label")}
            action={
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={handlePreviewCurrentVersion(
                    templateId,
                    currentVersion,
                  )}
                >
                  {t("template_details.preview_button")}
                </Button>
                <Button
                  href={`/api/documents/templates/${templateId}/versions/${currentVersion}`}
                >
                  {t("template_details.download_button")}
                </Button>
              </Stack>
            }
          >
            <DescriptionList label={t("template_details.title_label")}>
              <DescriptionList.Item
                label={t("template_details.description_label")}
              >
                {template.data.description}
              </DescriptionList.Item>
              {type === TemplateTypeEnum.Letters ? (
                <>
                  <DescriptionList.Item
                    label={t("template_details.document_type_label")}
                  >
                    {template.data.documentType}
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t("template_details.has_free_text_label")}
                  >
                    <YesNoValue
                      value={sortedTemplateData[0].hasFreeTextField}
                    />
                  </DescriptionList.Item>
                  <DescriptionList.Item
                    label={t("template_details.active_label")}
                  >
                    <YesNoValue value={template.data.active} />
                  </DescriptionList.Item>
                </>
              ) : (
                <></>
              )}

              <DescriptionList.Item label={t("template_details.version_label")}>
                {currentVersion}
              </DescriptionList.Item>
              <DescriptionList.Item label={t("template_details.created_label")}>
                {sortedTemplateData[0].createdAt
                  ? locale
                      .formatDateTime(new Date(sortedTemplateData[0].createdAt))
                      .concat(` - ${sortedTemplateData[0].createdBy}`)
                  : "-"}
              </DescriptionList.Item>
              <DescriptionList.Item label={t("template_details.comment_label")}>
                {sortedTemplateData[0].comment}
              </DescriptionList.Item>
            </DescriptionList>
          </Surface>

          <Surface
            disableGutters
            title={t("template_version_list.title_label")}
          >
            <TemplateVersionList
              templateId={templateId}
              loading={template.isValidating}
              data={sortedTemplateData}
              onCommentClick={onCommentClick}
              onRestore={handleTemplateVersionRestore}
              type={type}
            />
          </Surface>
        </Stack>
      </>
    );
  };

  return (
    <Loader
      fallback={
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      }
      ready={Boolean(template.data ?? template.error)}
      render={renderTemplate}
    />
  );
}
