import useQuery, { UseQueryReturn } from "common/hooks/useQuery/useQuery";
import { useApi } from "common/providers";
import { AutomationQueryApi } from "generated/core/apis";
import { AutomationDetailsModel } from "generated/core/models";
import * as keys from "./keys";

const useGetAutomation = (
  automationId: number,
): UseQueryReturn<AutomationDetailsModel> => {
  const api = useApi(AutomationQueryApi);

  return useQuery(keys.detail(automationId), () =>
    api.getAutomation({ automationId }),
  );
};

export { useGetAutomation };
