import { AppLayout } from "@ldms/mui-sdk/layouts";
import { Box, Link, Typography } from "@mui/material";
import { useEnvironment } from "common/providers/EnvironmentProvider";
import {
  FC,
  PropsWithChildren,
  ReactElement,
  createContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link as ReactRouterLink } from "react-router-dom";
import engageLogo from "./Engage.svg";

type ShellLayoutContextValue = {
  appPath: string;
  navigation: ReactElement | null;
  setAppPath: (path: string) => void;
  setNavigation: (navigation: ReactElement | null) => void;
};

export const ShellLayoutContext = createContext({
  navigation: null,
} as ShellLayoutContextValue);

export const ShellProvider: FC<PropsWithChildren> = ({ children }) => {
  const [navigation, setNavigation] = useState<ReactElement | null>(null);
  const [appPath, setAppPath] = useState("/");
  const memoizedValue = useMemo(
    () => ({
      appPath,
      setAppPath,
      navigation,
      setNavigation,
    }),
    [appPath, setAppPath, navigation, setNavigation],
  );

  return (
    <ShellLayoutContext.Provider value={memoizedValue}>
      {children}
    </ShellLayoutContext.Provider>
  );
};

const LogoLink: FC<{ to: string }> = ({ to }) => {
  return (
    <Link
      component={ReactRouterLink}
      color="inherit"
      to={to}
      underline="none"
      sx={(theme) => ({
        alignItems: "center",
        display: "flex",
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
      })}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginRight: 0.75,
          width: 30,
        }}
      >
        <img src={engageLogo} alt="Logo" />
      </Box>
      <Typography color="inherit" variant="h6">
        Engage
      </Typography>
    </Link>
  );
};

const ShellLayout: FC<
  PropsWithChildren<{
    appSwitcher: ReactElement;
    userMenu: ReactElement;
  }>
> = ({ children, appSwitcher, userMenu }) => {
  const { t } = useTranslation();
  const environment = useEnvironment();
  const isDemo = !environment.isProduction;

  return (
    <ShellProvider>
      <ShellLayoutContext.Consumer>
        {({ appPath, navigation }) => (
          <AppLayout
            appSwitcher={appSwitcher}
            appLogo={<LogoLink to={appPath} />}
            helpLink={{
              label: t("common:help_centre"),
              href: environment.helpUrl,
            }}
            topbar={<>{navigation}</>}
            actions={userMenu}
            isDemoEnvironment={isDemo}
          >
            {children}
          </AppLayout>
        )}
      </ShellLayoutContext.Consumer>
    </ShellProvider>
  );
};

export default ShellLayout;
