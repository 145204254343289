/* tslint:disable */
/* eslint-disable */
/**
 * Engage Tasks
 * API for managing tasks
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The task status
 * @export
 */
export const TaskStatusModel = {
    OnTrack: 'On Track',
    Complete: 'Complete',
    Urgent: 'Urgent',
    Overdue: 'Overdue'
} as const;
export type TaskStatusModel = typeof TaskStatusModel[keyof typeof TaskStatusModel];


export function TaskStatusModelFromJSON(json: any): TaskStatusModel {
    return TaskStatusModelFromJSONTyped(json, false);
}

export function TaskStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStatusModel {
    return json as TaskStatusModel;
}

export function TaskStatusModelToJSON(value?: TaskStatusModel | null): any {
    return value as any;
}

