import { Key, unstable_serialize, useSWRConfig } from "swr";

export default function usePartialMutate(): (
  key: Key,
  ...args: unknown[]
) => Promise<unknown> {
  const { cache, mutate } = useSWRConfig();
  return (key, ...args): Promise<unknown[]> => {
    if (!(cache instanceof Map)) {
      throw new Error(
        "partialMutate requires the cache provider to be a Map instance",
      );
    }

    const keys: Key[] = [];
    cache.forEach(
      (_, cacheKey) =>
        String(cacheKey).includes(unstable_serialize(key)) &&
        keys.push(cacheKey),
    );

    const mutations = keys.map((cacheKey) => mutate(cacheKey, ...args));
    return Promise.all(mutations);
  };
}
