/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Customers within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContactPointListItemModel,
  ContactPointModel,
  ErrorWithParametersModel,
} from '../models/index';
import {
    ContactPointListItemModelFromJSON,
    ContactPointListItemModelToJSON,
    ContactPointModelFromJSON,
    ContactPointModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
} from '../models/index';

export interface CreateCustomerContactPointRequest {
    id: string;
    contactPointModel: ContactPointModel;
}

export interface DeleteCustomerContactPointRequest {
    id: string;
    contactPointId: string;
}

export interface ListCustomerContactPointsRequest {
    id: string;
}

export interface UpdateCustomerContactPointsRequest {
    id: string;
    contactPointId: string;
    contactPointModel: ContactPointModel;
}

/**
 * 
 */
export class CustomerContactPointsApi extends runtime.BaseAPI {

    /**
     * API to create a contact point
     */
    async createCustomerContactPointRaw(requestParameters: CreateCustomerContactPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createCustomerContactPoint.');
        }

        if (requestParameters.contactPointModel === null || requestParameters.contactPointModel === undefined) {
            throw new runtime.RequiredError('contactPointModel','Required parameter requestParameters.contactPointModel was null or undefined when calling createCustomerContactPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customer:contact-points:manage"]);
        }

        const response = await this.request({
            path: `/customers/{id}/contact-points`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactPointModelToJSON(requestParameters.contactPointModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * API to create a contact point
     */
    async createCustomerContactPoint(requestParameters: CreateCustomerContactPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createCustomerContactPointRaw(requestParameters, initOverrides);
    }

    /**
     * API to delete a contact point
     */
    async deleteCustomerContactPointRaw(requestParameters: DeleteCustomerContactPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCustomerContactPoint.');
        }

        if (requestParameters.contactPointId === null || requestParameters.contactPointId === undefined) {
            throw new runtime.RequiredError('contactPointId','Required parameter requestParameters.contactPointId was null or undefined when calling deleteCustomerContactPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customer:contact-points:manage"]);
        }

        const response = await this.request({
            path: `/customers/{id}/contact-points/{contactPointId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"contactPointId"}}`, encodeURIComponent(String(requestParameters.contactPointId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * API to delete a contact point
     */
    async deleteCustomerContactPoint(requestParameters: DeleteCustomerContactPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCustomerContactPointRaw(requestParameters, initOverrides);
    }

    /**
     * API to list Customer contact points
     */
    async listCustomerContactPointsRaw(requestParameters: ListCustomerContactPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContactPointListItemModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listCustomerContactPoints.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customer:contact-points:view"]);
        }

        const response = await this.request({
            path: `/customers/{id}/contact-points`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactPointListItemModelFromJSON));
    }

    /**
     * API to list Customer contact points
     */
    async listCustomerContactPoints(requestParameters: ListCustomerContactPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContactPointListItemModel>> {
        const response = await this.listCustomerContactPointsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API to update a contact point for a customer
     */
    async updateCustomerContactPointsRaw(requestParameters: UpdateCustomerContactPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCustomerContactPoints.');
        }

        if (requestParameters.contactPointId === null || requestParameters.contactPointId === undefined) {
            throw new runtime.RequiredError('contactPointId','Required parameter requestParameters.contactPointId was null or undefined when calling updateCustomerContactPoints.');
        }

        if (requestParameters.contactPointModel === null || requestParameters.contactPointModel === undefined) {
            throw new runtime.RequiredError('contactPointModel','Required parameter requestParameters.contactPointModel was null or undefined when calling updateCustomerContactPoints.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["customer:contact-points:manage"]);
        }

        const response = await this.request({
            path: `/customers/{id}/contact-points/{contactPointId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"contactPointId"}}`, encodeURIComponent(String(requestParameters.contactPointId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContactPointModelToJSON(requestParameters.contactPointModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * API to update a contact point for a customer
     */
    async updateCustomerContactPoints(requestParameters: UpdateCustomerContactPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomerContactPointsRaw(requestParameters, initOverrides);
    }

}
