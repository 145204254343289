import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import { useState } from "react";
import errorHandler from "support/error-handler";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

interface UseRemoveInsuranceReturn {
  command: () => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
  reset(): void;
}

export const useRemoveInsurance = (
  agreementId: number,
  options: {
    onSuccess?: () => void;
  } = {},
): UseRemoveInsuranceReturn => {
  const api = useApi(AgreementCommandApi);
  const swr = useSWRConfig();
  const error = useResponseError();
  const [isLoading, setIsLoading] = useState(false);

  const command = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await api.removeInsurance({
        agreementId,
      });
      options.onSuccess?.();
      swr.mutate(keys.detail(agreementId));
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = (): void => {
    error.reset();
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
    reset,
  };
};
