import { Box, Chip, ChipProps, Typography } from "@mui/material";
import { ReactElement, ReactNode } from "react";

interface LabelledChipProps {
  chipProps?: ChipProps;
  label: ReactNode;
  value: string;
}

export default function LabelledChip({
  chipProps = {},
  label,
  value,
}: LabelledChipProps): ReactElement {
  return (
    <Box alignItems="center" display="flex">
      <Typography fontWeight={500} color="textSecondary" marginRight={1}>
        {label}:
      </Typography>
      <Chip label={value} size="small" {...chipProps} />
    </Box>
  );
}
