import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { LetterApi } from "generated/documents/apis";
import { LetterVerificationModel } from "generated/documents/models";
import * as keys from "./keys";

export const useRejectLettersToEmail = (
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(LetterApi);
  const partialMutate = usePartialMutate();

  return useCommand<LetterVerificationModel, void, ResponseError>(
    (model) =>
      api.rejectLettersToEmail({
        letterVerificationModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.lists());
        options.onSuccess?.();
      },
    },
  );
};
