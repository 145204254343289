import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/documents/templates/keys";
import { useApi } from "common/providers";
import {
  CreateTemplateVersionRequest,
  TemplateApi,
} from "generated/documents/apis";
import { useSWRConfig } from "swr";

export const useCreateTemplateVersion = (
  templateId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(TemplateApi);
  const swr = useSWRConfig();

  return useCommand<CreateTemplateVersionRequest, void, ResponseError>(
    (requestParameters) => {
      return api.createTemplateVersion(requestParameters);
    },
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.list());
        swr.mutate(keys.detail(templateId));
        options.onSuccess?.();
      },
    },
  );
};
