import { DateField } from "@ldms/mui-sdk/forms";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { UpdateAssetFormValues } from "apps/servicing/containers/UpdateAssetContainer/types";
import { ControlledTextField } from "common/components";
import { NewOrUsedModel } from "generated/core/models/NewOrUsedModel";
import { ReactElement } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

enum AssetCategory {
  Hard = "Hard",
  Soft = "Soft",
}

interface KeeperAddressModel {
  keeperAddress: {
    addressLine1: string;
    addressLine2: string;
    addressLine3?: string;
    addressLine4?: string;
    postcode: string;
  };
}

interface AddressFieldsetProps {
  errors: FieldErrors<KeeperAddressModel> | undefined;
  register: UseFormRegister<UpdateAssetFormValues>;
}

function AssetKeeperAddressFieldset({
  errors,
  register,
}: AddressFieldsetProps): ReactElement {
  const { t } = useTranslation("assets");

  return (
    <Box component="fieldset" sx={{ border: 0, margin: 0, padding: 0 }}>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={6}>
          <TextField
            {...register("keeperAddress.addressLine1")}
            error={Boolean(errors?.keeperAddress?.addressLine1)}
            helperText={errors?.keeperAddress?.addressLine1?.message}
            label={t("details.keeper_address.address_line_1")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("keeperAddress.addressLine2")}
            error={Boolean(errors?.keeperAddress?.addressLine2)}
            helperText={errors?.keeperAddress?.addressLine2?.message}
            label={t("details.keeper_address.address_line_2")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("keeperAddress.addressLine3")}
            error={Boolean(errors?.keeperAddress?.addressLine3)}
            helperText={errors?.keeperAddress?.addressLine3?.message}
            label={t("details.keeper_address.address_line_3")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("keeperAddress.addressLine4")}
            error={Boolean(errors?.keeperAddress?.addressLine4)}
            helperText={errors?.keeperAddress?.addressLine4?.message}
            label={t("details.keeper_address.address_line_4")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("keeperAddress.postcode")}
            error={Boolean(errors?.keeperAddress?.postcode)}
            helperText={errors?.keeperAddress?.postcode?.message}
            label={t("details.keeper_address.postcode")}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

interface IncludeInsuranceCheckboxProps {
  control: Control<UpdateAssetFormValues>;
}

function IncludeInsuranceCheckbox({
  control,
}: IncludeInsuranceCheckboxProps): ReactElement {
  const { t } = useTranslation("assets");

  return (
    <Box pt={2}>
      <FormControlLabel
        label={String(t("details.include_insurance_label"))}
        labelPlacement="end"
        control={
          <Controller
            control={control}
            name="includeInsurance"
            render={({ field: { value, ...field } }): ReactElement => (
              <Checkbox
                {...field}
                checked={value}
                onChange={(e): void => field.onChange(e.target.checked)}
              />
            )}
          />
        }
      />
    </Box>
  );
}

export interface UpdateAssetFormControlsProps {
  errors: FieldErrors<UpdateAssetFormValues>;
  control: Control<UpdateAssetFormValues>;
  register: UseFormRegister<UpdateAssetFormValues>;
  assetTypeOptions: { value: string; label: string }[];
  manufacturerOptions: { value: string; label: string }[];
  fromDate?: string;
  showIncludeInsurance: boolean;
  showManufacturerSubsidyPaid: boolean;
}

export default function UpdateAssetFormControls({
  errors,
  control,
  register,
  assetTypeOptions,
  manufacturerOptions,
  fromDate,
  showIncludeInsurance,
  showManufacturerSubsidyPaid,
}: UpdateAssetFormControlsProps): ReactElement {
  const { t } = useTranslation("assets");
  const noneOption = t("common:none");

  const renderMenuItems = (options: { value: string; label: string }[]) =>
    options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <TextField
          {...register("purchaseOrderNumber")}
          error={Boolean(errors?.purchaseOrderNumber)}
          helperText={errors?.purchaseOrderNumber?.message}
          label={t("details.purchase_order_number")}
        />
        <ControlledTextField
          helperText={errors?.typeCode?.message}
          SelectProps={{ displayEmpty: true }}
          error={errors?.typeCode?.message}
          control={control}
          id="typeCode"
          name="typeCode"
          label={t("details.type")}
          select
        >
          <MenuItem value="">{noneOption}</MenuItem>
          {renderMenuItems(assetTypeOptions)}
        </ControlledTextField>
        <ControlledTextField
          helperText={errors?.category?.message}
          SelectProps={{ displayEmpty: true }}
          error={errors?.category?.message}
          control={control}
          id="category"
          name="category"
          label={t("details.asset_category_label")}
          select
        >
          <MenuItem value="">{noneOption}</MenuItem>
          {Object.values(AssetCategory).map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </ControlledTextField>

        <TextField
          {...register("banding")}
          error={Boolean(errors?.banding)}
          label={t("details.asset_banding_label")}
          helperText={errors?.banding?.message}
        />

        <TextField
          {...register("description")}
          error={Boolean(errors?.description)}
          helperText={errors?.description?.message}
          label={t("details.description")}
        />
        <TextField
          {...register("serialNumber")}
          helperText={errors?.serialNumber?.message}
          error={Boolean(errors?.serialNumber)}
          label={t("details.serial_number")}
        />
        <TextField
          {...register("registrationNumber")}
          helperText={errors?.registrationNumber?.message}
          error={Boolean(errors?.registrationNumber)}
          label={t("details.registration_number")}
        />
        <DateField
          error={Boolean(errors?.registrationDate)}
          name="registrationDate"
          label={t("details.registration_date")}
          helperText={errors?.registrationDate?.message}
          control={control}
        />
        <TextField
          {...register("vehicleIdentificationNumber")}
          helperText={errors?.vehicleIdentificationNumber?.message}
          error={Boolean(errors?.vehicleIdentificationNumber)}
          label={t("details.vin")}
        />
        <TextField
          {...register("colour")}
          helperText={errors?.colour?.message}
          error={Boolean(errors?.colour)}
          label={t("details.colour")}
        />
        <ControlledTextField
          helperText={errors?.manufacturerCode?.message}
          error={errors?.manufacturerCode?.message}
          control={control}
          id="manufacturerCode"
          name="manufacturerCode"
          label={t("details.manufacturer")}
          select
        >
          <MenuItem value="">{noneOption}</MenuItem>
          {renderMenuItems(manufacturerOptions)}
        </ControlledTextField>

        {showIncludeInsurance && showManufacturerSubsidyPaid && (
          <IncludeInsuranceCheckbox control={control} />
        )}
      </Grid>
      <Grid item sm={6}>
        <TextField
          {...register("model")}
          error={Boolean(errors?.model)}
          helperText={errors?.model?.message}
          label={t("details.model")}
        />
        <TextField
          {...register("bodyType")}
          error={Boolean(errors?.bodyType)}
          helperText={errors?.bodyType?.message}
          label={t("details.body_type")}
        />
        <TextField
          {...register("yearManufactured")}
          error={Boolean(errors?.yearManufactured)}
          helperText={errors?.yearManufactured?.message}
          label={t("details.year_manufactured")}
          type="number"
        />
        <ControlledTextField
          helperText={errors?.newOrUsed?.message}
          error={errors?.newOrUsed?.message}
          control={control}
          id="newOrUsed"
          name="newOrUsed"
          label={t("details.new_used")}
          select
        >
          {Object.values(NewOrUsedModel).map((newOrUsed) => (
            <MenuItem key={newOrUsed} value={newOrUsed}>
              {newOrUsed}
            </MenuItem>
          ))}
        </ControlledTextField>
        <TextField
          {...register("engineSize")}
          error={Boolean(errors?.engineSize)}
          helperText={errors?.engineSize?.message}
          label={t("details.engine_size")}
          type="number"
        />
        <TextField
          {...register("annualMileage")}
          error={Boolean(errors?.annualMileage)}
          helperText={errors?.annualMileage?.message}
          label={t("details.annual_mileage")}
          type="number"
        />
        <DateField
          error={Boolean(errors?.hpiRegisteredDate)}
          name="hpiRegisteredDate"
          label={t("details.registered_interest")}
          helperText={errors?.hpiRegisteredDate?.message}
          control={control}
        />
        <DateField
          error={Boolean(errors?.hpiRemovedDate)}
          name="hpiRemovedDate"
          label={t("details.registered_interest_removed")}
          helperText={errors?.hpiRemovedDate?.message}
          control={control}
          inputProps={{
            min: fromDate,
          }}
        />
        <TextField
          {...register("registeredKeeper")}
          helperText={errors?.registeredKeeper?.message}
          error={Boolean(errors?.registeredKeeper)}
          label={t("details.registered_keeper")}
        />
        <TextField
          {...register("residualValue")}
          helperText={errors?.residualValue?.message}
          error={Boolean(errors?.residualValue)}
          label={t("details.residual_value")}
        />
        {showManufacturerSubsidyPaid && (
          <Box pt={2}>
            <FormControlLabel
              label={String(t("details.manufacturer_paid_label"))}
              labelPlacement="end"
              control={
                <Controller
                  control={control}
                  name="manufacturerSubsidyPaid"
                  render={({ field: { value, ...field } }): ReactElement => (
                    <Checkbox
                      {...field}
                      checked={value}
                      onChange={(e): void => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
            />
          </Box>
        )}
        {showIncludeInsurance && !showManufacturerSubsidyPaid && (
          <IncludeInsuranceCheckbox control={control} />
        )}
      </Grid>
      <Grid item sm={12}>
        <AssetKeeperAddressFieldset errors={errors} register={register} />
      </Grid>
    </Grid>
  );
}
