import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { useGetCompany } from "api/companies";
import UpdateCompanyContainer from "apps/servicing/modules/settings/containers/UpdateCompanyContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

interface UpdateCompanyViewProps {
  companyId: string;
  breadcrumbs?: { href?: string; label: string }[];
}

function UpdateCompanyView({
  companyId,
  breadcrumbs = [],
}: UpdateCompanyViewProps) {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();
  const company = useGetCompany(companyId);

  return (
    <main>
      <ViewLayout
        title={t("companies.edit_company.title_label")}
        breadcrumbs={[
          {
            href: `${appConfig.appRoutes.servicing}/settings/companies`,
            label: t("companies.breadcrumb_link_label"),
          },
          company.data
            ? {
                href: `${appConfig.appRoutes.servicing}/settings/companies/${companyId}`,
                label: company.data?.name,
              }
            : { label: "..." },
          ...breadcrumbs,
        ]}
      >
        <ContainedLayout>
          {company.data && (
            <UpdateCompanyContainer
              companyId={companyId}
              defaultValues={{
                ...company.data,
                vatCode: company.data.taxCodeId,
                vatNumber: company.data.taxRegistrationNumber,
                originationCommissionPercentage:
                  Number(company.data.originationCommissionPercentage) ||
                  undefined,
              }}
            />
          )}
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:companies:manage"],
})(UpdateCompanyView);
