import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { Button } from "@mui/material";
import AddCustomerAttachment from "apps/servicing/modules/customers/components/AddCustomerAttachment";
import CustomerAttachmentsContainer from "apps/servicing/modules/customers/containers/CustomerAttachmentsContainer";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { ContainedLayout } from "common/layouts";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next/";
import { withTranslationLoader } from "sdk/views";

interface CustomerDocumentsActionsProps {
  customerId: number;
}

function CustomerDocumentsActions({
  customerId,
}: CustomerDocumentsActionsProps) {
  const { t } = useTranslation("clients");
  const [isOpen, setOpen] = useState(false);

  const handleDialogOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <Button
        aria-label={t("documents.attach_document_button")}
        variant="contained"
        color="primary"
        onClick={handleDialogOpen}
      >
        {t("documents.attach_document_button")}
      </Button>

      <AddCustomerAttachment
        customerId={customerId}
        open={isOpen}
        onClose={onClose}
        onSubmit={onClose}
      />
    </>
  );
}

function DocumentsView(): ReactElement {
  const { t } = useTranslation("customers");
  const customer = useCustomer();
  const customerId = customer.data.id;

  return (
    <ViewLayout
      action={
        <AccessControl
          allowedPermissions={["servicing:customer-documents:manage"]}
        >
          <CustomerDocumentsActions customerId={customerId} />
        </AccessControl>
      }
      title={t("documents.heading")}
    >
      <FixedLayout>
        <ContainedLayout>
          <CustomerAttachmentsContainer customerId={customerId} />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}

export default withTranslationLoader(["customers", "documents"])(DocumentsView);
