/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TermModel } from './TermModel';
import {
    TermModelFromJSON,
    TermModelFromJSONTyped,
    TermModelToJSON,
} from './TermModel';

/**
 * 
 * @export
 * @interface FinancialDetailsModel
 */
export interface FinancialDetailsModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FinancialDetailsModel
     */
    balloonPayment?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FinancialDetailsModel
     */
    cashDeposit?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FinancialDetailsModel
     */
    partExchange?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FinancialDetailsModel
     */
    interestCharges?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FinancialDetailsModel
     */
    residualValue?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FinancialDetailsModel
     */
    blindDiscount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FinancialDetailsModel
     */
    fleetDiscount?: string;
    /**
     * 
     * @type {TermModel}
     * @memberof FinancialDetailsModel
     */
    leaseType?: TermModel;
    /**
     * Int value representing the VAT reclaim month (1 to 13)
     * @type {number}
     * @memberof FinancialDetailsModel
     */
    vatReclaimMonth?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FinancialDetailsModel
     */
    subsidyPayment?: string;
}

/**
 * Check if a given object implements the FinancialDetailsModel interface.
 */
export function instanceOfFinancialDetailsModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FinancialDetailsModelFromJSON(json: any): FinancialDetailsModel {
    return FinancialDetailsModelFromJSONTyped(json, false);
}

export function FinancialDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balloonPayment': !exists(json, 'balloonPayment') ? undefined : json['balloonPayment'],
        'cashDeposit': !exists(json, 'cashDeposit') ? undefined : json['cashDeposit'],
        'partExchange': !exists(json, 'partExchange') ? undefined : json['partExchange'],
        'interestCharges': !exists(json, 'interestCharges') ? undefined : json['interestCharges'],
        'residualValue': !exists(json, 'residualValue') ? undefined : json['residualValue'],
        'blindDiscount': !exists(json, 'blindDiscount') ? undefined : json['blindDiscount'],
        'fleetDiscount': !exists(json, 'fleetDiscount') ? undefined : json['fleetDiscount'],
        'leaseType': !exists(json, 'leaseType') ? undefined : TermModelFromJSON(json['leaseType']),
        'vatReclaimMonth': !exists(json, 'vatReclaimMonth') ? undefined : json['vatReclaimMonth'],
        'subsidyPayment': !exists(json, 'subsidyPayment') ? undefined : json['subsidyPayment'],
    };
}

export function FinancialDetailsModelToJSON(value?: FinancialDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balloonPayment': value.balloonPayment,
        'cashDeposit': value.cashDeposit,
        'partExchange': value.partExchange,
        'interestCharges': value.interestCharges,
        'residualValue': value.residualValue,
        'blindDiscount': value.blindDiscount,
        'fleetDiscount': value.fleetDiscount,
        'leaseType': TermModelToJSON(value.leaseType),
        'vatReclaimMonth': value.vatReclaimMonth,
        'subsidyPayment': value.subsidyPayment,
    };
}

