import { useListAgreementFinancialPostings } from "api/agreements/financial-postings/listAgreementFinancialPostings";
import { Loader, QueryError } from "common/components";
import FinancialPostingList from "common/components/FinancialPostingList";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";

interface AgreementFinancialPostingsContainerProps {
  agreementId: number;
}

export default function AgreementFinancialPostingsContainer({
  agreementId,
}: AgreementFinancialPostingsContainerProps): ReactElement {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const financialPostings = useListAgreementFinancialPostings({
    pageSize: rowsPerPage,
    params: {
      agreementId: agreementId,
    },
  });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => financialPostings.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Loader
      ready={Boolean(financialPostings.data ?? financialPostings.error)}
      render={(): ReactElement => {
        if (financialPostings.error || !financialPostings.data) {
          return <QueryError onRetry={financialPostings.refetch} />;
        }

        return (
          <FinancialPostingList
            data={financialPostings.data?.results}
            error={financialPostings.error}
            rowsPerPage={rowsPerPage}
            page={financialPostings.paging.page}
            total={financialPostings.data?.paging.total || 0}
            loading={financialPostings.isValidating}
            onRetry={financialPostings.refetch}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        );
      }}
    />
  );
}
