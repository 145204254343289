/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AssetCategoryModel = {
    Hard: 'Hard',
    Soft: 'Soft'
} as const;
export type AssetCategoryModel = typeof AssetCategoryModel[keyof typeof AssetCategoryModel];


export function AssetCategoryModelFromJSON(json: any): AssetCategoryModel {
    return AssetCategoryModelFromJSONTyped(json, false);
}

export function AssetCategoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetCategoryModel {
    return json as AssetCategoryModel;
}

export function AssetCategoryModelToJSON(value?: AssetCategoryModel | null): any {
    return value as any;
}

