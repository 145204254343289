import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import AddFinancialPostingContainer from "apps/finance/containers/AddFinancialPostingContainer";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { compose } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { withTranslationLoader } from "sdk/views";

function AddFinancialPostingView() {
  const { t } = useTranslation("finance");

  return (
    <ViewLayout
      title={t("financial_postings.add.heading")}
      breadcrumbs={[
        {
          href: `../filter-financial-postings`,
          label: t("financial_postings.add.breadcrumb_link_label"),
        },
      ]}
    >
      <ContainedLayout>
        <AddFinancialPostingContainer />
      </ContainedLayout>
    </ViewLayout>
  );
}

export default compose(
  withTranslationLoader("finance"),
  withProtectedView({
    allowedPermissions: ["finance:asset-financial-postings:add"],
  }),
)(AddFinancialPostingView);
