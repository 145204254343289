import { DateField } from "@ldms/mui-sdk/forms";
import { Grid, MenuItem, TextField } from "@mui/material";
import { OnboardingStatusEnum } from "apps/onboarding/types";
import { OnboardingStatusModel } from "generated/onboarding/models";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface OnboardingListFilterProps {
  filters: { status?: OnboardingStatusModel; from?: Date; to?: Date };
  onChange(key: string, value: string | Date | undefined): void;
}

export default function OnboardingListFilter({
  filters,
  onChange,
}: OnboardingListFilterProps): ReactElement {
  const { t } = useTranslation("onboardings");

  const makeDateChangeHandler =
    (name: string) =>
    (date: Date | null): void => {
      onChange(name, date ?? undefined);
    };

  const handleStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onChange(event.target.name, event.target.value);
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <DateField
          name="from"
          label={t("onboarding_list.filters.from")}
          inputProps={{
            max: new Date().toISOString().split("T")[0],
          }}
          margin="none"
          onChange={makeDateChangeHandler("from")}
          value={filters.from ? new Date(filters.from) : null}
        />
      </Grid>
      <Grid item>
        <DateField
          name="to"
          label={t("onboarding_list.filters.to")}
          inputProps={{
            min: filters.from,
            max: new Date().toISOString().split("T")[0],
          }}
          margin="none"
          onChange={makeDateChangeHandler("to")}
          value={filters.to ? new Date(filters.to) : null}
        />
      </Grid>
      <Grid item>
        <TextField
          InputLabelProps={{
            htmlFor: "onboardingStatusFilter",
            shrink: true,
          }}
          inputProps={{ displayEmpty: true, id: "onboardingStatusFilter" }}
          onChange={handleStatusChange}
          label={t("onboarding_list.filters.status")}
          name="status"
          select
          size="small"
          value={filters.status || ""}
          variant="outlined"
          margin="none"
        >
          <MenuItem value="" key="">
            {t("onboarding_list.filters.all_statuses")}
          </MenuItem>
          {Object.values(OnboardingStatusModel).map((status) => (
            <MenuItem value={status} key={status}>
              {t(OnboardingStatusEnum[status])}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}
