import { useCreateCustomerAttachment } from "api/customers/attachments";
import * as customerAttachmentKeys from "api/customers/attachments/keys";
import {
  AddAttachmentDialog,
  Attachment,
} from "common/components/AddAttachmentDialog";
import { usePartialMutate, useResponseError } from "common/hooks";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface AddCustomerAttachmentProps {
  customerId: number;
  onClose(): void;
  onSubmit(): void;
  open: boolean;
}

export default function AddCustomerAttachment({
  customerId,
  open,
  onClose,
  onSubmit: onSubmitCallBack,
}: AddCustomerAttachmentProps): ReactElement {
  const { t } = useTranslation("documents");
  const [filename, setFilename] = useState("");
  const error = useResponseError([
    [
      "file_type_upload_error",
      t("file_type_upload_error", { filename: filename }),
    ],
  ]);
  const partialMutate = usePartialMutate();

  const api = useCreateCustomerAttachment({
    onError: (response) => {
      error.setError(response.code);
    },
    onSuccess: () => {
      onSubmitCallBack();
      partialMutate(customerAttachmentKeys.list(customerId));
    },
  });

  const handleClose = (): void => {
    error.reset();
    setFilename("");
    onClose();
  };

  const onSubmit = async (data: Attachment): Promise<void> => {
    error.reset();
    setFilename(data.filename);
    await api.execute({
      clientId: customerId,
      metadata: {
        filename: data.filename,
        attachmentType: data.attachmentType,
        description: data.description,
      },
      file: data.file,
    });
  };

  return (
    <AddAttachmentDialog
      onClose={handleClose}
      onSubmit={onSubmit}
      open={open}
      error={error.message}
    />
  );
}
