import { useSession } from "@ldms/mui-sdk/bootstrap";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { GetApp } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import { DirectDebitSubmissionListItemModel } from "generated/core/models/DirectDebitSubmissionListItemModel";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface DirectDebitSubmissionsListProps {
  data?: DirectDebitSubmissionListItemModel[];
  loading: boolean;
  onGenerateFile(submissionId: number, status: string): void;
  onApplyPayments(submissionId: number, status: string): void;
  onDownloadSubmissionFile(submissionId: number): void;
}

export default function DirectDebitSubmissionsList({
  data,
  loading,
  onGenerateFile,
  onApplyPayments,
  onDownloadSubmissionFile,
}: DirectDebitSubmissionsListProps): ReactElement {
  const { t } = useTranslation("finance");
  const { formatAmount } = useFormat();
  const locale = useLocale();
  const session = useSession();

  const makeGenerateFileHandler =
    (submissionId: number, status: string) => (): void => {
      onGenerateFile(submissionId, status);
    };

  const makeApplyPaymentsHandler =
    (submissionId: number, status: string) => (): void => {
      onApplyPayments(submissionId, status);
    };

  const makeDownloadSubmissionFileHandler =
    (submissionId: number) => (): void => {
      onDownloadSubmissionFile(submissionId);
    };

  const tableHeaders: string[] = [
    t("direct_debit_submissions.sun_label"),
    t("direct_debit_submissions.submission_date_label"),
    t("direct_debit_submissions.due_date_label"),
    t("direct_debit_submissions.number_of_payments_label"),
    t("direct_debit_submissions.total_value_label"),
    t("direct_debit_submissions.status_label"),
    "",
  ];

  const convertStatus = (status: string): string => {
    switch (status) {
      case "PEND":
        return t("direct_debit_submissions.pending_label");
      case "FILE":
        return t("direct_debit_submissions.file_generated_label");
      default:
        return "";
    }
  };

  return (
    <>
      <Table
        size="small"
        aria-label={t("direct_debit_submissions.title")}
        stickyHeader
      >
        <LoadableTableHead headings={tableHeaders} loading={loading} />
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.id} hover>
              <TableCell>
                <Link component={RouterLink} to={`${String(item.id)}`}>
                  {item.sun}
                </Link>
              </TableCell>
              <TableCell>{locale.formatDate(item.submissionDate)}</TableCell>
              <TableCell>{locale.formatDate(item.dueDate)}</TableCell>
              <TableCell>{item.numberOfPayments}</TableCell>
              <TableCell>{formatAmount(item.totalValue)}</TableCell>
              <TableCell>{convertStatus(item.status)}</TableCell>
              <TableCell sx={{ paddingY: 0.25 }}>
                {item.status === "PEND" &&
                  session.hasPermission("finance:dd-submissions:manage") && (
                    <Grid container justifyContent="flex-end">
                      <Box whiteSpace="nowrap">
                        <Button
                          color="primary"
                          size="small"
                          onClick={makeGenerateFileHandler(
                            item.id,
                            item.status,
                          )}
                        >
                          {t(
                            "direct_debit_submissions.generate_file_button_label",
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                {item.status === "FILE" &&
                  session.hasPermission("finance:dd-submissions:manage") && (
                    <Grid container justifyContent="flex-end" spacing={1}>
                      <Grid item>
                        <Button
                          color="primary"
                          onClick={makeDownloadSubmissionFileHandler(item.id)}
                          size="small"
                          startIcon={<GetApp fontSize="inherit" />}
                        >
                          {t(
                            "direct_debit_submissions.download_submission_file_button",
                          )}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Box whiteSpace="nowrap">
                          <Button
                            color="primary"
                            size="small"
                            onClick={makeApplyPaymentsHandler(
                              item.id,
                              item.status,
                            )}
                          >
                            {t(
                              "direct_debit_submissions.apply_payments_button",
                            )}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(data || []).length === 0 && <NoResults />}
    </>
  );
}
