import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import {
  IconTooltipButton,
  SidebarNavigationList,
} from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { ContextLayout, SidebarLayout } from "@ldms/mui-sdk/layouts";
import { Loader } from "@ldms/mui-sdk/templates";
import { Email } from "@mui/icons-material";
import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import SendEmailContainer from "apps/servicing/modules/agreements/containers/SendEmailContainer";
import UpdateAgreementStatusMenuContainer from "apps/servicing/modules/agreements/containers/UpdateAgreementStatusMenuContainer";
import { AppError } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { useAgreement } from "common/providers";
import { useEnvironment } from "common/providers/EnvironmentProvider";
import { ProductTypeModel } from "generated/onboarding/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";

export default function AgreementLayout() {
  const [headerRect, setHeaderRect] = useState<DOMRectReadOnly>();
  const [isSendDialogOpen, setSendDialogOpen] = useState(false);
  const { ready, t } = useTranslation("agreements");
  const { formatAmount } = useFormat();
  const appConfig = useAppConfiguration();
  const agreement = useAgreement();
  const location = useLocation();
  const environment = useEnvironment();

  const onSend = (): void => {
    setSendDialogOpen(true);
  };
  const onClose = () => {
    setSendDialogOpen(false);
  };

  // This function is a callback which is triggered by a DOMRectReadOnly which
  // means it can only be tested in a real browser environment
  const onHeaderResize = (rect: DOMRectReadOnly) => {
    setHeaderRect(rect);
  };

  const makeItem = (label: string, to: string, display = true) => {
    return { label: t(label), to, display };
  };

  const errorCodesTranslations = new Map(
    ready
      ? [
          [
            "404",
            {
              message: t("error.agreement_404_message"),
              title: t("error.agreement_404"),
            },
          ],
          [
            "400",
            {
              message: t("error.agreement_404_message"),
              title: t("error.agreement_404"),
            },
          ],
        ]
      : undefined,
  );

  const errorCodeTranslation = errorCodesTranslations?.get(
    String(agreement.error?.status),
  );

  const navigationMenu = [
    makeItem("navigation.overview_link", "./overview"),
    makeItem("navigation.repayment_schedule_link", "./repayment-schedule"),
    makeItem("navigation.transactions_link", "./transactions"),
    makeItem("navigation.assets_link", "./assets"),
    makeItem("navigation.secondary_rentals_link", "./secondary-rentals"),
    makeItem("navigation.activity_link", "./activity"),
    makeItem("navigation.memos_link", "./memos"),
    makeItem(
      "navigation.payment_instructions_link",
      "./payment-instructions",
      agreement.isModifiable,
    ),
    makeItem("navigation.documents_link", "./documents"),
    makeItem(
      "navigation.third_parties_link",
      "./third-parties",
      agreement.isModifiable,
    ),
    makeItem("navigation.financial_postings_link", "./financial-postings"),
    makeItem(
      "navigation.settlement_quotes_link",
      "./settlement-quotes",
      agreement.data?.productName !== ProductTypeModel.FixedRateOperatingLease,
    ),
    makeItem(
      "navigation.insurance_link",
      "./insurance",
      agreement.isModifiable &&
        agreement.data?.productName !== ProductTypeModel.FixedRateLoan,
    ),
    makeItem(
      "navigation.termination_and_write_off_link",
      "./terminations",
      agreement.data?.statusCode === "TERMINATION",
    ),
  ];

  const renderAgreement = (): ReactElement => {
    if (agreement.error) {
      return (
        <AppError
          message={errorCodeTranslation?.message}
          title={errorCodeTranslation?.title}
        />
      );
    }

    const emailButton = "email.email_button";
    const isDemoEnvironment = !environment.isProduction;

    return (
      <>
        <ContextLayout
          isDemoEnvironment={isDemoEnvironment}
          actions={
            agreement.data ? (
              <Stack spacing={1} direction="row">
                <UpdateAgreementStatusMenuContainer />
                <AccessControl
                  allowedPermissions={["servicing:agreement-email:manage"]}
                >
                  <IconTooltipButton
                    title={t(emailButton)}
                    onClick={onSend}
                    aria-label={t(emailButton)}
                  >
                    <Email color="primary" />
                  </IconTooltipButton>
                </AccessControl>
              </Stack>
            ) : undefined
          }
          keyValues={
            agreement.data && [
              {
                label: t("agreement_layout.summary_labels.current_balance"),
                value: formatAmount(agreement.data?.currentBalance),
              },
              {
                label: t("agreement_layout.summary_labels.capital_balance"),
                value: formatAmount(agreement.data?.capitalBalance),
              },
              {
                label: t("agreement_layout.summary_labels.interest_balance"),
                value: formatAmount(agreement.data?.interestBalance),
              },
              {
                label: t("agreement_layout.summary_labels.arrears"),
                value: formatAmount(agreement.data?.arrearsBalance),
              },
              {
                label: t("agreement_layout.summary_labels.early_pays"),
                value: formatAmount(agreement.data?.earlyPays),
              },
            ]
          }
          breadcrumbs={
            <Breadcrumbs aria-label={t("common:breadcrumbs.label")}>
              {[
                agreement.data
                  ? {
                      href: `${appConfig.appRoutes.servicing}/customers/${agreement.data.customerId}`,
                      label: agreement.data.customerName,
                    }
                  : { label: "..." },
              ].map((crumb) =>
                typeof crumb.href !== "undefined" ? (
                  <Link
                    key={crumb.label}
                    component={RouterLink}
                    to={crumb.href}
                    variant="h5"
                  >
                    {crumb.label}
                  </Link>
                ) : (
                  <Typography key={crumb.label}>{crumb.label}</Typography>
                ),
              )}
              <Typography color="textPrimary" component="div" variant="h5">
                {agreement.data?.agreementNumber ?? "..."}
              </Typography>
            </Breadcrumbs>
          }
          onResize={onHeaderResize}
        >
          {headerRect && (
            <SidebarLayout
              sidebar={
                <Box
                  paddingY={2}
                  marginTop={`${headerRect.top + headerRect.height}px`}
                >
                  <SidebarNavigationList
                    heading={t("sidebar.heading")}
                    label="sidebar-navigation-heading"
                    pathname={`./${location.pathname.split("/").pop()}`}
                  >
                    {navigationMenu.map((item) => {
                      return (
                        item.display && (
                          <SidebarNavigationList.ItemButton
                            component={RouterLink}
                            to={item.to}
                            key={item.to}
                            label={item.label}
                          />
                        )
                      );
                    })}
                  </SidebarNavigationList>
                </Box>
              }
            >
              <Box component="main" height="100%">
                <Outlet />
              </Box>
            </SidebarLayout>
          )}
        </ContextLayout>
        {agreement.data && (
          <AccessControl
            allowedPermissions={["servicing:agreement-email:manage"]}
          >
            <SendEmailContainer open={isSendDialogOpen} onClose={onClose} />
          </AccessControl>
        )}
      </>
    );
  };

  return <Loader ready={ready} render={renderAgreement} />;
}
