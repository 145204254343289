import { AgreementActivity } from "apps/servicing/modules/agreements/containers";
import { ContainedLayout } from "common/layouts";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";

export default function ActivityView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();

  return (
    <ViewLayout title={t("activity.heading")}>
      <FixedLayout>
        <ContainedLayout>
          <AgreementActivity agreementId={agreement.id} />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}
