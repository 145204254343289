import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { AmountField, DateField, Form } from "@ldms/mui-sdk/forms";
import { AlertDialog, Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  useChequeTransaction,
  useRefundTransaction,
  useTransaction,
} from "api/agreements/transactions";
import { useConfirm, useResponseError, useYupResolver } from "common/hooks";
import { useAgreement, useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import {
  TransactionBankModel,
  TransactionModel,
  TransactionTypeModel,
} from "generated/core/models";
import { ReactElement, useState } from "react";
import { Controller, Resolver, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import errorHandler from "support/error-handler";
import useSWR, { SWRResponse } from "swr";

interface AddTransactionFormFieldValues {
  bankDate: Date;
  amountReceived: number;
  agreementId: string;
  comment?: string;
  bankId: string;
  chequeNumber?: string;
  transactionType: string;
  accountNumber?: string;
  accountName?: string;
  sortCode?: string;
  payee?: string;
  bankName?: string;
  chequeRequestComment?: string;
}

interface AddTransactionChequeFieldValues
  extends AddTransactionFormFieldValues {
  sortCode: string;
  chequeNumber: string;
  accountName: string;
  accountNumber: string;
  transactionType: "Cheque";
  bankName: string;
}

interface AddTransactionRefundFieldValues
  extends AddTransactionFormFieldValues {
  payee: string;
  transactionType: "Refund";
  chequeRequestComment?: string;
}

export type AddTransactionFieldValues =
  | AddTransactionChequeFieldValues
  | AddTransactionRefundFieldValues;

const labels = {
  chequeNumber: "transactions.cheque_number",
  accountNumber: "transactions.account_number",
  sortCode: "transactions.sort_code",
  payee: "transactions.payee",
};

const useAddTransactionResolver = (): Resolver<AddTransactionFieldValues> => {
  const { t } = useTranslation(["agreements", "common"]);
  const bankDateTranslation = t("transactions.bank_date");
  const amountReceived = t("transactions.amount_received");

  return useYupResolver<AddTransactionFieldValues>((yup) =>
    yup.object().shape({
      amountReceived: yup
        .number(amountReceived)
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .isRequired(amountReceived)
        .maxAmount(99999999.99, amountReceived),
      bankDate: yup
        .date()
        .localDate()
        .isValidDate(bankDateTranslation)
        .isRequired(bankDateTranslation)
        .isNotFuture(bankDateTranslation),
      transactionType: yup.string().isRequired(t("transactions.payment_type")),
      bankId: yup.string().isRequired(t("transactions.allocation_code")),
      comment: yup.string().maxCharacters(500, t("transactions.comment")),
      otherwise: yup.string().nullable(),
      chequeNumber: yup.string().when("transactionType", {
        is: "Cheque",
        then: yup
          .string()
          .isRequired(t(labels.chequeNumber))
          .maxCharacters(10, t(labels.chequeNumber)),
      }),
      accountNumber: yup.string().when("transactionType", {
        is: "Cheque",
        then: yup
          .string()
          .isRequired(t(labels.accountNumber))
          .maxCharacters(20, t(labels.accountNumber)),
        otherwise: yup.string().nullable(),
      }),
      sortCode: yup.string().when("transactionType", {
        is: "Cheque",
        then: yup
          .string()
          .isRequired(t(labels.sortCode))
          .maxCharacters(10, t(labels.sortCode)),
        otherwise: yup.string().nullable(),
      }),
      accountName: yup.string().when("transactionType", {
        is: "Cheque",
        then: yup.string().maxCharacters(20, t("transactions.account_name")),
        otherwise: yup.string().nullable(),
      }),
      bankName: yup.string().when("transactionType", {
        is: "Cheque",
        then: yup.string().maxCharacters(50, t("transactions.bank_name")),
        otherwise: yup.string().nullable(),
      }),
      chequeRequestComment: yup.string().when("transactionType", {
        is: "Refund",
        then: yup
          .string()
          .maxCharacters(250, t("transactions.cheque_request_comments")),
        otherwise: yup.string().nullable(),
      }),
      payee: yup.string().when("transactionType", {
        is: "Refund",
        then: yup
          .string()
          .isRequired(t(labels.payee))
          .maxCharacters(50, t(labels.payee)),
        otherwise: yup.string().nullable(),
      }),
    }),
  );
};

function useAgreementTransactions() {
  const agreementQueryApi = useApi(AgreementQueryApi);
  const agreement = useAgreement();
  const transaction = useTransaction(agreement.id);
  const chequeTransaction = useChequeTransaction(agreement.id);
  const refundTransaction = useRefundTransaction(agreement.id);

  const banks = useSWR(["agreements/transactions/banks"], () =>
    agreementQueryApi.listTransactionBanks(),
  );

  const transactionTypes = useSWR(["agreements/transactions/types"], () =>
    agreementQueryApi.listTransactionTypes(),
  );

  const addTransaction = async (
    data: AddTransactionFieldValues,
  ): Promise<void> => {
    const transactionModel: TransactionModel = {
      bankDate: new Date(data.bankDate),
      amountReceived: data.amountReceived.toFixed(2),
      bankId: Number(data.bankId),
      comment: data.comment,
      type: data.transactionType,
    };

    if (data.transactionType === "Refund") {
      return refundTransaction.execute({
        payee: data.payee,
        refundComment: data.chequeRequestComment,
        ...transactionModel,
      });
    }

    if (data.transactionType === "Cheque") {
      return chequeTransaction.execute({
        ...transactionModel,
        sortCode: data.sortCode,
        chequeNumber: data.chequeNumber,
        accountNumber: data.accountNumber,
        bankName: data.bankName,
        accountName: data.accountName,
      });
    }

    return transaction.execute(transactionModel);
  };

  return {
    agreement,
    banks,
    transactionTypes,
    addTransaction,
    error:
      refundTransaction.error ?? chequeTransaction.error ?? transaction.error,
  };
}

const AddTransactionContainerFormFields = ({
  form,
  transactionType,
  banks,
  transactionTypes,
}: {
  form: UseFormReturn<AddTransactionFieldValues>;
  banks: SWRResponse<TransactionBankModel[], ResponseError>;
  transactionTypes: SWRResponse<TransactionTypeModel[], ResponseError>;
  transactionType: string;
}) => {
  const { t } = useTranslation("agreements");

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <TextField
          {...form.register("agreementId")}
          error={Boolean(form.formState.errors.agreementId?.message)}
          label={t("transactions.agreement_id")}
          helperText={form.formState.errors.agreementId?.message}
          inputProps={{ readOnly: true }}
          type="number"
        />
        <DateField
          name="bankDate"
          control={form.control}
          helperText={form.formState.errors.bankDate?.message}
          label={t("transactions.bank_date")}
          error={Boolean(form.formState.errors.bankDate?.message)}
        />
        <AmountField
          required
          label={t("transactions.amount_received")}
          error={Boolean(form.formState.errors.amountReceived?.message)}
          helperText={form.formState.errors.amountReceived?.message}
          control={form.control}
          name="amountReceived"
        />
        <Controller
          name="bankId"
          control={form.control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={t("transactions.allocation_code")}
              SelectProps={{ displayEmpty: true }}
              required
              error={Boolean(form.formState.errors.bankId?.message)}
              helperText={form.formState.errors.bankId?.message}
            >
              <MenuItem value="">{t("common:please_select")}</MenuItem>
              {banks.data?.map((bank) => (
                <MenuItem key={bank.id} value={bank.id}>
                  {bank.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="transactionType"
          control={form.control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              required
              label={t("transactions.payment_type")}
            >
              {transactionTypes.data?.map((transactionType) => (
                <MenuItem
                  key={transactionType.code}
                  value={transactionType.code}
                >
                  {transactionType.type}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item sm={12}>
        <Divider />
      </Grid>
      <Grid item sm={12}>
        {!["Refund", "Cheque"].includes(transactionType || "") && (
          <TextField
            label={t("transactions.comment")}
            error={Boolean(form.formState.errors.comment)}
            {...form.register("comment")}
            helperText={form.formState.errors.comment?.message}
          />
        )}
        {transactionType === "Refund" && (
          <>
            <TextField
              {...form.register("payee")}
              error={Boolean(form.formState.errors.payee?.message)}
              label={t(labels.payee)}
              required
              helperText={form.formState.errors.payee?.message}
            />
            <TextField
              {...form.register("chequeRequestComment")}
              error={Boolean(
                form.formState.errors.chequeRequestComment?.message,
              )}
              label={t("transactions.cheque_request_comments")}
              helperText={form.formState.errors.chequeRequestComment?.message}
            />
          </>
        )}
        {transactionType === "Cheque" && (
          <>
            <TextField
              {...form.register("chequeNumber")}
              required
              error={Boolean(form.formState.errors.chequeNumber?.message)}
              label={t(labels.chequeNumber)}
              helperText={form.formState.errors.chequeNumber?.message}
            />
            <TextField
              {...form.register("sortCode")}
              required
              error={Boolean(form.formState.errors.sortCode?.message)}
              label={t(labels.sortCode)}
              helperText={form.formState.errors.sortCode?.message}
            />
            <TextField
              required
              {...form.register("accountNumber")}
              error={Boolean(form.formState.errors.accountNumber?.message)}
              label={t(labels.accountNumber)}
              helperText={form.formState.errors.accountNumber?.message}
            />
            <TextField
              {...form.register("accountName")}
              error={Boolean(form.formState.errors.accountName?.message)}
              label={t("transactions.account_name")}
              helperText={form.formState.errors.accountName?.message}
            />
            <TextField
              {...form.register("bankName")}
              error={Boolean(form.formState.errors.bankName?.message)}
              label={t("transactions.bank_name")}
              helperText={form.formState.errors.bankName?.message}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

const AddTransactionContainer = () => {
  const [transactionConfirmation, setTransactionConfirmation] = useState("");
  const { t } = useTranslation("agreements");
  const confirmReference = useConfirm();
  const agreementTransactions = useAgreementTransactions();
  const { formatAmount } = useFormat();
  const responseError = useResponseError();
  const resolver = useAddTransactionResolver();
  const navigate = useNavigate();

  const handleOnSubmit = async (
    data: AddTransactionFieldValues,
  ): Promise<void> => {
    try {
      responseError.reset();
      await agreementTransactions.addTransaction(data);
      agreementTransactions.agreement.mutate();
      setTransactionConfirmation(
        `${agreementTransactions.transactionTypes.data?.find(
          (tt) => tt.code === data.transactionType,
        )?.type} ${formatAmount(
          data.amountReceived,
        )} ${agreementTransactions.banks.data?.find(
          (b) => b.id === Number(data.bankId),
        )?.name}`,
      );
      confirmReference.handlePrompt(() => {
        setTransactionConfirmation("");
        navigate("../transactions");
      });
    } catch (response) {
      responseError.setError((await errorHandler(response)).code);
    }
  };

  return (
    <>
      <Form
        label={t("transactions.add_transaction")}
        onSubmit={handleOnSubmit}
        resolver={resolver}
        defaultValues={{
          bankDate: new Date(new Date().toISOString().split("T")[0]),
          agreementId: String(agreementTransactions.agreement.id),
          transactionType: "Cheque",
          bankId: "",
        }}
      >
        {(form): ReactElement => {
          const transactionType = form.watch("transactionType");

          return (
            <Loader
              fallback={
                <Box display="flex" justifyContent="center" p={2}>
                  <CircularProgress />
                </Box>
              }
              ready={Boolean(
                agreementTransactions.transactionTypes.data ||
                  agreementTransactions.error ||
                  agreementTransactions.agreement.data,
              )}
              render={(): ReactElement => {
                return (
                  <Container maxWidth="sm">
                    <Paper>
                      <Stack gap={2} padding={2}>
                        <Box>
                          <AddTransactionContainerFormFields
                            banks={agreementTransactions.banks}
                            form={form}
                            transactionType={transactionType}
                            transactionTypes={
                              agreementTransactions.transactionTypes
                            }
                          />
                          {agreementTransactions.error?.message && (
                            <Typography
                              color="error"
                              data-testid="addTransaction.error"
                            >
                              {t("common:error.default")}
                            </Typography>
                          )}
                        </Box>
                        <Box display="flex" justifyContent="flex-end" gap={1}>
                          <Button onClick={() => navigate("../transactions")}>
                            {t("common:cancel")}
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={form.formState.isSubmitting}
                          >
                            {t("common:save")}
                          </Button>
                        </Box>
                      </Stack>
                    </Paper>
                  </Container>
                );
              }}
            />
          );
        }}
      </Form>
      <AlertDialog
        content={t("transactions.confirmation.message", {
          reference: transactionConfirmation,
        })}
        labels={{ close: t("common:alert.close") }}
        onClose={confirmReference.handleConfirm}
        open={!!transactionConfirmation}
        title={t("transactions.confirmation.title")}
      />
    </>
  );
};

export default AddTransactionContainer;
