import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ResponseError } from "generated";
import { BankAccountsApi } from "generated/servicing-v2/apis";
import { CreateBankAccountModel } from "generated/servicing-v2/models";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

export const useAddBankAccount = (
  customerId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(BankAccountsApi, "v2");
  const swr = useSWRConfig();

  return useCommand<CreateBankAccountModel, void, ResponseError>(
    (model) =>
      api.createBankAccount({
        createBankAccountModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.listBanks(customerId));
        options.onSuccess?.();
      },
    },
  );
};
