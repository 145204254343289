import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { AssetCommandApi } from "generated/core/apis";
import { UpdateAssetModel } from "generated/core/models";
import { useState } from "react";
import errorHandler from "support/error-handler";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

interface UseUpdateAssetReturn {
  command: (updateAssetModel: UpdateAssetModel) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
}

export const useUpdateAsset = (
  assetId: number,
  options: {
    onSuccess?: () => void;
  } = {},
): UseUpdateAssetReturn => {
  const api = useApi(AssetCommandApi);
  const swrConfig = useSWRConfig();
  const error = useResponseError();
  const [isLoading, setIsLoading] = useState(false);

  const command = async (updateAssetModel: UpdateAssetModel): Promise<void> => {
    try {
      setIsLoading(true);
      await api.updateAsset({
        assetId,
        updateAssetModel,
      });
      options.onSuccess?.();
      swrConfig.mutate(keys.list());
      swrConfig.mutate(keys.details(assetId));
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
  };
};
