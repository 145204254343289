import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Button, Typography } from "@mui/material";
import { useCancelPaymentIntruction } from "api/agreements/payment-instructions/cancelPaymentInstruction";
import * as paymentIntructionKeys from "api/agreements/payment-instructions/keys";
import useConfirm from "common/hooks/useConfirm";
import usePartialMutate from "common/hooks/usePartialMutate";
import useResponseError from "common/hooks/useResponseError";
import { useTranslation } from "react-i18next";

interface CancelPaymentIntructionProps {
  agreementId: string;
  paymentInstructionId: string;
}

export default function CancelPaymentIntructionActionContainer({
  agreementId,
  paymentInstructionId,
}: Readonly<CancelPaymentIntructionProps>) {
  const { t } = useTranslation("agreements");
  const confirm = useConfirm();
  const partialMutate = usePartialMutate();
  const responseError = useResponseError();
  const cancelPaymentInstruction = useCancelPaymentIntruction(
    paymentInstructionId,
    {
      onError: (error) => responseError.setError(error.code),
      onSuccess: () => {
        partialMutate(paymentIntructionKeys.all(agreementId));
      },
    },
  );

  const handleClick = () =>
    confirm.handlePrompt(cancelPaymentInstruction.execute);

  return (
    <>
      <Button onClick={handleClick} variant="contained">
        {t("payment_instruction.cancel_button")}
      </Button>

      <ConfirmationDialog
        content={
          <Typography>
            {t("payments.cancel_payment_instruction.confirmation_message")}
          </Typography>
        }
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("payments.cancel_payment_instruction.confirmation_title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        content={responseError.message}
        labels={{ close: t("common:alert.close") }}
        onClose={responseError.reset}
        open={Boolean(responseError.message)}
        title={t("payments.cancel_payment_instruction.error_title")}
      />
    </>
  );
}
