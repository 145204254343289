import { QueryKey } from "api";

export const all = (): QueryKey => ["terminations"];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (terminationId: number): QueryKey => [
  ...details(),
  terminationId,
];
