import useSWR, { Fetcher, Key } from "swr";

export type UseQueryOptions = { enabled?: boolean };

export interface UseQueryReturn<TData, TError = Error> {
  data?: TData;
  error?: TError;
  isValidating: boolean;
  refetch: () => Promise<void>;
}

export type UseQueryHook<
  TData = unknown,
  TError = Error,
  TKey extends Key = null,
> = (
  key: TKey,
  fetcher: Fetcher<TData, TKey>,
  options?: UseQueryOptions,
) => UseQueryReturn<TData, TError>;

const useQuery = <TData = unknown, TError = Error, TKey extends Key = null>(
  key: TKey,
  fetcher: Fetcher<TData, TKey>,
  optionOverrides?: UseQueryOptions,
): UseQueryReturn<TData, TError> => {
  const options = { enabled: true, ...optionOverrides };
  const enabledKey = options.enabled
    ? [...(Array.isArray(key) ? key : [key])]
    : null;

  const response = useSWR(enabledKey, fetcher);

  return {
    data: response.data,
    error: response.error,
    isValidating: response.isValidating,
    refetch: async () => {
      await response.mutate();
    },
  };
};

export default useQuery;
