import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import EditCustomerContainer from "apps/servicing/modules/customers/containers/EditCustomerContainer/EditCustomerContainer";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout/ViewLayout";
import { useTranslation } from "react-i18next";

const EditCustomerView = () => {
  const { t } = useTranslation("clients");
  const customer = useCustomer();

  return (
    <ViewLayout
      title={t("update_customer.update_customer")}
      breadcrumbs={[
        { label: t("clients:breadcrumbs.contacts"), href: "../contacts" },
      ]}
    >
      <ContainedLayout>
        <EditCustomerContainer customerSystemId={customer.data.systemId} />
      </ContainedLayout>
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: ["servicing:contacts:manage"],
})(EditCustomerView);
