import { PagedQuery, useQueryPaged } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { AgreementListItemModel } from "generated/core/models";
import { keys } from ".";

export const useListAgreements: PagedQuery<
  AgreementListItemModel,
  {
    query?: string;
    agreementNumber?: string;
    active?: boolean;
    enabled?: boolean;
  }
> = ({ pageSize, params = {} } = {}) => {
  const api = useApi(AgreementQueryApi);

  return useQueryPaged(
    keys.list({
      query: params.query,
      agreementNumber: params.agreementNumber,
      active: params.active,
    }),
    async (_key, paging) =>
      api.listAgreementsRaw({
        limit: paging.pageSize,
        offset: paging.offset,
        query: params.query,
        agreementNumber: params.agreementNumber,
        active: params.active,
      }),
    { limit: pageSize, enabled: params.enabled },
  );
};
