import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { Loader } from "@ldms/mui-sdk/templates";
import { Grid } from "@mui/material";
import * as keys from "api/agreements/settlement-quotes/keys";
import { useGetSettlementQuote } from "api/settlement-quotes";
import ApproveSettlementQuoteContainer from "apps/servicing/containers/ApproveSettlementQuoteContainer";
import RejectSettlementQuoteContainer from "apps/servicing/containers/RejectSettlementQuoteContainer";
import SettlementQuoteDetails from "apps/servicing/modules/agreements/components/SettlementQuoteDetails";
import { withSettlementQuote } from "apps/servicing/modules/agreements/hocs/withSettlementQuote";
import { QueryError } from "common/components";
import ModifiableToggle from "common/components/ModifiableToggle";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import compose from "lodash/fp/compose";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";

interface SettlementQuotesProps {
  settlementQuoteId: number;
}

function SettlementQuoteDetailsView({
  settlementQuoteId,
}: SettlementQuotesProps) {
  const { t } = useTranslation("agreements");
  const appConfig = useAppConfiguration();
  const settlementQuote = useGetSettlementQuote(settlementQuoteId);
  const agreement = useAgreement();
  const navigate = useNavigate();
  const swr = useSWRConfig();

  const onSuccess = (): void => {
    agreement.mutate();
    swr.mutate(keys.list(agreement.id));
    navigate(
      `${appConfig.appRoutes.servicing}/agreements/${agreement.id}/overview`,
    );
  };

  return (
    <ViewLayout
      title={t("settlement_quotes.details.heading")}
      breadcrumbs={[
        {
          href: `${appConfig.appRoutes.servicing}/agreements/${agreement.id}/settlement-quotes/`,
          label: t("settlement_quotes.details.breadcrumb_link_label"),
        },
      ]}
      action={
        <AccessControl
          allowedPermissions={["servicing:settlement-quotes:elevated"]}
        >
          <ModifiableToggle>
            <Grid container spacing={1}>
              <Grid item>
                <RejectSettlementQuoteContainer
                  settlementQuoteId={settlementQuoteId}
                  settlementStatus={settlementQuote.data?.status}
                />
              </Grid>
              <Grid item>
                <ApproveSettlementQuoteContainer
                  settlementQuoteId={settlementQuoteId}
                  settlementStatus={settlementQuote.data?.status}
                  onSuccess={onSuccess}
                />
              </Grid>
            </Grid>
          </ModifiableToggle>
        </AccessControl>
      }
    >
      <FixedLayout>
        <ContainedLayout>
          <Loader
            ready={Boolean(settlementQuote.data ?? settlementQuote.error)}
            render={(): ReactElement => {
              if (settlementQuote.error || !settlementQuote.data) {
                return <QueryError onRetry={settlementQuote.refetch} />;
              }
              return <SettlementQuoteDetails data={settlementQuote.data} />;
            }}
          />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}

export default compose(withSettlementQuote)(SettlementQuoteDetailsView);
