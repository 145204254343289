/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Companies within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CompanyListItemModel,
  CompanyModel,
  CreateCompanyModel,
  ErrorWithParametersModel,
  UpdateCompanyModel,
} from '../models/index';
import {
    CompanyListItemModelFromJSON,
    CompanyListItemModelToJSON,
    CompanyModelFromJSON,
    CompanyModelToJSON,
    CreateCompanyModelFromJSON,
    CreateCompanyModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
    UpdateCompanyModelFromJSON,
    UpdateCompanyModelToJSON,
} from '../models/index';

export interface CreateCompanyRequest {
    createCompanyModel: CreateCompanyModel;
}

export interface GetCompanyRequest {
    id: string;
}

export interface ListCompaniesRequest {
    offset?: number;
    limit?: number;
}

export interface UpdateCompanyRequest {
    id: string;
    updateCompanyModel: UpdateCompanyModel;
}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI {

    /**
     * Create a new Company
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createCompanyModel === null || requestParameters.createCompanyModel === undefined) {
            throw new runtime.RequiredError('createCompanyModel','Required parameter requestParameters.createCompanyModel was null or undefined when calling createCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["companies:manage"]);
        }

        const response = await this.request({
            path: `/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyModelToJSON(requestParameters.createCompanyModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new Company
     */
    async createCompany(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createCompanyRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch details of a Company
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["companies:view"]);
        }

        const response = await this.request({
            path: `/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyModelFromJSON(jsonValue));
    }

    /**
     * Fetch details of a Company
     */
    async getCompany(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyModel> {
        const response = await this.getCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch list of Companies
     */
    async listCompaniesRaw(requestParameters: ListCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["companies:view"]);
        }

        const response = await this.request({
            path: `/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyListItemModelFromJSON));
    }

    /**
     * Fetch list of Companies
     */
    async listCompanies(requestParameters: ListCompaniesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyListItemModel>> {
        const response = await this.listCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a company
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompany.');
        }

        if (requestParameters.updateCompanyModel === null || requestParameters.updateCompanyModel === undefined) {
            throw new runtime.RequiredError('updateCompanyModel','Required parameter requestParameters.updateCompanyModel was null or undefined when calling updateCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["companies:manage"]);
        }

        const response = await this.request({
            path: `/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyModelToJSON(requestParameters.updateCompanyModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a company
     */
    async updateCompany(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCompanyRaw(requestParameters, initOverrides);
    }

}
