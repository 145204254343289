import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Typography } from "@mui/material";
import { useUpdateStatus } from "api/agreements/status/status";
import { useAgreement } from "common/providers";
import { StatusModel, UpdateStatusModel } from "generated/core/models";
import { useTranslation } from "react-i18next";

interface UpdateAgreementStatusContainerProps {
  status: StatusModel;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  confirmationMessage: string;
  title: string;
}

const UpdateAgreementStatusContainer = ({
  status,
  open,
  onClose,
  onSuccess,
  confirmationMessage,
  title,
}: UpdateAgreementStatusContainerProps) => {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const updateStatus = useUpdateStatus(agreement.id, { onSuccess });

  const onConfirm = async () => {
    const updateStatusModel: UpdateStatusModel = {
      status: status,
    };
    await updateStatus.execute(updateStatusModel);
    await agreement.mutate();
  };

  return (
    <>
      <ConfirmationDialog
        open={open}
        onConfirm={onConfirm}
        onReject={onClose}
        title={title}
        content={<Typography>{confirmationMessage}</Typography>}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />
      <AlertDialog
        title={t("common:error.title")}
        content={updateStatus.error?.message}
        labels={{ close: t("common:alert.close") }}
        onClose={updateStatus.reset}
        open={Boolean(updateStatus.error?.message)}
      />
    </>
  );
};

export default UpdateAgreementStatusContainer;
