import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { InsurerQueryApi } from "generated/core/apis";
import { InsurerListItemModel } from "generated/core/models";
import { keys } from ".";

type UseListInsurers = () => UseQueryReturn<InsurerListItemModel[]>;

export const useListInsurers: UseListInsurers = () => {
  const api = useApi(InsurerQueryApi);

  return useQuery(keys.list(), () => {
    return api.listInsurers();
  });
};
