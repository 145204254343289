import { QueryKey } from "api";

export const all = (): QueryKey => ["assets"];

export const lists = (): QueryKey => [...all(), "list"];

export const list = (params: { query?: string } = {}) =>
  [...lists(), params.query] as const;

export const details = (assetId: number): QueryKey => [
  ...all(),
  "details",
  assetId,
];
