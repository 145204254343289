import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { FinancialPostingCommandApi } from "generated/core/apis";
import { AddFinancialPostingModel } from "generated/core/models";
import { keys } from ".";

export const useAddFinancialPosting = (
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(FinancialPostingCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand<AddFinancialPostingModel, void, ResponseError>(
    (model) =>
      api.addFinancialPosting({
        addFinancialPostingModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.list());
        options.onSuccess?.();
      },
    },
  );
};
