import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useListTemplateMergeFields } from "api/templates/letters/merge-fields/listTemplateMergeFields";
import { LoadableTableHead, Loader, QueryError } from "common/components";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

export default function MergeFieldsDialogContainer(): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("documents");

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const mergeFields = useListTemplateMergeFields();

  return (
    <>
      <Button variant="contained" onClick={openDialog}>
        {t("letter_templates.merge_fields.merge_fields_button")}
      </Button>

      <Dialog open={isOpen} fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              {t("letter_templates.merge_fields.title_label")}
            </Box>
            <Box>
              <IconButton
                aria-label={t("common:close")}
                onClick={closeDialog}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Loader
            ready={Boolean(mergeFields.data ?? mergeFields.error)}
            fallback={
              <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress />
              </Box>
            }
            render={() => {
              if (!mergeFields.data) {
                return <QueryError onRetry={mergeFields.refetch} />;
              }

              return (
                <Table
                  size="small"
                  aria-label={t(
                    "letter_templates.merge_fields.merge_field_list",
                  )}
                  stickyHeader
                >
                  <LoadableTableHead
                    headings={[
                      t("letter_templates.merge_fields.merge_field_heading"),
                      t("letter_templates.merge_fields.example_heading"),
                    ]}
                    loading={mergeFields.isValidating}
                  />
                  <TableBody>
                    {mergeFields.data.map((item) => (
                      <TableRow key={item.tag} hover>
                        <TableCell>{item.tag}</TableCell>
                        <TableCell>{item.example}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
