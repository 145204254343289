import { DescriptionList } from "@ldms/mui-sdk/components";
import { Loader } from "@ldms/mui-sdk/templates";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Stack, Typography } from "@mui/material";
import { useGetPortfolioV2 } from "api/portfolios/getPortfolio";
import { QueryError } from "common/components";
import Surface from "common/components/Surface";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface PortfolioDetailsContainerProps {
  portfolioId: string;
}

export default function PortfolioDetailsContainer({
  portfolioId,
}: PortfolioDetailsContainerProps): ReactElement {
  const { t } = useTranslation("servicing");
  const portfolio = useGetPortfolioV2(portfolioId);

  return (
    <Loader
      ready={Boolean(portfolio.data ?? portfolio.error)}
      render={(): ReactElement => {
        if (portfolio.error || !portfolio.data) {
          return <QueryError onRetry={portfolio.refetch} />;
        }

        return (
          <Surface title={t("companies.portfolio_details.details_label")}>
            <Stack spacing={2}>
              <DescriptionList
                label={t("companies.portfolio_details.details_label")}
              >
                <DescriptionList.Item
                  label={t(
                    "companies.portfolio_details.registered_interest_heading",
                  )}
                >
                  {portfolio.data.isHpiRegistered ? (
                    <Box alignItems="center" display="flex">
                      <CheckCircleIcon fontSize="inherit" color="success" />
                      <Typography ml={0.5}>
                        {t("companies.portfolio_details.active_label")}
                      </Typography>
                    </Box>
                  ) : (
                    <Box alignItems="center" display="flex">
                      <CancelIcon fontSize="inherit" color="error" />
                      <Typography ml={0.5}>
                        {t("companies.portfolio_details.inactive_label")}
                      </Typography>
                    </Box>
                  )}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t("companies.portfolio_details.equifax_insight_label")}
                >
                  {portfolio.data?.equifaxInsightNumber}
                </DescriptionList.Item>

                <DescriptionList.Item
                  label={t(
                    "companies.portfolio_details.ccr_provider_code_label",
                  )}
                >
                  {portfolio.data?.ccrProviderCode}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t(
                    "companies.portfolio_details.asset_depreciation_model_label",
                  )}
                >
                  {portfolio.data.depreciationMinimumValue ||
                  portfolio.data.depreciationPercentage
                    ? "Annualised Straight-line"
                    : "None"}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t(
                    "companies.portfolio_details.depreciation_percentage_label",
                  )}
                >
                  {portfolio.data?.depreciationPercentage}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t(
                    "companies.portfolio_details.depreciation_minimum_value_label",
                  )}
                >
                  {portfolio.data?.depreciationMinimumValue}
                </DescriptionList.Item>
              </DescriptionList>
              <DescriptionList
                label={t("companies.portfolio_details.bank_account_heading")}
              >
                <DescriptionList.Item
                  label={t("companies.portfolio_details.bank_name_heading")}
                >
                  {portfolio.data?.bankAccount.name}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t("companies.portfolio_details.sun_heading")}
                >
                  {portfolio.data?.bankAccount.serviceUserNumber}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t("companies.portfolio_details.sort_code_heading")}
                >
                  {portfolio.data?.bankAccount.sortCode}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t(
                    "companies.portfolio_details.account_number_heading",
                  )}
                >
                  {portfolio.data?.bankAccount.accountNumber}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t("companies.portfolio_details.iban_heading")}
                >
                  {portfolio.data?.bankAccount.iban}
                </DescriptionList.Item>
                <DescriptionList.Item
                  label={t("companies.portfolio_details.bic_heading")}
                >
                  {portfolio.data?.bankAccount.bic}
                </DescriptionList.Item>
              </DescriptionList>
            </Stack>
          </Surface>
        );
      }}
    />
  );
}
