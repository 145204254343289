import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { keys } from "api/agreements";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";

export const useWriteOff = (
  agreementId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand<void, void, ResponseError>(
    async () =>
      api.writeOff({
        agreementId,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.all());
        options.onSuccess?.();
      },
    },
  );
};
