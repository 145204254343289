import { withAppAccess } from "common/security/withAppAccess";
import { compose } from "lodash/fp";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteAdapter } from "sdk/router/RouteAdapter";
import { withTranslationLoader } from "sdk/views";
import { useShellLayout } from "shell";
import DocumentsLayout from "./layouts/DocumentsLayout";
import SettingsLayout from "./layouts/SettingsLayout";
import Navigation from "./navigation";
import { TemplateTypeEnum } from "./types";
import BatchesView from "./views/BatchesView";
import LetterTemplatesView from "./views/LetterTemplatesView";
import LettersToEmailView from "./views/LettersToEmailView";
import SystemLettersView from "./views/SystemLettersView";
import TemplatesView from "./views/TemplatesView";
import VersionsView from "./views/VersionsView";

const App = () => {
  useShellLayout({
    navigation: <Navigation />,
  });

  return (
    <Routes>
      <Route path="/*" element={<DocumentsLayout />}>
        <Route index element={<Navigate to="batches" replace />} />
        <Route path="batches" element={<BatchesView />} />
        <Route path="letters-to-email" element={<LettersToEmailView />} />
      </Route>

      <Route path="settings" element={<SettingsLayout />}>
        <Route index element={<Navigate to="templates" replace />} />
        <Route path="templates" element={<TemplatesView />} />
        <Route
          path="templates/:id"
          element={
            <RouteAdapter
              render={(route) => (
                <VersionsView
                  templateId={route.params.id}
                  type={TemplateTypeEnum.System}
                />
              )}
            />
          }
        />
        <Route path="letter-templates" element={<LetterTemplatesView />} />
        <Route
          path="letter-templates/:id"
          element={
            <RouteAdapter
              render={(route) => (
                <VersionsView
                  templateId={route.params.id}
                  type={TemplateTypeEnum.Letters}
                />
              )}
            />
          }
        />
        <Route path="system-letters" element={<SystemLettersView />} />
      </Route>
    </Routes>
  );
};

export default compose(
  withTranslationLoader("documents"),
  withAppAccess("documents"),
)(App);
