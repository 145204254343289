import { ReceiptsFilterByEnum } from "generated/core/apis";
export enum ReportDialog {
  "Portfolio",
  "AgreementSetUp",
  "AgreementStatus",
  "FeeAmountDue",
  "Receipts",
  "InstalmentAmountsDue",
  "AppliedChargesAndDiscounts",
  "Cais",
  "Asset",
  "AgreementInsurance",
  "AgreementCashFlow",
  "DirectDebitPaymentStatus",
  "PortfolioCashFlowSummary",
  "PortfolioCashFlow",
  "SettledAgreement",
  "AgreementSettlementQuotes",
  "EndOfTerm",
  "CCR",
  "EquifaxInsight",
  "ManufacturerSubsidyInvoice",
}

export type PortfolioReportDialogFormValues = {
  portfolio: string;
};

export type PortfolioDateReportDialogFormValues = {
  portfolio: string;
  filterBy: ReceiptsFilterByEnum;
  fromDate: string;
  toDate: string;
};
