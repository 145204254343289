import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import NoResults from "common/components/NoResults";
import SortableTableHead from "common/components/SortableTableHead";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export type Direction = "asc" | "desc";

export enum SortBy {
  AgreementId = "id",
  ArrearsBalance = "arrearsBalance",
  CreditLine = "creditLineName",
  CurrentBalance = "currentBalance",
  NumberOfAssets = "numberOfAssets",
  NumberOfLiveAssets = "numberOfLiveAssets",
  Opened = "opened",
  Product = "product",
  Number = "number",
  Status = "status",
  Term = "term",
}

interface AgreementListProps {
  data: {
    id: number;
    number?: string;
    href: string;
    status: string;
    product?: string;
    term?: number;
    numberOfAssets: number;
    currentBalance: string;
    arrearsBalance: string;
  }[];
  loading: boolean;
  numberOfAssets?: number;
  onSort(key: SortBy): void;
  sortDirection: Direction;
  sortBy?: SortBy;
  totalArrears?: string;
  totalBalance?: string;
}

export default function AgreementList({
  data,
  loading,
  numberOfAssets,
  onSort,
  sortDirection,
  sortBy,
  totalArrears,
  totalBalance,
}: AgreementListProps): ReactElement {
  const { t } = useTranslation(["clients", "common"]);
  const { formatAmount, formatNumber } = useFormat();
  const makeAgreementsListLabel = (key: string): string =>
    t(`agreements.list.${key}`);

  const tableHeaders: {
    label: string;
    key: SortBy | string;
    sortable?: boolean;
  }[] = [
    {
      label: makeAgreementsListLabel("agreement_number_heading"),
      key: SortBy.Number,
    },
    { label: makeAgreementsListLabel("status_heading"), key: SortBy.Status },
    { label: makeAgreementsListLabel("product_heading"), key: SortBy.Product },
    { label: makeAgreementsListLabel("term_heading"), key: SortBy.Term },
    {
      label: makeAgreementsListLabel("assets_heading"),
      key: SortBy.NumberOfAssets,
    },
    {
      label: makeAgreementsListLabel("current_balance_heading"),
      key: SortBy.CurrentBalance,
    },
    {
      label: makeAgreementsListLabel("arrears_heading"),
      key: SortBy.ArrearsBalance,
    },
  ];

  return (
    <>
      <Table
        size="small"
        aria-label={makeAgreementsListLabel("label")}
        data-testid="agreementList.table"
        stickyHeader
      >
        <SortableTableHead
          headings={tableHeaders}
          sortBy={sortBy}
          direction={sortDirection}
          onSort={onSort}
          loading={loading}
        />
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id} hover>
              <TableCell padding="none">
                <ListItemButton component={RouterLink} dense to={item.href}>
                  <ListItemText
                    primary={item.number}
                    primaryTypographyProps={{ color: "primary" }}
                  />
                </ListItemButton>
              </TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{item.product}</TableCell>
              <TableCell>{item.term}</TableCell>
              <TableCell>{formatNumber(item.numberOfAssets)}</TableCell>
              <TableCell>{formatAmount(item.currentBalance)}</TableCell>
              <TableCell>{formatAmount(item.arrearsBalance)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter
          sx={(theme): SystemStyleObject => ({
            "& > tr": {
              "& > td, & > th": {
                height: "35px",
                backgroundColor: theme.palette.background.paper,
                borderTop: `1px solid ${theme.palette.divider}`,
                position: "sticky",
                bottom: 0,
              },
            },
          })}
        >
          <TableRow>
            <TableCell colSpan={4} align="right" component="th" scope="row">
              {makeAgreementsListLabel("totals")}
            </TableCell>
            <TableCell>{formatNumber(numberOfAssets)}</TableCell>
            <TableCell>{formatAmount(totalBalance)}</TableCell>
            <TableCell>{formatAmount(totalArrears)}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      {data.length === 0 && <NoResults />}
    </>
  );
}
