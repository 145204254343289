import {
  EditOnboardingView,
  FeesView,
  FinancialProductDetailsView,
  FinancialProductsView,
  OnboardCustomerView,
  OnboardExistingCustomerView,
  OnboardingReviewView,
  OnboardingView,
} from "apps/onboarding/views";
import { useEnvironment } from "common/providers/EnvironmentProvider";
import { withAppAccess } from "common/security/withAppAccess";
import { compose } from "lodash/fp";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteAdapter } from "sdk/router/RouteAdapter";
import { withTranslationLoader } from "sdk/views";
import { useShellLayout } from "shell";
import SettingsLayout from "./layouts/SettingsLayout";
import Navigation from "./navigation";
import AddFinancialProductView from "./views/AddFinancialProductView/AddFinancialProductView";

const App = () => {
  useShellLayout({
    navigation: <Navigation />,
  });

  const environment = useEnvironment();

  return (
    <Routes>
      <Route index element={<OnboardingView />} />
      <Route path="create" element={<OnboardCustomerView />} />
      <Route
        path="customers/:id/create"
        element={
          <RouteAdapter
            params={["id"]}
            render={(route) => (
              <OnboardExistingCustomerView
                customerId={Number(route.params.id)}
                initialActiveStep="/agreement-details"
              />
            )}
          />
        }
      />
      <Route
        path="review/:id"
        element={
          <RouteAdapter
            params={["id"]}
            render={(route) => (
              <OnboardingReviewView onboardingId={Number(route.params.id)} />
            )}
          />
        }
      />
      <Route
        path=":id/edit"
        element={
          <RouteAdapter
            params={["id"]}
            render={(route) => (
              <EditOnboardingView onboardingId={Number(route.params.id)} />
            )}
          />
        }
      />
      <Route path="settings" element={<SettingsLayout />}>
        <Route index element={<Navigate to="fees" replace />} />
        <Route path="fees" element={<FeesView />} />
        <Route path="financial-products">
          <Route index element={<FinancialProductsView />} />
          {environment.isFeatureEnabled("dev") && (
            <Route
              path="add-financial-product"
              element={<AddFinancialProductView />}
            />
          )}
          <Route
            path=":financialProductId"
            element={
              <RouteAdapter
                params={["financialProductId"]}
                render={({ params: { financialProductId } }) => (
                  <FinancialProductDetailsView
                    financialProductId={Number(financialProductId)}
                  />
                )}
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default compose(
  withTranslationLoader(["customers", "onboardings"]),
  withAppAccess("onboarding"),
)(App);
