/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Portfolio Bank Accounts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatePortfolioBankAccountModel,
  ErrorWithParametersModel,
  PortfolioBankAccountDetailsModel,
  PortfolioBankAccountListItemModel,
  UpdatePortfolioBankAccountModel,
} from '../models/index';
import {
    CreatePortfolioBankAccountModelFromJSON,
    CreatePortfolioBankAccountModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
    PortfolioBankAccountDetailsModelFromJSON,
    PortfolioBankAccountDetailsModelToJSON,
    PortfolioBankAccountListItemModelFromJSON,
    PortfolioBankAccountListItemModelToJSON,
    UpdatePortfolioBankAccountModelFromJSON,
    UpdatePortfolioBankAccountModelToJSON,
} from '../models/index';

export interface CreatePortfolioBankAccountRequest {
    createPortfolioBankAccountModel: CreatePortfolioBankAccountModel;
}

export interface GetPortfolioBankAccountDetailsRequest {
    id: string;
}

export interface ListPortfolioBankAccountsRequest {
    offset?: number;
    limit?: number;
}

export interface UpdatePortfolioBankAccountRequest {
    id: string;
    updatePortfolioBankAccountModel: UpdatePortfolioBankAccountModel;
}

/**
 * 
 */
export class PortfolioBankAccountsApi extends runtime.BaseAPI {

    /**
     * Create a new Portfolio Bank Account
     */
    async createPortfolioBankAccountRaw(requestParameters: CreatePortfolioBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createPortfolioBankAccountModel === null || requestParameters.createPortfolioBankAccountModel === undefined) {
            throw new runtime.RequiredError('createPortfolioBankAccountModel','Required parameter requestParameters.createPortfolioBankAccountModel was null or undefined when calling createPortfolioBankAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolio-bank-accounts:manage"]);
        }

        const response = await this.request({
            path: `/portfolio-bank-accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePortfolioBankAccountModelToJSON(requestParameters.createPortfolioBankAccountModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new Portfolio Bank Account
     */
    async createPortfolioBankAccount(requestParameters: CreatePortfolioBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPortfolioBankAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch details of a Portfolio Bank Account
     */
    async getPortfolioBankAccountDetailsRaw(requestParameters: GetPortfolioBankAccountDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PortfolioBankAccountDetailsModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPortfolioBankAccountDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolio-bank-accounts:view"]);
        }

        const response = await this.request({
            path: `/portfolio-bank-accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioBankAccountDetailsModelFromJSON(jsonValue));
    }

    /**
     * Fetch details of a Portfolio Bank Account
     */
    async getPortfolioBankAccountDetails(requestParameters: GetPortfolioBankAccountDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PortfolioBankAccountDetailsModel> {
        const response = await this.getPortfolioBankAccountDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch list of Portfolio Bank Accounts
     */
    async listPortfolioBankAccountsRaw(requestParameters: ListPortfolioBankAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PortfolioBankAccountListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolio-bank-accounts:view"]);
        }

        const response = await this.request({
            path: `/portfolio-bank-accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioBankAccountListItemModelFromJSON));
    }

    /**
     * Fetch list of Portfolio Bank Accounts
     */
    async listPortfolioBankAccounts(requestParameters: ListPortfolioBankAccountsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PortfolioBankAccountListItemModel>> {
        const response = await this.listPortfolioBankAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a portfolio bank account
     */
    async updatePortfolioBankAccountRaw(requestParameters: UpdatePortfolioBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePortfolioBankAccount.');
        }

        if (requestParameters.updatePortfolioBankAccountModel === null || requestParameters.updatePortfolioBankAccountModel === undefined) {
            throw new runtime.RequiredError('updatePortfolioBankAccountModel','Required parameter requestParameters.updatePortfolioBankAccountModel was null or undefined when calling updatePortfolioBankAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolio-bank-accounts:manage"]);
        }

        const response = await this.request({
            path: `/portfolio-bank-accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePortfolioBankAccountModelToJSON(requestParameters.updatePortfolioBankAccountModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a portfolio bank account
     */
    async updatePortfolioBankAccount(requestParameters: UpdatePortfolioBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePortfolioBankAccountRaw(requestParameters, initOverrides);
    }

}
