import {
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useListPortfolioBankAccountsV2 } from "api/portfolio-bank-accounts/listPortfolioBankAccounts";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
} from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export default function ListPortfolioBankAccountsContainer(): ReactElement {
  const { t } = useTranslation("servicing");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const portfolioBankAccounts = useListPortfolioBankAccountsV2({
    pageSize: rowsPerPage,
  });
  const appConfig = useAppConfiguration();

  const tableHeaders: string[] = [
    t("bank_accounts.list_bank_accounts.bank_account_heading"),
    t("bank_accounts.list_bank_accounts.sortcode_accountnumber_heading"),
    t("bank_accounts.list_bank_accounts.iban_bic_heading"),
  ];

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => portfolioBankAccounts.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Loader
      ready={Boolean(portfolioBankAccounts.data ?? portfolioBankAccounts.error)}
      render={(): ReactElement => {
        if (portfolioBankAccounts.error || !portfolioBankAccounts.data) {
          return <QueryError onRetry={portfolioBankAccounts.refetch} />;
        }
        return (
          <ListLayout
            pagination={{
              onPageChange: onChangePage,
              onRowsPerPageChange: onChangeRowsPerPage,
              rowsPerPage: rowsPerPage,
              page: portfolioBankAccounts.paging.page,
              count: portfolioBankAccounts.data.paging.total || 0,
            }}
          >
            <Table
              aria-label={t("bank_accounts.table_name_label")}
              size="small"
              stickyHeader
            >
              <LoadableTableHead
                headings={tableHeaders}
                loading={portfolioBankAccounts.isValidating}
              />

              <TableBody>
                {portfolioBankAccounts.data.results.map(
                  (portfolioBankAccount) => (
                    <TableRow key={portfolioBankAccount.systemId} hover>
                      <TableCell
                        padding="none"
                        sx={{ width: "500px" }}
                        component="th"
                        scope="row"
                      >
                        <ListItemButton
                          dense
                          component={RouterLink}
                          to={`${appConfig.appRoutes.servicing}/settings/bank-accounts/${portfolioBankAccount.systemId}`}
                        >
                          <ListItemText
                            primaryTypographyProps={{ color: "primary" }}
                            primary={portfolioBankAccount.name}
                            secondary={portfolioBankAccount.serviceUserNumber}
                          />
                        </ListItemButton>
                      </TableCell>
                      <TableCell>
                        <Typography>{portfolioBankAccount.sortCode}</Typography>
                        <Typography>
                          {portfolioBankAccount.accountNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{portfolioBankAccount.iban}</Typography>
                        <Typography>{portfolioBankAccount.bic}</Typography>
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
            {portfolioBankAccounts.data.results.length === 0 && <NoResults />}
          </ListLayout>
        );
      }}
    ></Loader>
  );
}
