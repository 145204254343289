/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SettlementTypeModel = {
    DiscountedFutureReceivables: 'Discounted Future Receivables'
} as const;
export type SettlementTypeModel = typeof SettlementTypeModel[keyof typeof SettlementTypeModel];


export function SettlementTypeModelFromJSON(json: any): SettlementTypeModel {
    return SettlementTypeModelFromJSONTyped(json, false);
}

export function SettlementTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementTypeModel {
    return json as SettlementTypeModel;
}

export function SettlementTypeModelToJSON(value?: SettlementTypeModel | null): any {
    return value as any;
}

