/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountTypeModel } from './DiscountTypeModel';
import {
    DiscountTypeModelFromJSON,
    DiscountTypeModelFromJSONTyped,
    DiscountTypeModelToJSON,
} from './DiscountTypeModel';

/**
 * Create a settlement quote to an agreement
 * @export
 * @interface CreateSettlementQuoteModel
 */
export interface CreateSettlementQuoteModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreateSettlementQuoteModel
     */
    discountAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreateSettlementQuoteModel
     */
    discountPercentage?: string;
    /**
     * 
     * @type {DiscountTypeModel}
     * @memberof CreateSettlementQuoteModel
     */
    discountType?: DiscountTypeModel;
}

/**
 * Check if a given object implements the CreateSettlementQuoteModel interface.
 */
export function instanceOfCreateSettlementQuoteModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateSettlementQuoteModelFromJSON(json: any): CreateSettlementQuoteModel {
    return CreateSettlementQuoteModelFromJSONTyped(json, false);
}

export function CreateSettlementQuoteModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSettlementQuoteModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountAmount': !exists(json, 'discountAmount') ? undefined : json['discountAmount'],
        'discountPercentage': !exists(json, 'discountPercentage') ? undefined : json['discountPercentage'],
        'discountType': !exists(json, 'discountType') ? undefined : DiscountTypeModelFromJSON(json['discountType']),
    };
}

export function CreateSettlementQuoteModelToJSON(value?: CreateSettlementQuoteModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountAmount': value.discountAmount,
        'discountPercentage': value.discountPercentage,
        'discountType': DiscountTypeModelToJSON(value.discountType),
    };
}

