import useQuery, {
  UseQueryOptions,
  UseQueryReturn,
} from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { ListTemplatesRequest, TemplateApi } from "generated/documents/apis";
import { TemplateListItemModel } from "generated/documents/models/TemplateListItemModel";
import { list } from "./keys";

type UseListTemplates = (
  params?: ListTemplatesRequest,
  options?: UseQueryOptions,
) => UseQueryReturn<TemplateListItemModel[]>;

export const useListTemplates: UseListTemplates = (
  params = {},
  options = {},
) => {
  const api = useApi(TemplateApi);
  const key = list(params);

  return useQuery(
    key,
    () => {
      return api.listTemplates(params);
    },
    options,
  );
};
