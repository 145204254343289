import { QueryKey } from "api/types";

export const all = (): QueryKey => ["charges"];

export const list = (): QueryKey => [...all(), "list"];

export const details = (): QueryKey => [...all(), "details"];

export const detail = (code: string): QueryKey => [
  ...details(),
  "detail",
  code,
];
