import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAddPortfolioBankAccount } from "api/portfolio-bank-accounts";
import { SubmissionFileType } from "apps/admin/types";
import { ControlledTextField, Loader } from "common/components";
import { useYupResolver } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { omit } from "lodash";
import { ReactElement } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

export interface AddPortfolioBankAccountFieldValues {
  name: string;
  serviceUserNumber: string;
  sortCode: string;
  accountNumber: string;
  iban: string;
  bic: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  postcode: string;
  submissionFileType: string;
}

const labels = {
  name: "bank_accounts.add_bank_account.name_label",
  serviceUserNumber: "bank_accounts.add_bank_account.service_user_number_label",
  accountNumber: "bank_accounts.add_bank_account.account_number_label",
  sortCode: "bank_accounts.add_bank_account.sort_code_label",
  iban: "bank_accounts.add_bank_account.iban_label",
  bic: "bank_accounts.add_bank_account.bic_label",
  addressLine1: "bank_accounts.add_bank_account.address_line_1_label",
  addressLine2: "bank_accounts.add_bank_account.address_line_2_label",
  addressLine3: "bank_accounts.add_bank_account.address_line_3_label",
  addressLine4: "bank_accounts.add_bank_account.address_line_4_label",
  postcode: "bank_accounts.add_bank_account.postcode_label",
  submissionFileType:
    "bank_accounts.add_bank_account.submission_file_type_label",
  isRequiredValidation: "common:validation.is_required",
  bankDetailsValidation: "bank_accounts.validation.bank_details_required",
};

const useAddPortfolioBankAccountResolver =
  (): Resolver<AddPortfolioBankAccountFieldValues> => {
    const { t } = useTranslation("servicing");

    const validateDependentField = (
      value: string | undefined,
      length: number,
    ): boolean => {
      if (length) {
        return Boolean(value);
      }
      return true;
    };

    return useYupResolver<AddPortfolioBankAccountFieldValues>((yup) =>
      yup.object().shape({
        name: yup
          .string()
          .isRequired(t(labels.name))
          .maxCharacters(50, t(labels.name)),
        serviceUserNumber: yup
          .string()
          .isRequired(t(labels.serviceUserNumber))
          .maxCharacters(30, t(labels.serviceUserNumber)),
        sortCode: yup
          .string()
          .test({
            name: "isDependentOnAccountNumber",
            message: t(labels.isRequiredValidation, {
              label: t(labels.sortCode),
            }),
            test: (value, testContext) =>
              validateDependentField(value, testContext.parent.accountNumber),
          })
          .oneOfBankDetailsRequired("sortCodeRequired")
          .maxCharacters(10, t(labels.sortCode)),
        accountNumber: yup
          .string()
          .test({
            name: "isDependentOnSortCode",
            message: t(labels.isRequiredValidation, {
              label: t(labels.accountNumber),
            }),
            test: (value, testContext) =>
              validateDependentField(value, testContext.parent.sortCode),
          })
          .oneOfBankDetailsRequired("accountNumberRequired")
          .maxCharacters(10, t(labels.accountNumber)),
        iban: yup
          .string()
          .test({
            name: "isDependentOnBic",
            message: t(labels.isRequiredValidation, {
              label: t(labels.iban),
            }),
            test: (value, testContext) =>
              validateDependentField(value, testContext.parent.bic),
          })
          .oneOfBankDetailsRequired("ibanRequired")
          .maxCharacters(50, t(labels.iban))
          .validateIban(),
        bic: yup
          .string()
          .test({
            name: "isDependentOnIban",
            message: t(labels.isRequiredValidation, {
              label: t(labels.bic),
            }),
            test: (value, testContext) =>
              validateDependentField(value, testContext.parent.iban),
          })
          .oneOfBankDetailsRequired("bicRequired")
          .maxCharacters(20, t(labels.bic))
          .validateBic(),
        addressLine1: yup
          .string()
          .isRequired(t(labels.addressLine1))
          .maxCharacters(50, t(labels.addressLine1)),
        addressLine2: yup.string().maxCharacters(50, t(labels.addressLine2)),
        addressLine3: yup.string().maxCharacters(50, t(labels.addressLine3)),
        addressLine4: yup.string().maxCharacters(50, t(labels.addressLine4)),
        postcode: yup
          .string()
          .isRequired(t(labels.postcode))
          .maxCharacters(10, t(labels.postcode)),
        submissionFileType: yup
          .string()
          .isRequired(t(labels.submissionFileType)),
      }),
    );
  };

export default function AddPortfolioBankAccountContainer(): ReactElement {
  const { ready, t } = useTranslation("servicing");
  const resolver = useAddPortfolioBankAccountResolver();
  const appConfig = useAppConfiguration();
  const navigate = useNavigate();
  const { ...form } = useForm<AddPortfolioBankAccountFieldValues>({
    resolver,
    defaultValues: {
      submissionFileType: "",
    },
  });

  const onSuccess = () => {
    navigate("..");
  };

  const addPortfolioBankAccount = useAddPortfolioBankAccount({ onSuccess });

  const responseError = new Map([
    [
      "service_user_number_exists",
      t("bank_accounts.validation.duplicate_service_user_number"),
    ],
  ]);

  const onSubmit = async (
    data: AddPortfolioBankAccountFieldValues,
  ): Promise<void> => {
    await addPortfolioBankAccount.execute({
      ...omit(data, "submissionFileType"),
      bic: data.bic === "" ? undefined : data.bic,
      iban: data.iban === "" ? undefined : data.iban,
      directDebitSubmissionFileType:
        data.submissionFileType as SubmissionFileType,
    });
  };

  const { isSubmitting } = form.formState;

  const renderAddPortfolioBankAccountForm = (): ReactElement => {
    return (
      <form
        id="add-bank-account-form"
        method="POST"
        aria-label={t("bank_accounts.add_bank_account.title_label")}
        noValidate
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Container maxWidth="sm">
          <Paper>
            <Stack gap={2} padding={2}>
              <Grid container spacing={2} rowSpacing={0.5}>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("name")}
                    label={t(labels.name)}
                    error={Boolean(form.formState.errors.name)}
                    helperText={form.formState.errors.name?.message}
                    required
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("serviceUserNumber")}
                    label={t(labels.serviceUserNumber)}
                    error={Boolean(
                      form.formState.errors.serviceUserNumber?.message,
                    )}
                    helperText={
                      form.formState.errors.serviceUserNumber?.message
                    }
                    required
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("sortCode")}
                    label={t(labels.sortCode)}
                    error={Boolean(form.formState.errors.sortCode)}
                    helperText={form.formState.errors.sortCode?.message}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("accountNumber")}
                    label={t(labels.accountNumber)}
                    error={Boolean(
                      form.formState.errors.accountNumber?.message,
                    )}
                    helperText={form.formState.errors.accountNumber?.message}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("iban")}
                    label={t(labels.iban)}
                    error={Boolean(form.formState.errors.iban)}
                    helperText={form.formState.errors.iban?.message}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("bic")}
                    label={t(labels.bic)}
                    error={Boolean(form.formState.errors.bic)}
                    helperText={form.formState.errors.bic?.message}
                  />
                </Grid>

                <Grid item sm={6}>
                  <ControlledTextField
                    required
                    select
                    id="submissionFileType"
                    name="submissionFileType"
                    label={t(labels.submissionFileType)}
                    helperText={
                      form.formState.errors.submissionFileType?.message
                    }
                    error={form.formState.errors.submissionFileType?.message}
                    SelectProps={{ displayEmpty: true }}
                    control={form.control}
                  >
                    <MenuItem value="">
                      <i>{t("common:please_select")}</i>
                    </MenuItem>

                    {Object.values(SubmissionFileType).map(
                      (submissionFileType) => (
                        <MenuItem
                          key={String(submissionFileType)}
                          value={String(submissionFileType)}
                        >
                          {submissionFileType}
                        </MenuItem>
                      ),
                    )}
                  </ControlledTextField>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <Divider flexItem variant="fullWidth" />
              </Grid>
              <Grid container spacing={2} rowSpacing={0.5}>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("addressLine1")}
                    label={t(labels.addressLine1)}
                    error={Boolean(form.formState.errors.addressLine1)}
                    helperText={form.formState.errors.addressLine1?.message}
                    required
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("addressLine2")}
                    label={t(labels.addressLine2)}
                    error={Boolean(form.formState.errors.addressLine2)}
                    helperText={form.formState.errors.addressLine2?.message}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    {...form.register("addressLine3")}
                    label={t(labels.addressLine3)}
                    error={Boolean(form.formState.errors.addressLine3)}
                    helperText={form.formState.errors.addressLine3?.message}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    label={t(labels.addressLine4)}
                    {...form.register("addressLine4")}
                    error={Boolean(form.formState.errors.addressLine4)}
                    helperText={form.formState.errors.addressLine4?.message}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    label={t(labels.postcode)}
                    {...form.register("postcode")}
                    error={Boolean(form.formState.errors.postcode)}
                    helperText={form.formState.errors.postcode?.message}
                    required
                  />
                </Grid>
              </Grid>

              {addPortfolioBankAccount.error && (
                <Box>
                  <Typography color="error">
                    {responseError.get(addPortfolioBankAccount.error.code) ??
                      t("common:error.default")}
                  </Typography>
                </Box>
              )}

              <Box display="flex" justifyContent="flex-end" gap={1}>
                <Button
                  role="link"
                  key="common:cancel"
                  component={RouterLink}
                  to={`${appConfig.appRoutes.servicing}/settings/bank-accounts`}
                  color="primary"
                >
                  {t("common:cancel")}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("bank_accounts.add_bank_account.submit_button")}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Container>
      </form>
    );
  };

  return <Loader ready={ready} render={renderAddPortfolioBankAccountForm} />;
}
