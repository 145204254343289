/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChequeTransactionModel
 */
export interface ChequeTransactionModel {
    /**
     * The payment type
     * @type {string}
     * @memberof ChequeTransactionModel
     */
    type: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof ChequeTransactionModel
     */
    bankDate: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ChequeTransactionModel
     */
    amountReceived?: string;
    /**
     * The bank ID
     * @type {number}
     * @memberof ChequeTransactionModel
     */
    bankId: number;
    /**
     * A general comment
     * @type {string}
     * @memberof ChequeTransactionModel
     */
    comment?: string;
    /**
     * The Cheque Number
     * @type {string}
     * @memberof ChequeTransactionModel
     */
    chequeNumber: string;
    /**
     * The Sort Code
     * @type {string}
     * @memberof ChequeTransactionModel
     */
    sortCode: string;
    /**
     * The Account Number
     * @type {string}
     * @memberof ChequeTransactionModel
     */
    accountNumber: string;
    /**
     * The Account Name
     * @type {string}
     * @memberof ChequeTransactionModel
     */
    accountName?: string;
    /**
     * The Bank Name
     * @type {string}
     * @memberof ChequeTransactionModel
     */
    bankName?: string;
}

/**
 * Check if a given object implements the ChequeTransactionModel interface.
 */
export function instanceOfChequeTransactionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "bankDate" in value;
    isInstance = isInstance && "bankId" in value;
    isInstance = isInstance && "chequeNumber" in value;
    isInstance = isInstance && "sortCode" in value;
    isInstance = isInstance && "accountNumber" in value;

    return isInstance;
}

export function ChequeTransactionModelFromJSON(json: any): ChequeTransactionModel {
    return ChequeTransactionModelFromJSONTyped(json, false);
}

export function ChequeTransactionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChequeTransactionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'bankDate': (new Date(json['bankDate'])),
        'amountReceived': !exists(json, 'amountReceived') ? undefined : json['amountReceived'],
        'bankId': json['bankId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'chequeNumber': json['chequeNumber'],
        'sortCode': json['sortCode'],
        'accountNumber': json['accountNumber'],
        'accountName': !exists(json, 'accountName') ? undefined : json['accountName'],
        'bankName': !exists(json, 'bankName') ? undefined : json['bankName'],
    };
}

export function ChequeTransactionModelToJSON(value?: ChequeTransactionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'bankDate': (value.bankDate.toISOString().substring(0,10)),
        'amountReceived': value.amountReceived,
        'bankId': value.bankId,
        'comment': value.comment,
        'chequeNumber': value.chequeNumber,
        'sortCode': value.sortCode,
        'accountNumber': value.accountNumber,
        'accountName': value.accountName,
        'bankName': value.bankName,
    };
}

