import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { AgreementAttachmentQueryApi } from "generated/core/apis/AgreementAttachmentQueryApi";
import { FileTypeModel } from "generated/core/models";
import { PreviewDocumentModel } from "generated/core/models/PreviewDocumentModel";

export const usePreviewDocument = (
  agreementId: number,
  fileType?: FileTypeModel,
  options: UseCommandOptions<Blob, ResponseError> = {},
) => {
  const api = useApi(AgreementAttachmentQueryApi);

  return useCommand<PreviewDocumentModel, Blob, ResponseError>(
    (model) =>
      api.previewDocument({
        agreementId,
        fileType,
        previewDocumentModel: model,
      }),
    options,
  );
};
