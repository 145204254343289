import { Form } from "@ldms/mui-sdk/forms";
import { Loader } from "@ldms/mui-sdk/templates";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useListAgreements } from "api/agreements";
import { useListAgreementAssets } from "api/agreements/assets";
import { useAddFinancialPosting } from "api/financial-postings/addFinancialPosting";
import { useListFinancialPostingStages } from "api/financial-postings/stages/listFinancialPostingStages";
import AddFinancialPostingFormControls, {
  AddFinancialPostingFieldValues,
  AssetFinancialPosting,
} from "common/components/AddFinancialPostingFormControls";
import { useYupResolver } from "common/hooks";
import {
  AddFinancialPostingModel,
  AgreementAssetListItemModel,
} from "generated/core/models";
import { ReactElement, useState } from "react";
import { Controller, Resolver, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

interface AddFinancialPostingFormParametersProps {
  props: UseFormReturn<AddFinancialPostingFieldValues>;
  agreementId: number;
}

const useAddFinancialPostingResolver =
  (): Resolver<AddFinancialPostingFieldValues> => {
    const { t } = useTranslation("finance");
    const addAmountLabel = t("financial_postings.add.amount_label");

    return useYupResolver<AddFinancialPostingFieldValues>((yup) =>
      yup.object().shape({
        agreement: yup.object().shape({
          label: yup
            .string()
            .isRequired(t("financial_postings.add.agreement_number_label")),
        }),
        accountName: yup
          .string()
          .isRequired(t("financial_postings.add.account_label")),
        amount: yup
          .number(addAmountLabel)
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .isRequired(addAmountLabel)
          .greaterThanAmount(0, addAmountLabel),
        financialDate: yup
          .date()
          .localDate()
          .isRequired(t("financial_postings.add.date_label"))
          .isNotFuture(t("financial_postings.add.date_label")),
        stageGroup: yup
          .string()
          .isRequired(t("financial_postings.add.stage_group_label")),
        stageCode: yup
          .string()
          .isRequired(t("financial_postings.add.stage_name_label")),
      }),
    );
  };

function AddFinancialPostingFormParameters({
  props,
  agreementId,
}: AddFinancialPostingFormParametersProps): ReactElement {
  const listFinancialPostingStages = useListFinancialPostingStages({
    agreementId: agreementId,
  });
  const agreementAssets = useListAgreementAssets(agreementId);

  const getSettledAssets = agreementAssets.data?.filter(
    (s) => s.status === "Settled",
  );

  const displaySettledAssets = getSettledAssets?.map(
    (asset: AgreementAssetListItemModel): AssetFinancialPosting => {
      return {
        id: asset.id,
        display: `${asset?.type} - ${
          asset?.serialNumber ? asset.serialNumber : asset.registrationNumber
        }`,
      };
    },
  );

  return (
    <>
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(
          listFinancialPostingStages.data ??
            agreementAssets.data ??
            listFinancialPostingStages.error ??
            agreementAssets.error,
        )}
        render={(): ReactElement => {
          return (
            <AddFinancialPostingFormControls
              props={props}
              settledAssets={displaySettledAssets}
              financialPostings={listFinancialPostingStages.data}
            />
          );
        }}
      />
    </>
  );
}

export default function AddFinancialPostingContainer(): ReactElement {
  const { t } = useTranslation("finance");
  const agreementNumber = "financial_postings.add.agreement_number_label";
  const resolver = useAddFinancialPostingResolver();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const agreements = useListAgreements({
    params: { agreementNumber: query, active: true },
  });
  const options =
    agreements.data?.results.map((option) => ({
      label: option.number,
      value: String(option.id),
    })) ?? [];

  const agreementId = options
    .filter((number) => number.label === query)
    .find((id) => id.value)?.value;

  const onSuccess = async (): Promise<void> => {
    navigate("../filter-financial-postings");
  };

  const addFinancialPosting = useAddFinancialPosting({
    onSuccess,
  });

  const responseError = new Map([
    [
      "invalid_financial_posting_date",
      t("financial_postings.add.invalid_financial_posting_date_error_message"),
    ],
    [
      "invalid_financial_posting_before_start_date",
      t(
        "financial_postings.add.invalid_financial_posting_before_start_date_error_message",
      ),
    ],
  ]);

  const onSubmit = async (
    data: AddFinancialPostingFieldValues,
  ): Promise<void> => {
    const addFinancialPostingModel: AddFinancialPostingModel = {
      ...data,
      agreementId: Number(data.agreement?.value),
      stageCode: data.stageCode,
      assetId: data.assetId === 0 ? undefined : data.assetId,
      amount: data.amount.toFixed(2),
      financialDate: data.financialDate,
    };

    await addFinancialPosting.execute(addFinancialPostingModel);
  };

  return (
    <>
      <Form
        label={t("financial_postings.add.form_title")}
        onSubmit={onSubmit}
        resolver={resolver}
        options={{ shouldUnregister: true }}
        defaultValues={{
          agreement: undefined,
          accountName: "",
          stageGroup: "",
          stageCode: "",
          assetId: 0,
          amount: undefined,
          financialDate: undefined,
        }}
      >
        {(form): ReactElement => {
          return (
            <Container maxWidth="sm">
              <Paper>
                <Stack gap={2} padding={2}>
                  <Typography variant="h6">
                    {t("financial_postings.add.form_title")}
                  </Typography>

                  <Grid container spacing={2} rowSpacing={0.5}>
                    <Grid item sm={12}>
                      <Controller
                        control={form.control}
                        name="agreement"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            ListboxProps={{
                              style: { maxHeight: "15rem" },
                            }}
                            disableClearable
                            value={value}
                            onChange={(event, newValue) => {
                              onChange(newValue);
                              return newValue.label && setQuery(newValue.label);
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            options={options}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label={t(agreementNumber)}
                                error={Boolean(
                                  form.formState.errors.agreement?.label,
                                )}
                                helperText={
                                  form.formState.errors.agreement?.label
                                    ?.message
                                }
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                  "aria-label": t(agreementNumber),
                                  endAdornment: (
                                    <>
                                      {agreements.isValidating && (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      )}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                                onChange={(event) =>
                                  setQuery(event.currentTarget.value)
                                }
                              />
                            )}
                          />
                        )}
                      />
                      {agreementId && (
                        <AddFinancialPostingFormParameters
                          props={form}
                          agreementId={Number(agreementId)}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {addFinancialPosting.error && (
                    <Typography color="error" aria-label="form.error">
                      {responseError.get(addFinancialPosting.error.code) ??
                        t("common:error.default")}
                    </Typography>
                  )}

                  <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Button
                      role="link"
                      key="common:cancel"
                      component={RouterLink}
                      to="../filter-financial-postings"
                      color="primary"
                    >
                      {t("common:cancel")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={addFinancialPosting.isExecuting}
                    >
                      {t("financial_postings.add.submit_button")}
                    </Button>
                  </Box>
                </Stack>
              </Paper>
            </Container>
          );
        }}
      </Form>
    </>
  );
}
