/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Attachments within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DeliveryStatusModel = {
    Pending: 'Pending',
    Sent: 'Sent',
    Failed: 'Failed'
} as const;
export type DeliveryStatusModel = typeof DeliveryStatusModel[keyof typeof DeliveryStatusModel];


export function DeliveryStatusModelFromJSON(json: any): DeliveryStatusModel {
    return DeliveryStatusModelFromJSONTyped(json, false);
}

export function DeliveryStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryStatusModel {
    return json as DeliveryStatusModel;
}

export function DeliveryStatusModelToJSON(value?: DeliveryStatusModel | null): any {
    return value as any;
}

