import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useListAvailableCCRReports } from "api/report/ccr/listAvailableCCRReports";
import { ControlledTextField, Loader } from "common/components";
import { useTranslation } from "react-i18next";
import { Control, Resolver, useForm } from "support/react-hook-form";
import React, { BaseSyntheticEvent, ReactElement } from "react";
import { Controller } from "react-hook-form";

export interface CCRFormValues {
  ccrProviderCode: string;
  reportDetail: {
    ccrCode: string;
    snapshotId: number | null;
    reportPeriod: string;
  };
}

interface CCRReportFormDialogProps {
  ccrProviderCodes?: string[];
  open: boolean;
  onClose: () => void;
  onSubmit: (formValues: CCRFormValues) => void;
  resolver: Resolver<CCRFormValues>;
  ready: boolean;
  error?: string;
}

interface CCRReportingPeriodProps {
  error?: string;
  ccrCode: string;
  isSubmitting: boolean;
  control: Control<CCRFormValues>;
}

interface FormDialogFallbackProps {
  onClose(event?: BaseSyntheticEvent): void;
  title: string;
}

function FormDialogFallback({
  onClose,
  title,
}: Readonly<FormDialogFallbackProps>): ReactElement {
  const { t } = useTranslation(["reporting", "common"]);

  return (
    <>
      <DialogTitle id="ccr-report-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("common:cancel")}
        </Button>
        <Button color="primary" disabled>
          {t("report_dialog.generate_button")}
        </Button>
      </DialogActions>
    </>
  );
}

const CCRReportingPeriod = ({
  ccrCode,
  error,
  isSubmitting,
  control,
}: CCRReportingPeriodProps) => {
  const { t } = useTranslation(["reporting"]);
  const ccrReports = useListAvailableCCRReports(ccrCode);

  return (
    <Controller
      name="reportDetail"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          value={field.value.reportPeriod}
          helperText={error}
          SelectProps={{ displayEmpty: true }}
          error={Boolean(error)}
          label={t("report_dialog.ccr.reporting_period_label")}
          disabled={isSubmitting}
          select
          required
          onChange={(event) => {
            field.onChange(
              ccrReports.data?.find(
                (item) => item.reportPeriod === event.target.value,
              ),
            );
          }}
        >
          <MenuItem key="please-select" value="">
            {t("common:please_select")}
          </MenuItem>
          {ccrReports.data?.map((report) => (
            <MenuItem key={report.reportPeriod} value={report.reportPeriod}>
              {report.reportPeriod}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

const CCRReportFormDialog = ({
  ccrProviderCodes,
  open,
  onClose,
  onSubmit: onSubmitCallback,
  resolver,
  error,
  ready,
}: CCRReportFormDialogProps) => {
  const { t } = useTranslation("reporting");
  const title = t("report_dialog.ccr.title");

  const form = useForm<CCRFormValues>({
    defaultValues: {
      reportDetail: {
        ccrCode: "",
        snapshotId: null,
        reportPeriod: "",
      },
      ccrProviderCode: "",
    },
    resolver,
  });

  const formWatch = form.watch();

  const handleOnClose = (): void => {
    onClose();
    form.reset();
  };

  const onSubmit = async (data: CCRFormValues) => {
    onSubmitCallback(data);
    form.reset();
  };

  return (
    <Dialog
      onClose={handleOnClose}
      aria-labelledby="ccr-report-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <Loader
        fallback={<FormDialogFallback onClose={handleOnClose} title={title} />}
        ready={ready}
        render={(): ReactElement => (
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            aria-label={title}
            noValidate
          >
            <DialogTitle id="ccr-report-dialog-title">
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{title}</Box>
                <Box>
                  <IconButton
                    aria-label={t("common:close")}
                    onClick={handleOnClose}
                    size="small"
                  >
                    <Close />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              <ControlledTextField
                helperText={form.formState.errors?.ccrProviderCode?.message}
                SelectProps={{ displayEmpty: true }}
                error={form.formState.errors?.ccrProviderCode?.message}
                control={form.control}
                id="ccrProviderCode"
                name="ccrProviderCode"
                label={t("report_dialog.ccr.ccr_provider_code_label")}
                disabled={form.formState.isSubmitting}
                select
                required
              >
                <MenuItem key="please-select" value="">
                  {t("common:please_select")}
                </MenuItem>
                {ccrProviderCodes?.map((ccrProviderCode) => (
                  <MenuItem key={ccrProviderCode} value={ccrProviderCode}>
                    {ccrProviderCode}
                  </MenuItem>
                ))}
              </ControlledTextField>

              {formWatch.ccrProviderCode && (
                <CCRReportingPeriod
                  ccrCode={formWatch.ccrProviderCode}
                  error={form.formState.errors?.reportDetail?.message}
                  isSubmitting={form.formState.isSubmitting}
                  control={form.control}
                />
              )}

              {error && <Typography color="error">{error}</Typography>}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleOnClose}>
                {t("common:cancel")}
              </Button>
              <Button
                type="submit"
                disabled={form.formState.isSubmitting}
                variant="contained"
                color="primary"
              >
                {t("report_dialog.generate_button")}
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default CCRReportFormDialog;
