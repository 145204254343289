import { useQuery, UseQueryReturn } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/assets/financial-details/keys";
import { useApi } from "common/providers/ApiProvider";
import { AssetQueryApi } from "generated/core/apis/AssetQueryApi";
import { FinancialDetailsModel } from "generated/core/models/FinancialDetailsModel";
import { ErrorLike } from "support/error-handler";

type UseGetFinancialDetails = (
  assetId: number,
) => UseQueryReturn<FinancialDetailsModel, ErrorLike>;

export const useGetFinancialDetails: UseGetFinancialDetails = (
  assetId: number,
) => {
  const api = useApi(AssetQueryApi);

  return useQuery(keys.details(assetId), () =>
    api.getFinancialDetails({ assetId }),
  );
};
