import { AmountField } from "@ldms/mui-sdk/forms";
import { Grid, MenuItem } from "@mui/material";
import { ControlledTextField } from "common/components";
import { DiscountTypeModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "support/react-hook-form";

export interface CreateSettlementQuotesFieldValues {
  discountAmount?: number;
  discountPercentage?: number;
  discountType?: DiscountTypeModel;
}

export default function CreateSettlementQuoteFormControls(
  props: UseFormReturn<CreateSettlementQuotesFieldValues>,
): ReactElement {
  const { t } = useTranslation("agreements");
  const discountType = Object.values(DiscountTypeModel);
  const formWatch = props.watch();
  return (
    <>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={6}>
          <ControlledTextField
            helperText={props.formState.errors.discountType?.message}
            error={props.formState.errors.discountType?.message}
            control={props.control}
            id="discountType"
            name="discountType"
            label={t("settlement_quotes.create_quote.discount_type_label")}
            SelectProps={{ displayEmpty: true }}
            select
            defaultValue=""
          >
            <MenuItem value="">
              <i>{t("settlement_quotes.create_quote.no_discount_label")}</i>
            </MenuItem>
            {discountType.map((type) => (
              <MenuItem key={String(type)} value={String(type)}>
                {type}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item sm={6}>
          {formWatch.discountType === DiscountTypeModel.Amount && (
            <AmountField
              error={Boolean(props.formState.errors?.discountAmount?.message)}
              helperText={props.formState.errors?.discountAmount?.message}
              control={props.control}
              name="discountAmount"
              label={t("settlement_quotes.create_quote.discount_amount_label")}
              required
            />
          )}
          {formWatch.discountType === DiscountTypeModel.Percentage && (
            <AmountField
              error={Boolean(
                props.formState.errors?.discountPercentage?.message,
              )}
              helperText={props.formState.errors?.discountPercentage?.message}
              control={props.control}
              name="discountPercentage"
              label={`${t(
                "settlement_quotes.create_quote.discount_percentage_label",
              )} %`}
              required
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
