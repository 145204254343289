import { QueryKey } from "api";
import * as assetKeys from "api/assets/keys";

export const list = (assetId: number): QueryKey => [
  ...assetKeys.details(assetId),
  "settlement-quotes",
  "list",
];

export const details = (assetId: number): QueryKey => [
  ...assetKeys.details(assetId),
  "settlement-quotes",
  "details",
];
