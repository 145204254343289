import { UseQueryReturn, useQuery } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { TasksApi } from "generated/tasks/apis";
import { TaskModel } from "generated/tasks/models/TaskModel";
import * as keys from "./keys";

export const useGetTask = (taskId: number): UseQueryReturn<TaskModel> => {
  const api = useApi(TasksApi, "task-management");

  return useQuery(keys.detail(taskId), () => api.getTask({ taskId: taskId }));
};
