import { DescriptionList } from "@ldms/mui-sdk/components";
import { YesNoValue } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface ContactProps {
  data: {
    title?: string;
    firstName?: string;
    secondName?: string;
    lastName?: string;
    companyName?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    addressLine4?: string;
    postcode?: string;
    invoiceByEmail?: boolean;
    receiveSystemGeneratedCorrespondence?: boolean;
  };
}

export default function Contact({ data }: ContactProps): ReactElement {
  const { t } = useTranslation("clients");

  return (
    <DescriptionList label={t("contacts.contact_details")}>
      <DescriptionList.Item label={t("contacts.name_label")}>
        {[data.title, data.firstName, data.secondName, data.lastName]
          .filter(Boolean)
          .join(" ") || "-"}
      </DescriptionList.Item>
      <DescriptionList.Item label={t("contacts.company_name_label")}>
        {data.companyName ?? "-"}
      </DescriptionList.Item>
      <DescriptionList.Item label={t("contacts.address_label")}>
        {[
          data.addressLine1,
          data.addressLine2,
          data.addressLine3,
          data.addressLine4,
          data.postcode,
        ]
          .filter(Boolean)
          .join(", ") || "-"}
      </DescriptionList.Item>
      <DescriptionList.Item label={t("contacts.invoice_via_email")}>
        <YesNoValue value={data.invoiceByEmail} />
      </DescriptionList.Item>
      <DescriptionList.Item
        label={t("contacts.system_generated_correspondence_label")}
      >
        <YesNoValue value={data.receiveSystemGeneratedCorrespondence} />
      </DescriptionList.Item>
    </DescriptionList>
  );
}
