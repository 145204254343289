import { AddButton } from "@ldms/mui-sdk/components";
import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useAddPortfolio } from "api/companies/portfolios";
import { useListPortfolioBankAccountsV2 } from "api/portfolio-bank-accounts/listPortfolioBankAccounts";
import usePortfolioResolver, {
  PortfolioFieldValues,
} from "apps/servicing/modules/settings/schema";
import { ControlledTextField } from "common/components";
import { useResponseError } from "common/hooks";
import { PortfolioModel } from "generated/servicing-v2/models";
import { ChangeEvent, ReactElement, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface ListPortfoliosContainerProps {
  companyId: string;
}

interface AddPortfolioProps {
  onSuccess(): void;
  onClose(): void;
  open: boolean;
  companyId: string;
}

function AddPortfolioDialogContainer({
  onSuccess: onSuccessCallback,
  onClose,
  open,
  companyId,
}: Readonly<AddPortfolioProps>): ReactElement {
  const { t } = useTranslation("servicing");
  const resolver = usePortfolioResolver("add_portfolio");
  const bankAccounts = useListPortfolioBankAccountsV2();
  const responseError = useResponseError();

  const addPortfolio = useAddPortfolio(companyId, {
    onSuccess: onSuccessCallback,
    onError: (response) => responseError.setError(response.code),
  });

  const onSubmit = async (data: PortfolioFieldValues): Promise<void> => {
    const addPortfolioModel: PortfolioModel = {
      ...data,
      portfolioBankAccountId: data.portfolioBankAccountId,
      companyId: companyId,
    };

    return await addPortfolio.execute(addPortfolioModel);
  };

  const handleAssetDepreciationModelChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    form: UseFormReturn<PortfolioFieldValues>,
  ) => {
    if (event.target.value === "None") {
      form.unregister("depreciationPercentage");
      form.unregister("depreciationMinimumValue");
    }
  };

  const assetDepreciationModelValues = new Map([
    ["None", t("companies.add_portfolio.none_value")],
    [
      "Annualised Straight-line",
      t("companies.add_portfolio.annualised_straight_line_value"),
    ],
  ]);

  return (
    <FormDialog<PortfolioFieldValues>
      open={open}
      title={t("companies.add_portfolio.title_label")}
      onClose={onClose}
      ready={Boolean(bankAccounts.data) || Boolean(bankAccounts.error)}
      onSubmit={onSubmit}
      defaultValues={{
        name: "",
        portfolioBankAccountId: "",
        companyId: "",
        isHpiRegistered: true,
        assetDepreciationModel: "None",
      }}
      labels={{
        submit: t("companies.add_portfolio.submit_button"),
      }}
      resolver={resolver}
    >
      {(form) => {
        if (bankAccounts.error) {
          return (
            <Typography color="error" role="alert">
              {t("common:error.default")}
            </Typography>
          );
        }

        return (
          <>
            <Grid container spacing={2} rowSpacing={0.5}>
              <Grid item sm={12}>
                <TextField
                  {...form.register("name")}
                  helperText={form.formState.errors.name?.message}
                  error={Boolean(form.formState.errors.name?.message)}
                  label={t("companies.add_portfolio.name_label")}
                  required
                />
              </Grid>
              <Grid item sm={6}>
                <ControlledTextField
                  select
                  id="portfolioBankAccountId"
                  name="portfolioBankAccountId"
                  label={t("companies.add_portfolio.bank_account_label")}
                  helperText={
                    form.formState.errors.portfolioBankAccountId?.message
                  }
                  error={form.formState.errors?.portfolioBankAccountId?.message}
                  SelectProps={{ displayEmpty: true }}
                  control={form.control}
                  required
                >
                  <MenuItem value="">
                    <i>{t("common:please_select")}</i>
                  </MenuItem>
                  {bankAccounts.data?.results.map((bank) => (
                    <MenuItem key={bank.systemId} value={bank.systemId}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  {...form.register("equifaxInsightNumber", {
                    setValueAs: (value) => value || undefined,
                  })}
                  error={Boolean(
                    form.formState.errors.equifaxInsightNumber?.message,
                  )}
                  helperText={
                    form.formState.errors.equifaxInsightNumber?.message
                  }
                  label={t("companies.add_portfolio.equifax_insight_label")}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  {...form.register("ccrProviderCode", {
                    setValueAs: (value) => value || undefined,
                  })}
                  error={Boolean(
                    form.formState.errors.ccrProviderCode?.message,
                  )}
                  helperText={form.formState.errors.ccrProviderCode?.message}
                  label={t("companies.add_portfolio.ccr_provider_code_label")}
                />
              </Grid>
              <Grid item sm={6}>
                <ControlledTextField
                  select
                  id="assetDepreciationModel"
                  name="assetDepreciationModel"
                  label={t(
                    "companies.add_portfolio.asset_depreciation_model_label",
                  )}
                  control={form.control}
                  onChange={(event) =>
                    handleAssetDepreciationModelChange(event, form)
                  }
                >
                  {["None", "Annualised Straight-line"].map((value) => (
                    <MenuItem key={value} value={value}>
                      {assetDepreciationModelValues.get(value)}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              {form.watch("assetDepreciationModel") !== "None" && (
                <>
                  <Grid item sm={6}>
                    <TextField
                      {...form.register("depreciationPercentage")}
                      label={t(
                        "companies.add_portfolio.depreciation_percentage_label",
                      )}
                      error={Boolean(
                        form.formState.errors.depreciationPercentage?.message,
                      )}
                      helperText={
                        form.formState.errors.depreciationPercentage?.message
                      }
                      required
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      {...form.register("depreciationMinimumValue")}
                      label={t(
                        "companies.add_portfolio.depreciation_minimum_value_label",
                      )}
                      error={Boolean(
                        form.formState.errors.depreciationMinimumValue?.message,
                      )}
                      helperText={
                        form.formState.errors.depreciationMinimumValue?.message
                      }
                      required
                    />
                  </Grid>
                </>
              )}
              <Grid item sm={6}>
                <FormControlLabel
                  label={String(
                    t("companies.add_portfolio.registered_interest_label"),
                  )}
                  labelPlacement="end"
                  control={
                    <Controller
                      control={form.control}
                      name="isHpiRegistered"
                      render={({
                        field: { onChange, value },
                      }): ReactElement => (
                        <Checkbox
                          checked={value}
                          onChange={(e): void => onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                />
              </Grid>
            </Grid>

            {addPortfolio.error && (
              <Typography color="error" aria-label="form.error">
                {responseError.message}
              </Typography>
            )}
          </>
        );
      }}
    </FormDialog>
  );
}

export default function AddPortfolioContainer({
  companyId,
}: ListPortfoliosContainerProps): ReactElement {
  const [open, setOpen] = useState(false);

  const openDialog = (): void => {
    setOpen(true);
  };
  const closeDialog = (): void => {
    setOpen(false);
  };

  return (
    <>
      <AddPortfolioDialogContainer
        onSuccess={closeDialog}
        open={open}
        onClose={closeDialog}
        companyId={companyId}
      />

      <AddButton onClick={openDialog} />
    </>
  );
}
