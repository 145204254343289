import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import ListChargesContainer from "apps/servicing/modules/settings/containers/ListChargesContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

function ChargesView() {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();
  const href = `${appConfig.appRoutes.servicing}/settings/charges/add-charge`;

  return (
    <main>
      <ViewLayout
        title={t("charges.charges_heading")}
        action={
          <AddButton component={RouterLink} to={href} variant="contained" />
        }
      >
        <ContainedLayout>
          <ListChargesContainer />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:charges:manage"],
})(ChargesView);
