/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing vat codes within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VatRateListItemModel
 */
export interface VatRateListItemModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof VatRateListItemModel
     */
    effectiveDate: Date;
    /**
     * Vat rate
     * @type {number}
     * @memberof VatRateListItemModel
     */
    rate: number;
    /**
     * Username of the user that created the vat rate
     * @type {string}
     * @memberof VatRateListItemModel
     */
    createdBy: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof VatRateListItemModel
     */
    createdDate: Date;
}

/**
 * Check if a given object implements the VatRateListItemModel interface.
 */
export function instanceOfVatRateListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "effectiveDate" in value;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "createdDate" in value;

    return isInstance;
}

export function VatRateListItemModelFromJSON(json: any): VatRateListItemModel {
    return VatRateListItemModelFromJSONTyped(json, false);
}

export function VatRateListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VatRateListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'effectiveDate': (new Date(json['effectiveDate'])),
        'rate': json['rate'],
        'createdBy': json['createdBy'],
        'createdDate': (new Date(json['createdDate'])),
    };
}

export function VatRateListItemModelToJSON(value?: VatRateListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'effectiveDate': (value.effectiveDate.toISOString().substring(0,10)),
        'rate': value.rate,
        'createdBy': value.createdBy,
        'createdDate': (value.createdDate.toISOString().substring(0,10)),
    };
}

