import { QueryKey } from "api";
import { keys } from "api/onboarding";

export const all = (onboardingId: number): QueryKey => [
  ...keys.detail(onboardingId),
  "summary",
];

export const detail = (onboardingId: number): QueryKey => [
  ...all(onboardingId),
  "detail",
];
