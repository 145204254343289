import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AddButton, EditButton } from "@ldms/mui-sdk/components";
import { Loader } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import {
  useCreateContactPoint,
  useListContactPoints,
} from "api/customers/contacts/contact-points";
import {
  Contact,
  CustomerDetails,
} from "apps/servicing/modules/customers/components";

import ContactPointList from "apps/servicing/modules/customers/components/ContactPointList";
import AddContactPoint, {
  AddContactPointFieldValues,
} from "apps/servicing/modules/customers/containers/AddContactPoint";
import Surface from "common/components/Surface";
import { ContactPointTypeModel } from "generated/servicing-v2/models";
import { ContactsListItemModel } from "generated/servicing-v2/models/ContactsListItemModel";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface ContactProps {
  clientSystemId: string;
  contact: ContactsListItemModel;
}

export default function ContactDetails({
  clientSystemId,
  contact,
}: Readonly<ContactProps>): ReactElement {
  const { t } = useTranslation("clients");
  const isCustomer = contact.isCustomer;
  const [addContactPointOpen, setAddContactPointOpen] = useState(false);
  const addContactPoint = useCreateContactPoint(contact.systemId, isCustomer);
  const contactPoints = useListContactPoints(contact.systemId, isCustomer);

  const onAddContactPoint = async (
    data: AddContactPointFieldValues,
  ): Promise<void> => {
    await addContactPoint.execute(data);
    setAddContactPointOpen(false);
  };

  const renderContactPoints = (): ReactElement => {
    return (
      <>
        {contactPoints.error ? (
          <Typography color="error">{t("common:error.default")}</Typography>
        ) : (
          <ContactPointList
            objectSystemId={contact.systemId}
            data={contactPoints.data}
            isInvoiceByEmail={Boolean(contact.invoiceByEmail)}
            forCustomer={isCustomer}
          />
        )}
      </>
    );
  };

  const handleOpenAddContactPoint = (): void => {
    setAddContactPointOpen(true);
  };

  const handleCloseAddContactPoint = (): void => {
    setAddContactPointOpen(false);
  };

  const availableContactPoints = Object.keys(ContactPointTypeModel)
    .filter(
      (key) =>
        !(contactPoints.data ?? []).some(
          (item) =>
            item.type ===
            ContactPointTypeModel[key as keyof typeof ContactPointTypeModel],
        ),
    )
    .map(
      (key) => ContactPointTypeModel[key as keyof typeof ContactPointTypeModel],
    );

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Box>
            <Surface
              action={
                <AccessControl
                  allowedPermissions={["servicing:contacts:manage"]}
                >
                  {isCustomer ? (
                    <EditButton
                      component={RouterLink}
                      to="../contacts/edit-customer"
                    />
                  ) : (
                    <EditButton
                      component={RouterLink}
                      to={`../contacts/${contact.systemId}/edit-contact`}
                      color="primary"
                    />
                  )}
                </AccessControl>
              }
              title={
                isCustomer
                  ? t("customer_details.customer_details_label")
                  : t("contacts.contact_details")
              }
            >
              {isCustomer ? (
                <CustomerDetails customerId={clientSystemId} />
              ) : (
                <Contact data={contact} />
              )}
            </Surface>
          </Box>
        </Grid>
        <Grid item>
          <Surface
            title={t("contacts.correspondence_methods")}
            action={
              <AccessControl allowedPermissions={["servicing:contacts:manage"]}>
                <AddButton onClick={handleOpenAddContactPoint} />
              </AccessControl>
            }
            disableGutters
          >
            <Loader
              fallback={
                <Box display="flex" justifyContent="center" p={2}>
                  <CircularProgress />
                </Box>
              }
              ready={Boolean(contactPoints.data ?? contactPoints.error)}
              render={renderContactPoints}
            />
          </Surface>
        </Grid>
      </Grid>

      <AddContactPoint
        open={addContactPointOpen}
        onClose={handleCloseAddContactPoint}
        contactPointTypes={availableContactPoints ?? []}
        onSubmit={onAddContactPoint}
        error={addContactPoint.error}
      />
    </>
  );
}
