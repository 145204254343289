/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Direct Debits within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Direct Debit Submission Payment
 * @export
 * @interface DirectDebitSubmissionPaymentListItemModel
 */
export interface DirectDebitSubmissionPaymentListItemModel {
    /**
     * Direct Debit Mandate Reference
     * @type {string}
     * @memberof DirectDebitSubmissionPaymentListItemModel
     */
    reference?: string;
    /**
     * Service User Number
     * @type {string}
     * @memberof DirectDebitSubmissionPaymentListItemModel
     */
    sun: string;
    /**
     * Account number for the payment
     * @type {string}
     * @memberof DirectDebitSubmissionPaymentListItemModel
     */
    agreementNumber: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof DirectDebitSubmissionPaymentListItemModel
     */
    paymentDueDate?: Date;
    /**
     * Payment type for the payment
     * @type {string}
     * @memberof DirectDebitSubmissionPaymentListItemModel
     */
    paymentType: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof DirectDebitSubmissionPaymentListItemModel
     */
    amount: string;
    /**
     * Name of the customer
     * @type {string}
     * @memberof DirectDebitSubmissionPaymentListItemModel
     */
    customerName?: string;
    /**
     * Name of the company
     * @type {string}
     * @memberof DirectDebitSubmissionPaymentListItemModel
     */
    customerCompanyName?: string;
}

/**
 * Check if a given object implements the DirectDebitSubmissionPaymentListItemModel interface.
 */
export function instanceOfDirectDebitSubmissionPaymentListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sun" in value;
    isInstance = isInstance && "agreementNumber" in value;
    isInstance = isInstance && "paymentType" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function DirectDebitSubmissionPaymentListItemModelFromJSON(json: any): DirectDebitSubmissionPaymentListItemModel {
    return DirectDebitSubmissionPaymentListItemModelFromJSONTyped(json, false);
}

export function DirectDebitSubmissionPaymentListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectDebitSubmissionPaymentListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'sun': json['sun'],
        'agreementNumber': json['agreementNumber'],
        'paymentDueDate': !exists(json, 'paymentDueDate') ? undefined : (new Date(json['paymentDueDate'])),
        'paymentType': json['paymentType'],
        'amount': json['amount'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerCompanyName': !exists(json, 'customerCompanyName') ? undefined : json['customerCompanyName'],
    };
}

export function DirectDebitSubmissionPaymentListItemModelToJSON(value?: DirectDebitSubmissionPaymentListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference': value.reference,
        'sun': value.sun,
        'agreementNumber': value.agreementNumber,
        'paymentDueDate': value.paymentDueDate === undefined ? undefined : (value.paymentDueDate.toISOString().substring(0,10)),
        'paymentType': value.paymentType,
        'amount': value.amount,
        'customerName': value.customerName,
        'customerCompanyName': value.customerCompanyName,
    };
}

