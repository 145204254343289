/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeesModel
 */
export interface FeesModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeesModel
     */
    arrangementFee?: string;
    /**
     * Flag to define if arrangement fee is deducted from advance
     * @type {boolean}
     * @memberof FeesModel
     */
    arrangementFeeDeductedFromAdvance?: boolean;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeesModel
     */
    annualAdministrationFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeesModel
     */
    facilityFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeesModel
     */
    legalFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeesModel
     */
    valuationFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeesModel
     */
    documentationFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeesModel
     */
    optionToPurchaseFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeesModel
     */
    landRegistryFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeesModel
     */
    exitFee?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof FeesModel
     */
    administrationFee?: string;
}

/**
 * Check if a given object implements the FeesModel interface.
 */
export function instanceOfFeesModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeesModelFromJSON(json: any): FeesModel {
    return FeesModelFromJSONTyped(json, false);
}

export function FeesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'arrangementFee': !exists(json, 'arrangementFee') ? undefined : json['arrangementFee'],
        'arrangementFeeDeductedFromAdvance': !exists(json, 'arrangementFeeDeductedFromAdvance') ? undefined : json['arrangementFeeDeductedFromAdvance'],
        'annualAdministrationFee': !exists(json, 'annualAdministrationFee') ? undefined : json['annualAdministrationFee'],
        'facilityFee': !exists(json, 'facilityFee') ? undefined : json['facilityFee'],
        'legalFee': !exists(json, 'legalFee') ? undefined : json['legalFee'],
        'valuationFee': !exists(json, 'valuationFee') ? undefined : json['valuationFee'],
        'documentationFee': !exists(json, 'documentationFee') ? undefined : json['documentationFee'],
        'optionToPurchaseFee': !exists(json, 'optionToPurchaseFee') ? undefined : json['optionToPurchaseFee'],
        'landRegistryFee': !exists(json, 'landRegistryFee') ? undefined : json['landRegistryFee'],
        'exitFee': !exists(json, 'exitFee') ? undefined : json['exitFee'],
        'administrationFee': !exists(json, 'administrationFee') ? undefined : json['administrationFee'],
    };
}

export function FeesModelToJSON(value?: FeesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'arrangementFee': value.arrangementFee,
        'arrangementFeeDeductedFromAdvance': value.arrangementFeeDeductedFromAdvance,
        'annualAdministrationFee': value.annualAdministrationFee,
        'facilityFee': value.facilityFee,
        'legalFee': value.legalFee,
        'valuationFee': value.valuationFee,
        'documentationFee': value.documentationFee,
        'optionToPurchaseFee': value.optionToPurchaseFee,
        'landRegistryFee': value.landRegistryFee,
        'exitFee': value.exitFee,
        'administrationFee': value.administrationFee,
    };
}

