/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FinancialProductModel,
  OnboardedAgreementModel,
  OnboardingListItemModel,
  OnboardingModel,
  OnboardingSummaryModel,
} from '../models/index';
import {
    FinancialProductModelFromJSON,
    FinancialProductModelToJSON,
    OnboardedAgreementModelFromJSON,
    OnboardedAgreementModelToJSON,
    OnboardingListItemModelFromJSON,
    OnboardingListItemModelToJSON,
    OnboardingModelFromJSON,
    OnboardingModelToJSON,
    OnboardingSummaryModelFromJSON,
    OnboardingSummaryModelToJSON,
} from '../models/index';

export interface ApproveOnboardingRequest {
    onboardingId: number;
}

export interface EditOnboardingRequest {
    onboardingId: number;
    onboardingModel?: OnboardingModel;
}

export interface GetOnboardingRequest {
    onboardingId: number;
}

export interface GetOnboardingSummaryRequest {
    onboardingId: number;
}

export interface ListOnboardingsRequest {
    offset?: number;
    limit?: number;
    status?: string;
    from?: Date;
    to?: Date;
}

export interface RejectOnboardingRequest {
    onboardingId: number;
}

export interface StageOnboardingDataRequest {
    onboardingModel: OnboardingModel;
}

export interface SubmitForReviewRequest {
    onboardingId: number;
}

/**
 * 
 */
export class OnboardingApi extends runtime.BaseAPI {

    /**
     * Approve the onboarding details
     */
    async approveOnboardingRaw(requestParameters: ApproveOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.onboardingId === null || requestParameters.onboardingId === undefined) {
            throw new runtime.RequiredError('onboardingId','Required parameter requestParameters.onboardingId was null or undefined when calling approveOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["onboarding:elevated"]);
        }

        const response = await this.request({
            path: `/onboardings/{onboardingId}`.replace(`{${"onboardingId"}}`, encodeURIComponent(String(requestParameters.onboardingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve the onboarding details
     */
    async approveOnboarding(requestParameters: ApproveOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approveOnboardingRaw(requestParameters, initOverrides);
    }

    /**
     * Edit Onboarding Data
     */
    async editOnboardingRaw(requestParameters: EditOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.onboardingId === null || requestParameters.onboardingId === undefined) {
            throw new runtime.RequiredError('onboardingId','Required parameter requestParameters.onboardingId was null or undefined when calling editOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["onboarding:manage"]);
        }

        const response = await this.request({
            path: `/onboardings/{onboardingId}`.replace(`{${"onboardingId"}}`, encodeURIComponent(String(requestParameters.onboardingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardingModelToJSON(requestParameters.onboardingModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit Onboarding Data
     */
    async editOnboarding(requestParameters: EditOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editOnboardingRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve the onboarding details
     */
    async getOnboardingRaw(requestParameters: GetOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardingModel>> {
        if (requestParameters.onboardingId === null || requestParameters.onboardingId === undefined) {
            throw new runtime.RequiredError('onboardingId','Required parameter requestParameters.onboardingId was null or undefined when calling getOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["onboarding:view"]);
        }

        const response = await this.request({
            path: `/onboardings/{onboardingId}`.replace(`{${"onboardingId"}}`, encodeURIComponent(String(requestParameters.onboardingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingModelFromJSON(jsonValue));
    }

    /**
     * Retrieve the onboarding details
     */
    async getOnboarding(requestParameters: GetOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardingModel> {
        const response = await this.getOnboardingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the agreement profile summary for an on-boarding
     */
    async getOnboardingSummaryRaw(requestParameters: GetOnboardingSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardingSummaryModel>> {
        if (requestParameters.onboardingId === null || requestParameters.onboardingId === undefined) {
            throw new runtime.RequiredError('onboardingId','Required parameter requestParameters.onboardingId was null or undefined when calling getOnboardingSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["onboarding:view"]);
        }

        const response = await this.request({
            path: `/onboardings/{onboardingId}/summary`.replace(`{${"onboardingId"}}`, encodeURIComponent(String(requestParameters.onboardingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingSummaryModelFromJSON(jsonValue));
    }

    /**
     * Retrieve the agreement profile summary for an on-boarding
     */
    async getOnboardingSummary(requestParameters: GetOnboardingSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardingSummaryModel> {
        const response = await this.getOnboardingSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a list of financial products
     */
    async listFinancialProductsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FinancialProductModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["financial-products:view"]);
        }

        const response = await this.request({
            path: `/onboardings/financial-products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FinancialProductModelFromJSON));
    }

    /**
     * Retrieve a list of financial products
     */
    async listFinancialProducts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FinancialProductModel>> {
        const response = await this.listFinancialProductsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a list of onboarded agreements
     */
    async listOnboardedAgreementsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OnboardedAgreementModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreements:view"]);
        }

        const response = await this.request({
            path: `/onboardings/onboarded-agreements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OnboardedAgreementModelFromJSON));
    }

    /**
     * Retrieve a list of onboarded agreements
     */
    async listOnboardedAgreements(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OnboardedAgreementModel>> {
        const response = await this.listOnboardedAgreementsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a list of onboarding agreements
     */
    async listOnboardingsRaw(requestParameters: ListOnboardingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OnboardingListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["onboarding:view"]);
        }

        const response = await this.request({
            path: `/onboardings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OnboardingListItemModelFromJSON));
    }

    /**
     * Retrieve a list of onboarding agreements
     */
    async listOnboardings(requestParameters: ListOnboardingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OnboardingListItemModel>> {
        const response = await this.listOnboardingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject the onboarding details
     */
    async rejectOnboardingRaw(requestParameters: RejectOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.onboardingId === null || requestParameters.onboardingId === undefined) {
            throw new runtime.RequiredError('onboardingId','Required parameter requestParameters.onboardingId was null or undefined when calling rejectOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["onboarding:elevated"]);
        }

        const response = await this.request({
            path: `/onboardings/{onboardingId}`.replace(`{${"onboardingId"}}`, encodeURIComponent(String(requestParameters.onboardingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject the onboarding details
     */
    async rejectOnboarding(requestParameters: RejectOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rejectOnboardingRaw(requestParameters, initOverrides);
    }

    /**
     * Stages onboarding data
     */
    async stageOnboardingDataRaw(requestParameters: StageOnboardingDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.onboardingModel === null || requestParameters.onboardingModel === undefined) {
            throw new runtime.RequiredError('onboardingModel','Required parameter requestParameters.onboardingModel was null or undefined when calling stageOnboardingData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["onboarding:manage"]);
        }

        const response = await this.request({
            path: `/onboardings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardingModelToJSON(requestParameters.onboardingModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stages onboarding data
     */
    async stageOnboardingData(requestParameters: StageOnboardingDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.stageOnboardingDataRaw(requestParameters, initOverrides);
    }

    /**
     * Set the status of an on-boarding to be read-only and ready for review
     */
    async submitForReviewRaw(requestParameters: SubmitForReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.onboardingId === null || requestParameters.onboardingId === undefined) {
            throw new runtime.RequiredError('onboardingId','Required parameter requestParameters.onboardingId was null or undefined when calling submitForReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["onboarding:manage"]);
        }

        const response = await this.request({
            path: `/onboardings/{onboardingId}/review`.replace(`{${"onboardingId"}}`, encodeURIComponent(String(requestParameters.onboardingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the status of an on-boarding to be read-only and ready for review
     */
    async submitForReview(requestParameters: SubmitForReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitForReviewRaw(requestParameters, initOverrides);
    }

}
