import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";

import { useApi } from "common/providers";
import { AutomationCommandApi } from "generated/core/apis";
import * as auditHistoryKeys from "./auditHistory/keys";
import * as keys from "./keys";

const useDeactivateAutomation = (
  automationId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AutomationCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand(() => api.deactivateAutomation({ automationId }), {
    ...options,
    onSuccess: () => {
      options.onSuccess?.();
      partialMutate(keys.all());
      partialMutate(auditHistoryKeys.list(automationId));
    },
  });
};

export default useDeactivateAutomation;
