import {
  Checkbox,
  ListItemText,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { ReactElement, ReactNode } from "react";
import {
  Control,
  Controller,
  FieldPathValue,
  FieldValues,
  Path,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

type MultipleSelectFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  error?: string;
  options: { label: string; value: string }[];
  name: Path<TFieldValues>;
  defaultValue?: FieldPathValue<TFieldValues, Path<TFieldValues>>;
} & Omit<TextFieldProps, "error">;

export default function MultipleSelectField<TFieldValues extends FieldValues>({
  control,
  error,
  options,
  defaultValue,
  name: controllerName,
  ...textFieldProps
}: MultipleSelectFieldProps<TFieldValues>): ReactElement {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={controllerName}
      {...(defaultValue ? { defaultValue } : {})}
      render={({ field: { ref, name, value, ...field } }): ReactElement => (
        <TextField
          margin="normal"
          size="small"
          helperText={error}
          variant="outlined"
          {...textFieldProps}
          {...field}
          InputLabelProps={{
            htmlFor: name,
            shrink: true,
          }}
          error={Boolean(error)}
          select
          ref={ref}
          name={name}
          SelectProps={{
            multiple: true,
            displayEmpty: true,
            value,
            renderValue: (selected): ReactNode =>
              (selected as string[]).length
                ? (selected as string[])
                    .map((s) => options.find((o) => o.value === s)?.label)
                    .join(", ")
                : t("please_select"),
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              <Checkbox checked={(value as unknown[]).includes(option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
}
