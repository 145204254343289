/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Portfolios within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortfolioModel
 */
export interface PortfolioModel {
    /**
     * The portfolio name
     * @type {string}
     * @memberof PortfolioModel
     */
    name: string;
    /**
     * The unique identifier of the portfolio bank
     * @type {string}
     * @memberof PortfolioModel
     */
    portfolioBankAccountId: string;
    /**
     * Indicates whether the portfolio is hpi registered
     * @type {boolean}
     * @memberof PortfolioModel
     */
    isHpiRegistered: boolean;
    /**
     * The Equifax Insight Portfolio number
     * @type {string}
     * @memberof PortfolioModel
     */
    equifaxInsightNumber?: string;
    /**
     * CCR Provider Code
     * @type {string}
     * @memberof PortfolioModel
     */
    ccrProviderCode?: string;
    /**
     * The unique identifier of a company
     * @type {string}
     * @memberof PortfolioModel
     */
    companyId?: string;
    /**
     * The default asset depreciation percentage
     * @type {number}
     * @memberof PortfolioModel
     */
    depreciationPercentage?: number;
    /**
     * The default asset depreciation minimum value
     * @type {number}
     * @memberof PortfolioModel
     */
    depreciationMinimumValue?: number;
}

/**
 * Check if a given object implements the PortfolioModel interface.
 */
export function instanceOfPortfolioModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "portfolioBankAccountId" in value;
    isInstance = isInstance && "isHpiRegistered" in value;

    return isInstance;
}

export function PortfolioModelFromJSON(json: any): PortfolioModel {
    return PortfolioModelFromJSONTyped(json, false);
}

export function PortfolioModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'portfolioBankAccountId': json['portfolioBankAccountId'],
        'isHpiRegistered': json['isHpiRegistered'],
        'equifaxInsightNumber': !exists(json, 'equifaxInsightNumber') ? undefined : json['equifaxInsightNumber'],
        'ccrProviderCode': !exists(json, 'ccrProviderCode') ? undefined : json['ccrProviderCode'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'depreciationPercentage': !exists(json, 'depreciationPercentage') ? undefined : json['depreciationPercentage'],
        'depreciationMinimumValue': !exists(json, 'depreciationMinimumValue') ? undefined : json['depreciationMinimumValue'],
    };
}

export function PortfolioModelToJSON(value?: PortfolioModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'portfolioBankAccountId': value.portfolioBankAccountId,
        'isHpiRegistered': value.isHpiRegistered,
        'equifaxInsightNumber': value.equifaxInsightNumber,
        'ccrProviderCode': value.ccrProviderCode,
        'companyId': value.companyId,
        'depreciationPercentage': value.depreciationPercentage,
        'depreciationMinimumValue': value.depreciationMinimumValue,
    };
}

