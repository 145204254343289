import { AmountField, DateField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { list } from "api/assets/valuations/keys";
import { useUpdateValuation } from "api/valuations";
import { usePartialMutate, useYupResolver } from "common/hooks";
import { UpdateValuationModel } from "generated/core/models";
import { ReactElement, useState } from "react";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface UpdateValuationFieldValues {
  valuationDate: Date;
  value: number;
  completedBy?: string;
  source?: string;
}

interface UpdateValuationContainerProps {
  data: UpdateValuationModel;
  valuationId: number;
  assetId: number;
}

const useUpdateValuationResolver = (): Resolver<UpdateValuationFieldValues> => {
  const { t } = useTranslation("assets");
  const dateLabel = t("valuations.update.valuation_date_label");
  const valueLabel = t("valuations.update.value_label");

  return useYupResolver<UpdateValuationFieldValues>((yup) =>
    yup.object().shape({
      valuationDate: yup
        .date()
        .localDate()
        .isValidDate(dateLabel)
        .isRequired(dateLabel),
      value: yup
        .number(valueLabel)
        .isRequired(valueLabel)
        .maxAmount(99999999.99, valueLabel),
      completedBy: yup
        .string()
        .maxCharacters(255, t("valuations.update.completed_by_label")),
      source: yup
        .string()
        .maxCharacters(255, t("valuations.update.source_label")),
    }),
  );
};

export default function UpdateValuationContainer({
  data,
  assetId,
  valuationId,
}: UpdateValuationContainerProps): ReactElement {
  const { t } = useTranslation("assets");
  const resolver = useUpdateValuationResolver();
  const partialMutate = usePartialMutate();

  const [openAddAssetValuationsDialog, setOpenAddAssetValuationsDialog] =
    useState(false);

  const openDialog = (): void => {
    setOpenAddAssetValuationsDialog(true);
  };

  const closeDialog = (): void => {
    setOpenAddAssetValuationsDialog(false);
  };

  const onSuccess = (): void => {
    partialMutate(list(assetId));
    closeDialogHandler();
  };

  const updateValuation = useUpdateValuation(valuationId, {
    onSuccess: onSuccess,
  });

  const closeDialogHandler = (): void => {
    updateValuation.reset();
    closeDialog();
  };

  const onSubmit = async (
    formValues: UpdateValuationFieldValues,
  ): Promise<void> => {
    const updateValuationModel: UpdateValuationModel = {
      ...formValues,
      value: formValues.value.toFixed(2),
    };
    return updateValuation.command(updateValuationModel);
  };

  return (
    <>
      <ListItemButton dense onClick={openDialog}>
        <ListItemText
          primaryTypographyProps={{ color: "primary" }}
          primary={t("valuations.update.update_button")}
        />
      </ListItemButton>

      <FormDialog
        title={t("valuations.update.heading_label")}
        onSubmit={onSubmit}
        onClose={closeDialogHandler}
        open={openAddAssetValuationsDialog}
        resolver={resolver}
        defaultValues={{ ...data, value: Number(data.value) }}
      >
        {(form) => {
          return (
            <>
              <DateField
                required
                error={Boolean(form.formState.errors.valuationDate)}
                name="valuationDate"
                label={t("valuations.update.valuation_date_label")}
                helperText={form.formState.errors.valuationDate?.message}
                control={form.control}
              />
              <AmountField
                name="value"
                error={Boolean(form.formState.errors?.value?.message)}
                helperText={form.formState.errors?.value?.message}
                control={form.control}
                label={t("valuations.update.value_label")}
                required
              />
              <TextField
                {...form.register("completedBy")}
                helperText={form.formState.errors.completedBy?.message}
                error={Boolean(form.formState.errors.completedBy)}
                label={t("valuations.update.completed_by_label")}
              />
              <TextField
                {...form.register("source")}
                error={Boolean(form.formState.errors.source)}
                helperText={form.formState.errors.source?.message}
                label={t("valuations.update.source_label")}
              />
              {updateValuation.error?.message && (
                <Typography color="error">
                  {updateValuation.error?.message}
                </Typography>
              )}
            </>
          );
        }}
      </FormDialog>
    </>
  );
}
