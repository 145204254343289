/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Portfolio Bank Accounts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The direct debit submission file type
 * @export
 */
export const DirectDebitSubmissionFileTypeModel = {
    Bacs: 'BACS',
    Sepa: 'SEPA',
    SepaXml: 'SEPA XML'
} as const;
export type DirectDebitSubmissionFileTypeModel = typeof DirectDebitSubmissionFileTypeModel[keyof typeof DirectDebitSubmissionFileTypeModel];


export function DirectDebitSubmissionFileTypeModelFromJSON(json: any): DirectDebitSubmissionFileTypeModel {
    return DirectDebitSubmissionFileTypeModelFromJSONTyped(json, false);
}

export function DirectDebitSubmissionFileTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectDebitSubmissionFileTypeModel {
    return json as DirectDebitSubmissionFileTypeModel;
}

export function DirectDebitSubmissionFileTypeModelToJSON(value?: DirectDebitSubmissionFileTypeModel | null): any {
    return value as any;
}

