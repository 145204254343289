import * as keys from "api/report/ccr/keys";
import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { ReportQueryApi } from "generated/core/apis";
import { CentralCreditRegisterReportListItemModel } from "generated/core/models";

type UseListAvailableCCRReports = (
  ccrCode: string,
) => UseQueryReturn<CentralCreditRegisterReportListItemModel[]>;

export const useListAvailableCCRReports: UseListAvailableCCRReports = (
  ccrCode: string,
) => {
  const api = useApi(ReportQueryApi);

  return useQuery(keys.list(ccrCode), () =>
    api.listAvailableCentralCreditRegisterReports({ ccrCode }),
  );
};
