import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ApiResponse } from "generated";
import { RequestAttachmentDownloadRequest } from "generated/core/apis";
import { AttachmentCommandApi } from "generated/core/apis/AttachmentCommandApi";

export const useRequestAttachmentDownload = (
  options: UseCommandOptions<ApiResponse<void>, ResponseError> = {},
) => {
  const api = useApi(AttachmentCommandApi);

  return useCommand<
    RequestAttachmentDownloadRequest,
    ApiResponse<void>,
    ResponseError
  >(
    (request) =>
      api.requestAttachmentDownloadRaw({
        attachmentId: request.attachmentId,
      }),
    options,
  );
};
