import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { Box, Typography } from "@mui/material";
import { useGetInsurance } from "api/agreements/insurance/getInsurance";
import InsuranceDescription from "apps/servicing/modules/agreements/components/InsuranceDescription";
import AddInsuranceContainer from "apps/servicing/modules/agreements/containers/AddInsuranceContainer";
import RemoveAgreementInsuranceContainer from "apps/servicing/modules/agreements/containers/RemoveAgreementInsuranceContainer";
import Loader from "common/components/Loader";
import QueryError from "common/components/QueryError";
import Surface from "common/components/Surface";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface AgreementInsuranceContainerProps {
  agreementId: number;
}

export default function AgreementInsuranceContainer({
  agreementId,
}: AgreementInsuranceContainerProps): ReactElement {
  const { t } = useTranslation("agreements");
  const insurance = useGetInsurance(agreementId);

  return (
    <Loader
      ready={Boolean(insurance.error ?? insurance.data)}
      render={() => {
        if (insurance.error || !insurance.data) {
          return <QueryError onRetry={insurance.refetch} />;
        }

        if (!insurance.data?.name) {
          return (
            <Box textAlign="center">
              <Typography color="textSecondary" gutterBottom>
                {t("insurance.no_insurance")}
              </Typography>
              <AccessControl
                allowedPermissions={["servicing:insurance:manage"]}
              >
                <AddInsuranceContainer agreementId={agreementId} />
              </AccessControl>
            </Box>
          );
        }

        return (
          <Surface
            title={t("insurance.insurance_details.details_label")}
            action={
              <AccessControl
                allowedPermissions={["servicing:insurance:manage"]}
              >
                <RemoveAgreementInsuranceContainer agreementId={agreementId} />
              </AccessControl>
            }
          >
            <InsuranceDescription {...insurance.data} />
          </Surface>
        );
      }}
    />
  );
}
