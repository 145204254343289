import { Loader } from "@ldms/mui-sdk/templates";
import { LockOutlined } from "@mui/icons-material";
import {
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useListRoles } from "api/roles";
import UpdateRoleContainer from "apps/admin/containers/UpdateRoleContainer/UpdateRoleContainer";
import { LoadableTableHead, NoResults, QueryError } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout/ListLayout";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const ListRolesContainer = () => {
  const { t } = useTranslation("roles");
  const listRoles = useListRoles();
  const appConfig = useAppConfiguration();

  return (
    <Loader
      ready={Boolean(listRoles.data ?? listRoles.error)}
      render={() => {
        if (!listRoles.data || listRoles.error) {
          return <QueryError onRetry={listRoles.refetch} />;
        }

        return (
          <ListLayout>
            <Table
              aria-label={t("roles_list.roles_table_label")}
              size="small"
              stickyHeader
            >
              <LoadableTableHead
                headings={[
                  t("roles_list.role_heading_label"),
                  t("roles_list.description_heading_label"),
                  t("roles_list.created_by_heading_label"),
                  t("roles_list.users_assigned_label"),
                  "",
                ]}
                loading={listRoles.isValidating}
              />
              <TableBody>
                {listRoles.data.map((role) => (
                  <TableRow key={role.id}>
                    <TableCell component="th" padding="none" scope="row">
                      <ListItemButton
                        dense
                        component={RouterLink}
                        to={`${appConfig.appRoutes.admin}/roles/${role.id}`}
                      >
                        <ListItemText
                          primary={role.name}
                          primaryTypographyProps={{ color: "primary" }}
                        />
                      </ListItemButton>
                    </TableCell>
                    <TableCell>{role.description}</TableCell>
                    <TableCell>{role.createdBy}</TableCell>
                    <TableCell>{role.usersAssigned}</TableCell>
                    <TableCell align="center">
                      {!role.updatable ? (
                        <Tooltip title={t("roles_list.role_locked_tooltip")}>
                          <LockOutlined
                            fontSize="inherit"
                            sx={{ verticalAlign: "middle" }}
                          />
                        </Tooltip>
                      ) : (
                        <UpdateRoleContainer
                          roleId={role.id}
                          defaultValues={{
                            name: role.name,
                            description: role.description,
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {(listRoles.data ?? []).length === 0 && <NoResults />}
          </ListLayout>
        );
      }}
    />
  );
};

export default ListRolesContainer;
