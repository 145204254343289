/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateAssetSettlementQuoteModel,
  UpdateAssetModel,
} from '../models/index';
import {
    CreateAssetSettlementQuoteModelFromJSON,
    CreateAssetSettlementQuoteModelToJSON,
    UpdateAssetModelFromJSON,
    UpdateAssetModelToJSON,
} from '../models/index';

export interface CreateAssetSettlementQuoteRequest {
    assetId: number;
    createAssetSettlementQuoteModel?: CreateAssetSettlementQuoteModel;
}

export interface SubmitValuationsRequest {
    assetId: number;
    body?: Blob;
}

export interface UpdateAssetRequest {
    assetId: number;
    updateAssetModel?: UpdateAssetModel;
}

/**
 * 
 */
export class AssetCommandApi extends runtime.BaseAPI {

    /**
     * Create a settlement quote for an asset
     */
    async createAssetSettlementQuoteRaw(requestParameters: CreateAssetSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling createAssetSettlementQuote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["asset-settlement-quotes:manage"]);
        }

        const response = await this.request({
            path: `/assets/{assetId}/settlement-quotes`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAssetSettlementQuoteModelToJSON(requestParameters.createAssetSettlementQuoteModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a settlement quote for an asset
     */
    async createAssetSettlementQuote(requestParameters: CreateAssetSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAssetSettlementQuoteRaw(requestParameters, initOverrides);
    }

    /**
     * Submit a valuations file
     */
    async submitValuationsRaw(requestParameters: SubmitValuationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling submitValuations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["valuations:manage"]);
        }

        const response = await this.request({
            path: `/assets/{assetId}/valuations`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Submit a valuations file
     */
    async submitValuations(requestParameters: SubmitValuationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitValuationsRaw(requestParameters, initOverrides);
    }

    /**
     * Update Asset
     */
    async updateAssetRaw(requestParameters: UpdateAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling updateAsset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["assets:manage"]);
        }

        const response = await this.request({
            path: `/assets/{assetId}`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAssetModelToJSON(requestParameters.updateAssetModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Asset
     */
    async updateAsset(requestParameters: UpdateAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAssetRaw(requestParameters, initOverrides);
    }

}
