import {
  DateRangeControl,
  PortfolioReportFormDialog,
} from "apps/reporting/components";
import { useReportDownload } from "apps/reporting/hooks";
import { PortfolioDateReportDialogFormValues } from "apps/reporting/types";
import { useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { ApiResponse } from "generated";
import { PortfolioQueryApi, ReportQueryApi } from "generated/core/apis";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import startOfYesterdayUTC from "support/form-helpers/start-of-yesterday-utc";
import useSWR from "swr";

interface InstalmentAmountsDueReportProps {
  onClose(): void;
  open: boolean;
}

export default function InstalmentAmountsDueReportContainer({
  onClose,
  open,
}: InstalmentAmountsDueReportProps): ReactElement {
  const { t } = useTranslation("reporting");
  const portfolioQueryApi = useApi(PortfolioQueryApi);
  const reportApi = useApi(ReportQueryApi);
  const portfolios = useSWR(["portfolios"], () =>
    portfolioQueryApi.listPortfolios(),
  );

  const instalmentAmountsDue = (
    data: PortfolioDateReportDialogFormValues,
  ): Promise<ApiResponse<string>> =>
    reportApi.instalmentAmountsDueRaw({
      portfolioId:
        data.portfolio === "All" ? undefined : Number(data.portfolio),
      from: new Date(data.fromDate),
      to: new Date(data.toDate),
    });

  const report = useReportDownload(instalmentAmountsDue, {
    onSuccess: onClose,
  });

  const onSubmit = async (
    data: PortfolioDateReportDialogFormValues,
  ): Promise<void> => report.download(data);

  const portfolioLabel = t("report_dialog.portfolio_label");
  const fromDateLabel = t("report_dialog.from_date_label");
  const toDateLabel = t("report_dialog.to_date_label");

  const resolver = useYupResolver<PortfolioDateReportDialogFormValues>(
    (yup) => {
      return yup.object().shape({
        portfolio: yup.string().isRequired(portfolioLabel),
        fromDate: yup
          .date()
          .localDate()
          .isValidDate(fromDateLabel)
          .isRequired(fromDateLabel),
        toDate: yup
          .date()
          .localDate()
          .isValidDate(toDateLabel)
          .isRequired(toDateLabel),
      });
    },
  );

  return (
    <PortfolioReportFormDialog<PortfolioDateReportDialogFormValues>
      defaultValues={{
        portfolio: "",
        fromDate: startOfYesterdayUTC(),
        toDate: startOfYesterdayUTC(),
      }}
      onClose={onClose}
      onSubmit={onSubmit}
      error={report.error?.message}
      open={open}
      portfolios={[
        { label: t("report_dialog.all_portfolio_option"), value: "All" },
        ...(portfolios.data || []).map((portfolio) => ({
          label: portfolio.name,
          value: String(portfolio.id),
        })),
      ]}
      ready={Boolean(portfolios.data) && !report.isLoading}
      resolver={resolver}
      title={t("report_dialog.instalment_amounts_due.title")}
    >
      {(form): ReactElement => (
        <DateRangeControl
          errors={form.formState.errors}
          register={form.register}
        />
      )}
    </PortfolioReportFormDialog>
  );
}
