import { DateField } from "@ldms/mui-sdk/forms";
import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useListAgreements } from "api/agreements";
import { useListFinancialPostingStages } from "api/financial-postings/stages/listFinancialPostingStages";
import { ControlledTextField } from "common/components";
import { ReactElement, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface Agreement {
  number?: string;
}

export interface FinancialPostingsFilterParameters {
  agreementOrPortfolio: string;
  agreement: { label: string; value: string };
  specifyAgreement: string;
  portfolio: string;
  accountName: string;
  stageGroup: string;
  stageCode: string;
  from: Date;
  to: Date;
}

interface FinancialPostingsFilterParametersProps {
  props: UseFormReturn<FinancialPostingsFilterParameters>;
  portfolios: { label: string; value: string }[];
}

export enum AgreementPortfolioFilter {
  Agreement = "Agreement",
  Portfolio = "Portfolio",
}

interface ViewAgreementNumberProps {
  form: UseFormReturn<FinancialPostingsFilterParameters>;
}

function ViewAgreementNumber({ form }: ViewAgreementNumberProps): ReactElement {
  const { t } = useTranslation("finance");

  return (
    <Controller
      control={form.control}
      name="specifyAgreement"
      render={({ field }): ReactElement => (
        <RadioGroup {...field} row>
          <FormControlLabel
            control={<Radio color="primary" size="small" />}
            label={String(t("financial_postings.filter.all_agreements_label"))}
            value="all"
          />

          <FormControlLabel
            label={String(
              t("financial_postings.filter.specify_agreement_number_label"),
            )}
            value="specifyAgreement"
            control={<Radio color="primary" size="small" />}
          />
        </RadioGroup>
      )}
    />
  );
}

export default function FinancialPostingsFilterParametersContainer({
  props,
  portfolios,
}: FinancialPostingsFilterParametersProps): ReactElement {
  const { t } = useTranslation("finance");
  const [query, setQuery] = useState("");

  const formWatch = props.watch();
  const pleaseSelectDefaultValue = "common:please_select";
  const filterAllOption = "financial_postings.filter.all_option";

  const agreements = useListAgreements({
    params: { query },
  });
  const financialPostingStages = useListFinancialPostingStages();

  const options =
    agreements.data?.results.map((option) => ({
      label: option.number,
      value: String(option.id),
    })) || [];

  const unqiueAccountNames = Array.from(
    new Set(financialPostingStages.data?.map((a) => a.accountCode)),
  ).map((accountCode) => {
    return financialPostingStages.data?.find(
      (a) => a.accountCode === accountCode,
    );
  });

  const unqiueStageGroups = Array.from(
    new Set(financialPostingStages.data?.map((a) => a.stageGroup)),
  ).map((stageGroup) => {
    return financialPostingStages.data?.find(
      (a) => a.stageGroup === stageGroup,
    );
  });

  const unqiueStageNames = Array.from(
    new Set(financialPostingStages.data?.map((a) => a.stageCode)),
  )
    .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
    .map((stageCode) => {
      return financialPostingStages.data?.find(
        (a) => a.stageCode === stageCode,
      );
    });

  const accountNames = [
    {
      label: t(filterAllOption),
      value: "All",
    },
    ...unqiueAccountNames.map((accountName) => ({
      label: accountName?.accountName,
      value: accountName?.accountCode,
    })),
  ];

  const stageGroups = [
    {
      label: t(filterAllOption),
      value: "All",
    },
    ...unqiueStageGroups.map((stageGroup) => ({
      label: stageGroup?.stageGroup,
      value: stageGroup?.sourceCode,
    })),
  ];

  const stageNames = [
    {
      label: t(filterAllOption),
      value: "All",
    },
    ...unqiueStageNames.map((stageName) => ({
      label: stageName?.stageName,
      value: stageName?.stageCode,
    })),
  ];

  return (
    <>
      <Grid item sm={12}>
        <ControlledTextField
          helperText={props.formState.errors.agreementOrPortfolio?.message}
          error={props.formState.errors.agreementOrPortfolio?.message}
          control={props.control}
          id="agreementOrPortfolio"
          name="agreementOrPortfolio"
          label={t("financial_postings.filter.agreement_or_portfolio_label")}
          SelectProps={{ displayEmpty: true }}
          select
          defaultValue=""
          required
        >
          <MenuItem value="">
            <i>{t(pleaseSelectDefaultValue)}</i>
          </MenuItem>

          {Object.values(AgreementPortfolioFilter).map(
            (agreementPortfolioFilter) => (
              <MenuItem
                key={String(agreementPortfolioFilter)}
                value={String(agreementPortfolioFilter)}
              >
                {agreementPortfolioFilter}
              </MenuItem>
            ),
          )}
        </ControlledTextField>
      </Grid>

      {formWatch.agreementOrPortfolio ===
        AgreementPortfolioFilter.Agreement && (
        <Grid item sm={12}>
          <ViewAgreementNumber form={props} />

          {formWatch.specifyAgreement === "specifyAgreement" ? (
            <Controller
              control={props.control}
              name="agreement"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: "15rem" } }}
                  disableClearable
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                    return newValue.label && setQuery(newValue.label);
                  }}
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={t(
                        "financial_postings.filter.agreement_number_label",
                      )}
                      error={Boolean(props.formState.errors.agreement?.label)}
                      helperText={
                        props.formState.errors.agreement?.label?.message
                      }
                      required
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <>
                            {agreements.isValidating && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      onChange={(event) => setQuery(event.currentTarget.value)}
                    />
                  )}
                />
              )}
            />
          ) : (
            <></>
          )}
        </Grid>
      )}

      {formWatch.agreementOrPortfolio ===
        AgreementPortfolioFilter.Portfolio && (
        <Grid item sm={12}>
          <ControlledTextField
            helperText={props.formState.errors.portfolio?.message}
            error={props.formState.errors.portfolio?.message}
            control={props.control}
            id="portfolio"
            name="portfolio"
            label={t("financial_postings.filter.portfolio_label")}
            SelectProps={{ displayEmpty: true }}
            select
            defaultValue=""
            required
          >
            <MenuItem value="">
              <i>{t(pleaseSelectDefaultValue)}</i>
            </MenuItem>

            {portfolios.map((portfolio) => (
              <MenuItem key={portfolio.value} value={portfolio.value}>
                {portfolio.label}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
      )}

      <Grid item sm={12}>
        <ControlledTextField
          helperText={props.formState.errors.accountName?.message}
          error={props.formState.errors.accountName?.message}
          control={props.control}
          id="accountName"
          name="accountName"
          label={t("financial_postings.filter.account_label")}
          SelectProps={{ displayEmpty: true }}
          select
          defaultValue=""
          required
        >
          <MenuItem value="">
            <i>{t(pleaseSelectDefaultValue)}</i>
          </MenuItem>

          {accountNames.map((accountName) => (
            <MenuItem key={accountName?.value} value={accountName?.value}>
              {accountName?.value === "All"
                ? accountName?.label
                : `${accountName?.value} - ${accountName?.label}`}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid item sm={12}>
        <ControlledTextField
          helperText={props.formState.errors.stageGroup?.message}
          error={props.formState.errors.stageGroup?.message}
          control={props.control}
          id="stageGroup"
          name="stageGroup"
          label={t("financial_postings.filter.stage_group_label")}
          SelectProps={{ displayEmpty: true }}
          select
          defaultValue=""
          required
        >
          <MenuItem value="">
            <i>{t(pleaseSelectDefaultValue)}</i>
          </MenuItem>
          {stageGroups.map((stageGroup) => (
            <MenuItem key={stageGroup?.value} value={stageGroup?.value}>
              {stageGroup?.label}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid item sm={12}>
        <ControlledTextField
          helperText={props.formState.errors.stageCode?.message}
          error={props.formState.errors.stageCode?.message}
          control={props.control}
          id="stageCode"
          name="stageCode"
          label={t("financial_postings.filter.stage_name_label")}
          SelectProps={{ displayEmpty: true }}
          select
          defaultValue=""
          required
        >
          <MenuItem value="">
            <i>{t(pleaseSelectDefaultValue)}</i>
          </MenuItem>
          {stageNames.map((stageName) => (
            <MenuItem key={stageName?.value} value={stageName?.value}>
              {stageName?.value === "All"
                ? stageName?.label
                : `${stageName?.value} - ${stageName?.label}`}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid item sm={6}>
        <DateField
          control={props.control}
          name="from"
          error={Boolean(props.formState.errors?.from?.message)}
          helperText={props.formState.errors?.from?.message}
          label={t("financial_postings.filter.from_label")}
          required
        />
      </Grid>
      <Grid item sm={6}>
        <DateField
          label={t("financial_postings.filter.to_label")}
          control={props.control}
          name="to"
          error={Boolean(props.formState.errors?.to?.message)}
          helperText={props.formState.errors?.to?.message}
          required
        />
      </Grid>
    </>
  );
}
