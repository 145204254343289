import { Grid, Stack } from "@mui/material";
import {
  CustomerAgreementsContainer,
  CustomerDetailsContainer,
  CustomerMemosContainer,
} from "apps/servicing/modules/customers/containers";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

export default function OverviewView() {
  const { t } = useTranslation("customers");
  const customer = useCustomer();

  return (
    <ViewLayout title={t("overview.heading")}>
      <ContainedLayout>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8} xl={9}>
            <Stack spacing={2}>
              <CustomerDetailsContainer customerId={customer.data.id} />
              <CustomerAgreementsContainer customerId={customer.data.id} />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4} xl={3}>
            <CustomerMemosContainer customerId={customer.data.systemId} />
          </Grid>
        </Grid>
      </ContainedLayout>
    </ViewLayout>
  );
}
