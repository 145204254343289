import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import { DirectDebitSubmissionPaymentListItemModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

interface DirectDebitDetailsPaymentsListProps {
  data?: DirectDebitSubmissionPaymentListItemModel[];
  totalValue?: string;
  loading: boolean;
}

export default function DirectDebitDetailsPaymentsList({
  data,
  totalValue,
  loading,
}: Readonly<DirectDebitDetailsPaymentsListProps>): ReactElement {
  const { t } = useTranslation("finance");
  const { formatAmount } = useFormat();
  const locale = useLocale();

  const tableHeaders: string[] = [
    t("direct_debit_submissions.details.customer_name_label"),
    t("direct_debit_submissions.details.agreement_number_label"),
    t("direct_debit_submissions.details.reference_label"),
    t("direct_debit_submissions.details.sun_label"),
    t("direct_debit_submissions.details.due_date_label"),
    t("direct_debit_submissions.details.payment_type_label"),
    t("direct_debit_submissions.details.amount_label"),
  ];
  return (
    <>
      <Table
        aria-label={t("direct_debit_submissions.details.title")}
        size="small"
        stickyHeader
      >
        <LoadableTableHead headings={tableHeaders} loading={loading} />
        <TableBody>
          {data?.map((item) => (
            <TableRow key={uuidv4()} hover>
              <TableCell component="th" scope="row">
                {item.customerName ?? item.customerCompanyName}
              </TableCell>
              <TableCell>{item.agreementNumber}</TableCell>
              <TableCell>{item.reference}</TableCell>
              <TableCell>{item.sun}</TableCell>
              <TableCell>
                {item.paymentDueDate && locale.formatDate(item.paymentDueDate)}
              </TableCell>
              <TableCell>{item.paymentType}</TableCell>
              <TableCell>{formatAmount(item.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        {(data ?? []).length !== 0 && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6} align="right" component="th" scope="row">
                {t("direct_debit_submissions.details.total_label")}
              </TableCell>
              <TableCell colSpan={1}>{formatAmount(totalValue)}</TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
      {(data || []).length === 0 && <NoResults />}
    </>
  );
}
