/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AttachmentModel,
  CreateDocumentModel,
  CreatedDocumentModel,
} from '../models/index';
import {
    AttachmentModelFromJSON,
    AttachmentModelToJSON,
    CreateDocumentModelFromJSON,
    CreateDocumentModelToJSON,
    CreatedDocumentModelFromJSON,
    CreatedDocumentModelToJSON,
} from '../models/index';

export interface CreateAgreementAttachmentRequest {
    agreementId: number;
    metadata: AttachmentModel;
    file: Blob;
}

export interface CreateDocumentRequest {
    agreementId: number;
    createDocumentModel?: CreateDocumentModel;
}

/**
 * 
 */
export class AgreementAttachmentCommandApi extends runtime.BaseAPI {

    /**
     * Create an agreement attachment
     */
    async createAgreementAttachmentRaw(requestParameters: CreateAgreementAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling createAgreementAttachment.');
        }

        if (requestParameters.metadata === null || requestParameters.metadata === undefined) {
            throw new runtime.RequiredError('metadata','Required parameter requestParameters.metadata was null or undefined when calling createAgreementAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling createAgreementAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-documents:manage"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.metadata !== undefined) {
            formParams.append('metadata', new Blob([JSON.stringify(AttachmentModelToJSON(requestParameters.metadata))], { type: "application/json", }));
                    }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/attachments`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an agreement attachment
     */
    async createAgreementAttachment(requestParameters: CreateAgreementAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAgreementAttachmentRaw(requestParameters, initOverrides);
    }

    /**
     * Create and attach a document
     */
    async createDocumentRaw(requestParameters: CreateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatedDocumentModel>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling createDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-documents:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/documents`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDocumentModelToJSON(requestParameters.createDocumentModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatedDocumentModelFromJSON(jsonValue));
    }

    /**
     * Create and attach a document
     */
    async createDocument(requestParameters: CreateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatedDocumentModel> {
        const response = await this.createDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
