import { useSession } from "@ldms/mui-sdk/bootstrap";
import { ErrorView } from "@ldms/mui-sdk/views";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { createDisplayName } from "sdk/views/createDisplayName";

export const AppAccess: React.FC<{
  children: React.ReactNode | React.ReactNode[];
  subString: string;
}> = ({ children, subString }) => {
  const { t } = useTranslation();

  const permissionContains = (permissions: Set<string>, subString: string) => {
    return Array.from(permissions).some((permission) =>
      permission.startsWith(subString),
    );
  };

  const session = useSession();

  if (!permissionContains(session.data.permissions, subString)) {
    return (
      <ErrorView
        title={t("restricted_route.title")}
        message={t("restricted_route.message")}
      />
    );
  }

  return <>{children}</>;
};

type PermissionPrefixes =
  | "servicing"
  | "documents"
  | "onboarding"
  | "admin"
  | "finance"
  | "reporting";

export const withAppAccess =
  (subString: PermissionPrefixes) =>
  <P,>(WrappedComponent: React.ComponentType<P>) => {
    const withAppAccess: React.FC<PropsWithChildren<P>> = (props) => (
      <AppAccess subString={subString}>
        <WrappedComponent {...props} />
      </AppAccess>
    );

    withAppAccess.displayName = createDisplayName(
      WrappedComponent,
      withAppAccess.name,
    );

    return withAppAccess;
  };
