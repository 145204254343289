export enum TypeOfLeaseEnum {
  FixedTerm = "finance_lease.fixed_term_option",
  MinimumTerm = "finance_lease.minimum_term_option",
}

export enum OnboardingType {
  EditOnboarding = "EDIT_ONBOARDING",
  CompanyDetails = "SUBMIT_COMPANY_DETAILS",
  CustomerDetails = "SUBMIT_CUSTOMER_DETAILS",
  AssetDetails = "SUBMIT_ASSET_DETAILS",
  AgreementDetails = "SUBMIT_AGREEMENT_DETAILS",
  FinancialDetails = "SUBMIT_FINANCIAL_DETAILS",
  FeesAndCommissions = "SUBMIT_FEES_AND_COMMISSIONS",
  RepaymentTerms = "SUBMIT_REPAYMENT_TERMS",
}

export enum OnboardingStatusEnum {
  Staged = "onboarding_status.staged",
  Review = "onboarding_status.review",
  Rejected = "onboarding_status.rejected",
  Approved = "onboarding_status.approved",
  Completed = "onboarding_status.completed",
}

export enum CommissionTypes {
  Supplier = "Supplier",
  Broker = "Broker",
}

export enum CommissionFieldTypes {
  Amount = "Amount",
  Percent = "Percent",
  Capitalised = "Capitalised",
  FundedVat = "Funded VAT",
}

export enum FeeTypeEnum {
  FrontEnd = "fees.front_end_fee_type",
  BackEnd = "fees.back_end_fee_type",
  Annual = "fees.annual_fee_type",
}
