import { FormDialog } from "@ldms/mui-sdk/templates";
import { Typography } from "@mui/material";
import { useGetAsset } from "api/assets/getAsset";
import { useListAssetManufacturers } from "api/assets/manufacturers/listAssetManufacturers";
import { useListAssetTypes } from "api/assets/types/listAssetTypes";
import { useUpdateAsset } from "api/assets/updateAsset";
import { UpdateAssetFormValues } from "apps/servicing/containers/UpdateAssetContainer/types";
import UpdateAssetFormControls from "apps/servicing/containers/UpdateAssetContainer/UpdateAssetFormControls";
import { useLocale, useYupResolver } from "common/hooks";
import { AssetModel } from "generated/core/models/AssetModel";
import { CategoryModel } from "generated/core/models/CategoryModel";
import { UpdateAssetModel } from "generated/core/models/UpdateAssetModel";
import { ReactElement } from "react";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

type DateUndefinedNull = Date | undefined | null;

const useUpdateAssetDetailsResolver = (
  hpiRegisteredOriginalDate?: Date | null,
): Resolver<UpdateAssetFormValues> => {
  const { t } = useTranslation("assets");

  const checkFieldChanged = (
    hpiRemovedDate?: DateUndefinedNull,
    hpiRegisteredDate?: DateUndefinedNull,
  ): boolean => {
    if (!hpiRegisteredDate || !hpiRemovedDate) {
      return true;
    }

    if (!hpiRegisteredOriginalDate) {
      return true;
    }

    return Boolean(
      hpiRegisteredOriginalDate.toString() === hpiRegisteredDate?.toString(),
    );
  };

  const checkInvalidRemovedDate = (
    hpiRemovedDate?: DateUndefinedNull,
    hpiRegisteredDate?: DateUndefinedNull,
  ): boolean => {
    if (hpiRemovedDate && hpiRegisteredDate) {
      return Boolean(hpiRemovedDate >= hpiRegisteredDate);
    }

    return true;
  };

  return useYupResolver<UpdateAssetFormValues>((yup) => {
    return yup.object().shape({
      typeCode: yup.string().isRequired(t("details.type")),
      description: yup
        .string()
        .isRequired(t("details.description"))
        .maxCharacters(100, t("details.description")),
      newOrUsed: yup.string().isRequired(t("details.new_used")),
      serialNumber: yup.string().maxCharacters(50, t("details.serial_number")),
      registrationNumber: yup
        .string()
        .maxCharacters(20, t("details.registration_number")),
      vehicleIdentificationNumber: yup
        .string()
        .maxCharacters(50, t("details.vin")),
      colour: yup.string().maxCharacters(50, t("details.colour")),
      model: yup.string().maxCharacters(50, t("details.model")),
      bodyType: yup.string().maxCharacters(50, t("details.body_type")),
      registeredKeeper: yup
        .string()
        .maxCharacters(50, t("details.registered_keeper")),
      keeperAddress: yup.object().shape({
        addressLine1: yup
          .string()
          .maxCharacters(50, t("details.address_line_1")),
        addressLine2: yup
          .string()
          .maxCharacters(50, t("details.address_line_2")),
        addressLine3: yup
          .string()
          .maxCharacters(50, t("details.address_line_3")),
        addressLine4: yup
          .string()
          .maxCharacters(50, t("details.address_line_4")),
        postcode: yup.string().maxCharacters(10, t("details.postcode")),
      }),
      purchaseOrderNumber: yup
        .string()
        .maxCharacters(50, t("details.purchase_order_number")),
      yearManufactured: yup
        .number(t("details.year_manufactured_format"))
        .transform((value) => (value ? value : undefined))
        .between(1000, 9999, t("details.year_manufactured_format")),
      hpiRegisteredDate: yup
        .date()
        .nullable()
        .transform((v, o) => (o === "" ? null : v)),
      hpiRemovedDate: yup
        .date()
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .test({
          name: "checkHpiRegisteredDateChanged",
          message: t("details.empty_registered_interest_removed_validation"),
          test: (hpiRemovedDate, hpiRegisteredDate) =>
            checkFieldChanged(
              hpiRemovedDate,
              hpiRegisteredDate.parent.hpiRegisteredDate,
            ),
        })
        .test({
          name: "checkHpiRemovedDateValid",
          message: t("details.invalid_registered_interest_removed_validation"),
          test: (hpiRemovedDate, hpiRegisteredDate) =>
            checkInvalidRemovedDate(
              hpiRemovedDate,
              hpiRegisteredDate.parent.hpiRegisteredDate,
            ),
        }),
      banding: yup.string().maxCharacters(5, t("details.asset_banding_label")),
    });
  });
};

const castDate = (value: Date | undefined) => value || null;
const castNumber = (value: string | undefined) =>
  value ? Number(value) : undefined;
const castString = (value: string | undefined) => value || "";

const mapModelToValues = (model: AssetModel): UpdateAssetFormValues => ({
  hpiRegisteredDate: castDate(model.hpiRegisteredDate),
  hpiRemovedDate: castDate(model.hpiRemovedDate),
  registrationDate: castDate(model.registrationDate),
  purchaseOrderNumber: castString(model.purchaseOrderNumber),
  colour: castString(model.colour),
  typeCode: castString(model.code),
  category: castString(model.category),
  description: castString(model.description),
  serialNumber: castString(model.serialNumber),
  registrationNumber: castString(model.registrationNumber),
  vehicleIdentificationNumber: castString(model.vehicleIdentificationNumber),
  manufacturerCode: castString(model.manufacturerCode),
  model: castString(model.model),
  bodyType: castString(model.bodyType),
  yearManufactured: castNumber(model.yearManufactured),
  newOrUsed: model.newOrUsed,
  engineSize: model.engineSize,
  annualMileage: model.annualMileage,
  registeredKeeper: castString(model.registeredKeeper),
  residualValue: castString(model.residualValue),
  keeperAddress: {
    addressLine1: castString(model.keeperAddress?.addressLine1),
    addressLine2: castString(model.keeperAddress?.addressLine2),
    addressLine3: castString(model.keeperAddress?.addressLine3),
    addressLine4: castString(model.keeperAddress?.addressLine4),
    postcode: castString(model.keeperAddress?.postcode),
  },
  includeInsurance: model.includeInsurance,
  banding: model.banding,
  manufacturerSubsidyPaid: model.manufacturerSubsidyPaid,
});

function nullable<T>(value: T | undefined) {
  return value || undefined;
}

const mapValueToModel = (values: UpdateAssetFormValues): UpdateAssetModel => ({
  ...values,
  keeperAddress: Object.values(values.keeperAddress).some(Boolean)
    ? values.keeperAddress
    : undefined,
  annualMileage: nullable(values.annualMileage),
  bodyType: nullable(values.bodyType),
  category: nullable(values.category as CategoryModel),
  colour: nullable(values.colour),
  engineSize: nullable(values.engineSize),
  manufacturerCode: nullable(values.manufacturerCode),
  model: nullable(values.model),
  residualValue: nullable(values.residualValue),
  hpiRegisteredDate: values.hpiRegisteredDate ?? undefined,
  newOrUsed: values.newOrUsed,
  registrationDate: values.registrationDate ?? undefined,
  yearManufactured: values.yearManufactured
    ? String(values.yearManufactured)
    : undefined,
  hpiRemovedDate: values.hpiRemovedDate ?? undefined,
  vehicleIdentificationNumber: nullable(values.vehicleIdentificationNumber),
  purchaseOrderNumber: nullable(values.purchaseOrderNumber),
  registeredKeeper: nullable(values.registeredKeeper),
  registrationNumber: nullable(values.registrationNumber),
  serialNumber: nullable(values.serialNumber),
  banding: nullable(values.banding),
});

interface UpdateAssetDetailsFormProps {
  assetId: number;
  open: boolean;
  onClose(): void;
  onSuccess(): void;
}

export default function UpdateAssetContainer({
  assetId,
  onClose,
  onSuccess,
  open,
}: UpdateAssetDetailsFormProps) {
  const { t } = useTranslation("assets");
  const asset = useGetAsset(assetId);
  const updateAsset = useUpdateAsset(assetId, { onSuccess });
  const assetTypes = useListAssetTypes();
  const manufacturers = useListAssetManufacturers();
  const locale = useLocale();
  const resolver = useUpdateAssetDetailsResolver(asset.data?.hpiRegisteredDate);

  const handleSubmit = (values: UpdateAssetFormValues): Promise<void> => {
    return updateAsset.command(mapValueToModel(values));
  };

  return (
    <FormDialog
      dialogProps={{ maxWidth: "sm", scroll: "paper" }}
      title={t("details.update_asset")}
      ready={Boolean(asset.data && assetTypes.data && manufacturers.data)}
      defaultValues={asset.data && mapModelToValues(asset.data)}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      resolver={resolver}
    >
      {(form): ReactElement => {
        const formWatch = form.watch();
        const fromDate = formWatch.hpiRegisteredDate
          ? locale.formatDate(formWatch.hpiRegisteredDate)
          : undefined;

        return (
          <>
            <UpdateAssetFormControls
              {...form}
              errors={form.formState.errors}
              control={form.control}
              register={form.register}
              assetTypeOptions={(assetTypes.data || []).map((assetType) => ({
                value: castString(assetType.code),
                label: castString(assetType.type),
              }))}
              manufacturerOptions={(manufacturers.data || []).map(
                (manufacturer) => ({
                  value: castString(manufacturer.code),
                  label: castString(manufacturer.description),
                }),
              )}
              fromDate={fromDate}
              showIncludeInsurance={Boolean(asset.data?.includeInsurance)}
              showManufacturerSubsidyPaid={
                asset.data?.manufacturerSubsidyPaid !== undefined
              }
            />
            {updateAsset.error && (
              <Typography color="error">{updateAsset.error.message}</Typography>
            )}
          </>
        );
      }}
    </FormDialog>
  );
}
