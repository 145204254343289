import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { PortfoliosApi } from "generated/servicing-v2/apis";
import { PortfolioModel } from "generated/servicing-v2/models";
import { useState } from "react";
import errorHandler from "support/error-handler";
import { useSWRConfig } from "swr";
import { keys } from ".";

interface UseUpdatePortfolioReturn {
  command: (portfolio: PortfolioModel) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
}

export const useUpdatePortfolio = (
  id: string,
  options: {
    onSuccess?: () => void;
  } = {},
): UseUpdatePortfolioReturn => {
  const api = useApi(PortfoliosApi, "v2");
  const swr = useSWRConfig();
  const error = useResponseError();
  const [isLoading, setIsLoading] = useState(false);

  const command = async (portfolio: PortfolioModel): Promise<void> => {
    try {
      setIsLoading(true);
      await api.updatePortfolio({
        id,
        portfolioModel: portfolio,
      });
      options.onSuccess?.();
      swr.mutate(keys.detail(id));
      swr.mutate(keys.list());
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
  };
};
