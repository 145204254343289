import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { PortfolioBankAccountsApi } from "generated/servicing-v2/apis";
import { UpdatePortfolioBankAccountModel } from "generated/servicing-v2/models";
import { keys } from ".";

export const useUpdatePortfolioBankAccount = (
  portfolioBankAccountId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(PortfolioBankAccountsApi, "v2");
  const partialMutate = usePartialMutate();

  return useCommand<UpdatePortfolioBankAccountModel, void, ResponseError>(
    (portfolioBankAccount) =>
      api.updatePortfolioBankAccount({
        id: portfolioBankAccountId,
        updatePortfolioBankAccountModel: portfolioBankAccount,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.detail(portfolioBankAccountId));
        options.onSuccess?.();
      },
    },
  );
};
