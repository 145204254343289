import { Box, Typography } from "@mui/material";
import Loader from "common/components/Loader";
import { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface AppErrorProps {
  message?: ReactNode;
  title?: ReactNode;
}

export default function AppError({
  message,
  title,
}: AppErrorProps): ReactElement {
  const { ready, t } = useTranslation();

  return (
    <Loader
      ready={ready}
      render={(): ReactElement => (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          p={3}
        >
          <Typography gutterBottom variant="h4" variantMapping={{ h3: "h1" }}>
            {title || t("error.default")}
          </Typography>
          <Typography>{message || t("error.default_message")}</Typography>
        </Box>
      )}
    />
  );
}
