/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomerContactWithContactPointsListItemModel,
} from '../models/index';
import {
    CustomerContactWithContactPointsListItemModelFromJSON,
    CustomerContactWithContactPointsListItemModelToJSON,
} from '../models/index';

export interface ListContactPointsRequest {
    clientId: number;
    type?: string;
}

/**
 * 
 */
export class ClientContactPointQueryApi extends runtime.BaseAPI {

    /**
     * List Client Contact Point Information
     */
    async listContactPointsRaw(requestParameters: ListContactPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerContactWithContactPointsListItemModel>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listContactPoints.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["contact-points:view"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/contact-points`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerContactWithContactPointsListItemModelFromJSON));
    }

    /**
     * List Client Contact Point Information
     */
    async listContactPoints(requestParameters: ListContactPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerContactWithContactPointsListItemModel>> {
        const response = await this.listContactPointsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
