import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { MenuItem, Typography } from "@mui/material";
import { useUpdateFeeEnabled } from "api/onboarding/fees/updateFeeEnabled";
import useModal from "common/hooks/useModal";
import { UpdateFeeEnabledModel } from "generated/onboarding/models";
import { useTranslation } from "react-i18next";

interface UpdateFeeEnabledContainerProps {
  feeId: number;
  enabled: boolean;
  closeMenu: () => void;
}

const UpdateFeeEnabledContainer = ({
  feeId,
  enabled,
  closeMenu,
}: UpdateFeeEnabledContainerProps) => {
  const { t } = useTranslation("onboardings");
  const updateFeeEnabledModel = useModal();

  const updateFeeEnabled = useUpdateFeeEnabled(feeId, { onSuccess: closeMenu });

  const handleAlertClose = () => {
    closeMenu();
    updateFeeEnabled.reset();
  };

  const onConfirm = async () => {
    const updateFeeEnabledModel: UpdateFeeEnabledModel = {
      enabled: !enabled,
    };
    await updateFeeEnabled.execute(updateFeeEnabledModel);
  };

  const confirmation = !enabled
    ? t("fees.update_enabled.enable_fee_confirmation_message")
    : t("fees.update_enabled.disable_fee_confirmation_message");

  const title = !enabled
    ? t("fees.update_enabled.enable_fee_title")
    : t("fees.update_enabled.disable_fee_title");

  const menuItem = !enabled
    ? t("fees.update_enabled.enable_label")
    : t("fees.update_enabled.disable_label");

  return (
    <>
      <MenuItem key="change-payment-date" onClick={updateFeeEnabledModel.open}>
        {menuItem}
      </MenuItem>
      <ConfirmationDialog
        open={updateFeeEnabledModel.isOpen}
        onConfirm={onConfirm}
        onReject={closeMenu}
        title={title}
        content={<Typography>{confirmation}</Typography>}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />
      <AlertDialog
        title={t("common:error.title")}
        content={updateFeeEnabled.error?.message}
        labels={{ close: t("common:alert.close") }}
        onClose={handleAlertClose}
        open={Boolean(updateFeeEnabled.error?.message)}
      />
    </>
  );
};

export default UpdateFeeEnabledContainer;
