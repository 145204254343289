import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Stack } from "@mui/material";
import { useLocale } from "common/hooks";
import { RepaymentMonthModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface SeasonalInstalment {
  month: RepaymentMonthModel;
  amount: string;
}

export interface RepaymentTermsDetail {
  numberOfPayments: number;
  paymentFrequency: string;
  paymentAmount: string;
  initialRentalPaymentAmount: string;
  inceptionDate?: Date;
  startDate?: Date;
  firstPaymentDate: Date;
  actualCalculatedInterestRate: number;
  customerInterestRate: string;
  brokerRate: string;
  b2bFundingRate?: string;
  seasonalInstalments?: Array<SeasonalInstalment>;
}

const createDateFormatter =
  (format: (date: Date) => string) =>
  (date?: Date): string =>
    date ? format(date) : "-";

interface RepaymentTermsDetailsProps {
  repaymentTerms?: RepaymentTermsDetail;
}

export default function RepaymentTermsDetails({
  repaymentTerms,
}: RepaymentTermsDetailsProps): ReactElement {
  const { t } = useTranslation("agreements");
  const { formatAmount, formatNumber } = useFormat();
  const locale = useLocale();

  const formatDate = createDateFormatter(locale.formatDate);

  return (
    <Stack spacing={3}>
      <DescriptionList label={t("repayment_terms.heading")}>
        <DescriptionList.Item label={t("repayment_terms.number_of_payments")}>
          {formatNumber(repaymentTerms?.numberOfPayments)}
        </DescriptionList.Item>

        <DescriptionList.Item label={t("repayment_terms.payment_frequency")}>
          {repaymentTerms?.paymentFrequency}
        </DescriptionList.Item>

        <DescriptionList.Item label={t("repayment_terms.payment_amount")}>
          {formatAmount(repaymentTerms?.paymentAmount)}
        </DescriptionList.Item>

        <DescriptionList.Item
          label={t("repayment_terms.initial_payment_amount")}
        >
          {formatAmount(repaymentTerms?.initialRentalPaymentAmount)}
        </DescriptionList.Item>

        <DescriptionList.Item label={t("repayment_terms.inception_date")}>
          {formatDate(repaymentTerms?.inceptionDate)}
        </DescriptionList.Item>

        <DescriptionList.Item label={t("repayment_terms.first_payment_date")}>
          {formatDate(repaymentTerms?.firstPaymentDate)}
        </DescriptionList.Item>

        <DescriptionList.Item
          label={t("repayment_terms.actual_calc_interest_rate")}
        >
          {`${locale.formatAmount(
            repaymentTerms?.actualCalculatedInterestRate
              ? repaymentTerms.actualCalculatedInterestRate
              : 0,
          )}%`}
        </DescriptionList.Item>

        <DescriptionList.Item
          label={t("repayment_terms.customer_interest_rate")}
        >
          {`${repaymentTerms?.customerInterestRate}%`}
        </DescriptionList.Item>

        <DescriptionList.Item label={t("repayment_terms.broker_rate")}>
          {`${repaymentTerms?.brokerRate}%`}
        </DescriptionList.Item>

        <DescriptionList.Item label={t("repayment_terms.b2b_funding_rate")}>
          {`${repaymentTerms?.b2bFundingRate}%`}
        </DescriptionList.Item>
      </DescriptionList>

      {repaymentTerms?.seasonalInstalments && (
        <DescriptionList
          label={t("repayment_terms.seasonal_payment_structure_label")}
        >
          <>
            {repaymentTerms.seasonalInstalments.map((month) => (
              <DescriptionList.Item key={month.month} label={month.month}>
                {month.amount}
              </DescriptionList.Item>
            ))}
          </>
        </DescriptionList>
      )}
    </Stack>
  );
}
