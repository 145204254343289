import { PortfolioReportFormDialog } from "apps/reporting/components";
import { usePortfolioReportResolver } from "apps/reporting/hooks";
import { PortfolioReportDialogFormValues } from "apps/reporting/types";
import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { PortfolioQueryApi, ReportQueryApi } from "generated/core/apis";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";
import fileDownloader from "support/file-downloader";
import useSWR from "swr";

interface PortfolioReportProps {
  onClose(): void;
  open: boolean;
}

export default function PortfolioReportContainer({
  onClose,
  open,
}: PortfolioReportProps): ReactElement {
  const { t } = useTranslation("reporting");
  const portfolioQueryApi = useApi(PortfolioQueryApi);
  const reportQueryApi = useApi(ReportQueryApi);
  const portfolios = useSWR(["portfolios"], () =>
    portfolioQueryApi.listPortfolios(),
  );
  const error = useResponseError();

  const resolver = usePortfolioReportResolver();

  const [exportProgress, setExportProgress] = useState(false);

  const onSubmit = async (
    data: PortfolioReportDialogFormValues,
  ): Promise<void> => {
    try {
      error.reset();
      setExportProgress(true);
      const response = await reportQueryApi.portfolioReportRaw({
        portfolioId: Number(data.portfolio),
      });
      await fileDownloader(response);
      onClose();
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setExportProgress(false);
    }
  };

  return (
    <PortfolioReportFormDialog<PortfolioReportDialogFormValues>
      title={t("report_dialog.portfolio.title")}
      error={error.message}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      portfolios={(portfolios.data || []).map((portfolio) => ({
        label: portfolio.name,
        value: String(portfolio.id),
      }))}
      ready={Boolean(portfolios.data) && !exportProgress}
      resolver={resolver}
    />
  );
}
