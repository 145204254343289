import * as agreementKeys from "api/agreements/keys";
import { QueryKey } from "api/types";

export const all = (agreementId: number): QueryKey => [
  ...agreementKeys.detail(agreementId),
  "memos",
];

export const list = (agreementId: number, from?: Date): QueryKey =>
  [...all(agreementId), "list"].concat(from ? [from] : []);
