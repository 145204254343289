import { TableCellButton } from "@ldms/mui-sdk/components";
import {
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import { AgreementAttachmentListItemModel } from "generated/core/models/AgreementAttachmentListItemModel";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface AgreementDocumentsListProps {
  data?: AgreementAttachmentListItemModel[];
  loading: boolean;
  onDocumentDownload(documentId: string): void;
}

export default function AgreementDocumentsList({
  data,
  loading,
  onDocumentDownload,
}: AgreementDocumentsListProps): ReactElement {
  const { t } = useTranslation("agreements");
  const locale = useLocale();

  const tableHeaders: string[] = [
    t("agreement_documents.list.file_name_label"),
    t("agreement_documents.list.type_heading_label"),
    t("agreement_documents.list.description_label"),
    t("agreement_documents.list.date_added_label"),
  ];

  return (
    <>
      <Table
        size="small"
        aria-label={t("agreement_documents.title")}
        stickyHeader
      >
        <LoadableTableHead headings={tableHeaders} loading={loading} />

        <TableBody>
          {data?.map((document) => (
            <TableRow key={document.id} hover>
              <TableCellButton onClick={() => onDocumentDownload(document.id)}>
                <ListItemText
                  primary={document.filename}
                  primaryTypographyProps={{ color: "primary" }}
                />
              </TableCellButton>
              <TableCell>{document.type}</TableCell>
              <TableCell>{document.description}</TableCell>
              <TableCell>{locale.formatDate(document.createdDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(data || []).length === 0 && <NoResults />}
    </>
  );
}
