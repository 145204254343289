import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { AvailableAgreementThirdPartyListItemModel } from "generated/core/models";
import { keys } from ".";

type UseListAvailableAgreementThirdParties = (
  agreementId: number,
  params: { query?: string },
) => UseQueryReturn<AvailableAgreementThirdPartyListItemModel[]>;

export const useListAvailableAgreementThirdParties: UseListAvailableAgreementThirdParties =
  (agreementId: number, params: { query?: string } = {}) => {
    const api = useApi(AgreementQueryApi);

    return useQuery(keys.list(agreementId, params), () =>
      api.listAvailableAgreementThirdParties({
        agreementId: agreementId,
        ...params,
      }),
    );
  };
