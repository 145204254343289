/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BatchMetadataModel,
  BatchModel,
  BatchTypeModel,
} from '../models';
import {
    BatchMetadataModelFromJSON,
    BatchMetadataModelToJSON,
    BatchModelFromJSON,
    BatchModelToJSON,
    BatchTypeModelFromJSON,
    BatchTypeModelToJSON,
} from '../models';

export interface GetBatchRequest {
    batchId: string;
    offset?: number;
    limit?: number;
}

export interface ListBatchesRequest {
    offset?: number;
    limit?: number;
    from?: Date;
    to?: Date;
    status?: string;
    type?: string;
}

export interface PerformBatchFileDownloadRequest {
    batchId: string;
    downloadToken: string;
}

/**
 * 
 */
export class BatchQueryApi extends runtime.BaseAPI {

    /**
     * View Batch metadata
     */
    async getBatchRaw(requestParameters: GetBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BatchMetadataModel>>> {
        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling getBatch.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["batches:view"]);
        }

        const response = await this.request({
            path: `/documents/batches/{batchId}`.replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BatchMetadataModelFromJSON));
    }

    /**
     * View Batch metadata
     */
    async getBatch(requestParameters: GetBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BatchMetadataModel>> {
        const response = await this.getBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the available batch types
     */
    async listBatchTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BatchTypeModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["batches:view"]);
        }

        const response = await this.request({
            path: `/documents/batches/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BatchTypeModelFromJSON));
    }

    /**
     * List the available batch types
     */
    async listBatchTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BatchTypeModel>> {
        const response = await this.listBatchTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List document batches
     */
    async listBatchesRaw(requestParameters: ListBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BatchModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["batches:view"]);
        }

        const response = await this.request({
            path: `/documents/batches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BatchModelFromJSON));
    }

    /**
     * List document batches
     */
    async listBatches(requestParameters: ListBatchesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BatchModel>> {
        const response = await this.listBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Perform a Batch file download
     */
    async performBatchFileDownloadRaw(requestParameters: PerformBatchFileDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling performBatchFileDownload.');
        }

        if (requestParameters.downloadToken === null || requestParameters.downloadToken === undefined) {
            throw new runtime.RequiredError('downloadToken','Required parameter requestParameters.downloadToken was null or undefined when calling performBatchFileDownload.');
        }

        const queryParameters: any = {};

        if (requestParameters.downloadToken !== undefined) {
            queryParameters['downloadToken'] = requestParameters.downloadToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["batches:view"]);
        }

        const response = await this.request({
            path: `/documents/batches/{batchId}/file`.replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Perform a Batch file download
     */
    async performBatchFileDownload(requestParameters: PerformBatchFileDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.performBatchFileDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
