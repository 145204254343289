import useQuery from "common/hooks/useQuery/useQuery";
import { useApi } from "common/providers";
import { AutomationQueryApi } from "generated/core/apis";
import * as keys from "./keys";

const useListAutomationAuditHistory = (automationId: number) => {
  const api = useApi(AutomationQueryApi);

  return useQuery(keys.list(automationId), () =>
    api.listAutomationAuditHistory({ automationId }),
  );
};

export { useListAutomationAuditHistory };
