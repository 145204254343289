import { FormDialog } from "@ldms/mui-sdk/templates";
import { MoreVert } from "@mui/icons-material";
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useUpdateRole } from "api/roles/updateRole";
import { useResponseError, useYupResolver } from "common/hooks";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface UpdateRoleFormValues {
  name: string;
  description: string;
}

const nameLabel = "update_role.name_label";
const descriptionLabel = "update_role.description_label";

const UpdateRoleContainer = ({
  roleId,
  defaultValues,
}: {
  roleId: number;
  defaultValues: { name: string; description: string };
}): ReactElement => {
  const { t } = useTranslation("roles");
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuElement);
  const [open, setOpen] = useState(false);

  const resolver = useYupResolver<UpdateRoleFormValues>((yup) => {
    return yup.object().shape({
      name: yup
        .string()
        .isRequired(t(nameLabel))
        .maxCharacters(255, t(nameLabel)),
      description: yup
        .string()
        .isRequired(t(descriptionLabel))
        .maxCharacters(255, t(descriptionLabel)),
    });
  });

  const error = useResponseError([
    ["role_exists", t("update_role.role_exists_error_message")],
  ]);

  const updateRole = useUpdateRole(roleId, {
    onSuccess: () => {
      setOpen(false);
    },
    onError: (response) => {
      error.setError(response.code);
    },
  });

  const onSubmit = async (data: UpdateRoleFormValues) => {
    await updateRole.execute(data);
    handleMenuClose();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuElement(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setMenuElement(null);
  };

  const handleOpenUpdateDialog = () => setOpen(true);
  const handleCloseUpdateDialog = () => {
    setOpen(false);
    handleMenuClose();
    error.reset();
  };

  return (
    <>
      <IconButton
        aria-label={t("roles_list.edit_role_menu_button")}
        color="primary"
        onClick={handleMenuClick}
        size="small"
        sx={{ padding: 0 }}
      >
        <MoreVert />
      </IconButton>

      <Menu open={menuOpen} anchorEl={menuElement} onClose={handleMenuClose}>
        <MenuItem
          onClick={handleOpenUpdateDialog}
          aria-label={t("update_role.edit_button")}
        >
          {t("update_role.edit_button")}
        </MenuItem>
      </Menu>

      <FormDialog
        dialogProps={{
          maxWidth: "xs",
        }}
        open={open}
        onClose={handleCloseUpdateDialog}
        onSubmit={onSubmit}
        resolver={resolver}
        title={t("update_role.title_label")}
      >
        {(form): ReactElement => (
          <Stack gap={2}>
            <TextField
              {...form.register("name")}
              label={t(nameLabel)}
              error={Boolean(form.formState.errors.name?.message)}
              helperText={form.formState.errors.name?.message}
              disabled={form.formState.isSubmitting}
              defaultValue={defaultValues.name}
              required
            />
            <TextField
              {...form.register("description")}
              label={t(descriptionLabel)}
              error={Boolean(form.formState.errors.description?.message)}
              helperText={form.formState.errors.description?.message}
              disabled={form.formState.isSubmitting}
              defaultValue={defaultValues.description}
              required
              multiline
              rows={3}
            />

            {error.message && (
              <Typography color="error">{error.message}</Typography>
            )}
          </Stack>
        )}
      </FormDialog>
    </>
  );
};

export default UpdateRoleContainer;
