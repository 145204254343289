/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonthlyInstalmentModel
 */
export interface MonthlyInstalmentModel {
    /**
     * Int value representing a month (Jan = 1 to Dec = 12)
     * @type {number}
     * @memberof MonthlyInstalmentModel
     */
    month: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof MonthlyInstalmentModel
     */
    amount: string;
}

/**
 * Check if a given object implements the MonthlyInstalmentModel interface.
 */
export function instanceOfMonthlyInstalmentModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function MonthlyInstalmentModelFromJSON(json: any): MonthlyInstalmentModel {
    return MonthlyInstalmentModelFromJSONTyped(json, false);
}

export function MonthlyInstalmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthlyInstalmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': json['month'],
        'amount': json['amount'],
    };
}

export function MonthlyInstalmentModelToJSON(value?: MonthlyInstalmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'amount': value.amount,
    };
}

