import { Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ActivityList } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { useApi } from "common/providers";
import { formatISO, subMonths } from "date-fns";
import { ClientQueryApi } from "generated/core/apis";
import { ClientActivityModel } from "generated/core/models";
import { ChangeEvent, ReactElement, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorLike } from "support/error-handler";
import useStickySWR from "support/use-sticky-swr";
import useSWR from "swr";

interface ClientActivityProps {
  clientId: number;
}

interface ClientActivityFilterParameters {
  from?: Date;
  type?: string;
  agreementId?: number;
}

function FilterSelect({
  children,
  id,
  label,
  onChange,
  name,
  value,
}: {
  children: ReactNode | ReactNode[];
  id: string;
  label: string;
  name: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  value: string;
}): ReactElement {
  return (
    <TextField
      InputLabelProps={{
        htmlFor: id,
        shrink: true,
      }}
      inputProps={{ displayEmpty: true, id }}
      onChange={onChange}
      label={label}
      name={name}
      select
      size="small"
      value={value}
      variant="outlined"
      margin="none"
    >
      {children}
    </TextField>
  );
}

const sixMonthsAgo = formatISO(subMonths(new Date(), 6), {
  representation: "date",
});

export default function ClientActivity({
  clientId,
}: ClientActivityProps): ReactElement {
  const appConfig = useAppConfiguration();
  const clientApi = useApi(ClientQueryApi);
  const { t } = useTranslation(["clients", "common"]);
  const [filters, setFilters] = useState<
    Record<keyof ClientActivityFilterParameters, string>
  >({
    agreementId: "",
    from: sixMonthsAgo,
    type: "",
  });

  const activity = useStickySWR<ClientActivityModel[], ErrorLike>(
    ["/activity", filters.from, filters.agreementId, filters.type],
    () =>
      clientApi.listClientActivity({
        clientId,
        agreementId: filters.agreementId
          ? Number(filters.agreementId)
          : undefined,
        from: filters.from ? new Date(filters.from) : undefined,
        type: filters.type ? String(filters.type) : undefined,
      }),
  );
  const agreements = useSWR(`/clients/${clientId}/agreements`, () =>
    clientApi.listClientAgreements({ clientId }),
  );

  const activityTypes = useSWR<Map<string | undefined, string>>(
    `/clients/${clientId}/activity-types`,
    async () => {
      const data = await clientApi.listActivityTypes();
      return new Map(data.map((item) => [item.type, item.name]));
    },
  );

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));
  };

  return (
    <ListLayout
      filters={
        <Grid container spacing={1}>
          <Grid item>
            <FilterSelect
              id="from"
              label={t("activity.filter_labels.time")}
              name="from"
              onChange={handleFilterChange}
              value={filters.from}
            >
              <MenuItem value="">
                {t("activity.filter_default_values.time")}
              </MenuItem>
              <MenuItem value={sixMonthsAgo}>{t("last_6_months")}</MenuItem>
            </FilterSelect>
          </Grid>
          <Grid item>
            <FilterSelect
              id="agreementId"
              label={t("activity.filter_labels.agreement_number")}
              name="agreementId"
              onChange={handleFilterChange}
              value={filters.agreementId}
            >
              <MenuItem value="">
                {t("activity.filter_default_values.agreement_number")}
              </MenuItem>
              {agreements.data?.agreements.map(({ id, number }) => (
                <MenuItem value={id} key={id}>
                  {number}
                </MenuItem>
              ))}
            </FilterSelect>
          </Grid>
          <Grid item>
            <FilterSelect
              id="type"
              onChange={handleFilterChange}
              label={t("activity.filter_labels.type")}
              name="type"
              value={filters.type}
            >
              <MenuItem value="">
                {t("activity.filter_default_values.type")}
              </MenuItem>
              {Array.from(activityTypes.data ?? []).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {value}
                </MenuItem>
              ))}
            </FilterSelect>
          </Grid>
        </Grid>
      }
    >
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(activity.data ?? activity.error)}
        render={() => {
          if (activity.error || !activity.data) {
            return (
              <Typography color="error" data-testid="clientActivityList.error">
                {t("common:error.default")}
              </Typography>
            );
          }

          return (
            <ActivityList
              data={activity.data.map((item) => ({
                date: item.created,
                user: item.operatorName,
                action: item.action,
                actionNote: item.description,
                agreementId: item.agreementId,
                agreementNumber: item.agreementNumber,
                agreementHref: `${appConfig.appRoutes.servicing}/agreements/${item.agreementId}`,
              }))}
              loading={activity.isValidating}
              showAgreementId
            />
          );
        }}
      />
    </ListLayout>
  );
}
