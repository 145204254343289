import { QueryKey } from "api";
import { keys } from "api/agreements";

export const all = (agreementId: number): QueryKey => [
  ...keys.detail(agreementId),
  "financial-postings",
];

export const list = (agreementId: number): QueryKey => [
  ...all(agreementId),
  "list",
];
