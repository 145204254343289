/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CommissionCalculationTypeModel = {
    Percentage: 'Percentage',
    Amount: 'Amount'
} as const;
export type CommissionCalculationTypeModel = typeof CommissionCalculationTypeModel[keyof typeof CommissionCalculationTypeModel];


export function CommissionCalculationTypeModelFromJSON(json: any): CommissionCalculationTypeModel {
    return CommissionCalculationTypeModelFromJSONTyped(json, false);
}

export function CommissionCalculationTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommissionCalculationTypeModel {
    return json as CommissionCalculationTypeModel;
}

export function CommissionCalculationTypeModelToJSON(value?: CommissionCalculationTypeModel | null): any {
    return value as any;
}

