/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The agreement transaction
 * @export
 * @interface AgreementTransactionModel
 */
export interface AgreementTransactionModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AgreementTransactionModel
     */
    readonly transactionId?: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AgreementTransactionModel
     */
    date: Date;
    /**
     * The Bank Code
     * @type {string}
     * @memberof AgreementTransactionModel
     */
    bankCode?: string;
    /**
     * The transaction type
     * @type {string}
     * @memberof AgreementTransactionModel
     */
    type: string;
    /**
     * The description of the transaction
     * @type {string}
     * @memberof AgreementTransactionModel
     */
    description?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementTransactionModel
     */
    creditAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementTransactionModel
     */
    debitAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementTransactionModel
     */
    balance: string;
    /**
     * Flag for whether the transaction is returnable or not
     * @type {boolean}
     * @memberof AgreementTransactionModel
     */
    returnable: boolean;
    /**
     * Flag for whether the transaction is transferable or not
     * @type {boolean}
     * @memberof AgreementTransactionModel
     */
    transferable: boolean;
    /**
     * Flag for whether the transaction is removable or not
     * @type {boolean}
     * @memberof AgreementTransactionModel
     */
    removable: boolean;
}

/**
 * Check if a given object implements the AgreementTransactionModel interface.
 */
export function instanceOfAgreementTransactionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "returnable" in value;
    isInstance = isInstance && "transferable" in value;
    isInstance = isInstance && "removable" in value;

    return isInstance;
}

export function AgreementTransactionModelFromJSON(json: any): AgreementTransactionModel {
    return AgreementTransactionModelFromJSONTyped(json, false);
}

export function AgreementTransactionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementTransactionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'date': (new Date(json['date'])),
        'bankCode': !exists(json, 'bankCode') ? undefined : json['bankCode'],
        'type': json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'creditAmount': !exists(json, 'creditAmount') ? undefined : json['creditAmount'],
        'debitAmount': !exists(json, 'debitAmount') ? undefined : json['debitAmount'],
        'balance': json['balance'],
        'returnable': json['returnable'],
        'transferable': json['transferable'],
        'removable': json['removable'],
    };
}

export function AgreementTransactionModelToJSON(value?: AgreementTransactionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString().substring(0,10)),
        'bankCode': value.bankCode,
        'type': value.type,
        'description': value.description,
        'creditAmount': value.creditAmount,
        'debitAmount': value.debitAmount,
        'balance': value.balance,
        'returnable': value.returnable,
        'transferable': value.transferable,
        'removable': value.removable,
    };
}

