/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The asset condition
 * @export
 */
export const NewOrUsedModel = {
    New: 'New',
    Used: 'Used'
} as const;
export type NewOrUsedModel = typeof NewOrUsedModel[keyof typeof NewOrUsedModel];


export function NewOrUsedModelFromJSON(json: any): NewOrUsedModel {
    return NewOrUsedModelFromJSONTyped(json, false);
}

export function NewOrUsedModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewOrUsedModel {
    return json as NewOrUsedModel;
}

export function NewOrUsedModelToJSON(value?: NewOrUsedModel | null): any {
    return value as any;
}

