/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientAgreementListItemModel
 */
export interface ClientAgreementListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof ClientAgreementListItemModel
     */
    readonly id: number;
    /**
     * The agreement number
     * @type {string}
     * @memberof ClientAgreementListItemModel
     */
    number?: string;
    /**
     * The agreement product category
     * @type {string}
     * @memberof ClientAgreementListItemModel
     */
    product?: string;
    /**
     * The agreement status
     * @type {string}
     * @memberof ClientAgreementListItemModel
     */
    status: string;
    /**
     * The agreement term
     * @type {number}
     * @memberof ClientAgreementListItemModel
     */
    term?: number;
    /**
     * The number of assets for the agreement
     * @type {number}
     * @memberof ClientAgreementListItemModel
     */
    numberOfAssets: number;
    /**
     * The number of live assets for the agreement
     * @type {number}
     * @memberof ClientAgreementListItemModel
     */
    numberOfLiveAssets: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ClientAgreementListItemModel
     */
    currentBalance: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ClientAgreementListItemModel
     */
    arrearsBalance: string;
}

/**
 * Check if a given object implements the ClientAgreementListItemModel interface.
 */
export function instanceOfClientAgreementListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "numberOfAssets" in value;
    isInstance = isInstance && "numberOfLiveAssets" in value;
    isInstance = isInstance && "currentBalance" in value;
    isInstance = isInstance && "arrearsBalance" in value;

    return isInstance;
}

export function ClientAgreementListItemModelFromJSON(json: any): ClientAgreementListItemModel {
    return ClientAgreementListItemModelFromJSONTyped(json, false);
}

export function ClientAgreementListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientAgreementListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'product': !exists(json, 'product') ? undefined : json['product'],
        'status': json['status'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'numberOfAssets': json['numberOfAssets'],
        'numberOfLiveAssets': json['numberOfLiveAssets'],
        'currentBalance': json['currentBalance'],
        'arrearsBalance': json['arrearsBalance'],
    };
}

export function ClientAgreementListItemModelToJSON(value?: ClientAgreementListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'product': value.product,
        'status': value.status,
        'term': value.term,
        'numberOfAssets': value.numberOfAssets,
        'numberOfLiveAssets': value.numberOfLiveAssets,
        'currentBalance': value.currentBalance,
        'arrearsBalance': value.arrearsBalance,
    };
}

