import {
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import LoadableTableHead from "common/components/LoadableTableHead";
import NoResults from "common/components/NoResults";
import useLocale from "common/hooks/useLocale";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

type Activity = {
  date: string;
  user: string;
  action?: string;
  actionNote?: string;
  agreementNumber?: string;
  agreementHref?: string;
};

type ActivityWithAgreement = {
  date: string;
  user: string;
  action?: string;
  actionNote?: string;
  agreementNumber: string;
  agreementHref: string;
};

function isActivityWithAgreement(
  activity: Activity | ActivityWithAgreement,
): activity is ActivityWithAgreement {
  return Boolean(activity.agreementNumber && activity.agreementHref);
}

interface ActivityListProps {
  data: Activity[];
  showAgreementId?: boolean;
  loading: boolean;
}

export default function ActivityList(props: ActivityListProps) {
  const { t } = useTranslation(["common"]);
  const locale = useLocale();
  const tableHeaders: string[] = [
    t("date"),
    t("time"),
    t("activity.user"),
    props.showAgreementId ? t("activity.agreement_number") : undefined,
    t("activity.action"),
    t("activity.action_note"),
  ].filter((value): value is string => !!value);

  return (
    <>
      <Table aria-label={t("activity_list.label")} stickyHeader size="small">
        <LoadableTableHead headings={tableHeaders} loading={props.loading} />
        <TableBody>
          {props.data.map((item) => (
            <TableRow key={uuidv4()} hover>
              <TableCell width={1}>
                {locale.formatDate(new Date(item.date))}
              </TableCell>
              <TableCell width={1}>
                {format(new Date(item.date), "HH:mm")}
              </TableCell>
              <TableCell width={200}>{item.user}</TableCell>
              {props.showAgreementId && (
                <TableCell padding="none" width={200}>
                  {isActivityWithAgreement(item) && (
                    <ListItemButton
                      component={RouterLink}
                      dense
                      to={item.agreementHref}
                    >
                      <ListItemText
                        primary={item.agreementNumber}
                        primaryTypographyProps={{ color: "primary" }}
                      />
                    </ListItemButton>
                  )}
                </TableCell>
              )}
              <TableCell width={200}>{item.action}</TableCell>
              <TableCell width={800}>{item.actionNote}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {props.data.length === 0 && <NoResults />}
    </>
  );
}
