import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface ThirdParty {
  id: number;
  name: string;
  type: string;
  contact?: string;
  address?: {
    addressLine1: string;
    addressLine2: string;
    addressLine3?: string;
    addressLine4?: string;
    postcode: string;
  };
  active: boolean;
  vatRegistrationNumber?: string;
}

interface ThirdPartiesListProps {
  data: ThirdParty[];
  loading: boolean;
  onSelect(thirdParties: ThirdParty): void;
}

export default function ThirdPartiesList({
  data,
  loading,
  onSelect,
}: ThirdPartiesListProps): ReactElement {
  const { t } = useTranslation("third-parties");

  const headings = [
    t("list.name_label"),
    t("list.type_label"),
    t("list.contact_label"),
    t("list.vat_registration_number_label"),
    t("list.address_label"),
    t("list.status_label"),
    t("list.action_label"),
  ];

  return (
    <TableContainer>
      <Table aria-label={t("list.list_label")}>
        <LoadableTableHead loading={loading} headings={headings} />
        <TableBody>
          {data?.map((thirdParty) => (
            <TableRow key={thirdParty.id} hover>
              <TableCell>{thirdParty.name}</TableCell>
              <TableCell>{thirdParty.type}</TableCell>
              <TableCell>{thirdParty.contact}</TableCell>
              <TableCell>{thirdParty.vatRegistrationNumber}</TableCell>
              <TableCell>
                {[
                  thirdParty.address?.addressLine1,
                  thirdParty.address?.addressLine2,
                  thirdParty.address?.addressLine3,
                  thirdParty.address?.addressLine4,
                  thirdParty.address?.postcode,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </TableCell>
              <TableCell>
                {thirdParty.active
                  ? t("list.active_label")
                  : t("list.inactive_label")}
              </TableCell>
              <TableCell
                padding="checkbox"
                align="right"
                sx={{ paddingY: 0.25 }}
              >
                <Button onClick={() => onSelect(thirdParty)} size="small">
                  {t("edit.edit_button")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data?.length === 0 && <NoResults />}
    </TableContainer>
  );
}
