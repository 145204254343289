import { QueryKey } from "api";

export const all = (): QueryKey => ["financial-products"];

export const list = (): QueryKey => [...all(), "list"];

export const details = (): QueryKey => [...all(), "details"];

export const detail = (financialProductId: number): QueryKey => [
  ...details(),
  "detail",
  financialProductId,
];
