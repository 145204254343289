import { QueryKey } from "api";
import * as keys from "api/report/keys";

export const all = (): QueryKey => [...keys.all(), "equifax"];

export const list = (equifaxInsightNumber: string): QueryKey => [
  ...all(),
  "list",
  equifaxInsightNumber,
];
