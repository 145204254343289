/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentInstructionDetailsModel
 */
export interface PaymentInstructionDetailsModel {
    /**
     * The payment method
     * @type {string}
     * @memberof PaymentInstructionDetailsModel
     */
    method: string;
    /**
     * The type of schedule
     * @type {string}
     * @memberof PaymentInstructionDetailsModel
     */
    schedule: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof PaymentInstructionDetailsModel
     */
    amount: string;
    /**
     * Payment day
     * @type {number}
     * @memberof PaymentInstructionDetailsModel
     */
    day: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PaymentInstructionDetailsModel
     */
    startDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PaymentInstructionDetailsModel
     */
    endDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PaymentInstructionDetailsModel
     */
    signatureDate?: Date;
    /**
     * Indicates whether the payment instruction is active or not
     * @type {boolean}
     * @memberof PaymentInstructionDetailsModel
     */
    active: boolean;
    /**
     * The reference number associated to the payment instruction
     * @type {string}
     * @memberof PaymentInstructionDetailsModel
     */
    reference?: string;
}

/**
 * Check if a given object implements the PaymentInstructionDetailsModel interface.
 */
export function instanceOfPaymentInstructionDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "method" in value;
    isInstance = isInstance && "schedule" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "day" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function PaymentInstructionDetailsModelFromJSON(json: any): PaymentInstructionDetailsModel {
    return PaymentInstructionDetailsModelFromJSONTyped(json, false);
}

export function PaymentInstructionDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInstructionDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'method': json['method'],
        'schedule': json['schedule'],
        'amount': json['amount'],
        'day': json['day'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (new Date(json['signatureDate'])),
        'active': json['active'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
    };
}

export function PaymentInstructionDetailsModelToJSON(value?: PaymentInstructionDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'method': value.method,
        'schedule': value.schedule,
        'amount': value.amount,
        'day': value.day,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString().substring(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substring(0,10)),
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate.toISOString().substring(0,10)),
        'active': value.active,
        'reference': value.reference,
    };
}

