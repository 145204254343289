import { QueryKey } from "api";
import { keys } from "api/customers";

export const all = (customerId: number | string): QueryKey => [
  ...keys.detail(customerId),
  "contacts",
];

export const list = (customerId: number | string): QueryKey => [
  ...all(customerId),
  "list",
];

export const details = (customerId: number | string): QueryKey => [
  ...all(customerId),
  "detail",
];

export const detail = (
  customerId: number | string,
  contactId?: number | string,
): QueryKey => [...details(customerId), contactId];
