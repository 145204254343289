/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AutomationStatusTypeModel } from './AutomationStatusTypeModel';
import {
    AutomationStatusTypeModelFromJSON,
    AutomationStatusTypeModelFromJSONTyped,
    AutomationStatusTypeModelToJSON,
} from './AutomationStatusTypeModel';

/**
 * 
 * @export
 * @interface AutomationListItemModel
 */
export interface AutomationListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AutomationListItemModel
     */
    readonly id: number;
    /**
     * The name of the portfolio to which the agreement belongs
     * @type {string}
     * @memberof AutomationListItemModel
     */
    portfolioName: string;
    /**
     * The user-defined name of the automation
     * @type {string}
     * @memberof AutomationListItemModel
     */
    name: string;
    /**
     * The user-defined description of the automation
     * @type {string}
     * @memberof AutomationListItemModel
     */
    description?: string;
    /**
     * 
     * @type {AutomationStatusTypeModel}
     * @memberof AutomationListItemModel
     */
    status: AutomationStatusTypeModel;
}

/**
 * Check if a given object implements the AutomationListItemModel interface.
 */
export function instanceOfAutomationListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "portfolioName" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function AutomationListItemModelFromJSON(json: any): AutomationListItemModel {
    return AutomationListItemModelFromJSONTyped(json, false);
}

export function AutomationListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomationListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'portfolioName': json['portfolioName'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'status': AutomationStatusTypeModelFromJSON(json['status']),
    };
}

export function AutomationListItemModelToJSON(value?: AutomationListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portfolioName': value.portfolioName,
        'name': value.name,
        'description': value.description,
        'status': AutomationStatusTypeModelToJSON(value.status),
    };
}

