import {
  AgreementDetailsFormModel,
  CompanyDetailsFormModel,
  CustomerDetailsFormModel,
  CustomInstalmentFormModel,
  OnboardingAssetFormModel,
  OnboardingFeeModel,
  RepaymentTermsFormModel,
} from "apps/onboarding/containers";
import { TypeOfLeaseEnum } from "apps/onboarding/types";
import {
  AssetCategoryModel,
  AssetModel,
  CompanySizeModel,
  CompanyTypeModel,
  CustomInstalmentModel,
  FeeModel,
  FrequencyOfInstalmentsModel,
  OnboardingModel,
  TermModel,
} from "generated/onboarding/models";
import { sumBy } from "lodash";
import {
  FeesAndCommissionsDetailsStateModel,
  FinancialDetailsStateModel,
  OnboardingAssetDetailsStateModel,
  OnboardingState,
} from ".";

export function mapCustomInstalments(
  customInstalments: CustomInstalmentModel[],
): CustomInstalmentFormModel[] {
  return customInstalments.map((instalment) => ({
    startingOn: instalment.startingOn,
    instalmentAmount: Number(instalment.instalmentAmount),
    forNumberOfInstalments: instalment.forNumberOfInstalments,
  }));
}

function mapRepaymentTerms(
  onboarding: OnboardingModel,
): RepaymentTermsFormModel {
  const monthValues = new Array(12).fill(0);
  onboarding.repaymentTerms.instalmentMonths &&
    onboarding.repaymentTerms?.instalmentMonths?.forEach(
      (label) => (monthValues[label.month - 1] = label.amount),
    );

  return {
    paymentStructure: onboarding.repaymentTerms.paymentStructure,
    loanTerm: onboarding.repaymentTerms.term,
    repaymentFrequency: onboarding.repaymentTerms
      .frequencyOfInstalments as FrequencyOfInstalmentsModel,
    frequencyAmount: Number(onboarding.repaymentTerms.instalmentAmount),
    inceptionDate: onboarding.repaymentTerms.inceptionDate,
    firstPaymentDate: onboarding.repaymentTerms.firstInstalmentDueDate,
    secondaryPeriodRentalAmount: Number(
      onboarding.repaymentTerms.secondaryRentalPeriodAmount,
    ),
    firstSecondaryPeriodRentalDate:
      onboarding.repaymentTerms.secondaryRentalDueDate,
    customerInterestRate:
      onboarding.repaymentTerms.interestCalculationRates.customerInterestRate,
    initialRentalPaymentAmount:
      onboarding.repaymentTerms.advancedPayment === "0.00"
        ? undefined
        : Number(onboarding.repaymentTerms.advancedPayment),
    b2bFundingRate:
      onboarding.repaymentTerms.interestCalculationRates.b2bFundingRate,
    rateToBroker:
      onboarding.repaymentTerms.interestCalculationRates.rateToBroker,
    january: monthValues[0],
    february: monthValues[1],
    march: monthValues[2],
    april: monthValues[3],
    may: monthValues[4],
    june: monthValues[5],
    july: monthValues[6],
    august: monthValues[7],
    september: monthValues[8],
    october: monthValues[9],
    november: monthValues[10],
    december: monthValues[11],
    customInstalments: onboarding.repaymentTerms.customInstalments
      ? mapCustomInstalments(onboarding.repaymentTerms.customInstalments)
      : undefined,
  };
}

function mapCustomerDetails(
  onboarding: OnboardingModel,
): CustomerDetailsFormModel {
  return {
    addressLine1: onboarding.customer?.address.addressLine1 || "",
    addressLine2: onboarding.customer?.address.addressLine2 || "",
    addressLine3: onboarding.customer?.address.addressLine3 || "",
    addressLine4: onboarding.customer?.address.addressLine4 || "",
    postcode: onboarding.customer?.address.postcode || "",
    email: onboarding.customer?.email || "",
    telephone: onboarding.customer?.telephoneNumber || "",
    bankName: onboarding.customer?.bankDetails.bankName || "",
    sortCode: onboarding.customer?.bankDetails.sortCode || "",
    accountNumber: onboarding.customer?.bankDetails.accountNumber || "",
    bankAccountName: onboarding.customer?.bankDetails.accountName || "",
    iban: onboarding.customer?.bankDetails.iban,
    bic: onboarding.customer?.bankDetails.bic,
    obligorRiskRating: onboarding.customer?.obligorRiskRating,
  };
}

function mapCompanyDetails(
  onboarding: OnboardingModel,
): CompanyDetailsFormModel {
  return {
    companyType: onboarding.customer?.companyType as CompanyTypeModel,
    companyName: onboarding.customer?.company?.companyName || "",
    tradingName: onboarding?.customer?.company?.companyName || "",
    companyRegistrationNumber:
      onboarding.customer?.company?.companyRegistrationNumber,
    vatRegistrationNumber: onboarding.customer?.company?.vatRegistrationNumber,
    companySize: onboarding.customer?.company?.companySize as CompanySizeModel,
    sectorOfEconomicActivity:
      onboarding.customer?.company?.sectorOfEconomicActivity ?? "",
    companySector: onboarding.customer?.company?.companySector ?? "",
    title: onboarding.customer?.customer?.title || "",
    firstName: onboarding.customer?.customer?.firstName || "",
    middleName: onboarding.customer?.customer?.middleName || "",
    lastName: onboarding.customer?.customer?.lastName || "",
    dateOfBirth: onboarding.customer?.customer?.dateOfBirth,
    nationalIdentificationNumber:
      onboarding.customer?.customer?.nationalIdentificationNumber ?? "",
  };
}

function mapAgreementDetails(
  onboarding: OnboardingModel,
): AgreementDetailsFormModel {
  return {
    portfolio: String(onboarding.portfolio),
    productId: String(onboarding.productId),
    productType: String(onboarding.productId),
    applyBalloonAsLastPayment: false,
    facilityRiskRating: onboarding.facilityRiskRating,
    setupCosts: Number(onboarding.setupCosts),
    maintainerName: onboarding.maintenance?.maintainerName,
    maintenanceAmount: Number(onboarding.maintenance?.amount),
    maintenanceCost: Number(onboarding.maintenance?.cost),
    agreementNumber: onboarding.agreementNumber,
    fundingLine: onboarding.fundingLine,
    lossPool: Number(onboarding.lossPoolPercentage),
    isFundedVat: Boolean(onboarding.isFundedVat),
    vatRate: onboarding.vatRate,
  };
}

function mapAssets(assets: AssetModel[]): OnboardingAssetFormModel[] {
  return assets.map((asset) => ({
    typeCode: asset.assetType,
    description: asset.assetDescription,
    manufacturerDescription: asset.manufacturerDescription,
    newOrUsed: asset.newOrUsed,
    model: asset.model,
    registrationNumber: asset.registrationNumber,
    registrationDate: asset.registrationDate,
    yearOfManufacture: asset.yearManufactured
      ? Number(asset.yearManufactured)
      : undefined,
    serialNumber: asset.serialNumber,
    vehicleIdentificationNumber: asset.vehicleIdentificationNumber,
    costPriceExVat: asset.costPriceNetVat,
    vat: asset.vat,
    hpiRegisteredDate: asset.hpiRegisteredDate,
    includeInsurance: asset.includeInsurance,
    assetCategory: (asset.assetCategory as AssetCategoryModel) || "",
    assetBanding: asset.assetBanding,
    fleetDiscount: Number(asset.fleetDiscount) || undefined,
    serviceProviderId: asset.serviceProviderId
      ? {
          label: "",
          value: asset.serviceProviderId,
        }
      : null,
    manufacturerSubsidy: Number(asset.manufacturerSubsidy) || undefined,
    vatCode: asset.vatCode,
    schemeCode: asset.schemeCode,
  }));
}

function mapAssetDetails(
  onboarding: OnboardingModel,
): OnboardingAssetDetailsStateModel {
  return {
    assets: mapAssets(onboarding?.assets),
    totalNetCashPrice: sumBy(onboarding.assets, (a) => a.costPriceNetVat),
    totalVat: sumBy(onboarding.assets, (a) => a.vat),
  };
}

const leaseTypeMap = new Map([
  [TermModel.FixedTerm, String(TypeOfLeaseEnum.FixedTerm)],
  [TermModel.MinimumTerm, String(TypeOfLeaseEnum.MinimumTerm)],
]);

function mapTypeOfLease(term?: TermModel): string | undefined {
  return term ? leaseTypeMap.get(term) : undefined;
}

const getNumberValue = (value: number | undefined) => value || 0;

const nullableNumber = (value: number | null | undefined) => {
  return value ? Number(value) : undefined;
};

function mapFinancialDetails(
  onboarding: OnboardingModel,
): FinancialDetailsStateModel {
  return {
    balanceFinanced: calculateFinancialDetailsBalanceFinanced(onboarding),
    balloonPayment: Number(onboarding.financialDetails.balloonPayment),
    cashDeposit: Number(onboarding.financialDetails.cashDeposit),
    partExchange: Number(onboarding.financialDetails.partExchange),
    blindDiscount: Number(onboarding.financialDetails.blindDiscount),
    subsidyPayment: Number(onboarding.financialDetails.subsidyPayment),
    fleetDiscount: Number(onboarding.financialDetails.fleetDiscount),
    typeOfLease: mapTypeOfLease(onboarding.financialDetails.leaseType),
    secondaryRentalAmount: nullableNumber(
      Number(onboarding.secondaryRental?.amount),
    ),
    secondaryRentalFrequency: onboarding.secondaryRental?.frequency,
    secondaryRentalInsurancePremium: nullableNumber(
      Number(onboarding.secondaryRental?.insurancePremium),
    ),
    secondaryRentalIntroducerShare: nullableNumber(
      onboarding.secondaryRental?.introducerShare,
    ),
    secondaryRentalMaintenenceCharge: nullableNumber(
      Number(onboarding.secondaryRental?.maintenanceCharge),
    ),
    vatReclaimMonth: onboarding.financialDetails.vatReclaimMonth,
    residualValue: Number(onboarding.financialDetails.residualValue),
  };
}

function calculateFrontEndFees(onboarding: OnboardingModel): number {
  return (
    getNumberValue(
      Number(onboarding.feesAndCommissions?.fees?.arrangementFee),
    ) +
    getNumberValue(
      Number(onboarding.feesAndCommissions?.fees?.documentationFee),
    ) +
    getNumberValue(Number(onboarding.feesAndCommissions?.fees?.facilityFee)) +
    getNumberValue(Number(onboarding.feesAndCommissions?.fees?.legalFee)) +
    getNumberValue(Number(onboarding.feesAndCommissions?.fees?.valuationFee)) +
    getNumberValue(
      Number(onboarding.feesAndCommissions?.fees?.landRegistryFee),
    ) +
    getNumberValue(
      Number(onboarding.feesAndCommissions?.fees?.administrationFee),
    )
  );
}

function calculateBackEndFees(onboarding: OnboardingModel): number {
  return (
    getNumberValue(
      Number(onboarding.feesAndCommissions?.fees?.optionToPurchaseFee),
    ) + getNumberValue(Number(onboarding.feesAndCommissions?.fees?.exitFee))
  );
}

const calculateCommission = (
  totalNetCashPrice: number | undefined,
  percentage: number | undefined,
): number =>
  Number(
    (
      (getNumberValue(totalNetCashPrice) * getNumberValue(percentage)) /
      100
    ).toFixed(2),
  );

const calculateCommissionPercent = (
  totalNetCashPrice: number | undefined,
  amount: number | undefined,
): number =>
  Number(
    (
      (getNumberValue(amount) / getNumberValue(totalNetCashPrice)) *
      100
    ).toFixed(2),
  );

const calculateBrokerCommission = (
  costPriceNewVat: number,
  brokerCommission?: number,
): number => Number(calculateCommission(costPriceNewVat, brokerCommission));

const calculateSupplierCommission = (
  costPriceNewVat: number,
  supplierCommission?: number,
): number => Number(calculateCommission(costPriceNewVat, supplierCommission));

const calculateBrokerCommissionPercent = (
  costPriceNewVat: number,
  brokerCommission?: number,
): number =>
  Number(calculateCommissionPercent(costPriceNewVat, brokerCommission));

const calculateSupplierCommissionPercent = (
  costPriceNewVat: number,
  supplierCommission?: number,
): number =>
  Number(calculateCommissionPercent(costPriceNewVat, supplierCommission));

const capitalisedCommissionAmount = (
  onboarding: OnboardingModel,
  costPriceNewVat: number,
) =>
  (onboarding.feesAndCommissions?.commissions?.applyBrokerAmountToCapital
    ? calculateBrokerCommission(
        costPriceNewVat,
        onboarding.feesAndCommissions?.commissions?.brokerCommission,
      )
    : 0) +
  (onboarding.feesAndCommissions?.commissions?.applySupplierAmountToCapital
    ? calculateSupplierCommission(
        costPriceNewVat,
        onboarding.feesAndCommissions?.commissions?.supplierCommission,
      )
    : 0);

const capitalisedCommissionPercent = (
  onboarding: OnboardingModel,
  costPriceNewVat: number,
) => {
  let commission = 0;

  if (onboarding.feesAndCommissions?.commissions?.applyBrokerAmountToCapital) {
    commission += calculateBrokerCommissionPercent(
      costPriceNewVat,
      onboarding.feesAndCommissions.commissions.brokerCommission,
    );
  }

  if (
    onboarding.feesAndCommissions?.commissions?.applySupplierAmountToCapital
  ) {
    commission += calculateSupplierCommissionPercent(
      costPriceNewVat,
      onboarding.feesAndCommissions.commissions.supplierCommission,
    );
  }

  return commission;
};

function calculateCommissionAmount(
  onboarding: OnboardingModel,
  costPriceNewVat: number,
): number {
  return (
    calculateBrokerCommission(
      costPriceNewVat,
      onboarding.feesAndCommissions?.commissions?.brokerCommission,
    ) +
    calculateSupplierCommission(
      costPriceNewVat,
      onboarding.feesAndCommissions?.commissions?.supplierCommission,
    )
  );
}

const calculateCommissionPercentage = (
  onboarding: OnboardingModel,
  costPriceNewVat: number,
): number =>
  calculateBrokerCommissionPercent(
    costPriceNewVat,
    Number(onboarding.feesAndCommissions?.commissions?.brokerCommissionAmount),
  ) +
  calculateSupplierCommissionPercent(
    costPriceNewVat,
    Number(
      onboarding.feesAndCommissions?.commissions?.supplierCommissionAmount,
    ),
  );

function calculateFinancialDetailsBalanceFinanced(
  onboarding: OnboardingModel,
): number {
  return (
    sumBy(onboarding.assets, (a) => a.costPriceNetVat) -
    getNumberValue(Number(onboarding.financialDetails?.cashDeposit)) -
    getNumberValue(Number(onboarding.financialDetails?.partExchange)) -
    getNumberValue(Number(onboarding.financialDetails?.blindDiscount)) -
    getNumberValue(Number(onboarding.financialDetails?.fleetDiscount)) -
    getNumberValue(Number(onboarding.financialDetails?.subsidyPayment))
  );
}

function calculateFeesAndCommissionsBalanceFinanced(
  onboarding: OnboardingModel,
  costPriceNewVat: number,
): number {
  return (
    calculateFinancialDetailsBalanceFinanced(onboarding) +
    (onboarding.feesAndCommissions?.commissions?.brokerCommission
      ? capitalisedCommissionAmount(onboarding, costPriceNewVat)
      : capitalisedCommissionPercent(onboarding, costPriceNewVat))
  );
}

function mapFees(fees: FeeModel[]): OnboardingFeeModel[] {
  return fees.map((fee) => ({
    id: String(fee.id),
    amount: getNumberValue(Number(fee.amount)),
  }));
}

function mapFeesAndCommissions(
  onboarding: OnboardingModel,
): FeesAndCommissionsDetailsStateModel {
  const costPriceNewVat = sumBy(onboarding.assets, (a) => a.costPriceNetVat);
  return {
    feesList: mapFees(onboarding.feesAndCommissions?.feeList || []),
    frontendFee: calculateFrontEndFees(onboarding),
    backendFee: calculateBackEndFees(onboarding),
    commissionAmount: calculateCommissionAmount(onboarding, costPriceNewVat),
    commissionPercent: calculateCommissionPercentage(
      onboarding,
      costPriceNewVat,
    ),
    balanceFinanced: calculateFeesAndCommissionsBalanceFinanced(
      onboarding,
      costPriceNewVat,
    ),
    arrangementFee: Number(onboarding.feesAndCommissions?.fees?.arrangementFee),
    annualAdministrationFee: Number(
      onboarding.feesAndCommissions?.fees?.annualAdministrationFee,
    ),
    facilityFee: Number(onboarding.feesAndCommissions?.fees?.facilityFee),
    legalFee: Number(onboarding.feesAndCommissions?.fees?.legalFee),
    valuationFee: Number(onboarding.feesAndCommissions?.fees?.valuationFee),
    documentationFee: Number(
      onboarding.feesAndCommissions?.fees?.documentationFee,
    ),
    optionToPurchaseFee: Number(
      onboarding.feesAndCommissions?.fees?.optionToPurchaseFee,
    ),
    landRegistryFee: Number(
      onboarding.feesAndCommissions?.fees?.landRegistryFee,
    ),
    exitFee: Number(onboarding.feesAndCommissions?.fees?.exitFee),
    arrangementFeeDeductedFromAdvance:
      onboarding.feesAndCommissions?.fees?.arrangementFeeDeductedFromAdvance,
    administrationFee: Number(
      onboarding.feesAndCommissions?.fees?.administrationFee,
    ),
    brokerCommission:
      onboarding.feesAndCommissions?.commissions?.brokerCommission,
    supplierCommission:
      onboarding.feesAndCommissions?.commissions?.supplierCommission,
    brokerCommissionAmount: Number(
      onboarding.feesAndCommissions?.commissions?.brokerCommissionAmount,
    ),
    supplierCommissionAmount: Number(
      onboarding.feesAndCommissions?.commissions?.supplierCommissionAmount,
    ),
    applyBrokerAmountToCapital:
      onboarding.feesAndCommissions?.commissions?.applyBrokerAmountToCapital,
    applySupplierAmountToCapital:
      onboarding.feesAndCommissions?.commissions?.applySupplierAmountToCapital,
    isBrokerCommissionFunded:
      onboarding.feesAndCommissions?.commissions?.isBrokerCommissionFundedVat,
    amountOrPercent:
      onboarding.feesAndCommissions?.commissions?.commissionCalculationType,
  };
}

export function mapOnboardingModelToState(
  onboarding: OnboardingModel,
): OnboardingState {
  return {
    existingCustomerId: onboarding.existingCustomerId,
    customerDetails: mapCustomerDetails(onboarding),
    companyDetails: mapCompanyDetails(onboarding),
    assetDetails: mapAssetDetails(onboarding),
    agreementDetails: mapAgreementDetails(onboarding),
    financialDetails: mapFinancialDetails(onboarding),
    feesAndCommissions: mapFeesAndCommissions(onboarding),
    repaymentTerms: mapRepaymentTerms(onboarding),
  };
}
