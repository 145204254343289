import { useEnvironment } from "common/providers/EnvironmentProvider";
import { ReactElement } from "react";

interface DevToggleProps {
  children: ReactElement;
  feature: string;
}

export default function DevToggle({ children, feature }: DevToggleProps) {
  const environment = useEnvironment();
  return environment.isFeatureEnabled(feature) ? <>{children}</> : <></>;
}
