/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommissionsModel } from './CommissionsModel';
import {
    CommissionsModelFromJSON,
    CommissionsModelFromJSONTyped,
    CommissionsModelToJSON,
} from './CommissionsModel';
import type { FeeModel } from './FeeModel';
import {
    FeeModelFromJSON,
    FeeModelFromJSONTyped,
    FeeModelToJSON,
} from './FeeModel';
import type { FeesModel } from './FeesModel';
import {
    FeesModelFromJSON,
    FeesModelFromJSONTyped,
    FeesModelToJSON,
} from './FeesModel';

/**
 * 
 * @export
 * @interface FeesAndCommissionsModel
 */
export interface FeesAndCommissionsModel {
    /**
     * 
     * @type {FeesModel}
     * @memberof FeesAndCommissionsModel
     */
    fees?: FeesModel;
    /**
     * 
     * @type {CommissionsModel}
     * @memberof FeesAndCommissionsModel
     */
    commissions?: CommissionsModel;
    /**
     * 
     * @type {Array<FeeModel>}
     * @memberof FeesAndCommissionsModel
     */
    feeList?: Array<FeeModel>;
}

/**
 * Check if a given object implements the FeesAndCommissionsModel interface.
 */
export function instanceOfFeesAndCommissionsModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeesAndCommissionsModelFromJSON(json: any): FeesAndCommissionsModel {
    return FeesAndCommissionsModelFromJSONTyped(json, false);
}

export function FeesAndCommissionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeesAndCommissionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fees': !exists(json, 'fees') ? undefined : FeesModelFromJSON(json['fees']),
        'commissions': !exists(json, 'commissions') ? undefined : CommissionsModelFromJSON(json['commissions']),
        'feeList': !exists(json, 'feeList') ? undefined : ((json['feeList'] as Array<any>).map(FeeModelFromJSON)),
    };
}

export function FeesAndCommissionsModelToJSON(value?: FeesAndCommissionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fees': FeesModelToJSON(value.fees),
        'commissions': CommissionsModelToJSON(value.commissions),
        'feeList': value.feeList === undefined ? undefined : ((value.feeList as Array<any>).map(FeeModelToJSON)),
    };
}

