/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Apply a settlement quote to an agreement
 * @export
 * @interface ApplySettlementQuoteModel
 */
export interface ApplySettlementQuoteModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ApplySettlementQuoteModel
     */
    discount?: string;
}

/**
 * Check if a given object implements the ApplySettlementQuoteModel interface.
 */
export function instanceOfApplySettlementQuoteModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplySettlementQuoteModelFromJSON(json: any): ApplySettlementQuoteModel {
    return ApplySettlementQuoteModelFromJSONTyped(json, false);
}

export function ApplySettlementQuoteModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplySettlementQuoteModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
    };
}

export function ApplySettlementQuoteModelToJSON(value?: ApplySettlementQuoteModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discount': value.discount,
    };
}

