import useQuery, {
  UseQueryOptions,
  UseQueryReturn,
} from "common/hooks/useQuery";
import { useApi } from "common/providers";
import {
  DirectDebitQueryApi,
  ListDirectDebitSubmissionPaymentsRequest,
} from "generated/core/apis";
import { DirectDebitSubmissionDetailsModel } from "generated/core/models";
import { list } from "./keys";

type UseListDirectDebitSubmissions = (
  params: ListDirectDebitSubmissionPaymentsRequest,
  options?: UseQueryOptions,
) => UseQueryReturn<DirectDebitSubmissionDetailsModel>;

const useListDirectDebitSubmissions: UseListDirectDebitSubmissions = (
  params,
  options = {},
) => {
  const api = useApi(DirectDebitQueryApi);
  const key = list(params);

  return useQuery(
    key,
    () => {
      return api.listDirectDebitSubmissionPayments(params);
    },
    options,
  );
};

export default useListDirectDebitSubmissions;
