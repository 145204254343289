import { DateField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useChangeInstalmentPaymentDate } from "api/agreements/schedule/payment-date";
import { LoadableTableHead } from "common/components";
import { useLocale, useYupResolver } from "common/hooks";
import { useAgreement } from "common/providers";
import {
  add,
  differenceInCalendarMonths,
  getDaysInMonth,
  max,
  min,
  startOfTomorrow,
  sub,
} from "date-fns";
import {
  ChangeInstalmentPaymentDateModel,
  InstalmentListItemModel,
  PaymentFrequencyModel,
} from "generated/core/models";
import { ReactElement, useState } from "react";
import { Controller, Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface ChangePaymentDateFormProps {
  open: boolean;
  onClose: () => void;
  selectedInstalment: InstalmentListItemModel;
  instalmentDateAfterSelected?: Date;
  instalmentDateBeforeSelected?: Date;
  agreementId: number;
  validInstalments: InstalmentListItemModel[];
}

interface ChangePaymentDateContainerProps {
  selectedInstalment: InstalmentListItemModel;
  instalments: InstalmentListItemModel[];
  closeMenu: () => void;
}

interface ChangeOfPaymentDateTableProps {
  validInstalments: InstalmentListItemModel[];
  newInstalmentDate: Date;
  selectedDate: Date;
  instalmentNumber: number;
}

export interface ChangePaymentDateFieldValues {
  newInstalmentDate: Date;
  amendFutureInstalments: boolean;
}

const newInstalmentDateLabel = "repayment_schedule.new_instalment_date_label";

const useChangePaymentDateResolver = (
  minimumAllowedDate: Date,
  maximumAllowedDate: Date,
): Resolver<ChangePaymentDateFieldValues> => {
  const { t } = useTranslation("agreements");
  const { formatDate } = useLocale();
  const newDateLabel = t(newInstalmentDateLabel);

  return useYupResolver<ChangePaymentDateFieldValues>((yup) =>
    yup.object().shape({
      amendFutureInstalments: yup.boolean(),
      newInstalmentDate: yup
        .date()
        .localDate()
        .isValidDate(newDateLabel)
        .min(
          minimumAllowedDate,
          t("repayment_schedule.earliest_available_date_error_message", {
            earliestDate: formatDate(minimumAllowedDate),
          }),
        )
        .max(
          maximumAllowedDate,
          t("repayment_schedule.latest_available_date_error_message", {
            latestDate: formatDate(maximumAllowedDate),
          }),
        )
        .isRequired(newDateLabel),
    }),
  );
};

const getNewDate = (
  selectedDate: Date,
  newInstalmentDate: Date,
  frequency: number,
  interval: number,
) => {
  const monthDifference = differenceInCalendarMonths(
    newInstalmentDate,
    selectedDate,
  );

  const newDay = newInstalmentDate.getDate();
  const newDate = new Date(selectedDate);

  newDate.setMonth(
    newDate.getMonth() + monthDifference + frequency * interval,
    1,
  );
  newDate.setDate(Math.min(newDay, getDaysInMonth(newDate)));

  return newDate;
};

const increment = () => {
  let n = -1;
  return () => {
    n = n + 1;
    return n;
  };
};

function ChangeOfPaymentDateTable({
  validInstalments,
  selectedDate,
  newInstalmentDate,
  instalmentNumber,
}: ChangeOfPaymentDateTableProps) {
  const locale = useLocale();
  const { t } = useTranslation("agreements");
  const filteredInstalments = validInstalments.filter(
    (instalment) => instalment.dueDate && instalment.dueDate >= selectedDate,
  );
  const agreement = useAgreement();
  const frequencyMonths = {
    [PaymentFrequencyModel.Annually]: 12,
    [PaymentFrequencyModel.HalfYearly]: 6,
    [PaymentFrequencyModel.Quarterly]: 3,
    [PaymentFrequencyModel.Monthly]: 1,
    [PaymentFrequencyModel.Irregular]: 1,
  };

  const frequency =
    frequencyMonths[agreement.data?.paymentFrequency as PaymentFrequencyModel];

  const getInstalmentIndex = increment();

  const newDateArray = filteredInstalments.map((instalment) =>
    newInstalmentDate
      ? {
          instalmentNumber: instalment.instalmentNumber,
          oldDate: instalment.dueDate,
          newDate: getNewDate(
            selectedDate,
            newInstalmentDate,
            frequency,
            getInstalmentIndex(),
          ),
          invalid: false,
        }
      : {
          instalmentNumber: instalment.instalmentNumber,
          oldDate: instalment.dueDate,
          newDate: instalment.dueDate,
          invalid: true,
        },
  );

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "200px",
      }}
    >
      <Box sx={{ overflowY: "auto" }}>
        <Table
          aria-label={t("repayment_schedule.table_schedule_preview_label")}
          size="small"
          stickyHeader
        >
          <LoadableTableHead
            headings={[
              t("repayment_schedule.instalment_number_label"),
              t("repayment_schedule.current_instalment_date_label"),
              t(newInstalmentDateLabel),
            ]}
            loading={false}
          />
          <TableBody>
            {newDateArray.map((instalment) => (
              <TableRow key={instalment.instalmentNumber} hover>
                <TableCell>{instalment.instalmentNumber}</TableCell>
                <TableCell>{locale.formatDate(instalment.oldDate)}</TableCell>
                <TableCell>
                  {instalment.invalid
                    ? "-"
                    : locale.formatDate(instalment.newDate)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
}

function ChangePaymentDateForm({
  open,
  onClose,
  agreementId,
  selectedInstalment,
  instalmentDateAfterSelected,
  instalmentDateBeforeSelected,
  validInstalments,
}: ChangePaymentDateFormProps) {
  const { t } = useTranslation("agreements");

  const changePaymentInstalmentDate = useChangeInstalmentPaymentDate(
    agreementId,
    selectedInstalment.instalmentNumber,
    {
      onSuccess: onClose,
    },
  );

  const tomorrow = startOfTomorrow();

  const instalmentDateBeforeSelectedPlusDay =
    instalmentDateBeforeSelected &&
    add(instalmentDateBeforeSelected, { days: 1 });

  const minimumAllowedDate = instalmentDateBeforeSelectedPlusDay
    ? max([instalmentDateBeforeSelectedPlusDay, tomorrow])
    : tomorrow;

  const thirtyDaysAfterSelectedInstalment = add(selectedInstalment.dueDate, {
    days: 30,
  });

  const dayBeforeInstalmentAfterSelected =
    instalmentDateAfterSelected &&
    sub(instalmentDateAfterSelected, {
      days: 1,
    });

  const maximumAllowedDate = dayBeforeInstalmentAfterSelected
    ? min([dayBeforeInstalmentAfterSelected, thirtyDaysAfterSelectedInstalment])
    : thirtyDaysAfterSelectedInstalment;

  const resolver = useChangePaymentDateResolver(
    minimumAllowedDate,
    maximumAllowedDate,
  );

  const onSubmit = async (
    data: ChangePaymentDateFieldValues,
  ): Promise<void> => {
    changePaymentInstalmentDate.reset();
    const changePaymentInstalmentModel: ChangeInstalmentPaymentDateModel = {
      newInstalmentDate: data.newInstalmentDate,
      updateFutureInstalments: data.amendFutureInstalments,
    };
    await changePaymentInstalmentDate.execute(changePaymentInstalmentModel);
  };

  const responseError = new Map([
    [
      "instalment_date_within_dd_window",
      t("repayment_schedule.instalment_date_within_dd_window_error_message"),
    ],
    [
      "selected_instalment_beyond_subsequent_two",
      t(
        "repayment_schedule.selected_instalment_beyond_subsequent_two_error_message",
      ),
    ],
    [
      "new_instalment_date_beyond_subsequent_instalment",
      t(
        "repayment_schedule.new_instalment_date_beyond_subsequent_instalment_error_message",
      ),
    ],
    [
      "new_instalment_date_beyond_30_days",
      t("repayment_schedule.new_instalment_date_beyond_30_days_error_message"),
    ],
    [
      "no_such_instalment",
      t("repayment_schedule.no_such_instalment_error_message"),
    ],
    [
      "no_such_agreement",
      t("repayment_schedule.no_such_agreement_error_message"),
    ],
    [
      "new_instalment_date_before_agreement_start_date",
      t("repayment_schedule.new_instalment_date_before_start_date"),
    ],
    [
      "new_instalment_date_equal_to_agreement_start_date",
      t("repayment_schedule.new_instalment_date_equal_to_start_date"),
    ],
  ]);

  return (
    <FormDialog
      title={t("repayment_schedule.change_payment_date_title")}
      onClose={onClose}
      open={open}
      resolver={resolver}
      onSubmit={onSubmit}
      defaultValues={{
        newInstalmentDate: selectedInstalment.dueDate,
        amendFutureInstalments: false,
      }}
      disabled={changePaymentInstalmentDate.isExecuting}
    >
      {(form) => {
        const formWatch = form.watch();
        return (
          <>
            <Grid container spacing={1}>
              <Grid item sm={12}>
                <TextField
                  label={t("repayment_schedule.instalment_number_label")}
                  disabled={true}
                  defaultValue={selectedInstalment.instalmentNumber}
                />
              </Grid>
              <Grid item sm={12}>
                <DateField
                  name="currentInstalmentDate"
                  label={t("repayment_schedule.current_instalment_date_label")}
                  defaultValue={selectedInstalment.dueDate}
                  disabled={true}
                />
              </Grid>
              <Grid item sm={12}>
                <DateField
                  error={Boolean(
                    form.formState.errors?.newInstalmentDate?.message,
                  )}
                  helperText={form.formState.errors?.newInstalmentDate?.message}
                  control={form.control}
                  name="newInstalmentDate"
                  label={t(newInstalmentDateLabel)}
                  required
                  defaultValue={selectedInstalment.dueDate}
                  inputProps={{
                    min: minimumAllowedDate.toISOString().split("T")[0],
                    max: maximumAllowedDate.toISOString().split("T")[0],
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <FormControlLabel
                  label={String(
                    t("repayment_schedule.amend_future_dates_label"),
                  )}
                  labelPlacement="end"
                  control={
                    <Controller
                      control={form.control}
                      name="amendFutureInstalments"
                      render={({ field }): ReactElement => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e): void =>
                            field.onChange(e.target.checked)
                          }
                        />
                      )}
                    />
                  }
                />
              </Grid>
              {formWatch.amendFutureInstalments && (
                <Grid item sm={12}>
                  <ChangeOfPaymentDateTable
                    validInstalments={validInstalments}
                    newInstalmentDate={formWatch.newInstalmentDate}
                    selectedDate={selectedInstalment.dueDate}
                    instalmentNumber={selectedInstalment.instalmentNumber}
                  />
                </Grid>
              )}
            </Grid>

            {changePaymentInstalmentDate.error && (
              <Box>
                <Typography color="error" data-testid="changePaymentDate.error">
                  {responseError.get(changePaymentInstalmentDate.error.code) ??
                    t("common:error.default")}
                </Typography>
              </Box>
            )}
          </>
        );
      }}
    </FormDialog>
  );
}

export default function ChangePaymentDateContainer({
  selectedInstalment,
  instalments,
  closeMenu,
}: ChangePaymentDateContainerProps) {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const [openChangePaymentDialog, setOpenChangePaymentDialog] = useState(false);

  const openDialog = (): void => {
    setOpenChangePaymentDialog(true);
  };

  const closeDialog = (): void => {
    setOpenChangePaymentDialog(false);
    closeMenu();
  };

  const standAloneFees = instalments.filter(
    (instalment) =>
      Number(instalment.fees) > 0 && Number(instalment.instalment) === 0,
  );

  const isStandaloneFee = (instalment?: InstalmentListItemModel) =>
    standAloneFees.some(
      (feeOnlyInstalment) =>
        feeOnlyInstalment.instalmentNumber === instalment?.instalmentNumber,
    );

  const instalmentDateAfterSelected = isStandaloneFee(
    instalments[selectedInstalment.instalmentNumber],
  )
    ? instalments[selectedInstalment.instalmentNumber + 1]?.dueDate
    : instalments[selectedInstalment.instalmentNumber]?.dueDate;

  const instalmentDateBeforeSelected = isStandaloneFee(
    instalments[selectedInstalment.instalmentNumber - 2],
  )
    ? instalments[selectedInstalment.instalmentNumber - 3]?.dueDate
    : instalments[selectedInstalment.instalmentNumber - 2]?.dueDate;

  const validInstalments = instalments.filter(
    (instalment) => Number(instalment.capital) > 0,
  );

  return (
    <>
      <MenuItem key="change-payment-date" onClick={openDialog}>
        {t("repayment_schedule.change_payment_date_label")}
      </MenuItem>

      <ChangePaymentDateForm
        open={openChangePaymentDialog}
        onClose={closeDialog}
        selectedInstalment={selectedInstalment}
        instalmentDateAfterSelected={instalmentDateAfterSelected}
        instalmentDateBeforeSelected={instalmentDateBeforeSelected}
        agreementId={agreement.id}
        validInstalments={validInstalments}
      />
    </>
  );
}
