import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import { ChequeTransactionModel } from "generated/core/models";
import * as transactionsKeys from "api/agreements/transactions/keys";
import { useSWRConfig } from "swr";

export const useChequeTransaction = (
  agreementId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementCommandApi);
  const { mutate } = useSWRConfig();

  return useCommand<ChequeTransactionModel, void, ResponseError>(
    (model) =>
      api.chequeTransaction({
        agreementId,
        chequeTransactionModel: {
          ...model,
        },
      }),
    {
      ...options,
      onSuccess: () => {
        mutate(transactionsKeys.list(agreementId));
        options.onSuccess?.();
      },
    },
  );
};
