import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material";
import { LoadableTableHead } from "common/components";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

interface AssetInstalmentListItem {
  instalmentNumber: number;
  instalmentDate: Date;
  capital: string;
  interest: string;
  vat: string;
  rental: string;
}

interface InstalmentTotals {
  totalCapital: string;
  totalInterest: string;
  totalVat: string;
  totalRental: string;
}

interface InstalmentsTableProps {
  instalments?: AssetInstalmentListItem[];
  totals?: InstalmentTotals;
  loading: boolean;
}

export default function InstalmentsTable({
  instalments,
  totals,
  loading,
}: InstalmentsTableProps): ReactElement {
  const { t } = useTranslation("assets");
  const { formatAmount } = useFormat();
  const locale = useLocale();
  const tableHeadings: string[] = [
    t("financial_details.instalment_number"),
    t("financial_details.due_date"),
    t("financial_details.capital"),
    t("financial_details.interest"),
    t("financial_details.vat"),
    t("financial_details.rental"),
  ];

  return (
    <Table
      aria-label={t("financial_details.instalments_table_label")}
      stickyHeader
      size="small"
    >
      <LoadableTableHead headings={tableHeadings} loading={loading} />
      <TableBody>
        {instalments?.map((instalment) => (
          <TableRow key={uuidv4()} hover>
            <TableCell>{instalment.instalmentNumber}</TableCell>
            <TableCell>
              {instalment.instalmentDate &&
                locale.formatDate(instalment.instalmentDate)}
            </TableCell>
            <TableCell>{formatAmount(instalment.capital)}</TableCell>
            <TableCell>{formatAmount(instalment.interest)}</TableCell>
            <TableCell>{formatAmount(instalment.vat)}</TableCell>
            <TableCell>{formatAmount(instalment.rental)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter
        sx={(theme) => ({
          "& > tr": {
            "& > td, & > th": {
              bottom: 0,
              height: "35px",
              position: "sticky",
              backgroundColor: theme.palette.background.paper,
              borderTop: `1px solid ${theme.palette.divider}`,
              borderBottom: "none",
            },
          },
        })}
      >
        <TableRow>
          <TableCell colSpan={2} align="right" component="th" scope="row">
            {t("financial_details.totals")}
          </TableCell>
          <TableCell>{formatAmount(totals?.totalCapital)}</TableCell>
          <TableCell>{formatAmount(totals?.totalInterest)}</TableCell>
          <TableCell>{formatAmount(totals?.totalVat)}</TableCell>
          <TableCell>{formatAmount(totals?.totalRental)}</TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}
