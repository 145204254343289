import {
  AgreementDetailsFormModel,
  CompanyDetailsFormModel,
  CustomerDetailsFormModel,
  FeesAndCommissionsFormModel,
  OnboardingAssetFormModel,
  RepaymentTermsFormModel,
} from "apps/onboarding/containers";
import { FinancialDetailsFormModel } from "apps/onboarding/containers/FinancialDetailsStep";
import { OnboardingType } from "apps/onboarding/types";
import { CommissionCalculationTypeModel } from "generated/onboarding/models/CommissionCalculationTypeModel";
import { cloneDeep, sumBy } from "lodash";

const getNumberValue = (value: number | undefined) => value || 0;

const getTotal = (amounts: (number | undefined)[]): number => {
  return getNumberValue(
    amounts.reduce(
      (previousValue, currentValue) =>
        getNumberValue(previousValue) + getNumberValue(currentValue),
      0,
    ),
  );
};

const calculateCommissionAmount = (
  totalNetCashPrice: number | undefined,
  percentage: number | undefined,
): number =>
  Number(
    (
      (getNumberValue(totalNetCashPrice) * getNumberValue(percentage)) /
      100
    ).toFixed(2),
  );

const calculateCommissionPercent = (
  totalNetCashPrice: number | undefined,
  amount: number | undefined,
): number =>
  Number((getNumberValue(amount) / getNumberValue(totalNetCashPrice)) * 100);

export interface OnboardingAssetDetailsStateModel {
  assets: OnboardingAssetFormModel[];
  totalNetCashPrice: number;
  totalVat: number;
}

export interface FeesAndCommissionsDetailsStateModel
  extends FeesAndCommissionsFormModel {
  frontendFee: number;
  backendFee: number;
  commissionAmount: number;
  commissionPercent: number;
  balanceFinanced: number;
  totalFundedVatAmount?: number;
  amountOrPercent?: CommissionCalculationTypeModel;
}

export type FinancialDetailsStateModel = {
  balanceFinanced: number;
  balloonPayment?: number;
  cashDeposit?: number;
  partExchange?: number;
  blindDiscount?: number;
  subsidyPayment?: number;
  fleetDiscount?: number;
} & FinancialDetailsFormModel;

export interface OnboardingState {
  existingCustomerId?: number;
  customerDetails?: CustomerDetailsFormModel;
  companyDetails?: CompanyDetailsFormModel;
  assetDetails?: OnboardingAssetDetailsStateModel;
  agreementDetails?: AgreementDetailsFormModel;
  financialDetails?: Partial<FinancialDetailsStateModel>;
  feesAndCommissions?: FeesAndCommissionsDetailsStateModel;
  repaymentTerms?: Partial<RepaymentTermsFormModel>;
}

type OnboardingAction =
  | { type: OnboardingType.CompanyDetails; payload: CompanyDetailsFormModel }
  | { type: OnboardingType.CustomerDetails; payload: CustomerDetailsFormModel }
  | { type: OnboardingType.AssetDetails; payload: OnboardingAssetFormModel[] }
  | {
      type: OnboardingType.AgreementDetails;
      payload: AgreementDetailsFormModel;
    }
  | {
      type: OnboardingType.FinancialDetails;
      payload: FinancialDetailsFormModel;
    }
  | {
      type: OnboardingType.FeesAndCommissions;
      payload: FeesAndCommissionsFormModel;
    }
  | {
      type: OnboardingType.RepaymentTerms;
      payload: RepaymentTermsFormModel;
    };

const feesAndCommissionsCase = (
  state: OnboardingState,
  payload: FeesAndCommissionsFormModel,
): OnboardingState => {
  const brokerCommissionAmount = calculateCommissionAmount(
    state.assetDetails?.totalNetCashPrice,
    payload.brokerCommission,
  );

  const supplierCommissionAmount = calculateCommissionAmount(
    state.assetDetails?.totalNetCashPrice,
    payload.supplierCommission,
  );

  const capitalisedCommissionAmount = getTotal([
    payload.applyBrokerAmountToCapital ? brokerCommissionAmount : 0,
    payload.applySupplierAmountToCapital ? supplierCommissionAmount : 0,
  ]);

  const commissionAmount = getTotal([
    brokerCommissionAmount,
    supplierCommissionAmount,
  ]);

  const brokerCommissionPercent = calculateCommissionPercent(
    state.assetDetails?.totalNetCashPrice,
    payload.brokerCommissionAmount,
  );

  const supplierCommissionPercent = calculateCommissionPercent(
    state.assetDetails?.totalNetCashPrice,
    payload.supplierCommissionAmount,
  );

  const capitalisedCommissionPercent = getTotal([
    payload.applyBrokerAmountToCapital ? brokerCommissionPercent : 0,
    payload.applySupplierAmountToCapital ? supplierCommissionPercent : 0,
  ]);

  const capitalisedCommissionPercentToAmount = Number(
    (
      (getNumberValue(state.assetDetails?.totalNetCashPrice) *
        capitalisedCommissionPercent) /
      100
    ).toFixed(2),
  );

  const commissionPercent = getTotal([
    brokerCommissionPercent,
    supplierCommissionPercent,
  ]);

  const agreementFundedVat = state.agreementDetails?.isFundedVat
    ? getNumberValue(state.assetDetails?.totalVat)
    : 0;

  const vatRate = getNumberValue(state.agreementDetails?.vatRate);

  const validateFundedVatCommission = payload.brokerCommission
    ? brokerCommissionAmount
    : payload.brokerCommissionAmount || 0;

  const commissionFundedVatAmount = payload.isBrokerCommissionFunded
    ? Number((validateFundedVatCommission * (vatRate / 100)).toFixed(2))
    : 0;

  const totalFundedVatAmount = agreementFundedVat + commissionFundedVatAmount;

  const totalBalanceFinanced = getTotal([
    getNumberValue(state.financialDetails?.balanceFinanced),
    payload.brokerCommission
      ? capitalisedCommissionAmount
      : capitalisedCommissionPercentToAmount,
    commissionFundedVatAmount,
  ]);

  return {
    ...state,
    feesAndCommissions: {
      ...payload,
      frontendFee: getTotal([
        payload.arrangementFee,
        payload.documentationFee,
        payload.facilityFee,
        payload.legalFee,
        payload.valuationFee,
        payload.landRegistryFee,
        payload.administrationFee,
      ]),
      backendFee: getTotal([payload.optionToPurchaseFee, payload.exitFee]) || 0,
      commissionAmount,
      commissionPercent,
      balanceFinanced: totalBalanceFinanced,
      totalFundedVatAmount: totalFundedVatAmount,
      amountOrPercent:
        payload.amountOrPercent === "" ? undefined : payload.amountOrPercent,
    },
  };
};

const handleOnboardingStateChange = (
  state: OnboardingState,
  action: OnboardingAction,
): OnboardingState => {
  switch (action.type) {
    case OnboardingType.CompanyDetails:
      return { ...state, companyDetails: action.payload };
    case OnboardingType.CustomerDetails:
      return { ...state, customerDetails: action.payload };
    case OnboardingType.AgreementDetails:
      const newState = { ...state, agreementDetails: action.payload };
      if (state.agreementDetails?.productId !== action.payload.productId) {
        newState.financialDetails = undefined;
        newState.feesAndCommissions = undefined;
        newState.repaymentTerms = undefined;
        newState.assetDetails = undefined;
      }

      return newState;
    case OnboardingType.AssetDetails:
      return {
        ...state,
        assetDetails: {
          assets: action.payload,
          totalNetCashPrice: sumBy(action.payload, (a) => a.costPriceExVat),
          totalVat: sumBy(action.payload, (a) => a.vat),
        },
        financialDetails: {
          ...state.financialDetails,
          fleetDiscount: sumBy(action.payload, (a) => a.fleetDiscount || 0),
          subsidyPayment: sumBy(
            action.payload,
            (a) => a.manufacturerSubsidy ?? 0,
          ),
        },
      };

    case OnboardingType.FinancialDetails:
      let balanceFinanced = 0;

      const financialDetails = action.payload as FinancialDetailsStateModel;

      const fundedVat = state.agreementDetails?.isFundedVat
        ? state.assetDetails?.totalVat
        : 0;

      balanceFinanced =
        getNumberValue(state.assetDetails?.totalNetCashPrice) -
        getNumberValue(financialDetails?.cashDeposit) -
        getNumberValue(financialDetails?.partExchange) -
        getNumberValue(financialDetails?.blindDiscount) -
        getNumberValue(financialDetails?.fleetDiscount) -
        getNumberValue(financialDetails?.subsidyPayment) +
        getNumberValue(fundedVat);

      return {
        ...state,
        financialDetails: {
          ...action.payload,
          balanceFinanced,
        },
      };

    case OnboardingType.FeesAndCommissions:
      return feesAndCommissionsCase(state, action.payload);
    case OnboardingType.RepaymentTerms:
      return { ...state, repaymentTerms: action.payload };
    default:
      return state;
  }
};

export default function onboardingReducer(
  state: OnboardingState,
  action: OnboardingAction,
): OnboardingState {
  // Need to clone the action to ensure we do not share a reference
  // to the object with React Hook Form to avoid data being deleted
  // as inputs unmount on stepper.next() when submitting
  const clonedAction = cloneDeep(action);
  return handleOnboardingStateChange(state, clonedAction);
}
