import { AmountField, Form, NumberField } from "@ldms/mui-sdk/forms";
import { AlertDialog, Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useListFinancialProducts } from "api/onboarding/financial-products/listFinancialProducts";
import { useListPortfolios } from "api/portfolios/listPortfolios";
import { OnboardingStepActions } from "apps/onboarding/components";
import { useOnboarding } from "apps/onboarding/providers";
import {
  ControlledTextField,
  KeyValueRow,
  KeyValueTable,
  QueryError,
} from "common/components";
import { useResponseError, useYupResolver } from "common/hooks";
import { useApi, useStepper } from "common/providers";
import { AgreementApi } from "generated/onboarding/apis";
import {
  FinancialProductModel,
  ProductTypeModel,
} from "generated/onboarding/models";
import { ChangeEventHandler, ReactElement, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";

export interface AgreementDetailsFormModel {
  portfolio: string;
  productId: string;
  productType: string;
  applyBalloonAsLastPayment: boolean;
  facilityRiskRating?: number;
  setupCosts?: number;
  isFundedVat: boolean;
  vatRate?: number;
  maintainerName?: string;
  maintenanceAmount?: number;
  maintenanceCost?: number;
  agreementNumber?: string;
  fundingLine?: string;
  lossPool?: number;
}

function nullable<T>(value: T | undefined) {
  return value || undefined;
}

export default function AgreementDetailsStep(): ReactElement {
  const { t } = useTranslation("onboardings");
  const onboarding = useOnboarding();
  const portfolios = useListPortfolios();
  const financialProducts = useListFinancialProducts();

  const maintenanceCostLabel = t("agreement_details.maintenance_cost_label");
  const maintenanceAmountLabel = t(
    "agreement_details.maintenance_amount_label",
  );
  const lossPoolLabel = t("agreement_details.loss_pool_label");
  const facilityRiskRatingLabel = t(
    "agreement_details.facility_risk_rating_label",
  );
  const setupCostsLabel = t("agreement_details.setup_costs_label");

  const validateDependents = (
    values: object[],
    value: number | string | undefined,
  ): boolean => {
    if (values.filter(Boolean).length) {
      return Boolean(value);
    }
    return true;
  };

  const transformField = (v: string, o: string): string | null | undefined => {
    return o === "" ? undefined : v;
  };

  const resolver = useYupResolver<AgreementDetailsFormModel>((yup) =>
    yup.object().shape({
      portfolio: yup
        .string()
        .isRequired(t("agreement_details.portfolio_label")),
      productId: yup.string().isRequired(t("agreement_details.product_label")),
      facilityRiskRating: yup
        .number(facilityRiskRatingLabel)
        .nullable()
        .transform(transformField)
        .minAmount(0, facilityRiskRatingLabel)
        .maxAmount(999.99999, facilityRiskRatingLabel),
      setupCosts: yup
        .number(setupCostsLabel)
        .nullable()
        .transform(transformField)
        .minAmount(0, setupCostsLabel)
        .maxAmount(99999999.99, setupCostsLabel),
      maintainerName: yup
        .string()
        .maxCharacters(255, t("agreement_details.maintainer_name_label"))
        .test({
          name: "maintainerNameDependencies",
          message: t("error.invalid_maintainer_name_dependency"),
          test: (maintainerName, testContext) =>
            validateDependents(
              [
                testContext.parent.maintenanceCost,
                testContext.parent.maintenanceAmount,
              ],
              maintainerName,
            ),
        }),
      maintenanceAmount: yup
        .number(maintenanceAmountLabel)
        .test({
          name: "maintenanceCostDependencies",
          message: t("error.invalid_maintenance_amount_dependency"),
          test: (maintenanceAmount, testContext) =>
            validateDependents(
              [
                testContext.parent.maintenanceCost,
                testContext.parent.maintainerName,
              ],
              maintenanceAmount,
            ),
        })
        .nullable()
        .transform(transformField)
        .minAmount(1, maintenanceAmountLabel)
        .maxAmount(99999999.99, maintenanceAmountLabel),
      maintenanceCost: yup
        .number(maintenanceCostLabel)
        .nullable()
        .transform(transformField)
        .minAmount(1, maintenanceCostLabel)
        .maxAmount(99999999.99, maintenanceCostLabel),
      agreementNumber: yup
        .string()
        .nullable()
        .maxCharacters(50, t("agreement_details.agreement_number_label"))
        .matches(
          /^[A-Z0-9/&]+$|^$/i,
          t("agreement_details.invalid_agreement_number"),
        )
        .uppercase(),
      fundingLine: yup
        .string()
        .nullable()
        .maxCharacters(50, t("agreement_details.funding_line_label")),
      lossPool: yup
        .number(lossPoolLabel)
        .nullable()
        .transform(transformField)
        .greaterThanAmount(0, lossPoolLabel)
        .notGreaterThanLimit(100, lossPoolLabel),
    }),
  );

  const stepper = useStepper();
  const api = useApi(AgreementApi);
  const responseError = useResponseError();
  const [isAlertOpen, setAlertOpen] = useState(false);

  const leaseProductTypes = [
    ProductTypeModel.FixedRateOperatingLease,
    ProductTypeModel.FixedRateFinanceLease,
  ] as string[];

  const handleDialogClose = (): void => {
    setAlertOpen(false);
  };

  const handleAgreementExists = async (
    agreementNumber: string | undefined,
  ): Promise<boolean> => {
    responseError.reset();
    if (agreementNumber) {
      try {
        const response = await api.findOnboardedAgreementsRaw({
          agreementNumber,
        });
        setAlertOpen(true);
        return Number(response.raw.headers.get("X-Total-Size")) > 0;
      } catch (error) {
        responseError.setError((await errorHandler(error)).code);
        return true;
      }
    }
    return false;
  };

  const getPortfolioVatRate = (portfolio: number | undefined) => {
    return portfolios.data?.find((p) => p.id === portfolio)?.currentTaxRate;
  };

  const onSubmit = async (data: AgreementDetailsFormModel): Promise<void> => {
    const agreementExists = await handleAgreementExists(data.agreementNumber);

    if (!agreementExists) {
      data.productType = String(
        financialProducts.data?.find((p) => p.id === Number(data.productId))
          ?.type,
      );
      data.applyBalloonAsLastPayment = Boolean(
        financialProducts.data?.find((p) => p.id === Number(data.productId))
          ?.applyBalloonAsLastPayment,
      );

      if (data.productType === ProductTypeModel.FixedRateLoan) {
        data.maintainerName = undefined;
        data.maintenanceAmount = undefined;
        data.maintenanceCost = undefined;
      }

      if (!leaseProductTypes.includes(data.productType as ProductTypeModel)) {
        data.isFundedVat = false;
      }

      if (!data.isFundedVat) {
        data.vatRate = undefined;
      } else {
        data.vatRate = getPortfolioVatRate(Number(data.portfolio));
      }

      onboarding.submitAgreementDetails(data);
      stepper.next();
    }
  };

  const makeHandleProductReset = (
    form: UseFormReturn<AgreementDetailsFormModel>,
  ): ChangeEventHandler<HTMLInputElement> => {
    return (event) => {
      if (event.target.value === "") {
        form.setValue("productId", "");
      }
    };
  };

  const getStringValue = (value: string | undefined) => {
    return value || "";
  };

  return (
    <>
      <AlertDialog
        content={t("agreement_details.duplicate_agreement_number")}
        labels={{ close: t("common:alert.close") }}
        onClose={handleDialogClose}
        open={isAlertOpen}
        title={t("agreement_details.error_title")}
      />
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(portfolios.data || portfolios.error)}
        render={() => {
          if (portfolios.error || !portfolios.data) {
            return <QueryError onRetry={portfolios.refetch} />;
          }
          return (
            <>
              <Box marginBottom={3}>
                <Typography variant="h4" variantMapping={{ h4: "h1" }}>
                  {t("agreement_details.title")}
                </Typography>
              </Box>
              <Form
                label={t("agreement_details.title")}
                onSubmit={onSubmit}
                resolver={resolver}
                defaultValues={{
                  portfolio: getStringValue(
                    onboarding.state.agreementDetails?.portfolio,
                  ),
                  productId: onboarding.state.agreementDetails?.productId ?? "",
                  ...onboarding.state.agreementDetails,
                  productType: getStringValue(
                    onboarding.state.agreementDetails?.productType,
                  ),
                  applyBalloonAsLastPayment:
                    onboarding.state.agreementDetails
                      ?.applyBalloonAsLastPayment ?? false,
                  facilityRiskRating: nullable(
                    onboarding.state.agreementDetails?.facilityRiskRating,
                  ),
                  maintainerName: getStringValue(
                    onboarding.state.agreementDetails?.maintainerName,
                  ),
                  maintenanceAmount: nullable(
                    onboarding.state.agreementDetails?.maintenanceAmount,
                  ),
                  maintenanceCost: nullable(
                    onboarding.state.agreementDetails?.maintenanceCost,
                  ),
                  setupCosts: nullable(
                    onboarding.state.agreementDetails?.setupCosts,
                  ),
                  agreementNumber: nullable(
                    onboarding.state.agreementDetails?.agreementNumber,
                  ),
                  fundingLine: getStringValue(
                    onboarding.state.agreementDetails?.fundingLine,
                  ),
                  lossPool: nullable(
                    onboarding.state.agreementDetails?.lossPool,
                  ),
                }}
              >
                {(form): ReactElement => {
                  return (
                    <>
                      <ControlledTextField
                        helperText={form.formState.errors?.portfolio?.message}
                        SelectProps={{ displayEmpty: true }}
                        error={form.formState.errors?.portfolio?.message}
                        control={form.control}
                        id="portfolio"
                        name="portfolio"
                        label={t("agreement_details.portfolio_label")}
                        onChange={makeHandleProductReset(form)}
                        select
                        required
                      >
                        <MenuItem value="">
                          {t("common:please_select")}
                        </MenuItem>
                        {portfolios.data?.map((portfolio) => (
                          <MenuItem key={portfolio.id} value={portfolio.id}>
                            {portfolio.name}
                          </MenuItem>
                        ))}
                      </ControlledTextField>
                      <ControlledTextField
                        helperText={form.formState.errors?.productId?.message}
                        SelectProps={{ displayEmpty: true }}
                        error={form.formState.errors?.productId?.message}
                        control={form.control}
                        id="productId"
                        name="productId"
                        label={t("agreement_details.product_label")}
                        select
                        required
                        disabled={form.watch("portfolio") === ""}
                      >
                        <MenuItem value="">
                          {t("common:please_select")}
                        </MenuItem>
                        {financialProducts.data?.map(
                          (financialProduct: FinancialProductModel) => (
                            <MenuItem
                              value={financialProduct.id}
                              key={financialProduct.id}
                            >
                              {financialProduct.name}
                            </MenuItem>
                          ),
                        )}
                      </ControlledTextField>

                      <TextField
                        {...form.register("agreementNumber")}
                        label={t("agreement_details.agreement_number_label")}
                        error={Boolean(
                          form.formState.errors.agreementNumber?.message,
                        )}
                        helperText={
                          form.formState.errors.agreementNumber?.message
                        }
                      />

                      <NumberField
                        control={form.control}
                        error={Boolean(
                          form.formState.errors?.facilityRiskRating?.message,
                        )}
                        helperText={
                          form.formState.errors?.facilityRiskRating?.message
                        }
                        label={facilityRiskRatingLabel}
                        name="facilityRiskRating"
                      />
                      <AmountField
                        control={form.control}
                        error={Boolean(
                          form.formState.errors?.setupCosts?.message,
                        )}
                        helperText={form.formState.errors?.setupCosts?.message}
                        label={setupCostsLabel}
                        name="setupCosts"
                      />
                      <TextField
                        {...form.register("fundingLine")}
                        label={t("agreement_details.funding_line_label")}
                        error={Boolean(
                          form.formState.errors.fundingLine?.message,
                        )}
                        helperText={form.formState.errors.fundingLine?.message}
                      />
                      <NumberField
                        control={form.control}
                        error={Boolean(
                          form.formState.errors?.lossPool?.message,
                        )}
                        helperText={form.formState.errors?.lossPool?.message}
                        label={`${lossPoolLabel} %`}
                        name="lossPool"
                      />
                      {leaseProductTypes.includes(
                        financialProducts.data?.find(
                          (p) => p.id === Number(form.watch("productId")),
                        )?.type as ProductTypeModel,
                      ) && (
                        <>
                          <FormControlLabel
                            componentsProps={{
                              typography: { variant: "body2" },
                            }}
                            label={String(
                              t("agreement_details.fund_vat_label"),
                            )}
                            labelPlacement="end"
                            control={
                              <Controller
                                control={form.control}
                                name="isFundedVat"
                                defaultValue={false}
                                render={({
                                  field: { value, ...field },
                                }): ReactElement => (
                                  <Checkbox
                                    {...field}
                                    checked={value}
                                    onChange={(e): void =>
                                      field.onChange(e.target.checked)
                                    }
                                  />
                                )}
                              />
                            }
                          />
                          <FormHelperText
                            error={Boolean(
                              form.formState.errors?.isFundedVat?.message,
                            )}
                            margin="dense"
                          >
                            {form.formState.errors?.isFundedVat?.message}
                          </FormHelperText>
                          {form.watch("isFundedVat") && (
                            <KeyValueTable
                              testId="agreement_details.total_funded_vat_label_table"
                              label={t(
                                "agreement_details.total_funded_vat_label_table",
                              )}
                            >
                              <KeyValueRow
                                align="right"
                                label={t("agreement_details.vat_rate_label")}
                              >
                                {getPortfolioVatRate(
                                  Number(form.watch("portfolio")),
                                )}
                              </KeyValueRow>
                            </KeyValueTable>
                          )}
                        </>
                      )}
                      {leaseProductTypes
                        .concat(ProductTypeModel.FixedRateHirePurchase)
                        .includes(
                          financialProducts.data?.find(
                            (p) => p.id === Number(form.watch("productId")),
                          )?.type as ProductTypeModel,
                        ) && (
                        <>
                          <Box marginY={3}>
                            <Typography
                              variant="h4"
                              variantMapping={{ h4: "h1" }}
                            >
                              {t("agreement_details.maintenance_heading")}
                            </Typography>
                          </Box>
                          <TextField
                            {...form.register("maintainerName")}
                            label={t("agreement_details.maintainer_name_label")}
                            helperText={
                              form.formState.errors.maintainerName?.message
                            }
                            error={Boolean(
                              form.formState.errors.maintainerName,
                            )}
                          />
                          <AmountField
                            control={form.control}
                            error={Boolean(
                              form.formState.errors?.maintenanceAmount?.message,
                            )}
                            helperText={
                              form.formState.errors?.maintenanceAmount?.message
                            }
                            label={maintenanceAmountLabel}
                            name="maintenanceAmount"
                          />
                          <AmountField
                            control={form.control}
                            error={Boolean(
                              form.formState.errors?.maintenanceCost?.message,
                            )}
                            helperText={
                              form.formState.errors?.maintenanceCost?.message
                            }
                            label={maintenanceCostLabel}
                            name="maintenanceCost"
                          />
                        </>
                      )}
                      {responseError.message && (
                        <Typography color="error">
                          {t("common:error.default")}
                        </Typography>
                      )}

                      <OnboardingStepActions
                        back={{
                          label: t("common:stepper.back_button"),
                          onBack: stepper.previous,
                        }}
                        next={{ label: t("common:stepper.next_button") }}
                      />
                    </>
                  );
                }}
              </Form>
            </>
          );
        }}
      />
    </>
  );
}
