/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ServicePlanModel } from './ServicePlanModel';
import {
    ServicePlanModelFromJSON,
    ServicePlanModelFromJSONTyped,
    ServicePlanModelToJSON,
} from './ServicePlanModel';

/**
 * The asset service plan
 * @export
 * @interface AssetServicePlanModel
 */
export interface AssetServicePlanModel {
    /**
     * 
     * @type {Array<ServicePlanModel>}
     * @memberof AssetServicePlanModel
     */
    plans?: Array<ServicePlanModel>;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetServicePlanModel
     */
    startDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetServicePlanModel
     */
    endDate?: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetServicePlanModel
     */
    totalCostPlan?: string;
    /**
     * The number of instalments
     * @type {number}
     * @memberof AssetServicePlanModel
     */
    numberOfInstalments?: number;
    /**
     * The number of services
     * @type {number}
     * @memberof AssetServicePlanModel
     */
    numberOfServices?: number;
    /**
     * A string representation of a number formatted to 2 decimal places with a maximum of 100 and a minimum of 1
     * @type {string}
     * @memberof AssetServicePlanModel
     */
    advanceRate?: string;
    /**
     * The billing date (in months)
     * @type {number}
     * @memberof AssetServicePlanModel
     */
    billingDate?: number;
    /**
     * The number of terms
     * @type {number}
     * @memberof AssetServicePlanModel
     */
    paymentTerms?: number;
    /**
     * The permitted default period
     * @type {number}
     * @memberof AssetServicePlanModel
     */
    permittedDefaultPeriod?: number;
}

/**
 * Check if a given object implements the AssetServicePlanModel interface.
 */
export function instanceOfAssetServicePlanModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssetServicePlanModelFromJSON(json: any): AssetServicePlanModel {
    return AssetServicePlanModelFromJSONTyped(json, false);
}

export function AssetServicePlanModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetServicePlanModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plans': !exists(json, 'plans') ? undefined : ((json['plans'] as Array<any>).map(ServicePlanModelFromJSON)),
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'totalCostPlan': !exists(json, 'totalCostPlan') ? undefined : json['totalCostPlan'],
        'numberOfInstalments': !exists(json, 'numberOfInstalments') ? undefined : json['numberOfInstalments'],
        'numberOfServices': !exists(json, 'numberOfServices') ? undefined : json['numberOfServices'],
        'advanceRate': !exists(json, 'advanceRate') ? undefined : json['advanceRate'],
        'billingDate': !exists(json, 'billingDate') ? undefined : json['billingDate'],
        'paymentTerms': !exists(json, 'paymentTerms') ? undefined : json['paymentTerms'],
        'permittedDefaultPeriod': !exists(json, 'permittedDefaultPeriod') ? undefined : json['permittedDefaultPeriod'],
    };
}

export function AssetServicePlanModelToJSON(value?: AssetServicePlanModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plans': value.plans === undefined ? undefined : ((value.plans as Array<any>).map(ServicePlanModelToJSON)),
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString().substring(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substring(0,10)),
        'totalCostPlan': value.totalCostPlan,
        'numberOfInstalments': value.numberOfInstalments,
        'numberOfServices': value.numberOfServices,
        'advanceRate': value.advanceRate,
        'billingDate': value.billingDate,
        'paymentTerms': value.paymentTerms,
        'permittedDefaultPeriod': value.permittedDefaultPeriod,
    };
}

