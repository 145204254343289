import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { FeesQueryApi } from "generated/onboarding/apis";
import { FeeListItemModel } from "generated/onboarding/models";
import * as keys from "./keys";

type UseListFees = (params?: {
  enabled?: boolean;
}) => UseQueryReturn<FeeListItemModel[]>;

export const useListFees: UseListFees = (params?: { enabled?: boolean }) => {
  const api = useApi(FeesQueryApi);

  return useQuery(keys.list(params), () => {
    return api.listFees({ enabled: params?.enabled });
  });
};
