/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Payment Instructions within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactTypeModel } from './ContactTypeModel';
import {
    ContactTypeModelFromJSON,
    ContactTypeModelFromJSONTyped,
    ContactTypeModelToJSON,
} from './ContactTypeModel';

/**
 * 
 * @export
 * @interface GetPaymentInstructionsAllOfPayeeModel
 */
export interface GetPaymentInstructionsAllOfPayeeModel {
    /**
     * The name of the payee
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfPayeeModel
     */
    name: string;
    /**
     * 
     * @type {ContactTypeModel}
     * @memberof GetPaymentInstructionsAllOfPayeeModel
     */
    type?: ContactTypeModel;
    /**
     * The first line of the address
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfPayeeModel
     */
    addressLine1?: string;
    /**
     * The second line of the address
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfPayeeModel
     */
    addressLine2?: string;
    /**
     * The third line of the address
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfPayeeModel
     */
    addressLine3?: string;
    /**
     * The fourth line of the address
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfPayeeModel
     */
    addressLine4?: string;
    /**
     * The postcode of the address
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfPayeeModel
     */
    postcode?: string;
    /**
     * The payee telephone number
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfPayeeModel
     */
    telephoneNumber?: string;
    /**
     * The payee email address
     * @type {string}
     * @memberof GetPaymentInstructionsAllOfPayeeModel
     */
    email?: string;
}

/**
 * Check if a given object implements the GetPaymentInstructionsAllOfPayeeModel interface.
 */
export function instanceOfGetPaymentInstructionsAllOfPayeeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function GetPaymentInstructionsAllOfPayeeModelFromJSON(json: any): GetPaymentInstructionsAllOfPayeeModel {
    return GetPaymentInstructionsAllOfPayeeModelFromJSONTyped(json, false);
}

export function GetPaymentInstructionsAllOfPayeeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentInstructionsAllOfPayeeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': !exists(json, 'type') ? undefined : ContactTypeModelFromJSON(json['type']),
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'telephoneNumber': !exists(json, 'telephoneNumber') ? undefined : json['telephoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function GetPaymentInstructionsAllOfPayeeModelToJSON(value?: GetPaymentInstructionsAllOfPayeeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': ContactTypeModelToJSON(value.type),
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
        'telephoneNumber': value.telephoneNumber,
        'email': value.email,
    };
}

