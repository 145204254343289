import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Box, Container, Typography } from "@mui/material";
import ReportingContainer from "apps/reporting/containers/ReportingContainer";
import { compose } from "lodash/fp";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { withTranslationLoader } from "sdk/views";

function ReportingView(): ReactElement {
  const { t } = useTranslation("reporting");

  return (
    <>
      <Box marginY={3}>
        <Container maxWidth="xl">
          <Typography component="h1" variant="h2">
            {t("title")}
          </Typography>
        </Container>
      </Box>
      <Box marginY={3}>
        <Container maxWidth="xl">
          <Box aria-label={t("reporting_panel")} role="tabpanel">
            <ReportingContainer />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default compose(
  withTranslationLoader("reporting"),
  withProtectedView({ allowedPermissions: ["reporting:reporting:view"] }),
)(ReportingView);
