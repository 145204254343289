import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ChangeEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface ViewLiveToggleProps {
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  value: "all" | "liveOnly";
}

export default function ViewLiveToggle({
  onChange,
  value = "all",
}: ViewLiveToggleProps): ReactElement {
  const { t } = useTranslation();

  const control = <Radio color="primary" size="small" />;

  return (
    <RadioGroup
      aria-label="live"
      name="live"
      onChange={onChange}
      row
      value={value}
    >
      <FormControlLabel
        control={control}
        label={String(t("view_live_toggle.view_all_label"))}
        value="all"
        data-testid="viewLiveToggle.viewAll"
      />
      <FormControlLabel
        control={control}
        label={String(t("view_live_toggle.view_live_label"))}
        value="liveOnly"
        data-testid="viewLiveToggle.viewLive"
      />
    </RadioGroup>
  );
}
