import { FormDialog, Loader } from "@ldms/mui-sdk/templates";
import { Search } from "@mui/icons-material";
import { Box, TextField, Typography } from "@mui/material";
import { useListAgreements } from "api/agreements";
import AgreementCashFlowList, {
  AgreementCashFlow,
} from "apps/reporting/components/AgreementCashFlowList";
import { useReportDownload } from "apps/reporting/hooks";
import { QueryError } from "common/components";
import { useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { ApiResponse } from "generated";
import { ReportQueryApi } from "generated/core/apis";
import { AgreementListItemModel } from "generated/core/models";
import { debounce } from "lodash";
import {
  ChangeEventHandler,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, FieldError, Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface AgreementCashFlowReportContainerProps {
  open: boolean;
  onClose(): void;
}

export interface AgreementCashFlowFieldValues {
  agreementId: number;
}

const useAgreementCashFlowResolver =
  (): Resolver<AgreementCashFlowFieldValues> => {
    const { t } = useTranslation("reporting");
    const findAgreementLabel = t(
      "report_dialog.agreement_cash_flow.find_agreement_label",
    );

    return useYupResolver<AgreementCashFlowFieldValues>((yup) => {
      return yup.object().shape({
        agreementId: yup
          .number(findAgreementLabel)
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .isRequired(findAgreementLabel),
      });
    });
  };

export default function AgreementCashFlowReportContainer({
  open,
  onClose: onCloseCallback,
}: AgreementCashFlowReportContainerProps): ReactElement {
  const { t } = useTranslation("reporting");
  const reportApi = useApi(ReportQueryApi);
  const [agreementNumberSearch, setAgreementNumberSearch] = useState("");
  const agreements = useListAgreements({
    pageSize: 10,
    params: { agreementNumber: agreementNumberSearch },
  });
  const resolver = useAgreementCashFlowResolver();
  const onQueryChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event): void => {
    setAgreementNumberSearch(event.target.value);
  };

  const onClose = (): void => {
    onCloseCallback();
    setAgreementNumberSearch("");
  };

  const agreementCashFlowApi = async (data: {
    agreementId: number;
  }): Promise<ApiResponse<string>> =>
    reportApi.agreementCashFlowRaw({
      agreementId: data.agreementId,
    });

  const agreementCashFlowReportDownload = useReportDownload(
    agreementCashFlowApi,
    {
      onSuccess: onClose,
    },
  );

  const onSubmit = async (data: { agreementId: number }): Promise<void> => {
    agreementCashFlowReportDownload.download(data);
  };

  const renderWarningMessage = (
    searchTerm: string,
    fieldError: FieldError | undefined,
    resultLength: number,
  ): ReactElement => {
    if (searchTerm && fieldError) {
      return resultLength > 0 ? (
        <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
          {t(
            "report_dialog.agreement_cash_flow.select_radio_button_warning_message",
          )}
        </Typography>
      ) : (
        <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
          {t(
            "report_dialog.agreement_cash_flow.search_agreement_warning_message",
          )}
        </Typography>
      );
    } else {
      return <></>;
    }
  };

  const handleQueryChange = useMemo(() => debounce(onQueryChange, 300), []);
  const mapAgreement = (
    agreement: AgreementListItemModel,
  ): AgreementCashFlow => ({
    ...agreement,
    number: agreement.number,
    customer: {
      id: agreement.customerId,
      name: agreement.customerName,
      companyName: agreement.companyName,
    },
  });
  useEffect(() => {
    return handleQueryChange.cancel;
  }, [handleQueryChange]);

  return (
    <FormDialog
      defaultValues={{ agreementId: undefined }}
      title={t("report_dialog.agreement_cash_flow.title")}
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      resolver={resolver}
      labels={{ submit: t("report_dialog.generate_button") }}
    >
      {(form) => (
        <>
          <Box marginY={3}>
            <Box marginBottom={3}>
              <TextField
                InputProps={{
                  endAdornment: <Search />,
                }}
                inputProps={{
                  "aria-label": t(
                    "report_dialog.agreement_cash_flow.search_label",
                  ),
                }}
                label={t(
                  "report_dialog.agreement_cash_flow.find_agreement_label",
                )}
                margin="none"
                onChange={(event) => {
                  handleQueryChange(event);
                  form.reset();
                }}
                fullWidth
                required
                helperText={
                  !agreementNumberSearch &&
                  form.formState.errors.agreementId?.message
                }
                error={
                  !agreementNumberSearch &&
                  Boolean(form.formState.errors.agreementId)
                }
              />
            </Box>
            {agreementNumberSearch && (
              <Loader
                ready={Boolean(agreements.data ?? agreements.error)}
                render={(): ReactElement => {
                  if (agreements.error || !agreements.data) {
                    return <QueryError onRetry={agreements.refetch} />;
                  }
                  const agreementData =
                    agreements.data.results.map(mapAgreement);
                  return (
                    <Controller
                      control={form.control}
                      name="agreementId"
                      render={({ field: { onChange, value } }) => (
                        <AgreementCashFlowList
                          data={agreementData}
                          loading={agreements.isValidating}
                          onSelectAgreement={onChange}
                          selected={value}
                        />
                      )}
                    />
                  );
                }}
              />
            )}
            {renderWarningMessage(
              agreementNumberSearch,
              form.formState.errors.agreementId,
              agreements?.data?.results.length ?? 0,
            )}
          </Box>
        </>
      )}
    </FormDialog>
  );
}
