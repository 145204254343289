/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VerificationLetterFilterModel } from './VerificationLetterFilterModel';
import {
    VerificationLetterFilterModelFromJSON,
    VerificationLetterFilterModelFromJSONTyped,
    VerificationLetterFilterModelToJSON,
} from './VerificationLetterFilterModel';
import type { VerificationLetterItemModel } from './VerificationLetterItemModel';
import {
    VerificationLetterItemModelFromJSON,
    VerificationLetterItemModelFromJSONTyped,
    VerificationLetterItemModelToJSON,
} from './VerificationLetterItemModel';

/**
 * 
 * @export
 * @interface LetterVerificationModel
 */
export interface LetterVerificationModel {
    /**
     * 
     * @type {boolean}
     * @memberof LetterVerificationModel
     */
    isAllSelected: boolean;
    /**
     * 
     * @type {VerificationLetterFilterModel}
     * @memberof LetterVerificationModel
     */
    filters?: VerificationLetterFilterModel;
    /**
     * 
     * @type {Array<VerificationLetterItemModel>}
     * @memberof LetterVerificationModel
     */
    selectedIds?: Array<VerificationLetterItemModel>;
}

/**
 * Check if a given object implements the LetterVerificationModel interface.
 */
export function instanceOfLetterVerificationModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isAllSelected" in value;

    return isInstance;
}

export function LetterVerificationModelFromJSON(json: any): LetterVerificationModel {
    return LetterVerificationModelFromJSONTyped(json, false);
}

export function LetterVerificationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LetterVerificationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isAllSelected': json['isAllSelected'],
        'filters': !exists(json, 'filters') ? undefined : VerificationLetterFilterModelFromJSON(json['filters']),
        'selectedIds': !exists(json, 'selectedIds') ? undefined : ((json['selectedIds'] as Array<any>).map(VerificationLetterItemModelFromJSON)),
    };
}

export function LetterVerificationModelToJSON(value?: LetterVerificationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isAllSelected': value.isAllSelected,
        'filters': VerificationLetterFilterModelToJSON(value.filters),
        'selectedIds': value.selectedIds === undefined ? undefined : ((value.selectedIds as Array<any>).map(VerificationLetterItemModelToJSON)),
    };
}

