import * as keys from "api/assets/settlement-quotes/keys";
import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AssetQueryApi } from "generated/core/apis";
import { AssetSettlementQuoteSummaryModel } from "generated/core/models";
import { ErrorLike } from "support/error-handler";

type UseGetAssetSettlementQuoteSummary = (
  assetId: number,
) => UseQueryReturn<AssetSettlementQuoteSummaryModel, ErrorLike>;

export const useGetAssetSettlementQuoteSummary: UseGetAssetSettlementQuoteSummary =
  (assetId: number) => {
    const api = useApi(AssetQueryApi);

    return useQuery(keys.details(assetId), () =>
      api.getAssetSettlementQuoteSummary({ assetId: assetId }),
    );
  };
