/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DirectDebitTypeModel } from './DirectDebitTypeModel';
import {
    DirectDebitTypeModelFromJSON,
    DirectDebitTypeModelFromJSONTyped,
    DirectDebitTypeModelToJSON,
} from './DirectDebitTypeModel';

/**
 * The direct debit to be created
 * @export
 * @interface CreateDirectDebitModel
 */
export interface CreateDirectDebitModel {
    /**
     * The client ID
     * @type {number}
     * @memberof CreateDirectDebitModel
     */
    clientId: number;
    /**
     * 
     * @type {DirectDebitTypeModel}
     * @memberof CreateDirectDebitModel
     */
    schedule: DirectDebitTypeModel;
    /**
     * Payment day
     * @type {number}
     * @memberof CreateDirectDebitModel
     */
    day?: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof CreateDirectDebitModel
     */
    startDate: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof CreateDirectDebitModel
     */
    endDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof CreateDirectDebitModel
     */
    signatureDate?: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof CreateDirectDebitModel
     */
    amount?: string;
}

/**
 * Check if a given object implements the CreateDirectDebitModel interface.
 */
export function instanceOfCreateDirectDebitModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "schedule" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function CreateDirectDebitModelFromJSON(json: any): CreateDirectDebitModel {
    return CreateDirectDebitModelFromJSONTyped(json, false);
}

export function CreateDirectDebitModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDirectDebitModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'schedule': DirectDebitTypeModelFromJSON(json['schedule']),
        'day': !exists(json, 'day') ? undefined : json['day'],
        'startDate': (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (new Date(json['signatureDate'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function CreateDirectDebitModelToJSON(value?: CreateDirectDebitModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'schedule': DirectDebitTypeModelToJSON(value.schedule),
        'day': value.day,
        'startDate': (value.startDate.toISOString().substring(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substring(0,10)),
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate.toISOString().substring(0,10)),
        'amount': value.amount,
    };
}

