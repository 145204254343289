import * as keys from "api/assets/settlement-quotes/keys";
import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AssetQueryApi } from "generated/core/apis";
import { SettlementQuoteListItemModel } from "generated/core/models";

type UseListAssetSettlementQuotes = (
  assetId: number,
) => UseQueryReturn<SettlementQuoteListItemModel[]>;

export const useListAssetSettlementQuotes: UseListAssetSettlementQuotes = (
  assetId: number,
) => {
  const api = useApi(AssetQueryApi);

  return useQuery(keys.list(assetId), () =>
    api.listAssetSettlementQuotes({
      assetId,
    }),
  );
};
