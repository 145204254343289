import { useQuery, UseQueryReturn } from "@ldms/mui-sdk/cqrs";
import { keys } from "api/customers";
import { useApi } from "common/providers";
import { ClientQueryApi } from "generated/core/apis";
import { CustomerOverviewModel } from "generated/core/models";
import { CustomersApi } from "generated/servicing-v2/apis";
import { CustomerOverviewModel as CustomerOverviewModelV2 } from "generated/servicing-v2/models";
import { ErrorLike } from "support/error-handler";

type UseGetCustomer = (
  customerId: number,
  params?: { current?: boolean },
) => UseQueryReturn<CustomerOverviewModel, ErrorLike>;

type UseGetCustomerV2 = (
  customerId: string,
) => UseQueryReturn<CustomerOverviewModelV2, ErrorLike>;

export const useGetCustomer: UseGetCustomer = (customerId, params) => {
  const api = useApi(ClientQueryApi);

  return useQuery(keys.detail(customerId), () =>
    api.getClient({
      clientId: customerId,
      ...params,
    }),
  );
};

export const useGetCustomerV2: UseGetCustomerV2 = (customerId) => {
  const api = useApi(CustomersApi, "v2");

  return useQuery(keys.detail(customerId), () =>
    api.getCustomerDetails({
      id: customerId,
    }),
  );
};
