/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Customers within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The customer type
 * @export
 */
export const CustomerTypeModel = {
    LimitedCompany: 'Limited Company',
    LimitedLiabilityPartnership: 'Limited Liability Partnership',
    CommunityInterestCompany: 'Community Interest Company',
    Charity: 'Charity',
    PublicSectorCompany: 'Public Sector Company',
    Partnership: 'Partnership',
    SoleTrader: 'Sole Trader'
} as const;
export type CustomerTypeModel = typeof CustomerTypeModel[keyof typeof CustomerTypeModel];


export function CustomerTypeModelFromJSON(json: any): CustomerTypeModel {
    return CustomerTypeModelFromJSONTyped(json, false);
}

export function CustomerTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerTypeModel {
    return json as CustomerTypeModel;
}

export function CustomerTypeModelToJSON(value?: CustomerTypeModel | null): any {
    return value as any;
}

