import { Box } from "@mui/material";
import ViewLiveToggle from "apps/servicing/components/ViewLiveToggle";
import ListLayout from "common/layouts/ListLayout";
import { ChangeEvent, ReactNode } from "react";

interface ListAssetsParameters {
  live?: boolean;
}

interface AssetListLayoutProps {
  children: ReactNode;
  onParameterChange: (parameters: ListAssetsParameters) => void;
  parameters: ListAssetsParameters;
}

export default function AssetListLayout({
  children,
  onParameterChange,
  parameters,
}: AssetListLayoutProps) {
  const handleViewLiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    onParameterChange({
      live: event.target.value === "liveOnly" ? true : undefined,
    });
  };

  return (
    <ListLayout
      filters={
        <Box marginTop={-0.5}>
          <ViewLiveToggle
            onChange={handleViewLiveChange}
            value={parameters.live ? "liveOnly" : "all"}
          />
        </Box>
      }
    >
      {children}
    </ListLayout>
  );
}
