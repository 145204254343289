import { AccessControl, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { DescriptionList, EditButton } from "@ldms/mui-sdk/components";
import { ConfirmationDialog } from "@ldms/mui-sdk/templates";
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import useActivateAutomation from "api/automations/activateAutomation";
import { useListAutomationAuditHistory } from "api/automations/auditHistory/listAutomationAuditHistory";
import useDeactivateAutomation from "api/automations/deactivateAutomation";
import { useGetAutomation } from "api/automations/getAutomation";
import { LoadableTableHead, Loader, QueryError } from "common/components";
import Surface from "common/components/Surface/Surface";
import { useConfirm, useLocale } from "common/hooks";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout/ViewLayout";
import { AutomationStatusTypeModel } from "generated/core/models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const AutomationDetailsView = ({ automationId }: { automationId: string }) => {
  const { t } = useTranslation(["automations"]);
  const [status, setStatus] = useState<AutomationStatusTypeModel | undefined>(
    undefined,
  );
  const automationDetails = useGetAutomation(Number(automationId));
  const confirm = useConfirm();

  const activateAutomation = useActivateAutomation(Number(automationId), {
    onError: () => {
      setStatus(undefined);
    },
  });

  const deactivateAutomation = useDeactivateAutomation(Number(automationId), {
    onError: () => {
      setStatus(undefined);
    },
  });

  const automationAuditHistory = useListAutomationAuditHistory(
    Number(automationId),
  );
  const locale = useLocale();

  const handleActivateAutomation = () => {
    setStatus("Active");
    return activateAutomation.execute(automationId);
  };

  const handleDeactivateAutomation = () => {
    setStatus("Inactive");
    confirm.handlePrompt(() => {
      return deactivateAutomation.execute(automationId);
    });
  };

  const isInactive =
    automationDetails.data?.status === AutomationStatusTypeModel.Inactive;
  const isExecuting = isInactive
    ? activateAutomation.isExecuting
    : deactivateAutomation.isExecuting;

  return (
    <ViewLayout
      breadcrumbs={[
        { label: t("automations_list.title"), href: "../automations" },
      ]}
      title={
        automationDetails.data && automationDetails.data.name + " Automation"
      }
      action={
        automationDetails.data && (
          <AccessControl allowedPermissions={["admin:automations:manage"]}>
            <Button
              variant="contained"
              onClick={
                isInactive
                  ? handleActivateAutomation
                  : handleDeactivateAutomation
              }
              disabled={
                isExecuting || status === (isInactive ? "Active" : "Inactive")
              }
            >
              {isInactive
                ? t("automation_details.activate_button")
                : t("automation_details.deactivate_button")}
            </Button>
          </AccessControl>
        )
      }
    >
      <Loader
        ready={Boolean(automationDetails.data ?? automationDetails.error)}
        render={() => {
          if (
            automationDetails.error ||
            !automationDetails.data ||
            !automationAuditHistory.data ||
            automationAuditHistory.error
          ) {
            return <QueryError onRetry={automationDetails.refetch} />;
          }

          return (
            <ContainedLayout>
              <Stack spacing={3}>
                <Surface
                  title={t("automation_details.title")}
                  action={
                    <AccessControl
                      allowedPermissions={["admin:automations:manage"]}
                    >
                      <EditButton
                        component={RouterLink}
                        variant="text"
                        to={`../automations/${automationId}/edit-automation`}
                      />
                    </AccessControl>
                  }
                >
                  <DescriptionList label={t("automation_details.title_label")}>
                    <DescriptionList.Item
                      label={t("automation_details.rule_name")}
                    >
                      {automationDetails.data.name}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t("automation_details.description")}
                    >
                      {automationDetails.data.description}
                    </DescriptionList.Item>
                    <DescriptionList.Item
                      label={t("automation_details.portfolio")}
                    >
                      {automationDetails.data.portfolioName}
                    </DescriptionList.Item>
                    <DescriptionList.Item label={t("automation_details.when")}>
                      {automationDetails.data.event.type}
                    </DescriptionList.Item>
                    <>
                      {automationDetails.data.parameters.map((param) => (
                        <DescriptionList.Item
                          key={param.key}
                          label={t(`automation_details.${param.key}`)}
                        >
                          {param.value}
                        </DescriptionList.Item>
                      ))}
                    </>
                    <DescriptionList.Item
                      label={t("automation_details.status")}
                    >
                      {automationDetails.data.status}
                    </DescriptionList.Item>
                  </DescriptionList>
                </Surface>

                <Surface title={t("automation_history.title")} disableGutters>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label={t("automation_history.title")}
                  >
                    <LoadableTableHead
                      headings={[
                        t("automation_history.table_header.action"),
                        t("automation_history.table_header.user"),
                      ]}
                      loading={automationAuditHistory.isValidating}
                    />
                    <TableBody>
                      {automationAuditHistory.data?.map((activity) => (
                        <TableRow key={activity.id} hover>
                          <TableCell>{activity.action}</TableCell>
                          <TableCell>
                            {activity.timestamp &&
                              `${locale.formatDateTime(activity.timestamp)} - ${
                                activity.userName
                              }`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Surface>
              </Stack>
            </ContainedLayout>
          );
        }}
      />

      <ConfirmationDialog
        title={t("automation_details.deactivate_confirmation.title")}
        open={confirm.isOpen}
        content={
          <Typography>
            {t("automation_details.deactivate_confirmation.description")}
          </Typography>
        }
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
        onConfirm={confirm.handleConfirm}
        onReject={() => {
          setStatus(undefined);
          confirm.handleReject();
        }}
      />
    </ViewLayout>
  );
};

export default withProtectedView({
  allowedPermissions: ["admin:automations:view"],
})(AutomationDetailsView);
