import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface ReportCardProps {
  title: string;
  onClick(): void;
}

export default function ReportCard({
  title,
  onClick,
}: ReportCardProps): ReactElement {
  const { t } = useTranslation("reporting");

  return (
    <Card
      aria-label={t(`cards.${title}.heading`)}
      key={title}
      variant="outlined"
    >
      <CardActionArea onClick={onClick}>
        <CardContent sx={{ minHeight: 150 }}>
          <Typography gutterBottom variant="h5" component="h2">
            {t(`cards.${title}.heading`)}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t(`cards.${title}.description_label`)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
