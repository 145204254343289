import { DateField } from "@ldms/mui-sdk/forms";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useLocale } from "common/hooks";
import React, { ReactElement, ReactNode } from "react";
import { AttachmentTypeModel } from "generated/core/models/AttachmentTypeModel";

const documentTypeFilterOptions = Object.values(AttachmentTypeModel);

interface DocumentFilterProps {
  children?: ReactNode;
  filters: {
    type?: string;
  };
  labels: {
    type: string;
    allTypesOption: string;
    fromDate: string;
    toDate: string;
  };
  onChange(key: string, value: string | Date | undefined): void;
}

function DocumentFilter({
  children,
  filters,
  labels,
  onChange,
}: DocumentFilterProps): ReactElement {
  const locale = useLocale();

  const makeDateChangeHandler =
    (name: string) =>
    (date: Date | null): void => {
      onChange(name, date ? locale.formatISODate(date) : "");
    };

  const handleTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onChange(
      event.target.name,
      event.target.value === "" ? undefined : event.target.value,
    );
  };

  const defaultType = filters.type || "";

  return (
    <Grid container spacing={1}>
      {children && <Grid item>{children}</Grid>}
      <Grid item>
        <TextField
          InputLabelProps={{
            htmlFor: "documentTypeFilter",
            shrink: true,
          }}
          inputProps={{ displayEmpty: true, id: "documentTypeFilter" }}
          onChange={handleTypeChange}
          label={labels.type}
          name="documentType"
          select
          size="small"
          value={defaultType}
          variant="outlined"
          margin="none"
        >
          <MenuItem value="">{labels.allTypesOption}</MenuItem>
          {documentTypeFilterOptions.map((documentType) => (
            <MenuItem key={documentType} value={documentType}>
              {documentType}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <DateField
          name="fromDate"
          label={labels.fromDate}
          onChange={makeDateChangeHandler("fromDate")}
          margin="none"
        />
      </Grid>
      <Grid item>
        <DateField
          name="toDate"
          label={labels.toDate}
          onChange={makeDateChangeHandler("toDate")}
          margin="none"
        />
      </Grid>
    </Grid>
  );
}

export default DocumentFilter;
