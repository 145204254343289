import { Box, Paper, Typography } from "@mui/material";
import useId from "@mui/utils/useId";
import { ReactNode } from "react";

interface SurfaceProps {
  action?: ReactNode;
  children: ReactNode;
  disableGutters?: boolean;
  footer?: ReactNode;
  title: string;
}

export default function Surface({
  action,
  children,
  disableGutters,
  footer,
  title,
}: SurfaceProps) {
  const id = useId();
  const headingId = `surface-heading-${id}`;

  return (
    <Paper>
      <Box display="flex" padding={2} justifyContent="space-between">
        <Typography id={headingId} variant="h5">
          {title}
        </Typography>
        {action && (
          <Box margin={-1} role="menubar" aria-labelledby={headingId}>
            {action}
          </Box>
        )}
      </Box>
      <Box
        {...(disableGutters ? {} : { paddingX: 2 })}
        {...(footer ? {} : { paddingBottom: 2 })}
      >
        {children}
      </Box>
      {footer && (
        <Box paddingX={1} paddingTop={1} paddingBottom={1}>
          {footer}
        </Box>
      )}
    </Paper>
  );
}
