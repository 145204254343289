/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';

/**
 * 
 * @export
 * @interface BankAccountListItemModel
 */
export interface BankAccountListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof BankAccountListItemModel
     */
    readonly id: number;
    /**
     * A read only Id
     * @type {string}
     * @memberof BankAccountListItemModel
     */
    readonly systemId: string;
    /**
     * The contact type in reference to the client
     * @type {string}
     * @memberof BankAccountListItemModel
     */
    contactType?: string;
    /**
     * The contact name in reference to the client
     * @type {string}
     * @memberof BankAccountListItemModel
     */
    contactName?: string;
    /**
     * The type
     * @type {string}
     * @memberof BankAccountListItemModel
     */
    type: string;
    /**
     * The account holders name
     * @type {string}
     * @memberof BankAccountListItemModel
     */
    accountName?: string;
    /**
     * The bank account roll number
     * @type {string}
     * @memberof BankAccountListItemModel
     */
    rollNumber?: string;
    /**
     * The bank account sort code
     * @type {string}
     * @memberof BankAccountListItemModel
     */
    sortCode?: string;
    /**
     * The international bank account number
     * @type {string}
     * @memberof BankAccountListItemModel
     */
    iban?: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof BankAccountListItemModel
     */
    bic?: string;
    /**
     * The name of the bank
     * @type {string}
     * @memberof BankAccountListItemModel
     */
    bankName?: string;
    /**
     * Whether the bank has a direct debit mandate
     * @type {boolean}
     * @memberof BankAccountListItemModel
     */
    directDebitMandate?: boolean;
    /**
     * 
     * @type {AddressModel}
     * @memberof BankAccountListItemModel
     */
    address?: AddressModel;
}

/**
 * Check if a given object implements the BankAccountListItemModel interface.
 */
export function instanceOfBankAccountListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemId" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function BankAccountListItemModelFromJSON(json: any): BankAccountListItemModel {
    return BankAccountListItemModelFromJSONTyped(json, false);
}

export function BankAccountListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankAccountListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemId': json['systemId'],
        'contactType': !exists(json, 'contactType') ? undefined : json['contactType'],
        'contactName': !exists(json, 'contactName') ? undefined : json['contactName'],
        'type': json['type'],
        'accountName': !exists(json, 'accountName') ? undefined : json['accountName'],
        'rollNumber': !exists(json, 'rollNumber') ? undefined : json['rollNumber'],
        'sortCode': !exists(json, 'sortCode') ? undefined : json['sortCode'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
        'bankName': !exists(json, 'bankName') ? undefined : json['bankName'],
        'directDebitMandate': !exists(json, 'directDebitMandate') ? undefined : json['directDebitMandate'],
        'address': !exists(json, 'address') ? undefined : AddressModelFromJSON(json['address']),
    };
}

export function BankAccountListItemModelToJSON(value?: BankAccountListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactType': value.contactType,
        'contactName': value.contactName,
        'type': value.type,
        'accountName': value.accountName,
        'rollNumber': value.rollNumber,
        'sortCode': value.sortCode,
        'iban': value.iban,
        'bic': value.bic,
        'bankName': value.bankName,
        'directDebitMandate': value.directDebitMandate,
        'address': AddressModelToJSON(value.address),
    };
}

