/* eslint-disable no-template-curly-in-string */
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { startOfDay, subDays } from "date-fns";
import { FieldValues, Resolver } from "react-hook-form";
import { TFunction, useTranslation } from "react-i18next";
import * as yup from "yup";
import Lazy from "yup/lib/Lazy";
import { AnyObject } from "yup/lib/types";

type Yup = typeof yup;

declare module "yup" {
  interface StringSchema {
    maxCharacters(max: number, label: string): StringSchema;
    isRequired(label: string): StringSchema;
    optionalPostcodeValidator(testName: string, label: string): StringSchema;
    optionalAddressLine1Validator(
      testName: string,
      label: string,
    ): StringSchema;
    oneOfBankDetailsRequired(testName: string): StringSchema;
    validateIban(): StringSchema;
    validateBic(): StringSchema;
    isNumbers(label: string): StringSchema;
  }
  interface NumberSchema {
    isRequired(label: string): NumberSchema;
    between(minimum: number, maximum: number, message: string): NumberSchema;
    minAmount(minimum: number, label: string): NumberSchema;
    maxAmount(limit: number, label: string): NumberSchema;
    greaterThanAmount(limit: number, label: string): NumberSchema;
    lessThanAmount(limit: number, label: string): NumberSchema;
    notGreaterThanAmount(limit: number, label: string): NumberSchema;
    notGreaterThanPercentage(limit: number, label: string): NumberSchema;
    notGreaterThanLimit(limit: number, label: string): NumberSchema;
  }
  interface DateSchema {
    localDate(): DateSchema;
    isRequired(label: string): DateSchema;
    isBeforeToday(label: string): DateSchema;
    isNotBeforeToday(label: string): DateSchema;
    isNotFuture(label: string): DateSchema;
    isValidDate(label: string): DateSchema;
    isAfterDays(days: number, label: string): DateSchema;
  }
}

const validationRequired = "common:validation.is_required";

function addStringSchemaMethods(stringYup: Yup, t: TFunction): void {
  stringYup.addMethod<yup.StringSchema>(
    stringYup.string,
    "isNumbers",
    function (label: string) {
      return this.test(
        "isNumbers",
        t("common:validation.only_numbers", { label }),
        function (value) {
          if (!value) {
            return true;
          }

          const isValid = /^\d+$/.test(value);

          return isValid;
        },
      );
    },
  );

  stringYup.addMethod<yup.StringSchema>(
    stringYup.string,
    "maxCharacters",
    function (limit: number, label: string) {
      return this.max(
        limit,
        t("common:validation.is_max_limit", {
          limit,
          label,
        }),
      );
    },
  );

  stringYup.addMethod<yup.StringSchema>(
    stringYup.string,
    "isRequired",
    function (label: string) {
      return this.required(t(validationRequired, { label }));
    },
  );

  stringYup.addMethod<yup.StringSchema>(
    stringYup.string,
    "optionalAddressLine1Validator",
    function (testName: string, label: string) {
      return this.test(
        testName,
        t(validationRequired, {
          label: label,
        }),
        function (value: string | undefined) {
          if (
            [
              this.parent.postcode,
              this.parent.addressLine2,
              this.parent.addressLine3,
              this.parent.addressLine4,
            ].filter(Boolean).length
          ) {
            return Boolean(value);
          }
          return true;
        },
      );
    },
  );

  stringYup.addMethod<yup.StringSchema>(
    stringYup.string,
    "optionalPostcodeValidator",
    function (testName: string, label: string) {
      return this.test(
        testName,
        t(validationRequired, {
          label: label,
        }),
        function (value: string | undefined) {
          if (
            [
              this.parent.addressLine1,
              this.parent.addressLine2,
              this.parent.addressLine3,
              this.parent.addressLine4,
            ].filter(Boolean).length
          ) {
            return Boolean(value);
          }
          return true;
        },
      );
    },
  );

  stringYup.addMethod<yup.StringSchema>(
    stringYup.string,
    "validateIban",
    function () {
      return this.matches(/^[a-zA-Z]{2}\d{2}([a-zA-Z\d]{11,26})?$/, {
        excludeEmptyString: true,
        message: t("common:validation.iban_format"),
      });
    },
  );

  stringYup.addMethod<yup.StringSchema>(
    stringYup.string,
    "validateBic",
    function () {
      return this.matches(
        /^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z\d]{2}([a-zA-Z\d]{3})?$/,
        {
          excludeEmptyString: true,
          message: t("common:validation.bic_format"),
        },
      );
    },
  );

  stringYup.addMethod<yup.StringSchema>(
    stringYup.string,
    "oneOfBankDetailsRequired",
    function (testName: string) {
      return this.test({
        name: testName,
        message: t("common:validation.bank_details_required"),
        test: (value, testContext) =>
          testContext.parent.sortCode ||
          testContext.parent.accountNumber ||
          testContext.parent.iban ||
          testContext.parent.bic,
      });
    },
  );
}

function addNumberSchemaMethods(
  numberYup: Yup,
  t: TFunction,
  formatAmount: (amount: string | number | undefined) => string,
): void {
  numberYup.addMethod<yup.NumberSchema>(
    numberYup.number,
    "isRequired",
    function (label: string) {
      return this.required(t(validationRequired, { label }));
    },
  );

  numberYup.addMethod<yup.NumberSchema>(
    numberYup.number,
    "between",
    function (minimum: number, maximum: number, message: string) {
      return this.min(minimum, message).max(maximum, message);
    },
  );

  numberYup.addMethod<yup.NumberSchema>(
    numberYup.number,
    "greaterThanAmount",
    function (limit: number, label: string) {
      return this.moreThan(
        limit,
        t("common:validation.is_not_greater_than", {
          limit,
          label,
        }),
      );
    },
  );

  numberYup.addMethod<yup.NumberSchema>(
    numberYup.number,
    "notGreaterThanAmount",
    function (limit: number, label: string) {
      const formattedLimit = formatAmount(limit);
      return this.max(
        limit,
        t("common:validation.is_not_greater_than_formatted_limit", {
          formattedLimit,
          label,
        }),
      );
    },
  );

  numberYup.addMethod<yup.NumberSchema>(
    numberYup.number,
    "notGreaterThanPercentage",
    function (limit: number, label: string) {
      const formattedLimit = limit.toFixed(2).concat("%");
      return this.max(
        limit,
        t("common:validation.is_not_greater_than_formatted_limit", {
          formattedLimit,
          label,
        }),
      );
    },
  );

  numberYup.addMethod<yup.NumberSchema>(
    numberYup.number,
    "notGreaterThanLimit",
    function (limit: number, label: string) {
      return this.max(
        limit,
        t("common:validation.is_not_greater_than_limit", {
          limit,
          label,
        }),
      );
    },
  );

  numberYup.addMethod<yup.NumberSchema>(
    numberYup.number,
    "lessThanAmount",
    function (limit: number, label: string) {
      return this.lessThan(
        limit,
        t("common:validation.is_not_less_than", {
          limit,
          label,
        }),
      );
    },
  );

  numberYup.addMethod<yup.NumberSchema>(
    numberYup.number,
    "minAmount",
    function (limit: number, label: string) {
      return this.min(
        limit,
        t("common:validation.is_too_small", {
          limit,
          label,
        }),
      );
    },
  );

  numberYup.addMethod<yup.NumberSchema>(
    numberYup.number,
    "maxAmount",
    function (limit: number, label: string) {
      return this.max(
        limit,
        t("common:validation.is_too_large", {
          limit,
          label,
        }),
      );
    },
  );
}

function addDateSchemaMethods(dateYup: Yup, t: TFunction): void {
  dateYup.addMethod<yup.DateSchema>(dateYup.date, "localDate", function () {
    return this.transform((_, originalValue) =>
      originalValue === null ? null : new Date(originalValue),
    );
  });

  dateYup.addMethod<yup.DateSchema>(
    dateYup.date,
    "isRequired",
    function (label: string) {
      return this.required(t(validationRequired, { label }));
    },
  );

  dateYup.addMethod<yup.DateSchema>(
    dateYup.date,
    "isValidDate",
    function (label: string) {
      return this.typeError(t("common:validation.is_valid_date", { label }));
    },
  );

  dateYup.addMethod<yup.DateSchema>(
    dateYup.date,
    "isBeforeToday",
    function (label: string) {
      return this.max(
        subDays(new Date(), 1),
        t("common:validation.is_before_today", { label }),
      );
    },
  );

  dateYup.addMethod<yup.DateSchema>(
    dateYup.date,
    "isNotBeforeToday",
    function (label: string) {
      return this.min(
        startOfDay(new Date()),
        t("common:validation.is_not_before_today", { label }),
      );
    },
  );

  dateYup.addMethod<yup.DateSchema>(
    dateYup.date,
    "isNotFuture",
    function (label: string) {
      return this.max(
        new Date(),
        t("common:validation.is_in_future", { label }),
      );
    },
  );

  dateYup.addMethod<yup.DateSchema>(
    dateYup.date,
    "isAfterDays",
    function (days: number, label: string) {
      return this.min(
        new Date().setDate(new Date().getDate() + days),
        t("common:validation.is_after_days", { label, days }),
      );
    },
  );
}

export type YupForm = Omit<typeof yup, "number"> & {
  number: (
    label: string,
  ) => yup.NumberSchema<number | undefined, AnyObject, number | undefined>;
};

export type YupResolverCallback = (
  yup: YupForm,
) => yup.AnyObjectSchema | Lazy<yup.AnySchema<unknown, unknown, unknown>>;

export default function useYupResolver<TFieldValues extends FieldValues>(
  callback: YupResolverCallback,
): Resolver<TFieldValues> {
  const { t } = useTranslation();
  const { formatAmount } = useFormat();

  yup.setLocale({
    mixed: {
      notType: t("validation.is_not_valid", {
        label: "${label}",
        type: "${type}",
      }),
    },
  });

  addStringSchemaMethods(yup, t);
  addNumberSchemaMethods(yup, t, formatAmount);
  addDateSchemaMethods(yup, t);

  return yupResolver(
    callback({
      ...yup,
      number: (label: string) =>
        yup
          .number()
          .label(label)
          .typeError(
            t("validation.is_not_valid", { label: "${label}", type: "number" }),
          ),
    }),
  );
}
