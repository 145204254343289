import { Divider, Grid, Typography } from "@mui/material";
import ReportCard from "apps/reporting/components/ReportCard";
import {
  AgreementInsuranceReportContainer,
  AgreementSettlementQuotesReportContainer,
  AgreementSetUpReportContainer,
  AgreementStatusReportContainer,
  AppliedChargesAndDiscountsReportContainer,
  AssetReportContainer,
  CCRReportContainer,
  DirectDebitPaymentStatusReportContainer,
  EndOfTermReportContainer,
  EquifaxInsightReportContainer,
  FeeAmountDueReportContainer,
  InstalmentAmountsDueContainer,
  ManufacturerSubsidyInvoiceReportContainer,
  PortfolioReportContainer,
  ReceiptsReportContainer,
  SettledAgreementReportContainer,
} from "apps/reporting/containers";
import AgreementCashFlowReportContainer from "apps/reporting/containers/AgreementCashFlowReportContainer";
import CaisReportContainer from "apps/reporting/containers/CaisReportContainer";
import PortfolioCashFlowReportContainer from "apps/reporting/containers/PortfolioCashFlowReportContainer";
import PortfolioCashFlowReportSummaryReportContainer from "apps/reporting/containers/PortfolioCashFlowReportSummaryReportContainer";
import { ReportDialog } from "apps/reporting/types";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ReportingContainer(): ReactElement {
  const { t } = useTranslation("reporting");
  const [activeDialog, setActiveDialog] = useState<ReportDialog | undefined>();

  const makeOpenDialogHandler = (name: ReportDialog) => (): void => {
    setActiveDialog(name);
  };

  const handleCloseDialog = (): void => {
    setActiveDialog(undefined);
  };

  return (
    <>
      <PortfolioReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.Portfolio}
      />
      <AgreementSetUpReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.AgreementSetUp}
      />
      <AgreementStatusReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.AgreementStatus}
      />
      <FeeAmountDueReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.FeeAmountDue}
      />
      <ReceiptsReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.Receipts}
      />
      <InstalmentAmountsDueContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.InstalmentAmountsDue}
      />
      <AppliedChargesAndDiscountsReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.AppliedChargesAndDiscounts}
      />
      <CaisReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.Cais}
      />
      <AssetReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.Asset}
      />
      <AgreementInsuranceReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.AgreementInsurance}
      />
      <DirectDebitPaymentStatusReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.DirectDebitPaymentStatus}
      />
      <AgreementCashFlowReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.AgreementCashFlow}
      />
      <PortfolioCashFlowReportSummaryReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.PortfolioCashFlowSummary}
      />
      <PortfolioCashFlowReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.PortfolioCashFlow}
      />
      <SettledAgreementReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.SettledAgreement}
      />
      <AgreementSettlementQuotesReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.AgreementSettlementQuotes}
      />
      <EndOfTermReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.EndOfTerm}
      />

      <CCRReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.CCR}
      />

      <EquifaxInsightReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.EquifaxInsight}
      />

      <ManufacturerSubsidyInvoiceReportContainer
        onClose={handleCloseDialog}
        open={activeDialog === ReportDialog.ManufacturerSubsidyInvoice}
      />

      <Typography variant="h6" component="div" align="left" marginBottom={2}>
        {t("groups.portfolio_analysis_label")}
      </Typography>
      <Grid container spacing={2} marginBottom={3}>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="portfolio_report"
            onClick={makeOpenDialogHandler(ReportDialog.Portfolio)}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="agreement_status_report"
            onClick={makeOpenDialogHandler(ReportDialog.AgreementStatus)}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="asset_report"
            onClick={makeOpenDialogHandler(ReportDialog.Asset)}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="agreement_insurance_report"
            onClick={makeOpenDialogHandler(ReportDialog.AgreementInsurance)}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="end_of_term_report"
            onClick={makeOpenDialogHandler(ReportDialog.EndOfTerm)}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: 3 }} />
      <Typography variant="h6" component="div" align="left" marginBottom={2}>
        {t("groups.financial_reporting_label")}
      </Typography>
      <Grid container spacing={2} marginBottom={3}>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="agreement_set_up_report"
            onClick={makeOpenDialogHandler(ReportDialog.AgreementSetUp)}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="fee_amount_due_report"
            onClick={makeOpenDialogHandler(ReportDialog.FeeAmountDue)}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="receipts_report"
            onClick={makeOpenDialogHandler(ReportDialog.Receipts)}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="instalment_amounts_due_report"
            onClick={makeOpenDialogHandler(ReportDialog.InstalmentAmountsDue)}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="applied_charges_and_discounts_report"
            onClick={makeOpenDialogHandler(
              ReportDialog.AppliedChargesAndDiscounts,
            )}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="direct_debit_payment_status_report"
            onClick={makeOpenDialogHandler(
              ReportDialog.DirectDebitPaymentStatus,
            )}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="agreement_cash_flow_report"
            onClick={makeOpenDialogHandler(ReportDialog.AgreementCashFlow)}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="portfolio_cash_flow_summary_report"
            onClick={makeOpenDialogHandler(
              ReportDialog.PortfolioCashFlowSummary,
            )}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="portfolio_cash_flow_report"
            onClick={makeOpenDialogHandler(ReportDialog.PortfolioCashFlow)}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="settled_agreement_report"
            onClick={makeOpenDialogHandler(ReportDialog.SettledAgreement)}
          />
        </Grid>

        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="agreement_settlement_quotes_report"
            onClick={makeOpenDialogHandler(
              ReportDialog.AgreementSettlementQuotes,
            )}
          />
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="manufacturer_subsidy_invoice_report"
            onClick={makeOpenDialogHandler(
              ReportDialog.ManufacturerSubsidyInvoice,
            )}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: 3 }} />
      <Typography variant="h6" component="div" align="left" marginBottom={2}>
        {t("groups.credit_bureau_label")}
      </Typography>
      <Grid container spacing={2} marginBottom={3}>
        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="cais_report"
            onClick={makeOpenDialogHandler(ReportDialog.Cais)}
          />
        </Grid>

        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="equifax_insight_report"
            onClick={makeOpenDialogHandler(ReportDialog.EquifaxInsight)}
          />
        </Grid>

        <Grid item xs={4} md={3} lg={2}>
          <ReportCard
            title="ccr_report"
            onClick={makeOpenDialogHandler(ReportDialog.CCR)}
          />
        </Grid>
      </Grid>
    </>
  );
}
