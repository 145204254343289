import { QueryKey } from "api";
import { keys } from "api/customers";

export const all = (customerId: number): QueryKey => [
  ...keys.detail(customerId),
  "agreements",
];

export const list = (
  customerId: number,
  params: { query?: string; live?: boolean } = {},
): QueryKey => [...all(customerId), "list", params.query, params.live];
