import { AgreementDetailsContainer } from "apps/servicing/modules/agreements/containers";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

export default function OverviewView() {
  const { t } = useTranslation("agreements");

  return (
    <ViewLayout title={t("overview.heading")}>
      <ContainedLayout>
        <AgreementDetailsContainer />
      </ContainedLayout>
    </ViewLayout>
  );
}
