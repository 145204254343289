import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useResponseError, useYupResolver } from "common/hooks";
import { AddMemoModel } from "generated/core/models";
import { HTMLAttributes, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";
import { useForm } from "support/react-hook-form";

interface AddMemoFromDialogProps {
  open: boolean;
  onClose(): void;
  onSubmit(data: AddMemoModel): Promise<void>;
}

export default function AddMemoFormDialog({
  open,
  onClose,
  onSubmit: onSubmitCallback,
}: AddMemoFromDialogProps): ReactElement {
  const { t } = useTranslation(["clients", "common"]);
  const resolver = useYupResolver<AddMemoModel>((yup) =>
    yup.object().shape({
      note: yup.string().isRequired(t("note")).maxCharacters(4000, t("note")),
    }),
  );
  const form = useForm<AddMemoModel>({
    resolver,
  });
  const error = useResponseError();

  async function onSubmit(data: AddMemoModel): Promise<void> {
    try {
      error.reset();
      await onSubmitCallback(data);
      form.reset();
    } catch (response) {
      error.setError((await errorHandler(response)).code);
    }
  }

  const handleOnClose = () => {
    onClose();
    form.reset();
  };

  return (
    <Dialog
      aria-labelledby="memo-dialog-title"
      open={open}
      onClose={handleOnClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="memo-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{t("add_memo")}</Box>
          <Box>
            <IconButton
              aria-label={t("common:close")}
              onClick={handleOnClose}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        data-testid="addMemo.form"
        aria-label={t("add_memo")}
      >
        <DialogContent>
          <TextField
            {...form.register("note")}
            disabled={form.formState.isSubmitting}
            error={Boolean(form.formState.errors?.note)}
            FormHelperTextProps={
              {
                "data-testid": "addMemo.errors.note",
              } as HTMLAttributes<HTMLParagraphElement>
            }
            fullWidth
            helperText={form.formState.errors.note?.message}
            id="note"
            label={t("note")}
            multiline
            rows={5}
            variant="outlined"
          />
          {error.message && (
            <Typography color="error" data-testid="form.error">
              {error.message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOnClose}
            color="primary"
            data-testid="dialog.cancel"
          >
            {t("common:cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={form.formState.isSubmitting}
            variant="contained"
            data-testid="dialog.submit"
          >
            {t("common:save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
