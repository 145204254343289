import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import AddMemoContainer from "apps/servicing/modules/agreements/containers/AddMemoContainer";
import AgreementMemos from "apps/servicing/modules/agreements/containers/AgreementMemos";
import ContainedLayout from "common/layouts/ContainedLayout";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useTranslation } from "react-i18next";

export default function MemosView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();

  return (
    <ViewLayout
      title={t("memos.heading")}
      action={
        <AccessControl
          allowedPermissions={["servicing:agreement-memos:manage"]}
        >
          <AddMemoContainer agreementId={agreement.id} />
        </AccessControl>
      }
    >
      <FixedLayout>
        <ContainedLayout>
          <AgreementMemos agreementId={agreement.id} />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}
