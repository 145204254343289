import { useContext, useEffect, useRef, type ReactElement } from "react";
import { ShellLayoutContext } from "./components/ShellLayout";

const useShellLayout = (props: { navigation?: ReactElement | null }) => {
  const navigationRef = useRef(props.navigation);
  const { setNavigation } = useContext(ShellLayoutContext);

  useEffect(() => {
    if (navigationRef.current) {
      setNavigation(navigationRef.current ?? null);
    }

    return () => {
      setNavigation(null);
    };
  }, [setNavigation, navigationRef]);
};

export default useShellLayout;
