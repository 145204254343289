import { AddButton } from "@ldms/mui-sdk/components";
import { DateField, NumberField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { Box, Grid, Typography } from "@mui/material";
import { useAddVatRate } from "api/vat-codes/rates/addVatRate";
import { useLocale, useYupResolver } from "common/hooks";
import { addDays, format, startOfDay } from "date-fns";
import { CreateTaxRateRequestModel } from "generated/servicing-v2/models";
import { ReactElement, useState } from "react";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface VatCodeDetailsViewProps {
  readonly systemId: string;
}

export interface AddVatRateFieldValues {
  effectiveDate: Date;
  rate: number;
}

const transformField = (v: string, o: string): string | null | undefined => {
  return o === "" ? undefined : v;
};

const useAddVatRateResolver = (
  minDate: Date,
): Resolver<AddVatRateFieldValues> => {
  const { t } = useTranslation("servicing");
  const { formatDate } = useLocale();

  const rateLabel = "vat_rates.add.vat_rate_label";
  const effectiveDateLabel = "vat_rates.add.effective_date_label";

  return useYupResolver<AddVatRateFieldValues>((yup) =>
    yup.object().shape({
      effectiveDate: yup
        .date()
        .localDate()
        .isRequired(t(effectiveDateLabel))
        .isValidDate(t(effectiveDateLabel))
        .min(
          minDate,
          t("vat_rates.add.minimum_date_error_message", {
            earliestDate: formatDate(minDate),
          }),
        ),
      rate: yup
        .number(t(rateLabel))
        .transform(transformField)
        .isRequired(t(rateLabel))
        .minAmount(0, t(rateLabel))
        .maxAmount(99.75, t(rateLabel)),
    }),
  );
};

export default function AddVatCodeContainer({
  systemId,
}: VatCodeDetailsViewProps): ReactElement {
  const { t } = useTranslation("servicing");

  const [openAddVatCodeDialog, setOpenAddVatCodeDialog] = useState(false);

  const minDate = startOfDay(addDays(new Date(), 30));

  const resolver = useAddVatRateResolver(minDate);

  const openDialog = (): void => {
    setOpenAddVatCodeDialog(true);
  };

  const closeDialog = (): void => {
    setOpenAddVatCodeDialog(false);
    addVatRate.reset();
  };

  const addVatRate = useAddVatRate(systemId, {
    onSuccess: closeDialog,
  });

  const onSubmit = async (data: AddVatRateFieldValues): Promise<void> => {
    addVatRate.reset();
    const addVatRateModel: CreateTaxRateRequestModel = {
      ...data,
    };
    await addVatRate.execute(addVatRateModel);
  };

  const responseError = new Map([
    ["no_such_vat_code", t("vat_rates.add.no_vat_code_message")],
    ["vat_rate_exists", t("vat_rates.add.vat_rate_exists_message")],
  ]);

  return (
    <>
      <AddButton onClick={openDialog} />

      <FormDialog
        onSubmit={onSubmit}
        resolver={resolver}
        title={t("vat_rates.add.title_label")}
        onClose={closeDialog}
        open={openAddVatCodeDialog}
        disabled={addVatRate.isExecuting}
      >
        {(form): ReactElement => {
          return (
            <>
              <Grid container spacing={2} rowSpacing={0.5}>
                <Grid item sm={12}>
                  <DateField
                    control={form.control}
                    name="effectiveDate"
                    label={t("vat_rates.add.effective_date_label")}
                    error={Boolean(
                      form.formState.errors.effectiveDate?.message,
                    )}
                    helperText={form.formState.errors.effectiveDate?.message}
                    required
                    inputProps={{
                      min: format(minDate, "yyyy-MM-dd"),
                    }}
                  />
                </Grid>

                <Grid item sm={12}>
                  <NumberField
                    control={form.control}
                    label={t("vat_rates.add.vat_rate_label")}
                    name="rate"
                    error={Boolean(form.formState.errors.rate?.message)}
                    helperText={form.formState.errors.rate?.message}
                    required
                    maximumFractionDigits={2}
                  />
                </Grid>
              </Grid>

              {addVatRate.error && (
                <Box>
                  <Typography color="error">
                    {responseError.get(addVatRate.error.code) ??
                      t("common:error.default")}
                  </Typography>
                </Box>
              )}
            </>
          );
        }}
      </FormDialog>
    </>
  );
}
