import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { FormDialog } from "@ldms/mui-sdk/templates";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useCreateTemplateVersion, usePreviewTemplate } from "api/templates";
import { FileUploader, UploadedFileBanner } from "common/components";
import { useYupResolver } from "common/hooks";
import { isUndefined } from "lodash";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
interface FormSchemaModel {
  comment: string;
}

export type UpdateTemplateFormValues = FormSchemaModel & {
  file: Blob;
};
interface UpdateTemplateDialogProps {
  templateId: string;
  currentVersion: number;
}

export default function UploadTemplateVersionContainer({
  templateId,
  currentVersion,
}: Readonly<UpdateTemplateDialogProps>): ReactElement {
  const { t } = useTranslation("documents");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<File>();
  const [uploadError, setUploadError] = useState<string | undefined>();
  const commentLabel = t("upload_version.comment_label");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const resolver = useYupResolver<FormSchemaModel>((yup) =>
    yup.object().shape({
      comment: yup
        .string()
        .isRequired(commentLabel)
        .maxCharacters(500, commentLabel),
    }),
  );

  const createNewTemplateVersion = useCreateTemplateVersion(templateId, {
    onSuccess: () => {
      setIsDialogOpen(false);
      setFileName("");
      setFile(undefined);
    },
  });

  const createTemplateVersionOnSubmit = async (
    data: UpdateTemplateFormValues,
  ) => {
    await createNewTemplateVersion.execute({
      file: data.file,
      metadata: {
        comment: data.comment,
      },
      templateId,
    });
  };

  const onSubmit = async (data: FormSchemaModel) => {
    file &&
      createTemplateVersionOnSubmit({
        comment: data.comment,
        file: file,
      });
  };

  const templatePreview = usePreviewTemplate({
    onSuccess: async (response) => {
      const url = window.URL.createObjectURL(response);
      window.open(url, "_blank", "noopener,noreferrer");
    },
  });

  const onPreview = async (file: File) => {
    await templatePreview.execute({
      body: file,
      templateId,
    });
  };

  const validateFileUpload = (file: File): void => {
    if (
      ![
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file.type)
    ) {
      throw new Error(
        t("create_template.template_incorrect_file_type_error", {
          fileName: file.name,
        }),
      );
    }
  };

  const responseError = new Map([
    ["invalid_fields", t("letter_templates.create.merge_field_error")],
  ]);

  const onTemplateUpload = async (template: File): Promise<void> => {
    setUploadError(undefined);
    setFileName(template.name);
    try {
      validateFileUpload(template);
      setFile(template);
    } catch (validationError) {
      setUploadError((validationError as Error).message);

      setFile(undefined);
    }
  };

  const onFileDelete = async (): Promise<void> => {
    setFile(undefined);
    setUploadError(undefined);
    setFileName("");
    createNewTemplateVersion.reset();
    templatePreview.reset();
  };

  function handleOpenUploadNewTemplateDialog() {
    templatePreview.reset();
    setIsDialogOpen(true);
  }

  const handleOnClose = () => {
    onFileDelete();
    setUploadError(undefined);
    setIsDialogOpen(false);
  };

  return (
    <>
      <AccessControl allowedPermissions={["documents:templates:manage"]}>
        <Button variant="contained" onClick={handleOpenUploadNewTemplateDialog}>
          {t("upload_version.upload_button")}
        </Button>
      </AccessControl>

      <FormDialog
        onClose={handleOnClose}
        onSubmit={onSubmit}
        title={t("upload_version.title_label")}
        open={isDialogOpen}
        resolver={resolver}
        disabled={!file || createNewTemplateVersion.isExecuting}
      >
        {(form) => {
          const isFormError =
            !isUndefined(templatePreview.error) ||
            !isUndefined(createNewTemplateVersion.error) ||
            !isUndefined(uploadError);

          const serviceError = responseError.get(
            templatePreview.error?.code ??
              createNewTemplateVersion.error?.code ??
              "",
          );

          function getFormErrorMessage() {
            if (!isUndefined(uploadError) && uploadError !== "") {
              return uploadError;
            }
            if (!isUndefined(serviceError) && serviceError !== "") {
              return serviceError;
            }
            return t("common:error.default");
          }

          return (
            <>
              <Box marginBottom={1}>
                <Link
                  href={`/api/documents/templates/${templateId}/versions/${currentVersion}`}
                >
                  {t("upload_version.download_current_template_link")}
                </Link>
              </Box>

              {!file && (
                <Box marginBottom={1}>
                  <FileUploader
                    onInput={onTemplateUpload}
                    loading={templatePreview.isExecuting}
                    prompt={t("upload_version.upload_file_text")}
                  />
                </Box>
              )}

              {file && (
                <>
                  <UploadedFileBanner
                    onFileDelete={onFileDelete}
                    fileName={fileName}
                    buttonText={t("delete_document_button")}
                  />
                  <Box marginY={2}>
                    <TextField
                      {...form.register("comment")}
                      error={Boolean(form.formState.errors?.comment)}
                      fullWidth
                      helperText={form.formState.errors.comment?.message}
                      label={commentLabel}
                      multiline
                      rows={5}
                      variant="outlined"
                    />
                  </Box>
                  <Alert
                    severity="warning"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    action={
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onPreview(file)}
                        disabled={templatePreview.isExecuting}
                      >
                        {t("upload_version.preview_button")}
                      </Button>
                    }
                  >
                    <AlertTitle>
                      {t("upload_version.preview_title_label")}
                    </AlertTitle>
                    <Typography variant="body2">
                      {t("upload_version.preview_message_content")}
                    </Typography>
                  </Alert>
                </>
              )}

              {isFormError && (
                <Box marginTop={2}>
                  <Typography color="error">{getFormErrorMessage()}</Typography>
                </Box>
              )}
            </>
          );
        }}
      </FormDialog>
    </>
  );
}
