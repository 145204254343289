import { AmountField, DateField, NumberField } from "@ldms/mui-sdk/forms";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { RepaymentTermsFormModel } from "apps/onboarding/containers/RepaymentTermsStep";
import { add } from "date-fns";
import { ReactElement, useEffect } from "react";
import { UseFormReturn, useFieldArray } from "react-hook-form";

interface CustomInstalmentsContainerProps {
  labels: {
    title: string;
    startingOn: string;
    instalmentAmount: string;
    numberOfInstalments: string;
    add: string;
    remove: string;
  };
  form: UseFormReturn<RepaymentTermsFormModel>;
}

export default function CustomInstalmentsContainer(
  props: CustomInstalmentsContainerProps,
): ReactElement {
  const customInstalments = props.form.watch("customInstalments");
  const { fields, append, remove } = useFieldArray({
    control: props.form.control,
    name: "customInstalments",
  });
  const fieldsLength = fields.length;

  useEffect(() => {
    if (fieldsLength === 0) {
      append({
        startingOn: null,
        instalmentAmount: null,
        forNumberOfInstalments: null,
      });
    }
  }, [fieldsLength, append]);

  const validateInstalments = async (): Promise<void> => {
    await props.form.trigger("customInstalments");
  };

  const onAddCustomInstalmentHandler = async () => {
    await validateInstalments();
    const lastCustomInstalment =
      customInstalments?.[customInstalments?.length - 1];
    if (
      !lastCustomInstalment ||
      lastCustomInstalment?.startingOn === null ||
      lastCustomInstalment?.forNumberOfInstalments === null
    ) {
      return;
    }

    if (Object.keys(props.form.formState.errors).length === 0) {
      append({
        startingOn: add(new Date(lastCustomInstalment.startingOn), {
          months: lastCustomInstalment.forNumberOfInstalments,
        }),
        instalmentAmount: null,
        forNumberOfInstalments: null,
      });
    }
  };

  return (
    <>
      <Box marginY={3}>
        <Divider />
      </Box>
      <Grid container marginY={3}>
        <Grid item sm={6}>
          <Typography variant="h6">{props.labels.title}</Typography>
        </Grid>
      </Grid>
      <Stack spacing={2}>
        {fields.map((item, index) => {
          return (
            <Box key={item.id}>
              <Grid
                container
                spacing={2}
                role="group"
                aria-label={`custom_instalments${index}`}
                justifyContent="flex-end"
              >
                <Grid item xs={3}>
                  <DateField
                    aria-label={`customInstalments.${index}.startingOn`}
                    control={props.form.control}
                    name={`customInstalments.${index}.startingOn`}
                    error={Boolean(
                      props.form.formState.errors?.customInstalments?.[index]
                        ?.startingOn?.message,
                    )}
                    helperText={
                      props.form.formState.errors?.customInstalments?.[index]
                        ?.startingOn?.message
                    }
                    label={props.labels.startingOn}
                    required
                    disabled={index !== fields.length - 1}
                    margin="none"
                  />
                </Grid>

                <Grid item xs={3}>
                  <AmountField
                    error={Boolean(
                      props.form.formState.errors?.customInstalments?.[index]
                        ?.instalmentAmount?.message,
                    )}
                    helperText={
                      props.form.formState.errors?.customInstalments?.[index]
                        ?.instalmentAmount?.message
                    }
                    control={props.form.control}
                    label={props.labels.instalmentAmount}
                    name={`customInstalments.${index}.instalmentAmount`}
                    required
                    disabled={index !== fields.length - 1}
                    margin="none"
                  />
                </Grid>
                <Grid item xs={3}>
                  <NumberField
                    control={props.form.control}
                    error={Boolean(
                      props.form.formState.errors?.customInstalments?.[index]
                        ?.forNumberOfInstalments?.message,
                    )}
                    helperText={
                      props.form.formState.errors?.customInstalments?.[index]
                        ?.forNumberOfInstalments?.message
                    }
                    label={props.labels.numberOfInstalments}
                    name={`customInstalments.${index}.forNumberOfInstalments`}
                    required
                    disabled={index !== fields.length - 1}
                    margin="none"
                  />
                </Grid>

                {index === fields.length - 1 && (
                  <Grid item xs="auto">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={onAddCustomInstalmentHandler}
                    >
                      {props.labels.add}
                    </Button>
                  </Grid>
                )}

                {index === fields.length - 1 && index > 0 && (
                  <Grid item xs="auto">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => remove(index)}
                    >
                      {props.labels.remove}
                    </Button>
                  </Grid>
                )}
              </Grid>
              {index === fields.length - 2 && (
                <Box marginY={3}>
                  <Divider />
                </Box>
              )}
            </Box>
          );
        })}
      </Stack>
    </>
  );
}
