/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Attachments within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface RequestAttachmentDownloadRequest {
    attachmentId: string;
}

/**
 * 
 */
export class AttachmentCommandApi extends runtime.BaseAPI {

    /**
     * Request an Attachment download
     */
    async requestAttachmentDownloadRaw(requestParameters: RequestAttachmentDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling requestAttachmentDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["documents:manage"]);
        }

        const response = await this.request({
            path: `/attachments/{attachmentId}`.replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request an Attachment download
     */
    async requestAttachmentDownload(requestParameters: RequestAttachmentDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestAttachmentDownloadRaw(requestParameters, initOverrides);
    }

}
