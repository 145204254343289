import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { GetApp } from "@mui/icons-material";
import RestoreIcon from "@mui/icons-material/Restore";
import {
  Button,
  Grid,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { usePreviewTemplateVersion } from "api/templates/previewTemplateVersion";
import { TemplateTypeEnum } from "apps/documents/types";
import { LoadableTableHead, NoResults } from "common/components";
import { truncateText } from "common/components/TruncateTypography";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface TemplateVersion {
  version: number;
  createdAt?: string;
  createdBy?: string;
  comment: string;
}

interface TemplateVersionListProps {
  templateId: string;
  data?: TemplateVersion[];
  loading: boolean;
  onCommentClick(comment: string): void;
  onRestore(version: number): void;
  type: TemplateTypeEnum;
}

export default function TemplateVersionList({
  templateId,
  data,
  loading,
  onCommentClick,
  onRestore,
  type,
}: Readonly<TemplateVersionListProps>): ReactElement {
  const { t } = useTranslation("documents");
  const locale = useLocale();
  const previewTemplateVersion = usePreviewTemplateVersion({
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(response);
      window.open(url, "_blank", "noopener,noreferrer");
    },
  });

  const handlePreviewCurrentVersion = (templateId: string, version: number) =>
    previewTemplateVersion.execute({ templateId, version });

  const tableHeaders: string[] = [
    t("template_version_list.version_label"),
    t("template_version_list.created_label"),
    t("template_version_list.comment_label"),
    "",
  ];

  const makeShowCommentHandler = (comment: string) => (): void => {
    onCommentClick(comment);
  };

  const makeRestoreHandler = (version: number) => (): void => {
    onRestore(version);
  };

  const templateVersionHandler = (version: number) => {
    return version === 0 ? t("template_versions.base_version") : version;
  };
  return (
    <>
      <Table aria-label={t("template_versions_page_title")} size="small">
        <LoadableTableHead headings={tableHeaders} loading={loading} />
        <TableBody>
          {data?.slice(1, data.length).map((item) => (
            <TableRow key={item.version} hover>
              <TableCell padding="none" component="th" scope="row">
                <ListItemButton
                  dense
                  onClick={() =>
                    handlePreviewCurrentVersion(templateId, item.version)
                  }
                >
                  <ListItemText
                    primary={templateVersionHandler(item.version)}
                    primaryTypographyProps={{ color: "primary" }}
                  />
                </ListItemButton>
              </TableCell>
              <TableCell>
                <Grid spacing={4} container direction="row" alignItems="center">
                  <Grid item>
                    {item.createdAt
                      ? locale
                          .formatDateTime(new Date(item.createdAt))
                          .concat(` - ${item.createdBy}`)
                      : "-"}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell padding="none">
                <ListItemButton
                  dense
                  onClick={makeShowCommentHandler(item.comment)}
                >
                  <ListItemText primary={truncateText(item.comment)} />
                </ListItemButton>
              </TableCell>
              <TableCell sx={{ paddingY: 0.25 }}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  {type === TemplateTypeEnum.System && (
                    <AccessControl
                      allowedPermissions={["documents:templates:manage"]}
                    >
                      <Grid item>
                        <Button
                          color="primary"
                          onClick={makeRestoreHandler(item.version)}
                          size="small"
                          startIcon={<RestoreIcon fontSize="inherit" />}
                        >
                          {t("versions.restore_button")}
                        </Button>
                      </Grid>
                    </AccessControl>
                  )}

                  <Grid item>
                    <Button
                      color="primary"
                      href={`/api/documents/templates/${templateId}/versions/${item.version}`}
                      size="small"
                      startIcon={<GetApp fontSize="inherit" />}
                    >
                      {t("versions.download_button")}
                    </Button>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(data ?? []).length === 0 && <NoResults />}
    </>
  );
}
