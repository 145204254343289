import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { ChangeEventHandler, MouseEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface ListLayoutProps {
  actions?: ReactNode;
  children: ReactNode;
  filters?: ReactNode;
  pagination?: {
    onPageChange: (
      event: MouseEvent<HTMLButtonElement> | null,
      page: number,
    ) => void;
    onRowsPerPageChange: ChangeEventHandler<HTMLInputElement>;
    rowsPerPage: number;
    page: number;
    count: number | undefined;
  };
  selected?: number;
  onSelectAll?: () => void;
}

export default function ListLayout({
  actions,
  children,
  filters,
  pagination,
  selected,
  onSelectAll,
}: ListLayoutProps) {
  const { t } = useTranslation();
  const displayHeader = actions || filters;

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
      }}
    >
      <Box
        display="grid"
        gridTemplateRows="min-content 1fr min-content"
        height="100%"
        overflow="hidden"
      >
        {displayHeader && (
          <Box
            component="header"
            paddingX={2}
            paddingTop={filters ? 2 : 1.5}
            paddingBottom={1.5}
          >
            <Box display="flex" justifyContent="space-between">
              <Box>{filters} </Box>
              <Box>{actions}</Box>
            </Box>
          </Box>
        )}
        <Box sx={{ overflowY: "auto" }}>{children}</Box>
        {!!pagination?.count && (
          <Box>
            <Divider />
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center" marginLeft={2}>
                {Boolean(selected) && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    display="flex"
                    gap={2}
                  >
                    <Typography variant="body2">
                      {t("list_total_selected_label", {
                        selected:
                          selected !== pagination.count
                            ? selected
                            : pagination.count,
                        total: pagination.count,
                      })}
                    </Typography>
                    {selected !== pagination.count && (
                      <Button onClick={onSelectAll} size="small">
                        {t("selected_all_label", {
                          total: pagination.count,
                        })}
                      </Button>
                    )}
                  </Stack>
                )}
              </Box>
              <TablePagination
                component="div"
                backIconButtonProps={{
                  "aria-label": t("pagination.back_button"),
                }}
                nextIconButtonProps={{
                  "aria-label": t("pagination.next_button"),
                }}
                {...pagination}
                count={pagination.count}
                labelRowsPerPage={t("pagination.rows_per_page_label")}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
