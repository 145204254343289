/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Direct Debits within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApplyPaymentsRequest {
    submissionId: number;
}

export interface GenerateSubmissionFileRequest {
    submissionId: number;
}

/**
 * 
 */
export class DirectDebitCommandApi extends runtime.BaseAPI {

    /**
     * Apply payments to a direct debit submission
     */
    async applyPaymentsRaw(requestParameters: ApplyPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling applyPayments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["apply-payments:manage"]);
        }

        const response = await this.request({
            path: `/direct-debit-submissions/{submissionId}/apply-payments`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Apply payments to a direct debit submission
     */
    async applyPayments(requestParameters: ApplyPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applyPaymentsRaw(requestParameters, initOverrides);
    }

    /**
     * Generate the submission file
     */
    async generateSubmissionFileRaw(requestParameters: GenerateSubmissionFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling generateSubmissionFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["generate-submission-file:manage"]);
        }

        const response = await this.request({
            path: `/direct-debit-submissions/{submissionId}/generate-file`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Generate the submission file
     */
    async generateSubmissionFile(requestParameters: GenerateSubmissionFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.generateSubmissionFileRaw(requestParameters, initOverrides);
    }

}
