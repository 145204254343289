import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { AssetValuationCommandApi } from "generated/core/apis";
import { UpdateValuationModel } from "generated/core/models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";
import { useSWRConfig } from "swr";
import * as valuationKey from "./keys";

interface UseUpdateValuationReturn {
  command: (updateValuationModel: UpdateValuationModel) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
  reset(): void;
}

export const useUpdateValuation = (
  valuationId: number,
  options: {
    onSuccess?: () => void;
  } = {},
): UseUpdateValuationReturn => {
  const api = useApi(AssetValuationCommandApi);
  const swrConfig = useSWRConfig();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("assets");

  const error = useResponseError([
    ["duplicate_date", t("valuations.update.duplicated_dates_error")],
  ]);

  const command = async (
    updateValuationModel: UpdateValuationModel,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      await api.updateAssetValuation({
        valuationId,
        updateValuationModel,
      });
      options.onSuccess?.();
      swrConfig.mutate(valuationKey.details(valuationId));
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = (): void => {
    error.reset();
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
    reset,
  };
};
