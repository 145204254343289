import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { AgreementFeeModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface FeesAndCommissionsDetail {
  annualAdministrationFee: string;
  arrangementFee: string;
  legalFee: string;
  facilityFee: string;
  documentationFee: string;
  valuationFee: string;
  optionToPurchaseFee: string;
  brokerCommission: number;
  supplierCommission: number;
  brokerAmount: string;
  supplierAmount: string;
  landRegistryFee: string;
  exitFee: string;
  administrationFee?: string;
}

interface FeesAndCommissionsDetailsProps {
  feesAndCommissions?: FeesAndCommissionsDetail;
  fees?: AgreementFeeModel[];
}

export default function FeesAndCommissionsDetails({
  feesAndCommissions,
  fees,
}: FeesAndCommissionsDetailsProps): ReactElement {
  const { t } = useTranslation("agreements");
  const { formatAmount, formatNumber } = useFormat();

  return (
    <DescriptionList label={t("fees_commissions.heading")}>
      <>
        {fees?.map((fee) => {
          return (
            <DescriptionList.Item label={fee.name} key={fee.name}>
              {formatAmount(fee.amount)}
            </DescriptionList.Item>
          );
        })}

        <DescriptionList.Item label={t("fees_commissions.broker_commission")}>
          {formatNumber(feesAndCommissions?.brokerCommission)}
        </DescriptionList.Item>

        <DescriptionList.Item label={t("fees_commissions.broker_amount")}>
          {formatAmount(feesAndCommissions?.brokerAmount)}
        </DescriptionList.Item>

        <DescriptionList.Item label={t("fees_commissions.supplier_commission")}>
          {formatNumber(feesAndCommissions?.supplierCommission)}
        </DescriptionList.Item>

        <DescriptionList.Item label={t("fees_commissions.supplier_amount")}>
          {formatAmount(feesAndCommissions?.supplierAmount)}
        </DescriptionList.Item>
      </>
    </DescriptionList>
  );
}
