/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConfigurePortfolioModel,
  CreateLetterTemplateTypeMetadataModel,
  PortfolioListItemModel,
} from '../models';
import {
    ConfigurePortfolioModelFromJSON,
    ConfigurePortfolioModelToJSON,
    CreateLetterTemplateTypeMetadataModelFromJSON,
    CreateLetterTemplateTypeMetadataModelToJSON,
    PortfolioListItemModelFromJSON,
    PortfolioListItemModelToJSON,
} from '../models';

export interface ConfigurePortfolioRequest {
    portfolioId: number;
    configurePortfolioModel: ConfigurePortfolioModel;
}

export interface CreateLetterTemplateRequest {
    portfolioId: number;
    metadata: CreateLetterTemplateTypeMetadataModel;
    file: Blob;
}

/**
 * 
 */
export class PortfolioApi extends runtime.BaseAPI {

    /**
     * Configure a portfolio
     */
    async configurePortfolioRaw(requestParameters: ConfigurePortfolioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling configurePortfolio.');
        }

        if (requestParameters.configurePortfolioModel === null || requestParameters.configurePortfolioModel === undefined) {
            throw new runtime.RequiredError('configurePortfolioModel','Required parameter requestParameters.configurePortfolioModel was null or undefined when calling configurePortfolio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:manage"]);
        }

        const response = await this.request({
            path: `/documents/portfolios/{portfolioId}/configure`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigurePortfolioModelToJSON(requestParameters.configurePortfolioModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Configure a portfolio
     */
    async configurePortfolio(requestParameters: ConfigurePortfolioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.configurePortfolioRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new letter template type
     */
    async createLetterTemplateRaw(requestParameters: CreateLetterTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling createLetterTemplate.');
        }

        if (requestParameters.metadata === null || requestParameters.metadata === undefined) {
            throw new runtime.RequiredError('metadata','Required parameter requestParameters.metadata was null or undefined when calling createLetterTemplate.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling createLetterTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["templates:manage"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.metadata !== undefined) {
            formParams.append('metadata', new Blob([JSON.stringify(CreateLetterTemplateTypeMetadataModelToJSON(requestParameters.metadata))], { type: "application/json", }));
                    }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/documents/portfolios/{portfolioId}/template`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new letter template type
     */
    async createLetterTemplate(requestParameters: CreateLetterTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createLetterTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * List all portfolios
     */
    async listAllPortfoliosRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PortfolioListItemModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["batches:view"]);
        }

        const response = await this.request({
            path: `/documents/portfolios`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioListItemModelFromJSON));
    }

    /**
     * List all portfolios
     */
    async listAllPortfolios(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PortfolioListItemModel>> {
        const response = await this.listAllPortfoliosRaw(initOverrides);
        return await response.value();
    }

}
