import { useQuery, UseQueryReturn } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/assets/manufacturers/keys";
import { useApi } from "common/providers/ApiProvider";
import { AssetQueryApi } from "generated/core/apis/AssetQueryApi";
import { AssetManufacturerModel } from "generated/core/models/AssetManufacturerModel";
import { ErrorLike } from "support/error-handler";

type UseListAssetManufacturers = () => UseQueryReturn<
  AssetManufacturerModel[],
  ErrorLike
>;

export const useListAssetManufacturers: UseListAssetManufacturers = () => {
  const api = useApi(AssetQueryApi);

  return useQuery(keys.list(), () => {
    return api.listAssetManufacturers();
  });
};
