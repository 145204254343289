import { FormDialog } from "@ldms/mui-sdk/templates";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useUpdateThirdParty } from "api/third-parties";
import ThirdPartyControls from "apps/servicing/modules/settings/components/ThirdPartyControls";
import { UpdateThirdPartyFieldValues } from "apps/servicing/modules/settings/types";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import { ReactElement } from "react";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

const useThirdPartyTranslation = () => useTranslation("third-parties");

const useUpdateThirdPartiesResolver = () => {
  const { t } = useThirdPartyTranslation();

  return useYupResolver<UpdateThirdPartyFieldValues>((yup) =>
    yup.object().shape({
      contact: yup.string().maxCharacters(50, t("edit.contact_label")),
      name: yup
        .string()
        .isRequired(t(nameLabel))
        .maxCharacters(50, t(nameLabel)),
      addressLine1: yup
        .string()
        .maxCharacters(50, t(addressLine1Label))
        .optionalAddressLine1Validator(
          "thirdPartyAddressLine1",
          t(addressLine1Label),
        ),
      addressLine4: yup
        .string()
        .maxCharacters(50, t("edit.address_line_4_label")),
      addressLine3: yup
        .string()
        .maxCharacters(50, t("edit.address_line_3_label")),
      addressLine2: yup
        .string()
        .maxCharacters(50, t("edit.address_line_2_label")),
      postcode: yup
        .string()
        .maxCharacters(10, t(postcodeLabel))
        .optionalPostcodeValidator("thirdPartyPostcode", t(postcodeLabel)),
      emailAddress: yup
        .string()
        .maxCharacters(200, t("edit.email_address_label")),
      faxNumber: yup.string().maxCharacters(20, t("edit.fax_number_label")),
      telephone: yup.string().maxCharacters(20, t("edit.telephone_label")),
      vatRegistrationNumber: yup
        .string()
        .maxCharacters(50, t("edit.vat_registration_number_label")),
    }),
  );
};

interface UpdateThirdPartyFormControlsProps {
  errors: FieldErrors<UpdateThirdPartyFieldValues>;
  register: UseFormRegister<UpdateThirdPartyFieldValues>;
  control: Control<UpdateThirdPartyFieldValues>;
}
const nameLabel = "edit.name_label";
const addressLine1Label = "edit.address_line_1_label";
const postcodeLabel = "edit.postcode_label";

function UpdateThirdPartyFormControls({
  errors,
  register,
  control,
}: UpdateThirdPartyFormControlsProps): ReactElement {
  const { t } = useThirdPartyTranslation();

  return (
    <ThirdPartyControls
      errors={errors}
      labels={{
        addressLine1: t("edit.address_line_1_label"),
        addressLine2: t("edit.address_line_2_label"),
        addressLine3: t("edit.address_line_3_label"),
        addressLine4: t("edit.address_line_4_label"),
        postcode: t("edit.postcode_label"),
        telephone: t("edit.telephone_label"),
        faxNumber: t("edit.fax_number_label"),
        emailAddress: t("edit.email_address_label"),
      }}
      register={register}
    >
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item sm={6}>
          <TextField
            {...register("type", { disabled: true })}
            label={t("edit.type_label")}
          />
        </Grid>
        <Grid item sm={6}>
          <ControlledTextField
            helperText={errors?.active?.message}
            SelectProps={{ displayEmpty: true }}
            error={errors?.active?.message}
            control={control}
            id="active"
            name="active"
            label={t("edit.status_label")}
            select
          >
            {[
              { label: t("list.active_label"), value: "true" },
              { label: t("list.inactive_label"), value: "false" },
            ].map((status) => (
              <MenuItem key={status.label} value={status.value}>
                {status.label}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("name")}
            error={Boolean(errors?.name?.message)}
            helperText={errors?.name?.message}
            required
            label={t("edit.name_label")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("contact")}
            error={Boolean(errors?.contact?.message)}
            helperText={errors?.contact?.message}
            label={t("edit.contact_label")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("vatRegistrationNumber")}
            label={t("edit.vat_registration_number_label")}
            error={Boolean(errors?.vatRegistrationNumber?.message)}
            helperText={errors?.vatRegistrationNumber?.message}
          />
        </Grid>
      </Grid>
    </ThirdPartyControls>
  );
}

interface UpdateThirdPartyDialogContainerProps {
  id: number;
  onSuccess(): void;
  onClose(): void;
  open: boolean;
  defaultValues?: UpdateThirdPartyFieldValues;
}

export default function UpdateThirdPartyDialogContainer({
  id,
  defaultValues,
  onSuccess,
  onClose,
  open,
}: UpdateThirdPartyDialogContainerProps): ReactElement {
  const { t } = useThirdPartyTranslation();
  const resolver = useUpdateThirdPartiesResolver();

  const updateThirdParty = useUpdateThirdParty(id, {
    onSuccess,
  });

  const onSubmit = (data: UpdateThirdPartyFieldValues) =>
    updateThirdParty.command({
      ...data,
    });

  const handleClose = () => {
    onClose();
    updateThirdParty.reset();
  };

  return (
    <FormDialog
      open={open}
      ready={Boolean(defaultValues)}
      onClose={handleClose}
      defaultValues={defaultValues}
      disabled={updateThirdParty.isLoading}
      onSubmit={onSubmit}
      resolver={resolver}
      title={t("edit.title_label")}
    >
      {(form) => (
        <>
          <UpdateThirdPartyFormControls
            errors={form.formState.errors}
            register={form.register}
            control={form.control}
          />
          {updateThirdParty.error && (
            <Typography color="error" role="alert">
              {updateThirdParty.error.message}
            </Typography>
          )}
        </>
      )}
    </FormDialog>
  );
}
