/* tslint:disable */
/* eslint-disable */
/**
 * Engage Admin
 * API for managing users within the Engage Admin application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Create a new user
 * @export
 * @interface CreateUserModel
 */
export interface CreateUserModel {
    /**
     * The users first name
     * @type {string}
     * @memberof CreateUserModel
     */
    firstName: string;
    /**
     * The users last name
     * @type {string}
     * @memberof CreateUserModel
     */
    lastName: string;
    /**
     * The username
     * @type {string}
     * @memberof CreateUserModel
     */
    username: string;
    /**
     * The user's email
     * @type {string}
     * @memberof CreateUserModel
     */
    email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserModel
     */
    roles: Array<string>;
    /**
     * A password
     * @type {string}
     * @memberof CreateUserModel
     */
    password: string;
}

/**
 * Check if a given object implements the CreateUserModel interface.
 */
export function instanceOfCreateUserModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "roles" in value;
    isInstance = isInstance && "password" in value;

    return isInstance;
}

export function CreateUserModelFromJSON(json: any): CreateUserModel {
    return CreateUserModelFromJSONTyped(json, false);
}

export function CreateUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'username': json['username'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'roles': json['roles'],
        'password': json['password'],
    };
}

export function CreateUserModelToJSON(value?: CreateUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'username': value.username,
        'email': value.email,
        'roles': value.roles,
        'password': value.password,
    };
}

