/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnboardedAgreementModel
 */
export interface OnboardedAgreementModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof OnboardedAgreementModel
     */
    readonly id: number;
    /**
     * The agreement number for the completed onboarding
     * @type {string}
     * @memberof OnboardedAgreementModel
     */
    agreementNumber: string;
}

/**
 * Check if a given object implements the OnboardedAgreementModel interface.
 */
export function instanceOfOnboardedAgreementModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "agreementNumber" in value;

    return isInstance;
}

export function OnboardedAgreementModelFromJSON(json: any): OnboardedAgreementModel {
    return OnboardedAgreementModelFromJSONTyped(json, false);
}

export function OnboardedAgreementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardedAgreementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'agreementNumber': json['agreementNumber'],
    };
}

export function OnboardedAgreementModelToJSON(value?: OnboardedAgreementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreementNumber': value.agreementNumber,
    };
}

