import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ReadOnlyUserDetails from "apps/admin/components/ReadOnlyUserDetails";
import { PasswordCriteriaAlert, PasswordTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "support/react-hook-form";

interface ResetPasswordFormDialogProps {
  onSubmit(data: ResetPasswordFormValues): void;
  onClose(): void;
  open: boolean;
  error?: string;
  firstName: string;
  lastName: string;
  username: string;
}

export interface ResetPasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

export default function ResetPasswordFormDialog({
  onSubmit: onSubmitCallback,
  onClose,
  open,
  error,
  firstName,
  lastName,
  username,
}: Readonly<ResetPasswordFormDialogProps>): ReactElement {
  const { t } = useTranslation("users");
  const resolver = useYupResolver<ResetPasswordFormValues>((yup) =>
    yup.object().shape({
      newPassword: yup.string().isRequired(t("new_password")),
      confirmPassword: yup
        .string()
        .isRequired(t("confirm_new_password"))
        .oneOf([yup.ref("newPassword")], t("password_mismatch")),
    }),
  );

  const form = useForm<ResetPasswordFormValues>({
    resolver,
  });

  const handleOnClose = (): void => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleOnClose}
      aria-labelledby="reset-password-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="reset-password-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{t("reset_password")}</Box>
          <Box>
            <IconButton
              aria-label={t("common:close")}
              onClick={handleOnClose}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <form
        aria-label={t("reset_password")}
        onSubmit={form.handleSubmit(onSubmitCallback)}
      >
        <DialogContent>
          <ReadOnlyUserDetails
            user={{
              username,
              firstName,
              lastName,
            }}
            labels={{
              username: t("create_user.username_label"),
              firstName: t("create_user.firstname_label"),
              lastName: t("create_user.lastname_label"),
            }}
          />
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <PasswordTextField
                {...form.register("newPassword")}
                error={form.formState.errors?.newPassword?.message}
                visibleLabel={t("show_new_password")}
                obfuscatedLabel={t("hide_new_password")}
                label={t("new_password")}
              />
            </Grid>
            <Grid item sm={6}>
              <PasswordTextField
                {...form.register("confirmPassword")}
                error={form.formState.errors?.confirmPassword?.message}
                visibleLabel={t("show_confirm_password")}
                obfuscatedLabel={t("hide_confirm_password")}
                label={t("confirm_new_password")}
              />
            </Grid>
          </Grid>

          {error && (
            <Box py={2}>
              <Typography color="error">{error}</Typography>
            </Box>
          )}

          <Box marginTop={3}>
            <PasswordCriteriaAlert />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleOnClose}>
            {t("common:cancel")}
          </Button>
          <Button
            type="submit"
            disabled={form.formState.isSubmitting}
            variant="contained"
            color="primary"
          >
            {t("reset_password")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
