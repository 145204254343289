import { Loader } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useListCustomerMemos } from "api/customers/memos";
import { MemoList, ViewDateToggle } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ListLayout from "common/layouts/ListLayout";
import { startOfDay, subMonths } from "date-fns";
import { ChangeEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface ClientMemosProps {
  clientId: string;
}

type FromValues = "lastSixMonths" | "";

interface ClientMemosParameters {
  from: FromValues;
}

export default function ClientMemos({
  clientId,
}: ClientMemosProps): ReactElement {
  const appConfig = useAppConfiguration();
  const sixMonthsAgo = startOfDay(subMonths(new Date(), 6));
  const { t } = useTranslation(["clients", "common"]);
  const [parameters, setParameters] = useState<ClientMemosParameters>({
    from: "lastSixMonths",
  });
  const memos = useListCustomerMemos(clientId, {
    from: parameters.from === "lastSixMonths" ? sixMonthsAgo : undefined,
  });

  const handleDateRangeChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setParameters({
      from: event.target.value as FromValues,
    });
  };

  return (
    <ListLayout
      filters={
        <Box marginTop={-0.5}>
          <ViewDateToggle
            onChange={handleDateRangeChange}
            value={parameters.from}
            testId="fromToggle.all"
          />
        </Box>
      }
    >
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(memos.data ?? memos.error)}
        render={() => {
          if (memos.error || !memos.data) {
            return (
              <Typography
                aria-label={t("common:error.default")}
                color="error"
                role="alert"
                data-testid="clientMemos.error"
              >
                {t("common:error.default")}
              </Typography>
            );
          }

          return (
            <MemoList
              loading={memos.isValidating}
              memos={memos.data.map((memo) => ({
                ...memo,
                operatorName: memo.createdBy,
                agreementNumber: memo.agreementReference,
                agreementHref: memo.agreementLegacyId
                  ? `${appConfig.appRoutes.servicing}/agreements/${memo.agreementLegacyId}`
                  : undefined,
                created: memo.createdAt,
              }))}
              showAgreementNumber
            />
          );
        }}
      />
    </ListLayout>
  );
}
