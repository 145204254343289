import { QueryKey } from "api";

export const all = (): QueryKey => ["settlement-quotes"];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (settlementQuoteId: number): QueryKey => [
  ...details(),
  settlementQuoteId,
];
