import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { GetApp } from "@mui/icons-material";
import {
  Button,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { BatchStatus } from "apps/documents/types";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import { BatchModel } from "generated/documents/models/BatchModel";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const batchDownloadableStatuses = [
  BatchStatus.Generated,
  BatchStatus.Downloaded,
  BatchStatus.Printed,
];

interface BatchListProps {
  data: BatchModel[];
  loading: boolean;
  onDownload: (batchId: string) => void;
  onPrint: (batchId: string) => void;
  onViewBatchContent(batchContent: BatchModel, paddedBatchId: string): void;
}

const numberRegex = /^\d*$/;

const getPaddedId = (id: string): string => {
  if (numberRegex.test(id)) {
    return `B${String(id).padStart(6, "0")}`;
  } else {
    return id;
  }
};

const isBatchDownloadable = (status: string): boolean => {
  return batchDownloadableStatuses.includes(status as BatchStatus);
};

export default function BatchList({
  data,
  loading,
  onDownload,
  onPrint,
  onViewBatchContent,
}: Readonly<BatchListProps>): ReactElement {
  const locale = useLocale();
  const { t } = useTranslation("documents");

  const tableHeaders: string[] = [
    t("batch_list.number_label"),
    t("batch_list.created_date_label"),
    t("batch_list.last_activity_label"),
    t("batch_list.portfolio_label"),
    t("batch_list.type_label"),
    t("batch_list.size_label"),
    t("batch_list.status_label"),
    "",
  ];

  const onDownloadHandler = (batchId: string) => async (): Promise<void> => {
    onDownload(batchId);
  };

  const handleViewBatchContent =
    (batch: BatchModel, paddedBatchId: string) => (): void => {
      onViewBatchContent(batch, paddedBatchId);
    };

  return (
    <>
      <Table size="small" aria-label={t("batches_page_title")} stickyHeader>
        <LoadableTableHead headings={tableHeaders} loading={loading} />

        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id} hover>
              <TableCell
                component="th"
                padding="none"
                sx={{ width: "20%" }}
                scope="row"
              >
                <ListItemButton
                  dense
                  onClick={handleViewBatchContent(
                    item,
                    getPaddedId(item.reference),
                  )}
                >
                  <ListItemText
                    primary={getPaddedId(item.reference)}
                    primaryTypographyProps={{ color: "primary" }}
                  />
                </ListItemButton>
              </TableCell>
              <TableCell>
                {item.createdAt
                  ? locale.formatDateTime(new Date(item.createdAt))
                  : "-"}
              </TableCell>
              <TableCell>
                {item.downloadedAt && item.downloadedBy
                  ? `${item.status} - ${locale.formatDateTime(
                      new Date(item.downloadedAt),
                    )} - ${item.downloadedBy}`
                  : "-"}
              </TableCell>
              <TableCell>{item.portfolioName}</TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>{item.size}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell
                sx={{
                  paddingY: 0.25,
                  whiteSpace: "nowrap",
                }}
                align="right"
              >
                {item.status === "Downloaded" && (
                  <AccessControl
                    allowedPermissions={["documents:batches:manage"]}
                  >
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => onPrint(item.id)}
                    >
                      {t("batch_list.print_button")}
                    </Button>
                  </AccessControl>
                )}
                {isBatchDownloadable(item.status) && (
                  <Button
                    color="primary"
                    onClick={onDownloadHandler(item.id)}
                    size="small"
                    startIcon={<GetApp fontSize="inherit" />}
                  >
                    {t("batch_list.download_button")}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(data || []).length === 0 && <NoResults />}
    </>
  );
}
