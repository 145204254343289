import { PagedQuery, useQueryPaged } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ThirdPartyQueryApi } from "generated/core/apis";
import { ThirdPartyListItemModel } from "generated/core/models";
import { list } from "./keys";

export const useListThirdParties: PagedQuery<
  ThirdPartyListItemModel,
  { query?: string; type?: string }
> = ({ pageSize, params = {} } = {}) => {
  const api = useApi(ThirdPartyQueryApi);

  return useQueryPaged(
    list({ query: params.query }),
    async (_key, paging) =>
      api.listThirdPartiesRaw({
        limit: paging.pageSize,
        offset: paging.offset,
        query: params.query,
        type: params.type,
      }),
    { limit: pageSize },
  );
};
