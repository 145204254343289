import { CreateSettlementQuotesFieldValues } from "apps/servicing/components/CreateSettlementQuoteFormControls/CreateSettlementQuoteFormControls";
import { useYupResolver } from "common/hooks";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function useSettlementQuoteResolver(
  totalAmountDue: number,
): Resolver<CreateSettlementQuotesFieldValues> {
  const { t } = useTranslation("agreements");
  const transformField = (v: string, o: string): string | null | undefined => {
    return o === "" ? undefined : v;
  };

  const discountAmountLabel =
    "settlement_quotes.create_quote.discount_amount_label";
  const discountPercentageLabel =
    "settlement_quotes.create_quote.discount_percentage_label";

  return useYupResolver<CreateSettlementQuotesFieldValues>((yup) =>
    yup.object().shape({
      discountAmount: yup.number(t(discountAmountLabel)).when("discountType", {
        is: "Amount",
        then: yup
          .number(t(discountAmountLabel))
          .nullable()
          .transform(transformField)
          .isRequired(t(discountAmountLabel))
          .greaterThanAmount(0, t(discountAmountLabel))
          .notGreaterThanAmount(totalAmountDue, t(discountAmountLabel)),
      }),
      discountPercentage: yup
        .number(t(discountPercentageLabel))
        .when("discountType", {
          is: "Percentage",
          then: yup
            .number(t(discountPercentageLabel))
            .nullable()
            .transform(transformField)
            .isRequired(t(discountPercentageLabel))
            .greaterThanAmount(0, t(discountPercentageLabel))
            .notGreaterThanPercentage(100, t(discountPercentageLabel)),
        }),
    }),
  );
}
