import { QueryKey } from "api";
import { keys } from "api/agreements";

export const all = (agreement: number): QueryKey => [
  ...keys.detail(agreement),
  "settlement-quotes",
];

export const list = (agreement: number): QueryKey => [
  ...all(agreement),
  "list",
];

export const details = (agreement: number): QueryKey => [
  ...all(agreement),
  "detail",
];

export const detail = (agreement: number): QueryKey => [...details(agreement)];
