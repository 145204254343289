import {
  useCommand,
  UseCommandOptions,
  UseCommandReturn,
} from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import {
  BatchCommandApi,
  RequestBatchFileDownloadRequest,
} from "generated/documents/apis";
import fileDownloader from "support/file-downloader";
import * as keys from "./keys";

export const useDownloadBatch = (
  options: UseCommandOptions<void> = {},
): UseCommandReturn<RequestBatchFileDownloadRequest, void> => {
  const api = useApi(BatchCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand(
    async (model) => {
      const response = await api.requestBatchFileDownloadRaw({
        batchId: model.batchId,
      });
      fileDownloader(response);
    },
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.all());
        options.onSuccess?.();
      },
    },
  );
};
