import { Box, Button, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface QueryErrorProps {
  onRetry: () => void;
}

export default function QueryError({ onRetry }: QueryErrorProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center">
      <Box
        aria-describedby="error-description"
        aria-labelledby="error-label"
        role="alert"
        maxWidth={300}
        textAlign="center"
      >
        <Typography id="error-label" gutterBottom variant="h6">
          {t("query_error.unexpected_error_title")}
        </Typography>
        <Typography id="error-description">
          {t("query_error.unexpected_error_message")}
        </Typography>
        <Box marginTop={2}>
          <Button color="primary" onClick={onRetry} variant="contained">
            {t("query_error.unexpected_error_retry_button")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
