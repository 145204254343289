import { useFormat } from "@ldms/mui-sdk/formatting";
import { AmountField, Form, NumberField } from "@ldms/mui-sdk/forms";
import { Box, MenuItem, Typography } from "@mui/material";
import {
  FinancialDetailsBalanceTable,
  FinancialDetailsCashPriceTable,
  OnboardingStepActions,
} from "apps/onboarding/components";
import { useOnboarding } from "apps/onboarding/providers";
import { TypeOfLeaseEnum } from "apps/onboarding/types";
import { ControlledTextField } from "common/components";
import CashDepositField from "common/components/CashDepositField";
import { useLocale, useYupResolver } from "common/hooks";
import { useStepper } from "common/providers";
import { FrequencyOfInstalmentsModel } from "generated/onboarding/models";
import { ReactElement } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

const residualValueLabel = "operating_lease.residual_value_label";
const cashDepositLabel = "operating_lease.cash_deposit_label";
const blindDiscountLabel = "operating_lease.blind_discount_label";
const vatReclaimMonthLabel = "operating_lease.vat_reclaim_month_label";
const subsidyPaymentLabel = "operating_lease.subsidy_payment_label";
const partExchangeLabel = "operating_lease.part_exchange_label";

export interface OperatingLeaseFinancialDetailsFormModel {
  typeOfLease: string;
  residualValue: number;
  cashDeposit?: number;
  blindDiscount?: number;
  vatReclaimMonth?: number;
  subsidyPayment?: number;
  partExchange?: number;
  secondaryRentalAmount?: number;
  secondaryRentalFrequency?: FrequencyOfInstalmentsModel | "";
  secondaryRentalIntroducerShare?: number;
  secondaryRentalMaintenenceCharge?: number;
  secondaryRentalInsurancePremium?: number;
}

const secondaryRentalAmountLabel =
  "operating_lease.secondary_rental_amount_label";
const secondaryRentalFrequencyLabel =
  "operating_lease.secondary_rental_frequency_label";
const secondaryRentalIntroducerShareLabel =
  "operating_lease.secondary_rental_introducer_share_label";
const secondaryRentalMaintenenceChargeLabel =
  "operating_lease.secondary_rental_maintenence_charge_label";
const secondaryRentalInsurancePremiumLabel =
  "operating_lease.secondary_rental_insurance_premium_label";
const greaterThanZero = "common:validation.is_greater_than_zero";

const transformField = (v: string, o: string): string | null | undefined => {
  return o === "" ? undefined : v;
};

const getValue = (amount?: number): number => {
  return amount ? Number(amount) : 0;
};

const useOperatingLeaseFinancialDetailsResolver = () => {
  const { t } = useTranslation("onboardings");
  const onboarding = useOnboarding();

  const isFundedVat = onboarding.state.agreementDetails?.isFundedVat;

  return useYupResolver<OperatingLeaseFinancialDetailsFormModel>((yup) =>
    yup.object().shape({
      cashDeposit: yup
        .number(t(cashDepositLabel))
        .nullable()
        .transform(transformField)
        .minAmount(0, t(cashDepositLabel))
        .maxAmount(99999999.99, t(cashDepositLabel)),
      blindDiscount: yup
        .number(t(blindDiscountLabel))
        .nullable()
        .transform(transformField)
        .minAmount(0, t(blindDiscountLabel))
        .maxAmount(999999.99, t(blindDiscountLabel)),
      residualValue: yup
        .number(t(residualValueLabel))
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .isRequired(t(residualValueLabel))
        .maxAmount(99999999.99, t(residualValueLabel)),
      vatReclaimMonth: yup
        .number(t(vatReclaimMonthLabel))
        .transform(transformField)
        .between(
          1,
          13,
          t("form_validation.vat_reclaim_month_must_be_between", {
            label: t(vatReclaimMonthLabel),
          }),
        )
        .test({
          test: (vatReclaimMonthValue, context) => {
            if (isFundedVat && !vatReclaimMonthValue) {
              return context.createError({
                message: t(
                  "form_validation.vat_reclaim_is_required_when_funding_vat",
                  {
                    label: t(vatReclaimMonthLabel),
                  },
                ),
                path: context.path,
              });
            }
            return true;
          },
          name: "requiredWhenVatReclaim",
        }),
      subsidyPayment: yup
        .number(t(subsidyPaymentLabel))
        .nullable()
        .transform(transformField)
        .minAmount(0, t(subsidyPaymentLabel))
        .maxAmount(99999999.99, t(subsidyPaymentLabel)),
      partExchange: yup
        .number(t(partExchangeLabel))
        .nullable()
        .transform(transformField)
        .minAmount(0, t(partExchangeLabel))
        .maxAmount(99999999.99, t(partExchangeLabel)),
      typeOfLease: yup
        .string()
        .isRequired(t("operating_lease.type_of_lease_label")),
      secondaryRentalFrequency: yup.string().when("typeOfLease", {
        is: TypeOfLeaseEnum.MinimumTerm,
        then: yup
          .string()
          .transform(transformField)
          .isRequired(t(secondaryRentalFrequencyLabel)),
      }),
      secondaryRentalAmount: yup
        .number(t(secondaryRentalAmountLabel))
        .when("typeOfLease", {
          is: TypeOfLeaseEnum.MinimumTerm,
          then: yup
            .number(t(secondaryRentalAmountLabel))
            .transform(transformField)
            .isRequired(t(secondaryRentalAmountLabel))
            .moreThan(
              0,
              t(greaterThanZero, {
                label: t(secondaryRentalAmountLabel),
              }),
            )
            .maxAmount(99999999.99, t(secondaryRentalAmountLabel)),
        }),
      secondaryRentalIntroducerShare: yup
        .number(t(secondaryRentalIntroducerShareLabel))
        .nullable()
        .transform(transformField)
        .maxAmount(100, t(secondaryRentalIntroducerShareLabel))
        .minAmount(0, t(secondaryRentalIntroducerShareLabel)),

      secondaryRentalMaintenenceCharge: yup
        .number(t(secondaryRentalMaintenenceChargeLabel))
        .transform(transformField)
        .nullable()
        .maxAmount(99999999.99, t(secondaryRentalMaintenenceChargeLabel))
        .moreThan(
          0,
          t(greaterThanZero, {
            label: t(secondaryRentalMaintenenceChargeLabel),
          }),
        ),
      secondaryRentalInsurancePremium: yup
        .number(t(secondaryRentalInsurancePremiumLabel))
        .transform(transformField)
        .nullable()
        .moreThan(
          0,
          t(greaterThanZero, {
            label: t(secondaryRentalInsurancePremiumLabel),
          }),
        )
        .maxAmount(99999999.99, t(secondaryRentalInsurancePremiumLabel)),
    }),
  );
};

const FinancialDetailsControls = ({
  form,
}: {
  form: UseFormReturn<OperatingLeaseFinancialDetailsFormModel>;
}) => {
  const { t } = useTranslation("onboardings");

  return (
    <>
      <NumberField
        control={form.control}
        error={Boolean(form.formState.errors?.vatReclaimMonth?.message)}
        helperText={form.formState.errors?.vatReclaimMonth?.message}
        label={t(vatReclaimMonthLabel)}
        name="vatReclaimMonth"
      />
      <CashDepositField
        control={form.control}
        error={Boolean(form.formState.errors.cashDeposit?.message)}
        helperText={form.formState.errors.cashDeposit?.message}
      />
      <AmountField
        control={form.control}
        label={t(partExchangeLabel)}
        name="partExchange"
        error={Boolean(form.formState.errors.partExchange?.message)}
        helperText={form.formState.errors.partExchange?.message}
      />
      <AmountField
        control={form.control}
        label={t("operating_lease.residual_value_label")}
        name="residualValue"
        error={Boolean(form.formState.errors?.residualValue?.message)}
        helperText={form.formState.errors?.residualValue?.message}
      />
      <AmountField
        control={form.control}
        label={t(blindDiscountLabel)}
        name="blindDiscount"
        error={Boolean(form.formState.errors?.blindDiscount?.message)}
        helperText={form.formState.errors?.blindDiscount?.message}
      />
      <AmountField
        control={form.control}
        label={t(subsidyPaymentLabel)}
        name="subsidyPayment"
        error={Boolean(form.formState.errors.subsidyPayment?.message)}
        helperText={form.formState.errors.subsidyPayment?.message}
      />

      <ControlledTextField
        control={form.control}
        error={form.formState.errors?.typeOfLease?.message}
        required
        helperText={form.formState.errors?.typeOfLease?.message}
        id="typeOfLease"
        label={t("operating_lease.type_of_lease_label")}
        name="typeOfLease"
        select
        SelectProps={{ displayEmpty: true }}
      >
        <MenuItem value="">{t("common:please_select")}</MenuItem>
        {Object.values(TypeOfLeaseEnum).map((currentValue) => (
          <MenuItem value={String(currentValue)} key={String(currentValue)}>
            {t(currentValue)}
          </MenuItem>
        ))}
      </ControlledTextField>
    </>
  );
};

const MinimumTermControls = ({
  form,
}: {
  form: UseFormReturn<OperatingLeaseFinancialDetailsFormModel>;
}) => {
  const { t } = useTranslation("onboardings");

  return (
    <>
      <Box marginY={3}>
        <Typography variant="h4" variantMapping={{ h4: "h1" }}>
          {t("financial_details.secondary_rentals_heading")}
        </Typography>
      </Box>
      <AmountField
        control={form.control}
        error={Boolean(form.formState.errors.secondaryRentalAmount?.message)}
        name="secondaryRentalAmount"
        label={t(secondaryRentalAmountLabel)}
        required
        helperText={form.formState.errors.secondaryRentalAmount?.message}
      />
      <ControlledTextField
        error={form.formState.errors?.secondaryRentalFrequency?.message}
        name="secondaryRentalFrequency"
        id="secondaryRentalFrequency"
        helperText={form.formState.errors?.secondaryRentalFrequency?.message}
        SelectProps={{ displayEmpty: true }}
        control={form.control}
        label={t(secondaryRentalFrequencyLabel)}
        select
        required
      >
        <MenuItem value="">{t("common:please_select")}</MenuItem>
        {Object.values(FrequencyOfInstalmentsModel).map((value) => (
          <MenuItem value={String(value)} key={String(value)}>
            {value}
          </MenuItem>
        ))}
      </ControlledTextField>
      <NumberField
        control={form.control}
        helperText={
          form.formState.errors.secondaryRentalIntroducerShare?.message
        }
        error={Boolean(
          form.formState.errors.secondaryRentalIntroducerShare?.message,
        )}
        label={t(secondaryRentalIntroducerShareLabel)}
        name="secondaryRentalIntroducerShare"
      />
      <AmountField
        name="secondaryRentalMaintenenceCharge"
        label={t(secondaryRentalMaintenenceChargeLabel)}
        control={form.control}
        helperText={
          form.formState.errors.secondaryRentalMaintenenceCharge?.message
        }
        error={Boolean(
          form.formState.errors.secondaryRentalMaintenenceCharge?.message,
        )}
      />
      <AmountField
        name="secondaryRentalInsurancePremium"
        label={t(secondaryRentalInsurancePremiumLabel)}
        control={form.control}
        helperText={
          form.formState.errors.secondaryRentalInsurancePremium?.message
        }
        error={Boolean(
          form.formState.errors.secondaryRentalInsurancePremium?.message,
        )}
      />
    </>
  );
};

export default function OperatingLeaseFinancialDetails(): ReactElement {
  const { t } = useTranslation("onboardings");
  const onboarding = useOnboarding();
  const stepper = useStepper();
  const locale = useLocale();
  const { formatAmount } = useFormat();

  const resolver = useOperatingLeaseFinancialDetailsResolver();

  const defaultValues = onboarding.state
    .financialDetails as OperatingLeaseFinancialDetailsFormModel;

  const onSubmit = (data: OperatingLeaseFinancialDetailsFormModel): void => {
    onboarding.submitFinancialDetails(data);
    stepper.next();
  };

  return (
    <>
      <Box marginBottom={3}>
        <Typography variant="h4" variantMapping={{ h4: "h1" }}>
          {t("financial_details.title")}
        </Typography>
      </Box>
      <Form
        label={t("financial_details.title")}
        onSubmit={onSubmit}
        defaultValues={{
          ...defaultValues,
          typeOfLease: defaultValues?.typeOfLease ?? "",
          secondaryRentalFrequency:
            defaultValues?.secondaryRentalFrequency ?? "",
        }}
        resolver={resolver}
        options={{ shouldUnregister: true }}
      >
        {(form) => {
          const formWatch = form.watch();
          const residualValueWatch = formWatch.residualValue;
          const totalDeposit =
            getValue(formWatch.cashDeposit) +
            getValue(formWatch.blindDiscount) +
            getValue(formWatch.subsidyPayment) +
            getValue(formWatch.partExchange);

          const totalNetCashPrice =
            onboarding.state.assetDetails?.totalNetCashPrice ?? 0;
          const totalVat = onboarding.state.assetDetails?.totalVat ?? 0;
          const totalCashPriceIncVat = totalNetCashPrice + totalVat;
          const fundedVat = onboarding.state.agreementDetails?.isFundedVat
            ? totalVat
            : 0;
          const balanceFinanced = totalNetCashPrice + fundedVat - totalDeposit;
          const residualValuePercentage =
            ((residualValueWatch ? residualValueWatch : 0) /
              totalNetCashPrice) *
            100;

          return (
            <>
              <FinancialDetailsCashPriceTable
                testId="financialDetails.table"
                totalCashPriceIncVat={{
                  label: "operating_lease.total_inc_cash_price_label",
                  amount: formatAmount(totalCashPriceIncVat),
                }}
                totalNetCashPrice={{
                  label: "operating_lease.total_net_cash_price_label",
                  amount: formatAmount(totalNetCashPrice),
                }}
                totalVat={{
                  label: "operating_lease.vat_on_cash_price_label",
                  amount: formatAmount(totalVat),
                }}
              />

              <FinancialDetailsControls form={form} />

              {formWatch.typeOfLease === TypeOfLeaseEnum.MinimumTerm && (
                <MinimumTermControls form={form} />
              )}

              <FinancialDetailsBalanceTable
                totalDeposit={{
                  label: "operating_lease.total_deposit_label",
                  amount: formatAmount(totalDeposit),
                }}
                balanceFinanced={{
                  label: "operating_lease.balance_financed_label",
                  amount: formatAmount(balanceFinanced),
                }}
                residualValuePercentage={{
                  label: "operating_lease.residual_value_percentage_label",
                  value: `${locale.formatAmount(residualValuePercentage)}%`,
                }}
              />

              <OnboardingStepActions
                back={{
                  label: t("common:stepper.back_button"),
                  onBack: stepper.previous,
                }}
                next={{ label: t("common:stepper.next_button") }}
              />
            </>
          );
        }}
      </Form>
    </>
  );
}
