/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AutomationEventModel } from './AutomationEventModel';
import {
    AutomationEventModelFromJSON,
    AutomationEventModelFromJSONTyped,
    AutomationEventModelToJSON,
} from './AutomationEventModel';
import type { AutomationParameterModel } from './AutomationParameterModel';
import {
    AutomationParameterModelFromJSON,
    AutomationParameterModelFromJSONTyped,
    AutomationParameterModelToJSON,
} from './AutomationParameterModel';

/**
 * 
 * @export
 * @interface CreateAutomationModel
 */
export interface CreateAutomationModel {
    /**
     * The Id of the portfolio
     * @type {number}
     * @memberof CreateAutomationModel
     */
    portfolioId: number;
    /**
     * The user-defined name of the automation
     * @type {string}
     * @memberof CreateAutomationModel
     */
    name: string;
    /**
     * The user-defined description of the automation
     * @type {string}
     * @memberof CreateAutomationModel
     */
    description?: string;
    /**
     * 
     * @type {AutomationEventModel}
     * @memberof CreateAutomationModel
     */
    event: AutomationEventModel;
    /**
     * 
     * @type {Array<AutomationParameterModel>}
     * @memberof CreateAutomationModel
     */
    parameters: Array<AutomationParameterModel>;
}

/**
 * Check if a given object implements the CreateAutomationModel interface.
 */
export function instanceOfCreateAutomationModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "portfolioId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "parameters" in value;

    return isInstance;
}

export function CreateAutomationModelFromJSON(json: any): CreateAutomationModel {
    return CreateAutomationModelFromJSONTyped(json, false);
}

export function CreateAutomationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAutomationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'portfolioId': json['portfolioId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'event': AutomationEventModelFromJSON(json['event']),
        'parameters': ((json['parameters'] as Array<any>).map(AutomationParameterModelFromJSON)),
    };
}

export function CreateAutomationModelToJSON(value?: CreateAutomationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portfolioId': value.portfolioId,
        'name': value.name,
        'description': value.description,
        'event': AutomationEventModelToJSON(value.event),
        'parameters': ((value.parameters as Array<any>).map(AutomationParameterModelToJSON)),
    };
}

