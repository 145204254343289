import { AlertDialog } from "@ldms/mui-sdk/templates";
import {
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import LoadableTableHead from "common/components/LoadableTableHead";
import NoResults from "common/components/NoResults";
import useLocale from "common/hooks/useLocale";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

interface MemoListProps {
  memos: {
    created: Date;
    note: string;
    agreementHref?: string;
    agreementNumber?: string;
    operatorName: string;
  }[];
  loading: boolean;
  showAgreementNumber?: boolean;
}

export default function MemoList({
  loading,
  memos,
  showAgreementNumber,
}: MemoListProps) {
  const { t } = useTranslation("common");
  const locale = useLocale();
  const [fullNote, setFullNote] = useState("");
  const [isNoteOpen, setNoteOpen] = useState(false);

  const makeExpandNoteHandler = (note: string) => () => {
    setFullNote(note);
    setNoteOpen(true);
  };

  const handleCloseNoteDialog = () => setNoteOpen(false);

  return (
    <>
      <Table
        stickyHeader
        size="small"
        data-testid="memoList.table"
        aria-label={t("memos")}
      >
        <LoadableTableHead
          headings={
            showAgreementNumber
              ? [
                  t("memo.created"),
                  t("memo.user"),
                  t("memo.agreement"),
                  t("memo.note"),
                ]
              : [t("memo.created"), t("memo.user"), t("memo.note")]
          }
          loading={loading}
        />
        <TableBody>
          {memos?.map((memo) => (
            <TableRow hover key={uuidv4()}>
              <TableCell sx={{ width: "100px" }}>
                {locale.formatDate(memo.created)}
              </TableCell>
              <TableCell sx={{ width: "160px" }}>{memo.operatorName}</TableCell>
              {showAgreementNumber && (
                <TableCell sx={{ width: "160px" }} padding="none">
                  {memo.agreementHref && (
                    <ListItemButton
                      component={RouterLink}
                      dense
                      to={memo.agreementHref}
                    >
                      <ListItemText
                        primary={memo.agreementNumber}
                        primaryTypographyProps={{ color: "primary" }}
                      />
                    </ListItemButton>
                  )}
                </TableCell>
              )}
              <TableCell
                onClick={makeExpandNoteHandler(memo.note)}
                padding="none"
                sx={{
                  maxWidth: "200px",
                }}
              >
                <ListItemButton dense>
                  <ListItemText
                    primary={memo.note}
                    primaryTypographyProps={{
                      noWrap: true,
                      textOverflow: "ellipsis",
                    }}
                  />
                </ListItemButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(memos || []).length === 0 && <NoResults />}

      <AlertDialog
        content={fullNote}
        labels={{ close: t("alert.close") }}
        onClose={handleCloseNoteDialog}
        open={isNoteOpen}
        title={t("memo_dialog_title")}
      />
    </>
  );
}
