import useQuery, {
  UseQueryOptions,
  UseQueryReturn,
} from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { DocumentQueryApi } from "generated/documents/apis";
import { PortfolioListItemModel } from "generated/documents/models/PortfolioListItemModel";
import { list } from "./keys";

type UseListPortfolios = (
  options?: UseQueryOptions,
) => UseQueryReturn<Array<PortfolioListItemModel>>;

export const useListPortfolios: UseListPortfolios = (options) => {
  const api = useApi(DocumentQueryApi);
  const key = list();

  return useQuery(
    key,
    () => {
      return api.listPortfolios();
    },
    options,
  );
};
