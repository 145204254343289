import {
  useCommand,
  UseCommandOptions,
  UseCommandReturn,
} from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import {
  BatchCommandApi,
  MarkBatchPrintedRequest,
} from "generated/documents/apis";
import * as keys from "./keys";

export const useMarkBatchPrinted = (
  options: UseCommandOptions<void> = {},
): UseCommandReturn<MarkBatchPrintedRequest, void> => {
  const api = useApi(BatchCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand(
    async (model) => {
      return api.markBatchPrinted({ batchId: model.batchId });
    },
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.all());
        options.onSuccess?.();
      },
    },
  );
};
