import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import AddChargeContainer from "apps/servicing/modules/settings/containers/AddChargeContainer";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { useTranslation } from "react-i18next";

function AddChargeView() {
  const { t } = useTranslation("servicing");
  const appConfig = useAppConfiguration();
  const href = `${appConfig.appRoutes.servicing}/settings/charges`;

  return (
    <main>
      <ViewLayout
        title={t("charges.add_charge.title_label")}
        breadcrumbs={[
          {
            href,
            label: t("charges.add_charge.breadcrumb_link_label"),
          },
        ]}
      >
        <ContainedLayout>
          <AddChargeContainer />
        </ContainedLayout>
      </ViewLayout>
    </main>
  );
}

export default withProtectedView({
  allowedPermissions: ["servicing:charges:manage"],
})(AddChargeView);
