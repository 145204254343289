import { QueryKey } from "api";
import { keys } from "api/agreements";

export const all = (agreementId: number): QueryKey => [
  ...keys.detail(agreementId),
  "assets",
];

export const list = (
  agreementId: number,
  params?: { live?: boolean },
): QueryKey => [...all(agreementId), "list", ...(params ? [params?.live] : [])];
