import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { PortfolioQueryApi } from "generated/core/apis";
import { PortfolioModel } from "generated/core/models";
import { PortfoliosApi } from "generated/servicing-v2/apis";
import { PortfolioDetailsModel } from "generated/servicing-v2/models";
import { ErrorLike } from "support/error-handler";
import { keys } from ".";

type UseGetPortfolio = (
  portfolioId: number,
) => UseQueryReturn<PortfolioModel, ErrorLike>;

export const useGetPortfolio: UseGetPortfolio = (portfolioId: number) => {
  const api = useApi(PortfolioQueryApi);

  return useQuery(keys.detail(portfolioId), () =>
    api.getPortfolioDetails({ portfolioId: portfolioId }),
  );
};

type UseGetPortfolioV2 = (
  portfolioId: string,
) => UseQueryReturn<PortfolioDetailsModel, ErrorLike>;

export const useGetPortfolioV2: UseGetPortfolioV2 = (portfolioId: string) => {
  const api = useApi(PortfoliosApi, "v2");

  return useQuery(keys.detail(portfolioId), () =>
    api.getPortfolioDetails({ id: portfolioId }),
  );
};
