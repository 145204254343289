/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstalmentListItemModel
 */
export interface InstalmentListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof InstalmentListItemModel
     */
    readonly instalmentNumber: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof InstalmentListItemModel
     */
    dueDate: Date;
    /**
     * The type of instalment
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    type?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    capital?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    interest?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    vat?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    instalment?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    capitalOutstanding?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    trueOutstandingBalance?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    fees?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    balloon?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    leaseInstalment?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    commissionCharge?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    commissionBalance?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    periodicRental?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    rentalInAdvance?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    commissionUplift?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    periodicDepreciation?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    currentNetBookValue?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    commissionIncome?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    variableCapitalInstalment?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    variableCapitalOutstanding?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    instalmentUplift?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    instalmentUpliftMaintenance?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof InstalmentListItemModel
     */
    billingDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof InstalmentListItemModel
     */
    determination?: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    b2bInstalmentAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    b2bInterestPaid?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    b2bCapitalPaid?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    b2bCapitalOutstanding?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    lenderFeesPaid?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    expensesOther?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    expensesLossPool?: string;
    /**
     * The reference of the invoice that relates to the instalment
     * @type {string}
     * @memberof InstalmentListItemModel
     */
    invoiceReference?: string;
    /**
     * Indicates whether an invoice can be created for the instalment
     * @type {boolean}
     * @memberof InstalmentListItemModel
     */
    allowCreateInvoice?: boolean;
}

/**
 * Check if a given object implements the InstalmentListItemModel interface.
 */
export function instanceOfInstalmentListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "instalmentNumber" in value;
    isInstance = isInstance && "dueDate" in value;

    return isInstance;
}

export function InstalmentListItemModelFromJSON(json: any): InstalmentListItemModel {
    return InstalmentListItemModelFromJSONTyped(json, false);
}

export function InstalmentListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstalmentListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instalmentNumber': json['instalmentNumber'],
        'dueDate': (new Date(json['dueDate'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'capital': !exists(json, 'capital') ? undefined : json['capital'],
        'interest': !exists(json, 'interest') ? undefined : json['interest'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'instalment': !exists(json, 'instalment') ? undefined : json['instalment'],
        'capitalOutstanding': !exists(json, 'capitalOutstanding') ? undefined : json['capitalOutstanding'],
        'trueOutstandingBalance': !exists(json, 'trueOutstandingBalance') ? undefined : json['trueOutstandingBalance'],
        'fees': !exists(json, 'fees') ? undefined : json['fees'],
        'balloon': !exists(json, 'balloon') ? undefined : json['balloon'],
        'leaseInstalment': !exists(json, 'leaseInstalment') ? undefined : json['leaseInstalment'],
        'commissionCharge': !exists(json, 'commissionCharge') ? undefined : json['commissionCharge'],
        'commissionBalance': !exists(json, 'commissionBalance') ? undefined : json['commissionBalance'],
        'periodicRental': !exists(json, 'periodicRental') ? undefined : json['periodicRental'],
        'rentalInAdvance': !exists(json, 'rentalInAdvance') ? undefined : json['rentalInAdvance'],
        'commissionUplift': !exists(json, 'commissionUplift') ? undefined : json['commissionUplift'],
        'periodicDepreciation': !exists(json, 'periodicDepreciation') ? undefined : json['periodicDepreciation'],
        'currentNetBookValue': !exists(json, 'currentNetBookValue') ? undefined : json['currentNetBookValue'],
        'commissionIncome': !exists(json, 'commissionIncome') ? undefined : json['commissionIncome'],
        'variableCapitalInstalment': !exists(json, 'variableCapitalInstalment') ? undefined : json['variableCapitalInstalment'],
        'variableCapitalOutstanding': !exists(json, 'variableCapitalOutstanding') ? undefined : json['variableCapitalOutstanding'],
        'instalmentUplift': !exists(json, 'instalmentUplift') ? undefined : json['instalmentUplift'],
        'instalmentUpliftMaintenance': !exists(json, 'instalmentUpliftMaintenance') ? undefined : json['instalmentUpliftMaintenance'],
        'billingDate': !exists(json, 'billingDate') ? undefined : (new Date(json['billingDate'])),
        'determination': !exists(json, 'determination') ? undefined : (new Date(json['determination'])),
        'b2bInstalmentAmount': !exists(json, 'b2bInstalmentAmount') ? undefined : json['b2bInstalmentAmount'],
        'b2bInterestPaid': !exists(json, 'b2bInterestPaid') ? undefined : json['b2bInterestPaid'],
        'b2bCapitalPaid': !exists(json, 'b2bCapitalPaid') ? undefined : json['b2bCapitalPaid'],
        'b2bCapitalOutstanding': !exists(json, 'b2bCapitalOutstanding') ? undefined : json['b2bCapitalOutstanding'],
        'lenderFeesPaid': !exists(json, 'lenderFeesPaid') ? undefined : json['lenderFeesPaid'],
        'expensesOther': !exists(json, 'expensesOther') ? undefined : json['expensesOther'],
        'expensesLossPool': !exists(json, 'expensesLossPool') ? undefined : json['expensesLossPool'],
        'invoiceReference': !exists(json, 'invoiceReference') ? undefined : json['invoiceReference'],
        'allowCreateInvoice': !exists(json, 'allowCreateInvoice') ? undefined : json['allowCreateInvoice'],
    };
}

export function InstalmentListItemModelToJSON(value?: InstalmentListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dueDate': (value.dueDate.toISOString().substring(0,10)),
        'type': value.type,
        'capital': value.capital,
        'interest': value.interest,
        'vat': value.vat,
        'instalment': value.instalment,
        'capitalOutstanding': value.capitalOutstanding,
        'trueOutstandingBalance': value.trueOutstandingBalance,
        'fees': value.fees,
        'balloon': value.balloon,
        'leaseInstalment': value.leaseInstalment,
        'commissionCharge': value.commissionCharge,
        'commissionBalance': value.commissionBalance,
        'periodicRental': value.periodicRental,
        'rentalInAdvance': value.rentalInAdvance,
        'commissionUplift': value.commissionUplift,
        'periodicDepreciation': value.periodicDepreciation,
        'currentNetBookValue': value.currentNetBookValue,
        'commissionIncome': value.commissionIncome,
        'variableCapitalInstalment': value.variableCapitalInstalment,
        'variableCapitalOutstanding': value.variableCapitalOutstanding,
        'instalmentUplift': value.instalmentUplift,
        'instalmentUpliftMaintenance': value.instalmentUpliftMaintenance,
        'billingDate': value.billingDate === undefined ? undefined : (value.billingDate.toISOString().substring(0,10)),
        'determination': value.determination === undefined ? undefined : (value.determination.toISOString().substring(0,10)),
        'b2bInstalmentAmount': value.b2bInstalmentAmount,
        'b2bInterestPaid': value.b2bInterestPaid,
        'b2bCapitalPaid': value.b2bCapitalPaid,
        'b2bCapitalOutstanding': value.b2bCapitalOutstanding,
        'lenderFeesPaid': value.lenderFeesPaid,
        'expensesOther': value.expensesOther,
        'expensesLossPool': value.expensesLossPool,
        'invoiceReference': value.invoiceReference,
        'allowCreateInvoice': value.allowCreateInvoice,
    };
}

