import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers/ApiProvider";
import { PaymentInstructionsApi } from "generated/servicing-v2/apis";

export const useCancelPaymentIntruction = (
  id: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const paymentInstructionsApi = useApi(PaymentInstructionsApi, "v2");

  return useCommand<void, void, ResponseError>(
    () =>
      paymentInstructionsApi.deletePaymentInstruction({
        id,
      }),
    options,
  );
};
