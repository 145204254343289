import { Autocomplete, TextField } from "@mui/material";
import { useListAssignees } from "api/tasks/assignees/listAssignees";
import { useTranslation } from "react-i18next";

export type AssignedToFilterProps = {
  onChange: (newValue: AssigneeOptions) => void;
  value: AssigneeOptions | undefined;
  label: string;
};

export interface AssigneeOptions {
  name: string;
  id: string;
}

const AssignedToFilter = ({
  onChange,
  value,
  label,
}: AssignedToFilterProps) => {
  const assignees = useListAssignees();
  const { t } = useTranslation(["start"]);
  const translations = {
    all: t("assignee_filter_list.all"),
    unassigned: t("assignee_filter_list.unassigned"),
  };

  const assigneeOptions = (assignees?.data || []).map((name) => ({
    name: [name.firstName, name.lastName].join(" "),
    id: name.id,
  }));

  const defaultOptions = [
    { name: translations.all, id: "All" },
    { name: translations.unassigned, id: "Unassigned" },
  ];

  const makeAssignedToHandler = (newValue: AssigneeOptions) => {
    return onChange(newValue);
  };

  const options = [...defaultOptions, ...assigneeOptions];

  return (
    <Autocomplete
      options={options}
      isOptionEqualToValue={(value) => value.name === translations.all}
      disableClearable
      onChange={(_, newValue) => {
        makeAssignedToHandler(newValue);
      }}
      getOptionLabel={(option) => option.name}
      fullWidth
      id="assigned-to"
      value={value}
      sx={{ width: 250 }}
      renderInput={(params) => (
        <TextField
          {...params}
          name="assignedTo"
          label={t(label)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="none"
        />
      )}
    />
  );
};

export { AssignedToFilter };
