import { KeyValueRow, KeyValueTable } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type FinancialDetailsBalanceTableProps = {
  balanceFinanced: {
    label: string;
    amount: string;
  };
  totalDeposit: {
    label: string;
    amount: string;
  };
  residualValuePercentage?: {
    label: string;
    value: string;
  };
  fleetDiscount?: {
    label: string;
    amount: string;
  };
  manufacturerSubsidy?: {
    label: string;
    amount: string;
  };
};

export default function FinancialDetailsBalanceTable({
  balanceFinanced,
  totalDeposit,
  residualValuePercentage,
  fleetDiscount,
  manufacturerSubsidy,
}: FinancialDetailsBalanceTableProps): ReactElement {
  const { t } = useTranslation("onboardings");
  return (
    <KeyValueTable testId="financialDetails.balance.table">
      {residualValuePercentage ? (
        <KeyValueRow align="right" label={t(residualValuePercentage.label)}>
          {residualValuePercentage.value}
        </KeyValueRow>
      ) : (
        <></>
      )}
      <>
        {fleetDiscount && (
          <KeyValueRow align="right" label={t(fleetDiscount.label)}>
            {fleetDiscount.amount}
          </KeyValueRow>
        )}

        {manufacturerSubsidy && (
          <KeyValueRow align="right" label={t(manufacturerSubsidy.label)}>
            {manufacturerSubsidy.amount}
          </KeyValueRow>
        )}
      </>
      <KeyValueRow align="right" label={t(totalDeposit.label)}>
        {totalDeposit.amount}
      </KeyValueRow>
      <KeyValueRow align="right" label={t(balanceFinanced.label)}>
        {balanceFinanced.amount}
      </KeyValueRow>
    </KeyValueTable>
  );
}
