/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing agreements within onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface FindOnboardedAgreementsRequest {
    agreementNumber: string;
}

export interface GetOnboardedAgreementRequest {
    agreementNumber: string;
}

/**
 * 
 */
export class AgreementApi extends runtime.BaseAPI {

    /**
     * Retrieves onboarded agreements matching the provided agreement number
     */
    async findOnboardedAgreementsRaw(requestParameters: FindOnboardedAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementNumber === null || requestParameters.agreementNumber === undefined) {
            throw new runtime.RequiredError('agreementNumber','Required parameter requestParameters.agreementNumber was null or undefined when calling findOnboardedAgreements.');
        }

        const queryParameters: any = {};

        if (requestParameters.agreementNumber !== undefined) {
            queryParameters['agreementNumber'] = requestParameters.agreementNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreements:view"]);
        }

        const response = await this.request({
            path: `/onboardings/agreements`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Retrieves onboarded agreements matching the provided agreement number
     */
    async findOnboardedAgreements(requestParameters: FindOnboardedAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.findOnboardedAgreementsRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves an onboarded agreement
     */
    async getOnboardedAgreementRaw(requestParameters: GetOnboardedAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementNumber === null || requestParameters.agreementNumber === undefined) {
            throw new runtime.RequiredError('agreementNumber','Required parameter requestParameters.agreementNumber was null or undefined when calling getOnboardedAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreements:view"]);
        }

        const response = await this.request({
            path: `/onboardings/agreements/{agreementNumber}`.replace(`{${"agreementNumber"}}`, encodeURIComponent(String(requestParameters.agreementNumber))),
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Retrieves an onboarded agreement
     */
    async getOnboardedAgreement(requestParameters: GetOnboardedAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getOnboardedAgreementRaw(requestParameters, initOverrides);
    }

}
