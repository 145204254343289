import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import {
  Button,
  Grid,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { BankAccountsListItemModel } from "generated/servicing-v2/models";
import { isEmpty } from "lodash";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export interface BankAccount {
  id: number;
  contactType?: string;
  contactName?: string;
  bankName?: string;
  accountName?: string;
  accountNumber?: string;
  directDebitMandate?: boolean;
}

interface BankAccountsListProps {
  data?: BankAccountsListItemModel[];
  loading: boolean;
  onDeleteBankAccount(bankAccountId: string): void;
  onUpdateBankAccount(bankAccountId: string): void;
}

export default function BankAccountsList({
  data,
  loading,
  onDeleteBankAccount,
  onUpdateBankAccount,
}: BankAccountsListProps): ReactElement {
  const { t } = useTranslation("clients");

  const tableHeaders: string[] = [
    t("bank_accounts.list.contact_label"),
    t("bank_accounts.list.bank_name_label"),
    t("bank_accounts.list.account_holders_name_label"),
    t("bank_accounts.list.mandate_held_label"),
    "",
  ];

  const makeDeleteBankAccountHandler = (id: string) => (): void =>
    onDeleteBankAccount(id);

  const makeUpdateBankAccountHandler = (id: string) => (): void => {
    onUpdateBankAccount(id);
  };

  return (
    <>
      <Table
        aria-label={t("bank_accounts.list.label")}
        size="small"
        stickyHeader
      >
        <LoadableTableHead headings={tableHeaders} loading={loading} />
        <TableBody>
          {data?.map((item) => {
            const contactLabel = item.isCustomer ? "Main" : item.type;
            const primaryText = isEmpty(item.contactName)
              ? contactLabel
              : `${contactLabel} - ${item.contactName}`;

            return (
              <TableRow key={item.systemId} hover>
                <TableCell padding="none">
                  <ListItemButton
                    component={RouterLink}
                    dense
                    to={`./${String(item.systemId)}`}
                  >
                    <ListItemText
                      primary={primaryText}
                      primaryTypographyProps={{ color: "primary" }}
                    />
                  </ListItemButton>
                </TableCell>
                <TableCell>{item.bankName}</TableCell>
                <TableCell>{item.accountHoldersName}</TableCell>
                <TableCell>
                  {item.hasDirectDebitMandate
                    ? t("bank_accounts.mandate_held_label")
                    : t("bank_accounts.no_mandate_held_label")}
                </TableCell>
                <TableCell sx={{ paddingY: 0.25 }}>
                  <AccessControl
                    allowedPermissions={["servicing:bank-accounts:manage"]}
                  >
                    <Grid container spacing={1} justifyContent="flex-end">
                      <Grid item>
                        <Button
                          onClick={makeUpdateBankAccountHandler(item.systemId)}
                          size="small"
                        >
                          {t("bank_accounts.edit_account_button")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={makeDeleteBankAccountHandler(item.systemId)}
                          size="small"
                        >
                          {t("bank_accounts.remove_account_button")}
                        </Button>
                      </Grid>
                    </Grid>
                  </AccessControl>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {(data || []).length === 0 && <NoResults />}
    </>
  );
}
