/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanySizeModel } from './CompanySizeModel';
import {
    CompanySizeModelFromJSON,
    CompanySizeModelFromJSONTyped,
    CompanySizeModelToJSON,
} from './CompanySizeModel';

/**
 * 
 * @export
 * @interface UpdateClientModel
 */
export interface UpdateClientModel {
    /**
     * The company name
     * @type {string}
     * @memberof UpdateClientModel
     */
    companyName?: string;
    /**
     * The company registration number
     * @type {string}
     * @memberof UpdateClientModel
     */
    companyRegistrationNumber?: string;
    /**
     * The customer VAT number
     * @type {string}
     * @memberof UpdateClientModel
     */
    vatRegistrationNumber?: string;
    /**
     * The customer title
     * @type {string}
     * @memberof UpdateClientModel
     */
    title?: string;
    /**
     * The customer first name
     * @type {string}
     * @memberof UpdateClientModel
     */
    firstName?: string;
    /**
     * The customer second name
     * @type {string}
     * @memberof UpdateClientModel
     */
    secondName?: string;
    /**
     * The customer last name
     * @type {string}
     * @memberof UpdateClientModel
     */
    lastName?: string;
    /**
     * First line of customer's address
     * @type {string}
     * @memberof UpdateClientModel
     */
    addressLine1: string;
    /**
     * Second line of customer's address
     * @type {string}
     * @memberof UpdateClientModel
     */
    addressLine2: string;
    /**
     * Third line of customer's address
     * @type {string}
     * @memberof UpdateClientModel
     */
    addressLine3?: string;
    /**
     * Forth line of customer's address
     * @type {string}
     * @memberof UpdateClientModel
     */
    addressLine4?: string;
    /**
     * The customer's postcode
     * @type {string}
     * @memberof UpdateClientModel
     */
    postcode: string;
    /**
     * 
     * @type {CompanySizeModel}
     * @memberof UpdateClientModel
     */
    companySize?: CompanySizeModel;
    /**
     * Sector of Economic Activity
     * @type {string}
     * @memberof UpdateClientModel
     */
    sectorOfEconomicActivity?: string;
    /**
     * Company Sector
     * @type {string}
     * @memberof UpdateClientModel
     */
    companySector?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof UpdateClientModel
     */
    dateOfBirth?: Date;
    /**
     * National Identification Number
     * @type {string}
     * @memberof UpdateClientModel
     */
    nationalIdentificationNumber?: string;
    /**
     * Indicates whether the contact receives system generated messages
     * @type {boolean}
     * @memberof UpdateClientModel
     */
    receiveSystemGeneratedCorrespondence?: boolean;
}

/**
 * Check if a given object implements the UpdateClientModel interface.
 */
export function instanceOfUpdateClientModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "addressLine1" in value;
    isInstance = isInstance && "addressLine2" in value;
    isInstance = isInstance && "postcode" in value;

    return isInstance;
}

export function UpdateClientModelFromJSON(json: any): UpdateClientModel {
    return UpdateClientModelFromJSONTyped(json, false);
}

export function UpdateClientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateClientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyRegistrationNumber': !exists(json, 'companyRegistrationNumber') ? undefined : json['companyRegistrationNumber'],
        'vatRegistrationNumber': !exists(json, 'vatRegistrationNumber') ? undefined : json['vatRegistrationNumber'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'secondName': !exists(json, 'secondName') ? undefined : json['secondName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'addressLine1': json['addressLine1'],
        'addressLine2': json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': json['postcode'],
        'companySize': !exists(json, 'companySize') ? undefined : CompanySizeModelFromJSON(json['companySize']),
        'sectorOfEconomicActivity': !exists(json, 'sectorOfEconomicActivity') ? undefined : json['sectorOfEconomicActivity'],
        'companySector': !exists(json, 'companySector') ? undefined : json['companySector'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'nationalIdentificationNumber': !exists(json, 'nationalIdentificationNumber') ? undefined : json['nationalIdentificationNumber'],
        'receiveSystemGeneratedCorrespondence': !exists(json, 'receiveSystemGeneratedCorrespondence') ? undefined : json['receiveSystemGeneratedCorrespondence'],
    };
}

export function UpdateClientModelToJSON(value?: UpdateClientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'companyRegistrationNumber': value.companyRegistrationNumber,
        'vatRegistrationNumber': value.vatRegistrationNumber,
        'title': value.title,
        'firstName': value.firstName,
        'secondName': value.secondName,
        'lastName': value.lastName,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
        'companySize': CompanySizeModelToJSON(value.companySize),
        'sectorOfEconomicActivity': value.sectorOfEconomicActivity,
        'companySector': value.companySector,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substring(0,10)),
        'nationalIdentificationNumber': value.nationalIdentificationNumber,
        'receiveSystemGeneratedCorrespondence': value.receiveSystemGeneratedCorrespondence,
    };
}

