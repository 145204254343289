import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import usePartialMutate from "common/hooks/usePartialMutate";
import { useApi } from "common/providers/ApiProvider";
import { AgreementCommandApi } from "generated/core/apis/AgreementCommandApi";
import { AddMemoModel } from "generated/core/models/AddMemoModel";
import * as agreementMemoKey from "./keys";

export const useAddAgreementMemo = (
  agreementId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand<AddMemoModel, void, ResponseError>(
    (addMemoModel) =>
      api.addAgreementMemo({
        agreementId,
        addMemoModel,
      }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        partialMutate(agreementMemoKey.list(agreementId));
      },
    },
  );
};
