import {
  LinearProgress,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { ReactElement } from "react";

type Heading<T> = {
  label: string;
  key: T;
  sortable?: boolean;
};

interface SortableTableHeadProps<T> {
  headings: Heading<T>[];
  sortBy?: T;
  direction: "asc" | "desc";
  onSort(key: T): void;
  loading: boolean;
}

export default function SortableTableHead<T extends string | number>({
  headings,
  sortBy,
  direction,
  onSort,
  loading,
}: SortableTableHeadProps<T>): ReactElement {
  const createSortHandler = (key: T) => (): void => {
    onSort(key);
  };

  const tableHeadings = headings.map((heading) => ({
    ...heading,
    sortable: typeof heading.sortable === "undefined" ? true : heading.sortable,
  }));

  return (
    <TableHead>
      <TableRow>
        {tableHeadings.map((heading) => (
          <TableCell
            key={heading.key}
            sx={heading.sortable ? { paddingRight: "8px" } : undefined}
          >
            {heading.sortable ? (
              <TableSortLabel
                active={sortBy === heading.key}
                direction={direction}
                onClick={createSortHandler(heading.key)}
                data-testid={`sort.${heading.key}`}
              >
                {heading.label}
              </TableSortLabel>
            ) : (
              heading.label
            )}
          </TableCell>
        ))}
      </TableRow>
      {loading && (
        <TableRow>
          <TableCell colSpan={headings.length} padding="none">
            <LinearProgress />
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
}
