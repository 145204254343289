import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export interface Customer {
  id: number;
  address?: string;
  email?: string;
  href: string;
  name?: string;
  companyName?: string;
  phone?: string;
}

interface CustomerListProps {
  data: Customer[];
  loading: boolean;
}

const name = (customer: Customer): string | undefined =>
  customer.name ? customer.name : customer.companyName;

const displayName = (customer: Customer): string | undefined =>
  customer.companyName && customer.name
    ? `${customer.name} (${customer.companyName})`
    : name(customer);

export default function CustomerList({
  data,
  loading,
}: CustomerListProps): ReactElement {
  const { t } = useTranslation("customers");

  const sxColSmall = { minWidth: 240 };
  const sxColLarge = { minWidth: 452 };

  return (
    <TableContainer>
      <Table aria-label={t("customers.list_label")}>
        <LoadableTableHead
          loading={loading}
          headings={[
            t("customers.column_headings.name"),
            t("customers.column_headings.address"),
            t("customers.column_headings.email"),
            t("customers.column_headings.telephone"),
          ]}
        />
        <TableBody>
          {data?.map((customer) => (
            <TableRow key={customer.id} hover>
              <TableCell sx={sxColSmall}>
                <Link component={RouterLink} to={customer.href}>
                  {displayName(customer)}
                </Link>
              </TableCell>
              <TableCell sx={sxColLarge}>{customer.address}</TableCell>
              <TableCell sx={sxColSmall}>{customer.email}</TableCell>
              <TableCell sx={sxColSmall}>{customer.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data.length === 0 && <NoResults />}
    </TableContainer>
  );
}
