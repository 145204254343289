import { QueryKey } from "api";
import { keys } from "api/companies";

export const all = (companyId: string): QueryKey => [
  ...keys.detail(companyId),
  "portfolios",
];

export const list = (companyId: string): QueryKey => [
  ...all(companyId),
  "list",
];
