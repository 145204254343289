import { TableCellButton } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface CustomerAgreement {
  id: number;
  number: string;
  product?: string;
  balance: string;
  arrears: string;
  status: string;
  href: string;
}

interface CustomerAgreementListProps {
  data: CustomerAgreement[];
  loading: boolean;
}

export default function CustomerAgreementList({
  data,
  loading,
}: CustomerAgreementListProps): ReactElement {
  const { t } = useTranslation("customers");
  const { formatAmount } = useFormat();

  const sxCol = { minWidth: 80 };

  return (
    <TableContainer>
      <Table size="small" aria-label={t("overview.agreements.heading")}>
        <LoadableTableHead
          loading={loading}
          headings={[
            t("overview.agreements.column_headings.number"),
            t("overview.agreements.column_headings.current_balance"),
            t("overview.agreements.column_headings.arrears"),
            t("overview.agreements.column_headings.status"),
          ]}
        />
        <TableBody>
          {data?.map((customerAgreement) => (
            <TableRow key={customerAgreement.id} hover>
              <TableCellButton
                sx={{ height: "100%" }}
                buttonProps={{
                  component: Link,
                  to: customerAgreement.href,
                }}
              >
                <ListItemText
                  primary={customerAgreement.number}
                  primaryTypographyProps={{ color: "primary" }}
                />
              </TableCellButton>
              <TableCell sx={sxCol}>
                {formatAmount(customerAgreement.balance)}
              </TableCell>
              <TableCell sx={sxCol}>
                {formatAmount(customerAgreement.arrears)}
              </TableCell>
              <TableCell sx={sxCol}>{customerAgreement.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data.length === 0 && <NoResults />}
    </TableContainer>
  );
}
