import { useQuery, UseQueryReturn } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers/ApiProvider/ApiProvider";
import { TasksApi } from "generated/tasks/apis";
import { CustomersAndAgreementsModel } from "generated/tasks/models";
import * as keys from "./keys";

type UseFindCustomerAndAgreements = (params?: {
  query?: string;
  enabled?: boolean;
  customerId?: number;
}) => UseQueryReturn<CustomersAndAgreementsModel>;

const useFindCustomerAndAgreements: UseFindCustomerAndAgreements = (params?: {
  query?: string;
  customerId?: number;
  enabled?: boolean;
}) => {
  const api = useApi(TasksApi, "task-management");

  return useQuery(
    keys.list(params),
    () =>
      api.findCustomersAndAgreements({
        limit: 5,
        ...params,
      }),
    {
      enabled: params?.enabled,
    },
  );
};

export { useFindCustomerAndAgreements };
