/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (report)
 * API for reporting within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CentralCreditRegisterReportListItemModel
 */
export interface CentralCreditRegisterReportListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof CentralCreditRegisterReportListItemModel
     */
    readonly snapshotId: number;
    /**
     * The ccr code
     * @type {string}
     * @memberof CentralCreditRegisterReportListItemModel
     */
    ccrCode: string;
    /**
     * The report period formatted as MM-YYYY
     * @type {string}
     * @memberof CentralCreditRegisterReportListItemModel
     */
    reportPeriod: string;
}

/**
 * Check if a given object implements the CentralCreditRegisterReportListItemModel interface.
 */
export function instanceOfCentralCreditRegisterReportListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "snapshotId" in value;
    isInstance = isInstance && "ccrCode" in value;
    isInstance = isInstance && "reportPeriod" in value;

    return isInstance;
}

export function CentralCreditRegisterReportListItemModelFromJSON(json: any): CentralCreditRegisterReportListItemModel {
    return CentralCreditRegisterReportListItemModelFromJSONTyped(json, false);
}

export function CentralCreditRegisterReportListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CentralCreditRegisterReportListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'snapshotId': json['snapshotId'],
        'ccrCode': json['ccrCode'],
        'reportPeriod': json['reportPeriod'],
    };
}

export function CentralCreditRegisterReportListItemModelToJSON(value?: CentralCreditRegisterReportListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ccrCode': value.ccrCode,
        'reportPeriod': value.reportPeriod,
    };
}

