/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bInstalmentListItemModel } from './B2bInstalmentListItemModel';
import {
    B2bInstalmentListItemModelFromJSON,
    B2bInstalmentListItemModelFromJSONTyped,
    B2bInstalmentListItemModelToJSON,
} from './B2bInstalmentListItemModel';
import type { InstalmentListItemModel } from './InstalmentListItemModel';
import {
    InstalmentListItemModelFromJSON,
    InstalmentListItemModelFromJSONTyped,
    InstalmentListItemModelToJSON,
} from './InstalmentListItemModel';

/**
 * 
 * @export
 * @interface ScheduleModel
 */
export interface ScheduleModel {
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    totalCapital: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    totalInterest: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    totalVat: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    totalInstalmentAmount: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    b2bTotalInstalmentAmount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    b2bTotalInterestPaid?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    b2bTotalCapitalPaid?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    b2bTotalLenderFeesPaid?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    b2bTotalExpensesOther?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    b2bTotalExpensesLossPool?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ScheduleModel
     */
    b2bTotalCommissionCharge?: string;
    /**
     * 
     * @type {Array<InstalmentListItemModel>}
     * @memberof ScheduleModel
     */
    instalments: Array<InstalmentListItemModel>;
    /**
     * 
     * @type {Array<B2bInstalmentListItemModel>}
     * @memberof ScheduleModel
     */
    b2bInstalments: Array<B2bInstalmentListItemModel>;
}

/**
 * Check if a given object implements the ScheduleModel interface.
 */
export function instanceOfScheduleModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalCapital" in value;
    isInstance = isInstance && "totalInterest" in value;
    isInstance = isInstance && "totalVat" in value;
    isInstance = isInstance && "totalInstalmentAmount" in value;
    isInstance = isInstance && "instalments" in value;
    isInstance = isInstance && "b2bInstalments" in value;

    return isInstance;
}

export function ScheduleModelFromJSON(json: any): ScheduleModel {
    return ScheduleModelFromJSONTyped(json, false);
}

export function ScheduleModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCapital': json['totalCapital'],
        'totalInterest': json['totalInterest'],
        'totalVat': json['totalVat'],
        'totalInstalmentAmount': json['totalInstalmentAmount'],
        'b2bTotalInstalmentAmount': !exists(json, 'b2bTotalInstalmentAmount') ? undefined : json['b2bTotalInstalmentAmount'],
        'b2bTotalInterestPaid': !exists(json, 'b2bTotalInterestPaid') ? undefined : json['b2bTotalInterestPaid'],
        'b2bTotalCapitalPaid': !exists(json, 'b2bTotalCapitalPaid') ? undefined : json['b2bTotalCapitalPaid'],
        'b2bTotalLenderFeesPaid': !exists(json, 'b2bTotalLenderFeesPaid') ? undefined : json['b2bTotalLenderFeesPaid'],
        'b2bTotalExpensesOther': !exists(json, 'b2bTotalExpensesOther') ? undefined : json['b2bTotalExpensesOther'],
        'b2bTotalExpensesLossPool': !exists(json, 'b2bTotalExpensesLossPool') ? undefined : json['b2bTotalExpensesLossPool'],
        'b2bTotalCommissionCharge': !exists(json, 'b2bTotalCommissionCharge') ? undefined : json['b2bTotalCommissionCharge'],
        'instalments': ((json['instalments'] as Array<any>).map(InstalmentListItemModelFromJSON)),
        'b2bInstalments': ((json['b2bInstalments'] as Array<any>).map(B2bInstalmentListItemModelFromJSON)),
    };
}

export function ScheduleModelToJSON(value?: ScheduleModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCapital': value.totalCapital,
        'totalInterest': value.totalInterest,
        'totalVat': value.totalVat,
        'totalInstalmentAmount': value.totalInstalmentAmount,
        'b2bTotalInstalmentAmount': value.b2bTotalInstalmentAmount,
        'b2bTotalInterestPaid': value.b2bTotalInterestPaid,
        'b2bTotalCapitalPaid': value.b2bTotalCapitalPaid,
        'b2bTotalLenderFeesPaid': value.b2bTotalLenderFeesPaid,
        'b2bTotalExpensesOther': value.b2bTotalExpensesOther,
        'b2bTotalExpensesLossPool': value.b2bTotalExpensesLossPool,
        'b2bTotalCommissionCharge': value.b2bTotalCommissionCharge,
        'instalments': ((value.instalments as Array<any>).map(InstalmentListItemModelToJSON)),
        'b2bInstalments': ((value.b2bInstalments as Array<any>).map(B2bInstalmentListItemModelToJSON)),
    };
}

