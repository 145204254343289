import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { PortfoliosApi } from "generated/servicing-v2/apis";
import { PortfolioModel } from "generated/servicing-v2/models";
import * as keys from "./keys";

export const useAddPortfolio = (
  companyId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(PortfoliosApi, "v2");
  const partialMutate = usePartialMutate();

  return useCommand<PortfolioModel, void, ResponseError>(
    (model) =>
      api.addPortfolio({
        portfolioModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.list(companyId));
        options.onSuccess?.();
      },
    },
  );
};
