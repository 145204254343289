/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Attachments within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentTypeModel } from './AttachmentTypeModel';
import {
    AttachmentTypeModelFromJSON,
    AttachmentTypeModelFromJSONTyped,
    AttachmentTypeModelToJSON,
} from './AttachmentTypeModel';
import type { DeliveryMethodModel } from './DeliveryMethodModel';
import {
    DeliveryMethodModelFromJSON,
    DeliveryMethodModelFromJSONTyped,
    DeliveryMethodModelToJSON,
} from './DeliveryMethodModel';
import type { DeliveryStatusModel } from './DeliveryStatusModel';
import {
    DeliveryStatusModelFromJSON,
    DeliveryStatusModelFromJSONTyped,
    DeliveryStatusModelToJSON,
} from './DeliveryStatusModel';

/**
 * The information of an attachment
 * @export
 * @interface AgreementAttachmentListItemModel
 */
export interface AgreementAttachmentListItemModel {
    /**
     * A read only Id
     * @type {string}
     * @memberof AgreementAttachmentListItemModel
     */
    readonly id: string;
    /**
     * 
     * @type {AttachmentTypeModel}
     * @memberof AgreementAttachmentListItemModel
     */
    type: AttachmentTypeModel;
    /**
     * 
     * @type {string}
     * @memberof AgreementAttachmentListItemModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAttachmentListItemModel
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof AgreementAttachmentListItemModel
     */
    createdDate: Date;
    /**
     * 
     * @type {DeliveryMethodModel}
     * @memberof AgreementAttachmentListItemModel
     */
    deliveryMethod?: DeliveryMethodModel;
    /**
     * 
     * @type {DeliveryStatusModel}
     * @memberof AgreementAttachmentListItemModel
     */
    deliveryStatus?: DeliveryStatusModel;
}

/**
 * Check if a given object implements the AgreementAttachmentListItemModel interface.
 */
export function instanceOfAgreementAttachmentListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "createdDate" in value;

    return isInstance;
}

export function AgreementAttachmentListItemModelFromJSON(json: any): AgreementAttachmentListItemModel {
    return AgreementAttachmentListItemModelFromJSONTyped(json, false);
}

export function AgreementAttachmentListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementAttachmentListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': AttachmentTypeModelFromJSON(json['type']),
        'filename': json['filename'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdDate': (new Date(json['createdDate'])),
        'deliveryMethod': !exists(json, 'deliveryMethod') ? undefined : DeliveryMethodModelFromJSON(json['deliveryMethod']),
        'deliveryStatus': !exists(json, 'deliveryStatus') ? undefined : DeliveryStatusModelFromJSON(json['deliveryStatus']),
    };
}

export function AgreementAttachmentListItemModelToJSON(value?: AgreementAttachmentListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': AttachmentTypeModelToJSON(value.type),
        'filename': value.filename,
        'description': value.description,
        'createdDate': (value.createdDate.toISOString().substring(0,10)),
        'deliveryMethod': DeliveryMethodModelToJSON(value.deliveryMethod),
        'deliveryStatus': DeliveryStatusModelToJSON(value.deliveryStatus),
    };
}

