import { Delete } from "@mui/icons-material";
import { Alert, IconButton } from "@mui/material";
import { ReactElement } from "react";

interface UploadedFileBannerProps {
  onFileDelete(): void;
  fileName: string;
  buttonText: string;
  disabled?: boolean;
}

export default function UploadedFileBanner({
  onFileDelete,
  fileName,
  buttonText,
  disabled,
}: UploadedFileBannerProps): ReactElement {
  return (
    <Alert
      severity="success"
      action={
        !disabled && (
          <IconButton
            aria-label={buttonText}
            color="inherit"
            onClick={onFileDelete}
            size="small"
          >
            <Delete fontSize="inherit" />
          </IconButton>
        )
      }
    >
      {fileName}
    </Alert>
  );
}
