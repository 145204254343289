import {
  CommercialLoanFinancialDetailsFormModel,
  FinanceLeaseFinancialDetailsFormModel,
  HirePurchaseFinancialDetailsFormModel,
  OperatingLeaseFinancialDetailsFormModel,
} from "apps/onboarding/components";
import {
  AgreementDetailsFormModel,
  CompanyDetailsFormModel,
  CustomerDetailsFormModel,
  FeesAndCommissionsFormModel,
  OnboardingAssetFormModel,
  RepaymentTermsFormModel,
  RepaymentTermsStateModel,
  isCustomer,
} from "apps/onboarding/containers";
import { FinancialDetailsFormModel } from "apps/onboarding/containers/FinancialDetailsStep";
import { TypeOfLeaseEnum } from "apps/onboarding/types";
import { formatISO } from "date-fns";
import {
  AssetCategoryModel,
  AssetModel,
  CompanySizeModel,
  CompanyTypeModel,
  CustomerDetailsModel,
  FeesAndCommissionsModel,
  FinancialDetailsModel,
  FrequencyOfInstalmentsModel,
  MaintenanceDetailsModel,
  MonthlyInstalmentModel,
  NewOrUsedModel,
  OnboardingModel,
  PaymentStructureModel,
  RepaymentTermsModel,
  TermModel,
} from "generated/onboarding/models";
import { CommissionCalculationTypeModel } from "generated/onboarding/models/CommissionCalculationTypeModel";
import { CustomInstalmentModel } from "generated/onboarding/models/CustomInstalmentModel";
import { OnboardingState } from ".";

interface ExistingCustomer {
  id?: number;
  name?: string;
}

const leaseTypeMap = new Map([
  [String(TypeOfLeaseEnum.FixedTerm), TermModel.FixedTerm],
  [String(TypeOfLeaseEnum.MinimumTerm), TermModel.MinimumTerm],
]);

function mapTypeOfLease(term: string): TermModel | undefined {
  return leaseTypeMap.get(term);
}

export function mapFinancialDetails(
  financialDetails: FinancialDetailsFormModel,
): FinancialDetailsModel {
  const hirePurchaseFinancialDetails =
    financialDetails as HirePurchaseFinancialDetailsFormModel;
  const financeLeaseFinancialDetails =
    financialDetails as FinanceLeaseFinancialDetailsFormModel;
  const operatingLeaseFinancialDetails =
    financialDetails as OperatingLeaseFinancialDetailsFormModel;
  const commercialLoanFinancialDetails =
    financialDetails as CommercialLoanFinancialDetailsFormModel;
  const balloonPayment =
    hirePurchaseFinancialDetails.balloonPayment ||
    financeLeaseFinancialDetails.balloonPayment ||
    commercialLoanFinancialDetails.balloonPayment;
  const cashDeposit =
    financeLeaseFinancialDetails.cashDeposit ||
    hirePurchaseFinancialDetails.cashDeposit ||
    commercialLoanFinancialDetails.cashDeposit ||
    operatingLeaseFinancialDetails.cashDeposit;
  const blindDiscount =
    financeLeaseFinancialDetails.blindDiscount ||
    operatingLeaseFinancialDetails.blindDiscount;
  const vatReclaimMonth =
    financeLeaseFinancialDetails.vatReclaimMonth ||
    operatingLeaseFinancialDetails.vatReclaimMonth;
  const subsidyPayment =
    financeLeaseFinancialDetails.subsidyPayment ||
    operatingLeaseFinancialDetails.subsidyPayment ||
    hirePurchaseFinancialDetails.subsidyPayment;
  const partExchange =
    hirePurchaseFinancialDetails.partExchange ||
    financeLeaseFinancialDetails.partExchange ||
    operatingLeaseFinancialDetails.partExchange;

  return {
    balloonPayment: balloonPayment ? balloonPayment.toFixed(2) : "0.00",
    cashDeposit: cashDeposit ? cashDeposit.toFixed(2) : "0.00",
    partExchange: partExchange ? partExchange.toFixed(2) : "0.00",
    residualValue: operatingLeaseFinancialDetails.residualValue
      ? operatingLeaseFinancialDetails.residualValue.toFixed(2)
      : "0.00",
    leaseType: mapTypeOfLease(financeLeaseFinancialDetails.typeOfLease),
    blindDiscount: blindDiscount ? blindDiscount.toFixed(2) : "0.00",
    vatReclaimMonth: vatReclaimMonth ? vatReclaimMonth : undefined,
    fleetDiscount: hirePurchaseFinancialDetails.fleetDiscount
      ? hirePurchaseFinancialDetails.fleetDiscount.toFixed(2)
      : "0.00",
    subsidyPayment: subsidyPayment ? subsidyPayment.toFixed(2) : "0.00",
  };
}

export function mapCompanyCustomer(
  customerDetails: CustomerDetailsFormModel,
  companyDetails: CompanyDetailsFormModel,
): CustomerDetailsModel {
  return {
    customer: isCustomer(companyDetails.companyType as CompanyTypeModel)
      ? {
          title: companyDetails.title,
          firstName: companyDetails.firstName,
          middleName: companyDetails.middleName,
          lastName: companyDetails.lastName,
          dateOfBirth: companyDetails.dateOfBirth,
          nationalIdentificationNumber:
            companyDetails.nationalIdentificationNumber,
        }
      : undefined,
    companyType: companyDetails.companyType as CompanyTypeModel,
    company: !isCustomer(companyDetails.companyType)
      ? {
          companyName: companyDetails.companyName,
          vatRegistrationNumber: companyDetails.vatRegistrationNumber,
          companyRegistrationNumber: companyDetails.companyRegistrationNumber,
          companySize: companyDetails.companySize as CompanySizeModel,
          sectorOfEconomicActivity: companyDetails.sectorOfEconomicActivity,
          companySector: companyDetails.companySector,
        }
      : {
          companyName: companyDetails.tradingName,
        },
    address: {
      addressLine1: customerDetails.addressLine1,
      addressLine2: customerDetails.addressLine2,
      addressLine3: customerDetails.addressLine3,
      addressLine4: customerDetails.addressLine4,
      postcode: customerDetails.postcode,
    },
    bankDetails: {
      bankName: customerDetails.bankName,
      accountName: customerDetails.bankAccountName,
      accountNumber: customerDetails.accountNumber,
      sortCode: customerDetails.sortCode,
      iban: customerDetails.iban === "" ? undefined : customerDetails.iban,
      bic: customerDetails.bic === "" ? undefined : customerDetails.bic,
    },
    email: customerDetails.email,
    telephoneNumber: customerDetails.telephone,
    obligorRiskRating: customerDetails.obligorRiskRating,
  };
}
export function mapFeesAndCommissions(
  feesAndCommissions?: FeesAndCommissionsFormModel,
): FeesAndCommissionsModel {
  return {
    fees: {
      annualAdministrationFee:
        feesAndCommissions?.annualAdministrationFee?.toFixed(2),
      arrangementFee: feesAndCommissions?.arrangementFee?.toFixed(2),
      documentationFee: feesAndCommissions?.documentationFee?.toFixed(2),
      legalFee: feesAndCommissions?.legalFee?.toFixed(2),
      valuationFee: feesAndCommissions?.valuationFee?.toFixed(2),
      facilityFee: feesAndCommissions?.facilityFee?.toFixed(2),
      optionToPurchaseFee: feesAndCommissions?.optionToPurchaseFee?.toFixed(2),
      landRegistryFee: feesAndCommissions?.landRegistryFee?.toFixed(2),
      exitFee: feesAndCommissions?.exitFee?.toFixed(2),
      administrationFee: feesAndCommissions?.administrationFee?.toFixed(2),
      arrangementFeeDeductedFromAdvance:
        feesAndCommissions?.arrangementFeeDeductedFromAdvance,
    },
    commissions: {
      brokerCommission: feesAndCommissions?.brokerCommission,
      supplierCommission: feesAndCommissions?.supplierCommission,
      brokerCommissionAmount:
        feesAndCommissions?.brokerCommissionAmount?.toFixed(2),
      supplierCommissionAmount:
        feesAndCommissions?.supplierCommissionAmount?.toFixed(2),
      applyBrokerAmountToCapital:
        feesAndCommissions?.applyBrokerAmountToCapital,
      applySupplierAmountToCapital:
        feesAndCommissions?.applySupplierAmountToCapital,
      isBrokerCommissionFundedVat: feesAndCommissions?.isBrokerCommissionFunded,
      commissionCalculationType:
        feesAndCommissions?.amountOrPercent as CommissionCalculationTypeModel,
    },
    feeList: feesAndCommissions?.feesList?.map((fee) => ({
      id: Number(fee.id),
      amount: fee.amount.toFixed(2),
    })),
  };
}

export function mapMaintenance(
  agreementDetails: AgreementDetailsFormModel,
): MaintenanceDetailsModel {
  return {
    maintainerName: agreementDetails.maintainerName || "",
    amount: agreementDetails.maintenanceAmount?.toFixed(2) || "0.00",
    cost: agreementDetails?.maintenanceCost?.toFixed(2) || "0.00",
  };
}

export function mapAssets(assets: OnboardingAssetFormModel[]): AssetModel[] {
  return assets.map((asset) => ({
    newOrUsed: asset.newOrUsed as NewOrUsedModel,
    assetType: asset.typeCode,
    assetDescription: asset.description,
    costPriceNetVat: asset.costPriceExVat,
    vat: asset.vat,
    model: asset.model,
    manufacturerDescription: asset.manufacturerDescription,
    registrationNumber: asset.registrationNumber,
    registrationDate: asset.registrationDate,
    serialNumber: asset.serialNumber,
    vehicleIdentificationNumber: asset.vehicleIdentificationNumber,
    yearManufactured: asset.yearOfManufacture
      ? String(asset.yearOfManufacture)
      : undefined,
    hpiRegisteredDate: asset.hpiRegisteredDate,
    includeInsurance: asset.includeInsurance,
    assetCategory: asset.assetCategory as AssetCategoryModel,
    assetBanding: asset.assetBanding,
    fleetDiscount: asset.fleetDiscount
      ? asset.fleetDiscount.toFixed(2)
      : "0.00",
    serviceProviderId: asset.serviceProviderId?.value,
    manufacturerSubsidy: asset.manufacturerSubsidy
      ? asset.manufacturerSubsidy.toFixed(2)
      : "0.00",
    vatCode: asset.vatCode,
    schemeCode: asset.schemeCode,
  }));
}

function mapSeasonalPaymentStructure(
  repaymentTerms: RepaymentTermsFormModel,
): MonthlyInstalmentModel[] | undefined {
  const months = [
    [1, repaymentTerms.january],
    [2, repaymentTerms.february],
    [3, repaymentTerms.march],
    [4, repaymentTerms.april],
    [5, repaymentTerms.may],
    [6, repaymentTerms.june],
    [7, repaymentTerms.july],
    [8, repaymentTerms.august],
    [9, repaymentTerms.september],
    [10, repaymentTerms.october],
    [11, repaymentTerms.november],
    [12, repaymentTerms.december],
  ];
  const monthlyInstalments = months.map((month) => {
    const amount = month[1];
    return {
      month: month[0],
      amount: amount ? amount.toFixed(2) : "0.00",
    } as MonthlyInstalmentModel;
  });
  return repaymentTerms.paymentStructure === PaymentStructureModel.Seasonal
    ? monthlyInstalments
    : undefined;
}

function mapCustomPaymentStructure(
  repaymentTerms: RepaymentTermsStateModel,
): CustomInstalmentModel[] | undefined {
  const customInstalments = repaymentTerms.customInstalments?.map(
    (customInstalments) => {
      return {
        startingOn: new Date(
          formatISO(new Date(customInstalments.startingOn), {
            representation: "date",
          }),
        ),
        instalmentAmount: customInstalments.instalmentAmount
          ? customInstalments.instalmentAmount.toFixed(2)
          : "0.00",
        forNumberOfInstalments: customInstalments.forNumberOfInstalments,
      } as CustomInstalmentModel;
    },
  );

  return repaymentTerms.paymentStructure === PaymentStructureModel.Custom
    ? customInstalments
    : undefined;
}

function getFirstPaymentDate(repaymentTerms: RepaymentTermsStateModel): Date {
  return repaymentTerms.paymentStructure === PaymentStructureModel.Custom &&
    repaymentTerms.customInstalments
    ? repaymentTerms.customInstalments?.[0].startingOn
    : repaymentTerms.firstPaymentDate;
}

const mapSecondaryRentalDetails = (
  financialDetails: FinancialDetailsFormModel,
) => {
  const financeLeaseFinancialDetails =
    financialDetails as FinanceLeaseFinancialDetailsFormModel;

  if (!financeLeaseFinancialDetails.secondaryRentalAmount) {
    return undefined;
  }

  return {
    amount: financeLeaseFinancialDetails.secondaryRentalAmount?.toFixed(2),
    frequency:
      financeLeaseFinancialDetails.secondaryRentalFrequency as FrequencyOfInstalmentsModel,
    introducerShare:
      financeLeaseFinancialDetails.secondaryRentalIntroducerShare,
    maintenanceCharge:
      financeLeaseFinancialDetails.secondaryRentalMaintenenceCharge?.toFixed(2),
    insurancePremium:
      financeLeaseFinancialDetails.secondaryRentalInsurancePremium?.toFixed(2),
  };
};

export function mapRepaymentTerms(
  repaymentTerms: RepaymentTermsStateModel,
): RepaymentTermsModel {
  return {
    paymentStructure: repaymentTerms.paymentStructure,
    totalNumberOfInstalments: repaymentTerms.loanTerm,
    term: repaymentTerms.loanTerm,
    inceptionDate: repaymentTerms.inceptionDate,
    firstInstalmentDueDate: getFirstPaymentDate(repaymentTerms),
    frequencyOfInstalments:
      repaymentTerms.repaymentFrequency as FrequencyOfInstalmentsModel,
    instalmentAmount: repaymentTerms.frequencyAmount?.toFixed(2),
    interestCalculationRates: {
      customerInterestRate: repaymentTerms.customerInterestRate,
      b2bFundingRate: repaymentTerms.b2bFundingRate,
      rateToBroker: repaymentTerms.rateToBroker,
    },
    advancedPayment: repaymentTerms.initialRentalPaymentAmount
      ? repaymentTerms.initialRentalPaymentAmount?.toFixed(2)
      : undefined,
    secondaryRentalDueDate: repaymentTerms.firstSecondaryPeriodRentalDate,
    secondaryRentalPeriodAmount: repaymentTerms.secondaryPeriodRentalAmount
      ? repaymentTerms.secondaryPeriodRentalAmount.toFixed(2)
      : "0.00",
    instalmentMonths: mapSeasonalPaymentStructure(repaymentTerms),
    customInstalments: mapCustomPaymentStructure(repaymentTerms),
  };
}

export function mapOnboardingStateToModel(
  onboarding: OnboardingState,
  existingCustomer: ExistingCustomer,
): OnboardingModel {
  return {
    existingCustomerId: existingCustomer.id,
    existingCustomerName: existingCustomer.name,
    isFundedVat: onboarding.agreementDetails?.isFundedVat,
    vatRate: onboarding.agreementDetails?.vatRate,
    agreementNumber: onboarding.agreementDetails?.agreementNumber || undefined,
    customer: !existingCustomer.id
      ? mapCompanyCustomer(
          onboarding.customerDetails as CustomerDetailsFormModel,
          onboarding.companyDetails as CompanyDetailsFormModel,
        )
      : undefined,
    assets: mapAssets(
      onboarding.assetDetails?.assets as OnboardingAssetFormModel[],
    ),
    portfolio: String(onboarding.agreementDetails?.portfolio),
    productId: Number(onboarding.agreementDetails?.productId),
    facilityRiskRating: onboarding.agreementDetails?.facilityRiskRating,
    fundingLine: onboarding.agreementDetails?.fundingLine,
    setupCosts: onboarding.agreementDetails?.setupCosts
      ? onboarding.agreementDetails?.setupCosts.toFixed(2)
      : "0.00",
    lossPoolPercentage: onboarding.agreementDetails?.lossPool,
    maintenance:
      onboarding.agreementDetails?.maintainerName &&
      onboarding.agreementDetails.maintenanceAmount
        ? mapMaintenance(onboarding.agreementDetails)
        : undefined,
    financialDetails: mapFinancialDetails(
      onboarding.financialDetails as FinancialDetailsFormModel,
    ),
    feesAndCommissions: mapFeesAndCommissions(onboarding.feesAndCommissions),
    repaymentTerms: mapRepaymentTerms(
      onboarding.repaymentTerms as RepaymentTermsStateModel,
    ),
    secondaryRental: mapSecondaryRentalDetails(
      onboarding.financialDetails as FinancialDetailsFormModel,
    ),
  };
}
