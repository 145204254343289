/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (automations)
 * API for managing Automations within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AutomationEventModel } from './AutomationEventModel';
import {
    AutomationEventModelFromJSON,
    AutomationEventModelFromJSONTyped,
    AutomationEventModelToJSON,
} from './AutomationEventModel';
import type { AutomationParameterModel } from './AutomationParameterModel';
import {
    AutomationParameterModelFromJSON,
    AutomationParameterModelFromJSONTyped,
    AutomationParameterModelToJSON,
} from './AutomationParameterModel';
import type { AutomationStatusTypeModel } from './AutomationStatusTypeModel';
import {
    AutomationStatusTypeModelFromJSON,
    AutomationStatusTypeModelFromJSONTyped,
    AutomationStatusTypeModelToJSON,
} from './AutomationStatusTypeModel';

/**
 * The details for the automation
 * @export
 * @interface AutomationDetailsModel
 */
export interface AutomationDetailsModel {
    /**
     * The Id of the portfolio
     * @type {number}
     * @memberof AutomationDetailsModel
     */
    portfolioId: number;
    /**
     * The user-defined name of the automation
     * @type {string}
     * @memberof AutomationDetailsModel
     */
    name: string;
    /**
     * The user-defined description of the automation
     * @type {string}
     * @memberof AutomationDetailsModel
     */
    description?: string;
    /**
     * 
     * @type {AutomationEventModel}
     * @memberof AutomationDetailsModel
     */
    event: AutomationEventModel;
    /**
     * 
     * @type {Array<AutomationParameterModel>}
     * @memberof AutomationDetailsModel
     */
    parameters: Array<AutomationParameterModel>;
    /**
     * A read only Id
     * @type {number}
     * @memberof AutomationDetailsModel
     */
    readonly id: number;
    /**
     * The portfolio name
     * @type {string}
     * @memberof AutomationDetailsModel
     */
    portfolioName: string;
    /**
     * 
     * @type {AutomationStatusTypeModel}
     * @memberof AutomationDetailsModel
     */
    status?: AutomationStatusTypeModel;
}

/**
 * Check if a given object implements the AutomationDetailsModel interface.
 */
export function instanceOfAutomationDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "portfolioId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "parameters" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "portfolioName" in value;

    return isInstance;
}

export function AutomationDetailsModelFromJSON(json: any): AutomationDetailsModel {
    return AutomationDetailsModelFromJSONTyped(json, false);
}

export function AutomationDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomationDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'portfolioId': json['portfolioId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'event': AutomationEventModelFromJSON(json['event']),
        'parameters': ((json['parameters'] as Array<any>).map(AutomationParameterModelFromJSON)),
        'id': json['id'],
        'portfolioName': json['portfolioName'],
        'status': !exists(json, 'status') ? undefined : AutomationStatusTypeModelFromJSON(json['status']),
    };
}

export function AutomationDetailsModelToJSON(value?: AutomationDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portfolioId': value.portfolioId,
        'name': value.name,
        'description': value.description,
        'event': AutomationEventModelToJSON(value.event),
        'parameters': ((value.parameters as Array<any>).map(AutomationParameterModelToJSON)),
        'portfolioName': value.portfolioName,
        'status': AutomationStatusTypeModelToJSON(value.status),
    };
}

