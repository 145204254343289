/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocumentTemplateListItemModel,
} from '../models';
import {
    DocumentTemplateListItemModelFromJSON,
    DocumentTemplateListItemModelToJSON,
} from '../models';

export interface ListDocumentTemplatesRequest {
    portfolioId: number;
}

/**
 * 
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     * List available templates available to generate documents
     */
    async listDocumentTemplatesRaw(requestParameters: ListDocumentTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentTemplateListItemModel>>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling listDocumentTemplates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/documents/portfolios/{portfolioId}/templates`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentTemplateListItemModelFromJSON));
    }

    /**
     * List available templates available to generate documents
     */
    async listDocumentTemplates(requestParameters: ListDocumentTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentTemplateListItemModel>> {
        const response = await this.listDocumentTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
