import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import LettersToEmailContainer from "apps/documents/containers/LettersToEmailContainer";
import ContainedLayout from "common/layouts/ContainedLayout";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

function LettersToEmailView(): ReactElement {
  const { t } = useTranslation("documents");

  return (
    <ViewLayout title={t("letters_to_email.heading")}>
      <FixedLayout>
        <ContainedLayout>
          <LettersToEmailContainer />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}

export default withProtectedView({
  allowedPermissions: ["documents:letters-to-email:view"],
})(LettersToEmailView);
