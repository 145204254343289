/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Tax Codes within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaxRateListItemModel } from './TaxRateListItemModel';
import {
    TaxRateListItemModelFromJSON,
    TaxRateListItemModelFromJSONTyped,
    TaxRateListItemModelToJSON,
} from './TaxRateListItemModel';

/**
 * 
 * @export
 * @interface TaxCodeDetailsModel
 */
export interface TaxCodeDetailsModel {
    /**
     * Tax Code unique identifier
     * @type {string}
     * @memberof TaxCodeDetailsModel
     */
    id: string;
    /**
     * Description of the tax code
     * @type {string}
     * @memberof TaxCodeDetailsModel
     */
    name: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof TaxCodeDetailsModel
     */
    effectiveDate: Date;
    /**
     * Actual Tax rate
     * @type {number}
     * @memberof TaxCodeDetailsModel
     */
    currentRate: number;
    /**
     * Username of the user that created the Tax rate
     * @type {string}
     * @memberof TaxCodeDetailsModel
     */
    createdBy: string;
    /**
     * A date time format
     * @type {Date}
     * @memberof TaxCodeDetailsModel
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<TaxRateListItemModel>}
     * @memberof TaxCodeDetailsModel
     */
    rates: Array<TaxRateListItemModel>;
}

/**
 * Check if a given object implements the TaxCodeDetailsModel interface.
 */
export function instanceOfTaxCodeDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "effectiveDate" in value;
    isInstance = isInstance && "currentRate" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "rates" in value;

    return isInstance;
}

export function TaxCodeDetailsModelFromJSON(json: any): TaxCodeDetailsModel {
    return TaxCodeDetailsModelFromJSONTyped(json, false);
}

export function TaxCodeDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxCodeDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'effectiveDate': (new Date(json['effectiveDate'])),
        'currentRate': json['currentRate'],
        'createdBy': json['createdBy'],
        'createdAt': (new Date(json['createdAt'])),
        'rates': ((json['rates'] as Array<any>).map(TaxRateListItemModelFromJSON)),
    };
}

export function TaxCodeDetailsModelToJSON(value?: TaxCodeDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'effectiveDate': (value.effectiveDate.toISOString().substring(0,10)),
        'currentRate': value.currentRate,
        'createdBy': value.createdBy,
        'createdAt': (value.createdAt.toISOString()),
        'rates': ((value.rates as Array<any>).map(TaxRateListItemModelToJSON)),
    };
}

