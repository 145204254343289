import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers/ApiProvider";
import { ResponseError } from "generated";
import { TasksApi } from "generated/tasks/apis";
import { UpdateStatusModel } from "generated/tasks/models";
import * as keys from "./keys";

const useUpdateTaskStatus = (
  taskId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(TasksApi, "task-management");
  const partialMutate = usePartialMutate();

  return useCommand(
    async (updateStatusModel: UpdateStatusModel) =>
      api.updateTaskStatus({
        taskId,
        updateStatusModel,
      }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        partialMutate(keys.all());
      },
    },
  );
};

export { useUpdateTaskStatus };
