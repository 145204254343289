import { FormDialog } from "@ldms/mui-sdk/templates";
import { MenuItem, Typography } from "@mui/material";
import { list as transactionsKey } from "api/agreements/transactions/keys";
import { Transaction } from "apps/servicing/modules/agreements/components";
import { ControlledTextField } from "common/components";
import { useResponseError, useYupResolver } from "common/hooks";
import { useAgreement, useApi } from "common/providers";
import { AgreementCommandApi, AgreementQueryApi } from "generated/core/apis";
import { RemoveChargeModel } from "generated/core/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";
import useSWR, { useSWRConfig } from "swr";

interface RemoveChargeFormValues {
  reasonCode: string;
}

interface RemoveChargeProps {
  transactionId: number;
  transactions?: Transaction[];
  open: boolean;
  onClose(): void;
  onSubmit(): void;
}

export default function RemoveCharge({
  transactionId,
  open,
  onClose,
  onSubmit: onSubmitCallback,
}: RemoveChargeProps): ReactElement {
  const agreement = useAgreement();
  const { mutate } = useSWRConfig();
  const { t } = useTranslation("agreements");
  const agreementQueryApi = useApi(AgreementQueryApi);
  const removalReasons = useSWR(
    ["agreements/transactions/removal-reasons"],
    () => agreementQueryApi.listRemovalReasons(),
  );
  const agreementCommandApi = useApi(AgreementCommandApi);

  const error = useResponseError();

  const resolver = useYupResolver<RemoveChargeFormValues>((yup) => {
    return yup.object().shape({
      reasonCode: yup
        .string()
        .isRequired(t("transactions.remove_charge.reason_label")),
    });
  });

  const onSubmit = async (data: RemoveChargeFormValues): Promise<void> => {
    try {
      const removeChargeModel: RemoveChargeModel = {
        transactionId,
        reasonCode: data.reasonCode,
      };

      await agreementCommandApi.removeCharge({
        agreementId: agreement.id,
        removeChargeModel,
      });

      agreement.mutate();
      mutate(transactionsKey(agreement.id));
      onSubmitCallback();
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    }
  };

  return (
    <FormDialog
      defaultValues={{
        reasonCode: "",
      }}
      dialogProps={{
        maxWidth: "xs",
      }}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      ready={Boolean(removalReasons.data)}
      resolver={resolver}
      title={t("transactions.remove_charge.remove_charge_dialog_title")}
    >
      {(form): ReactElement => (
        <>
          <ControlledTextField
            helperText={form.formState.errors.reasonCode?.message}
            SelectProps={{ displayEmpty: true }}
            error={form.formState.errors.reasonCode?.message}
            control={form.control}
            id="reasonCode"
            name="reasonCode"
            label={t("transactions.remove_charge.reason_label")}
            select
          >
            <MenuItem value="">{t("common:please_select")}</MenuItem>
            {removalReasons.data?.map((reason) => (
              <MenuItem key={reason.type} value={reason.type}>
                {reason.name}
              </MenuItem>
            ))}
          </ControlledTextField>
          {error.message && (
            <Typography color="error">{error.message}</Typography>
          )}
        </>
      )}
    </FormDialog>
  );
}
