import { Close } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useGetAsset } from "api/assets/getAsset";
import { useGetCustomer } from "api/customers/contacts/getCustomer";
import LabelledChip from "common/components/LabelledChip";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface AssetDialogHeaderProps {
  action: ReactNode;
  assetId: number;
  breadcrumbs: ReactNode;
  onClose: () => void;
}

function AssetDialogHeader({
  action,
  assetId,
  breadcrumbs,
  onClose,
}: AssetDialogHeaderProps) {
  const asset = useGetAsset(assetId);
  const { t } = useTranslation();

  return (
    <>
      <Box paddingBottom={2}>
        <Box display="flex" justifyContent="flex-end">
          <Box paddingLeft={3} paddingTop={3} marginRight="auto">
            {breadcrumbs}
          </Box>
          <Stack marginTop={2} marginRight={2} direction="row" columnGap={2}>
            <Box>{action}</Box>
            <IconButton aria-label={t("close")} onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </Box>

        <Box display="flex" paddingX={3}>
          <DialogTitle sx={{ padding: 0 }}>
            {asset.data ? `${asset.data.type} ${asset.data.assetNumber}` : " "}
          </DialogTitle>
          {asset.data && (
            <Stack direction="row" spacing={3} paddingX={3}>
              <LabelledChip label="Status" value={asset.data.status ?? ""} />
            </Stack>
          )}
        </Box>
      </Box>
    </>
  );
}

interface AssetDialogLayoutProps {
  action: ReactNode;
  assetId: number;
  clientId: number;
  children: ReactNode;
  onClose: () => void;
}

export default function AssetDialogLayout({
  action,
  assetId,
  clientId,
  children,
  onClose,
}: Readonly<AssetDialogLayoutProps>) {
  const appConfig = useAppConfiguration();
  const asset = useGetAsset(assetId);
  const customer = useGetCustomer(clientId);

  let displayName = customer.data?.companyName;

  if (customer.data?.isIndividual) {
    displayName = customer.data?.companyName
      ? `${customer.data?.name} (${customer.data?.companyName})`
      : `${customer.data?.name} `;
  }

  return (
    <>
      <AssetDialogHeader
        action={action}
        breadcrumbs={
          <Breadcrumbs>
            {customer.data ? (
              <Link
                component={RouterLink}
                to={`${appConfig.appRoutes.servicing}/customers/${customer.data.id}`}
              >
                {displayName}
              </Link>
            ) : (
              <Typography>...</Typography>
            )}
            {asset.data ? (
              <Link
                component={RouterLink}
                to={`${appConfig.appRoutes.servicing}/agreements/${asset.data?.agreementId}`}
              >
                {asset.data?.agreementNumber}
              </Link>
            ) : (
              <Typography>...</Typography>
            )}
          </Breadcrumbs>
        }
        assetId={assetId}
        onClose={onClose}
      />
      {children}
    </>
  );
}
