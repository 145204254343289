import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import { RefundTransactionModel } from "generated/core/models";
import { useSWRConfig } from "swr";
import * as transactionsKeys from "api/agreements/transactions/keys";
import { ResponseError } from "@ldms/mui-sdk/bootstrap";

export const useRefundTransaction = (
  agreementId: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementCommandApi);
  const { mutate } = useSWRConfig();

  return useCommand<RefundTransactionModel, void, ResponseError>(
    (model) =>
      api.refundTransaction({
        agreementId,
        refundTransactionModel: {
          ...model,
        },
      }),
    {
      ...options,
      onSuccess: () => {
        mutate(transactionsKeys.list(agreementId));
        options.onSuccess?.();
      },
    },
  );
};
