/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeInstalmentPaymentDateModel,
} from '../models/index';
import {
    ChangeInstalmentPaymentDateModelFromJSON,
    ChangeInstalmentPaymentDateModelToJSON,
} from '../models/index';

export interface ChangeInstalmentPaymentDateRequest {
    agreementId: number;
    instalmentNumber: number;
    changeInstalmentPaymentDateModel?: ChangeInstalmentPaymentDateModel;
}

export interface CreateInvoiceRequest {
    agreementId: number;
    instalmentNumber: number;
}

/**
 * 
 */
export class AgreementScheduleApi extends runtime.BaseAPI {

    /**
     * Change instalment payment date
     */
    async changeInstalmentPaymentDateRaw(requestParameters: ChangeInstalmentPaymentDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling changeInstalmentPaymentDate.');
        }

        if (requestParameters.instalmentNumber === null || requestParameters.instalmentNumber === undefined) {
            throw new runtime.RequiredError('instalmentNumber','Required parameter requestParameters.instalmentNumber was null or undefined when calling changeInstalmentPaymentDate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-schedule:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/schedule/{instalmentNumber}/payment-date`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"instalmentNumber"}}`, encodeURIComponent(String(requestParameters.instalmentNumber))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeInstalmentPaymentDateModelToJSON(requestParameters.changeInstalmentPaymentDateModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change instalment payment date
     */
    async changeInstalmentPaymentDate(requestParameters: ChangeInstalmentPaymentDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeInstalmentPaymentDateRaw(requestParameters, initOverrides);
    }

    /**
     * Create invoice
     */
    async createInvoiceRaw(requestParameters: CreateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling createInvoice.');
        }

        if (requestParameters.instalmentNumber === null || requestParameters.instalmentNumber === undefined) {
            throw new runtime.RequiredError('instalmentNumber','Required parameter requestParameters.instalmentNumber was null or undefined when calling createInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-schedule:manage"]);
        }

        const response = await this.request({
            path: `/agreements/{agreementId}/schedule/{instalmentNumber}/invoice`.replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))).replace(`{${"instalmentNumber"}}`, encodeURIComponent(String(requestParameters.instalmentNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create invoice
     */
    async createInvoice(requestParameters: CreateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createInvoiceRaw(requestParameters, initOverrides);
    }

}
