/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';

/**
 * 
 * @export
 * @interface PaymentInstructionPayeeModel
 */
export interface PaymentInstructionPayeeModel {
    /**
     * The name of the payee
     * @type {string}
     * @memberof PaymentInstructionPayeeModel
     */
    name: string;
    /**
     * the type of payee
     * @type {string}
     * @memberof PaymentInstructionPayeeModel
     */
    type: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof PaymentInstructionPayeeModel
     */
    address?: AddressModel;
    /**
     * The payee telephone number
     * @type {string}
     * @memberof PaymentInstructionPayeeModel
     */
    telephoneNumber?: string;
    /**
     * The payee email address
     * @type {string}
     * @memberof PaymentInstructionPayeeModel
     */
    email?: string;
}

/**
 * Check if a given object implements the PaymentInstructionPayeeModel interface.
 */
export function instanceOfPaymentInstructionPayeeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function PaymentInstructionPayeeModelFromJSON(json: any): PaymentInstructionPayeeModel {
    return PaymentInstructionPayeeModelFromJSONTyped(json, false);
}

export function PaymentInstructionPayeeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInstructionPayeeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': json['type'],
        'address': !exists(json, 'address') ? undefined : AddressModelFromJSON(json['address']),
        'telephoneNumber': !exists(json, 'telephoneNumber') ? undefined : json['telephoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function PaymentInstructionPayeeModelToJSON(value?: PaymentInstructionPayeeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'address': AddressModelToJSON(value.address),
        'telephoneNumber': value.telephoneNumber,
        'email': value.email,
    };
}

