import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import { useListSettlementQuotes } from "api/agreements/settlement-quotes/listSettlementQuotes";
import AgreementSettlementQuotesContainer from "apps/servicing/modules/agreements/containers/AgreementSettlementQuotesContainer";
import { withSettlementQuote } from "apps/servicing/modules/agreements/hocs/withSettlementQuote";
import ModifiableToggle from "common/components/ModifiableToggle";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import ContainedLayout from "common/layouts/ContainedLayout";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import compose from "lodash/fp/compose";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

function SettlementQuotesView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();
  const agreementId = agreement.id;
  const settlementQuotes = useListSettlementQuotes(agreementId);
  const appConfig = useAppConfiguration();
  const href = `${appConfig.appRoutes.servicing}/agreements/${agreementId}/settlement-quotes/apply-settlement-quote`;

  return (
    <ViewLayout
      title={t("settlement_quotes.heading")}
      action={
        Boolean(settlementQuotes.data?.length) && (
          <AccessControl
            allowedPermissions={["servicing:settlement-quotes:manage"]}
          >
            <ModifiableToggle>
              <AddButton
                component={RouterLink}
                to={href}
                role="link"
                variant="contained"
              >
                {t("settlement_quotes.create_quote.create_button")}
              </AddButton>
            </ModifiableToggle>
          </AccessControl>
        )
      }
    >
      <FixedLayout>
        <ContainedLayout>
          <AgreementSettlementQuotesContainer agreementId={agreementId} />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}

export default compose(withSettlementQuote)(SettlementQuotesView);
