import { QueryKey } from "api";
import { keys } from "api/documents";

export const all = (): QueryKey => [...keys.all(), "portfolios", "templates"];

export const list = (portfolioId: number): QueryKey => [
  ...all(),
  "list",
  portfolioId,
];
