import {
  AlertDialog,
  ConfirmationDialog,
  Loader,
} from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDeleteBankAccount } from "api/customers/bank-accounts/deleteBankAccount";
import { useListBankAccounts } from "api/customers/bank-accounts/listBanksAccounts";
import { BankAccountsList } from "apps/servicing/modules/customers/components";
import UpdateBankAccount from "apps/servicing/modules/customers/containers/UpdateBankAccount";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { useConfirm } from "common/hooks";
import ListLayout from "common/layouts/ListLayout";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

const commonError = "common:error.default";

const DeleteBankAccount = ({
  customerId,
  bankAccountId,
  open,
  onClose,
}: {
  customerId: string;
  bankAccountId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation("clients");
  const confirm = useConfirm();
  const deleteBankAccount = useDeleteBankAccount(customerId, bankAccountId, {
    onSuccess: onClose,
  });

  const handleClose = () => {
    deleteBankAccount.reset();
    onClose();
  };

  const handleConfirm = async () => {
    await deleteBankAccount.execute();
  };

  const handleReject = async () => {
    confirm.handleReject();
    handleClose();
  };

  return (
    <>
      <AlertDialog
        content={deleteBankAccount.error?.message ?? t(commonError)}
        labels={{ close: t("common:alert.close") }}
        onClose={handleClose}
        open={Boolean(deleteBankAccount.error)}
        title={t("remove_bank_account.error_title")}
      />

      <ConfirmationDialog
        content={
          <>
            <Typography>
              {t("remove_bank_account.confirmation_message")}
            </Typography>
            <Typography>
              {t("remove_bank_account.confirmation_message_mandates")}
            </Typography>
          </>
        }
        open={open}
        onConfirm={handleConfirm}
        onReject={handleReject}
        title={t("remove_bank_account.confirmation_title_label")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />
    </>
  );
};

export default function BankAccounts(): ReactElement {
  const { t } = useTranslation("customers");
  const customer = useCustomer();
  const listBankAccounts = useListBankAccounts(customer.data.systemId);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>();
  const [selectedBankAccountIdForDelete, setSelectedBankAccountIdForDelete] =
    useState<string>();
  const [isUpdateBankAccountDialogOpen, setIsUpdateBankAccountDialogOpen] =
    useState(false);
  const [isDeleteBankAccountDialogOpen, setIsDeleteBankAccountDialogOpen] =
    useState(false);

  const handleUpdateBankAccountDialogClose = (): void => {
    setIsUpdateBankAccountDialogOpen(false);
  };

  const handleDeleteBankAccountClose = (): void => {
    setIsDeleteBankAccountDialogOpen(false);
  };

  const onUpdateBankAccountSubmit = (): void => {
    setIsUpdateBankAccountDialogOpen(false);
  };

  const onUpdateBankAccount = (bankAccountId: string): void => {
    setIsUpdateBankAccountDialogOpen(true);
    setSelectedBankAccountId(bankAccountId);
  };

  const onDeleteBankAccount = (bankAccountId: string): void => {
    setSelectedBankAccountIdForDelete(bankAccountId);
    setIsDeleteBankAccountDialogOpen(true);
  };

  return (
    <>
      {selectedBankAccountId && isUpdateBankAccountDialogOpen && (
        <UpdateBankAccount
          clientId={customer.data.systemId}
          bankAccountId={selectedBankAccountId}
          onSubmit={onUpdateBankAccountSubmit}
          open={isUpdateBankAccountDialogOpen}
          onClose={handleUpdateBankAccountDialogClose}
        />
      )}

      {selectedBankAccountIdForDelete && isDeleteBankAccountDialogOpen && (
        <DeleteBankAccount
          customerId={customer.data.systemId}
          bankAccountId={selectedBankAccountIdForDelete}
          open={isDeleteBankAccountDialogOpen}
          onClose={handleDeleteBankAccountClose}
        />
      )}

      <ListLayout>
        <Loader
          ready={Boolean(listBankAccounts.data ?? listBankAccounts.error)}
          render={() => {
            if (listBankAccounts.error || !listBankAccounts.data) {
              return (
                <Typography
                  color="error"
                  aria-label={t(commonError)}
                  role="alert"
                >
                  {t(commonError)}
                </Typography>
              );
            }

            return (
              <BankAccountsList
                data={listBankAccounts.data}
                loading={listBankAccounts.isValidating}
                onDeleteBankAccount={onDeleteBankAccount}
                onUpdateBankAccount={onUpdateBankAccount}
              />
            );
          }}
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
        />
      </ListLayout>
    </>
  );
}
