/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing vat codes within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VatCodeListItemModel
 */
export interface VatCodeListItemModel {
    /**
     * Vat code
     * @type {string}
     * @memberof VatCodeListItemModel
     */
    vatCode: string;
    /**
     * Description of the vat code
     * @type {string}
     * @memberof VatCodeListItemModel
     */
    description: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof VatCodeListItemModel
     */
    effectiveDate: Date;
    /**
     * Vat rate
     * @type {number}
     * @memberof VatCodeListItemModel
     */
    rate: number;
}

/**
 * Check if a given object implements the VatCodeListItemModel interface.
 */
export function instanceOfVatCodeListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vatCode" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "effectiveDate" in value;
    isInstance = isInstance && "rate" in value;

    return isInstance;
}

export function VatCodeListItemModelFromJSON(json: any): VatCodeListItemModel {
    return VatCodeListItemModelFromJSONTyped(json, false);
}

export function VatCodeListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VatCodeListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vatCode': json['vatCode'],
        'description': json['description'],
        'effectiveDate': (new Date(json['effectiveDate'])),
        'rate': json['rate'],
    };
}

export function VatCodeListItemModelToJSON(value?: VatCodeListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vatCode': value.vatCode,
        'description': value.description,
        'effectiveDate': (value.effectiveDate.toISOString().substring(0,10)),
        'rate': value.rate,
    };
}

