import { AddButton } from "@ldms/mui-sdk/components";
import { useAddAgreementMemo } from "api/agreements/memos/addAgreementMemo";
import { AddMemoFormDialog } from "common/components";
import useModal from "common/hooks/useModal";
import { AddMemoModel } from "generated/core/models/AddMemoModel";

interface AddMemoContainerProps {
  agreementId: number;
}

export default function AddMemoContainer({
  agreementId,
}: AddMemoContainerProps) {
  const addCustomerMemo = useAddAgreementMemo(agreementId);
  const modal = useModal();

  const onSubmit = async (model: AddMemoModel) => {
    await addCustomerMemo.execute(model);
    modal.close();
  };

  return (
    <>
      <AddButton onClick={modal.open} variant="contained" />
      <AddMemoFormDialog
        onClose={modal.close}
        onSubmit={onSubmit}
        open={modal.isOpen}
      />
    </>
  );
}
