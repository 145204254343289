/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Attachments within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AttachmentTypeModel = {
    Accounts: 'Accounts',
    Agreement: 'Agreement',
    AnnualStatement: 'Annual Statement',
    Bank: 'Bank',
    Broker: 'Broker',
    Charge: 'Charge',
    Collections: 'Collections',
    Contract: 'Contract',
    Correspondence: 'Correspondence',
    Deed: 'Deed',
    Director: 'Director',
    Funder: 'Funder',
    Gdpr: 'GDPR',
    Hpi: 'HPI',
    Insurance: 'Insurance',
    Invoice: 'Invoice',
    Kyc: 'KYC',
    LandReg: 'Land Reg',
    Legal: 'Legal',
    Owner: 'Owner',
    Searches: 'Searches',
    Statement: 'Statement',
    Valuations: 'Valuations',
    Other: 'Other',
    Letter: 'Letter'
} as const;
export type AttachmentTypeModel = typeof AttachmentTypeModel[keyof typeof AttachmentTypeModel];


export function AttachmentTypeModelFromJSON(json: any): AttachmentTypeModel {
    return AttachmentTypeModelFromJSONTyped(json, false);
}

export function AttachmentTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentTypeModel {
    return json as AttachmentTypeModel;
}

export function AttachmentTypeModelToJSON(value?: AttachmentTypeModel | null): any {
    return value as any;
}

