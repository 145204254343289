/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Assets within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssetCategoryModel,
  AssetListItemModel,
  AssetManufacturerModel,
  AssetModel,
  AssetServicePlanModel,
  AssetSettlementQuoteSummaryModel,
  AssetTypeModel,
  AssetValuationListItemModel,
  CalculatePartialSettlementQuoteModel,
  FinancialDetailsModel,
  SettlementQuoteListItemModel,
} from '../models/index';
import {
    AssetCategoryModelFromJSON,
    AssetCategoryModelToJSON,
    AssetListItemModelFromJSON,
    AssetListItemModelToJSON,
    AssetManufacturerModelFromJSON,
    AssetManufacturerModelToJSON,
    AssetModelFromJSON,
    AssetModelToJSON,
    AssetServicePlanModelFromJSON,
    AssetServicePlanModelToJSON,
    AssetSettlementQuoteSummaryModelFromJSON,
    AssetSettlementQuoteSummaryModelToJSON,
    AssetTypeModelFromJSON,
    AssetTypeModelToJSON,
    AssetValuationListItemModelFromJSON,
    AssetValuationListItemModelToJSON,
    CalculatePartialSettlementQuoteModelFromJSON,
    CalculatePartialSettlementQuoteModelToJSON,
    FinancialDetailsModelFromJSON,
    FinancialDetailsModelToJSON,
    SettlementQuoteListItemModelFromJSON,
    SettlementQuoteListItemModelToJSON,
} from '../models/index';

export interface GetAssetRequest {
    assetId: number;
}

export interface GetAssetSettlementQuoteSummaryRequest {
    assetId: number;
}

export interface GetCalculatedPartialSettlementQuoteRequest {
    assetId: number;
    amount: string;
}

export interface GetFinancialDetailsRequest {
    assetId: number;
}

export interface GetServicePlanRequest {
    assetId: number;
}

export interface ListAssetSettlementQuotesRequest {
    assetId: number;
}

export interface ListAssetsRequest {
    offset?: number;
    limit?: number;
    query?: string;
}

export interface ListValuationsRequest {
    assetId: number;
    offset?: number;
    limit?: number;
}

/**
 * 
 */
export class AssetQueryApi extends runtime.BaseAPI {

    /**
     * View Asset
     */
    async getAssetRaw(requestParameters: GetAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetModel>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling getAsset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["assets:view"]);
        }

        const response = await this.request({
            path: `/assets/{assetId}`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetModelFromJSON(jsonValue));
    }

    /**
     * View Asset
     */
    async getAsset(requestParameters: GetAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetModel> {
        const response = await this.getAssetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get settlement quote summary for an asset
     */
    async getAssetSettlementQuoteSummaryRaw(requestParameters: GetAssetSettlementQuoteSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetSettlementQuoteSummaryModel>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling getAssetSettlementQuoteSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["asset-settlement-quotes:view"]);
        }

        const response = await this.request({
            path: `/assets/{assetId}/settlement-quote`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetSettlementQuoteSummaryModelFromJSON(jsonValue));
    }

    /**
     * Get settlement quote summary for an asset
     */
    async getAssetSettlementQuoteSummary(requestParameters: GetAssetSettlementQuoteSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetSettlementQuoteSummaryModel> {
        const response = await this.getAssetSettlementQuoteSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Calculate partial settlement quote for an asset
     */
    async getCalculatedPartialSettlementQuoteRaw(requestParameters: GetCalculatedPartialSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalculatePartialSettlementQuoteModel>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling getCalculatedPartialSettlementQuote.');
        }

        if (requestParameters.amount === null || requestParameters.amount === undefined) {
            throw new runtime.RequiredError('amount','Required parameter requestParameters.amount was null or undefined when calling getCalculatedPartialSettlementQuote.');
        }

        const queryParameters: any = {};

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["asset-settlement-quotes:view"]);
        }

        const response = await this.request({
            path: `/assets/{assetId}/partial-settlement-quote`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalculatePartialSettlementQuoteModelFromJSON(jsonValue));
    }

    /**
     * Calculate partial settlement quote for an asset
     */
    async getCalculatedPartialSettlementQuote(requestParameters: GetCalculatedPartialSettlementQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalculatePartialSettlementQuoteModel> {
        const response = await this.getCalculatedPartialSettlementQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Asset Financial Details
     */
    async getFinancialDetailsRaw(requestParameters: GetFinancialDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FinancialDetailsModel>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling getFinancialDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["financial-details:view"]);
        }

        const response = await this.request({
            path: `/assets/{assetId}/financial-details`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FinancialDetailsModelFromJSON(jsonValue));
    }

    /**
     * View Asset Financial Details
     */
    async getFinancialDetails(requestParameters: GetFinancialDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FinancialDetailsModel> {
        const response = await this.getFinancialDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View Asset Service Plan
     */
    async getServicePlanRaw(requestParameters: GetServicePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetServicePlanModel>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling getServicePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["service-plan:view"]);
        }

        const response = await this.request({
            path: `/assets/{assetId}/service-plan`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetServicePlanModelFromJSON(jsonValue));
    }

    /**
     * View Asset Service Plan
     */
    async getServicePlan(requestParameters: GetServicePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetServicePlanModel> {
        const response = await this.getServicePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available asset categories
     */
    async listAssetCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AssetCategoryModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["asset-categories:view"]);
        }

        const response = await this.request({
            path: `/assets/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssetCategoryModelFromJSON));
    }

    /**
     * List available asset categories
     */
    async listAssetCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AssetCategoryModel>> {
        const response = await this.listAssetCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List available asset manufacturers
     */
    async listAssetManufacturersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AssetManufacturerModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["manufacturers:view"]);
        }

        const response = await this.request({
            path: `/assets/manufacturers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssetManufacturerModelFromJSON));
    }

    /**
     * List available asset manufacturers
     */
    async listAssetManufacturers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AssetManufacturerModel>> {
        const response = await this.listAssetManufacturersRaw(initOverrides);
        return await response.value();
    }

    /**
     * List settlement quotes for an asset
     */
    async listAssetSettlementQuotesRaw(requestParameters: ListAssetSettlementQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SettlementQuoteListItemModel>>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling listAssetSettlementQuotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["asset-settlement-quotes:view"]);
        }

        const response = await this.request({
            path: `/assets/{assetId}/settlement-quotes`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SettlementQuoteListItemModelFromJSON));
    }

    /**
     * List settlement quotes for an asset
     */
    async listAssetSettlementQuotes(requestParameters: ListAssetSettlementQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SettlementQuoteListItemModel>> {
        const response = await this.listAssetSettlementQuotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List available asset types
     */
    async listAssetTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AssetTypeModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["asset-types:view"]);
        }

        const response = await this.request({
            path: `/assets/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssetTypeModelFromJSON));
    }

    /**
     * List available asset types
     */
    async listAssetTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AssetTypeModel>> {
        const response = await this.listAssetTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Provides an API to list assets in the system
     */
    async listAssetsRaw(requestParameters: ListAssetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AssetListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["assets:view"]);
        }

        const response = await this.request({
            path: `/assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssetListItemModelFromJSON));
    }

    /**
     * Provides an API to list assets in the system
     */
    async listAssets(requestParameters: ListAssetsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AssetListItemModel>> {
        const response = await this.listAssetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List valuations for a given asset
     */
    async listValuationsRaw(requestParameters: ListValuationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AssetValuationListItemModel>>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling listValuations.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["valuations:view"]);
        }

        const response = await this.request({
            path: `/assets/{assetId}/valuations`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssetValuationListItemModelFromJSON));
    }

    /**
     * List valuations for a given asset
     */
    async listValuations(requestParameters: ListValuationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AssetValuationListItemModel>> {
        const response = await this.listValuationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
