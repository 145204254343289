import { FormDialog } from "@ldms/mui-sdk/templates";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useAddThirdParty } from "api/third-parties";
import ThirdPartyControls from "apps/servicing/modules/settings/components/ThirdPartyControls";
import { ThirdPartyFormValues } from "apps/servicing/modules/settings/components/ThirdPartyControls/ThirdPartyControls";
import { ControlledTextField } from "common/components";
import { useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { ThirdPartyQueryApi } from "generated/core/apis";
import { ThirdPartyTypeListItemModel } from "generated/core/models";
import { ReactElement } from "react";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

const thirdParties = "third-parties";

const useAddThirdPartyResolver = () => {
  const { t } = useTranslation(thirdParties);

  return useYupResolver<AddThirdPartyFieldValues>((yup) =>
    yup.object().shape({
      type: yup.string().isRequired(t("add.type_label")),
      name: yup
        .string()
        .isRequired(t(nameLabel))
        .maxCharacters(50, t(nameLabel)),
      contact: yup.string().maxCharacters(50, t("add.contact_label")),
      addressLine1: yup
        .string()
        .optionalAddressLine1Validator(
          "thirdPartyAddressLine1",
          t(addressLine1Label),
        )
        .maxCharacters(50, t(addressLine1Label)),
      addressLine4: yup
        .string()
        .maxCharacters(50, t("add.address_line_4_label")),
      addressLine3: yup
        .string()
        .maxCharacters(50, t("add.address_line_3_label")),
      addressLine2: yup
        .string()
        .maxCharacters(50, t("add.address_line_2_label")),
      postcode: yup
        .string()
        .optionalPostcodeValidator("thirdPartyPostcode", t(postcodeLabel))
        .maxCharacters(10, t(postcodeLabel)),
      telephone: yup.string().maxCharacters(20, t("add.telephone_label")),
      faxNumber: yup.string().maxCharacters(20, t("add.fax_number_label")),
      emailAddress: yup
        .string()
        .maxCharacters(200, t("add.email_address_label")),
      vatRegistrationNumber: yup
        .string()
        .maxCharacters(50, t("add.vat_registration_number_label")),
    }),
  );
};

export interface AddThirdPartyFieldValues extends ThirdPartyFormValues {
  type: string;
  name: string;
  contact: string;
  vatRegistrationNumber: string;
}

interface AddThirdPartyFormControlsProps {
  errors: FieldErrors<AddThirdPartyFieldValues>;
  register: UseFormRegister<AddThirdPartyFieldValues>;
  control: Control<AddThirdPartyFieldValues>;
  thirdPartyTypes: ThirdPartyTypeListItemModel[] | undefined;
}

const nameLabel = "add.name_label";
const addressLine1Label = "add.address_line_1_label";
const postcodeLabel = "add.postcode_label";

function AddThirdPartyFormControls({
  errors,
  register,
  control,
  thirdPartyTypes,
}: AddThirdPartyFormControlsProps): ReactElement {
  const { t } = useTranslation(thirdParties);

  return (
    <ThirdPartyControls
      errors={errors}
      labels={{
        addressLine1: t("add.address_line_1_label"),
        addressLine2: t("add.address_line_2_label"),
        addressLine3: t("add.address_line_3_label"),
        addressLine4: t("add.address_line_4_label"),
        postcode: t("add.postcode_label"),
        telephone: t("add.telephone_label"),
        faxNumber: t("add.fax_number_label"),
        emailAddress: t("add.email_address_label"),
      }}
      register={register}
    >
      <Grid container columnSpacing={2}>
        <Grid item sm={12}>
          <ControlledTextField
            select
            id="type"
            name="type"
            label={t("add.type_label")}
            helperText={errors?.type?.message}
            error={errors?.type?.message}
            SelectProps={{ displayEmpty: true }}
            control={control}
            required
            defaultValue=""
          >
            <MenuItem value="">
              <i>Please Select</i>
            </MenuItem>

            {thirdPartyTypes?.map((type) => (
              <MenuItem key={type.code} value={type.code}>
                {type.description}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("name")}
            required
            label={t(nameLabel)}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("contact")}
            label={t("add.contact_label")}
            error={Boolean(errors.contact)}
            helperText={errors.contact?.message}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("vatRegistrationNumber")}
            label={t("add.vat_registration_number_label")}
            error={Boolean(errors.vatRegistrationNumber)}
            helperText={errors.vatRegistrationNumber?.message}
          />
        </Grid>
      </Grid>
    </ThirdPartyControls>
  );
}

interface AddThirdPartyDialogContainerProps {
  onSuccess(): void;
  onClose(): void;
  open: boolean;
}

export default function AddThirdPartyDialogContainer({
  onSuccess,
  onClose,
  open,
}: AddThirdPartyDialogContainerProps) {
  const { t } = useTranslation(thirdParties);
  const resolver = useAddThirdPartyResolver();

  const addThirdParty = useAddThirdParty({ onSuccess });

  const onSubmit = async (data: AddThirdPartyFieldValues): Promise<void> =>
    addThirdParty.command(data);

  const thirdPartyQueryApi = useApi(ThirdPartyQueryApi);
  const thirdPartyTypes = useSWR(["/third-party-types"], () =>
    thirdPartyQueryApi.listThirdPartyTypes(),
  );

  const handleClose = () => {
    onClose();
    addThirdParty.reset();
  };

  return (
    <FormDialog
      title={t("add.third_party_title_label")}
      defaultValues={{ type: "" }}
      disabled={addThirdParty.isLoading}
      onClose={handleClose}
      open={open}
      onSubmit={onSubmit}
      ready={Boolean(thirdPartyTypes.data ?? thirdPartyTypes.error)}
      resolver={resolver}
    >
      {(form) => (
        <>
          <AddThirdPartyFormControls
            errors={form.formState.errors}
            register={form.register}
            control={form.control}
            thirdPartyTypes={thirdPartyTypes.data}
          />
          {addThirdParty.error && (
            <Typography color="error" data-testid="form.error">
              {addThirdParty.error.message}
            </Typography>
          )}
        </>
      )}
    </FormDialog>
  );
}
