import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import * as paymentIntructionKeys from "api/agreements/payment-instructions/keys";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers/ApiProvider";
import { AgreementPaymentInstructionsApi } from "generated/servicing-v2/apis";
import { PaymentInstructionModel } from "generated/servicing-v2/models";

export const useAddPaymentIntruction = (
  id: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementPaymentInstructionsApi, "v2");
  const partialMutate = usePartialMutate();

  return useCommand<PaymentInstructionModel, void, ResponseError>(
    (model) =>
      api.createPaymentInstruction({
        id: id,
        paymentInstructionModel: model,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(paymentIntructionKeys.all(id));
        options.onSuccess?.();
      },
    },
  );
};
