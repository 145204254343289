/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetCategoryModel } from './AssetCategoryModel';
import {
    AssetCategoryModelFromJSON,
    AssetCategoryModelFromJSONTyped,
    AssetCategoryModelToJSON,
} from './AssetCategoryModel';
import type { NewOrUsedModel } from './NewOrUsedModel';
import {
    NewOrUsedModelFromJSON,
    NewOrUsedModelFromJSONTyped,
    NewOrUsedModelToJSON,
} from './NewOrUsedModel';

/**
 * 
 * @export
 * @interface AssetModel
 */
export interface AssetModel {
    /**
     * The asset type
     * @type {string}
     * @memberof AssetModel
     */
    assetType: string;
    /**
     * The asset description
     * @type {string}
     * @memberof AssetModel
     */
    assetDescription: string;
    /**
     * 
     * @type {AssetCategoryModel}
     * @memberof AssetModel
     */
    assetCategory?: AssetCategoryModel | null;
    /**
     * Manufacturer description
     * @type {string}
     * @memberof AssetModel
     */
    manufacturerDescription?: string;
    /**
     * A read write Id
     * @type {number}
     * @memberof AssetModel
     */
    serviceProviderId?: number;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    manufacturerSubsidy?: string;
    /**
     * Scheme code for a manufacturer
     * @type {string}
     * @memberof AssetModel
     */
    schemeCode?: string;
    /**
     * VAT code for a manufacturer
     * @type {string}
     * @memberof AssetModel
     */
    vatCode?: string;
    /**
     * asset model
     * @type {string}
     * @memberof AssetModel
     */
    model?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetModel
     */
    hpiRegisteredDate?: Date;
    /**
     * Flag to define if the user wishes to apply insurance
     * @type {boolean}
     * @memberof AssetModel
     */
    includeInsurance: boolean;
    /**
     * asset registration number
     * @type {string}
     * @memberof AssetModel
     */
    registrationNumber?: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof AssetModel
     */
    registrationDate?: Date;
    /**
     * 
     * @type {NewOrUsedModel}
     * @memberof AssetModel
     */
    newOrUsed: NewOrUsedModel;
    /**
     * The asset year of manufacture
     * @type {string}
     * @memberof AssetModel
     */
    yearManufactured?: string;
    /**
     * The vehicle identification number
     * @type {string}
     * @memberof AssetModel
     */
    vehicleIdentificationNumber?: string;
    /**
     * The asset serial number
     * @type {string}
     * @memberof AssetModel
     */
    serialNumber?: string;
    /**
     * The totl cost price excluding vat
     * @type {number}
     * @memberof AssetModel
     */
    costPriceNetVat: number;
    /**
     * The amount of vat
     * @type {number}
     * @memberof AssetModel
     */
    vat: number;
    /**
     * The asset banding representation
     * @type {string}
     * @memberof AssetModel
     */
    assetBanding?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AssetModel
     */
    fleetDiscount?: string;
}

/**
 * Check if a given object implements the AssetModel interface.
 */
export function instanceOfAssetModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assetType" in value;
    isInstance = isInstance && "assetDescription" in value;
    isInstance = isInstance && "includeInsurance" in value;
    isInstance = isInstance && "newOrUsed" in value;
    isInstance = isInstance && "costPriceNetVat" in value;
    isInstance = isInstance && "vat" in value;

    return isInstance;
}

export function AssetModelFromJSON(json: any): AssetModel {
    return AssetModelFromJSONTyped(json, false);
}

export function AssetModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetType': json['assetType'],
        'assetDescription': json['assetDescription'],
        'assetCategory': !exists(json, 'assetCategory') ? undefined : AssetCategoryModelFromJSON(json['assetCategory']),
        'manufacturerDescription': !exists(json, 'manufacturerDescription') ? undefined : json['manufacturerDescription'],
        'serviceProviderId': !exists(json, 'serviceProviderId') ? undefined : json['serviceProviderId'],
        'manufacturerSubsidy': !exists(json, 'manufacturerSubsidy') ? undefined : json['manufacturerSubsidy'],
        'schemeCode': !exists(json, 'schemeCode') ? undefined : json['schemeCode'],
        'vatCode': !exists(json, 'vatCode') ? undefined : json['vatCode'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'hpiRegisteredDate': !exists(json, 'hpiRegisteredDate') ? undefined : (new Date(json['hpiRegisteredDate'])),
        'includeInsurance': json['includeInsurance'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'registrationDate': !exists(json, 'registrationDate') ? undefined : (new Date(json['registrationDate'])),
        'newOrUsed': NewOrUsedModelFromJSON(json['newOrUsed']),
        'yearManufactured': !exists(json, 'yearManufactured') ? undefined : json['yearManufactured'],
        'vehicleIdentificationNumber': !exists(json, 'vehicleIdentificationNumber') ? undefined : json['vehicleIdentificationNumber'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'costPriceNetVat': json['costPriceNetVat'],
        'vat': json['vat'],
        'assetBanding': !exists(json, 'assetBanding') ? undefined : json['assetBanding'],
        'fleetDiscount': !exists(json, 'fleetDiscount') ? undefined : json['fleetDiscount'],
    };
}

export function AssetModelToJSON(value?: AssetModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetType': value.assetType,
        'assetDescription': value.assetDescription,
        'assetCategory': AssetCategoryModelToJSON(value.assetCategory),
        'manufacturerDescription': value.manufacturerDescription,
        'serviceProviderId': value.serviceProviderId,
        'manufacturerSubsidy': value.manufacturerSubsidy,
        'schemeCode': value.schemeCode,
        'vatCode': value.vatCode,
        'model': value.model,
        'hpiRegisteredDate': value.hpiRegisteredDate === undefined ? undefined : (value.hpiRegisteredDate.toISOString().substring(0,10)),
        'includeInsurance': value.includeInsurance,
        'registrationNumber': value.registrationNumber,
        'registrationDate': value.registrationDate === undefined ? undefined : (value.registrationDate.toISOString().substring(0,10)),
        'newOrUsed': NewOrUsedModelToJSON(value.newOrUsed),
        'yearManufactured': value.yearManufactured,
        'vehicleIdentificationNumber': value.vehicleIdentificationNumber,
        'serialNumber': value.serialNumber,
        'costPriceNetVat': value.costPriceNetVat,
        'vat': value.vat,
        'assetBanding': value.assetBanding,
        'fleetDiscount': value.fleetDiscount,
    };
}

