import { DateField } from "@ldms/mui-sdk/forms";
import { FormDialog } from "@ldms/mui-sdk/templates";
import { Box, Grid, TextField } from "@mui/material";
import { useAddComment } from "api/tasks/addComment";
import { useUpdateTaskStatus } from "api/tasks/updateTaskStatus";
import { useYupResolver } from "common/hooks";
import { useTranslation } from "react-i18next";

type ReopenTaskDialogProps = {
  open: boolean;
  taskId: number;
  defaultValues: ReopenTaskFieldValues;
  onClose: () => void;
};

export type ReopenTaskFieldValues = {
  dueAt: Date;
  dueTime: string;
};

const labels = {
  dueAt: "reopen_task_dialog.due_date_label",
  dueTime: "reopen_task_dialog.due_date_time_label",
};

const useReopenTaskResolver = () => {
  const { t } = useTranslation(["start"]);

  const transformField = (v: string, o: string): string | null | undefined => {
    return o === "" ? undefined : v;
  };

  return useYupResolver<ReopenTaskFieldValues>((yup) =>
    yup.object().shape({
      dueAt: yup
        .date()
        .localDate()
        .isValidDate(t(labels.dueAt))
        .isNotBeforeToday(t(labels.dueAt))
        .isRequired(t(labels.dueAt)),
      dueTime: yup
        .string()
        .transform(transformField)
        .isRequired(t(labels.dueTime))
        .test({
          name: "isNotBeforeCurrentTime",
          message: t("reopen_task_dialog.due_date_and_time_error"),
          test: (value: string | undefined, testContext) => {
            const dueAt = testContext.parent.dueAt
              ?.toISOString()
              .slice(0, 11)
              .concat(value);

            return new Date(dueAt) >= new Date();
          },
        }),
    }),
  );
};

const ReopenTaskDialog = ({
  open,
  taskId,
  defaultValues,
  onClose,
}: ReopenTaskDialogProps) => {
  const { t } = useTranslation(["start"]);
  const createComment = useAddComment(taskId);
  const reopenTaskResolver = useReopenTaskResolver();

  const reopenTask = useUpdateTaskStatus(taskId, {
    onSuccess: async () => {
      await createComment.execute({
        comment: t("comments.comment_task_reopened"),
      });
      onClose();
    },
  });

  const onSubmit = (data: ReopenTaskFieldValues) => {
    const dueAt = data.dueAt?.toISOString().slice(0, 11).concat(data.dueTime);

    reopenTask.execute({
      status: "Reopen",
      dueAt,
    });
  };

  return (
    <FormDialog
      open={open}
      title={t("reopen_task_dialog.title")}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      onClose={onClose}
      resolver={reopenTaskResolver}
      labels={{
        cancel: t("common:cancel"),
        submit: t("reopen_task_dialog.reopen_button"),
      }}
    >
      {(form) => (
        <Box my={3}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <DateField
                control={form.control}
                required
                label={t(labels.dueAt)}
                error={Boolean(form.formState.errors.dueAt?.message)}
                id="due-at-field"
                helperText={form.formState.errors.dueAt?.message}
                margin="none"
                name="dueAt"
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                type="time"
                margin="none"
                {...form.register("dueTime")}
                label={t(labels.dueTime)}
                id="due-time-field"
                required
                error={Boolean(form.formState.errors.dueTime?.message)}
                helperText={form.formState.errors.dueTime?.message}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </FormDialog>
  );
};

export { ReopenTaskDialog };
