import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { AddButton } from "@ldms/mui-sdk/components";
import { Loader } from "@ldms/mui-sdk/templates";
import { Box, Pagination, Typography } from "@mui/material";
import { useAddCustomerMemo, useListCustomerMemos } from "api/customers/memos";
import { CustomerOverviewMemos } from "apps/servicing/modules/customers/components";
import { AddMemoFormDialog } from "common/components";
import Surface from "common/components/Surface";
import usePaginateData from "common/hooks/usePaginateData";
import { AddMemoModel } from "generated/core/models";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

interface CustomerMemosContainerProps {
  customerId: string;
}

export default function CustomerMemosContainer({
  customerId,
}: CustomerMemosContainerProps) {
  const { t } = useTranslation("customers");

  const [isAddMemoDialogOpen, setAddMemoDialogOpen] = useState(false);
  const handleAddMemoClick = (): void => {
    setAddMemoDialogOpen(true);
  };
  const handleCloseMemoClick = (): void => {
    setAddMemoDialogOpen(false);
  };
  const addCustomerMemo = useAddCustomerMemo(customerId, {
    onSuccess: handleCloseMemoClick,
  });
  const memos = useListCustomerMemos(customerId, {
    from: undefined,
  });
  const onAddMemoSubmit = async (addMemoModel: AddMemoModel): Promise<void> => {
    await addCustomerMemo.command(addMemoModel);
  };

  const data = memos.data || [];
  const paginateData = usePaginateData(data, 5);

  const handlePageChange = (_: ChangeEvent<unknown>, value: number): void => {
    paginateData.setPage(value);
  };

  const renderLoadedMemos = () => {
    if (memos.error || !memos.data) {
      return (
        <Typography color="error" data-testid="clientMemos.error">
          {t("common:error.default")}
        </Typography>
      );
    }

    return <CustomerOverviewMemos memos={paginateData.results} />;
  };

  return (
    <>
      <AddMemoFormDialog
        open={isAddMemoDialogOpen}
        onClose={handleCloseMemoClick}
        onSubmit={onAddMemoSubmit}
      />

      <Surface
        title={t("overview.memos.heading")}
        disableGutters
        action={
          <AccessControl
            allowedPermissions={["servicing:customer-memos:manage"]}
          >
            <AddButton onClick={handleAddMemoClick} />
          </AccessControl>
        }
        footer={
          <Box display="flex" justifyContent="flex-end">
            {paginateData.totalNumberOfPages > 1 && (
              <Pagination
                aria-label="memo-pagination"
                count={paginateData.totalNumberOfPages}
                page={paginateData.currentPage}
                onChange={handlePageChange}
                siblingCount={0}
                boundaryCount={1}
              />
            )}
          </Box>
        }
      >
        <Loader
          ready={Boolean(memos.data ?? memos.error)}
          render={renderLoadedMemos}
        />
      </Surface>
    </>
  );
}
