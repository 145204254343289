/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductTypeModel = {
    FixedRateFinanceLease: 'Fixed Rate Finance Lease',
    FixedRateHirePurchase: 'Fixed Rate Hire Purchase',
    FixedRateLoan: 'Fixed Rate Loan',
    FixedRateOperatingLease: 'Fixed Rate Operating Lease'
} as const;
export type ProductTypeModel = typeof ProductTypeModel[keyof typeof ProductTypeModel];


export function ProductTypeModelFromJSON(json: any): ProductTypeModel {
    return ProductTypeModelFromJSONTyped(json, false);
}

export function ProductTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductTypeModel {
    return json as ProductTypeModel;
}

export function ProductTypeModelToJSON(value?: ProductTypeModel | null): any {
    return value as any;
}

