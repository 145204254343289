import {
  StepButton,
  Step as StepperItem,
  Stepper as StepperList,
} from "@mui/material";
import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";

interface Stepper {
  initialActiveStep: string;
  steps: string[];
  step: string;
  goTo(path: string): void;
}

const StepperContext = createContext<Stepper>({} as Stepper);

interface StepModel<Path> {
  active: boolean;
  index: number;
  path: Path;
  completed: boolean;
}

export interface UseStepperMethods<Path> {
  activeStep: string;
  activeIndex: number;
  goTo(path: Path): void;
  next(): void;
  previous(): void;
  steps: StepModel<Path>[];
}

export function useStepper<Path extends string>(): UseStepperMethods<Path> {
  const stepper = useContext(StepperContext);
  const activeIndex = stepper.steps.indexOf(stepper.step);

  const next = (): void => {
    stepper.goTo(stepper.steps[activeIndex + 1]);
  };

  const previous = (): void => {
    stepper.goTo(stepper.steps[activeIndex - 1]);
  };

  return {
    activeIndex,
    activeStep: stepper.step,
    goTo: stepper.goTo,
    next,
    previous,
    steps: stepper.steps.map<StepModel<Path>>((path, index) => ({
      active: index === activeIndex,
      completed: index < activeIndex,
      index,
      path: path as Path,
    })),
  };
}

interface StepProps {
  children: ReactNode;
  path: string;
}

export function Step({ children, path }: StepProps) {
  const stepper = useStepper();

  if (stepper.activeStep !== path) {
    return null;
  }

  return <>{children}</>;
}

interface StepperMenuProps {
  labels: string[];
}

export function StepperMenu({ labels }: StepperMenuProps): ReactElement {
  const stepper = useStepper();

  const makeGoToStepHandler = (path: string) => (): void => stepper.goTo(path);

  return (
    <StepperList
      activeStep={stepper.activeIndex}
      sx={{
        backgroundColor: "transparent",
      }}
      nonLinear
      orientation="vertical"
    >
      {stepper.steps.map((step) => (
        <StepperItem completed={step.completed} key={step.path}>
          <StepButton
            disabled={!step.active && !step.completed}
            onClick={makeGoToStepHandler(step.path)}
          >
            {labels[step.index]}
          </StepButton>
        </StepperItem>
      ))}
    </StepperList>
  );
}

interface StepperProviderProps {
  children: ReactNode;
  initialActiveStep?: string;
  steps: string[];
}

export default function StepperProvider({
  children,
  initialActiveStep = "/",
  steps,
}: StepperProviderProps): ReactElement {
  const [step, setStep] = useState(initialActiveStep);

  return (
    <StepperContext.Provider
      value={{ initialActiveStep, steps, step, goTo: setStep }}
    >
      {children}
    </StepperContext.Provider>
  );
}
