/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Companies within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyModel
 */
export interface CompanyModel {
    /**
     * Unique identifier of Company
     * @type {string}
     * @memberof CompanyModel
     */
    systemId?: string;
    /**
     * The company name
     * @type {string}
     * @memberof CompanyModel
     */
    name: string;
    /**
     * The company registration number
     * @type {string}
     * @memberof CompanyModel
     */
    registrationNumber?: string;
    /**
     * The Tax registration number
     * @type {string}
     * @memberof CompanyModel
     */
    taxRegistrationNumber?: string;
    /**
     * The currency used
     * @type {string}
     * @memberof CompanyModel
     */
    currency: string;
    /**
     * The CAIS Source Code
     * @type {string}
     * @memberof CompanyModel
     */
    caisSourceCode?: string;
    /**
     * The Tax Code Description
     * @type {string}
     * @memberof CompanyModel
     */
    taxCodeDescription?: string;
    /**
     * The tax Code ID
     * @type {string}
     * @memberof CompanyModel
     */
    taxCodeId: string;
    /**
     * The origination commission percentage
     * @type {number}
     * @memberof CompanyModel
     */
    originationCommissionPercentage?: number;
    /**
     * The first line of the address
     * @type {string}
     * @memberof CompanyModel
     */
    addressLine1: string;
    /**
     * The second line of the address
     * @type {string}
     * @memberof CompanyModel
     */
    addressLine2?: string;
    /**
     * The third line of the address
     * @type {string}
     * @memberof CompanyModel
     */
    addressLine3?: string;
    /**
     * The fourth line of the address
     * @type {string}
     * @memberof CompanyModel
     */
    addressLine4?: string;
    /**
     * The postcode of the address
     * @type {string}
     * @memberof CompanyModel
     */
    postcode: string;
}

/**
 * Check if a given object implements the CompanyModel interface.
 */
export function instanceOfCompanyModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "taxCodeId" in value;
    isInstance = isInstance && "addressLine1" in value;
    isInstance = isInstance && "postcode" in value;

    return isInstance;
}

export function CompanyModelFromJSON(json: any): CompanyModel {
    return CompanyModelFromJSONTyped(json, false);
}

export function CompanyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemId': !exists(json, 'systemId') ? undefined : json['systemId'],
        'name': json['name'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'taxRegistrationNumber': !exists(json, 'taxRegistrationNumber') ? undefined : json['taxRegistrationNumber'],
        'currency': json['currency'],
        'caisSourceCode': !exists(json, 'caisSourceCode') ? undefined : json['caisSourceCode'],
        'taxCodeDescription': !exists(json, 'taxCodeDescription') ? undefined : json['taxCodeDescription'],
        'taxCodeId': json['taxCodeId'],
        'originationCommissionPercentage': !exists(json, 'originationCommissionPercentage') ? undefined : json['originationCommissionPercentage'],
        'addressLine1': json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': json['postcode'],
    };
}

export function CompanyModelToJSON(value?: CompanyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'systemId': value.systemId,
        'name': value.name,
        'registrationNumber': value.registrationNumber,
        'taxRegistrationNumber': value.taxRegistrationNumber,
        'currency': value.currency,
        'caisSourceCode': value.caisSourceCode,
        'taxCodeDescription': value.taxCodeDescription,
        'taxCodeId': value.taxCodeId,
        'originationCommissionPercentage': value.originationCommissionPercentage,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
    };
}

