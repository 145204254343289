import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { PortfolioBankAccountsApi } from "generated/servicing-v2/apis";
import { PortfolioBankAccountDetailsModel } from "generated/servicing-v2/models";
import { ErrorLike } from "support/error-handler";
import { keys } from ".";

type UseGetPortfolioBankAccount = (
  portfolioBankAccountId: string,
) => UseQueryReturn<PortfolioBankAccountDetailsModel, ErrorLike>;

export const useGetPortfolioBankAccount: UseGetPortfolioBankAccount = (
  portfolioBankAccountId: string,
) => {
  const api = useApi(PortfolioBankAccountsApi, "v2");

  return useQuery(keys.detail(portfolioBankAccountId), () =>
    api.getPortfolioBankAccountDetails({
      id: portfolioBankAccountId,
    }),
  );
};
