/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Bank Accounts within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateBankAccountModel
 */
export interface CreateBankAccountModel {
    /**
     * Name of the bank account holder
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    accountHoldersName: string;
    /**
     * Name of the bank
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    bankName?: string;
    /**
     * The bank account sort code
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    sortCode?: string;
    /**
     * The bank account number
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    accountNumber?: string;
    /**
     * The international bank account number
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    iban?: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    bic?: string;
    /**
     * The first line of the address
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    addressLine1?: string;
    /**
     * The second line of the address
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    addressLine2?: string;
    /**
     * The third line of the address
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    addressLine3?: string;
    /**
     * The fourth line of the address
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    addressLine4?: string;
    /**
     * The postcode of the address
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    postcode?: string;
    /**
     * Indicator of customer or contact
     * @type {boolean}
     * @memberof CreateBankAccountModel
     */
    isCustomer: boolean;
    /**
     * Unique ID of customer/contact
     * @type {string}
     * @memberof CreateBankAccountModel
     */
    typeSystemId: string;
}

/**
 * Check if a given object implements the CreateBankAccountModel interface.
 */
export function instanceOfCreateBankAccountModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accountHoldersName" in value;
    isInstance = isInstance && "isCustomer" in value;
    isInstance = isInstance && "typeSystemId" in value;

    return isInstance;
}

export function CreateBankAccountModelFromJSON(json: any): CreateBankAccountModel {
    return CreateBankAccountModelFromJSONTyped(json, false);
}

export function CreateBankAccountModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBankAccountModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountHoldersName': json['accountHoldersName'],
        'bankName': !exists(json, 'bankName') ? undefined : json['bankName'],
        'sortCode': !exists(json, 'sortCode') ? undefined : json['sortCode'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'isCustomer': json['isCustomer'],
        'typeSystemId': json['typeSystemId'],
    };
}

export function CreateBankAccountModelToJSON(value?: CreateBankAccountModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountHoldersName': value.accountHoldersName,
        'bankName': value.bankName,
        'sortCode': value.sortCode,
        'accountNumber': value.accountNumber,
        'iban': value.iban,
        'bic': value.bic,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
        'isCustomer': value.isCustomer,
        'typeSystemId': value.typeSystemId,
    };
}

