/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VersionListItemModel
 */
export interface VersionListItemModel {
    /**
     * The version number of the template
     * @type {number}
     * @memberof VersionListItemModel
     */
    version: number;
    /**
     * A date time format
     * @type {string}
     * @memberof VersionListItemModel
     */
    createdAt?: string;
    /**
     * The name of the user who created the template
     * @type {string}
     * @memberof VersionListItemModel
     */
    createdBy?: string;
    /**
     * The comment related to a specific template version
     * @type {string}
     * @memberof VersionListItemModel
     */
    comment: string;
    /**
     * Flag denoting if the template contains a free text field
     * @type {boolean}
     * @memberof VersionListItemModel
     */
    hasFreeTextField: boolean;
}

/**
 * Check if a given object implements the VersionListItemModel interface.
 */
export function instanceOfVersionListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "hasFreeTextField" in value;

    return isInstance;
}

export function VersionListItemModelFromJSON(json: any): VersionListItemModel {
    return VersionListItemModelFromJSONTyped(json, false);
}

export function VersionListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': json['version'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'comment': json['comment'],
        'hasFreeTextField': json['hasFreeTextField'],
    };
}

export function VersionListItemModelToJSON(value?: VersionListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'comment': value.comment,
        'hasFreeTextField': value.hasFreeTextField,
    };
}

