import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { ScheduleModel } from "generated/core/models";
import { keys } from ".";

type UseListSchedule = (agreementId: number) => UseQueryReturn<ScheduleModel>;

export const useListSchedule: UseListSchedule = (agreementId: number) => {
  const api = useApi(AgreementQueryApi);

  return useQuery(keys.list(agreementId), () =>
    api.listSchedule({
      agreementId,
    }),
  );
};
