/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Customers within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactTypeModel } from './ContactTypeModel';
import {
    ContactTypeModelFromJSON,
    ContactTypeModelFromJSONTyped,
    ContactTypeModelToJSON,
} from './ContactTypeModel';

/**
 * 
 * @export
 * @interface BankAccountsListItemModel
 */
export interface BankAccountsListItemModel {
    /**
     * Name of the bank account holder
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    accountHoldersName: string;
    /**
     * Name of the bank
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    bankName?: string;
    /**
     * The bank account sort code
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    sortCode?: string;
    /**
     * The bank account number
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    accountNumber?: string;
    /**
     * The international bank account number
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    iban?: string;
    /**
     * The bank identifier code
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    bic?: string;
    /**
     * The first line of the address
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    addressLine1?: string;
    /**
     * The second line of the address
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    addressLine2?: string;
    /**
     * The third line of the address
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    addressLine3?: string;
    /**
     * The fourth line of the address
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    addressLine4?: string;
    /**
     * The postcode of the address
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    postcode?: string;
    /**
     * A read only Id
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    readonly systemId: string;
    /**
     * Name of the bank account holder
     * @type {string}
     * @memberof BankAccountsListItemModel
     */
    contactName: string;
    /**
     * Indicator of customer or contact
     * @type {boolean}
     * @memberof BankAccountsListItemModel
     */
    isCustomer: boolean;
    /**
     * Indicates if Bank Account contains mandates
     * @type {boolean}
     * @memberof BankAccountsListItemModel
     */
    hasDirectDebitMandate?: boolean;
    /**
     * 
     * @type {ContactTypeModel}
     * @memberof BankAccountsListItemModel
     */
    type?: ContactTypeModel;
}

/**
 * Check if a given object implements the BankAccountsListItemModel interface.
 */
export function instanceOfBankAccountsListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accountHoldersName" in value;
    isInstance = isInstance && "systemId" in value;
    isInstance = isInstance && "contactName" in value;
    isInstance = isInstance && "isCustomer" in value;

    return isInstance;
}

export function BankAccountsListItemModelFromJSON(json: any): BankAccountsListItemModel {
    return BankAccountsListItemModelFromJSONTyped(json, false);
}

export function BankAccountsListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankAccountsListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountHoldersName': json['accountHoldersName'],
        'bankName': !exists(json, 'bankName') ? undefined : json['bankName'],
        'sortCode': !exists(json, 'sortCode') ? undefined : json['sortCode'],
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'systemId': json['systemId'],
        'contactName': json['contactName'],
        'isCustomer': json['isCustomer'],
        'hasDirectDebitMandate': !exists(json, 'hasDirectDebitMandate') ? undefined : json['hasDirectDebitMandate'],
        'type': !exists(json, 'type') ? undefined : ContactTypeModelFromJSON(json['type']),
    };
}

export function BankAccountsListItemModelToJSON(value?: BankAccountsListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountHoldersName': value.accountHoldersName,
        'bankName': value.bankName,
        'sortCode': value.sortCode,
        'accountNumber': value.accountNumber,
        'iban': value.iban,
        'bic': value.bic,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postcode': value.postcode,
        'contactName': value.contactName,
        'isCustomer': value.isCustomer,
        'hasDirectDebitMandate': value.hasDirectDebitMandate,
        'type': ContactTypeModelToJSON(value.type),
    };
}

