/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionModel
 */
export interface TransactionModel {
    /**
     * The payment type
     * @type {string}
     * @memberof TransactionModel
     */
    type: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof TransactionModel
     */
    bankDate: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof TransactionModel
     */
    amountReceived?: string;
    /**
     * The bank ID
     * @type {number}
     * @memberof TransactionModel
     */
    bankId: number;
    /**
     * A general comment
     * @type {string}
     * @memberof TransactionModel
     */
    comment?: string;
}

/**
 * Check if a given object implements the TransactionModel interface.
 */
export function instanceOfTransactionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "bankDate" in value;
    isInstance = isInstance && "bankId" in value;

    return isInstance;
}

export function TransactionModelFromJSON(json: any): TransactionModel {
    return TransactionModelFromJSONTyped(json, false);
}

export function TransactionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'bankDate': (new Date(json['bankDate'])),
        'amountReceived': !exists(json, 'amountReceived') ? undefined : json['amountReceived'],
        'bankId': json['bankId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function TransactionModelToJSON(value?: TransactionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'bankDate': (value.bankDate.toISOString().substring(0,10)),
        'amountReceived': value.amountReceived,
        'bankId': value.bankId,
        'comment': value.comment,
    };
}

