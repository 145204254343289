import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { ApiResponse } from "generated";
import {
  AutomationCommandApi,
  CreateAutomationRequest,
} from "generated/core/apis";
import * as keys from "./keys";

const useCreateAutomation = (
  options: UseCommandOptions<ApiResponse<void>, ResponseError> = {},
) => {
  const api = useApi(AutomationCommandApi);
  const partialMutate = usePartialMutate();

  return useCommand<CreateAutomationRequest, ApiResponse<void>, ResponseError>(
    async (requestParameters) => {
      const response = await api.createAutomationRaw(requestParameters);
      return response;
    },
    {
      ...options,
      onSuccess: (response) => {
        options.onSuccess?.(response);
        partialMutate(keys.list());
      },
    },
  );
};

export { useCreateAutomation };
