import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { FinancialProductApi } from "generated/onboarding/apis";
import { FinancialProductDetailsModel } from "generated/onboarding/models";
import * as keys from "./keys";

type UseGetFinancialProductDetails = (
  financialProductId: number,
) => UseQueryReturn<FinancialProductDetailsModel>;

export const useGetFinancialProductDetails: UseGetFinancialProductDetails = (
  financialProductId: number,
) => {
  const api = useApi(FinancialProductApi);

  return useQuery(keys.detail(financialProductId), () => {
    return api.getFinancialProduct({ financialProductId });
  });
};
