/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface MarkBatchPrintedRequest {
    batchId: string;
}

export interface RequestBatchFileDownloadRequest {
    batchId: string;
}

/**
 * 
 */
export class BatchCommandApi extends runtime.BaseAPI {

    /**
     * Mark a Batch as printed
     */
    async markBatchPrintedRaw(requestParameters: MarkBatchPrintedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling markBatchPrinted.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["batches:view"]);
        }

        const response = await this.request({
            path: `/documents/batches/{batchId}/printed`.replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark a Batch as printed
     */
    async markBatchPrinted(requestParameters: MarkBatchPrintedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markBatchPrintedRaw(requestParameters, initOverrides);
    }

    /**
     * Request a Batch file download
     */
    async requestBatchFileDownloadRaw(requestParameters: RequestBatchFileDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling requestBatchFileDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/documents/batches/{batchId}/file`.replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request a Batch file download
     */
    async requestBatchFileDownload(requestParameters: RequestBatchFileDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestBatchFileDownloadRaw(requestParameters, initOverrides);
    }

}
