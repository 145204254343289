import { AlertDialog } from "@ldms/mui-sdk/templates";
import {
  Avatar,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { NoResults } from "common/components";
import { useLocale } from "common/hooks";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { MemoModel } from "generated/servicing-v2/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const truncateText = (value: string): string => {
  const maxLength = 150;

  if (value.length > maxLength) {
    return `${value.substring(0, maxLength)}...`;
  }

  return value;
};

interface CustomerOverviewMemosProps {
  memos?: MemoModel[];
}

export default function CustomerOverviewMemos({
  memos,
}: CustomerOverviewMemosProps): ReactElement {
  const locale = useLocale();
  const { t } = useTranslation("clients");
  const appConfig = useAppConfiguration();
  const [isFullContentView, setFullContentView] = useState(false);
  const [fullContentMemo, setFullContentMemo] = useState("");
  const makeViewContentHandler = (note: string) => (): void => {
    setFullContentMemo(note);
    setFullContentView(true);
  };
  const handleCloseDialog = (): void => {
    setFullContentView(false);
  };
  const getUserInitials = (name: string) => {
    return name
      .split(" ")
      .filter(Boolean)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  return (
    <>
      <List disablePadding>
        {memos?.map((memo, index) => (
          <ListItem
            key={index}
            alignItems="flex-start"
            dense
            button
            onClick={makeViewContentHandler(memo.note)}
          >
            <ListItemAvatar data-testid={`${index}-avatar`}>
              <Avatar>{getUserInitials(memo.createdBy)}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="subtitle2"
                  >
                    {memo.createdBy}
                  </Typography>
                  {memo.agreementLegacyId ? (
                    <>
                      {` - ${locale.formatDateTime(
                        new Date(memo.createdAt),
                      )} - `}
                      <Link
                        component={RouterLink}
                        to={`${appConfig.appRoutes.servicing}/agreements/${memo.agreementLegacyId}`}
                      >
                        {memo.agreementReference}
                      </Link>
                    </>
                  ) : (
                    <>{` - ${locale.formatDateTime(
                      new Date(memo.createdAt),
                    )}`}</>
                  )}
                </>
              }
              secondary={
                <Typography sx={{ wordBreak: "break-word" }}>
                  {truncateText(memo.note)}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>

      <AlertDialog
        title={t("memos.dialog_title")}
        content={fullContentMemo}
        labels={{ close: t("common:alert.close") }}
        onClose={handleCloseDialog}
        open={isFullContentView}
      />

      {(memos || []).length === 0 && <NoResults />}
    </>
  );
}
