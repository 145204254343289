/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCorrespondenceMethodModel
 */
export interface UpdateCorrespondenceMethodModel {
    /**
     * The contact method type
     * @type {string}
     * @memberof UpdateCorrespondenceMethodModel
     */
    type: string;
    /**
     * The value of the correspondence method which can be a contact number or email address
     * @type {string}
     * @memberof UpdateCorrespondenceMethodModel
     */
    detail: string;
    /**
     * The comment for the update to the correspondence method
     * @type {string}
     * @memberof UpdateCorrespondenceMethodModel
     */
    comment?: string;
}

/**
 * Check if a given object implements the UpdateCorrespondenceMethodModel interface.
 */
export function instanceOfUpdateCorrespondenceMethodModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "detail" in value;

    return isInstance;
}

export function UpdateCorrespondenceMethodModelFromJSON(json: any): UpdateCorrespondenceMethodModel {
    return UpdateCorrespondenceMethodModelFromJSONTyped(json, false);
}

export function UpdateCorrespondenceMethodModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCorrespondenceMethodModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'detail': json['detail'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function UpdateCorrespondenceMethodModelToJSON(value?: UpdateCorrespondenceMethodModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'detail': value.detail,
        'comment': value.comment,
    };
}

