/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentAllocationListItemModel
 */
export interface PaymentAllocationListItemModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof PaymentAllocationListItemModel
     */
    transactionDate?: Date;
    /**
     * The record type
     * @type {string}
     * @memberof PaymentAllocationListItemModel
     */
    recordType?: string;
    /**
     * The allocation type
     * @type {string}
     * @memberof PaymentAllocationListItemModel
     */
    allocationType?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof PaymentAllocationListItemModel
     */
    amount?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof PaymentAllocationListItemModel
     */
    unallocatedAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentAllocationListItemModel
     */
    paidIndicator?: PaymentAllocationListItemModelPaidIndicatorEnum;
}


/**
 * @export
 */
export const PaymentAllocationListItemModelPaidIndicatorEnum = {
    NotFullyAllocated: 'Not Fully Allocated',
    FullyAllocated: 'Fully Allocated',
    CashAllocated: 'Cash Allocated'
} as const;
export type PaymentAllocationListItemModelPaidIndicatorEnum = typeof PaymentAllocationListItemModelPaidIndicatorEnum[keyof typeof PaymentAllocationListItemModelPaidIndicatorEnum];


/**
 * Check if a given object implements the PaymentAllocationListItemModel interface.
 */
export function instanceOfPaymentAllocationListItemModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentAllocationListItemModelFromJSON(json: any): PaymentAllocationListItemModel {
    return PaymentAllocationListItemModelFromJSONTyped(json, false);
}

export function PaymentAllocationListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentAllocationListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionDate': !exists(json, 'transactionDate') ? undefined : (new Date(json['transactionDate'])),
        'recordType': !exists(json, 'recordType') ? undefined : json['recordType'],
        'allocationType': !exists(json, 'allocationType') ? undefined : json['allocationType'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'unallocatedAmount': !exists(json, 'unallocatedAmount') ? undefined : json['unallocatedAmount'],
        'paidIndicator': !exists(json, 'paidIndicator') ? undefined : json['paidIndicator'],
    };
}

export function PaymentAllocationListItemModelToJSON(value?: PaymentAllocationListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionDate': value.transactionDate === undefined ? undefined : (value.transactionDate.toISOString().substring(0,10)),
        'recordType': value.recordType,
        'allocationType': value.allocationType,
        'amount': value.amount,
        'unallocatedAmount': value.unallocatedAmount,
        'paidIndicator': value.paidIndicator,
    };
}

