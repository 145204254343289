/* tslint:disable */
/* eslint-disable */
/**
 * Engage Gateway (Profile)
 * API for managing Profiles within Engage Gateway
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProfileModel
 */
export interface ProfileModel {
    /**
     * The user display name
     * @type {string}
     * @memberof ProfileModel
     */
    username: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileModel
     */
    privileges: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileModel
     */
    roles: Array<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof ProfileModel
     */
    permissions: Set<string>;
}

/**
 * Check if a given object implements the ProfileModel interface.
 */
export function instanceOfProfileModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "privileges" in value;
    isInstance = isInstance && "roles" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function ProfileModelFromJSON(json: any): ProfileModel {
    return ProfileModelFromJSONTyped(json, false);
}

export function ProfileModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'privileges': json['privileges'],
        'roles': json['roles'],
        'permissions': json['permissions'],
    };
}

export function ProfileModelToJSON(value?: ProfileModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'privileges': value.privileges,
        'roles': value.roles,
        'permissions': Array.from(value.permissions as Set<any>),
    };
}

