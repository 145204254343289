import { ConfirmationDialog, FormDialog } from "@ldms/mui-sdk/templates";
import { Info } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { useSubmitValuations } from "api/assets/valuations/submitValuations";
import { FileUploader, UploadedFileBanner } from "common/components";
import { useConfirm, useYupResolver } from "common/hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, Resolver } from "support/react-hook-form";

interface AddAssetValuationsContainerProps {
  assetId: number;
  overwrite: boolean;
  isModifiable: boolean;
}

export interface AddValuationsFieldValues {
  valuations: File;
}

const useAddAssetValuationsResolver =
  (): Resolver<AddValuationsFieldValues> => {
    const { t } = useTranslation("assets");
    const maxFileSize = 31457280; //maxFileSize = 30MB

    return useYupResolver<AddValuationsFieldValues>((yup) =>
      yup.object().shape({
        valuations: yup
          .mixed()
          .required(t("valuations.add.required"))
          .test(
            "filesize",
            t("valuations.add.file_size_upload_error"),
            (value: File) => {
              return value && value.size <= maxFileSize;
            },
          )
          .test(
            "text/csv",
            t("valuations.add.file_type_upload_error"),
            (value: File) => {
              return value && value.type === "text/csv";
            },
          ),
      }),
    );
  };

export default function AddAssetValuationsContainer({
  assetId,
  overwrite,
  isModifiable,
}: AddAssetValuationsContainerProps) {
  const { t } = useTranslation("assets");
  const confirm = useConfirm();
  const [openAddAssetValuationsDialog, setOpenAddAssetValuationsDialog] =
    useState(false);

  const openDialog = (): void => {
    setOpenAddAssetValuationsDialog(true);
  };

  const closeDialog = (): void => {
    setOpenAddAssetValuationsDialog(false);
  };

  const submitValuations = useSubmitValuations(assetId, {
    onSuccess: closeDialog,
  });

  const closeDialogHandler = (): void => {
    submitValuations.reset();
    closeDialog();
  };

  const onSubmit = async (
    formValues: AddValuationsFieldValues,
  ): Promise<void> => {
    if (overwrite) {
      return confirm.handlePrompt(async (): Promise<void> => {
        await submitValuations.command(formValues.valuations);
      });
    }
    return submitValuations.command(formValues.valuations);
  };

  const resolver = useAddAssetValuationsResolver();

  return (
    <>
      {isModifiable && (
        <Button variant="contained" onClick={openDialog}>
          {t("valuations.add.submit_valuations_button")}
        </Button>
      )}

      <FormDialog
        title={t("valuations.add.heading_label")}
        onSubmit={onSubmit}
        onClose={closeDialogHandler}
        open={openAddAssetValuationsDialog}
        resolver={resolver}
      >
        {(form) => {
          const fileWatch = form.watch("valuations");
          const onClearFile = () => {
            form.reset();
            submitValuations.reset();
          };

          return (
            <>
              {fileWatch ? (
                <>
                  <UploadedFileBanner
                    onFileDelete={onClearFile}
                    fileName={fileWatch.name}
                    buttonText={t("valuations.add.delete_document_button")}
                  />
                  {submitValuations.error && (
                    <Box>
                      <Typography
                        color="error"
                        role="alert"
                        aria-label={submitValuations.error.message}
                        marginTop={2}
                      >
                        {submitValuations.error.message}
                      </Typography>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Controller
                    name="valuations"
                    render={({ field }) => (
                      <FileUploader
                        onInput={field.onChange}
                        loading={false}
                        prompt={t("valuations.add.upload_file_text")}
                      />
                    )}
                  />
                  <Alert severity="info" icon={<Info />}>
                    <AlertTitle aria-label="file-format-criteria">
                      {t("valuations.file_criteria.title")}
                    </AlertTitle>
                    <ul>
                      {[
                        t("valuations.file_criteria.mandatory_headers"),
                        t("valuations.file_criteria.date_format"),
                        t("valuations.file_criteria.value_format"),
                        t("valuations.file_criteria.completed_by_format"),
                        t("valuations.file_criteria.source_format"),
                      ].map((content) => (
                        <Typography
                          component="li"
                          variant="body2"
                          key={content}
                        >
                          {content}
                        </Typography>
                      ))}
                    </ul>
                  </Alert>
                </>
              )}
              {form.formState.errors.valuations && (
                <Typography
                  color="error"
                  role="alert"
                  aria-label={form.formState.errors.valuations.message}
                  marginTop={2}
                >
                  {form.formState.errors.valuations.message}
                </Typography>
              )}
            </>
          );
        }}
      </FormDialog>
      <ConfirmationDialog
        content={t("valuations.overwrite.confirmation_message")}
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("valuations.overwrite.confirmation_title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />
    </>
  );
}
