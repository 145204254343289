/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerModel
 */
export interface CustomerModel {
    /**
     * Customer Salutation
     * @type {string}
     * @memberof CustomerModel
     */
    title: string;
    /**
     * Customer's First Name
     * @type {string}
     * @memberof CustomerModel
     */
    firstName: string;
    /**
     * Customer's Middle Name
     * @type {string}
     * @memberof CustomerModel
     */
    middleName?: string;
    /**
     * Customer's Last Name
     * @type {string}
     * @memberof CustomerModel
     */
    lastName: string;
    /**
     * A format for dates
     * @type {Date}
     * @memberof CustomerModel
     */
    dateOfBirth?: Date;
    /**
     * National Identification Number
     * @type {string}
     * @memberof CustomerModel
     */
    nationalIdentificationNumber?: string;
}

/**
 * Check if a given object implements the CustomerModel interface.
 */
export function instanceOfCustomerModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;

    return isInstance;
}

export function CustomerModelFromJSON(json: any): CustomerModel {
    return CustomerModelFromJSONTyped(json, false);
}

export function CustomerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'firstName': json['firstName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'lastName': json['lastName'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'nationalIdentificationNumber': !exists(json, 'nationalIdentificationNumber') ? undefined : json['nationalIdentificationNumber'],
    };
}

export function CustomerModelToJSON(value?: CustomerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'firstName': value.firstName,
        'middleName': value.middleName,
        'lastName': value.lastName,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substring(0,10)),
        'nationalIdentificationNumber': value.nationalIdentificationNumber,
    };
}

