import { useSession } from "@ldms/mui-sdk/bootstrap";
import { Box } from "@mui/material";
import UnexpectedError from "common/components/UnexpectedError";
import SidebarNavigation from "common/containers/SidebarNavigation";
import SidebarLayout from "common/layouts/SidebarLayout";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

function FinanceLayout() {
  const { t } = useTranslation("finance");
  const session = useSession();

  const navigationMenu = (
    [
      [
        t("finance_layout.navigation_links.direct_debit_submissions"),
        "./direct-debit-submissions",
        session.hasPermission("finance:dd-submissions:view"),
      ],
      [
        t("finance_layout.navigation_links.financial_postings"),
        "./filter-financial-postings",
        session.hasPermission("finance:asset-financial-postings:view"),
      ],
    ] as [string, string, boolean][]
  ).map(([label, to, display = true]) => ({
    label,
    to,
    display,
  }));

  return (
    <SidebarLayout
      title={t("title")}
      sidebar={<SidebarNavigation menu={navigationMenu} />}
    >
      <Box component="main" height="100%">
        <ErrorBoundary FallbackComponent={UnexpectedError}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </SidebarLayout>
  );
}

export default withTranslationLoader("finance")(FinanceLayout);
