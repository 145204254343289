import { QueryKey } from "api";
import { keys } from "api/agreements";

export const all = (agreementId: number): QueryKey => [
  ...keys.detail(agreementId),
  "attachments",
];

export const list = (
  agreementId: number,
  params?: { from?: Date; to?: Date; type?: string },
): QueryKey => [
  ...all(agreementId),
  "list",
  ...(params ? [params?.from, params?.to, params?.type] : []),
];
