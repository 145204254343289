/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgreementFeeModel
 */
export interface AgreementFeeModel {
    /**
     * The name of the fee
     * @type {string}
     * @memberof AgreementFeeModel
     */
    name: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof AgreementFeeModel
     */
    amount: string;
}

/**
 * Check if a given object implements the AgreementFeeModel interface.
 */
export function instanceOfAgreementFeeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function AgreementFeeModelFromJSON(json: any): AgreementFeeModel {
    return AgreementFeeModelFromJSONTyped(json, false);
}

export function AgreementFeeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgreementFeeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'amount': json['amount'],
    };
}

export function AgreementFeeModelToJSON(value?: AgreementFeeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'amount': value.amount,
    };
}

