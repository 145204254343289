import { usePartialMutate, useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { ThirdPartyCommandApi } from "generated/core/apis";
import { UpdateThirdPartyModel } from "generated/core/models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";
import { list } from "./keys";

interface UseUpdateThirdPartyReturn {
  command: (thirdParty: UpdateThirdPartyModel) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
  reset: () => void;
}

export const useUpdateThirdParty = (
  thirdPartyId: number,
  options: {
    onSuccess?: () => void;
  } = {},
): UseUpdateThirdPartyReturn => {
  const api = useApi(ThirdPartyCommandApi);
  const { t } = useTranslation("third-parties");
  const error = useResponseError([
    ["duplicate_name", t("validation.duplicate_name_validation_message")],
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const mutate = usePartialMutate();

  const command = async (thirdParty: UpdateThirdPartyModel): Promise<void> => {
    try {
      setIsLoading(true);
      await api.updateThirdParty({
        thirdPartyId,
        updateThirdPartyModel: thirdParty,
      });
      options.onSuccess?.();
      mutate(list({ query: "" }));
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
    reset: error.reset,
  };
};
