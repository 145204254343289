import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useReportDownload } from "apps/reporting/hooks";
import { Loader } from "common/components";
import { useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { ReportQueryApi } from "generated/core/apis";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import startOfYesterdayUTC from "support/form-helpers/start-of-yesterday-utc";

export interface ManufacturerSubsidyInvoiceReportFormValues {
  fromDate: string;
  toDate: string;
}

interface ManufacturerSubsidyInvoiceReportFormProps {
  onSubmit: (data: ManufacturerSubsidyInvoiceReportFormValues) => void;
  onClose: () => void;
  error?: string;
}

function ManufacturerSubsidyInvoiceReportForm({
  onSubmit,
  onClose,
  error,
}: ManufacturerSubsidyInvoiceReportFormProps): ReactElement {
  const { t } = useTranslation("reporting");

  const fromDateLabel = t("report_dialog.from_date_label");
  const toDateLabel = t("report_dialog.to_date_label");

  const resolver = useYupResolver<ManufacturerSubsidyInvoiceReportFormValues>(
    (yup) =>
      yup.object().shape({
        fromDate: yup
          .date()
          .localDate()
          .isValidDate(fromDateLabel)
          .isNotFuture(fromDateLabel)
          .isRequired(fromDateLabel),
        toDate: yup
          .date()
          .localDate()
          .isValidDate(toDateLabel)
          .isNotFuture(toDateLabel)
          .isRequired(toDateLabel),
      }),
  );

  const form = useForm<ManufacturerSubsidyInvoiceReportFormValues>({
    defaultValues: {
      fromDate: startOfYesterdayUTC(),
      toDate: startOfYesterdayUTC(),
    },
    resolver,
  });

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      aria-label={t("report_dialog.manufacturer_subsidy.title")}
      noValidate
    >
      <DialogTitle id="manufacturer_subsidy_invoice-report-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {t("report_dialog.manufacturer_subsidy.title")}
          </Box>
          <Box>
            <IconButton
              aria-label={t("common:close")}
              onClick={onClose}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TextField
          {...form.register("fromDate")}
          helperText={form.formState.errors.fromDate?.message}
          error={Boolean(form.formState.errors.fromDate)}
          label={t("report_dialog.from_date_label")}
          type="date"
        />

        <TextField
          {...form.register("toDate")}
          helperText={form.formState.errors.toDate?.message}
          error={Boolean(form.formState.errors.toDate)}
          label={t("report_dialog.to_date_label")}
          type="date"
        />
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          type="submit"
          disabled={form.formState.isSubmitting}
          variant="contained"
          color="primary"
        >
          {t("report_dialog.generate_button")}
        </Button>
      </DialogActions>
    </form>
  );
}

interface ManufacturerSubsidyInvoiceReportContainerProps {
  onClose(): void;
  open: boolean;
}

export default function ManufacturerSubsidyInvoiceReportContainer({
  onClose,
  open,
}: ManufacturerSubsidyInvoiceReportContainerProps): ReactElement {
  const reportApi = useApi(ReportQueryApi);

  const manufacturerSubsidyInvoiceReport = useReportDownload(
    (data: ManufacturerSubsidyInvoiceReportFormValues) =>
      reportApi.manufacturerSubsidyInvoicesRaw({
        from: new Date(data.fromDate),
        to: new Date(data.toDate),
      }),
    {
      onSuccess: onClose,
    },
  );

  const onSubmit = async (
    data: ManufacturerSubsidyInvoiceReportFormValues,
  ): Promise<void> => manufacturerSubsidyInvoiceReport.download(data);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="manufacturer_subsidy_invoice-report-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <Loader
        ready={!manufacturerSubsidyInvoiceReport.isLoading}
        render={(): ReactElement => {
          return (
            <ManufacturerSubsidyInvoiceReportForm
              onSubmit={onSubmit}
              onClose={onClose}
              error={manufacturerSubsidyInvoiceReport.error?.message}
            />
          );
        }}
      />
    </Dialog>
  );
}
