import { keys } from "api/agreements";
import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import { UpdateSubsidyDateModel } from "generated/core/models";
import { useState } from "react";
import errorHandler from "support/error-handler";
import { useSWRConfig } from "swr";

interface UseUpdateSubsidyDateReturn {
  command: (subsidyDate: UpdateSubsidyDateModel) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
  resetError(): void;
}

export const useUpdateSubsidyDate = (
  agreementId: number,
  options: {
    onSuccess?: () => void;
  } = {},
): UseUpdateSubsidyDateReturn => {
  const api = useApi(AgreementCommandApi);
  const swr = useSWRConfig();
  const error = useResponseError();
  const [isLoading, setIsLoading] = useState(false);

  const command = async (
    subsidyDate: UpdateSubsidyDateModel,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      await api.updateSubsidyDate({
        agreementId,
        updateSubsidyDateModel: subsidyDate,
      });
      options.onSuccess?.();
      swr.mutate(keys.detail(agreementId));
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
    resetError: error.reset,
  };
};
