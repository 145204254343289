import { QueryKey } from "api";

export const all = (): QueryKey => ["secondary-rentals"];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (secondaryRentalId: number): QueryKey => [
  ...details(),
  secondaryRentalId,
];
