import {
  Grid,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export type Template = {
  id: string;
  name: string;
  lastUpdatedAt?: string;
  lastUpdatedBy?: string;
  version: number;
};

interface TemplateListProps {
  data?: Template[];
  loading: boolean;
  templateUrl: string;
}

export default function TemplateList({
  data,
  loading,
  templateUrl,
}: Readonly<TemplateListProps>): ReactElement {
  const { t } = useTranslation("documents");
  const locale = useLocale();

  const tableHeaders: string[] = [
    t("template_list.name"),
    t("template_list.last_updated"),
    t("template_list.version"),
  ];

  return (
    <>
      <Table size="small" aria-label={t("templates_table_label")} stickyHeader>
        <LoadableTableHead headings={tableHeaders} loading={loading} />
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.id} hover>
              <TableCell
                component="th"
                padding="none"
                sx={{ width: "20%" }}
                scope="row"
              >
                <ListItemButton
                  dense
                  component={RouterLink}
                  to={`${templateUrl}/${item.id}`}
                >
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{ color: "primary" }}
                  />
                </ListItemButton>
              </TableCell>
              <TableCell>
                <Grid spacing={4} container direction="row" alignItems="center">
                  <Grid item>
                    {item.lastUpdatedAt
                      ? locale
                          .formatDateTime(new Date(item.lastUpdatedAt))
                          .concat(` - ${item.lastUpdatedBy}`)
                      : "-"}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                {item.version === 0
                  ? t("template_versions.base_version")
                  : item.version}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {(data ?? []).length === 0 && <NoResults />}
    </>
  );
}
