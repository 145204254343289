/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';
import type { CompanySizeModel } from './CompanySizeModel';
import {
    CompanySizeModelFromJSON,
    CompanySizeModelFromJSONTyped,
    CompanySizeModelToJSON,
} from './CompanySizeModel';
import type { ContactNameModel } from './ContactNameModel';
import {
    ContactNameModelFromJSON,
    ContactNameModelFromJSONTyped,
    ContactNameModelToJSON,
} from './ContactNameModel';

/**
 * 
 * @export
 * @interface ContactListItemModel
 */
export interface ContactListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof ContactListItemModel
     */
    readonly id: number;
    /**
     * The client type
     * @type {string}
     * @memberof ContactListItemModel
     */
    type: string;
    /**
     * The company type
     * @type {string}
     * @memberof ContactListItemModel
     */
    companyType?: string;
    /**
     * The company registration Number
     * @type {string}
     * @memberof ContactListItemModel
     */
    companyRegistrationNumber?: string;
    /**
     * The vat registration Number
     * @type {string}
     * @memberof ContactListItemModel
     */
    vatRegistrationNumber?: string;
    /**
     * 
     * @type {ContactNameModel}
     * @memberof ContactListItemModel
     */
    name: ContactNameModel;
    /**
     * 
     * @type {AddressModel}
     * @memberof ContactListItemModel
     */
    address?: AddressModel;
    /**
     * Indicates whether the contact receives invoices by email
     * @type {boolean}
     * @memberof ContactListItemModel
     */
    invoiceByEmail?: boolean;
    /**
     * Indicates whether the contact receives system generated messages
     * @type {boolean}
     * @memberof ContactListItemModel
     */
    receiveSystemGeneratedCorrespondence?: boolean;
    /**
     * A format for dates
     * @type {Date}
     * @memberof ContactListItemModel
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {CompanySizeModel}
     * @memberof ContactListItemModel
     */
    companySize?: CompanySizeModel;
    /**
     * The national identification number
     * @type {string}
     * @memberof ContactListItemModel
     */
    nationalIdentificationNumber?: string;
    /**
     * The sector of economic activity
     * @type {string}
     * @memberof ContactListItemModel
     */
    sectorOfEconomicActivity?: string;
    /**
     * The company sector
     * @type {string}
     * @memberof ContactListItemModel
     */
    companySector?: string;
    /**
     * The customer identification number
     * @type {string}
     * @memberof ContactListItemModel
     */
    customerIdentificationNumber?: string;
    /**
     * A read only Id
     * @type {string}
     * @memberof ContactListItemModel
     */
    readonly systemId: string;
}

/**
 * Check if a given object implements the ContactListItemModel interface.
 */
export function instanceOfContactListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "systemId" in value;

    return isInstance;
}

export function ContactListItemModelFromJSON(json: any): ContactListItemModel {
    return ContactListItemModelFromJSONTyped(json, false);
}

export function ContactListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'companyType': !exists(json, 'companyType') ? undefined : json['companyType'],
        'companyRegistrationNumber': !exists(json, 'companyRegistrationNumber') ? undefined : json['companyRegistrationNumber'],
        'vatRegistrationNumber': !exists(json, 'vatRegistrationNumber') ? undefined : json['vatRegistrationNumber'],
        'name': ContactNameModelFromJSON(json['name']),
        'address': !exists(json, 'address') ? undefined : AddressModelFromJSON(json['address']),
        'invoiceByEmail': !exists(json, 'invoiceByEmail') ? undefined : json['invoiceByEmail'],
        'receiveSystemGeneratedCorrespondence': !exists(json, 'receiveSystemGeneratedCorrespondence') ? undefined : json['receiveSystemGeneratedCorrespondence'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'companySize': !exists(json, 'companySize') ? undefined : CompanySizeModelFromJSON(json['companySize']),
        'nationalIdentificationNumber': !exists(json, 'nationalIdentificationNumber') ? undefined : json['nationalIdentificationNumber'],
        'sectorOfEconomicActivity': !exists(json, 'sectorOfEconomicActivity') ? undefined : json['sectorOfEconomicActivity'],
        'companySector': !exists(json, 'companySector') ? undefined : json['companySector'],
        'customerIdentificationNumber': !exists(json, 'customerIdentificationNumber') ? undefined : json['customerIdentificationNumber'],
        'systemId': json['systemId'],
    };
}

export function ContactListItemModelToJSON(value?: ContactListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'companyType': value.companyType,
        'companyRegistrationNumber': value.companyRegistrationNumber,
        'vatRegistrationNumber': value.vatRegistrationNumber,
        'name': ContactNameModelToJSON(value.name),
        'address': AddressModelToJSON(value.address),
        'invoiceByEmail': value.invoiceByEmail,
        'receiveSystemGeneratedCorrespondence': value.receiveSystemGeneratedCorrespondence,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substring(0,10)),
        'companySize': CompanySizeModelToJSON(value.companySize),
        'nationalIdentificationNumber': value.nationalIdentificationNumber,
        'sectorOfEconomicActivity': value.sectorOfEconomicActivity,
        'companySector': value.companySector,
        'customerIdentificationNumber': value.customerIdentificationNumber,
    };
}

