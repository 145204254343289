import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { CustomersApi } from "generated/servicing-v2/apis";
import { CustomerModel } from "generated/servicing-v2/models";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

export const useUpdateCustomer = (
  customerId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(CustomersApi, "v2");
  const swr = useSWRConfig();

  return useCommand(
    (customer: CustomerModel) =>
      api.updateCustomer({
        customerModel: customer,
        id: customerId,
      }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        swr.mutate(keys.detail(customerId));
        swr.mutate(keys.list());
      },
    },
  );
};
