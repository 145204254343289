import { Loader } from "@ldms/mui-sdk/templates";
import {
  Alert,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { useGetRole } from "api/roles";
import { useAssociatePermission } from "api/roles/permissions/associatePermission";
import { useDisassociatePermission } from "api/roles/permissions/disassociatePermission";
import { QueryError } from "common/components";
import {
  DomainModel,
  PermissionModel,
  RoleDetailsModel,
} from "generated/admin/models";
import { Fragment, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useForm } from "support/react-hook-form";

const TabPanel = ({
  children,
  value,
  index,
}: {
  children: React.ReactNode;
  value: number;
  index: number;
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`permissions-tabpanel-${index}`}
      aria-labelledby={`permissions-tab-${index}`}
    >
      {value === index && children}
    </Box>
  );
};

const EditPermissionContainer = ({
  roleDetails,
  defaultValues,
  roleId,
}: {
  roleDetails: RoleDetailsModel;
  defaultValues: { [key: string]: boolean };
  roleId: number;
}) => {
  const { t } = useTranslation("roles");
  const associatePermission = useAssociatePermission(roleId);
  const disassociatePermission = useDisassociatePermission(roleId);

  const form = useForm<{ [key: string]: boolean }>({
    defaultValues,
  });

  const [value, setValue] = useState(0);
  const [permissionToUpdate, setPermissionToUpdate] = useState<string>("");

  const navigateToSelector = (selector: string) => {
    const targetElement = document.querySelector<HTMLElement>(`#${selector}`);

    if (targetElement) {
      const targetPosition = targetElement.offsetTop + 130;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  const isRoleUpdateable = roleDetails.updatable;

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean,
    permission: PermissionModel,
    permissionSet: DomainModel,
    form: UseFormReturn,
  ) => {
    setPermissionToUpdate(`${permissionSet.name} ${permission.title}`);
    if (value) {
      form.setValue(
        `${permissionSet.name} ${permission.title}`,
        event.target.checked,
      );
      associatePermission.execute({
        permissionId: permission.id,
      });
    } else {
      form.setValue(
        `${permissionSet.name} ${permission.title}`,
        event.target.checked,
      );

      disassociatePermission.execute({
        permissionId: permission.id,
      });
    }
  };

  return (
    <>
      {!roleDetails.updatable && (
        <Alert severity="info" sx={{ mb: 1.6 }}>
          <Typography variant="body1">
            {t("permissions.locked_role_warning")}
          </Typography>
        </Alert>
      )}
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
        }}
      >
        {roleDetails.applications?.map((application, i) => (
          <TabPanel key={application.name} value={value} index={i}>
            <Box
              display="grid"
              gridTemplateColumns="240px 1fr"
              sx={{ height: "100%", overflow: "hidden" }}
            >
              <Box borderRight={1} borderColor="divider">
                <Box paddingX={2} paddingY={1}>
                  <Box padding={2}>
                    <Typography fontWeight={500}>
                      {t("permissions.applications_title")}
                    </Typography>
                  </Box>
                  <List aria-label={t("permissions.applications_list")}>
                    {roleDetails.applications?.map((application, i) => (
                      <Fragment key={application.name}>
                        <ListItemButton
                          dense
                          onClick={() => setValue(i)}
                          selected={value === i}
                          key={application.name}
                        >
                          <ListItemText
                            sx={{ textTransform: "capitalize" }}
                            primary={application.name}
                          />
                        </ListItemButton>
                        {value === i && application.domains.length > 1 && (
                          <List>
                            {application.domains.map((permissionSet) => (
                              <ListItemButton
                                dense
                                key={permissionSet.name}
                                onClick={() =>
                                  navigateToSelector(permissionSet.name)
                                }
                              >
                                <ListItemText
                                  sx={{ paddingLeft: 2 }}
                                  secondary={permissionSet.name}
                                />
                              </ListItemButton>
                            ))}
                          </List>
                        )}
                      </Fragment>
                    ))}
                  </List>
                </Box>
              </Box>

              <Box paddingBottom={6}>
                <Box marginTop={4} paddingX={3}>
                  <Typography
                    fontWeight={500}
                    fontSize={21}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {application.name}
                  </Typography>
                </Box>
                <Box paddingX={1}>
                  <List sx={{ position: "relative" }}>
                    {application.domains.map((permissionSet) => (
                      <Fragment key={permissionSet.name}>
                        <ListItem
                          id={permissionSet.name}
                          component={Box}
                          display="flex"
                          alignItems="center"
                          gap={2}
                          marginTop={3}
                        >
                          <Typography fontWeight={500} fontSize={18}>
                            {permissionSet.name}
                          </Typography>
                        </ListItem>
                        <List
                          aria-label={`${permissionSet.name} permission toggles`}
                        >
                          {permissionSet.permissions.map((permission) => (
                            <Fragment key={permission.id}>
                              <Divider variant="fullWidth" />
                              <ListItem dense>
                                <ListItemIcon>
                                  <Controller
                                    name={`${permissionSet.name} ${permission.title}`}
                                    control={form.control}
                                    render={({ field }) => {
                                      return (
                                        <Switch
                                          size="small"
                                          {...field}
                                          aria-label={`${permissionSet.name} ${permission.title}`}
                                          checked={field.value}
                                          disabled={
                                            !isRoleUpdateable ||
                                            (`${permissionSet.name} ${permission.title}` ===
                                              permissionToUpdate &&
                                              (associatePermission.isExecuting ||
                                                disassociatePermission.isExecuting))
                                          }
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>,
                                            value,
                                          ) =>
                                            handleOnChange(
                                              event,
                                              value,
                                              permission,
                                              permissionSet,
                                              form,
                                            )
                                          }
                                        />
                                      );
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={permission.title}
                                  primaryTypographyProps={{
                                    id: String(permission.id),
                                  }}
                                  secondary={permission.description}
                                />
                              </ListItem>
                            </Fragment>
                          ))}
                        </List>
                      </Fragment>
                    ))}
                  </List>
                </Box>
              </Box>
            </Box>
          </TabPanel>
        ))}
      </Paper>
    </>
  );
};

const EditRolePermissionsContainer = ({ roleId }: { roleId: number }) => {
  const roleDetails = useGetRole({ id: Number(roleId) });

  const defaultValues = roleDetails.data?.applications
    ?.flatMap((category) =>
      category.domains.flatMap((domain) =>
        domain.permissions.map((permission) => ({
          ...permission,
          domainName: domain.name,
        })),
      ),
    )
    ?.reduce((prev, current) => {
      const key = `${current.domainName} ${current.title}`;
      return {
        ...prev,
        [key]: current.enabled,
      };
    }, {});

  return (
    <Loader
      ready={Boolean(roleDetails.data ?? roleDetails.error)}
      render={() => {
        if (!roleDetails.data || roleDetails.error || !defaultValues) {
          return <QueryError onRetry={roleDetails.refetch} />;
        }

        return (
          <EditPermissionContainer
            defaultValues={defaultValues}
            roleDetails={roleDetails.data}
            roleId={roleId}
          />
        );
      }}
    />
  );
};

export default EditRolePermissionsContainer;
