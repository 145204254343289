import { AppNavigationList } from "@ldms/mui-sdk/components";
import { useTranslation } from "react-i18next";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();
  const { t } = useTranslation("start");

  return (
    <AppNavigationList
      label={t("primary_navigation")}
      pathname={location.pathname}
    >
      <AppNavigationList.ItemButton
        LinkComponent={ReactRouterLink}
        to="/tasks"
        aria-label={t("navigation.tasks_link")}
      >
        {t("navigation.tasks_link")}
      </AppNavigationList.ItemButton>
    </AppNavigationList>
  );
};

export default Navigation;
