/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterestCalculationRatesModel
 */
export interface InterestCalculationRatesModel {
    /**
     * The lender rate percentage
     * @type {number}
     * @memberof InterestCalculationRatesModel
     */
    b2bFundingRate?: number;
    /**
     * The rate to broker percentage
     * @type {number}
     * @memberof InterestCalculationRatesModel
     */
    rateToBroker?: number;
    /**
     * The customer interest rate percentage
     * @type {number}
     * @memberof InterestCalculationRatesModel
     */
    customerInterestRate: number;
}

/**
 * Check if a given object implements the InterestCalculationRatesModel interface.
 */
export function instanceOfInterestCalculationRatesModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerInterestRate" in value;

    return isInstance;
}

export function InterestCalculationRatesModelFromJSON(json: any): InterestCalculationRatesModel {
    return InterestCalculationRatesModelFromJSONTyped(json, false);
}

export function InterestCalculationRatesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterestCalculationRatesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'b2bFundingRate': !exists(json, 'b2bFundingRate') ? undefined : json['b2bFundingRate'],
        'rateToBroker': !exists(json, 'rateToBroker') ? undefined : json['rateToBroker'],
        'customerInterestRate': json['customerInterestRate'],
    };
}

export function InterestCalculationRatesModelToJSON(value?: InterestCalculationRatesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'b2bFundingRate': value.b2bFundingRate,
        'rateToBroker': value.rateToBroker,
        'customerInterestRate': value.customerInterestRate,
    };
}

