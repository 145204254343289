/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Agreements within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentTypeModel } from './PaymentTypeModel';
import {
    PaymentTypeModelFromJSON,
    PaymentTypeModelFromJSONTyped,
    PaymentTypeModelToJSON,
} from './PaymentTypeModel';

/**
 * 
 * @export
 * @interface ListPaymentInstructionsModel
 */
export interface ListPaymentInstructionsModel {
    /**
     * Unique ID of customer/contact
     * @type {string}
     * @memberof ListPaymentInstructionsModel
     */
    bankSystemId: string;
    /**
     * 
     * @type {PaymentTypeModel}
     * @memberof ListPaymentInstructionsModel
     */
    type: PaymentTypeModel;
    /**
     * A format for dates
     * @type {Date}
     * @memberof ListPaymentInstructionsModel
     */
    startDate: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof ListPaymentInstructionsModel
     */
    endDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof ListPaymentInstructionsModel
     */
    signatureDate: Date;
    /**
     * Amount to be paid
     * @type {number}
     * @memberof ListPaymentInstructionsModel
     */
    amount: number;
    /**
     * Day of month 1 to 31
     * @type {number}
     * @memberof ListPaymentInstructionsModel
     */
    dayOfMonth: number;
    /**
     * The unique system identifier for a Payment Instruction
     * @type {string}
     * @memberof ListPaymentInstructionsModel
     */
    id: string;
    /**
     * Indicator for active
     * @type {boolean}
     * @memberof ListPaymentInstructionsModel
     */
    isActive: boolean;
    /**
     * Payee name
     * @type {string}
     * @memberof ListPaymentInstructionsModel
     */
    payeeName: string;
    /**
     * The payment method
     * @type {string}
     * @memberof ListPaymentInstructionsModel
     */
    method: string;
}

/**
 * Check if a given object implements the ListPaymentInstructionsModel interface.
 */
export function instanceOfListPaymentInstructionsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bankSystemId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "signatureDate" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "dayOfMonth" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "payeeName" in value;
    isInstance = isInstance && "method" in value;

    return isInstance;
}

export function ListPaymentInstructionsModelFromJSON(json: any): ListPaymentInstructionsModel {
    return ListPaymentInstructionsModelFromJSONTyped(json, false);
}

export function ListPaymentInstructionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPaymentInstructionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankSystemId': json['bankSystemId'],
        'type': PaymentTypeModelFromJSON(json['type']),
        'startDate': (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'signatureDate': (new Date(json['signatureDate'])),
        'amount': json['amount'],
        'dayOfMonth': json['dayOfMonth'],
        'id': json['id'],
        'isActive': json['isActive'],
        'payeeName': json['payeeName'],
        'method': json['method'],
    };
}

export function ListPaymentInstructionsModelToJSON(value?: ListPaymentInstructionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankSystemId': value.bankSystemId,
        'type': PaymentTypeModelToJSON(value.type),
        'startDate': (value.startDate.toISOString().substring(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substring(0,10)),
        'signatureDate': (value.signatureDate.toISOString().substring(0,10)),
        'amount': value.amount,
        'dayOfMonth': value.dayOfMonth,
        'id': value.id,
        'isActive': value.isActive,
        'payeeName': value.payeeName,
        'method': value.method,
    };
}

