/* tslint:disable */
/* eslint-disable */
/**
 * Engage Admin
 * API for managing users within the Engage Admin application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserStatusModel
 */
export interface UserStatusModel {
    /**
     * Set the user status to active or inactive
     * @type {boolean}
     * @memberof UserStatusModel
     */
    active: boolean;
}

/**
 * Check if a given object implements the UserStatusModel interface.
 */
export function instanceOfUserStatusModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function UserStatusModelFromJSON(json: any): UserStatusModel {
    return UserStatusModelFromJSONTyped(json, false);
}

export function UserStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatusModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': json['active'],
    };
}

export function UserStatusModelToJSON(value?: UserStatusModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
    };
}

