import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useListAssetTypes } from "api/assets/types/listAssetTypes";
import { useGetPortfolio } from "api/portfolios";
import { Loader } from "common/components";
import Surface from "common/components/Surface";
import { useLocale } from "common/hooks";
import {
  CompanyTypeModel,
  OnboardingSummaryModel,
  PaymentStructureModel,
  ProductTypeModel,
  SeasonalPaymentStructureModel,
  TermModel,
} from "generated/onboarding/models";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface OnboardingLayoutProps {
  onboardingSummary: OnboardingSummaryModel;
  product: ProductTypeModel;
  portfolioId: number;
  gridColumns?: number;
}

const getProductTranslation = (product: ProductTypeModel): string => {
  switch (product) {
    case ProductTypeModel.FixedRateHirePurchase:
      return "onboarding_summary.hire_purchase";
    case ProductTypeModel.FixedRateFinanceLease:
      return "onboarding_summary.finance_lease";
    case ProductTypeModel.FixedRateOperatingLease:
      return "onboarding_summary.operating_lease";
    default:
      return "onboarding_summary.commercial_loan";
  }
};

interface ProfileSummaryModel {
  totalCashPriceNet: string;
  interestRate: number;
  totalCostOfCredit: string;
  totalBalancePayable: string;
}

interface ProfileSummaryDescriptionListProps {
  product: ProductTypeModel;
  profileSummary: ProfileSummaryModel;
}

interface DescriptionListItem {
  label: string;
  value: string;
  assignedTo?: ProductTypeModel[];
  leaseType?: TermModel[];
}

function ProfileSummaryDescriptionList({
  product,
  profileSummary,
}: ProfileSummaryDescriptionListProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const profileSummaryLabel = "onboarding_summary.profile_summary";
  const productTranslation = getProductTranslation(product);

  const { formatAmount } = useFormat();

  return (
    <Surface title={t(`${profileSummaryLabel}.title_label`)}>
      <DescriptionList label={t(`${profileSummaryLabel}.title_label`)}>
        {[
          {
            label: t(`${productTranslation}.total_cash_price_net_label`),
            value: formatAmount(profileSummary.totalCashPriceNet),
          },
          {
            label: t(`${profileSummaryLabel}.actual_interest_label`),
            value: profileSummary.interestRate.toFixed(2),
          },
          {
            label: t(`${profileSummaryLabel}.total_cost_of_credit_label`),
            value: formatAmount(profileSummary.totalCostOfCredit),
          },
          {
            label: t(`${profileSummaryLabel}.total_amount_repayable_label`),
            value: formatAmount(profileSummary.totalBalancePayable),
          },
        ].map((item) => (
          <DescriptionList.Item key={item.label} label={item.label}>
            {item.value}
          </DescriptionList.Item>
        ))}
      </DescriptionList>
    </Surface>
  );
}

interface NewCustomerDetailsModel {
  companyType: CompanyTypeModel;
  customer?: {
    title?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
  };
  company?: {
    companyName?: string;
    companyRegistrationNumber?: string;
    vatRegistrationNumber?: string;
  };
  address?: {
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    addressLine4?: string;
    postcode?: string;
  };
  email?: string;
  telephoneNumber?: string;
  obligorRiskRating?: number;
  bankDetails?: {
    bankName?: string;
    accountName?: string;
    sortCode?: string;
    accountNumber?: string;
    iban?: string;
    bic?: string;
  };
}

interface NewCustomerDetailsDescriptionListProps {
  customerDetails: NewCustomerDetailsModel;
}

function NewCustomerDetailsDescriptionList({
  customerDetails,
}: NewCustomerDetailsDescriptionListProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const customerDetailsLabel = "onboarding_summary.customer_details";

  const isIndividual =
    customerDetails.companyType === CompanyTypeModel.SoleTrader ||
    customerDetails.companyType === CompanyTypeModel.Partnership;

  const companyNameLabel = isIndividual
    ? "trading_name_label"
    : "company_name_label";

  return (
    <Surface title={t(`${customerDetailsLabel}.title_label`)}>
      <DescriptionList label={t(`${customerDetailsLabel}.title_label`)}>
        {[
          {
            label: t(`${customerDetailsLabel}.customer_name_label`),
            value:
              [
                customerDetails.customer?.title,
                customerDetails.customer?.firstName,
                customerDetails.customer?.middleName,
                customerDetails.customer?.lastName,
              ]
                .filter(Boolean)
                .join(" ") || "-",
            companyTypes: [
              CompanyTypeModel.Partnership,
              CompanyTypeModel.SoleTrader,
            ] as CompanyTypeModel[],
          },
          {
            label: t(`${customerDetailsLabel}.${companyNameLabel}`),
            value: customerDetails.company?.companyName || "-",
          },
          {
            label: t(`${customerDetailsLabel}.company_registration_label`),
            value: customerDetails.company?.companyRegistrationNumber || "-",
            companyTypes: [
              CompanyTypeModel.Charity,
              CompanyTypeModel.CommunityInterestCompany,
              CompanyTypeModel.LimitedCompany,
              CompanyTypeModel.LimitedLiabilityPartnership,
              CompanyTypeModel.PublicSectorCompany,
            ] as CompanyTypeModel[],
          },
          {
            label: t(`${customerDetailsLabel}.vat_registration_label`),
            value: customerDetails.company?.vatRegistrationNumber || "-",
            companyTypes: [
              CompanyTypeModel.Charity,
              CompanyTypeModel.CommunityInterestCompany,
              CompanyTypeModel.LimitedCompany,
              CompanyTypeModel.LimitedLiabilityPartnership,
              CompanyTypeModel.PublicSectorCompany,
            ] as CompanyTypeModel[],
          },
          {
            label: t(`${customerDetailsLabel}.address_label`),
            value: [
              customerDetails.address?.addressLine1,
              customerDetails.address?.addressLine2,
              customerDetails.address?.addressLine3,
              customerDetails.address?.addressLine4,
              customerDetails.address?.postcode,
            ]
              .filter(Boolean)
              .join(", "),
          },
          {
            label: t(`${customerDetailsLabel}.email_label`),
            value: customerDetails.email || "-",
          },
          {
            label: t(`${customerDetailsLabel}.telephone_number_label`),
            value: customerDetails.telephoneNumber || "-",
          },
          {
            label: t(`${customerDetailsLabel}.bank_details_label`),
            value: [
              customerDetails.bankDetails?.accountName,
              customerDetails.bankDetails?.bankName,
              customerDetails.bankDetails?.accountNumber,
              customerDetails.bankDetails?.sortCode,
              customerDetails.bankDetails?.bic,
              customerDetails.bankDetails?.iban,
            ]
              .filter(Boolean)
              .join(", "),
          },
          {
            label: t(`${customerDetailsLabel}.obligor_risk_rating_label`),
            value: customerDetails.obligorRiskRating || "-",
          },
        ]
          .filter(
            (item) =>
              !item.companyTypes ||
              item.companyTypes.includes(customerDetails.companyType),
          )
          .map((item) => (
            <DescriptionList.Item key={item.label} label={item.label}>
              {item.value}
            </DescriptionList.Item>
          ))}
      </DescriptionList>
    </Surface>
  );
}

interface ExistingCustomerDetailsDescriptionListProps {
  existingCustomerName?: string;
}

function ExistingCustomerDetailsDescriptionList({
  existingCustomerName,
}: ExistingCustomerDetailsDescriptionListProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const customerDetailsLabel = "onboarding_summary.customer_details";

  return (
    <Surface title={t(`${customerDetailsLabel}.title_label`)}>
      <DescriptionList label={t(`${customerDetailsLabel}.title_label`)}>
        <DescriptionList.Item
          key={t(`${customerDetailsLabel}.customer_label`)}
          label={t(`${customerDetailsLabel}.customer_label`)}
        >
          {existingCustomerName || "-"}
        </DescriptionList.Item>
      </DescriptionList>
    </Surface>
  );
}

interface AgreementDetailsModel {
  productName?: string;
  maintainerName?: string;
  maintenanceAmount?: string;
  maintenanceCost?: string;
  agreementNumber?: string;
  fundingLine?: string;
}

interface AgreementDetailsDescriptionListProps {
  product: ProductTypeModel;
  agreementDetails: AgreementDetailsModel;
  portfolioName?: string;
}

function AgreementDetailsDescriptionList({
  product,
  agreementDetails,
  portfolioName,
}: AgreementDetailsDescriptionListProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const agreementDetailsLabel = "onboarding_summary.agreement_details";

  const { formatAmount } = useFormat();

  return (
    <Surface title={t(`${agreementDetailsLabel}.title_label`)}>
      <DescriptionList label={t(`${agreementDetailsLabel}.title_label`)}>
        {[
          {
            label: t(`${agreementDetailsLabel}.portfolio_label`),
            value: portfolioName || "-",
          },
          {
            label: t(`${agreementDetailsLabel}.agreement_number_label`),
            value: agreementDetails.agreementNumber || "-",
          },
          {
            label: t(`${agreementDetailsLabel}.funding_line_label`),
            value: agreementDetails.fundingLine || "-",
          },
          {
            label: t(`${agreementDetailsLabel}.product_label`),
            value: agreementDetails.productName || "-",
          },
          {
            label: t(`${agreementDetailsLabel}.maintainer_name_label`),
            value: agreementDetails.maintainerName || "-",
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateHirePurchase,
              ProductTypeModel.FixedRateOperatingLease,
            ] as ProductTypeModel[],
          },
          {
            label: t(`${agreementDetailsLabel}.maintenance_amount_label`),
            value: formatAmount(agreementDetails.maintenanceAmount),
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateHirePurchase,
              ProductTypeModel.FixedRateOperatingLease,
            ] as ProductTypeModel[],
          },
          {
            label: t(`${agreementDetailsLabel}.maintenance_cost_label`),
            value: formatAmount(agreementDetails.maintenanceCost),
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateHirePurchase,
              ProductTypeModel.FixedRateOperatingLease,
            ] as ProductTypeModel[],
          },
        ]
          .filter(
            (item) => !item.assignedTo || item.assignedTo.includes(product),
          )
          .map((item) => (
            <DescriptionList.Item key={item.label} label={item.label}>
              {item.value}
            </DescriptionList.Item>
          ))}
      </DescriptionList>
    </Surface>
  );
}

interface FinancialDetailsModel {
  vatReclaimMonth?: number;
  deposit?: string;
  partExchange?: string;
  blindDiscount?: string;
  subsidyPayment?: string;
  balloonPayment?: string;
  fleetDiscount?: string;
  typeOfLease?: string;
  residualValue?: string;
  secondaryRentalStartDate?: Date;
  secondaryRentalAmount?: string;
  secondaryRentalFrequency?: string;
  secondaryRentalIntroducerShare?: number;
  secondaryRentalMaintenanceCharge?: string;
  secondaryRentalInsurancePremium?: string;
}

interface FinancialDetailsDescriptionListProps {
  product: ProductTypeModel;
  financialDetails: FinancialDetailsModel;
  leaseType: TermModel;
}

function FinancialDetailsDescriptionList({
  product,
  financialDetails,
  leaseType,
}: FinancialDetailsDescriptionListProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const financialDetailsLabel = "onboarding_summary.financial_details";
  const locale = useLocale();
  const { formatAmount } = useFormat();

  const renderSecondaryRentalDetails = () => {
    return leaseType === TermModel.MinimumTerm
      ? [
          {
            label: t(
              `${financialDetailsLabel}.secondary_rental_start_date_label`,
            ),
            value: financialDetails.secondaryRentalStartDate
              ? locale.formatDate(financialDetails.secondaryRentalStartDate)
              : "-",
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateOperatingLease,
            ] as ProductTypeModel[],
          },
          {
            label: t(`${financialDetailsLabel}.secondary_rental_amount_label`),
            value: formatAmount(financialDetails.secondaryRentalAmount),
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateOperatingLease,
            ] as ProductTypeModel[],
          },
          {
            label: t(
              `${financialDetailsLabel}.secondary_rental_frequency_label`,
            ),
            value: financialDetails.secondaryRentalFrequency
              ? financialDetails.secondaryRentalFrequency
              : "-",
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateOperatingLease,
            ] as ProductTypeModel[],
          },
          {
            label: t(
              `${financialDetailsLabel}.secondary_rental_introducer_share_label`,
            ),
            value: financialDetails.secondaryRentalIntroducerShare
              ? financialDetails.secondaryRentalIntroducerShare?.toFixed(2)
              : "-",
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateOperatingLease,
            ] as ProductTypeModel[],
          },
          {
            label: t(
              `${financialDetailsLabel}.secondary_rental_maintenance_charge_label`,
            ),
            value: formatAmount(
              financialDetails.secondaryRentalMaintenanceCharge,
            ),
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateOperatingLease,
            ] as ProductTypeModel[],
          },
          {
            label: t(
              `${financialDetailsLabel}.secondary_rental_insurance_premium_label`,
            ),
            value: formatAmount(
              financialDetails.secondaryRentalInsurancePremium,
            ),
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateOperatingLease,
            ] as ProductTypeModel[],
          },
        ]
      : [];
  };

  return (
    <Surface title={t(`${financialDetailsLabel}.title_label`)}>
      <DescriptionList label={t(`${financialDetailsLabel}.title_label`)}>
        {[
          {
            label: t(`${financialDetailsLabel}.vat_reclaim_label`),
            value: financialDetails.vatReclaimMonth || "-",
            assignedTo: [
              ProductTypeModel.FixedRateOperatingLease,
              ProductTypeModel.FixedRateFinanceLease,
            ] as ProductTypeModel[],
          },
          {
            label: t(`${financialDetailsLabel}.cash_deposit_label`),
            value: formatAmount(financialDetails.deposit),
          },
          {
            label: t(`${financialDetailsLabel}.part_exchange_label`),
            value: formatAmount(financialDetails.partExchange),
            assignedTo: [
              ProductTypeModel.FixedRateOperatingLease,
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateHirePurchase,
            ] as ProductTypeModel[],
          },
          {
            label: t(`${financialDetailsLabel}.residual_value_label`),
            value: formatAmount(financialDetails.residualValue),
            assignedTo: [
              ProductTypeModel.FixedRateOperatingLease,
            ] as ProductTypeModel[],
          },
          {
            label: t(`${financialDetailsLabel}.blind_discount_label`),
            value: formatAmount(financialDetails.blindDiscount),
            assignedTo: [
              ProductTypeModel.FixedRateOperatingLease,
              ProductTypeModel.FixedRateFinanceLease,
            ] as ProductTypeModel[],
          },
          {
            label: t(`${financialDetailsLabel}.subsidy_payment_label`),
            value: formatAmount(financialDetails.subsidyPayment),
            assignedTo: [
              ProductTypeModel.FixedRateOperatingLease,
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateHirePurchase,
            ] as ProductTypeModel[],
          },
          {
            label: t(`${financialDetailsLabel}.balloon_payment_label`),
            value: formatAmount(financialDetails.balloonPayment),
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
              ProductTypeModel.FixedRateHirePurchase,
              ProductTypeModel.FixedRateLoan,
            ] as ProductTypeModel[],
          },
          {
            label: t(`${financialDetailsLabel}.fleet_discount_label`),
            value: formatAmount(financialDetails.fleetDiscount),
            assignedTo: [
              ProductTypeModel.FixedRateHirePurchase,
            ] as ProductTypeModel[],
          },
          {
            label: t(`${financialDetailsLabel}.type_of_lease_label`),
            value: financialDetails.typeOfLease || "-",
            assignedTo: [
              ProductTypeModel.FixedRateFinanceLease,
            ] as ProductTypeModel[],
          },
        ]
          .concat(renderSecondaryRentalDetails())
          .filter(
            (item) => !item.assignedTo || item.assignedTo.includes(product),
          )
          .map((item) => (
            <DescriptionList.Item key={item.label} label={item.label}>
              {item.value}
            </DescriptionList.Item>
          ))}
      </DescriptionList>
    </Surface>
  );
}

interface FeeModel {
  name: string;
  amount: string;
}

interface FeesAndCommissionsModel {
  fees?: {
    annualAdministrationFee?: string;
    facilityFee?: string;
    legalFee?: string;
    documentationFee?: string;
    landRegistryFee?: string;
    exitFee?: string;
    administrationFee?: string;
    arrangementFee?: string;
    valuationFee?: string;
    optionToPurchaseFee?: string;
  };
  commissions?: {
    brokerCommission?: number;
    brokerCommissionAmount?: string;
    supplierCommission?: number;
    supplierCommissionAmount?: string;
  };
  feesList: FeeModel[];
}

interface FeesAndCommissionsDescriptionListProps {
  product: ProductTypeModel;
  feesAndCommissions: FeesAndCommissionsModel;
}

function FeesAndCommissionsDescriptionList({
  product,
  feesAndCommissions,
}: FeesAndCommissionsDescriptionListProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const feesAndCommissionsLabel = "onboarding_summary.fees_and_commissions";

  const { formatAmount, formatNumber } = useFormat();

  return (
    <Surface title={t(`${feesAndCommissionsLabel}.title_label`)}>
      <DescriptionList label={t(`${feesAndCommissionsLabel}.title_label`)}>
        {feesAndCommissions.feesList
          .map((fee) => {
            return {
              label: fee.name,
              value: formatAmount(fee.amount),
            } as DescriptionListItem;
          })
          .concat([
            {
              label: t(`${feesAndCommissionsLabel}.broker_commission_label`),
              value: formatNumber(
                feesAndCommissions.commissions?.brokerCommission,
              ),
            },
            {
              label: t(`${feesAndCommissionsLabel}.broker_amount_label`),
              value: formatAmount(
                feesAndCommissions.commissions?.brokerCommissionAmount,
              ),
            },
            {
              label: t(`${feesAndCommissionsLabel}.supplier_commission_label`),
              value: formatNumber(
                feesAndCommissions.commissions?.supplierCommission,
              ),
              assignedTo: [
                ProductTypeModel.FixedRateOperatingLease,
                ProductTypeModel.FixedRateFinanceLease,
                ProductTypeModel.FixedRateHirePurchase,
              ] as ProductTypeModel[],
            },
            {
              label: t(`${feesAndCommissionsLabel}.supplier_amount_label`),
              value: formatAmount(
                feesAndCommissions.commissions?.supplierCommissionAmount,
              ),
              assignedTo: [
                ProductTypeModel.FixedRateOperatingLease,
                ProductTypeModel.FixedRateFinanceLease,
                ProductTypeModel.FixedRateHirePurchase,
              ] as ProductTypeModel[],
            },
          ])
          .filter(
            (item) => !item.assignedTo || item.assignedTo.includes(product),
          )
          .map((item) => (
            <DescriptionList.Item key={item.label} label={item.label}>
              {item.value}
            </DescriptionList.Item>
          ))}
      </DescriptionList>
    </Surface>
  );
}

interface RepaymentTermsModel {
  paymentStructure: PaymentStructureModel;
  term: number;
  frequencyOfInstalments?: string;
  instalmentAmount?: string;
  inceptionDate?: Date;
  firstRepaymentDate?: Date;
  customerInterestRate?: number;
  advancedPayment?: string;
  seasonalPaymentStructure?: SeasonalPaymentStructureModel[];
}

interface RepaymentTermsDescriptionListProps {
  product: ProductTypeModel;
  repaymentTerms: RepaymentTermsModel;
}

function RepaymentTermsDescriptionList({
  product,
  repaymentTerms,
}: RepaymentTermsDescriptionListProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const repaymentTermsLabel = "onboarding_summary.repayment_terms";
  const locale = useLocale();
  const { formatAmount } = useFormat();
  const productTranslation = getProductTranslation(product);
  const isSeasonal =
    String(repaymentTerms.paymentStructure) === PaymentStructureModel.Seasonal;

  return (
    <Surface title={t(`${repaymentTermsLabel}.title_label`)}>
      <Stack spacing={3}>
        <DescriptionList label={t(`${repaymentTermsLabel}.title_label`)}>
          {[
            {
              label: `${repaymentTermsLabel}.payment_structure_label`,
              value: repaymentTerms.paymentStructure,
            },
            {
              label: `${productTranslation}.total_number_of_instalments_label`,
              value: repaymentTerms.term,
            },
            {
              label: `${productTranslation}.frequency_of_instalments_label`,
              value: repaymentTerms.frequencyOfInstalments,
              hidden: isSeasonal,
            },
            {
              label: `${productTranslation}.instalment_amount_label`,
              value: formatAmount(repaymentTerms.instalmentAmount),
              hidden: isSeasonal,
            },
            {
              label: `${repaymentTermsLabel}.inception_date_label`,
              value: repaymentTerms.inceptionDate
                ? locale.formatDate(repaymentTerms.inceptionDate)
                : "-",
            },
            {
              label: `${repaymentTermsLabel}.first_repayment_label`,
              value: repaymentTerms.firstRepaymentDate
                ? locale.formatDate(repaymentTerms.firstRepaymentDate)
                : "-",
            },
            {
              label: `${repaymentTermsLabel}.customer_interest_label`,
              value: repaymentTerms.customerInterestRate?.toFixed(2) || "-",
            },
            {
              label: `${productTranslation}.advanced_payment_label`,
              value: formatAmount(repaymentTerms.advancedPayment),
              hidden: !(
                [
                  ProductTypeModel.FixedRateOperatingLease,
                  ProductTypeModel.FixedRateFinanceLease,
                ] as ProductTypeModel[]
              ).includes(product),
            },
          ]
            .filter((item) => !item.hidden)
            .map((item) => (
              <DescriptionList.Item key={t(item.label)} label={t(item.label)}>
                {item.value}
              </DescriptionList.Item>
            ))}
        </DescriptionList>
        <DescriptionList
          label={t(`${repaymentTermsLabel}.seasonal_description_label`)}
        >
          <>
            {repaymentTerms.seasonalPaymentStructure?.map((payment) => (
              <DescriptionList.Item key={payment.month} label={payment.month}>
                {formatAmount(payment.amount)}
              </DescriptionList.Item>
            ))}
          </>
        </DescriptionList>
      </Stack>
    </Surface>
  );
}

interface AssetDetailsModel {
  assetType: string;
  assetDescription: string;
  manufacturerDescription?: string;
  model?: string;
  includeInsurance?: boolean;
  costPriceNetVat: number;
  vat: number;
  fleetDiscount?: string;
}

interface AssetDetailsTableProps {
  product: ProductTypeModel;
  assetDetails?: AssetDetailsModel[];
}

function AssetDetailsTable({
  product,
  assetDetails,
}: AssetDetailsTableProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const assetDetailsLabel = "onboarding_summary.asset_details";
  const { formatAmount } = useFormat();

  return (
    <Surface title={t(`${assetDetailsLabel}.title_label`)}>
      <Table size="small" aria-label={t(`${assetDetailsLabel}.title_label`)}>
        <TableHead>
          <TableRow>
            <TableCell>{t(`${assetDetailsLabel}.asset_type_label`)}</TableCell>
            <TableCell align="left">
              {t(`${assetDetailsLabel}.asset_description_label`)}
            </TableCell>
            <TableCell align="left">
              {t(`${assetDetailsLabel}.manufacturer_description_label`)}
            </TableCell>
            <TableCell align="left">
              {t(`${assetDetailsLabel}.model_label`)}
            </TableCell>
            <TableCell align="left">
              {t(`${assetDetailsLabel}.insurance_applied_label`)}
            </TableCell>
            <TableCell align="right">
              {t(`${assetDetailsLabel}.total_cash_price_label`)}
            </TableCell>
            <TableCell align="right">
              {t(`${assetDetailsLabel}.vat_label`)}
            </TableCell>
            {product === ProductTypeModel.FixedRateHirePurchase && (
              <>
                <TableCell align="right">
                  {t(`${assetDetailsLabel}.fleet_discount_label`)}
                </TableCell>

                <TableCell align="right">
                  {t(`${assetDetailsLabel}.asset_invoice_price_label`)}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {assetDetails?.map((asset, index) => (
            <TableRow key={index}>
              <TableCell align="left">{asset.assetType}</TableCell>
              <TableCell align="left">{asset.assetDescription}</TableCell>
              <TableCell align="left">
                {asset.manufacturerDescription || "-"}
              </TableCell>
              <TableCell align="left">{asset.model || "-"}</TableCell>
              <TableCell align="left">
                {asset.includeInsurance ? "Yes" : "No"}
              </TableCell>
              <TableCell align="right">
                {formatAmount(asset.costPriceNetVat.toFixed(2))}
              </TableCell>
              <TableCell align="right">
                {formatAmount(asset.vat.toFixed(2))}
              </TableCell>
              {product === ProductTypeModel.FixedRateHirePurchase && (
                <>
                  <TableCell align="right">
                    {formatAmount(asset.fleetDiscount)}
                  </TableCell>

                  <TableCell align="right">
                    {formatAmount(
                      asset.costPriceNetVat - Number(asset.fleetDiscount),
                    )}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Surface>
  );
}

interface RepaymentScheduleModel {
  id: number;
  number: number;
  date: Date;
  amount: string;
  interest: string;
  fee: string;
  capital: string;
  capitalOutstanding: string;
}

interface RepaymentScheduleTableProps {
  repaymentSchedule?: RepaymentScheduleModel[];
}

function RepaymentScheduleTable({
  repaymentSchedule,
}: RepaymentScheduleTableProps): React.ReactElement {
  const { t } = useTranslation(["onboardings"]);
  const locale = useLocale();
  const { formatAmount } = useFormat();

  const repaymentScheduleLabel = "onboarding_summary.agreement_profile";

  return (
    <>
      <Surface title={t("onboarding_summary.repayment_schedule_heading")}>
        <Table size="small" data-testid="agreement.table">
          <TableHead>
            <TableRow>
              <TableCell>
                {t(`${repaymentScheduleLabel}.instalment_number`)}
              </TableCell>
              <TableCell>
                {t(`${repaymentScheduleLabel}.instalment_date`)}
              </TableCell>
              <TableCell align="right">
                {t(`${repaymentScheduleLabel}.instalment_amount`)}
              </TableCell>
              <TableCell align="right">
                {t(`${repaymentScheduleLabel}.instalment_interest`)}
              </TableCell>
              <TableCell align="right">
                {t(`${repaymentScheduleLabel}.instalment_fee`)}
              </TableCell>
              <TableCell align="right">
                {t(`${repaymentScheduleLabel}.instalment_capital`)}
              </TableCell>
              <TableCell align="right">
                {t(`${repaymentScheduleLabel}.instalment_balance_outstanding`)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {repaymentSchedule?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.number}</TableCell>
                <TableCell>{locale.formatDate(item.date)}</TableCell>
                <TableCell align="right">{formatAmount(item.amount)}</TableCell>
                <TableCell align="right">
                  {formatAmount(item.interest)}
                </TableCell>
                <TableCell align="right">{formatAmount(item.fee)}</TableCell>
                <TableCell align="right">
                  {formatAmount(item.capital)}
                </TableCell>
                <TableCell align="right">
                  {formatAmount(item.capitalOutstanding)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Surface>
    </>
  );
}

export default function OnboardingSummaryDetailsContainer({
  onboardingSummary,
  product,
  portfolioId,
  gridColumns = 1,
}: OnboardingLayoutProps): ReactElement {
  const { t } = useTranslation("onboardings");
  const assetTypes = useListAssetTypes();
  const portfolio = useGetPortfolio(portfolioId);

  return (
    <Loader
      ready={Boolean(
        (portfolio.error || portfolio.data) &&
          (assetTypes.error || assetTypes.data),
      )}
      render={(): ReactElement => {
        if (
          portfolio.error ||
          !portfolio.data ||
          assetTypes.error ||
          !assetTypes.data
        ) {
          return (
            <Typography color="error" role="alert">
              {t("common:error.default")}
            </Typography>
          );
        }
        return (
          <>
            <Box
              display="grid"
              gap={2}
              gridTemplateColumns={{
                lg: "repeat(1, minmax(0, 1fr))",
                xl: `repeat(${gridColumns}, minmax(0, 1fr))`,
              }}
            >
              <ProfileSummaryDescriptionList
                product={product}
                profileSummary={{ ...onboardingSummary }}
              />

              {onboardingSummary.customer ? (
                <NewCustomerDetailsDescriptionList
                  customerDetails={{ ...onboardingSummary.customer }}
                />
              ) : (
                <ExistingCustomerDetailsDescriptionList
                  existingCustomerName={onboardingSummary.existingCustomerName}
                />
              )}

              <AgreementDetailsDescriptionList
                product={product}
                agreementDetails={{ ...onboardingSummary }}
                portfolioName={portfolio.data.name}
              />

              <FinancialDetailsDescriptionList
                product={product}
                financialDetails={{ ...onboardingSummary }}
                leaseType={onboardingSummary.typeOfLease as TermModel}
              />

              <FeesAndCommissionsDescriptionList
                product={product}
                feesAndCommissions={{
                  ...onboardingSummary.feesAndCommissions,
                  feesList: onboardingSummary.fees || [],
                }}
              />
              <RepaymentTermsDescriptionList
                product={product}
                repaymentTerms={{ ...onboardingSummary }}
              />
            </Box>

            <Box marginBottom={2} marginTop={2}>
              <AssetDetailsTable
                product={product}
                assetDetails={onboardingSummary.assets?.map((item) => ({
                  ...item,
                  assetType:
                    assetTypes.data?.find(
                      (value) => value.code === item.assetType,
                    )?.type || "-",
                }))}
              />
            </Box>

            <Box marginBottom={3}>
              <RepaymentScheduleTable
                repaymentSchedule={onboardingSummary.instalments}
              />
            </Box>
          </>
        );
      }}
    />
  );
}
