import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useListBatches } from "api/batches";
import { useDownloadBatch } from "api/batches/downloadBatch";
import { useMarkBatchPrinted } from "api/batches/markBatchPrinted";
import { useListBatchesTypes } from "api/documents/batches-types/listBatchesTypes";
import BatchFilter from "apps/documents/components/BatchFilter";
import BatchList from "apps/documents/components/BatchList";
import BatchContentDialogContainer from "apps/documents/containers/BatchContentDialogContainer";
import { Loader } from "common/components";
import { useConfirm } from "common/hooks";
import ListLayout from "common/layouts/ListLayout";
import { BatchModel } from "generated/documents/models/BatchModel";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface ListBatchesFilterProps {
  status?: string;
  type?: string;
  from?: Date;
  to?: Date;
}

export default function BatchesContainer(): ReactElement {
  const { t } = useTranslation("documents");
  const [filters, setFilters] = useState<ListBatchesFilterProps>({});
  const [isPrintError, setIsPrintError] = useState(false);
  const [isBatchContentDialogOpen, setIsBatchContentDialogOpen] =
    useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedBatch, setSelectedBatch] = useState<{
    batch: BatchModel;
    paddedBatchId: string;
  }>();

  const confirm = useConfirm();
  const batchTypes = useListBatchesTypes();
  const handleError = () => setIsPrintError(true);
  const markBatchAsPrinted = useMarkBatchPrinted({ onError: handleError });
  const download = useDownloadBatch({ onError: handleError });

  const batches = useListBatches({
    pageSize: rowsPerPage,
    params: filters,
  });

  const onDownload = async (batchId: string): Promise<void> => {
    await download.execute({
      batchId,
    });
  };

  const handleOnPrint = (batchId: string): void => {
    confirm.handlePrompt(async (): Promise<void> => {
      await markBatchAsPrinted.execute({ batchId });
    });
  };

  const handleCloseError = () => {
    setIsPrintError(false);
    markBatchAsPrinted.reset();
  };

  const handleBatchContentDialogOpen = (
    batch: BatchModel,
    paddedBatchId: string,
  ): void => {
    setSelectedBatch({ batch, paddedBatchId });
    setIsBatchContentDialogOpen(true);
  };

  const handleBatchContentDialogClose = (): void => {
    setSelectedBatch(undefined);
    setIsBatchContentDialogOpen(false);
  };

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => batches.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => setRowsPerPage(Number(event.target.value));

  const onFilterChange = (
    key: string,
    value: string | Date | undefined,
  ): void => setFilters((current) => ({ ...current, [key]: value }));

  const typeFilterOptions = batchTypes.data?.map((batchType) => batchType.name);

  const renderBatches = (): ReactElement => {
    if (batches.error || !batches.data) {
      return (
        <Typography
          role="alert"
          color="error"
          aria-label={t("common:error.default")}
        >
          {t("common:error.default")}
        </Typography>
      );
    }

    return (
      <BatchList
        data={batches.data.results}
        loading={batches.isValidating}
        onDownload={onDownload}
        onPrint={handleOnPrint}
        onViewBatchContent={handleBatchContentDialogOpen}
      />
    );
  };

  return (
    <>
      <ListLayout
        pagination={{
          onPageChange: onChangePage,
          onRowsPerPageChange: onChangeRowsPerPage,
          rowsPerPage,
          page: batches.paging.page,
          count: batches.data?.paging.total ?? 0,
        }}
        filters={
          <BatchFilter
            types={typeFilterOptions}
            filters={filters}
            onChange={onFilterChange}
          />
        }
      >
        <Loader
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
          ready={Boolean(batches.data ?? batches.error)}
          render={renderBatches}
        />
      </ListLayout>
      {selectedBatch && (
        <BatchContentDialogContainer
          open={isBatchContentDialogOpen}
          onClose={handleBatchContentDialogClose}
          batchId={selectedBatch.batch.id}
          batchType={selectedBatch.batch.type}
          paddedBatchReference={selectedBatch.paddedBatchId}
        />
      )}
      {confirm.isOpen && (
        <ConfirmationDialog
          content={
            <Typography variant="body2">
              {t("batch_print_dialog.content")}
            </Typography>
          }
          open={confirm.isOpen}
          onConfirm={confirm.handleConfirm}
          onReject={confirm.handleReject}
          title={t("batch_print_dialog.title")}
          labels={{
            confirm: t("batch_print_dialog.labels.confirm"),
            reject: t("batch_print_dialog.labels.reject"),
          }}
        />
      )}
      {isPrintError && (
        <AlertDialog
          open={isPrintError}
          title={t("common:error.title")}
          labels={{
            close: t("common:alert.close"),
          }}
          onClose={handleCloseError}
          content={
            <Typography variant="body2">
              {t("common:error.default_message")}
            </Typography>
          }
        />
      )}
    </>
  );
}
