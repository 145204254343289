import { keys as availableThirdPartiesKeys } from "api/agreements/available-third-parties";
import { usePartialMutate } from "common/hooks";
import { keys } from ".";

export default function useMutateThirdParties(agreementId: number): () => void {
  const partialMutate = usePartialMutate();
  return (): void => {
    partialMutate(keys.list(agreementId));
    partialMutate(availableThirdPartiesKeys.list(agreementId, { query: "" }));
  };
}
