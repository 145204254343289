/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Change types within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AllocationTypeModel } from './AllocationTypeModel';
import {
    AllocationTypeModelFromJSON,
    AllocationTypeModelFromJSONTyped,
    AllocationTypeModelToJSON,
} from './AllocationTypeModel';
import type { NetEffectModel } from './NetEffectModel';
import {
    NetEffectModelFromJSON,
    NetEffectModelFromJSONTyped,
    NetEffectModelToJSON,
} from './NetEffectModel';
import type { RecoverableTypeModel } from './RecoverableTypeModel';
import {
    RecoverableTypeModelFromJSON,
    RecoverableTypeModelFromJSONTyped,
    RecoverableTypeModelToJSON,
} from './RecoverableTypeModel';
import type { SubTypeModel } from './SubTypeModel';
import {
    SubTypeModelFromJSON,
    SubTypeModelFromJSONTyped,
    SubTypeModelToJSON,
} from './SubTypeModel';

/**
 * Charge type details
 * @export
 * @interface ChargeTypeModel
 */
export interface ChargeTypeModel {
    /**
     * Charge type name
     * @type {string}
     * @memberof ChargeTypeModel
     */
    name: string;
    /**
     * 
     * @type {SubTypeModel}
     * @memberof ChargeTypeModel
     */
    subType: SubTypeModel;
    /**
     * 
     * @type {AllocationTypeModel}
     * @memberof ChargeTypeModel
     */
    allocationType: AllocationTypeModel;
    /**
     * 
     * @type {NetEffectModel}
     * @memberof ChargeTypeModel
     */
    netEffect: NetEffectModel;
    /**
     * 
     * @type {RecoverableTypeModel}
     * @memberof ChargeTypeModel
     */
    recoverableType?: RecoverableTypeModel;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof ChargeTypeModel
     */
    amount: string;
    /**
     * Indicates whether a charge of this type is to be collected by Direct debit
     * @type {boolean}
     * @memberof ChargeTypeModel
     */
    isCollectedByDirectDebit?: boolean;
    /**
     * Indicates whether a charge of this type is subject to Tax (E.g. VAT)
     * @type {boolean}
     * @memberof ChargeTypeModel
     */
    isTaxable: boolean;
    /**
     * Indicates whether a charge of this type is to be included on invoices
     * @type {boolean}
     * @memberof ChargeTypeModel
     */
    isIncludedOnInvoice?: boolean;
    /**
     * Indicates whether the charge type is currently active
     * @type {boolean}
     * @memberof ChargeTypeModel
     */
    isActive: boolean;
}

/**
 * Check if a given object implements the ChargeTypeModel interface.
 */
export function instanceOfChargeTypeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "subType" in value;
    isInstance = isInstance && "allocationType" in value;
    isInstance = isInstance && "netEffect" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "isTaxable" in value;
    isInstance = isInstance && "isActive" in value;

    return isInstance;
}

export function ChargeTypeModelFromJSON(json: any): ChargeTypeModel {
    return ChargeTypeModelFromJSONTyped(json, false);
}

export function ChargeTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeTypeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'subType': SubTypeModelFromJSON(json['subType']),
        'allocationType': AllocationTypeModelFromJSON(json['allocationType']),
        'netEffect': NetEffectModelFromJSON(json['netEffect']),
        'recoverableType': !exists(json, 'recoverableType') ? undefined : RecoverableTypeModelFromJSON(json['recoverableType']),
        'amount': json['amount'],
        'isCollectedByDirectDebit': !exists(json, 'isCollectedByDirectDebit') ? undefined : json['isCollectedByDirectDebit'],
        'isTaxable': json['isTaxable'],
        'isIncludedOnInvoice': !exists(json, 'isIncludedOnInvoice') ? undefined : json['isIncludedOnInvoice'],
        'isActive': json['isActive'],
    };
}

export function ChargeTypeModelToJSON(value?: ChargeTypeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'subType': SubTypeModelToJSON(value.subType),
        'allocationType': AllocationTypeModelToJSON(value.allocationType),
        'netEffect': NetEffectModelToJSON(value.netEffect),
        'recoverableType': RecoverableTypeModelToJSON(value.recoverableType),
        'amount': value.amount,
        'isCollectedByDirectDebit': value.isCollectedByDirectDebit,
        'isTaxable': value.isTaxable,
        'isIncludedOnInvoice': value.isIncludedOnInvoice,
        'isActive': value.isActive,
    };
}

