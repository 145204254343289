import { QueryKey } from "api";
import { keys } from "api/customers/contacts";

export const all = (customerId: string, contactId?: string): QueryKey => [
  ...keys.detail(customerId, contactId),
  "contact-points",
];

export const list = (customerId: string): QueryKey => [
  ...all(customerId),
  "list",
];
