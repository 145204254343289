/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactNameModel
 */
export interface ContactNameModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof ContactNameModel
     */
    readonly clientId: number;
    /**
     * The company name
     * @type {string}
     * @memberof ContactNameModel
     */
    companyName?: string;
    /**
     * The contact title
     * @type {string}
     * @memberof ContactNameModel
     */
    title?: string;
    /**
     * The contact first name
     * @type {string}
     * @memberof ContactNameModel
     */
    firstName?: string;
    /**
     * The contact second name
     * @type {string}
     * @memberof ContactNameModel
     */
    secondName?: string;
    /**
     * The contact last name
     * @type {string}
     * @memberof ContactNameModel
     */
    lastName?: string;
}

/**
 * Check if a given object implements the ContactNameModel interface.
 */
export function instanceOfContactNameModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;

    return isInstance;
}

export function ContactNameModelFromJSON(json: any): ContactNameModel {
    return ContactNameModelFromJSONTyped(json, false);
}

export function ContactNameModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactNameModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'secondName': !exists(json, 'secondName') ? undefined : json['secondName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
    };
}

export function ContactNameModelToJSON(value?: ContactNameModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'title': value.title,
        'firstName': value.firstName,
        'secondName': value.secondName,
        'lastName': value.lastName,
    };
}

