import { useYupResolver } from "common/hooks";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface PortfolioFieldValues {
  name: string;
  portfolioBankAccountId: string;
  isHpiRegistered: boolean;
  equifaxInsightNumber: string;
  companyId: string;
  ccrProviderCode?: string;
  assetDepreciationModel: string;
  depreciationPercentage?: number;
  depreciationMinimumValue?: number;
}

const usePortfolioResolver = (
  value: "add_portfolio" | "update_portfolio",
): Resolver<PortfolioFieldValues> => {
  const { t } = useTranslation("servicing");
  const nameLabel = t(`companies.${value}.name_label`);
  const transformField = (v: string, o: string): string | null | undefined => {
    return o === "" ? undefined : v;
  };

  return useYupResolver<PortfolioFieldValues>((yup) =>
    yup.object().shape({
      name: yup.string().isRequired(nameLabel).maxCharacters(50, nameLabel),
      portfolioBankAccountId: yup
        .string()
        .isRequired(t(`companies.${value}.bank_account_label`)),
      isHpiRegistered: yup.boolean(),
      equifaxInsightNumber: yup
        .string()
        .maxCharacters(8, t(`companies.${value}.equifax_insight_label`))
        .matches(
          /^[A-Z0-9]+$|^$/i,
          t(`companies.${value}.invalid_equifax_number`),
        ),
      ccrProviderCode: yup
        .string()
        .maxCharacters(8, t(`companies.${value}.ccr_provider_code_label`))
        .matches(
          /^[A-Z0-9]+$|^$/i,
          t(`companies.${value}.invalid_ccr_provider_code`),
        ),
      assetDepreciationModel: yup.string(),
      depreciationPercentage: yup
        .number(t(`companies.${value}.depreciation_percentage_label`))
        .transform(transformField)
        .when("assetDepreciationModel", {
          is: "Annualised Straight-line",
          then: yup
            .number(t(`companies.${value}.depreciation_percentage_label`))
            .isRequired(t(`companies.${value}.depreciation_percentage_label`))
            .integer(t("companies.invalid_percentage"))
            .transform(transformField)
            .greaterThanAmount(
              0,
              t(`companies.${value}.depreciation_percentage_label`),
            )
            .maxAmount(
              100,
              t(`companies.${value}.depreciation_percentage_label`),
            ),
        }),
      depreciationMinimumValue: yup
        .number(t(`companies.${value}.depreciation_minimum_value_label`))
        .transform(transformField)
        .when("assetDepreciationModel", {
          is: "Annualised Straight-line",
          then: yup
            .number(t(`companies.${value}.depreciation_minimum_value_label`))
            .isRequired(
              t(`companies.${value}.depreciation_minimum_value_label`),
            )
            .transform(transformField)
            .greaterThanAmount(
              0,
              t(`companies.${value}.depreciation_minimum_value_label`),
            ),
        }),
    }),
  );
};

export default usePortfolioResolver;
