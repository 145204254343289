import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { ArrowDropDown } from "@mui/icons-material";
import { Button, Menu } from "@mui/material";
import { RepaymentScheduleContainer } from "apps/servicing/modules/agreements/containers";
import ExportPaymentAllocationsContainer from "apps/servicing/modules/agreements/containers/ExportPaymentAllocationsContainer/ExportPaymentAllocationsContainer";
import ExportRepaymentSchedule from "apps/servicing/modules/agreements/containers/ExportRepaymentScheduleContainer/ExportRepaymentScheduleContainer";
import ContainedLayout from "common/layouts/ContainedLayout";
import FixedLayout from "common/layouts/FixedLayout";
import ViewLayout from "common/layouts/ViewLayout";
import { useAgreement } from "common/providers";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ExportMenuContainerProps {
  agreementId: number;
}

const repaymentScheduleExportPermission = "servicing:repayment-schedule:export";

const ExportMenuContainer = ({ agreementId }: ExportMenuContainerProps) => {
  const { t } = useTranslation("agreements");
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuElement);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuElement(event.currentTarget);
  };
  const handleMenuClose = (): void => {
    setMenuElement(null);
  };

  return (
    <>
      <Button
        endIcon={<ArrowDropDown />}
        variant="contained"
        onClick={handleMenuClick}
        aria-label={t("repayment_schedule.export_menu_button_label")}
      >
        {t("repayment_schedule.export_menu_button")}
      </Button>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        anchorEl={menuElement}
        onClose={handleMenuClose}
      >
        <AccessControl allowedPermissions={[repaymentScheduleExportPermission]}>
          <ExportRepaymentSchedule agreementId={agreementId} />
        </AccessControl>
        <AccessControl
          allowedPermissions={["servicing:payment-allocation:export"]}
        >
          <ExportPaymentAllocationsContainer agreementId={agreementId} />
        </AccessControl>
      </Menu>
    </>
  );
};

export default function ScheduleView() {
  const { t } = useTranslation("agreements");
  const agreement = useAgreement();

  return (
    <ViewLayout
      title={t("repayment_schedule.heading")}
      action={
        <AccessControl
          allowedPermissions={[
            repaymentScheduleExportPermission,
            "servicing:payment-allocation:export",
          ]}
        >
          <ExportMenuContainer agreementId={agreement.id} />
        </AccessControl>
      }
    >
      <FixedLayout>
        <ContainedLayout>
          <RepaymentScheduleContainer agreementId={agreement.id} />
        </ContainedLayout>
      </FixedLayout>
    </ViewLayout>
  );
}
