import { Form } from "@ldms/mui-sdk/forms";
import { ConfirmationDialog, Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useUpdateContact } from "api/customers/contacts";
import { useListContacts } from "api/customers/contacts/listContacts";
import { QueryError } from "common/components";
import AddressFieldset, {
  AddressFieldsetModel,
} from "common/components/AddressFieldset";
import { useConfirm, useYupResolver } from "common/hooks";
import { ContactTypeModel } from "generated/servicing-v2/models";
import { ReactElement } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const DigitalCorrespondencePreferences = <T extends FieldValues>({
  isInvoicing,
  control,
}: {
  isInvoicing: boolean;
  control: Control<T>;
}) => {
  const { t } = useTranslation("clients");

  return (
    <Grid container flexDirection="column">
      <Box mb={2}>
        <Typography variant="h5">
          {t("contacts.digital_correspondence_title")}
        </Typography>
      </Box>
      {isInvoicing && (
        <Grid sm={12} item>
          <FormControlLabel
            control={
              <Controller
                name={"invoiceByEmail" as Path<T>}
                control={control}
                render={({ field: { value, ...field } }): ReactElement => (
                  <Checkbox
                    checked={Boolean(value)}
                    {...field}
                    size="small"
                    onChange={(e): void => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={t("contacts.email_invoice_label")}
          />
        </Grid>
      )}
      <Grid item sm={12}>
        <FormControlLabel
          label={t("contacts.system_generated_correspondence_label")}
          control={
            <Controller
              control={control}
              name={"receiveSystemGeneratedCorrespondence" as Path<T>}
              defaultValue={true as PathValue<T, Path<T>>}
              render={({ field: { value, ...field } }): ReactElement => (
                <Checkbox
                  {...field}
                  checked={Boolean(value)}
                  onChange={(e): void => field.onChange(e.target.checked)}
                  size="small"
                />
              )}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

interface EditContactFormControlProps {
  errors: FieldErrors<EditContactFieldValues>;
  register: UseFormRegister<EditContactFieldValues>;
  control: Control<EditContactFieldValues>;
  type: string | undefined;
}

const postcodeLabel = "update_contact.postcode_label";
const addressLine1Label = "update_contact.address_line_1_label";
const firstNameLabel = "update_contact.first_name_label";
const lastNameLabel = "update_contact.last_name_label";
const companyNameLabel = "update_contact.company_name_label";

function EditContactFormControl({
  register,
  errors,
  control,
  type,
}: Readonly<EditContactFormControlProps>): ReactElement {
  const { t } = useTranslation(["clients", "common"]);

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sm={12}>
            <TextField
              label={t("update_contact.contact_type_label")}
              {...register("type", { disabled: true })}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              label={t("update_contact.title_label")}
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
              {...register("title")}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              error={Boolean(errors.firstName)}
              helperText={errors.firstName?.message}
              label={t(firstNameLabel)}
              {...register("firstName")}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              {...register("secondName")}
              error={Boolean(errors.secondName)}
              helperText={errors.secondName?.message}
              label={t("update_contact.second_name_label")}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              {...register("lastName")}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName?.message}
              label={t(lastNameLabel)}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              {...register("companyName")}
              error={Boolean(errors.companyName)}
              helperText={errors.companyName?.message}
              label={t(companyNameLabel)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Divider flexItem variant="fullWidth" />
      </Grid>
      <Grid item>
        <AddressFieldset
          register={register}
          errors={errors}
          labels={{
            postcode: t(postcodeLabel),
            addressLine4: t("update_contact.address_line_4_label"),
            addressLine3: t("update_contact.address_line_3_label"),
            addressLine2: t("update_contact.address_line_2_label"),
            addressLine1: t(addressLine1Label),
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <Divider flexItem variant="fullWidth" />
      </Grid>
      <Grid item>
        <DigitalCorrespondencePreferences<EditContactFieldValues>
          control={control}
          isInvoicing={type === ContactTypeModel.Invoicing}
        />
      </Grid>
    </Grid>
  );
}

export interface EditContactFieldValues extends AddressFieldsetModel {
  type: string;
  title?: string;
  firstName: string;
  secondName?: string;
  lastName: string;
  companyName: string;
  invoiceByEmail?: boolean;
  receiveSystemGeneratedCorrespondence: boolean;
}

const EditContactContainer = ({
  customerId,
  contactId,
}: {
  customerId: string;
  contactId: string;
}) => {
  const { t } = useTranslation(["clients", "common"]);
  const contacts = useListContacts(customerId);
  const currentContact = contacts.data?.find(
    (contact) => contact.systemId === contactId,
  );
  const navigate = useNavigate();
  const updateContact = useUpdateContact(customerId, contactId, {
    onSuccess: () => navigate("../contacts"),
  });
  const confirm = useConfirm();
  const firstNameLastNameCompanyRequired =
    "update_contact.errors.first_name_last_name_company_required";

  const resolver = useYupResolver<EditContactFieldValues>((yup) =>
    yup.object().shape({
      title: yup.string().maxCharacters(50, t("update_contact.title_label")),
      firstName: yup
        .string()
        .maxCharacters(50, t(firstNameLabel))
        .test(
          "isDependentOnLastNameAndCompanyName",
          t(firstNameLastNameCompanyRequired),
          function (value, testContext) {
            return (
              value ||
              (!testContext.parent.lastName && testContext.parent.companyName)
            );
          },
        ),
      lastName: yup
        .string()
        .maxCharacters(50, t(lastNameLabel))
        .test(
          "isDependentOnFirstNameAndCompanyName",
          t(firstNameLastNameCompanyRequired),
          function (value, testContext) {
            return (
              value ||
              (!testContext.parent.firstName && testContext.parent.companyName)
            );
          },
        ),
      secondName: yup
        .string()
        .maxCharacters(50, t("update_contact.second_name_label")),
      companyName: yup
        .string()
        .maxCharacters(50, t(companyNameLabel))
        .test(
          "isDependentOnFirstNameAndLastName",
          t(firstNameLastNameCompanyRequired),
          function (value, testContext) {
            return (
              value ||
              (testContext.parent.firstName && testContext.parent.lastName)
            );
          },
        ),
      addressLine1: yup
        .string()
        .maxCharacters(50, t(addressLine1Label))
        .optionalAddressLine1Validator(
          "updateContactAddressLine1",
          t(addressLine1Label),
        ),
      addressLine2: yup
        .string()
        .maxCharacters(50, t("update_contact.address_line_2_label")),
      addressLine3: yup
        .string()
        .maxCharacters(50, t("update_contact.address_line_3_label")),
      addressLine4: yup
        .string()
        .maxCharacters(50, t("update_contact.address_line_4_label")),
      postcode: yup
        .string()
        .maxCharacters(10, t(postcodeLabel))
        .optionalPostcodeValidator("updateContactPostcode", t(postcodeLabel)),
    }),
  );

  const onSubmit = async (data: EditContactFieldValues): Promise<void> => {
    if (!currentContact?.invoiceByEmail && data.invoiceByEmail) {
      return confirm.handlePrompt(async (): Promise<void> => {
        await updateContact.execute({ ...data });
      });
    }

    return await updateContact.execute({ ...data });
  };

  return (
    <Loader
      fallback={
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      }
      ready={Boolean(contacts.data ?? contacts.error)}
      render={() => {
        if (contacts.error || !contacts.data) {
          return <QueryError onRetry={contacts.refetch} />;
        }

        return (
          <>
            <Form
              label={t("contacts.update_contact")}
              method="POST"
              noValidate
              defaultValues={{
                title: currentContact?.title,
                firstName: currentContact?.firstName,
                secondName: currentContact?.secondName,
                lastName: currentContact?.lastName,
                addressLine1: currentContact?.addressLine1,
                addressLine2: currentContact?.addressLine2,
                addressLine3: currentContact?.addressLine3,
                addressLine4: currentContact?.addressLine4,
                postcode: currentContact?.postcode,
                companyName: currentContact?.companyName,
                invoiceByEmail: currentContact?.invoiceByEmail,
                receiveSystemGeneratedCorrespondence:
                  currentContact?.receiveSystemGeneratedCorrespondence,
                type: currentContact?.type,
              }}
              resolver={resolver}
              onSubmit={onSubmit}
            >
              {(form) => (
                <Container maxWidth="sm">
                  <Paper>
                    <Stack gap={2} padding={2}>
                      <Box>
                        <EditContactFormControl
                          errors={form.formState.errors}
                          register={form.register}
                          control={form.control}
                          type={currentContact?.type}
                        />
                        {updateContact.error && (
                          <Typography color="error">
                            {t("common:error.default")}
                          </Typography>
                        )}
                      </Box>
                      <Box display="flex" justifyContent="flex-end" gap={1}>
                        <Button
                          color="primary"
                          onClick={() => navigate("../contacts")}
                        >
                          {t("common:cancel")}
                        </Button>
                        <Button
                          disabled={form.formState.isSubmitting}
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          {t("update_contact.update_contact_button")}
                        </Button>
                      </Box>
                    </Stack>
                  </Paper>
                </Container>
              )}
            </Form>
            <ConfirmationDialog
              content={
                <Typography>
                  {t("update_contact.confirmation_dialog.message")}
                </Typography>
              }
              open={confirm.isOpen}
              onConfirm={confirm.handleConfirm}
              onReject={confirm.handleReject}
              title={t("update_contact.confirmation_dialog.title")}
              labels={{
                confirm: t("common:confirm"),
                reject: t("common:cancel"),
              }}
            />
          </>
        );
      }}
    />
  );
};

export default EditContactContainer;
