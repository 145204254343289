/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing settlement quotes within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The settlement status
 * @export
 */
export const SettlementStatusModel = {
    Approved: 'Approved',
    Rejected: 'Rejected',
    Quoted: 'Quoted',
    Expired: 'Expired'
} as const;
export type SettlementStatusModel = typeof SettlementStatusModel[keyof typeof SettlementStatusModel];


export function SettlementStatusModelFromJSON(json: any): SettlementStatusModel {
    return SettlementStatusModelFromJSONTyped(json, false);
}

export function SettlementStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementStatusModel {
    return json as SettlementStatusModel;
}

export function SettlementStatusModelToJSON(value?: SettlementStatusModel | null): any {
    return value as any;
}

