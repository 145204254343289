import { PortfolioReportDialogFormValues } from "apps/reporting/types";
import { useYupResolver } from "common/hooks";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function usePortfolioReportResolver(): Resolver<PortfolioReportDialogFormValues> {
  const { t } = useTranslation("reporting");

  return useYupResolver<PortfolioReportDialogFormValues>((yup) => {
    return yup.object().shape({
      portfolio: yup.string().isRequired(t("report_dialog.portfolio_label")),
    });
  });
}
