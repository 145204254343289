/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Portfolios within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorWithParametersModel,
  PortfolioDetailsModel,
  PortfolioListItemModel,
  PortfolioModel,
} from '../models/index';
import {
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
    PortfolioDetailsModelFromJSON,
    PortfolioDetailsModelToJSON,
    PortfolioListItemModelFromJSON,
    PortfolioListItemModelToJSON,
    PortfolioModelFromJSON,
    PortfolioModelToJSON,
} from '../models/index';

export interface AddPortfolioRequest {
    portfolioModel: PortfolioModel;
}

export interface GetPortfolioDetailsRequest {
    id: string;
}

export interface ListPortfoliosRequest {
    companySystemId?: string;
}

export interface UpdatePortfolioRequest {
    id: string;
    portfolioModel: PortfolioModel;
}

/**
 * 
 */
export class PortfoliosApi extends runtime.BaseAPI {

    /**
     * Add a new Portfolio for a Company
     */
    async addPortfolioRaw(requestParameters: AddPortfolioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.portfolioModel === null || requestParameters.portfolioModel === undefined) {
            throw new runtime.RequiredError('portfolioModel','Required parameter requestParameters.portfolioModel was null or undefined when calling addPortfolio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolios:manage"]);
        }

        const response = await this.request({
            path: `/portfolios`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioModelToJSON(requestParameters.portfolioModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a new Portfolio for a Company
     */
    async addPortfolio(requestParameters: AddPortfolioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addPortfolioRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch Portfolio Details
     */
    async getPortfolioDetailsRaw(requestParameters: GetPortfolioDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PortfolioDetailsModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPortfolioDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolios:view"]);
        }

        const response = await this.request({
            path: `/portfolios/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioDetailsModelFromJSON(jsonValue));
    }

    /**
     * Fetch Portfolio Details
     */
    async getPortfolioDetails(requestParameters: GetPortfolioDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PortfolioDetailsModel> {
        const response = await this.getPortfolioDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch list of Portfolios
     */
    async listPortfoliosRaw(requestParameters: ListPortfoliosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PortfolioListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.companySystemId !== undefined) {
            queryParameters['companySystemId'] = requestParameters.companySystemId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolios:view"]);
        }

        const response = await this.request({
            path: `/portfolios`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioListItemModelFromJSON));
    }

    /**
     * Fetch list of Portfolios
     */
    async listPortfolios(requestParameters: ListPortfoliosRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PortfolioListItemModel>> {
        const response = await this.listPortfoliosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update details of a portfolio
     */
    async updatePortfolioRaw(requestParameters: UpdatePortfolioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePortfolio.');
        }

        if (requestParameters.portfolioModel === null || requestParameters.portfolioModel === undefined) {
            throw new runtime.RequiredError('portfolioModel','Required parameter requestParameters.portfolioModel was null or undefined when calling updatePortfolio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolios:manage"]);
        }

        const response = await this.request({
            path: `/portfolios/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioModelToJSON(requestParameters.portfolioModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update details of a portfolio
     */
    async updatePortfolio(requestParameters: UpdatePortfolioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePortfolioRaw(requestParameters, initOverrides);
    }

}
