import { PagedQuery, UsePagingReturn, useQueryPaged } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { UsersApi } from "generated/admin/apis";
import { UserListItemModel } from "generated/admin/models";
import { Key } from "swr";
import { keys } from ".";

export const useListUsers: PagedQuery<UserListItemModel> = ({
  pageSize,
} = {}) => {
  const usersApi = useApi(UsersApi);

  const listUsers = async (_key: Key, paging: UsePagingReturn) => {
    const response = await usersApi.listUsersRaw({
      limit: paging.pageSize,
      offset: paging.offset,
    });

    return response;
  };

  return useQueryPaged(keys.list(), listUsers, { limit: pageSize });
};
