import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { usePartialMutate } from "common/hooks";
import { useApi } from "common/providers";
import { UsersApi } from "generated/admin/apis";
import { UserStatusModel } from "generated/admin/models";
import { useSWRConfig } from "swr";
import { keys } from ".";

const useUpdateUserStatus = (
  userId: string,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const usersApi = useApi(UsersApi);
  const partialMutate = usePartialMutate();
  const swr = useSWRConfig();

  const updateStatus = async (model: UserStatusModel) => {
    const response = await usersApi.updateUserStatus({
      userId,
      userStatusModel: model,
    });

    return response;
  };

  return useCommand(updateStatus, {
    ...options,
    onSuccess: () => {
      options.onSuccess?.();
      swr.mutate(keys.detail(userId));
      partialMutate(keys.list());
    },
  });
};

export { useUpdateUserStatus };
