/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FileTypeModel = {
    PDF: 'PDF',
    DOCX: 'DOCX'
} as const;
export type FileTypeModel = typeof FileTypeModel[keyof typeof FileTypeModel];


export function FileTypeModelFromJSON(json: any): FileTypeModel {
    return FileTypeModelFromJSONTyped(json, false);
}

export function FileTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileTypeModel {
    return json as FileTypeModel;
}

export function FileTypeModelToJSON(value?: FileTypeModel | null): any {
    return value as any;
}

