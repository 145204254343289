/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressModel } from './AddressModel';
import {
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './AddressModel';

/**
 * Available third parties to associate to an agreement
 * @export
 * @interface AvailableAgreementThirdPartyListItemModel
 */
export interface AvailableAgreementThirdPartyListItemModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof AvailableAgreementThirdPartyListItemModel
     */
    readonly id: number;
    /**
     * Name of the third party
     * @type {string}
     * @memberof AvailableAgreementThirdPartyListItemModel
     */
    name: string;
    /**
     * The third party type description
     * @type {string}
     * @memberof AvailableAgreementThirdPartyListItemModel
     */
    type: string;
    /**
     * The nominated contact for the third party
     * @type {string}
     * @memberof AvailableAgreementThirdPartyListItemModel
     */
    contact?: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof AvailableAgreementThirdPartyListItemModel
     */
    address: AddressModel;
}

/**
 * Check if a given object implements the AvailableAgreementThirdPartyListItemModel interface.
 */
export function instanceOfAvailableAgreementThirdPartyListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function AvailableAgreementThirdPartyListItemModelFromJSON(json: any): AvailableAgreementThirdPartyListItemModel {
    return AvailableAgreementThirdPartyListItemModelFromJSONTyped(json, false);
}

export function AvailableAgreementThirdPartyListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableAgreementThirdPartyListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'address': AddressModelFromJSON(json['address']),
    };
}

export function AvailableAgreementThirdPartyListItemModelToJSON(value?: AvailableAgreementThirdPartyListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'contact': value.contact,
        'address': AddressModelToJSON(value.address),
    };
}

