/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Payment Instructions within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentTypeModel } from './PaymentTypeModel';
import {
    PaymentTypeModelFromJSON,
    PaymentTypeModelFromJSONTyped,
    PaymentTypeModelToJSON,
} from './PaymentTypeModel';

/**
 * A payment instruction
 * @export
 * @interface PaymentInstructionModel
 */
export interface PaymentInstructionModel {
    /**
     * Unique ID of customer/contact
     * @type {string}
     * @memberof PaymentInstructionModel
     */
    bankSystemId: string;
    /**
     * 
     * @type {PaymentTypeModel}
     * @memberof PaymentInstructionModel
     */
    type: PaymentTypeModel;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PaymentInstructionModel
     */
    startDate: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PaymentInstructionModel
     */
    endDate?: Date;
    /**
     * A format for dates
     * @type {Date}
     * @memberof PaymentInstructionModel
     */
    signatureDate?: Date;
    /**
     * Amount to be paid
     * @type {number}
     * @memberof PaymentInstructionModel
     */
    amount?: number;
    /**
     * Day of month 1 to 31
     * @type {number}
     * @memberof PaymentInstructionModel
     */
    dayOfMonth?: number;
}

/**
 * Check if a given object implements the PaymentInstructionModel interface.
 */
export function instanceOfPaymentInstructionModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bankSystemId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function PaymentInstructionModelFromJSON(json: any): PaymentInstructionModel {
    return PaymentInstructionModelFromJSONTyped(json, false);
}

export function PaymentInstructionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInstructionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankSystemId': json['bankSystemId'],
        'type': PaymentTypeModelFromJSON(json['type']),
        'startDate': (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (new Date(json['signatureDate'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'dayOfMonth': !exists(json, 'dayOfMonth') ? undefined : json['dayOfMonth'],
    };
}

export function PaymentInstructionModelToJSON(value?: PaymentInstructionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankSystemId': value.bankSystemId,
        'type': PaymentTypeModelToJSON(value.type),
        'startDate': (value.startDate.toISOString().substring(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substring(0,10)),
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate.toISOString().substring(0,10)),
        'amount': value.amount,
        'dayOfMonth': value.dayOfMonth,
    };
}

