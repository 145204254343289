import * as keys from "api/assets/valuations/keys";
import { usePartialMutate, useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { AssetCommandApi } from "generated/core/apis";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";

interface UseSubmitValuationsReturn {
  command: (body: File) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
  reset(): void;
}

export const useSubmitValuations = (
  assetId: number,
  options: {
    onSuccess?: () => void;
  } = {},
): UseSubmitValuationsReturn => {
  const api = useApi(AssetCommandApi);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("assets");
  const partialMutate = usePartialMutate();

  const error = useResponseError([
    ["DUPLICATE_DATE", t("valuations.errors.duplicate_date")],
    ["INVALID_HEADERS", t("valuations.errors.invalid_headers")],
    ["INVALID_FILE", t("valuations.errors.invalid_file")],
    ["INVALID_FILE_SIZE", t("valuations.errors.invalid_file_size")],
    ["INVALID_VALUE_FORMAT", t("valuations.errors.invalid_value_format")],
    ["INVALID_DATE_FORMAT", t("valuations.errors.invalid_date_format")],
    ["INVALID_VALUE", t("valuations.errors.invalid_value")],
    ["INVALID_DATE", t("valuations.errors.invalid_date")],
    ["INVALID_RECORD", t("valuations.errors.invalid_record")],
    ["INVALID_FILE_NO_RECORDS", t("valuations.errors.invalid_file_no_records")],
  ]);

  const command = async (body: File): Promise<void> => {
    try {
      setIsLoading(true);
      await api.submitValuations({ assetId, body });
      options.onSuccess?.();
      partialMutate(keys.list(assetId));
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = (): void => {
    error.reset();
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
    reset,
  };
};
