import { useSession, withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Box } from "@mui/material";
import UnexpectedError from "common/components/UnexpectedError";
import SidebarNavigation from "common/containers/SidebarNavigation";
import { SidebarLayout } from "common/layouts";
import { useEnvironment } from "common/providers/EnvironmentProvider";
import { compose } from "lodash/fp";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

function AdminLayout() {
  const { t } = useTranslation("admin");
  const session = useSession();
  const environment = useEnvironment();

  const makeItem = (label: string, to: string, display: boolean) => {
    return { label: t(label), to, display };
  };

  const navigatioMenu = [
    makeItem(
      "navigation_links.users",
      "./users",
      session.hasPermission("admin:users:manage"),
    ),
    makeItem(
      "navigation_links.automations",
      "./automations",
      session.hasPermission("admin:automations:view") &&
        environment.isFeatureEnabled("automations"),
    ),
    makeItem(
      "navigation_links.roles",
      "./roles",
      session.hasPermission("admin:roles:manage"),
    ),
  ];

  return (
    <Box height="100%" display="grid" gridTemplateRows="max-content 1fr">
      <SidebarLayout
        sidebar={<SidebarNavigation menu={navigatioMenu} />}
        title={t("administration_heading")}
      >
        <Box component="main" height="100%">
          <ErrorBoundary FallbackComponent={UnexpectedError}>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </SidebarLayout>
    </Box>
  );
}

export default compose(
  withProtectedView({
    allowedPermissions: [
      "admin:users:manage",
      "admin:automations:view",
      "admin:roles:manage",
    ],
  }),
)(AdminLayout);
