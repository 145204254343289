import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { OnboardStepperContainer } from "apps/onboarding/containers";
import { OnboardingProvider } from "apps/onboarding/providers";
import { ContainedLayout } from "common/layouts";
import { compose } from "lodash/fp";
import { ReactElement } from "react";
import { withTranslationLoader } from "sdk/views";

interface OnboardCustomerViewProps {
  initialActiveStep?: string;
}

function OnboardCustomerView({
  initialActiveStep,
}: OnboardCustomerViewProps): ReactElement {
  const stepRoutes = {
    companyDetails: "/",
    customerDetails: "/customer-details",
    agreementDetails: "/agreement-details",
    assetDetails: "/asset-details",
    financialDetails: "/financial-details",
    feesAndCommissionsStep: "/fees-and-commissions",
    repaymentTerms: "/repayment-terms",
  };

  return (
    <ContainedLayout maxWidth="lg">
      <OnboardingProvider>
        <OnboardStepperContainer
          initialActiveStep={initialActiveStep}
          routes={stepRoutes}
        />
      </OnboardingProvider>
    </ContainedLayout>
  );
}

export default compose(
  withTranslationLoader("onboardings"),
  withProtectedView({
    allowedPermissions: ["onboarding:onboarding:manage"],
  }),
)(OnboardCustomerView);
