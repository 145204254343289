/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing third parties within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddThirdPartyModel,
  UpdateThirdPartyModel,
} from '../models/index';
import {
    AddThirdPartyModelFromJSON,
    AddThirdPartyModelToJSON,
    UpdateThirdPartyModelFromJSON,
    UpdateThirdPartyModelToJSON,
} from '../models/index';

export interface AddThirdPartyRequest {
    addThirdPartyModel?: AddThirdPartyModel;
}

export interface UpdateThirdPartyRequest {
    thirdPartyId: number;
    updateThirdPartyModel?: UpdateThirdPartyModel;
}

/**
 * 
 */
export class ThirdPartyCommandApi extends runtime.BaseAPI {

    /**
     * Add a Third Party
     */
    async addThirdPartyRaw(requestParameters: AddThirdPartyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["third-parties:manage"]);
        }

        const response = await this.request({
            path: `/third-parties`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddThirdPartyModelToJSON(requestParameters.addThirdPartyModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a Third Party
     */
    async addThirdParty(requestParameters: AddThirdPartyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addThirdPartyRaw(requestParameters, initOverrides);
    }

    /**
     * Update third party details
     */
    async updateThirdPartyRaw(requestParameters: UpdateThirdPartyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.thirdPartyId === null || requestParameters.thirdPartyId === undefined) {
            throw new runtime.RequiredError('thirdPartyId','Required parameter requestParameters.thirdPartyId was null or undefined when calling updateThirdParty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["third-parties:manage"]);
        }

        const response = await this.request({
            path: `/third-parties/{thirdPartyId}`.replace(`{${"thirdPartyId"}}`, encodeURIComponent(String(requestParameters.thirdPartyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateThirdPartyModelToJSON(requestParameters.updateThirdPartyModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update third party details
     */
    async updateThirdParty(requestParameters: UpdateThirdPartyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateThirdPartyRaw(requestParameters, initOverrides);
    }

}
