/* tslint:disable */
/* eslint-disable */
/**
 * Engage Tasks
 * API for managing tasks
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommentModel } from './CommentModel';
import {
    CommentModelFromJSON,
    CommentModelFromJSONTyped,
    CommentModelToJSON,
} from './CommentModel';
import type { TaskStatusModel } from './TaskStatusModel';
import {
    TaskStatusModelFromJSON,
    TaskStatusModelFromJSONTyped,
    TaskStatusModelToJSON,
} from './TaskStatusModel';

/**
 * 
 * @export
 * @interface TaskModel
 */
export interface TaskModel {
    /**
     * A read only Id
     * @type {number}
     * @memberof TaskModel
     */
    readonly id: number;
    /**
     * The title of the task
     * @type {string}
     * @memberof TaskModel
     */
    title: string;
    /**
     * 
     * @type {TaskStatusModel}
     * @memberof TaskModel
     */
    status: TaskStatusModel;
    /**
     * The person assigned to the task
     * @type {string}
     * @memberof TaskModel
     */
    assignedTo?: string;
    /**
     * The ID of the assignee the task is assigned to
     * @type {string}
     * @memberof TaskModel
     */
    assignedToId?: string;
    /**
     * A date time format
     * @type {string}
     * @memberof TaskModel
     */
    dueAt: string;
    /**
     * The Name of the customer related to the task
     * @type {string}
     * @memberof TaskModel
     */
    customerName?: string;
    /**
     * A read only Id
     * @type {number}
     * @memberof TaskModel
     */
    readonly customerId?: number;
    /**
     * The agreement number of the agreement related to the task
     * @type {string}
     * @memberof TaskModel
     */
    agreementNumber?: string;
    /**
     * A read only Id
     * @type {number}
     * @memberof TaskModel
     */
    readonly agreementId?: number;
    /**
     * The description of the task
     * @type {string}
     * @memberof TaskModel
     */
    description: string;
    /**
     * 
     * @type {Array<CommentModel>}
     * @memberof TaskModel
     */
    comments: Array<CommentModel>;
}

/**
 * Check if a given object implements the TaskModel interface.
 */
export function instanceOfTaskModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "dueAt" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "comments" in value;

    return isInstance;
}

export function TaskModelFromJSON(json: any): TaskModel {
    return TaskModelFromJSONTyped(json, false);
}

export function TaskModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'status': TaskStatusModelFromJSON(json['status']),
        'assignedTo': !exists(json, 'assignedTo') ? undefined : json['assignedTo'],
        'assignedToId': !exists(json, 'assignedToId') ? undefined : json['assignedToId'],
        'dueAt': json['dueAt'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'agreementNumber': !exists(json, 'agreementNumber') ? undefined : json['agreementNumber'],
        'agreementId': !exists(json, 'agreementId') ? undefined : json['agreementId'],
        'description': json['description'],
        'comments': ((json['comments'] as Array<any>).map(CommentModelFromJSON)),
    };
}

export function TaskModelToJSON(value?: TaskModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'status': TaskStatusModelToJSON(value.status),
        'assignedTo': value.assignedTo,
        'assignedToId': value.assignedToId,
        'dueAt': value.dueAt,
        'customerName': value.customerName,
        'agreementNumber': value.agreementNumber,
        'description': value.description,
        'comments': ((value.comments as Array<any>).map(CommentModelToJSON)),
    };
}

