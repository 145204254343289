/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing financial products within onboarding
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SettlementTypeModel } from './SettlementTypeModel';
import {
    SettlementTypeModelFromJSON,
    SettlementTypeModelFromJSONTyped,
    SettlementTypeModelToJSON,
} from './SettlementTypeModel';

/**
 * 
 * @export
 * @interface UpdateFinancialProductModel
 */
export interface UpdateFinancialProductModel {
    /**
     * 
     * @type {SettlementTypeModel}
     * @memberof UpdateFinancialProductModel
     */
    settlementType: SettlementTypeModel;
    /**
     * The discount percentage
     * @type {number}
     * @memberof UpdateFinancialProductModel
     */
    discountPercentage: number;
}

/**
 * Check if a given object implements the UpdateFinancialProductModel interface.
 */
export function instanceOfUpdateFinancialProductModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "settlementType" in value;
    isInstance = isInstance && "discountPercentage" in value;

    return isInstance;
}

export function UpdateFinancialProductModelFromJSON(json: any): UpdateFinancialProductModel {
    return UpdateFinancialProductModelFromJSONTyped(json, false);
}

export function UpdateFinancialProductModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFinancialProductModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settlementType': SettlementTypeModelFromJSON(json['settlementType']),
        'discountPercentage': json['discountPercentage'],
    };
}

export function UpdateFinancialProductModelToJSON(value?: UpdateFinancialProductModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settlementType': SettlementTypeModelToJSON(value.settlementType),
        'discountPercentage': value.discountPercentage,
    };
}

