/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing third parties within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ThirdPartyListItemModel,
  ThirdPartyTypeListItemModel,
} from '../models/index';
import {
    ThirdPartyListItemModelFromJSON,
    ThirdPartyListItemModelToJSON,
    ThirdPartyTypeListItemModelFromJSON,
    ThirdPartyTypeListItemModelToJSON,
} from '../models/index';

export interface ListThirdPartiesRequest {
    offset?: number;
    limit?: number;
    type?: string;
    query?: string;
}

/**
 * 
 */
export class ThirdPartyQueryApi extends runtime.BaseAPI {

    /**
     * Provides an API to list third parties
     */
    async listThirdPartiesRaw(requestParameters: ListThirdPartiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ThirdPartyListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["third-parties:view"]);
        }

        const response = await this.request({
            path: `/third-parties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ThirdPartyListItemModelFromJSON));
    }

    /**
     * Provides an API to list third parties
     */
    async listThirdParties(requestParameters: ListThirdPartiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ThirdPartyListItemModel>> {
        const response = await this.listThirdPartiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List third party types
     */
    async listThirdPartyTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ThirdPartyTypeListItemModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["third-party-types:view"]);
        }

        const response = await this.request({
            path: `/third-party-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ThirdPartyTypeListItemModelFromJSON));
    }

    /**
     * List third party types
     */
    async listThirdPartyTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ThirdPartyTypeListItemModel>> {
        const response = await this.listThirdPartyTypesRaw(initOverrides);
        return await response.value();
    }

}
