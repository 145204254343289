import { Grid, TextField } from "@mui/material";
import { BankAccountFormValues } from "apps/servicing/modules/customers/hooks/useBankAccountResolver";
import { ReactElement } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface BankAccountFieldSetProps {
  errors: FieldErrors<BankAccountFormValues>;
  register: UseFormRegister<BankAccountFormValues>;
}

export default function BankAccountFieldSet({
  errors,
  register,
}: BankAccountFieldSetProps): ReactElement {
  const { t } = useTranslation(["clients", "common"]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            {...register("bankName")}
            error={Boolean(errors.bankName)}
            helperText={errors.bankName?.message}
            label={t("manage_bank_account.bank_name_label")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine1")}
            error={Boolean(errors.addressLine1)}
            helperText={errors.addressLine1?.message}
            label={t("manage_bank_account.address_line_1_label")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine2")}
            error={Boolean(errors.addressLine2)}
            helperText={errors.addressLine2?.message}
            label={t("manage_bank_account.address_line_2_label")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine3")}
            error={Boolean(errors.addressLine3)}
            helperText={errors.addressLine3?.message}
            label={t("manage_bank_account.address_line_3_label")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("addressLine4")}
            error={Boolean(errors.addressLine4)}
            helperText={errors.addressLine4?.message}
            label={t("manage_bank_account.address_line_4_label")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            {...register("postcode")}
            error={Boolean(errors.postcode)}
            helperText={errors.postcode?.message}
            label={t("manage_bank_account.postcode_label")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            {...register("accountHolderName")}
            error={Boolean(errors.accountHolderName)}
            helperText={errors.accountHolderName?.message}
            label={t("manage_bank_account.account_holders_name_label")}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            {...register("accountNumber")}
            error={Boolean(errors.accountNumber)}
            helperText={errors.accountNumber?.message}
            label={t("manage_bank_account.account_number_label")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("sortCode")}
            error={Boolean(errors.sortCode)}
            helperText={errors.sortCode?.message}
            label={t("manage_bank_account.sort_code_label")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            {...register("iban")}
            error={Boolean(errors.iban)}
            helperText={errors.iban?.message}
            label={t("manage_bank_account.iban_label")}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            {...register("bic")}
            error={Boolean(errors.bic)}
            helperText={errors.bic?.message}
            label={t("manage_bank_account.bic_label")}
          />
        </Grid>
      </Grid>
    </>
  );
}
