/* tslint:disable */
/* eslint-disable */
/**
 * Engage Admin
 * API for managing roles within the Engage Admin application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleModel
 */
export interface RoleModel {
    /**
     * Read only Id of role
     * @type {number}
     * @memberof RoleModel
     */
    readonly id: number;
    /**
     * The name of the role
     * @type {string}
     * @memberof RoleModel
     */
    name: string;
    /**
     * The description of the role
     * @type {string}
     * @memberof RoleModel
     */
    description: string;
    /**
     * Creator of the role
     * @type {string}
     * @memberof RoleModel
     */
    createdBy: string;
    /**
     * Total number of users assigned to this role
     * @type {number}
     * @memberof RoleModel
     */
    usersAssigned: number;
    /**
     * Denotes whether a user can update the role
     * @type {boolean}
     * @memberof RoleModel
     */
    updatable: boolean;
}

/**
 * Check if a given object implements the RoleModel interface.
 */
export function instanceOfRoleModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "usersAssigned" in value;
    isInstance = isInstance && "updatable" in value;

    return isInstance;
}

export function RoleModelFromJSON(json: any): RoleModel {
    return RoleModelFromJSONTyped(json, false);
}

export function RoleModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'createdBy': json['createdBy'],
        'usersAssigned': json['usersAssigned'],
        'updatable': json['updatable'],
    };
}

export function RoleModelToJSON(value?: RoleModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'createdBy': value.createdBy,
        'usersAssigned': value.usersAssigned,
        'updatable': value.updatable,
    };
}

