import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useGetBankAccountDetails } from "api/customers/bank-accounts/getBankAccountDetails";
import { useUpdateBankAccount } from "api/customers/bank-accounts/updateBankAccount";
import BankAccountFieldSet from "apps/servicing/modules/customers/components/BankAccountFieldSet";
import { useBankAccountResolver } from "apps/servicing/modules/customers/hooks";
import { BankAccountFormValues } from "apps/servicing/modules/customers/hooks/useBankAccountResolver";
import { Loader, QueryError } from "common/components";
import { useConfirm } from "common/hooks";
import { BankAccountDetailsModel } from "generated/servicing-v2/models";
import { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface UpdateBankAccountProps {
  clientId: string;
  bankAccountId: string;
  onSubmit(): void;
  onClose(): void;
  open: boolean;
}

interface UpdateBankAccountFormDialogProps {
  bankAccountId: string;
  defaultValues: BankAccountDetailsModel;
  customerId: string;
  onSubmit(): void;
  onClose(): void;
  open: boolean;
}

function UpdateBankAccountFormDialog({
  bankAccountId,
  defaultValues,
  customerId,
  onSubmit: onSubmitCallback,
  onClose,
  open,
}: Readonly<UpdateBankAccountFormDialogProps>) {
  const { t } = useTranslation(["clients", "common"]);
  const updateBankAccount = useUpdateBankAccount(customerId, bankAccountId, {
    onSuccess: onSubmitCallback,
  });
  const onCloseErrorDialog = (): void => updateBankAccount.reset();
  const confirm = useConfirm();
  const resolver = useBankAccountResolver();
  const { reset, ...form } = useForm<BankAccountFormValues>({
    defaultValues: {
      ...defaultValues,
      typeId: customerId,
      bankName: defaultValues.bankName,
      addressLine1: defaultValues.addressLine1,
      addressLine2: defaultValues.addressLine2,
      addressLine3: defaultValues.addressLine3,
      addressLine4: defaultValues.addressLine4,
      postcode: defaultValues.postcode,
      accountNumber: defaultValues.accountNumber,
      sortCode: defaultValues.sortCode,
      accountHolderName: defaultValues.accountHoldersName,
    },
    resolver,
  });
  useEffect(() => {
    reset();
  }, [open, reset]);
  const titleLabel = t("edit_bank_account.title_label");
  const migrateMandates = form.watch("migrateMandates");

  const onSubmit = async (data: BankAccountFormValues): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      const model = {
        bankName: data.bankName,
        accountHoldersName: data.accountHolderName,
        accountNumber: data.accountNumber,
        sortCode: data.sortCode,
        iban: data.iban === "" ? undefined : data.iban,
        bic: data.bic === "" ? undefined : data.bic,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        addressLine3: data.addressLine3,
        addressLine4: data.addressLine4,
        postcode: data.postcode,
        migrateMandates: data.migrateMandates,
        isCustomer: Boolean(defaultValues.isCustomer),
      };
      updateBankAccount.execute(model);
    });
  };

  return (
    <>
      <Dialog
        aria-labelledby="edit-bank-account-title"
        maxWidth="sm"
        open={open}
        onClose={onClose}
        fullWidth
      >
        <form
          method="POST"
          aria-label={titleLabel}
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <DialogTitle id="edit-bank-account-title">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>{titleLabel}</Box>
              <Box>
                <IconButton
                  aria-label={t("common:close")}
                  onClick={onClose}
                  size="small"
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent>
            <BankAccountFieldSet
              errors={form.formState.errors}
              register={form.register}
            />

            <FormControlLabel
              {...form.register("migrateMandates")}
              name="migrateMandates"
              label={String(t("edit_bank_account.migrate_mandates_label"))}
              aria-label={t("edit_bank_account.migrate_mandates_label")}
              labelPlacement="end"
              control={<Checkbox color="primary" />}
            />

            {updateBankAccount.error && (
              <Typography color="error" data-testid="form.error">
                {t("common:error.default")}
              </Typography>
            )}
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={onClose}>
              {t("common:cancel")}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {t("edit_bank_account.edit_account_button")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationDialog
        content={
          <Typography>
            {migrateMandates
              ? t("edit_bank_account.confirmation_message_migrating_mandates")
              : t(
                  "edit_bank_account.confirmation_message_no_mandates_migrated",
                )}
          </Typography>
        }
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("edit_bank_account.confirmation_title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        title={t("edit_bank_account.error_title")}
        content={updateBankAccount.error?.message ?? t("common:error.default")}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(updateBankAccount.error)}
      />
    </>
  );
}

export default function UpdateBankAccount({
  clientId,
  bankAccountId,
  onSubmit: onSubmitCallback,
  onClose,
  open,
}: Readonly<UpdateBankAccountProps>): ReactElement {
  const bankDetails = useGetBankAccountDetails(bankAccountId);

  return (
    <Loader
      ready={Boolean(bankDetails.data ?? bankDetails.error)}
      render={() => {
        if (!bankDetails.data || bankDetails.error) {
          return <QueryError onRetry={bankDetails.refetch} />;
        }

        return (
          <UpdateBankAccountFormDialog
            defaultValues={bankDetails.data}
            customerId={clientId}
            bankAccountId={bankAccountId}
            onClose={onClose}
            onSubmit={onSubmitCallback}
            open={open}
          />
        );
      }}
    />
  );
}
