/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeInstalmentPaymentDateModel
 */
export interface ChangeInstalmentPaymentDateModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof ChangeInstalmentPaymentDateModel
     */
    newInstalmentDate: Date;
    /**
     * Whether to amend all future instalments or this single instalment
     * @type {boolean}
     * @memberof ChangeInstalmentPaymentDateModel
     */
    updateFutureInstalments: boolean;
}

/**
 * Check if a given object implements the ChangeInstalmentPaymentDateModel interface.
 */
export function instanceOfChangeInstalmentPaymentDateModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "newInstalmentDate" in value;
    isInstance = isInstance && "updateFutureInstalments" in value;

    return isInstance;
}

export function ChangeInstalmentPaymentDateModelFromJSON(json: any): ChangeInstalmentPaymentDateModel {
    return ChangeInstalmentPaymentDateModelFromJSONTyped(json, false);
}

export function ChangeInstalmentPaymentDateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeInstalmentPaymentDateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newInstalmentDate': (new Date(json['newInstalmentDate'])),
        'updateFutureInstalments': json['updateFutureInstalments'],
    };
}

export function ChangeInstalmentPaymentDateModelToJSON(value?: ChangeInstalmentPaymentDateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newInstalmentDate': (value.newInstalmentDate.toISOString().substring(0,10)),
        'updateFutureInstalments': value.updateFutureInstalments,
    };
}

