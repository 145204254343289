/* tslint:disable */
/* eslint-disable */
/**
 * Engage Tasks
 * API for managing tasks
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditTaskModel
 */
export interface EditTaskModel {
    /**
     * The task Title
     * @type {string}
     * @memberof EditTaskModel
     */
    title: string;
    /**
     * The task description
     * @type {string}
     * @memberof EditTaskModel
     */
    description: string;
    /**
     * The due date and time for the task
     * @type {string}
     * @memberof EditTaskModel
     */
    dueAt: string;
    /**
     * The ID of the assignee the task is assigned to
     * @type {string}
     * @memberof EditTaskModel
     */
    assigneeToId?: string;
    /**
     * The id of the customer related to the task
     * @type {number}
     * @memberof EditTaskModel
     */
    customerId?: number;
    /**
     * The id of the agreement related to the task
     * @type {number}
     * @memberof EditTaskModel
     */
    agreementId?: number;
}

/**
 * Check if a given object implements the EditTaskModel interface.
 */
export function instanceOfEditTaskModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "dueAt" in value;

    return isInstance;
}

export function EditTaskModelFromJSON(json: any): EditTaskModel {
    return EditTaskModelFromJSONTyped(json, false);
}

export function EditTaskModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditTaskModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'dueAt': json['dueAt'],
        'assigneeToId': !exists(json, 'assigneeToId') ? undefined : json['assigneeToId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'agreementId': !exists(json, 'agreementId') ? undefined : json['agreementId'],
    };
}

export function EditTaskModelToJSON(value?: EditTaskModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'dueAt': value.dueAt,
        'assigneeToId': value.assigneeToId,
        'customerId': value.customerId,
        'agreementId': value.agreementId,
    };
}

