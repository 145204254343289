/* tslint:disable */
/* eslint-disable */
/**
 * Engage Admin
 * API for managing roles within the Engage Admin application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateRoleModel,
  ErrorWithParametersModel,
  RoleDetailsModel,
  RoleModel,
  UpdateRoleModel,
} from '../models/index';
import {
    CreateRoleModelFromJSON,
    CreateRoleModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
    RoleDetailsModelFromJSON,
    RoleDetailsModelToJSON,
    RoleModelFromJSON,
    RoleModelToJSON,
    UpdateRoleModelFromJSON,
    UpdateRoleModelToJSON,
} from '../models/index';

export interface AssociatePermissionRequest {
    roleId: number;
    permissionId: number;
}

export interface CreateRoleRequest {
    createRoleModel?: CreateRoleModel;
}

export interface DeleteRoleRequest {
    roleId: number;
}

export interface DisassociatePermissionRequest {
    roleId: number;
    permissionId: number;
}

export interface GetRoleRequest {
    roleId: number;
}

export interface UpdateRoleRequest {
    roleId: number;
    updateRoleModel?: UpdateRoleModel;
}

/**
 * 
 */
export class RolesApi extends runtime.BaseAPI {

    /**
     * Associate permission to a role
     */
    async associatePermissionRaw(requestParameters: AssociatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling associatePermission.');
        }

        if (requestParameters.permissionId === null || requestParameters.permissionId === undefined) {
            throw new runtime.RequiredError('permissionId','Required parameter requestParameters.permissionId was null or undefined when calling associatePermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["roles:manage"]);
        }

        const response = await this.request({
            path: `/roles/{roleId}/permissions/{permissionId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))).replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters.permissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Associate permission to a role
     */
    async associatePermission(requestParameters: AssociatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.associatePermissionRaw(requestParameters, initOverrides);
    }

    /**
     * Create Role
     */
    async createRoleRaw(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["roles:manage"]);
        }

        const response = await this.request({
            path: `/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRoleModelToJSON(requestParameters.createRoleModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Role
     */
    async createRole(requestParameters: CreateRoleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRoleRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a Role
     */
    async deleteRoleRaw(requestParameters: DeleteRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling deleteRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["roles:manage"]);
        }

        const response = await this.request({
            path: `/roles/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Role
     */
    async deleteRole(requestParameters: DeleteRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRoleRaw(requestParameters, initOverrides);
    }

    /**
     * Disassociate permission from a role
     */
    async disassociatePermissionRaw(requestParameters: DisassociatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling disassociatePermission.');
        }

        if (requestParameters.permissionId === null || requestParameters.permissionId === undefined) {
            throw new runtime.RequiredError('permissionId','Required parameter requestParameters.permissionId was null or undefined when calling disassociatePermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["roles:manage"]);
        }

        const response = await this.request({
            path: `/roles/{roleId}/permissions/{permissionId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))).replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters.permissionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disassociate permission from a role
     */
    async disassociatePermission(requestParameters: DisassociatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.disassociatePermissionRaw(requestParameters, initOverrides);
    }

    /**
     * Get role details and categorised role permissions
     */
    async getRoleRaw(requestParameters: GetRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleDetailsModel>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["roles:view"]);
        }

        const response = await this.request({
            path: `/roles/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDetailsModelFromJSON(jsonValue));
    }

    /**
     * Get role details and categorised role permissions
     */
    async getRole(requestParameters: GetRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleDetailsModel> {
        const response = await this.getRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Roles
     */
    async listRolesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["roles:view"]);
        }

        const response = await this.request({
            path: `/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleModelFromJSON));
    }

    /**
     * List Roles
     */
    async listRoles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleModel>> {
        const response = await this.listRolesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update Role
     */
    async updateRoleRaw(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling updateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["roles:manage"]);
        }

        const response = await this.request({
            path: `/roles/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRoleModelToJSON(requestParameters.updateRoleModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Role
     */
    async updateRole(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRoleRaw(requestParameters, initOverrides);
    }

}
