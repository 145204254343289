/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddContactModel,
  AddCorrespondenceMethodModel,
  AddMemoModel,
  CreateBankAccountModel,
  UpdateBankAccountModel,
  UpdateClientModel,
  UpdateContactModel,
  UpdateCorrespondenceMethodModel,
} from '../models/index';
import {
    AddContactModelFromJSON,
    AddContactModelToJSON,
    AddCorrespondenceMethodModelFromJSON,
    AddCorrespondenceMethodModelToJSON,
    AddMemoModelFromJSON,
    AddMemoModelToJSON,
    CreateBankAccountModelFromJSON,
    CreateBankAccountModelToJSON,
    UpdateBankAccountModelFromJSON,
    UpdateBankAccountModelToJSON,
    UpdateClientModelFromJSON,
    UpdateClientModelToJSON,
    UpdateContactModelFromJSON,
    UpdateContactModelToJSON,
    UpdateCorrespondenceMethodModelFromJSON,
    UpdateCorrespondenceMethodModelToJSON,
} from '../models/index';

export interface AddClientMemoRequest {
    clientId: number;
    addMemoModel?: AddMemoModel;
}

export interface AddContactRequest {
    clientId: number;
    addContactModel?: AddContactModel;
}

export interface AddCorrespondenceMethodRequest {
    clientId: number;
    contactId: number;
    addCorrespondenceMethodModel?: AddCorrespondenceMethodModel;
}

export interface CancelMandatesRequest {
    clientId: number;
    bankAccountId: number;
}

export interface CreateBankAccountRequest {
    clientId: number;
    createBankAccountModel?: CreateBankAccountModel;
}

export interface DeleteBankAccountRequest {
    clientId: number;
    bankAccountId: number;
}

export interface DeleteContactRequest {
    clientId: number;
    contactId: number;
}

export interface DeleteCorrespondenceMethodRequest {
    clientId: number;
    contactId: number;
    correspondenceMethodId: number;
}

export interface UpdateBankAccountRequest {
    clientId: number;
    bankAccountId: number;
    updateBankAccountModel?: UpdateBankAccountModel;
}

export interface UpdateClientRequest {
    clientId: number;
    updateClientModel?: UpdateClientModel;
}

export interface UpdateContactRequest {
    clientId: number;
    contactId: number;
    updateContactModel?: UpdateContactModel;
}

export interface UpdateCorrespondenceMethodRequest {
    clientId: number;
    contactId: number;
    correspondenceMethodId: number;
    updateCorrespondenceMethodModel?: UpdateCorrespondenceMethodModel;
}

/**
 * 
 */
export class ClientCommandApi extends runtime.BaseAPI {

    /**
     * Add a new client memo
     */
    async addClientMemoRaw(requestParameters: AddClientMemoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling addClientMemo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["client-memos:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/memos`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMemoModelToJSON(requestParameters.addMemoModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a new client memo
     */
    async addClientMemo(requestParameters: AddClientMemoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addClientMemoRaw(requestParameters, initOverrides);
    }

    /**
     * Add a contact to a customer
     */
    async addContactRaw(requestParameters: AddContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling addContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["contacts:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/contacts`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddContactModelToJSON(requestParameters.addContactModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a contact to a customer
     */
    async addContact(requestParameters: AddContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addContactRaw(requestParameters, initOverrides);
    }

    /**
     * Add a correspondence method to a contact
     */
    async addCorrespondenceMethodRaw(requestParameters: AddCorrespondenceMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling addCorrespondenceMethod.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling addCorrespondenceMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["correspondence-methods:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/contacts/{contactId}/correspondence-methods`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCorrespondenceMethodModelToJSON(requestParameters.addCorrespondenceMethodModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a correspondence method to a contact
     */
    async addCorrespondenceMethod(requestParameters: AddCorrespondenceMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addCorrespondenceMethodRaw(requestParameters, initOverrides);
    }

    /**
     * Cancel mandates associated to a bank account
     */
    async cancelMandatesRaw(requestParameters: CancelMandatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling cancelMandates.');
        }

        if (requestParameters.bankAccountId === null || requestParameters.bankAccountId === undefined) {
            throw new runtime.RequiredError('bankAccountId','Required parameter requestParameters.bankAccountId was null or undefined when calling cancelMandates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["cancel-mandates:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/bank-accounts/{bankAccountId}/mandates`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"bankAccountId"}}`, encodeURIComponent(String(requestParameters.bankAccountId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel mandates associated to a bank account
     */
    async cancelMandates(requestParameters: CancelMandatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelMandatesRaw(requestParameters, initOverrides);
    }

    /**
     * Create a bank account
     */
    async createBankAccountRaw(requestParameters: CreateBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling createBankAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["bank-accounts:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/bank-accounts`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBankAccountModelToJSON(requestParameters.createBankAccountModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a bank account
     */
    async createBankAccount(requestParameters: CreateBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createBankAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a bank account
     */
    async deleteBankAccountRaw(requestParameters: DeleteBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling deleteBankAccount.');
        }

        if (requestParameters.bankAccountId === null || requestParameters.bankAccountId === undefined) {
            throw new runtime.RequiredError('bankAccountId','Required parameter requestParameters.bankAccountId was null or undefined when calling deleteBankAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["bank-accounts:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/bank-accounts/{bankAccountId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"bankAccountId"}}`, encodeURIComponent(String(requestParameters.bankAccountId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a bank account
     */
    async deleteBankAccount(requestParameters: DeleteBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteBankAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a contact related to a customer
     */
    async deleteContactRaw(requestParameters: DeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling deleteContact.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling deleteContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["contacts:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/contacts/{contactId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a contact related to a customer
     */
    async deleteContact(requestParameters: DeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteContactRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a correspondence method for a contact
     */
    async deleteCorrespondenceMethodRaw(requestParameters: DeleteCorrespondenceMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling deleteCorrespondenceMethod.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling deleteCorrespondenceMethod.');
        }

        if (requestParameters.correspondenceMethodId === null || requestParameters.correspondenceMethodId === undefined) {
            throw new runtime.RequiredError('correspondenceMethodId','Required parameter requestParameters.correspondenceMethodId was null or undefined when calling deleteCorrespondenceMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["correspondence-methods:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/contacts/{contactId}/correspondence-methods/{correspondenceMethodId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))).replace(`{${"correspondenceMethodId"}}`, encodeURIComponent(String(requestParameters.correspondenceMethodId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a correspondence method for a contact
     */
    async deleteCorrespondenceMethod(requestParameters: DeleteCorrespondenceMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCorrespondenceMethodRaw(requestParameters, initOverrides);
    }

    /**
     * Update a bank account
     */
    async updateBankAccountRaw(requestParameters: UpdateBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling updateBankAccount.');
        }

        if (requestParameters.bankAccountId === null || requestParameters.bankAccountId === undefined) {
            throw new runtime.RequiredError('bankAccountId','Required parameter requestParameters.bankAccountId was null or undefined when calling updateBankAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["bank-accounts:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/bank-accounts/{bankAccountId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"bankAccountId"}}`, encodeURIComponent(String(requestParameters.bankAccountId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBankAccountModelToJSON(requestParameters.updateBankAccountModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a bank account
     */
    async updateBankAccount(requestParameters: UpdateBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateBankAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Update client details
     */
    async updateClientRaw(requestParameters: UpdateClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling updateClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["contacts:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateClientModelToJSON(requestParameters.updateClientModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update client details
     */
    async updateClient(requestParameters: UpdateClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateClientRaw(requestParameters, initOverrides);
    }

    /**
     * Update a contact related to a customer
     */
    async updateContactRaw(requestParameters: UpdateContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling updateContact.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling updateContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["contacts:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/contacts/{contactId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContactModelToJSON(requestParameters.updateContactModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a contact related to a customer
     */
    async updateContact(requestParameters: UpdateContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateContactRaw(requestParameters, initOverrides);
    }

    /**
     * Update a correspondence method for a contact
     */
    async updateCorrespondenceMethodRaw(requestParameters: UpdateCorrespondenceMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling updateCorrespondenceMethod.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling updateCorrespondenceMethod.');
        }

        if (requestParameters.correspondenceMethodId === null || requestParameters.correspondenceMethodId === undefined) {
            throw new runtime.RequiredError('correspondenceMethodId','Required parameter requestParameters.correspondenceMethodId was null or undefined when calling updateCorrespondenceMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["correspondence-methods:manage"]);
        }

        const response = await this.request({
            path: `/clients/{clientId}/contacts/{contactId}/correspondence-methods/{correspondenceMethodId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))).replace(`{${"correspondenceMethodId"}}`, encodeURIComponent(String(requestParameters.correspondenceMethodId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCorrespondenceMethodModelToJSON(requestParameters.updateCorrespondenceMethodModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a correspondence method for a contact
     */
    async updateCorrespondenceMethod(requestParameters: UpdateCorrespondenceMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCorrespondenceMethodRaw(requestParameters, initOverrides);
    }

}
