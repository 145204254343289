/* tslint:disable */
/* eslint-disable */
/**
 * Engage Tasks
 * API for managing tasks
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssigneeListItemModel
 */
export interface AssigneeListItemModel {
    /**
     * The assignees ID
     * @type {string}
     * @memberof AssigneeListItemModel
     */
    id: string;
    /**
     * The first name of the assignee
     * @type {string}
     * @memberof AssigneeListItemModel
     */
    firstName?: string;
    /**
     * The last name of the assignee
     * @type {string}
     * @memberof AssigneeListItemModel
     */
    lastName?: string;
}

/**
 * Check if a given object implements the AssigneeListItemModel interface.
 */
export function instanceOfAssigneeListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function AssigneeListItemModelFromJSON(json: any): AssigneeListItemModel {
    return AssigneeListItemModelFromJSONTyped(json, false);
}

export function AssigneeListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssigneeListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
    };
}

export function AssigneeListItemModelToJSON(value?: AssigneeListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}

