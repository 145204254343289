import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ResponseError } from "generated";
import { CustomerContactPointsApi } from "generated/servicing-v2/apis";
import { ContactsContactPointsApi } from "generated/servicing-v2/apis/ContactsContactPointsApi";
import { ContactPointModel } from "generated/servicing-v2/models";
import { useSWRConfig } from "swr";
import { keys } from ".";

export const useUpdateContactPoint = (
  id: string,
  contactPointId: string,
  forCustomer: boolean,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const customerApi = useApi(CustomerContactPointsApi, "v2");
  const contactApi = useApi(ContactsContactPointsApi, "v2");
  const swr = useSWRConfig();

  return useCommand<ContactPointModel, void, ResponseError>(
    (model) => {
      if (forCustomer) {
        return customerApi.updateCustomerContactPoints({
          id,
          contactPointId,
          contactPointModel: model,
        });
      } else {
        return contactApi.updateContactContactPoints({
          id: id,
          contactPointId,
          contactPointModel: model,
        });
      }
    },
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.list(id));
        options.onSuccess?.();
      },
    },
  );
};
