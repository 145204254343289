import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { AgreementQueryApi } from "generated/core/apis";
import { AgreementAssetListItemModel } from "generated/core/models";
import { keys } from ".";

type UseListAgreementAssets = (
  agreementId: number,
  params?: { live?: boolean },
) => UseQueryReturn<AgreementAssetListItemModel[]>;

export const useListAgreementAssets: UseListAgreementAssets = (
  agreementId: number,
  params?: { live?: boolean },
) => {
  const api = useApi(AgreementQueryApi);

  return useQuery(keys.list(agreementId, params), () =>
    api.listAgreementAssets({
      agreementId: agreementId,
      live: params?.live,
    }),
  );
};
