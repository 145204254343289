import UnexpectedError from "common/components/UnexpectedError";
import { Params, useLocation, useParams } from "react-router-dom";

const isSafeParams = <TParam extends string>(
  expectedParams: TParam[],
  actualParams: Params<TParam>,
): actualParams is Record<TParam, string> =>
  expectedParams.length === 0 ||
  expectedParams.every((param) => Object.keys(actualParams).includes(param));

interface RouteAdapterProps<
  TParam extends string,
  TSearchParam extends string = string,
> {
  params?: TParam[];
  render: (props: {
    params: Record<TParam, string>;
    searchParams: Record<TSearchParam, string | undefined>;
  }) => React.ReactElement;
}

const RouteAdapter = <
  TSearchParam extends string,
  TParam extends string = string,
>({
  params = [],
  render,
}: RouteAdapterProps<TParam, TSearchParam>) => {
  const actualParams = useParams<TParam>() as Params<TParam>;
  const location = useLocation();

  if (!isSafeParams(params, actualParams)) {
    return <UnexpectedError />;
  }

  const searchParamsObj = Object.fromEntries(
    new URLSearchParams(location.search),
  ) as Record<TSearchParam, string | undefined>;

  return render({ params: actualParams, searchParams: searchParamsObj });
};

export { RouteAdapter };
