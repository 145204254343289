import { Box, Typography } from "@mui/material";
import { useGetAsset } from "api/assets/getAsset";
import CreateAssetPartialSettlementQuoteContainer from "apps/servicing/containers/CreateAssetPartialSettlementQuoteContainer";
import { Loader } from "common/components";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface AssetPartialSettlementQuotesContainerProps {
  assetId: number;
}

export default function AssetPartialSettlementQuotesContainer({
  assetId,
}: AssetPartialSettlementQuotesContainerProps): ReactElement {
  const { t } = useTranslation("assets");
  const asset = useGetAsset(assetId);

  return (
    <Loader
      ready={Boolean(asset.error ?? asset.data)}
      render={(): ReactElement => {
        if (asset.error || !asset.data) {
          return (
            <Typography color="error" role="alert">
              {t("common:error.default")}
            </Typography>
          );
        }

        return (
          <Box textAlign="center">
            <Typography color="textSecondary" gutterBottom>
              {t("partial_settlement_quotes.no_partial_settlement_quotes")}
            </Typography>
            <CreateAssetPartialSettlementQuoteContainer asset={asset.data} />
          </Box>
        );
      }}
    />
  );
}
