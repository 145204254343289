import CCRReportFormDialog, {
  CCRFormValues,
} from "apps/reporting/components/CCRReportFormDialog/CCRReportFormDialog";
import { useReportDownload } from "apps/reporting/hooks";
import { useYupResolver } from "common/hooks";
import { useApi } from "common/providers";
import { PortfolioQueryApi, ReportQueryApi } from "generated/core/apis";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

interface CCRReportContainerProps {
  onClose: () => void;
  open: boolean;
}

const CCRReportContainer = ({ onClose, open }: CCRReportContainerProps) => {
  const { t } = useTranslation("reporting");
  const portfolioQueryApi = useApi(PortfolioQueryApi);
  const reportApi = useApi(ReportQueryApi);
  const ccrProviderCodes = useSWR(["portfolios"], () =>
    portfolioQueryApi.listPortfolios(),
  ).data?.flatMap((portfolio) => portfolio.ccrProviderCode ?? []);

  const resolver = useYupResolver<CCRFormValues>((yup) =>
    yup.object().shape({
      ccrProviderCode: yup
        .string()
        .isRequired(t("report_dialog.ccr.ccr_provider_code_label")),
      reportDetail: yup
        .object()
        .shape({
          ccrCode: yup.string(),
          snapshotId: yup.number(""),
          reportPeriod: yup.string(),
        })
        .test({
          name: "allFieldsRequired",
          test: (value, context) => {
            if (value.ccrCode || value.snapshotId || value.reportPeriod) {
              return true;
            }
            return context.createError({
              message: t("common:validation.is_required", {
                label: t("report_dialog.ccr.reporting_period_label"),
              }),
            });
          },
        }),
    }),
  );

  const ccrReportApi = (data: CCRFormValues) =>
    reportApi.centralCreditRegisterRaw({
      ccrCode: String(data.reportDetail.ccrCode),
      ccrSnapshotId: Number(data.reportDetail.snapshotId),
    });

  const ccrReportDownload = useReportDownload(ccrReportApi, {
    onSuccess: onClose,
  });

  const onSubmit = async (data: CCRFormValues) => {
    await ccrReportDownload.download(data);
  };

  return (
    <CCRReportFormDialog
      ccrProviderCodes={ccrProviderCodes}
      error={ccrReportDownload.error?.message}
      resolver={resolver}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      ready={Boolean(ccrProviderCodes) && !ccrReportDownload.isLoading}
    />
  );
};

export default CCRReportContainer;
