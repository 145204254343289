import { PagedQuery, useQueryPaged } from "@ldms/mui-sdk/cqrs";
import { useApi } from "common/providers";
import { ClientQueryApi } from "generated/core/apis";
import { ClientListItemModel } from "generated/core/models";
import { keys } from ".";

export const useListCustomers: PagedQuery<
  ClientListItemModel,
  { query?: string; enabled?: boolean }
> = ({ pageSize, params = {} } = {}) => {
  const api = useApi(ClientQueryApi);

  return useQueryPaged(
    keys.list({ query: params.query }),
    async (_key, paging) =>
      api.listClientsRaw({
        limit: paging.pageSize,
        offset: paging.offset,
        query: params.query,
      }),
    { limit: pageSize, enabled: params.enabled },
  );
};
