import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { useLocale } from "common/hooks";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

export interface Mandate {
  reference?: string;
  startDate: Date;
  endDate?: Date;
}

interface MandatesListProps {
  data: Mandate[];
  loading: boolean;
}

export default function MandateList({ data, loading }: MandatesListProps) {
  const { t } = useTranslation("clients");
  const locale = useLocale();

  const tableHeaders: string[] = [
    t("mandates.reference_label"),
    t("mandates.start_date_label"),
    t("mandates.end_date_label"),
  ];

  return (
    <>
      <Table aria-label={t("mandates.mandates_title")} size="small">
        <LoadableTableHead headings={tableHeaders} loading={loading} />
        <TableBody>
          {data?.map((item) => (
            <TableRow key={uuidv4()} hover>
              <TableCell>{item.reference}</TableCell>
              <TableCell>{locale.formatDate(item.startDate)}</TableCell>
              <TableCell>
                {item.endDate && locale.formatDate(item.endDate)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data.length === 0 && <NoResults />}
    </>
  );
}
