import PageNotFoundView from "common/views/PageNotFoundView";
import { Route, Routes } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";
import { useShellLayout } from "shell";
import Navigation from "./navigation/Navigation";
import { ListApps } from "./views/ListApps";
import { TasksView } from "./views/TasksView";

const StartApp = () => {
  useShellLayout({
    navigation: <Navigation />,
  });

  return (
    <Routes>
      <Route index element={<ListApps />} />
      <Route path="tasks" element={<TasksView />} />
      <Route path="*" element={<PageNotFoundView />} />
    </Routes>
  );
};

export default withTranslationLoader("start")(StartApp);
