export enum BatchStatus {
  Downloaded = "Downloaded",
  Failed = "Failed",
  Generated = "Generated",
  Pending = "Pending",
  Printed = "Printed",
}

export enum TemplateType {
  DdPayerInvoice = "DD Payer Invoice",
  NonDdPayerInvoice = "Non-DD Payer Invoice",
}

export enum DocumentTypeEnum {
  "DD Payer Invoice" = "letters_to_email.filters.dd_payers_label",
  "Non-DD Payer Invoice" = "letters_to_email.filters.non_dd_payers_label",
}

export enum DeliveryStatusEnum {
  "Pending" = "letters_to_email.letters_list.pending_table",
  "Sent" = "letters_to_email.letters_list.sent_table",
  "Failed" = "letters_to_email.letters_list.failed_table",
}

export enum TemplateTypeEnum {
  System = "SYSTEM",
  Letters = "LETTER",
}
