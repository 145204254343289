import { DescriptionList } from "@ldms/mui-sdk/components";
import { ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Loader } from "common/components";
import Surface from "common/components/Surface";
import { useConfirm } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface ThirdParty {
  id: number;
  name: string;
  type: string;
  contact?: string;
  emailAddress?: string;
  faxNumber?: string;
  telephone?: string;
  address?: {
    addressLine1: string;
    addressLine2: string;
    addressLine3?: string;
    addressLine4?: string;
    postcode: string;
  };
  vatRegistrationNumber?: string;
}

interface AgreementThirdPartyDetailsContainerProps {
  thirdParty: ThirdParty;
}

export default function AgreementThirdPartyDetailsContainer({
  thirdParty,
}: AgreementThirdPartyDetailsContainerProps): ReactElement {
  const { t } = useTranslation("agreements");
  const confirm = useConfirm();

  const renderThirdPartyDetails = () => (
    <Surface title={t("third_parties.details_heading")}>
      <AssociatedThirdPartyDescription thirdParty={thirdParty} />
    </Surface>
  );

  return (
    <>
      <Loader ready={Boolean(thirdParty)} render={renderThirdPartyDetails} />

      <ConfirmationDialog
        content={t("third_parties.disassociate_confirmation.message")}
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("third_parties.disassociate_confirmation.title_label")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />
    </>
  );
}

interface AssociatedThirdPartyDescriptionProps {
  thirdParty: ThirdParty;
}

function AssociatedThirdPartyDescription({
  thirdParty,
}: AssociatedThirdPartyDescriptionProps): ReactElement {
  const { t } = useTranslation("agreements");

  return (
    <DescriptionList label={t("third_parties.details_heading")}>
      <DescriptionList.Item label={t("third_parties.name_label")}>
        {thirdParty.name}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("third_parties.type_label")}>
        {thirdParty.type}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("third_parties.contact_label")}>
        {thirdParty.contact}
      </DescriptionList.Item>

      <DescriptionList.Item
        label={t("third_parties.vat_registration_number_label")}
      >
        {thirdParty.vatRegistrationNumber}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("third_parties.address_label")}>
        {[
          thirdParty.address?.addressLine1,
          thirdParty.address?.addressLine2,
          thirdParty.address?.addressLine3,
          thirdParty.address?.addressLine4,
          thirdParty.address?.postcode,
        ]
          .filter(Boolean)
          .join(", ")}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("third_parties.telephone_label")}>
        {thirdParty.telephone}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("third_parties.email_label")}>
        {thirdParty.emailAddress}
      </DescriptionList.Item>

      <DescriptionList.Item label={t("third_parties.fax_label")}>
        {thirdParty.faxNumber}
      </DescriptionList.Item>
    </DescriptionList>
  );
}
