import { Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import useListDirectDebitSubmissions from "api/direct-debit-submissions/listDirectDebitSubmissionPayments";
import useListPaymentTypes from "api/direct-debit-submissions/payment-types/listPaymentTypes";
import DirectDebitDetailsPaymentsList from "apps/finance/components/DirectDebitDetailsPaymentsList";
import ListLayout from "common/layouts/ListLayout";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface DirectDebitDetailsFilterParameters {
  type: string;
}

interface DirectDebitDetailsProps {
  submissionId: number;
}

export default function DirectDebitDetails({
  submissionId,
}: DirectDebitDetailsProps): ReactElement {
  const { t } = useTranslation("finance");
  const paymentTypes = useListPaymentTypes();
  const [parameters, setParameters] =
    useState<DirectDebitDetailsFilterParameters>({
      type: "",
    });

  const errorMessage = (
    <Typography
      role="alert"
      color="error"
      aria-label={t("common:error.default")}
    >
      {t("common:error.default")}
    </Typography>
  );

  const directDebitDetails = useListDirectDebitSubmissions({
    submissionId,
    type: parameters.type,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setParameters({
      type: event.target.value,
    });
  };

  const renderDirectDebitDetails = (): ReactElement => {
    if (!paymentTypes.data || paymentTypes.error) {
      return errorMessage;
    }

    return (
      <ListLayout
        filters={
          <TextField
            InputLabelProps={{
              htmlFor: "paymentType",
              shrink: true,
            }}
            inputProps={{ displayEmpty: true, id: "paymentType" }}
            onChange={handleChange}
            label={t("direct_debit_submissions.details.payment_type_label")}
            name="paymentType"
            select
            value={parameters.type}
            margin="none"
          >
            <MenuItem value="" key="">
              {t("direct_debit_submissions.details.all_payment_types")}
            </MenuItem>
            {paymentTypes.data.map((p) => (
              <MenuItem value={p.name} key={p.type}>
                {p.name}
              </MenuItem>
            ))}
          </TextField>
        }
      >
        <Loader
          ready={Boolean(directDebitDetails.data ?? directDebitDetails.error)}
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
          render={(): ReactElement => {
            if (!directDebitDetails.data || directDebitDetails.error) {
              return errorMessage;
            }

            return (
              <DirectDebitDetailsPaymentsList
                data={directDebitDetails.data.payments}
                totalValue={directDebitDetails.data.totalAmount}
                loading={directDebitDetails.isValidating}
              />
            );
          }}
        />
      </ListLayout>
    );
  };

  return (
    <Loader
      fallback={
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      }
      ready={Boolean(paymentTypes.data ?? paymentTypes.error)}
      render={renderDirectDebitDetails}
    />
  );
}
