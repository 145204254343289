import { PagedQuery, useQueryPaged } from "@ldms/mui-sdk/cqrs";
import * as keys from "api/assets/keys";
import { useApi } from "common/providers";
import { AssetQueryApi } from "generated/core/apis";
import { AssetListItemModel } from "generated/core/models";

export const useListAssets: PagedQuery<
  AssetListItemModel,
  {
    query?: string;
    enabled?: boolean;
  }
> = ({ pageSize, params = {} } = {}) => {
  const api = useApi(AssetQueryApi);

  return useQueryPaged(
    keys.list({ query: params.query }),
    async (_key, paging) =>
      api.listAssetsRaw({
        limit: paging.pageSize,
        offset: paging.offset,
        query: params.query,
      }),
    { limit: pageSize, enabled: params.enabled },
  );
};
