import { QueryKey } from "api/types";
import { ListDirectDebitSubmissionPaymentsRequest } from "generated/core/apis";

export const all = (): QueryKey => ["direct-debit-submissions"];

export const list = (params: ListDirectDebitSubmissionPaymentsRequest) => [
  ...all(),
  "list",
  params.submissionId,
  params.type,
];
