import { Close, Info } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ReadOnlyUserDetails from "apps/admin/components/ReadOnlyUserDetails";
import MultipleSelectField from "common/components/MultipleSelectField";
import { useConfirm, useYupResolver } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "support/react-hook-form";

export interface UpdateUserFormValues {
  roles: string[];
  active: boolean;
}

interface UpdateUserFormDialogProps {
  defaultValues: { roles: string[] };
  roleOptions?: { label: string; value: string }[];
  active?: boolean;
  firstName: string;
  lastName: string;
  username: string;
  open: boolean;
  onSubmit: SubmitHandler<UpdateUserFormValues>;
  onClose(): void;
  error?: string;
  onActiveToggle: (active: boolean) => void;
}

export default function UpdateUserFormDialog({
  active,
  defaultValues,
  open,
  onSubmit: onSubmitCallback,
  onClose,
  roleOptions,
  error,
  firstName,
  lastName,
  username,
  onActiveToggle,
}: Readonly<UpdateUserFormDialogProps>): ReactElement {
  const { t } = useTranslation("users");
  const activationButtonText =
    active === true
      ? t("update_user.deactivate_user")
      : t("update_user.activate_user");

  const confirm = useConfirm();
  const resolver = useYupResolver<UpdateUserFormValues>((yup) =>
    yup.object().shape({
      roles: yup.array().of(yup.string()).required(),
    }),
  );

  const form = useForm<UpdateUserFormValues>({
    resolver,
  });

  const handleActiveToggle = (): void => {
    confirm.handlePrompt(() => onActiveToggle(!active));
  };

  let activePrompt = active ? (
    <Alert severity="error">
      <Box display="flex" alignItems="center">
        <Box paddingRight={1}>
          <AlertTitle aria-label="deactivate-user">
            {t("update_user.confirmation_title")}
          </AlertTitle>
          {t("update_user.deactivate_message")}
        </Box>
        <Box>
          <Button
            aria-label="deactivate"
            color="error"
            onClick={confirm.handleConfirm}
            variant="contained"
            disabled={form.formState.isSubmitting}
            sx={{ whiteSpace: "nowrap" }}
          >
            {t("update_user.deactivate_user")}
          </Button>
        </Box>
      </Box>
    </Alert>
  ) : (
    <Alert severity="info" icon={<Info />}>
      <Box display="flex" alignItems="center">
        <Box paddingRight={1}>
          <AlertTitle aria-label="activate-user">
            {t("update_user.confirmation_title")}
          </AlertTitle>
          {t("update_user.activate_message")}
        </Box>
        <Box>
          <Button
            color="info"
            variant="contained"
            aria-label="activate"
            onClick={confirm.handleConfirm}
            disabled={form.formState.isSubmitting}
            sx={{ whiteSpace: "nowrap" }}
          >
            {t("update_user.activate_user")}
          </Button>
        </Box>
      </Box>
    </Alert>
  );

  if (!confirm.isOpen) {
    activePrompt = (
      <Button
        variant="contained"
        onClick={handleActiveToggle}
        color="primary"
        disabled={form.formState.isSubmitting}
      >
        {activationButtonText}
      </Button>
    );
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="update-user-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <form
        method="POST"
        onSubmit={form.handleSubmit(onSubmitCallback)}
        aria-labelledby="update-user-dialog-title"
      >
        <DialogTitle id="update-user-dialog-title">
          <Box display="flex">
            <Box flexGrow={1}>{t("edit_user")}</Box>
            <Box>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label={t("common:close")}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ReadOnlyUserDetails
            user={{
              username,
              firstName,
              lastName,
            }}
            labels={{
              username: t("create_user.username_label"),
              firstName: t("create_user.firstname_label"),
              lastName: t("create_user.lastname_label"),
            }}
          />
          <Grid container spacing={1}>
            <Grid item sm={12}>
              <MultipleSelectField
                label={t("user_list.roles")}
                disabled={!active || confirm.isOpen}
                control={form.control}
                name="roles"
                fullWidth
                options={roleOptions ?? []}
                error={form.formState.errors?.roles?.message}
                defaultValue={defaultValues.roles}
              />
            </Grid>
          </Grid>
          <Box paddingTop={4} paddingBottom={1}>
            {confirm.isOpen ? (
              activePrompt
            ) : (
              <Button
                variant="contained"
                onClick={handleActiveToggle}
                color="primary"
                disabled={form.formState.isSubmitting}
              >
                {activationButtonText}
              </Button>
            )}
          </Box>
          {error && (
            <Typography color="error" data-testid="updateUser.error">
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("common:cancel")}</Button>
          <Button
            type="submit"
            color="primary"
            disabled={
              !roleOptions ||
              form.formState.isSubmitting ||
              confirm.isOpen ||
              !active
            }
            variant="contained"
          >
            {t("submit_user")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
