import { KeyValueRow, KeyValueTable } from "common/components";
import React from "react";
import { useTranslation } from "react-i18next";

type FinancialDetailsCashPriceTableProps = {
  totalNetCashPrice: {
    label: string;
    amount: string;
  };
  totalVat: {
    label: string;
    amount: string;
  };
  totalCashPriceIncVat: {
    label: string;
    amount: string;
  };
  testId: string;
};

export default function FinancialDetailsCashPriceTable({
  totalNetCashPrice,
  totalVat,
  totalCashPriceIncVat,
  testId,
}: FinancialDetailsCashPriceTableProps): React.ReactElement {
  const { t } = useTranslation("onboardings");
  return (
    <>
      <KeyValueTable testId={testId}>
        <KeyValueRow align="right" label={t(totalNetCashPrice.label)}>
          {totalNetCashPrice.amount}
        </KeyValueRow>
        <KeyValueRow align="right" label={t(totalVat.label)}>
          {totalVat.amount}
        </KeyValueRow>
        <KeyValueRow align="right" label={t(totalCashPriceIncVat.label)}>
          {totalCashPriceIncVat.amount}
        </KeyValueRow>
      </KeyValueTable>
    </>
  );
}
