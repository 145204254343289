import { QueryKey } from "api/types";

export const all = (): QueryKey => ["automations"];

export const list = (params?: { portfolioId?: number }): QueryKey => [
  ...all(),
  "list",
  ...(params ? [params.portfolioId] : []),
];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (automationId: number): QueryKey => [
  ...details(),
  automationId,
];
