import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { MenuItem, Typography } from "@mui/material";
import { useAssociateFees } from "api/onboarding/financial-products/associateFees";
import useModal from "common/hooks/useModal";
import { useTranslation } from "react-i18next";

interface DisassociateFeeContainerProps {
  financialProductId: number;
  feeId: number;
  currentFees: number[];
  closeMenu: () => void;
}

const DisassociateFeeContainer = ({
  financialProductId,
  feeId,
  currentFees,
  closeMenu,
}: DisassociateFeeContainerProps) => {
  const { t } = useTranslation("onboardings");
  const disassociateFeeModel = useModal();

  const associateFees = useAssociateFees(financialProductId, {
    onSuccess: closeMenu,
  });

  const handleAlertClose = () => {
    closeMenu();
    associateFees.reset();
  };

  const onConfirm = async () => {
    await associateFees.execute(currentFees.filter((fee) => fee !== feeId));
  };

  return (
    <>
      <MenuItem key="disassociate-fee" onClick={disassociateFeeModel.open}>
        {t("financial_products.disassociate_fee.menu_item_label")}
      </MenuItem>
      <ConfirmationDialog
        open={disassociateFeeModel.isOpen}
        onConfirm={onConfirm}
        onReject={closeMenu}
        title={t("financial_products.disassociate_fee.confirmation_title")}
        content={
          <Typography>
            {t("financial_products.disassociate_fee.confirmation_message")}
          </Typography>
        }
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />
      <AlertDialog
        title={t("common:error.title")}
        content={associateFees.error?.message}
        labels={{ close: t("common:alert.close") }}
        onClose={handleAlertClose}
        open={Boolean(associateFees.error?.message)}
      />
    </>
  );
};

export default DisassociateFeeContainer;
