/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateMergeFieldListItemModel
 */
export interface TemplateMergeFieldListItemModel {
    /**
     * The tag
     * @type {string}
     * @memberof TemplateMergeFieldListItemModel
     */
    tag: string;
    /**
     * Example data for the associated tag
     * @type {string}
     * @memberof TemplateMergeFieldListItemModel
     */
    example: string;
}

/**
 * Check if a given object implements the TemplateMergeFieldListItemModel interface.
 */
export function instanceOfTemplateMergeFieldListItemModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tag" in value;
    isInstance = isInstance && "example" in value;

    return isInstance;
}

export function TemplateMergeFieldListItemModelFromJSON(json: any): TemplateMergeFieldListItemModel {
    return TemplateMergeFieldListItemModelFromJSONTyped(json, false);
}

export function TemplateMergeFieldListItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateMergeFieldListItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tag': json['tag'],
        'example': json['example'],
    };
}

export function TemplateMergeFieldListItemModelToJSON(value?: TemplateMergeFieldListItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag': value.tag,
        'example': value.example,
    };
}

