/* tslint:disable */
/* eslint-disable */
/**
 * Engage Onboarding
 * API for managing fees
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeeTypeModel } from './FeeTypeModel';
import {
    FeeTypeModelFromJSON,
    FeeTypeModelFromJSONTyped,
    FeeTypeModelToJSON,
} from './FeeTypeModel';

/**
 * 
 * @export
 * @interface AddFeeModel
 */
export interface AddFeeModel {
    /**
     * Name of the fee
     * @type {string}
     * @memberof AddFeeModel
     */
    name: string;
    /**
     * 
     * @type {FeeTypeModel}
     * @memberof AddFeeModel
     */
    type: FeeTypeModel;
    /**
     * Indicates whether the fee is deducted from advance
     * @type {boolean}
     * @memberof AddFeeModel
     */
    deductedFromAdvance: boolean;
    /**
     * Indicates whether the fee should be taxed
     * @type {boolean}
     * @memberof AddFeeModel
     */
    taxable: boolean;
    /**
     * Indicates whether the fee is enabled
     * @type {boolean}
     * @memberof AddFeeModel
     */
    enabled: boolean;
    /**
     * Indicates whether the fee should be collected by direct debit
     * @type {boolean}
     * @memberof AddFeeModel
     */
    collectByDirectDebit?: boolean;
    /**
     * Indicates whether the fee should be included on invoices
     * @type {boolean}
     * @memberof AddFeeModel
     */
    includeOnInvoices?: boolean;
}

/**
 * Check if a given object implements the AddFeeModel interface.
 */
export function instanceOfAddFeeModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "deductedFromAdvance" in value;
    isInstance = isInstance && "taxable" in value;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

export function AddFeeModelFromJSON(json: any): AddFeeModel {
    return AddFeeModelFromJSONTyped(json, false);
}

export function AddFeeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddFeeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': FeeTypeModelFromJSON(json['type']),
        'deductedFromAdvance': json['deductedFromAdvance'],
        'taxable': json['taxable'],
        'enabled': json['enabled'],
        'collectByDirectDebit': !exists(json, 'collectByDirectDebit') ? undefined : json['collectByDirectDebit'],
        'includeOnInvoices': !exists(json, 'includeOnInvoices') ? undefined : json['includeOnInvoices'],
    };
}

export function AddFeeModelToJSON(value?: AddFeeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': FeeTypeModelToJSON(value.type),
        'deductedFromAdvance': value.deductedFromAdvance,
        'taxable': value.taxable,
        'enabled': value.enabled,
        'collectByDirectDebit': value.collectByDirectDebit,
        'includeOnInvoices': value.includeOnInvoices,
    };
}

