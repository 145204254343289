import { Loader } from "@ldms/mui-sdk/templates";
import { useListUsers } from "api/users";
import UserList from "apps/admin/components/UserList";
import { QueryError } from "common/components";
import ListLayout from "common/layouts/ListLayout";
import { ChangeEventHandler, MouseEvent, ReactElement, useState } from "react";

export default function UsersContainer(): ReactElement {
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const users = useListUsers({ pageSize: rowsPerPage });

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => users.paging.setPage(page);

  const onChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Loader
      ready={Boolean(users.data ?? users.error)}
      render={(): ReactElement => {
        if (users.error || !users.data) {
          return <QueryError onRetry={users.refetch} />;
        }
        return (
          <ListLayout
            pagination={{
              onPageChange: onChangePage,
              onRowsPerPageChange: onChangeRowsPerPage,
              rowsPerPage: rowsPerPage,
              page: users.paging.page,
              count: users.data.paging.total || 0,
            }}
          >
            <UserList data={users.data.results} loading={users.isValidating} />
          </ListLayout>
        );
      }}
    />
  );
}
