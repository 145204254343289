import {
  DateRangeControl,
  PortfolioReportFormDialog,
} from "apps/reporting/components";
import {
  usePortfolioDateReportResolver,
  useReportDownload,
} from "apps/reporting/hooks";
import { PortfolioDateReportDialogFormValues } from "apps/reporting/types";
import { useApi } from "common/providers";
import { PortfolioQueryApi, ReportQueryApi } from "generated/core/apis";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import startOfYesterdayUTC from "support/form-helpers/start-of-yesterday-utc";
import useSWR from "swr";

interface DirectDebitPaymentStatusReportContainerProps {
  onClose(): void;
  open: boolean;
}

export default function DirectDebitPaymentStatusReportContainer({
  onClose,
  open,
}: DirectDebitPaymentStatusReportContainerProps): ReactElement {
  const { t } = useTranslation("reporting");
  const resolver = usePortfolioDateReportResolver().BeforeTodayResolver();
  const portfolioApi = useApi(PortfolioQueryApi);
  const api = useApi(ReportQueryApi);
  const portfolios = useSWR(["portfolios"], () =>
    portfolioApi.listPortfolios(),
  );

  const DirectDebitPaymentStatusReportDownload = useReportDownload(
    (data: PortfolioDateReportDialogFormValues) =>
      api.directDebitPaymentStatusRaw({
        portfolioId:
          data.portfolio === "All" ? undefined : Number(data.portfolio),
        from: new Date(data.fromDate),
        to: new Date(data.toDate),
      }),
    {
      onSuccess: onClose,
    },
  );

  const onSubmit = async (
    data: PortfolioDateReportDialogFormValues,
  ): Promise<void> => DirectDebitPaymentStatusReportDownload.download(data);

  return (
    <PortfolioReportFormDialog<PortfolioDateReportDialogFormValues>
      defaultValues={{
        portfolio: "",
        fromDate: startOfYesterdayUTC(),
        toDate: startOfYesterdayUTC(),
      }}
      onClose={onClose}
      onSubmit={onSubmit}
      error={DirectDebitPaymentStatusReportDownload.error?.message}
      open={open}
      portfolios={[
        { label: t("report_dialog.all_portfolio_option"), value: "All" },
        ...(portfolios.data ?? []).map((portfolio) => ({
          label: portfolio.name,
          value: String(portfolio.id),
        })),
      ]}
      ready={
        Boolean(portfolios.data) &&
        !DirectDebitPaymentStatusReportDownload.isLoading
      }
      resolver={resolver}
      title={t("report_dialog.direct_debit_payment_status.title")}
    >
      {(form): ReactElement => (
        <DateRangeControl
          errors={form.formState.errors}
          register={form.register}
        />
      )}
    </PortfolioReportFormDialog>
  );
}
