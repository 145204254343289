/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (Client)
 * API for finding and viewing Clients with Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientFilterModel
 */
export interface ClientFilterModel {
    /**
     * The agreement number to filter on (exact match)
     * @type {string}
     * @memberof ClientFilterModel
     */
    agreementNumber?: string;
    /**
     * The agreement ID to filter on (exact match)
     * @type {number}
     * @memberof ClientFilterModel
     */
    agreementId?: number;
    /**
     * The Direct Debit reference to filter on (supports wildcards)
     * @type {string}
     * @memberof ClientFilterModel
     */
    directDebitReference?: string;
    /**
     * The company name to filter on (supports wildcards)
     * @type {string}
     * @memberof ClientFilterModel
     */
    companyName?: string;
    /**
     * The vehicle registration number to filter on (supports wildcards)
     * @type {string}
     * @memberof ClientFilterModel
     */
    vehicleRegistrationNumber?: string;
    /**
     * The first line of the address to filter on (supports wildcards)
     * @type {string}
     * @memberof ClientFilterModel
     */
    firstLineOfAddress?: string;
    /**
     * The postcode to filter on (supports wildcards)
     * @type {string}
     * @memberof ClientFilterModel
     */
    postcode?: string;
    /**
     * The contact telephone number to filter on (supports wildcards)
     * @type {string}
     * @memberof ClientFilterModel
     */
    telephoneNumber?: string;
}

/**
 * Check if a given object implements the ClientFilterModel interface.
 */
export function instanceOfClientFilterModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClientFilterModelFromJSON(json: any): ClientFilterModel {
    return ClientFilterModelFromJSONTyped(json, false);
}

export function ClientFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreementNumber': !exists(json, 'agreementNumber') ? undefined : json['agreementNumber'],
        'agreementId': !exists(json, 'agreementId') ? undefined : json['agreementId'],
        'directDebitReference': !exists(json, 'directDebitReference') ? undefined : json['directDebitReference'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'vehicleRegistrationNumber': !exists(json, 'vehicleRegistrationNumber') ? undefined : json['vehicleRegistrationNumber'],
        'firstLineOfAddress': !exists(json, 'firstLineOfAddress') ? undefined : json['firstLineOfAddress'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'telephoneNumber': !exists(json, 'telephoneNumber') ? undefined : json['telephoneNumber'],
    };
}

export function ClientFilterModelToJSON(value?: ClientFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreementNumber': value.agreementNumber,
        'agreementId': value.agreementId,
        'directDebitReference': value.directDebitReference,
        'companyName': value.companyName,
        'vehicleRegistrationNumber': value.vehicleRegistrationNumber,
        'firstLineOfAddress': value.firstLineOfAddress,
        'postcode': value.postcode,
        'telephoneNumber': value.telephoneNumber,
    };
}

