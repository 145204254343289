/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server (report)
 * API for reporting within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CaisReportListItemModel,
  CentralCreditRegisterReportListItemModel,
  EquifaxInsightReportListItemModel,
} from '../models/index';
import {
    CaisReportListItemModelFromJSON,
    CaisReportListItemModelToJSON,
    CentralCreditRegisterReportListItemModelFromJSON,
    CentralCreditRegisterReportListItemModelToJSON,
    EquifaxInsightReportListItemModelFromJSON,
    EquifaxInsightReportListItemModelToJSON,
} from '../models/index';

export interface AgreementCashFlowRequest {
    agreementId: number;
}

export interface AgreementInsuranceRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
}

export interface AgreementSettlementQuotesRequest {
    portfolioId?: number;
}

export interface AgreementSetupRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
}

export interface AgreementStatusRequest {
    portfolioId?: number;
}

export interface AppliedChargesAndDiscountsRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
}

export interface AssetsRequest {
    portfolioId?: number;
}

export interface CaisRequest {
    reportId: number;
}

export interface CentralCreditRegisterRequest {
    ccrSnapshotId: number;
    ccrCode: string;
}

export interface DirectDebitPaymentStatusRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
}

export interface EndOfTermReportRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
}

export interface EquifaxInsightRequest {
    reportId: number;
}

export interface FeeAmountDueRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
}

export interface InstalmentAmountsDueRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
}

export interface ListAvailableCentralCreditRegisterReportsRequest {
    ccrCode: string;
}

export interface ListAvailableEquifaxInsightReportsRequest {
    equifaxInsightNumber: string;
}

export interface ManufacturerSubsidyInvoicesRequest {
    from: Date;
    to: Date;
}

export interface PenaltyFeesRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
}

export interface PortfolioCashFlowReportRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
}

export interface PortfolioCashFlowSummaryRequest {
    portfolioId?: number;
}

export interface PortfolioReportRequest {
    portfolioId: number;
}

export interface ReceiptsRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
    filterBy?: ReceiptsFilterByEnum;
}

export interface SettledAgreementRequest {
    from: Date;
    to: Date;
    portfolioId?: number;
}

/**
 * 
 */
export class ReportQueryApi extends runtime.BaseAPI {

    /**
     * Download a CSV report of agreement cash flow
     */
    async agreementCashFlowRaw(requestParameters: AgreementCashFlowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling agreementCashFlow.');
        }

        const queryParameters: any = {};

        if (requestParameters.agreementId !== undefined) {
            queryParameters['agreementId'] = requestParameters.agreementId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-cash-flow-report:view"]);
        }

        const response = await this.request({
            path: `/reports/agreement-cash-flow`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of agreement cash flow
     */
    async agreementCashFlow(requestParameters: AgreementCashFlowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.agreementCashFlowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report for agreement insurance
     */
    async agreementInsuranceRaw(requestParameters: AgreementInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling agreementInsurance.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling agreementInsurance.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-insurance-report:view"]);
        }

        const response = await this.request({
            path: `/reports/agreement-insurance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report for agreement insurance
     */
    async agreementInsurance(requestParameters: AgreementInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.agreementInsuranceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report of quoted agreements
     */
    async agreementSettlementQuotesRaw(requestParameters: AgreementSettlementQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-settlement-quotes-report:view"]);
        }

        const response = await this.request({
            path: `/reports/agreement-settlement-quotes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of quoted agreements
     */
    async agreementSettlementQuotes(requestParameters: AgreementSettlementQuotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.agreementSettlementQuotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report of agreements setup
     */
    async agreementSetupRaw(requestParameters: AgreementSetupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling agreementSetup.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling agreementSetup.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-setup-report:view"]);
        }

        const response = await this.request({
            path: `/reports/agreement-setup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of agreements setup
     */
    async agreementSetup(requestParameters: AgreementSetupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.agreementSetupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report for agreement status
     */
    async agreementStatusRaw(requestParameters: AgreementStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["agreement-status-report:view"]);
        }

        const response = await this.request({
            path: `/reports/agreement-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report for agreement status
     */
    async agreementStatus(requestParameters: AgreementStatusRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.agreementStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report of applied charges and discounts fees
     */
    async appliedChargesAndDiscountsRaw(requestParameters: AppliedChargesAndDiscountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling appliedChargesAndDiscounts.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling appliedChargesAndDiscounts.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["applied-charges-and-discounts-report:view"]);
        }

        const response = await this.request({
            path: `/reports/applied-charges-and-discounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of applied charges and discounts fees
     */
    async appliedChargesAndDiscounts(requestParameters: AppliedChargesAndDiscountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.appliedChargesAndDiscountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report containing details of assets belonging to a portfolio
     */
    async assetsRaw(requestParameters: AssetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["assets-report:view"]);
        }

        const response = await this.request({
            path: `/reports/assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report containing details of assets belonging to a portfolio
     */
    async assets(requestParameters: AssetsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.assetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the available CAIS reports
     */
    async availableCaisReportsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CaisReportListItemModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["cais-report:view"]);
        }

        const response = await this.request({
            path: `/reports/cais`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CaisReportListItemModelFromJSON));
    }

    /**
     * List the available CAIS reports
     */
    async availableCaisReports(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CaisReportListItemModel>> {
        const response = await this.availableCaisReportsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Download a CAIS report
     */
    async caisRaw(requestParameters: CaisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling cais.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["cais-report:view"]);
        }

        const response = await this.request({
            path: `/reports/cais/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CAIS report
     */
    async cais(requestParameters: CaisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.caisRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a Central Credit Register report
     */
    async centralCreditRegisterRaw(requestParameters: CentralCreditRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.ccrSnapshotId === null || requestParameters.ccrSnapshotId === undefined) {
            throw new runtime.RequiredError('ccrSnapshotId','Required parameter requestParameters.ccrSnapshotId was null or undefined when calling centralCreditRegister.');
        }

        if (requestParameters.ccrCode === null || requestParameters.ccrCode === undefined) {
            throw new runtime.RequiredError('ccrCode','Required parameter requestParameters.ccrCode was null or undefined when calling centralCreditRegister.');
        }

        const queryParameters: any = {};

        if (requestParameters.ccrSnapshotId !== undefined) {
            queryParameters['ccrSnapshotId'] = requestParameters.ccrSnapshotId;
        }

        if (requestParameters.ccrCode !== undefined) {
            queryParameters['ccrCode'] = requestParameters.ccrCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["central-credit-register-report:view"]);
        }

        const response = await this.request({
            path: `/reports/central-credit-register`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a Central Credit Register report
     */
    async centralCreditRegister(requestParameters: CentralCreditRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.centralCreditRegisterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report of direct debit payment status
     */
    async directDebitPaymentStatusRaw(requestParameters: DirectDebitPaymentStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling directDebitPaymentStatus.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling directDebitPaymentStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["direct-debit-payment-status-report:view"]);
        }

        const response = await this.request({
            path: `/reports/direct-debit-payment-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of direct debit payment status
     */
    async directDebitPaymentStatus(requestParameters: DirectDebitPaymentStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.directDebitPaymentStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report for end of term
     */
    async endOfTermReportRaw(requestParameters: EndOfTermReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling endOfTermReport.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling endOfTermReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["end-of-term-report:view"]);
        }

        const response = await this.request({
            path: `/reports/end-of-term`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report for end of term
     */
    async endOfTermReport(requestParameters: EndOfTermReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.endOfTermReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download an Equifax Insight report
     */
    async equifaxInsightRaw(requestParameters: EquifaxInsightRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling equifaxInsight.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["equifax-report:view"]);
        }

        const response = await this.request({
            path: `/reports/equifax/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download an Equifax Insight report
     */
    async equifaxInsight(requestParameters: EquifaxInsightRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.equifaxInsightRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report of fee amount due
     */
    async feeAmountDueRaw(requestParameters: FeeAmountDueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling feeAmountDue.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling feeAmountDue.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["fee-amount-due-report:view"]);
        }

        const response = await this.request({
            path: `/reports/fee-amount-due`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of fee amount due
     */
    async feeAmountDue(requestParameters: FeeAmountDueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.feeAmountDueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report of instalment amounts due
     */
    async instalmentAmountsDueRaw(requestParameters: InstalmentAmountsDueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling instalmentAmountsDue.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling instalmentAmountsDue.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["instalment-amounts-due-report:view"]);
        }

        const response = await this.request({
            path: `/reports/instalment-amounts-due`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of instalment amounts due
     */
    async instalmentAmountsDue(requestParameters: InstalmentAmountsDueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.instalmentAmountsDueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the available reports for a Central Credit Register Code
     */
    async listAvailableCentralCreditRegisterReportsRaw(requestParameters: ListAvailableCentralCreditRegisterReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CentralCreditRegisterReportListItemModel>>> {
        if (requestParameters.ccrCode === null || requestParameters.ccrCode === undefined) {
            throw new runtime.RequiredError('ccrCode','Required parameter requestParameters.ccrCode was null or undefined when calling listAvailableCentralCreditRegisterReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["central-credit-register-report:view"]);
        }

        const response = await this.request({
            path: `/reports/central-credit-register/{ccrCode}`.replace(`{${"ccrCode"}}`, encodeURIComponent(String(requestParameters.ccrCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CentralCreditRegisterReportListItemModelFromJSON));
    }

    /**
     * List the available reports for a Central Credit Register Code
     */
    async listAvailableCentralCreditRegisterReports(requestParameters: ListAvailableCentralCreditRegisterReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CentralCreditRegisterReportListItemModel>> {
        const response = await this.listAvailableCentralCreditRegisterReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the available Equifax Insight reports for an Insight Number
     */
    async listAvailableEquifaxInsightReportsRaw(requestParameters: ListAvailableEquifaxInsightReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EquifaxInsightReportListItemModel>>> {
        if (requestParameters.equifaxInsightNumber === null || requestParameters.equifaxInsightNumber === undefined) {
            throw new runtime.RequiredError('equifaxInsightNumber','Required parameter requestParameters.equifaxInsightNumber was null or undefined when calling listAvailableEquifaxInsightReports.');
        }

        const queryParameters: any = {};

        if (requestParameters.equifaxInsightNumber !== undefined) {
            queryParameters['equifaxInsightNumber'] = requestParameters.equifaxInsightNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["equifax-report:view"]);
        }

        const response = await this.request({
            path: `/reports/equifax`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EquifaxInsightReportListItemModelFromJSON));
    }

    /**
     * List the available Equifax Insight reports for an Insight Number
     */
    async listAvailableEquifaxInsightReports(requestParameters: ListAvailableEquifaxInsightReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EquifaxInsightReportListItemModel>> {
        const response = await this.listAvailableEquifaxInsightReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report of manufacturer subsidy invoices
     */
    async manufacturerSubsidyInvoicesRaw(requestParameters: ManufacturerSubsidyInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling manufacturerSubsidyInvoices.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling manufacturerSubsidyInvoices.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["manufacturer-subsidy-invoice-report:view"]);
        }

        const response = await this.request({
            path: `/reports/manufacturer-subsidy-invoice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of manufacturer subsidy invoices
     */
    async manufacturerSubsidyInvoices(requestParameters: ManufacturerSubsidyInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.manufacturerSubsidyInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report of penalty fees
     */
    async penaltyFeesRaw(requestParameters: PenaltyFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling penaltyFees.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling penaltyFees.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["penalty-fees-report:view"]);
        }

        const response = await this.request({
            path: `/reports/penalty-fees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of penalty fees
     */
    async penaltyFees(requestParameters: PenaltyFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.penaltyFeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a monthly portfolio cash flow report
     */
    async portfolioCashFlowReportRaw(requestParameters: PortfolioCashFlowReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling portfolioCashFlowReport.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling portfolioCashFlowReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolio-cash-flow-report:view"]);
        }

        const response = await this.request({
            path: `/reports/portfolio-cash-flow`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download a monthly portfolio cash flow report
     */
    async portfolioCashFlowReport(requestParameters: PortfolioCashFlowReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.portfolioCashFlowReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download an XLSX report of portfolio cash flow summary
     */
    async portfolioCashFlowSummaryRaw(requestParameters: PortfolioCashFlowSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolio-cash-flow-summary-report:view"]);
        }

        const response = await this.request({
            path: `/reports/portfolio-cash-flow-summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download an XLSX report of portfolio cash flow summary
     */
    async portfolioCashFlowSummary(requestParameters: PortfolioCashFlowSummaryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.portfolioCashFlowSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report for a portfolio
     */
    async portfolioReportRaw(requestParameters: PortfolioReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling portfolioReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["portfolio-report:view"]);
        }

        const response = await this.request({
            path: `/reports/portfolio-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report for a portfolio
     */
    async portfolioReport(requestParameters: PortfolioReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.portfolioReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report of receipts
     */
    async receiptsRaw(requestParameters: ReceiptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling receipts.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling receipts.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        if (requestParameters.filterBy !== undefined) {
            queryParameters['filterBy'] = requestParameters.filterBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["receipts-report:view"]);
        }

        const response = await this.request({
            path: `/reports/receipts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of receipts
     */
    async receipts(requestParameters: ReceiptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.receiptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a CSV report of settled agreements
     */
    async settledAgreementRaw(requestParameters: SettledAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling settledAgreement.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling settledAgreement.');
        }

        const queryParameters: any = {};

        if (requestParameters.portfolioId !== undefined) {
            queryParameters['portfolioId'] = requestParameters.portfolioId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substring(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("permissions", ["settled-agreement-report:view"]);
        }

        const response = await this.request({
            path: `/reports/settled-agreement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download a CSV report of settled agreements
     */
    async settledAgreement(requestParameters: SettledAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.settledAgreementRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ReceiptsFilterByEnum = {
    TransactionDate: 'Transaction Date',
    PostedDate: 'Posted Date'
} as const;
export type ReceiptsFilterByEnum = typeof ReceiptsFilterByEnum[keyof typeof ReceiptsFilterByEnum];
