import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { AgreementCommandApi } from "generated/core/apis";
import { AddInsuranceModel } from "generated/core/models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";
import { useSWRConfig } from "swr";
import * as keys from "./keys";

interface UseAddInsuranceReturn {
  command: (insurance: AddInsuranceModel) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
  reset(): void;
}

export const useAddInsurance = (
  agreementId: number,
  options: {
    onSuccess?: () => void;
  } = {},
): UseAddInsuranceReturn => {
  const api = useApi(AgreementCommandApi);
  const swr = useSWRConfig();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("agreements");
  const error = useResponseError([
    [
      "invalid_insurance_within_final_instalment",
      t("insurance.add_insurance.insurance_final_instalment_error_message"),
    ],
    [
      "invalid_insurance_start_date",
      t("insurance.add_insurance.invalid_insurance_start_date_error_message"),
    ],
  ]);

  const command = async (insurance: AddInsuranceModel): Promise<void> => {
    try {
      setIsLoading(true);
      await api.addInsurance({
        agreementId,
        addInsuranceModel: insurance,
      });
      options.onSuccess?.();
      swr.mutate(keys.detail(agreementId));
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = (): void => {
    error.reset();
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
    reset,
  };
};
