/* tslint:disable */
/* eslint-disable */
/**
 * Engage Admin
 * API for managing users within the Engage Admin application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Updates a new user
 * @export
 * @interface UpdateUserModel
 */
export interface UpdateUserModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserModel
     */
    roles: Array<string>;
}

/**
 * Check if a given object implements the UpdateUserModel interface.
 */
export function instanceOfUpdateUserModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function UpdateUserModelFromJSON(json: any): UpdateUserModel {
    return UpdateUserModelFromJSONTyped(json, false);
}

export function UpdateUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roles': json['roles'],
    };
}

export function UpdateUserModelToJSON(value?: UpdateUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roles': value.roles,
    };
}

