import FinanceLayout from "apps/finance/layouts/FinanceLayout/FinanceLayout";
import AddFinancialPostingView from "apps/finance/views/AddFinancialPostingView";
import DirectDebitSubmissionDetailView from "apps/finance/views/DirectDebitSubmissionDetailView";
import DirectDebitSubmissionsView from "apps/finance/views/DirectDebitSubmissionsView";
import { withAppAccess } from "common/security/withAppAccess";
import { compose } from "lodash/fp";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteAdapter } from "sdk/router/RouteAdapter";
import { withTranslationLoader } from "sdk/views";
import FilterFinancialPostingsView from "./views/FilterFinancialPostingsView";
import FinancialPostingsView from "./views/FinancialPostingsView";

const App = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="direct-debit-submissions" />} />
      <Route path="*" element={<FinanceLayout />}>
        <Route
          path="direct-debit-submissions"
          element={<DirectDebitSubmissionsView />}
        />
        <Route
          path="direct-debit-submissions/:submissionId"
          element={
            <RouteAdapter
              params={["submissionId"]}
              render={(route) => (
                <DirectDebitSubmissionDetailView
                  submissionId={Number(route.params.submissionId)}
                />
              )}
            />
          }
        />
        <Route path="financial-postings" element={<FinancialPostingsView />} />
        <Route
          path="filter-financial-postings"
          element={<FilterFinancialPostingsView />}
        />
        <Route
          path="financial-postings/add-financial-posting"
          element={<AddFinancialPostingView />}
        />
      </Route>
    </Routes>
  );
};

export default compose(
  withTranslationLoader(["finance", "agreements"]),
  withAppAccess("finance"),
)(App);
