/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing secondary rentals within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentFrequencyModel } from './PaymentFrequencyModel';
import {
    PaymentFrequencyModelFromJSON,
    PaymentFrequencyModelFromJSONTyped,
    PaymentFrequencyModelToJSON,
} from './PaymentFrequencyModel';

/**
 * 
 * @export
 * @interface SecondaryRentalDetailsModel
 */
export interface SecondaryRentalDetailsModel {
    /**
     * A format for dates
     * @type {Date}
     * @memberof SecondaryRentalDetailsModel
     */
    startDate: Date;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    amount: string;
    /**
     * 
     * @type {PaymentFrequencyModel}
     * @memberof SecondaryRentalDetailsModel
     */
    frequency: PaymentFrequencyModel;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    introducerShare?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    maintenanceCharge?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    insurancePremium?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    primaryTermArrears?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    primaryTermCapitalBalance?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    primaryTermInterestBalance?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    secondaryTermArrears?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    secondaryTermCapitalBalance?: string;
    /**
     * A string representation of a number formatted to 2 decimal places
     * @type {string}
     * @memberof SecondaryRentalDetailsModel
     */
    secondaryTermInterestBalance?: string;
}

/**
 * Check if a given object implements the SecondaryRentalDetailsModel interface.
 */
export function instanceOfSecondaryRentalDetailsModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "frequency" in value;

    return isInstance;
}

export function SecondaryRentalDetailsModelFromJSON(json: any): SecondaryRentalDetailsModel {
    return SecondaryRentalDetailsModelFromJSONTyped(json, false);
}

export function SecondaryRentalDetailsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecondaryRentalDetailsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': (new Date(json['startDate'])),
        'amount': json['amount'],
        'frequency': PaymentFrequencyModelFromJSON(json['frequency']),
        'introducerShare': !exists(json, 'introducerShare') ? undefined : json['introducerShare'],
        'maintenanceCharge': !exists(json, 'maintenanceCharge') ? undefined : json['maintenanceCharge'],
        'insurancePremium': !exists(json, 'insurancePremium') ? undefined : json['insurancePremium'],
        'primaryTermArrears': !exists(json, 'primaryTermArrears') ? undefined : json['primaryTermArrears'],
        'primaryTermCapitalBalance': !exists(json, 'primaryTermCapitalBalance') ? undefined : json['primaryTermCapitalBalance'],
        'primaryTermInterestBalance': !exists(json, 'primaryTermInterestBalance') ? undefined : json['primaryTermInterestBalance'],
        'secondaryTermArrears': !exists(json, 'secondaryTermArrears') ? undefined : json['secondaryTermArrears'],
        'secondaryTermCapitalBalance': !exists(json, 'secondaryTermCapitalBalance') ? undefined : json['secondaryTermCapitalBalance'],
        'secondaryTermInterestBalance': !exists(json, 'secondaryTermInterestBalance') ? undefined : json['secondaryTermInterestBalance'],
    };
}

export function SecondaryRentalDetailsModelToJSON(value?: SecondaryRentalDetailsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': (value.startDate.toISOString().substring(0,10)),
        'amount': value.amount,
        'frequency': PaymentFrequencyModelToJSON(value.frequency),
        'introducerShare': value.introducerShare,
        'maintenanceCharge': value.maintenanceCharge,
        'insurancePremium': value.insurancePremium,
        'primaryTermArrears': value.primaryTermArrears,
        'primaryTermCapitalBalance': value.primaryTermCapitalBalance,
        'primaryTermInterestBalance': value.primaryTermInterestBalance,
        'secondaryTermArrears': value.secondaryTermArrears,
        'secondaryTermCapitalBalance': value.secondaryTermCapitalBalance,
        'secondaryTermInterestBalance': value.secondaryTermInterestBalance,
    };
}

