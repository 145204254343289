/* tslint:disable */
/* eslint-disable */
/**
 * Servicing V2 API
 * API for managing Tax Codes within Engage
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTaxCodeRequestModel
 */
export interface CreateTaxCodeRequestModel {
    /**
     * The description of the tax code
     * @type {string}
     * @memberof CreateTaxCodeRequestModel
     */
    name: string;
    /**
     * Actual Rate of the tax
     * @type {number}
     * @memberof CreateTaxCodeRequestModel
     */
    rate: number;
    /**
     * A format for dates
     * @type {Date}
     * @memberof CreateTaxCodeRequestModel
     */
    effectiveDate: Date;
}

/**
 * Check if a given object implements the CreateTaxCodeRequestModel interface.
 */
export function instanceOfCreateTaxCodeRequestModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "effectiveDate" in value;

    return isInstance;
}

export function CreateTaxCodeRequestModelFromJSON(json: any): CreateTaxCodeRequestModel {
    return CreateTaxCodeRequestModelFromJSONTyped(json, false);
}

export function CreateTaxCodeRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTaxCodeRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'rate': json['rate'],
        'effectiveDate': (new Date(json['effectiveDate'])),
    };
}

export function CreateTaxCodeRequestModelToJSON(value?: CreateTaxCodeRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'rate': value.rate,
        'effectiveDate': (value.effectiveDate.toISOString().substring(0,10)),
    };
}

