import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { keys } from "api/agreements/schedule";
import { useApi } from "common/providers";
import { AgreementScheduleApi } from "generated/core/apis";
import { useSWRConfig } from "swr";

export const useCreateInvoice = (
  agreementId: number,
  instalmentNumber: number,
  options: UseCommandOptions<void, ResponseError> = {},
) => {
  const api = useApi(AgreementScheduleApi);
  const swr = useSWRConfig();

  return useCommand<void, void, ResponseError>(
    () =>
      api.createInvoice({
        agreementId,
        instalmentNumber,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.list(agreementId));
        options.onSuccess?.();
      },
    },
  );
};
