import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { DescriptionList } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import * as agreementAssetsKeys from "api/agreements/assets/keys";
import { useGetAsset } from "api/assets/getAsset";
import * as assetKeys from "api/assets/keys";
import * as keys from "api/assets/settlement-quotes/keys";
import * as customerAssetsKeys from "api/customers/assets/keys";
import { useGetSettlementQuote } from "api/settlement-quotes";
import ApproveSettlementQuoteContainer from "apps/servicing/containers/ApproveSettlementQuoteContainer";
import RejectSettlementQuoteContainer from "apps/servicing/containers/RejectSettlementQuoteContainer";
import { Loader, QueryError } from "common/components";
import { useLocale, usePartialMutate } from "common/hooks";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSWRConfig } from "swr";

interface AssetSettlementQuoteDetailsProps {
  settlementQuoteId: number;
  onClose(): void;
  open: boolean;
  assetId: number;
}

export default function AssetSettlementQuoteDetailsContainer({
  settlementQuoteId,
  onClose,
  open,
  assetId,
}: AssetSettlementQuoteDetailsProps): ReactElement {
  const { t } = useTranslation("assets");
  const locale = useLocale();
  const { formatAmount } = useFormat();
  const assetSettlementQuoteDetails = useGetSettlementQuote(settlementQuoteId);
  const swr = useSWRConfig();
  const asset = useGetAsset(assetId);
  const partialMutate = usePartialMutate();

  const onSuccess = (): void => {
    swr.mutate(keys.list(assetId));
    swr.mutate(assetKeys.details(assetId));
    if (asset.data) {
      partialMutate(customerAssetsKeys.list(asset.data.clientId));
      partialMutate(agreementAssetsKeys.list(asset.data.agreementId));
    }
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="asset-settlement-quote-details"
      open={open}
    >
      <DialogTitle id="asset-settlement-quote-details-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{t("settlement_quotes.details.heading")}</Box>
          <Box>
            <IconButton
              aria-label={t("common:close")}
              onClick={onClose}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <Loader
        ready={Boolean(
          assetSettlementQuoteDetails.data ?? assetSettlementQuoteDetails.error,
        )}
        render={(): ReactElement => {
          if (
            assetSettlementQuoteDetails.error ||
            !assetSettlementQuoteDetails.data
          ) {
            return <QueryError onRetry={assetSettlementQuoteDetails.refetch} />;
          }

          return (
            <>
              <DialogContent>
                <Box marginBottom={3} width={500}>
                  <Box>
                    <Typography variant="h5" marginY={2}>
                      {t("settlement_quotes.details.details_label")}
                    </Typography>

                    <DescriptionList
                      label={t("settlement_quotes.details.details_label")}
                    >
                      <DescriptionList.Item
                        label={t("settlement_quotes.details.status_label")}
                      >
                        {assetSettlementQuoteDetails.data.status}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t(
                          "settlement_quotes.details.settlement_date_label",
                        )}
                      >
                        {assetSettlementQuoteDetails.data.settlementDate &&
                          locale.formatDate(
                            assetSettlementQuoteDetails.data.settlementDate,
                          )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t(
                          "settlement_quotes.details.expiration_date_label",
                        )}
                      >
                        {assetSettlementQuoteDetails.data.expiryDate &&
                          locale.formatDate(
                            assetSettlementQuoteDetails.data?.expiryDate,
                          )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t(
                          "settlement_quotes.details.discount_type_label",
                        )}
                      >
                        {assetSettlementQuoteDetails.data.discountType
                          ? assetSettlementQuoteDetails.data.discountType
                          : t("settlement_quotes.details.no_discount_label")}
                      </DescriptionList.Item>

                      {assetSettlementQuoteDetails.data.discountType ===
                      "Percentage" ? (
                        <DescriptionList.Item
                          label={t(
                            "settlement_quotes.details.discount_percentage_label",
                          )}
                        >
                          {`${assetSettlementQuoteDetails.data.discountPercentage}%`}
                        </DescriptionList.Item>
                      ) : (
                        <DescriptionList.Item
                          label={t(
                            "settlement_quotes.details.discount_amount_label",
                          )}
                        >
                          {formatAmount(
                            assetSettlementQuoteDetails.data.discountAmount,
                          )}
                        </DescriptionList.Item>
                      )}
                      <DescriptionList.Item
                        label={t(
                          "settlement_quotes.details.new_periodic_monthly_instalment_label",
                        )}
                      >
                        {formatAmount(
                          assetSettlementQuoteDetails.data
                            .newPeriodicMonthlyInstalment,
                        )}
                      </DescriptionList.Item>
                    </DescriptionList>
                  </Box>

                  <Box>
                    <Typography variant="h5" marginY={2}>
                      {t("settlement_quotes.details.summary_label")}
                    </Typography>
                    <DescriptionList
                      label={t("settlement_quotes.details.summary_label")}
                    >
                      <DescriptionList.Item
                        label={t(
                          "settlement_quotes.details.outstanding_arrears_label",
                        )}
                      >
                        {formatAmount(
                          assetSettlementQuoteDetails.data.outstandingArrears,
                        )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t(
                          "settlement_quotes.details.discount_amount_label",
                        )}
                      >
                        {formatAmount(
                          assetSettlementQuoteDetails.data.discountAmount,
                        )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t("settlement_quotes.details.vat_label")}
                      >
                        {formatAmount(assetSettlementQuoteDetails.data.vat)}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t(
                          "settlement_quotes.details.total_outstanding_capital_and_interest_label",
                        )}
                      >
                        {formatAmount(
                          assetSettlementQuoteDetails.data
                            .totalOutstandingCapitalInterest,
                        )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t(
                          "settlement_quotes.details.total_amount_due_label",
                        )}
                      >
                        {formatAmount(
                          assetSettlementQuoteDetails.data.totalAmountDue,
                        )}
                      </DescriptionList.Item>
                      <DescriptionList.Item
                        label={t(
                          "settlement_quotes.details.total_amount_to_settle_label",
                        )}
                      >
                        {formatAmount(
                          assetSettlementQuoteDetails.data.totalAmountToSettle,
                        )}
                      </DescriptionList.Item>
                    </DescriptionList>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <AccessControl
                  allowedPermissions={[
                    "servicing:asset-settlement-quotes:elevated",
                  ]}
                >
                  <RejectSettlementQuoteContainer
                    settlementQuoteId={settlementQuoteId}
                    settlementStatus={assetSettlementQuoteDetails.data.status}
                    onSuccess={onSuccess}
                  />
                  <ApproveSettlementQuoteContainer
                    settlementQuoteId={settlementQuoteId}
                    settlementStatus={assetSettlementQuoteDetails.data.status}
                    onSuccess={onSuccess}
                  />
                </AccessControl>
              </DialogActions>
            </>
          );
        }}
      />
    </Dialog>
  );
}
