import { DateField } from "@ldms/mui-sdk/forms";
import { Grid, MenuItem, TextField } from "@mui/material";
import { DocumentTypeEnum, TemplateType } from "apps/documents/types";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface LettersToEmailListFilterProps {
  filters: { type?: TemplateType; from?: Date; to?: Date };
  onChange(key: string, value: string | Date | undefined): void;
}

export default function LettersToEmailListFilter({
  filters,
  onChange,
}: Readonly<LettersToEmailListFilterProps>): ReactElement {
  const { t } = useTranslation("documents");

  const handleTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target.value === "" ? undefined : event.target.value;
    onChange(event.target.name, value);
  };

  const makeDateChangeHandler =
    (name: string) =>
    (date: Date | null): void => {
      onChange(name, date ?? undefined);
    };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <DateField
          label={t("letters_to_email.filters.from_filter")}
          name="from"
          margin="none"
          onChange={makeDateChangeHandler("from")}
          value={filters.from ? new Date(filters.from) : null}
        />
      </Grid>
      <Grid item>
        <DateField
          label={t("letters_to_email.filters.to_filter")}
          name="to"
          inputProps={{
            min: filters.from,
          }}
          margin="none"
          onChange={makeDateChangeHandler("to")}
          value={filters.to ? new Date(filters.to) : null}
        />
      </Grid>
      <Grid item>
        <TextField
          inputProps={{ displayEmpty: true, id: "emailToLetterTypeFilter" }}
          InputLabelProps={{
            htmlFor: "emailToLetterTypeFilter",
            shrink: true,
          }}
          onChange={handleTypeChange}
          label={t("letters_to_email.filters.type_filter")}
          name="type"
          select
          size="small"
          value={filters.type ?? ""}
          variant="outlined"
          margin="none"
        >
          <MenuItem value="" key="">
            {t("letters_to_email.filters.all_type_label")}
          </MenuItem>
          {Object.values(TemplateType).map((type) => (
            <MenuItem value={type} key={type}>
              {t(DocumentTypeEnum[type])}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}
