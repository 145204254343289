import { withProtectedView } from "@ldms/mui-sdk/bootstrap";
import { Loader } from "@ldms/mui-sdk/templates";
import { Box, CircularProgress } from "@mui/material";
import { useGetCustomer } from "api/customers/contacts/getCustomer";
import { OnboardStepperContainer } from "apps/onboarding/containers";
import { OnboardingProvider } from "apps/onboarding/providers";
import { ContainedLayout } from "common/layouts";
import { compose } from "lodash/fp";
import { ReactElement } from "react";
import { withTranslationLoader } from "sdk/views";

const getIndividualName = (
  name: string,
  tradingName: string | undefined,
): string => {
  return tradingName ? `${name} (${tradingName})` : name;
};

interface OnboardExistingCustomerViewProps {
  customerId: number;
  initialActiveStep?: string;
}

function ExistingCustomerView({
  customerId,
  initialActiveStep,
}: OnboardExistingCustomerViewProps): ReactElement {
  const customer = useGetCustomer(customerId);

  const name = customer.data?.isIndividual
    ? getIndividualName(customer.data?.name, customer.data?.companyName)
    : customer.data?.companyName;

  const stepRoutes = {
    agreementDetails: "/agreement-details",
    assetDetails: "/asset-details",
    financialDetails: "/financial-details",
    feesAndCommissionsStep: "/fees-and-commissions",
    repaymentTerms: "/repayment-terms",
  };

  return (
    <ContainedLayout maxWidth="lg">
      <Loader
        fallback={
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        }
        ready={Boolean(customer.data ?? customer.error)}
        render={(): ReactElement => (
          <OnboardingProvider customerId={customerId} customerName={name}>
            <OnboardStepperContainer
              initialActiveStep={initialActiveStep}
              routes={stepRoutes}
            />
          </OnboardingProvider>
        )}
      />
    </ContainedLayout>
  );
}

export default compose(
  withTranslationLoader("onboardings"),
  withProtectedView({
    allowedPermissions: ["onboarding:onboarding:manage"],
  }),
)(ExistingCustomerView);
