import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { OnboardingApi } from "generated/onboarding/apis";
import { OnboardingModel } from "generated/onboarding/models";
import { ErrorLike } from "support/error-handler";
import { keys } from ".";

type UseGetOnboarding = (
  onboardingId: number,
) => UseQueryReturn<OnboardingModel, ErrorLike>;

export const useGetOnboarding: UseGetOnboarding = (onboardingId: number) => {
  const api = useApi(OnboardingApi);

  return useQuery(keys.detail(onboardingId), () =>
    api.getOnboarding({ onboardingId: onboardingId }),
  );
};
