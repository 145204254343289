import { useFormat } from "@ldms/mui-sdk/formatting";
import {
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface AssetListProps {
  data: {
    agreementNumber: string;
    assets: {
      assetUrl: string;
      serialNumber?: string;
      registrationNumber?: string;
      description?: string;
      status?: string;
      assetNumber?: number;
      purchasePrice?: string;
      type?: string;
    }[];
  }[];
  label: string;
  loading: boolean;
}

export default function AssetList({
  data,
  label,
  loading,
}: AssetListProps): ReactElement {
  const { t } = useTranslation("assets");
  const format = useFormat();

  const tableHeaders: string[] = [
    t("asset_list.column_headings.asset"),
    t("asset_list.column_headings.serial_number"),
    t("asset_list.column_headings.registration_number"),
    t("asset_list.column_headings.description"),
    t("asset_list.column_headings.purchase_price"),
    t("asset_list.column_headings.status"),
  ];

  return (
    <>
      <Table size="small" aria-label={label} stickyHeader>
        <LoadableTableHead loading={loading} headings={tableHeaders} />
        <TableBody>
          {data?.map((item) => (
            <Fragment key={item.agreementNumber}>
              {Boolean(item.agreementNumber) && (
                <TableRow key={item.agreementNumber}>
                  <TableCell colSpan={6} scope="colgroup">
                    <Typography component="div" variant="h5" marginTop={2}>
                      {item.agreementNumber}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {item.assets.map((asset) => (
                <TableRow key={asset.assetUrl} hover>
                  <TableCell
                    component="th"
                    padding="none"
                    sx={{ width: "20%" }}
                    scope="row"
                  >
                    <ListItemButton
                      component={RouterLink}
                      dense
                      to={asset.assetUrl}
                    >
                      <ListItemText
                        primary={`${asset.type} ${asset.assetNumber}`}
                        primaryTypographyProps={{ color: "primary" }}
                      />
                    </ListItemButton>
                  </TableCell>
                  <TableCell>{asset.serialNumber}</TableCell>
                  <TableCell>{asset.registrationNumber}</TableCell>
                  <TableCell sx={{ width: "40%" }}>
                    {asset.description}
                  </TableCell>
                  <TableCell>
                    {format.formatAmount(asset.purchasePrice)}
                  </TableCell>
                  <TableCell>{asset.status}</TableCell>
                </TableRow>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>

      {(data || []).length === 0 && <NoResults />}
    </>
  );
}
