import { keys } from "api/vat-codes";
import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers";
import { TaxCodesApi } from "generated/servicing-v2/apis";
import { TaxCodeDetailsModel } from "generated/servicing-v2/models";

type UseGetVatCodeDetails = (id: string) => UseQueryReturn<TaxCodeDetailsModel>;

export const useGetVatCodeDetails: UseGetVatCodeDetails = (id: string) => {
  const api = useApi(TaxCodesApi, "v2");

  return useQuery(keys.detail(id), () => {
    return api.getTaxCodeDetails({ id });
  });
};
