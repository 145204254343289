/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing Agreements within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentStructureModel = {
    Regular: 'Regular',
    Seasonal: 'Seasonal',
    Custom: 'Custom'
} as const;
export type PaymentStructureModel = typeof PaymentStructureModel[keyof typeof PaymentStructureModel];


export function PaymentStructureModelFromJSON(json: any): PaymentStructureModel {
    return PaymentStructureModelFromJSONTyped(json, false);
}

export function PaymentStructureModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStructureModel {
    return json as PaymentStructureModel;
}

export function PaymentStructureModelToJSON(value?: PaymentStructureModel | null): any {
    return value as any;
}

