import * as agreementKeys from "api/agreements/keys";

export const all = (agreementId: number | string) => [
  ...agreementKeys.detail(agreementId),
  "payment-instructions",
];

export const list = (agreementId: number | string) => [
  ...all(agreementId),
  "list",
];

export const detail = (
  agreementId: number | string,
  paymentInstructionId: number | string,
) => [...all(agreementId), "detail", paymentInstructionId];
