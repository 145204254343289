/* tslint:disable */
/* eslint-disable */
/**
 * Engage Server
 * API for managing settlement quotes within the Engage Server
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DiscountTypeModel = {
    Percentage: 'Percentage',
    Amount: 'Amount'
} as const;
export type DiscountTypeModel = typeof DiscountTypeModel[keyof typeof DiscountTypeModel];


export function DiscountTypeModelFromJSON(json: any): DiscountTypeModel {
    return DiscountTypeModelFromJSONTyped(json, false);
}

export function DiscountTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountTypeModel {
    return json as DiscountTypeModel;
}

export function DiscountTypeModelToJSON(value?: DiscountTypeModel | null): any {
    return value as any;
}

