import { AlertDialog } from "@ldms/mui-sdk/templates";
import { MoreVert } from "@mui/icons-material";
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useConfigurePortfolio } from "api/documents/portfolios/configurePortfolio";
import { useListPortfolios } from "api/documents/templates/portfolios/listPortfolios";
import {
  LoadableTableHead,
  Loader,
  NoResults,
  QueryError,
  YesNoValue,
} from "common/components";
import ListLayout from "common/layouts/ListLayout";
import { ConfigurePortfolioModel } from "generated/documents/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface UpdatePortfolioMenuProps {
  portfolioId: number;
  enabled: boolean;
}

function UpdatePortfolioMenu({
  portfolioId,
  enabled,
}: Readonly<UpdatePortfolioMenuProps>): ReactElement {
  const { t } = useTranslation("documents");
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuElement);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuElement(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setMenuElement(null);
  };

  const configurePortfolio = useConfigurePortfolio(portfolioId, {
    onSuccess: handleMenuClose,
  });

  const handleAlertClose = () => {
    handleMenuClose();
    configurePortfolio.reset();
  };

  const onSubmit = async () => {
    const configurePortfolioModel: ConfigurePortfolioModel = {
      isSystemLetterVerificationEnabled: !enabled,
    };
    await configurePortfolio.execute(configurePortfolioModel);
  };

  const menuItem = !enabled
    ? t("system_letters.enable_label")
    : t("system_letters.disable_label");

  return (
    <>
      <IconButton
        aria-label={t("system_letters.menu_button")}
        color="primary"
        onClick={handleMenuClick}
        size="small"
      >
        <MoreVert fontSize="inherit" />
      </IconButton>

      <Menu open={menuOpen} anchorEl={menuElement} onClose={handleMenuClose}>
        <MenuItem key="system-letters " onClick={onSubmit}>
          {menuItem}
        </MenuItem>
      </Menu>

      <AlertDialog
        title={t("common:error.title")}
        content={configurePortfolio.error?.message}
        labels={{ close: t("common:alert.close") }}
        onClose={handleAlertClose}
        open={Boolean(configurePortfolio.error?.message)}
      />
    </>
  );
}

export default function SystemLettersContainer(): ReactElement {
  const { t } = useTranslation("documents");
  const portfolios = useListPortfolios();

  return (
    <Loader
      ready={Boolean(portfolios.data ?? portfolios.error)}
      render={(): ReactElement => {
        if (portfolios.error || !portfolios.data) {
          return <QueryError onRetry={portfolios.refetch} />;
        }

        return (
          <ListLayout>
            <Table
              aria-label={t("system_letters.heading")}
              size="small"
              stickyHeader
            >
              <LoadableTableHead
                headings={[
                  t("system_letters.portfolio_name_heading"),
                  t("system_letters.require_verification_heading"),
                  "",
                ]}
                loading={portfolios.isValidating}
              />
              <TableBody>
                {portfolios.data.map((portfolio) => (
                  <TableRow key={portfolio.id} hover>
                    <TableCell>{portfolio.name}</TableCell>
                    <TableCell>
                      <YesNoValue
                        value={portfolio.systemLetterVerificationEnabled}
                      />
                    </TableCell>
                    <TableCell sx={{ paddingY: 0.25 }} align="right">
                      <UpdatePortfolioMenu
                        portfolioId={portfolio.id}
                        enabled={portfolio.systemLetterVerificationEnabled}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {portfolios.data.length === 0 && <NoResults />}
          </ListLayout>
        );
      }}
    />
  );
}
