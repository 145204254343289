/* tslint:disable */
/* eslint-disable */
/**
 * Engage Documents
 * API for managing documents
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateEmailModel,
} from '../models';
import {
    CreateEmailModelFromJSON,
    CreateEmailModelToJSON,
} from '../models';

export interface SendEmailRequest {
    createEmailModel: CreateEmailModel;
}

/**
 * 
 */
export class EmailApi extends runtime.BaseAPI {

    /**
     * Create and send an email
     */
    async sendEmailRaw(requestParameters: SendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createEmailModel === null || requestParameters.createEmailModel === undefined) {
            throw new runtime.RequiredError('createEmailModel','Required parameter requestParameters.createEmailModel was null or undefined when calling sendEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmailModelToJSON(requestParameters.createEmailModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create and send an email
     */
    async sendEmail(requestParameters: SendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendEmailRaw(requestParameters, initOverrides);
    }

}
